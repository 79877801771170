import { Form, Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useState } from "react";
import { CTAButton, Message, BackButton, OTPInput } from "../../common";
import InputPassword from "../common/InputPassword";
import Heading from "../Heading";
import api from "../../API";
import { validation } from "../config";
import maskEmail from "../util/maskEmail";
import generateErrorMessage from "../../util/functions/customError";
import { MAX_MIN } from "../../util/constants";
import { validatePassword } from "../../util/functions/onboarding";
import REGEX from "../../util/constants/regex";
import { ROUTES } from "../../constants";
import classNames from "./ForgotPassword.module.scss";

export default function EnterOTP() {
  const navigate = useNavigate();
  const [newRank, setNewRank] = useState(0);
  const [form] = Form.useForm();

  const forgotPWSubmitOTP = useMutation((data) => {
    return api.onBoarding.forgotPasswordOTP(data);
  });

  const forgotPWResendOTP = useMutation((data) => {
    return api.onBoarding.forgotPassword(data);
  });

  async function handleOnFinish(values) {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    let code = Object.keys(values)
      .filter((item) => item !== "password" && item !== "confirmPassword")
      .map((item) => values[item])
      .join("");

    forgotPWSubmitOTP.mutate(
      {
        email: window.localStorage.getItem("OTTERZ.FPEmail"),
        code: code,
        newPassword: values.password,
      },
      {
        onSuccess: () => {
          Message({
            type: "success",
            content: "Password changed Successfully",
          });
          window.localStorage.removeItem("OTTERZ.FPEmail");
          navigate(ROUTES.PUBLIC.LOGIN);
        },
        onError: (error) => generateErrorMessage(error),
      }
    );
  }

  const handleResend = () => {
    forgotPWResendOTP.mutate(window.localStorage.getItem("OTTERZ.FPEmail"), {
      onSuccess: () => {
        Message({ type: "success", content: "OTP resent successfully" });
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  //Helper
  const rankFunction = () => {
    if (newRank === 1) {
      return {
        color: ["#ff0000", "#e8e8e8", "#e8e8e8"],
        value: "Password is weak",
      };
    } else if (newRank === 2) {
      return {
        color: ["#ffa500", "#ffa500", "#e8e8e8"],
        value: "Password is medium",
      };
    } else if (newRank === 3) {
      return {
        color: ["#00ff00", "#00ff00", "#00ff00"],
        value: "Password is strong",
      };
    } else {
      return {
        color: ["#e8e8e8", "#e8e8e8", "#e8e8e8"],
        value: "",
      };
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.backButtonWrapper}>
        <BackButton variant="tail" onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Forgot Password"
          description={`An OTP has been sent to your email address
          ${maskEmail(window.localStorage.getItem("OTTERZ.FPEmail"))}`}
        />
        <Spin spinning={forgotPWResendOTP.isLoading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item
              label="Enter OTP"
              rules={[{ required: true, message: "required" }]}
            >
              <OTPInput />
            </Form.Item>
            <InputPassword
              label="New password"
              name="password"
              rules={[
                { required: true, message: validation.password.required },
                {
                  min: MAX_MIN.password.min,
                  message: validation.password.minLength,
                },
                {
                  max: MAX_MIN.password.max,
                  message: validation.password.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.password),
                  message: validation.password.regex,
                },
                {
                  validator: async (_, password, callback) => {
                    if (password) {
                      if (!validatePassword(password, setNewRank)) {
                        callback(validation.password.regex);
                      }
                    } else {
                      setNewRank(0);
                    }
                  },
                },
              ]}
              extra={
                <sup style={{ top: "5px" }}>{validation.password.hint}</sup>
              }
              placeholder={validation.password.placeholder}
            />
            <div className={classNames.passHint}>
              <div>Password strength :</div>
              <span
                style={{ backgroundColor: rankFunction()?.color?.[0] }}
              ></span>
              <span
                style={{ backgroundColor: rankFunction()?.color?.[1] }}
              ></span>
              <span
                style={{ backgroundColor: rankFunction()?.color?.[2] }}
              ></span>
              <div key={newRank} style={{ color: rankFunction()?.color?.[0] }}>
                {rankFunction()?.value}
              </div>
            </div>

            <InputPassword
              label="Confirm new password"
              name="confirmPassword"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(validation.confirmPassword.match)
                    );
                  },
                }),
                {
                  required: true,
                  message: validation.confirmPassword.required,
                },
              ]}
              placeholder={validation.confirmPassword.placeholder}
            />
            <div className={classNames.links} style={{ textAlign: "left" }}>
              <span>Didn’t receive email? </span>
              <Button type="link" size="small" onClick={() => handleResend()}>
                Resend
              </Button>
            </div>
            <CTAButton
              htmlType="submit"
              type="primary"
              loading={forgotPWSubmitOTP.isLoading}
              disabled={newRank !== 3}
            >
              Next
            </CTAButton>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
