import classNames from "./HostedPayment.module.scss";
import { CTAButton, Modal } from "../../../../common";
import HostedPaymentForm from "./HostedPaymentForm";
import React, { useState } from "react";

export default function HostedPayment({ visible, onCancel }) {
  const [generatePaymentLink, setGeneratePaymentLink] = useState(false);

  const onOk = () => {
    // form.submit();
    console.log("confirm");
    setGeneratePaymentLink(true);
  };

  return (
    <>
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title="Customize payment page"
        className={classNames.modal}
        bodyStyle={{
          height: "70vh",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        footer={
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={onOk}
            style={{
              height: 44,
              width: 385,
            }}
          >
            Generate Payment Link
          </CTAButton>
        }
        width={866}
      >
        <HostedPaymentForm generatePaymentLink={generatePaymentLink} />
      </Modal>
    </>
  );
}
