import { useState, useContext, useEffect } from "react";
import { Card, Form, Input as AntInput } from "antd";
import { useQuery } from "react-query";
import { Title, CTAButton, Select, Input } from "../../../common";
import ConfirmPayment from "./ConfirmPayment/ConfirmPayment";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import { centsToDollar } from "../../../util/functions/dollarConvertion";
import { isBusinessType } from "../../../util/functions/onboarding";
import messages from "../../config/validation.messages";
import REGEX from "../../../util/constants/regex";
import { MAX_MIN } from "../../../util/constants";
import { DEFAULT_AMOUNT } from "../../../constants";
import classNames from "../Overview.module.scss";
import AccountActivityModal from "../../config/accountActivityModal";

export default function QuickFundTransfer({ balance, setRefetch }) {
  //Hooks
  const { application } = useContext(AppContext);
  const [payee, setPayee] = useState();
  const [paymentData, setPaymentData] = useState();
  const [confirmPaymentModalVisibility, setConfirmPaymentModalVisibility] =
    useState(false);
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
    useState(true);
  const [form] = Form.useForm();

  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

  useEffect(() => {
    forceUpdate({});
  }, []);

  //Other variables
  const { TextArea } = AntInput;
  const applicationData = application;
  const id = isBusinessType(applicationData?.type)
    ? applicationData?.otz_business_id
    : applicationData?.otz_person_id;

  //Api calls
  const { data: payeeData } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: id }
          : { otz_person_id: id }),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  /**
   * TODO: I think the dev didnt delete this as wirepayments gonna be implemented
   * post MVP. Hence leaving it here
   */

  //Event handlers
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let newACHPayment = {
        amount: values?.amount,
        otz_payee_id: payee?.otz_payee_id,
        description: values?.description,
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: id }
          : { otz_person_id: id }),
      };
      setPaymentData({ payee, payment: newACHPayment });

      // Account Activity Modal
      if(isAccountInfoNeeded) {
        setAccountActivityVisible(true);
      } else {
        setConfirmPaymentModalVisibility(true);
      }
    });
  };

  const handleConfirmPaymentModalCancelClick = () => {
    setConfirmPaymentModalVisibility(false);
    setRefetch(true);
  };

  const [accountActivityVisible, setAccountActivityVisible] =
  useState(false);

  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };

  return (
    <Card className={classNames.fundTransferCard}>
      <div className={classNames.fundTransferCardHeader}>
        <Title as="h3">Quick Fund Transfer</Title>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        hideRequiredMark
        className={classNames.fundTransferForm}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item
          label={
            <span className={classNames.label}>
              Payee <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          name="payee"
          className={classNames.transferType}
          rules={[
            { required: true, message: messages.quickFundTransfer.payee },
          ]}
        >
          <Select
            size="small"
            placeholder={messages.quickFundTransfer.dropdown}
            onSelect={(e) =>
              setPayee(
                payeeData?.payload?.find((item) => item?.otz_payee_id === e)
              )
            }
          >
            {payeeData &&
              payeeData.payload &&
              payeeData.payload.length > 0 &&
              payeeData.payload.map((value) => (
                <Select.Option value={value?.otz_payee_id}>
                  {`${value?.account_owner_name} - (${value?.account_number_masked})`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: messages.quickFundTransfer.amount },
            {
              pattern: new RegExp(REGEX.amount),
              message: messages.quickFundTransfer.validAmount,
            },
            {
              validator: async (_, amount) => {
                if (Number(amount) === 0) {
                  return Promise.reject(
                    new Error(messages.quickFundTransfer.validAmount)
                  );
                } else if (amount * 100 > balance) {
                  return Promise.reject(
                    new Error(messages.quickFundTransfer.insufficientFund)
                  );
                } else if (!Number(balance)) {
                  return Promise.resolve();
                }
              },
            },
          ]}
          label={
            <span className={classNames.label}>
              Amount <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          name="amount"
          className={classNames.transferAmount}
        >
          <Input
            size="small"
            prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            placeholder={messages.quickFundTransfer.amountPlaceholder}
          />
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <span style={{ top: "6px", fontSize: 12, color: "#82898F" }}>
            Available Balance:
            <span style={{ color: "#000" }}>
              {!Number(balance) ? DEFAULT_AMOUNT : centsToDollar(balance)}
            </span>
          </span>
        </div>
        <Form.Item
          label={
            <span className={classNames.label}>
              Reason
            </span>
          }
          name="description"
          className={classNames.transferReason}
          rules={[
            // { required: true, message: messages.quickFundTransfer.description },
            {
              max: MAX_MIN.description.max,
              message: messages.quickFundTransfer.descriptionMax,
            },
          ]}
        >
          <TextArea
            autoSize
            placeholder={messages.quickFundTransfer.placeHolder}
          ></TextArea>
        </Form.Item>

        <div className={classNames.fundTransferCardFooter}>
          <Form.Item shouldUpdate>
            {() => (
              <CTAButton
                disabled={
                  !(form.isFieldTouched('payee') && form.isFieldTouched('amount')) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                  +balance <= 0
                }
                htmlType="submit"
                type="primary"
                style={{ height: 44, width: 330 }}
                lo
              >
                Transfer
              </CTAButton>
            )}
          </Form.Item>
        </div>
      </Form>

      <ConfirmPayment
        visible={confirmPaymentModalVisibility}
        onCancel={handleConfirmPaymentModalCancelClick}
        paymentData={paymentData && paymentData}
        form={form}
      />
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setConfirmPaymentModalVisibility}
        spawnModal={setAccountActivityVisible}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        title="Outgoing ACH Transactions"
      />
    </Card>
  );
}
