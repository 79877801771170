import { API } from "aws-amplify";

export default async function createApplication(data, isBusiness) {
  return await API.post(
    "createApplication",
    `${isBusiness ? "business" : "person"}`,
    {
      body: {
        ...data,
      },
    }
  );
}
