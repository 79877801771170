import { API } from "aws-amplify";

export default async function updateScheduledPayment(data) {
  return await API.put("payments", "update-scheduled-payment/", {
    queryStringParameters: {
      id: data.data.id,
    },
    body: {
      ...data.data,
    },
  });
}
