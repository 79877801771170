import { Outlet } from "react-router-dom";
import "./App.less";
import "remixicon/fonts/remixicon.css";
import "react-vis/dist/style.css";
import "swiper/css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Outlet />
      </div>
    </QueryClientProvider>
  );
};

export default App;
