export const removeValuesFromLocalStorage = (isEmailVerified, isApproved) => {
  if (isApproved) {
    localStorage.removeItem("OTTERZ.application");
    localStorage.removeItem("OTTERZ_op_accountType");
    localStorage.removeItem("OTTERZ_op_individualApplication");
    localStorage.removeItem("OTTERZ_op_bd");
    localStorage.removeItem("OTTERZ_op_od");
    localStorage.removeItem("OTTERZ_op_bo");
    localStorage.removeItem("UNIT_customer_token");
  }

  if (isEmailVerified) {
    localStorage.removeItem("OTTERZ.emailVerified");
    localStorage.removeItem("OTTERZ.verifyEmail");
    localStorage.removeItem("UNIT_customer_token");
  }
};
