import React from "react";
import { Button } from "antd";
import CompassDiscoverLineIcon from "remixicon-react/CompassDiscoverLineIcon";

import { DEFAULT_MAP_CENTER_COORDS } from "./ATMLocator";

function LocateUserLocation({ onCenter }) {
  return (
    <Button
      style={{
        position: "absolute",
        borderRadius: 1,
        boxshadow: "0px 0px 15px rgba(0, 0, 0, 0.4)",
        bottom: "7rem",
        right: "0.6rem",
        zIndex: "10",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: 7.5,
        width: 40,
        height: 40,
      }}
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            onCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => {
            onCenter(DEFAULT_MAP_CENTER_COORDS);
          }
        );
      }}
    >
      <CompassDiscoverLineIcon size={24} color="#7372FF" />
    </Button>
  );
}

export default LocateUserLocation;
