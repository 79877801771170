import { API } from "aws-amplify";

export default async function listSchedule(pageToken, data, download) {
  return await API.post("pa-transaction", `/schedule/list-schedule`, {
    body: {
      data: {
        filters: { ...data },
        nextToken: download ? "" : pageToken,
        pageSize: download ? 1000 : 10,
        SortOrder: "Descending",
      },
    },
  });
}
