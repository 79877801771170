import { API } from "aws-amplify";

export default async function createCustomerToken(data) {
  if (data?.customerId) {
    return await API.post("cards", "create-token", {
      queryStringParameters: {
        customerId: data.customerId,
      },
    });
  }
}
