import { useState } from "react";
import { Tooltip } from "antd";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  GradientDefs,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  Hint,
  MarkSeries,
} from "react-vis";
import {
  RiArrowLeftDownLine,
  RiArrowRightUpLine,
  RiInformationLine,
} from "react-icons/ri";

import { centsToDollar } from "../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../../constants";
import classNames from "./BankAccountBalance.module.scss";

const BankAccountBalance = ({
  dataArray,
  averageBalance,
  highestBalance,
  lowestBalance,
  period,
  height,
  width,
}) => {
  const [state, setState] = useState();
  const forgetValue = () => {
    setState(null);
  };
  const rememberValue = (value) => {
    setState(value);
  };
  return (
    <div className={classNames.wrapper}>
      <div
        className={classNames.chartWrapper}
        key="BankAccountBalanceChartWrapper"
      >
        <div className={classNames.chartName} key="BankAccountBalanceChartName">
          Bank Account Balance
          <Tooltip
            title={`The typical cutoff time (end-of-${period}) is 7PM`}
            placement="right"
          >
            <RiInformationLine />
          </Tooltip>
        </div>
        <div key="BankAccountBalanceChart" className={classNames.chart}>
          <FlexibleXYPlot
            height={height}
            width={width}
            key="BankAccountBalance"
            xType="ordinal"
            margin={{ left: 55, right: 0, top: 50, bottom: 30 }}
            animation={true}
          >
            <XAxis />
            <YAxis
              tickFormat={(value) => {
                const lookupArray = [
                  { scale: 1, symbol: "" },
                  { scale: 1e3, symbol: "k" },
                  { scale: 1e6, symbol: "M" },
                  { scale: 1e9, symbol: "G" },
                  { scale: 1e12, symbol: "T" },
                  { scale: 1e15, symbol: "P" },
                  { scale: 1e18, symbol: "E" },
                ];
                const regX = /\.0+$|(\.[0-9]*[1-9])0+$/;
                // divide the value (bank account balance) by 100 as
                // the data receiving in cents by default from Unit
                let item = lookupArray
                  .slice()
                  .reverse()
                  .find(function (item) {
                    return Math.abs(value / 100) >= item.scale;
                  });
                return item
                  ? (value / 100 / item.scale).toFixed(2).replace(regX, "$1") +
                      item.symbol
                  : "0";
              }}
              tickPadding={1}
            />
            <HorizontalGridLines />
            <MarkSeries
              onNearestXY={rememberValue}
              onValueMouseOver={rememberValue}
              onValueMouseOut={forgetValue}
              data={
                Array.isArray(dataArray) &&
                dataArray.length > 0 &&
                dataArray.map((value, index) => {
                  return { x: `${value.x}`, y: `${value.y}` };
                })
              }
            />
            {state ? (
              <Hint className={classNames.hint} value={state}>
                <p>{`${centsToDollar(state.y) || DEFAULT_AMOUNT}`}</p>
              </Hint>
            ) : null}
            <GradientDefs>
              <linearGradient x1="0" x2="0" y1="0" y2="1" id="areaGradient">
                <stop offset="0%" stopColor="#52EABC3B" />
                <stop offset="100%" stopColor="#52EABC3B" stopOpacity="-0.1" />
              </linearGradient>
            </GradientDefs>

            <AreaSeries
              animation={true}
              data={dataArray}
              colorType="literal"
              color={"url(#areaGradient)"}
              barWidth={1}
              curve={"curveMonotoneX"}
            />

            <LineSeries
              // animation={true}
              data={dataArray}
              colorType="literal"
              color={"#1DBC83"}
              barWidth={1}
              curve={"curveMonotoneX"}
              lineStyle={{ stroke: "red" }}
              markStyle={{ stroke: "black", fill: "red" }}
            />
          </FlexibleXYPlot>
        </div>
      </div>
      <div className={classNames.itemsWrapper}>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div
              className={classNames.iconContainer}
              style={{ backgroundColor: averageBalance < 0 && "#fff3f3" }}
            >
              {averageBalance >= 0 ? (
                <RiArrowLeftDownLine color="#1DBC83" size={18} />
              ) : (
                <RiArrowRightUpLine color="#ee6969" size={18} />
              )}
            </div>
            <div
              className={classNames.heading}
              style={{ color: averageBalance < 0 && "#ee6969" }}
            >
              Average Balance
            </div>
          </div>
          <div className={classNames.count}>
            {centsToDollar(Math.abs(averageBalance)) || DEFAULT_AMOUNT}
          </div>
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div
              className={classNames.iconContainer}
              style={{ backgroundColor: highestBalance < 0 && "#fff3f3" }}
            >
              {highestBalance >= 0 ? (
                <RiArrowLeftDownLine color="#1DBC83" size={18} />
              ) : (
                <RiArrowRightUpLine color="#ee6969" size={18} />
              )}
            </div>
            <div
              className={classNames.heading}
              style={{ color: highestBalance < 0 && "#ee6969" }}
            >
              Highest Balance
            </div>
          </div>
          <div className={classNames.count}>
            {centsToDollar(Math.abs(highestBalance)) || DEFAULT_AMOUNT}
          </div>
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div
              className={classNames.iconContainer}
              style={{ backgroundColor: lowestBalance < 0 && "#fff3f3" }}
            >
              {lowestBalance >= 0 ? (
                <RiArrowLeftDownLine color="#1DBC83" size={18} />
              ) : (
                <RiArrowRightUpLine color="#ee6969" size={18} />
              )}
            </div>
            <div
              className={classNames.heading}
              style={{ color: lowestBalance < 0 && "#ee6969" }}
            >
              Lowest Balance
            </div>
          </div>
          <div className={classNames.count}>
            {centsToDollar(Math.abs(lowestBalance)) || DEFAULT_AMOUNT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountBalance;
