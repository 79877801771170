// import endpoints from '../../API/endpoints'

const baseUrl = "https://api.s.unit.sh/atm-locations";

export default async function getATMLocations(input) {
  function formatParams(input) {
    let result;
    if (typeof input === "object") {
      result = {
        longitute: input.lng.toString(),
        latitute: input.lat.toString(),
      };
    }
    return JSON.stringify(result);
  }
  let url;

  const { filter, searchRadius } = input;

  //postal
  if (filter === "postalCode" && !searchRadius) {
    url = `${baseUrl}?filter%5BpostalCode%5D=${input.filterParams}`;
  } else if (filter === "postalCode" && searchRadius) {
    url = `${baseUrl}?filter%5BpostalCode%5D=${input.filterParams}&filter%5BsearchRadius%5D=${input.searchRadius}`;

    //coordinates
  } else if (filter === "coordinates" && !searchRadius) {
    let params = formatParams(input.filterParams);
    let urlFormatted = new URL(baseUrl);
    urlFormatted.search = new URLSearchParams(params).toString();
  } else if (filter === "coordinates" && searchRadius) {
    url = `${baseUrl}?filter%5Bcoordinates%5D=%7B%22longitude%22%3A%20%22${input.filterParams.lng}%22,%20%22latitude%22%3A%20%22${input.filterParams.lat}%22%7D&filter%5BsearchRadius%5D=${input.searchRadius}`; //refractor pending

    //address
  } else if (filter === "address" && !searchRadius) {
    url = `${baseUrl}?filter%5Baddress%5D=${input.filterParams}`;
  } else if (filter === "address" && searchRadius) {
    url = `${baseUrl}?filter%5Baddress%5D=${input.filterParams}&filter%5BsearchRadius%5D=${input.searchRadius}`;
  }
  // switch (true) {
  //   case input.filter === "postalCode" && !input.searchRadius:
  //     url = `${baseUrl}?filter%5BpostalCode%5D=${input.filterParams}`;
  //     break;
  //   case input.filter === "postalCode" && input.searchRadius:
  //     console.log("hit");
  //     url = `${baseUrl}?filter%5BpostalCode%5D=${input.filterParams}&filter%5BsearchRadius%5D=${input.searchRadius}`;
  //     console.log(url);
  //     break;
  //   case input.filter === "coordinates" && !input.searchRadius:
  //     url = `${baseUrl}?filter%5BpostalCode%5D=${input.filterParams}`;
  //     break;
  //   case input.filter === "coordinates" && input.searchRadius:
  //     url = `${baseUrl}?filter%5Bcoordinates%5D=${input.filterParams}&filter%5BsearchRadius%5D=${input.searchRadius}`;
  //     break;
  //   case input.filter === "address" && !input.searchRadius:
  //     url = `${baseUrl}?filter%5Baddress%5D=${input.filterParams}`;
  //     break;
  //   case input.filter === "address" && input.searchRadius:
  //     url = `${baseUrl}?filter%5Baddress%5D=${input.filterParams}&filter%5BsearchRadius%5D=${input.searchRadius}`;
  //     break;
  //   default:
  //     console.log("Error");
  // }

  return await (
    await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer v2.public.eyJyb2xlIjoiYWRtaW4iLCJ1c2VySWQiOiIxNTMwIiwic3ViIjoiZG1mZXJuYW5kbzkxQGdtYWlsLmNvbSIsImV4cCI6IjIwMjMtMDItMDRUMDM6MTI6MDMuOTU0WiIsImp0aSI6IjExNjI0MiIsIm9yZ0lkIjoiODg2Iiwic2NvcGUiOiJhcHBsaWNhdGlvbnMgYXBwbGljYXRpb25zLXdyaXRlIGN1c3RvbWVycyBjdXN0b21lci10YWdzLXdyaXRlIGN1c3RvbWVyLXRva2VuLXdyaXRlIGFjY291bnRzIGFjY291bnRzLXdyaXRlIGNhcmRzIGNhcmRzLXNlbnNpdGl2ZSB0cmFuc2FjdGlvbnMgYXV0aG9yaXphdGlvbnMgc3RhdGVtZW50cyBwYXltZW50cyBwYXltZW50cy13cml0ZS1jb3VudGVycGFydHkgcGF5bWVudHMtd3JpdGUtYWNoLWRlYml0IGNvdW50ZXJwYXJ0aWVzIGJhdGNoLXJlbGVhc2VzIGJhdGNoLXJlbGVhc2VzLXdyaXRlIHdlYmhvb2tzIHdlYmhvb2tzLXdyaXRlIGV2ZW50cyBldmVudHMtd3JpdGUgYXV0aG9yaXphdGlvbi1yZXF1ZXN0cyBhdXRob3JpemF0aW9uLXJlcXVlc3RzLXdyaXRlIGNoZWNrLWRlcG9zaXRzIGNoZWNrLWRlcG9zaXRzLXdyaXRlIGZ1dHVyZS1pbmNvbWluZy1wYXltZW50cyIsIm9yZyI6IlNtYXNoVGFwc1Rlc3QiLCJzb3VyY2VJcCI6IiIsInVzZXJUeXBlIjoib3JnIiwiaXNVbml0UGlsb3QiOmZhbHNlfVbmQw0qO3sMisM1v9cTHJnebgFlZHBU9x1FAzP_IgURph8GWnBrVGbWSctW4B8EtiFTl9_pqG07MPlz0W8l0Qk`,
        //Test bearer token from sandbox
      },
    })
  ).json();
}
