import { useContext } from "react";
import { useMutation } from "react-query";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import { AppContext } from "../../../../../context/AppState";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import { isBusinessType } from "../../../../util/functions/onboarding";
import classNames from "../../MakePayments.module.scss";

export default function DeletePayee({
  visible,
  onCancel,
  dataId,
  queryClient,
  refetch,
}) {
  //Hooks
  const { application } = useContext(AppContext);

  //Api calls
  const { mutate: deletePayeeDetail, isLoading } = useMutation(
    (body) => api.BusinessBanking.deletePayee(body),
    {
      onSuccess: () => {
        Message({ type: "success", content: "Payee deleted successfully" });
        queryClient.invalidateQueries("getPayees");
        onCancel();
        refetch();
      },
      onError: (error) => generateErrorMessage(error),
    }
  );

  // const { mutate: deleteMultipleMutate, isLoading: isLoadingMultiple } =
  //   useMutation(api.BusinessBanking.deletePayee, {
  //     onError: (error) => generateErrorMessage(error),
  //   });

  // async function deleteMultiple() {
  //   data
  //     ?.map((record) => record.key)
  //     .forEach((key) => {
  //       deleteMultipleMutate(
  //         { counterpartyId: key },
  //         {
  //           onSuccess: () => {
  //             Message({
  //               type: "success",
  //               content: "Payees deleted successfully",
  //             });
  //             queryClient.invalidateQueries("getPayees");
  //             onCancel();
  //           },
  //           onError: (error) => generateErrorMessage(error),
  //         }
  //       );
  //     });
  // }

  const handleDeleteApi = () => {
    const reqBody = {
      id: dataId,
      body: {
        ...(isBusinessType(application?.type)
          ? { otz_business_id: application?.otz_business_id }
          : {
              otz_person_id: application?.otz_person_id,
            }),
      },
    };
    deletePayeeDetail(reqBody);
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete payee?
      </Title>
      {/* {data.length > 1 ? (
        <div style={{ marginBottom: 8 }}>
          Are you sure you want to delete these {data.length} payee(s)?
        </div>
      ) : ( */}
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this payee?
      </div>
      {/* )} */}
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          loading={isLoading}
          onClick={() => {
            // data?.length === 1
            handleDeleteApi();
            // : deleteMultiple();
          }}
        >
          Delete
        </CTAButton>
      </div>
    </Modal>
  );
}
