import React, { useEffect, useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { Form, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { CTAButton, Message } from "../../../common";
import Steps from "../../Steps";
import Heading from "../../Heading";
import { AppContext } from "../../../../context/AppState";
import getApplicationAC from "../../../../context/actions/application/getApplicationByTenantId.ac";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import camelCaseToTitle from "../../../BusinessBanking/ManageCards/CardCarousel/camelCaseToTitle.util";
import DocumentMap from "./DocumentMap";
import {
  ACCOUNT_TYPE,
  ROUTES,
  ROUTE_STATUS,
  USER_STATES,
} from "../../../constants";
import { DOCUMENT_STATUS } from "../../../constants";
import classNames from "../Signup.module.scss";

export default function UploadDocuments() {
  const navigate = useNavigate();

  const [toBeUploadDocs, setToBeUploadDocs] = useState();
  const [trackingdDocs, setTrackingdDocs] = useState();
  const [isDisableNext, setIsDisableNext] = useState(false);
  const {
    application: { application },
    dispatchApplication,
    userData,
  } = useContext(AppContext);

  const [pending, setPending] = useLocalStorage("OTTERZ_upload_docs");

  // checking account type
  const accountType = JSON.parse(
    localStorage.getItem("OTTERZ_op_accountType")
  )?.accountType;

  function handleOnFinish() {
    let count = 0;
    if (application?.data) {
      Object.entries(application?.data?.data?.documentStatus).forEach(
        (value, index, array) => {
          if (value[1].docStatus.toLowerCase() === "required") {
            count += 1;
            Message({
              type: "error",
              content: `${camelCaseToTitle(value[1].documentType)} is required`,
            });
            setPending({ completed: false });
          } else {
            setPending({ completed: true });
          }
          if (index === array.length - 1 && count === 0 && pending.completed) {
            navigate(
              `${ROUTES.ONBOARDING.THANK_YOU}${
                ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
              }`
            );
          }
        }
      );
    }
  }

  useEffect(() => {
    if (trackingdDocs === undefined) {
      setIsDisableNext(true);
    } else if (trackingdDocs.length > 0) {
      const actionRequiredDocs = trackingdDocs.filter((o) => {
        return (
          !o.isUploaded &&
          [
            DOCUMENT_STATUS.INVALID.toLowerCase(),
            DOCUMENT_STATUS.REQUIRED.toLowerCase(),
          ].includes(o.docStatus.toLowerCase())
        );
      });
      setIsDisableNext(actionRequiredDocs.length > 0);
    }
  }, [trackingdDocs]);

  useEffect(() => {
    async function getCurrentUser() {
      try {
        const user = await Auth.currentSession();
        if (user) {
          getApplicationAC(user?.idToken?.payload?.tenantId)(
            dispatchApplication
          );
        }
      } catch (error) {
        navigate(ROUTES.PUBLIC.LOGIN);
      }
    }
    getCurrentUser();
  }, [dispatchApplication, navigate]);

  useEffect(() => {
    if (application?.data) {
      let docsData = Object.entries(
        application?.data?.data?.documentStatus
      ).map((value, index) => {
        return { documentId: value[0], ...value[1] };
      });
      setToBeUploadDocs(docsData);
      setTrackingdDocs((prev) => {
        if (prev === undefined) {
          return docsData.map((value) => {
            return { isUploaded: false, ...value };
          });
        } else {
          return prev;
        }
      });
    }
  }, [application, navigate]);

  return (
    <Spin spinning={false}>
      <div className={classNames.accountFormWrapper}>
        <Steps
          classNames={classNames}
          currentIndex={accountType === ACCOUNT_TYPE.INDIVIDUAL ? 4 : 6}
          backButtonDisabled
        />
        <div className={classNames.formWrapper}>
          <Heading
            classNames={classNames}
            title="Upload Documents"
            description="Please upload the supporting documents as requested below"
          />

          <Form
            onFinish={handleOnFinish}
            layout="vertical"
            hideRequiredMark
            autoComplete="off"
          >
            {toBeUploadDocs &&
              toBeUploadDocs.map((value) => {
                return (
                  <DocumentMap
                    key={value?.documentId}
                    applicationId={application?.data?.data?.applicationId}
                    applicationDBId={application?.data?.data?.id}
                    tenantId={userData.tenantId}
                    dispatcAcRef={dispatchApplication}
                    setUploadTrackingDocs={setTrackingdDocs}
                    ownerName={value?.documentOwner}
                    {...value}
                  />
                );
              })}

            <Form.Item style={{ marginTop: 25 }}>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <CTAButton
                  htmlType="submit"
                  type="primary"
                  loading={application?.loading}
                  disabled={isDisableNext}
                >
                  Next
                </CTAButton>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
