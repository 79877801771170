export default class flattenObject {
  constructor(object) {
    this.sourceObject = object;
    this.destinationObject = {};
  }

  keyLabel(parent, child, prepend, separator) {
    return `${parent || ""}${parent ? separator : ""}${child}${prepend || ""}`;
  }

  recursiveByKeys(object, key, prepend, separator) {
    Object.entries(object).forEach(([keyName, value]) => {
      if (typeof value === "object") {
        this.recursiveByKeys(value, this.keyLabel(key, keyName, null, separator), prepend, separator);
      } else {
        this.destinationObject[this.keyLabel(key, keyName, prepend, separator)] = value;
      }
    });
  }

  recursivelyFlattenObject(object) {
    Object.entries(object).forEach(([key, value]) => {
      if (typeof object[key] === "object") {
        this.recursivelyFlattenObject(object[key]);
      } else {
        this.destinationObject[key] = object[key];
      }
    });
  }

  byKey(separator = '_', prepend) {
    this.destinationObject = {};
    this.recursiveByKeys(this.sourceObject, null, prepend, separator);
    return this.destinationObject;
  }

  byTail() {
    this.destinationObject = {};
    this.recursivelyFlattenObject(this.sourceObject);
    return this.destinationObject;
  }
}
