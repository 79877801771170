import { Auth } from "aws-amplify";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { CTAButton, BackButton, Input, Message } from "../../common";
import Heading from "../Heading";
import { useLocalStorageData } from "../../../hooks/useLocalStorage";
import generateErrorMessage from "../../util/functions/customError";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import { ROUTES } from "../../constants";
import { validation } from "../config";
import classNames from "./ChildUserOnboarding.module.scss";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const email = useLocalStorageData("TEMP_USER_EMAIL");
  const tempPassword = useLocalStorageData("TEMP_USER_PW");

  function performLogin(email, tempPW, newPassword) {
    Auth.signIn(email, tempPW)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword)
            .then((value) => value)
            .catch((err) => err);
        }
      })
      .catch((error) => error);
  }

  const loginMutation = useMutation((data) => {
    return performLogin(data.email, data.tempPassword, data.newPassword);
  });

  const handleOnFinish = () => {
    form
      .validateFields()
      .then(() => {
        try {
          // await form.validateFields();
          loginMutation.mutate(
            {
              email: email,
              tempPassword: tempPassword,
              newPassword: form.getFieldValue("newPassword").toString(),
            },
            {
              onSuccess: (data, variables, context) => {
                Message({
                  type: "success",
                  content: "Password reset successfully",
                });
                localStorage.removeItem("TEMP_USER_EMAIL");
                localStorage.removeItem("TEMP_USER_PW");
                Auth.signOut().then(() => navigate(ROUTES.PUBLIC.LOGIN));
              },
              onError: (error) => generateErrorMessage(error),
            }
          );
        } catch (error) {
          console.error("SetNewPassword:handleOnFinish: ", { error });
          return;
        }
      })
      .catch((error) => {
        console.error("SetNewPassword:inputValidation: ", { error });
        return;
      });
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.backButtonWrapper}>
        <BackButton variant="tail" onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.formWrapper}>
        <Heading classNames={classNames} title="Set new password" />
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              { required: true, message: validation.password.required },
              {
                max: MAX_MIN.password.max,
                message: validation.password.maxLength,
              },
              {
                pattern: new RegExp(REGEX.password),
                message: validation.password.regex,
              },
            ]}
          >
            <Input placeholder="New password" type="password" />
          </Form.Item>
          <Form.Item
            label="Confirm new password"
            name="confirmPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(validation.confirmPassword.match)
                  );
                },
              }),
              { required: true, message: validation.confirmPassword.required },
            ]}
          >
            <Input
              placeholder={validation.confirmPassword.placeholder}
              type="password"
            />
          </Form.Item>
          <CTAButton
            htmlType="button"
            onClick={() => handleOnFinish()}
            type="primary"
            loading={loginMutation.isLoading}
            style={{ marginTop: 20 }}
          >
            Submit
          </CTAButton>
        </Form>
      </div>
    </div>
  );
};

export default SetNewPassword;
