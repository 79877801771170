import classNames from "./Payment.module.scss";
import { Outlet } from "react-router-dom";
import UseIdleTimer from "../../hooks/useIdleTimer";

const Payment = () => {
  return (
    <div className={classNames.wrapper}>
      <UseIdleTimer />
      <div className={classNames.purpleBackground}>
        <Outlet />
      </div>
    </div>
  );
};

export default Payment;
