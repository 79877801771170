import React, { useCallback, useContext } from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useScopeForRole from '../../../hooks/useScopeForRole';
import { AppContext } from '../../../context/AppState';
import { ROUTES } from '../../constants';

export default function Forbidden() {
  const { userData } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const isScopeAvailableForPrevious = useScopeForRole(from);

  const handleOnClickBack = useCallback(() => {
    // if the user has access to the page where the user came to the forbidden page
    // send back to that page
    if (isScopeAvailableForPrevious) {
      navigate(from);
    } else if (userData?.tenantId) { // if user doesn't have access to the previous page send to the app page
      navigate(ROUTES.APP.INDEX);
    } else {
      // if the user doesn't have access to the both above routes send to the login page
      navigate(ROUTES.PUBLIC.LOGIN)
    }
  }, [userData, from, navigate, isScopeAvailableForPrevious]);

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary" onClick={handleOnClickBack}>Go Back</Button>}
    />
  )
}