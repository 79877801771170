import { useEffect, useState } from "react";

export const useIfield = (
  iFieldKey,
  cvvIfieldStyles,
  cvvIframeStyles,
  cardIfieldStyles,
  cardIframeStyles,
  paymentMethod,
  handleSubmit,
  achIfieldStyle,
  achIframeStyle
) => {
  const [cvvSUT, setCVVSut] = useState(null);
  const [cardSUT, setCardSUT] = useState(null);
  const [achSUT, setAchSUT] = useState(null);

  const [cvvoptions, setCvvOptions] = useState({
    placeholder: " CVV",
    autoFormat: true,
    autoFormatSeparator: " ",
    iFieldstyle: cvvIfieldStyles,
    iFrameStyle: cvvIframeStyles,
  });

  const [ccoptions, setCcOptions] = useState({
    placeholder: " Enter card number",
    autoFormat: true,
    autoFormatSeparator: " ",
    iFieldstyle: cardIfieldStyles,
    iFrameStyle: cardIframeStyles,
  });

  const [achOption, setAchOptions] = useState({
    placeholder: "  Account Number",
    autoFormat: true,
    autoFormatSeparator: " ",
    iFieldstyle: achIfieldStyle,
    iFrameStyle: achIframeStyle,
  });

  const account = {
    xKey: iFieldKey,
    xSoftwareName: "Otterz",
    xSoftwareVersion: "1.0.0",
  };

  const threeds = {
    enable3DS: false,
    waitForResponse: false,
    waitForResponseTimeout: undefined,
    amount: 0,
    month: "01",
    year: "2020",
  };

  useEffect(() => {
    if (cvvSUT && cardSUT) {
      handleSubmit(cvvSUT, cardSUT);
      setCVVSut(null);
      setCardSUT(null);
    }
  }, [cvvSUT, cardSUT, handleSubmit]);

  useEffect(() => {
    if (achSUT) {
      handleSubmit(achSUT, null);
      setAchSUT(null);
    }
  }, [achSUT, handleSubmit]);

  const onCcChange = (data) => {
    if (!data.isValid) {
      setCcOptions((ccoptions) =>
        Object.assign(
          {},
          ccoptions,
          {
            iFieldstyle: {
              borderRadius: "6px",
              height: "44px",
              border: "1px solid red",
              width: "94%",
            },
          },
          { error: true }
        )
      );
    } else if (data.isValid) {
      setCcOptions((ccoptions) =>
        Object.assign(
          {},
          ccoptions,
          {
            iFieldstyle: {
              borderRadius: "6px",
              height: "44px",
              border: "1px solid #d1d5dd",
              width: "94%",
            },
          },
          { error: false }
        )
      );
    }
  };

  const onCVVChange = (data) => {
    if (data.isEmpty) {
      setCvvOptions((ccoptions) =>
        Object.assign(
          {},
          ccoptions,
          {
            iFieldstyle: {
              borderRadius: "6px",
              height: "44px",
              border: "1px solid red",
              width: "93%",
            },
          },
          { error: true }
        )
      );
    } else {
      setCvvOptions((cvvoptions) =>
        Object.assign(
          {},
          cvvoptions,
          {
            iFieldstyle: {
              borderRadius: "6px",
              height: "44px",
              border: "1px solid #d1d5dd",
            },
          },
          { error: false }
        )
      );
    }
  };

  const onACHChange = (data) => {
    if (data.isValid) {
      setAchOptions((achOption) =>
        Object.assign({}, achOption, {
          iFieldstyle: {
            borderRadius: "6px",
            height: "44px",
            border: "1px solid #d1d5dd",
            width: "94%",
          },
        })
      );
    }
  };

  const onCcFocus = (data) => {
    setCcOptions((ccoptions) =>
      Object.assign({}, ccoptions, {
        iFieldstyle: {
          borderRadius: "6px",
          height: "44px",
          border: "1px solid #d1d5dd",
          width: "94%",
        },
      })
    );
  };

  const onCVVFocus = (data) => {
    setCvvOptions((cvvoptions) =>
      Object.assign({}, cvvoptions, {
        iFieldstyle: {
          borderRadius: "6px",
          height: "44px",
          border: "1px solid #d1d5dd",
        },
      })
    );
  };

  const onACHFocus = (data) => {
    setAchOptions((achOption) =>
      Object.assign({}, achOption, {
        iFieldstyle: {
          borderRadius: "6px",
          height: "44px",
          border: "1px solid #d1d5dd",
          width: "94%",
        },
      })
    );
  };
  // const onCVVClick = () => {
  //   setCvvOptions((cvvoptions) => Object.assign({}, cvvoptions, {
  //     iFieldstyle: {
  //       border: "1px solid #d1d5dd"
  //     }
  //   }))
  // };

  const onCcUpdate = (data) => {
    switch (data.event) {
      case "input":
      case "click":
      case "blur":
        onCcChange(data);
        break;
      case "focus":
        onCcFocus(data);
        break;
      default:
        break;
    }
  };

  const onCVVUpdate = (data) => {
    switch (data.event) {
      case "change":
        onCVVChange(data);
        break;
      case "focus":
        onCVVFocus(data);
        break;
      default:
        break;
    }
  };

  const onACHUpdate = (data) => {
    switch (data.event) {
      case "change":
        onACHChange(data);
        break;
      case "focus":
        onACHFocus(data);
        break;
      default:
        break;
    }
  };

  const handleError = (data) => {
    const optionAch = {
      iFieldstyle: {
        border: "1px solid #ff4d4f",
        borderRadius: "6px",
        height: "44px",
      },
    };
    const optionCvvAndCard = {
      iFieldstyle: {
        border: "1px solid #ff4d4f",
        borderRadius: "6px",
        height: "44px",
      },
    };
    if (data?.xTokenType === "card") {
      setCcOptions(Object.assign({}, ccoptions, optionCvvAndCard));
    }
    if (data?.xTokenType === "cvv") {
      setCvvOptions(Object.assign({}, cvvoptions, optionCvvAndCard));
    }
    if (data?.xTokenType === "ach") {
      setAchOptions(Object.assign({}, achOption, optionAch));
    }
  };

  const onToken = (data) => {
    if (!data?.xToken) {
      handleError(data);
    }
    if (data?.xToken && data?.xTokenType === "card") {
      setCardSUT(() => data?.xToken);
    }
    if (data?.xToken && data?.xTokenType === "cvv") {
      setCVVSut(() => data?.xToken);
    }
    if (data?.xToken && data?.xTokenType === "ach") {
      setAchSUT(() => data?.xToken);
    }
  };

  const onError = (data) => {
    handleError(data);
  };

  return {
    cvvoptions,
    setCvvOptions,
    onCcUpdate,
    onCVVUpdate,
    ccoptions,
    setCcOptions,
    onError,
    onToken,
    account,
    threeds,
    achOption,
    onACHUpdate,
  };
};
