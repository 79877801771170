import {
  addFunds,
  cardFreeze,
  cardReplace,
  cardReportStolen,
  cardUnfreeze,
  closeCard,
  createACHWithLinkedCP,
  createBillPayment,
  createCounterPartyWithPlaid,
  createSingleUseToken,
  createCustomerToken,
  createDepositCheck,
  createNewCard,
  createPayWithCardPayment,
  createPlaidProcessorToken,
  createPlaidToken,
  createClientToken,
  createWirePayment,
  getAccount,
  getBankAccountDetails,
  getCustomerProfile,
  getTempCustomPayments,
  getTransactions,
  getTransferTypes,
  listBillers,
  listCards,
  listScheduledTransactions,
  listPayments,
  pendingTransaction,
  getPerson,
  getBusiness,
  getScheduledPayment,
  getCard,
  getWidgetUrl,
  generateBarcode,
  updateCard,
  createWithdrawPayment,
  getTransactionPdf,
  getCategorywiseCardSpend,
  getCardTransaction,
  delinkExternalAccount,
  createWiresPayment,
} from "./BusinessBanking";
import * as payees from "./BusinessBanking/Payees";
import {
  createACHPayment,
  createRecurringPayment,
  createScheduledPayment,
  deleteScheduledPayment,
  deleteSinglePayment,
  freezePayment,
  listAllSchedulePayments,
  listRecurringPayments,
  unFreezePayment,
  updatePayment,
  updateRecurringPayments,
  updateScheduledPayment,
} from "./BusinessBanking/ScheduledPayments";
import {
  getBankLetter,
  getBankLetterDetail,
  getStatementPDF,
  getStatements,
  getBankVerificationLetter,
} from "./BusinessBanking/Statements";
import {
  createApplication,
  createAccountRelationship,
  forgotPassword,
  forgotPasswordOTP,
  getApplicationById,
  getApplicationByTenantId,
  inviteUser,
  resendSignup,
  uploadDocumentsBack,
  uploadDocumentsFront,
  uploadRegDocsOnboarding,
  getBusinessRelations,
  verifyEmail,
  createOfficerBusinessRelationship,
  createBeneficiaryBusinessRelation,
  getAccountTemplateId,
  createAccount,
  createDisclosure,
  getDisclosure,
  createKYCKYB,
  updateUserStatus,
  updateApplication,
  updateOfficerBusinessRelationship,
  callWebhook,
  getBusinessPersonDetail,
  updateApplicationByTenantId,
  addDigiStamp,
  updateBusinessOperationDetails,
  sendSocureMail,
  getListDocuments,
  getDocumentFile
} from "./Onboarding";
import {
  changeSubscription,
  closeAccount,
  deleteCloseAccountRequest,
  getAccountDetails,
  getAlertsandNotifications,
  getChildUsersByTenantId,
  getCurrentSubscriptionPlan,
  getSubscriptions,
  getUserById,
  updateAlertsandNotifications,
  updateUserProfile,
  getUnitApplication,
  deleteChildUser,
  selectSubscriptionPlan
} from "./Settings";
import {
  createAPInvoice,
  updateAPInvoice,
  updateAPInvoicePaymentStatus,
  getAPInvoice,
  getAPInvoiceListForPayee,
  rejectAPInvoice,
  rejectAPInvoicesBulk,
  approveAPInvoice,
  approveAPInvoicesBulk,
  uploadAPInvoiceFile,
  getAPInvoiceFile,
  getAPInvoiceByStatus,
  getAllInvoicesQBs,
} from "./Payables"
import { getATMLocations } from "./Support";
// import { listChecksDeposited } from "./ManageChecks";
import { fetchCurrentUser, getTenantById, requestOTP, verifyOTP } from "./Auth";
import {
  getAccountBalance,
  getRecentTransactions,
  getTotalDeposits,
  getStatementDetail,
  getAccountBalanceHistory,
} from "./BusinessBanking/Overview";
import {
  createCheckDeposit,
  getCheckDeposit,
  listChecksDeposited,
  uploadImage,
} from "./ManageChecks";
import {
  createCustomer as createCardknoxCustomer,
  createPaymentMethod as createCardknoxPaymentMethod,
  createSchedule,
  deleteSchedule,
  updateSchedule,
  createTransaction as createPATransaction,
  deleteCustomer as deleteCardknoxCustomer,
  getCustomers as getCardknoxCustomers,
  getCustomer as getCardknoxCustomer,
  getDashboardDeatils,
  getPaymentMethod as getCardknoxPaymentMethod,
  getTransaction as getPATransaction,
  listSchedule,
  listTransactions,
  sendEmail,
  updateCustomer as updateCardknoxCustomer,
  updatePaymentMethod as updateCardknoxPaymentMethod,
  checkStatus,
  updateStatus,
  getMerchantDetail,
} from "./PaymentAcceptance";
import { getScheduledPayments } from "./PaymentAcceptance/ScheduledPayment";
import {
  getCompanyById,
  updateCompany,
  createCompany,
} from "./Receivables/Company";
import {
  createCustomer,
  deleteCustomer,
  editCustomer,
  getCustomers,
} from "./Receivables/Customers";
import {
  createEstimate,
  deleteEstimate,
  editEstimate,
  getEstimateById,
  getEstimatePDF,
  listEstimates,
} from "./Receivables/Estimates";
import {
  createInvoice,
  deleteInvoice,
  editInvoice,
  getInvoiceById,
  getInvoicePDF,
  getInvoices,
  getOverallInvoiceDetails,
  setInvoiceStatus,
} from "./Receivables/Invoices";
import {
  callbackRequest,
  getOnboardRequest,
  onboardUser,
  sendInternalMail,
} from "./Receivables/PaymentAcceptance";
import {
  createProduct,
  deleteProduct,
  editProduct,
  getProducts,
} from "./Receivables/Products";
import {
  createRecurringInvoice,
  deleteRecurringInvoice,
  editRecurringInvoice,
  getRecurringInvoiceById,
  getRecurringInvoices,
} from "./Receivables/RecurringInvoices";
import {
  editEstimateTemplateSettings,
  editInvoiceTemplateSettings,
  getCompanyEstimateTemplatePDF,
  getCompanyInvoiceTemplatePDF,
  getEstimateTemplateSettings,
  getInvoiceTemplateSettings,
  getProfileSettings,
  updateCompanySettings,
} from "./Receivables/Settings";
import { shareEmail } from "./Receivables/Share";
import {
  createTaxRate,
  deleteTaxRate,
  editTaxRate,
  getAllTaxRates,
} from "./Receivables/Taxes";
import { cashbackHistory, getCashbackAmount } from "./Rewards";
import getCurrentTempNotifSettings from "./tempNotificationSupport/getCurrentSettings";
import toggleTempNotifications from "./tempNotificationSupport/toggleTempNotifications";
import {
  getAlertNotification,
  setAlertNotification,
} from "./tempNotificationSupport";

import {
  createHPPTransaction,
  getIFieldsKey,
  getPASettings,
  savePASettings,
  updatePASettings,
  createHPPSaleTransaction,
} from "./PaymentAcceptance";
import { getPAReports } from "./PaymentAcceptance/Reports";

const api = {
  Auth: {
    fetchCurrentUser,
    getTenantById,
    requestOTP,
    verifyOTP,
  },
  onBoarding: {
    getApplicationByTenantId: getApplicationByTenantId,
    getApplicationById: getApplicationById,
    createApplication: createApplication,
    createAccountRelationship,
    createBeneficiaryBusinessRelation: createBeneficiaryBusinessRelation,
    createOfficerBusinessRelationship: createOfficerBusinessRelationship,
    uploadDocumentsFront: uploadDocumentsFront,
    uploadDocumentsBack: uploadDocumentsBack,
    uploadRegDocsOnboarding: uploadRegDocsOnboarding,
    forgotPassword: forgotPassword,
    forgotPasswordOTP: forgotPasswordOTP,
    verifyEmail: verifyEmail,
    resendSignup: resendSignup,
    inviteUser: inviteUser,
    getBusinessRelations,
    addDigiStamp,
    getAccountTemplateId,
    createAccount,
    createKYCKYB,
    createDisclosure,
    getDisclosure,
    updateUserStatus,
    updateApplication,
    updateOfficerBusinessRelationship,
    callWebhook,
    getBusinessPersonDetail,
    updateApplicationByTenantId,
    updateBusinessOperationDetails,
    sendSocureMail,
    getListDocuments,
    getDocumentFile,
  },
  BusinessBanking: {
    getAccount,
    getAccountBalanceHistory,
    getCustomerProfile: getCustomerProfile,
    addFund: addFunds,
    createWirePayment: createWirePayment,
    createBillPayment: createBillPayment,
    createPlaidToken: createPlaidToken,
    createPlaidProcessorToken: createPlaidProcessorToken,
    createCounterPartyWithPlaid: createCounterPartyWithPlaid,
    createACHWithLinkedCP: createACHWithLinkedCP,
    listBillers: listBillers,
    listCards: listCards,
    createPayWithCardPayment: createPayWithCardPayment,
    listScheduledTransactions: listScheduledTransactions,
    getTransactions: getTransactions,
    getTransferTypes,
    getBankAccountDetails,
    cardReportStolen: cardReportStolen,
    cardFreeze: cardFreeze,
    cardUnfreeze: cardUnfreeze,
    createDepositCheck: createDepositCheck,
    createCustomerToken,
    cardReplace,
    closeCard,
    recentTransactions: getRecentTransactions,
    totalDeposits: getTotalDeposits,
    statementDetail: getStatementDetail,
    getAccountBalance,
    createNewCard,
    createClientToken,
    createSingleUseToken,
    delinkExternalAccount,
    // <---- Payments APIs ---->
    listRecurringPayments,
    createRecurringPayment,
    freezePayment,
    updatePayment,
    createACHPayment,
    createScheduledPayment,
    deleteScheduledPayment,
    listAllSchedulePayments,
    unFreezePayment,
    updateRecurringPayments,
    updateScheduledPayment,
    deleteSinglePayment,
    listPayments,
    pendingTransaction,
    getPerson,
    getBusiness,
    getScheduledPayment,
    createWithdrawPayment,
    createWiresPayment,
    // <---- Payee APIs ---->
    listPayees: payees.listPayees,
    createPayee: payees.createPayee,
    deletePayee: payees.deletePayee,
    updatePayee: payees.updatePayee,
    getPayeeById: payees.getPayeeById,
    listWiresPayees: payees.listWiresPayees,
    // <---- Statement APIs ---->
    getStatementPDF,
    getStatements,
    getBankLetter,
    getBankLetterDetail,
    getTransactionPdf,
    getBankVerificationLetter,
    // <---- Temp get custom payment data APIs ---->
    getTempCustomPayments,
    // <---- Cards ---->
    getCard,
    getWidgetUrl,
    generateBarcode,
    updateCard,
    getCategorywiseCardSpend,
    getCardTransaction,
  },
  Support: {
    getATMLocations,
  },
  ManageChecks: {
    listChecksDeposited: listChecksDeposited,
    createCheckDeposit: createCheckDeposit,
    getCheckDeposit: getCheckDeposit,
    uploadImage: uploadImage,
  },
  Settings: {
    updateAlertsandNotifications,
    getAlertsandNotifications,
    updateUserProfile,
    getUserById,
    getAccountDetails,
    closeAccount,
    deleteCloseAccountRequest,
    getSubscriptions,
    changeSubscription,
    getCurrentSubscriptionPlan,
    getChildUsersByTenantId,
    getUnitApplication,
    deleteChildUser,
    selectSubscriptionPlan,
  },
  Receivables: {
    Invoices: {
      getInvoices,
      editInvoice,
      createInvoice,
      deleteInvoice,
      getOverallInvoiceDetails,
      getInvoiceById,
      getInvoicePDF,
      setInvoiceStatus,
    },
    Estimates: {
      editEstimate,
      listEstimates,
      createEstimate,
      deleteEstimate,
      getEstimateById,
      getEstimatePDF,
    },
    Recurring: {
      getRecurringInvoices,
      editRecurringInvoice,
      createRecurringInvoice,
      getRecurringInvoiceById,
      deleteRecurringInvoice,
    },
    Taxes: {
      getAllTaxRates,
      createTaxRate,
      editTaxRate,
      deleteTaxRate,
    },
    Customers: {
      getCustomers,
      createCustomer,
      editCustomer,
      deleteCustomer,
    },
    Product: {
      create: createProduct,
      list: getProducts,
      edit: editProduct,
      deleteProduct,
    },
    Settings: {
      invoice: editInvoiceTemplateSettings,
      estimate: editEstimateTemplateSettings,
      company: updateCompanySettings,
      getInvoiceSettings: getInvoiceTemplateSettings,
      getEstimateTemplateSettings: getEstimateTemplateSettings,
      getProfileSettings: getProfileSettings,
      invoiceTemplate: getCompanyInvoiceTemplatePDF,
      estimateTemplate: getCompanyEstimateTemplatePDF,
    },
    Share: {
      email: shareEmail,
    },
    PaymentAcceptOnboard: {
      onboardUser: onboardUser,
      callbackRequest: callbackRequest,
      getOnboardRequest: getOnboardRequest,
      sendInternalMail,
    },
    Company: {
      getCompany: getCompanyById,
      updateCompany: updateCompany,
      createCompany,
    },
    PaymentAcceptance: {
      getCardknoxCustomers,
      getCardknoxCustomer,
      deleteCardknoxCustomer,
      createCardknoxCustomer,
      updateCardknoxCustomer,
      createCardknoxPaymentMethod,
      updateCardknoxPaymentMethod,
      getCardknoxPaymentMethod,
      createPATransaction,
      getPATransaction,
      listTransactions,
      getDashboardDeatils,
      sendEmail,
      listSchedule,
      createSchedule,
      deleteSchedule,
      updateSchedule,
      getPASettings,
      savePASettings,
      updatePASettings,
      getIFieldsKey,
      createHPPTransaction,
      createHPPSaleTransaction,
      checkStatus,
      updateStatus,
      getMerchantDetail,
    },
    PaymentAcceptanceTransactions: {
      getScheduledPayments,
      sendEmail: sendEmail,
      reports: getPAReports,
    },
  },
  AccountsPayable: {
    createAPInvoice,
    updateAPInvoice,
    updateAPInvoicePaymentStatus,
    getAPInvoice,
    getAPInvoiceListForPayee,
    rejectAPInvoice,
    rejectAPInvoicesBulk,
    approveAPInvoice,
    approveAPInvoicesBulk,
    uploadAPInvoiceFile,
    getAPInvoiceFile,
    getAPInvoiceByStatus,
    getAllInvoicesQBs,
  },
  Rewards: {
    cashbackHistory,
    getCashbackAmount,
  },
  toggleTempNotifications,
  getCurrentTempNotifSettings,
  Notification: {
    setAlertNotification,
    getAlertNotification,
  },
};

export default api;
