import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../App/API";
import { STATUS } from "../App/constants";
// import { useLocalStorageData } from "./useLocalStorage";
import { AppContext } from "../context/AppState";

const usePAActiveStatus = () => {
  const [status, setStatus] = useState(false);
  // const userData = useLocalStorageData("OTTERZ.userData");
  const { userData } = useContext(AppContext);
  const tenantId = userData?.tenantId;

  const { data, isSuccess, isLoading } = useQuery(
    "getOnboardRequest",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: getPASettingsData,
    isLoading: getPASettingsDataLoading = false,
  } = useQuery(
    "getPASettings",
    () =>
      api.Receivables.PaymentAcceptance.getPASettings({
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!tenantId,
    }
  );
  const isPASettingsAvailable = !!getPASettingsData?.data?.companyName;

  useEffect(() => {
    setStatus(isSuccess && data?.data?.onboardStatus === STATUS.APPROVED);
  }, [isSuccess, data]);

  return {
    status,
    paSettingsStatus: isPASettingsAvailable,
    isPASettingsLoading: getPASettingsDataLoading,
    isSuccess,
    data,
    isLoading,
  };
};

export default usePAActiveStatus;
