import classNames from "./Customers.module.scss";
import { Modal, CTAButton, Title } from "../../../common";

export default function DeleteCustomer({ visible, onCancel, onConfirm, isDeleting }) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete Customer
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this customer?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={onConfirm}
          disabled={isDeleting}
        >
          Yes, delete
        </CTAButton>
      </div>
    </Modal>
  );
}
