import { Navigate } from "react-router-dom";

import useUserState from "../hooks/useUserState";
import useRouterSwitch from "../hooks/useRouterSwitch";
import { ONBOARDING_USER_STATES } from "../App/constants";

const OnboardingRoute = ({ children }) => {
  const userState = useUserState(); // get the current state of the user
  const path = useRouterSwitch(); // give the next route to navigate if user is not in onboarding flow

  if (ONBOARDING_USER_STATES.includes(userState) || userState === "authorized_user") {
    return children;
  } else {
    return <Navigate to={path} replace />
  }
};

export default OnboardingRoute;
