import classNames from "../Settings.module.scss";
import { Form, Button } from "antd";
import { Input, CTAButton, Message } from "../../common";
import { useEffect, useState } from "react";

function ChangePassword() {
  const [step, setStep] = useState(1);

  const temp = true;

  function onKeyUp(first, last, event) {
    if (event && event.nativeEvent.inputType === "deleteContentBackward") {
      document.getElementById(`otpBox${first}`).focus();
    } else {
      document.getElementById(`otpBox${last}`).focus();
    }
  }

  const handleSubmit = () => {
    Message({ type: "success", content: "Password changed successfully" });
  };
  /** ========== THis is a temporary solution which will be removed in post MVP */
  useEffect(function () {
    window.localStorage.clear();
    window.location.href = "/forgot-password";
  }, []);

  if (temp) {
    return <div>You will be signed out</div>;
  }

  /**========== */

  if (step === 2) {
    return (
      <div className={classNames.formWrapper}>
        <Form layout="vertical" hideRequiredMark autoComplete="off">
          <Form.Item label="Old password" name="oldPassword">
            <Input placeholder="Old password" type="password" />
          </Form.Item>
          <Form.Item label="New password" name="newPassword">
            <Input placeholder="New password" type="password" />
          </Form.Item>
          <Form.Item label="Confirm new password" name="confirmPassword">
            <Input placeholder="Confirm new password" type="password" />
          </Form.Item>
          <CTAButton
            htmlType="submit"
            type="primary"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            Submit
          </CTAButton>
        </Form>
      </div>
    );
  }

  return (
    <div className={classNames.formWrapper}>
      <div className={classNames.heading}>
        An OTP has been sent to your phone number +1 800 ***** 898
      </div>
      <Form layout="vertical" hideRequiredMark autoComplete="off">
        <Form.Item
          label="Enter OTP"
          rules={[{ required: true, message: "required" }]}
        >
          <div className={classNames.inputsWrapper}>
            <Form.Item name="1" className={classNames.otp}>
              <Input
                id="otpBox1"
                otp
                maxLength={1}
                onChange={(event) => onKeyUp(1, 2, event)}
              />
            </Form.Item>
            <Form.Item name="2" className={classNames.otp}>
              <Input
                id="otpBox2"
                otp
                maxLength={1}
                onChange={(event) => onKeyUp(2, 3, event)}
              />
            </Form.Item>
            <Form.Item name="3" className={classNames.otp}>
              <Input
                id="otpBox3"
                otp
                maxLength={1}
                onChange={(event) => onKeyUp(3, 4, event)}
              />
            </Form.Item>
            <Form.Item name="4" className={classNames.otp}>
              <Input
                id="otpBox4"
                otp
                maxLength={1}
                onChange={(event) => onKeyUp(4, 5, event)}
              />
            </Form.Item>
            <Form.Item name="5" className={classNames.otp}>
              <Input
                id="otpBox5"
                otp
                maxLength={1}
                onChange={(event) => onKeyUp(5, 6, event)}
              />
            </Form.Item>
            <Form.Item name="6" className={classNames.otp}>
              <Input id="otpBox6" otp maxLength={1} />
            </Form.Item>
          </div>
        </Form.Item>
        <div className={classNames.links} style={{ textAlign: "left" }}>
          <span>Didn’t receive email? </span>
          <Button type="link" size="small">
            Resend
          </Button>
        </div>
        <CTAButton htmlType="button" type="primary" onClick={() => setStep(2)}>
          Next
        </CTAButton>
        <div className={classNames.links}>
          <Button type="link" size="small">
            Send OTP to phone instead?
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ChangePassword;
