import { useState } from "react";
import copy from "copy-to-clipboard";
import { message } from "antd";
import FileList2LineIcon from "remixicon-react/FileList2LineIcon";
import FileCopy2LineIcon from "remixicon-react/FileCopy2LineIcon";
import { Modal, CTAButton, Title } from "../../../../common";
import ShareModal from "../ShareModal/ShareModal";
import convertToTitleCase from "../../../../util/functions/convertToTitleCase";
import whatsappIcon from "../../../../../assets/common/modal/whatsapp-ico.svg";
import classNames from "../../Overview.module.scss";

const ModalForm = ({ accountData, isDownloading, setDownloadBankLetter, displayBankLetter }) => {
  //Other variables
  const data = {
    BeneficiaryName: accountData?.customer_name,
    AccountNumber: accountData?.account_number,
    TypeOfAccount: accountData?.account_type,
    // benefeciaryAddress: "5 Union Square West, #1083 New York, NY 10003",
    ABARoutingNumber: accountData?.routing_number,
    BankName: accountData?.bank_name,
    BankAddress: accountData?.bank_address,
    // BankAddress: [
    //   accountData?.address?.address_line_1,
    //   accountData?.address?.address_line_2,
    //   accountData?.address?.city,
    //   accountData?.address?.state,
    //   accountData?.address?.country_code === "US"
    //     ? "United States"
    //     : accountData?.address?.country_code,
    //   accountData?.address?.postal_code,
    // ]
    //   .filter(Boolean)
    //   ?.join(", "),
  };

  const formatData = `Beneficiary Details\n\nBeneficiary Name:${
    data?.BeneficiaryName
  }\nAccount Number:${
    data?.AccountNumber
  }\nType of Account:${convertToTitleCase(
    data?.TypeOfAccount
  )}\n\nReceiving Bank Details\n\nABA Routing Number:${
    data?.ABARoutingNumber
  }\nBank Name:${data?.BankName}\nBank Address:${data?.BankAddress}`;

  //Event handlers
  function handleClick(type) {
    switch (type) {
      case "whatsapp":
        window.open(
          `https://wa.me/?text=${encodeURI(formatData.replace("#", " "))}`
        );
        break;
      case "email":
        copy(formatData);
        message.success("Accouunt details copied to clipboard!");
        window.open("mailto:");
        break;
      default:
        break;
    }
  }

  return (
    <>
      <span className={classNames.modalInfoText}>
        {`Use these details to receive both domestic wires and ACH transfers to your Otterz account`}
      </span>
      <div className={classNames.modalDetailsContainer}>
        <Title as="h4">Beneficiary Details</Title>
        <div>
          <span>Beneficiary Name</span>
          <span>{data?.BeneficiaryName}</span>
        </div>
        <div>
          <span>Account Number</span>
          <span>{data?.AccountNumber}</span>
        </div>
        <div>
          <span>Type of Account</span>
          <span>{convertToTitleCase(data?.TypeOfAccount)}</span>
        </div>
      </div>
      <div className={classNames.modalDetailsContainer}>
        <Title as="h4">Receiving Bank Details</Title>
        <div>
          <span>ABA Routing Number</span>
          <span>{data?.ABARoutingNumber}</span>
        </div>
        <div>
          <span>Bank Name</span>
          <span>{data?.BankName}</span>
        </div>
        <div>
          <span>Bank Address</span>
          <span>{data?.BankAddress}</span>
        </div>
      </div>
      {displayBankLetter !== "hide" && (
        <CTAButton
          loading={isDownloading}
          onClick={() => {
            setDownloadBankLetter(true);
          }}
          style={{
            height: 44,
            width: 384,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 16,
          }}
          type="outline"
        >
          <FileList2LineIcon size={16} style={{ marginRight: 10 }} />
          Download Bank Letter
        </CTAButton>
      )}
      <CTAButton
        style={{
          height: 44,
          width: 384,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#F3F3FF",
          border: "none",
          color: "#7372FF",
        }}
        type="primary"
        onClick={() => {
          copy(formatData);
          message.success("Account details copied to clipboard!");
        }}
      >
        <FileCopy2LineIcon size={16} style={{ marginRight: 10 }} />
        Copy
      </CTAButton>
      <div className={classNames.modalFooter}>
        <span>Or, share via</span>
        <div>
          <div onClick={() => handleClick("whatsapp")}>
            <img src={whatsappIcon} alt="" />
            <span>WhatsApp</span>
          </div>
          {/* TODO: enable this post MVP */}
          {/* <div onClick={() => setShareModalVisibility(true)}>
            <img src={emailIcon} alt="" />
            <span>Email</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default function DownloadModal({
  visible,
  onCancel,
  accountData,
  setDownloadBankLetter,
  isDownloading,
  displayBankLetter
}) {
  //Hooks
  const [shareModalVisibility, setShareModalVisibility] = useState(false);

  //Event handlers
  const handleShareModalCancelClick = () => {
    setShareModalVisibility(false);
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Bank Account Details"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <ModalForm
        accountData={accountData}
        setDownloadBankLetter={setDownloadBankLetter}
        isDownloading={isDownloading}
        displayBankLetter={displayBankLetter}
      />
      <ShareModal
        visible={shareModalVisibility}
        onCancel={handleShareModalCancelClick}
      />
    </Modal>
  );
}
