const URL = "https://62013650fdf50900172498f0.mockapi.io";
// const URL_CUSTOMER_DEV = "https://3muqu6u3y8.execute-api.us-west-2.amazonaws.com/prod/";
const URL_INVOICES_DEV =
  "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/";
const BASE_URL_DEV = "http://dev.sync.app.otterz.co/"
const BASE_URL_TTEN = "https://tten.app.otterz.co/"

const endpoints = {
  BUSINESS_BANKING: {
    SUBSCRIPTION_PLANS: {
      DEV: `${BASE_URL_DEV}api/v1/subscription/plan/list`
    },
    GET_CHECK_DEPOSIT_DETAILS: {
      MOCK: "/api/check-deposits/details/",
      DEV: "",
    },
    CREATE_DEPOSIT_CHECK: {
      MOCK: "/api/check-deposits",
      DEV: "",
    },
    UPDATE_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/counterparties",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    GET_CARDS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card",
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card",
    },
    SET_CARD_SPENT_LIMIT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card/1",
    },
    CARD_REPORT_STOLEN: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
    },
    CARD_FREEZE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
    },
    CARD_UNFREEZE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
    },
    GET_CARD_CATEGORY_WISE_SPEND: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      MOCK_FS: "/api/cards/spend",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
    },
    GET_PAYEES: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payees/",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    ADD_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payee",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    DELETE_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payee",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    CREATE_ACH_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      MOCK_FS: "/api/payments/ach",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
    },
    CREATE_WIRE_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      MOCK_FS: "/api/payments/wire",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
    },
    CREATE_BILL_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      MOCK_FS: "/api/payments/bill",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
    },
    CREATE_PAY_WITH_CARD: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      MOCK_FS: "/api/payments/card",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
    },
    LIST_BILLERS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/biller",
      MOCK_FS: "api/payments/billpay/billers",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/biller",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/biller",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/biller",
    },
    GET_TRANSACTIONS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/transaction",
      MOCK_FS: "/api/transactions",
      DEV: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod",
      TTEN: "https://emzwwhpTTENk.execute-api.us-west-2.amazonaws.com/prod",
      DEMO: "https://pyw9k9rvzf.execute-api.us-west-2.amazonaws.com/prod",
    },
    GET_RECENT_TRANSACTIONS: {
      MOCK: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      DEV: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      TTEN: "https://emzwwhpTTENk.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      DEMO: "https://pyw9k9rvzf.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
    },
    LIST_SCHEDULED_TRANSACTIONS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      MOCK_FS: "/api/scheduled-transactions",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
    },
    GET_BANK_ACCOUNT_DETAILS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
    },
    GET_BALANCE: {
      MOCK: "",
      DEV: "NO-LINK",
    },
    ADD_FUNDS: {
      MOCK: "",
      DEV: "NO-LINK",
    },
    GET_TRANSFER_TYPES: {
      // this endpoint not used anywhere. not found data in server.js file hence no mock api added
      MOCK: "/api/transfers",
      DEV: "NO-LINK",
    },
    UPDATE_SETTINGS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/setting",
      MOCK_FS: "/api/settings",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/setting",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/setting",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/setting",
    },
    SCHEDULED_PAYMENTS: {
      GET_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      },
      EDIT_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
      },
      DELETE_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
      },
    },
    GET_STATEMENTS: {
      MOCK: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      MOCK_FS: "/api/statements",
      DEV: "https://itmga33umb.execute-api.us-west-2.amazonaws.com/prod/statements",
      TTEN: "https://6yh04neu7f.execute-api.us-west-2.amazonaws.com/prod/statements",
      DEMO: "https://5vifbctre4.execute-api.us-west-2.amazonaws.com/prod/statements",
    },
    GET_STATEMENT_PDF: {
      MOCK: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      MOCK_FS: "/api/statements",
      DEV: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      TTEN: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      DEMO: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
    },
    POST_FILTER_PARAMS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/statement",
      MOCK_FS: "/api/statements",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/statement",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/statement",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/statement",
    },
  },
  MANAGE_CHECKS: {
    LIST_CHECKS_DEPOSITED: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      MOCK_FS: "/api/check-deposits",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
    },
  },
  ONBOARDING: {
    REQUEST_OTP: {
      MOCK: "cdk-extend/phone/otp",
      DEV: `${BASE_URL_DEV}cdk-extend/phone/otp`,
      ISOLATED: `${BASE_URL_DEV}cdk-extend/phone/otp`,
      TTEN: `${BASE_URL_TTEN}cdk-extend/phone/otp`,
      PROD: `${BASE_URL_TTEN}cdk-extend/phone/otp`,
    },
    VERIFY_OTP: {
      MOCK: "cdk-extend/phone/verify",
      DEV: `${BASE_URL_DEV}cdk-extend/phone/verify`,
      ISOLATED: `${BASE_URL_DEV}cdk-extend/phone/verify`,
      TTEN: `${BASE_URL_TTEN}cdk-extend/phone/verify`,
      PROD: `${BASE_URL_TTEN}cdk-extend/phone/verify`,
    },
    CREATE_APPLICATION: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/application",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_APPLICATION_BY_ID: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      DEV: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    // GET_APPLICATION_BY_TENANT_ID: {
    //   MOCK: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/get-by-tenant-id",
    //   DEV: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
    // },
    UPLOAD_DOCUMENTS_FRONT: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-front",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/document-front",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application/document-front",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    UPLOAD_DOCUMENTS_BACK: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-back",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/document-back",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application/document-back",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    UPLOAD_REG_DOCUMENTS: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-back",
      DEV: `${BASE_URL_DEV}api/v1/docstorage/add`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage/add`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage/add`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    ADD_DIGI_STAMP: {
      MOCK: "/api/v1/person/digi-stamp/",
      DEV: `${BASE_URL_DEV}api/v1/person/digi-stamp/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/person/digi-stamp/`,
      TTEN: `${BASE_URL_TTEN}api/v1/person/digi-stamp/`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_BUSINESS_RELATIONS: {
      MOCK: "/api/v1/business-relation",
      DEV: `${BASE_URL_DEV}api/v1/business-relation`,
      ISOLATED: `${BASE_URL_DEV}api/v1/business-relation`,
      TTEN: `${BASE_URL_TTEN}api/v1/business-relation`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    SEND_SOCURE_MAIL: {
      MOCK: "api/v1/kyckyb/docV/send-mail",
      DEV: `${BASE_URL_DEV}api/v1/kyckyb/docV/send-mail`,
      ISOLATED: `${BASE_URL_DEV}api/v1/kyckyb/docV/send-mail`,
      TTEN: `${BASE_URL_TTEN}api/v1/kyckyb/docV/send-mail`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    CREATE_ACCOUNT_RELATIONSHIP: {
      MOCK: "api/v1/account-relationship/",
      DEV: `${BASE_URL_DEV}api/v1/account-relationship/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/account-relationship/`,
      TTEN: `${BASE_URL_TTEN}api/v1/account-relationship/`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_LIST_DOCUMENTS: {
      MOCK: "api/v1/docstorage",
      DEV: `${BASE_URL_DEV}api/v1/docstorage`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage`,
      PROD: `${BASE_URL_TTEN}api/v1/docstorage`,
    },
    GET_DOCUMENT_FILE: {
      MOCK: "api/v1/docstorage/retrieve/",
      DEV: `${BASE_URL_DEV}api/v1/docstorage/retrieve/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage/retrieve/`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage/retrieve/`,
      PROD: `${BASE_URL_TTEN}api/v1/docstorage/retrieve/`,
    },
    SUPPORT: {
      GET_ATM_LOCATIONS_UNIT: {
        MOCK: "https://api.s.unit.sh/atm-locations",
        DEV: "https://api.s.unit.sh/atm-locations",
        TTEN: "https://api.s.unit.sh/atm-locations",
        DEMO: "https://api.s.unit.sh/atm-locations",
      },
    },
  },
  RECEIVABLES: {
    ENABLE_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
    },
    CLIENTS: {
      GET_CLIENTS: {
        MOCK: `${URL}/clients/`, //`${URL}/invoice/:companyId
        DEV: `${URL}/clients/`, //`${URL}/invoice/:companyId
        TTEN: `${URL}/clients/`, //`${URL}/invoice/:companyId
        DEMO: `${URL}/clients/`, //`${URL}/invoice/:companyId
      },
      GET_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
      CREATE_CLIENT: {
        MOCK: `${URL}/clients/:companyId`,
        DEV: `${URL}/clients/:companyId`,
        TTEN: `${URL}/clients/:companyId`,
        DEMO: `${URL}/clients/:companyId`,
      },
      DELETE_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
      UPDATE_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
    },
    COMPANIES: {
      GET_COMPANIES: {
        MOCK: `${URL}/companies/`,
        DEV: `${URL}/companies/`,
        TTEN: `${URL}/companies/`,
        DEMO: `${URL}/companies/`,
      },
      GET_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
      CREATE_COMPANY: {
        MOCK: `${URL}/companies/`,
        DEV: `${URL}/companies/`,
        TTEN: `${URL}/companies/`,
        DEMO: `${URL}/companies/`,
      },
      DELETE_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
      UPDATE_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
    },
    ESTIMATES: {
      LIST_ESTIMATES: {
        MOCK: `${URL}/estimates/`,
        DEV: `${URL_INVOICES_DEV}/estimates/`,
        TTEN: `${URL}/estimates/`,
        DEMO: `${URL}/estimates/`,
      },
      GET_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      CREATE_ESTIMATE: {
        MOCK: `${URL}/estimate/`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      DELETE_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      UPDATE_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
    },
    INVOICES: {
      GET_INVOICES: {
        MOCK: URL_INVOICES_DEV,
        // MOCK: `${URL}/invoices`, //`${URL}/invoice/:companyId
        // MOCK_FS: "/api/invoices",
        DEV: URL_INVOICES_DEV,
        TTEN: URL_INVOICES_DEV,
        DEMO: URL_INVOICES_DEV,
      },
      GET_OVERALL_INVOICES_DETAILS: {
        MOCK: "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/",
        // MOCK: `${URL}/invoices`, //`${URL}/invoice/:companyId
        // MOCK_FS: "/api/invoices",
        DEV: "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/",
      },
      CREATE_INVOICE: {
        MOCK: `${URL}/invoices`,

        DEV: `${URL}/invoices`,
        TTEN: `${URL}/invoices`,
        DEMO: `${URL}/invoices`,
      },
      DELETE_INVOICE: {
        MOCK: `${URL}/invoices/:id`,
        DEV: `${URL_INVOICES_DEV}/invoices/:id`,
        TTEN: `${URL}/invoices/:id`,
        DEMO: `${URL}/invoices/:id`,
      },
      UPDATE_INVOICE: {
        MOCK: `${URL}/invoices`,
        DEV: URL_INVOICES_DEV,
      },
      GET_INVOICE_PDF: {
        MOCK: `${URL}/pdf/:id`,
        DEV: `${URL}/pdf/:id`,
        TTEN: `${URL}/pdf/:id`,
        DEMO: `${URL}/pdf/:id`,
      },
    },
    RECURRING_INVOICES: {
      GET_RECCURING_INVOICES: {
        MOCK: `${URL}/recurring`, //`${URL}/recurring/:companyId
        DEV: `${URL}/recurring`, //`${URL}/recurring/:companyId
        TTEN: `${URL}/recurring`, //`${URL}/recurring/:companyId
        DEMO: `${URL}/recurring`, //`${URL}/recurring/:companyId
      },
      CREATE_RECURRING_INVOICE: {
        MOCK: `${URL}/recurring`,
        DEV: `${URL}/recurring`,
        TTEN: `${URL}/recurring`,
        DEMO: `${URL}/recurring`,
      },
      EDIT_RECURRING_INVOICE: {
        MOCK: `${URL}/recurring/:id`,
        DEV: `${URL}/recurring/:id`,
        TTEN: `${URL}/recurring/:id`,
        DEMO: `${URL}/recurring/:id`,
      },
    },
    TAX_RATES: {
      GET_ALL_TAX_RATES: {
        MOCK: `${URL}/tax`,
        DEV: `${URL}/tax`,
        TTEN: `${URL}/tax`,
        DEMO: `${URL}/tax`,
      },
      CREATE_TAX_RATES: {
        MOCK: `${URL}/tax`,
        DEV: `${URL}/tax`,
        TTEN: `${URL}/tax`,
        DEMO: `${URL}/tax`,
      },
      EDIT_TAX_RATES: {
        MOCK: `${URL}/tax/:id`,
        DEV: `${URL}/tax/:id`,
        TTEN: `${URL}/tax/:id`,
        DEMO: `${URL}/tax/:id`,
      },
      DELETE_TAX_RATES: {
        MOCK: `${URL}/tax/:id`,
        DEV: `${URL}/tax/:id`,
        TTEN: `${URL}/tax/:id`,
        DEMO: `${URL}/tax/:id`,
      },
    },
    CUSTOMERS: {
      GET_CUSTOMERS: {
        MOCK: `${URL}/customer`,
        DEV: `${URL_INVOICES_DEV}`,
        TTEN: `${URL}/customer`,
        DEMO: `${URL}/customer`,
      },
      ADD_CUSTOMER: {
        MOCK: `${URL}/customer`,
        DEV: `${URL}/customer`,
        TTEN: `${URL}/customer`,
        DEMO: `${URL}/customer`,
      },
      EDIT_CUSTOMER: {
        MOCK: `${URL}/customer/:id`,
        DEV: `${URL}/customer/:id`,
        TTEN: `${URL}/customer/:id`,
        DEMO: `${URL}/customer/:id`,
      },
    },

    SETTINGS: {},
  },
  PAYABLES: {
    ACCOUNTS_PAYABLES: {
      INVOICES: {
        MOCK: "/api/v1/ap/invoice/",
        DEV: `${BASE_URL_DEV}api/v1/ap/invoice/`,
        ISOLATED: `${BASE_URL_DEV}api/v1/ap/invoice/`,
        TTEN: `${BASE_URL_TTEN}api/v1/ap/invoice/`,
        DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
      },
      QUICKBOOKS: {
        MOCK: "/api/v1/ap/invoice/",
        DEV: `${BASE_URL_DEV}proxy/quick-book/tenant`,
        ISOLATED: `${BASE_URL_DEV}proxy/quick-book/tenant`,
        TTEN: `${BASE_URL_TTEN}proxy/quick-book/tenant`,
        DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
      },
    }
  },
  REWARDS: {
    GET_REWARDS_DETAILS: {
      MOCK: "/api/rewards/details/",
      DEV: "",
    },
    GET_REWARDS_CASHBACK_HISTORY_DETAILS: {
      MOCK: "/api/rewards/cashback-history/details/",
      DEV: "",
    },
    GET_REWARDS_CASHBACK_HISTORY_PAYMENTS: {
      MOCK: "/api/rewards/cashback-history/payments/",
      DEV: "",
    },
    GET_REWARDS_ACCUMULATED_CASHBACK_PAYMENTS: {
      MOCK: "/api/rewards/accumulated-cashback/payments/",
      DEV: "",
    },
  },
};

export default endpoints;
