import { API } from "aws-amplify";

export default async function getIFieldsKey(queryStringParams) {
  if (queryStringParams?.companyId || queryStringParams?.tenantId) {
    return await API.get("cardknox-ifields", "get", {
      queryStringParameters: {
        ...queryStringParams,
      },
    });
  }
}
