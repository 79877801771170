import { GET_ACCOUNT } from "./actions.data";

const getAccountReducer = (state, action) => {
    switch(action.type){
        case GET_ACCOUNT:
            return {};
        default:
            return state;
    }
}

export default getAccountReducer;