import React from 'react';
import { Button } from 'antd';

import Check from "remixicon-react/CheckLineIcon";
import Close from "remixicon-react/CloseLineIcon";

import classNames from './styles.module.scss'

const SubscriptionPlanCard = ({ planName, price, description, subDescription, planInclude, paymentRate, features, onSubscribe, selected, save, transactionVol, addOn }) => {
  return (
    <div className={classNames.subscriptionCard}>
      {save && (
        <div className={classNames.saveBadge}>
          {save}
        </div>
      )}
      <div className={classNames.subscriptionCardHeader}>
          <h2 className={classNames.planName}>{planName}</h2>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1vw'}}>
            <h4 className={classNames.price}><span><sup>$</sup>{price}</span></h4>
            <p className={classNames.planDescription}>
                <span>{description}</span><br/>
                <b>{subDescription}</b>
            </p>
          </div>
      </div>
      <div className={classNames.cardContent}>
        {/* <p style={{textAlign: "left", width: "90%", fontWeight: "700", marginBottom: 0}}>What's included : <span style={{color: "#7372FF"}}>{planInclude}</span></p> */}
        <p style={{textAlign: "left", width: "90%", marginBottom: 0}}>{planInclude}</p>
        <ul className={classNames.featuresList}>
            {features.map((feature, index) => (
            <li key={`${planName}_${index}`}>
                <div className={classNames.featureItem}>
                  {feature.isAvailable ?<Check className={classNames.featureIcon} color="#1cbd83" size={16} />:<Close className={classNames.featureIcon} color='#dd2c2c' size={16} />}<span> {`${feature.name} `} {feature.extraInfo ? (<span>(<b style={{color: '#1cbd83'}}>{feature.extraInfo}</b>)</span>) : null}</span>
                </div>
            </li>
            ))}
        </ul>
        <div style={{display: 'flex', flexDirection: 'column', margin: '2px 0px 0px', width: "90%", textAlign: "left", 
          // position: "absolute", top: "160px"
        }}>
          <p style={{textAlign: "left", width: "90%", fontWeight: "700", margin: "5px 0"}}>Payments Processing Rates :</p>
          {/* <p style={{margin: "2.5px 0"}}> Interchange plus <span style={{color: "#7372FF"}}>0.10% + 30c</span></p> */}
          {
            planName === "Pro" ? (
              <p style={{margin: "2.5px 0"}}> <span style={{color: "#7372FF", fontWeight: '600'}}>{paymentRate}</span></p>
            ) : <p style={{margin: "2.5px 0"}}> Interchange plus <span style={{color: "#7372FF", fontWeight: '600'}}>{paymentRate}</span></p>
          }
          <p style={{fontStyle: "italic", margin: "2.5px 0"}}>{transactionVol}</p>
        </div>
        {
          addOn ? (
            <div className={classNames.addOn}>
              <strong>Add-on: </strong>Tax Filing for <span style={{color: '#7372FF', fontWeight: '600'}}>{addOn}</span>
            </div>
          ) : (
            <div className={classNames.addOn}style={{opacity: '0'}}>
              <strong>Don't look: </strong><span>Ahhhh!</span>
            </div>
          )
        }
      </div>
      <div className={classNames.cardFooter}>
        <Button type={selected ? '' : 'primary'} disabled={selected} className={[classNames.button, selected && classNames.selected]} onClick={selected ? () => null : onSubscribe}>{selected ? "Active" :"Get the Plan"}</Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
