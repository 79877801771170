import { Button as AntButton } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

export default function CTAButton({
  children,
  className,
  // variant = "default",
  onClick,
  ...props
}) {
  const Button = styled(AntButton)`
    width: 100%;
    height: ${(props) => (props.small ? "44px" : "56px")};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  `;
  // const Button = styled.AntButton(
  //   {
  //     width: "100%",
  //     fontStyle: "normal",
  //     fontWeight: "500",
  //     fontSize: "16px",
  //   },
  //   ({ variant }) => ({ height: variant === "small" ? "44px" : "56px" })
  // );

  return (
    <Button {...props} className={`${className}`} onClick={onClick}>
      {children}
    </Button>
  );
}

CTAButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  // variant: PropTypes.oneOf(["default", "small"]),
};
