import classNames from "./Preview.module.scss";
import {  Modal } from "../../../../../common";
import PreviewForm from "./PreviewForm";
// import ConfirmModel from "../ConfirmModal/ConfirmModel";

export default function Preview({ visible, onCancel }) {


  return (
    <>
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        className={classNames.modal}
        bodyStyle={{
          height: "95vh",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        width={942}
      >
        <PreviewForm  />
      </Modal>
    </>
  );
}
