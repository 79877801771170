import { Modal as AntModal } from "antd";
import PropTypes from "prop-types";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

export default function Modal({
  children,
  className,
  style,
  title,
  visible,
  onOk,
  closable,
  onCloseClick,
  maskClosable,
  footer = null,
  ...props
}) {
  return (
    <AntModal
      {...props}
      className={className}
      style={style}
      title={title}
      visible={visible}
      onOk={onOk}
      closeIcon={<CloseLineIcon size={20} style={{ color: "#3B3F43" }} />}
      onCancel={onCloseClick}
      maskClosable={maskClosable}
      closable={closable}
      centered
      footer={footer}
    >
      {children}
    </AntModal>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  title: PropTypes.string,
  visible: PropTypes.bool,
  closable: PropTypes.bool,
  onOk: PropTypes.func,
  onCloseClick: PropTypes.func,
  maskClosable: PropTypes.bool,
  footer: PropTypes.node,
};
