import { API } from "aws-amplify";

export default async function deleteInvoice(id) {
  if (id) {
    return await API.del("invoices", "delete", {
      queryStringParameters: {
        invoiceId: id,
      },
    });
  }
}
