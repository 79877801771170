import classNames from "./UsersCard.module.scss";

function UserCardButton({
  icon: Icon,
  disabled,
  onClick,
  children,
  comingSoon,
  iconSize = 30,
  ...rest
}) {
  return (
    <div
      {...rest}
      onClick={!disabled && onClick ? onClick : null}
      /**
       * TODO: It is so difficult to override the current styles
       * as the selectors have few levels, there is a best practice based
       * on on the how many levels a selector should go based on the css speficity
       * Should do a research and should inherit a better styling practice.
       */
      style={{ cursor: disabled ? "auto" : "pointer" }}
    >
      <div className={disabled ? classNames.deactivated : ""}>
        {children}
        {comingSoon ? (
          <div className={classNames.optionCardComingSoonLabel}>
            COMING SOON
          </div>
        ) : (
          false
        )}
      </div>

      <Icon
        color={disabled ? "rgb(209, 213, 221)" : "#7372ff"}
        size={iconSize}
      />
    </div>
  );
}

export default UserCardButton;
