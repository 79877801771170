import { Header, Title, Input } from "../../common";
import { Card, Avatar } from "antd";
import { useQuery } from "react-query";
import { useContext } from "react";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import UserSmileLineIcon from "remixicon-react/UserSmileLineIcon";
import SupportTopicCard from "./SupportTopicCard/SupportTopicCard";
import { AppContext } from "../../../context/AppState";
import user from "../../../assets/user.png";
import api from "../../API";
import classNames from "./SupportHome.module.scss";

export default function SupportHome() {
  const { application } = useContext(AppContext);

  //Api calls
  const { data: accountDetail } = useQuery(
    "getBankLetterDetail",
    () =>
      api.BusinessBanking.getBankLetterDetail(
        application?.otz_account_id
      ),
    {
      refetchOnWindowFocus: true,
    }
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Support" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <Card className={classNames.supportHomeCard}>
              <div>
                <Title as="h3">How can we help you</Title>
                <span>Search for a help topic or browse from below</span>
                <Input
                  placeholder="Search help topic"
                  prefix={<Search2LineIcon />}
                  className={classNames.searchTopic}
                />
              </div>
              <div>
                <UserSmileLineIcon />
                <span>Or, reach out to your dedicated support agent</span>
                <div className={classNames.supportPill}>
                  <Avatar size={34} src={user} />
                  <div>
                    <span>{accountDetail?.payload?.customer_care?.name}</span>
                    <span>{accountDetail?.payload?.customer_care?.number}</span>
                  </div>
                </div>
              </div>
            </Card>
            <div className={classNames.topicsWrapper}>
              {/* <SupportTopicCard
                topic="ATM Locator"
                onClick={() => navigate(ROUTES.APP.SUPPORT.ATM_LOCATOR)}
              /> */}
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
              <SupportTopicCard topic="Help Topic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
