import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {  
  useNavigate 
} from "react-router-dom";
import { Form, Input as AntInput, Checkbox } from "antd";
import moment from "moment";
import {
  Modal,
  Message,
  CTAButton,
  Input,
  // Select,
  DatePicker,
} from "../../../../common";
import useLanguage from "../../../../../hooks/useLanguage";
import { AppContext } from "../../../../../context/AppState";
import api from "../../../../API";
import { isBusinessType } from "../../../../util/functions/onboarding";
import { checkIsAmount } from "../../../../util/functions/checkIsAmount";
import {
  centsToDollar,
  dollarToCents,
} from "../../../../util/functions/dollarConvertion";
import generateErrorMessage from "../../../../util/functions/customError";
import ACHTransactionModal from "./ACHTransactionModal";
import REGEX from "../../../../util/constants/regex";
import { MAX_MIN } from "../../../../util/constants";
import { DEFAULT_AMOUNT, FORMATS, ROUTES } from "../../../../constants";
import { validationPayee } from "../../../../BusinessBanking/config";
import { strings } from "../../../../BusinessBanking/businessBanking.const";
import classNames from "./MakePayments.module.scss"
import AccountActivityModal from "../../../../BusinessBanking/config/accountActivityModal";

function AddACHPayment({ onCancel, accountBalance, refetch, incomingPayee, amountPayable, invoiceId, receiptId }) {
  //Hooks
  const [amount, setAmount] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [payee, ] = useState();
  const [payLater, setPayLater] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [transMsg, setTransMsg] = useState("");
  const messages = useLanguage(strings);
  const [form] = Form.useForm();
  const { application } = useContext(AppContext);
  const queryClient = useQueryClient();

  //Other variables
  const navigate = useNavigate();
  const { TextArea } = AntInput;
  useEffect(() => {
    // Prefill the form with incomingPayee and amountPayable
    form.setFieldsValue({
      payee: incomingPayee?.account_owner_name,
      amount: amountPayable,
    });
  }, [form, incomingPayee, amountPayable]);

  //Api calls

  const markAsPaidMutation = useMutation(api.AccountsPayable.updateAPInvoicePaymentStatus);

  const { mutate: createACHPayment, isLoading: createACHPaymentLoading } =
    useMutation(api.BusinessBanking.createACHPayment, {
      onSuccess: async () => {
        // refetch();
        await markAsPaidMutation.mutateAsync({
          invoice_id: invoiceId,
          updateToStatus: "paid"
        });
        navigate(ROUTES.APP.PAYABLES.APPROVED_INVOICES)
      },
    });

  const {
    mutate: createRecurringPayment,
    isLoading: createRecurringPaymentLoading,
  } = useMutation(api.BusinessBanking.createRecurringPayment, {
    onSuccess: async () => {
      // refetch();
      await markAsPaidMutation.mutateAsync({
        invoice_id: invoiceId,
        updateToStatus: "scheduled"
      });
      navigate(ROUTES.APP.PAYABLES.APPROVED_INVOICES)
    },
  });

  const [OTPModalVisible, setOTPModalVisible] = useState(false);

  //Event handlers
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (!payLater && !isRecurring) {
        let newACHPayment = {
          amount: Number(dollarToCents(amountPayable)),
          otz_payee_id: incomingPayee?.otz_payee_id,
          description: values.description,
          ...(isBusinessType(application?.type)
            ? { otz_business_id: application?.otz_business_id }
            : { otz_person_id: application?.otz_person_id }),
        };

        createACHPayment(newACHPayment, {
          onSuccess: () => {
            Message({
              type: "success",
              content: messages.paymentSuccessMessage,
            });

            onCancel();
            form.resetFields();
            queryClient.invalidateQueries("getStatementDetail");
            queryClient.invalidateQueries("getPayees");
          },
          onError: (error) => {
            if (
              error?.response?.status === 422 &&
              error?.response?.data?.payload?.code === "MFA_REQUIRED"
            ) {
              setOTPModalVisible(true);
            } else if (
              error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
            ) {
              Message({
                type: "error",
                content:
                  "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
              });
            } else {
              generateErrorMessage(error);
            }
          },
        });
      } else if (payLater) {
        let newScheduledPayment = {
          description: values.description,
          amount: dollarToCents(amountPayable),
          frequency: "DAILY",
          start_date: moment(values.date).format(FORMATS.API_SEND_FORMAT),
          is_recurring: 0,
          otz_payee_id: incomingPayee?.otz_payee_id,
          ...(isBusinessType(application?.type)
            ? { otz_business_id: application?.otz_business_id }
            : { otz_person_id: application?.otz_person_id }),
        };

        createRecurringPayment(newScheduledPayment, {
          onSuccess: (data) => {
            Message({
              type: "success",
              content: messages.payLaterSuccessMessage,
            });
            form.resetFields();
            onCancel();
            queryClient.invalidateQueries("getStatementDetail");
            queryClient.invalidateQueries("getPayees");
          },
          onError: (error) => {
            if (
              error?.response?.status === 422 &&
              error?.response?.data?.payload?.code === "MFA_REQUIRED"
            ) {
              setOTPModalVisible(true);
            } else if (
              error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
            ) {
              Message({
                type: "error",
                content:
                  "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
              });
            } else {
              generateErrorMessage(error);
            }
          },
        });
      }
    });
  };

  //Helper functions
  function disabledDateBeforePayLater(current) {
    return current && current <= moment().subtract(0, "days");
  }

  useEffect(() => {
    let message = "";
    if (!(paymentDate && (isRecurring || payLater))) {
      if (payee) {
        message = `${payee?.account_owner_name} will receive $${
          amount && checkIsAmount(amount)
        }.`;
      }
    } else {
      message = `Payment will be initiated ${
        paymentDate && ` on ${paymentDate}`
      }.`;
    }
    setTransMsg(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDate, isRecurring, payLater, amount]);

  useEffect(() => {
    // Prefill the form with incomingPayee and amountPayable
    form.setFieldsValue({
      payee: incomingPayee?.account_owner_name,
      amount: amountPayable,
      description: `${receiptId} paid to ${incomingPayee?.account_owner_name}`
    });
  }, [form, incomingPayee, amountPayable, invoiceId, receiptId]);

  return (
    // <Spin spinning={isLoading}>
    <>
      <div className={classNames.modalLeft}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="payee"
            label={
              <span className={classNames.label}>
                Vendor <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: validationPayee.quickFundTransfer.payee,
              },
            ]}
          >
            <div style={{ backgroundColor: '#f5f5f5', padding: '24px', borderRadius: '5px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p style={{margin: "0"}}>Pay <strong>{incomingPayee.account_owner_name}</strong></p> 
              <p style={{margin: "0", fontSize: "2rem"}}><sup style={{fontSize: "1.1rem"}}>$</sup>{amountPayable}</p>
            </div>
          </Form.Item>
          <Form.Item
            name="amount"
            value={amountPayable}
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value > accountBalance / 100) {
                    callback(
                      validationPayee.quickFundTransfer.insufficientFund
                    );
                  }
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Available Balance:{" "}
                <span style={{ color: "#000" }}>
                  {centsToDollar(accountBalance) || DEFAULT_AMOUNT}
                </span>
              </span>
            }
          >
            <Input
              disabled
              onChange={(e) => setAmount(e.target.value)}
              placeholder={validationPayee.quickFundTransfer.amountPlaceholder}
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              maxlength="11"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.description,
              },
              {
                max: MAX_MIN.description.max,
                message: validationPayee.quickFundTransfer.descriptionMax,
              },
            ]}
            name="description"
            label={
              <span className={classNames.label}>
                Description *
              </span>
            }
          >
            <TextArea
              autoSize
              placeholder={
                validationPayee.quickFundTransfer.descriptionPlaceholder
              }
              style={{ height: 44 }}
            ></TextArea>
          </Form.Item>
          <Form.Item layout="vertical" name="payLater">
            <Checkbox
              checked={payLater}
              value="payLater"
              onChange={() => {
                setPayLater(!payLater);
                setIsRecurring(false);
                setPaymentDate(null);
                form.resetFields(["startDate", "endDate"]);
              }}
            >
              Pay later
            </Checkbox>
            {payLater && (
              <div
                style={{
                  backgroundColor: "#F6F7FA",
                  borderRadius: 8,
                  padding: "16px 6px 6px 16px",
                  marginTop: "16px",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: validationPayee.quickFundTransfer.date,
                    },
                  ]}
                  name="date"
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      Date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker
                    disabledDate={disabledDateBeforePayLater}
                    // disabled={[true, false]}
                    onChange={(e) =>
                      setPaymentDate(
                        moment(e).format(FORMATS.DATEPICKER_FORMAT)
                      )
                    }
                    style={{ height: 44, width: 142 }}
                  />
                </Form.Item>
              </div>
            )}
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <div>Transaction Summary</div>
        <div className={classNames.transactionCard}>
          <div className={classNames.payee}>
            <div className={classNames.payeeDetails}>
              {payee && payee?.account_owner_name}
              {payee?.account_number_masked && (
                <span>
                  Account Number : {payee && payee?.account_number_masked}
                </span>
              )}
              {payee?.routing_no && (
                <span>Routing Number : {payee?.routing_no}</span>
              )}
            </div>
            <span>
              {payee && payee?.type === "achCounterparty" ? "ACH" : payee?.type}
            </span>
          </div>
          <div className={classNames.transaction}>
            Amount
            <span>$ {checkIsAmount(amountPayable)}</span>
          </div>
          <div className={classNames.transaction}>
            Transaction Fee
            <span>{DEFAULT_AMOUNT}</span>
          </div>
          <div className={classNames.total}>
            Total
            <span>${checkIsAmount(amountPayable)}</span>
          </div>
          {payee && amount && (
            <div className={classNames.summaryText}>
              {/*  {/* {payee && payee?.account_owner_name} will receive $ $
              {amount && `${checkIsAmount(amount)}`} payment will be initiated
              {paymentDate && ` on  ${paymentDate}`}. */}
              {transMsg}
              <br />
              <span> Standard ACH will take 2-3 working days</span>
            </div>
          )}
        </div>

        <CTAButton
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmit}
          style={{ height: 44, width: 248 }}
          loading={createACHPaymentLoading || createRecurringPaymentLoading}
          disabled={+accountBalance <= 0}
        >
          Transfer
        </CTAButton>
        {+accountBalance <= 0 && (
          <p style={{ color: "#ff4d4f", width: 248, marginTop: 10 }}>
            Your account balance is USD 0.00, Please add funds to your account.
          </p>
        )}
      </div>
      <ACHTransactionModal
        visible={OTPModalVisible}
        // closable={true}
        onCloseClick={() => setOTPModalVisible(false)}
        onCancel={() => setOTPModalVisible(false)}
        onAuthorize={handleSubmit}
      />
    </>
  );
}

export default function PayViaBank({
  visible,
  onCancel,
  incomingPayee,
  setVisible,
  isLoadingPayeeData,
  amount,
  accountBalance,
  refetch,
  invoiceId,
  receiptId,
}) {

  const [accountActivityVisible, setAccountActivityVisible] = useState(false);
  const [showACHPayment, setShowACHPayment] = useState(false);
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);

  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
    setVisible(false)
  }

  useEffect(() => { 
    if(visible) {
      if(isAccountInfoNeeded) {
        setAccountActivityVisible(true);
      } else {
        setShowACHPayment(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
    <Modal
      closable={true}
      visible={showACHPayment}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      title="ACH Payment"
      className={classNames.modal}
      destroyOnClose
      bodyStyle={{
        padding: 0,
        height: "70vh",
        display: "flex",
        justifyContent: "space-between",
      }}
      width={730}
    >
      <AddACHPayment
        onCancel={onCancel}
        incomingPayee={incomingPayee}
        isLoading={isLoadingPayeeData}
        accountBalance={accountBalance}
        refetch={refetch}
        amountPayable={amount}
        invoiceId={invoiceId}
        receiptId={receiptId}
      />
    </Modal>
    <AccountActivityModal
      setIsAccountInfoNeeded={setIsAccountInfoNeeded}
      visible={accountActivityVisible}
      onCancel={handleAccountActivityModalCancelClick}
      onDone={setShowACHPayment}
      spawnModal={setAccountActivityVisible}
      title="Outgoing ACH Transactions"
    />
    </>
  );
}
