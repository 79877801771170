import { Input as AntInput } from "antd";
import PropTypes from "prop-types";
import classNames from "./Input.module.scss";

const Input = function Input({
  onInputLowerCase,
  className,
  size = "default",
  style,
  ...props
}) {
  return (
    <AntInput
      className={`${classNames.input} ${classNames[size]} ${className} `}
      style={style}
      {...props}
    ></AntInput>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.oneOf(["default", "small"]),
};

export default Input;
