function Slide({ classNames, title, description, imgSrc }) {
  // console.log("getting called");
  return (
    <div className={classNames.carouselContent}>
      <img
        src={imgSrc}
        alt="person pointing at a chart"
        className={classNames.carouselImg}
      />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default Slide;
