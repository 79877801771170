import React, { useState, useContext } from "react";
import classNames from "../BillPayments.module.scss";
import { Form, Checkbox, Input as AntInput, message, Spin } from "antd";
import { CTAButton, Input, Select } from "../../../../common";
import { useMutation, useQuery } from "react-query";
import api from "../../../../API";
import { AppContext } from "../../../../../context/AppState";
import { centsToDollarWithoutSign } from "../../../../util/functions/dollarConvertion";
import { checkIsAmount } from "../../../../util/functions/checkIsAmount";

import generateErrorMessage from "../../../../util/functions/customError";
import { DEFAULT_AMOUNT } from "../../../../constants";

export default function BillPaymentForm() {
  const [form] = Form.useForm();
  const { account } = useContext(AppContext);
  const [amount, setAmount] = useState(0);
  const { TextArea } = AntInput;

  const { data: accountPayload } = useQuery(
    "getAccount",
    () =>
      account &&
      api.BusinessBanking.getAccountBalance(account.tempIndividualAccountId),
    {
      retry: false,
    }
  );

  // const {
  //   data: listBillersPayload,
  //   // status: accountStatus,
  //   // refetch: accountsRefetch,
  // } = useQuery("listBillers", () => api.BusinessBanking.listBillers(1), {
  //   retry: false,
  // });

  const createBillPaymentFn = (event) => {
    let input = {
      type: "billPayment",
      attributes: {
        billerId: event.biller,
        accountNumber: event.accountNumber,
        amount: event.amount,
        description: event.description,
        tags: {
          account_id: "Need info from unit's end",
        },
      },
      relationships: {
        account: {
          data: {
            type: "depositAccount",
            id: accountPayload?.account?.id,
          },
        },
      },
    };
    return api.BusinessBanking.createBillPayment(input);
  };

  const createBillPayment = useMutation((event) => createBillPaymentFn(event));

  const onFinish = (data) => {
    createBillPayment.mutate(data, {
      onSuccess: (data, variables, context) => {
        message.success("Bill payment made successfully!");
        form.resetFields();
        setAmount(0);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={classNames.modalLeft}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          {/* Commenting below input field due to the lack of data from unit's end.
        POST method used here do not require category as a input. Refer: https://docs.unit.co/bill-pay#create-bill-payment
        Can retrieve available categories by listing billers. But each biller includes a string which contains multiple categories.
        Refer: https://docs.unit.co/bill-pay#list-billers */}
          {/* <Form.Item
            name="category"
            label={
              <span className={classNames.label}>
                Category <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Select size="small" placeholder="Please select">
              <Select.Option key="test">Test</Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            name="biller"
            label={
              <span className={classNames.label}>
                Biller <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[{ required: true, message: "Please select biller" }]}
          >
            <Select size="small" placeholder="Please select">
              {/* {listBillersPayload &&
                listBillersPayload.length > 0 &&
                listBillersPayload.map((val, index) => (
                  <Select.Option key={val.id}>
                    {val.attributes.name}
                  </Select.Option>
                ))} */}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Account number is required" }]}
            name="accountNumber"
            label={
              <span className={classNames.label}>
                Account Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input placeholder="Please enter" size="small" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Amount is required" },
              {
                pattern: new RegExp(/^\d{0,500}(\.\d{2,2})?$/),
                message: "Enter a valid amount",
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value > accountPayload?.data.balance / 100) {
                    callback(`Insufficient Funds`);
                  }
                  callback();
                },
              },
            ]}
            name="amount"
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Available Balance:
                <span style={{ color: "#000" }}>
                  ${" "}
                  {!Number(accountPayload?.data.balance)
                    ? 0.00
                    : centsToDollarWithoutSign(accountPayload?.data.balance)}
                </span>
              </span>
            }
          >
            <Input
              placeholder="Please enter"
              size="small"
              onChange={(e) => setAmount(e.target.value)}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              maxlength="11"
              align="left"
            />
          </Form.Item>
          {/* Add below field as the POST request requires description field. Refer: https://docs.unit.co/bill-pay#create-bill-payment */}
          <Form.Item
            rules={[{ required: true, message: "Description is required" }]}
            name="description"
            label={
              <span className={classNames.label}>
                Description <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <TextArea
              autoSize
              placeholder="Max 100 characters"
              style={{ height: 44 }}
            ></TextArea>
          </Form.Item>
          <Form.Item
            extra={
              <span style={{ fontSize: 12 }}>
                This will reming you about the upcoming payments
              </span>
            }
          >
            <Checkbox>Remind me about the next payments</Checkbox>
          </Form.Item>
          <Form.Item
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Auto payment will occur on the same day you make the payment
              </span>
            }
          >
            <Checkbox>Auto Payment</Checkbox>
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <Spin spinning={createBillPayment.isLoading}>
          <div>Transaction Summary</div>
          <div className={classNames.transactionCard}>
            <div className={classNames.transaction}>
              Amount<span>$ {amount ? checkIsAmount(amount) : "0.00"}</span>
            </div>
            <div className={classNames.transaction}>
              Transaction Fee<span>{DEFAULT_AMOUNT}</span>
            </div>
            <div className={classNames.total}>
              Total<span>$ {amount ? checkIsAmount(amount) : "0.00"}</span>
            </div>
          </div>
          <CTAButton
            type="primary"
            style={{ height: 44, width: 248 }}
            onClick={form.submit}
          >
            Pay
          </CTAButton>
        </Spin>
      </div>
    </div>
  );
}
