import { 
  Form, 
  // Input as AntdInput 
} from "antd";
import { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  CTAButton,
  Input,
  Title,
  Select,
  Message,
  CountryPicker,
} from "../../common";
import Heading from "../Heading";
import Steps from "../Steps";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { 
  usStateList, 
  businessEntityType, 
  industryList,
  businessIsFollowing
} from "../../util";
import { validation } from "../config";
import { ROUTES } from "../../constants";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import classNames from "../Signup/Signup.module.scss";
import mixpanel from "mixpanel-browser";

export default function BusinessAccount() {
  //Hooks
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [progress, setProgress] = useLocalStorage("OTTERZ_op_bd");
  const { userData, application } = useContext(AppContext);

  mixpanel.identify(userData.email);

  // Set user properties
  mixpanel.people.set({
    $email: userData.email,
  });

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: true,
    }
  );

  try {
    mixpanel.identify(userData?.email);

    // Set user properties
    mixpanel.people.set({
      $email: userData?.email,
    });
  } catch (error) {
    console.error("error: ", error);
  }

  //Api calls
  const createBusiApplication = useMutation((data) =>
    api.onBoarding.createApplication(data, true)
  );

  const createCompany = useMutation((data) =>
    api.Receivables.Company.createCompany(data)
  );

  const updateDetails = useMutation((data) =>
    api.onBoarding.updateBusinessOperationDetails(data)
  ); 

  const updateBusiApplication = useMutation((data) => {
    const reqBody = {
      id: progress?.id,
      data,
    };
    return api.onBoarding.updateApplication(reqBody, true);
  });

  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  useQuery(
    "getBusinessDetail",
    () => api.BusinessBanking.getBusiness(application?.otz_business_id),
    {
      onSuccess: (data) => {
        const payloadData = data?.payload;
        setProgress((preProgress) => {
          return {
            ...preProgress,
            id: application?.otz_business_id,
            name: payloadData?.entity_name,
            ein: payloadData?.ein?.replace("-", ""),
            email: payloadData?.email,
            number: payloadData?.phone_number?.replace("+1", ""),
            street: payloadData?.legal_address?.address_line_1,
            street2: payloadData?.legal_address?.address_line_2,
            city: payloadData?.legal_address?.city,
            country: payloadData?.legal_address?.country_code,
            postalCode: payloadData?.legal_address?.postal_code,
            website: payloadData?.website,
            stateOfIncorporation: payloadData?.stateOfIncorporation,
            entityType: payloadData?.structure,
          };
        });
      },
      enabled: !!application?.otz_business_id,
      refetchOnWindowFocus: false,
    }
  );

  const [showOtherIndustryInput, setShowOtherIndustryInput] = useState(false);
  const [otherIndustry, setOtherIndustry] = useState(""); // To store the value of the "Other" input field

  const handleIndustryChange = (value) => {
    if (value === "other") {
      setShowOtherIndustryInput(true);
    } else {
      setShowOtherIndustryInput(false);
      // Clear the value when changing to another industry type
      setOtherIndustry("");
    }
  };

  const handleOtherIndustryInputChange = (e) => {
    setOtherIndustry(e.target.value);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  
  // Define a function to handle the multiselect change
  const handleBusinessMetaChange = (value) => {
    if(value.includes("None")) {
      setSelectedOptions(["None"]);
    } else {
      setSelectedOptions(value);
    }
  };

  // Define a function to disable other options if 'None' is selected
  const isOptionDisabled = (option) => {
    return selectedOptions.includes('None') && option !== 'None';
  };

  //Event handlers
  const handleOnFinish = () => {
    let inputData = form.getFieldsValue();

    setProgress((prevVals) => ({
      ...prevVals,
      ...inputData,
    }));

    const businessReqData = {
      tenant_id: userData?.tenantId,
      entity_name: inputData?.name,
      ein: inputData?.ein?.slice(0, 2) + "-" + inputData?.ein?.slice(2),
      email: inputData?.email,
      phone_number: `+${inputData?.countryCode}${inputData?.number}`,
      address_line_1: inputData?.street,
      address_line_2: inputData?.street2 ? inputData?.street2 : "",
      city: inputData?.city,
      country_code: inputData?.country,
      postal_code: inputData?.postalCode,
      state: inputData?.state,
      website: inputData?.website,
      formation_state: inputData?.stateOfIncorporation || "AL",
      structure: inputData?.entityType,
      is_customer: true,
    };

    if (!progress?.id) {
      createBusiApplication.mutate(businessReqData, {
        onSuccess: (data) => {
          setProgress((prevVals) => ({
            ...prevVals,
            id: data?.payload?.otz_business_id,
          }));

          createCompany.mutate(
            {
              name: inputData?.name,
              email: inputData?.email,
              tenantId: userData?.tenantId,
              userId: currentUserData?.username,
            },
            {
              onSuccess: () => {},
              onError: (err) => {
                throw new Error(err);
              },
            }
          );

          updateApplication.mutate(
            {
              onBoardingStatus: "BUSINESS_CREATED",
            },
            {
              onSuccess: () => {
              },
              onError: (err) => {
                throw new Error(err);
              },
            }
          );

          Message({ type: "success", content: data?.message });

          updateDetails.mutate({
            otz_business_id: data?.payload?.otz_business_id,
            industry: inputData?.industry,
            business_info: selectedOptions,
            products_services_offered: inputData?.serviceOffered,
          }, {
            onSuccess: () => {
              Message({ type: "success", content: "Business details submitted successfully" });
              
            },
            onError: (error) => {
              generateErrorMessage(error);
            },
          });

          try {
            mixpanel.people.set({
              $name: data?.name,
            });

            mixpanel.track("Business details - completed", {
              Company_id: data?.payload?.otz_business_id,
              "Sign Up Date": new Date().toISOString(),
              "Email ID": userData?.email,
              "Business Name": data?.payload?.entity_name,
            });
            mixpanel.people.set({
              "Sign Up": "Business details submitted",
            });
          } catch (error) {
            console.error("error: ", error);
          }

          navigate(ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS);
        },
        onError: (error) => {
          generateErrorMessage(error);
        },
      });
    } else if (progress?.id && form.isFieldsTouched()) {
      updateBusiApplication.mutate(businessReqData, {
        onSuccess: (data) => {
          Message({ type: "success", content: data?.message });

          try {
            mixpanel.people.set({
              $name: data?.name,
            });
            mixpanel.track("Business details - completed", {
              "Email ID": userData?.email,
              "Sign Up Date": new Date().toISOString(),
              "Business Name": data?.payload?.entity_name,
              Company_id: data?.payload?.id,
            });
          } catch (error) {
            console.error("error: ", error);
          }

          navigate(ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS);
        },
        onError: (error) => {
          generateErrorMessage(error);
        },
      });
    } else {
      navigate(ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS);
    }
  };

  useEffect(() => {
    document.querySelectorAll(".ant-select-selector").forEach((it) => {
      it.classList.add(classNames.businessInfoSelect)
    })
  }, [])
  

  return (
    <div className={classNames.accountFormWrapper}>
      <Steps classNames={classNames} currentIndex={1} count={5}/>
      <div className={classNames.formWrapper}>
        <Heading
          classNames={classNames}
          title="Open a Business Account"
          description="Fill in the form below and submit, we will review your details and get back to you"
        />
        <div className={classNames.formLabel}>Business Details</div>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={handleOnFinish}
          form={form}
          initialValues={{
            progress,
            country: "US",
          }}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item>
            <Form.Item
              name="name"
              label={
                <Title as="h4" className={classNames.label}>
                  Name of the business <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                { required: true, message: validation.businessName.required },
                {
                  max: MAX_MIN.businessName.max,
                  message: validation.businessName.max,
                },
                {
                  pattern: new RegExp(REGEX.businessName),
                  message: validation.businessName.valid,
                },
              ]}
            >
              <Input placeholder={validation.businessName.placeholder} />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <Title as="h4" className={classNames.label}>
                Address <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
          >
            <Form.Item
              name={"street"}
              rules={[
                { required: true, message: validation.street.required },
                { max: MAX_MIN.street.max, message: validation.street.max },
              ]}
            >
              <Input placeholder={validation.street.placeholder} />
            </Form.Item>
            <Form.Item
              name={"street2"}
              rules={[
                { max: MAX_MIN.street.max, message: validation.street2.max },
              ]}
            >
              <Input placeholder={validation.street2.placeholder} />
            </Form.Item>
            <Form.Item
              name={"city"}
              rules={[
                { required: true, message: validation.city.required },
                { max: MAX_MIN.city.max, message: validation.city.max },
              ]}
            >
              <Input placeholder={validation.city.placeholder} />
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name={"state"}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[{ required: true, message: validation.state.required }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={validation.state.placeholder}
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={"postalCode"}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: validation.postalCode.required },
                  {
                    pattern: new RegExp(REGEX.postalCode),
                    message: validation.postalCode.valid,
                  },
                ]}
              >
                <Input placeholder={validation.postalCode.placeholder} />
              </Form.Item>
            </Form.Item>{" "}
            {/* End of Postal code pair */}
            <Form.Item
              name={"country"}
              rules={[{ required: true, message: validation.country.required }]}
            >
              <CountryPicker
                placeholder={validation.country.placeholder}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: validation.email.required },
              {
                type: "email",
                message: validation.email.valid,
              },
              {
                min: MAX_MIN.email.min,
                message: validation.email.minLength,
              },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
            ]}
            label={
              <Title as="h4" className={classNames.label}>
                Email Address <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
          >
            <Input
              placeholder={validation.email.placeholder}
              // disabled
              // defaultValue={userData?.email}
            />
          </Form.Item>

          <Form.Item
            name="number"
            label={
              <Title as="h4" className={classNames.label}>
                Phone Number <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            rules={[
              { required: true, message: validation.phoneNumber.required },
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: validation.phoneNumber.valid,
              },
            ]}
          >
            <Input
              placeholder={validation.phoneNumber.placeholder}
              prefix="+1"
              // disabled
              // defaultValue={userData?.phone_number}
            />
          </Form.Item>
          <Form.Item hidden name="countryCode" initialValue="1">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="stateOfIncorporation"
              label={
                <Title as="h4" className={classNames.label}>
                  State of incorporation <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  required: true,
                  message: validation.stateOfIncorporation.required,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={validation.stateOfIncorporation.placeholder}
              >
                {usStateList()?.map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="ein"
              label={
                <Title as="h4" className={classNames.label}>
                  Business EIN <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                { required: true, message: validation.ein.required },
                {
                  pattern: new RegExp(REGEX.einNumber),
                  message: validation.ein.onlyNumbers,
                },
              ]}
            >
              <Input placeholder={validation.ein.placeholder} />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="entityType"
              label={
                <Title as="h4" className={classNames.label}>
                  Entity Type <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                { required: true, message: validation.entityType.required },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
            >
              <Select placeholder={validation.entityType.placeholder}>
                {businessEntityType()?.map((value) => (
                  <Select.Option key={value.value} value={value.value}>
                    {value.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="industry"
              label={
                <Title as="h4" className={classNames.label}>
                  Industry <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                { required: true, message: validation.industry.required },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <Select 
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={validation.industry.placeholder}
                onChange={handleIndustryChange}
              >
                {industryList()?.map((value) => (
                  <Select.Option key={value.value} value={value.value}>
                    {value.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {showOtherIndustryInput && (
              <Form.Item
                name="otherIndustry"
                label={
                  <Title as="h4" className={classNames.label}>
                    Other Industry <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                  { required: true, message: validation.industry.required },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(100%)",
                  // margin: "0 0 0 4px",
                  // float: "right",
                }}
              >
                <Input
                  placeholder="Enter other industry"
                  value={otherIndustry}
                  onChange={handleOtherIndustryInputChange}
                />
              </Form.Item>
            )}
            
          </Form.Item>

          <Form.Item>
            <Form.Item
                label={
                  <Title as="h4" className={classNames.label}>
                    Select those that apply to your business <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                  { required: true, message: validation.businessMeta.required },
                ]}
              >
              <Select
                className={classNames.businessInfoSelect}
                mode="multiple"
                placeholder="Select options"
                onChange={handleBusinessMetaChange}
                value={selectedOptions}
                style={{ height: "fit-content" }}
                allowClear
              >
                {businessIsFollowing().map((option) => (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    disabled={isOptionDisabled(option.value)}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item> 

          <Form.Item>
            <Form.Item
                name="serviceOffered"
                label={
                  <Title as="h4" className={classNames.label}>
                    Products/Services Offered <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                  { required: true, message: validation.serviceOffered.required },
                  {
                    max: MAX_MIN.serviceOffered.max,
                    message: validation.serviceOffered.maxLength,
                  },
                ]}
              >
                <Input placeholder={validation.serviceOffered.placeholder}></Input>
            </Form.Item>
          </Form.Item> 

          <Form.Item>
            <Form.Item
              name="website"
              label={
                <Title as="h4" className={classNames.label}>
                  Website
                </Title>
              }
              rules={[
                {
                  pattern: new RegExp(REGEX.website),
                  message: validation.website.valid,
                },
                {
                  max: MAX_MIN.website.max,
                  message: validation.website.maxLength,
                },
              ]}
            >
              <Input placeholder={validation.website.placeholder}></Input>
            </Form.Item>
          </Form.Item>

          {/* <Form.Item>
            <Form.Item
              name="businessDescription"
              label={
                <Title as="h4" className={classNames.label}>
                  Business Description <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              // rules={[
              //   { required: true, message: validation.businessDescription.required },
              //   {
              //     max: MAX_MIN.businessDescription.max,
              //     message: validation.businessDescription.max,
              //   },
              // ]}
            >
              <AntdInput.TextArea placeholder={validation.businessDescription.placeholder} />
            </Form.Item>
          </Form.Item>
            
          <Form.Item>
            <Form.Item
              name="industry"
              label={
                <Title as="h4" className={classNames.label}>
                  Industry <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                // { required: true, message: validation.industry.required },
              ]}
            >
              <Select placeholder={validation.industry.placeholder}>
                {industryList()?.map((value) => (
                  <Select.Option key={value.value} value={value.value}>
                    {value.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
                
          <Form.Item>
            <Form.Item
              name="yearsOfOperation"
              label={
                <Title as="h4" className={classNames.label}>
                  Years of Operation <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                // { required: true, message: validation.yearsOfOperation.required },
                {
                  pattern: new RegExp(REGEX.onlyNumbers),
                  message: validation.yearsOfOperation.onlyNumbers,
                },
              ]}
            >
              <Input placeholder={validation.yearsOfOperation.placeholder} />
            </Form.Item>
          </Form.Item>*/}

          <Form.Item>
            {/* <Form.Item className={classNames.links}>
              <span>
                By clicking 'Next' , I agree that I have read and accepted the
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    window.open("https://www.otterz.co/terms-of-service");
                  }}
                >
                  KYC disclosure
                </Button>
                of Otterz
              </span>
            </Form.Item> */}

            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <CTAButton
                htmlType="submit"
                type="primary"
                loading={createBusiApplication.isLoading}
              >
                Next
              </CTAButton>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
