import { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Card, Button as AntButton, Form, Spin } from "antd";
import { RiEditLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

import EditInvoiceTemplate from "./EditInvoiceTemplate";
import classNames from "./TemplateSettings.module.scss";
import { Header, Title } from "../../../common";
import EditEstimateTemplate from "./EditEstimateTemplate";
import api from "../../../API";
import { ROUTES } from "../../../constants";
import usePAActiveStatus from "../../../../hooks/usePAActiveStatus";
import { AppContext } from "../../../../context/AppState";
import useInvoiceActiveStatus from "../../../../hooks/useInvoiceActiveStatus";
import useLanguage from "../../../../hooks/useLanguage";
import messages from "../../config/messages.const";

export default function TemplateSettings() {
  const [form] = Form.useForm();
  const location = useLocation();
  const language = useLanguage(messages);

  // fetching PA status to disable enable online payments widget
  // online payment widget will disable if the payment acceptance is not active (paStatus attribute is false)
  const { status: paStatus, paSettingsStatus } = usePAActiveStatus();
  const { application } = useContext(AppContext);
  const [locationState] = useState(location.state);
  const { icidStatus } = useInvoiceActiveStatus();
  const [
    estimateTemplatePDFDataIsLoading,
    setEstimateTemplatePDFDataIsLoading,
  ] = useState(false);
  const [invoiceTemplatePDFDataIsLoading, setInvoiceTemplatePDFDataIsLoading] =
    useState(false);

  const { isSuccess: getBankAccountDetailsQueryIsSuccess } = useQuery(
    "getBankAccountDetails",
    () => api.BusinessBanking.getAccount(application?.otz_account_id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: personData } = useQuery(
    "getPerson",
    () => api.BusinessBanking.getPerson(application?.otz_person_id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: businessData } = useQuery(
    "getBusiness",
    () => api.BusinessBanking.getBusiness(application?.otz_business_id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (currentUserData?.signInUserSession?.idToken?.payload?.icid) {
      setEstimateTemplatePDFDataIsLoading(true);
      setInvoiceTemplatePDFDataIsLoading(true);
    }
  }, [currentUserData?.signInUserSession?.idToken?.payload?.icid]);

  const { data: getCompanyQuery } = useQuery(
    "getCompany",
    () =>
      api.Receivables.Company.getCompany(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
      refetchOnWindowFocus: false,
    }
  );

  const { data: getInvoiceSettingsQuery } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
      refetchOnWindowFocus: false,
    }
  );

  const { data: getEstimateSettingsQuery } = useQuery(
    "getEstimateSettings",
    () =>
      api.Receivables.Settings.getEstimateTemplateSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
      refetchOnWindowFocus: false,
    }
  );

  const { data: getProfileSettingsQuery } = useQuery(
    "getProfileSettings",
    () =>
      api.Receivables.Settings.getProfileSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
      refetchOnWindowFocus: false,
    }
  );

  const { data: getCompanyEstimateTemplatePDFData, refetch: refetchEstimate } =
    useQuery(
      "getCompanyEstimateTemplatePDF",
      () =>
        api.Receivables.Settings.estimateTemplate(
          currentUserData?.signInUserSession?.idToken?.payload?.icid
        ),
      {
        onSuccess: () => setEstimateTemplatePDFDataIsLoading(false),
        onError: () => setEstimateTemplatePDFDataIsLoading(false),
        enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
        refetchOnWindowFocus: false,
      }
    );

  const { data: getCompanyInvoiceTemplatePDFData, refetch: refetchInvoice } =
    useQuery(
      "getCompanyInvoiceTemplatePDF",
      () =>
        api.Receivables.Settings.invoiceTemplate(
          currentUserData?.signInUserSession?.idToken?.payload?.icid
        ),
      {
        onSuccess: () => setInvoiceTemplatePDFDataIsLoading(false),
        onError: () => setInvoiceTemplatePDFDataIsLoading(false),
        enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
        refetchOnWindowFocus: false,
      }
    );

  const [
    editInvoiceTemplateModalVisibility,
    setEditInvoiceTemplateModalVisibility,
  ] = useState(false);

  const handleEditInvoiceTemplateModalCancelClick = (isCancel) => {
    if (!isCancel) {
      setInvoiceTemplatePDFDataIsLoading(true);
      refetchInvoice();
    }
    setEditInvoiceTemplateModalVisibility(false);
  };

  const [
    editEstimateTemplateModalVisibility,
    setEditEstimateTemplateModalVisibility,
  ] = useState(false);

  const handleEditEstimateTemplateModalCancelClick = (isCancel) => {
    if (!isCancel) {
      setEstimateTemplatePDFDataIsLoading(true);
      refetchEstimate();
    }

    setEditEstimateTemplateModalVisibility(false);
  };

  const navigate = useNavigate();
  const addressObj = getBankAccountDetailsQueryIsSuccess /* &&
  getBankAccountDetailsQuery.data &&
  getBankAccountDetailsQuery.data.data &&
  getBankAccountDetailsQuery.data.data.attributes &&
    getBankAccountDetailsQuery.data.data.attributes.tags &&
    getBankAccountDetailsQuery.data.data.attributes.tags.address */
    ? // getBankAccountDetailsQuery?.data?.data?.attributes?.tags?.address
      personData?.payload?.legal_address
    : {};

  const editInvoiceTemplateDataObj = Object.assign(
    {},
    getCompanyQuery?.data,
    getInvoiceSettingsQuery?.data,
    getProfileSettingsQuery?.data,
    {
      icidActive: icidStatus,
    },
    {
      tenantId: currentUserData?.signInUserSession?.idToken?.payload?.tenantId,
    },
    { taxNumber: getCompanyQuery?.data?.taxNumber },
    { companyName: getCompanyQuery?.data?.name }
  );

  if (!icidStatus) {
    editInvoiceTemplateDataObj.email =
      application?.type === "PERSON"
        ? personData?.payload?.email
        : businessData?.payload?.email;
    editInvoiceTemplateDataObj.street1 = addressObj?.address_line_1;
    editInvoiceTemplateDataObj.street2 = addressObj?.address_line_2;
    editInvoiceTemplateDataObj.city = addressObj?.city;
    editInvoiceTemplateDataObj.state = addressObj?.state;
    editInvoiceTemplateDataObj.zipcode = addressObj?.postal_code;
    editInvoiceTemplateDataObj.country = addressObj?.country_code;
    editInvoiceTemplateDataObj.companyName =
      application?.type === "PERSON"
        ? `${personData?.payload?.first_name} ${personData?.payload?.last_name}`
        : businessData?.payload?.entity_name;
    // getBankAccountDetailsQuery?.data?.data?.attributes?.tags?.companyName ||
    // getBankAccountDetailsQuery?.data?.data?.attributes?.name;
    editInvoiceTemplateDataObj.taxNumber =
      application?.type === "BUSINESS" ? businessData?.payload?.ein : "";
    // getBankAccountDetailsQuery?.data?.data?.attributes?.tags?.ein;
  }

  const editEstimateTemplateObj = Object.assign(
    {},
    getEstimateSettingsQuery?.data,
    { icid: currentUserData?.signInUserSession?.idToken?.payload?.icid }
  );

  const inactiveLabel = (
    <div className={classNames.inactiveLabel}>{language.INACTIVE_LABEL}</div>
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title="Template Settings"
          back
          onClick={() =>
            navigate(
              locationState?.from ||
                ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED
            )
          }
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          {estimateTemplatePDFDataIsLoading ||
          invoiceTemplatePDFDataIsLoading ? (
            <div className={classNames.spinWrapper}>
              <Spin size="large" />
            </div>
          ) : (
            <div className={classNames.body}>
              <Card className={classNames.invoiceCard}>
                <div className={classNames.invoiceHeader}>
                  <Title as="h3">Invoice Design</Title>
                  <AntButton
                    className={classNames.editBtn}
                    onClick={() => setEditInvoiceTemplateModalVisibility(true)}
                  >
                    <RiEditLine size={13} color="#7372FF" />
                    Edit template
                  </AntButton>
                </div>
                {!icidStatus ? (
                  inactiveLabel
                ) : (
                  <div className={classNames.invoiceContainer}>
                    <iframe
                      id="pdfIframeinvoice"
                      src={`data:application/pdf;base64,${getCompanyInvoiceTemplatePDFData?.data?.pdf}`}
                      // src={`${invoice}#zoom=${100}&view=Fit&toolbar=0&scrollbar=0&statusbar=0&navpanes=0`}
                      title="invoice"
                      frameBorder="0"
                      className={classNames.invoice}
                    />
                  </div>
                )}
                {/* <div className={classNames.invoiceOptions}>
                <AntButton className={classNames.zoomBtn}>
                <RiSearch2Line size={13} color="#fff" />
                Zoom
                </AntButton>
              </div> */}
              </Card>
              <Card className={classNames.invoiceCard}>
                <div className={classNames.invoiceHeader}>
                  <Title as="h3">Estimate Design</Title>
                  <AntButton
                    className={classNames.editBtn}
                    onClick={() => setEditEstimateTemplateModalVisibility(true)}
                  >
                    <RiEditLine size={13} color="#7372FF" />
                    Edit template
                  </AntButton>
                </div>
                {!icidStatus ? (
                  inactiveLabel
                ) : (
                  <div className={classNames.invoiceContainer}>
                    <iframe
                      id="pdfIframeEstimate"
                      src={`data:application/pdf;base64,${getCompanyEstimateTemplatePDFData?.data?.pdf}`}
                      // src={`${invoice}#zoom=${100}&view=Fit&toolbar=0&scrollbar=0&statusbar=0&navpanes=0`}
                      title="invoice"
                      frameBorder="0"
                      className={classNames.invoice}
                    />
                  </div>
                )}
                {/* <div className={classNames.invoiceOptions}>
                <AntButton className={classNames.zoomBtn}>
                <RiSearch2Line size={13} color="#fff" />
                Zoom
                </AntButton>
              </div> */}
              </Card>
            </div>
          )}
        </div>
      </div>
      <EditInvoiceTemplate
        visible={editInvoiceTemplateModalVisibility}
        onCancel={handleEditInvoiceTemplateModalCancelClick}
        form={form}
        dataObj={editInvoiceTemplateDataObj}
        paStatus={paStatus}
        paSettingsStatus={paSettingsStatus}
      />
      <EditEstimateTemplate
        visible={editEstimateTemplateModalVisibility}
        onCancel={handleEditEstimateTemplateModalCancelClick}
        dataObj={editEstimateTemplateObj}
      />
    </div>
  );
}
