import { Message } from "../../common";
import { utilFileReader } from "../../util/functions/utilFileReader";

export function uploadProfilePicture(file, callback) {
  try {
    utilFileReader(file).then((data) => {
      callback(data);
    });
  } catch (error) {
    console.log(error);
    Message({ type: "error", content: error.toString() });
  }
}
