import { useMemo, memo } from "react";
import { AutoComplete, Input } from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { RiSearch2Line } from "react-icons/ri";

function Search({ panTo, isLoading, classNames, onSearch }) {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const searchResult = useMemo(() => {
    return status === "OK"
      ? data.map(({ id, description }) => ({
          value: description,
          label: description,
        }))
      : [];
  }, [status, data]);

  const handleSelect = async (address) => {
    setValue(address);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const coords = await getLatLng(results[0]);

      panTo(coords);
      onSearch(coords);
    } catch (error) {
      console.log(" Error: ", error);
    }
  };

  return (
    <AutoComplete
      disabled={!ready || isLoading}
      className={classNames.searchATM}
      onSearch={(e) => setValue(e)}
      onSelect={handleSelect}
      options={searchResult}
    >
      <Input
        className={classNames.searchInput}
        prefix={<RiSearch2Line />}
        placeholder="Search ATM through pin code or area"
        allowClear
      />
    </AutoComplete>
  );
}

export default memo(Search);
