import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { Button } from "antd";
import { commonMessage } from "../config";
import { ROUTES } from "../../../../constants";
import { useNavigate } from "react-router-dom";

const PendingApproval = ({ name, validKey }) => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      {validKey !== "isOnBoard" && (
        <div className={classNames.closeIcon}>
          <RiCloseCircleFill
            size={24}
            className={classNames.inputGroupRemoveIcon}
            onClick={() => {
              navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE);
            }}
          />
        </div>
      )}
      <div className={classNames.success}>
        <RiCheckboxCircleFill
          size={90}
          color="#1DBC83"
          style={{ marginBottom: 34 }}
        />
        <Title as="h3">
          {commonMessage.success.title + " "}!
          {/* {`${name}`}! */}
        </Title>
        <div className={classNames.extra}>
          {commonMessage?.callBackText[validKey]?.pendingSentence}
          <Button
            type="link"
            className={classNames.linkButton}
            href={commonMessage.pendingApproval.link}
            rel="noreferrer"
            target="_blank"
          >
            Customer Support.
          </Button>
          &nbsp;for additional information.
        </div>
      </div>
    </>
  );
};

export default PendingApproval;
