import { API } from "aws-amplify";

export default async function editInvoiceTemplateSetting(data) {
  if (data?.id) {
    return await API.put("invoices", "estimate-settings", {
      queryStringParameters: {
        companyId: data.id,
      },
      body: { ...data.data },
    });
  }
}
