import carouselImg from "../../../assets/auth/undraw_revenue_re_2bmg 1.png";

const slides = [
  {
    id: "1",
    imgSrc: carouselImg,
    title: "Your world of financial possibilities",
    description: "Get all your financial needs in one place. Look no further",
  },
  // {
  //   id: "2",
  //   imgSrc: carouselImg,
  //   title: "Your world of financial possibilities",
  //   description: "Get all your financial needs in one place. Look no further",
  // },
  // {
  //   id: "3",
  //   imgSrc: carouselImg,
  //   title: "Your world of financial possibilities",
  //   description: "Get all your financial needs in one place. Look no further",
  // },
  // {
  //   id: "4",
  //   imgSrc: carouselImg,
  //   title: "Your world of financial possibilities",
  //   description: "Get all your financial needs in one place. Look no further",
  // },
];

export default slides;
