import { Auth } from 'aws-amplify';
import endpoints from "../../../endpoints";

export default async function rejectAPInvoice(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    return await(
        await fetch(endpoints.PAYABLES.ACCOUNTS_PAYABLES.INVOICES[env] + "reject/" + data.invoice_id, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        })
    ).json();
}
