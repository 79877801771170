import { Auth } from "aws-amplify";
import mixpanel from "mixpanel-browser";

export default async function verifyEmail({ email, code }) {
  const response = await Auth.confirmSignUp(email, code);
  // put code to verify email here

  try {
    mixpanel.identify(email);
    mixpanel.people.set({
      '$email': email,
    });
  
    if(response === 'SUCCESS') {
      mixpanel.track('User Email verification', {
        'Email ID': email,
        'Sign Up Date': new Date().toISOString(),
        'Email verification status': "Successful"
      });
    } else {
      mixpanel.track('User Email verification', {
        'Email ID': email,
        'Sign Up Date': new Date().toISOString(),
        'Email verification status': "Failure",
      });
    }
  } catch (error) {
    console.error("error: ", error)
  }

  return response
}
