import { useEffect, useState } from "react";
import { Card, Button as AntButton, Table, Space, Radio } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import moment from "moment-timezone";

import { Title } from "../../../../common";
import api from "../../../../API";
import {
  last30DaysFilterProps,
  transactionStates,
} from "../../../../API/PaymentAcceptance/Transactions/listTransaction.api";

import { cardTypeLogos } from "../../../common/cardTypeLogos";
import { FORMATS } from "../../../../constants";
import dateUtils from "../../../../util/dateUtils";
import classNames from "../PaymentAcceptanceOverview.module.scss";

export default function RecentTransactions() {
  const navigate = useNavigate();

  const [resentTransactionState, setResentTransactionState] = useState(
    transactionStates?.all?.state
  );

  const cardArray = ["JCB", "Diners"];

  const {
    mutate: fetchRecentTransaction,
    data: recentTransactionsRes,
    isLoading: isRecentTransactionsLoading,
  } = useMutation(async () => {
    let maxRecordCount = 10;
    const otherFilters = {};

    const response = await api.Receivables.PaymentAcceptance.listTransactions(
      resentTransactionState,
      maxRecordCount,
      last30DaysFilterProps.xBeginDate,
      last30DaysFilterProps.xEndDate,
      otherFilters
    );

    return {
      ...response,
      data: { ...response.data },
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "xEnteredDate",
      key: "xEnteredDate",
      render: (xEnteredDate) => {
        const newFormattedDate = moment(
          xEnteredDate,
          FORMATS.DATE_TIME_A_FORMAT
        ).format(FORMATS.DATE_TIME_FORMAT);

        const date = dateUtils.tzToLocal(newFormattedDate, "EST");
        return date;
      },
    },
    {
      title: "Amount",
      dataIndex: "xAmount",
      key: "xAmount",
      render: (amount) =>
        Number(amount === undefined ? null : amount).toLocaleString("en-US", {
          // NOTE: Number(null) equals to 0. Add this to avoid NaN from undefined
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Payment Medium",
      dataIndex: "xMaskedAccountNumber",
      key: "xMaskedAccountNumber",
      render: (paymentMedium, record) => (
        <div className={classNames.paymentMedium}>
          <div
            className={
              cardArray?.includes(record?.xCardType) ? classNames.cardImage : ""
            }
          >
            {cardTypeLogos[record.xCardType]?.image}
          </div>
          <span>{paymentMedium}</span>
        </div>
      ),
    },
  ];

  const handleStateChange = (event) => {
    setResentTransactionState(transactionStates[event?.target?.value]?.state);
  };

  useEffect(() => {
    fetchRecentTransaction();
  }, [fetchRecentTransaction, resentTransactionState]);

  return (
    <Card className={classNames.tableCard}>
      <div className={classNames.tableCardHeader}>
        <Title as="h3">Recent Transactions</Title>
        <AntButton
          type="link"
          onClick={() => navigate("/app/receivables/transactions")}
        >
          View all
        </AntButton>
      </div>
      <div className={classNames.tableCardFilters}>
        <Radio.Group
          defaultValue="all"
          buttonStyle="solid"
          onChange={handleStateChange}
        >
          <Space size={0}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="approved">Approved</Radio.Button>
            <Radio.Button value="declined">Declined</Radio.Button>
          </Space>
        </Radio.Group>
      </div>
      <Table
        loading={isRecentTransactionsLoading}
        pagination={false}
        dataSource={
          recentTransactionsRes?.data?.xReportData
            ?.sort((a, b) =>
              moment(a?.xEnteredDate, FORMATS.DATE_TIME_FORMAT).diff(
                moment(b?.xEnteredDate, FORMATS.DATE_TIME_FORMAT)
              )
            )
            ?.reverse() || []
        }
        columns={columns}
        className={classNames.table}
      />
    </Card>
  );
}
