import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Form, Button, Spin } from "antd";
import moment from "moment";

import api from "../../../../API";
import classNames from "../Onboarding.module.scss";
import { usCityList, usStateList, countryList } from "../../../../util";
import { Title, Input, Select, DatePicker, Message } from "../../../../common";
// import { useLocalStorageData } from "../../../../../hooks/useLocalStorage";
import { AppContext } from "../../../../../context/AppState";

const Application = ({ nextStep }) => {
  const [form] = Form.useForm();

  const [agreementError, setAgreementError] = useState("");
  const [processing, setProcessing] = useState(false);

  /*  const { email, phone_number } = useLocalStorageData("OTTERZ.userData") || {
    email: "",
    phone_number: "",
  }; */

  const { userData } = useContext(AppContext);
  const { email, phone_number } = userData || {
    email: "",
    phone_number: "",
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    ckCustomerAgreement.enableAgreement({
      iframeField: "agreement",
      xKey: "ifields_otterzdev13d987bca2844ece811593be08d1",
      autoAgree: false,
    });
  }, []);

  function goAppOnboard(data) {
    return api.Receivables.PaymentAcceptOnboard.onboardUser({
      ...data,
      userEmail: email,
      userPhone: phone_number,
    });
  }

  const goAppOnboardMn = useMutation((event) => goAppOnboard(event));

  const onFinish = async (data) => {
    setProcessing(true);
    try {
      // eslint-disable-next-line no-undef
      const agreementResponse = await ckCustomerAgreement.getToken();
      if (!agreementResponse) {
        setProcessing(false);
        setAgreementError("Agreement failed; No response returned.");
        return;
      }
      if (agreementResponse.status !== 100) {
        setProcessing(false);
        setAgreementError(
          `Agreement failed; Error on response. (${
            agreementResponse.statusText || "No Error description available"
          })`
        );
        return;
      }
      if (!agreementResponse.token) {
        setProcessing(false);
        setAgreementError("Agreement failed; Failed to load agreement token.");
        return;
      }
      setAgreementError("");
      goAppOnboardMn.mutate(
        { ...data, signature: { token: agreementResponse.token } },
        {
          onSuccess: (data, variables, context) => {
            setProcessing(false);
            Message({ type: "success", content: "Onboard success!" });
            form.resetFields();
            nextStep();
          },
          onError: (error, variables, context) => {
            let errorList = [];
            errorList =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.error &&
              error.response.data.error.message &&
              JSON.parse(error.response.data.error.message);
            if (Array.isArray(errorList) && errorList.length > 0) {
              errorList.forEach((err, index) => {
                setProcessing(false);
                Message({ type: "error", content: err });
              });
            } else {
              setProcessing(false);
              Message({ type: "error", content: error.toString() });
            }
          },
        }
      );
    } catch (error) {
      setAgreementError(`Agreement failed; ${error?.statusText}`);
      setProcessing(false);
    }
  };

  return (
    <Spin spinning={processing}>
      <div className={classNames.formWrapper}>
        <Title as="h3">Fill the form please</Title>
        <div className={classNames.subheading}>
          Please fill the following form, one of our agents will be in touch
          with you as soon as possible
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          hideRequiredMark
          autoComplete="off"
        >
          <Form.Item
            name={"corporateName"}
            label={
              <span className={classNames.label} name={"corporateName"}>
                Corporate Name <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Corporate name is required" },
              { max: 225, message: "Max 225 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name={"dbaName"}
            label={
              <span className={classNames.label} name={"dbaName"}>
                DBA Name <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "DBA name is required" },
              { max: 225, message: "Max 225 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  Annual Credit Card Sales
                </span>
              }
              name={"annualCreditCardSales"}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  pattern: new RegExp(/^\d{0,8}(\.\d{2})?$/),
                  message: "A valid amount is required",
                },
              ]}
            >
              <Input
                placeholder="Enter amount"
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Form.Item
              name={"ownershipType"}
              label={
                <span className={classNames.label}>
                  Ownership Type <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: "Ownership type is required" },
              ]}
            >
              <Select
                placeholder="Please select"
                showSearch
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
              >
                <Select.Option value="SoleProprietor">
                  Sole Proprietor
                </Select.Option>
                <Select.Option value="Partnership">Partnership</Select.Option>
                <Select.Option value="Corporation">Corporation</Select.Option>
                <Select.Option value="SoleOwnership">
                  Sole Ownership
                </Select.Option>
                <Select.Option value="LLC">LLC</Select.Option>
                <Select.Option value="NonProfit">Non Profit</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={"businessStartDate"}
              label={
                <span className={classNames.label}>
                  Business Start Date <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                { required: true, message: "Business start date is required" },
              ]}
            >
              <DatePicker placeholder="MMM DD, YYYY" />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name={"agentName"}
            label={<span className={classNames.label}>Agent Name </span>}
            rules={[
              {
                max: 225,
                message: "Max 225 characters",
              },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={"agentEmail"}
            label={
              <span className={classNames.label}>Agent Email Address</span>
            }
            rules={[
              { type: "email", message: "Enter a valid email" },
              { max: 225, message: "Max 225 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={"website"}
            label={<span className={classNames.label}>Website</span>}
            rules={[
              {
                pattern: new RegExp(
                  /^(http:\/\/|https:\/\/)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[‌​a-z]{3}\.([a-z]+)?$/
                ),
                message: "Enter valid website",
              },
              { max: 150, message: "Max 150 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={"taxId"}
            label={
              <span className={classNames.label}>
                TAX ID <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Tax id is required" },
              {
                pattern: new RegExp(/^[0-9]{9}$/),
                message: "Enter a valid tax id",
              },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={"businessEmail"}
            rules={[
              { type: "email", message: "Enter a valid email" },
              { max: 225, message: "Max 225 characters" },
            ]}
            label={
              <span className={classNames.label}>Business Email Address</span>
            }
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name="businessPhone"
            label={
              <span className={classNames.label}>
                Business Phone Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Phone number is required" },
              {
                pattern: new RegExp(/^[0-9]{10}$/),
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input
              placeholder="Please enter"
              prefix={
                "+1"
                // <Form.Item
                //   name="countryCode"
                //   rules={[
                //     { required: true, message: "Country code is required" },
                //   ]}
                //   noStyle
                // >
                //   <Select
                //     placeholder="+1"
                //     className={classNames.mobileInputPrefix}
                //   >
                //     <Select.Option value="+1">+1</Select.Option>
                //   </Select>
                // </Form.Item>
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>
                Business Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Form.Item
              name={["businessAddress", "streetAddress"]}
              rules={[
                { required: true, message: "Street is required" },
                { max: 50, message: "Max 50 characters" },
              ]}
            >
              <Input placeholder="Street *" />
            </Form.Item>

            <Form.Item
              name={["businessAddress", "city"]}
              rules={[
                { required: true, message: "City is required" },
                { max: 264, message: "Max 264 characters" },
              ]}
            >
              <Input placeholder="City *" />
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name={["businessAddress", "state"]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  { required: true, message: "State is required" },
                  { max: 264, message: "Max 264 characters" },
                ]}
              >
                <Input placeholder="State *" />
              </Form.Item>

              <Form.Item
                name={["businessAddress", "zip"]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: "Postal code is required" },
                  {
                    pattern: new RegExp(/^[0-9]{5}$/),
                    message: "Enter valid postal code",
                  },
                ]}
              >
                <Input placeholder="Postal code *" style={{ width: "100%" }} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={["businessAddress", "country"]}
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country *"
              >
                {countryList().map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>
                Mailing Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Form.Item
              name={["mailingAddress", "streetAddress"]}
              rules={[
                { required: true, message: "Please enter street address" },
                { max: 50, message: "Maximum characters limit 50" },
              ]}
            >
              <Input placeholder="Street *" />
            </Form.Item>
            <Form.Item
              name={["mailingAddress", "city"]}
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="City"
              >
                {usCityList().map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name={["mailingAddress", "state"]}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[{ required: true, message: "Please enter state" }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State"
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={["mailingAddress", "zip"]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: "Please enter valid postal code" },
                  {
                    pattern: new RegExp(/^[0-9]{5}$/),
                    message: "Only numbers allowed and count should be 5",
                  },
                ]}
              >
                <Input placeholder="Postal code *" style={{ width: "100%" }} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={["mailingAddress", "country"]}
              rules={[{ required: true, message: "Please enter country" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country"
              >
                {countryList().map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name={"productSold"}
            label={
              <span className={classNames.label}>
                Products Sold <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Products sold is required" },
              { max: 8, message: "Max 8 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>Banking Information</span>
            }
          >
            <Form.Item
              name={["bankingInformation", "bankName"]}
              rules={[
                { required: true, message: "Banking name is required" },
                { max: 50, message: "Max 50 characters" },
              ]}
            >
              <Input placeholder="Bank Name *" />
            </Form.Item>

            <Form.Item
              name={["bankingInformation", "routingNumber"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: "Routing number is required" },

                {
                  pattern: new RegExp(/^[0-9]{9}$/),
                  message: "Enter valid postal code",
                },
              ]}
            >
              <Input placeholder="Routing Number *" />
            </Form.Item>

            <Form.Item
              name={["bankingInformation", "accountNumber"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                { required: true, message: "Account number is required" },
                {
                  pattern: new RegExp(/^[0-9]*$/),
                  message: "Enter valid account number",
                },
                {
                  max: 28,
                  message: "Max 28 characters",
                },
              ]}
            >
              <Input placeholder="Account Number *" />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>
                Signer Information <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Form.Item
              name={["signerInformationList", "ssn"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: "SSN is required" },
                {
                  pattern: new RegExp("^\\d{9}$"),
                  message: "Must be 9 numbers without hyphens (-)",
                },
              ]}
            >
              <Input placeholder="SSN *" />
            </Form.Item>

            <Form.Item
              name={["signerInformationList", "dateOfBirth"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[{ required: true, message: "Date of birth is required" }]}
            >
              <DatePicker
                placeholder="MMM DD, YYYY"
                disabledDate={(date) =>
                  !date || date.isAfter(moment().subtract(18, "years"))
                }
                defaultPickerValue={moment().subtract(18, "years")}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Form.Item
              name={["signerInformationList", "firstName"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: "First name is required" },
                { max: 225, message: "Max 225 characters" },
              ]}
            >
              <Input placeholder="First Name *" />
            </Form.Item>

            <Form.Item
              name={["signerInformationList", "lastName"]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                { required: true, message: "Last name is required" },
                { max: 225, message: "Max 225 characters" },
              ]}
            >
              <Input placeholder="Last Name *" />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>
                Signer Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Form.Item
              name={["signerInformationList", "address", "streetAddress"]}
              rules={[
                { required: true, message: "Street is required" },
                { max: 50, message: "Max 50 characters" },
              ]}
            >
              <Input placeholder="Street *" />
            </Form.Item>
            {/* <Form.Item
            name="street2"
            rules={[
              { required: true, message: "Please enter street address" },
              { max: 50, message: "Maximum characters limit 50" },
            ]}
          >
            <Input placeholder="Street 2" />
          </Form.Item> */}
            <Form.Item
              name={["signerInformationList", "address", "city"]}
              rules={[{ required: true, message: "City is required" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="City *"
              >
                {usCityList().map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name={["signerInformationList", "address", "state"]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[{ required: true, message: "State is required" }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State *"
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={["signerInformationList", "address", "zip"]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: "Postal code is required" },
                  {
                    pattern: new RegExp(/^[0-9]{5}$/),
                    message: "Only numbers allowed and count should be 5",
                  },
                ]}
              >
                <Input placeholder="Postal code *" style={{ width: "100%" }} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={["signerInformationList", "address", "country"]}
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country *"
              >
                {countryList().map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name={["signerInformationList", "ownershipPercentage"]}
            label={
              <span className={classNames.label}>
                Signer Ownership percentage <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Signer ownership percentage is required",
              },
              {
                pattern: new RegExp(/^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/),
                message: "Enter a valid percentage",
              },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={["signerInformationList", "title"]}
            label={
              <span className={classNames.label}>
                Signer Title <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Signer title is required" },
              { max: 25, message: "Max 25 characters" },
            ]}
          >
            <Input placeholder="Please enter" />
          </Form.Item>

          <Form.Item
            name={["signerInformationList", "ownerCellPhone"]}
            label={
              <span className={classNames.label}>
                Owner Phone Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Phone number is required" },
              {
                pattern: new RegExp(/^[0-9]{10}$/),
                message: "Enter a valid number",
              },
            ]}
          >
            <Input
              placeholder="Please enter"
              prefix={
                "+1"
                // <Form.Item
                //   name={["signerInformationList", "ownerCountryCode"]}
                //   rules={[
                //     { required: true, message: "Country code is required" },
                //   ]}
                //   noStyle
                // >
                //   <Select
                //     placeholder="+1"
                //     className={classNames.mobileInputPrefix}
                //   >
                //     =<Select.Option value="+1">+1</Select.Option>
                //   </Select>
                // </Form.Item>
              }
            />
          </Form.Item>

          <iframe
            title="User concent for Cardknox agreement"
            id="agreement"
            class="agreement"
            data-ifields-id="agreement"
            src="https://cdn.cardknox.com/ifields/2.12.2201.2101/agreement.htm"
            style={{
              border: "none",
              width: "100%",
              height: 630,
              overflowY: "hidden",
            }}
          ></iframe>

          {agreementError && (
            <span style={{ fontSize: 12, color: "red" }}>{agreementError}</span>
          )}

          <div className={classNames.footerButtonWrapper}>
            <Button
              type="primary"
              className={classNames.nextButton}
              htmlType="submit"
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default Application;
