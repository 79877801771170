import { useState, useContext, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Modal, CTAButton, Title, Message, Select } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import api from "../../../API";
import { AppContext } from "../../../../context/AppState";
import classNames from "../ManageCards.module.scss";

const Body = ({
  onCancel,
  currentCard,
  setSelectedReason,
  selectedReason,
  isError,
  setIsError,
}) => {
  const queryClient = useQueryClient();

  const [cardReason, setCardReason] = useState([]);

  const { application } = useContext(AppContext);

  const replaceCardFns = (event) => {
    return api.BusinessBanking.createNewCard({
      form: currentCard?.form,
      otz_account_id: application?.otz_account_id,
      otz_customer_id:
        application?.type === "PERSON"
          ? application?.otz_person_id
          : currentCard?.otz_customer_id,
      reissue_reason: selectedReason,
      otz_reissued_from_id: currentCard?.otz_card_id,
      ...(Boolean(currentCard?.form === "PHYSICAL") && {
        ...currentCard.shipping.address,
        recipient_first_name: currentCard?.shipping?.recipient_name?.first_name,
        recipient_last_name: currentCard?.shipping?.recipient_name?.last_name,
      }),
      emboss_name: { line_1: currentCard?.emboss_name?.line_1 },
    });
  };

  const replaceCardMutation = useMutation((event) => replaceCardFns(event));

  const replaceCardOnClick = (data) => {
    if (selectedReason) {
      replaceCardMutation.mutate(data, {
        onSuccess: (data, variables, context) => {
          onCancel();
          queryClient.invalidateQueries("listCards");
          Message({
            type: "success",
            content: "Request for replacement successful!",
          });
        },
        onError: (error) => {
          generateErrorMessage(error);
          onCancel();
        },
      });
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentCard?.form === "VIRTUAL" && currentCard?.isExpReq) {
      setCardReason([
        {
          label: "Expired",
          value: "EXPIRATION",
        },
      ]);
    } else if (currentCard?.form === "PHYSICAL" && currentCard?.isExpReq) {
      const reason = [
        {
          label: "Expired",
          value: "EXPIRATION",
        },
        {
          label: "Lost",
          value: "LOST",
        },
        { label: "Report Stolen", value: "STOLEN" },
      ];
      currentCard?.card_status === "ACTIVE" &&
        reason.push({
          label: "Damaged",
          value: "DAMAGED",
        });
      setCardReason(reason);
    } else if (currentCard?.form === "PHYSICAL" && !currentCard?.isExpReq) {
      const reason = [
        {
          label: "Lost",
          value: "LOST",
        },
        { label: "Report Stolen", value: "STOLEN" },
      ];
      currentCard?.card_status === "ACTIVE" &&
        reason.push({
          label: "Damaged",
          value: "DAMAGED",
        });
      setCardReason(reason);
    }
  }, [currentCard]);

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Replace card?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to replace this card with a new one?
      </div>

      {cardReason?.length > 0 ? (
        <>
          <Select
            placeholder="Please select reason"
            name="selectedReason"
            value={selectedReason}
            onChange={(event) => {
              setSelectedReason(event);
              setIsError(false);
            }}
            style={{ width: "100%" }}
          >
            {cardReason.map((value) => (
              <Select.Option key={value.value} value={value.value}>
                {value.label}
              </Select.Option>
            ))}
          </Select>
          {isError && (
            <span
              style={{
                display: "block",
                width: "100%",
                textAlign: "left",
                color: "#ff4d4f",
                fontSize: 14,
              }}
            >
              Please select reason
            </span>
          )}
        </>
      ) : null}
      <div className={classNames.confirmModalCTA} style={{ marginTop: 25 }}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
          disabled={replaceCardMutation.isLoading}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
            marginLeft: "8px",
          }}
          loading={replaceCardMutation.isLoading}
          disabled={replaceCardMutation.isLoading}
          onClick={() => replaceCardOnClick(currentCard?.otz_card_id)}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};
export default function ReplaceCard({ visible, onCancel, currentCard }) {
  const [selectedReason, setSelectedReason] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setSelectedReason(null);
    setIsError(false);
  }, [visible]);

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Body
        onCancel={onCancel}
        currentCard={currentCard}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        setIsError={setIsError}
        isError={isError}
      />
    </Modal>
  );
}
