import { MAX_MIN } from "../../util/constants";

const messages = {
  addPayee: {
    name: "Payee name is required",
    nameMax: `Max ${MAX_MIN.businessName.max} characters`,
    payeePlaceholder: "Please enter",
    accountMax: `Max ${MAX_MIN.accountNumber.max} characters`,
    accountMin: `Min ${MAX_MIN.accountNumber.min} characters`,
    accountNumber: "Account number is required",
    accountNumberValid: "Enter a valid account number",
    bank: "Bank is required",
    bankMax: `Max ${MAX_MIN.bank.max} characters`,
    validBankName: "Enter a valid bank name",
    branchCode: "Branch code is required",
    routingNumber: "Routing number is required.",
    routingNumberValid: "Enter a valid routing number",
    accountType: "Account type is required",
    validPayeeName: "Enter a valid payee name",
    bankNamePlaceholder: "Please enter bank name",
    dropdown: "Please select",
    email: "Please enter email"
  },
  quickFundTransfer: {
    payee: "Payee is required",
    amount: "Amount is required",
    validAmount: "Enter a valid amount",
    description: "Description is required",
    descriptionMax: `Max ${MAX_MIN.description.max} characters`,
    placeHolder: "Please enter",
    insufficientFund: "Insufficient funds",
    dropdown: "Please select",
    payeePlaceholder: "Please enter",
    amountPlaceholder: "Enter amount",
    descriptionPlaceholder: "Maximum 100 characters",
    interval: "Interval is required",
    date: "Date is required",
    startDate: "Payment date is required",
    scheduleDate: "Start date is required",
    endDate: "End date is required",
    period: "Period is required",
  },
};

export const validationCards = {
  en: {
    name: {
      required: "First name is required",
      valid: "Enter a valid first name",
      max: "Max 35 characters",
    },
    lastName: {
      required: "Last name is required",
      valid: "Enter a valid last name",
      max: "Max 35 characters",
    },
    dateOfBirth: {
      required: "Date of birth is required",
      valid: "Enter a valid last name",
    },
    phoneNumber: {
      required: "Phone number is required",
      valid: "Enter a valid phone number",
    },
    email: {
      required: "Email is required",
      valid: "Please enter a valid Email",
      placeholder: "Email address",
    },
    emboss_name: {
      required: "Name on Card is required",
      max: "Maximum 21 characters",
    },
    person: {
      required: "Person is required",
      placeholder: "Please select person",
    },
  },
};

export default messages;
