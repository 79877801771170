const industryList = () => {
  return [
    { value: 'banking/finance', label: 'Banking/Finance' },
    { value: 'law/lawEnforcement', label: 'Law/Law Enforcement' },
    { value: 'sales', label: 'Sales' },
    { value: 'warehouse/shipping', label: 'Warehouse/Shipping' },
    { value: 'agriculture/farming/lawnServices', label: 'Agriculture/Lawn Services' },
    { value: 'restaurantEmployee', label: 'Restaurant Employee' },
    { value: 'military', label: 'Military' },
    { value: 'computer/it/technology', label: 'Computer/IT/Technology' },
    { value: 'teacher/education', label: 'Teacher/Education' },
    { value: 'clerical', label: 'Clerical' },
    { value: 'medicalField', label: 'Medical Field' },
    { value: 'sports/entertainment', label: 'Sports/Entertainment' },
    { value: 'generalLaborer', label: 'General Laborer' },
    { value: 'other', label: 'Other' },
  ];
};

const businessIsFollowing = () => {
  return [
    { value: 'None', label: 'None' },
    { value: 'NonGovernmentOrganizationorNonProfitCharity', label: 'Non - Government Organization or Non-Profit Charity' },
    { value: 'ThirdPartyProcessor', label: 'Third-Party Processor' },
    { value: 'Registered/licensedMoneyServiceBusiness', label: 'Registered/licensed Money Service Business' },
    { value: 'HempRelated', label: 'Marijuana Related Business or Industrial Hemp/Hemp Derived CBD Business' },
    { value: 'ATMNearYourPremise', label: 'Is there an ATM located on your premises?' },
  ];
};

export const industries = () => {
  return [{
    label: "Accommodation",
    value: "Accommodation"
  }, {
    label: "Administration of Economic Programs",
    value: "Administration of Economic Programs"
  }, {
    label: "Administration of Environmental Quality Programs",
    value: "Administration of Environmental Quality Programs"
  }, {
    label: "Administration of Housing Programs, Urban Planning, and Community Development",
    value: "Administration of Housing Programs, Urban Planning, and Community Development"
  }, {
    label: "Administration of Human Resource Programs",
    value: "Administration of Human Resource Programs"
  }, {
    label: "Administrative and Support Services",
    value: "Administrative and Support Services"
  }, {
    label: "Air Transportation",
    value: "Air Transportation"
  }, {
    label: "Ambulatory Health Care Services",
    value: "Ambulatory Health Care Services"
  }, {
    label: "Amusement, Gambling, and Recreation Industries",
    value: "Amusement, Gambling, and Recreation Industries"
  }, {
    label: "Animal Production and Aquaculture",
    value: "Animal Production and Aquaculture"
  }, {
    label: "Apparel Manufacturing",
    value: "Apparel Manufacturing"
  }, {
    label: "Beverage and Tobacco Product Manufacturing",
    value: "Beverage and Tobacco Product Manufacturing"
  }, {
    label: "Broadcasting and Content Providers",
    value: "Broadcasting and Content Providers"
  }, {
    label: "Building Material and Garden Equipment and Supplies Dealers",
    value: "Building Material and Garden Equipment and Supplies Dealers"
  }, {
    label: "Chemical Manufacturing",
    value: "Chemical Manufacturing"
  }, {
    label: "Clothing, Clothing Accessories, Shoe, and Jewelry Retailers",
    value: "Clothing, Clothing Accessories, Shoe, and Jewelry Retailers"
  }, {
    label: "Computer and Electronic Product Manufacturing",
    value: "Computer and Electronic Product Manufacturing"
  }, {
    label: "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
    value: "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services"
  }, {
    label: "Construction of Buildings",
    value: "Construction of Buildings"
  }, {
    label: "Couriers and Messengers",
    value: "Couriers and Messengers"
  }, {
    label: "Credit Intermediation and Related Activities",
    value: "Credit Intermediation and Related Activities"
  }, {
    label: "Crop Production",
    value: "Crop Production"
  }, {
    label: "Educational Services",
    value: "Educational Services"
  }, {
    label: "Electrical Equipment, Appliance, and Component Manufacturing",
    value: "Electrical Equipment, Appliance, and Component Manufacturing"
  }, {
    label: "Executive, Legislative, and Other General Government Support",
    value: "Executive, Legislative, and Other General Government Support"
  }, {
    label: "Fabricated Metal Product Manufacturing",
    value: "Fabricated Metal Product Manufacturing"
  }, {
    label: "Fishing, Hunting and Trapping",
    value: "Fishing, Hunting and Trapping"
  }, {
    label: "Food and Beverage Retailers",
    value: "Food and Beverage Retailers"
  }, {
    label: "Food Manufacturing",
    value: "Food Manufacturing"
  }, {
    label: "Food Services and Drinking Places",
    value: "Food Services and Drinking Places"
  }, {
    label: "Forestry and Logging",
    value: "Forestry and Logging"
  }, {
    label: "Funds, Trusts, and Other Financial Vehicles",
    value: "Funds, Trusts, and Other Financial Vehicles"
  }, {
    label: "Furniture and Related Product Manufacturing",
    value: "Furniture and Related Product Manufacturing"
  }, {
    label: "Furniture, Home Furnishings, Electronics, and Appliance Retailers",
    value: "Furniture, Home Furnishings, Electronics, and Appliance Retailers"
  }, {
    label: "Gasoline Stations and Fuel Dealers",
    value: "Gasoline Stations and Fuel Dealers"
  }, {
    label: "General Merchandise Retailers",
    value: "General Merchandise Retailers"
  }, {
    label: "Health and Personal Care Retailers",
    value: "Health and Personal Care Retailers"
  }, {
    label: "Heavy and Civil Engineering Construction",
    value: "Heavy and Civil Engineering Construction"
  }, {
    label: "Hospitals",
    value: "Hospitals"
  }, {
    label: "Insurance Carriers and Related Activities",
    value: "Insurance Carriers and Related Activities"
  }, {
    label: "Justice, Public Order, and Safety Activities",
    value: "Justice, Public Order, and Safety Activities"
  }, {
    label: "Leather and Allied Product Manufacturing",
    value: "Leather and Allied Product Manufacturing"
  }, {
    label: "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    value: "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  }, {
    label: "Machinery Manufacturing",
    value: "Machinery Manufacturing"
  }, {
    label: "Management of Companies and Enterprises",
    value: "Management of Companies and Enterprises"
  }, {
    label: "Merchant Wholesalers, Durable Goods",
    value: "Merchant Wholesalers, Durable Goods"
  }, {
    label: "Merchant Wholesalers, Nondurable Goods",
    value: "Merchant Wholesalers, Nondurable Goods"
  }, {
    label: "Mining (except Oil and Gas)",
    value: "Mining (except Oil and Gas)"
  }, {
    label: "Miscellaneous Manufacturing",
    value: "Miscellaneous Manufacturing"
  }, {
    label: "Monetary Authorities-Central Bank",
    value: "Monetary Authorities-Central Bank"
  }, {
    label: "Motion Picture and Sound Recording Industries",
    value: "Motion Picture and Sound Recording Industries"
  }, {
    label: "Motor Vehicle and Parts Dealers",
    value: "Motor Vehicle and Parts Dealers"
  }, {
    label: "Museums, Historical Sites, and Similar Institutions",
    value: "Museums, Historical Sites, and Similar Institutions"
  }, {
    label: "National Security and International Affairs",
    value: "National Security and International Affairs"
  }, {
    label: "Nonmetallic Mineral Product Manufacturing",
    value: "Nonmetallic Mineral Product Manufacturing"
  }, {
    label: "Nursing and Residential Care Facilities",
    value: "Nursing and Residential Care Facilities"
  }, {
    label: "Oil and Gas Extraction",
    value: "Oil and Gas Extraction"
  }, {
    label: "Paper Manufacturing",
    value: "Paper Manufacturing"
  }, {
    label: "Performing Arts, Spectator Sports, and Related Industries",
    value: "Performing Arts, Spectator Sports, and Related Industries"
  }, {
    label: "Personal and Laundry Services",
    value: "Personal and Laundry Services"
  }, {
    label: "Petroleum and Coal Products Manufacturing",
    value: "Petroleum and Coal Products Manufacturing"
  }, {
    label: "Pipeline Transportation",
    value: "Pipeline Transportation"
  }, {
    label: "Plastics and Rubber Products Manufacturing",
    value: "Plastics and Rubber Products Manufacturing"
  }, {
    label: "Postal Service",
    value: "Postal Service"
  }, {
    label: "Primary Metal Manufacturing",
    value: "Primary Metal Manufacturing"
  }, {
    label: "Printing and Related Support Activities",
    value: "Printing and Related Support Activities"
  }, {
    label: "Private Households",
    value: "Private Households"
  }, {
    label: "Professional, Scientific, and Technical Services",
    value: "Professional, Scientific, and Technical Services"
  }, {
    label: "Publishing Industries",
    value: "Publishing Industries"
  }, {
    label: "Rail Transportation",
    value: "Rail Transportation"
  }, {
    label: "Real Estate",
    value: "Real Estate"
  }, {
    label: "Religious, Grantmaking, Civic, Professional, and Similar Organizations",
    value: "Religious, Grantmaking, Civic, Professional, and Similar Organizations"
  }, {
    label: "Rental and Leasing Services",
    value: "Rental and Leasing Services"
  }, {
    label: "Repair and Maintenance",
    value: "Repair and Maintenance"
  }, {
    label: "Scenic and Sightseeing Transportation",
    value: "Scenic and Sightseeing Transportation"
  }, {
    label: "Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
    value: "Securities, Commodity Contracts, and Other Financial Investments and Related Activities"
  }, {
    label: "Social Assistance",
    value: "Social Assistance"
  }, {
    label: "Space Research and Technology",
    value: "Space Research and Technology"
  }, {
    label: "Specialty Trade Contractors",
    value: "Specialty Trade Contractors"
  }, {
    label: "Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers",
    value: "Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers"
  }, {
    label: "Support Activities for Agriculture and Forestry",
    value: "Support Activities for Agriculture and Forestry"
  }, {
    label: "Support Activities for Mining",
    value: "Support Activities for Mining"
  }, {
    label: "Support Activities for Transportation",
    value: "Support Activities for Transportation"
  }, {
    label: "Telecommunications",
    value: "Telecommunications"
  }, {
    label: "Textile Mills",
    value: "Textile Mills"
  }, {
    label: "Textile Product Mills",
    value: "Textile Product Mills"
  }, {
    label: "Transit and Ground Passenger Transportation",
    value: "Transit and Ground Passenger Transportation"
  }, {
    label: "Transportation Equipment Manufacturing",
    value: "Transportation Equipment Manufacturing"
  }, {
    label: "Truck Transportation",
    value: "Truck Transportation"
  }, {
    label: "Utilities",
    value: "Utilities"
  }, {
    label: "Warehousing and Storage",
    value: "Warehousing and Storage"
  }, {
    label: "Waste Management and Remediation Services",
    value: "Waste Management and Remediation Services"
  }, {
    label: "Water Transportation",
    value: "Water Transportation"
  }, {
    label: "Web Search Portals, Libraries, Archives, and Other Information Services",
    value: "Web Search Portals, Libraries, Archives, and Other Information Services"
  }, {
    label: "Wholesale Trade Agents and Brokers",
    value: "Wholesale Trade Agents and Brokers"
  }, {
    label: "Wood Product Manufacturing",
    value: "Wood Product Manufacturing"
  }]
}

export { industryList, businessIsFollowing };
