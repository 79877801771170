const businessEntityType = () => {
  return [
    { label: "Corporation", value: "CORPORATION" },
    { label: "LLC", value: "LLC" },
    { label: "Partnership", value: "PARTNERSHIP" },
    { label: "Sole Proprietor", value: "SOLE_PROPRIETORSHIP" },
    { label: "Non Profit", value: "NON_PROFIT" },
    { label: "Other", value: "OTHER" },
  ];
};

export default businessEntityType;
