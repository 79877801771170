import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import FORMATS from "../../constants/formats";

export default function DatePicker({ className, onChange, ...props }) {
  const DatePicker = styled(AntDatePicker)`
    width: 100%;
    height: 54px;
    &:hover {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
    &:active {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
    &:focus {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
  `;
  // format="L" - sets default date format to US standard (MM/DD/YYYY)
  return (
    <DatePicker
      {...props}
      className={`${className}`}
      onChange={onChange}
      format={FORMATS.DATEPICKER_FORMAT}
      placeholder={FORMATS.DATEPICKER_FORMAT}
    />
  );
}

DatePicker.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
};
