import { API } from "aws-amplify";

export default async function updatePayment(data) {
  if (data?.id) {
    return await API.put("payments", "update-recurring-payment", {
      queryStringParameters: {
        id: data.id,
      },
      body: {
        ...data.input,
      },
    });
  }
}
