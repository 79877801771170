import moment from "moment";
import classNames from "../Overview.module.scss";
import columns from "./columns.data";
import { useState } from "react";
import { Card, Button as AntButton, Table, Space, Radio } from "antd";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../common";
import { FORMATS, ROUTES } from "../../../constants";
import { centsToDollar } from "../../../util/functions/dollarConvertion";

export default function Overview({ transactionData }) {
  //Hooks
  const navigate = useNavigate();

  const [filterType, filterTransactions] = useState();

  //JSX
  const renderList = (data) => {
    if (filterType === "Credit") {
      return (
        Array.isArray(data) &&
        data
          ?.filter(
            (transaction) =>
              transaction?.hasOwnProperty("creditAmount") &&
              transaction?.creditAmount
          )
          ?.sort((a, b) =>
            moment(a?.date, FORMATS.DATE_TIME_FORMAT).diff(
              moment(b?.date, FORMATS.DATE_TIME_FORMAT)
            )
          )
          ?.map((transaction, index) => {
            return {
              // key: index, //requires change
              key: transaction?.id,
              payee: transaction?.memo || "-",
              date: moment(transaction?.date).format(FORMATS.DATEPICKER_FORMAT),
              paymentType: transaction?.transfer_type,
              credit: centsToDollar(
                transaction?.dc_sign === "credit" && transaction?.creditAmount
              ),
              debit: centsToDollar(
                transaction?.dc_sign === "debit" && transaction?.debitAmount
              ),
              balance: centsToDollar(transaction?.balance),
            };
          })
      );
    } else if (filterType === "Debit") {
      return (
        Array.isArray(data) &&
        data
          ?.filter(
            (transaction) =>
              transaction?.hasOwnProperty("debitAmount") &&
              transaction?.debitAmount
          )
          ?.sort((a, b) =>
            moment(a?.date, FORMATS.DATE_TIME_FORMAT).diff(
              moment(b?.date, FORMATS.DATE_TIME_FORMAT)
            )
          )
          ?.map((transaction, index) => {
            return {
              // key: index, //requires change
              key: transaction?.id,
              payee: transaction?.memo || "-",
              date: moment(transaction?.date).format(FORMATS.DATEPICKER_FORMAT),
              paymentType: transaction?.transfer_type,
              credit: centsToDollar(
                transaction?.dc_sign === "credit" && transaction?.creditAmount
              ),
              debit: centsToDollar(
                transaction?.dc_sign === "debit" && transaction?.debitAmount
              ),
              balance: centsToDollar(transaction?.balance),
            };
          })
      );
    } else {
      return (
        Array.isArray(data) &&
        data.length > 0 &&
        data
          ?.sort((a, b) =>
            moment(a?.date, FORMATS.DATE_TIME_FORMAT).diff(
              moment(b?.date, FORMATS.DATE_TIME_FORMAT)
            )
          )
          ?.map((transaction) => {
            return {
              key: transaction?.id,
              payee: transaction?.memo || "-",
              date: moment(transaction?.date).format(FORMATS.DATEPICKER_FORMAT),
              paymentType: transaction?.transfer_type,
              credit: centsToDollar(
                transaction?.dc_sign === "credit" && transaction?.creditAmount
              ),
              debit: centsToDollar(
                transaction?.dc_sign === "debit" && transaction?.debitAmount
              ),
              balance: centsToDollar(transaction?.balance),
            };
          })
      );
    }
  };

  return (
    <Card className={classNames.tableCard}>
      <div className={classNames.tableCardHeader}>
        <Title as="h3" style={{ margin: 0 }}>
          Recent Transactions
        </Title>
        <div className={classNames.tableCardFilters}>
          <Radio.Group defaultValue={null} buttonStyle="solid">
            <Space size="small">
              <Radio.Button
                value={null}
                onClick={(e) => filterTransactions(e.target.value)}
              >
                ALL
              </Radio.Button>
              <Radio.Button
                value="Credit"
                onClick={(e) => filterTransactions(e.target.value)}
              >
                INCOMING
              </Radio.Button>
              <Radio.Button
                value="Debit"
                onClick={(e) => filterTransactions(e.target.value)}
              >
                OUTGOING
              </Radio.Button>
            </Space>
          </Radio.Group>
        </div>
        <AntButton
          className={classNames.tableViewAllBtn}
          type="link"
          onClick={() =>
            navigate(ROUTES.APP.BUSINESS_BANKING.STATEMENTS_TRANSACTIONS)
          }
        >
          View all
        </AntButton>
      </div>
      {transactionData && (
        <Table
          size="small"
          pagination={false}
          dataSource={renderList(transactionData)}
          columns={columns}
          className={classNames.table}
        />
      )}
    </Card>
  );
}
