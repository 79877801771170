import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Card,
  Table,
  Space,
  Select as AntSelect,
  Empty,
  Button as AntButton,
} from "antd";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiEditLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QueryErrorResetBoundary,
  useMutation,
  useQueryClient,
} from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import moment from "moment";

import { Input, Header, Select, Button, Message } from "../../../common";
import { DEFAULT_AMOUNT, FORMATS, ROUTES } from "../../../constants";
import ChangeStatus from "../Invoices/ChangeStatus/ChangeStatus";
import CreateNewEstimate from "../Invoices/CreateNewEstimate/CreateNewEstimate";
import api from "../../../API";
import EditEstimate from "../ViewEstimate/EditEstimate/EditEstimate";
import DeleteEstimate from "./DeleteEstimate/DeleteEstimate";
import { intToDecimalDollar } from "../../../util/functions/dollarConvertion";
import generateErrorMessage from "../../../util/functions/customError";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import messages from "../../config/messages.const";
import useLanguage from "../../../../hooks/useLanguage";
import classNames from "./AllEstimates.module.scss";

export default function AllEstimates() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  const [pagination, setPagination] = useState(PAGINATION);
  const [estimateData, setEstimateData] = useState([]);
  const [filteredEstimateData, setFilteredEstimateData] =
    useState(estimateData);
  const [keyword, setKeyword] = useState(null);
  const [client, setClient] = useState(null);
  const [sort, setSortChange] = useState();
  // const [selectedEstimateId, setEstimateId] = useState(null);
  const [statusAttribute, setStatusAttribute] = useState(
    location.state?.filter
  );
  const [
    createNewEstimateModalVisibility,
    setCreateNewEstimateModalVisibility,
  ] = useState(false);

  useEffect(() => {
    setPagination(PAGINATION);
  }, [keyword]);

  useEffect(() => {
    if (sort) {
      setPagination(PAGINATION);
    }
  }, [sort]);

  const handleCreateNewEstimateModalCancelClick = () => {
    setCreateNewEstimateModalVisibility(false);
  };

  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);
  const [estimateStatus, setEstimateStatus] = useState();

  const [deleteEstimateModalVisibility, setDeleteEstimateModalVisibility] =
    useState({ visible: false, record: [] });

  const [editEstimateModalVisibility, setEditEstimateModalVisibility] =
    useState({ visible: false, record: [] });

  const handleEditEstimateCancelClick = () => {
    setEditEstimateModalVisibility({ visible: false, record: false });
  };

  const handleEstimateEditModalButtonClick = (record) => {
    setEditEstimateModalVisibility({ visible: true, record: record });
  };

  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
  };
  const handleStatusChange = (value, record) => {
    setEstimateStatus({ flag: "estimate", value: value, record: record });
    setChangeStatusModalVisibility(true);
  };

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateQuery, isLoading: isLoadingEstimate } = useQuery(
    ["getEstimates", location.state],
    () =>
      api.Receivables.Estimates.listEstimates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      onSettled: () => setPagination(PAGINATION),
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: products } = useQuery(
    "getProductsByCompanyId",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateTemplateSettingsQuery } = useQuery(
    "getEstimateSettings",
    () =>
      api.Receivables.Settings.getEstimateTemplateSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  //Update estimate
  // const updateEstimateFunction = (event) => {
  //   return api.Receivables.Estimates.editEstimate(event);
  // };

  // const updateEstimateMutation = useMutation((event) =>
  //   updateEstimateFunction(event)
  // );

  // const updateEstimateStatus = (data) => {
  //   updateEstimateMutation.mutate(data, {
  //     onSuccess: (data, variables, context) => {
  //       Message({ type: "success", content: "Status changed successfully" });
  //     },
  //     onError: (error, variables, context) => {
  //       Message({ type: "error", content: `${error}` });
  //     },
  //   });
  // };

  // const deleteEstimate = () => {
  //   return api.Receivables.Estimates.deleteEstimate(selectedEstimateId);
  // };

  // const deleteMt = useMutation((event) => deleteEstimate(event));

  // const handleEstimateDeleteClick = () => {
  //   if (selectedEstimateId) {
  //     deleteMt.mutate(
  //       {},
  //       {
  //         onSuccess: (data, variables, context) => {
  //           setDeleteEstimateModalVisibility(false);
  //           Message({
  //             type: "success",
  //             content: "Estimate deleted successfully!",
  //           });
  //           setEstimateId(null);
  //         },
  //         onError: (error, variables, context) => {
  //           Message({ type: "error", content: error.toString() });
  //           setEstimateId(null);
  //         },
  //       }
  //     );
  //   }
  // };

  // const handleEstimateDeleteModelVisibility = (event, record) => {
  //   event.stopPropagation();
  //   setEstimateId(record.id);
  //   setDeleteEstimateModalVisibility({ visible: false, record: record });
  // };

  useEffect(() => {
    if (estimateQuery && !estimateQuery?.data?.message) {
      setEstimateData(
        estimateQuery?.data.map((estimate) => {
          return {
            id: estimate.id,
            key: estimate.id,
            number: estimate.estimateNumber,
            client: estimate.receiverName,
            date: estimate.invoiceDate,
            amount: estimate.total,
            total: estimate.total,
            status: estimate.estimateStatus,
            ...estimate,
          };
        })
      );
    }
  }, [estimateQuery]);

  // delete estimate mutation
  const estimateDeleteMn = useMutation(() =>
    api.Receivables.Estimates.deleteEstimate(
      deleteEstimateModalVisibility?.record?.id
    )
  );
  const estimateDelete = () => {
    estimateDeleteMn.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getEstimates");
          setDeleteEstimateModalVisibility({ visible: false, record: null });
          Message({
            type: "success",
            content: language.ESTIMATE_DELETE_SUCCESS,
          });
        },
        onError: (error) => generateErrorMessage(error),
      }
    );
  };

  const { Option } = Select;

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <span>{moment(date).format()}</span>;
      },
      sorter: (a, b) => {
        return moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b?.date, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (total, record) =>
        intToDecimalDollar(Number(total)) || DEFAULT_AMOUNT,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: `status${Math.random()}`,
      render: (status, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <AntSelect
            key={`allEstimateStatus${record.id}`}
            onClick={(event) => event.stopPropagation()}
            defaultValue={status === "overdue" ? "Overdue" : status}
            bordered={false}
            style={{ width: 120 }}
            // disabled={status === "overdue" ? true : false}
            className={classNames.statusSelect}
            onChange={(value) => {
              handleStatusChange(value, record);
            }}
          >
            {" "}
            {status === "draft" || status === "overdue" ? (
              <>
                <AntSelect.Option value="draft">
                  <span style={{ color: "#BC7C1D" }}>Draft</span>
                </AntSelect.Option>
                <AntSelect.Option value="sent">
                  <span style={{ color: "#7372FF" }}>Sent</span>
                </AntSelect.Option>
              </>
            ) : status === "sent" ? (
              <>
                {/* <AntSelect.Option value="late">
    <span style={{ color: "#EE6969" }}>Overdue</span>
  </AntSelect.Option> */}
                <AntSelect.Option value="sent">
                  <span style={{ color: "#7372FF" }}>Sent</span>
                </AntSelect.Option>
              </>
            ) : (
              ""
            )}
          </AntSelect>

          <Space size="middle">
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleEstimateEditModalButtonClick(record);
              }}
            />
            <RiDeleteBin7Line
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteEstimateModalVisibility({
                  visible: true,
                  record: record,
                });
              }}
            />
          </Space>
        </Space>
      ),
    },
  ];

  // comment for future use: AfterMvp
  // const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  //   console.log(
  //     `selectedRowKeys: ${selectedRowKeys}`,
  //     "selectedRows: ",
  //     selectedRows
  //   );
  // },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const rowClick = (record) => {
    Object.assign(record, { from: location.pathname });
    navigate(ROUTES.APP.RECEIVABLES.ESTIMATES.replace(":id", record.number), {
      state: record,
    });
  };

  const renderClientOptions = (data) => {
    const mainData =
      data?.data && data?.data?.length
        ? data?.data
            ?.filter(Boolean)
            ?.filter((item) =>
              estimateQuery?.data?.some(
                (estimate) => estimate?.receiverName === item?.name
              )
            )
        : [];

    return mainData?.map((item, index) => (
      <Option key={`client${index}`} value={item.name}></Option>
    ));
  };

  const status = [
    { label: "All", value: "" },
    { label: "Draft", value: "Draft" },
    { label: "Sent", value: "Sent" },
    { label: "Overdue", value: "Overdue" },
  ];
  const renderStatusOptions = (data) =>
    data?.map((item, index) => (
      <Select.Option key={`status${index}`} value={item.value}>
        {item.label}
      </Select.Option>
    ));

  const keywordFilteredArray =
    (keyword
      ? filteredEstimateData && filteredEstimateData.length > 0
        ? filteredEstimateData.filter((obj) => {
            let filterObj = {
              amount: obj.amount,
              client: obj.client,
              date: obj.date,
              number: obj.number,
              status: obj.status,
            };
            return Object.values(filterObj)
              .toString()
              .toLowerCase()
              .includes(keyword.toLowerCase());
          })
        : []
      : filteredEstimateData) || [];

  //Display pagination count text
  let totalNoOfRecords =
    Array.isArray(estimateData) &&
    estimateData?.length > 0 &&
    (keyword || client || statusAttribute)
      ? Array.isArray(keywordFilteredArray)
        ? keywordFilteredArray?.length
        : Array.isArray(estimateData)
        ? estimateData?.length
        : 0
      : estimateData.length;
  let maximumNoOfPages = Math.ceil(totalNoOfRecords / pagination.pageSize);
  let noOfRecordsForLastPage =
    totalNoOfRecords % pagination.pageSize > 0
      ? totalNoOfRecords - pagination.pageSize * (maximumNoOfPages - 1)
      : pagination.pageSize;
  let calculateRecordCount =
    pagination.pageSize > totalNoOfRecords
      ? totalNoOfRecords
      : pagination.page === maximumNoOfPages
      ? noOfRecordsForLastPage
      : pagination.pageSize;

  const paginationText =
    totalNoOfRecords === 0
      ? `No estimates to display`
      : `Displaying
     ${calculateRecordCount}
     of ${totalNoOfRecords} ${totalNoOfRecords > 1 ? "estimates" : "estimate"}`;

  useEffect(() => {
    if (!client && !keyword && !statusAttribute) {
      setFilteredEstimateData(
        estimateQuery?.data
          .map((estimate) => {
            return {
              key: estimate.id,
              id: estimate.id,
              number: estimate.estimateNumber,
              client: estimate.receiverName,
              date: estimate.estimateDate,
              amount: estimate.total,
              total: estimate.total,
              status: estimate.estimateStatus,
              ...estimate,
            };
          })
          .sort((a, b) => {
            return a.number - b.number;
          })
      );
    }
  }, [client, estimateQuery?.data, keyword, statusAttribute]);

  useEffect(() => {
    let estimateQueryDatasource = [];

    if (client && statusAttribute) {
      estimateQueryDatasource =
        estimateData &&
        estimateData.length > 0 &&
        estimateData.filter(
          (obj) =>
            obj.receiverName === client &&
            obj.estimateStatus ===
              (statusAttribute && statusAttribute.toLowerCase())
        );
      setFilteredEstimateData(estimateQueryDatasource);
    } else if (statusAttribute || client) {
      estimateQueryDatasource =
        estimateData &&
        estimateData.length > 0 &&
        estimateData.filter(
          (obj) =>
            obj.estimateStatus ===
              (statusAttribute && statusAttribute.toLowerCase()) ||
            obj.receiverName === client
        );
      setFilteredEstimateData(estimateQueryDatasource);
    }
  }, [client, statusAttribute, estimateData]);

  const handleClientEvent = (event, isFilter) => {
    if (!event) {
      setPagination(PAGINATION);
      if (isFilter) {
        setClient("");
      } else {
        setStatusAttribute("");
      }

      setFilteredEstimateData(
        estimateQuery?.data
          .map((estimate) => {
            return {
              key: estimate.id,
              id: estimate.id,
              number: estimate.estimateNumber,
              client: estimate.receiverName,
              date: estimate.estimateDate,
              amount: estimate.total,
              total: estimate.total,
              status: estimate.estimateStatus,
              ...estimate,
            };
          })
          .sort((a, b) => {
            return a.number - b.number;
          })
      );
    } else {
      setPagination(PAGINATION);
      if (isFilter) {
        setClient(event);
      } else {
        setStatusAttribute(event);
      }
    }
  };

  return (
    <div className={classNames.wrapper}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
              </div>
            )}
          >
            <div className={classNames.headerWrapper}>
              <Header
                title="All Estimates"
                back
                onClick={() => navigate("../invoices")}
              />
            </div>
            <div className={classNames.layout}>
              <div className={classNames.bodyWrapper}>
                <div className={classNames.topRow}>
                  <div className={classNames.invoiceFilters}>
                    <Input
                      placeholder="Search estimates"
                      prefix={<RiSearch2Line size={26} />}
                      className={classNames.search}
                      onChange={(event) => setKeyword(event.target.value)}
                    />
                    <span style={{ margin: "0 16px" }}>Filter by</span>
                    <Select
                      placeholder="Customers"
                      className={classNames.filterSelect}
                      bordered={false}
                      onChange={(event) => handleClientEvent(event, true)}
                      allowClear
                    >
                      {renderClientOptions(customerQuery)}
                    </Select>
                    <span style={{ margin: "0 16px" }}>Status</span>
                    <Select
                      placeholder={location.state ? "Draft" : "All"}
                      className={classNames.filterSelect}
                      bordered={false}
                      onChange={(event) => handleClientEvent(event, false)}
                      allowClear
                    >
                      {renderStatusOptions(status)}
                    </Select>
                  </div>
                  <Card
                    className={classNames.optionCard}
                    onClick={() => setCreateNewEstimateModalVisibility(true)}
                  >
                    <div className={classNames.cardAction}>
                      <span>Create Estimate</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                </div>
                <div className={classNames.body}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "26px 0",
                    }}
                  >
                    {paginationText}
                  </div>
                  <Table
                    loading={isLoadingEstimate}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          rowClick(record);
                        },
                      };
                    }}
                    // comment due to future use afterMVP
                    // rowSelection={{
                    //   type: "checkbox",
                    //   ...rowSelection,
                    // }}
                    // scroll={{ y: `var(--table-height)` }}
                    size="large"
                    dataSource={keywordFilteredArray
                      ?.sort((a, b) => b.number - a.number)}
                    columns={columns}
                    className={classNames.table}
                    rowClassName={classNames.actionIcon}
                    pagination={{
                      ...PAGINATION_DEFAULT,
                      current: pagination.page,
                      pageSize: pagination.pageSize,
                      onChange: (page, pageSize) => {
                        setPagination({ page: page, pageSize: pageSize });
                      },
                      itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                          return (
                            <AntButton style={{ color: "#9f9cff" }}>
                              Prev
                            </AntButton>
                          );
                        }
                        if (type === "next") {
                          return (
                            <AntButton
                              style={{
                                color: "#9f9cff",
                                marginLeft: "32px",
                              }}
                            >
                              Next
                            </AntButton>
                          );
                        }
                      },
                    }}
                    onChange={(pagination, filters, sorter) => {
                      setSortChange(sorter?.order);
                    }}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            keyword && totalNoOfRecords === 0
                              ? "No Result found"
                              : "No Data Found"
                          }
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <ChangeStatus
              visible={changeStatusModalVisibility}
              status={estimateStatus}
              onCancel={handleChangeStatusModalCancelClick}
              setChangeStatusModalVisibility={setChangeStatusModalVisibility}
            />
            <CreateNewEstimate
              visible={createNewEstimateModalVisibility}
              onCancel={handleCreateNewEstimateModalCancelClick}
              invoiceTemplateSettings={estimateTemplateSettingsQuery?.data}
            />
            <DeleteEstimate
              visible={deleteEstimateModalVisibility.visible}
              setDeleteEstimateModalVisibility={
                setDeleteEstimateModalVisibility
              }
              onCancel={() => setDeleteEstimateModalVisibility(false)}
              estimate={deleteEstimateModalVisibility.record}
              estimateDelete={estimateDelete}
              estimateDeleteMn={estimateDeleteMn}
            />
            <EditEstimate
              customers={customerQuery?.data}
              estimate={editEstimateModalVisibility.record}
              products={products?.data}
              taxRates={taxRates?.data}
              visible={editEstimateModalVisibility.visible}
              onCancel={handleEditEstimateCancelClick}
              setEditEstimateModalVisibility={setEditEstimateModalVisibility}
              invoiceTemplateSettings={estimateTemplateSettingsQuery?.data}
            />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
}
