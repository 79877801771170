import classNames from "./SummaryCollapse.module.scss";
import { Collapse } from "antd";
import { RiSubtractLine, RiAddFill } from "react-icons/ri";
import { CgMathEqual } from "react-icons/cg";
import { useState } from "react";

const SummaryCollapse = ({ amount, transaction, total }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { Panel } = Collapse;

  const onCollapseChange = (data) => {
    if (data.length === 1) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["0"]}
      onChange={onCollapseChange}
      expandIcon={({ isActive }) =>
        isActive ? (
          <RiSubtractLine color="#7372FF" />
        ) : (
          <RiAddFill color="#7372FF" />
        )
      }
    >
      <Panel
        header={
          isCollapsed ? (
            <span className={classNames.panelHeader}>Hide payment summary</span>
          ) : (
            <span className={classNames.panelHeader}>Show payment summary</span>
          )
        }
        key="1"
      >
        <div className={classNames.collapsePanel}>
          <div>
            <div>
              <div>Amount</div>
              <div>${amount ? Number(amount).toFixed(2) : "0.00"}</div>
            </div>
            <RiAddFill />
            <div>
              <div>Transaction Fee</div>
              <div>${transaction.toFixed(2)}</div>
            </div>
            <CgMathEqual />
            <div>
              <div>Total Payable</div>
              <div style={{ fontWeight: 700 }}>${total.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default SummaryCollapse;
