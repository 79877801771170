import { useMutation } from "react-query";
import useLanguage from "../../../../../hooks/useLanguage";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import messages from "../../../config/messages.const";
import classNames from "../../MakePayments.module.scss";

export default function DeletePayment({
  visible,
  onCancel,
  payments,
  refetch,
}) {
  //Hooks
  const language = useLanguage(messages);

  //Api calls
  const { mutate, isLoading } = useMutation(
    api.BusinessBanking.deleteScheduledPayment
  );

  //Event handlers
  const onDelete = () => {
    mutate(payments?.id, {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.PAYMENT_DELETED,
        });
        onCancel();
        refetch();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete payment?
      </Title>
      {/* {payments && payments.length > 1 ? (
        <div style={{ marginBottom: 8 }}>
          Are you sure you want to delete these {payments.length} payments?
        </div>
      ) : ( */}
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this payment?
      </div>
      {/* )} */}
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          loading={isLoading}
          type="primary"
          spinning
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={onDelete}
        >
          {language.DELETE}
        </CTAButton>
      </div>
    </Modal>
  );
}
