import { API } from "aws-amplify";

export default async function unFreezePayment(data) {
  console.log("unFreezePayment ===> ",data);
  return await API.post("payments", "unfreeze-payment", {
    body: {
      ...data,
    },
  });
}
