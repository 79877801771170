import commonMessages from "../../util/constants";
import deepMerge from "../../util/functions/deepMerge";

const directoryMessages = {
  en: {
    PRODUCT_CREATE_SUCCESS: "Product created successfully!",
    PRODUCT_DELETE_SUCCESS: "Product deleted successfully!",
    PRODUCT_UPDATE_SUCCESS: "Product updated",
    ESTIMATE_CREATE_SUCCESS: "Estimate created successfully!",
    ESTIMATE_DELETE_SUCCESS: "Estimate deleted successfully!",
    ESTIMATE_UPDATE_SUCCESS: "Estimate updated successfully!",
    ESTIMATE_TEMPLATE_UPDATE_SUCCESS: "Estimate template updated",
    INVOICE_CREATE_SUCCESS: "Invoice created successfully!",
    INVOICE_SENT_SUCCESS: "Invoice sent successfully!",
    INVOICE_DELETE_SUCCESS: "Invoice deleted successfully!",
    INVOICE_UPDATE_SUCCESS: "Invoice updated successfully!",
    INVOICE_TEMPLATE_UPDATE_SUCCESS: "Invoice template updated",
    CUSTOMER_CREATE_SUCCESS: "Customer created successfully!",
    CUSTOMER_DELETE_SUCCESS: "Customer deleted successfully!",
    CUSTOMER_UPDATE_SUCCESS: "Customer updated successfully!",
    RECURRING_INVOICE_CREATE_SUCCESS: "Recurring invoice created successfully!",
    TAX_RATE_CREATE_SUCCESS: "Tax Rate created successfully!",
    TAX_RATE_DELETE_SUCCESS: "Tax Rate deleted successfully!",
    TAX_RATE_UPDATE_SUCCESS: "Tax updated",
    ENABLE_ONLINE_PAYMENTS: "Enabled online payments",
    DISABLE_ONLINE_PAYMENTS: "Disabled online payments",
    STATUS_CHANGE_SUCCESS: "Status changed successfully",
    EMAIL_SENT_SUCCESS: "Email sent successfully!",
    TIMING_VALUES_ALLOWED: "Only values from 0 - 31 allowed.",
    MONTHLY_VALUES_ALLOWED: "Only values from 0 - 12 allowed",
    WEEKLY_VALUES_ALLOWED: "Only values from 0 - 52 allowed",
    INACTIVE_LABEL: "Please fill in Template Details to Preview the Design.",
  },
};

const messages = deepMerge(commonMessages, directoryMessages);

export default messages;
