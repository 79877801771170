import { useState } from "react";
import { Card, Spin } from "antd";
import { RiAddCircleFill, RiEqualizerLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { ROUTES } from "../../../constants";
import AddUser from "../../../Settings/UserManagement/AddUser/AddUser";
import api from "../../../API";
import UserCardButton from "./UserCardButton";

import classNames from "./UsersCard.module.scss";

const UsersCard = () => {
  const navigate = useNavigate();

  const [addUser, setAddUser] = useState(false);

  const { data: childUsers, isLoading: childUsersLoading } = useQuery(
    "getChildUsers",
    () => api.Settings.getChildUsersByTenantId(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleAddUserModalCancelClick = () => {
    setAddUser(false);
  };

  return (
    <Spin spinning={childUsersLoading}>
      <Card className={classNames.usersCard}>
        <div>
          <div>No. of users</div>
          <div>
            <div>
              {childUsers && childUsers?.data ? childUsers?.data?.length : 0}
            </div>
          </div>
        </div>
        <div>
          <UserCardButton
            disabled={true}
            comingSoon={true}
            onClick={() => {
              navigate(ROUTES.APP.SETTINGS.USER_MANAGEMENT);
            }}
            icon={RiAddCircleFill}
          >
            Add user
          </UserCardButton>
          <UserCardButton
            disabled={true}
            comingSoon={true}
            onClick={() => {
              navigate(ROUTES.APP.SETTINGS.USER_MANAGEMENT);
            }}
            icon={RiEqualizerLine}
            iconSize={25}
          >
            Manage User
          </UserCardButton>
        </div>
        <AddUser visible={addUser} onCancel={handleAddUserModalCancelClick} />
      </Card>
    </Spin>
  );
};

export default UsersCard;
