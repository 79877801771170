import classNames from "../Customers.module.scss";
import { CTAButton, Input, Select, Message } from "../../../../common";
import { Form, Typography } from "antd";
import api from "../../../../API";
import { useMutation, useQueryClient } from "react-query";

// import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { MAX_MIN } from "../../../../util/constants";
import { validation } from "../../../../Auth/config";
import { countryList, usStateList } from "../../../../util";
import REGEX from "../../../../util/constants/regex";

export default function EditProduct({
  data,
  onCancel,
  form,
  setEditCustomersCardModalVisibility,
}) {
  const { Option } = Select;
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  // let einPattern = /^[0-9]{9}$/;

  const { Text } = Typography;

  const editCustomer = (cusomerDetails) => {
    const input = {
      street1: cusomerDetails?.street1,
      street2: cusomerDetails?.street2,
      zipcode: cusomerDetails.zipcode,
      city: cusomerDetails.city,
      state: cusomerDetails.state,
      country: cusomerDetails.country,
      currencyIso: cusomerDetails.currencyIso,
      paymentDue: cusomerDetails.paymentDue
        ? Number(cusomerDetails.paymentDue)
        : 0,
      registrationNumber:
        cusomerDetails.registrationNumber &&
        cusomerDetails.registrationNumber.length > 0
          ? cusomerDetails.registrationNumber
          : " ",
      taxNumber:
        cusomerDetails.taxNumber && cusomerDetails.taxNumber.length > 0
          ? cusomerDetails.taxNumber
          : " ",
    };
    if (!(data?.name === cusomerDetails.name)) {
      input.name = cusomerDetails.name;
    }
    if (!(data?.email === cusomerDetails.email)) {
      input.email = cusomerDetails.email;
    }
    return api.Receivables.Customers.editCustomer(input, data.id);
  };

  const editMn = useMutation((event) => editCustomer(event));

  const onFinish = (values) => {
    editMn.mutate(values, {
      onSuccess: () => {
        setEditCustomersCardModalVisibility({
          visibility: false,
          record: null,
        });
        Message({ type: "success", content: language.CUSTOMER_UPDATE_SUCCESS });
        queryClient.invalidateQueries("getCustomers");
        onCancel();
      },
      onError: (error) => {
        const errorObj = error.response.data.error
        for (let key in errorObj) {
          if (errorObj.hasOwnProperty(key)) {
            Message({
              type: "error",
              content: errorObj[key][0]
            });
          }
        }
      },
    });
  };

  return (
    <div className={classNames.modalBody}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{
          name: data?.name,
          email: data?.email,
          street1: data?.street1,
          street2: data?.street2,
          city: data?.city,
          state: data?.state,
          zipcode: data?.zipcode,
          country: data?.country,
          currencyIso: data?.currencyIso,
          paymentDue: data?.paymentDue,
          taxNumber: data?.taxNumber,
          registrationNumber: data?.registrationNumber,
        }}
        onFinish={onFinish}
        autoComplete="off"
        key={data.key}
      >
        <Form.Item
          name="name"
          label={
            <span className={classNames.label}>
              Customer Name <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            { required: true, message: "Customer name is required" },
            {
              max: MAX_MIN.businessName.max,
              message: `Max ${MAX_MIN.businessName.max} characters`,
            },
          ]}
        >
          <Input placeholder="Please Enter" size="small" />
        </Form.Item>
        <Form.Item
          name="email"
          label={
            <span className={classNames.label}>
              Email Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            { required: true, message: "Email is required" },
            {
              type: "email",
              message: "Enter a valid email address",
            },
            { min: MAX_MIN.email.min, message: validation.email.minLength },
            { max: MAX_MIN.email.max, message: validation.email.maxLength },
          ]}
        >
          <Input placeholder="Please Enter" size="small" />
        </Form.Item>
        <Form.Item
          name="address"
          label={
            <span className={classNames.label}>
              Billing Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
        >
          <Form.Item
            name="street1"
            rules={[
              { required: true, message: validation.street.required },
              { max: MAX_MIN.street.max, message: validation.street.max },
            ]}
          >
            <Input placeholder="Street*" size="small" />
          </Form.Item>
          <Form.Item
            name="street2"
            rules={[
              { max: MAX_MIN.street.max, message: validation.street2.max },
            ]}
          >
            <Input placeholder="Street 2" size="small" />
          </Form.Item>
          {/* <Form.Item
            name="city"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select defaultValue={data?.city} placeholder="City" size="small">
              <Option value={"Los Angeles"}>Los Angeles</Option>
              <Option value={"California"}> California</Option>
              <Option value={"New York"}>New York</Option>
              <Option value={"Chicago"}>Chicago</Option>
            </Select>
          </Form.Item>
        </Form.Item> */}

          <Form.Item
            name="city"
            rules={[
              { required: true, message: validation.city.required },
              { max: MAX_MIN.city.max, message: validation.city.max },
            ]}
          >
            <Input placeholder="City*" size="small" />
          </Form.Item>

          {/* <Form.Item>
          <Form.Item
            name="state"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[{ required: true, message: "State is required" }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue={data?.state}
              style={{ width: 180 }}
              size="small"
            >
              <Option value={"State 1"}>State 1</Option>
              <Option value={"State 2"}>State 2</Option>
              <Option value={"State 3"}>State 3</Option>
              <Option value={"State 4"}>State 4</Option>
            </Select>
          </Form.Item> */}

          <Form.Item
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            name="state"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[{ required: true, message: validation.state.required }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={validation.state.placeholder}
              size="small"
            >
              {usStateList().map((value) => (
                <Select.Option key={value.code} value={value.code}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="zipcode"
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
            rules={[
              { required: true, message: validation.postalCode.required },
              {
                pattern: new RegExp(REGEX.postalCode),
                message: validation.postalCode.valid,
              },
            ]}
          >
            <Input placeholder="Zip Code *" size="small" />
          </Form.Item>
        </Form.Item>

        {/* <Form.Item name="country">
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={data?.country}
            placeholder="Country"
            size="small"
          >
            <Option value={"USA"}>USA</Option>
            <Option value={"India"}> India</Option>
            <Option value={"UAE"}>UAE</Option>
            <Option value={"Sri lanka"}>Sri lanka</Option>
          </Select>
        </Form.Item> */}

        <Form.Item name="country">
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Country"
            size="small"
          >
            {countryList(true).map((value) => (
              <Select.Option key={value.code} value={value.code}>
                {value.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Form.Item
            name="currencyIso"
            label={
              <span className={classNames.label}>
                Currency <sup style={{ top: 1 }}>*</sup>{" "}
              </span>
            }
            rules={[{ required: true, message: "Currency is required." }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
          >
            <Select
              placeholder="Please select"
              style={{ width: 180 }}
              size="small"
              disabled
            >
              <Option value={"USD"}>USD</Option>
              <Option value={"GBP"}>GBP</Option>
              <Option value={"AED"}>AED</Option>
              <Option value={"UYD"}>UYD</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="paymentDue"
            label={
              <span className={classNames.label}>
                Payment Due in
                {/* <sup style={{ top: 1 }}>*</sup> */}
              </span>
            }
            rules={[
              {
                pattern: new RegExp(/^[0-9]\d*$/),
                message: "Enter a valid value",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    form.getFieldValue("paymentDue") &&
                    form.getFieldValue("paymentDue").length > 2
                  ) {
                    return Promise.reject(new Error("Max two characters"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
          >
            <Input
              placeholder="Please Enter"
              size="small"
              suffix={<Text type="secondary">Days</Text>}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="taxNumber"
            label={<span className={classNames.label}>EIN Number</span>}
            rules={[
              {
                pattern: new RegExp(/^[0-9]{9}$/),
                message: "Enter a valid EIN",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
          >
            <Input placeholder="Please Enter" size="small" />
          </Form.Item>
          <Form.Item
            name="registrationNumber"
            label={
              <span className={classNames.label}>Registration Number</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
          >
            <Input placeholder="Please Enter" size="small" />
          </Form.Item>
        </Form.Item>
        <CTAButton
          htmlType="submit"
          key="submit"
          type="primary"
          style={{ height: 44, width: 384, marginBottom: 24 }}
          loading={editMn.isLoading}
        >
          Save
        </CTAButton>
      </Form>
    </div>
  );
}
