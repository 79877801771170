import IField, { ACH_TYPE, CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import { useEffect, useRef, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useMutation, useQuery } from "react-query";
import useLanguage from "../../../../../hooks/useLanguage";
import useIFieldKey from "../../../../../hooks/useIFieldKey";
import api from "../../../../API";
import {
  CTAButton,
  Input,
  Message,
  Select,
  Title,
  ExpiryInput,
} from "../../../../common";
import { useIfield } from "../../../../hooks/useIfield";
import { countryList, usStateList } from "../../../../util";
import formatExpiryInput from "../../../../util/formatExpiryInput.util";
import REGEX from "../../../../util/constants/regex";
import { validationPayee } from "../../../../BusinessBanking/config";
import { validation } from "../../../../Auth/config";
import { FILE_SIZE } from "../../../../constants";
import messages from "../../../../BusinessBanking/config/messages.const";
import { RiCloseFill, RiFileFill } from "react-icons/ri";
import classNames from "./AddNewTransactions.module.scss";
import { MAX_MIN } from "../../../../util/constants";
import Bugsnag from "@bugsnag/js";

//#region
const cvvIfieldStyles = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
};

const cvvIframeStyles = {
  border: "none",
  height: "50px",
  width: "200px",
};

const cardIfieldStyles = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "168px",
};

const cardIframeStyles = {
  border: "none",
  height: "50px",
  width: "190px",
};

const achIfieldStyle = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "360px",
};

const achIframeStyle = {
  border: "none",
  height: "50px",
  width: "400px",
};
//#endregion

export default function AddNewTransactionsForm({
  onCancel,
  fetchTransaction,
  form,
  title,
  customer,
  existCustomer,
  isCustomerPage,
}) {
  const cvvRef = useRef();
  const cardRef = useRef();
  const achRef = useRef();

  const [showShippingForm, setShowShippingForm] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [email, setEmail] = useState(null);
  const [values, setValues] = useState({});
  const [exDate, setExDate] = useState(null);
  const [error, setError] = useState(false);
  const [front, setFront] = useState(false);
  const [back, setBack] = useState(false);
  const [, forceUpdate] = useState({});

  const { Dragger } = Upload;
  const acceptableType = ["image/jpg", "image/jpeg", "image/png"];
  const acceptableTypes = acceptableType.join(",");

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  const language = useLanguage(messages);

  const { iFieldKey, iFieldLoading } = useIFieldKey();

  const {
    cvvoptions,
    ccoptions,
    onError,
    onToken,
    account,
    threeds,
    onCVVUpdate,
    onCcUpdate,
    achOption,
    onACHUpdate,
  } = useIfield(
    iFieldKey,
    cvvIfieldStyles,
    cvvIframeStyles,
    cardIfieldStyles,
    cardIframeStyles,
    paymentMethod,
    handleSubmit,
    achIfieldStyle,
    achIframeStyle
  );

  const getToken = () => {
    if (paymentMethod === "cc") {
      cardRef.current.getToken();
      cvvRef.current.getToken();
    }
    if (paymentMethod === "ach") {
      achRef.current.getToken();
    }
  };

  const { data: merchantData, isLoading: isOnboardStatusLoading } = useQuery(
    "getOnboardStatus",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    { retry: false, refetchOnWindowFocus: false }
  );

  // const clear = () => {
  //   cardRef.current.clearIfield();
  //   cvvRef.current.clearIfield();
  // }

  function createTransactionFn(data) {
    return api.Receivables.PaymentAcceptance.createPATransaction(
      data,
      "/transaction"
    );
  }

  const createTransactionMt = useMutation((event) =>
    createTransactionFn(event)
  );

  const onChange = (date) => {
    setExDate(formatExpiryInput(date.target.value));
  };

  const formatData = (val1, val2) => {
    if (paymentMethod === "cc") {
      return Object.assign({}, values, {
        cardNum: val2,
        cvv: val1,
        exp: exDate,
        merchantEmail: merchantData?.data?.email,
        transactionType: "sale",
      });
    }
    return Object.assign({}, values, {
      accountNumber: val1,
      transactionType: "check-sale",
      merchantEmail: merchantData?.data?.email,
      xCheckImageFront: front ? front : null,
      xCheckImageRear: back ? back : null,
      xCheckNum: values.checkNum,
    });
  };

  function handleSubmit(val1, val2) {
    if (paymentMethod) {
      createTransactionMt.mutate(formatData(val1, val2), {
        onSuccess: (data, variables, context) => {
          
          if (title === "Virtual Terminal") {
            try {
              const outputData = JSON.parse(data.output);
              const transactionData = outputData.data;
              
              if(transactionData.xStatus === "Error") {
                mixpanel.track("Failed Virtual Terminal Payment Request", {
                  "Payment Method": "CC",
                  "Transaction Type": variables?.transactionType,
                  "Failure Message": transactionData.xError,
                  "Amount": variables?.amount,
                  "Currency": "USD",
                });
  
                Message({
                  type: "error",
                  content: `Transaction failed: ${transactionData.xError}`,
                });
              } else {
                mixpanel.track("Successful Virtual Terminal Payment Request", {
                  "Reference Number": transactionData.xRefNum,
                  "Payment Method": "CC",
                  "Transaction Type": variables?.transactionType,
                  "Amount": variables?.amount,
                  "Currency": "USD",
                  "Payment Status": transactionData.xStatus,
                  "Transaction Status": transactionData.xResult,
                });
  
                Message({
                  type: "success",
                  content: `Virtual Terminal Payment Request Successful`,
                });
              }
  
            } catch (error) {
              Bugsnag.notify("mixpanel error", error);
            }
          }

          if (!isCustomerPage && title !== "Virtual Terminal") {
            fetchTransaction();
          }
          form.resetFields();
          onCancel();
        },
        onError: (error, variables) => {
          if (title === "Virtual Terminal") {
            try {
              mixpanel.track("Failed Virtual Terminal Payment Request", {
                "Payment Method": "CC",
                "Transaction Type": variables?.transactionType,
                "Failure Message": error,
                "Amount": variables?.amount,
                "Currency": "USD",
            });
            } catch (error) {
              Bugsnag.notify("mixpanel error", error);
            }
            
            Message({
              type: "error",
              content: `Virtual Terminal Payment Request Failed`,
            });
          }
          if (!isCustomerPage && title !== "Virtual Terminal") {
            fetchTransaction();
          }
          form.resetFields();
          onCancel();
        },
      });
    }
  }

  function onChangeFrontImage(info) {
    if (!error) {
      setFront(info?.file);
    }
  }

  function onChangeBackImage(info) {
    if (!error) {
      setBack(info?.file);
    }
  }

  const onFinish = (values) => {
    const newValues = !existCustomer
      ? { ...values, isSaveCustomer: true }
      : { ...values, isSaveCustomer: false };
    setValues(newValues);
    getToken();
  };

  const onClickPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div className={classNames.modalLeft}>
        <Spin spinning={iFieldLoading}>
          <div>
            <Title
              as="h5"
              style={{ color: "#BC7C1D" }}
              className={classNames.label}
            >
              General Information
            </Title>
          </div>

          <Form
            id="data-form"
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
            initialValues={{
              isSendEmailReceipt: false,
              isSendEmailCopy: false,
              isSaveCustomer: false,
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item>
              <Form.Item
                name={"billFirstName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    First Name
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  {
                    max: MAX_MIN.name.max,
                    message: validation.firstName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.firstName.valid,
                  },
                ]}
              >
                <Input
                  placeholder="Please enter"
                  size="small"
                  defaultValue={customer && customer?.BillFirstName}
                />
              </Form.Item>
              <Form.Item
                name={"billLastName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    Last Name
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  {
                    max: MAX_MIN.name.max,
                    message: validation.lastName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.lastName.valid,
                  },
                ]}
              >
                <Input
                  placeholder="Please enter"
                  size="small"
                  defaultValue={customer && customer?.BillLastName}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={"billCompany"}
              label={<span className={classNames.label}>Company Name</span>}
              rules={[
                {
                  max: MAX_MIN.businessName.max,
                  message: validation.businessName.max,
                },
                {
                  pattern: new RegExp(REGEX.businessName),
                  message: validation.businessName.valid,
                },
              ]}
            >
              <Input
                size="small"
                placeholder="Please enter"
                defaultValue={customer && customer?.BillCompany}
              />
            </Form.Item>
            <Form.Item
              label={<span className={classNames.label}>Billing Address</span>}
            >
              <Form.Item
                name={"billStreet"}
                rules={[
                  { max: MAX_MIN.street.max, message: validation.street.max },
                ]}
              >
                <Input
                  placeholder="Street"
                  size="small"
                  defaultValue={customer && customer?.BillStreet}
                />
              </Form.Item>
              <Form.Item
                name={"billStreet2"}
                rules={[
                  { max: MAX_MIN.street.max, message: validation.street2.max },
                ]}
              >
                <Input
                  placeholder="Street 2"
                  size="small"
                  defaultValue={customer && customer?.BillStreet2}
                />
              </Form.Item>
              <Form.Item
                name={"billCity"}
                rules={[
                  { max: MAX_MIN.city.max, message: validation.city.max },
                ]}
              >
                <Input
                  placeholder="City"
                  size="small"
                  defaultValue={customer && customer?.BillCity}
                />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name={"billState"}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginBottom: "10px",
                    float: "left",
                  }}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="State"
                    size="small"
                    defaultValue={customer && customer?.BillState}
                  >
                    {usStateList().map((value) => (
                      <Select.Option key={value.code} value={value.name}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name={"billZip"}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    margin: "0 0 0 4px",
                    float: "right",
                  }}
                  rules={[
                    {
                      pattern: new RegExp(REGEX.postalCode),
                      message: validation.postalCode.valid,
                    },
                  ]}
                >
                  <Input
                    placeholder="Postal code"
                    size="small"
                    defaultValue={customer && customer?.BillZip}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item name={"billCountry"}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Country"
                  defaultValue={customer && customer?.BillCountry}
                >
                  {countryList({ usa: true }).map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={"billPhone"}
              label={<span className={classNames.label}>Phone Number</span>}
              rules={[
                {
                  pattern: new RegExp(REGEX.phoneNumber),
                  message: validation.phoneNumber.valid,
                },
              ]}
            >
              <Input
                size="small"
                placeholder="Please enter"
                prefix={
                  "+1"
                  // <Form.Item>
                  //   <Select
                  //     size="small"
                  //     placeholder="+1"
                  //     className={classNames.mobileInputPrefix}
                  //     showSearch
                  //     filterOption={(input, option) =>
                  //       option.children
                  //         .toLowerCase()
                  //         .indexOf(input.toLowerCase()) >= 0
                  //     }
                  //   >
                  //     <Select.Option value="">+1</Select.Option>
                  //   </Select>
                  // </Form.Item>
                }
                defaultValue={customer && customer?.BillPhone}
              />
            </Form.Item>
            <Form.Item
              name={"sameAsBilling"}
              label={<span className={classNames.label}>Shipping Address</span>}
              valuePropName="checked"
            >
              <Checkbox
                onChange={() => setShowShippingForm((prevData) => !prevData)}
              >
                Same as billing
              </Checkbox>
            </Form.Item>
            {showShippingForm && (
              <Form.Item>
                <Form.Item
                  name={"shippingStreet"}
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street.max,
                    },
                  ]}
                >
                  <Input placeholder="Street" size="small" />
                </Form.Item>
                <Form.Item
                  name={"shippingStreet2"}
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street2.max,
                    },
                  ]}
                >
                  <Input placeholder="Street 2" size="small" />
                </Form.Item>
                <Form.Item
                  name={"shippingCity"}
                  rules={[
                    { max: MAX_MIN.city.max, message: validation.city.max },
                  ]}
                >
                  <Input placeholder="City" size="small" />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name={"shippingState"}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "left",
                    }}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="State"
                      size="small"
                    >
                      {usStateList().map((value) => (
                        <Select.Option key={value.code} value={value.name}>
                          {value.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"shippingZip"}
                    rules={[
                      {
                        pattern: new RegExp(REGEX.postalCode),
                        message: validation.postalCode.valid,
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                  >
                    <Input placeholder="Postal code" size="small" />
                  </Form.Item>
                </Form.Item>

                <Form.Item name={"shippingCountry"}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country"
                  >
                    {countryList({ usa: true }).map((value) => (
                      <Select.Option key={value.code} value={value.code}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            )}
          </Form>
        </Spin>
      </div>

      <div className={classNames.modalRight}>
        <Title
          as="h5"
          style={{ color: "#EE6969" }}
          className={classNames.label}
        >
          Mandatory fields
        </Title>

        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name={"amount"}
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input
              className={classNames.inputAmount}
              style={{
                backgroundColor: "#F6F7FA",
                hight: 54,
                border: "#fff",
              }}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              placeholder="Enter Amount"
            />
          </Form.Item>
          <Form.Item
            name="client"
            label={<span className={classNames.label}>Payment Method</span>}
          >
            <div className={classNames.paymentMethod}>
              <Radio.Group
                defaultValue="cc"
                buttonStyle="solid"
                onChange={onClickPaymentMethod}
              >
                <Space size={12}>
                  <Radio.Button
                    value="cc"
                    style={{
                      width: "120px",
                      height: "36px",
                      border: "1px solid #d1d5dd",
                    }}
                  >
                    Card
                  </Radio.Button>
                  {/* <Radio.Button
                    value="ach"
                    style={{
                      width: "120px",
                      height: "36px",
                      border: "1px solid #d1d5dd",
                    }}
                  >
                    {" "}
                    ACH/Check
                  </Radio.Button> */}
                </Space>
              </Radio.Group>
            </div>
          </Form.Item>
          {/* {paymentMethod === "cc" && (
            <Form.Item
              name={"transactionType"}
              label={
                <span className={classNames.label}>
                  Transaction Type <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              rules={[
                { required: true, message: "Transaction Type is required" },
              ]}
            >
              <Select
                size="small"
                style={{ width: 185 }}
                placeholder="Please Select"
              >
                <Select.Option value="sale">Charge</Select.Option>
                <Select.Option value="authonly">Auth Only</Select.Option>
                <Select.Option value="credit">Refund</Select.Option>
              </Select>
            </Form.Item>
          )} */}
          {paymentMethod === "cc" && (
            <>
              <div style={{ display: "inline-flex" }}>
                <Form.Item
                  name="cardNumber"
                  label={
                    <span className={classNames.label}>
                      Card Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  className={classNames.cardNumberField}
                >
                  {iFieldKey && (
                    <IField
                      type={CARD_TYPE}
                      account={account}
                      options={ccoptions}
                      threeDS={threeds}
                      onUpdate={onCcUpdate}
                      onToken={onToken}
                      ref={cardRef}
                      onError={onError}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name="cardCVV"
                  label={
                    <span className={classNames.label}>
                      CVV <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  className={classNames.cardNumberField}
                >
                  {iFieldKey && (
                    <IField
                      type={CVV_TYPE}
                      account={account}
                      options={cvvoptions}
                      threeDS={threeds}
                      onUpdate={onCVVUpdate}
                      onToken={onToken}
                      ref={cvvRef}
                      onError={onError}
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item
                name="expiry"
                label={
                  <span className={classNames.label}>
                    Expiry <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[{ required: true, message: "Expiry is required" }]}
              >
                <ExpiryInput style={{ height: 44 }} onChange={onChange} />
              </Form.Item>
            </>
          )}
          {paymentMethod === "ach" && (
            <>
              <Row gutter={[16]}>
                <Col span={12}>
                  <Form.Item
                    className={front ? classNames.hasFile : ""}
                    name="xCheckImageFront"
                    label={
                      <span className={classNames.label}>
                        {language.FRONT_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback("Max 20MB is allowed");
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={front ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageFront")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeFrontImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>{" "}
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !front ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setFront(null);
                        form.setFieldsValue({ xCheckImageFront: null });
                        form.validateFields(["xCheckImageFront"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div>{front?.name}</div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className={back ? classNames.hasFile : ""}
                    name="xCheckImageRear"
                    label={
                      <span className={classNames.label}>
                        {language.BACK_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback(`Max ${FILE_SIZE.MB} is allowed`);
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={back ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageRear")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptedType(file)) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file?.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeBackImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !back ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setBack(null);
                        form.setFieldsValue({ xCheckImageRear: null });
                        form.validateFields(["xCheckImageRear"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div> {back?.name} </div>
                  </Card>
                </Col>
              </Row>
              <Form.Item
                name="checkNum"
                label={<span className={classNames.label}>Check Number</span>}
                rules={[
                  { min: 4, message: "Invalid check number" },
                  { max: 4, message: "Invalid check number" },
                ]}
              >
                <Input
                  id="checkNum"
                  size="small"
                  placeholder={"Check Number"}
                />
              </Form.Item>
              <Form.Item
                name="accountType"
                label={
                  <span className={classNames.label}>
                    Account Type <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  { required: true, message: "Account type is required" },
                ]}
                initialValue="CHECKING"
              >
                {/* <Input
                  id="accountType"
                  size="small"
                  placeholder={"Account Type"}
                /> */}{" "}
                <Select
                  defaultValue="CHECKING"
                  placeholder={validationPayee.addPayee.dropdown}
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select.Option value="SAVINGS">Savings</Select.Option>
                  <Select.Option value="CHECKING">Checking</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="accountName"
                label={
                  <span className={classNames.label}>
                    Account Owner's Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Account owner's name is required",
                  },
                ]}
              >
                <Input
                  id="accountName"
                  size="small"
                  placeholder={"Account Owner's Name"}
                />
              </Form.Item>

              <Form.Item
                name="routingNumber"
                label={
                  <span className={classNames.label}>
                    Routing Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  { required: true, message: "Routing number is required" },
                  { max: 9, message: "Invalid routing number" },
                  { min: 9, message: "Invalid routing number" },
                ]}
              >
                <Input
                  id="routingNumber"
                  size="small"
                  placeholder={"Routing Number"}
                />
              </Form.Item>
              <Form.Item
                name="accountNumber"
                label={
                  <span className={classNames.label}>
                    Account Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                className={classNames.accountNumberField}
              >
                {iFieldKey && (
                  <IField
                    type={ACH_TYPE}
                    account={account}
                    options={achOption}
                    threeDS={threeds}
                    onUpdate={onACHUpdate}
                    onToken={onToken}
                    ref={achRef}
                    onError={onError}
                  />
                )}
              </Form.Item>
            </>
          )}

          {/* <button style={{ display: 'block' }} onClick={getToken}>Get Token</button> */}

          <Form.Item
            name="email"
            label={
              <span className={classNames.label}>
                Email Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: validation.email.required },
              {
                type: "email",
                message: validation.email.valid,
              },
              { min: MAX_MIN.email.min, message: validation.email.minLength },
              { max: MAX_MIN.email.max, message: validation.email.maxLength },
            ]}
          >
            <Input
              size="small"
              placeholder="Please enter"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.checkbox}>
              <Form.Item name={"isSendEmailReceipt"} valuePropName="checked">
                <Checkbox style={{ marginLeft: 0 }}>
                  Email receipt to customer
                </Checkbox>
              </Form.Item>
              <Form.Item name={"isSendEmailCopy"} valuePropName="checked">
                <Checkbox style={{ marginLeft: 0 }}>Send a copy to me</Checkbox>
              </Form.Item>
              {!existCustomer && (
                <Form.Item name={"isSaveCustomer"} valuePropName="checked">
                  <Checkbox style={{ marginLeft: 0 }}>Save customer</Checkbox>
                </Form.Item>
              )}
            </div>
          </Form.Item>
          <CTAButton
            htmlType="submit"
            type="primary"
            id="submit-btn"
            key="submit"
            disabled={isOnboardStatusLoading || iFieldLoading}
            loading={createTransactionMt.isLoading}
            style={{
              height: 44,
              width: 385,
            }}
          >
            Process
          </CTAButton>
        </Form>
        {/* <Ifield/> */}
      </div>
    </div>
  );
}
