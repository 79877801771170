import React, { useState, useContext, useEffect, useMemo } from "react";
import { /*useMutation,*/ useQuery } from "react-query";
import { NavLink } from "react-router-dom";
// import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import { Button } from "antd";
import Steps from "../../Steps";
import StatusTitle from "./StatusTitle";
import useUserState from "../../../../hooks/useUserState";
import api from "../../../API";
import { AppContext } from "../../../../context/AppState";
// import { clearUserData } from "../../../../context/actions/userData.ac";
import { clearApplicationData } from "../../../../context/actions/application/getApplicationByTenantId.ac";
import {
  // ROUTES,
  USER_STATES,
  // APPLICAITON_STATUS_MESSAGES,
} from "../../../constants";
import classNames from "../Signup.module.scss";

export default function Success() {
  //Hooks
  // const navigate = useNavigate();
  const userState = useUserState();
  const { /*dispatchUserData,*/ dispatchApplication } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const isClearStorage = useMemo(
    () => ![USER_STATES.APPLICATION_CANCELED].includes(userState),
    [userState]
  );

  // const isDocumentSubmitted = useMemo(
  //   () =>
  //     userState === USER_STATES.APPLICATION_AWAITING_DOCUMENT &&
  //     application?.application?.data?.data?.isRequiredDocumentUploaded,
  //   [application, userState]
  // );

  useEffect(() => {
    if (isClearStorage) {
      localStorage.removeItem("OTTERZ_op_individualApplication");
      localStorage.removeItem("OTTERZ.emailVerified");
      localStorage.removeItem("OTTERZ.verifyEmail");
      localStorage.removeItem("OTTERZ_op_accountType");
      localStorage.removeItem("OTTERZ.userData");
      localStorage.removeItem("OTTERZ.application");

      clearApplicationData()(dispatchApplication);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Event handler
  // const onLoginClick = () => {
  //   clearUserData()(dispatchUserData);
  //   // navigate(ROUTES.PUBLIC.LOGIN, { replace: true });
  //   navigate("/app/settings/plans-preferences");
  // };

  const { userData } = useContext(AppContext);

  const [verifiedOwners, setVerifiedOwners] = useState(true);
  const [ownerRejected, setOwnerRejected] = useState(false);
  
  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: async (data) => {
        const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
        await setVerifiedOwners(businessRelationsResponse?.payload?.relationShips?.every(relation => 
          relation.person_info.verification_status === "ACCEPTED"
        ));
        await setOwnerRejected(businessRelationsResponse?.payload?.relationShips?.some(relation => 
          relation.person_info.verification_status === "REJECTED"
        ));
        setLoading(false)
      },
      refetchOnWindowFocus: false,
    }
  );
  // const updateApplication = useMutation((data) => {
  //   return api.onBoarding.updateApplicationByTenantId(data);
  // });

  // const onActivateClick = () => {
  //   if (application?.payload?.onBoardingStatus === "OFFICER_CREATED") {
  //     navigate("/onboarding/business-application-documents")
  //   } else if (application?.payload?.onBoardingStatus === "BUSINESS_DOCS_UPLOADED") {
  //     navigate("/onboarding/business-operation-details");
  //   } else if(application?.payload?.onBoardingStatus === "BUSINESS_OPERATION_DETAILS_FILLED") {
  //     navigate("/onboarding/account-activity-details");
  //   } else if(application?.payload?.onBoardingStatus === "ACCOUNT_ACTIVITY_DETAILS_FILLED") {
  //     navigate("/onboarding/business-profile-success");
  //   }
  // };

  const [subPlan, setCurrSubPlan] = useState(false);
  useQuery(
      "getCurrentSubscriptionPlanId",
      () => api.Settings.getCurrentSubscriptionPlan(),
      {
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
          console.log(response?.payload);
          if(response?.payload?.data === null) {
              setCurrSubPlan(true);
          }
        },
      }
  );


  // const vkycLink = () => {
  //   if (process.env.REACT_APP_ENV.trim() === "DEV") {
  //     return "http://cardhmtl.s3.amazonaws.com/QR_Code.html";
  //   }
  //   if (
  //     process.env.REACT_APP_ENV.trim() === "TTEN" ||
  //     process.env.REACT_APP_ENV.trim() === "PROD"
  //   ) {
  //     return "http://cardhmtl.s3.amazonaws.com/QR_Code_TTEN.html";
  //   }
  //   return "http://cardhmtl.s3.amazonaws.com/QR_Code.html";
  // };

  return (
    <div className={classNames.wrapper}>
      <Steps classNames={classNames} currentIndex={4} count={5} backButtonDisabled={true} />
      <div className={classNames.success}>
        {/* <CheckboxCircleFillIcon
          size={90}
          color="#1DBC83"
          style={{ marginBottom: 34 }}
        /> */}
        <StatusTitle />
        {!loading && isClearStorage && application?.payload?.verification_status !== "REJECTED" && (
          <>
            {/* <div>{APPLICAITON_STATUS_MESSAGES.DESCRIPTION}</div> */}
            <div style={{width: "50%"}}>
               
            {/* Application reviews take ~2 days. While your application is under review,
            complete{" "}
              <a href={vkycLink()} style={{ textDecoration: "underline" }}>
                video verification
              </a>{" "}
              to unlock spend controls. */}
              {((!verifiedOwners || ownerRejected) && application?.payload?.verification_status === "ACCEPTED") ? (
                <>
                  {"We’ve mailed verification links to newly added beneficial owners. Complete verification within 15 days to resume access to your checking account."}
                </>
              ) : <>{"We've mailed verification links to all owners/officers'. Links expire in 15 days."}</> }
            </div>

            {/* <h2 className={classNames.subheading} style={{marginTop: "4rem", width: "60%", fontSize: "1rem"}}>
              Complete your business profile to activate wire transfers.
            </h2> */}
            {/* Add buttons */}
            <div className={classNames.actionButtons} style={{margin: "0.5rem 0", width: "100%"}}>
              {subPlan && <Button size="large" type="default" style={{width: "fit-content"}}>    
                <NavLink to={`/onboarding/subscription`}>Subscribe while you verify</NavLink>
              </Button>
              }
              {/* <Button size="large" type="primary" onClick={onActivateClick} style={{margin: "0 1rem"}}>
                Activate Wires Transfer
              </Button> */}
            </div>

            {/* <div className={classNames.actionButtons}>
              <Button type="primary" onClick={onLoginClick}>
                Login
              </Button>
            </div> */}
          </>
        )}

        {!loading && application?.payload?.verification_status === "REJECTED" && (
        <>
          {/* <div>{APPLICAITON_STATUS_MESSAGES.DESCRIPTION}</div> */}
          {/* <div style={{width: "65%"}}>
          We've sent verification links to all owners/officers' email IDs. Links expire in 15 days. Complete verification to submit your application. 
          </div>
          <h2 className={classNames.subheading} style={{marginTop: "4rem", width: "60%", fontSize: "1rem", display: "none"}}>
            Complete your business profile to activate wire transfers.
          </h2>
          <div className={classNames.actionButtons} style={{margin: "0.5rem 0"}}>
            {subPlan && <Button size="large" type="default" style={{width: "150px"}}>    
              <NavLink to={`/onboarding/subscription`}>Subscribe while you verify</NavLink>
            </Button>
            }
            <Button size="large" type="primary" onClick={onActivateClick} style={{margin: "0 1rem", display: "none"}}>
              Activate Wires Transfer
            </Button>
          </div> */}
        </>
        )}
        {!loading && application?.payload?.verification_status === "PENDING_REVIEW" && (
        <>
          {/* <div>{APPLICAITON_STATUS_MESSAGES.DESCRIPTION}</div> */}
          <div style={{width: "65%"}}>
          Application reviews take ~2 business days.
          {/* Application reviews take ~2 days. While your application is under review,
          complete{" "}
            <a href={vkycLink()} style={{ textDecoration: "underline" }}>
              video verification
            </a>{" "}
            to unlock spend controls. */}
          </div>
        </>
        )}
      </div>
    </div>
  );
}
