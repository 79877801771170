import { useEffect, useReducer } from "react";

const getInitialStorageState = (storageKey, initialState) => {
  const persistedState = localStorage.getItem(storageKey);
  return !!persistedState ? JSON.parse(persistedState) : initialState;
};

const usePersistedReducer = (storageKey, reducer, initialState) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialStorageState(storageKey, initialState)
  );

  useEffect(
    () => localStorage.setItem(storageKey, JSON.stringify(state)),
    [storageKey, state]
  );

  return [state, dispatch];
};

export default usePersistedReducer;
