import classNames from "../TaxDatabase.module.scss";
import { CTAButton, Input, Message } from "../../../../common";
import { Modal, Form } from "antd";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function EditTax({
  visible,
  onCancel,
  tax,
  setEditTaxCardModalVisibility,
}) {
  const [form] = Form.useForm();
  // const { Option } = Select;
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  function editTaxFn(data) {
    let input = {
      id: tax.id,
      taxRateName: data.taxName ? data.taxName : tax.name,
      taxRate: data.taxRate ? Number(data.taxRate) : Number(tax.rate),
    };
    return api.Receivables.Taxes.editTaxRate(input);
  }

  const editTaxMn = useMutation((event) => editTaxFn(event));
  const handleOnFinish = (values) => {
    editTaxMn.mutate(values, {
      onSuccess: (data, variables, context) => {
        setEditTaxCardModalVisibility({ visibility: false, record: null });
        form.resetFields();
        queryClient.invalidateQueries("getAllTaxes");
        Message({ type: "success", content: language.TAX_RATE_UPDATE_SUCCESS });
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    tax && (
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={() => {
          form.resetFields();
          onCancel();
        }}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        title="Edit Tax Rates"
        className={classNames.modal}
        bodyStyle={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
        }}
        width={434}
        footer={null}
        key={tax.key}
      >
        <div className={classNames.modalBody}>
          <Form
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={handleOnFinish}
            key={tax.key}
            initialValues={{
              taxName: tax.name,
              taxRate: tax.rate,
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item
              name="taxName"
              label={
                <span className={classNames.label}>
                  Tax Rate Name <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              initialValue={tax?.name}
              // rules={[{ required: tax?.name ? false : true, message: "Tax Rate Name is required" }]}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Tax Rate Name is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                { max: 256, message: "Max 256 characters" },
              ]}
            >
              <Input
                placeholder="Please Enter"
                size="small"
                defaultValue={tax?.name}
              />
            </Form.Item>
            <Form.Item
              name="taxRate"
              label={
                <span className={classNames.label}>
                  Tax Rate <sup style={{ top: 1 }}>*</sup>{" "}
                </span>
              }
              // rules={[
              //   {
              //     required: tax?.rate ? false : true,
              //     message: "Tax Rate is required",
              //   },
              // ]}
              initialValue={tax?.rate}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error("Tax Rate is required"));
                    }
                    return Promise.resolve();
                  },
                }),
                {
                  pattern: new RegExp(
                    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,13})?$)/g
                  ),
                  message: "Valid Tax rate is required",
                },
                // {max:15,message:"Max 16 characters"}
              ]}
            >
              <Input
                style={{ width: 220 }}
                placeholder="Enter amount"
                type="number"
                size="small"
                suffix="%"
                defaultValue={tax?.rate}
              />
              {/* <Select
            suffixIcon="%"
            placeholder="Please select"
            style={{ width: 220 }}
            defaultValue={tax?.rate}
            size="small"
          >
            <Option value={3.5}>3.5%</Option>
            <Option value={10.5}>10.5%</Option>
            <Option value={12.5}>12.5%</Option>
            <Option value={10.5}>13.5%</Option>
          </Select> */}
            </Form.Item>
            <CTAButton
              type="primary"
              style={{ height: 44, width: 384, marginTop: 20 }}
              onClick={form.submit}
              loading={editTaxMn.isLoading}
            >
              Save
            </CTAButton>
          </Form>
        </div>
      </Modal>
    )
  );
}
