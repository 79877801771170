import classNames from "./UpdateSchedule.module.scss";
import { useState } from "react";
import {
  Input,
  Select,
  DatePicker,
  CTAButton,
  Message,
  Title,
} from "../../../../common";
import { Form, Spin } from "antd";
import moment from "moment";
import api from "../../../../API";
import { useMutation, useQuery } from "react-query";
import generateErrorMessage from "../../../../util/functions/customError";

export default function UpdateScheduleForm({
  onCancel,
  queryClient,
  selectedRecord,
}) {
  const [form] = Form.useForm();

  const { isFetching: isCustomerInfoFetching } = useQuery(
    "GetCustomerInfoById",
    async () =>
      await api.Receivables.PaymentAcceptance.getCardknoxCustomer({
        CustomerId: selectedRecord.CustomerId,
      }),
    {
      enabled: !!selectedRecord.CustomerId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const {
          BillCompany = "",
          BillFirstName = "",
          BillLastName = "",
        } = data?.data || {};

        form.setFieldsValue({
          firstName: BillFirstName,
          lastName: BillLastName,
          company: BillCompany,
        });
      },
    }
  );

  const [endScheduleType, setEndScheduleType] = useState(
    selectedRecord?.EndDate ? "date" : "never"
  );
  const [options] = useState({});

  function disabledDateBefore(current) {
    return moment().add(-1, "days") >= current;
  }

  function disabledDateBeforeEndDate(current) {
    let startDateValue = form.getFieldValue("startDate");
    return moment(startDateValue).add(1, "days") > current;
  }

  function initialValueForEndDate() {
    let startDateValue = form.getFieldValue("startDate");
    return moment(startDateValue).add(1, "days");
  }

  // NOTE: PENDING CLARIFICATION
  // const onChangeOptions = (name, value) => {
  //   setOptions(Object.assign({}, options, { [name]: value }));
  // };

  const onFinish = (values) => {
    // removing as below params are not allowed to update
    // and if exist in the request, Cardknox throws an error
    // for unexpected parameter existence
    delete values.firstName;
    delete values.lastName;
    delete values.company;

    values.startDate = moment(values.startDate).format("YYYY-MM-DD");
    values.endDate = moment(values.endDate).format("YYYY-MM-DD");

    updateScheduleMutation.mutate(values, {
      onSuccess: (data, variables, context) => {
        Message({
          type: "success",
          content: "Schedule Updated Successfully",
        });
        onCancel();
        form.resetFields();
        queryClient.invalidateQueries("listSchedule");
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  function updateScheduleFn(data) {
    return api.Receivables.PaymentAcceptance.updateSchedule(
      Object.assign({}, data, {
        ...options,
        scheduleId: selectedRecord.ScheduleId,
        revision: selectedRecord.Revision,
      })
    );
  }

  const updateScheduleMutation = useMutation((event) =>
    updateScheduleFn(event)
  );

  return (
    <Spin spinning={isCustomerInfoFetching}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <div style={{ display: "flex" }}>
          <div className={classNames.modalLeft}>
            <div>
              <Title
                as="h5"
                style={{ color: "#BC7C1D" }}
                className={classNames.label}
              >
                General Information
              </Title>
            </div>

            <Form.Item>
              <Form.Item
                name={"firstName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    First Name<sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <Input placeholder="" size="small" disabled />
              </Form.Item>
              <Form.Item
                name={"lastName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    Last Name<sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <Input placeholder="" size="small" disabled />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={"company"}
              label={<span className={classNames.label}>Company</span>}
            >
              <Input size="small" placeholder="Please enter" disabled />
            </Form.Item>
            <Form.Item
              name="invoice"
              label={<span className={classNames.label}>Invoice</span>}
              rules={[{ max: 128, message: "Max 128 characters" }]}
              initialValue={selectedRecord?.Invoice}
            >
              <Input
                size="small"
                style={{ width: "49%" }}
                placeholder="Please enter"
              />
            </Form.Item>
          </div>
          <div className={classNames.modalRight}>
            <Form.Item
              rules={[
                { required: true, message: "Amount is required" },
                {
                  pattern: new RegExp(/^\d{0,500}(\.\d{2,2})?$/),
                  message: "Enter a valid amount",
                },
              ]}
              name="amount"
              label={
                <span className={classNames.label}>
                  Amount <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              initialValue={String(selectedRecord?.Amount?.toFixed(2))}
            >
              <Input
                className={classNames.inputAmount}
                style={{
                  backgroundColor: "#F6F7FA",
                  hight: 54,
                  border: "#fff",
                }}
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                placeholder="Enter Amount"
                maxlength="16"
              />
            </Form.Item>

            <Form.Item
              label={<span className={classNames.label}>Start Date</span>}
              style={{
                display: "inline-block",
                marginRight: 14,
                marginTop: 15,
              }}
              name={"startDate"}
              initialValue={moment(selectedRecord?.StartDate)}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                placeholder="YYYY-MM-DD"
                size="small"
                style={{ height: 44, width: 175 }}
                disabledDate={disabledDateBefore}
              />
            </Form.Item>

            <Form.Item
              label={<span className={classNames.label}>End Date</span>}
              style={{
                display: "inline-block",
                marginTop: 15,
              }}
              initialValue={selectedRecord?.EndDate ? "date" : "never"}
            >
              <Select
                size="small"
                style={{ width: 175 }}
                onSelect={(val) => setEndScheduleType(val)}
                value={endScheduleType}
              >
                <Select.Option value={"never"}>Never</Select.Option>
                <Select.Option value={"date"}>Date</Select.Option>
              </Select>
            </Form.Item>
            {endScheduleType === "date" && (
              <Form.Item
                label={<span className={classNames.label}>End Date</span>}
                style={{
                  marginTop: 8,
                }}
                name={"endDate"}
                initialValue={initialValueForEndDate()}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  placeholder="YYYY-MM-DD"
                  size="small"
                  style={{ height: 44, width: 175 }}
                  disabledDate={disabledDateBeforeEndDate}
                  // disabled={!form.getFieldValue("startDate")}
                />
              </Form.Item>
            )}

            {
              //#region
            }

            {/* PENDING CLARIFICATION */}
            {/* 
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.checkbox}>
              <Checkbox
                style={{ marginLeft: 0 }}
                onChange={(e) =>
                  onChangeOptions(
                    "useDefaultPaymentMethodOnly",
                    e.target.checked
                  )
                }
              >
                Retry declined recurring with default card only
              </Checkbox>
            </div>
          </Form.Item> */}

            {
              //#endregion
            }

            <Form.Item
              style={{
                display: "inline-block",
                marginRight: 14,
              }}
              name={"failedTransactionRetryTimes"}
              initialValue={selectedRecord?.FailedTransactionRetryTimes}
            >
              <span style={{ color: "#82898F" }}>After</span>
              <Select
                size="small"
                style={{ width: 110, margin: 10 }}
                value={selectedRecord?.FailedTransactionRetryTimes}
              >
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: "inline-block",
              }}
              name={"daysBetweenRetries"}
              initialValue={selectedRecord?.DaysBetweenRetries}
            >
              <span style={{ color: "#82898F" }}>Tries With</span>
              <Select
                size="small"
                style={{ width: 110, margin: 10 }}
                value={selectedRecord?.DaysBetweenRetries}
              >
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: "inline-block",
              }}
            >
              <span style={{ color: "#82898F" }}> Day(s) between Retries:</span>
              <Select size="small" style={{ width: 190, margin: 10 }}>
                <Select.Option>Disable recurring scheduling</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={classNames.modalFooter}>
          <CTAButton
            loading={updateScheduleMutation.isLoading}
            htmlType="submit"
            type="primary"
            key="submit"
            id="submit-btn"
            style={{
              height: 44,
              width: 385,
              float: "right",
              marginBottom: "100px !important",
            }}
          >
            Save
          </CTAButton>
        </div>
      </Form>
    </Spin>
  );
}
