import { Form } from "antd";
import { Modal, CTAButton, Title, Input } from "../../common";
import REGEX from "../../util/constants/regex";
import messages from "../config/validation.messages";

import classNames from "./Overview.module.scss";

export default function FundAmount({
  visible,
  setVisible,
  onCancel,
  setFundAmount,
  balance,
  withdraw,
}) {
  //Hooks
  const [form] = Form.useForm();

  //Event handlers
  const handleOnfinish = (values) => {
    setFundAmount(values.amount);
    form.resetFields();
    setVisible(false);
  };

  const onCloseClick = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCloseClick}
      destroyOnClose={true}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8, textAlign: "center" }}>
        Enter amount
      </Title>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={handleOnfinish}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: messages.quickFundTransfer.amount },
            {
              validator: async (_, value) => {
                if (Number(value) === 0) {
                  return Promise.reject(
                    new Error(messages.quickFundTransfer.validAmount)
                  );
                } else if (typeof balance !== "number") {
                  return Promise.resolve();
                } else if (withdraw && Number(value) * 100 >= balance) {
                  return Promise.reject(
                    new Error(messages.quickFundTransfer.insufficientFund)
                  );
                }
              },
            },
            {
              pattern: new RegExp(REGEX.amount),
              message: messages.quickFundTransfer.validAmount,
            },
          ]}
        >
          <Input
            placeholder={messages.quickFundTransfer.amountPlaceholder}
            size="small"
            prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            min={0}
          />
        </Form.Item>
        <Form.Item>
          <div className={classNames.confirmModalCTA}>
            <CTAButton
              onClick={onCloseClick}
              type="secondary"
              style={{
                height: 44,
                width: "47%",
                color: "#9BA3AB",
                border: "1px solid #9BA3AB",
              }}
            >
              Cancel
            </CTAButton>
            <CTAButton
              htmlType="submit"
              type="primary"
              style={{
                height: 44,
                width: "47%",
                border: "none",
              }}
            >
              Submit
            </CTAButton>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
