import React from "react";

const RegE = () => {
  return (
    <>
      <p>
        Telephone us at 857-234-4000, or email us at{" "}
        <a href="mailto:support@otterz.co" className="link">support@otterz.co</a>, as soon as you
        can, if you think your statement or receipt is wrong or if you need more
        information about a transfer on the statement or receipt. We must hear
        from you no later than 60 days after we sent you the FIRST statement on
        which the error or problem appeared.
      </p>
      <ol>
        <li>
          <span>Tell us your name and account number (if any).</span>
        </li>
        <li>
          <span>
            Describe the error or the transfer you are unsure about and explain
            as clearly as you can why you believe it is an error or why you need
            more information.
          </span>
        </li>
        <li>
          <span>
            Tell us the dollar amount of the suspected error and the date when
            the transfer took place.
          </span>
        </li>
      </ol>
      <p>
        If you tell us orally, we may require that you send us your complaint or
        question in writing within ten (10) business days.
      </p>
      <p>
        We will determine whether an error occurred within 10 business days
        after we hear from you and will correct any error promptly. If we need
        more time, however, we may take up to 45 days to investigate your
        complaint or question. If we decide to do this, we will provide you with
        a provisional credit to your account within 10 business days for the
        amount you think is in error so that you will have use of the money
        during the time it takes us to complete our investigation. If we ask you
        to put your complaint or question in writing and we do not receive it
        within 10 business days, we may not provide a provisional credit to your
        account.
      </p>
      <p>
        For errors involving new accounts, point-of-sale, or foreign-initiated
        transactions, we may take up to 90 days to investigate your complaint or
        question. For new accounts, we may take up to 20 business days to credit
        your account for the amount you think is in error.
      </p>
      <p>
        We will advise you of the results within three (3) business days after
        completing our investigation. If we decide that an error didn’t occur,
        we will withdraw any previously issued provisional credit placed in your
        account and send you a written explanation of our decision. Note that
        you may ask for copies of the documents that we used in our
        investigation.
      </p>
    </>
  );
};

export default RegE;
