import React, { useState, useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import mixpanel from "mixpanel-browser";
import { Button, Switch } from 'antd';

import SubscriptionPlanCard from './SubscriptionCard';
import classNames from './styles.module.scss';
import api from "../../API";
import { AppContext } from "../../../context/AppState";
import { tempUrls } from "../../../apiEndpoints";
import { Message } from "../../common";
import Bugsnag from "@bugsnag/js";

const env = process.env.REACT_APP_ENV?.trim();
const ProLink = tempUrls.PAYMENT_LINK_PRO[env];
const EliteLink = tempUrls.PAYMENT_LINK_ELITE[env];
const EnterpriseLink = tempUrls.PAYMENT_LINK_ENTERPRISE[env];
const ProLinkAnnual = tempUrls.PAYMENT_LINK_PRO_ANNUAL[env];
const EliteLinkAnnual = tempUrls.PAYMENT_LINK_ELITE_ANNUAL[env];
const EnterpriseLinkAnnual = tempUrls.PAYMENT_LINK_ENTERPRISE_ANNUAL[env];

const subscriptionPlans = {
    monthly: {
        // starter: {
        //     planName: 'Starter',
        //     price: 0,
        //     description: '',
        //     planInclude: '',
        //     // subDescription: 'Peace of Mind',
        //     link: '',
        //     features: [
        //         {
        //             name: "Business Checking Account",
        //             isAvailable: true,
        //         },
        //         {
        //             name: "Free ATM Withdrawals, ACH, Wires",
        //             isAvailable: true,
        //         },
        //         // {
        //         //     name: "ACH, Wires",
        //         //     isAvailable: true,
        //         // },
        //         {
        //             name: "Unlimited Invoices",
        //             isAvailable: true,
        //         },
        //         // {
        //         //     name: "Payments",
        //         //     extraInfo: "2.7% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "eCommerce Payments",
        //         //     extraInfo: "2.9% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "Invoice Payments",
        //         //     extraInfo: "2.9% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "Automated Accounts Payable",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "AI Insights",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "Bookkeeping",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "Customer Support",
        //         //     extraInfo: "Office Hours",
        //         //     isAvailable: true,
        //         // },
        //     ],
        //     transactionVol: '',
        // },
        pro: {
            planName: 'Pro',
            price: 300,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: < $10k',
            // subDescription: 'High Sales Volume',
            link: `${ProLink}`,
            paymentRate: "2.6% + 30c",
            addOn: ' $1300',
            features: [
                // {
                //     name: "Business Checking Account",
                //     isAvailable: true,
                // },
                // {
                //     name: "Free ATM Withdrawals",
                //     isAvailable: true,
                // },
                // {
                //     name: "ACH, Wires",
                //     isAvailable: true,
                // },
                // {
                //     name: "Unlimited Invoices",
                //     isAvailable: true,
                // },
                // {
                //     name: "Payments",
                //     extraInfo: "2.5% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "eCommerce Payments",
                //     extraInfo: "2.6% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Invoice Payments",
                //     extraInfo: "2.6% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Automated Accounts Payable",
                //     isAvailable: true,
                // },
                // {
                //     name: "AI Insights",
                //     isAvailable: true,
                // },
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: false,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: false,
                },
                {
                    name: "Payroll Support",
                    isAvailable: false,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: false,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
                // {
                //     name: "Customer Support",
                //     extraInfo: "Office Hours",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        elite: {
            planName: 'Elite',
            price: 600,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: $10k - $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "0.10% + 30c",
            link: `${EliteLink}`,
            addOn: ' $1000',
            features: [
                // {
                //     name: "Business Checking Account",
                //     isAvailable: true,
                // },
                // {
                //     name: "Free ATM Withdrawals",
                //     isAvailable: true,
                // },
                // {
                //     name: "ACH, Wires",
                //     isAvailable: true,
                // },
                // {
                //     name: "Unlimited Invoices",
                //     isAvailable: true,
                // },
                // {
                //     name: "Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "eCommerce Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Invoice Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: true,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: true,
                },
                {
                    name: "Payroll Support",
                    isAvailable: true,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: false,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
                // {
                //     name: "AI Insights",
                //     isAvailable: true,
                // },
                // {
                //     name: "Bookkeeping",
                //     isAvailable: true,
                // },
                // {
                //     name: "Customer Support",
                //     extraInfo: "24 x 7",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        enterprise: {
            // save: "Free Tax Filing worth $2,000",
            planName: 'Enterprise',
            price: 1000,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: > $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "0.10% + 30c",
            link: `${EnterpriseLink}`,
            addOn: ' $1000',
            features: [
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: true,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: true,
                },
                {
                    name: "Payroll Support",
                    isAvailable: true,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: true,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: true,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers high volume of transactions'
        },
    },
    annual: {
        // starter: {
        //     planName: 'Starter',
        //     price: 0,
        //     // description: 'Streamline your business with Smart Banking, Payments and Invoicing',
        //     // subDescription: 'Peace of Mind',
        //     link: '',
        //     planInclude: '',
        //     features: [
        //         {
        //             name: "Business Checking Account",
        //             isAvailable: true,
        //         },
        //         {
        //             name: "Free ATM Withdrawals, ACH, Wires",
        //             isAvailable: true,
        //         },
        //         // {
        //         //     name: "ACH, Wires",
        //         //     isAvailable: true,
        //         // },
        //         {
        //             name: "Unlimited Invoices",
        //             isAvailable: true,
        //         },
        //         // {
        //         //     name: "Payments",
        //         //     extraInfo: "2.7% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "eCommerce Payments",
        //         //     extraInfo: "2.9% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "Invoice Payments",
        //         //     extraInfo: "2.9% + 30c",
        //         //     isAvailable: true,
        //         // },
        //         // {
        //         //     name: "Automated Accounts Payable",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "AI Insights",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "Bookkeeping & Tax Filing",
        //         //     isAvailable: false,
        //         // },
        //         // {
        //         //     name: "Customer Support",
        //         //     extraInfo: "Office Hours",
        //         //     isAvailable: true,
        //         // },
        //     ],
        //     transactionVol: ''
        // },
        pro_annual: {
            // save: "Free Books Cleanup worth $3,500",
            planName: 'Pro',
            price: 300,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: < $10k',
            // subDescription: 'High Sales Volume',
            paymentRate: "2.6% + 30c",
            link: `${ProLinkAnnual}`,
            addOn: ' $1000',
            features: [
                // {
                //     name: "Business Checking Account",
                //     isAvailable: true,
                // },
                // {
                //     name: "Free ATM Withdrawals",
                //     isAvailable: true,
                // },
                // {
                //     name: "ACH, Wires",
                //     isAvailable: true,
                // },
                // {
                //     name: "Unlimited Invoices",
                //     isAvailable: true,
                // },
                // {
                //     name: "Payments",
                //     extraInfo: "2.5% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "eCommerce Payments",
                //     extraInfo: "2.6% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Invoice Payments",
                //     extraInfo: "2.6% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Automated Accounts Payable",
                //     isAvailable: true,
                // },
                // {
                //     name: "AI Insights",
                //     isAvailable: true,
                // },
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Books Cleanup : 6 months Catch up",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: false,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: false,
                },
                {
                    name: "Payroll Support",
                    isAvailable: false,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: false,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
                // {
                //     name: "Payment Acceptance Solution",
                //     isAvailable: true,
                // },
                // {
                //     name: "Financial Reporting",
                //     isAvailable: true,
                // },
                // {
                //     name: "Customer Support",
                //     extraInfo: "Office Hours",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        elite_annual: {
            save: "Free Tax Filing",
            planName: 'Elite',
            price: 600,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: $10k - $50k',
            paymentRate: "0.10% + 30c",
            // subDescription: 'Lowest Processing Fees',
            link: `${EliteLinkAnnual}`,
            features: [
                // {
                //     name: "Business Checking Account",
                //     isAvailable: true,
                // },
                // {
                //     name: "Free ATM Withdrawals",
                //     isAvailable: true,
                // },
                // {
                //     name: "ACH, Wires",
                //     isAvailable: true,
                // },
                // {
                //     name: "Unlimited Invoices",
                //     isAvailable: true,
                // },
                // {
                //     name: "Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "eCommerce Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Invoice Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Books Cleanup : 18 months Catch up",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: true,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: true,
                },
                {
                    name: "Payroll Support",
                    isAvailable: true,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: false,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
                // {
                //     name: "AI Insights",
                //     isAvailable: true,
                // },
                // {
                //     name: "Bookkeeping & Tax Filing",
                //     isAvailable: true,
                // },
                // {
                //     name: "Customer Support",
                //     extraInfo: "24 x 7",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        enterprise_annual: {
            save: "Free Tax Filing",
            planName: 'Enterprise',
            price: 1000,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: > $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "0.10% + 30c",
            link: `${EnterpriseLinkAnnual}`,
            features: [
                {
                    name: "Daily Bookkeeping (Accrual, Cash Basis)",
                    isAvailable: true,
                },
                {
                    name: "Chart of Accounts ",
                    isAvailable: true,
                },
                {
                    name: "Books Cleanup : Complete Catch up",
                    isAvailable: true,
                },
                {
                    name: "Accounts Payable Manager",
                    isAvailable: true,
                },
                {
                    name: "Invoicing Manager",
                    isAvailable: true,
                },
                {
                    name: "Payroll Support",
                    isAvailable: true,
                },
                {
                    name: "Multi-Business Support",
                    isAvailable: true,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: true,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers high volume of transactions'
        },
    }
}

const Subscription = ({onSubmit, selectedSubscriptionPlan}) => {
    const [, setSubscriptionPlan] = useState([]);
    const [duration, setDuration] = useState('annual'); // New state for duration
    const { userData } = useContext(AppContext);
    const queryClient = useQueryClient();
    const currentPlanName = useMemo(()=> {
        if (!selectedSubscriptionPlan ||!selectedSubscriptionPlan.data) {
            return null;
        }

        switch (selectedSubscriptionPlan.data.plan_name) {
            case 'starter':
            case 'custom_starter':
                return 'starter';
            default:
                return selectedSubscriptionPlan.data.plan_name;
        }
    }, [selectedSubscriptionPlan])

    // Custom switch styling
    const switchStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f6f7fa',
        borderRadius: '22px',
        position: 'relative',
    };

    const switchButtonStyle = {
        background: '#7372FF',
        transform: "scale(1.5)",
    };

    const currentPlanID = useMemo(()=> selectedSubscriptionPlan?.data?.plan_id ?? null, [selectedSubscriptionPlan])
    const isCardInfoReceived = useMemo(()=> currentPlanID && selectedSubscriptionPlan?.data?.plan_id !=='custom_starter', [selectedSubscriptionPlan, currentPlanID])

    useQuery(
        "getSubscriptions",
        () => api.Settings.getSubscriptions({tenant_id: userData?.tenantId}),
        {
          refetchOnWindowFocus: false,
          onSuccess: (response) => {
            setSubscriptionPlan(response?.payload);
          },
        }
      );

    const onSelectSubscription = (planName, link) => {
        queryClient.invalidateQueries("getCurrentSubscriptionPlanId");

        mixpanel.track("User selects a subscription plan", {
            "Email ID": userData.email,
            "Phone Number": userData.phone_number,
            "Plan name": planName,
          });

        if (!link) {
            // user selects starter plan
            if (isCardInfoReceived) {
                // existing user with card selects starter
                onSubmit({ plan_name: 'starter' })
                return;
            }

            // new user without card selects starter
            onSubmit()
            return;
        }

        if (selectedSubscriptionPlan?.data?.plan_name === "custom_starter") {
            const clientReferenceID = userData?.tenantId.split('.')[1] ?? '';
            const url = `${link}?client_reference_id=${clientReferenceID}&prefilled_email=${userData?.email}`;
    
            window.open(url);
            startPollingForSubscriptionStatus();
            return;
        }
        
        if (isCardInfoReceived) {
            // existing user with card info changes their plan
            onSubmit({ plan_name: planName })
            return;
        }


        // existing user without card info pays for the first time
        const clientReferenceID = userData?.tenantId.split('.')[1] ?? '';
        const url = `${link}?client_reference_id=${clientReferenceID}&prefilled_email=${userData?.email}`;
        startPollingForSubscriptionStatus();
        window.open(url);
    }

    const startPollingForSubscriptionStatus = () => {
        const intervalId = setInterval(async () => {
            queryClient.invalidateQueries("getCurrentSubscriptionPlanId");
            if (currentPlanName !== null && currentPlanName !== "starter") {
                clearInterval(intervalId);
            }
        }, 5000); // Poll every 5 seconds, adjust as needed
    }

    const sendEmailInternal = useMutation(
        (data) => api.Receivables.PaymentAcceptOnboard.sendInternalMail(data, false),
        {
          onSuccess: (data, variables) => {
            // This function will be called when the mutation is successful
            Bugsnag.notify(data)
            Message({ type: "success", content: `Your request to enable ${variables.formData.tax_filling_type.toLowerCase()} has been captured.` });
          },
          // You can also handle errors using the onError callback if needed
          onError: (error) => {
            // Handle error
            Message({ type: "error", content: "Tax filling Request Failed" });
          },
        }
      );
      
      // Event handlers
      const sendTaxFillingMail = (data) => {
        const reqBody = {
          templateName: "TAXFILLING",
          tenantId: userData?.tenantId,
          formData: {
            tax_filling_type: data,
            email: userData?.email,
          },
        };
        sendEmailInternal.mutate(reqBody);
      };

    return (
        <>
            {/* AntD Switch for selecting duration */}
            <div className={classNames.switchContainer} style={switchStyle}>
                <span style={{ marginRight: '25px', fontWeight: 600, color: duration === 'monthly' ? '#7372FF' : '#82898FC2' }}>Monthly</span> {/* Updated this */}
                <Switch
                    style={switchButtonStyle}
                    checked={duration === 'annual'}
                    onChange={(checked) => setDuration(checked ? 'annual' : 'monthly')}
                    trackColor={{ true: '#7372FF', false: '#7372FF' }} // Always color the track
                    thumbColor={duration === 'annual' ? '#f5f5f5' : '#7372FF'} // Change thumb color based on selection
                />
                <span style={{ marginLeft: '25px', fontWeight: 600, color: duration === 'annual' ? '#7372FF' : '#82898FC2' }}>Annual</span> {/* Updated this */}
            </div>
            <div className={classNames.container} style={{
                flexDirection: 'column'
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                {/* Render subscription cards based on selected duration */}
                {Object.keys(subscriptionPlans[duration]).map((planName, index) => {
                    const data = subscriptionPlans[duration][planName];

                    if (!data) {
                        return null;
                    }

                    return (
                        <SubscriptionPlanCard
                            key={index}
                            planName={data.planName}
                            price={data.price}
                            description={data.description}
                            subDescription={data.subDescription}
                            planInclude={data.planInclude}
                            paymentRate={data.paymentRate}
                            features={data.features}
                            onSubscribe={() => onSelectSubscription(planName, data.link)}
                            selected={currentPlanName === planName}
                            save={data?.save}
                            transactionVol={data.transactionVol}
                            addOn={data?.addOn}
                        />
                    );
                })}
                </div>
                <div className={classNames.taxContainer}>
                    <div className={classNames.taxCard}>
                        <p className={classNames.taxPara}><strong>Enable Tax Filing to enjoy a Stress Free Tax Season <br/></strong>(rates are subject to your active subscription plan)</p>
                        <div style={{display: 'flex', gap: '2vw'}}>
                            <Button type="primary" disabled={currentPlanName === null} onClick={() => { sendTaxFillingMail("Corporate Tax filling")}} style={{width: 'fit-content'}} className={[classNames.taxButton]}>Enable Corporate Tax Filing</Button>
                            <Button type="primary" disabled={currentPlanName === null} onClick={() => { sendTaxFillingMail("Personal Tax filling")}} style={{width: 'fit-content'}} className={[classNames.taxButton]}>Enable Personal Tax Filing</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Subscription;
