import React, { useEffect, useState } from "react";
import { Form, Checkbox } from "antd";
import { useMutation } from "react-query";
import generateErrorMessage from "../../../../util/functions/customError";
import { Input, Title, CTAButton, Message, Select } from "../../../../common";
import classNames from "./ManageCustomer.module.scss";
import api from "../../../../API";
import { validation } from "../../../../Auth/config";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";
import { countryList, usStateList } from "../../../../util";

export default function ManageCustomerForm({
  updatingCustomer = null,
  onCustomerModelClose,
}) {
  const [isSubmitButtonDisabled, setSubmitDisable] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form]);

  const { mutate: createCardknoxCustomer } = useMutation(
    api.Receivables.PaymentAcceptance.createCardknoxCustomer,
    {
      onSuccess: (response) => {
        Message({
          type: "success",
          content: `Customer created (ID: ${response?.data?.CustomerId})`,
        });
        onCustomerModelClose(true);
        form.resetFields();
      },
      onError: (error) => {
        generateErrorMessage(error);
        setSubmitDisable(false);
      },
    }
  );

  const { mutate: updateCardknoxCustomer } = useMutation(
    async (data) => {
      const customerObj = {
        ...updatingCustomer,
        ...data,
      };
      delete customerObj.CreatedDate;
      delete customerObj.BasicScheduleData;
      await api.Receivables.PaymentAcceptance.updateCardknoxCustomer(
        customerObj
      );
    },
    {
      onSuccess: () => {
        Message({
          type: "success",
          content: `Customer updated (ID: ${updatingCustomer?.CustomerId})`,
        });
        onCustomerModelClose(true);
        form.resetFields();
      },
      onError: (error) => {
        generateErrorMessage(error);
        setSubmitDisable(false);
      },
    }
  );

  const [sameAsBilling, setSameAsBilling] = useState(
    !!updatingCustomer?.CustomerCustom01 || false
  );
  if (sameAsBilling) {
    const shippingFormValues = {};
    [
      "BillFirstName",
      "BillLastName",
      "BillCompany",
      "BillStreet",
      "BillStreet2",
      "BillCity",
      "BillState",
      "BillZip",
      "BillCountry",
      "BillPhone",
    ].forEach((billField) => {
      shippingFormValues[billField.replace("Bill", "Ship")] =
        form.getFieldValue(billField);
    });
    form.setFieldsValue({
      ...shippingFormValues,
      ShipEmail: form.getFieldValue("Email"),
    });
  }

  const onSameAsBillingChecked = (e) => {
    if (!e.target.checked) {
      form.setFieldsValue({
        ShipFirstName: "",
        ShipLastName: "",
        ShipCompany: "",
        ShipStreet: "",
        ShipStreet2: "",
        ShipCity: "",
        ShipState: "",
        ShipZip: "",
        ShipCountry: "",
        ShipPhone: "",
        ShipEmail: "",
      });
    }

    setSameAsBilling(e.target.checked);
  };

  const manageCustomer = (data) => {
    if (isSubmitButtonDisabled) {
      return;
    }
    setSubmitDisable(true);
    form.validateFields().then(() => {
      data.sameAsBilling = sameAsBilling;
      updatingCustomer
        ? updateCardknoxCustomer(data)
        : createCardknoxCustomer(data);
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div className={classNames.modalLeft}>
        <div>
          <Title
            as="h5"
            style={{ color: "#BC7C1D" }}
            className={classNames.label}
          >
            General Information
          </Title>
        </div>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={manageCustomer}
          initialValues={updatingCustomer}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item>
            <Form.Item
              name="BillFirstName"
              label={
                <span className={classNames.label}>
                  First Name <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: validation.firstName.required },

                {
                  max: MAX_MIN.name.max,
                  message: validation.firstName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.firstName.valid,
                },
              ]}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>
            <Form.Item
              name="BillLastName"
              label={
                <span className={classNames.label}>
                  Last Name <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              rules={[
                { required: true, message: validation.lastName.required },
                {
                  max: MAX_MIN.name.max,
                  message: validation.lastName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.lastName.valid,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="BillCompany"
            label={
              <span className={classNames.label}>
                Company <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: "Company name is required" },
              {
                max: MAX_MIN.businessName.max,
                message: validation.businessName.max,
              },
              {
                pattern: new RegExp(REGEX.businessName),
                message: "Enter a valid company name",
              },
            ]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            label={<span className={classNames.label}>Billing Address</span>}
          >
            <Form.Item
              name="BillStreet"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street.max,
                },
              ]}
            >
              <Input placeholder="Street" size="small" />
            </Form.Item>
            <Form.Item
              name="BillStreet2"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street2.max,
                },
              ]}
            >
              <Input placeholder="Street 2" size="small" />
            </Form.Item>
            <Form.Item
              name="BillCity"
              rules={[{ max: MAX_MIN.city.max, message: validation.city.max }]}
            >
              <Input placeholder="City" size="small" />
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name="BillState"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State"
                  size="small"
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="BillZip"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  {
                    pattern: new RegExp(REGEX.postalCode),
                    message: validation.postalCode.valid,
                  },
                ]}
              >
                <Input placeholder="Postal code" size="small" />
              </Form.Item>
            </Form.Item>

            <Form.Item name="BillCountry">
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country"
                size="small"
              >
                {countryList(true).map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="BillPhone"
            label={<span className={classNames.label}>Phone Number</span>}
            rules={[
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input
              size="small"
              placeholder="Please enter"
              prefix={
                "+1"
                // <Form.Item>
                //   <Select
                //     size="small"
                //     placeholder="+1"
                //     className={classNames.mobileInputPrefix}
                //     showSearch
                //     filterOption={(input, option) =>
                //       option.children
                //         .toLowerCase()
                //         .indexOf(input.toLowerCase()) >= 0
                //     }
                //   >
                //     <Select.Option value="">+1</Select.Option>
                //   </Select>
                // </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item
            name="Email"
            label={
              <span className={classNames.label}>
                Email Address <sup style={{ top: 1 }}></sup>
              </span>
            }
            rules={[
              {
                type: "email",
                message: validation.email.valid,
              },
              { min: MAX_MIN.email.min, message: validation.email.minLength },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
            ]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            label={<span className={classNames.label}>Shipping Details</span>}
          >
            <Checkbox
              name={"sameAsBilling"}
              checked={sameAsBilling}
              onChange={onSameAsBillingChecked}
            >
              Same as billing
            </Checkbox>
          </Form.Item>
          {sameAsBilling ? null : (
            <>
              <Form.Item>
                <Form.Item
                  name="ShipFirstName"
                  label={
                    <Title as="h4" className={classNames.label}>
                      Shipping First Name
                    </Title>
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginBottom: "10px",
                    float: "left",
                  }}
                  rules={[
                    {
                      max: MAX_MIN.name.max,
                      message: validation.firstName.maxLength,
                    },
                    {
                      pattern: new RegExp(REGEX.name),
                      message: validation.firstName.valid,
                    },
                  ]}
                >
                  <Input placeholder="Please enter" size="small" />
                </Form.Item>
                <Form.Item
                  name="ShipLastName"
                  label={
                    <Title as="h4" className={classNames.label}>
                      Shipping Last Name
                    </Title>
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    margin: "0 0 0 4px",
                    float: "right",
                  }}
                  rules={[
                    {
                      max: MAX_MIN.name.max,
                      message: validation.lastName.maxLength,
                    },
                    {
                      pattern: new RegExp(REGEX.name),
                      message: validation.lastName.valid,
                    },
                  ]}
                >
                  <Input placeholder="Please enter" size="small" />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="ShipCompany"
                label={
                  <span className={classNames.label}>Shipping Company</span>
                }
                rules={[
                  {
                    max: MAX_MIN.businessName.max,
                    message: validation.businessName.max,
                  },
                ]}
              >
                <Input size="small" placeholder="Please enter" />
              </Form.Item>
              <Form.Item
                label={
                  <span className={classNames.label}>Shipping Address</span>
                }
              >
                <Form.Item
                  name="ShipStreet"
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street.max,
                    },
                  ]}
                >
                  <Input placeholder="Shipping Street" size="small" />
                </Form.Item>
                <Form.Item
                  name="ShipStreet2"
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street2.max,
                    },
                  ]}
                >
                  <Input placeholder="Shipping Street 2" size="small" />
                </Form.Item>
                <Form.Item
                  name="ShipCity"
                  rules={[
                    { max: MAX_MIN.city.max, message: validation.city.max },
                  ]}
                >
                  <Input placeholder="Shipping City" size="small" />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    name="ShipState"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "left",
                    }}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="State"
                      size="small"
                    >
                      {usStateList().map((value) => (
                        <Select.Option key={value.code} value={value.code}>
                          {value.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="ShipZip"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                    rules={[
                      {
                        pattern: new RegExp(REGEX.postalCode),
                        message: validation.postalCode.valid,
                      },
                    ]}
                  >
                    <Input placeholder="Shipping Postal code" size="small" />
                  </Form.Item>
                </Form.Item>
                <Form.Item name="ShipCountry">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country"
                    size="small"
                  >
                    {countryList(true).map((value) => (
                      <Select.Option key={value.code} value={value.code}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="ShipPhone"
                label={
                  <span className={classNames.label}>
                    Shipping Phone Number
                  </span>
                }
                rules={[
                  {
                    pattern: new RegExp(REGEX.phoneNumber),
                    message: "Enter a valid phone number",
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Please enter"
                  prefix={
                    "+1"
                    // <Form.Item>
                    //   <Select
                    //     size="small"
                    //     placeholder="+1"
                    //     className={classNames.mobileInputPrefix}
                    //     showSearch
                    //     filterOption={(input, option) =>
                    //       option.children
                    //         .toLowerCase()
                    //         .indexOf(input.toLowerCase()) >= 0
                    //     }
                    //   >
                    //     <Select.Option value="+1">+1</Select.Option>
                    //   </Select>
                    // </Form.Item>
                  }
                />
              </Form.Item>
              <Form.Item
                name="ShipEmail"
                label={
                  <span className={classNames.label}>
                    Shipping Email Address <sup style={{ top: 1 }}></sup>
                  </span>
                }
                rules={[
                  {
                    type: "email",
                    message: validation.email.valid,
                  },
                  {
                    min: MAX_MIN.email.min,
                    message: validation.email.minLength,
                  },
                  {
                    max: MAX_MIN.email.max,
                    message: validation.email.maxLength,
                  },
                ]}
              >
                <Input size="small" placeholder="Please enter" />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <CTAButton
              htmlType="submit"
              type="primary"
              key="submit"
              style={{
                height: 44,
                width: 385,
                marginTop: "35px",
              }}
              disabled={isSubmitButtonDisabled}
            >
              {updatingCustomer ? "Update" : "Save"}
            </CTAButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
