import classNames from "./Header.module.scss";
import { useState } from "react";
import { Title, BackButton } from "../index";
import { Popover, Switch } from "antd";
// TODO: post-mvp
// import Search2LineIcon from "remixicon-react/Search2LineIcon";

import PropTypes from "prop-types";
import constants from "../../Messages/paymentAcceptance/constants";
import Settings from "../../Receivables/PaymentAcceptance/PaymentAcceptanceOverview/Settings/Settings";

export default function Header({
  title,
  history,
  back,
  onClick,
  enableOnlinePayments,
  enableOnlinePaymentsOnChange,
  enableOnlinePaymentsChecked,
  enableOnlinePaymentLoading,
  paSettingsStatus,
  paStatus,
}) {

  const [settingsVisibility, setSettingsVisibility] = useState(false);

  const handleSettingsCancelClick = () => {
    setSettingsVisibility(false);
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerName}>
        {back && <BackButton history={history} onClick={onClick} />}
        <Title as="h2" style={{ marginLeft: 15 }}>
          {title}
        </Title>
      </div>
      <div className={classNames.headerRight}>
        {enableOnlinePayments && (
          <div className={classNames.enableOnlinePayments}>
            <span>Enable Online Payments</span>
            {!paStatus ? (
              <Popover
                content={() => {
                  if (!paStatus) {
                    return constants.strings.onboardStatus.warning;
                  }
                }}
              >
                <Switch disabled />
              </Popover>
            ) : (
              <Switch
                loading={enableOnlinePaymentLoading}
                onChange={
                  paSettingsStatus ? enableOnlinePaymentsOnChange : () => {}
                }
                checked={paSettingsStatus ? enableOnlinePaymentsChecked : false}
                onClick={
                  paSettingsStatus
                    ? () => {}
                    : () => {
                        if (!enableOnlinePaymentsChecked) {
                          setSettingsVisibility(true);
                        }
                        enableOnlinePaymentsOnChange();
                      }
                }
              />
            )}
          </div>
        )}
        <div className={classNames.buttonWrapper}>
          {/* TODO: post MVP */}
          {/* <Button shape="circle" icon={<Search2LineIcon size={20} />} /> */}
        </div>
      </div>
      {settingsVisibility && (
        <Settings
          visible={settingsVisibility}
          onCancel={handleSettingsCancelClick}
        />
      )}
    </div>
  );
}

Header.propTypes = {
  onClick: PropTypes.func,
  history: PropTypes.object,
  title: PropTypes.string,
};
