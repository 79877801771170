import { useEffect, useState } from "react";
import classNames from "./ViewInvoice.module.scss";
import { CTAButton, Header, Select } from "../../../common";
import { Card, Button as AntButton, Spin } from "antd";
import {
  RiEditLine,
  RiShareBoxFill,
  RiPrinterLine,
  RiDownloadCloudLine,
  RiDeleteBin7Line,
  // RiSearch2Line,
} from "react-icons/ri";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import EditInvoice from "./EditInvoice/EditInvoice";
import ShareInvoice from "./ShareInvoice/ShareInvoice";
import ChangeStatus from "../Invoices/ChangeStatus/ChangeStatus";
import { useQuery } from "react-query";
import api from "../../../API";
import DeleteInvoice from "../AllInvoices/DeleteInvoice/DeleteInvoice";
import { ROUTES } from "../../../constants";

export default function ViewInvoice() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;

  const [invoiceData] = useState(location.state);
  const [loading, setLoading] = useState(false);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoice, isLoading: invoiceIsLoading } = useQuery(
    "getInvoiceById",
    () => api.Receivables.Invoices.getInvoiceById(invoiceData.id),
    {
      enabled: !!invoiceData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoicePDF,
    isLoading: invoicePDFIsLoading,
    refetch: pdfRefetch,
  } = useQuery(
    "getInvoicePDF",
    () => api.Receivables.Invoices.getInvoicePDF(invoiceData.id),
    {
      enabled: !!invoiceData,
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
      refetchOnWindowFocus: false,
    }
  );

  const { data: products } = useQuery(
    "getProductsByCompanyId",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoiceTemplateSettingsQuery,
    // isFetching: invoiceTemplateSettingsFetching,
    // isSuccess: invoiceTemplateSettingsSuccess,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const [deleteInvoiceModalVisibility, setDeleteInvoiceModalVisibility] =
    useState(false);

  const [editInvoiceModalVisibility, setEditInvoiceModalVisibility] =
    useState(false);

  const [shareInvoiceModalVisibility, setShareInvoiceModalVisibility] =
    useState(false);

  // const [invoiceZoomLevel, setInvoiceZoomLevel] = useState(0);
  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);

  const [invoiceStatus, setInvoiceStatus] = useState();

  useEffect(() => {
    setLoading(true);
  }, [location.state]);

  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
    setInvoiceStatus(
      invoice?.data?.invoiceStatus
        ? invoice?.data?.invoiceStatus
        : invoiceData.status
    );
  };

  const handleInvoiceDeleteClick = () => {
    setDeleteInvoiceModalVisibility(true);
  };

  const handleDeleteInvoiceCancelClick = () => {
    setDeleteInvoiceModalVisibility(false);
  };
  const handleEditInvoiceCancelClick = (isEdit = false) => {
    if (isEdit) {
      setLoading(true);
      pdfRefetch();
    }
    setEditInvoiceModalVisibility(false);
  };

  const handleShareInvoiceClick = () => {
    setShareInvoiceModalVisibility(true);
  };
  const handleShareInvoiceCancelClick = () => {
    setShareInvoiceModalVisibility(false);
  };

  // const handleInvoiceZoomLevel = () => {
  //   console.log(invoiceZoomLevel);
  //   setInvoiceZoomLevel(100);
  // };

  const handleInvoiceStatusChange = (value) => {
    setChangeStatusModalVisibility(true);
    setInvoiceStatus({ flag: "invoice", value: value, record: location.state });
  };

  const handlePrintClick = () => {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ` src="data:application/pdf;base64,${invoicePDF?.data?.pdf}` +
      '"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    // printWindow.print();

    // Message({ type: "success", content: "Sent for print" });
  };

  // const handleDownloadClick = () => {
  // Message({ type: "success", content: "Downloaded" });
  // };

  useEffect(() => {
    if (invoice) {
      setInvoiceStatus(
        invoice?.data?.invoiceStatus
          ? invoice?.data?.invoiceStatus
          : invoiceData.status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, invoiceData]);

  if (!invoiceData) {
    return <Navigate to={ROUTES.APP.RECEIVABLES.INVOICES} />;
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title={`Invoice : ${
            invoice?.data?.invoicePrefix ? invoice?.data?.invoicePrefix : ""
          }${invoiceData.number || invoice?.data.invoiceNumber}`}
          back
          onClick={() => navigate(invoiceData.from)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            {loading || invoiceIsLoading || invoicePDFIsLoading ? (
              <div className={classNames.spinWrapper}>
                <Spin size="large" />
              </div>
            ) : (
              <Card className={classNames.invoiceCard}>
                <div className={classNames.cardLeft}>
                  <div className={classNames.invoiceContainer}>
                    <div>
                      <b>Invoice</b>
                    </div>
                    <iframe
                      id="pdfIframe"
                      src={`data:application/pdf;base64,${invoicePDF?.data?.pdf}`}
                      // src={`${invoice}#zoom=${100}&view=Fit&toolbar=0&scrollbar=0&statusbar=0&navpanes=0`}
                      title="invoice"
                      frameBorder="0"
                      className={classNames.invoice}
                      zoom={1.5}
                    />
                  </div>
                  <div className={classNames.invoiceOptions}>
                    <AntButton
                      className={classNames.deleteBtn}
                      onClick={handleInvoiceDeleteClick}
                    >
                      <RiDeleteBin7Line size={13} color="#EE6969" />
                      Delete
                    </AntButton>
                    {/* <AntButton
                      className={classNames.zoomBtn}
                      // onClick={handleInvoiceZoomLevel}
                    >
                      <RiSearch2Line size={13} color="#fff" />
                      Zoom
                    </AntButton> */}
                  </div>
                </div>
                <div className={classNames.cardRight}>
                  <div className={classNames.invoiceStatusSelect}>
                    <span>Mark invoice as</span>

                    <Select
                      defaultValue={invoiceStatus}
                      onChange={handleInvoiceStatusChange}
                      value={invoiceStatus}
                      disabled={invoiceStatus === "paid"}
                    >
                      {invoiceStatus === "draft" ||
                      invoiceStatus === "overdue" ? (
                        <>
                          <Option value="draft">Draft</Option>
                          <Option value="sent">Sent</Option>
                          {/* <Option value="late">Overdue</Option> */}
                          <Option value="paid">Paid</Option>
                        </>
                      ) : invoiceStatus === "sent" ? (
                        <>
                          <Option value="sent">Sent</Option>
                          {/* <Option value="late">Overdue</Option> */}
                          <Option value="paid">Paid</Option>
                        </>
                      ) : (
                        ""
                      )}
                    </Select>
                  </div>
                  <div>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={() => {
                        setEditInvoiceModalVisibility(true);
                      }}
                      disabled={invoiceStatus === "paid"}
                    >
                      <RiEditLine size={12} />
                      Edit
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handleShareInvoiceClick}
                    >
                      <RiShareBoxFill size={12} />
                      Share
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handlePrintClick}
                    >
                      <RiPrinterLine size={12} />
                      Print
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      download={`${invoice?.data?.invoiceNumber}_${invoice?.data?.receiverName}.pdf`}
                      href={`data:application/octet-stream;base64, ${invoicePDF?.data?.pdf}`}
                    >
                      <RiDownloadCloudLine size={12} />
                      Download
                    </CTAButton>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
      <ChangeStatus
        visible={changeStatusModalVisibility}
        status={invoiceStatus}
        onCancel={handleChangeStatusModalCancelClick}
      />
      <DeleteInvoice
        visible={deleteInvoiceModalVisibility}
        onCancel={handleDeleteInvoiceCancelClick}
        invoice={invoice?.data}
        setDeleteInvoiceModalVisibility={setDeleteInvoiceModalVisibility}
      />
      <EditInvoice
        customers={customerQuery?.data}
        invoice={invoice?.data}
        products={products?.data}
        taxRates={taxRates?.data}
        visible={editInvoiceModalVisibility}
        onCancel={handleEditInvoiceCancelClick}
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
      />
      <ShareInvoice
        visible={shareInvoiceModalVisibility}
        onCancel={handleShareInvoiceCancelClick}
        customers={customerQuery?.data}
        invoice={invoice?.data}
      />
    </div>
  );
}
