import { API } from "aws-amplify";

export default async function updatePayee(data) {
  if (data?.counterpartyId) {
    return await API.put("payees", "update-payee", {
      queryStringParameters: {
        counterpartyId: data.counterpartyId,
      },
      body: {
        ...data.body,
      },
    });
  }
}
