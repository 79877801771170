import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table, Space, Button as AntButton, Spin } from "antd";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import { Input, Header, TypeTag, Title } from "../../../common";
import DeletePayment from "../RecurringPayments/DeletePayment/DeletePayment";
import NewPayment from "./NewPayment/NewPayment";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import {
  centsToDollar,
  centsToDollarWithoutSign,
} from "../../../util/functions/dollarConvertion";
import { isBusinessType } from "../../../util/functions/onboarding";
import generateErrorMessage from "../../../util/functions/customError";
import { FORMATS } from "../../../constants";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
// import { isPaymentOnPast } from "../helpers/isPaymentOnPast";
import classNames from "./RecurringPayments.module.scss";

export default function RecurringPayments() {
  //Hooks
  const [modalVisibility, setModalVisibility] = useState(false);
  // const [editPaymentModalVisibility, setEditPaymentModalVisible] =
  //   useState(false);
  const [deletePaymentModalVisibility, setDeletePaymentModalVisibility] =
    useState(false);

  const [sanitizedData, setSanitizedData] = useState();
  const [searchResults, setSearchResults] = useState();
  const [record, setRecord] = useState();
  const queryClient = useQueryClient();
  const [schedulePaymentData, setSchedulePaymentData] = useState();
  const { application } = useContext(AppContext);
  const applicationData = application;
  const [upcomingPayments, setUpcomingPayments] = useState(0);
  const [recurringPayments, setRecurringPayments] = useState(0);
  const [pagination, setPagination] = useState(PAGINATION);
  const navigate = useNavigate();

  //Other variables
  const today = moment().format(FORMATS.DATEPICKER_FORMAT);
  const oneMonthFromNow = moment()
    .add(1, "month")
    .add(1, "day")
    .format(FORMATS.DATEPICKER_FORMAT);

  //Api calls
  const { data: payeeData } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getRecurringPayments = useMutation(
    "getSchedulePayment",
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getScheduledPayments = useMutation(
    "getSchedulePayment",
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: accountPayload } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: applicationData?.otz_account_id,
      }),
    {
      retry: false,
    }
  );

  useEffect(() => {
    handleRecurringPaymentList();
    handleSchedulePaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination(PAGINATION);

    !getRecurringPayments.isLoading &&
    Boolean(schedulePaymentData) &&
    schedulePaymentData?.length > 0
      ? setSanitizedData(
          schedulePaymentData
            ?.sort((a, b) =>
              moment(a?.startDate, FORMATS.DATEPICKER_FORMAT).diff(
                moment(b?.startDate, FORMATS.DATEPICKER_FORMAT)
              )
            )
            ?.reverse()
        )
      : setSanitizedData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePaymentData]);

  //Event handlers
  function onSearchByName(keyword) {
    setPagination(PAGINATION);

    setSearchResults(
      !getRecurringPayments.isLoading &&
        Boolean(schedulePaymentData) &&
        schedulePaymentData?.length > 0 &&
        schedulePaymentData
          ?.filter((item) => {
            const filterObj = {
              payeeName: item?.payeeName,
              startDate: item?.startDate,
              endDate: item?.endDate,
              amount: item?.amount,
              paymentType: item?.paymentType,
              status: item?.status,
              period: item?.period,
            };
            return Object.values(filterObj)
              ?.toString()
              ?.toLowerCase()
              .includes(keyword.target.value.toLowerCase());
          })
          .sort((a, b) =>
            moment(a.startDate, FORMATS.DATEPICKER_FORMAT).diff(
              moment(b.startDate, FORMATS.DATEPICKER_FORMAT)
            )
          )
          ?.reverse()
    );
  }

  const handleModalCancelClick = () => {
    setModalVisibility(false);
  };

  // const handleEditPaymentModalCancelClick = () => {
  //   setEditPaymentModalVisible(false);
  // };

  const handleDeletePaymentModalCancelClick = () => {
    setDeletePaymentModalVisibility(false);
  };

  // const handleOnTableRowClick = (record) => () => {
  //   setRecord(record);
  //   setEditPaymentModalVisible(true);
  // };

  const handleRecurringPaymentList = () => {
    const reqBody = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: applicationData?.otz_business_id }
        : {
            otz_person_id: applicationData?.otz_person_id,
          }),
      is_recurring: 1,
    };

    getRecurringPayments.mutate(reqBody, {
      onSuccess: (response) => {
        const filteredData = response?.payload?.map((item) => {
          return {
            id: item?.id,
            payeeName: item?.payee_name,
            startDate: moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            ),
            endDate: moment(item?.schedule?.end_date).format(
              FORMATS.DATEPICKER_FORMAT
            ),
            amount: centsToDollar(item?.payment_instruction?.request?.amount),
            period: getPeriod(
              item?.schedule?.frequency,
              item?.schedule?.interval
            ),
            paymentType: item?.payment_instruction?.type,
            status: item?.status,
          };
        });

        const amount = response?.payload
          ?.filter((item) => {
            const date = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );

            if (
              moment(date).isBefore(moment(oneMonthFromNow)) &&
              item?.status === "ACTIVE"
            ) {
              return true;
            }
            return false;
          })
          .map((item) => {
            const startDate = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            const endDate = moment(item?.schedule?.end_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            if (item?.schedule?.frequency === "DAILY") {
              let day;
              if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                day = moment
                  .duration(moment(endDate).diff(moment(today)))
                  .asDays();
              } else if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isAfter(moment(today))
              ) {
                day =
                  moment
                    .duration(moment(endDate).diff(moment(startDate)))
                    .asDays() + 1;
              } else if (
                moment(endDate).isAfter(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                day = moment
                  .duration(moment(oneMonthFromNow).diff(moment(today)))
                  .asDays() - 1;
              } else {
                day = moment
                  .duration(moment(oneMonthFromNow).diff(moment(startDate)))
                  .asDays();
              }
              return day * item?.payment_instruction?.request?.amount;
            } else if (item?.schedule?.frequency === "WEEKLY") {
              let currentStartDate = startDate;
              let count = 0;

              if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                while (
                  moment(currentStartDate).isSameOrBefore(moment(endDate))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isAfter(moment(today))
              ) {
                while (
                  moment(currentStartDate).isSameOrBefore(moment(endDate))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else if (
                moment(endDate).isAfter(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                while (
                  moment(currentStartDate).isBefore(moment(oneMonthFromNow))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else {
                while (
                  moment(currentStartDate).isBefore(moment(oneMonthFromNow))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              }
              return count * item?.payment_instruction?.request?.amount;
            } else {
              const date = moment(
                item?.next_payment_date?.execution_date
              ).format(FORMATS.DATEPICKER_FORMAT);
              if (
                (moment(date).isSame(moment(today)) &&
                  moment(endDate).isSameOrAfter(oneMonthFromNow)) ||
                (moment(date).isAfter(moment(today)) &&
                  moment(date).isBefore(moment(oneMonthFromNow)))
              ) {
                return item?.payment_instruction?.request?.amount;
              }
              return 0;
            }
          })
          .reduce((value1, value2) => {
            return value1 + value2;
          }, 0);

        setRecurringPayments(amount);

        setSchedulePaymentData(filteredData);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  const handleSchedulePaymentList = () => {
    const reqBody = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: applicationData?.otz_business_id }
        : {
            otz_person_id: applicationData?.otz_person_id,
          }),
      is_recurring: 0,
    };

    getScheduledPayments.mutate(reqBody, {
      onSuccess: (response) => {
        const amount = response?.payload
          ?.filter((item) => {
            let date = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            return (
              moment(date).isBefore(moment(oneMonthFromNow)) &&
              moment(date)?.isAfter(moment(today)) &&
              item?.status === "ACTIVE"
            );
          })
          .map((item) => {
            return item?.payment_instruction?.request?.amount;
          })
          .reduce((value1, value2) => {
            return value1 + value2;
          }, 0);

        setUpcomingPayments(amount);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  //JSX
  const columns = [
    {
      title: "Payee Name",
      dataIndex: "payeeName",
      key: "payeeName",
      width: 200,
      ellipsis: true,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (key, record) => record?.startDate,
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a.startDate, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b.startDate, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (key, record) => record?.endDate,
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a.endDate, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b.endDate, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <span>{amount}</span>;
      },
    },
    {
      title: "Frequency",
      dataIndex: "period",
      key: "period",
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return a.period.length - b.period.length;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        switch (status) {
          case "CANCELLED":
            return "Cancelled";
          case "ACTIVE":
            return "Active";
          case "EXPIRED":
            return "Expired";
          default:
            return "N/A";
        }
      },
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return a.status.length - b.status.length;
      },
    },
    {
      title: "Transfer Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType) => {
        return (
          <Space
            size="middle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TypeTag type={paymentType?.toLowerCase()} />
          </Space>
        );
      },
    },
    {
      render: (_, record) => {
        return (
          record?.status === "ACTIVE" && (
            <Space size="middle">
              {/* <EditLineIcon
                  size={14}
                  color="#9BA3AB"
                  className={classNames.actionIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditPaymentModalVisible(true);
                    setRecord(record);
                  }}
                /> */}
              <DeleteBin7LineIcon
                size={14}
                color="#9BA3AB"
                className={classNames.actionIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeletePaymentModalVisibility(true);
                  setRecord(record);
                }}
              />
            </Space>
            // )
          )
        );
      },
    },
  ];

  //Helper
  const getPeriod = (period, interval = 1) => {
    switch (period) {
      case "DAILY":
        return "Daily";
      case "WEEKLY":
        return "Weekly";
      case "MONTHLY":
        if (interval === 4) {
          return "Quarterly";
        }
        return "Monthly";
      case "QUARTERLY":
        return "Quarterly";
      default:
        return "N/A";
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Recurring Payments" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.paymentFilters}>
              <Input
                placeholder="Search recurring payment"
                prefix={<Search2LineIcon />}
                className={classNames.searchPayment}
                onChange={onSearchByName}
              />
            </div>
            <Card
              className={classNames.optionCard}
              onClick={() => setModalVisibility(true)}
            >
              <div className={classNames.cardAction}>
                <span>New Recurring Payment</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
          </div>

          <div className={classNames.body}>
            <Card className={classNames.balanceCard}>
              {getScheduledPayments.isLoading ||
              getRecurringPayments.isLoading ? (
                <Spin />
              ) : (
                <>
                  <div className={classNames.totalBalance}>
                    <Title as="h4">
                      Upcoming Payments
                      <span style={{ color: "#EE6969" }}>* </span> (next 30
                      days)
                    </Title>
                    <div className={classNames.amount}>
                      <sup>$</sup>
                      {centsToDollarWithoutSign(
                        upcomingPayments + recurringPayments
                      ) || "0.00"}
                    </div>
                  </div>
                  <span>
                    <span style={{ color: "#EE6969" }}>*</span>Amount includes
                    both - one time pay later payments and recurring payments.
                  </span>
                </>
              )}
            </Card>

            <Table
              loading={getRecurringPayments.isLoading}
              size="large"
              columns={columns}
              dataSource={
                Boolean(sanitizedData) &&
                sanitizedData.length > 0 &&
                !searchResults
                  ? sanitizedData
                  : searchResults
              }
              className={classNames.table}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  } else if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              // onRow={(record) => ({
              //   ...(!isPaymentOnPast(
              //     moment(record?.startDate).format(FORMATS.API_SEND_FORMAT)
              //   )
              //     ? {
              //         onClick: handleOnTableRowClick(record),
              //         className: classNames.tableRow,
              //       }
              //     : {}),
              // })}
            />
          </div>
        </div>
      </div>
      <NewPayment
        payees={payeeData?.payload}
        visible={modalVisibility}
        onCancel={handleModalCancelClick}
        accountBalance={accountPayload?.payload?.total_available_balance || 0}
        queryClient={queryClient}
        refetch={handleRecurringPaymentList}
      />
      {/* <EditPayment
        visible={editPaymentModalVisibility}
        onCancel={handleEditPaymentModalCancelClick}
        record={record}
        refresh={refreshScreen}
        refetch={handleRecurringPaymentList}
        accountBalance={accountPayload?.data.balance}
      /> */}
      <DeletePayment
        visible={deletePaymentModalVisibility}
        onCancel={handleDeletePaymentModalCancelClick}
        record={record}
        setVisible={setDeletePaymentModalVisibility}
        refetch={handleRecurringPaymentList}
        queryClient={queryClient}
      />
    </div>
  );
}
