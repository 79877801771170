import { useEffect, useState } from "react";
import { CTAButton, Modal, Title } from "../../../../common";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "../AddNewSchedule/AddNewSchedule.module.scss";

import { RiCheckboxCircleFill } from "react-icons/ri";
export default function ConfirmModel({ visible, onCancel }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 9000);
  }, []);
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      bodyStyle={{
        height: "55vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={300}
    >
      <div className={classNames.confirmContainer}>
        {loading ? (
          <>
            <Spin indicator={antIcon} />
            <Title as="h2">Processing request...</Title>
          </>
        ) : (
          <>
            <RiCheckboxCircleFill color="rgb(10, 221, 17)"  size={100} />
            <Title as="h2">Transaction added</Title>
            <span>Your transaction has been added successfully</span>
            <CTAButton
              type="primary"
              style={{
                height: 44,
                marginTop: 20,
              }}
            >
              {" "}
              Done
            </CTAButton>
          </>
        )}
      </div>
    </Modal>
  );
}
