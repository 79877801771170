import { Auth } from "aws-amplify";
import { getEndpoints } from "../../../apiEndpoints";

export default async function customFetch(
  method,
  endpointKey,
  path,
  queryParams,
  body,
  headers,
  options
) {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const endpoints = getEndpoints([process.env.REACT_APP_ENV?.trim()]);
  const urlObj =
    Array.isArray(endpoints) &&
    endpoints.find((val) => val.name === endpointKey);

  return await fetch(
    `${urlObj.endpoint}${path}?${new URLSearchParams(queryParams)}`,
    {
      ...(body && { body: body }),
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      method,
      ...options
    }
  );
}
