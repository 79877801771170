import { Message } from "../../../common";
import { tempUrls } from "../../../../apiEndpoints";
import getApplicationAC from "../../../../context/actions/application/getApplicationByTenantId.ac";

const handleUpload = (
  onSuccess,
  onError,
  file,
  applicationId,
  documentId,
  applicationDBId,
  tenantId,
  setUploadTrackingDocs,
  dispatcAcRef,
  isFront = true
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("contentType", file.type);
  formData.append("applicationId", applicationId);
  formData.append("documentId", documentId);
  formData.append("applicationDBId", applicationDBId);

  let env = process.env.REACT_APP_ENV?.trim();

  let endpointUrl = isFront
    ? tempUrls.UPLOAD_DOCUMENTS_FRONT[env]
    : tempUrls.UPLOAD_DOCUMENTS_BACK[env];

  fetch(endpointUrl, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess();
      if (data?.success) {
        Message({ type: "success", content: "Upload successful" });
      } else {
        Message({ type: "error", content: "Upload failed please try again" });
      }
      localStorage.setItem("uploadedDoc", documentId);
      setUploadTrackingDocs((prev) => {
        return prev.map((value) => {
          if (value.documentId === documentId) {
            return { ...value, isUploaded: true };
          } else {
            return { ...value };
          }
        });
      });
      getApplicationAC(tenantId)(dispatcAcRef);
    })
    .catch((error) => {
      onError(error);
      Message({ type: "error", content: "Upload failed please try again" });
      setUploadTrackingDocs((prev) => {
        return prev.map((value) => {
          if (value.documentId === documentId) {
            return { ...value, isUploaded: false };
          } else {
            return { ...value };
          }
        });
      });
    });
};

export default handleUpload;
