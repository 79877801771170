import { Card } from "antd";
import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";
import { Title } from "../../../common";
import classNames from "../StatementsAndTransactions.module.scss";

export default function StatementCard({ month, date }) {
  return (
    <Card className={classNames.statementListCard}>
      <div>
        <Title as="h3">{month}</Title>
        {/* <span>{date}</span> */}
      </div>
      <div className={classNames.cardAction}>
        <span>Download</span>
        <ArrowDownLineIcon className={classNames.arrowBtnWithBg} />
      </div>
    </Card>
  );
}
