import { useState } from "react";
import classNames from "./Customers.module.scss";
import { Input, Header } from "../../../common";
import { Card, Table, Space, Empty, Button as AntButton } from "antd";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiEditLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AddNewCustomer from "./AddNewCustomer/AddNewCustomer";
import EditCustomer from "./EditCustomer/EditCustomer";
import api from "../../../API";
import { useQuery } from "react-query";
import DeleteCustomer from "./DeleteCustomer/DeleteCustomer";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";

export default function Customers() {
  // const pageSize = 10;
  const [keyword, setKeyword] = useState(null);
  // const [pageCount, setPageCount] = useState(pageSize);
  const [pagination, setPagination] = useState(PAGINATION);
  const [addCustomersCardModalVisibility, setAddCustomersCardModalVisibility] =
    useState(false);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: customerQuery, isLoading: isCustomerLoading } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      onSettled: () => setPagination(PAGINATION),
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const [
    deleteCustomerCardModalVisibility,
    setDeleteCustomerCardModalVisibility,
  ] = useState({ visibility: null, record: null });

  const handleDeleteTaxCardModalCancelClick = () => {
    setDeleteCustomerCardModalVisibility(false);
  };

  const handleAddProductCardModalCancelClick = () => {
    setAddCustomersCardModalVisibility(false);
  };
  const [
    editCustomersCardModalVisibility,
    setEditCustomersCardModalVisibility,
  ] = useState({ visibility: null, record: null });

  const handleEditProductCardModalCancelClick = () => {
    setEditCustomersCardModalVisibility({ visibility: false, record: null });
  };

  const customersArray = customerQuery?.data;
  const customersDataSource =
    Array.isArray(customersArray) &&
    customersArray.map((value, index) => Object.assign(value, { key: index }));

  const navigate = useNavigate();

  const filteredArray =
    keyword &&
    Boolean(customersDataSource?.length) &&
    customersDataSource.filter((obj) => {
      let filterObj = {
        name: obj.name,
        email: obj.email,
      };
      return Object.values(filterObj)
        .toString()
        .toLowerCase()
        .includes(keyword.toLowerCase());
    });

  //Display pagination count text
  let totalNoOfRecords =
    Array.isArray(customersDataSource) &&
    customersDataSource?.length > 0 &&
    keyword
      ? Array.isArray(filteredArray)
        ? filteredArray.length
        : Array.isArray(customersDataSource)
        ? customersDataSource?.length
        : 0
      : customersDataSource.length;

  let maximumNoOfPages = Math.ceil(totalNoOfRecords / pagination.pageSize);
  let noOfRecordsForLastPage =
    totalNoOfRecords % maximumNoOfPages > 0
      ? totalNoOfRecords - pagination.pageSize * (maximumNoOfPages - 1)
      : pagination.pageSize;
  let calculateRecordCount =
    pagination.pageSize > totalNoOfRecords
      ? totalNoOfRecords
      : pagination.page === maximumNoOfPages
      ? noOfRecordsForLastPage
      : pagination.pageSize;

  const paginationText =
    totalNoOfRecords === 0 || totalNoOfRecords === undefined
      ? `No customers to display`
      : `Displaying
       ${calculateRecordCount}
       of ${totalNoOfRecords} ${
          totalNoOfRecords > 1 ? "customers" : "customer"
        }`;

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text, record) => (
        <Space
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditCustomersCardModalVisibility({
              visibility: true,
              record: record,
            });
          }}
        >
          {text}
        </Space>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "right",
            width: "80%",
          }}
        >
          <Space size="middle">
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setEditCustomersCardModalVisibility({
                  visibility: true,
                  record: record,
                });
              }}
            />
            <RiDeleteBin7Line
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteCustomerCardModalVisibility({
                  visibility: true,
                  record: record,
                });
              }}
            />
          </Space>
        </Space>
      ),
    },
  ];

  // commenting due to future use: afterMVP
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {},
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  // const handlePageChange = (page) => {
  //   if (page * pageSize > customersDataSource.length) {
  //     const reduceCount = (page - 1) * pageSize;
  //     setPageCount(customersDataSource.length - reduceCount);
  //   } else {
  //     setPageCount(pageSize);
  //   }
  // };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Customers" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Input
              placeholder="Search Customers"
              prefix={<RiSearch2Line size={26} />}
              className={classNames.searchInvoice}
              onChange={(event) => {
                setKeyword(event.target.value);
                setPagination(PAGINATION);
              }}
            />

            <Card
              className={classNames.optionCard}
              onClick={() => {
                setAddCustomersCardModalVisibility(true);
              }}
            >
              <div className={classNames.cardAction}>
                <span>Add New Customer</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              {paginationText}
            </div>
            <Table
              loading={isCustomerLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      keyword && totalNoOfRecords === 0
                        ? "No Result found"
                        : "No Data Found"
                    }
                  />
                ),
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setEditCustomersCardModalVisibility({
                      visibility: true,
                      record: record,
                    });
                  },
                };
              }}
              // commenting due to future use - afterMVP
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              size="large"
              dataSource={keyword ? filteredArray : customersDataSource}
              columns={columns}
              className={classNames.table}
              rowClassName={classNames.actionIcon}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  }
                  if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
            />
            <AddNewCustomer
              visible={addCustomersCardModalVisibility}
              onCancel={handleAddProductCardModalCancelClick}
              setAddCustomersCardModalVisibility={
                setAddCustomersCardModalVisibility
              }
            />
            <EditCustomer
              key={editCustomersCardModalVisibility?.record?.key}
              visible={editCustomersCardModalVisibility.visibility}
              onCancel={handleEditProductCardModalCancelClick}
              data={editCustomersCardModalVisibility.record}
              setEditCustomersCardModalVisibility={
                setEditCustomersCardModalVisibility
              }
            />
            <DeleteCustomer
              visible={deleteCustomerCardModalVisibility.visibility}
              onCancel={handleDeleteTaxCardModalCancelClick}
              data={deleteCustomerCardModalVisibility.record}
              // setRefetch={setRefetch}
              setDeleteCustomerCardModalVisibility={
                setDeleteCustomerCardModalVisibility
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
