import { Button as AntButton } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

export default function Button({ children, className, onClick, ...props }) {
  const Button = styled(AntButton)`
    border-radius: 54px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #3b3f43;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
    &:hover {
      color: #3f63e2;
    }
    &:active {
      color: #3f63e2;
      border: 1px solid #3f63e2;
    }
    &:focus {
      color: #3f63e2;
      border: 1px solid #3f63e2;
    }
  `;
  return (
    <Button {...props} className={`${className}`} onClick={onClick}>
      {children}
    </Button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  onClick: PropTypes.func,
};
