import React from "react";

const RegDD = () => {
  return (
    <>
      <p>Your interest rate and annual percentage yield may change.</p>
      <div>
        <b>CURRENT RATE INFORMATION.</b>&nbsp;
        <span>
          The interest rate on this account can fluctuate at any given time
          depending on market rates. The account is not interest. Bearing
        </span>
      </div>
      <div>
        <b>MINIMUM BALANCE REQUIREMENTS.</b>&nbsp;
        <span>
          No minimum balance is required to open or to obtain the account's
          annual percentage yield. Your account statement cycle is monthly
        </span>
      </div>
      <div>
        <b>DAILY BALANCE METHOD.</b>&nbsp;
        <span>
          We use the Daily Balance Method which applies a daily periodic rate to
          the account balance that has accrued in the Account each day.
          Interest, if applicable will be calculated daily, including holidays
          and weekends. This means that the interest from the Account is
          calculated everyday on 365 days a year and 366 days a year for leap
          years.
        </span>
      </div>
      <div>
        <b>ACCRUAL ON NONCASH DEPOSITS.</b>&nbsp;
        <span>
          Interest, if applicable begins to accrue on the business day you
          deposit noncash items (for example, checks). The cut off time for
          interest calculation will be 12:00 a.m. est. All deposits after the
          cut off time will have the posting day of the following business day.
        </span>
      </div>
    </>
  );
};

export default RegDD;
