import { API } from "aws-amplify";

export default async function deletePayment(id) {
  if (id) {
    return await API.patch("schedulePayments", "/delete", {
      body: {
        otz_schedule_id: id,
      },
    });
  }
}
