import { API } from "aws-amplify";

export default async function updateRecurringPayments(data) {
  if (data?.id) {
    return await API.put("payments", "update-recurring-payments", {
      queryStringParameters: {
        parentId: data.id,
      },
      body: {
        ...data.input,
      },
    });
  }
}
