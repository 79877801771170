import React from "react";
import { Select as AntSelect } from "antd";
import PropTypes from "prop-types";
import classNames from "./Select.module.scss";
export default function Select({
  className,
  children,
  size = "default",
  disabled,
  ...props
}) {
  return (
    <AntSelect
      {...props}
      className={`${disabled ? classNames.disabled : classNames.select} ${
        classNames[size]
      } ${className} `}
      disabled={disabled}
    >
      {children}
    </AntSelect>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  size: PropTypes.oneOf(["default", "small"]),
  disabled: PropTypes.bool,
};

Select.Option = Option;
