import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import api from "../App/API";
// import useLocalStorage from "./useLocalStorage";
import { AppContext } from "../context/AppState";

const useIFieldKey = () => {
  // const userData = useLocalStorage("OTTERZ.userData");
  const { userData } = useContext(AppContext);
  const [queryParams] = useSearchParams();
  const [iFieldsKeyRes, setIFieldsKeyRes] = useState(null);
  // hosted payment action does not have user data, then company id should get from query param
  let companyId =
    userData?.icid || window.atob(queryParams.get("companyId") || "");

  let tenantId =
    userData?.tenantId || window.atob(queryParams.get("tenantId") || "");

  const { isLoading: iFieldLoading } = useQuery(
    "getIFieldsKey",
    () =>
      api.Receivables.PaymentAcceptance.getIFieldsKey({
        companyId,
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => setIFieldsKeyRes(response?.data),
    }
  );

  const iFieldKey = iFieldsKeyRes?.ifieldKey || "";
  const merchantId = iFieldsKeyRes?.merchantId || "";
  const businessContactName = iFieldsKeyRes?.businessContactName || "";
  const businessName = iFieldsKeyRes?.businessName || "";

  return {
    iFieldKey,
    merchantId,
    businessContactName,
    businessName,
    iFieldLoading,
  };
};

export default useIFieldKey;
