import classNames from "./Mobile.module.scss";
import { Outlet } from "react-router-dom";

const Mobile = () => {
  return (
    <div className={classNames.wrapper}>
      <Outlet />
    </div>
  );
};

export default Mobile;
