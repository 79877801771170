import classNames from "../MakePayments.module.scss";
import {
  CTAButton,
  Input,
  Message,
  Select /*  DatePicker, Title  */,
} from "../../../common";
import {
  Form,
  Button,
  Input as AntInput,
  Checkbox,
  /*message,
  Radio,*/ Spin,
} from "antd";
import { useContext, useState } from "react";
import { useMutation /* useQuery */, useQueryClient } from "react-query";
import api from "../../../API";

import generateErrorMessage from "../../../util/functions/customError";
import messages from "../../config/messages.const";
import WireAuthenticationModal from "./WireAuthenticationModal";
import useLanguage from "../../../../hooks/useLanguage";
import { DEFAULT_AMOUNT } from "../../../constants";
import {
  centsToDollar,
  dollarToCents,
} from "../../../util/functions/dollarConvertion";
import { validationPayee } from "../../config";
import AddNewPayee from "../Payees/AddNewPayee/AddNewPayee";
import REGEX from "../../../util/constants/regex";
import { MAX_MIN } from "../../../util/constants";
import { checkIsAmount } from "../../../util/functions/checkIsAmount";
import { isBusinessType } from "../../../util/functions/onboarding";
import { AppContext } from "../../../../context/AppState";

export default function WireTransactionForm({
  accountBalance,
  payeeData,
  refetch,
  onCancel,
}) {
  const [amount, setAmount] = useState();
  // const [receiversName, setReceiversName] = useState("");
  // const [receiversAccNo, setReceiversAccNo] = useState("");
  // const [receiversRoutingNo, setReceiversRoutingNo] = useState("");
  const [addPayeeModalVisibility, setAddPayeeModalVisibility] = useState(false);
  const [payee, setPayee] = useState();
  const [form] = Form.useForm();
  const language = useLanguage(messages);
  const queryClient = useQueryClient();
  const { application } = useContext(AppContext);
  const [agreementChecked, setAgreementChecked] = useState(false);

  const { TextArea } = AntInput;

  /* Wire transfer auth related */
  const [OTPModalVisible, setOTPModalVisible] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  const { mutate: createWiresPayment, isLoading: createWiresPaymentisLoading } =
    useMutation(api.BusinessBanking.createWiresPayment, {
      onSuccess: () => {
        refetch();
      },
    });

  const handleCheckboxChange = (e) => {
    setAgreementChecked(e.target.checked);
    setButtonActive(prev => !prev)
  };

  const onFinish = () => {
    form.validateFields().then((data) => {
      if (agreementChecked) {
        // Proceed with the transaction only if the agreement is checked
        setOTPModalVisible(true);
        // Rest of the code
      } else {
        Message({
          type: "error",
          content: "Please check the Wire Transfer Agreement.",
        });
      }
      // const reqBody = {
      //   ...(isBusinessType(application?.type)
      //     ? { otz_business_id: application?.otz_business_id }
      //     : { otz_person_id: application?.otz_person_id }),
      //   amount: Number(dollarToCents(data.amount)),
      //   otz_payee_id: payee?.otz_payee_id,
      //   description: data.description,
      // };
      // createWiresPayment(reqBody, {
      //   onSuccess: () => {
      //     Message({ type: "success", content: language.WIRE_PAYMENT_SUCCESS });
      //     onCancel();
      //     form.resetFields();
      //     queryClient.invalidateQueries("getStatementDetail");
      //     queryClient.invalidateQueries("getPayees");
      //   },
      //   onError: (error) =>
      //     generateErrorMessage(
      //       error,
      //       "Payee does not have wire routing number!"
      //     ),
      // });
    });
  };

  const handleOTPAuthorize = () => {
    // Validate the OTP and create the payment if it's correct
    // ...
    form.validateFields().then((data) => {
      const reqBody = {
        ...(isBusinessType(application?.type)
          ? { otz_business_id: application?.otz_business_id }
          : { otz_person_id: application?.otz_person_id }),
        amount: Number(dollarToCents(data.amount)),
        otz_payee_id: payee?.otz_payee_id,
        description: data.description,
        is_acknowledged: true,
      };
      createWiresPayment(reqBody, {
        onSuccess: () => {
          Message({ type: "success", content: language.WIRE_PAYMENT_SUCCESS });
          onCancel();
          form.resetFields();
          queryClient.invalidateQueries("getStatementDetail");
          queryClient.invalidateQueries("getPayees");
        },
        onError: (error) => {
          if (error?.response?.data?.message === "SPEND_CONTROL_VIOLATION") {
            Message({
              type: "error",
              content:
                "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
            });
          } else {
            onCancel();
            form.resetFields();
            generateErrorMessage(
              error,
              "Transaction failed - Payee does not have wire routing number!"
            );
          }
        },
      });
    });

    // Close the OTP modal
    setOTPModalVisible(false);
  };

  const handleModalCancelClick = () => {
    setAddPayeeModalVisibility(false);
  };

  return (
    <>
      <div className={classNames.modalLeft}>
        <Form form={form} layout="vertical" hideRequiredMark autoComplete="off">
          <Form.Item
            name="payee"
            label={
              <span className={classNames.label}>
                Payee <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: validationPayee.quickFundTransfer.payee,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              name="payee"
              size="small"
              placeholder={validationPayee.quickFundTransfer.payeePlaceholder}
              onSelect={(e) =>
                setPayee(payeeData?.find((entry) => entry?.otz_payee_id === e))
              }
            >
              {payeeData &&
                payeeData.length > 0 &&
                payeeData.map((value) => (
                  <Select.Option value={value?.otz_payee_id}>
                    {`${value?.account_owner_name} - (${value?.account_number_masked})`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="dashed"
              style={{ marginTop: 16, height: 44, width: "100%" }}
              onClick={() => setAddPayeeModalVisibility(true)}
            >
              Add new payee
            </Button>
          </Form.Item>
          {/* <Form.Item
            name="address"
            label={
              <span className={classNames.label}>
                Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <AntInput.Group compact>
              <Form.Item name="street">
                <AntInput placeholder="Please add the street" size="small" />
              </Form.Item>
              <Form.Item name="city">
                <AntInput placeholder="Please add the city" size="small" />
              </Form.Item>
              <Form.Item name="state">
                <AntInput placeholder="Please add the state" size="small" />
              </Form.Item>
              <Form.Item name="postalCode">
                <AntInput
                  placeholder="Please add the postal code"
                  size="small"
                />
              </Form.Item>
              <Form.Item name="country">
                <AntInput placeholder="Please add the country" size="small" />
              </Form.Item>
            </AntInput.Group>
          </Form.Item> */}
          {/*    <Form.Item>
            <Form.Item
              name="street"
              label={
                <Title as="h4" className={classNames.label}>
                  Address <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
            >
              <Input placeholder="Street" size="small" />
            </Form.Item>
            <Form.Item name="city">
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="City"
                size="small"
              ></Select>
            </Form.Item>
            <Form.Item
              name="state"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="State"
                size="small"
              ></Select>
            </Form.Item>
            <Form.Item
              name="postalCode"
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <Input placeholder="Postal Code" size="small" />
            </Form.Item>
          </Form.Item> */}
          <Form.Item
            name="amount"
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Available Balance:{" "}
                <span style={{ color: "#000" }}>
                  {centsToDollar(accountBalance) || DEFAULT_AMOUNT}
                </span>
              </span>
            }
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value > accountBalance / 100) {
                    callback(
                      validationPayee.quickFundTransfer.insufficientFund
                    );
                  }
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input
              placeholder="Enter amount"
              size="small"
              onChange={(e) => setAmount(e.target.value)}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              maxlength="11"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <span className={classNames.label}>
                Description <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.description,
              },
              {
                max: MAX_MIN.description.max,
                message: validationPayee.quickFundTransfer.descriptionMax,
              },
            ]}
          >
            <TextArea
              autoSize
              placeholder={
                validationPayee.quickFundTransfer.descriptionPlaceholder
              }
              style={{ height: 44 }}
            ></TextArea>
          </Form.Item>
          <Form.Item>
            <Checkbox onChange={handleCheckboxChange}>
              I agree that I have read and accepted the <a
            href="https://drive.google.com/file/d/1UHeKGjKR-XzybZkfcJfPkj31gZ8Tk80j/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wire Transfer Agreement
          </a> of Otterz.
            </Checkbox>
          </Form.Item>
          {/* <Form.Item
            name="name"
            label={
              <span className={classNames.label}>
                Name <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder="Receiver's name"
              size="small"
              onChange={(e) => setReceiversName(e.target.value)}
            />
          </Form.Item> */}
          {/* <Form.Item
            name="accountNumber"
            label={
              <span className={classNames.label}>
                Account number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder="Receiver's account number"
              size="small"
              onChange={(e) => setReceiversAccNo(e.target.value)}
            />
          </Form.Item> */}
          {/*  <Form.Item
            name="routingNumber"
            label={
              <span className={classNames.label}>
                Routing number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder="Receiver's routing number"
              size="small"
              onChange={(e) => setReceiversRoutingNo(e.target.value)}
            />
          </Form.Item> */}
          {/* <Form.Item layout="vertical">
            <Checkbox>Pay later</Checkbox>
            <div
              style={{
                backgroundColor: "#F6F7FA",
                borderRadius: 8,
                padding: "16px 6px 6px 16px",
                marginTop: "16px",
              }}
            >
              <Form.Item
                label={
                  <span
                    className={classNames.label}
                    style={{ fontWeight: "normal", fontSize: 12 }}
                  >
                    Date <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
              >
                <DatePicker style={{ height: 44, width: 142 }} />
              </Form.Item>
            </div>
          </Form.Item> */}
          {/*  <Form.Item>
            <Checkbox>Recurring Payment</Checkbox>
            <div
              style={{
                backgroundColor: "#F6F7FA",
                borderRadius: 8,
                padding: "16px 6px 6px 16px",
                marginTop: "16px",
              }}
            >
              <Form.Item>
                <Radio.Group name="radiogroup" defaultValue={1}>
                  <Radio value={1} style={{ fontSize: 12 }}>
                    Daily
                  </Radio>
                  <Radio value={2} style={{ fontSize: 12 }}>
                    Weekly
                  </Radio>
                  <Radio value={3} style={{ fontSize: 12 }}>
                    Monthly
                  </Radio>
                  <Radio value={4} style={{ fontSize: 12 }}>
                    Quaterly
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginRight: 14,
                  }}
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      Start Date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker style={{ height: 44 }} />
                </Form.Item>

                <Form.Item
                  style={{
                    display: "inline-block",
                  }}
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      End Date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker style={{ height: 44 }} />
                </Form.Item>
              </Form.Item>
            </div>
          </Form.Item> */}
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <Spin spinning={createWiresPaymentisLoading}>
          <div>Transaction Summary</div>
          <div className={classNames.transactionCard}>
            <div className={classNames.payee}>
              {/* {receiversName && ( */}
              <div className={classNames.payeeDetails}>
                {/*  {receiversName}

                  {receiversAccNo && <span>A/C No: {receiversAccNo}</span>}

                  {receiversRoutingNo && (
                    <span> Routing No: {receiversRoutingNo} </span>
                  )} */}
                {payee && payee?.account_owner_name}
                {payee?.account_number_masked && (
                  <span>
                    Account Number : {payee && payee?.account_number_masked}
                  </span>
                )}
                {payee?.wire_routing_number && (
                  <span>
                    Wire Routing Number : {payee?.wire_routing_number}
                  </span>
                )}
              </div>
              {/* )} */}
              <span>WIRE</span>
            </div>

            <div className={classNames.transaction}>
              Amount<span>$ {checkIsAmount(amount)}</span>
            </div>

            <div className={classNames.transaction}>
              Transaction Fee<span>{DEFAULT_AMOUNT}</span>
            </div>
            <div className={classNames.total}>
              Total<span>{checkIsAmount(amount)}</span>
            </div>
            {payee && amount && (
              <div className={classNames.summaryText}>
                {payee && payee?.account_owner_name} will receive $
                {amount && checkIsAmount(amount)}.
              </div>
            )}
          </div>
          {/* <a
            href="https://drive.google.com/file/d/1UHeKGjKR-XzybZkfcJfPkj31gZ8Tk80j/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "11px" }}
          >
            Wire Transfer Agreement
          </a> */}
          <CTAButton
            key="submit"
            htmlType="submit"
            type="primary"
            style={{ height: 44, width: 248, marginTop: "5px" }}
            onClick={onFinish}
            disabled={+accountBalance <= 0 || !buttonActive}
          >
            Transfer
          </CTAButton>
        </Spin>
        {+accountBalance <= 0 && (
          <p style={{ color: "#ff4d4f", width: 248, marginTop: 10 }}>
            Your account balance is USD 0.00, Please add funds to your account.
          </p>
        )}
      </div>
      <AddNewPayee
        visible={addPayeeModalVisibility}
        onCancel={handleModalCancelClick}
      />
      <WireAuthenticationModal
        visible={OTPModalVisible}
        // closable={true}
        onCloseClick={() => setOTPModalVisible(false)}
        onCancel={() => setOTPModalVisible(false)}
        onAuthorize={handleOTPAuthorize}
      />
    </>
  );
}
