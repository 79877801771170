import { useContext, useEffect, useState } from "react";
import classNames from "../TemplateSettings.module.scss";
import { Modal, CTAButton, Message } from "../../../../common";
import CompanyDetails from "./Steps/CompanyDetails";
import InvoiceDetails from "./Steps/InvoiceDetails";
import { Form } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { AppContext } from "../../../../../context/AppState";

import Settings from "../../../PaymentAcceptance/PaymentAcceptanceOverview/Settings/Settings";

export default function EditInvoiceTemplate({
  visible,
  onCancel,
  form,
  dataObj,
  icid,
  paStatus,
  paSettingsStatus,
}) {
  const [step, setStep] = useState(1);
  const [acceptQR, setAcceptQR] = useState(false);
  const language = useLanguage(messages);
  const [enableOnlinePayments, setEnableOnlinePayments] = useState();
  const [reminderMessage, setReminderMessage] = useState();
  const [companyLogoString, setCompanyLogoString] = useState(null);
  const [remove, setRemoved] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const { application } = useContext(AppContext);
  const [changeAddr, setChangeAddr] = useState(false);
  
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  
  useEffect(() => {
    if (!paSettingsStatus) {
      setShowSettingsModal(true);
    }
  }, [paSettingsStatus]);

  useEffect(() => {
    setChangeAddr(false);
  }, [visible]);

  const queryClient = useQueryClient();

  const nextStep = () => {
    form
      .validateFields()
      .then((values) => {
        setStep(step + 1);
      })
      .catch((errorInfo) => {
        // console.log(errorInfo);
      });
  };
  const prevStep = () => {
    setStep(step - 1);
  };

  const utilFileReader = (e) => {
    let base64String;
    const reader = new FileReader();
    reader.onloadend = async function () {
      base64String = reader.result;
      setCompanyLogoString(base64String);
    };
    reader.readAsDataURL(e.file.originFileObj);
  };

  //Api calls
  useQuery(
    "getPersonDetail",
    () => api.BusinessBanking.getPerson(application?.otz_person_id),
    {
      onSuccess: (data) => {
        setUserInfo(data?.payload);
      },
      enabled: !!application?.otz_person_id,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    "getBusinessDetail",
    () => api.BusinessBanking.getBusiness(application?.otz_business_id),
    {
      onSuccess: (data) => {
        setUserInfo(data?.payload);
      },
      enabled: !!application?.otz_business_id,
      refetchOnWindowFocus: false,
    }
  );

  function updateInvoiceTemplateFn(data) {
    let inputCompanySettings = {
      id: dataObj.id,
      street1: changeAddr
        ? form.getFieldValue("companyNewAddressStreet1")
          ? form.getFieldValue("companyNewAddressStreet1")
          : ""
        : form.getFieldValue("companyAddressStreet1")
        ? form.getFieldValue("companyAddressStreet1")
        : dataObj.street1,
      street2:
        (changeAddr
          ? form.getFieldValue("companyNewAddressStreet2")
            ? form.getFieldValue("companyNewAddressStreet2")
            : ""
          : form.getFieldValue("companyAddressStreet2")
          ? form.getFieldValue("companyAddressStreet2")
          : dataObj.street2) || "",

      zipcode: changeAddr
        ? form.getFieldValue("companyNewAddressPostalCode")
          ? form.getFieldValue("companyNewAddressPostalCode")
          : ""
        : form.getFieldValue("companyAddressPostalCode")
        ? form.getFieldValue("companyAddressPostalCode")
        : dataObj.zipcode,

      city: changeAddr
        ? form.getFieldValue("companyNewAddressCity")
          ? form.getFieldValue("companyNewAddressCity")
          : ""
        : form.getFieldValue("companyAddressCity")
        ? form.getFieldValue("companyAddressCity")
        : dataObj.city,

      state: changeAddr
        ? form.getFieldValue("companyNewAddressState")
          ? form.getFieldValue("companyNewAddressState")
          : ""
        : form.getFieldValue("companyAddressState")
        ? form.getFieldValue("companyAddressState")
        : dataObj.state,

      country: changeAddr
        ? form.getFieldValue("companyNewAddressCountry")
          ? form.getFieldValue("companyNewAddressCountry")
          : ""
        : form.getFieldValue("companyAddressCountry")
        ? form.getFieldValue("companyAddressCountry")
        : dataObj.country,

      registrationNumber: form.getFieldValue("registrationNumber")
        ? form.getFieldValue("registrationNumber")
        : form.getFieldValue("registrationNumber") === ""
        ? " "
        : dataObj.registrationNumber,
      taxNumber: form.getFieldValue("taxNumber")
        ? form.getFieldValue("taxNumber")
        : form.getFieldValue("taxNumber") === ""
        ? " "
        : dataObj.taxNumber,
    };

    if (
      dataObj.name !== form.getFieldValue("companyName") ||
      dataObj.name !== dataObj.companyName
    ) {
      inputCompanySettings.name =
        form.getFieldValue("companyName") || dataObj.companyName;
    }
    let inputInvoiceSettings = {
      id: dataObj.id,
      prefix: form.getFieldValue("companyPrefix")
        ? form.getFieldValue("companyPrefix")
        : form.getFieldValue("companyPrefix") === ""
        ? " "
        : dataObj.prefix,
      seed: form.getFieldValue("companySeed")
        ? Number(form.getFieldValue("companySeed"))
        : form.getFieldValue === ""
        ? " "
        : Number(dataObj.seed),
      startNumber: form.getFieldValue("companyStartNumber")
        ? Number(form.getFieldValue("companyStartNumber"))
        : form.getFieldValue === ""
        ? " "
        : Number(dataObj.startNumber),
      enableOnlinePayments: enableOnlinePayments,
      notes: form.getFieldValue("companyNotes")
        ? form.getFieldValue("companyNotes")
        : form.getFieldValue("companyNotes") === ""
        ? " "
        : dataObj.notes,
      terms: form.getFieldValue("companyTerms")
        ? form.getFieldValue("companyTerms")
        : form.getFieldValue("companyTerms") === ""
        ? " "
        : dataObj.terms,
      logo:
        !remove && companyLogoString
          ? companyLogoString
          : remove
          ? ""
          : dataObj.logo,

      enableReminders: reminderMessage
        ? reminderMessage
        : dataObj.enableReminders,
      sendRemindersAfterDueDateInDays: form.getFieldValue(
        "sendRemindersAfterDueDateInDays"
      )
        ? Number(form.getFieldValue("sendRemindersAfterDueDateInDays"))
        : dataObj.sendRemindersAfterDueDateInDays,
      sendRemindersRepetitionInDays: form.getFieldValue(
        "sendRemindersRepetitionInDays"
      )
        ? Number(form.getFieldValue("sendRemindersRepetitionInDays"))
        : dataObj.sendRemindersRepetitionInDays,
      icidActive: dataObj.icidActive,
      tenantId: dataObj.tenantId,
    };
    return (
      api.Receivables.Company.updateCompany(inputCompanySettings) &&
      api.Receivables.Settings.invoice(inputInvoiceSettings)
    );
  }

  const updateInvoiceTemplateMn = useMutation((event) =>
    updateInvoiceTemplateFn(event)
  );
  const onFinish = (values) => {
    form.validateFields();
    updateInvoiceTemplateMn.mutate(values, {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.INVOICE_TEMPLATE_UPDATE_SUCCESS,
        });
        localStorage.setItem("icupdated", true);
        onCancel(false);
        setRemoved(false);
        setStep(1);
        queryClient.invalidateQueries("getCompany");
        queryClient.invalidateQueries("getInvoiceSettings");
        queryClient.invalidateQueries("getProfileSettings");
        queryClient.invalidateQueries("getCompanyEstimateTemplatePDF", icid);
        queryClient.invalidateQueries("getCompanyInvoiceTemplatePDF", icid);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  useEffect(() => {
    if (dataObj && dataObj.enableReminders) {
      setReminderMessage(dataObj.enableReminders);
    }
    if (dataObj && dataObj.enableOnlinePayments) {
      setReminderMessage(dataObj.enableOnlinePayments);
    }
  }, [dataObj]);
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        setStep(1);
        onCancel(true);
        setRemoved(false);
      }}
      onCancel={() => {
        form.resetFields();
        setStep(1);
        onCancel(true);
        setRemoved(false);
      }}
      title="Edit Invoice Template"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
      width={443}
      footer={
        <>
          {step === 1 ? (
            <CTAButton
              type="primary"
              style={{
                height: 44,
              }}
              onClick={nextStep}
            >
              Next
            </CTAButton>
          ) : (
            <>
              <CTAButton
                type="outline"
                style={{
                  height: 44,
                  width: 180,
                  marginRight: 10,
                }}
                onClick={prevStep}
              >
                Back
              </CTAButton>
              <CTAButton
                type="primary"
                style={{ height: 44, width: 180 }}
                onClick={form.submit}
                loading={updateInvoiceTemplateMn.isLoading}
              >
                Save
              </CTAButton>
            </>
          )}
        </>
      }
    >
      <div className={classNames.stepper}>
        {step === 1 ? (
          <>
            <span />
            <span style={{ borderTop: "6px solid #D1D5DD" }} />
          </>
        ) : (
          <>
            <span />
            <span />
          </>
        )}
      </div>
      <div className={classNames.modalBody}>
        <Form
          key="editinvoiceform"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          scrollToFirstError={true}
        >
          {step === 1 ? (
            <CompanyDetails
              form={form}
              nextStep={nextStep}
              fileReader={utilFileReader}
              data={dataObj}
              setRemoved={setRemoved}
              remove={remove}
              userInfo={userInfo}
              changeAddr={changeAddr}
              setChangeAddr={setChangeAddr}
              key={dataObj}
            />
          ) : (
              <InvoiceDetails
                form={form}
                prevStep={prevStep}
                setAcceptQR={setAcceptQR}
                setEnableOnlinePayments={setEnableOnlinePayments}
                setReminderMessage={setReminderMessage}
                acceptQR={acceptQR}
                enableOnlinePayments={enableOnlinePayments}
                reminderMessage={reminderMessage}
                data={dataObj}
                paStatus={paStatus}
                paSettingsStatus={paSettingsStatus}
              />
          )}
        </Form>
        {showSettingsModal && (
          <Settings
            visible={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
            onCancel={() => setShowSettingsModal(false)}
          />
        )}
        {/* {showPAModal && (
          // need to add Payment acceptance form here
          <Settings
            visible={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
            onCancel={() => setShowSettingsModal(false)}
          />
        )} */}
      </div>
    </Modal>
  );
}
