import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  GradientDefs,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  MarkSeries,
  Hint,
} from "react-vis";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useState } from "react";
import classNames from "./TotalCardSpend.module.scss";
import {
  centsToDollar,
  intToDecimalDollar,
} from "../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../../constants";

const TotalCardSpend = ({
  dataArray,
  topCategoryOne,
  topCategoryTwo,
  others,
  height,
  width,
  isSpendExist,
}) => {
  const [state, setState] = useState();
  const forgetValue = () => {
    setState(null);
  };

  const rememberValue = (value) => {
    setState(value);
  };
  return (
    <div className={classNames.wrapper} key="TotalCardSpendWrapper">
      <div className={classNames.chartWrapper} key="TotalCardSpendName">
        <div className={classNames.chartName}>Total Card Spent</div>
        <div className={classNames.chart} key="TotalCardSpendChart">
          <FlexibleXYPlot
            height={height}
            width={width}
            key="TotalCardSpend"
            xType="ordinal"
            margin={{ left: 55, right: 0, top: 50, bottom: 30 }}
            animation={true}
          >
            <XAxis />
            <YAxis
              tickFormat={(value) => {
                const lookupArray = [
                  { scale: 1, symbol: "" },
                  { scale: 1e3, symbol: "k" },
                  { scale: 1e6, symbol: "M" },
                  { scale: 1e9, symbol: "G" },
                  { scale: 1e12, symbol: "T" },
                  { scale: 1e15, symbol: "P" },
                  { scale: 1e18, symbol: "E" },
                ];
                const regX = /\.0+$|(\.[0-9]*[1-9])0+$/;
                let item = lookupArray
                  .slice()
                  .reverse()
                  .find(function (item) {
                    return Math.abs(value) >= item.scale;
                  });
                return item
                  ? (value / item.scale).toString().replace(regX, "$1") +
                      item.symbol
                  : "0";
              }}
              tickPadding={1}
            />
            <HorizontalGridLines />
            <MarkSeries
              onNearestXY={rememberValue}
              onValueMouseOver={rememberValue}
              onValueMouseOut={forgetValue}
              data={
                Array.isArray(dataArray) &&
                dataArray.length > 0 &&
                dataArray.map((value, index) => {
                  return { x: `${value.x}`, y: `${value.y}` };
                })
              }
            />
            {state ? (
              <Hint className={classNames.hint} value={state}>
                <p>{`${
                  intToDecimalDollar(Number(state.y)) || DEFAULT_AMOUNT
                }`}</p>
              </Hint>
            ) : null}
            <GradientDefs>
              <linearGradient x1="0" x2="0" y1="0" y2="1" id="areaGradient">
                <stop offset="0%" stopColor="#52EABC3B" />
                <stop offset="100%" stopColor="#52EABC3B" stopOpacity="-0.1" />
              </linearGradient>
            </GradientDefs>

            <AreaSeries
              animation={true}
              data={dataArray}
              colorType="literal"
              color={"url(#areaGradient)"}
              barWidth={1}
              curve={"curveMonotoneX"}
            />

            <LineSeries
              // animation={true}
              data={dataArray}
              colorType="literal"
              color={"#1DBC83"}
              barWidth={1}
              curve={"curveMonotoneX"}
              lineStyle={{ stroke: "red" }}
              markStyle={{ stroke: "black", fill: "red" }}
            />
          </FlexibleXYPlot>
        </div>
      </div>
      {isSpendExist && (
        <div className={classNames.itemsWrapper}>
          <div className={classNames.item}>
            <div className={classNames.itemHeading}>
              <div className={classNames.iconContainer}>
                <RiArrowRightUpLine color="#1DBC83" size={18} />
              </div>
              <div className={classNames.heading}>{topCategoryOne[0]}</div>
            </div>
            <div className={classNames.count}>
              {centsToDollar(topCategoryOne[1])
                ? centsToDollar(topCategoryOne[1])
                : "0"}
            </div>
          </div>
          <div className={classNames.item}>
            <div className={classNames.itemHeading}>
              <div className={classNames.iconContainer}>
                <RiArrowRightUpLine color="#1DBC83" size={18} />
              </div>
              <div className={classNames.heading}>{topCategoryTwo[0]}</div>
            </div>
            <div className={classNames.count}>
              {centsToDollar(topCategoryTwo[1])
                ? centsToDollar(topCategoryTwo[1])
                : "$0.00"}
            </div>
          </div>
          <div className={classNames.item}>
            <div className={classNames.itemHeading}>
              <div className={classNames.iconContainer}>
                <RiArrowRightUpLine color="#1DBC83" size={18} />
              </div>
              <div className={classNames.heading}>Others</div>
            </div>
            <div className={classNames.count}>
              {centsToDollar(others) ? centsToDollar(others) : "$0.00"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalCardSpend;
