import { useState, useEffect } from 'react';

const useDeviceOS = () => {
  const [deviceOS, setDeviceOS] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    
    if (userAgent.match(/Android/i)) {
      setDeviceOS('Android');
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setDeviceOS('iOS');
    } else if (userAgent.match(/Windows/i)) {
      setDeviceOS('Windows');
    } else if (userAgent.match(/Mac OS/i)) {
      setDeviceOS('macOS');
    } else if (userAgent.match(/Linux/i)) {
      setDeviceOS('Linux');
    } else {
      setDeviceOS('Unknown');
    }
  }, []);

  return deviceOS;
};

export default useDeviceOS;
