import { API } from "aws-amplify";

export default async function getInvoicePDF(id) {
  if (id) {
    return await API.get("invoices", "invoice-template-pdf", {
      queryStringParameters: {
        id: id,
      },
    });
  }
}
