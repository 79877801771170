import classNames from "./HostedPayment.module.scss";
import copy from "copy-to-clipboard";
import FileCopy2LineIcon from "remixicon-react/FileCopy2LineIcon";
import { Input, Select, CTAButton, Title, Message } from "../../../../common";
import { RiMailLine, RiEyeLine } from "react-icons/ri";
import { QrcodeOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form, Checkbox, Button as AntButton, Spin } from "antd";
import { useState, useEffect } from "react";
import Preview from "./Preview/Preview";

export default function HostedPaymentForm({ generatePaymentLink }) {
  const [form] = Form.useForm();
  const onFinish = (data) => {};
  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = useState(false);
  const handlePreviewButton = () => {
    setPreview(false);
  };

  useEffect(() => {
    if (generatePaymentLink) {
      setLoading(true);
      setTimeout(() => setLoading(false), 2000);
    }
  }, [generatePaymentLink]);

  return (
    <div style={{ display: "flex" }}>
      <div className={classNames.modalLeft}>
        <div>
          <Title
            as="h5"
            style={{ color: "#BC7C1D" }}
            className={classNames.label}
          >
            Optional Information
          </Title>
        </div>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item>
            <Form.Item
              label={
                <Title as="h4" className={classNames.label}>
                  First Name
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>
            <Form.Item
              label={
                <Title as="h4" className={classNames.label}>
                  Last Name
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="company"
            label={<span className={classNames.label}>Company</span>}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="billingAddress"
            label={<span className={classNames.label}>Billing Address</span>}
          >
            <Form.Item>
              <Input placeholder="Street" size="small" />
            </Form.Item>
            <Form.Item>
              <Input placeholder="Street 2" size="small" />
            </Form.Item>
            <Form.Item>
              <Select placeholder="City" size="small"></Select>
            </Form.Item>
            <Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <Select placeholder="State" size="small"></Select>
              </Form.Item>

              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <Input placeholder="Postal code" size="small" />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Select placeholder="Country" size="small"></Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={<span className={classNames.label}>Phone Number</span>}
          >
            <Input
              size="small"
              placeholder="Please enter"
              prefix={
                "+1"
                // <Form.Item>
                //   <Select
                //     size="small"
                //     placeholder="+19"
                //     className={classNames.mobileInputPrefix}
                //     showSearch
                //     filterOption={(input, option) =>
                //       option.children
                //         .toLowerCase()
                //         .indexOf(input.toLowerCase()) >= 0
                //     }
                //   >
                //     <Select.Option value="">+19</Select.Option>
                //   </Select>
                // </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item
            name="shippingAddress"
            label={<span className={classNames.label}>Shipping Address</span>}
          >
            <Checkbox>Same as billing</Checkbox>
          </Form.Item>
          <Form.Item
            name="invoice"
            label={<span className={classNames.label}>Invoice</span>}
          >
            <Input
              size="small"
              style={{ width: 135 }}
              placeholder="Please enter"
            />
          </Form.Item>
          <Form.Item
            name="details"
            label={<span className={classNames.label}>Details </span>}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <Title
          as="h5"
          style={{ color: "#EE6969" }}
          className={classNames.label}
        >
          Mandatory fields
        </Title>

        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="amount"
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              className={classNames.inputAmount}
              style={{
                backgroundColor: "#F6F7FA",
                hight: 54,
                border: "#fff",
              }}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              placeholder="Enter Amount"
            />
          </Form.Item>
          <Checkbox style={{ marginLeft: 0 }}>
            Allow user to adjust amount
          </Checkbox>
        </Form>
        <div className={classNames.shareWrapper}>
          {loading ? (
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
              />
              <Title as="h2">Generating payment link...</Title>
            </div>
          ) : generatePaymentLink ? (
            <div>
              <Title as="h3">Share this payment link</Title>
              <CTAButton
                style={{
                  height: 44,
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#F3F3FF",
                  border: "none",
                  color: "#7372FF",
                }}
                type="primary"
                onClick={() => {
                  copy("http://ottrz.pay.com");
                  Message({
                    type: "success",
                    content: "Link copied to clipboard!",
                  });
                }}
              >
                <div className={classNames.shareSection}>
                  <span>http://ottrz.pay.com</span>
                  <div>
                    <FileCopy2LineIcon size={12} style={{ marginRight: 10 }} />
                    Copy
                  </div>
                </div>
              </CTAButton>
              <div className={classNames.bottomBar}>
                <AntButton
                  style={{ height: "44px" }}
                  icon={<RiEyeLine style={{ marginRight: 5 }} />}
                  onClick={() => {
                    setPreview(true);
                  }}
                >
                  Preview
                </AntButton>
                <AntButton
                  style={{ height: "44px", width: "100px" }}
                  icon={<RiMailLine style={{ marginRight: 5 }} />}
                >
                  Email
                </AntButton>
                <AntButton style={{ height: "44px" }} icon={<QrcodeOutlined />}>
                  Get QR Code
                </AntButton>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Preview visible={preview} onCancel={handlePreviewButton} />
    </div>
  );
}
