import React from "react";
import classNames from "./Chatbot.module.scss";

function Chatbot() {
  return (
    <div className={classNames.wrapper}>
      <iframe
        src="http://ec2-52-91-35-16.compute-1.amazonaws.com:8501/"
        style={{ width: "100%", height: "100vh", border: "none" }}
        title="Chatbot Iframe"
      />
    </div>
  );
}

export default Chatbot;