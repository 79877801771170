import { Navigate, useLocation } from "react-router-dom";

import useUserState from "../hooks/useUserState";
import useRouterSwitch from "../hooks/useRouterSwitch";
import { ROUTES, USER_STATES } from "../App/constants";
import useScopeForRole from "../hooks/useScopeForRole";
import { invoiceRoutes } from "../App/constants/invoiceRoutes";
import useInvoiceActiveStatus from "../hooks/useInvoiceActiveStatus";

const AuthorizedRoute = ({ children }) => {
  const userType = useUserState(); // get the current state of the user
  const path = useRouterSwitch(); // give the next route to navigate if user is not authorized
  const location = useLocation();
  const currentPath = location.pathname;
  const isScopeAvailable = useScopeForRole();
  const { icidStatus: invoiceStatus } = useInvoiceActiveStatus();

  if (userType === USER_STATES.AUTHORIZED_USER && isScopeAvailable) {
    if (
      invoiceRoutes.some((route) => route === currentPath) &&
      !invoiceStatus
    ) {
      return <Navigate to={ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED} />;
    }

    // if the user is authorized user and has access to the route render content
    return children;
  } else if (userType === USER_STATES.AUTHORIZED_USER && !isScopeAvailable) {
    // if the user is authorized but doesn't have access to the route send to the forbidden page
    return <Navigate to="/forbidden" state={{ from: currentPath }} />;
  } else {
    // neither conditions are satisfied send the user to the next available path
    return <Navigate to={path} replace />;
  }
};

export default AuthorizedRoute;
