import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Form,
  Input as AntInput,
  Button as AntButton,
  AutoComplete,
} from "antd";
import {
  RiAddFill,
  RiArrowDropDownLine,
  RiCloseCircleFill,
} from "react-icons/ri";
import moment from "moment";

import classNames from "./EditRecurringInvoice.module.scss";
import {
  Title,
  Input,
  Select,
  DatePicker,
  Message,
  Modal,
  CTAButton,
} from "../../../../common";
import { DEFAULT_AMOUNT, FORMATS } from "../../../../constants";
import { validation } from "../../../../Auth/config";
import api from "../../../../API";
import { intToDecimalDollar } from "../../../../util/functions/dollarConvertion";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import TextArea from "antd/lib/input/TextArea";

export default function EditRecurringInvoice({
  recurringInvoice,
  products,
  taxRates,
  customers,
  visible,
  onCancel,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  const language = useLanguage(messages);

  const [summary, setSummary] = useState({
    subTotal: recurringInvoice.subTotal,
    tax: recurringInvoice.taxTotal,
  });

  const [discount, setDiscount] = useState(recurringInvoice.discount);
  const queryClient = useQueryClient();
  const [currFields, setCurrFields] = useState(
    recurringInvoice?.item?.length || 1
  );

  const calculatePrice = (index, flag, value) => {
    let values = form.getFieldsValue();
    let obj = values["salesItemsRecurring"][index];
    let product =
      Array.isArray(products) &&
      products.filter(
        (productItem, index) => productItem.id === obj.description?.[0]
      );
    let productUnitCost;
    let productTaxRate;
    if (flag === "description") {
      let productFromProductsArray =
        Array.isArray(products) &&
        products.filter((productItem, index) => productItem.id === value.id);
      productUnitCost = productFromProductsArray[0]?.price || 0;
      productTaxRate = productFromProductsArray[0]?.taxRate || 0;
    }
    if (flag === "taxRate") {
      let taxObj =
        Array.isArray(taxRates) &&
        taxRates.filter((val) => val.id === value[0])[0];
      productTaxRate = (taxObj && taxObj.taxRate) || value[0];
    }
    if (flag === "unitCost") {
      let taxObj =
        (Array.isArray(taxRates) &&
          taxRates.filter(
            (taxRateItem) => taxRateItem.id === obj.taxRate?.[0]
          )[0]) ||
        0;
      productTaxRate =
        (taxObj && taxObj.taxRate) || (obj.taxRate && obj.taxRate[0]);
    }

    productTaxRate = productTaxRate
      ? Number(productTaxRate)
      : Number(obj.taxRate);
    let unitCost = productUnitCost ? Number(productUnitCost) : obj.unitCost;
    let quantity = obj.quantity ? parseInt(obj.quantity) : 1;
    let totalWithOutTax = Number(unitCost) * Number(quantity) || 0;
    let taxAmount = productTaxRate
      ? parseFloat((totalWithOutTax * Number(productTaxRate)) / 100)
      : 0;
    let price = unitCost * quantity;
    //+ taxAmount;
    let array = values["salesItemsRecurring"];

    Object.assign(obj, {
      unitCost: unitCost || 0,
      price: price.toFixed(2) || 0,
      taxAmount: taxAmount,
      taxRate: productTaxRate ? Number(productTaxRate) : 0,
      subTotal: totalWithOutTax,
      quantity: quantity,
      description:
        product && product?.length > 0
          ? product[0]?.productName
          : obj.description,
    });

    if (flag === "remove") {
      delete array[index];
      array.filter(Boolean);
      form.setFieldsValue({ salesItemsRecurring: array });
      setCurrFields((prev) => prev - 1);
    } else {
      array.filter(Boolean);
      form.setFieldsValue({ salesItemsRecurring: array });
    }
    let subTotal = array
      .map(
        (value, index) =>
          Number(value.subTotal) ||
          Number(value.unitCost) * Number(value.quantity)
      )
      .reduce((sum, next) => sum + next, 0);
    let tax = array
      .map((value, index) => {
        return (
          Number(value.taxAmount) ||
          Number(value.taxRate) ||
          Number(value.unitCost * value.quantity * (value.taxRate / 100)) ||
          0
        );
      })
      .reduce((sum, next) => sum + next, 0);
    setSummary({ subTotal, tax });
  };

  function editInvoiceFn(data) {
    let array = data.salesItemsRecurring
      ? data.salesItemsRecurring
      : recurringInvoice.item;
    let itemDto =
      array &&
      array?.length > 0 &&
      array.map((item, index) => {
        return {
          description:
            item.description && Array.isArray(item.description)
              ? item.description[0]
              : item.description,
          quantity: Number(item.quantity),
          taxRate: item.taxRate ? Number(item.taxRate) : 0,
          unitCost: Number(item.unitCost),
        };
      });

    const reference =
      data.purchaseOrderNumber === "" && !data.purchaseOrderNumber
        ? " "
        : data.purchaseOrderNumber;

    let input = {
      id: recurringInvoice.id,
      startDate: data.startDate
        ? moment(data.startDate).format(FORMATS.API_OTHER_FORMAT)
        : moment(recurringInvoice.startDate).format(FORMATS.API_OTHER_FORMAT),
      discount: data.discount
        ? Number(data.discount)
        : data.discount === ""
        ? 0
        : Number(recurringInvoice.discount),
      reference: reference || recurringInvoice.purchaseOrderNumber,
      itemDto: itemDto,
      period: data.scheduledPeriod
        ? data.scheduledPeriod
        : recurringInvoice.scheduledPeriod,
      timing: data.scheduledTiming
        ? data.scheduledTiming
        : recurringInvoice.scheduledTiming,
      action: data.scheduledAction
        ? data.scheduledAction
        : recurringInvoice.scheduledAction,
    };
    return api.Receivables.Recurring.editRecurringInvoice(input);
  }

  const editInvoiceMn = useMutation((event) => editInvoiceFn(event));
  const onFinish = (values) => {
    editInvoiceMn.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries("getRecurringData");
        queryClient.invalidateQueries(
          "getRecurringInvoiceById",
          recurringInvoice.id
        );
        queryClient.invalidateQueries("getInvoicePDF", recurringInvoice.id);
        Message({ type: "success", content: language.INVOICE_UPDATE_SUCCESS });
        onCancel();
        form.resetFields();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  let initialRecurringInvoicesSaleItems = recurringInvoice?.item
    ? recurringInvoice?.item?.length > 0 &&
      recurringInvoice?.item.map((value, index) => {
        return {
          key: `rIinvoice${index}`,
          description: value.description,
          unitCost: value.unitCost,
          quantity: value.quantity,
          taxRate: value.taxRate,
          taxAmount: value.unitCost * value.quantity * (value.taxRate / 100),
          price: value.unitCost * value.quantity,
          subTotal: Number(value.subTotal) || 0,
        };
      })
    : [];

  useEffect(() => {
    form.resetFields();
  }, [form, recurringInvoice]);

  useEffect(() => {
    setSummary({
      subTotal: recurringInvoice.subTotal,
      tax: recurringInvoice.taxTotal,
    });
    setDiscount(recurringInvoice.discount);
  }, [recurringInvoice]);

  //Helper
  function validationCheck() {
    console.log(form.getFieldsValue()?.salesItemsRecurring, currFields);
    return (
      form
        ?.getFieldsError()
        ?.filter(
          (item) =>
            ["unitCost", "description"].includes(item?.name[2]) &&
            item?.errors?.length
        )?.length ||
      !(form?.getFieldsValue()?.salesItemsRecurring?.length
        ? form?.getFieldsValue()?.salesItemsRecurring[currFields - 1]
            ?.unitCost &&
          form?.getFieldsValue()?.salesItemsRecurring[currFields - 1]
            ?.description
        : false)
    );
  }

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        onCancel();
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      title="Edit Recurring Invoice"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      footer={
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          onClick={form.submit}
          style={{
            height: 44,
            width: 248,
          }}
          loading={editInvoiceMn.isLoading}
        >
          Save
        </CTAButton>
      }
      width={872}
      getContainer={false}
    >
      <Form
        key={`rIeditRecurringInvoiceForm`}
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <div
          key={`rImodalBodyFormUpper`}
          className={classNames.modalBodyFormUpper}
        >
          <div key={`rImodalLeft`} className={classNames.modalLeft}>
            <div key={`rImodalHeader`} className={classNames.modalHeader}>
              <Title as="h3">Invoice Details</Title>
            </div>
            <Form.Item
              name="number"
              key={`rIrecurringInvoicenumber`}
              label={
                <Title as="h4" className={classNames.label}>
                  Invoice Number
                </Title>
              }
              initialValue={recurringInvoice?.invoiceNumber}
            >
              <Input
                key={`rIrecurringInvoiceNumberInput`}
                size="small"
                style={{ width: 168 }}
                disabled
              />
            </Form.Item>
            <Form.Item
              key={`rIrIreceiverName`}
              name="receiverName"
              label={
                <Title as="h4" className={classNames.label}>
                  Customer
                </Title>
              }
              initialValue={recurringInvoice?.receiverName}
            >
              <Select
                key={`rIreceiverNameInput`}
                size="small"
                style={{ width: 352 }}
                disabled
              >
                {Array.isArray(customers) &&
                  customers.map((val, index) => (
                    <Select.Option key={`rIreceiverNameOption`} value={val.id}>
                      {val.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              key={`rIfrequency_input_field`}
              label={
                <span className={classNames.label}>
                  Frequency <sup style={{ top: 1 }}>*</sup>
                </span>
              }
            >
              <Form.Item
                key={`rIscheduledTiming`}
                name="scheduledTiming"
                style={{
                  display: "inline-block",
                  marginRight: 14,
                  width: 168,
                }}
                initialValue={recurringInvoice?.scheduledTiming}
                rules={[
                  { required: true, message: "Frequency is required" },
                  {
                    pattern: new RegExp(/^(?!0)[0-9]{1,6}$/),
                    message: language.TIMING_VALUES_ALLOWED,
                  },
                  () => ({
                    validator(_, value) {
                      if (value < 32) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(language.TIMING_VALUES_ALLOWED)
                      );
                    },
                  }),
                ]}
              >
                <Input
                  key={`rIscheduledTimingInput`}
                  size="small"
                  prefix="Every"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                key={`rIscheduledPeriod`}
                name="scheduledPeriod"
                style={{
                  display: "inline-block",
                }}
                initialValue={recurringInvoice?.scheduledPeriod}
              >
                <Select
                  key={`rIscheduledPeriodInput`}
                  size="small"
                  style={{ width: 168 }}
                  placeholder="Daily"
                >
                  <Select.Option key={`rIday`} value="day">
                    Daily
                  </Select.Option>
                  <Select.Option key={`rIweek`} value="week">
                    Weekly
                  </Select.Option>
                  <Select.Option key={`rImonth`} value="month">
                    Monthly
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item key={`rIfrequency_input_group`}>
              <Form.Item
                key={`rIstartDate`}
                name="startDate"
                label={
                  <span className={classNames.label}>
                    Start Date<sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                style={{
                  display: "inline-block",
                  marginRight: 14,
                }}
                initialValue={moment(recurringInvoice?.startDate)}
                rules={[{ required: true, message: "Start date is required" }]}
              >
                <DatePicker
                  key={`rIstartDateInput`}
                  format={FORMATS.DATEPICKER_FORMAT}
                  style={{ height: 44, width: 168 }}
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </Form.Item>
              <Form.Item
                key={`rIdiscount`}
                name="discount"
                label={<span className={classNames.label}>Discount</span>}
                style={{
                  display: "inline-block",
                }}
                rules={[
                  {
                    pattern: new RegExp(
                      /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,13})?$)/g
                    ),
                    message: "Valid Discount required.",
                  },
                ]}
                initialValue={recurringInvoice?.discount}
              >
                <Input
                  key={`rIdiscountInput`}
                  size="small"
                  style={{ width: 168 }}
                  prefix="%"
                  defaultValue={recurringInvoice.discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              key={`rIpurchaseOrderNumber`}
              name="purchaseOrderNumber"
              label={
                <Title as="h4" className={classNames.label}>
                  Reference
                </Title>
              }
              initialValue={recurringInvoice?.purchaseOrderNumber}
              rules={[{ max: 50, message: "Max 50 characters" }]}
            >
              <Input
                key={`rIpurchaseOrderNumberInput`}
                size="small"
                style={{ width: 352 }}
              />
            </Form.Item>
          </div>
          <div key={`modalRight`} className={classNames.modalRight}>
            <div key={`invoiceSummary`} className={classNames.invoiceSummary}>
              <div key={`modalHeader`} className={classNames.modalHeader}>
                <Title as="h3">Invoice Summary</Title>
              </div>
              <div>
                <span>Sub Total</span>
                <span>
                  {intToDecimalDollar(Number(summary.subTotal)) ||
                    DEFAULT_AMOUNT}
                </span>
              </div>
              <div>
                <span>Tax</span>
                <span>
                  {intToDecimalDollar(Number(summary.tax)) || DEFAULT_AMOUNT}
                </span>
              </div>
              <div>
                <span>Discount</span>
                <span>{discount}% </span>
              </div>
              <div className={classNames.total}>
                <span>Total</span>
                <span>
                  {intToDecimalDollar(
                    Number(
                      summary.subTotal -
                        summary.subTotal * (discount / 100 || 0) +
                        summary.tax
                    )
                  ) || DEFAULT_AMOUNT}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.saleItemsWrapper}>
          <div className={classNames.modalHeader}>
            <Title as="h3">Sale Items</Title>
          </div>
          <div className={classNames.salesItemsHeader}>
            <span
              style={{
                width: "35%",
                marginLeft: currFields > 1 ? "32px" : "",
                marginRight: currFields > 1 ? "-5px" : "5px",
              }}
            >
              Description <sup style={{ top: 1 }}>*</sup>
            </span>
            <span style={{ width: 125 }}>
              Unit Cost <sup style={{ top: 1 }}>*</sup>
            </span>
            <span style={{ width: 75 }}>
              Qty <sup style={{ top: 1 }}>*</sup>
            </span>
            <span style={{ width: 115 }}>
              Tax <sup style={{ top: 1 }}>*</sup>
            </span>
            <span style={{ width: 150 }}>Price</span>
          </div>
          <div>
            <Form.List
              name="salesItemsRecurring"
              initialValue={initialRecurringInvoicesSaleItems}
              rules={[
                {
                  validator: async (_, salesItemsRecurring) => {
                    if (
                      !salesItemsRecurring ||
                      salesItemsRecurring?.length < 1
                    ) {
                      return Promise.reject(
                        new Error(validation.salesItems.error)
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      key={field.key}
                      required={false}
                      className={classNames.inputGroup}
                    >
                      {fields.length > 1 ? (
                        <RiCloseCircleFill
                          size={20}
                          className={classNames.inputGroupRemoveIcon}
                          onClick={() => {
                            if (currFields > 1) {
                              calculatePrice(index, "remove");
                              remove(field.index);
                            } else {
                              form.resetFields();
                            }
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <AntInput.Group
                        compact
                        className={classNames.inputGroupItems}
                      >
                        <Form.Item
                          name={[field.name, "description"]}
                          style={{
                            width: "35%",
                            marginRight: "5px",
                          }}
                          rules={[
                            { required: true, message: "Required" },
                            () => ({
                              validator(_, value) {
                                if (value && value?.length > 50) {
                                  return Promise.reject(
                                    new Error("Max 50 characters")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <AutoComplete
                            size="large"
                            style={{ width: "100%" }}
                            className={classNames.autoSelectDesc}
                            onChange={(label, value) => {
                              calculatePrice(index, "description", value);
                            }}
                            showSearch
                            optionFilterProp="label"
                            filterOption={true}
                            options={
                              Array.isArray(products) &&
                              products.map((val, index) => {
                                return {
                                  key: `productName`,
                                  label: val.productName,
                                  value: val.productName,
                                  id: val.id,
                                };
                              })
                            }
                            mode="tags"
                            maxTagCount={1}
                          ></AutoComplete>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "unitCost"]}
                          rules={[
                            { required: true, message: "Required" },
                            {
                              pattern: new RegExp(
                                /^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/
                              ),
                              message: "Enter a valid price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value === "0" || value === 0) {
                                  return Promise.reject(
                                    new Error("0 is not allowed")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                            () => ({
                              validator(_, value) {
                                if (value && value?.toString()?.length > 11) {
                                  return Promise.reject(
                                    new Error(
                                      "Max 8 digits with 2 decimal points"
                                    )
                                  );
                                }
                                if (!value?.toString()?.length) {
                                  return Promise.reject(new Error("Required"));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            placeholder="Enter"
                            prefix="$"
                            style={{ width: 125, marginRight: "5px" }}
                            type="number"
                            min={0}
                            maxLength={11}
                            onChange={() => calculatePrice(index, "unitCost")}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "quantity"]}
                          rules={[
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: "Invalid quantity",
                            },
                            {
                              required: true,
                              message: "Required",
                            },
                            () => ({
                              validator(_, value) {
                                if (value && value?.toString()?.length > 6) {
                                  return Promise.reject(
                                    new Error("Max 6 characters")
                                  );
                                }
                                if (!value?.toString()?.length) {
                                  return Promise.reject(new Error("Required"));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            placeholder="Enter"
                            style={{ width: 75, marginRight: "5px" }}
                            type="number"
                            onChange={() => calculatePrice(index)}
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item name={[field.name, "taxRate"]}>
                          <Select
                            size="small"
                            style={{ width: 115, marginRight: "5px" }}
                            className={classNames.salesItemSelect}
                            onChange={(value, obj) => {
                              calculatePrice(index, "taxRate", obj);
                            }}
                            showSearch
                            optionFilterProp="keyword"
                            filterOption={true}
                            // mode="tags"
                            // maxTagCount={1}
                            suffixIcon={
                              <span style={{ color: "#9ba3ab" }}>
                                %<RiArrowDropDownLine />
                              </span>
                            }
                            options={
                              Array.isArray(taxRates) &&
                              taxRates.map((val, index) => {
                                return {
                                  key: `${val.taxRate}`,
                                  label: `${val.taxRateName} (${val.taxRate}%)`,
                                  value: Number(val.taxRate),
                                  keyword: val.taxRate,
                                };
                              })
                            }
                          ></Select>
                        </Form.Item>
                        <Form.Item name={[field.name, "price"]}>
                          <Input
                            size="small"
                            placeholder="0"
                            prefix="$"
                            style={{
                              width: 150,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              marginRight: "5px",
                            }}
                            // type="number"
                            disabled
                            //   value={getItemsPrice(
                            //     form.getFieldValue("Items")[index]
                            //   )}
                          />
                        </Form.Item>
                      </AntInput.Group>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <div
                      className={classNames.salesItemsFooter}
                      style={{ marginLeft: currFields === 1 ? "0" : "14px" }}
                    >
                      <AntButton
                        onClick={() => {
                          const currentSaleItem =
                            form.getFieldsValue().salesItemsRecurring[
                              currFields - 1
                            ];
                          if (
                            currentSaleItem?.unitCost &&
                            currentSaleItem?.description
                          ) {
                            add();
                            setCurrFields((prev) => prev + 1);
                          }
                        }}
                        type="link"
                        className={classNames.addNewButton}
                        disabled={validationCheck()}
                      >
                        <RiAddFill size={13} style={{ marginRight: 5 }} />
                        Add new item
                      </AntButton>
                    </div>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div className={classNames.modalHeader}>
          <Title as="h3">More Information</Title>
        </div>
        <Form.Item>
          <Form.Item
            name="invoiceNotes"
            label={<span className={classNames.label}>Notes</span>}
            style={{
              display: "inline-block",
              marginRight: 14,
            }}
            initialValue={invoiceTemplateSettings?.notes}
          >
            <TextArea
              size="small"
              style={{ width: 380 }}
              placeholder="Prefilled notes"
              disabled
              autoSize={true}
            />
          </Form.Item>

          <Form.Item
            name="invoiceTerms"
            label={<span className={classNames.label}>Terms</span>}
            style={{
              display: "inline-block",
            }}
            initialValue={invoiceTemplateSettings?.terms}
          >
            <TextArea
              size="small"
              style={{ width: 380 }}
              placeholder="Prefilled invoice terms"
              disabled
              autoSize={true}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
}
