import endpoints from "../endpoints";

export default async function uploadDocumentFrontAPI(data){
    let env = process.env.REACT_APP_ENV?.trim()
    return await(
        await fetch(endpoints.ONBOARDING.UPLOAD_DOCUMENTS_FRONT[env],{
            method: "POST",
            body: JSON.stringify(data),
          })
    ).json();
}