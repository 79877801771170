import classNames from "../MakePayments.module.scss";
import { Modal } from "../../../common";
import WireTransactionForm from "./WireTransactionForm";

export default function WirePayments({
  visible,
  onCancel,
  accountBalance,
  payeeData,
  refetch,
}) {
  return (
    <Modal
      closable={true}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      title="Wire Transfer"
      className={classNames.modal}
      bodyStyle={{
        padding: 0,
        height: "70vh",
        display: "flex",
        justifyContent: "space-between",
      }}
      width={730}
    >
      <WireTransactionForm
        accountBalance={accountBalance}
        payeeData={payeeData}
        refetch={refetch}
        onCancel={onCancel}
      />
    </Modal>
  );
}
