import classNames from "./UpdateSchedule.module.scss";
import { Modal } from "../../../../common";
import UpdateScheduleForm from "./UpdateScheduleForm";

export default function UpdateSchedule({
  visible,
  onCancel,
  queryClient,
  selectedRecord,
}) {
  return (
    <>
      <Modal
        destroyOnClose
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title={"Update Schedule"}
        className={classNames.modal}
        bodyStyle={{
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        width={866}
      >
        <UpdateScheduleForm
          queryClient={queryClient}
          onCancel={onCancel}
          selectedRecord={selectedRecord}
        />
      </Modal>
    </>
  );
}
