import { API } from "aws-amplify";

export default async function getTenantById(id) {
  if (id) {
    return await API.get("tenant", "", {
      queryStringParameters: {
        tenantId: id,
      },
    });
  }
}
