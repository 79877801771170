import { useState } from "react";
import classNames from "../../ManageCards.module.scss";
import { Form, Radio, Space } from "antd";

export default function CardType({
  onSuccess,
  form,
  isAllowPhysicalCard,
  isAllowVirtualCard,
  allowedPhysicalCard,
  allowedVirtualCard,
}) {
  const [notification, setNotification] = useState();

  const restrictObject = {
    physical: `All accounts are liable to only ${allowedPhysicalCard} physical card`,
    virtual: `All accounts are liable to only ${allowedVirtualCard} virtual card`,
  };

  return (
    <Form
      layout="vertical"
      // layout="horizontal"
      hideRequiredMark
      name="Card Type"
      onFinish={onSuccess}
      form={form}
      style={{ width: "250px", margin: "20px auto 0" }}
      autoComplete="off"
    >
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: "Type is required",
          },
        ]}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          onChange={(data) => {
            onSuccess(data);
            form.resetFields();
          }}
        >
          <Space size={9}>
            <Radio.Button
              className={classNames.optionTxtButton}
              value={`PHYSICAL`}
              disabled={!isAllowPhysicalCard}
              onMouseEnter={() =>
                setNotification(
                  !isAllowPhysicalCard ? restrictObject.physical : null
                )
              }
              onMouseLeave={() => setNotification(null)}
            >
              Physical
            </Radio.Button>
            <Radio.Button
              className={classNames.optionTxtButton}
              value={`VIRTUAL`}
              disabled={!isAllowVirtualCard}
              onMouseEnter={() =>
                setNotification(
                  !isAllowVirtualCard ? restrictObject.virtual : null
                )
              }
              onMouseLeave={() => setNotification(null)}
            >
              Virtual
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>

      <div className={classNames.notification}>
        {notification ? (
          <>
            <span style={{ color: "#EE6969" }}>* </span>
            {notification}
          </>
        ) : null}
      </div>
    </Form>
  );
}
