import classNames from "../TemplateSettings.module.scss";
import { Modal, CTAButton, Message, Input } from "../../../../common";
import { Form } from "antd";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function EditEstimateTemplate({ visible, onCancel, dataObj }) {
  const [form] = Form.useForm();
  const language = useLanguage(messages);
  const queryClient = useQueryClient();

  function updateEstimateTemplateFn(data) {
    let input = {
      id: dataObj.icid,
      data: {
        terms: data.terms
          ? data.terms
          : data.terms === ""
          ? " "
          : dataObj.terms,
        notes: data.notes
          ? data.notes
          : data.notes === ""
          ? " "
          : dataObj.notes,
        prefix: data.prefix
          ? data.prefix
          : data.prefix === ""
          ? " "
          : dataObj.prefix,
        seed: data.seed
          ? Number(data.seed)
          : data.seed === ""
          ? " "
          : dataObj.seed,
        startNumber: data.startNumber
          ? Number(data.startNumber)
          : data.startNumber === ""
          ? " "
          : dataObj.startNumber,
      },
    };
    return api.Receivables.Settings.estimate(input);
  }

  const updateestimateTemplateMn = useMutation((event) =>
    updateEstimateTemplateFn(event)
  );
  const onFinish = (values) => {
    updateestimateTemplateMn.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries("getEstimateSettings");
        Message({
          type: "success",
          content: language.ESTIMATE_TEMPLATE_UPDATE_SUCCESS,
        });
        onCancel(false);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        onCancel(true);
      }}
      onCancel={() => {
        form.resetFields();
        onCancel(true);
      }}
      title="Edit Estimate Template"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
      width={434}
      footer={
        <CTAButton
          type="primary"
          style={{ height: 44 }}
          onClick={form.submit}
          loading={updateestimateTemplateMn.isLoading}
        >
          Save
        </CTAButton>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item>
          <Form.Item
            name="prefix"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[{ max: 25, message: "Max 25 characters" }]}
            label={<span className={classNames.label}>Prefix</span>}
            extra={
              <span style={{ fontSize: "10px" }}>
                Your invoice no. prefix (e.g. INV-333)
              </span>
            }
          >
            <Input
              placeholder="Please enter"
              size="small"
              defaultValue={dataObj?.prefix}
              maxLength={25}
            />
          </Form.Item>

          <Form.Item
            name="seed"
            initialValue={dataObj?.seed}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
            rules={[
              () => ({
                validator(_, val) {
                  // Seed value is not allowed to be empty according to invoicea.
                  // Endpoint: Patch / estimate settings
                  // As the max char length is zero,
                  // following function calculates the sum of the added seed value and validates.
                  // Seed value needs be an integer
                  const value = val && val.toString();
                  let amount;
                  let valuesArray =
                    (value && value.length > 1 ? value.split("") : [value]) ||
                    [];
                  if (Array.isArray(valuesArray) && valuesArray.length > 0) {
                    amount = valuesArray.reduce(
                      (a, b) => Number(a) + Number(b),
                      0
                    );
                  }
                  if (!Number.isInteger(Number(value))) {
                    return Promise.reject(
                      new Error("Decimal numbers are not allowed")
                    );
                  }
                  if (value.length > 6) {
                    return Promise.reject(new Error("Max 6 characters"));
                  }
                  if (!value.length || amount === 0 || value < 0) {
                    return Promise.reject(
                      new Error("Seed should be greater than zero")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            extra={
              <span style={{ fontSize: "10px" }}>
                Your next invoice# will be increased by this amount
              </span>
            }
            label={
              <span className={classNames.label}>
                Seed<sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              type="text"
              placeholder="Please enter"
              size="small"
              defaultValue={dataObj?.seed}
              maxLength={6}
            />
          </Form.Item>

          <Form.Item
            name="startNumber"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            initialValue={dataObj?.startNumber}
            rules={[
              () => ({
                validator(_, val) {
                  const value = val && val.toString();
                  let amount;
                  let valuesArray =
                    (value && value.length > 1 ? value.split("") : [value]) ||
                    [];
                  if (Array.isArray(valuesArray) && valuesArray.length > 0) {
                    amount = valuesArray.reduce(
                      (a, b) => Number(a) + Number(b),
                      0
                    );
                  }
                  if (!value.length) {
                    return Promise.reject(
                      new Error("Start Number is required")
                    );
                  }
                  if (!Number.isInteger(Number(value))) {
                    return Promise.reject(
                      new Error("Only integer numbers are allowed")
                    );
                  }
                  if (value.length > 6) {
                    return Promise.reject(new Error("Max 6 characters"));
                  }
                  if (!value.length || amount === 0 || value < 0) {
                    return Promise.reject(
                      new Error("Start Number should be greater than zero")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            extra={
              <span style={{ fontSize: "10px" }}>
                Your estimate# will be started by this number
              </span>
            }
            label={
              <span className={classNames.label}>
                Start Number<sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              type="text"
              placeholder="Please enter"
              size="small"
              defaultValue={dataObj?.startNumber}
              maxLength={6}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="terms"
          label={<span className={classNames.label}>Terms</span>}
          rules={[{ max: 1024, message: "Max 1024 characters" }]}
        >
          <Input
            placeholder="Please enter"
            size="small"
            defaultValue={dataObj?.terms}
            maxLength={1024}
          ></Input>
        </Form.Item>
        <Form.Item
          name="notes"
          label={<span className={classNames.label}>Notes</span>}
          rules={[{ max: 1024, message: "Max 1024 characters" }]}
        >
          <Input
            placeholder="Please enter"
            size="small"
            defaultValue={dataObj?.notes}
            maxLength={1024}
          ></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
}
