import copy from "copy-to-clipboard";
import FileCopy2LineIcon from "remixicon-react/FileCopy2LineIcon";
import mixpanel from "mixpanel-browser";

import { Input, CTAButton, Title, Message, Select } from "../../../../common";
// import { RiMailLine } from "react-icons/ri";
import { RiEyeLine } from "react-icons/ri";
import { QrcodeOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form, Checkbox, Button as AntButton, Spin } from "antd";
import { useContext, useState } from "react";
import QrModal from "../QrModal/QrModal";
import { useMutation } from "react-query";
import api from "../../../../API";
// import { useLocalStorageData } from "../../../../../hooks/useLocalStorage";
import { MAX_MIN } from "../../../../util/constants";
import { validation } from "../../../../Auth/config";
import REGEX from "../../../../util/constants/regex";
import { validationPayee } from "../../../../BusinessBanking/config";
import { countryList, usStateList } from "../../../../util";
import { uuidv4 } from "../../../../util/functions/uuidV4";
import classNames from "./PaymentRequests.module.scss";
import { AppContext } from "../../../../../context/AppState";

export default function PaymentRequestsForm() {
  const { userData } = useContext(AppContext);
  const [form] = Form.useForm();
  const { submit } = form;
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [emailSendBtnVisible, setEmailSendBtnVisible] = useState(false);
  const [email, setEmail] = useState(userData?.email);
  const [amount, setAmount] = useState(false);
  const [shippingAddress, setSameShippingAddress] = useState();

  const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  // const userData = useLocalStorageData("OTTERZ.userData");
  const tenantId = userData?.tenantId || "";

  const onFinish = (data) => {
    const id = uuidv4();
    const formData = {
      ...data,
      infoEditable: "false",
      amountEditable: String(!!data.amountEditable),
      tenantId,
    };
    formData.email = email
    setLoading(true);
    setQueryString(
      `${new URL(window.location.href).origin}/payment/summary?id=${id}&` +
        Object.keys(formData)
          .map((param) => `${param}=${window.btoa(formData[param] || "")}`)
          .join("&")
    );
    setLoading(false);
  };

  const [initGenerated, setInitGenerated] = useState(false);
  const onGenerateLinkClick = () => {
    setInitGenerated(true);
    submit();
    try {
      mixpanel.track('Send Payment Request Started', {
        'Entry point': 'WEB',
      });
    } catch (error) {
      console.error("mixpanel_exception: ", error)
    }
  };

  const openHPGInTab = () => {
    window.open(queryString, "_blank", "noopener,noreferrer");
  };

  const [showQRModal, setShowQRModal] = useState(false);
  const onQRClick = () => setShowQRModal(true);
  const onQRModalClose = () => setShowQRModal(false);

  const sendEmailFN = (data) => {
    // update the apiName, sub root on api.Receivables.PaymentAcceptanceTransactions.sendEmail(data) after the backend development
    let input = {
      email: email,
      ...data,
    };
    return api.Receivables.PaymentAcceptanceTransactions.sendEmail(input);
  };

  const sendEmailMn = useMutation((event) => sendEmailFN(event));

  const onEmailClick = (data) => {
    sendEmailMn.mutate(data, {
      onSuccess: (data, variables, context) => {
        Message({ type: "success", content: "Email sent successfully!" });
        setEmailSendBtnVisible(false);
      },
      onError: (error, variables, context) => {
        Message({ type: "error", content: error.toString() });
      },
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div className={classNames.modalLeft}>
        <div>
          <Title
            as="h5"
            style={{ color: "#BC7C1D" }}
            className={classNames.label}
          >
            Optional fields
          </Title>
        </div>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autocomplete="off"
          scrollToFirstError={true}
        >
          <Form.Item>
            <Form.Item
              name="firstName"
              label={
                <Title as="h4" className={classNames.label}>
                  First Name
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  max: MAX_MIN.name.max,
                  message: validation.firstName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.firstName.valid,
                },
              ]}
            >
              <Input
                placeholder={validation.firstName.placeholder}
                size="small"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={
                <Title as="h4" className={classNames.label}>
                  Last Name
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                {
                  max: MAX_MIN.name.max,
                  message: validation.lastName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.lastName.valid,
                },
              ]}
            >
              <Input
                placeholder={validation.lastName.placeholder}
                size="small"
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="companyName"
            label={<span className={classNames.label}>Company</span>}
            rules={[
              {
                max: MAX_MIN.businessName.max,
                message: validation.businessName.max,
              },
              {
                pattern: new RegExp(REGEX.businessName),
                message: "Enter a valid company name",
              },
            ]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="email"
            label={<span className={classNames.label}>Email</span>}
            rules={[
              {
                type: "email",
                message: validation.email.valid,
              },
              {
                min: MAX_MIN.email.min,
                message: validation.email.minLength,
              },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
            ]}
          >
            <Input
              size="small"
              placeholder={validation.email.placeholder}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="billingAddress"
            label={<span className={classNames.label}>Billing Address</span>}
          >
            <Form.Item
              name="addressStreet"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street.max,
                },
              ]}
            >
              <Input placeholder="Street" size="small" />
            </Form.Item>
            <Form.Item
              name="addressStreet2"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street2.max,
                },
              ]}
            >
              <Input
                placeholder={validation.street2.placeholder}
                size="small"
              />
            </Form.Item>
            <Form.Item
              name="addressCity"
              rules={[{ max: MAX_MIN.city.max, message: validation.city.max }]}
            >
              <Input placeholder="City" size="small" />
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name="addressState"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State"
                  size="small"
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="addressZip"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  {
                    pattern: new RegExp(REGEX.postalCode),
                    message: validation.postalCode.valid,
                  },
                ]}
                // COMMENTED DUE POSSIBLE FUTURE USE
                // rules={[
                //   { required: true, message: "Postal code is required" },
                //   {
                //     pattern: new RegExp(/^[0-9]{5}$/),
                //     message: "Enter a valid postal code",
                //   },
                // ]}
              >
                <Input placeholder="Postal code" size="small" />
              </Form.Item>
            </Form.Item>

            <Form.Item name="addressCountry">
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country"
                size="small"
              >
                {countryList(true).map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item
              name="addressCountry"
              rules={[
                { max: MAX_MIN.name.max, message: validation.country.max },
              ]}
            >
              <Input placeholder="Country" size="small" />
            </Form.Item> */}
          </Form.Item>
          <Form.Item
            name="phone"
            label={<span className={classNames.label}>Phone Number</span>}
            // COMMENTED DUE POSSIBLE FUTURE USE
            // rules={[
            //   {
            //     pattern: new RegExp(/^[0-9]{10}$/),
            //     message: "Enter a valid phone number",
            //   },
            // ]}
            rules={[
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: validation.phoneNumber.valid,
              },
            ]}
          >
            <Input
              size="small"
              placeholder="Please enter"
              prefix={
                "+1"
                // <Form.Item>
                //   <Select
                //     size="small"
                //     placeholder="+1"
                //     className={classNames.mobileInputPrefix}
                //     showSearch
                //     filterOption={(input, option) =>
                //       option.children
                //         .toLowerCase()
                //         .indexOf(input.toLowerCase()) >= 0
                //     }
                //     defaultValue="+1"
                //   >
                //     <Select.Option value="+1">+1</Select.Option>
                //   </Select>
                // </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item
            name="shippingAddress"
            label={<span className={classNames.label}>Shipping Address</span>}
          >
            <Checkbox
              onChange={(e) => setSameShippingAddress(e.target.checked)}
            >
              Same as billing
            </Checkbox>
          </Form.Item>
          {!shippingAddress && (
            <Form.Item name="shippingAddress">
              <Form.Item
                name="shippingAddressStreet"
                rules={[
                  {
                    max: MAX_MIN.street.max,
                    message: validation.street.max,
                  },
                ]}
              >
                <Input placeholder="Street" size="small" />
              </Form.Item>
              <Form.Item
                name="shippingAddressStreet2"
                rules={[
                  {
                    max: MAX_MIN.street.max,
                    message: validation.street2.max,
                  },
                ]}
              >
                <Input placeholder="Street 2" size="small" />
              </Form.Item>
              <Form.Item
                name="shippingAddressCity"
                rules={[
                  {
                    max: MAX_MIN.city.max,
                    message: validation.city.max,
                  },
                ]}
              >
                <Input placeholder="City" size="small"></Input>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  name="shippingAddressState"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginBottom: "10px",
                    float: "left",
                  }}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size="small"
                    placeholder="State"
                  >
                    {usStateList().map((value) => (
                      <Select.Option key={value.code} value={value.code}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="shippingAddressZip"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    margin: "0 0 0 4px",
                    float: "right",
                  }}
                  rules={[
                    {
                      pattern: new RegExp(REGEX.postalCode),
                      message: validation.postalCode.valid,
                    },
                  ]}
                >
                  <Input placeholder="Postal code" size="small" />
                </Form.Item>
              </Form.Item>
              <Form.Item name="shippingAddressCountry">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Country"
                  size="small"
                >
                  {countryList(true).map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item
            name="invoiceNo"
            label={<span className={classNames.label}>Invoice</span>}
            rules={[
              {
                pattern: new RegExp(REGEX.invoice),
                message: "Enter a valid invoice number",
              },
            ]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="poNumber"
            label={<span className={classNames.label}>PO Number</span>}
            rules={[{ max: 6, message: "Max 6 characters" }]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="orderId"
            label={<span className={classNames.label}>Order ID</span>}
            rules={[{ max: 6, message: "Max 6 characters" }]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
          <Form.Item
            name="description"
            label={<span className={classNames.label}>Description</span>}
            rules={[
              {
                max: MAX_MIN.description.max,
                message: `Max ${MAX_MIN.description.max} characters`,
              },
            ]}
          >
            <Input size="small" placeholder="Please enter" />
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <Title
          as="h5"
          style={{ color: "#EE6969" }}
          className={classNames.label}
        >
          Mandatory fields
        </Title>

        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          autocomplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="amount"
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input
              className={classNames.inputAmount}
              style={{
                backgroundColor: "#F6F7FA",
                hight: 54,
                border: "#fff",
              }}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              placeholder="Enter Amount"
              onChange={(e) => setAmount(Number(e.target.value))}
            />
          </Form.Item>

          <Form.Item name="amountEditable" valuePropName="checked">
            <Checkbox
              style={{ marginLeft: 0 }}
              onClick={() => setInitGenerated(false)}
            >
              Allow user to adjust amount
            </Checkbox>
          </Form.Item>
        </Form>
        <div className={classNames.shareWrapper}>
          {loading ? (
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
              />
              <Title as="h2">Generating payment link...</Title>
            </div>
          ) : initGenerated ? (
            <div>
              <Title as="h3">Share this payment link</Title>
              <CTAButton
                style={{
                  height: 44,
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#F3F3FF",
                  border: "none",
                  color: "#7372FF",
                }}
                type="primary"
                onClick={() => {
                  copy(queryString);
                  Message({
                    type: "success",
                    content: "Link copied to clipboard!",
                  });
                }}
              >
                <div className={classNames.shareSection}>
                  <span
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {queryString}
                  </span>
                  <div>
                    <FileCopy2LineIcon size={12} style={{ marginRight: 10 }} />
                    Copy
                  </div>
                </div>
              </CTAButton>
              <div className={classNames.bottomBar}>
                <AntButton
                  style={{ height: "44px" }}
                  icon={<RiEyeLine style={{ marginRight: 5 }} />}
                  onClick={openHPGInTab}
                >
                  Preview
                </AntButton>
                {/* Following button hide due to pending development */}
                {/* <AntButton
                  style={{ height: "44px", width: "100px" }}
                  icon={<RiMailLine style={{ marginRight: 5 }} />}
                  onClick={() => setEmailSendBtnVisible(true)}
                  loading={sendEmailMn.isLoading}
                >
                  Email
                </AntButton> */}
                <AntButton
                  style={{ height: "44px" }}
                  icon={<QrcodeOutlined />}
                  onClick={onQRClick}
                >
                  Get QR Code
                </AntButton>
              </div>
              {emailSendBtnVisible && (
                <div style={{ display: "flex" }}>
                  <Input
                    placeholder="Please enter"
                    size="small"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <AntButton
                    style={{ height: "44px", width: "100px" }}
                    onClick={onEmailClick}
                    loading={sendEmailMn.isLoading}
                    disabled={!emailRegex.test(email)}
                  >
                    Send
                  </AntButton>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          style={{
            height: 44,
            width: "100%",
            bottom: 0,
          }}
          onClick={onGenerateLinkClick}
          disabled={
            !amount || !REGEX.amount.test(form?.getFieldValue("amount"))
          }
        >
          Generate Payment Link
        </CTAButton>
      </div>
      <QrModal
        visible={showQRModal}
        onCancel={onQRModalClose}
        queryString={queryString}
      />
    </div>
  );
}
