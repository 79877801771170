import { API } from "aws-amplify";

export default async function deleteTaxRate(data) {
  if (data?.id) {
    return await API.del("taxRate", "", {
      queryStringParameters: {
        id: data.id,
      },
    });
  }
}
