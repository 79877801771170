import SCOPES from "./scopes";
import SUBSCRIPTIONS from "./subscriptions";
import ROLES from "./roles";

const SCOPE_TO_ROLE = {
  // Owner has access to all parts of the application and available for free and paid subscriptions
  [ROLES.OWNER]: {
    ...Object.entries(SCOPES).reduce((list, [key, value]) => {
      list[key] = {
        ...value,
        subscriptions: [SUBSCRIPTIONS.ALL],
      };
      return list;
    }, {}),
  },
  [ROLES.ACCOUNTING_USER]: {
    ...SCOPES.setScopeFor("ROUTES.APP.INDEX", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    // banking
    ...SCOPES.setScopeFor("ROUTES.APP.BUSINESS_BANKING.MANAGE_CHECKS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor(
      "ROUTES.APP.BUSINESS_BANKING.STATEMENTS_TRANSACTIONS",
      { subscriptions: [SUBSCRIPTIONS.ALL] }
    ),

    // payment acceptance
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor(
      "ROUTES.APP.RECEIVABLES.CUSTOMERS_PAYMENT_ACCEPTANCE",
      { subscriptions: [SUBSCRIPTIONS.ALL] }
    ),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.REPORTS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TRANSACTIONS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_SCHEDULES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),

    // invoicing
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ESTIMATES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_INVOICE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ALL_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ALL_ESTIMATES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.CUSTOMERS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PRODUCTS_SERVICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TAX_DB", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.DRAFT_ESTIMATES_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TEMPLATE_SETTINGS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),

    // reports
    ...SCOPES.setScopeFor("ROUTES.APP.PAYMENT.SUMMARY", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
  },
  [ROLES.INVOICING_USER]: {
    ...SCOPES.setScopeFor("ROUTES.APP.INDEX", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),

    // payment acceptance
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor(
      "ROUTES.APP.RECEIVABLES.CUSTOMERS_PAYMENT_ACCEPTANCE",
      { subscriptions: [SUBSCRIPTIONS.ALL] }
    ),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.REPORTS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TRANSACTIONS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_SCHEDULES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),

    // invoicing
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ESTIMATES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.RECURRING_INVOICE", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ALL_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.ALL_ESTIMATES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.CUSTOMERS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.PRODUCTS_SERVICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TAX_DB", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.DRAFT_ESTIMATES_INVOICES", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.TEMPLATE_SETTINGS", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
    ...SCOPES.setScopeFor("ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
  },
  [ROLES.EXPENSE_USER]: {
    ...SCOPES.setScopeFor("ROUTES.APP.INDEX", {
      subscriptions: [SUBSCRIPTIONS.ALL],
    }),
  },
};

export default SCOPE_TO_ROLE;
