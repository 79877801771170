import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RiArrowRightLine } from "react-icons/ri";
import { Switch, Card, Popover } from "antd";
import { Header, Message, Title } from "../../../common";
import api from "../../../API";
import { ROUTES } from "../../../constants";
import generateErrorMessage from "../../../util/functions/customError";
import messages from "../../config/messages.const";
import useLanguage from "../../../../hooks/useLanguage";
import usePAActiveStatus from "../../../../hooks/usePAActiveStatus";
import constants from "../../../Messages/paymentAcceptance/constants";
import useInvoiceActiveStatus from "../../../../hooks/useInvoiceActiveStatus";
import Settings from "../../PaymentAcceptance/PaymentAcceptanceOverview/Settings/Settings";
import classNames from "./GettingStarted.module.scss";

function GettingStarted() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const language = useLanguage(messages);
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const { icidStatus: invoiceStatus } = useInvoiceActiveStatus();

  // fetching PA status to disable enable online payments widget
  // onlline payment widget will disable if the payment acceptance is not active (paStatus attribute is false)
  const {
    status: paStatus,
    paSettingsStatus,
    isPASettingsLoading: getPASettingsLoading,
    isLoading: onboardingRequestLoading,
  } = usePAActiveStatus();

  const [isPASettingsAvailable, setPASettingsAvailability] =
    useState(paSettingsStatus);

  // if (invoiceStatus) {
  //   navigate(ROUTES.APP.RECEIVABLES.INVOICES);
  // }

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: getInvoiceSettingsQuery,
    isLoading: getInvoiceSettingsQueryIsLoading,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const updateInvoiceTemplateFn = (event) => {
    let inputInvoiceSettings = {
      id: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      enableOnlinePayments: event,
    };
    return api.Receivables.Settings.invoice(inputInvoiceSettings);
  };

  const handleSettingsCancelClick = () => {
    setSettingsVisibility(false);
  };

  const enableOnlinePaymentseMn = useMutation((event) =>
    updateInvoiceTemplateFn(event)
  );

  const handleOnChange = (event) => {
    const paSetting = paSettingsStatus
      ? paSettingsStatus
      : isPASettingsAvailable;

    if (!paSetting) {
      setSettingsVisibility(true);
    } else {
      enableOnlinePaymentseMn.mutate(event, {
        onSuccess: () => {
          Message({
            type: "success",
            content: event
              ? language.ENABLE_ONLINE_PAYMENTS
              : language.DISABLE_ONLINE_PAYMENTS,
          });
          queryClient.invalidateQueries("getInvoiceSettings");
        },
        onError: (error) => generateErrorMessage(error),
      });
    }
  };

  return (
    <>
      <div className={classNames.wrapper}>
        <div className={classNames.headerWrapper}>
          <Header title="Invoices" />
        </div>
        <div className={classNames.layout}>
          <div className={classNames.bodyWrapper}>
            <div className={classNames.body}>
              <Card className={classNames.settingsCard}>
                <div className={classNames.invoiceSettingsHeading}>
                  <Title as="h1">Invoice Settings</Title>
                  <span>
                    You first need to design your invoice/ estimate to be able
                    to create an invoice or an estimate
                  </span>
                </div>
                <div className={classNames.settingsOptionsWrapper}>
                  <Card
                    className={classNames.settingsOptionCard}
                    onClick={() =>
                      navigate(ROUTES.APP.RECEIVABLES.TEMPLATE_SETTINGS)
                    }
                  >
                    <div>Set up your invoice design</div>
                    <RiArrowRightLine
                      size={24}
                      className={classNames.arrowBtnWithBg}
                    />
                  </Card>
                  <Card className={classNames.settingsOptionCard}>
                    <div>
                      <div>Enable Online Payments</div>
                      <span>Send a payment link with the invoices</span>
                    </div>
                    {paStatus ? (
                      <Popover
                        content={() => {
                          if (!paStatus) {
                            return constants.strings.onboardStatus.warning;
                          }
                        }}
                      >
                        <Switch disabled />
                      </Popover>
                    ) : (
                      <Switch
                        onChange={paSettingsStatus ? handleOnChange : () => {}}
                        disabled={
                          onboardingRequestLoading || getPASettingsLoading
                        }
                        loading={
                          enableOnlinePaymentseMn.isLoading ||
                          getInvoiceSettingsQueryIsLoading
                        }
                        checked={
                          paSettingsStatus
                            ? getInvoiceSettingsQuery?.data
                                ?.enableOnlinePayments
                            : false
                        }
                        onClick={paSettingsStatus ? () => {} : handleOnChange}
                      />
                    )}
                  </Card>
                  {invoiceStatus && (
                    <Card
                      className={classNames.settingsOptionCard}
                      onClick={() => navigate(ROUTES.APP.RECEIVABLES.INVOICES)}
                    >
                      <div>
                        <b>Done!</b> Start sending invoices & estimates
                      </div>
                    </Card>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {settingsVisibility && (
        <Settings
          visible={settingsVisibility}
          onCancel={handleSettingsCancelClick}
          setPASettingsAvailability={setPASettingsAvailability}
        />
      )}
    </>
  );
}
export default GettingStarted;
