import React from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "../../common";
import { Carousel } from "../../common";

import classNames from "./onboarding.module.scss";

export default function Focused() {
  return (
    <div className={classNames.wrapper}>
      <Carousel classNames={classNames} />
      {/* <div className={classNames.sidebarWrapper}>
        <Sidebar minimalView={true}/>
      </div> */}
      
      <div className={classNames.sectionWrapper}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}
