import classNames from "../ATMLocator.module.scss";
import { Card } from "antd";
import { Title } from "../../../common";
import DirectionLineIcon from "remixicon-react/DirectionLineIcon";
import { BsDot } from "react-icons/bs";

export default function SearchResultCard({ network, address, isSelected = false }) {
  return (
    <Card className={`${classNames.searchResultCard} ${isSelected ? 'selected' : ''}`}>
      <div>
        <Title as="h4">{network} - ATM</Title>
        <span>
          ATM <BsDot /> {address}
        </span>
      </div>
      <div>
        <DirectionLineIcon color="#7372FF" size={30} />
      </div>
    </Card>
  );
}
