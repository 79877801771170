import classNames from "./PaymentRequests.module.scss";
import { Modal } from "../../../../common";
import PaymentRequestsForm from "./PaymentRequestsForm";
import React from "react";

export default function PaymentRequests({ visible, onCancel }) {
  return (
    <>
      <Modal
        destroyOnClose
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title="Send Payment Request"
        className={classNames.modal}
        bodyStyle={{
          height: "70vh",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        width={866}
      >
        <PaymentRequestsForm />
      </Modal>
    </>
  );
}
