const cardTypes = {
  // ACTIVE: {
  //   bgColor: "#1E262E",
  // },
  // UNACTIVATED: {
  //   bgColor: "#003366",
  // },
  // STOLEN: {
  //   bgColor: "#003366",
  // },
  // LOST: {
  //   bgColor: "#856B6B",
  // },
  // FROZEN: {
  //   bgColor: "#003333",
  // },
  // SUSPENDED: {
  //   bgColor: "#003333",
  // },
  // TERMINATED: {
  //   bgColor: "#856B6B",
  // },
  VIRTUAL: {
    bgColor: "#C3C3C3",
  },
  PHYSICAL: { bgColor: "#0C0C0C" },
};

export default cardTypes;
