import { API } from "aws-amplify";

export default async function deleteProduct(id) {
  if (id) {
    return await API.del("product", "", {
      queryStringParameters: {
        id,
      },
    });
  }
}
