// import endpoints from "../endpoints";

// export default async function cardReportStolen(data, id) {
//   let env = process.env.REACT_APP_ENV?.trim()
//   return await (
//     await fetch(`${endpoints.BUSINESS_BANKING.CARD_REPORT_STOLEN[env]}/${id}/report-stolen`, {
//       method: "POST",
//       body: JSON.stringify(data),
//     })
//   ).json();
// }

import { API } from "aws-amplify";

export default async function cardReportStolen(id) {
  if (id) {
    return await API.post("cards", "report-stolen", {
      queryStringParameters: {
        cardId: id,
      },
    });
  }
}
