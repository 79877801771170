import React, { useContext, useState, useEffect } from 'react';
import { Modal, Table, Radio, Button } from 'antd';
import { useQuery } from 'react-query';
import { CheckCircleOutlined } from '@ant-design/icons';
import api from '../../../API';
import { AppContext } from '../../../../context/AppState';

const DisclosureCheckModal = ({ isVisible, onSubmit, onClose, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmationReceived, setConfirmationReceived] = useState(false);
  const [boData, setBOData] = useState([]);
  const [totalOwnership, setTotalOwnership] = useState(0);

  const { userData } = useContext(AppContext);

  // Automatically close the modal after 3-4 seconds when confirmation is received
  useEffect(() => {
    let timer;
    if (confirmationReceived) {
      timer = setTimeout(() => {
        onConfirm(); // Close the modal
      }, 3000); // Set to 3000 for 3 seconds or 4000 for 4 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [confirmationReceived, onClose, onConfirm]);

  useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: async (data) => {
        if (boData.length === 0) {
          const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
          const beneficialOwners = businessRelationsResponse.payload.relationShips
            .filter(relation => relation.relationship_type === "BENEFICIAL_OWNER_OF")
            .map((relation, index) => ({
              key: index.toString(),
              name: `${relation.person_info.first_name} ${relation.person_info.last_name}`,
              ownership: `${relation.additional_data.percent_ownership}%`,
            }));

          const totalOwnership = beneficialOwners.reduce((acc, owner) => {
            const ownership = parseFloat(owner.ownership.replace('%', ''));
            return acc + ownership;
          }, 0);
          setTotalOwnership(totalOwnership);
          setBOData(beneficialOwners);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const dataSource = boData; // Use boData as the dataSource for the table

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'ownership',
      key: 'ownership',
    },
  ];

  const handleRadioChange = e => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption === 1) {
      setConfirmationReceived(true); // Set confirmation received to true when option 1 is selected

    }
    // Handle other submit logic here if necessary
    onSubmit(selectedOption);
  };

  // Conditional rendering based on confirmationReceived
  const renderModalContent = () => {
    if (confirmationReceived) {
      return (
        <div style={{ textAlign: 'center', marginTop: 0, display: 'flex', gap: "10px", justifyContent: 'center', alignItems: "center" }}>
          <CheckCircleOutlined style={{ color: 'green', fontSize: '24px' }} />
          <p style={{ marginTop: 16, fontSize: '16px' }}>Thank you</p>
        </div>
      );
    }

  let isAddMoreDisabled = false;  
  isAddMoreDisabled = totalOwnership > 75;

    return (
      <>
        <p>
          As a regulatory mandate, we are required to collect information about all the beneficial owners who have {'>'}=25% ownership in the business.
        </p>
        <p>We recorded these beneficial owners during onboarding:</p>
        <Table dataSource={dataSource} columns={columns} pagination={false} showHeader={false} />

        <Radio.Group onChange={handleRadioChange} value={selectedOption}>
          <Radio value={1}>I confirm that I have mentioned all beneficial owners who have {'>'}=25% stake in the company</Radio>
          {!isAddMoreDisabled && <Radio value={2}>I want to add more beneficial owners to my account</Radio>}
        </Radio.Group>

        <div style={{ textAlign: 'right', marginTop: 16 }}>
          <Button 
            type="primary" 
            onClick={handleSubmit} 
            style={{ 
              padding: '0 20px',
              fontSize: '16px',
            }}
          >
            Next
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      title="Confirm Ownership"
      visible={isVisible}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default DisclosureCheckModal;
