import { useMutation } from "react-query";

import classNames from "../RecurringSchedules.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";

const DeleteScheduleFormBody = ({ onCancel, selectedRecord, queryClient }) => {
  const deleteSchedulFn = (event) => {
    return api.Receivables.PaymentAcceptance.deleteSchedule(event);
  };

  const deleteScheduleMutation = useMutation((event) => deleteSchedulFn(event));

  const deleteScheduleOnClick = (data) => {
    deleteScheduleMutation.mutate(data, {
      onSuccess: (data, variables, context) => {
        Message({ type: "success", content: "Record Deleted Successfully!" });
        queryClient.invalidateQueries("listSchedule");
        onCancel();
      },
      onError: error => generateErrorMessage(error),
    });
  };

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete Schedule?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this record?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "auto",
            backgroundColor: "#EE6969",
            border: "none",
            marginLeft: "15px",
          }}
          loading={deleteScheduleMutation.isLoading}
          onClick={() => deleteScheduleOnClick(selectedRecord.ScheduleId)}
        >
          Yes, delete
        </CTAButton>
      </div>
    </>
  );
};

export default function DeleteSchedule({
  visible,
  onCancel,
  selectedRecord,
  queryClient,
}) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <DeleteScheduleFormBody
        onCancel={onCancel}
        selectedRecord={selectedRecord}
        queryClient={queryClient}
      />
    </Modal>
  );
}
