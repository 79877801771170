import { useContext, useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { CTAButton, Input, OTPInput, Message } from "../../../common";
import {useLocalStorageData} from "../../../../hooks/useLocalStorage";
import Heading from "../../Heading";
// import Steps from "../../Steps";
import { AppContext } from "../../../../context/AppState";
import { clearUserData } from "../../../../context/actions/userData.ac";
import api from "../../../API";
import { validation } from "../../config";
import REGEX from "../../../util/constants/regex";
import { maskPhone } from "../../../util";
import generateErrorMessage from "../../../util/functions/customError";
import classNames from "./VerifyPhone.module.scss";
import mixpanel from "mixpanel-browser";

export default function VerifyPhone() {
  //Hooks
  const [requested, setRequested] = useState("");
  const [id, setID] = useState("");
  const [success, setSuccess] = useState(false);
  const [disable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const { dispatchUserData, userData } = useContext(AppContext);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updatedPhone, setUpdatedPhone] = useState("");
  const businessData = useLocalStorageData("OTTERZ_op_bd");

  useEffect(() => {
    if (success === "SUCCESS") {
      setUpdate(false);
      Auth.signOut().finally(() => {
        clearUserData()(dispatchUserData);
        navigate("/login", { replace: true });
      });
    }
  }, [success, navigate, dispatchUserData]);

  useEffect(() => {
    if (requested === "SUCCESS") {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, requested]);

  //Api calls
  const { data: currentUserData, refetch: userRefetch } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: true,
    }
  );

  const sendOTP = useMutation(() => api.Auth.requestOTP());
  const verifyOTP = useMutation(api.Auth.verifyOTP);


  // Event handler for requesting OTP
  async function requestOtp() {
    if (userData.email_verified && !userData.phone_number_verified) {
      try {
        form.resetFields();

        // Call your custom requestOTP API function
        const requestData = await sendOTP.mutateAsync();

        setRequested("SUCCESS"); // Assuming the id is returned as "id" from your API
        setID(requestData.payload.id)

        setSeconds(60);
        Message({
          type: "success",
          content: "Verification code sent successfully!",
        });
      } catch (error) {
        generateErrorMessage(error, `${error.message}`);
      }
    } else {
      Message({
        type: "info",
        content: `Your phone number ${userData.phone_number} has already been verified!`,
      });
    }
  }

  function onUpdatePhoneNumber() {
    setUpdate(true);
  }

  // Event handler for verifying OTP
  async function handleFinish(otp) {
    let code = otp['otp'];

    try {
      mixpanel.identify(userData?.email);

      // Call your custom verifyOTP API function
      const requestData = await verifyOTP.mutateAsync({
        OTPNumber: code,
        id: id, // Use the ID received from the requestOTP call
      });

      if (requestData.status === 200) {
        setSuccess("SUCCESS"); // Assuming the status is returned from your API
        Message({ type: "success", content: "Phone number verification successful" });
      } else {
        Message({ type: "error", content: "Phone number verification failed - Incorrect OTP" });
      }

      if (requestData.status === 200) {
        mixpanel.track('User verifies phone number', {
          'Company_id': businessData?.id,
          'Email ID': userData?.email,
          'Sign Up Date': new Date().toISOString(),
          'Verification status': "Successful Verification",
          'Phone number': userData?.phone_number,
        });
      } else {
        mixpanel.track('User verifies phone number', {
          'Company_id': businessData?.id,
          'Email ID': userData?.email,
          'Sign Up Date': new Date().toISOString(),
          'Verification status': "Verification failed",
          'Phone number': userData?.phone_number,
        });
      }
    } catch (error) {
      Message({ type: "error", content: error.message });
      form.resetFields();
      mixpanel.track('User verifies phone number', {
        'Company_id': businessData?.id,
        'Email ID': userData?.email,
        'Sign Up Date': new Date().toISOString(),
        'Verification status': `Verification failed: ${error.message}`,
        'Phone number': userData?.phone_number,
      });
    }
  }

  async function onClickUpdate() {
    if (REGEX.phoneNumber.test(updatedPhone)) {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await Auth.updateUserAttributes(currentUserData, {
          phone_number: String("+1" + updatedPhone),
        });
        setUpdatedPhone("");
        setUpdate(false);
        userRefetch();
        Message({
          type: "success",
          content: "Phone number updated successfully!",
        });
      } catch (error) {
        Message({ type: "error", content: error.message });
      }
    }
  }

  return (
    <div className={classNames.wrapper}>
      {/* <Steps classNames={classNames} currentIndex={1} /> */}
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Verify your phone number"
          description={
            update
              ? ""
              : requested
              ? `We have sent an OTP to your phone number ${maskPhone(
                  currentUserData?.attributes?.phone_number ||
                    userData.phone_number
                )}`
              : `We'll be sending an OTP to your phone number ${maskPhone(
                  currentUserData?.attributes?.phone_number ||
                    userData.phone_number
                )}`
          }
        />
        {requested === "SUCCESS" ? (
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            autoComplete="off"
            onValuesChange={() => {
              setDisable(false);
            }}
          >
            <Form.Item
              label="Enter OTP"
              rules={[{ required: true, message: "required" }]}
            >
              <OTPInput />
            </Form.Item>
            <div className={classNames.linksWrapper}>
              {seconds > 0 ? (
                <div>
                  Time Remaining: {`00:${seconds < 10 ? 0 : ""}${seconds}`}
                </div>
              ) : (
                <>
                  <div className={classNames.links}>Didn’t receive code</div>
                  <Button type="link" size="small" onClick={() => {
                    try {
                      mixpanel.identify(userData?.email)
                      
                      mixpanel.track('User requests to Resend Code', {
                        'Company_id': businessData?.id,
                        'Email ID': userData?.email,
                        'Sign Up Date': new Date().toISOString(),
                      });
                    } catch (error) {
                      console.error("error: ", error)
                    }

                      requestOtp()
                    }}>
                    Resend
                  </Button>
                </>
              )}
            </div>
            <CTAButton htmlType="submit" type="primary" disabled={disable}>
              Next
            </CTAButton>
          </Form>
        ) : (
          <Form layout="vertical" autoComplete="off" form={form}>
            {update ? (
              <>
                <Form.Item
                  name="phoneNumber"
                  label={<span className={classNames.label}>Phone Number</span>}
                  rules={[
                    {
                      required: true,
                      message: validation.phoneNumber.required,
                    },
                    {
                      pattern: new RegExp(REGEX.phoneNumber),
                      message: validation.phoneNumber.valid,
                    },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder="Please enter"
                    className={classNames.inputItem}
                    prefix={"+1"}
                    defaultValue={updatedPhone}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                </Form.Item>
                <Form.Item style={{ marginTop: 25 }}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                  >
                    <CTAButton
                      htmlType="submit"
                      type="primary"
                      onClick={() => onClickUpdate()}
                    >
                      Save
                    </CTAButton>
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                  >
                    <CTAButton
                      htmlType="button"
                      type="secondary"
                      onClick={() => {
                        setUpdate(false);
                        setUpdatedPhone("");
                      }}
                    >
                      Cancel
                    </CTAButton>
                  </Form.Item>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  label={<span className={classNames.label}>Phone Number</span>}
                >
                  <Input
                    size="small"
                    placeholder="Please enter"
                    disabled={true}
                    value={
                      currentUserData?.attributes?.phone_number?.slice(2) ||
                      userData.phone_number?.slice(2)
                    }
                    className={classNames.inputItem}
                    prefix={"+1"}
                  />
                </Form.Item>
                <div className={classNames.linksWrapper}>
                  <Button
                    type="link"
                    size="small"
                    onClick={() => onUpdatePhoneNumber()}
                    disabled={update}
                  >
                    Edit phone number
                  </Button>
                </div>

                <CTAButton
                  htmlType="submit"
                  type="primary"
                  onClick={() => requestOtp()}
                >
                  {"Request Code"}
                </CTAButton>
              </>
            )}
          </Form>
        )}
      </div>
    </div>
  );
}
