import { useContext } from "react";
import { Form, Input as AntInput, Spin, Checkbox } from "antd";

import classNames from "./Information.module.scss";
import { Input, Select, Title } from "../../../common";

import { HPGContext } from "../Summary";
import { MAX_MIN } from "../../../util/constants";
import REGEX from "../../../util/constants/regex";
import { validation } from "../../../Auth/config";
import { countryList, usStateList } from "../../../util";

const InformationForm = ({ isLoading, form }) => {
  const {
    xBillFirstName,
    xBillLastName,
    xBillCompany,
    xBillStreet,
    xBillStreet2,
    xBillCity,
    xBillState,
    xBillCountry,
    xBillZip,
    xBillPhone,
    xEmail,
    xInvoice,
    xPONum,
    xDescription,
    xOrderID,
    isShippingIsSameAsBilling,
    setXBillFirstName,
    setXBillLastName,
    setXBillCompany,
    setXBillStreet,
    setXBillStreet2,
    setXBillCity,
    setXBillState,
    setXBillZip,
    setXBillPhone,
    setXEmail,
    setXInvoice,
    setXPONum,
    setXDescription,
    setXOrderID,
    setShippingIsSameAsBilling,
  } = useContext(HPGContext);

  const { TextArea } = AntInput;
  return (
    <div className={classNames.formWrapper}>
      <Spin spinning={isLoading}>
        <Form layout="vertical" hideRequiredMark form={form} autoComplete="off">
          <Title as="h3">Billing Information</Title>

          <Form.Item>
            <Form.Item
              name="first"
              label={<span className={classNames.label}>First Name</span>}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  max: MAX_MIN.name.max,
                  message: validation.firstName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.firstName.valid,
                },
              ]}
            >
              <Input
                placeholder="First name"
                defaultValue={xBillFirstName}
                onChange={(e) => setXBillFirstName(e.target.value)}
                size="small"
              />
            </Form.Item>

            <Form.Item
              name="last"
              label={<span className={classNames.label}>Last Name</span>}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                {
                  max: MAX_MIN.name.max,
                  message: validation.lastName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.lastName.valid,
                },
              ]}
            >
              <Input
                placeholder="Last name"
                defaultValue={xBillLastName}
                onChange={(e) => setXBillLastName(e.target.value)}
                size="small"
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="company"
            label={<span className={classNames.label}>Company</span>}
            rules={[
              {
                max: MAX_MIN.businessName.max,
                message: `Max ${MAX_MIN.businessName.max} characters`,
              },
              {
                pattern: new RegExp(REGEX.businessName),
                message: "Enter a valid company name",
              },
            ]}
          >
            <Input
              placeholder="Please enter"
              defaultValue={xBillCompany}
              onChange={(e) => setXBillCompany(e.target.value)}
              size="small"
            />
          </Form.Item>

          <Form.Item label={<span className={classNames.label}>Address</span>}>
            <Form.Item
              name="street"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street.max,
                },
              ]}
            >
              <Input
                placeholder="Street"
                defaultValue={xBillStreet}
                onChange={(e) => setXBillStreet(e.target.value)}
                size="small"
              />
            </Form.Item>
            <Form.Item
              name="street2"
              rules={[
                {
                  max: MAX_MIN.street.max,
                  message: validation.street2.max,
                },
              ]}
            >
              <Input
                placeholder="Street 2"
                defaultValue={xBillStreet2}
                onChange={(e) => setXBillStreet2(e.target.value)}
                size="small"
              />
            </Form.Item>
            <Form.Item
              name="city"
              rules={[{ max: MAX_MIN.city.max, message: validation.city.max }]}
            >
              <Input
                size="small"
                placeholder="City"
                defaultValue={xBillCity || undefined}
                onChange={(e) => setXBillCity(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                name="state"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State"
                  size="small"
                  defaultValue={xBillState || undefined}
                  onChange={(e) => setXBillState(e.target.value)}
                >
                  {usStateList().map((value) => (
                    <Select.Option key={value.code} value={value.code}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="postalCode"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  {
                    pattern: new RegExp(REGEX.postalCode),
                    message: "Enter a valid postal code",
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Postal code"
                  defaultValue={xBillZip}
                  onChange={(e) => setXBillZip(e.target.value)}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item name="country">
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Country"
                size="small"
                defaultValue={xBillCountry || undefined}
                onChange={(e) => setXBillCity(e.target.value)}
              >
                {countryList(true).map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          {/* **************************************************************************************** */}

          <Form.Item
            label={<span className={classNames.label}>Shipping Address</span>}
          >
            <Checkbox
              onChange={() =>
                setShippingIsSameAsBilling((prevData) => !prevData)
              }
            >
              Same as billing
            </Checkbox>
            {isShippingIsSameAsBilling && (
              <Form.Item>
                <Form.Item
                  name={"shippingStreet"}
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street.max,
                    },
                  ]}
                >
                  <Input placeholder="Street" size="small" />
                </Form.Item>
                <Form.Item
                  name={"shippingStreet2"}
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street2.max,
                    },
                  ]}
                >
                  <Input placeholder="Street 2" size="small" />
                </Form.Item>
                <Form.Item
                  name={"shippingCity"}
                  rules={[
                    {
                      max: MAX_MIN.city.max,
                      message: validation.city.max,
                    },
                  ]}
                >
                  <Input placeholder="City" size="small" />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    name="shippingState"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "left",
                    }}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="State"
                      size="small"
                    >
                      {usStateList().map((value) => (
                        <Select.Option key={value.code} value={value.code}>
                          {value.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"shippingZip"}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                    rules={[
                      {
                        pattern: new RegExp(REGEX.postalCode),
                        message: "Enter a valid postal code",
                      },
                    ]}
                  >
                    <Input placeholder="Postal code" size="small" />
                  </Form.Item>
                </Form.Item>

                <Form.Item name="shippingCountry">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country"
                    size="small"
                  >
                    {countryList(true).map((value) => (
                      <Select.Option key={value.code} value={value.code}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            )}
          </Form.Item>

          {/* **************************************************************************************** */}

          <Form.Item
            name="number"
            label={<span className={classNames.label}>Phone Number</span>}
            rules={[
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input
              placeholder="Please enter"
              prefix="+1"
              defaultValue={xBillPhone}
              onChange={(e) => setXBillPhone(e.target.value)}
              size="small"
            />
          </Form.Item>
          <Form.Item hidden name="countryCode" initialValue="1">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="email"
            label={<span className={classNames.label}>Email Address</span>}
            rules={[
              {
                type: "email",
                message: validation.email.valid,
              },
              { min: MAX_MIN.email.min, message: validation.email.minLength },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
            ]}
          >
            <Input
              placeholder="Please enter"
              defaultValue={xEmail}
              onChange={(e) => setXEmail(e.target.value)}
              size="small"
            />
          </Form.Item>

          <Title as="h3" style={{ margin: "24px 0" }}>
            Transaction Details
          </Title>
          <Form.Item>
            <Form.Item
              name="invoice"
              label={<span className={classNames.label}>Invoice</span>}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  pattern: new RegExp(REGEX.invoice),
                  message: "Enter a valid invoice number",
                },
              ]}
            >
              <Input
                placeholder="Please enter"
                defaultValue={xInvoice}
                onChange={(e) => setXInvoice(e.target.value)}
                size="small"
              />
            </Form.Item>

            <Form.Item
              name="poNumber"
              label={<span className={classNames.label}>PO Number</span>}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[{ max: 6, message: "Max 6 characters" }]}
            >
              <Input
                placeholder="Please enter"
                defaultValue={xPONum}
                onChange={(e) => setXPONum(e.target.value)}
                size="small"
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="description"
            label={<span className={classNames.label}>Description</span>}
            rules={[
              {
                max: MAX_MIN.description.max,
                message: `Max ${MAX_MIN.description.max} characters`,
              },
            ]}
          >
            <TextArea
              autoSize
              placeholder="Please enter"
              defaultValue={xDescription}
              onChange={(e) => setXDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="orderId"
            label={<span className={classNames.label}>Order ID</span>}
            rules={[{ max: 6, message: "Max 6 characters" }]}
          >
            <Input
              placeholder="Please enter"
              defaultValue={xOrderID}
              onChange={(e) => setXOrderID(e.target.value)}
              size="small"
            />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default InformationForm;
