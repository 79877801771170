export const OWNER_INFO = [
  {
    que: "What is this form?",
    ans: `To help the government fight financial crime, Federal regulation requires certain financial institutions to obtain, verify, and record information about the beneficial owners of legal entity customers. Legal entities can be abused to disguise involvement in terrorist financing, money laundering, tax evasion, corruption, fraud, and other financial crimes. Requiring the disclosure of key individuals who own or control a legal entity (<i>i.e.</i>, the beneficial owners) helps law enforcement investigate and prosecute these crimes.`,
  },
  {
    que: "Who must complete this form?",
    ans: `This form must be completed by the person opening a new account on behalf of a legal entity with any of the following U.S. financial institutions: (i) a bank or credit union; (ii) a broker or dealer in securities; (iii) a mutual fund; (iv) a futures commission merchant; or (v) an introducing broker in commodities. <br/> <br/>For the purposes of this form, a legal entity includes a corporation, limited liability company, or other entity that is created by filing a public document with a Secretary of State or similar office, a general partnership, and any similar business entity formed in the United States or a foreign country. Legal entity does not include sole proprietorships, unincorporated associations, or natural persons opening accounts on their own behalf.`,
  },
  {
    que: "What information do I have to provide?",
    ans: `This form requires you to provide the name, address, date of birth and Social Security number (or passport number or other similar information, in the case of foreign persons) for the following individuals (<i>i.e.</i>, the <b>beneficial owners</b> ): <ol> <li> Each individual, if any, who owns, directly or indirectly, 25 percent or more of the equity interests of the legal entity customer (<i>e.g.</i>, each natural person that owns 25 percent or more of the shares of a corporation); and </li> <li> An individual with significant responsibility for managing the legal entity customer (<i>e.g.</i>, a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice President, or Treasurer). </li> </ol> The number of individuals that satisfy this definition of “beneficial owner” may vary. Under section (i), depending on the factual circumstances, up to four individuals (but as few as zero) may need to be identified. Regardless of the number of individuals identified under section (i), you must provide the identifying information of one individual under section (ii). It is possible that in some circumstances the same individual might be identified under both sections (<i>e.g.</i>, the President of Acme, Inc. who also holds a 30% equity interest). Thus, a completed form will contain the identifying information of at least one individual (under section (ii)), and up to five individuals (<i>i.e.</i>, one individual under section (ii) and four 25 percent equity holders under section (i)). <br/> <br/>The financial institution may ask to see and obtain a legible copy of a driver’s license or other identifying document for each beneficial owner and control owner, listed on this form. The image must be of sufficient quality to be legible after being scanned into the bank’s system.`,
  },
  {
    que: "Certification Language",
    ans: `<i>I certify to the best of my knowledge that the information provided is complete and correct.</i>`,
  },
];
