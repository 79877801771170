const draftInvoice = [
  {
    key: "1",
    type: "Invoice",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "2",
    type: "Invoice",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "3",
    type: "Invoice",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "4",
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "5",
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "6",
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "7",
    type: "Invoice",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "8",
    type: "Invoice",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: "9",
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key: '10',
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
  {
    key:"11",
    type: "Estimate",
    number: "388392",
    client: "LB & Company",
    date: "21 Nov 2021",
    amount: "$350",
    status: "Draft",
  },
];
export default draftInvoice