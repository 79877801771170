import { types } from "./types";

const signupReducer = (state, { type, error, payload }) => {
  switch (type) {
    case types.PERFORMING_SIGNUP:
      return { status: "fetching" };

    case types.VERIFICATION_SENT:
      return { status: "success", user: payload };

    case types.OPERATION_FAILED:
      return { status: "failed", error };

    default:
      return state;
  }
};

export default signupReducer;
