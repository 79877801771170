import { Message } from "../../../common";

const checkFileSize = (file) => {
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    Message({ type: "error", content: "Image must smaller than 20Mb" });
  }
  return isLt20M;
};

export default checkFileSize;
