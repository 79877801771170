import { API } from "aws-amplify";

export default async function freezePayment(data) {
  if (data?.paymentId) {
    return await API.put("payments", "freeze-payment", {
      queryStringParameters: {
        paymentId: data.paymentId,
      },
    });
  }
}
