import { useCallback, useContext, useEffect, useRef, useState } from "react";
import mixpanel from "mixpanel-browser";
import { Radio, Form, Button, Row, Col, Card, Upload } from "antd";
import IField, { ACH_TYPE, CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import useLanguage from "../../../../hooks/useLanguage";
import { Title, Input, Message, Select, ExpiryInput } from "../../../common";
import { useIfield } from "../../../hooks/useIfield";
import { useMutation } from "react-query";
import api from "../../../API";
import { HPGContext } from "../Summary";
import formatExpiryInput from "../../../util/formatExpiryInput.util";
import { isString } from "lodash";
import generateErrorMessage from "../../../util/functions/customError";
import messages from "../../../BusinessBanking/config/messages.const";
import classNames from "./PaymentDetails.module.scss";
import { FILE_SIZE } from "../../../constants";
import { RiCloseFill, RiFileFill } from "react-icons/ri";

const cardIfieldStyles = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "calc(100% - 6px)",
  fontSize: "14px",
};

const cardIframeStyles = {
  border: "none",
  height: "55px",
  width: "100%",
  marginTop: "14px",
};

const cvvIfieldStyles = {
  borderRadius: "6px",
  height: "44px",
  border: "1px solid #d1d5dd",
  width: "calc(100% - 6px)",
  fontSize: "14px",
};

const cvvIframeStyles = {
  border: "none",
  height: "55px",
  width: "100%",
};

const achIfieldStyle = {
  boxSizing: "content-box",
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "calc(100% - 6px)",
  fontSize: "14px",
};

const achIframeStyle = {
  border: "none",
  height: "55px",
  width: "100%",
  marginTop: "14px",
};
const PaymentDetails = ({ payable, form, id, email, setMessage, setErrMessage }) => {
  // const [form] = Form.useForm();
  const {
    companyId = "",
    tenantId = "",
    invoiceId = "",
    iFieldKey,
    xBillFirstName = "",
    xBillMiddleName = "",
    xBillLastName = "",
    fullName = "",
    xBillCompany = "",
    xBillStreet = "",
    xBillStreet2 = "",
    xBillCity = "",
    xBillState = "",
    xBillCountry = "",
    xBillZip = "",
    xBillPhone = "",
    xEmail = "",
    xInvoice = "",
    xPONum = "",
    xDescription = "",
    xOrderID = "",
    amount,
    setSuccessTransaction,
  } = useContext(HPGContext);

  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [exDate, setExDate] = useState(null);
  const [values, setValues] = useState({});
  const [isExpiry, setIsExpiry] = useState(true);
  const [error, setError] = useState(false);
  const [front, setFront] = useState(false);
  const [back, setBack] = useState(false);
  const [, forceUpdate] = useState({});
  const { Dragger } = Upload;
  const acceptableType = ["image/jpg", "image/jpeg", "image/png"];
  const acceptableTypes = acceptableType.join(",");

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);
  const language = useLanguage(messages);

  const cvvRef = useRef();
  const cardRef = useRef();
  const achRef = useRef();

  const createTransactionFn = useCallback(
    (data) => {
      const requestBody = {
        amount: String(amount),
        email: xEmail,
        billFirstName: xBillFirstName,
        billLastName: xBillLastName,
        billMiddleName: xBillMiddleName,
        accountName: fullName,
        billCompany: xBillCompany,
        billStreet: xBillStreet,
        billStreet2: xBillStreet2,
        billCity: xBillCity,
        billState: xBillState,
        billZip: xBillZip,
        billCountry: xBillCountry,
        billPhone: xBillPhone,
        invoiceNumber: xInvoice,
        poNumber: xPONum,
        description: xDescription,
        orderId: xOrderID,
        custReceipt: true,
        ...data,
      };
      return !!invoiceId
        ? api.Receivables.PaymentAcceptance.createHPPTransaction({
            ...requestBody,
            command: paymentMethod === "cc" ? "cc:Sale" : "check:Sale",
            invoiceId,
            tenantId,
            companyId,
            isShippingDetailsIncludes: false,
          })
        : api.Receivables.PaymentAcceptance.createHPPSaleTransaction(
            { ...requestBody, tenantId },
            paymentMethod === "cc" ? "/card/sale" : "/check/sale"
          );
    },
    [
      companyId,
      tenantId,
      amount,
      xEmail,
      xBillFirstName,
      xBillLastName,
      xBillMiddleName,
      fullName,
      xBillCompany,
      xBillStreet,
      xBillStreet2,
      xBillCity,
      xBillState,
      xBillZip,
      xBillCountry,
      xBillPhone,
      xInvoice,
      xPONum,
      xDescription,
      xOrderID,
      invoiceId,
      paymentMethod,
    ]
  );

  const isMobile = window.matchMedia(
    "(min-width: 320px) and (max-width: 768px)"
  ).matches;

  const createTransactionMt = useMutation((event) =>
    createTransactionFn(event)
  );

  const updateInvoiceFunction = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);

  const updateInvoiceMutation = useMutation((event) =>
    updateInvoiceFunction(event)
  );

  const updateInvoiceStatus = (data) => updateInvoiceMutation.mutate(data);

  const updateStatus = useMutation((data) =>
    api.Receivables.PaymentAcceptance.updateStatus(data)
  );

  const formatData = (val1, val2) => {
    if (paymentMethod === "cc") {
      return Object.assign({}, values, {
        cardNum: val2,
        cvv: val1,
        exp: exDate,
      });
    }
    return Object.assign({}, values, {
      accountNumber: val1,
      xCheckImageFront: front ? front : null,
      xCheckImageRear: back ? back : null,
      xCheckNum: values.checkNum,
    });
  };

  const handleUserFeedback = useCallback(
    (data) => {
      let _data = data;
      if (data?.output && isString(data.output)) {
        const convert = JSON.parse(data.output);
        _data = { data: convert?.details?.cardknoxResponse };
      }
      console.log("Transac data", _data);
      Message({
        type: "success",
        content: "Transaction create successfully",
      });

      setSuccessTransaction(_data);
      form.resetFields();
    },
    [setSuccessTransaction, form]
  );

  const handleSubmit = (val1, val2) => {
    console.log("Payment Submit: val1, val2", val1, val2);
    if (paymentMethod) {
      createTransactionMt.mutate(formatData(val1, val2), {
        onSuccess: (data, variables, context) => {
          handleUserFeedback(data);
          if (invoiceId) {
            updateInvoiceStatus({
              id: invoiceId,
              type: "invoice",
              status: "paid",
            });
          }
          const reqPayload = {
            uuid: id,
            payment_status: true,
          };
          try {
            mixpanel.identify(email)
            mixpanel.track('Payment Request Successful', {
              'email': email,
              'Payment Medium': 'URL',
              'Amount': amount,
              'Currency': 'USD',
              'Payment method': paymentMethod,
            });
          } catch (error) {
            console.error("Mixpanel_error: ", error);
          }
          updateStatus.mutate(reqPayload);
        },
        onError: (error) => {
          const reqPayload = {
            uuid: id,
            payment_status: false,
          };
          updateStatus.mutate(reqPayload);
          try {
            mixpanel.identify(email)
            
            mixpanel.track('Payment Request Failed', {
              'email': email,
              'Payment Medium': 'URL',
              'Amount': amount,
              'Currency': 'USD',
              'Payment method': paymentMethod,
              'Failure Message': error,
            });
          } catch (error) {
            console.error("Mixpanel_error: ", error);
          }
          generateErrorMessage(error);
        },
        onSettled: () => {
          setMessage(null);
          setErrMessage(null);
        },
      });
    }
  };

  const getToken = () => {
    if (paymentMethod === "cc") {
      cardRef.current.getToken();
      cvvRef.current.getToken();
    }
    if (paymentMethod === "ach") {
      achRef.current.getToken();
    }
  };

  const onChange = (date) => {
    setExDate(formatExpiryInput(date.target.value));
  };

  const onFinish = (values) => {
    setValues(values);
    getToken();
  };

  const onClickPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  function onChangeFrontImage(info) {
    if (!error) {
      setFront(info?.file);
    }
  }

  function onChangeBackImage(info) {
    if (!error) {
      setBack(info?.file);
    }
  }

  const {
    cvvoptions,
    ccoptions,
    onError,
    onToken,
    account,
    threeds,
    onCVVUpdate,
    onCcUpdate,
    achOption,
    onACHUpdate,
  } = useIfield(
    iFieldKey,
    cvvIfieldStyles,
    cvvIframeStyles,
    cardIfieldStyles,
    cardIframeStyles,
    paymentMethod,
    handleSubmit,
    achIfieldStyle,
    achIframeStyle
  );
  


  const handler = useCallback(
    (event) => {
      if (event?.data && isString(event?.data)) {
        try {
          console.log("Event data", event?.data);
          const dataObj = JSON.parse(event?.data);
          const { amount, token, walletType } = dataObj;
          if(!amount || !token || !walletType) {
            return;
          }
          createTransactionFn({
            amount: amount,
            cardNum: token,
            digitalWalletType: walletType,
            command: paymentMethod === "cc" ? "cc:Sale" : "check:Sale",
          }).then((data) => {
            handleUserFeedback(data);
          });
        } catch (e) {
          console.warn("JSON format issue.", e);
        }
      }
    },
    [createTransactionFn, handleUserFeedback, paymentMethod]
  );

  useEffect(() => {
    window.addEventListener("message", handler);
    // clean up listener
    return () => window.removeEventListener("message", handler);
  }, [handler]);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      mixpanel.identify(email)

      mixpanel.track('Payment Request Abandoned', {
        'email': email,
        'Payment Medium': 'URL',
        'Amount': amount,
        'Currency': 'USD',
        'Payment method': paymentMethod,
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [amount, paymentMethod, email]);

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.paymentDetails}>
        <Title as="h3">Payment Details</Title>
        {/* <div className={classNames.buttonWrapper}> */}
          {/* Google Pay */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: `<iframe id="gp-by-otters" amount=${amount} width="100%" height="60" scrolling="no" frameborder="no" allow="autoplay" src="/googlePay.html#${amount}"></iframe>`,
            }}
          /> */}
          {/* Apple Pay - DO NOT REMOVE BELOW COMMENTED BLOCK */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: `<iframe id="ap-by-otters" amount=${amount} width="100%" height="60" scrolling="no" frameborder="no" allow="autoplay" src="/applePay.html#${amount}"></iframe>`,
            }}
          /> */}
        {/* </div> */}
        {/* <div className={classNames.divider}>
          <div>OR</div>
        </div> */}
      </div>
      <div className={classNames.paymentForm}>
        <Radio.Group defaultValue="cc" onChange={onClickPaymentMethod}>
          <Radio value="cc" defaultChecked>Credit/ Debit Card</Radio>
          {/* <Radio value="ach">ACH/ Check</Radio> */}
        </Radio.Group>
        <Form
          layout="vertical"
          hideRequiredMark
          style={{ margin: "40px 0" }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          {paymentMethod === "cc" && (
            <div className={classNames.ccWrapper}>
              <div>
                <span className={classNames.label}>
                  Card Number <sup style={{ top: 1 }}>*</sup>
                </span>
                <IField
                  type={CARD_TYPE}
                  account={account}
                  options={ccoptions}
                  threeDS={threeds}
                  onUpdate={onCcUpdate}
                  onToken={onToken}
                  ref={cardRef}
                  onError={onError}
                />
              </div>
              <div className={isMobile ? "" : classNames.cardFieldsRow}>
                <Form.Item
                  name="expiry"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (!value) {
                          setIsExpiry(false);
                          return callback();
                        } else {
                          setIsExpiry(true);
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  label={
                    <span className={classNames.label}>
                      Expiry <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  style={{ flex: 1 }}
                >
                  <ExpiryInput
                    onChange={onChange}
                    style={
                      isExpiry
                        ? {}
                        : {
                            border: "1px solid #FF4D4D",
                          }
                    }
                  />
                </Form.Item>
                {isMobile ? null : (
                  <Form.Item
                    label={
                      <span className={classNames.label}>
                        CVV <sup style={{ top: 1 }}>*</sup>
                      </span>
                    }
                    style={{ flex: 1, marginLeft: 8, marginTop: 12 }}
                  >
                    {iFieldKey && (
                      <IField
                        type={CVV_TYPE}
                        account={account}
                        options={cvvoptions}
                        threeDS={threeds}
                        onUpdate={onCVVUpdate}
                        onToken={onToken}
                        ref={cvvRef}
                        onError={onError}
                      />
                    )}
                  </Form.Item>
                )}
              </div>
              {isMobile && (
                <div>
                  <Form.Item
                    label={
                      <span className={classNames.label}>
                        CVV <sup style={{ top: 1 }}>*</sup>
                      </span>
                    }
                    style={{ flex: 1 }}
                  >
                    {iFieldKey && (
                      <IField
                        type={CVV_TYPE}
                        account={account}
                        options={cvvoptions}
                        threeDS={threeds}
                        onUpdate={onCVVUpdate}
                        onToken={onToken}
                        ref={cvvRef}
                        onError={onError}
                      />
                    )}
                  </Form.Item>
                </div>
              )}
            </div>
          )}
          {paymentMethod === "ach" && (
            <div style={{ margin: "24px 0" }}>
              <Row gutter={[16]}>
                <Col span={12}>
                  <Form.Item
                    className={front ? classNames.hasFile : ""}
                    name="xCheckImageFront"
                    label={
                      <span className={classNames.label}>
                        {language.FRONT_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback("Max 20MB is allowed");
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={front ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageFront")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptableType(file) && file?.size > FILE_SIZE.B) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeFrontImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>{" "}
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !front ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setFront(null);
                        form.setFieldsValue({ xCheckImageFront: null });
                        form.validateFields(["xCheckImageFront"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div>{front?.name}</div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className={back ? classNames.hasFile : ""}
                    name="xCheckImageRear"
                    label={
                      <span className={classNames.label}>
                        {language.BACK_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback(`Max ${FILE_SIZE.MB} is allowed`);
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={back ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageRear")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptableType(file)) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file?.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeBackImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !back ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setBack(null);
                        form.setFieldsValue({ xCheckImageRear: null });
                        form.validateFields(["xCheckImageRear"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div> {back?.name} </div>
                  </Card>
                </Col>
              </Row>
              <Form.Item
                name="checkNum"
                label={<span className={classNames.label}>Check Number</span>}
                rules={[
                  { min: 4, message: "Invalid check number" },
                  { max: 4, message: "Invalid check number" },
                ]}
              >
                <Input
                  id="checkNum"
                  size="small"
                  placeholder={"Check Number"}
                />
              </Form.Item>
              <Form.Item
                name="accountType"
                label={
                  <span className={classNames.label}>
                    Account Type <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  { required: true, message: "Please select account type" },
                ]}
              >
                <Select
                  id="accountType"
                  showSearch
                  placeholder="Account Type"
                  size="small"
                >
                  <Select.Option key={"savings"} value={"savings"}>
                    {"Savings"}
                  </Select.Option>
                  "
                  <Select.Option key={"checking"} value={"checking"}>
                    {"checking"}
                  </Select.Option>
                  "
                </Select>
              </Form.Item>

              <Form.Item
                name="accountName"
                label={
                  <span className={classNames.label}>
                    Account Owner's Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter account owner's name",
                  },
                ]}
                initialValue={fullName}
              >
                <Input
                  placeholder={"Account Owner's Name"}
                  id="accountName"
                  size="small"
                />
              </Form.Item>

              <Form.Item
                name="routingNumber"
                rules={[
                  { required: true, message: "Please enter routing number" },
                ]}
                label={
                  <span className={classNames.label}>
                    Routing Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
              >
                <Input
                  placeholder={"Routing Number"}
                  id="routingNumber"
                  size="small"
                />
              </Form.Item>
              <Form.Item
                name="accountNumber"
                label={
                  <span className={classNames.label}>
                    Account Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                className={classNames.accountNumberField}
              >
                <IField
                  type={ACH_TYPE}
                  account={account}
                  options={achOption}
                  threeDS={threeds}
                  onUpdate={onACHUpdate}
                  onToken={onToken}
                  ref={achRef}
                  onError={onError}
                />
              </Form.Item>
            </div>
          )}

          {/* <Form.Item
            label={
              <Title as="h4" className={classNames.label}>
                {paymentMethod === "cc" ? "Card Details " : "Check details "}
                <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            style={{ display: "inline-block", marginRight: 16, width: "50%" }}
          >
            <Input placeholder="Enter card number" size="small" />
          </Form.Item>
          <Form.Item
            label={
              <Title as="h4" className={classNames.label}>
                Expiry <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            style={{ display: "inline-block", marginRight: 16, width: "22%" }}
          >
            <DatePicker
              placeholder="MM/YYYY"
              format={"MM/YYYY"}
              style={{ height: 44 }}
            />
          </Form.Item>
          <Form.Item
            label={
              <Title as="h4" className={classNames.label}>
                CVV <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            style={{ display: "inline-block", width: "22%" }}
          >
            <Input placeholder="CVV" size="small" />
          </Form.Item> */}

          <Button
            htmlType="submit"
            type="primary"
            id="submit-btn"
            key="submit"
            className={classNames.submitButton}
            loading={createTransactionMt.isLoading}
            disabled={!payable}
          >
            Pay $ {payable ? payable.toFixed(2) : "0.00"}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default PaymentDetails;
