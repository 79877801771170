import { getEndpoints } from "./apiEndpoints";
import { Amplify } from "aws-amplify";

const ENV = Object.freeze({
  QA: "QA",
  DEV: "DEV",
  DEMO: "DEMO",
  PROD: "PROD",
  TTEN: "TTEN",
  ISOLATED: "ISOLATED",
});

// NOTE: Please do not commit your changes to the below code block (isolated config)
if (process.env.REACT_APP_ENV.trim() === ENV.ISOLATED) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID?.trim(),
      region: process.env.REACT_APP_REGION?.trim(),
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION?.trim(),
      userPoolId: process.env.REACT_APP_USER_POOL_ID?.trim(),
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID?.trim(),
      cookieStorage: {
        domain: "localhost",
        expires: 0.083,
        sameSite: "strict",
        secure: false,
      },
    },
    API: {
      endpoints: getEndpoints([process.env.REACT_APP_ENV?.trim()]),
    },
  });
}

if (process.env.REACT_APP_ENV.trim() === ENV.DEV) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID?.trim(),
      region: process.env.REACT_APP_REGION?.trim(),
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION?.trim(),
      userPoolId: process.env.REACT_APP_USER_POOL_ID?.trim(),
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID?.trim(),
      /* cookieStorage: {
        domain: ".amazonaws.com",
        expires: 0.083,
        sameSite: "lax",
        secure: false,
      },*/
    },
    API: {
      endpoints: getEndpoints([process.env.REACT_APP_ENV?.trim()]),
    },
  });
}

if (process.env.REACT_APP_ENV.trim() === ENV.QA) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID?.trim(),
      region: process.env.REACT_APP_REGION?.trim(),
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION?.trim(),
      userPoolId: process.env.REACT_APP_USER_POOL_ID?.trim(),
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID?.trim(),
      /* cookieStorage: {
        domain: ".amazonaws.com",
        expires: 0.083,
        sameSite: "lax",
        secure: false,
      }, */
    },
    API: {
      endpoints: getEndpoints([process.env.REACT_APP_ENV?.trim()]),
    },
  });
}

if (process.env.REACT_APP_ENV.trim() === ENV.PROD) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID?.trim(),
      region: process.env.REACT_APP_REGION?.trim(),
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION?.trim(),
      userPoolId: process.env.REACT_APP_USER_POOL_ID?.trim(),
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID?.trim(),
      cookieStorage: {
        domain: ".app.otterz.co",
        expires: 0.083,
        sameSite: "strict",
        secure: true,
      },
    },
    API: {
      endpoints: getEndpoints([process.env.REACT_APP_ENV?.trim()]),
    },
  });
}

if (process.env.REACT_APP_ENV.trim() === ENV.TTEN) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID?.trim(),
      region: process.env.REACT_APP_REGION?.trim(),
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION?.trim(),
      userPoolId: process.env.REACT_APP_USER_POOL_ID?.trim(),
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID?.trim(),
      cookieStorage: {
        domain: ".app.otterz.co",
        expires: 0.083,
        sameSite: "strict",
        secure: true,
      },
    },
    API: {
      endpoints: getEndpoints([process.env.REACT_APP_ENV?.trim()]),
    },
  });
}
