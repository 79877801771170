import classNames from "../ViewInvoice.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function DeleteRecurringInvoice({
  visible,
  onCancel,
  invoice,
  setDeleteRecurringInvoiceModalVisibility,
}) {
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  const invoiceDeleteMn = useMutation(() =>
    api.Receivables.Recurring.deleteRecurringInvoice(invoice.id)
  );
  const recurringInvoiceDelete = () => {
    invoiceDeleteMn.mutate(
      {},
      {
        onSuccess: () => {
          setDeleteRecurringInvoiceModalVisibility(false);
          Message({
            type: "success",
            content: language.INVOICE_DELETE_SUCCESS,
          });
          queryClient.invalidateQueries("getRecurringData");
        },
        onError: (error) => generateErrorMessage(error),
      }
    );
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete Invoice
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you Sure you want to Delete this Recurring Invoice?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={recurringInvoiceDelete}
          loading={invoiceDeleteMn.isLoading}
        >
          Yes, delete
        </CTAButton>
      </div>
    </Modal>
  );
}
