import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import PropTypes from "prop-types";

export default function BackButton({
  onClick,
  history,
  style,
  variant = "default",
}) {
  function handleClick() {
    onClick();
    // if (history) {
    //   history.goBack();
    // }
  }

  if (variant === "tail") {
    return (
      <ArrowLeftLineIcon
        onClick={handleClick}
        style={{
          cursor: "pointer",
          ...style,
        }}
      />
    );
  }

  return (
    <ArrowLeftSLineIcon
      onClick={handleClick}
      style={{
        cursor: "pointer",
        background: "#fff",
        width: 32,
        height: 32,
        borderRadius: "50%",
        ...style,
      }}
    />
  );
}

BackButton.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  history: PropTypes.object,
  variant: PropTypes.oneOf(["default", "tail"]),
};
