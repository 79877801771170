import { API } from "aws-amplify";

export default async function getInvoiceById(id) {
  if (id) {
    return await API.get("invoices", "get", {
      queryStringParameters: {
        invoiceId: id,
      },
    });
  }
}
