import { useNavigate } from "react-router-dom";
import { Form, Button as AntButton, Upload, Tooltip } from "antd";
import { RiImageLine, RiInformationLine } from "react-icons/ri";
import { useQuery } from "react-query";

import { Input, Select, Title, CTAButton } from "../../common";
import { validation } from "../config";
import { uuidv4 } from "../../util/functions/uuidV4";
import { MAX_MIN } from "../../util/constants";
import { usStateList } from "../../util";
import api from "../../API";

import classNames from "./UserProfile.module.scss";
import AvatarImage from "../../../assets/settings/user-smile-fill.png";

// TODO: post MVP
// import EditAddress from "./EditAddress/EditAddress";
// import AddBeneficialOwner from "./AddBeneficialOwner/AddBeneficialOwner";
// import AddOfficer from "./AddOfficer/AddOfficer";
// import ChangePhoneNumber from "./ChangePhoneNumber/ChangePhoneNumber";
// import ChangeEmail from "./ChangeEmail/ChangeEmail";

const TOOLTIP_TITLE_CONTACT_SUPPORT =
  "Please get in touch with our customer support";

const CustomerSupportTooltip = () => (
  <Tooltip title={TOOLTIP_TITLE_CONTACT_SUPPORT}>
    <RiInformationLine
      size={16}
      style={{ marginLeft: 8, color: "#82898F", verticalAlign: "middle" }}
    />
  </Tooltip>
);
export default function UserProfileForm({
  form,
  onFinish,
  setUserProfileFile,
  userData,
  userProfileData,
  applicationData,
  isBusiness,
}) {
  const { Option } = Select;
  const navigate = useNavigate();
  // TODO: post MVP
  // const [submittedAddress, setSubmittedAddress] = useState(false);
  // const [editAddress, setEditAddress] = useState(false);
  // const [addBeneficialOwner, setAddBeneficialOwner] = useState(false);
  // const [showBeneficialCard, setShowBeneficialCard] = useState(false);
  // const [addOfficer, setAddOfficer] = useState(false);
  // const [showOfficerCard, setShowOfficeCard] = useState(false);
  // const [showChangePhone, setShowChangePhone] = useState(false);
  // const [showChangeEmail, setShowChangeEmail] = useState(false);

  // const handleAddBeneficialOwnerModalCancelClick = () => {
  //   setAddBeneficialOwner(false);
  // };
  // const handleEditAddressModalCancelClick = () => {
  //   setEditAddress(false);
  // };
  // const handleOfficerModalCancelClick = () => {
  //   setAddOfficer(false);
  // };
  // const onClickOfficeCard = () => {
  //   setAddOfficer(true);
  //   setShowOfficeCard(true);
  // };
  // const onClickEditEmail = () => {
  //   setEditAddress(true);
  //   setSubmittedAddress(true);
  // };
  // const onClickBeneficialCard = () => {
  //   setAddBeneficialOwner(true);
  //   setShowBeneficialCard(true);
  // };

  // const handleChangePhoneCancelClick = () => {
  //   setShowChangePhone(false);
  // };

  // const handleChangeEmailCancelClick = () => {
  //   setShowChangeEmail(false);
  // };
  
  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
        refetchOnWindowFocus: false,
    }
  );

  const preventRequest = () => false;
  const fileList =
    userData && userData.imageUrl
      ? [
          {
            uid: userData.id,
            name: `${userData.id}${userData.imageUrl}`,
            status: "done",
            url: userData.imageUrl
              ? `${userData.imageUrl}?${uuidv4()}`
              : AvatarImage,
          },
        ]
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: AvatarImage,
          },
        ];

  return (
    <div>
      <div className={classNames.topRow}>
        <Upload
          className={classNames.uploaderComponent}
          accept="image/*"
          beforeUpload={preventRequest}
          listType="picture-card"
          defaultFileList={fileList}
          maxCount={1}
          onChange={(event) => setUserProfileFile(event)}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
        >
          <AntButton
            type="text"
            style={{ border: "1px solid #E5E7EB" }}
            shape="circle"
            icon={<RiImageLine style={{ color: "#82898F" }} />}
          />
        </Upload>
      </div>

      <div style={{ display: "flex" }}>
        <div className={classNames.modalLeft}>
          <Form
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item>
              {isBusiness ? (
                <>
                  <Form.Item
                    name="businessName"
                    style={{
                      width: "390px",
                    }}
                    label={
                      <>
                        <span className={classNames.label}>Business Name</span>
                        <CustomerSupportTooltip />
                      </>
                    }
                    initialValue={applicationData?.entity_name}
                  >
                    <Input
                      size="small"
                      placeholder="Please enter"
                      disabled
                      value={applicationData?.entity_name}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label={
                      <Title as="h4" className={classNames.label}>
                        First Name
                        <CustomerSupportTooltip />
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px !important",
                      float: "left",
                    }}
                  >
                    <Input
                      value={applicationData?.first_name}
                      placeholder="Please enter"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <Title as="h4" className={classNames.label}>
                        Last Name
                        <CustomerSupportTooltip />
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px  !important",
                      float: "right",
                    }}
                  >
                    <Input
                      value={applicationData?.last_name}
                      placeholder="Please enter"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label={
                <>
                  <span className={classNames.label}>Phone Number</span>
                  <CustomerSupportTooltip />
                </>
              }
              rules={[
                { required: true, message: validation.phoneNumber.required },
                {
                  pattern: new RegExp(/^[0-9]{10}$/),
                  message: validation.phoneNumber.valid,
                },
              ]}
              initialValue={applicationData?.phone_number}
            >
              <Input
                size="small"
                placeholder="Please enter"
                disabled
                className={classNames.disabledField}
                // suffix={
                //   <AntButton
                //     type="link"
                //     onClick={() => setShowChangePhone(true)}
                //   >
                //     Change
                //   </AntButton>
                // }
              />

              {/* post MVP feature */}
              {/* <div>
                <Form.List name="phoneNumber">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          name={[field.name, "description"]}
                          style={{ width: "100%" }}
                        >
                          <Input
                            size="small"
                            placeholder="Please enter"
                            suffix={
                              <RiCloseCircleFill
                                size={20}
                                className={classNames.inputRemoveIcon}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                            prefix={
                              <Form.Item>
                                <Select
                                  size="small"
                                  placeholder="+19"
                                  className={classNames.mobileInputPrefix}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Select.Option value="">+19</Select.Option>
                                </Select>
                              </Form.Item>
                            }
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <div>
                          <AntButton
                            onClick={() => add()}
                            type="link"
                            className={classNames.addNewButton}
                          >
                            <RiAddFill size={13} style={{ marginRight: 5 }} />
                            Add alternate phone number
                          </AntButton>
                        </div>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div> */}
            </Form.Item>
            <Form.Item
              name="email"
              label={
                <>
                  <span className={classNames.label}>Email Address</span>
                  <CustomerSupportTooltip />
                </>
              }
              rules={[
                {
                  type: "email",
                  message: validation.email.valid,
                },
                { required: true, message: validation.email.required },
                { min: MAX_MIN.email.min, message: validation.email.minLength },
                { max: MAX_MIN.email.max, message: validation.email.maxLength },
              ]}
              initialValue={applicationData?.email}
            >
              <Input
                size="small"
                placeholder="Please enter"
                disabled
                className={classNames.disabledField}
                // suffix={
                //   <AntButton
                //     type="link"
                //     onClick={() => setShowChangeEmail(true)}
                //   >
                //     Change
                //   </AntButton>
                // }
              />
              {/* post MVP feature */}
              {/* <div>
                <Form.List name="emails">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          name={[field.name, "description"]}
                          style={{ width: "100%" }}
                        >
                          <Input
                            size="small"
                            placeholder="Please enter"
                            suffix={
                              <RiCloseCircleFill
                                size={20}
                                className={classNames.inputRemoveIcon}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <div className={classNames.salesItemsFooter}>
                          <AntButton
                            onClick={() => add()}
                            type="link"
                            className={classNames.addNewButton}
                          >
                            <RiAddFill size={13} style={{ marginRight: 5 }} />
                            Add alternate email address
                          </AntButton>
                        </div>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div> */}
            </Form.Item>
            {/* <Form.Item name="shippingAddress">
              <Checkbox>Allow user to adjust amount</Checkbox>
            </Form.Item> */}
          </Form>
        </div>
        <div className={classNames.modalRight}>
          <Form
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="address"
              label={
                <div className={classNames.addressLabel}>
                  <span>
                    Address
                    {/* {submittedAddress ? (
                      <span
                        style={{
                          paddingBottom: "5px",
                          color: "#BC7C1D",
                          marginLeft: "5px",
                        }}
                      >
                        Submitted new address
                      </span>
                    ) : null} */}
                    <CustomerSupportTooltip />
                  </span>
                  {/* {submittedAddress ? (
                    <span
                      onClick={onClickEditEmail}
                      style={{
                        paddingBottom: "5px",
                        color: "#7372ff",
                        cursor: "pointer",
                        marginLeft: "75px",
                      }}
                    >
                      Edit address
                    </span>
                  ) : (
                    <span
                      onClick={onClickEditEmail}
                      style={{
                        paddingBottom: "5px",
                        color: "#7372ff",
                        cursor: "pointer",
                        marginLeft: "235px",
                      }}
                    >
                      Edit address
ee                    </span>
                  )} */}
                </div>
              }
            >
              <Form.Item
                name="street1"
                // rules={[{ required: true, message: "Street is required." }]}
                initialValue={applicationData?.legal_address?.address_line_1}
              >
                <Input
                  placeholder="Street 1"
                  size="small"
                  disabled
                  className={classNames.disabledField}
                />
              </Form.Item>
              <Form.Item
                name="street2"
                initialValue={applicationData?.legal_address?.address_line_2}
              >
                <Input placeholder="Street 2" size="small" disabled />
              </Form.Item>
              <Form.Item
                name="city"
                initialValue={applicationData?.legal_address?.city}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="City"
                  size="small"
                  disabled
                  bordered={false}
                  className={classNames.disabledField}
                >
                  <Option value={"Los Angeles"}>Los Angeles</Option>
                  <Option value={"California"}> California</Option>
                  <Option value={"New York"}>New York</Option>
                  <Option value={"Chicago"}>Chicago</Option>
                </Select>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Form.Item
                name="state"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                initialValue={applicationData?.legal_address?.state}
              >
                <Select
                  placeholder="Country"
                  onChange={""}
                  size="small"
                  disabled
                  style={{ width: 180 }}
                  bordered={false}
                  className={classNames.disabledField}
                >
                  {usStateList()?.map((state) => (
                    <Option value={state.code}>{state.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="zipcode"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 6px",
                  float: "right",
                }}
                initialValue={applicationData?.legal_address?.postal_code}
              >
                <Input placeholder="Postal Code" size="small" disabled />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="country"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              initialValue={
                applicationData?.legal_address?.country_code || "United State"
              }
            >
              <Select
                placeholder="Country"
                onChange={""}
                size="small"
                disabled
                bordered={false}
                className={classNames.disabledField}
              >
                <Option value={"US"}>United State</Option>
                <Option value={"India"}> India</Option>
                <Option value={"UAE"}>UAE</Option>
                <Option value={"Sri lanka"}>Sri lanka</Option>
              </Select>
            </Form.Item>

            {/* {showBeneficialCard ? (
              <Form.Item
                label={
                  <Title as="h4" className={classNames.label}>
                    Beneficial Owner
                  </Title>
                }
                style={{ margin: "32px 0 14px 0" }}
              >
                <Card className={classNames.beneficialOwnerCard}>
                  <div>Andrew Harrott</div>
                  <div className={classNames.beneficialActions}>
                    <span>Edit</span>
                    <span>Remove</span>
                  </div>
                </Card>
              </Form.Item>
            ) : ( */}
            <Tooltip title={TOOLTIP_TITLE_CONTACT_SUPPORT}>
              <CTAButton
                key="submit"
                style={{
                  height: 38,
                  width: "100%",
                  marginTop: 10,
                  fontSize: "12px",
                }}
                //   onClick={onClickBeneficialCard}
              >
                Add New Beneficial Owner
              </CTAButton>
            </Tooltip>
            {/* )} */}

            {/* {showOfficerCard ? (
              <Form.Item
                label={
                  <Title as="h4" className={classNames.label}>
                    Beneficial Owner
                  </Title>
                }
                style={{ margin: "32px 0 14px 0" }}
              >
                <Card className={classNames.beneficialOwnerCard}>
                  <div>Andrew Harrott</div>
                  <div className={classNames.beneficialActions}>
                    <span>Edit</span>
                    <span>Remove</span>
                  </div>
                </Card>
              </Form.Item>
            ) : ( */}
            <Tooltip title={TOOLTIP_TITLE_CONTACT_SUPPORT}>
              <CTAButton
                key="submit"
                style={{
                  height: 38,
                  width: "100%",
                  marginTop: 10,
                  fontSize: "12px",
                }}
                //    onClick={onClickOfficeCard}
              >
                Add New Officer
              </CTAButton>
            </Tooltip>
            {/* )} */}
            <CTAButton
                style={{
                    height: 38,
                    width: "100%",
                    marginTop: 10,
                    fontSize: "12px",
                }}
                disabled={
                  ((application === undefined || 
                    application?.payload?.onBoardingStatus === "BUSINESS_CREATED" ||
                    application?.payload?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED") &&
                    application?.payload?.verification_status !== "ACCEPTED")
                }
                onClick={
                    () => {
                        if ((application?.payload?.verification_status === "ACCEPTED") && (application?.payload?.onBoardingStatus !== "BUSINESS_DOCS_UPLOADED" && application?.payload?.onBoardingStatus !== "BUSINESS_OPERATION_DETAILS_FILLED" && application?.payload?.onBoardingStatus !== "ACCOUNT_ACTIVITY_DETAILS_FILLED")) {
                          navigate("/onboarding/business-application-documents")
                        } else if (application?.payload?.onBoardingStatus === "BUSINESS_DOCS_UPLOADED") {
                          navigate("/onboarding/business-operation-details");
                        } else if(application?.payload?.onBoardingStatus === "BUSINESS_OPERATION_DETAILS_FILLED") {
                          navigate("/onboarding/account-activity-details");
                        } else if(application?.payload?.onBoardingStatus === "ACCOUNT_ACTIVITY_DETAILS_FILLED") {
                          navigate("/onboarding/business-profile-success");
                        }
                    }
                }
            >
                Complete Profile
            </CTAButton>
          </Form>
        </div>
      </div>


      {/* TODO: implement post MVP */}
      {/* <AddBeneficialOwner
        visible={addBeneficialOwner}
        onCancel={handleAddBeneficialOwnerModalCancelClick}
      />
      <EditAddress
        visible={editAddress}
        onCancel={handleEditAddressModalCancelClick}
      />

      <AddOfficer
        visible={addOfficer}
        onCancel={handleOfficerModalCancelClick}
      />

      <ChangePhoneNumber
        visible={showChangePhone}
        onCancel={handleChangePhoneCancelClick}
      />

      <ChangeEmail
        visible={showChangeEmail}
        onCancel={handleChangeEmailCancelClick}
      /> */}
    </div>
  );
}
