const maskPhone = (number) => {
  if (!number) return "";
  
  number = String(number);

  if (number.length < 5) return number; // ex: 12345

  const length = number.length - 5;
  const middle = "*".repeat(length);

  // ex: 12*456
  return (
    number[0] +
    number[1] +
    middle +
    number[number.length - 3] +
    number[number.length - 2] +
    number[number.length - 1]
  );
};
export default maskPhone;
