// import endpoints from "../endpoints";

// export default async function listCards() {
//   let env = process.env.REACT_APP_ENV?.trim();
//   return await (await fetch(endpoints.BUSINESS_BANKING.GET_CARDS[env])).json();
// }

import { API } from "aws-amplify";

export default async function listCards(params) {
  if (params?.otz_account_id) {
    return await API.get("otzcards", "", {
      queryStringParameters: {
        ...params,
      },
    });
  }
}
