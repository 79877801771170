import {
  last30DaysFilterProps,
  transactionModes,
  transactionStates,
  transactionTypes,
} from "../API/PaymentAcceptance/Transactions/listTransaction.api";

const reports = [
  {
    name: "All",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.all.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Sale",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.sale.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Auth Only",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.authOnly.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Capture",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.capture.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Post Auth",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.postAuth.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Save",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.save.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Credit",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.credit.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Refund",
    shortDescription: "by Transaction type",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.refund.mode,
      ...last30DaysFilterProps,
    },
  },
];

export default reports;
