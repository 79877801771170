import * as types from '../actionTypes';

export const saveUserData = user => dispatch => {
  dispatch({
    type: types.SET_LOGGED_USER_DATA,
    payload: user
  });
};

export const clearUserData = () => dispatch => {
  dispatch({
    type: types.CLEAR_LOGGED_USER_DATA,
  });
};