import { useMutation, useQueryClient } from "react-query";
import mixpanel from "mixpanel-browser";
import api from "../../../API";
import { CTAButton, Message, Modal, Title } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import classNames from "../Overview.module.scss";

const DelinkAccountBody = ({ onCancel, customerID }) => {
  const queryClient = useQueryClient();

  const delinkAccountFn = (event) =>
    api.BusinessBanking.delinkExternalAccount(customerID);

  const delinkAccountMutation = useMutation((event) => delinkAccountFn(event));

  const handleDelinkAccountClick = () => {
    delinkAccountMutation.mutate(customerID, {
      onSuccess: () => {
        Message({ type: "success", content: "Account delinked successfully" });
        try {
          mixpanel.track("Delink Account Successful", {
            "Bank Name": customerID.bankName,
          });
        } catch (error) {
          console.error("error: ", error)
        }
        onCancel();
        if (customerID.otz_business_id) {
          queryClient.invalidateQueries("getBusinessDetail", {
            queryKey: { id: customerID.otz_business_id },
          });
        } else {
          queryClient.invalidateQueries("getPersonDetail", {
            queryKey: { id: customerID.otz_person_id },
          });
        }
      },
      onError: (error) => {
        generateErrorMessage(error)
        try {
          mixpanel.track("Delink Account Failed", {
            "Bank Name": customerID.bankName,
          });
        } catch (error) {
          console.error("error: ", error)
        }
      },
    });
  };

  return (
    <>
      <Title as="h3" style={{ marginBottom: 10 }}>
        Delink Account?
      </Title>
      <div style={{ marginBottom: 30 }}>
        Are you sure you want to delink account?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
            marginLeft: "10px",
          }}
          onClick={handleDelinkAccountClick}
          loading={!!delinkAccountMutation.isLoading}
          disabled={!!delinkAccountMutation.isLoading}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};

export default function DelinkAccount({ visible, onCancel, customerID }) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      maskClosable={false}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <DelinkAccountBody onCancel={onCancel} customerID={customerID} />
    </Modal>
  );
}
