import classNames from "./Preview.module.scss";
import { Input, CTAButton, Title } from "../../../../../common";
import logo from "../../../../../../assets/logo.svg";
import { Form, Input as AntInput, Button as AntButton } from "antd";

export default function VirtualTerminalFrom() {
  const [form] = Form.useForm();

  const onFinish = (data) => {};

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        <div>
          <img src={logo} alt="logo" className={classNames.logo} />
        </div>
        <div>
          <h5>1-800-555-1212</h5>
          <h5>otterzpayments.com</h5>
        </div>
      </div>
      <Title style={{ marginLeft: "30px", fontWeight: 700 }} as="h2">
        Secure Payment Form
      </Title>
      <div className={classNames.formWrapper}>
        <div className={classNames.modalLeft}>
          <div>
            <div className={classNames.modalDetailsContainer}>
              <Title as="h4">Name</Title>
              <div>
                <span>ABA Routing Number</span>
              </div>
              <Title as="h4">Company</Title>
              <div>
                <span>Harrott Connections LLC</span>
              </div>
              <Title as="h4">Invoice Number</Title>
              <div>
                <span>INV78377</span>
              </div>
              <Title as="h4">PO Number</Title>
              <div>
                <span>PO99393</span>
              </div>
              <Title as="h4">Billing Address</Title>
              <div>
                <span>
                  Ivy Lane, Newark, New York 19882,
                  <br />
                  United States of America
                  <br />
                  +1 800 888 827 7727 <br /> aharrott@gmail.com
                </span>
              </div>
              <Title as="h4">Shipping Address</Title>
              <div>
                <span>Same as billing address</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.modalRight}>
          <Form
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="amount"
              label={
                <span className={classNames.label}>
                  Amount <sup style={{ top: 1 }}>*</sup>
                </span>
              }
            >
              <Input
                className={classNames.inputAmount}
                style={{
                  backgroundColor: "#F6F7FA",
                  hight: 54,
                  border: "#fff",
                }}
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                placeholder="Enter Amount"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className={classNames.label}>Description</span>}
            >
              <Input size="small" placeholder="Please enter" />
            </Form.Item>

            <Form.Item
              name="client"
              label={<span className={classNames.label}>Payment Method</span>}
            >
              <div className={classNames.paymentMethod}>
                <AntButton style={{ width: "116px" }}>Card</AntButton>
                <AntButton style={{ width: "116px" }}>ACH</AntButton>
              </div>
            </Form.Item>

            <Form.Item
              name="email"
              label={
                <span className={classNames.label}>
                  Card Details <sup style={{ top: 1 }}>*</sup>
                </span>
              }
            >
              <AntInput.Group className={classNames.search} compact>
                <Input
                  size="small"
                  style={{ width: "60%" }}
                  placeholder="Enter card number"
                />

                <Input
                  size="small"
                  style={{ width: "20%" }}
                  placeholder="Expiry"
                />
                <Input
                  size="small"
                  style={{ width: "20%" }}
                  placeholder="CVV"
                  type="password"
                />
              </AntInput.Group>
            </Form.Item>
          </Form>
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            style={{
              height: 44,
              marginTop: "10px",
            }}
          >
            Process Payment
          </CTAButton>
        </div>
      </div>
    </div>
  );
}
