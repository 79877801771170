import { Message } from "../../common";
import constants from "./constants";

export const toastPAOnboardStatusWarning = () =>
  Message({
    type: "warning",
    content: constants.strings.onboardStatus.warning,
  });

export const toastPAMerchantSettingsStatus = () =>
  Message({
    type: "warning",
    content: constants.strings.settingsStatus.warning,
  });
