import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import TagManager from 'react-gtm-module';
import mixpanel from "mixpanel-browser";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import "./config";
import { StoreProvider } from "./store";
import Routes from "./Routes/RoutesConfig";
import reportWebVitals from "./reportWebVitals";
import { FORMATS } from "./App/constants";

try {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }

  TagManager.initialize(tagManagerArgs);
} catch (error) {
  console.log("GTM initialization error: ", error);
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_TOKEN,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

try {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });
} catch (error) {
  console.error("mixpanel-error: ", error)
}

moment.locale("en");
moment.defaultFormat = FORMATS.DATEPICKER_FORMAT;
moment.defaultFormatUtc = FORMATS.DATEPICKER_FORMAT;

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });

const queryClient = new QueryClient();

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <Routes />
        </StoreProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
