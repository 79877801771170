import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { commonMessage } from "../config";

const Success = ({ name }) => {
  return (
    <div className={classNames.success}>
      <RiCheckboxCircleFill
        size={90}
        color="#1DBC83"
        style={{ marginBottom: 34 }}
      />
      <Title as="h3">
        {commonMessage.success.title+" "}!
        {/* {`${name?.split(" ")?.[0]}`}! */}
      </Title>
      <div className={classNames.extra}>{commonMessage.success.extra}</div>
    </div>
  );
};

export default Success;
