import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import mixpanel from "mixpanel-browser";
import { Modal, CTAButton, Message, Title } from "../../../../common";
import useLanguage from "../../../../../hooks/useLanguage";
import api from "../../../../API";
import {
  dollarToCents,
  intToDecimalDollar,
} from "../../../../util/functions/dollarConvertion";
import generateErrorMessage from "../../../../util/functions/customError";
import { strings as makePaymentsString } from "../../../businessBanking.const";
import { DEFAULT_AMOUNT } from "../../../../constants";
import classNames from "./ConfirmPayment.module.scss";
import ACHTransactionModal from "../../../MakePayments/ACHPayments/ACHTransactionModal";

export default function ConfirmPayment({
  visible,
  onCancel,
  paymentData,
  form,
}) {
  //Hooks
  const messages = useLanguage(makePaymentsString);
  const queryClient = useQueryClient();

  const [OTPModalVisible, setOTPModalVisible] = useState(false);

  //Api Calls
  const { mutate, isLoading } = useMutation(
    api.BusinessBanking.createACHPayment,
    {
      onSuccess: (data) => {
        Message({
          type: "success",
          content: messages.paymentSuccessMessage,
        });

        try {
          mixpanel.track("Transfer Funds Successful", {
            Payee: paymentData?.payee?.account_owner_name,
            Amount: paymentData?.payment?.amount,
            "Reason for Transfer": paymentData?.payment?.description,
          });
        } catch (error) {
          console.error("error: ", error);
        }

        onCancel();
        form.resetFields();
        setOTPModalVisible(false);
        queryClient.invalidateQueries("getRecentTransaction", {
          exact: true,
        });
        queryClient.invalidateQueries("getAccountBalance", { exact: true });
      },

      onError: (error) => {
        if (
          error.response.status === 422 &&
          error.response.data.payload.code === "MFA_REQUIRED"
        ) {
          setOTPModalVisible(true);
        } else if (
          error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
        ) {
          Message({
            type: "error",
            content:
              "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
          });
        } else {
          generateErrorMessage(error);
          try {
            mixpanel.track("Transfer Funds Failed", {
              Payee: paymentData?.payee?.account_owner_name,
              Amount: paymentData?.payment?.amount,
              "Reason for Transfer": paymentData?.payment?.description,
              "Failure Message": error?.response?.data?.message,
            });
          } catch (error) {
            console.error("error: ", error);
          }
          form.resetFields();
          onCancel();
        }
      },
    }
  );

  //Event handlers
  const onOk = () => {
    const reqBody = {
      ...paymentData.payment,
      amount: Number(dollarToCents(paymentData?.payment?.amount)),
    };
    mutate(reqBody);
  };

  return (
    <>
      <Modal
        closable={false}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title={false}
        className={classNames.modal}
        bodyStyle={{
          padding: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        footer={
          <div className={classNames.footer}>
            <CTAButton
              onClick={() => {
                onCancel();
                try {
                  mixpanel.track("Transfer Funds Cancelled", {
                    Payee: paymentData?.payee?.account_owner_name,
                    Amount: paymentData?.payment?.amount,
                    "Reason for Transfer": paymentData?.payment?.description,
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
              }}
              style={{ height: 35 }}
            >
              Cancel
            </CTAButton>
            <CTAButton
              type="primary"
              style={{ height: 35 }}
              onClick={onOk}
              loading={isLoading}
            >
              Confirm
            </CTAButton>
          </div>
        }
        width={360}
        onOk={onOk}
      >
        <div className={classNames.title}>
          <Title as="h3">Confirm payment</Title>
          {/* <div>Please confirm the below payment</div> */}
        </div>
        <div className={classNames.transactionCard}>
          <div>
            <div className={classNames.payeeDetails}>
              <span>{paymentData?.payee?.account_owner_name}</span>
              {paymentData?.payee?.account_number_masked && (
                <span>{`Account Number : ${paymentData?.payee?.account_number_masked}`}</span>
              )}
              {paymentData?.payee?.account_type && (
                <span>{`Account Type : ${paymentData?.payee?.account_type}`}</span>
              )}
            </div>
            <div className={classNames.transaction}>
              <div>
                <span>Amount</span>
                <span>
                  {intToDecimalDollar(Number(paymentData?.payment?.amount))}
                </span>
              </div>
              <div>
                <span>Transaction Fee</span>
                <span>{DEFAULT_AMOUNT}</span>
              </div>
            </div>
            <div className={classNames.total}>
              Total
              <span>
                {intToDecimalDollar(Number(paymentData?.payment?.amount))}
              </span>
            </div>
            <div className={classNames.summaryText}>
              <span>
                {paymentData?.payee?.account_owner_name} will receive{" "}
                {intToDecimalDollar(Number(paymentData?.payment?.amount))} .
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <ACHTransactionModal
        visible={OTPModalVisible}
        // closable={true}
        onCloseClick={() => setOTPModalVisible(false)}
        onCancel={() => setOTPModalVisible(false)}
        onAuthorize={onOk}
      />
    </>
  );
}
