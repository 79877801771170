import { Form } from "antd";
import { Input, CTAButton } from "../../../../common";
import classNames from "./Adjust.module.scss";

const AdjustForm = ({ record, onTransactionClick, onCancel }) => {
  const onFinish = (data) => {
    onTransactionClick(Object.assign({}, data, { refNum: record?.xRefNum }));
    onCancel();
  };

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <Form.Item
        label={
          <span className={classNames.label}>
            Description <sup style={{ top: 1 }}>*</sup>
          </span>
        }
        name={"description"}
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input size="small" placeholder="Schedule: Everyday" />
      </Form.Item>
      <Form.Item
        label={
          <span className={classNames.label}>
            Order ID <sup style={{ top: 1 }}>*</sup>
          </span>
        }
        name={"orderId"}
        rules={[{ required: true, message: "OrderId is required" }]}
      >
        <Input size="small" placeholder="Please enter" />
      </Form.Item>
      <CTAButton
        htmlType="submit"
        type="primary"
        key="submit"
        style={{
          height: 44,
          width: 385,
        }}
      >
        Adjust
      </CTAButton>
    </Form>
  );
};

export default AdjustForm;
