import { API } from "aws-amplify";

export default async function createBeneficiaryBusinessRelation(reqBody) {
  if (reqBody?.id) {
    return await API.patch(
      "businessRelationship",
      `beneficial-owner/${reqBody?.id}`,
      {
        body: {
          ...reqBody?.data,
        },
      }
    );
  } else {
    return await API.post("businessRelationship", `beneficial-owner`, {
      body: {
        ...reqBody?.data,
      },
    });
  }
}
