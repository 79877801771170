import commonMessages from "../../util/constants";
import deepMerge from "../../util/functions/deepMerge";

const directoryMessages = {
  en: {
      DATA_NOT_AVAILABLE: "Data is not yet available, please check back later.",
    },
};

const messages = deepMerge(commonMessages, directoryMessages);

export default messages;
