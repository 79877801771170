import classNames from "../SupportHome.module.scss";
import { Card } from "antd";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";

export default function SupportTopicCard({ topic, onClick }) {
  return (
    <Card className={classNames.supportTopicCard}>
      <BankCardLineIcon
        color="#7372FF"
        size={30}
        className={classNames.topicIcon}
      />
      <div className={classNames.topicIconBackdrop} />
      <div className={classNames.topicAction}>
        <span>{topic}</span>
        <ArrowRightLineIcon
          className={classNames.arrowBtnWithBg}
          onClick={onClick}
        />
      </div>
    </Card>
  );
}
