import { useState } from "react";
import { Title, Input, Select, DatePicker } from "../../../../common";
import {
  Form,
  Input as AntInput,
  Button as AntButton,
  AutoComplete,
} from "antd";
import {
  RiAddFill,
  RiCloseCircleFill,
  RiArrowDropDownLine,
} from "react-icons/ri";
import moment from "moment";

import { validation } from "../../../../Auth/config";
import { intToDecimalDollar } from "../../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../../../constants";
import TextArea from "antd/lib/input/TextArea";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";
import classNames from "../Invoices.module.scss";

export default function CreateNewInvoiceForm({
  form,
  onFinish,
  customers,
  productData,
  invoiceTemplateSettings,
  taxRates,
  summary,
  setSummary,
  discount,
  setDiscount,
}) {
  const [currFields, setCurrFields] = useState(1);
  let dataSource =
    productData &&
    productData.length > 0 &&
    productData.map((value, index) => {
      return {
        text: value.productName,
        value: value.productName,
        key: value.id,
        ...value,
      };
    });

  const calculatePrice = (index, flag, option) => {
    let values = form.getFieldsValue();
    let obj = values["salesItems"][index];
    let unitCost = obj.unitCost ? parseFloat(obj.unitCost) : 0;
    let quantity = obj.quantity && (parseInt(obj.quantity) && parseInt(obj.quantity) > 0)
      ? parseInt(obj.quantity)
      : '';
    let totalWithOutTax = unitCost * quantity;
    let taxAmount = obj.taxRate
      ? parseFloat((totalWithOutTax * obj.taxRate) / 100)
      : 0;
    let price = unitCost * quantity;
    //+ taxAmount;
    let array = values["salesItems"];

    if (flag === "productSelect") {
      Object.assign(obj, {
        unitCost: option.price ? parseFloat(option.price) : 0,
        taxRate: option.taxRate ? parseFloat(option.taxRate) : 0,
        taxAmount: Number(parseFloat(option.taxRate / 100))
          ? parseFloat(option.taxRate / 100) * parseFloat(option.price)
          : 0,
        subTotal: option.price ? parseFloat(option.price) : 0,
        quantity: 1,
        price: Number(parseFloat(option.price).toFixed(2)) || 0,
        // price: (parseFloat(option.price) + parseFloat(option.taxRate / 100)).toFixed(2)
        // subTotal: totalWithOutTax,
      });
    } else {
      Object.assign(obj, {
        price: Number(price.toFixed(2)),
        taxAmount: taxAmount,
        subTotal: totalWithOutTax,
      });
    }

    if (flag === "remove") {
      delete array[index];
      array.filter(Boolean);
      form.setFieldsValue({ salesItems: array });
      setCurrFields((prev) => prev - 1);
    } else {
      array.filter(Boolean);
      form.setFieldsValue({ salesItems: array });
    }
    let subTotal = array
      .map((value, index) => value.subTotal)
      .reduce((sum, next) => sum + next, 0);
    let tax = array
      .map((value, index) => value.taxAmount)
      .reduce((sum, next) => sum + next, 0);
    setSummary({ subTotal, tax });
  };

  //Helper
  function validationCheck() {
    return (
      form
        ?.getFieldsError()
        ?.filter(
          (item) =>
            ["unitCost", "description"].includes(item?.name[2]) &&
            item?.errors?.length
        )?.length ||
      !(form?.getFieldsValue()?.salesItems?.length
        ? form?.getFieldsValue()?.salesItems[currFields - 1]?.unitCost &&
        form?.getFieldsValue()?.salesItems[currFields - 1]?.description
        : false)
    );
  }

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <div className={classNames.modalBodyFormUpper}>
        <div className={classNames.modalLeft}>
          <div className={classNames.modalHeader}>
            <Title as="h3">Invoice Details</Title>
          </div>
          {/* Commenting below form input to according to Mukund's approval on 4/12/2022.
          Reason: Invoice number is not an editable field from invoicea's end. - Sagara */}
          {/* <Form.Item
            name="invoiceNumber"
            rules={[{ required: true, message: "Invoice number is required" }]}
            label={
              <Title as="h4" className={classNames.label}>
                Invoice Number
              </Title>
            }
          >
            <Input
              size="small"
              style={{ width: 168 }}
              placeholder="Please Enter"
            />
          </Form.Item> */}
          <Form.Item
            name="client"
            label={
              <Title as="h4" className={classNames.label}>
                Customer <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            rules={[{ required: true, message: "Customer is required" }]}
          >
            <Select
              size="small"
              style={{ width: 352 }}
              placeholder="Please Select"
              className={classNames.customSelect}
            >
              {Array.isArray(customers) &&
                customers.map((val, index) => (
                  <Select.Option value={val.id}>{val.name}</Select.Option>
                ))}
              {/* <AntButton type="link" className={classNames.addNewButton}>
                <RiAddFill size={13} style={{ marginRight: 5 }} />
                Add new client
              </AntButton> */}
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="invoiceDate"
              label={
                <span className={classNames.label}>
                  Date <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                marginRight: 14,
              }}
              rules={[{ required: true, message: "Date is required" }]}
              initialValue={moment()}
            >
              <DatePicker
                defaultPickerValue={moment()}
                style={{ height: 44, width: 168 }}
              />
            </Form.Item>

            <Form.Item
              name="discount"
              label={<span className={classNames.label}>Discount</span>}
              style={{
                display: "inline-block",
              }}
              rules={[
                {
                  pattern: new RegExp(REGEX.discount),
                  message: "Valid Discount required.",
                },
              ]}
            >
              <Input
                type="number"
                size="small"
                style={{ width: 168 }}
                prefix="%"
                defaultValue={0}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="reference"
            label={
              <Title as="h4" className={classNames.label}>
                Reference
              </Title>
            }
            rules={[
              {
                max: MAX_MIN.description.max,
                message: `Max ${MAX_MIN.description.max} characters`,
              },
            ]}
          >
            <Input
              size="small"
              style={{ width: 352 }}
              placeholder="Please Enter"
              maxLength={50}
            />
          </Form.Item>
        </div>
        <div className={classNames.modalRight}>
          <div className={classNames.invoiceSummary}>
            <div className={classNames.modalHeader}>
              <Title as="h3">Invoice Summary</Title>
            </div>
            <div>
              <span>Sub Total</span>
              <span>
                {intToDecimalDollar(Number(summary.subTotal)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Tax</span>
              <span>
                {intToDecimalDollar(Number(summary.tax)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Discount</span>
              <span>{discount}%</span>
            </div>
            <div className={classNames.total}>
              <span>Total</span>
              <span>
                {intToDecimalDollar(
                  Number(
                    summary.subTotal -
                    summary.subTotal * (discount / 100) -
                    (summary.subTotal - summary.subTotal) * (discount / 100) +
                    summary.tax
                  )
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
            {/* <div>
              <span>Amount Paid</span>
              <span>$150.00</span>
            </div>
            <div>
              <span>Amount Due</span>
              <span>$200.00</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className={classNames.saleItemsWrapper}>
        <div className={classNames.modalHeader}>
          <Title as="h3">Sale Items</Title>
        </div>
        <div className={classNames.salesItemsHeader}>
          <span
            style={{
              width: "35%",
              marginLeft: currFields > 1 ? "32px" : "",
              marginRight: currFields > 1 ? "-5px" : "5px",
            }}
          >
            Description <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 125 }}>
            Unit Cost <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 75 }}>
            Qty <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 115 }}>
            Tax <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 150 }}>Price</span>
        </div>
        <div>
          <Form.List
            name="salesItems"
            on
            rules={[
              {
                validator: async (_, salesItems) => {
                  if (!salesItems || salesItems.length < 1) {
                    return Promise.reject(
                      new Error(validation.salesItems.error)
                    );
                  }
                },
              },
            ]}
            initialValue={[{}]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={field.key}
                    className={classNames.inputGroup}
                  >
                    {fields.length > 1 ? (
                      <RiCloseCircleFill
                        size={20}
                        className={classNames.inputGroupRemoveIcon}
                        onClick={() => {
                          if (currFields > 1) {
                            calculatePrice(index, "remove");
                            remove(field.index);
                          } else {
                            form.resetFields();
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <AntInput.Group
                      compact
                      className={classNames.inputGroupItems}
                    >
                      <Form.Item
                        name={[field.name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                          {
                            max: MAX_MIN.description.max,
                            message: `Max ${MAX_MIN.description.max} characters`,
                          },
                        ]}
                        style={{
                          width: "35%",
                          marginRight: "5px",
                        }}
                      >
                        <AutoComplete
                          size="large"
                          placeholder="Please enter"
                          style={{
                            width: "100%",
                          }}
                          className={classNames.autoSelectDesc}
                          options={dataSource}
                          filterOption={(inputValue, option) => {
                            return Object.values(option)
                              .toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          onSelect={(value, option) => {
                            calculatePrice(index, "productSelect", option);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "unitCost"]}
                        rules={[
                          { required: true, message: "Required" },
                          {
                            pattern: new RegExp(
                              /^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/
                            ),
                            message: "Enter a valid price",
                          },
                          () => ({
                            validator(_, value) {
                              if (value && value?.toString()?.length > 11) {
                                return Promise.reject(
                                  new Error(
                                    "Max 8 digits with 2 decimal points"
                                  )
                                );
                              }
                              if (!value?.toString()?.length) {
                                return Promise.reject(new Error("Required"));
                              }
                              return Promise.resolve();
                            },
                          }),
                          () => ({
                            validator(_, value) {
                              if (value === "0" || value === 0) {
                                return Promise.reject(
                                  new Error("0 is not allowed")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <AntInput
                          type="number"
                          size="small"
                          placeholder="Enter"
                          prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                          style={{ width: 125, marginRight: "5px" }}
                          onChange={() => calculatePrice(index)}
                          className={classNames.inputNumber}
                          min={0}
                          maxLength={11}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "quantity"]}
                        initialValue={0}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "Invalid quantity",
                          },
                          () => ({
                            validator(_, value) {
                              if (value) {
                                value = value.toString().replace(/[e+-.]/gi, '');
                              }
                              if (value && value?.toString()?.length > 6) {
                                return Promise.reject(
                                  new Error("Max 6 characters")
                                );
                              }
                              if (!value?.toString()?.length) {
                                return Promise.reject(new Error("Required"));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="number"
                          size="small"
                          placeholder="Enter"
                          style={{ width: 75, marginRight: "5px" }}
                          onChange={() => calculatePrice(index)}
                          className={classNames.inputNumber}
                        />
                      </Form.Item>
                      <Form.Item name={[field.name, "taxRate"]}>
                        <Select
                          size="small"
                          style={{ width: 115, marginRight: "5px" }}
                          className={classNames.salesItemSelect}
                          onChange={() => calculatePrice(index)}
                          defaultValue={0}
                          suffixIcon={
                            <span style={{ color: "#9ba3ab" }}>
                              %<RiArrowDropDownLine />
                            </span>
                          }
                        >
                          <Select.Option key={0} value={"0"}>
                            0
                          </Select.Option>
                          {Array.isArray(taxRates) &&
                            taxRates.map((val, index) => (
                              <Select.Option
                                key={`${index}${val.taxRate}`}
                                value={val.taxRate}
                              >
                                {val.taxRateName} ({val.taxRate}%)
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name={[field.name, "price"]}>
                        <Input
                          size="small"
                          placeholder="0"
                          prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                          style={{
                            width: 150,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            marginRight: "5px",
                          }}
                          disabled
                        />
                      </Form.Item>
                    </AntInput.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <div
                    className={classNames.salesItemsFooter}
                    style={{ marginLeft: currFields === 1 ? "0" : "14px" }}
                  >
                    <AntButton
                      onClick={() => {
                        const currentSaleItem =
                          form.getFieldsValue().salesItems[currFields - 1];
                        if (
                          currentSaleItem?.unitCost &&
                          currentSaleItem?.description
                        ) {
                          add();
                          setCurrFields((prev) => prev + 1);
                        }
                      }}
                      type="link"
                      className={classNames.addNewButton}
                      disabled={validationCheck()}
                    >
                      <RiAddFill size={13} style={{ marginRight: 5 }} />
                      Add new item
                    </AntButton>
                  </div>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>
      <div className={classNames.modalHeader}>
        <Title as="h3">More Information</Title>
      </div>
      <Form.Item>
        <Form.Item
          name="notes"
          label={<span className={classNames.label}>Notes</span>}
          style={{
            display: "inline-block",
            marginRight: 14,
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            disabled
            defaultValue={invoiceTemplateSettings?.notes}
            autoSize={true}
          />
        </Form.Item>

        <Form.Item
          name="terms"
          label={<span className={classNames.label}>Terms</span>}
          style={{
            display: "inline-block",
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            disabled
            defaultValue={invoiceTemplateSettings?.terms}
            autoSize={true}
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
}
