import { API } from "aws-amplify";

export default async function getAlertsandNotifications(id) {
  if (id) {
    return await API.get("notifications", "external-notifications", {
      queryStringParameters: {
        tenantId: id,
      },
    });
  }
}
