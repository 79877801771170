import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { CTAButton, Input, Message, BackButton } from "../../common";
import api from "../../API";
import { ROUTES } from "../../constants";
import generateErrorMessage from "../../util/functions/customError";

import { validation } from "../config";
import Heading from "../Heading";
import classNames from "./ForgotPassword.module.scss";

export default function OTPMessage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const forgotPWRequestOTP = useMutation((data) => {
    return api.onBoarding.forgotPassword(data);
  });

  const handleOnFinish = async ({ email }) => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    forgotPWRequestOTP.mutate(email, {
      onSuccess: () => {
        window.localStorage.setItem("OTTERZ.FPEmail", email); // FP - Forgot Password
        Message({ type: "success", content: "OTP sent successfully" });
        navigate(ROUTES.VERIFICATION.EMAIL_OTP);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.backButtonWrapper}>
        <BackButton variant="tail" onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Forgot Password"
          description="Enter your email address registered with Otterz"
        />
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleOnFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: validation.email.valid,
              },
              { required: true, message: validation.email.required },
            ]}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>
          <CTAButton
            htmlType="submit"
            type="primary"
            loading={forgotPWRequestOTP.isLoading}
          >
            Send OTP
          </CTAButton>
        </Form>
      </div>
    </div>
  );
}
