// import { Modal } from "../../../common";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import DepositCheckForm from "./DepositCheckForm";
import useLanguage from "../../../../hooks/useLanguage";
import messages from "../../config/messages.const";

export default function DepositCheck({
  visible,
  onCancel,
  form,
  personId,
  businessId,
  accountId,
}) {
  //need to handle react portal in Modal from common components inorder to handle drag and drop
  //created ticekt to handle above issue
  //ticket link: https://otterz1.atlassian.net/browse/OTT-1312

  //Hooks
  const [removeCheckImage, setRemoveCheckImage] = useState(false);
  const language = useLanguage(messages);
  const [front, setFront] = useState(false);
  const [back, setBack] = useState(false);

  useEffect(() => {
    setFront(false);
    setBack(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      closable={true}
      visible={visible}
      form
      onCancel={() => {
        setRemoveCheckImage(true);
        onCancel();
      }}
      maskClosable={false}
      title={language.DEPOSIT_CHECK}
      bodyStyle={{
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
      width={730}
      footer={false}
    >
      <DepositCheckForm
        onCancel={onCancel}
        form={form}
        removeCheckImage={removeCheckImage}
        personId={personId}
        businessId={businessId}
        accountId={accountId}
        visible={visible}
        setFront={setFront}
        setBack={setBack}
        back={back}
        front={front}
      />
    </Modal>
  );
}
