import { useEffect, useState } from "react";
import classNames from "./ViewEstimate.module.scss";
import { CTAButton, Header, Select, Message } from "../../../common";
import { Card, Button as AntButton, Spin } from "antd";
import {
  RiEditLine,
  RiShareBoxFill,
  RiPrinterLine,
  RiDownloadCloudLine,
  RiDeleteBin7Line,
  // RiSearch2Line,
  RiToolsFill,
} from "react-icons/ri";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import EditEstimate from "./EditEstimate/EditEstimate";
import ChangeStatus from "../Invoices/ChangeStatus/ChangeStatus";
import api from "../../../API";
import ShareEstimate from "./ShareEstimate/ShareEstimate";
import DeleteEstimate from "../AllEstimates/DeleteEstimate/DeleteEstimate";
import ROUTES from "../../../constants/routes";
import generateErrorMessage from "../../../util/functions/customError";
import messages from "../../config/messages.const";
import useLanguage from "../../../../hooks/useLanguage";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/browser";

export default function ViewEstimate() {
  const location = useLocation();
  const language = useLanguage(messages);

  const [estimateData] = useState(location.state);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setLoading(true);
  }, [location.state]);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimate, isLoading: estimateIsLoading } = useQuery(
    "getEstimateById",
    () => api.Receivables.Estimates.getEstimateById(location?.state?.id),
    {
      enabled: !!location?.state?.id,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: estimatePDF,
    isLoading: estimatePDFIsLoading,
    refetch: pdfRefetch,
  } = useQuery(
    "getEstimatePDF",
    () => api.Receivables.Estimates.getEstimatePDF(location?.state?.id),
    {
      enabled: !!location?.state?.id,
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateTemplateSettingsQuery } = useQuery(
    "getEstimateSettings",
    () =>
      api.Receivables.Settings.getEstimateTemplateSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: productsQuery } = useQuery(
    "getProductsByCompanyId",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const [deleteEstimateModalVisibility, setDeleteEstimateModalVisibility] =
    useState(false);

  const [editEstimateModalVisibility, setEditEstimateModalVisibility] =
    useState(false);

  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);

  const [estimateStatus, setEstimateStatus] = useState();

  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
    setEstimateStatus(
      estimate?.data?.estimateStatus
        ? estimate?.data?.estimateStatus
        : estimateData.status
    );
  };

  //   const [shareEstimateModalVisibility, setShareEstimateModalVisibility] =
  //     useState(false);

  // const [invoiceZoomLevel, setInvoiceZoomLevel] = useState(0);

  const handleEditEstimateCancelClick = (isEdit = false) => {
    if (isEdit) {
      setLoading(true);
      pdfRefetch();
    }
    setEditEstimateModalVisibility(false);
  };

  //   const handleShareEstimateClick = () => {
  //     setShareEstimateModalVisibility(true);
  //   };

  // const handleInvoiceZoomLevel = () => {
  //   console.log(invoiceZoomLevel);
  //   setInvoiceZoomLevel(100);
  // };

  const navigate = useNavigate();
  const { Option } = Select;

  // const deleteEstimate = () => {
  //   return api.Receivables.Estimates.deleteEstimate(location?.state?.id);
  // };

  // const deleteMt = useMutation((event) => deleteEstimate(event));

  // const handleEstimateDeleteClick = () => {
  //   if (location?.state?.id) {
  //     deleteMt.mutate(
  //       {},
  //       {
  //         onSuccess: (data, variables, context) => {
  //           setDeleteEstimateModalVisibility(false);
  //           Message({
  //             type: "success",
  //             content: "Estimate deleted successfully!",
  //           });
  //         },
  //         onError: (error, variables, context) => {
  //           Message({ type: "error", content: error.toString() });
  //         },
  //       }
  //     );
  //   }
  // };

  const handleEstimateStatusChange = (value) => {
    setChangeStatusModalVisibility(true);
    setEstimateStatus({
      flag: "estimate",
      value: value,
      record: location.state,
    });
  };

  // const handleDownloadClick = () => {
  //   Message({ type: "success", content: "Downloaded" });
  // };

  const [shareInvoiceModalVisibility, setShareInvoiceModalVisibility] =
    useState(false);

  const handleShareInvoiceCancelClick = () => {
    setShareInvoiceModalVisibility(false);
  };

  const handlePrintClick = () => {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ` src="data:application/pdf;base64,${estimatePDF?.data?.pdf}` +
      '"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    // printWindow.print();

    // Message({ type: "success", content: "Sent for print" });
  };
  //convert estimate to an invoice
  function createInvoiceFn(data) {
    let customersArray = customerQuery?.data || [];
    const client =
      Array.isArray(customersArray) &&
      customersArray.length > 0 &&
      customersArray.find((value, index) => value.name === data.receiverName);
    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      // invoiceNumber: data.invoiceNumber,
      clientId: client.id,
      invoiceDate: data.estimateDate,
      discount: data.discount,
      reference: data.purchaseOrderNumber,
      itemDto: data.item,
    };
    return api.Receivables.Invoices.createInvoice(input);
  }

  const createInvoiceMn = useMutation((event) => createInvoiceFn(event));
  const convertInvoiceToEstimate = () => {
    createInvoiceMn.mutate(estimate?.data, {
      onSuccess: async (response) => {
        Message({ type: "success", content: language.INVOICE_CREATE_SUCCESS });
        try {
          // Extracting values from data
          const totalItems = estimate?.data.item.length;
          const totalPrice = estimate?.data.item.reduce((acc, item) => acc + item.subTotal, 0);

          const invoice = await api.Receivables.Invoices.getInvoiceById(response.data.id)

          mixpanel.track("Invoice Creation Successful", {
            "Invoice Number": invoice?.data?.invoiceNumber,
            "Date Created": invoice?.data?.invoiceDate,
            "Date Settled": invoice?.data?.overdueDate, // This value is not provided in the data object
            "No. of items": totalItems,
            "Total Price": totalPrice,
            "Discount": estimate?.data?.discount,
            "Tax": estimate?.data?.taxTotal,
            "Payable Amount": estimate?.data?.total,
            "Status": invoice?.data?.invoiceStatus, // This value is not provided in the data object
            "Converted from Estimate": "True", // This value is not provided in the data object
          });
        } catch (error) {
          Bugsnag.notify("mixpanel error", error);
        }

      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  // delete estimate mutation
  const estimateDeleteMn = useMutation(() =>
    api.Receivables.Estimates.deleteEstimate(estimate?.data?.id)
  );
  const estimateDelete = () => {
    estimateDeleteMn.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getEstimates");
          setDeleteEstimateModalVisibility(false);
          navigate(ROUTES.APP.RECEIVABLES.ALL_ESTIMATES);
          Message({
            type: "success",
            content: language.ESTIMATE_DELETE_SUCCESS,
          });
        },
        onError: (error) => generateErrorMessage(error),
      }
    );
  };

  useEffect(() => {
    if (estimate) {
      setEstimateStatus(
        estimate?.data?.estimateStatus
          ? estimate?.data?.estimateStatus
          : estimateData.status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate, estimateData]);

  if (!estimateData) {
    return <Navigate to={ROUTES.APP.RECEIVABLES.INVOICES} />;
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title={`Estimate : ${
            estimate?.data?.estimatePrefix ? estimate?.data?.estimatePrefix : ""
          }${location.state.number}`}
          back
          onClick={() => navigate(estimateData.from)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            {loading || estimateIsLoading || estimatePDFIsLoading ? (
              <div className={classNames.spinWrapper}>
                <Spin size="large" />
              </div>
            ) : (
              <Card className={classNames.invoiceCard}>
                <div className={classNames.cardLeft}>
                  <div className={classNames.invoiceContainer}>
                    <iframe
                      src={`data:application/pdf;base64,${estimatePDF?.data?.pdf}`}
                      title="estimate"
                      frameBorder="0"
                      className={classNames.invoice}
                      zoom={1.5}
                    />
                  </div>
                  <div className={classNames.invoiceOptions}>
                    <AntButton
                      className={classNames.deleteBtn}
                      onClick={() => setDeleteEstimateModalVisibility(true)}
                    >
                      <RiDeleteBin7Line size={13} color="#EE6969" />
                      Delete
                    </AntButton>
                    {/* <AntButton
                    className={classNames.zoomBtn}
                    // onClick={handleInvoiceZoomLevel}
                  >
                    <RiSearch2Line size={13} color="#fff" />
                    Zoom
                  </AntButton> */}
                  </div>
                </div>
                <div className={classNames.cardRight}>
                  <div className={classNames.invoiceStatusSelect}>
                    <span>Mark estimate as</span>
                    <Select
                      defaultValue={
                        estimateStatus === "overdue"
                          ? "Overdue"
                          : estimateStatus
                      }
                      value={
                        estimateStatus === "overdue"
                          ? "Overdue"
                          : estimateStatus
                      }
                      onChange={handleEstimateStatusChange}
                      // disabled={estimateStatus === "overdue" ? true : false}
                    >
                      {estimateStatus === "draft" ||
                      estimateStatus === "overdue" ? (
                        <>
                          <Option value="draft">Draft</Option>
                          <Option value="sent">Sent</Option>
                        </>
                      ) : estimateStatus === "sent" ? (
                        <>
                          <Option value="sent">Sent</Option>
                        </>
                      ) : (
                        ""
                      )}
                    </Select>
                  </div>
                  <div>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={() => setEditEstimateModalVisibility(true)}
                    >
                      <RiEditLine size={12} />
                      Edit
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={() => setShareInvoiceModalVisibility(true)}
                    >
                      <RiShareBoxFill size={12} />
                      Share
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handlePrintClick}
                    >
                      <RiPrinterLine size={12} />
                      Print
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      download={`${estimate?.data?.estimateNumber}_${estimate?.data?.receiverName}.pdf`}
                      href={`data:application/octet-stream;base64, ${estimatePDF?.data?.pdf}`}
                    >
                      <RiDownloadCloudLine size={12} />
                      Download
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={convertInvoiceToEstimate}
                      loading={createInvoiceMn.isLoading}
                    >
                      <RiToolsFill size={12} />
                      Convert to Invoice
                    </CTAButton>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
      <DeleteEstimate
        visible={deleteEstimateModalVisibility}
        onCancel={() => setDeleteEstimateModalVisibility(false)}
        estimate={estimate?.data}
        estimateDelete={estimateDelete}
        estimateDeleteMn={estimateDeleteMn}
      />
      <EditEstimate
        estimate={estimate?.data}
        visible={editEstimateModalVisibility}
        onCancel={handleEditEstimateCancelClick}
        customers={customerQuery?.data}
        products={productsQuery?.data}
        taxRates={taxRates?.data}
        invoiceTemplateSettings={estimateTemplateSettingsQuery?.data}
        setEditEstimateModalVisibility={setEditEstimateModalVisibility}
      />
      <ChangeStatus
        visible={changeStatusModalVisibility}
        status={estimateStatus}
        onCancel={handleChangeStatusModalCancelClick}
        setChangeStatusModalVisibility={setChangeStatusModalVisibility}
      />
      <ShareEstimate
        visible={shareInvoiceModalVisibility}
        onCancel={handleShareInvoiceCancelClick}
        customers={customerQuery?.data}
        estimate={estimate?.data}
      />
    </div>
  );
}
