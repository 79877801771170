import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Avatar } from "antd";
import { NavLink } from "react-router-dom";
import {
  RiLogoutBoxLine,
  RiHome7Line,
  // RiBankLine,
  RiUserReceived2Line,
  // RiPieChart2Line,
  RiSettings3Line,
  // RiGiftLine,
  RiVipCrownLine,
} from "react-icons/ri";
import { useQuery } from "react-query";
import { AppContext } from "../../../context/AppState";
// import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import api from "../../API";
import { uuidv4 } from "../../util/functions/uuidV4";
// import businessBankingMenuItems from "./data/businessBanking.data";
import receivablesMenuItems from "./data/receivables.data";
// import payablesMenuItems from "./data/payables.data";
import ROLES from "../../constants/scopesToRoles/roles";
import { /*APPLICATION_STATUS,*/ ROUTES } from "../../constants";
import logo from "../../../assets/logo.svg";
import user from "../../../assets/user.png";
import classNames from "./Sidebar.module.scss";
import Button from "../Button/Button";

const getDefaultSelectedKeys = (path) => {
  let keys = [];

  if (path === ROUTES.APP.INDEX) {
    keys = ["Home", "home"];
  } else {
    keys = path.replace(`${ROUTES.APP.INDEX}/`, "").split("/");
    const paymentAcceptanceIndex =
      keys.indexOf("customers-payment-acceptance") +
      keys.indexOf("transactions") +
      keys.indexOf("recurring-schedules") +
      keys.indexOf("payment-acceptance-onboarding");

    const bankingIndex =
      keys.indexOf("recurring-payments") +
      keys.indexOf("payees") +
      keys.indexOf("scheduled-payments");

    const invoiceIndex =
      keys.indexOf("all-invoices") +
      keys.indexOf("invoices") +
      keys.indexOf("all-estimates") +
      keys.indexOf("template-settings") +
      keys.indexOf("recurring-invoices") +
      keys.indexOf("customers") +
      keys.indexOf("products-services") +
      keys.indexOf("estimates") +
      keys.indexOf("tax-database") +
      keys.indexOf("invoices-getting-started");

    const settingsIndex = keys.indexOf("atm-locator");

    const rewardsIndex = keys.indexOf("rewards");

    const reportsIndex = keys.indexOf("reports");

    if (invoiceIndex > -9) {
      keys[1] = "invoices";
    }
    if (paymentAcceptanceIndex > -4) {
      keys[1] = "payment-acceptance";
    }
    if (bankingIndex > -3) {
      keys[1] = "make-payments";
    }
    if (settingsIndex > 0) {
      keys = ["settings", "atm-locator"];
    }
    if (rewardsIndex === 0) {
      keys = ["rewards", "rewards"];
    }
    if (reportsIndex === 0) {
      keys = ["reports", "reports"];
    }
  }

  return keys;
};

export default function Sidebar({ minimalView = false }) {
  //Hooks
  const navigate = useNavigate();
  const location = useLocation();
  // const { icidStatus: invoiceStatus } = useInvoiceActiveStatus();
  const [selectedKeys, setSelectedKeys] = useState(
    getDefaultSelectedKeys(location.pathname)
  );
  const [openKeys, setOpenKeys] = React.useState(selectedKeys);
  const { application/*, userData*/ } = useContext(AppContext);

  const [verifiedOwners, /*setVerifiedOwners*/] = useState(true);
  const [ownerRejected, /*setOwnerRejected*/] = useState(false);
  const [accountCreated, /*setAccountCreated*/] = useState(false);
  // const [route, setRoute] = useState("/app");

  useEffect(() => {
    const keys = getDefaultSelectedKeys(location.pathname);
    setSelectedKeys(keys);
    setOpenKeys(keys);
  }, [location.pathname]);

  //Other variables
  const { SubMenu } = Menu;

  // let activeStyle = {
  //   color: "#7372ff",
  // };

  const rootSubmenuKeys = ["banking", "receivables", "payables"];

  //Api calls
  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  // const { data: dataApplication, isLoading: loadingApplication } = useQuery(
  //   "getApplicationByTenantId",
  //   () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const { data: currentSubscriptionPlan } = useQuery(
    "getCurrentSubscriptionPlanId",
    () => api.Settings.getCurrentSubscriptionPlan(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useQuery("getAccountById", () => api.BusinessBanking.getAccount(), {
    refetchOnWindowFocus: false,
  });

  const { data: getUserByIdQuery } = useQuery(
    "getUserById",
    () => api.Settings.getUserById(currentUserData.username),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  //Event handlers
  const performLogout = async () => {
    navigate("/logout", { replace: true });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function handleTitleClick(menuItem) {
    setSelectedKeys([menuItem.parent, menuItem.item.replace(" ", "_")]);
  }

  function hideMenuItem(sidebarPath, role) {
    switch (role) {
      case ROLES.ACCOUNTING_USER:
        if (
          [
            "overview",
            "make-payments",
            "manage-cards",
            "reports",
            "rewards",
            "settings",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.INVOICING_USER:
        if (
          [
            "banking", // Whole banking section restrict to INVOICING user
            "reports",
            "rewards",
            "settings",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.EXPENSE_USER:
        if (
          [
            "banking",
            "reports",
            "rewards",
            "settings",
            "payment-acceptance",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      default: // OWNER has all the access
        return false;
    }
  }

  //JSX Element
  const menu = (
    <Menu.Item
      key="Logout"
      onClick={() => performLogout()}
      icon={<RiLogoutBoxLine size={18} style={{ color: "#7372ff" }} />}
      className={classNames.profilePicLogout}
      style={{ background: "#fff" }}
    >
      <span>Logout</span>
    </Menu.Item>
  );

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (minimalView) {
      if (minimalView && location.pathname === '/onboarding/dashboard') {
        setOpenKeys(["home"]);
        setSelectedKeys(["home"]);
      }
    }

    // console.log("Isloading: ", loadingApplication);

    // Define an async function inside the useEffect
    // const fetchBusinessRelations = async () => {
    //   try {
    //     const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
    //     await setVerifiedOwners(businessRelationsResponse?.payload?.relationShips?.every(relation => 
    //       relation.person_info.verification_status === "ACCEPTED"
    //     ));
    //     await setOwnerRejected(businessRelationsResponse?.payload?.relationShips?.some(relation => 
    //       relation.person_info.verification_status === "REJECTED"
    //     ));

    //     await setAccountCreated(
    //       [APPLICATION_STATUS.APPROVED].includes(
    //         dataApplication?.payload?.verification_status
    //       ) &&
    //       dataApplication?.payload?.otz_account_id
    //     )

    //     const subscriptionResponse = currentSubscriptionPlan?.payload;
    //     if(accountCreated && dataApplication?.payload?.verification_status === "ACCEPTED" && verifiedOwners && subscriptionResponse?.data !== null) {
    //       setRoute("/app")
    //       setLoading(false)
    //     }

    //     if(minimalView || (dataApplication?.payload?.verification_status && dataApplication?.payload?.verification_status !== "ACCEPTED") || subscriptionResponse?.data === null) {
    //       setRoute("/onboarding/dashboard")
    //       setLoading(false)
    //     }
    
    //     if((!verifiedOwners || ownerRejected) && accountCreated && dataApplication?.payload?.verification_status === "ACCEPTED") {
    //       setRoute("/onboarding/application/success")
    //       setLoading(false)

    //       setOpenKeys(["home"]);
    //       setSelectedKeys(["home"]);
    //     }

    //     if((!verifiedOwners || ownerRejected) && (verifiedOwners !== undefined) && (ownerRejected !== undefined) && dataApplication?.payload?.verification_status === "ACCEPTED") {
    //       setRoute("/onboarding/application/application-denied")
    //       setLoading(false)

    //       setOpenKeys(["home"]);
    //       setSelectedKeys(["home"]);
    //     }

    //     console.log(dataApplication?.payload, route, minimalView, verifiedOwners, ownerRejected, accountCreated);
    //   } catch (error) {
    //     console.error("Error fetching business relations:", error);
    //     // Handle error appropriately
    //   }
    // };

    // Call the async function
    // fetchBusinessRelations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname/*, loadingApplication*/, verifiedOwners, ownerRejected, accountCreated]);

  // const homeRoute = () => {
  //   const subscriptionResponse = currentSubscriptionPlan?.payload;
  //   return minimalView || application?.verification_status !== "ACCEPTED" || subscriptionResponse?.data === null
  // }

  return (
    <div className={classNames.sidebar}>
      <div className={classNames.logo}>
        <img src={logo} alt="logo" width={"100%"} />
      </div>

      <Menu
        mode="inline"
        className={classNames.menu}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Home", path: "" },
              parent: "home",
            })
          }
          key="home"
          icon={<RiHome7Line size={16} />}
          hidden={hideMenuItem(
            "home",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={loading}
        >
          <NavLink 
            to={`/onboarding/dashboard`}
            // {Below line is before synctera}
            // to={route}
            activeClassName="selectedRouteClass"  // This class will style the active link
            isActive={(match, location) => {
              // If minimalView is true and the current location is /onboarding/dashboard, consider this link active
              if (minimalView && location.pathname === '/onboarding/dashboard') {
                handleTitleClick({
                  ...{ item: "Home", path: "" },
                  parent: "home",
                })
              }
              // Otherwise, rely on the default matching behavior
              return match;
            }}
          >
            Home
          </NavLink>
        </Menu.Item>
        {/* <SubMenu
          key="business-banking"
          icon={<RiBankLine size={16} />}
          title="Banking"
          onClick={() =>
            handleTitleClick({
              ...{ item: "Banking", path: "banking" },
              parent: "business-banking",
            })
          }
          hidden={hideMenuItem(
            "banking",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
        >
          {businessBankingMenuItems.map((menuItem) => 
          {
          const currentPlanDetails = currentSubscriptionPlan?.payload?.data;   
          return (
            <Menu.Item
              key={menuItem.item
                .toLowerCase()
                .replace(" ", "-")
                .replace("&", "")}
              style={{ paddingLeft: 50, color: "#1890ff" }}
              onClick={() =>
                handleTitleClick({ ...menuItem, parent: "business-banking" })
              }
              hidden={hideMenuItem(
                menuItem.path,
                currentUserData?.signInUserSession?.idToken?.payload?.roleName
              )}
              disabled={!currentPlanDetails?.banking?.bank_account || minimalView}
            >
              <NavLink
                to={`business-banking/${menuItem.path}`}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {menuItem.item}
              </NavLink>
            </Menu.Item>
          )})}
        </SubMenu> */}
        <SubMenu
          key="receivables"
          icon={<RiUserReceived2Line size={16} />}
          title="Receivables"
        >
          {receivablesMenuItems.map((menuItem) => {
            const currentPlanDetails = currentSubscriptionPlan?.payload?.data;
            return (
              <Menu.Item
                disabled={!currentPlanDetails?.receivable?.[menuItem.key]}
                key={menuItem.item.toLowerCase().replace(" ", "-")}
                onClick={() =>
                  handleTitleClick({ ...menuItem, parent: "receivables" })
                }
                style={{ paddingLeft: 50 }}
                hidden={hideMenuItem(
                  menuItem.path,
                  currentUserData?.signInUserSession?.idToken?.payload?.roleName
                )}
              >
                <NavLink
                  to={
                    !currentPlanDetails?.receivable?.[menuItem.key]
                      ? ""
                      : `/app/receivables/${
                          // !invoiceStatus && 
                          menuItem.path === "invoices"
                            ? "invoices-getting-started"
                            : menuItem.path
                        }`
                  }
                >
                  {menuItem.item}
                  {menuItem.isPaid ? (
                    <div className={classNames.topicProIconBackdrop}>
                      <div>
                        <RiVipCrownLine size={18} />
                        <span>Pro</span>
                      </div>
                    </div>
                  ) : null}
                </NavLink>
              </Menu.Item>
            );
          })}
        </SubMenu>
        {/*<SubMenu
          key="payables"
          icon={<RiUserReceived2Line size={16} />}
          title="Payables"
        >
          {payablesMenuItems.map((menuItem) => {

            const currentPlanDetails = currentSubscriptionPlan?.payload?.data;
            const key = menuItem.key === "payables" ? "account_payable" : menuItem.key 
            return (
              <Menu.Item
                disabled={!currentPlanDetails?.payable?.[key] || minimalView}
                key={menuItem.item.toLowerCase().replace(" ", "-")}
                onClick={() =>
                  handleTitleClick({ ...menuItem, parent: "payables" })
                }
                style={{ paddingLeft: 50 }}
                hidden={hideMenuItem(
                  menuItem.path,
                  currentUserData?.signInUserSession?.idToken?.payload?.roleName
                )}
              >
                <NavLink
                  to={
                    // !currentPlanDetails?.payable?.[key]? ""
                      // : `receivables/${
                          // !invoiceStatus && menuItem.path === "invoices"
                            // ? "invoices-getting-started"
                            // : menuItem.path
                        // }`
                      // : `receivables/${menuItem.path}`
                    `/app/payables/${menuItem.path}`
                  }
                >
                  {menuItem.item}
                  {menuItem.isPaid ? (
                    <div className={classNames.topicProIconBackdrop}>
                      <div>
                        <RiVipCrownLine size={18} />
                        <span>Pro</span>
                      </div>
                    </div>
                  ) : null}
                </NavLink>
              </Menu.Item>
            );
          })}
        </SubMenu> */}
        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Reports", path: "reports" },
              parent: "reports",
            })
          }
          key="reports"
          icon={<RiPieChart2Line size={16} />}
          hidden={hideMenuItem(
            "reports",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={homeRoute()}
        >
          <NavLink to={`/app/reports/overview`}>Reports</NavLink>
        </Menu.Item> */}
        <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Settings", path: "settings" },
              parent: "settings",
            })
          }
          key="settings"
          icon={<RiSettings3Line size={16} />}
          hidden={hideMenuItem(
            "settings",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={minimalView}
        >
          {/* <NavLink to={`settings`}>Settings</NavLink> */}
          <NavLink to={minimalView ? `/app/settings` : `settings`}>Settings</NavLink>
        </Menu.Item>
        <Menu.Item key="ask-button" style={{display: "none"}}>
          <Button className={minimalView ? classNames.aibotbtnDisabled : classNames.aibotbtn} disabled={minimalView} onClick={() => {
              // window.open(`http://ec2-52-91-35-16.compute-1.amazonaws.com:8501/?tenant_id=${userData?.tenantId}`)
              handleTitleClick({
                ...{ item: "Ask Otterz AI", path: "home" },
                parent: "otterz-ai",
              })
              navigate(ROUTES.APP.ASK_OTTERZ_AI.HOME)
            }}>
            <svg style={{margin: "2px"}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4742_91846)">
              <path d="M2.8815 8.5H10V2.5H2V9.1925L2.8815 8.5ZM3.2275 9.5L1 11.25V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H10.5C10.6326 1.5 10.7598 1.55268 10.8536 1.64645C10.9473 1.74021 11 1.86739 11 2V9C11 9.13261 10.9473 9.25979 10.8536 9.35355C10.7598 9.44732 10.6326 9.5 10.5 9.5H3.2275Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_4742_91846">
              <rect width="12" height="12" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            Ask Otterz AI 
          </Button>
        </Menu.Item>
        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Rewards", path: "rewards" },
              parent: "rewards",
            })
          }
          key="rewards"
          icon={<RiGiftLine size={16} />}
          hidden={hideMenuItem(
            "rewards",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
        >
          <NavLink to={`rewards/overview`}>Rewards</NavLink>
        </Menu.Item> */}
      </Menu>
      
      <div className={classNames.footer}>
        <Menu mode="inline" className={classNames.profileMenu}>
          {minimalView ? (
            // Render the logout button directly when minimalView is true
            <Menu.Item>
              {/* Assuming you have a logout function, you can bind it to the onClick event */}
              {menu}
            </Menu.Item>
          ) : (
            // Render the SubMenu with the avatar when minimalView is false
            <SubMenu
              icon={
                <div>
                  <Avatar
                    size={34}
                    src={`${
                      `${getUserByIdQuery?.data.imageUrl}` || user
                    }?${uuidv4()}`}
                    className={classNames.profilePic}
                  />
                </div>
              }
              title={
                <div className={classNames.profileContent}>
                  <div className={classNames.name}>{application?.username}</div>
                </div>
              }
            >
              {menu}
            </SubMenu>
          )}
        </Menu>
      </div>

    </div>
  );
}
