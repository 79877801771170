import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { Radio, Space, Card, Tabs, Spin } from "antd";
import moment from "moment";

import api from "../../API";
import {
  centsToDollar,
  intToDecimalDollar,
} from "../../util/functions/dollarConvertion";
import { Header } from "../../common";
import TabHeader from "./TabHeader/TabHeader";
import TotalOutgoingInvoices from "./TotalOutgoingInvoices/TotalOutgoingInvoices";
import TotalAcceptanceTransaction from "./TotalAcceptanceTransactions/TotalAcceptanceTransactions";
import NoOfUniqueCustomers from "./NoOfUniqueCustomers/NoOfUniqueCustomers";
import TotalCardSpend from "./TotalCardSpend/TotalCardSpend";
import UpcomingScheduledPayments from "./UpcomingScheduledPayments/UpcomingScheduledPayments";
import TotalDueInvoices from "./TotalDueInvoices/TotalDueInvoices";
import BankAccountBalance from "./BankAccountBalance/BankAccountBalance";
import NetCashflow from "./NetCashflow/NetCashflow";
import TotalIncomingPayments from "./TotalIncomingPayments/TotalIncomingPayments";
import TotalOutgoingPayments from "./TotalOutgoingPayments/TotalOutgoingPayments";
import { isPaymentOnPast } from "../../BusinessBanking/MakePayments/helpers/isPaymentOnPast";
import graphData from "../../data/reportsPAGraph";
import usePAActiveStatus from "../../../hooks/usePAActiveStatus";
import useLanguage from "../../../hooks/useLanguage";
import messages from "./messages.const";
import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import { DEFAULT_AMOUNT, FORMATS } from "../../constants";
import { AppContext } from "../../../context/AppState";
import { isBusinessType } from "../../util/functions/onboarding";
import classNames from "./ReportsOverview.module.scss";

const ReportsOverview = () => {
  const { TabPane } = Tabs;

  // localization
  const language = useLanguage(messages);
  const { application } = useContext(AppContext);
  // following ref used to capture the height and width of the parent div
  const ref = useRef(null);
  // height and width state attributes used to overcome the issue raised while switching
  // between the widget.
  // Issue id: https://otterz1.atlassian.net/browse/OTT-3784
  // height and width sets on XYPlot (graph according to
  // the tabs parent div's(line 1495 / classname: tabsWrapper) width and height )
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [cardwiseSpentObj, setCardwiseSpentObj] = useState({});
  const [cardWiseSpentQuery, setCardWiseSpentQuery] = useState([]);
  const [scheduledPaymentData, setScheduledPaymentsData] = useState([]);

  // period state variable contain the value of the datePicker
  const [period, setPeriod] = useState("day");

  // mainFilter state variable contains the value of main nav button on the top
  const [mainFilter, setMainButton] = useState("income");

  // tab state to find the user's current tab
  // used to hide filters for PA tab
  // Commenting below line due to Year issue for future use
  // Ticket: https://otterz1.atlassian.net/browse/OTT-3863  */}
  // const [tab, setTab] = useState();

  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const monthStartDate = new Date(
    new Date(nextDay).setMonth(new Date().getMonth() - 1)
  );
  const queryParams = {
    otz_account_id: application?.otz_account_id,
    type: "card",
    from_date: moment(monthStartDate).format(FORMATS.API_SEND_FORMAT),
  };

  // dates to filter invoices by period
  const tomorrowDate = moment().add(1, "days").format(FORMATS.API_OTHER_FORMAT);

  // invoicesForThePeriodArray stores data according to the mainFilter and period vars
  let invoicesForThePeriodArray = [];

  // invoicesForThePeriodArray stores data according to the mainFilter and period vars
  let dueInvoicesForThePeriodArray = [];

  // upcomingScheduledPaymentsForThePeriodArray stores data according to the mainFilter and period vars
  let upcomingScheduledPaymentsForThePeriodArray = [];

  // cardSpentForThePeriodArray stores data according to the mainFilter and period vars
  let cardSpentForThePeriodArray = [];

  // dataArrayForTotalCardSpent contain the graph data for total outgoing invoices calculated at line 98
  let dataArrayForTotalCardSpent = [];

  // categoryArray contains categorized data by merchantType of all card transactions
  let categoryArray = [];

  let incomingPayments = [];
  let outgoingPayments = [];
  let accountBalances = [];
  let netCashFlow = [];

  // otherCategoryArray contains categorized data by merchantType of all card transactions.
  // this excludes top two categories
  // let otherCategoryArray = [];

  // dataArrayForTotalOutgoingInvoices contain the graph data for total outgoing invoices calculated at line 98
  let dataArrayForTotalOutgoingInvoices = [];

  // dataArrayForNumberOfUniqueCustomers contain the graph data for total outgoing invoices calculated at line 98
  let dataArrayForNumberOfUniqueCustomers = [];

  // dataArrayForTotalDueInvoices contain the graph data for total outgoing invoices calculated at line 98
  let dataArrayForTotalDueInvoices = [];

  // dataArrayForUpcomingScheduledPayments contain the graph data for total outgoing invoices calculated at line 98
  let dataArrayForUpcomingScheduledPayments = [];

  let dataInComingPayments = [];
  let dataOutgoingPayments = [];
  let dataAccountBalances = [];
  let dataNetCashFlowArray = [];

  // PA module status
  const { status: paStatus } = usePAActiveStatus();

  // Invoice module status
  const { status: invoiceModuleStatus } = useInvoiceActiveStatus();

  // fetch current user data
  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  // fetch invoice data
  const { data: invoiceQuery, isLoading: isLoadingInvoices } = useQuery(
    "getInvoices",
    () =>
      api.Receivables.Invoices.getInvoices(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  // fetch customers data
  const { data: customersQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  // fetch PA reports data
  const { data: paReportsQuery, isLoading: isLoadingPaQuery } = useQuery(
    "getPAReports",
    () => api.Receivables.PaymentAcceptanceTransactions.reports(),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );
  // const paReportsData = paReportsQuery?.data || {};
  const paSummary = paReportsQuery?.data || {};
  const summary = paSummary?.summary;

  // Get all transactions for cards
  const { data: cardQuery } = useQuery(
    "getCardTransactions",
    () => api.BusinessBanking.getTransactions(queryParams),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isCardSpendLoading } = useQuery(
    "cardwiseSpendTransaction",
    () =>
      api.BusinessBanking.getCategorywiseCardSpend({
        otz_account_id: application?.otz_account_id,
      }),
    {
      onSuccess: (data) => setCardWiseSpentQuery(data?.payload),
      refetchOnWindowFocus: false,
    }
  );

  const getScheduledPayments = useMutation(
    "getSchedulePayment",
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    { retry: false }
  );

  const { data: bankData, isLoading: isBankDataLoading } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: application?.otz_account_id,
      })
  );

  const cardData = cardQuery?.payload || [];

  const invoices =
    invoiceQuery && invoiceQuery.data && Array.isArray(invoiceQuery.data)
      ? invoiceQuery.data
      : [];

  // sort invoices array by date
  const sortedInvoiceArray = Array.isArray(invoices)
    ? invoices.sort((a, b) => {
        return (
          new Date(moment(a.invoiceDate).toLocaleString()).getTime() -
          new Date(moment(b.invoiceDate).toLocaleString()).getTime()
        );
      })
    : [];

  // filter invoices with due payments
  const dueInvoicesArray =
    sortedInvoiceArray && sortedInvoiceArray.length > 0
      ? sortedInvoiceArray.filter((value) => value.invoiceStatus === "sent")
      : [];

  const upcomingScheduledPaymentsArray =
    (scheduledPaymentData &&
      scheduledPaymentData?.length > 0 &&
      scheduledPaymentData
        .filter((value) => moment(value.schedule.start_date).isValid())
        .filter((item) => {
          if (isPaymentOnPast(item.schedule.start_date)) {
            return false;
          } else return item;
        })
        .sort((a, b) => {
          return (
            new Date(moment(a.schedule.start_date).toLocaleString()).getTime() -
            new Date(moment(b.schedule.start_date).toLocaleString()).getTime()
          );
        })) ||
    [];

  function calculateIncomeData() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalOutgoingInvoicesGraph = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };
      // calculate data for Y axis in Total outgoing invoices
      const calculateYForTotalOutgoingInvoicesGraph = (
        index,
        period,
        startDay
      ) => {
        invoicesForThePeriodArray = sortedInvoiceArray.filter((value, i) => {
          let invoiceDateMoment = moment(value.invoiceDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          return moment(invoiceDateMoment).isBetween(
            moment()
              .startOf(`${period}`)
              .add(-1, "days")
              .subtract(9, `${period}s`)
              .format(FORMATS.API_OTHER_FORMAT),
            moment()
              .endOf(`${period}`)
              .add(1, "days")
              .format(FORMATS.API_OTHER_FORMAT),
            "day"
          );
        });

        // invoiceTotalForTheYValue contains data for Y axis
        let invoiceTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceTotalForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => value.invoiceDate === dateToFilter)
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          invoiceTotalForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceTotalForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceTotalForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        return invoiceTotalForTheYValue;
      };

      // calculate x,y and z values for dataArrayForTotalOutgoingInvoices
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(index, period);
        dataArrayForTotalOutgoingInvoices.push({
          // x is time intervals
          x: calculateXForTotalOutgoingInvoicesGraph(
            moment().startOf("day").subtract(index, period)
          ),
          // y is invoice amount
          y:
            calculateYForTotalOutgoingInvoicesGraph(index, period, startDay) ||
            0,
        });
      }
      dataArrayForTotalOutgoingInvoices.reverse();

      // calculate data for dataArrayForPATransactions
      // let paArray =
      //   period === "day"
      //     ? paReportsData?.salesByDays
      //     : period === "week"
      //     ? paReportsData?.salesByWeeks
      //     : period === "month"
      //     ? paReportsData?.salesByMonths
      //     : paReportsData?.salesByYear;

      // calculate data for Y axis in # Unique customers
      const calculateYForNumberOfUniqueCustomers = (
        index,
        period,
        startDay
      ) => {
        // numberOfUniqueCustomersForTheYValue contains data for Y axis
        let numberOfUniqueCustomersForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format(
            FORMATS.API_OTHER_FORMAT
          );
          numberOfUniqueCustomersForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => value.invoiceDate === dateToFilter)
              .map((value) => value.receiverName)
              .filter((value, index, array) => array.indexOf(value) === index)
              .length;
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          numberOfUniqueCustomersForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.receiverName)
              .filter((value, index, array) => array.indexOf(value) === index)
              .length;
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          numberOfUniqueCustomersForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.receiverName)
              .filter((value, index, array) => array.indexOf(value) === index)
              .length;
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          numberOfUniqueCustomersForTheYValue =
            Array.isArray(invoicesForThePeriodArray) &&
            invoicesForThePeriodArray.length > 0 &&
            invoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.receiverName)
              .filter((value, index, array) => array.indexOf(value) === index)
              .length;
        }
        return numberOfUniqueCustomersForTheYValue;
      };

      // calculate x,y and z values for dataArrayForNumberOfUniqueCustomers
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(index, period);
        let obj = {
          // x is time intervals
          x: calculateXForTotalOutgoingInvoicesGraph(
            moment().startOf("day").subtract(index, period)
          ),
          // y is # of unique customers
          y: calculateYForNumberOfUniqueCustomers(index, period, startDay) || 0,
        };

        dataArrayForNumberOfUniqueCustomers.push(obj);
      }
      dataArrayForNumberOfUniqueCustomers.reverse();
    }
    calculateArray();
  }

  const handleScheduledPayments = () => {
    const body = {
      ...(isBusinessType(application?.type)
        ? { otz_business_id: application?.otz_business_id }
        : { otz_person_id: application?.otz_person_id }),
      is_recurring: 0,
    };

    getScheduledPayments.mutate(body, {
      onSuccess: (res) => {
        setScheduledPaymentsData(res.payload);
      },
    });
  };

  // calculateIncomeData() triggers when user select income button
  if (mainFilter === "income" && invoices) {
    calculateIncomeData(invoices);
  }

  // calculate data for due payments graph
  function calculateCashflowDuePaymentsData() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalDuePayments = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis in Total due invoices
      const calculateYForTotalDueInvoices = (index, period, startDay) => {
        dueInvoicesForThePeriodArray = dueInvoicesArray.filter((value, i) => {
          let invoiceDateMoment = moment(value.invoiceDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          return moment(invoiceDateMoment).isBetween(
            moment()
              .startOf(`${period}`)
              .add(-1, "days")
              .subtract(9, `${period}s`)
              .format(FORMATS.API_OTHER_FORMAT),
            moment()
              .endOf(`${period}`)
              .add(1, "days")
              .format(FORMATS.API_OTHER_FORMAT),
            "day"
          );
        });

        // invoiceDueTotalForTheYValue contains data for Y axis
        let invoiceDueTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceDueTotalForTheYValue =
            Array.isArray(dueInvoicesForThePeriodArray) &&
            dueInvoicesForThePeriodArray.length > 0 &&
            dueInvoicesForThePeriodArray
              .filter((value) => value.invoiceDate === dateToFilter)
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          invoiceDueTotalForTheYValue =
            Array.isArray(dueInvoicesForThePeriodArray) &&
            dueInvoicesForThePeriodArray.length > 0 &&
            dueInvoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceDueTotalForTheYValue =
            Array.isArray(dueInvoicesForThePeriodArray) &&
            dueInvoicesForThePeriodArray.length > 0 &&
            dueInvoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          invoiceDueTotalForTheYValue =
            Array.isArray(dueInvoicesForThePeriodArray) &&
            dueInvoicesForThePeriodArray.length > 0 &&
            dueInvoicesForThePeriodArray
              .filter((value) => {
                return moment(value.invoiceDate).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => value.total)
              .reduce((a, b) => a + b, 0);
        }
        return invoiceDueTotalForTheYValue;
      };

      // calculate x,y and z values for dataArrayForTotalDueInvoices
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(index, period);
        dataArrayForTotalDueInvoices.push({
          // x is time intervals
          x: calculateXForTotalDuePayments(
            moment().startOf("day").subtract(index, period)
          ),
          // y is invoice amount
          y: calculateYForTotalDueInvoices(index, period, startDay) || 0,
        });
      }
      dataArrayForTotalDueInvoices.reverse();
    }
    calculateArray();
  }
  if (mainFilter === "cashflow") {
    calculateCashflowDuePaymentsData();
    calculateIncomeData();
  }

  // calculate data for upcoming scheduled payments graph
  function calculateUpcomingScheduledPayments() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForupcominScheduledPayments = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis in Total due payments
      const calculateYForUpcomingScheduledPayments = (
        index,
        period,
        startDay
      ) => {
        upcomingScheduledPaymentsForThePeriodArray =
          Array.isArray(upcomingScheduledPaymentsArray) &&
          upcomingScheduledPaymentsArray.length > 0 &&
          upcomingScheduledPaymentsArray.filter((value, i) => {
            let upcomingScheduledPaymentDateMoment = moment(
              value.schedule.start_date
            ).format(FORMATS.API_OTHER_FORMAT);
            return moment(upcomingScheduledPaymentDateMoment).isBetween(
              moment().format(FORMATS.API_OTHER_FORMAT),
              moment()
                .subtract(-9, `${period}s`)
                .endOf(`${period}`)
                .add(1, "days")
                .format(FORMATS.API_OTHER_FORMAT),
              "day"
            );
          });
        // upcomingScheduledPaymentsTotalForTheYValue contains data for Y axis
        let upcomingScheduledPaymentsTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("YYYY-MM-DD");
          upcomingScheduledPaymentsTotalForTheYValue =
            Array.isArray(upcomingScheduledPaymentsForThePeriodArray) &&
            upcomingScheduledPaymentsForThePeriodArray.length > 0 &&
            upcomingScheduledPaymentsForThePeriodArray
              .filter((value) => value.schedule.start_date === dateToFilter)
              .map((value) => Number(value.payment_instruction.request.amount))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format("YYYY-MM-DD");
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format("YYYY-MM-DD");
          upcomingScheduledPaymentsTotalForTheYValue =
            Array.isArray(upcomingScheduledPaymentsForThePeriodArray) &&
            upcomingScheduledPaymentsForThePeriodArray.length > 0 &&
            upcomingScheduledPaymentsForThePeriodArray
              .filter((value) => {
                return moment(value.schedule.start_date).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.payment_instruction.request.amount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format("YYYY-MM-DD");
          const lastDateToFilter = moment(lastDate).format("YYYY-MM-DD");
          upcomingScheduledPaymentsTotalForTheYValue =
            Array.isArray(upcomingScheduledPaymentsForThePeriodArray) &&
            upcomingScheduledPaymentsForThePeriodArray.length > 0 &&
            upcomingScheduledPaymentsForThePeriodArray
              .filter((value) => {
                return moment(value.schedule.start_date).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.payment_instruction.request.amount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format("YYYY-MM-DD");
          const lastDateToFilter = moment(lastDate).format("YYYY-MM-DD");
          upcomingScheduledPaymentsTotalForTheYValue =
            Array.isArray(upcomingScheduledPaymentsForThePeriodArray) &&
            upcomingScheduledPaymentsForThePeriodArray.length > 0 &&
            upcomingScheduledPaymentsForThePeriodArray
              .filter((value) => {
                return moment(value.schedule.start_date).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.payment_instruction.request.amount))
              .reduce((a, b) => a + b, 0);
        }
        return upcomingScheduledPaymentsTotalForTheYValue;
      };

      // calculate x,y and z values for dataArrayForUpcomingScheduledPayments
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(-index), period);
        dataArrayForUpcomingScheduledPayments.push({
          // x is time intervals
          x: calculateXForupcominScheduledPayments(
            moment().startOf("day").subtract(Number(-index), period)
          ),
          // y is invoice amount
          y:
            calculateYForUpcomingScheduledPayments(index, period, startDay) ||
            0,
        });
      }
    }
    calculateArray();
  }

  // calculate data for total card spent
  function calculateTotalCardSpent() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalCardSpent = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis in Total due payments
      const calculateYForTotalCardSpent = (index, period, startDay) => {
        cardSpentForThePeriodArray =
          Array.isArray(cardData) &&
          cardData.length > 0 &&
          cardData.filter((value, i) => {
            let totalCardSpentDateMoment = moment(value.date).format(
              FORMATS.API_OTHER_FORMAT
            );
            return moment(totalCardSpentDateMoment).isBetween(
              moment()
                .subtract(10, `${period}s`)
                .format(FORMATS.API_OTHER_FORMAT),
              tomorrowDate,
              "day"
            );
          });

        // upcomingScheduledPaymentsTotalForTheYValue contains data for Y axis
        let cardSpentTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("MM-DD-YYYY");
          cardSpentTotalForTheYValue =
            Array.isArray(cardSpentForThePeriodArray) &&
            cardSpentForThePeriodArray.length > 0 &&
            cardSpentForThePeriodArray
              .filter(
                (value) =>
                  moment(value.date).format("MM-DD-YYYY") === dateToFilter
              )
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          cardSpentTotalForTheYValue =
            Array.isArray(cardSpentForThePeriodArray) &&
            cardSpentForThePeriodArray.length > 0 &&
            cardSpentForThePeriodArray
              .filter((value) => {
                let cardSpentDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(cardSpentDateMoment).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          cardSpentTotalForTheYValue =
            Array.isArray(cardSpentForThePeriodArray) &&
            cardSpentForThePeriodArray.length > 0 &&
            cardSpentForThePeriodArray
              .filter((value) => {
                let cardSpentDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  cardSpentDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          cardSpentTotalForTheYValue =
            Array.isArray(cardSpentForThePeriodArray) &&
            cardSpentForThePeriodArray.length > 0 &&
            cardSpentForThePeriodArray
              .filter((value) => {
                let cardSpentDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  cardSpentDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        // divide calculated Y value by 100 to convert cents
        return Number(cardSpentTotalForTheYValue / 100);
      };

      // calculate x,y and z values for dataArrayForTotalCardSpent
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(index), period);
        dataArrayForTotalCardSpent.push({
          // x is time intervals
          x: calculateXForTotalCardSpent(
            moment().startOf("day").subtract(Number(index), period)
          ),
          // y is invoice amount
          y: calculateYForTotalCardSpent(index, period, startDay) || 0,
        });
      }
    }
    calculateArray();
    dataArrayForTotalCardSpent.reverse();

    // filters unique mechants by merchantType attribute
    const uniqueMerchantArray =
      (cardSpentForThePeriodArray.length > 0 &&
        cardSpentForThePeriodArray
          .map((value) => value.merchantType)
          .filter((value, index, array) => array.indexOf(value) === index)) ||
      [];

    // construct array on categoryArray attribute to categorize data by merchant type
    for (let index = 0; index < uniqueMerchantArray.length; index++) {
      const element = uniqueMerchantArray[index];
      const filteredArray =
        cardSpentForThePeriodArray.filter(
          (value) => value.merchantType === element
        ) || [];
      const sumOfFilteredArray =
        Array.isArray(filteredArray) &&
        filteredArray.map((value) => value.debit).reduce((a, b) => a + b, 0);
      categoryArray.push({
        merchantType: element,
        sum: sumOfFilteredArray,
        array: filteredArray,
      });
    }

    categoryArray.sort((a, b) => b.sum - a.sum);
    // assigning category data to otherCategoryArray for the calculation of "others" sub field
    // in TotalCardSpend graph except top two categories

    // otherCategoryArray = categoryArray.filter((value, index) => index > 1);
  }

  if (mainFilter === "expenses") {
    calculateUpcomingScheduledPayments();
    calculateTotalCardSpent();
  }

  // Calculate incoming funds
  function calculateIncomingFunds() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalIncomingFunds = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis
      const calculateYForTotalIncomingFunds = (index, period, startDay) => {
        incomingPayments =
          Array.isArray(bankData?.payload?.data) &&
          bankData?.payload?.data?.length > 0 &&
          bankData?.payload?.data?.filter((value, i) => {
            let totalIncomingFundsDateMoment = moment(value.date).format(
              FORMATS.API_OTHER_FORMAT
            );
            return moment(totalIncomingFundsDateMoment).isBetween(
              moment()
                .subtract(10, `${period}s`)
                .format(FORMATS.API_OTHER_FORMAT),
              tomorrowDate,
              "day"
            );
          });

        // incomingFundsTotalForTheYValue contains data for Y axis
        let incomingFundsTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("MM-DD-YYYY");
          incomingFundsTotalForTheYValue =
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              .filter(
                (value) =>
                  moment(value.date).format("MM-DD-YYYY") === dateToFilter
              )
              .map((value) => Number(value.creditAmount))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          incomingFundsTotalForTheYValue =
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              .filter((value) => {
                let incomingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(incomingFundDateMoment).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.creditAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          incomingFundsTotalForTheYValue =
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              .filter((value) => {
                let incomingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  incomingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.creditAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          incomingFundsTotalForTheYValue =
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              .filter((value) => {
                let incomingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  incomingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.creditAmount))
              .reduce((a, b) => a + b, 0);
        }
        // divide calculated Y value by 100 to convert cents
        return Number(incomingFundsTotalForTheYValue / 100);
      };

      // calculate x,y and z values
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(index), period);
        dataInComingPayments.push({
          // x is time intervals
          x: calculateXForTotalIncomingFunds(
            moment().startOf("day").subtract(Number(index), period)
          ),
          // y is invoice amount
          y: calculateYForTotalIncomingFunds(index, period, startDay) || 0,
        });
      }
    }
    calculateArray();
  }

  // Calculate outgoing funds

  function calculateOutgoingFunds() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalOutGoingFunds = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis
      const calculateYForTotalOutgoingFunds = (index, period, startDay) => {
        outgoingPayments =
          Array.isArray(bankData?.payload?.data) &&
          bankData?.payload?.data?.length > 0 &&
          bankData?.payload?.data?.filter((value, i) => {
            let totalOutgoingFundsDateMoment = moment(value.date).format(
              FORMATS.API_OTHER_FORMAT
            );
            return moment(totalOutgoingFundsDateMoment).isBetween(
              moment()
                .subtract(10, `${period}s`)
                .format(FORMATS.API_OTHER_FORMAT),
              tomorrowDate,
              "day"
            );
          });

        // outgoingFundsTotalForTheYValue contains data for Y axis
        let outgoingFundsTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("MM-DD-YYYY");
          outgoingFundsTotalForTheYValue =
            Array.isArray(outgoingPayments) &&
            outgoingPayments.length > 0 &&
            outgoingPayments
              .filter(
                (value) =>
                  moment(value.date).format("MM-DD-YYYY") === dateToFilter
              )
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          outgoingFundsTotalForTheYValue =
            Array.isArray(outgoingPayments) &&
            outgoingPayments.length > 0 &&
            outgoingPayments
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(outgoingFundDateMoment).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          outgoingFundsTotalForTheYValue =
            Array.isArray(outgoingPayments) &&
            outgoingPayments.length > 0 &&
            outgoingPayments
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  outgoingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          outgoingFundsTotalForTheYValue =
            Array.isArray(outgoingPayments) &&
            outgoingPayments.length > 0 &&
            outgoingPayments
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  outgoingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        // divide calculated Y value by 100 to convert cents
        return Number(outgoingFundsTotalForTheYValue / 100);
      };

      // calculate x,y and z values
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(index), period);
        dataOutgoingPayments.push({
          // x is time intervals
          x: calculateXForTotalOutGoingFunds(
            moment().startOf("day").subtract(Number(index), period)
          ),
          // y is invoice amount
          y: calculateYForTotalOutgoingFunds(index, period, startDay) || 0,
        });
      }
    }
    calculateArray();
  }

  // Bank Account Balance

  function calculateBankAccountBalances() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalAccountBalances = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis
      const calculateYForTotalAccountBalances = (index, period, startDay) => {
        accountBalances =
          Array.isArray(bankData?.payload?.data) &&
          bankData?.payload?.data?.length > 0 &&
          bankData?.payload?.data?.filter((value, i) => {
            let totalAccountBalanceDateMoment = moment(value.date).format(
              FORMATS.API_OTHER_FORMAT
            );
            return moment(totalAccountBalanceDateMoment).isBetween(
              moment()
                .subtract(10, `${period}s`)
                .format(FORMATS.API_OTHER_FORMAT),
              tomorrowDate,
              "day"
            );
          });

        // accountBalancesTotalForTheYValue contains data for Y axis
        let accountBalancesTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("MM-DD-YYYY");
          accountBalancesTotalForTheYValue =
            Array.isArray(accountBalances) &&
            accountBalances.length > 0 &&
            accountBalances
              .filter(
                (value) =>
                  moment(value.date).format("MM-DD-YYYY") === dateToFilter
              )
              .map((value) => Number(value.balance))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          accountBalancesTotalForTheYValue =
            Array.isArray(accountBalances) &&
            accountBalances.length > 0 &&
            accountBalances
              .filter((value) => {
                let accountBalancesDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(accountBalancesDateMoment).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.balance))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          accountBalancesTotalForTheYValue =
            Array.isArray(accountBalances) &&
            accountBalances.length > 0 &&
            accountBalances
              .filter((value) => {
                let accountBalancesDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  accountBalancesDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.balance))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          accountBalancesTotalForTheYValue =
            Array.isArray(accountBalances) &&
            accountBalances.length > 0 &&
            accountBalances
              .filter((value) => {
                let accountBalancesDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  accountBalancesDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.balance))
              .reduce((a, b) => a + b, 0);
        }
        // divide calculated Y value by 100 to convert cents
        return Number(accountBalancesTotalForTheYValue / 100);
      };

      // calculate x,y and z values
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(index), period);
        dataAccountBalances.push({
          // x is time intervals
          x: calculateXForTotalAccountBalances(
            moment().startOf("day").subtract(Number(index), period)
          ),
          // y is invoice amount
          y: calculateYForTotalAccountBalances(index, period, startDay) || 0,
        });
      }
    }
    calculateArray();
  }

  // Calculate total cash flow

  function calculateNetCashFlow() {
    function calculateArray() {
      // calculate data for X axis
      const calculateXForTotalOutGoingFunds = (m) => {
        return period === "day"
          ? m?.format("M/DD")
          : period === "week"
          ? m?.startOf("week").format("M/DD")
          : period === "month"
          ? m?.format("MMM")
          : period === "year"
          ? m?.format("YYYY")
          : "";
      };

      // calculate data for Y axis
      const calculateYForTotalOutgoingFunds = (index, period, startDay) => {
        netCashFlow =
          Array.isArray(bankData?.payload?.data) &&
          bankData?.payload?.data?.length > 0 &&
          bankData?.payload?.data?.filter((value, i) => {
            let totalOutgoingFundsDateMoment = moment(value.date).format(
              FORMATS.API_OTHER_FORMAT
            );
            return moment(totalOutgoingFundsDateMoment).isBetween(
              moment()
                .subtract(10, `${period}s`)
                .format(FORMATS.API_OTHER_FORMAT),
              tomorrowDate,
              "day"
            );
          });

        // outgoingFundsTotalForTheYValue contains data for Y axis
        let outgoingFundsTotalForTheYValue;

        if (period === "day") {
          const dateToFilter = moment(startDay).format("MM-DD-YYYY");
          outgoingFundsTotalForTheYValue =
            Array.isArray(netCashFlow) &&
            netCashFlow.length > 0 &&
            netCashFlow
              .filter(
                (value) =>
                  moment(value.date).format("MM-DD-YYYY") === dateToFilter
              )
              .map((value) => Number(value.creditAmount - value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }

        if (period === "week") {
          const firstDateToFilter = moment(startDay)
            .startOf("week")
            .add(-1, "days")
            .format(FORMATS.API_OTHER_FORMAT);
          const lastDateToFilter = moment(startDay)
            .endOf("week")
            .add(1, "day")
            .format(FORMATS.API_OTHER_FORMAT);
          outgoingFundsTotalForTheYValue =
            Array.isArray(netCashFlow) &&
            netCashFlow.length > 0 &&
            netCashFlow
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(outgoingFundDateMoment).isBetween(
                  firstDateToFilter,
                  lastDateToFilter,
                  "day"
                );
              })
              .map((value) => Number(value.creditAmount - value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "month") {
          const monthNumber = moment(startDay).get("month");
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, monthNumber, 0);
          const lastDate = new Date(monthYear, monthNumber + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          outgoingFundsTotalForTheYValue =
            Array.isArray(netCashFlow) &&
            netCashFlow.length > 0 &&
            netCashFlow
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  outgoingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.creditAmount - value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        if (period === "year") {
          const monthYear = moment(startDay).get("year");
          const firstDate = new Date(monthYear, 0, 0);
          const lastDate = new Date(monthYear, 11 + 1, 1);
          const firstDateToFilter = moment(firstDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          const lastDateToFilter = moment(lastDate).format(
            FORMATS.API_OTHER_FORMAT
          );
          outgoingFundsTotalForTheYValue =
            Array.isArray(netCashFlow) &&
            netCashFlow.length > 0 &&
            netCashFlow
              .filter((value) => {
                let outgoingFundDateMoment = moment(value.date).format(
                  FORMATS.API_OTHER_FORMAT
                );
                return moment(
                  outgoingFundDateMoment,
                  FORMATS.API_OTHER_FORMAT
                ).isBetween(firstDateToFilter, lastDateToFilter, "day");
              })
              .map((value) => Number(value.creditAmount - value.debitAmount))
              .reduce((a, b) => a + b, 0);
        }
        // divide calculated Y value by 100 to convert cents
        return Number(outgoingFundsTotalForTheYValue / 100);
      };

      // calculate x,y and z values
      for (let index = 0; index < 10; index++) {
        let startDay = moment().startOf("day").subtract(Number(index), period);
        dataNetCashFlowArray.push({
          // x is time intervals
          x: calculateXForTotalOutGoingFunds(
            moment().startOf("day").subtract(Number(index), period)
          ),
          // y is invoice amount
          y: calculateYForTotalOutgoingFunds(index, period, startDay) || 0,
        });
      }
    }
    calculateArray();
  }

  if (mainFilter === "cashflow") {
    calculateIncomingFunds();
    calculateOutgoingFunds();
    calculateNetCashFlow();
    calculateBankAccountBalances();
  }

  const handleAccountBalance = () => {
    const transactionsList = bankData?.payload?.data;
    // Calculate average balance
    const averageBalance =
      transactionsList.reduce(
        (total, transaction) => total + transaction.balance,
        0
      ) / transactionsList.length;

    const balanceList = transactionsList.map(
      (transaction) => transaction.balance
    );
    // Find lowest balance
    const lowestBalance = Math.min(...balanceList);

    // Find highest balance
    const highestBalance = Math.max(...balanceList);

    return { lowestBalance, highestBalance, averageBalance };
  };

  useEffect(() => {
    handleScheduledPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // width calculated according to current
    // measurements of the parent
    // height calculated according to current
    // measurements of the parent
    setHeight(ref.current.offsetHeight * 0.5);
    setWidth(ref.current.offsetWidth * 0.65);
  }, [ref]);

  useEffect(() => {
    if (cardWiseSpentQuery.length > 0 && !isCardSpendLoading) {
      let spentMap = new Map();

      cardWiseSpentQuery.forEach((eachSpend) => {
        if (spentMap.has(eachSpend.enhanced_transaction)) {
          let calculateAmount =
            spentMap.get(eachSpend.enhanced_transaction) + eachSpend.amount;

          spentMap.set(eachSpend.enhanced_transaction, calculateAmount);
        } else {
          spentMap.set(eachSpend.enhanced_transaction, eachSpend.amount);
        }
      });

      spentMap = new Map([...spentMap.entries()].sort((a, b) => b[1] - a[1]));

      const spentMapArr = [...spentMap.entries()];

      if (spentMap.size === 1) {
        const categoryWiseSpendObj = {
          topCategoryOne: [[spentMapArr[0][0]], spentMapArr[0][1]],
          topCategoryTwo: ["Top Category 2", 0],
          Others: 0,
        };
        setCardwiseSpentObj(categoryWiseSpendObj);
      } else if (spentMap.size >= 2) {
        const categoryWiseSpendObj = {
          topCategoryOne: [[spentMapArr[0][0]], spentMapArr[0][1]],
          topCategoryTwo: [[spentMapArr[1][0]], spentMapArr[1][1]],
          Others: 0,
        };

        for (let i = 2; i < spentMapArr.length; i++) {
          categoryWiseSpendObj["Others"] += spentMapArr[i][1];
        }

        setCardwiseSpentObj(categoryWiseSpendObj);
      }
    }
  }, [cardWiseSpentQuery, isCardSpendLoading]);

  const getRefundPercentage = useCallback(
    (mode) => {
      const refundPercentage =
        ((summary?.[mode]?.refund?.total || 0) /
          (summary?.[mode]?.sale?.total || 1)) *
        100;
      return refundPercentage < 0 ? refundPercentage * -1 : refundPercentage;
    },
    [summary]
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Reports" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.filterRow}>
            <Radio.Group>
              <Space size="middle">
                <Radio.Button
                  name="tabs"
                  className={
                    mainFilter === "income"
                      ? classNames.tabRadioButtonFocused
                      : classNames.tabRadioButton
                  }
                  onClick={() => setMainButton("income")}
                >
                  Income
                </Radio.Button>
                <Radio.Button
                  name="tabs"
                  className={
                    mainFilter === "expenses"
                      ? classNames.tabRadioButtonFocused
                      : classNames.tabRadioButton
                  }
                  onClick={() => setMainButton("expenses")}
                >
                  Expenses
                </Radio.Button>
                {/* <Radio.Button
                  name="tabs"
                  className={
                    mainFilter === "cashflow"
                      ? classNames.tabRadioButtonFocused
                      : classNames.tabRadioButton
                  }
                  onClick={() => setMainButton("cashflow")}
                >
                  Cashflow
                </Radio.Button> */}
              </Space>
            </Radio.Group>
            <Card className={classNames.filtersCard}>
              <div className={classNames.filtersGroup}>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Space size={0}>
                    <Radio.Button onClick={() => setPeriod("day")} value="a">
                      D
                    </Radio.Button>
                    <Radio.Button onClick={() => setPeriod("week")} value="b">
                      W
                    </Radio.Button>
                    <Radio.Button onClick={() => setPeriod("month")} value="c">
                      M
                    </Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
            </Card>
          </div>
        </div>
        <div ref={ref} className={classNames.tabsWrapper}>
          <Tabs
            key="reportsTabs"
            type="card"
            animated={false}
            className={classNames.tabsHeaderWrapper}
            // Commenting below line due to Year issue for future use
            // Ticket: https://otterz1.atlassian.net/browse/OTT-3863  */}
            // onChange={(e) => setTab(e)}
          >
            {mainFilter === "income" ? (
              <>
                <TabPane
                  tabKey="outgoingInvoices"
                  tab={
                    <TabHeader
                      title="Total Outgoing Invoices"
                      count={
                        invoicesForThePeriodArray.filter(
                          (value) => value.invoiceStatus === "sent"
                        ).length || 0
                      }
                      amount={intToDecimalDollar(
                        Number(
                          invoicesForThePeriodArray.length > 0 &&
                            invoicesForThePeriodArray
                              .filter((value) => value.invoiceStatus === "sent")
                              .map((value) => value.total)
                              .reduce((a, b) => a + b, 0)
                        ) || "0"
                      )}
                      // trend="up"
                      type="outgoing"
                      isLoading={isLoadingInvoices}
                    />
                  }
                  key="1"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isLoadingInvoices}>
                      <TotalOutgoingInvoices
                        status={invoiceModuleStatus}
                        language={language}
                        height={height}
                        width={width}
                        dataArray={dataArrayForTotalOutgoingInvoices}
                        highestInvoiceValue={
                          (invoicesForThePeriodArray.length > 0 &&
                            Math.max(
                              ...(invoicesForThePeriodArray.map((value) =>
                                Number(value.total)
                              ) || 0)
                            ).toFixed(2)) ||
                          "0.00"
                        }
                        invoiceAverage={
                          (invoicesForThePeriodArray.length > 0 &&
                            Number(
                              invoicesForThePeriodArray
                                .map((value) => value.total)
                                .reduce((a, b) => a + b, 0)
                            ) / invoicesForThePeriodArray.length) ||
                          0
                        }
                        noOfUniqueCustomers={customersQuery?.data?.length || 0}
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="acceptanceTransactions"
                  tab={
                    <TabHeader
                      title="Total Acceptance Transactions"
                      count={
                        period === "day"
                          ? summary?.day?.sale?.count || 0
                          : period === "week"
                          ? summary?.week?.sale?.count || 0
                          : period === "month"
                          ? summary?.month?.sale?.count || 0
                          : 0
                      }
                      amount={intToDecimalDollar(
                        period === "day"
                          ? summary?.day?.sale?.total
                          : period === "week"
                          ? summary?.week?.sale?.total
                          : period === "month"
                          ? summary?.month?.sale?.total
                          : 0
                      )}
                      // trend="down"
                      type="incoming"
                      isLoading={isLoadingPaQuery}
                    />
                  }
                  key="2"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isLoadingPaQuery}>
                      <TotalAcceptanceTransaction
                        language={language}
                        status={paStatus}
                        height={height}
                        width={width}
                        period={period}
                        dataArray={
                          period === "day"
                            ? summary?.day?.sale?.graphData?.reverse() ||
                              graphData.days
                            : period === "week"
                            ? summary?.week?.sale?.graphData?.reverse() ||
                              graphData.weeks
                            : period === "month"
                            ? summary?.month?.sale?.graphData?.reverse() ||
                              graphData.months
                            : []
                        }
                        highestTransactionValue={
                          period === "day"
                            ? summary?.day?.sale?.highest || 0
                            : period === "week"
                            ? summary?.week?.sale?.highest || 0
                            : period === "month"
                            ? summary?.month?.sale?.highest || 0
                            : "0"
                        }
                        averageTransactionValue={
                          period === "day"
                            ? summary?.day?.sale?.average || 0
                            : period === "week"
                            ? summary?.week?.sale?.average || 0
                            : period === "month"
                            ? summary?.month?.sale?.average || 0
                            : "0"
                        }
                        refundTransactionPercentage={getRefundPercentage(
                          period
                        )}
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="noUniqueCustomers"
                  tab={
                    <TabHeader
                      title="No of Unique Customers"
                      count={
                        (invoicesForThePeriodArray.length > 0 &&
                          invoicesForThePeriodArray
                            .map((value) => value.receiverName)
                            .filter(
                              (value, index, array) =>
                                array.indexOf(value) === index
                            ).length) ||
                        0
                      }
                      // amount="$384.58"
                      // trend="down"
                      type="incoming"
                      isLoading={isLoadingInvoices}
                    />
                  }
                  key="3"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isLoadingInvoices}>
                      <NoOfUniqueCustomers
                        language={language}
                        status={invoiceModuleStatus}
                        height={height}
                        width={width}
                        dataArray={dataArrayForNumberOfUniqueCustomers}
                        highestBilledCustomerAmount={intToDecimalDollar(
                          (invoicesForThePeriodArray.length > 0 &&
                            Math.max(
                              ...invoicesForThePeriodArray.map(
                                (value) => value.total
                              )
                            )) ||
                            "0"
                        )}
                        averageInvoiceFrequency={(invoicesForThePeriodArray.length >
                        0
                          ? invoicesForThePeriodArray.length /
                            invoicesForThePeriodArray
                              .map((value) => value.receiverName)
                              .filter(
                                (value, index, array) =>
                                  array.indexOf(value) === index
                              ).length
                          : 0
                        )?.toFixed(2)}
                        averageAmountBilledToEachCustomer={intToDecimalDollar(
                          (invoicesForThePeriodArray.length > 0 &&
                            invoicesForThePeriodArray
                              .map((value) => value.total)
                              .reduce((a, b) => a + b, 0) /
                              invoicesForThePeriodArray
                                .map((value) => value.receiverName)
                                .filter(
                                  (value, index, array) =>
                                    array.indexOf(value) === index
                                ).length) ||
                            "0"
                        )}
                      />
                    </Spin>
                  </div>
                </TabPane>
                {/* <TabPane
                tabKey="sentInvoices"
                tab={
                  <TabHeader
                    title="Customers sent invoice"
                    count="83"
                    amount="4.5%"
                    trend="up"
                    type="outgoing"
                  />
                }
                key="4"
              >
                <div className={classNames.tabPaneWrapper}></div>
              </TabPane>
              <TabPane
              tabKey=""
              tab={
                <TabHeader
                    title="Volume of transactions"
                    count="83"
                    amount="$384.58"
                    trend="down"
                    type="outgoing"
                  />
                }
                key="5"
              >
                <div className={classNames.tabPaneWrapper}></div>
              </TabPane> */}
              </>
            ) : mainFilter === "expenses" ? (
              <>
                <TabPane
                  tabKey="cardSpent"
                  tab={
                    <TabHeader
                      title="Total Card Spend"
                      count={cardSpentForThePeriodArray.length || "0"}
                      amount={centsToDollar(
                        Number(
                          cardSpentForThePeriodArray.length > 0 &&
                            cardSpentForThePeriodArray
                              .map((value, index) => value.debitAmount)
                              .reduce((a, b) => a + b, 0)
                        ) || "0"
                      )}
                      // trend="up"
                      type="outgoing"
                      isLoading={isCardSpendLoading}
                    />
                  }
                  key="1"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isCardSpendLoading}>
                      <TotalCardSpend
                        height={height}
                        width={width}
                        dataArray={dataArrayForTotalCardSpent}
                        topCategoryOne={cardwiseSpentObj.topCategoryOne}
                        topCategoryTwo={cardwiseSpentObj.topCategoryTwo}
                        others={cardwiseSpentObj.Others}
                        period={period}
                        isSpendExist={
                          cardWiseSpentQuery.length > 0 ? true : false
                        }
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="upcomingScheduledPayments"
                  tab={
                    <TabHeader
                      title="Upcoming Scheduled Payments"
                      count={
                        upcomingScheduledPaymentsForThePeriodArray.length || 0
                      }
                      amount={
                        centsToDollar(
                          upcomingScheduledPaymentsForThePeriodArray.length >
                            0 &&
                            upcomingScheduledPaymentsForThePeriodArray
                              .map(
                                (value) =>
                                  Number(
                                    value.payment_instruction.request.amount
                                  ) || 0
                              )
                              ?.reduce((a, b) => a + b, 0)
                        ) || DEFAULT_AMOUNT
                      }
                      type="incoming"
                      isLoading={getScheduledPayments.isLoading}
                    />
                  }
                  key="2"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={getScheduledPayments.isLoading}>
                      <UpcomingScheduledPayments
                        height={height}
                        width={width}
                        dataArray={dataArrayForUpcomingScheduledPayments}
                        highestScheduledPaymentValue={
                          upcomingScheduledPaymentsForThePeriodArray.length >
                            0 &&
                          Math.max(
                            ...(upcomingScheduledPaymentsForThePeriodArray.map(
                              (value) =>
                                Number(
                                  value.payment_instruction.request.amount
                                ) || 0
                            ) || 0)
                          )
                        }
                        highestScheduledPaymentDate={(() => {
                          const maxValue =
                            upcomingScheduledPaymentsForThePeriodArray.length >
                              0 &&
                            Math.max(
                              ...(upcomingScheduledPaymentsForThePeriodArray.map(
                                (value) =>
                                  Number(
                                    value.payment_instruction.request.amount
                                  ) || 0
                              ) || 0)
                            );

                          const datesForMaxValues =
                            maxValue &&
                            upcomingScheduledPaymentsForThePeriodArray.filter(
                              (value, index) =>
                                Number(
                                  value.payment_instruction.request.amount
                                ) === maxValue
                            );
                          return Array.isArray(datesForMaxValues) &&
                            datesForMaxValues.length > 0
                            ? datesForMaxValues?.[0]?.schedule.start_date &&
                                moment(
                                  datesForMaxValues?.[0]?.schedule.start_date
                                ).format(FORMATS.API_OTHER_FORMAT)
                            : "";
                        })()}
                      />
                    </Spin>
                  </div>
                </TabPane>
                {/* <TabPane
                  tab={
                    <TabHeader
                      title="No of Unique Customers"
                      count="23"
                      amount="$384.58"
                      trend="down"
                      type="incoming"
                    />
                  }
                  key="3"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <NoOfUniqueCustomers />
                  </div>
                </TabPane> */}
                {/* <TabPane
              tab={
                <TabHeader
                  title="Customers sent invoice"
                  count="83"
                  amount="4.5%"
                  trend="up"
                  type="outgoing"
                />
              }
              key="4"
            >
              <div className={classNames.tabPaneWrapper}></div>
            </TabPane>
            <TabPane
            tab={
              <TabHeader
                  title="Volume of transactions"
                  count="83"
                  amount="$384.58"
                  trend="down"
                  type="outgoing"
                />
              }
              key="5"
            >
              <div className={classNames.tabPaneWrapper}></div>
            </TabPane> */}
              </>
            ) : (
              <>
                <TabPane
                  tabKey="dueInvoices"
                  tab={
                    <TabHeader
                      title="Total Due Invoices"
                      count={
                        (dueInvoicesForThePeriodArray.length > 0 &&
                          dueInvoicesForThePeriodArray.map(
                            (value) => value.amountDue
                          ).length) ||
                        0
                      }
                      amount={intToDecimalDollar(
                        (dueInvoicesForThePeriodArray.length > 0 &&
                          dueInvoicesForThePeriodArray
                            .map((value) => value.amountDue)
                            .reduce((a, b) => a + b, 0)) ||
                          "0"
                      )}
                      // trend="up"
                      type="incoming"
                      isLoading={isLoadingInvoices}
                    />
                  }
                  key="1"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <TotalDueInvoices
                      language={language}
                      status={invoiceModuleStatus}
                      height={height}
                      width={width}
                      dataArray={dataArrayForTotalDueInvoices}
                      period={period}
                      paidCount={(
                        ((invoicesForThePeriodArray &&
                          invoicesForThePeriodArray.length > 0 &&
                          invoicesForThePeriodArray
                            .filter(
                              (value) => value.invoiceStatus === "overdue"
                            )
                            .map((value) => value.total)
                            .reduce((a, b) => a + b, 0)) /
                          (invoicesForThePeriodArray &&
                            invoicesForThePeriodArray.length > 0 &&
                            invoicesForThePeriodArray
                              .map((value) => value.total)
                              .reduce((a, b) => a + b, 0))) *
                          100 || 0
                      ).toFixed(2)}
                      unpaidCount={(
                        ((invoicesForThePeriodArray &&
                          invoicesForThePeriodArray.length > 0 &&
                          invoicesForThePeriodArray
                            .filter(
                              (value) => value.invoiceStatus === "overdue"
                            )
                            .map((value) => value.total)
                            .reduce((a, b) => a + b, 0)) /
                          (invoicesForThePeriodArray &&
                            invoicesForThePeriodArray.length > 0 &&
                            invoicesForThePeriodArray
                              .map((value) => value.total)
                              .reduce((a, b) => a + b, 0))) *
                          100 || 0
                      ).toFixed(2)}
                    />
                  </div>
                </TabPane>
                <TabPane
                  tabKey="accountBalance"
                  tab={
                    <TabHeader
                      title="Bank Available Balance"
                      count={
                        centsToDollar(
                          bankData?.payload?.total_available_balance
                        ) || DEFAULT_AMOUNT
                      }
                      isLoading={isBankDataLoading}
                    />
                  }
                  key="2"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isBankDataLoading}>
                      <BankAccountBalance
                        height={height}
                        width={width}
                        period={period}
                        dataArray={dataAccountBalances}
                        averageBalance={
                          // totalCashflowOfBank().achPaymentTotal || 0
                          // dataArrayForBankHistoryBalance.length > 0 &&
                          //   dataArrayForBankHistoryBalance
                          //     .map((value) => value.y)
                          //     .reduce((a, b) => a + b, 0) / 10
                          handleAccountBalance().averageBalance || 0
                        }
                        highestBalance={
                          // centsToDollar(
                          // Math.max(
                          //   ...dataArrayForBankHistoryBalance.map(
                          //     (value) => value.y
                          //   )
                          // )
                          // totalCashflowOfBank().checkPaymentTotal || 0
                          // ) || DEFAULT_AMOUNT
                          handleAccountBalance().highestBalance || 0
                        }
                        // highestValueDate={(() => {
                        //   var highestValue = Math.max(
                        //     ...dataArrayForBankHistoryBalance.map(
                        //       (value) => value.y
                        //     )
                        //   );
                        //   var highestValueDate =
                        //     dataArrayForBankHistoryBalance.find(
                        //       (value) => value.y === highestValue
                        //     ).x || "";
                        //   return highestValueDate;
                        // })()}
                        lowestBalance={
                          // centsToDollar(
                          // Math.min(
                          //   ...dataArrayForBankHistoryBalance.map(
                          //     (value) => value.y
                          //   )
                          // )
                          // totalCashflowOfBank().cardAmountTotal || 0
                          handleAccountBalance().lowestBalance || 0
                          // ) || DEFAULT_AMOUNT
                        }
                        // lowestValueDate={(() => {
                        //   var lowestValue = Math.min(
                        //     ...dataArrayForBankHistoryBalance.map(
                        //       (value) => value.y
                        //     )
                        //   );
                        //   var lowestValueDate =
                        //     dataArrayForBankHistoryBalance.find(
                        //       (value) => value.y === lowestValue
                        //     ).x || "";
                        //   return lowestValueDate;
                        // })()}
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="incomingPayments"
                  tab={
                    <TabHeader
                      title="Total Incoming Payments"
                      count={intToDecimalDollar(
                        (dataInComingPayments.length > 0 &&
                          dataInComingPayments
                            .map((value) => value.y)
                            .reduce((a, b) => a + b, 0)) ||
                          "0"
                      )}
                      type="incoming"
                      isLoading={isBankDataLoading}
                    />
                  }
                  key="3"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isBankDataLoading}>
                      <TotalIncomingPayments
                        height={height}
                        width={width}
                        dataArray={dataInComingPayments}
                        averageTransactionAmount={intToDecimalDollar(
                          dataInComingPayments
                            .map((value) => value.y)
                            .reduce((a, b) => a + b, 0) / 10 || "0"
                        )}
                        highestTransactionValue={intToDecimalDollar(
                          (Array.isArray(dataInComingPayments) &&
                            dataInComingPayments.length > 0 &&
                            Math.max(
                              ...dataInComingPayments.map((value) =>
                                Number(value.y)
                              )
                            )) ||
                            "0"
                        )}
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="outgoingPayments"
                  tab={
                    <TabHeader
                      title="Total Outgoing Payments"
                      count={intToDecimalDollar(
                        (dataOutgoingPayments.length > 0 &&
                          dataOutgoingPayments
                            .map((value) => value.y)
                            .reduce((a, b) => a + b, 0)) ||
                          "0"
                      )
                        ?.toString()
                        ?.replace("-", "")}
                      type="outgoing"
                      isLoading={isBankDataLoading}
                    />
                  }
                  key="4"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isBankDataLoading}>
                      <TotalOutgoingPayments
                        height={height}
                        width={width}
                        dataArray={dataOutgoingPayments}
                        averageTransactionAmount={intToDecimalDollar(
                          dataOutgoingPayments
                            .map((value) => value.y)
                            .reduce((a, b) => a + b, 0) / 10 || "0"
                        )
                          ?.toString()
                          ?.replace("-", "")}
                        highestTransactionValue={intToDecimalDollar(
                          (Array.isArray(dataOutgoingPayments) &&
                            dataOutgoingPayments.length > 0 &&
                            Math.max(
                              ...dataOutgoingPayments.map((value) =>
                                Number(value.y).toFixed(2).replace("-", "")
                              )
                            )) ||
                            "0"
                        )}
                      />
                    </Spin>
                  </div>
                </TabPane>
                <TabPane
                  tabKey="netCashflow"
                  tab={
                    <TabHeader
                      isLoading={isBankDataLoading}
                      title="Net Cashflow"
                      count={intToDecimalDollar(
                        (dataNetCashFlowArray.length > 0 &&
                          dataNetCashFlowArray
                            .map((value) => value.y)
                            .reduce((a, b) => a + b, 0)) ||
                          "0"
                      )}
                      type="outgoing"
                    />
                  }
                  key="5"
                >
                  <div className={classNames.tabPaneWrapper}>
                    <Spin spinning={isBankDataLoading}>
                      <NetCashflow
                        height={height}
                        width={width}
                        dataArray={dataNetCashFlowArray}
                        averageCashflow={intToDecimalDollar(
                          dataNetCashFlowArray.length > 0 &&
                            dataNetCashFlowArray
                              .map((value) => value.y)
                              .reduce((a, b) => a + b, 0) / 10
                        )}
                        highestCashflow={intToDecimalDollar(
                          dataNetCashFlowArray.length > 0 &&
                            Math.max(
                              ...dataNetCashFlowArray.map((value) =>
                                Number(value.y)
                              )
                            )
                        )}
                        highestCashflowDate={(() => {
                          var maxValue =
                            (Array.isArray(dataNetCashFlowArray) &&
                              dataNetCashFlowArray.length > 0 &&
                              Math.max(
                                ...dataNetCashFlowArray.map((value) =>
                                  Number(value.y)
                                )
                              )) ||
                            0;
                          var maxDate =
                            (
                              Array.isArray(dataNetCashFlowArray) &&
                              dataNetCashFlowArray.length > 0 &&
                              dataNetCashFlowArray.find(
                                (value) => value.y === maxValue
                              )
                            )?.x || " ";
                          return maxDate;
                        })()}
                        lowestCashflow={`-$${
                          dataNetCashFlowArray.length > 0 &&
                          Math.min(
                            ...dataNetCashFlowArray.map((value) =>
                              Number(value.y)
                            )
                          )
                            .toFixed(2)
                            .replace("-", "")
                        }`}
                        lowestCashflowDate={(() => {
                          var minValue =
                            (Array.isArray(dataNetCashFlowArray) &&
                              dataNetCashFlowArray.length > 0 &&
                              Math.min(
                                ...dataNetCashFlowArray.map((value) =>
                                  Number(value.y)
                                )
                              )) ||
                            0;
                          var minDate =
                            (
                              Array.isArray(dataNetCashFlowArray) &&
                              dataNetCashFlowArray.length > 0 &&
                              dataNetCashFlowArray.find(
                                (value) => value.y === minValue
                              )
                            )?.x || " ";
                          return minDate;
                        })()}
                      />
                    </Spin>
                  </div>
                </TabPane>
              </>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ReportsOverview;
