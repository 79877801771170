import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { removeValuesFromLocalStorage } from "../util";
import { AppContext } from "../../../context/AppState";
import { clearUserData } from "../../../context/actions/userData.ac";
import { clearApplicationData } from "../../../context/actions/application/getApplicationByTenantId.ac";

const forcedLogOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error("error signing out: ", error);
  }
};

export default function Logout() {
  const { userData, dispatchUserData, dispatchApplication } =
    useContext(AppContext);

  useEffect(() => {
    removeValuesFromLocalStorage(userData.email_verified, userData.approved);
    clearUserData()(dispatchUserData);
    clearApplicationData()(dispatchApplication);
    localStorage.removeItem("icupdated");
    forcedLogOut();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate to="/login" />;
}
