import React from "react";
function useLocalStorage(key = "", initialData = {}) {
  const [data, setData] = React.useState(
    () => JSON.parse(window.localStorage.getItem(key)) || initialData
  );

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(data));
  }, [data, key]);

  return [data, setData];
}

export const useLocalStorageData = (key) => {
  const [localStorageData, setLocalStorageData] = React.useState();
  React.useEffect(() => {
    setLocalStorageData(JSON.parse(localStorage.getItem(key)) || undefined);
  }, [key]);
  return localStorageData;
};

export default useLocalStorage;
