import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { Form, Button, Input as AntInput } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import isEmpty from "lodash/isEmpty";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { CTAButton, Input, Message } from "../../common";
import api from "../../API";
import Heading from "../Heading";
import { AppContext } from "../../../context/AppState";
import getApplicationAC from "../../../context/actions/application/getApplicationByTenantId.ac";
import { saveUserData } from "../../../context/actions/userData.ac";
// import useRouterSwitch from "../../../hooks/useRouterSwitch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useLanguage from "../../../hooks/useLanguage";
import generateErrorMessage from "../../util/functions/customError";
import messages from "../util/messages.const";
import lowerCase from "../../util/functions/lowerCase";
import {
  // ACCOUNT_TYPE,
  APPLICATION_STATUS,
  ROLE_SPECIFIC_LANDING_PAGE,
  ROUTES,
  // ROUTE_STATUS,
  // USER_STATES,
  // ROUTE_STATUS,
  // USER_STATES,
} from "../../constants";
import ROLES from "../../constants/scopesToRoles/roles";
import { validation } from "../config";
import classNames from "./Login.module.scss";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";

const Login = () => {
  //Hooks

  const navigate = useNavigate();
  // const redirectTo = useRouterSwitch();

  // Only for testing
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });

  const [form] = Form.useForm();

  const [disable, setDisable] = useState(true);

  const [, setTempUserEmail] = useLocalStorage("TEMP_USER_EMAIL");

  const [, setTempUserPassword] = useLocalStorage("TEMP_USER_PW");

  const [, setVerifyEmail] = useLocalStorage("OTTERZ.verifyEmail");

  const language = useLanguage(messages);

  const { application, userData, dispatchApplication, dispatchUserData } =
    useContext(AppContext);

    const fetchData = async () => {
      try {
        const response = await api.Settings.getCurrentSubscriptionPlan();
        console.log("Sub at login: ", response?.payload);
        return response?.payload; // Return the payload
      } catch (err) {
        Bugsnag.notify(err);
        return null; // Return null in case of an error
      }
    };

  // const redirection = useCallback(() => {
  // const isLoggedUser = userData?.active && userData?.tenantId ? true : false;

  // const isEmailVerified = userData?.email_verified;
  // const isPhoneVerified =
  //   isLoggedUser &&
  //   userData?.email_verified &&
  //   userData?.phone_number_verified;
  // const isChildUser = userData?.roleName !== ROLES.OWNER;
  // const isUserApproved = userData?.approved === "true";
  // const isAccountTypeSelected =
  //   isPhoneVerified &&
  //   Object.values(ACCOUNT_TYPE).includes(application?.type);
  // const isBusinessAccountCreated =
  //   application?.onBoardingStatus === "BUSINESS_CREATED";
  // const isBeneficialOwnerCreated =
  //   application?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED";
  // const isOfficerCreated =
  //   application?.onBoardingStatus === "OFFICER_CREATED";
  // const isApplicationPendingReview =
  //   isPhoneVerified &&
  //   [
  //     APPLICATION_STATUS.PENDING_REVIEW,
  //     APPLICATION_STATUS.PROVISIONAL,
  //     APPLICATION_STATUS.REVIEW,
  //     APPLICATION_STATUS.UNVERIFIFED,
  //     APPLICATION_STATUS.CREATED,
  //   ].includes(application?.verification_status);
  // const isAccountPending =
  //   application?.status === "ACTIVE" &&
  //   [APPLICATION_STATUS.APPROVED].includes(
  //     application?.verification_status
  //   ) &&
  //   !application?.otz_account_id;
  // const isAccountCreated =
  //   application?.status === "ACTIVE" &&
  //   [APPLICATION_STATUS.APPROVED].includes(
  //     application?.verification_status
  //   ) &&
  //   application?.otz_account_id;
  // const isApplicationCanceled =
  //   isPhoneVerified &&
  //   !isUserApproved &&
  //   [APPLICATION_STATUS.CANCELED].includes(application?.verification_status);
  // const isApplicationCompleted =
  //   (isPhoneVerified &&
  //     application?.status === "ACTIVE" &&
  //     application?.verification_status === APPLICATION_STATUS.APPROVED) ||
  //   userData?.approved === "true";

  // let path = ROUTES.PUBLIC.LOGIN;

  // if (!isEmailVerified && isLoggedUser) {
  //   path = ROUTES.VERIFICATION.VERIFY_EMAIL;
  // }

  // if (isEmailVerified && isLoggedUser) {
  //   path = ROUTES.VERIFICATION.VERIFY_PHONE;
  // }
  // if (isEmailVerified && isPhoneVerified) {
  //   path = ROUTES.ONBOARDING.ACCOUNT_TYPE;
  // }
  // if (isPhoneVerified && isAccountTypeSelected) {
  //   if (application?.type === ACCOUNT_TYPE.BUSINESS) {
  //     if (isBusinessAccountCreated) {
  //       path = ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS; //
  //     }

  //     if (isBeneficialOwnerCreated) {
  //       path = ROUTES.ONBOARDING.OFFICER_DETAILS;
  //     }

  //     if (isOfficerCreated) {
  //       path = `${ROUTES.ONBOARDING.THANK_YOU}${
  //         ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
  //       }`;
  //     }
  //     if (isApplicationPendingReview && isOfficerCreated) {
  //       path = `${ROUTES.ONBOARDING.THANK_YOU}${
  //         ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
  //       }`;
  //     }
  //     if (application?.status === "PROSPECT" && isOfficerCreated) {
  //       path = `${ROUTES.ONBOARDING.THANK_YOU}${
  //         ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
  //       }`;
  //     }
  //   }
  // }

  // if (
  //   (isApplicationPendingReview && !isUserApproved && isOfficerCreated) ||
  //   isAccountPending
  // ) {
  //   path = `${ROUTES.ONBOARDING.THANK_YOU}${
  //     ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
  //   }`;
  // }

  // if (isApplicationCanceled) {
  //   path = `${ROUTES.ONBOARDING.THANK_YOU}${
  //     ROUTE_STATUS[USER_STATES.APPLICATION_CANCELED]
  //   }`;
  // }

  // if (isChildUser && isLoggedUser) {
  //   path = ROLE_SPECIFIC_LANDING_PAGE[userData?.roleName] || ROUTES.APP.INDEX;
  // }

  // if (isApplicationCompleted && isAccountCreated) {
  //   path = ROLE_SPECIFIC_LANDING_PAGE[userData?.roleName] || ROUTES.APP.INDEX;
  // }
  // return path;
  // }, [userData, application]);

  const redirection = async (userPayload, application) => {
    const isLoggedUser =
      userPayload?.active && userPayload?.tenantId ? true : false;

    const isEmailVerified = userPayload?.email_verified;
    const isPhoneVerified =
      isLoggedUser &&
      userPayload?.email_verified &&
      userPayload?.phone_number_verified;
    const isChildUser = userPayload?.roleName !== ROLES.OWNER;
    
    // const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
    // const verifiedOwners = businessRelationsResponse?.payload?.relationShips?.every(relation => 
    //   relation.person_info.verification_status === "ACCEPTED"
    // );
    // const ownerRejected = businessRelationsResponse?.payload?.relationShips?.some(relation => 
    //   relation.person_info.verification_status === "REJECTED"
    // );

    // // Commented for now
    // const isUserApproved = userPayload?.approved === "true";
    
    // const isAccountTypeSelected =
    //   isPhoneVerified &&
    //   Object.values(ACCOUNT_TYPE).includes(application?.type);
    const subscriptionResponse = await fetchData();

    const isOnboardingBusinessAccount =
      application?.onBoardingStatus === "ONBOARDING_CREATE_BUSINESS_ACCOUNT";
    
    // Commented for now
    // const isBusinessAccountCreated = application?.onBoardingStatus === "BUSINESS_CREATED";
    // const isBeneficialOwnerCreated = application?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED";
    // const isOfficerCreated = application?.onBoardingStatus === "OFFICER_CREATED";
    
    // const isInstructionsCompleted = application?.onBoardingStatus === "BUSINESS_OPERATION_INSTRUCTIONS_COMPLETED" 
    // const isBODetailsSubmitted = application?.onBoardingStatus === "BUSINESS_OPERATION_DETAILS_SUBMITTED"

    // const wiresActivationStart = application?.onBoardingStatus === "ACTIVATING_WIRES"

    // const isBusinessDocsUpload =
    //   application?.onBoardingStatus === "BUSINESS_DOCS_UPLOADED";
    // const isBODocsUpload =
    //   application?.onBoardingStatus === "BENEFICIAL_OWNER_DOCS_UPLOADED";
    
    // Commented for now
    // const isApplicationPendingReview =
    //   // isPhoneVerified &&
    //   isEmailVerified &&
    //   [
    //     APPLICATION_STATUS.PENDING_REVIEW,
    //     APPLICATION_STATUS.PROVISIONAL,
    //     APPLICATION_STATUS.REVIEW,
    //     APPLICATION_STATUS.UNVERIFIFED,
    //     APPLICATION_STATUS.CREATED,
    //   ].includes(application?.verification_status);
    
    // Commented for now
    // const isAccountPending =
    //   application?.status === "ACTIVE" &&
    //   [APPLICATION_STATUS.APPROVED].includes(
    //     application?.verification_status
    //   ) &&
    //   !application?.otz_account_id;

    const isAccountCreated =
      application?.status === "ACTIVE" &&
      [APPLICATION_STATUS.APPROVED].includes(
        application?.verification_status
      ) &&
      application?.otz_account_id;
    
    // Commented for now
    // const isApplicationCanceled =
    //   // isPhoneVerified &&
    //   isEmailVerified &&
    //   !isUserApproved &&
    //   [APPLICATION_STATUS.CANCELED].includes(application?.verification_status);
      
    const isApplicationCompleted =
      (isPhoneVerified &&
        isEmailVerified &&
        application?.status === "ACTIVE" &&
        application?.verification_status === APPLICATION_STATUS.APPROVED) ||
      userData?.approved === "true";

    let path = ROUTES.PUBLIC.LOGIN;

    if (!isEmailVerified && isLoggedUser) {
      path = ROUTES.VERIFICATION.VERIFY_EMAIL;
    }

    if (isEmailVerified && isLoggedUser) {
      path = ROUTES.VERIFICATION.VERIFY_PHONE;
    }
    // if (isEmailVerified && isPhoneVerified) {
    if (isPhoneVerified &&isEmailVerified && isLoggedUser) {
      path = ROUTES.ONBOARDING.ONBOARDING_DASHBOARD
    }

    /*NOTE: KEEP IN CHECK - Routing for subscription*/

    // if (isEmailVerified && isPhoneVerified) {
    //   path = ROUTES.ONBOARDING.SUBSCRIPTION_PLAN;
    // }
    // if (isPhoneVerified && isAccountTypeSelected) {
    if (isPhoneVerified && isEmailVerified) {
      if (subscriptionResponse?.data === null) {
        path = ROUTES.ONBOARDING.ONBOARDING_DASHBOARD;
      }

      // if (isBusinessDocsUpload) {
      //   path = ROUTES.ONBOARDING.BUSINESS_OPERATION_DETAILS;
      // }
      // if(wiresActivationStart) {
      //   path = ROUTES.ONBOARDING.BUSINESS_APPLICATION_DOCS
      // }

      // Commented for now
      // if (isBeneficialOwnerCreated) {
      //   path = ROUTES.ONBOARDING.OFFICER_DETAILS;
      // }
      
      
      // if(isInstructionsCompleted) {
      //   path = ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS
      // }
      // if (isOfficerCreated) {
      //   path = ROUTES.ONBOARDING.BENEFICIAL_OWNER_DOCS;
      // }
      // if (isBusinessAccountCreated) {
      //   path = ROUTES.ONBOARDING.BUSINESS_OPERATION_DETAILS;
      // }
      
      
      // if (isBusinessAccountCreated) {
      //   path = ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS;
      // }
      if (isOnboardingBusinessAccount) {
        path = ROUTES.ONBOARDING.ONBOARDING_DASHBOARD;
      }
      
      // Commented for now
      /* 
      if (isOfficerCreated) {
        path = `${ROUTES.ONBOARDING.THANK_YOU}${
          ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
        }`;
      }
      if (isApplicationPendingReview && isOfficerCreated) {
        path = `${ROUTES.ONBOARDING.THANK_YOU}${
          ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
        }`;
      }
      if (application?.status === "PROSPECT" && isOfficerCreated) {
        path = `${ROUTES.ONBOARDING.THANK_YOU}${
          ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
        }`;
      }
      */


      // if (application?.type === ACCOUNT_TYPE.BUSINESS) {
      // }
    }

    // Commented for now
    // if (
    //   (isApplicationPendingReview && !isUserApproved && isOfficerCreated) ||
    //   isAccountPending
    // ) {
    //   path = `${ROUTES.ONBOARDING.THANK_YOU}${
    //     ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
    //   }`;
    // }

    // Commented for now
    // if (isApplicationCanceled) {
    //   path = `${ROUTES.ONBOARDING.THANK_YOU}${
    //     ROUTE_STATUS[USER_STATES.APPLICATION_CANCELED]
    //   }`;
    // }



    if (isChildUser && isLoggedUser && subscriptionResponse?.data !== null) {
      path = ROLE_SPECIFIC_LANDING_PAGE[userData?.roleName] || ROUTES.APP.INDEX;
    }

    console.log("isApplicationCompleted: ", isApplicationCompleted);
    console.log("isPhoneVerified: ", isPhoneVerified);
    console.log("userData: ", userData);
    // console.log("ownerRejected: ", ownerRejected);

    if (isApplicationCompleted && isAccountCreated && subscriptionResponse?.data !== null) { 
      path = ROLE_SPECIFIC_LANDING_PAGE[userData?.roleName] || ROUTES.APP.INDEX;
    }

    // if(application?.verification_status === "REJECTED" || ownerRejected) {
    //   path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[USER_STATES.APPLICATION_CANCELED]}`
    // }

    navigate(path, { replace: true });
  };

  useEffect(() => {
    const isApprovedUser =
      userData?.approved === "true" && application?.success;
    const isRegisteringUser =
      userData?.tenantId && userData?.approved === "false";
    const { OWNER, ...rest } = ROLES; // destruct OWNER and CHILD_USERS from ROLES object

    // NOTE: KEEP IN CHECK
    // here we need to wait until we load application data, if user is an approved user. after we load the application data
    // useUserState will return authorized_user and useRouterSwitch will return link to app. If not we will take user in the
    // onboarding flow.
    if (
      userData?.roleName === ROLES.OWNER &&
      /* redirection() && */
      (isApprovedUser || isRegisteringUser) &&
      (application?.error || application?.success)
    ) {
      /* navigate(redirection(), { replace: true }); */

      try {
        mixpanel.people.set({
          "Company ID": application?.otz_business_id,
          Username: application?.username,
          $name: application?.username,
        });

        mixpanel.track("Login Completed", {
          "Company Name": application?.username,
          "Login Method": "WEB", // Assuming 'WEB' is the login method
          "Device Resolution": window.innerWidth + "x" + window.innerHeight, // Device resolution
          OS: navigator.platform, // Operating system
          PLATFORM: "WEB",
          "Email ID": userData?.email,
        });
      } catch (error) {
        console.error("error: ", error);
      }
    }
    if (
      Object.values(rest).includes(userData?.roleName) /* && redirection() */
    ) {
      /*  navigate(redirection(), { replace: true }); */

      try {
        mixpanel.people.set({
          "Company ID": application?.username,
          Username: application?.username,
          $name: application?.username,
        });

        mixpanel.track("Login Completed", {
          "Company ID": application?.otz_business_id,
          "Login Method": "WEB", // Assuming 'WEB' is the login method
          "Device Resolution": window.innerWidth + "x" + window.innerHeight, // Device resolution
          OS: navigator.platform, // Operating system
          PLATFORM: "WEB",
          "Email ID": userData?.email,
        });
      } catch (error) {
        console.error("error: ", error);
      }
    }
  }, [
    navigate,
    // redirectTo,
    // redirection,
    userData,
    userData?.approved,
    userData?.tenantId,
    application?.success,
    application?.error,
    userData?.roleName,
    application,
  ]);
  //Api calls
  const loginMutation = useMutation(async (data) => {
    const response = await Auth.signIn(data);
    return response;
  });

  //Event handlers
  const handleOnFinish = async () => {
    try {
      await form.validateFields();
      loginMutation.mutate(
        {
          username: lowerCase(form.getFieldValue("email").toString()),
          password: form.getFieldValue("password").toString(),
        },
        {
          onSuccess: (data) => {
            // child user set new password navigation
            if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
              setTempUserEmail(form.getFieldValue("email").toString());
              setTempUserPassword(form.getFieldValue("password").toString());
              navigate(ROUTES.ONBOARDING.CHILD_USER_SET_PASSWORD);
              return;
            }

            Message({ type: "success", content: "Logging you in..." });
            const userPayload = data?.signInUserSession?.idToken?.payload;

            if (
              userPayload?.roleName &&
              userPayload?.roleName !== ROLES.OWNER
            ) {
              // child user login
              if (!isEmpty(userPayload)) {
                // Mixpanel event logging for the successful login
                try {
                  mixpanel.identify(userPayload?.email);

                  mixpanel.people.set({
                    $email: userPayload?.email,
                  });

                  mixpanel.track("Login Completed", {
                    "Login Method": "WEB", // Assuming 'WEB' is the login method
                    "Device Resolution":
                      window.innerWidth + "x" + window.innerHeight, // Device resolution
                    OS: navigator.platform, // Operating system
                    PLATFORM: "WEB",
                  });
                } catch (error) {
                  console.error("error: ", error);
                }

                saveUserData(userPayload)(dispatchUserData);
                getApplicationAC(userPayload?.tenantId)(dispatchApplication);
              }
            } else {
              // owner login
              if (!isEmpty(userPayload)) {
                saveUserData(userPayload)(dispatchUserData);
                getApplicationAC(userPayload?.tenantId)(
                  dispatchApplication
                ).then((response) => {
                  const application = response?.payload;
                  redirection(userPayload, application);
                });
              }
              try {
                mixpanel.identify(userPayload?.email);

                mixpanel.people.set({
                  $email: userPayload?.email,
                });
              } catch (error) {
                console.error("error: ", error);
              }
            }
            localStorage.removeItem("OTTERZ.userData");
            localStorage.removeItem("OTTERZ.application");
          },
          onError: (error) => {
            switch (error?.code) {
              case "UserNotConfirmedException":
                setVerifyEmail(form.getFieldValue("email").toString());
                generateErrorMessage(
                  error,
                  language.LOG_IN.OTP_EMAIL_PROMPT,
                  "warning"
                );
                navigate(ROUTES.VERIFICATION.VERIFY_EMAIL);
                break;

              case "UserNotFoundException":
                generateErrorMessage(
                  error,
                  language.LOG_IN.OTP_INCORRECT_PROMPT
                );
                break;

              default:
                generateErrorMessage(
                  error,
                  language.LOG_IN.OTP_INCORRECT_PROMPT
                );
                break;
            }
          },
        }
      );
    } catch (error) {
      generateErrorMessage(error);
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Login"
          description="Please enter your email address to login"
        />
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={(changedValues, { email }) => {
            if (changedValues && email?.length > 0) {
              setDisable(false);
            } else if (changedValues && email?.length === 0) {
              setDisable(true);
            }
          }}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: validation.email.required }]}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>
          <>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: validation.password.required },
              ]}
            >
              <AntInput.Password
                placeholder={validation.password.placeholder}
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                }
              />
            </Form.Item>
            <div className={classNames.options}>
              <p onClick={() => navigate(ROUTES.PUBLIC.FORGOT_PASSWORD)}>
                Forgot password?
              </p>
            </div>
          </>

          <CTAButton
            htmlType="submit"
            type="primary"
            loading={loginMutation.isLoading || application?.loading}
            disabled={disable}
          >
            Login
          </CTAButton>
          <div className={classNames.links}>
            <span>Don't have an account?</span>
            <Button
              type="link"
              size="small"
              onClick={() => navigate(ROUTES.PUBLIC.SIGN_UP)}
            >
              Sign up here
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
