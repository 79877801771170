import * as types from "../../actionTypes";

const getBusinessPersonDetailReducer = (state, action) => {
  switch (action.type) {
    case types.GET_BUSINESS_PERSON_DETAIL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_BUSINESS_PERSON_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        payload: action.payload,
      };

    case types.GET_BUSINESS_PERSON_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CLEAR_BUSINESS_PERSON_DETAIL_DATA:
      return {};

    default:
      return state;
  }
};

export default getBusinessPersonDetailReducer;
