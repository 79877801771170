import classNames from "../ManageCards.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import api from "../../../API";
import { useMutation, useQueryClient } from "react-query";

const UnfreezeFormBody = ({ onCancel, currentCard }) => {
  const queryClient = useQueryClient();

  const cardUnfreezeFn = (event) => {
    return api.BusinessBanking.updateCard(
      { card_status: "ACTIVE", reason: "REQ" },
      currentCard?.otz_card_id
    );
  };

  const cardUnfreezeMutation = useMutation((event) => cardUnfreezeFn(event));

  const cardUnfreezeOnclick = (data) => {
    cardUnfreezeMutation.mutate(data, {
      onSuccess: (data, variables, context) => {
        onCancel();
        queryClient.invalidateQueries("listCards");
        Message({ type: "success", content: "Card successfully unfrozen!" });
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Unfreeze Card?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to unfreeze this card?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#1DBC83",
            border: "none",
            marginLeft: "15px",
          }}
          loading={cardUnfreezeMutation.isLoading}
          onClick={() => cardUnfreezeOnclick(currentCard?.otz_card_id)}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};

export default function UnfreezeCard({ visible, onCancel, currentCard }) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <UnfreezeFormBody onCancel={onCancel} currentCard={currentCard} />
    </Modal>
  );
}
