import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { Form } from "antd";
import classNames from "../Invoices.module.scss";
import moment from "moment";

import { Modal, CTAButton, Message } from "../../../../common";
import CreateNewEstimateForm from "./CreateNewEstimateForm";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { FORMATS } from "../../../../constants";
import Bugsnag from "@bugsnag/browser";
import mixpanel from "mixpanel-browser";

export default function CreateNewEstimate({
  visible,
  onCancel,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  const language = useLanguage(messages);

  const queryClient = useQueryClient();
  const [summary, setSummary] = useState({ subTotal: 0, tax: 0 });
  const [discount, setDiscount] = useState(0);

  const [isSendEstimate, setIsSendEstimate] = useState(false);

  function shareInvoiceFn(input) {
    return api.Receivables.Share.email(input);
  }

  const shareInvoiceMn = useMutation((data) => shareInvoiceFn(data));

  const updateEstimateFunction = (event) => {
    return api.Receivables.Invoices.setInvoiceStatus(event);
  };

  const updateEstimateMutation = useMutation(
    (event) => updateEstimateFunction(event),
    {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.ESTIMATE_SENT_SUCCESS,
        });
        queryClient.invalidateQueries("getInvoices");
        form.resetFields();
        onCancel();
      },
      onSettled: () => {
        setIsSendEstimate(false);
      },
    }
  );

  const updateEstimateStatus = (data) =>
    updateEstimateMutation.mutate(data, {
      onSuccess: () => {
        shareInvoiceMn.mutate({
          id: data.id,
          type: "invoice",
        });
      },
    });

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: productQuery } = useQuery(
    "getProducts",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxQuery } = useQuery(
    "getAllTaxes",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  function createEstimateFn(data) {
    let itemDto =
      data.salesItemsEstimate &&
      data.salesItemsEstimate.length > 0 &&
      data.salesItemsEstimate.map((item, index) => {
        return {
          description: item.description,
          quantity: item.quantity ? Number(item.quantity) : 1,
          taxRate: item.taxRate ? Number(item.taxRate) : 0,
          unitCost: Number(item.unitCost),
        };
      });
    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      // estimateNumber: data.estimateNumber,
      clientId: data.client,
      //Changed estimateDate attr to invoiceDate as it's on BE
      estimateDate: moment(data.estimateDate).format(FORMATS.API_OTHER_FORMAT),
      discount: data.discount && Number(data.discount),
      reference: data.reference ? data.reference : "",
      itemDto: data.salesItemsEstimate ? itemDto : [],
    };
    return api.Receivables.Estimates.createEstimate(input);
  }

  const createEstimateMn = useMutation((event) => createEstimateFn(event));

  const onFinish = (data) => {
    createEstimateMn.mutate(data, {
        onSuccess: async (response) => {
          if (isSendEstimate) {
            updateEstimateStatus({
              id: response.data.id,
              type: "invoice",
              status: "sent",
            });

            try {
              // Extracting values from data
              const totalItems = data.salesItemsEstimate.length;
              const totalPrice = data.salesItemsEstimate.reduce((acc, item) => acc + item.subTotal, 0);
              const totalTax = data.salesItemsEstimate.reduce((acc, item) => acc + item.taxAmount, 0);
              const payableAmount = totalPrice + totalTax - parseFloat(data.discount);
  
              const estimate = await api.Receivables.Estimates.getEstimateById(response.data.id)
  
              mixpanel.track("Estimate Creation Successful", {
                "Estimate Number": estimate?.data?.estimateNumber,
                "Date Created": estimate?.data?.estimateDate,
                "No. of items": totalItems,
                "Total Price": totalPrice,
                "Discount": data?.discount,
                "Tax": totalTax,
                "Payable Amount": payableAmount,
                "Status": estimate?.data?.estimateStatus, // This value is not provided in the data object
              });
            } catch (error) {
              Bugsnag.notify("mixpanel error", error);
            }
        } else {
          Message({
              type: "success",
              content: language.ESTIMATE_CREATE_SUCCESS,
          });

          try {
            // Extracting values from data
            const totalItems = data.salesItemsEstimate.length;
            const totalPrice = data.salesItemsEstimate.reduce((acc, item) => acc + item.subTotal, 0);
            const totalTax = data.salesItemsEstimate.reduce((acc, item) => acc + item.taxAmount, 0);
            const payableAmount = totalPrice + totalTax - parseFloat(data.discount);

            const estimate = await api.Receivables.Estimates.getEstimateById(response.data.id)

            mixpanel.track("Estimate Creation Successful", {
              "Estimate Number": estimate?.data?.estimateNumber,
              "Date Created": estimate?.data?.estimateDate,
              "No. of items": totalItems,
              "Total Price": totalPrice,
              "Discount": data?.discount,
              "Tax": totalTax,
              "Payable Amount": payableAmount,
              "Status": estimate?.data?.estimateStatus, // This value is not provided in the data object
            });
          } catch (error) {
            Bugsnag.notify("mixpanel error", error);
          }

          queryClient.invalidateQueries("getEstimates");
          form.resetFields();
          onCancel();
        }
      },
      onError: (error) => {
        try {
          mixpanel.track("Estimate Creation Failed", {
            "Invoice Number": data?.reference,
            "Failure Message": generateErrorMessage(error),
        });
        } catch (error) {
          Bugsnag.notify("mixpanel error", error);
        }
      },
      onSettled: () => {
        setIsSendEstimate(false);
      },
    });
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        setSummary({ subTotal: 0, tax: 0 });
        setDiscount(0);
        form.resetFields();
        onCancel();
      }}
      onCancel={() => {
        setSummary({ subTotal: 0, tax: 0 });
        setDiscount(0);
        form.resetFields();
        onCancel();
      }}
      title="Create Estimate"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      footer={
        <>
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={() => {
              setIsSendEstimate(true);
              form.submit();
            }}
            style={{
              height: 44,
              width: 248,
            }}
            loading={
              isSendEstimate &&
              (updateEstimateMutation.isLoading ||
                createEstimateFn.isLoading ||
                shareInvoiceMn.isLoading)
            }
          >
            Send Estimate
          </CTAButton>
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={form.submit}
            style={{
              height: 44,
              width: 248,
            }}
            loading={!isSendEstimate && createEstimateFn.isLoading}
          >
            Save
          </CTAButton>
        </>
      }
      width={872}
      centered
    >
      <CreateNewEstimateForm
        form={form}
        onFinish={onFinish}
        customers={customerQuery?.data}
        products={productQuery?.data}
        taxRates={taxQuery?.data}
        invoiceTemplateSettings={invoiceTemplateSettings}
        summary={summary}
        setSummary={setSummary}
        discount={discount}
        setDiscount={setDiscount}
      />
    </Modal>
  );
}
