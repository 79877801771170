import React from "react";
import classNames from "./ManageCustomer.module.scss";
import { Modal } from "../../../../common";
import ManageCustomerForm from "./ManageCustomerForm";

export default function ManageCustomer({
  visible,
  onCustomerModelClose,
  updatingCustomer = null,
}) {
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCustomerModelClose}
      onCancel={onCustomerModelClose}
      title={updatingCustomer ? "Update customer" : "Add New Customer"}
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 0,
        justifyContent: "center",
      }}
      width={430}
    >
      <ManageCustomerForm
        updatingCustomer={updatingCustomer}
        onCustomerModelClose={onCustomerModelClose}
      />
    </Modal>
  );
}
