import AddressVerificationUpload from "./AddressVerificationUpload";
import PassportUpload from "./PassportUpload";
import IdDocumentUpload from "./IdDocumentUpload";

const DocumentMap = (props) => {
  switch (props.documentType) {
    case "AddressVerification":
      return <AddressVerificationUpload {...props} />;

    case "IdDocument":
      return <IdDocumentUpload {...props} />;

    case "Passport":
    case "SocialSecurityCard":
    case "CertificateOfIncorporation":
    case "EmployerIdentificationNumberConfirmation":
    case "PowerOfAttorney":
      return <PassportUpload docTitle={props.documentType} {...props} />;

    default:
      return null;
  }
};

export default DocumentMap;
