import endpoints from "../endpoints";

export default async function createWirePayment(data) {
  let env = process.env.REACT_APP_ENV?.trim()
  return await (
    await fetch(`${endpoints.BUSINESS_BANKING.CREATE_WIRE_PAYMENT[env]}`, {
      method: "POST",
      body: JSON.stringify(data),
    })
  ).json();
}
