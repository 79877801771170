import { useCallback } from "react";
import {
  last30DaysFilterProps,
  transactionModes,
  transactionStates,
  transactionTypes,
} from "../../App/API/PaymentAcceptance/Transactions/listTransaction.api";
import usePersistedReducer from "./usePersistedReducer";

const initialState = {
  filter: {
    transactionType: transactionTypes.all.type,
    transactionMode: transactionModes.all.mode,
    api: {
      xCommand: transactionStates.all.state,
      xBeginDate: last30DaysFilterProps.xBeginDate,
      xEndDate: last30DaysFilterProps.xEndDate,
      xName: "",
      xRefNum: "",
      xAmount: "",
    },
  },
};

const types = {
  SET: "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-FILTER",
  CLEAR: "PAYMENT-ACCEPTANCE-TRANSACTIONS-CLEAR-FILTER",
  SET_DATE_RANGE: "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-DATE-RANGE-FILTER",
  SET_TRANSACTION_TYPE:
    "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-TRANSACTION-TYPE-FILTER",
  SET_TRANSACTION_MODE:
    "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-TRANSACTION-MODE-FILTER",
  SET_TRANSACTION_COMMAND:
    "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-TRANSACTION-COMMAND-FILTER",
  SET_TRANSACTION_INPUT_TYPE:
    "PAYMENT-ACCEPTANCE-TRANSACTIONS-SET-TRANSACTION-INPUT-TYPE-FILTER",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET: {
      return payload;
    }
    case types.CLEAR: {
      return initialState;
    }
    case types.SET_DATE_RANGE: {
      const { xBeginDate, xEndDate } = payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          api: { ...state.filter.api, xBeginDate, xEndDate },
        },
      };
    }
    case types.SET_TRANSACTION_TYPE: {
      return {
        ...state,
        filter: { ...state.filter, transactionType: payload },
      };
    }
    case types.SET_TRANSACTION_MODE: {
      return {
        ...state,
        filter: { ...state.filter, transactionMode: payload },
      };
    }
    case types.SET_TRANSACTION_COMMAND: {
      return {
        ...state,
        filter: {
          ...state.filter,
          api: { ...state.filter.api, xCommand: payload },
        },
      };
    }
    case types.SET_TRANSACTION_INPUT_TYPE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          api: { ...state.filter.api, ...payload },
        },
      };
    }
    default:
      return state;
  }
};

const usePATransactions = () => {
  const [state, dispatch] = usePersistedReducer(
    "OTTERZ_PaymentAcceptanceTransactions",
    reducer,
    initialState
  );

  const setFilter = useCallback(
    (filter) => dispatch({ type: types.SET, payload: filter }),
    [dispatch]
  );

  const setDateRangeFilter = useCallback(
    (xBeginDate, xEndDate) =>
      !!xBeginDate &&
      !!xEndDate &&
      dispatch({
        type: types.SET_DATE_RANGE,
        payload: { xBeginDate, xEndDate },
      }),
    [dispatch]
  );

  const setTransactionTypeFilter = useCallback(
    (transactionType) =>
      !!transactionType &&
      dispatch({
        type: types.SET_TRANSACTION_TYPE,
        payload: transactionType,
      }),
    [dispatch]
  );

  const setTransactionModeFilter = useCallback(
    (transactionMode) =>
      !!transactionMode &&
      dispatch({
        type: types.SET_TRANSACTION_MODE,
        payload: transactionMode,
      }),
    [dispatch]
  );

  const setTransactionCommandFilter = useCallback(
    (command) =>
      !!command &&
      dispatch({
        type: types.SET_TRANSACTION_COMMAND,
        payload: command,
      }),
    [dispatch]
  );

  const setTransactionInputTypeFilter = useCallback(
    (inputType, value) => {
      const filterKeyValue = {};

      if (["xName", "xRefNum"].includes(inputType)) {
        filterKeyValue[inputType] = `a=${value}`;
      }

      if ("xAmount" === inputType) {
        filterKeyValue[inputType] = `ai${value}`;
      }

      dispatch({
        type: types.SET_TRANSACTION_INPUT_TYPE,
        payload: filterKeyValue,
      });
    },
    [dispatch]
  );

  const clearFilter = useCallback(
    () => dispatch({ type: types.CLEAR }),
    [dispatch]
  );

  return {
    state,
    actions: {
      setFilter,
      setDateRangeFilter,
      setTransactionTypeFilter,
      setTransactionModeFilter,
      setTransactionCommandFilter,
      setTransactionInputTypeFilter,
      clearFilter,
    },
  };
};

export default usePATransactions;
