import { API } from "aws-amplify";

export default async function editTaxRate(data) {
  return await API.put("taxRate", "", {
    // queryStringParameters: {
    //   invoiceId: data.id
    // },
    body: {...data},
  });
}
