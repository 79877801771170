import { useState, useRef, useEffect } from "react";
import { Card, Button as AntButton, Spin } from "antd";
import {
  RiMailLine,
  RiDownloadCloudLine,
  // RiEditLine,
  RiRepeat2Line,
  RiStopCircleLine,
} from "react-icons/ri";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

import classNames from "./TransactionsInformation.module.scss";
import { Title, Modal, Message } from "../../../../common";

import Adjust from "../Adjust/Adjust";
import Refund from "../Refund/Refund";
import Void from "../Void/Void";
import api from "../../../../API";
import { cardTypeLogos } from "../../../common/cardTypeLogos";
import generateErrorMessage from "../../../../util/functions/customError";
import { FORMATS } from "../../../../constants";

export default function TransactionsInformation({
  visible,
  onCancel,
  record,
  fetchTransaction,
  currentUserEmail,
}) {
  const componentRef = useRef();
  const queryClient = useQueryClient();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [transaction, setTransaction] = useState();
  const [loadingType, setLoadingType] = useState();

  useEffect(() => {
    setTransaction(null);
  }, [record, fetchTransaction]);

  const transactionAddress = [
    transaction?.xMerchantStreet,
    transaction?.xMerchantCity,
    transaction?.xMerchantState,
    transaction?.xMerchantZip,
  ]
    .filter((v) => v?.length)
    .join(", ");

  const billingTransactionAddress = [
    transaction?.xShipStreet,
    transaction?.xShipStreet2,
    transaction?.xShipCity,
    transaction?.xShipState,
    transaction?.xShipZip,
  ]
    .filter((v) => v?.length)
    .join(", ");

  const enteredDate =
    transaction?.xEnteredDate &&
    moment(transaction?.xEnteredDate).format(FORMATS.API_OTHER_FORMAT);

  const handleAdjustModalCancelClick = () => {
    setShowAdjustModal(false);
  };

  const [showRefundModal, setShowRefundModal] = useState(false);
  const handleRefundModalCancelClick = () => {
    setShowRefundModal(false);
  };

  const [showVoidModal, setShowVoidModal] = useState(false);
  const handleVoidModalCancelClick = () => {
    setShowVoidModal(false);
  };

  const { data: details, isLoading } = useQuery(
    ["getCustomers", record?.xRefNum],
    () => api.Receivables.PaymentAcceptance.getPATransaction(record?.xRefNum),
    {
      onSuccess: (data) => {
        setTransaction(data?.data?.xReportData?.[0]);
      },
      enabled: !!record?.xRefNum,
      refetchOnWindowFocus: false,
    }
  );

  function handleTransactionFn(event) {
    return api.Receivables.PaymentAcceptance.createPATransaction(
      event?.data,
      transaction?.xCommand.includes("CC")
        ? `/card/${event?.method}`
        : `/check/${event?.method}`
    );
  }

  const handleTransactionMt = useMutation(
    (event) => handleTransactionFn(event),
    {}
  );

  const onTransactionClick = (data) => {
    let { method } = data;
    handleTransactionMt.mutate(data, {
      onSuccess: (res, variables, context) => {
        setLoadingType(undefined);
        if (method === "void") {
          if (res?.data?.xStatus === "Error") {
            Message({
              type: "error",
              content: "Void Refund declined"
            });
          } else {
            Message({
              type: "success",
              content: "Void transaction approved successfully",
            });
          }
        } else if (method === "refund") {
          if (res?.data?.xStatus === "Error") {
            Message({
              type: "error",
              content: "Refund declined"
            });
          } else {
            Message({
              type: "success",
              content: "Refund transaction approved successfully",
            });
          }
        }
        fetchTransaction();
        queryClient.invalidateQueries("getCustomers");
        onCancel();
      },
      onError: (error) => {
        setLoadingType(undefined);
        generateErrorMessage(error);
        fetchTransaction();
        queryClient.invalidateQueries("getCustomers");
        onCancel();
      },
    });
  };

  function handleEmailSendfn(event) {
    return api.Receivables.PaymentAcceptance.sendEmail(event);
  }

  const handleEmailSendMt = useMutation((event) => handleEmailSendfn(event));

  const onEmailSend = () => {
    const userDetails = {
      xEmail: transaction.xEmail,
      xRefNum: transaction.xRefNum,
    };

    handleEmailSendMt.mutate(userDetails, {
      onSuccess: (data, variables, context) => {
        Message({ type: "success", content: "Email sent successfully" });
        onCancel();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title="Transaction Information"
        className={classNames.modal}
        bodyStyle={{
          height: "76vh",
          padding: 24,
          display: "flex",
          flexDirection: "column",
        }}
        footer={
          <div className={classNames.footer}>
            <AntButton
              type="link"
              disabled={isLoading}
              icon={<RiDownloadCloudLine style={{ marginRight: 5 }} />}
              onClick={handlePrint}
            >
              Print or Download
            </AntButton>
            <AntButton
              loading={handleEmailSendMt.isLoading}
              disabled={!!!details?.data?.xReportData[0]?.xEmail}
              type="link"
              onClick={onEmailSend}
              icon={<RiMailLine style={{ marginRight: 5 }} />}
            >
              Email
            </AntButton>
          </div>
        }
        width={495}
      >
        <Spin spinning={isLoading}>
          <div ref={componentRef}>
            <Card className={classNames.card}>
              <div className={classNames.billingAccountDetails}>
                <Title as="h4">General Information</Title>

                <div>
                  <span>Transaction Status</span>
                  <span
                    className={
                      transaction?.xResponseResult === "Error"
                        ? classNames.result
                        : ""
                    }
                  >
                    {transaction?.xResponseResult === "Error"
                      ? "Declined"
                      : transaction?.xResponseResult}
                  </span>
                </div>
                <div className={classNames.billingDetails}>
                  <div>
                    <div>
                      <span>Amount</span>
                      {record?.xVoid === "1" ? (
                        <>
                          <span>
                            <s>{transaction?.xAmount}</s>
                            <span
                              style={{ color: "#BC7C1D" }}
                            >{` (Void)`}</span>
                          </span>
                        </>
                      ) : (
                        <span>{transaction?.xAmount}</span>
                      )}
                    </div>
                    <div>
                      <span>Payment Type</span>
                      <span>{transaction?.xCommand}</span>
                    </div>
                    <div>
                      {transaction?.xCommand?.split(":")?.[0] === "CC" ? (
                        <>
                          {" "}
                          <span>Card Number</span>
                          <span className={classNames.cardDetail}>
                            <div>
                              {cardTypeLogos[transaction?.xCardType]?.image}
                            </div>
                            <span>{transaction?.xMaskedAccountNumber}</span>
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span>Account Number</span>
                          <span>{transaction?.xMaskedAccountNumber}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>Transaction Type</span>
                      <span>Sale</span>
                    </div>
                    <div>
                      <span>Entered Date</span>
                      <span>{enteredDate}</span>
                    </div>
                    <div>
                      <span>Address</span>
                      <span>{transactionAddress}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Card className={classNames.card}>
              <div className={classNames.billingAccountDetails}>
                <Title as="h4">Billing Information</Title>
                <div className={classNames.billingDetails}>
                  <div>
                    <div>
                      <span>Name</span>
                      <span>
                        {[
                          transaction?.xBillFirstName,
                          transaction?.xBillLastName,
                        ]
                          .filter(Boolean)
                          ?.join(" ")}
                      </span>
                    </div>
                    {billingTransactionAddress ? (
                      <div>
                        <span>Address</span>
                        <span>{billingTransactionAddress}</span>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {transaction?.xBillCompany ? (
                      <div>
                        <span>Company</span>
                        <span>{transaction?.xBillCompany}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Card>
            <Card className={classNames.card}>
              <div className={classNames.billingAccountDetails}>
                <Title as="h4">Additional Information</Title>
                <div className={classNames.billingDetails}>
                  <div>
                    <div className={classNames.additionalInfo}>
                      <span>Transaction Description</span>
                      <span>{transaction?.xDescription}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={classNames.bottomBar}>
            <AntButton
              loading={handleTransactionMt.isLoading && loadingType === "void"}
              disabled={
                record?.xResponseResult === "Error" ||
                (handleTransactionMt.isLoading && loadingType === "refund")
              }
              style={{ height: 44, width: 125, borderRadius: 8 }}
              icon={<RiStopCircleLine style={{ marginRight: 5 }} />}
              onClick={() => setShowVoidModal(true)}
            >
              Void
            </AntButton>
            <AntButton
              loading={
                handleTransactionMt.isLoading && loadingType === "refund"
              }
              disabled={
                record?.transactionMode === "Refund" ||
                (record?.xResponseResult &&
                  (loadingType === "void") === "Error") ||
                (handleTransactionMt.isLoading && loadingType === "void")
              }
              style={{ height: 44, width: 125, borderRadius: 8 }}
              icon={<RiRepeat2Line style={{ marginRight: 5 }} />}
              onClick={() => setShowRefundModal(true)}
            >
              Refund
            </AntButton>
            {/*  <AntButton
              loading={handleTransactionMt.isLoading}
              disabled={record?.xResponseResult === "Error"}
              style={{ height: 44, width: 125, borderRadius: 8 }}
              icon={<RiEditLine style={{ marginRight: 5 }} />}
              onClick={() => setShowAdjustModal(true)}
            >
              Adjust
            </AntButton> */}
          </div>
        </Spin>
      </Modal>
      <Adjust
        visible={showAdjustModal}
        onCancel={handleAdjustModalCancelClick}
        record={record}
        onTransactionClick={(data) => {
          onTransactionClick({
            data,
            method: "adjust",
            message: "Update Successful",
          });
          handleVoidModalCancelClick();
        }}
        fetchTransaction={fetchTransaction}
      />
      <Refund
        visible={showRefundModal}
        onCancel={handleRefundModalCancelClick}
        onTransactionClick={(data) => {
          setLoadingType("refund");
          onTransactionClick({
            data,
            method: "refund",
            message: "Refund Requested Successful",
          });
          handleRefundModalCancelClick();
        }}
        record={record}
        currentUserEmail={currentUserEmail}
      />
      <Void
        visible={showVoidModal}
        onCancel={handleVoidModalCancelClick}
        onTransactionClick={() => {
          setLoadingType("void");
          onTransactionClick({
            data: { refNum: record?.xRefNum },
            method: "void",
            message: "Transaction Void Successful",
          });
          handleVoidModalCancelClick();
        }}
        transaction={transaction}
      />
    </>
  );
}
