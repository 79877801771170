import React, { useContext } from "react";
import carouselImg from "../../assets/auth/undraw_revenue_re_2bmg 1.png";
import checkmark from "../../assets/checkmark.png";
import classNames from './Payables.module.scss'
import { AppContext } from "../../context/AppState";

export default function WelcomeScreen() {
    const { application } = useContext(AppContext);
    return (
        <div className={classNames.welcomeScreen}>
            <div className={classNames.welcomeScreenLeft}>
                <img src={carouselImg} alt="carouselImg"  />
                <div>
                    <h3>Welcome to Otterz</h3>  
                    <h3>Accounts Payable</h3>  
                </div>
            </div>
            <div className={classNames.welcomeScreenRight}>
                <img src={checkmark} alt='checkmark'/>
                <h3>Hello {application?.username}</h3>
                <p>Thanks for signing up for Accounts Payable.</p>
                <p>Your access to Accounts Payable features will commence with the upcoming subscription cycle.</p>
            </div>
        </div>
    );
}
