import { Form, DatePicker as RangeDatePicker } from "antd";
import moment from "moment";
import { CTAButton, Modal } from "../../common";
import { FORMATS } from "../../constants";
import classNames from "./CustomDateFilter.module.scss";

const FilterFields = ({
  onCancel,
  setCustomQueryParams,
  getCustomQueryParams,
}) => {
  //Hooks
  const [form] = Form.useForm();

  const { from_date, to_date, filterType } = getCustomQueryParams();

  const { RangePicker } = RangeDatePicker;

  //Event handlers
  const handleFilter = (values) => {
    const input = {
      ...(values?.range && {
        from_date: moment(values?.range[0])
          .startOf("day")
          .format(FORMATS.API_SEND_FORMAT),
        to_date: moment(values?.range[1])
          .endOf("day")
          .format(FORMATS.API_SEND_FORMAT),
      }),
    };
    setCustomQueryParams(input);
    onCancel();
  };

  //JSX Element
  function disabledFutureDate(current) {
    return current && current >= moment();
  }

  return (
    <div className={classNames.modalBody}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={handleFilter}
        autoComplete="off"
      >
        <Form.Item
          label={<span className={classNames.label}>Filter by date</span>}
        >
          <Form.Item
            name="range"
            initialValue={
              from_date && to_date && !filterType
                ? [moment(from_date), moment(to_date)]
                : undefined
            }
          >
            <RangePicker
              disabledDate={disabledFutureDate}
              format={FORMATS.DATEPICKER_FORMAT}
              style={{ width: "380px", height: "40px" }}
            />
          </Form.Item>
        </Form.Item>

        <CTAButton
          htmlType="submit"
          type="primary"
          style={{ height: 44, width: 384 }}
        >
          Filter
        </CTAButton>
      </Form>
    </div>
  );
};

export default function CustomDateFilter({
  visible,
  onCancel,
  setCustomQueryParams,
  getCustomQueryParams,
  mainFilter,
  isForCard,
}) {
  return (
    <Modal
      destroyOnClose
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Custom Filter"
      className={classNames.modal}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <FilterFields
        onCancel={onCancel}
        setCustomQueryParams={setCustomQueryParams}
        mainFilter={mainFilter}
        getCustomQueryParams={getCustomQueryParams}
        isForCard={isForCard}
      />
    </Modal>
  );
}
