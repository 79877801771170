import classNames from "../Settings.module.scss";
import { Modal } from "../../common";
import { RiArrowRightLine } from "react-icons/ri";
import { Checkbox } from "antd";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import { RiArrowLeftSLine } from "react-icons/ri";

function SignInPreferences({ visible, onCancel }) {
  const [changePasswordVisibility, setChangePasswordVisibility] =
    useState(false);

  const PreferencesMenu = () => {
    return (
      <div>
        <div
          className={classNames.menuItem}
          onClick={() => setChangePasswordVisibility(true)}
        >
          <div>Change password</div>
          <RiArrowRightLine size={26} className={classNames.arrowBtnWithBg} />
        </div>
        <div className={classNames.menuItem} style={{ display: "none" }}>
          <Checkbox />
          <div>
            <div>Opt-In for OTP based sign In</div>
            <div>
              You will be sent an OTP to +1 800 *** 898 whenever you try to sign
              in. This can be changed in Settings {">"} Authentication
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title={
        changePasswordVisibility ? (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => setChangePasswordVisibility(false)}
          >
            <RiArrowLeftSLine size={22} />
            <span>Change password</span>
          </div>
        ) : (
          <span style={{ fontWeight: "bold" }}>
            {`Sign in preferences`}
          </span>
        )
      }
      className={classNames.modal}
      bodyStyle={{
        // height: "80vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={730}
    >
      {changePasswordVisibility ? <ChangePassword /> : <PreferencesMenu />}
    </Modal>
  );
}

export default SignInPreferences;
