export const cvvIfieldStyles = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
};

export const cvvIframeStyles = {
  border: "none",
  height: "50px",
  width: "200px",
};

export const cardIfieldStyles = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "168px",
};

export const cardIframeStyles = {
  border: "none",
  height: "50px",
  width: "190px",
};

export const achIfieldStyle = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "370px",
};

export const achIframeStyle = {
  border: "none",
  height: "50px",
  width: "400px",
};
