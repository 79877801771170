import { useState, useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Message } from "../../common";
import useLanguage from "../../../hooks/useLanguage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { messages } from "../util/messages.const";
import {Subscription} from "../../Auth/Subscription"
import mixpanel from "mixpanel-browser";

export default function PlansAndPreferences() {
  //Hooks
  const language = useLanguage(messages);

  const { userData } = useContext(AppContext);
  const [principlePlanId, ] = useState(null);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState(null);
  const queryClient = useQueryClient();

  //Api calls
  useQuery(
    "getSubscriptions",
    () => api.Settings.getSubscriptions(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    "getCurrentSubscriptionPlanId",
    () => api.Settings.getCurrentSubscriptionPlan(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setSelectedSubscriptionPlan(response?.payload);
        mixpanel.identify(userData?.email);
        if(response?.payload?.data === null) {
          mixpanel.people.set({
            "Subscription Status": "None",
          });
      } else {
          if (response?.payload?.data?.plan_name === "custom_starter") {
            mixpanel.people.set({
              "Subscription Status": "starter",
            });
          } else {
            mixpanel.people.set({
              "Subscription Status": response?.payload?.data?.plan_name,
            });
          }
      }
      },
    }
  );

  const subscription = useMutation(
    (reqBody) => api.Settings.changeSubscription(reqBody),
    {
      enabled: !!principlePlanId,
    }
  );

  const onSubmit = (reqBody) => {
    subscription.mutate(
      reqBody,
      {
        onSuccess: (resp) => {
          Message({
            type: "success",
            content: resp?.message || language.SUBSCRIPTION_ASSIGNMENT_SUCCESS,
          });
          setSelectedSubscriptionPlan(undefined);
          queryClient.invalidateQueries("getCurrentSubscriptionPlanId");
        },
        onError: (error) => {
          generateErrorMessage(error);
          queryClient.invalidateQueries("getCurrentSubscriptionPlanId");
        },
      }
    );
  };

  return (
    <Subscription onSubmit={onSubmit} selectedSubscriptionPlan={selectedSubscriptionPlan}/>
  )
}
