import moment from "moment";
import { useQuery } from "react-query";
import api from "../App/API";

const useInvoiceActiveStatus = () => {
  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getCompanyQuery } = useQuery(
    "getCompany",
    () =>
      api.Receivables.Company.getCompany(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData?.signInUserSession?.idToken?.payload?.icid,
      refetchOnWindowFocus: false,
    }
  );

  // nameValidation is used for the temporary fix. This should be removed once the
  // onboarding flow for invoice module move to invoice stack
  // Ticket: https://otterz1.atlassian.net/browse/OTT-4872
  // Same validation used on company details form on
  // Template settings > Edit Invoice template component
  const nameValidation = getCompanyQuery?.data?.name.match(/required.*/)
    ? true
    : false;

  let icidStatus =
    Boolean(localStorage.getItem("icupdated")) === true ||
    currentUserData?.signInUserSession?.idToken?.payload?.icidActive === "true"
      ? true
      : false;

  if (Boolean(nameValidation)) {
    icidStatus = false;
  }
  const { data: allInvoices } = useQuery(
    "getInvoices",
    () =>
      api.Receivables.Invoices.getInvoices(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: allEstimates } = useQuery(
    "getEstimates",
    () =>
      api.Receivables.Estimates.listEstimates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  function findInvoiceIntegrationStatus(estimates, invoices) {
    if (!Array.isArray(estimates) && !Array.isArray(invoices)) {
      return false;
    }
    let sortedEstimates =
      Array.isArray(estimates) && estimates.length > 0
        ? estimates.sort((a, b) => {
            return (
              new Date(moment(a.estimateDate).toLocaleString()).getTime() -
              new Date(moment(b.estimateDate).toISOString()).getTime()
            );
          })
        : [];
    let sortedInvoices =
      Array.isArray(invoices) && invoices.length > 0
        ? invoices.sort((a, b) => {
            return (
              new Date(moment(a.invoiceDate).toLocaleString()).getTime() -
              new Date(moment(b.invoiceDate).toISOString()).getTime()
            );
          })
        : [];
    let sortedArray = sortedEstimates.concat(sortedInvoices);
    let lastRecord =
      sortedArray[sortedArray.length - 1].estimateDate ||
      sortedArray[sortedArray.length - 1].invoiceDate;
    const priorDate = moment().add(-30, "days");
    let diff = moment(lastRecord).diff(priorDate, "days");
    if (Number(diff) < 0) {
      return false;
    } else return true;
  }
  let status =
    findInvoiceIntegrationStatus(allEstimates?.data, allInvoices?.data) &&
    icidStatus
      ? true
      : false;

  // Difference between status and icidStatus
  // icidStatus is true once the user completed the onboarding flow
  // status is calculated according to user activity in invoicing.
  // status attribute will be false if the user did not create any invoices or estimates for the past 30 days.
  return {
    status,
    icidStatus,
  };
};

export default useInvoiceActiveStatus;
