import { useContext, useMemo } from "react";
import { useLocation, matchPath } from 'react-router-dom';

import { AppContext } from "../context/AppState";
import SCOPE_TO_ROLE from "../App/constants/scopesToRoles";
import SUBSCRIPTIONS from "../App/constants/scopesToRoles/subscriptions";

function useScopeForRole(path) {
  const {
    userData,
  } = useContext(AppContext);
  const location = useLocation();
  const nextPath = path || location.pathname;
  const { roleName, planType } = userData;

  // get scope object available for the logged in user
  const userScopes = useMemo(() => {
    const scope = SCOPE_TO_ROLE?.[roleName];
    return scope ? Object.values(scope) : [];
  }, [roleName]);

  // get the scope object available for the route user about to navigate
  const scope = useMemo(() => userScopes.find(item => matchPath({ path: item.url }, nextPath)), [nextPath, userScopes]);

  // check user have access to the url based on the subscription
  const isInScope = useMemo(() => scope && (scope.subscriptions?.[0] === SUBSCRIPTIONS.ALL || scope.subscriptions.includes(planType)), [scope, planType]);

  return isInScope;
}

export default useScopeForRole;