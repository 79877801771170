import { Card, Spin } from "antd";
import { useQuery } from "react-query";
import { useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import ShareBoxFillIcon from "remixicon-react/ShareBoxFillIcon";
import FileList2LineIcon from "remixicon-react/FileList2LineIcon";
import { Title, CTAButton } from "../../../common";
import DownloadModal from "./DownloadModal/DownloadModal";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import generateErrorMessage from "../../../util/functions/customError";
import otterzLogo from "../../../../assets/logo-mark.svg";
// import otterzLogo from "../../../../assets/logo-mark.png";
import classNames from "../Overview.module.scss";

export default function BankAccountDetails() {
  //Hooks
  const [downloadBankLetter, setDownloadBankLetter] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadModalVisibility, setDownloadModalVisibility] = useState(false);
  const { application } = useContext(AppContext);

  //Api calls
  const { data: accountDetail, isLoading } = useQuery(
    "getBankLetterDetail",
    () =>
      api.BusinessBanking.getBankLetterDetail(
        application?.otz_account_id
      ),
    {
      refetchOnWindowFocus: true,
    }
  );

  useQuery(
    "getBankLetter",
    () =>
      api.BusinessBanking.getBankLetter(application?.otz_account_id),
    {
      onSuccess: (data) => {
        downloadPDF(data);
        setDownloadBankLetter(false);
      },
      onError: (err) => {
        generateErrorMessage(err);
        setDownloadBankLetter(false);
      },
      enabled: !!downloadBankLetter,
      refetchOnWindowFocus: false,
    }
  );

  //Event handlers
  const handleDownloadModalCancelClick = () => {
    setDownloadModalVisibility(false);
  };

  //Helper functions
  function downloadPDF(file) {
    const data = new Blob([file], { type: "application/pdf" });
    const url = window.URL.createObjectURL(data);
    const downloadLink = document.createElement("a");
    const fileName = `bank-letter-${accountDetail?.payload?.account_number}`;
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    try {
      mixpanel.track("Download Bank Letter", {
        fileName: fileName,  
      });
    } catch (error) {
      console.error("error: ", error)
    }
    setIsDownloading(false);
  }

  return isLoading ? (
    <Spin />
  ) : (
    <Card className={classNames.accountDetailsCard}>
      <div className={classNames.accountDetailsHeader}>
        <Title as="h3">Bank Account Details</Title>
        <ShareBoxFillIcon
          size={16}
          color="#7372FF"
          onClick={() => setDownloadModalVisibility(true)}
        />
      </div>
      <div className={classNames.accountDetails}>
        <div className={classNames.bankLogo}>
          <img src={otterzLogo} alt="logo" />
        </div>
        <div className={classNames.details}>
          <div className={classNames.accNo}>
            {accountDetail?.payload?.account_number ? (
              <>
                <span>Account Number</span>
                <span>{accountDetail?.payload?.account_number}</span>
              </>
            ) : null}
          </div>
          <div className={classNames.accNo}>
            {accountDetail?.payload?.customer_name && (
              <>
                <span>Account Holder</span>
                <span>{accountDetail?.payload?.customer_name}</span>
              </>
            )}
          </div>
          <div className={classNames.accNo}>
            {accountDetail?.payload?.routing_number && (
              <>
                <span>Routing Number</span>
                <span>{accountDetail?.payload?.routing_number} </span>
              </>
            )}
          </div>
        </div>
      </div>
      <CTAButton
        className={classNames.shareBtn}
        style={{
          height: 44,
          width: 330,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        loading={isDownloading}
        disable={!accountDetail?.payload?.account_number}
        onClick={() => {
          setIsDownloading(true);
          setDownloadBankLetter(true);
        }}
      >
        <FileList2LineIcon size={16} style={{ marginRight: 10 }} />
        Download Bank Letter
      </CTAButton>
      <DownloadModal
        visible={downloadModalVisibility}
        onCancel={handleDownloadModalCancelClick}
        accountData={accountDetail?.payload}
        setDownloadBankLetter={setDownloadBankLetter}
        isDownloading={isDownloading}
      />
    </Card>
  );
}
