export const types = {
  PERFORMING_SIGNUP: "PERFORMING_SIGNUP",
  VERIFICATION_SENT: "VERIFICATION_SENT",
  OPERATION_FAILED: "OPERATION_FAILED",
  PERFORM_EMAIL_VERIFICATION: "PERFORM_EMAIL_VERIFICATION",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  SEND_INDIVIDUAL_APPLICATION: "SEND_INDIVIDUAL_APPLICATION",
  SENT_INDIVIDUAL_APPLICATION: "SENT_INDIVIDUAL_APPLICATION",
  SENDING_INDIVIDUAL_APPLICATION_FAILED:
    "SENDING_INDIVIDUAL_APPLICATION_FAILED",
  PERFORMING_RESET_PASSWORD: "PERFORMING_RESET_PASSWORD",
  PERFORMED_RESET_PASSWORD_SUCCESS: "PERFORMED_RESET_PASSWORD_SUCCESS",
  SEND_BUSINESS_APPLICATION_FETCHING: "SEND_BUSINESS_APPLICATION_FETCHING",
  SENT_BUSINESS_APPLICATION_SUCCESS: "SENT_BUSINESS_APPLICATION_SUCCESS",
  SENDING_BUSINESS_APPLICATION_FAILED: "SENDING_INDIVIDUAL_APPLICATION_FAILED",
  PERFORMING_LOGIN: "PERFORMING_LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SUBMIT_FP_EMAIL_OTP_AND_PW: "SUBMIT_FP_EMAIL_OTP_AND_PW", // FP - Forgot Password
  SUBMIT_FP_EMAIL_OTP_AND_PW_SUCCESS: "SUBMIT_FP_EMAIL_OTP_AND_PW_SUCCESS",
  REQUEST_FP_EMAIL_OTP: "REQUEST_FP_EMAIL_OTP",
  REQUEST_FP_EMAIL_OTP_SUCCESS: "REQUEST_FP_EMAIL_OTP_SUCCESS",
  UPLOAD_DOCUMENTS_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENTS_FAILED: "UPLOAD_DOCUMENT_FAILED",
  UPLOAD_DOCUMENTS_UPLOADING: "UPLOAD_DOCUMENT_UPLOADING",
  RESEND_VERIFICATION_EMAIL_SUCCESS: "RESEND_VERIFICATION_EMAIL_SUCCESS",
  RESEND_VERIFICATION_EMAIL_FETCHING: "RESEND_VERIFICATION_EMAIL_FETCHING",
  RESEND_VERIFICATION_EMAIL_FAILED: "RESEND_VERIFICATION_EMAIL_FAILED",
  GET_BALANCE_SUCCESS: "GET_BALANCE_SUCCESS",
  GET_BALANCE_FETCHING: "GET_BALANCE_FETCHING",
  GET_BALANCE_FAILED: "GET_BALANCE_FAILED",
  ADD_FUNDS_SUCCESS: "ADD_FUNDS_SUCCESS",
  ADD_FUNDS_FETCHING: "ADD_FUNDS_FETCHING",
  ADD_FUNDS_FAILED: "ADD_FUNDS_FAILED",
};
