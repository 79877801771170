import classNames from "./Information.module.scss";
import InformationForm from "./InformationForm";
import { Title } from "../../../common";
import { useContext } from "react";
import { HPGContext } from "../Summary";

const Information = ({ isLoading, form }) => {
  const {
    fullName,
    xBillCompany,
    fullAddress,
    xBillPhone,
    xInvoice,
    xPONum,
    xDescription,
    xOrderID,
    infoEditable,
  } = useContext(HPGContext);

  if (infoEditable) {
    return <InformationForm isLoading={isLoading} form={form} />;
  }

  return (
    <div className={classNames.infoWrapper}>
      <div>
        <Title as="h3">Billing Information</Title>
        <div>
          {fullName && (
            <div>
              <div>Name</div>
              <div>{fullName}</div>
            </div>
          )}
          {xBillCompany && (
            <div>
              <div>Company</div>
              <div>{xBillCompany}</div>
            </div>
          )}
          {fullAddress && (
            <div>
              <div>Address</div>
              <div>{fullAddress}</div>
            </div>
          )}
          {xBillPhone && (
            <div>
              <div>Phone</div>
              <div>{xBillPhone}</div>
            </div>
          )}
        </div>
      </div>
      {(xInvoice || xPONum || xDescription || xOrderID) && (
        <div>
          <Title as="h3">Transaction Details</Title>
          <div>
            <div>
              {xInvoice && (
                <div>
                  <div>Invoice</div>
                  <div>{xInvoice}</div>
                </div>
              )}
              {xPONum && (
                <div>
                  <div>PO Number</div>
                  <div>{xPONum}</div>
                </div>
              )}
            </div>
            {xDescription && (
              <div>
                <div>Description</div>
                <div>{xDescription}</div>
              </div>
            )}
            {xOrderID && (
              <div>
                <div>Order ID</div>
                <div>{xOrderID}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Information;
