import {
  RiServerLine,
  RiUser3Line,
  RiNotification3Line,
  RiArrowRightCircleLine,
  // RiProfileLine,
  // RiSettingsLine,
  RiEqualizerLine,
  RiInformationLine,
} from "react-icons/ri";
import { Dropdown } from "antd";

import { ROUTES } from "../../constants";

import SettingsTopicCard from "./SettingsTopicCard/SettingsTopicCard";

export const cardListConfig = (classNames, methods, enums) => [
  {
    name: "Alerts & Notifications",
    component: (
      <div
        className={classNames.supportTopicCardDropdown}
        key="Alerts & Notifications"
      >
        <SettingsTopicCard
          topic="Alerts & Notifications"
          icon={
            <RiNotification3Line
              color="#7372FF"
              size={30}
              className={classNames.topicIcon}
            />
          }
          onClick={() => methods.setAlertsModalVisibility(true)}
          dropdown={false}
        />
      </div>
    ),
  },
  {
    name: "Sign In Preferences",
    component: (
      <Dropdown
        key="Sign In Preferences"
        overlay={enums.signInPreferenceList}
        placement="top"
        arrow
        className={classNames.supportTopicCardDropdown}
      >
        <div className={classNames.supportTopicCardDropdown}>
          <SettingsTopicCard
            topic="Sign In Preferences"
            icon={
              <RiArrowRightCircleLine
                color="#7372FF"
                size={30}
                className={classNames.topicIcon}
              />
            }
          />
        </div>
      </Dropdown>
    ),
  },
  // {
  //   name: "User Profile",
  //   component: (
  //     <div className={classNames.supportTopicCardDropdown} key="User Profile">
  //       <SettingsTopicCard
  //         topic="User Profile"
  //         icon={
  //           <RiProfileLine
  //             color="#7372FF"
  //             size={30}
  //             className={classNames.topicIcon}
  //           />
  //         }
  //         onClick={() => methods.setUserProfileVisibility(true)}
  //         dropdown={false}
  //       />
  //     </div>
  //   ),
  // },
  {
    name: "Plans & Preferences",
    component: (
      <div
        className={classNames.supportTopicCardDropdown}
        key="Plans & Preferences"
      >
        <SettingsTopicCard
          topic="Plans & Preferences"
          icon={
            <RiEqualizerLine
              color="#7372FF"
              size={30}
              className={classNames.topicIcon}
            />
          }
          onClick={() => methods.navigate(ROUTES.APP.SETTINGS.PLAN_PREFERENCES)}
          dropdown={false}
        />
      </div>
    ),
  },
  {
    name: "General Information",
    component: (
      <div
        className={classNames.supportTopicCardDropdown}
        key="General Information"
      >
        <SettingsTopicCard
          topic="General Information"
          icon={
            <RiInformationLine
              color="#7372FF"
              size={30}
              className={classNames.topicIcon}
            />
          }
          onClick={() =>
            methods.navigate(ROUTES.APP.SETTINGS.GENERAL_INFORMATION)
          }
          dropdown={false}
        />
      </div>
    ),
  },
  // {
  //   name: "Upload documents",
  //   component: (
  //     <div
  //       className={classNames.supportTopicCardDropdown}
  //       key="Upload documents"
  //     >
  //       <SettingsTopicCard
  //         topic="Upload documents"
  //         icon={
  //           <RiSettingsLine
  //             color="#7372FF"
  //             size={30}
  //             className={classNames.topicIcon}
  //           />
  //         }
  //         onClick={() =>
  //           methods.navigate(ROUTES.APP.SETTINGS.UPLOAD_BUSINESS_DOCS)
  //         }
  //         dropdown={false}
  //       />
  //     </div>
  //   ),
  // },
  {
    name: "Data Source",
    component: (
      <div className={classNames.supportTopicCardDropdown} key="Data Source">
        <SettingsTopicCard
          topic="Data Source"
          comingSoon={true}
          icon={
            <RiServerLine
              color="#9BA3AB"
              size={30}
              className={classNames.topicIcon}
            />
          }
          dropdown={false}
        />
      </div>
    ),
  },
  {
    name: "User Management",
    component: (
      <div
        className={classNames.supportTopicCardDropdown}
        key="User Management"
      >
        <SettingsTopicCard
          topic="User Management"
          comingSoon={true}
          icon={
            <RiUser3Line
              color="#7372FF"
              size={30}
              className={classNames.topicIcon}
            />
          }
          // onClick={() => methods.navigate(ROUTES.APP.SETTINGS.USER_MANAGEMENT)}
          dropdown={false}
        />
      </div>
    ),
  },
  // {
  //   name: "ATM Locator",
  //   component: (
  //     <div className={classNames.supportTopicCardDropdown} key="ATM Locator">
  //       <SettingsTopicCard
  //         topic="ATM Locator"
  //         comingSoon={true}
  //         icon={
  //           <RiBankCardLine
  //             color="#7372FF"
  //             size={30}
  //             className={classNames.topicIcon}
  //           />
  //         }
  //         onClick={() => methods.navigate(ROUTES.APP.SUPPORT.ATM_LOCATOR)}
  //         dropdown={false}
  //       />
  //     </div>
  //   ),
  // },
];
