import flattenObject from "../../util/functions/flattenObject";
import ROUTES from "../routes";

/*
  here we are taking all the paths in the routes constant and flat the nesting structure.
  So we can use this in the scopeToRole file. I did this because we don't need to duplicate
  effort of adding same entry in this file and scopesToRoles file.
*/
const flatten = new flattenObject({ ROUTES });

const SCOPES = {
  ...Object.entries(flatten.byKey('.')).reduce((list, [key, value]) => {
    list[key] = {
      name: key,
      url: value,
      subscriptions: [],
      components: {}
    };
    return list;
  }, {}),
};

Object.defineProperty(SCOPES, "setScopeFor", {
  value: (key, value) => {
    const updatedScope = {};

    if (SCOPES[key]) {
      updatedScope[key] = {
        ...SCOPES[key],
        ...value,
      }

      return updatedScope;
    }

    return {};
  }
});

export default SCOPES;