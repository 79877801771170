import { useState } from "react";
import classNames from "./Customers.module.scss";
import { Input, Header, Select, Message } from "../../../common";
import {
  Card,
  Table,
  Space,
  Input as AntInput,
  Button as AntButton,
} from "antd";
import { RiArrowRightLine, RiEditLine, RiDeleteBin7Line } from "react-icons/ri";
import { BsCreditCard } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ManageCustomer from "./ManageCustomer/ManageCustomer";
import { useMutation, useQuery } from "react-query";
import api from "../../../API";
import DeleteCustomer from "./DeleteCustomer";
// import ManagePaymentMethod from "./ManagePaymentMethod/ManagePaymentMethod";
import generateErrorMessage from "../../../util/functions/customError";
import { SearchOutlined } from "@ant-design/icons";
import AddNewTransactions from "../Transactions/AddNewTransaction/AddNewTransactions";

export default function Customers() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("CustomerId");
  const [updatingCustomer, setUpdatingCustomer] = useState(null);
  const [visibleCustomerModel, setVisibleCustomerModel] = useState(false);
  const [deletingCustomerId, setDeletingCustomerId] = useState(null);
  const [cardknoxCustomers, setCardknoxCustomers] = useState([]);
  // const [visiblePaymentMethodModel, setVisiblePaymentMethodModel] =
  //   useState(false);
  const [visibleTransactionModel, setVisibleTransactionModel] = useState(false);
  const [paginationToken, setPaginationToken] = useState("");
  const [nextToken, setNextToken] = useState("");
  const [prevToken, setPrevToken] = useState("");
  const [prevCount, setPrevCount] = useState(0);
  const [filterValue, setFilterValue] = useState("");

  const fetchCustomers = (filterValue) => {
    const Filters = {};
    if (filterValue && typeof filterValue === "string" && !!filter) {
      if (filter === "Street") {
        Filters.BillStreet = filterValue;
        Filters.BillStreet2 = filterValue;
      } else {
        Filters[filter] = filterValue;
      }
    }

    return api.Receivables.PaymentAcceptance.getCardknoxCustomers({
      NextToken: paginationToken,
      PageSize: 10,
      SortOrder: "Descending",
      Filters,
    });
  };

  const { isLoading } = useQuery(
    ["getCardknoxCustomers", { paginationToken: paginationToken }],
    () => fetchCustomers(filterValue),
    {
      onSuccess: (data) => {
        setCardknoxCustomers(data?.data?.Customers);
        setNextToken(data?.data?.NextToken);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: fetchFilteredCustomers } = useMutation(
    async (filterValue) => await fetchCustomers(filterValue),
    {
      onSuccess: (data) => {
        setCardknoxCustomers(data?.data?.Customers);
        setNextToken(data?.data?.NextToken);
      },
    }
  );

  const { mutate: deleteCardknoxCustomer, isLoading: isDeleting } = useMutation(
    async (CustomerId) => {
      return await api.Receivables.PaymentAcceptance.deleteCardknoxCustomer({
        CustomerId,
      });
    },
    {
      onSuccess: () => {
        Message({ type: "success", content: "Customer successfully deleted" });
        setDeletingCustomerId(null);
        fetchFilteredCustomers();
      },
      onError: (error) => {
        setDeletingCustomerId(null);
        generateErrorMessage(error);
      },
    }
  );

  const nextButton = () => {
    setPrevToken([...prevToken, paginationToken]);
    setPaginationToken(nextToken);
    setPrevCount(prevCount + 1);
  };

  const prevButton = () => {
    let indexValue = prevCount - 1;
    setPrevCount(indexValue);
    setPaginationToken(prevToken[indexValue]);
  };

  const onCustomerModelClose = (shouldFetch) => {
    setVisibleCustomerModel(false);
    setUpdatingCustomer(null);
    if (typeof shouldFetch === "boolean" && shouldFetch)
      fetchFilteredCustomers();
  };

  // const onPaymentMethodModelClose = (shouldFetch) => {
  //   setVisiblePaymentMethodModel(false);
  //   setUpdatingCustomer(null);
  //   if (typeof shouldFetch === "boolean" && shouldFetch)
  //     fetchFilteredCustomers();
  // };

  const onVisibleTransactionModelClose = (shouldFetch) => {
    setVisibleTransactionModel(false);
    setUpdatingCustomer(null);
    if (typeof shouldFetch === "boolean" && shouldFetch)
      fetchFilteredCustomers();
  };

  const onDeleteCancel = () => setDeletingCustomerId(null);
  const onDeleteConfirm = () => {
    deleteCardknoxCustomer(deletingCustomerId);
  };

  const columns = [
    {
      title: "Customer ID",
      dataIndex: "CustomerId",
      key: "CustomerId",
    },
    // {
    //   title: "Customer #",
    //   dataIndex: "CustomerNumber",
    //   key: "CustomerNumber",
    // },

    {
      title: "First Name",
      dataIndex: "BillFirstName",
      key: "BillFirstName",
    },
    {
      title: "Last Name",
      dataIndex: "BillLastName",
      key: "BillLastName",
    },
    {
      title: "Company",
      dataIndex: "BillCompany",
      key: "BillCompany",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (t, r, i) => {
        const {
          BillStreet = "",
          BillStreet2 = "",
          BillCity = "",
          BillState = "",
          BillZip = "",
          BillCountry = "",
        } = cardknoxCustomers[i];
        return [
          BillStreet,
          BillStreet2,
          BillCity,
          BillState,
          BillZip,
          BillCountry,
        ]
          .filter((part) => !!part)
          .join(", ");
      },
    },
    {
      title: "Phone",
      dataIndex: "BillPhone",
      key: "BillPhone",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Payment Method Status",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (t, r, i) =>
        cardknoxCustomers[i]?.DefaultPaymentMethodId
          ? "Available"
          : "Not available",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (t, r, i) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {" "}
          <Space size="middle">
            <BsCreditCard
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={() => {
                setUpdatingCustomer(cardknoxCustomers[i]);
                // setVisiblePaymentMethodModel(true);
                setVisibleTransactionModel(true);
              }}
            />
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={() => {
                setUpdatingCustomer(cardknoxCustomers[i]);
                setVisibleCustomerModel(true);
              }}
            />
            <RiDeleteBin7Line
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={() =>
                setDeletingCustomerId(cardknoxCustomers[i]?.CustomerId)
              }
            />
          </Space>
        </Space>
      ),
    },
  ];

  const selectHandleChange = (value) => {
    setFilter(value);
  };

  const onSearchChange = (e) => {
    setFilterValue(e.target.value);

    if (!e.target.value) {
      onSearchClick();
    }
  };

  const onSearchClick = () => {
    setNextToken("");
    setPrevToken("");
    setPrevCount(0);
    setPaginationToken("");
    fetchFilteredCustomers(filterValue);
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Customers" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.transactionFilters}>
              <AntInput.Group className={classNames.search} compact>
                <Select
                  placeholder="Filter by"
                  className={classNames.filterSelect}
                  onChange={selectHandleChange}
                  defaultValue={"CustomerId"}
                >
                  <Select.Option value="CustomerId">Customer Id</Select.Option>
                  {/* <Select.Option value="CustomerNumber">
                    Customer Number
                  </Select.Option> */}
                  <Select.Option value="BillFirstName">
                    First Name
                  </Select.Option>
                  <Select.Option value="BillLastName">Last Name</Select.Option>
                  <Select.Option value="BillCompany">Company</Select.Option>
                  <Select.Option value="BillPhone">Phone</Select.Option>
                  <Select.Option value="Email">Email</Select.Option>
                </Select>
                <Input
                  style={{
                    width: "65%",
                    padding: "0",
                  }}
                  className={classNames.filterInput}
                  placeholder="Please enter"
                  onChange={(e) => onSearchChange(e)}
                  addonAfter={
                    <SearchOutlined onClick={() => onSearchClick()} />
                  }
                  onPressEnter={() => onSearchClick()}
                />
              </AntInput.Group>
            </div>
            <Card
              className={classNames.optionCard}
              onClick={() => setVisibleCustomerModel(true)}
            >
              <div className={classNames.cardAction}>
                <span>Add New Customers</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            {/* NOTE: COMMENTED FOR FUTURE USE */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              Displaying 50 of 205 transactions
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            />

            <Table
              loading={isLoading}
              size="large"
              dataSource={cardknoxCustomers}
              columns={columns}
              className={classNames.table}
              pagination={false}
            />
            <div style={{ textAlign: "center", margin: 20 }}>
              <AntButton
                style={{ marginRight: 10 }}
                onClick={prevButton}
                disabled={prevCount === 0 || isLoading}
              >
                Prev
              </AntButton>
              <AntButton
                onClick={nextButton}
                disabled={isLoading || !nextToken}
              >
                Next
              </AntButton>
            </div>
          </div>
        </div>
      </div>
      {!!deletingCustomerId && (
        <DeleteCustomer
          visible={!!deletingCustomerId}
          onCancel={onDeleteCancel}
          onConfirm={onDeleteConfirm}
          isDeleting={isDeleting}
        />
      )}
      {visibleCustomerModel && (
        <ManageCustomer
          visible={visibleCustomerModel}
          onCustomerModelClose={onCustomerModelClose}
          updatingCustomer={updatingCustomer}
        />
      )}
      {/* {visiblePaymentMethodModel && (
        <ManagePaymentMethod
          visible={visiblePaymentMethodModel}
          onPaymentMethodModelClose={onPaymentMethodModelClose}
          customer={updatingCustomer}
        />
      )} */}
      {visibleTransactionModel && (
        <AddNewTransactions
          visible={visibleTransactionModel}
          onTransactionModelClose={onVisibleTransactionModelClose}
          customer={updatingCustomer}
          existCustomer={true}
          title="Add New Transaction"
          isCustomerPage={true}
        />
      )}
    </div>
  );
}
