import { API } from "aws-amplify";

export default async function editProduct(data) {
  return await API.put("product", "", {
    // queryStringParameters: {
    //   invoiceId: data.id
    // },
    body: {...data},
  });
}
