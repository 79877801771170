import { Avatar, Card, Form, Input, Spin } from "antd";
import { createContext, useEffect, useMemo, useState } from "react";
import { BsDot } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import { default as OtterzLogo } from "../../../assets/logo.svg";
import user from "../../../assets/user.png";
import useIFieldKey from "../../../hooks/useIFieldKey";
import { useStoreContext } from "../../../store";
import api from "../../API";
import Error from "./Error/Error";
import Information from "./Information/Information";
import PaymentDetails from "./PaymentDetails/PaymentDetails";
import Success from "./Success/Success";
import SummaryCollapse from "./SummaryCollapse/SummaryCollapse";
import constants from "./summary.const";
import REGEX from "../../util/constants/regex";
import { ROUTES } from "../../constants";
import { DISCLOSURES_TYPE } from "../../Disclosure/Disclosure.constant";
import classNames from "./Summary.module.scss";

export const HPGContext = createContext();

const getErrors = (
  base64PassError,
  isPASettings,
  companyId,
  tenantId,
  isLoading,
  companyName,
  iFieldKey,
  message,
  errMessage
) => {
  let errorTitle;
  let errorMessage;

  if (base64PassError) {
    errorTitle = constants.strings.errors.invalidPayment.title;
    errorMessage = constants.strings.errors.invalidPayment.message;
  } else if (!isPASettings) {
    if (!companyId && !tenantId) {
      errorTitle = constants.strings.errors.incompletePayment.title;
      errorMessage = constants.strings.errors.incompletePayment.message;
    } else if (!isLoading) {
      if (errMessage && !message) {
        errorTitle = constants.strings.errors.invalidLink.title;
        errorMessage = constants.strings.errors.invalidLink.message;
      } else if (!companyName) {
        errorTitle = constants.strings.errors.invalidMerchant.title;
        errorMessage = constants.strings.errors.invalidMerchant.message;
      } else if (!iFieldKey) {
        errorTitle = constants.strings.errors.infoNotFound.title;
        errorMessage = constants.strings.errors.infoNotFound.message;
      }
    }
  }

  return { errorTitle, errorMessage };
};

const Summary = () => {
  const [queryParams] = useSearchParams();

  const { state: paymentAcceptanceSettings } = useStoreContext(
    (state) => state.paymentAcceptanceSettings
  );
  const isPASettings = !(
    window.atob(queryParams.get("paSettings") || "ZmFsc2U=") === "false"
  );

  const [base64PassError, setBase64PassError] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  const [xBillFirstName, setXBillFirstName] = useState("");
  const [xBillMiddleName, setXBillMiddleName] = useState("");
  const [xBillLastName, setXBillLastName] = useState("");
  const [xBillCompany, setXBillCompany] = useState("");
  const [xBillStreet, setXBillStreet] = useState("");
  const [xBillStreet2, setXBillStreet2] = useState("");
  const [xBillCity, setXBillCity] = useState("");
  const [xBillState, setXBillState] = useState("");
  const [xBillZip, setXBillZip] = useState("");
  const [xBillCountry, setXBillCountry] = useState("");
  const [id, setId] = useState("");

  const [xBillPhone, setXBillPhone] = useState("");
  const [xEmail, setXEmail] = useState("");
  const [xInvoice, setXInvoice] = useState("");
  const [xPONum, setXPONum] = useState("");
  const [xDescription, setXDescription] = useState("");
  const [xOrderID, setXOrderID] = useState("");

  const [amount, setAmount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [message, setMessage] = useState();
  const [errMessage, setErrMessage] = useState();

  const [amountEditable, setAmountEditable] = useState(false);
  const [infoEditable, setInfoEditable] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [isShippingIsSameAsBilling, setShippingIsSameAsBilling] =
    useState(true);

  const { mutate } = useMutation(
    (data) => api.Receivables.PaymentAcceptance.checkStatus(data),
    {
      refetchOnWindowFocus: false,
      enabled: queryParams.get("id") || "",
      onSuccess: (data) => {
        setMessage(data?.message);
      },
      onError: (error) => {
        setErrMessage(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    try {
      mutate({ uuid: queryParams.get("id") || "" });
      setId(queryParams.get("id") || "");
      setCompanyId(window.atob(queryParams.get("companyId") || ""));
      setTenantId(window.atob(queryParams.get("tenantId") || ""));
      setInvoiceId(window.atob(queryParams.get("invoiceId") || ""));
      setXBillFirstName(window.atob(queryParams.get("firstName") || ""));
      setXBillMiddleName(window.atob(queryParams.get("middleName") || ""));
      setXBillLastName(window.atob(queryParams.get("lastName") || ""));
      setXBillCompany(window.atob(queryParams.get("companyName") || ""));
      setXBillStreet(window.atob(queryParams.get("addressStreet") || ""));
      setXBillStreet2(window.atob(queryParams.get("addressStreet2") || ""));
      setXBillCity(window.atob(queryParams.get("addressCity") || ""));
      setXBillState(window.atob(queryParams.get("addressState") || ""));
      setXBillZip(window.atob(queryParams.get("addressZip") || ""));
      setXBillCountry(window.atob(queryParams.get("addressCountry") || ""));
      setXBillPhone(window.atob(queryParams.get("phone") || ""));
      setXEmail(window.atob(queryParams.get("email") || ""));
      setXInvoice(window.atob(queryParams.get("invoiceNo") || ""));
      setXPONum(window.atob(queryParams.get("poNumber") || ""));
      setXDescription(window.atob(queryParams.get("description") || ""));
      setXOrderID(window.atob(queryParams.get("orderId") || ""));
      setAmount(
        Number.parseFloat(
          window.atob(queryParams.get("amount") || window.btoa("0"))
        )
      );
      setTransactionFee(
        Number.parseFloat(
          window.atob(queryParams.get("transactionFee") || window.btoa("0"))
        )
      );
      setAmountEditable(
        !(
          window.atob(queryParams.get("amountEditable") || "ZmFsc2U=") ===
          "false"
        )
      );
      setInfoEditable(
        !(
          window.atob(queryParams.get("infoEditable") || "ZmFsc2U=") === "false"
        )
      );
      setRedirectURL(window.atob(queryParams.get("redirectURL") || ""));
    } catch (error) {
      setBase64PassError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const fullName =
    useMemo(
      () =>
        [xBillFirstName, xBillMiddleName, xBillLastName]
          .filter((namePart) => !!namePart)
          .join(" ")
          .trim(),
      [xBillFirstName, xBillMiddleName, xBillLastName]
    ) || "";

  let fullAddress =
    useMemo(
      () =>
        [
          xBillStreet,
          xBillStreet2,
          xBillCity,
          xBillState,
          xBillZip,
          xBillCountry,
        ]
          .filter((addressPart) => !!addressPart)
          .join(", "),
      [xBillStreet, xBillStreet2, xBillCity, xBillState, xBillZip, xBillCountry]
    ) || "";
  if (!!fullAddress) fullAddress = fullAddress.concat(".");

  const xAmount = Number.parseFloat(amount) + Number.parseFloat(transactionFee);

  const [form] = Form.useForm();

  const { data: getPASettings, isLoading: getPASettingsLoading = false } =
    useQuery(
      "getPASettings",
      () =>
        api.Receivables.PaymentAcceptance.getPASettings({
          companyId,
          tenantId,
        }),
      {
        refetchOnWindowFocus: false,
        enabled:
          !base64PassError && !isPASettings && (!!tenantId || !!companyId),
      }
    );

  const {
    companyName = "",
    address = "",
    email = "",
    phoneNumber = "",
    logo = "",
  } = isPASettings ? paymentAcceptanceSettings : getPASettings?.data || {};

  const { iFieldKey, iFieldLoading = false } = useIFieldKey(
    tenantId,
    companyId,
    () => !base64PassError && !isPASettings && (!!tenantId || !!companyId)
  );

  const [successTransaction, setSuccessTransaction] = useState(null);

  const isLoading = getPASettingsLoading || iFieldLoading;

  const { errorTitle, errorMessage } = getErrors(
    base64PassError,
    isPASettings,
    companyId,
    tenantId,
    isLoading,
    companyName,
    iFieldKey,
    message,
    errMessage
  );
  
  return (
    <Spin spinning={isLoading} size="large" style={{ height: "100vh" }}>
      <div style={{ height: "100vh", display: "flex" }}>
        {errorTitle && <Error title={errorTitle} message={errorMessage} />}
        {!isLoading && !errorTitle && (
          <HPGContext.Provider
            value={{
              companyId,
              tenantId,
              invoiceId,
              iFieldKey,
              xBillFirstName,
              xBillMiddleName,
              xBillLastName,
              fullName,
              xBillCompany,
              xBillStreet,
              xBillStreet2,
              xBillCity,
              xBillState,
              xBillCountry,
              xBillZip,
              fullAddress,
              xBillPhone,
              xEmail,
              xInvoice,
              xPONum,
              xDescription,
              xOrderID,
              amount,
              transactionFee,
              amountEditable,
              infoEditable,
              redirectURL,
              isShippingIsSameAsBilling,
              setXBillFirstName,
              setXBillMiddleName,
              setXBillLastName,
              setXBillCompany,
              setXBillStreet,
              setXBillStreet2,
              setXBillCity,
              setXBillState,
              setXBillCountry,
              setXBillZip,
              setXBillPhone,
              setXEmail,
              setXInvoice,
              setXPONum,
              setXDescription,
              setXOrderID,
              setAmount,
              setTransactionFee,
              setAmountEditable,
              setInfoEditable,
              setRedirectURL,
              setSuccessTransaction,
              setShippingIsSameAsBilling,
            }}
          >
            <div className={classNames.wrapper}>
              <div className={classNames.contentWrapper}>
                <div className={classNames.header}>
                  <div>
                    <div>Make Payment to</div>
                    <div>
                      <Avatar className={classNames.logo} src={logo || user} />
                    </div>
                  </div>
                  <div className={classNames.companyInfo}>
                    {companyName && <div>{companyName}</div>}
                    {address && (
                      <div>
                        <span>Address: </span>
                        {address}
                      </div>
                    )}
                    {email && (
                      <div>
                        <span>Email: </span>
                        {email}
                      </div>
                    )}
                    {phoneNumber && <div>{phoneNumber}</div>}
                  </div>
                </div>
                {!!successTransaction ? (
                  <Success refNum={successTransaction?.data?.xRefNum} />
                ) : (
                  <Card className={classNames.paymentCard}>
                    {(fullName || xBillCompany || fullAddress || xBillPhone || xInvoice || xPONum || xDescription || xOrderID || infoEditable) && (
                      <div className={classNames.paymentCardInfo}>
                        <Information
                          isLoading={iFieldLoading || getPASettingsLoading}
                          form={form}
                        />
                      </div>
                    )}
                    <div className={classNames.paymentCardForm}>
                      <div className={classNames.paymentAmount}>
                        <div>Amount</div>
                        <div>
                          <div>
                            <Form
                              layout="vertical"
                              hideRequiredMark
                              form={form}
                              style={{ display: "inline-flex" }}
                              autoComplete="off"
                              scrollToFirstError={true}
                            >
                              <span>$</span>

                              <Form.Item
                                name="amountValue"
                                initialValue={Number(amount).toFixed(2)}
                                rules={[
                                  {
                                    required: true,
                                    message: "Amount is required",
                                  },
                                  {
                                    pattern: new RegExp(REGEX.amount),
                                    message: "Enter a valid amount",
                                  },
                                ]}
                                style={{
                                  borderBottom: "1px solid #7372ff",
                                  paddingBottom: 10,
                                }}
                              >
                                <Input
                                  className={classNames.amountInput}
                                  type="number"
                                  style={{ width: "240px" }}
                                  bordered={false}
                                  disabled={!amountEditable}
                                  onChange={(e) => {
                                    form
                                      .validateFields()
                                      .then(() => {
                                        console.log("value", e.target.value);
                                      })
                                      .catch((error) => {
                                        console.error("Validate Failed:", {
                                          error,
                                        });
                                        return;
                                      });
                                    setAmount(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          </div>
                          <div>
                            Net payable $
                            {amount ? Number(amount).toFixed(2) : "0.00"}
                          </div>
                        </div>
                      </div>
                      <SummaryCollapse
                        amount={amount}
                        transaction={transactionFee}
                        total={xAmount}
                      />
                      <PaymentDetails
                        payable={xAmount}
                        form={form}
                        isLoading={iFieldLoading || getPASettingsLoading}
                        id={id}
                        key={id}
                        email={email}
                        setMessage={setMessage}
                        setErrMessage={setErrMessage}
                      />
                    </div>
                  </Card>
                )}
                <div className={classNames.footer}>
                  <div>
                    <span>Powered by</span>
                    <img alt="logo" src={OtterzLogo} width={100} />
                  </div>
                  <div>
                    <a
                      // href={`${
                      //   new URL(window.location.href).origin
                      // }${ROUTES.DISCLOSURE_DETAIL?.replace(
                      //   ":param",
                      //   DISCLOSURES_TYPE[0].param
                      // )}`}
                      href={DISCLOSURES_TYPE[0].link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Terms</span>
                    </a>
                    <BsDot color="rgba(0, 0, 0, 0.5)" />
                    <a
                      href={`${
                        new URL(window.location.href).origin
                      }${ROUTES.DISCLOSURE_DETAIL?.replace(
                        ":param",
                        DISCLOSURES_TYPE[2].link
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Privacy</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </HPGContext.Provider>
        )}
      </div>
    </Spin>
  );
};

export default Summary;
