import { useState } from "react";
import { RadialChart } from "react-vis";
import { Empty } from "antd";
import { centsToDollar } from "../../../util/functions/dollarConvertion";
import classNames from "../ManageCards.module.scss";

export default function CategoryWiseSpend({
  displayData: { formattedSummary, totalAmount },
}) {
  const [hoveredCell, setHoveredCell] = useState(false);

  return (
    <div>
      {formattedSummary ? (
        Boolean(formattedSummary) && (
          <div className={classNames.categoryWiseSpendWrapper}>
            {
              <RadialChart
                animation={true}
                colorType="literal"
                className={classNames}
                data={formattedSummary}
                width={300}
                height={300}
                innerRadius={55}
                radius={60}
                onValueMouseOver={(v) => setHoveredCell(v.x && v.y ? v : false)}
                onValueMouseOut={(v) => setHoveredCell(false)}
              >
                <div className={classNames.radialChartInner}>
                  <span>Card Spend</span>
                  <span
                    style={
                      totalAmount && totalAmount >= 100
                        ? { fontSize: "10px" }
                        : undefined
                    }
                  >
                    {totalAmount && centsToDollar(totalAmount)}
                  </span>
                </div>
              </RadialChart>
            }

            <div className={classNames.spentCategoriesList}>
              {formattedSummary &&
                formattedSummary.length > 0 &&
                formattedSummary.map((item) => (
                  <div
                    key={item.color}
                    className={classNames.spentCategory}
                    data-active={item.label === hoveredCell.label}
                  >
                    <span style={{ background: `${item.color}` }}></span>
                    <div>
                      <span>{item.label}</span>
                      <span>{centsToDollar(item.amount) || "$0.00"}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      ) : (
        <div>
          <Empty
            description={
              <span style={{ color: "#9ba3ab" }}>No Transactions Made</span>
            }
          />
        </div>
      )}
    </div>
  );
}
