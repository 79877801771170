import React from "react";

const CardholderAgreement = () => {
  return (
    <>
      <p>
        As used in this agreement, the words “we”, “our” and “us” means Otterz
        and the words “you” and “your” means the owner, officer or employee of
        the business who has been issued a Otterz Business Debit Card. The word
        “Card” refers to Otterz Business Debit Card. The terms of this
        Disclosure Statement / Account Agreement are in addition to the Terms of
        Service you received upon opening your account. By signing, using and
        accepting the Card or by allowing anyone to use your Card, you agree to
        the terms and conditions of this Disclosure Statement / Account
        Agreement and that you will be responsible for all authorized and
        unauthorized transactions conducted using your Card.
      </p>
      <ol>
        <li>
          <div>
            <b>Issuance of Cards:</b>{" "}
            <span>
              You acknowledge that the Otterz is providing such service to You
              as an accommodation party only and except as otherwise provided by
              law, Otterz is not responsible in any way for the manner in which
              the Card(s) are utilized.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Use of your Card:</b>{" "}
            <span>
              When you use your card to make an authorized transaction, you are
              authorizing us to withdraw funds from your account for the amount
              of the transaction. You will be assigned, or may select a Personal
              Identification Number (“PIN”) for use with your card. You
              acknowledge that the PIN which you use with your card is an
              identification code that is personal and confidential and that the
              use of the PIN is a security method by which we are helping you to
              maintain the security of the account(s). Therefore, YOU AGREE TO
              TAKE ALL REASONABLE PRECAUTIONS THAT NO ONE ELSE LEARNS YOUR PIN.
            </span>
          </div>
          <ol className="lower-alpha">
            <li>
              <span>
                Mastercard POS Purchases. You may use your Card to purchase
                goods and services from any retail establishment (“Merchant”)
                that accepts Mastercard business cards. If you give your Card
                number to make a purchase without presenting the Card (such as
                Internet purchases), we will deduct the transaction from your
                primary checking account.
              </span>
            </li>
            <li>
              <span>
                When you use your Card in person to make a purchase at a
                Mastercard merchant that accepts Mastercard Business Debit
                Cards, the merchant will obtain authorization for the amount of
                the transaction and if approved, we will reduce the amount of
                the available balance in your account by placing a temporary
                hold for the amount of the transaction. When the actual
                transaction is processed by us, your account will be debited and
                the temporary hold will be released. You agree to hold us
                harmless if we dishonor other POS or ATM transactions while the
                temporary hold is in place.
              </span>
            </li>
            <li>
              <span>
                ATM Transactions: You may use your Card with your PIN at
                designated ATMs to withdraw cash.
              </span>
            </li>
            <li>
              <span>
                Purchase (POS) Transaction. You may use your ATM Card with your
                PIN at certain retail establishments (“Merchant”) to purchase
                goods and services and/or to obtain cash where permitted by the
                Merchant (“Purchase”). The amount of such Purchases (including
                any cash obtained) will be deducted from your primary account.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <div>
            <b>Limitations on Card Use:</b>
            <ol className="lower-alpha">
              <li>
                <span>
                  The maximum daily amount you may withdraw in cash each day
                  and/or to make purchases at designated ATMs and retail
                  establishments (Merchants) will be up to $1000 each business
                  day. This limit is provided the funds are available in your
                  account.
                </span>
              </li>
            </ol>
          </div>
          <p>
            The day for withdrawal limits starts at 8:00am each business day and
            ends at 8:00am the next business day. Weekends, including three day
            holidays are considered “one business day”. There are also certain
            limitations on the frequency of use of your Card each business day.
            These limitations are not revealed for security reasons.
          </p>
          <p>
            You will be denied the use of the Card if you exceed the daily
            withdrawal or purchase limits, do not have adequate funds available
            in your account, you do not enter the correct PIN or you exceed the
            frequency of usage limitation. There is a limit on the number of
            such denials permitted.
          </p>
          <p>
            For your security, if we notice excessive use of your Card that
            appears suspicious, we may restrict further use of your Card until
            we confirm the activity with you.
          </p>
        </li>
        <li>
          <div>
            <b>Business Card Purpose:</b>{" "}
            <span>
              You and any cardholder agree that this Card is for use by business
              owners and employees. The Card can be used for business purpose
              point-of-sale and ATM transactions only. The Card may not be used
              for personal purposes. You acknowledge and understand that the
              card shall not be treated as a consumer card under the provisions
              of state and federal law. You agree to provide instructions to all
              Cardholders that the Card shall not be used for consumer purposes.
              We assume all transactions are for business purposes. We do not
              monitor transactions to determine their purpose.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Charges for Transactions:</b>{" "}
            <span>
              You agree to pay any service charges applicable to your card. You
              should refer to our fee schedule and/or your account disclosure
              for the charges currently in effect. We reserve the right to
              change our fee schedule from time to time and to charge your
              account in accordance with the fee schedule that will be provided
              to you at least thirty (30) days prior to the implementation date.
            </span>
          </div>
          <p>
            Notice of ATM Fees By Others: If you use an automated teller machine
            that is not operated by us, you may be charged a fee by the operator
            of the machine and/or by an automated transfer network.
          </p>
        </li>
        <li>
          <div>
            <b>Purchase Refunds: </b>
            <span>
              Any refund to you by a merchant must be made by credit to your
              account. No cash refunds are permitted.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Illegal Activities:</b>
            <span>
              You agree not to use your Card or Account to engage in activities
              deemed illegal by federal and/or state laws, including but not
              limited to Internet gambling. If you use your Card to engage in
              activities deemed to be illegal, you understand you will be liable
              for any transactions conducted.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>
              Unauthorized Transfers/Loss or Theft of Card/Errors or Questions:{" "}
            </b>
            <span>
              You may not have the benefit of any consumer law limiting
              liability with respect to unauthorized use of your card. This
              means your liability for the unauthorized use of your Card could
              be greater than the liability in a consumer debit card
              transaction. You accept and agree to undertake the additional risk
              and greater measure of liability associated with the use of
              business purpose cards as described in this agreement.
            </span>
          </div>
          <p>
            Tell us AT ONCE if you believe your Card and/or PIN has been lost or
            stolen. If your Card and/or PIN are lost, stolen or used without
            your permission, you agree to notify us immediately by emailing us
            at{" "}
            <a href="mailto:support@otterz.co" className="link">
              support@otterz.co
            </a>{" "}
            and/or calling us at 857-234-4000
          </p>
          <p>
            We will not be liable for any consequential or incidental damages
            resulting from the unauthorized use of your Card. If you or any
            authorized user furnishes a Card to another person, you shall be
            deemed to have authorized all transactions that may be accomplished
            by the Card.
          </p>
          <p>
            You agree to examine your receipts and electronic statements using
            ordinary care and to report any errors to us within a reasonable
            period of time, not exceeding a total of 60 days from when the
            statement containing the error or problem was first mailed or made
            available to you. If you do not report within 60 days, we will be
            entitled to treat the information in the periodic statement as
            correct and you will be precluded from asserting otherwise. We will
            only re-credit your account for error or problems as required by
            law. Call or write us immediately with errors or questions at the
            telephone number listed below. If notified orally, we will require
            that you email us at the email noted above, including the following
            information:
            <ol className="lower-alpha">
              <li>
                <span>
                  Name, account number, Card Number, and the dollar amount of
                  the suspected error;
                </span>
              </li>
              <li>
                <span>
                  Describe the error or the transaction you are unsure about,
                  and explain as clearly as you can why you believe it is an
                  error or why you need more information.
                </span>
              </li>
            </ol>
          </p>
        </li>
        <li>
          <div>
            <b>Business Days:</b>{" "}
            <span>
              Our business days are Monday through Friday,excluding Federal
              Holidays.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Liability: </b>
            <span>
              We shall have no liability for losses you sustain when using the
              Card. You agree to notify Otterz immediately if an employee who
              has been issued a Card has been terminated and is no longer
              authorized to conduct business on your behalf using the Card, so
              that we can cancel the Card and limit your liability.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Limitations on Our Liability:</b>
            <span> We will not be liable if:</span>
            <ul>
              <li>
                <span>
                  You do not have enough money on your account to make a
                  transaction
                </span>
              </li>
              <li>
                <span>An ATM does not have sufficient cash</span>
              </li>
              <li>
                <span>A terminal or system is not working properly</span>
              </li>
              <li>
                <span>
                  Circumstances beyond our control (such as fire or flood)
                  prevent a transaction
                </span>
              </li>
              <li>
                <span>A merchant refuses to accept your card</span>
              </li>
              <li>
                <span>An ATM rejects your card</span>
              </li>
              <li>
                <span>There may be other limitations on our liability.</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <b>Amendment of this Agreement: </b>
            <span>
              You agree that from time to time we may amend or change the terms
              of this Agreement. We may do so by notifying you electronically of
              such amendments or changes and your use of the Card after the
              effective date of any such amendment or change shall constitute
              your acceptance of and agreement to such amendment or change.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Ownership:</b>
            <span>
              {" "}
              You agree that any card we issue is our property and you will
              surrender it to us upon our request, or upon the cancellation of
              your Card or closing of your account. We reserve the right to
              revoke your Card privilege at any time and to require you return
              your Card. You agree that we may terminate, limit or modify your
              Card access at any time without notice to you.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Address Change:</b>
            <span>
              {" "}
              You must notify the Otterz of any change in your business mailing
              address or telephone number by emailing us at{" "}
              <a href="mailto:support@otterz.co" className="link">
                support@otterz.co
              </a>{" "}
              as well as keep your details on the Otterz platform upto date.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Cancellation of Card:</b>
            <span>
              {" "}
              You or your company may cancel your Card at any time by emailing
              us at{" "}
              <a href="mailto:support@otterz.co" className="link">
                support@otterz.co
              </a>{" "}
              and initiating a cancel request. We may cancel this agreement at
              any time. You or the company will be responsible for all
              transactions completed prior to the cancellation of your Card or
              this agreement.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Replacement Cards:</b>
            <span>
              {" "}
              A fee of $5.00 will be assessed to your Account whenever we
              furnish you with a new card to replace one which you lost or
              destroyed. This fee will not be charged for cards we reissue to
              replace those cards that expire.
            </span>
          </div>
        </li>
        <li>
          <div>
            <b>Governing Law:</b>
            <span>
              {" "}
              This Agreement and Disclosure Statement shall be governed by all
              applicable federal laws and regulations.
            </span>
          </div>
        </li>
      </ol>
    </>
  );
};

export default CardholderAgreement;
