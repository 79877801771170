import { useState } from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  GradientDefs,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  MarkSeries,
  Hint,
} from "react-vis";
import { RiArrowRightUpLine } from "react-icons/ri";

import classNames from "./NoOfUniqueCustomers.module.scss";
import DataNotAvailableResult from "../dataNotAvailableComponent/DataNotAvailableComponent";

const NoOfUniqueCustomers = ({
  status,
  language,
  dataArray,
  averageInvoiceFrequency,
  highestBilledCustomerAmount,
  averageAmountBilledToEachCustomer,
  height,
  width,
}) => {
  const [state, setState] = useState();
  const forgetValue = () => {
    setState(null);
  };

  const rememberValue = (value) => {
    setState(value);
  };

  if (!status) return <DataNotAvailableResult language={language} />;
  return (
    <div className={classNames.wrapper} key="NoOfUniqueCustomersWrapper">
      <div className={classNames.chartWrapper} key="NoOfUniqueCustomersName">
        <div className={classNames.chartName}>Number Of Unique Customers</div>
        <div className={classNames.chart} key="NoOfUniqueCustomersChart">
          <FlexibleXYPlot
            height={height}
            width={width}
            key="NoOfUniqueCustomers"
            xType="ordinal"
            margin={{ left: 55, right: 0, top: 50, bottom: 30 }}
            animation={true}
          >
            <XAxis />
            <YAxis
              tickFormat={(value) => {
                const lookupArray = [
                  { scale: 1, symbol: "" },
                  { scale: 1e3, symbol: "k" },
                  { scale: 1e6, symbol: "M" },
                  { scale: 1e9, symbol: "G" },
                  { scale: 1e12, symbol: "T" },
                  { scale: 1e15, symbol: "P" },
                  { scale: 1e18, symbol: "E" },
                ];
                let item = lookupArray
                  .slice()
                  .reverse()
                  .find(function (item) {
                    return Math.abs(value) >= item.scale;
                  });
                if (typeof value === "number" && !Number.isInteger(value)) {
                  return;
                }
                return item ? value + item.symbol : "0";
              }}
              tickPadding={1}
            />
            <HorizontalGridLines />
            <MarkSeries
              onNearestXY={rememberValue}
              onValueMouseOver={rememberValue}
              onValueMouseOut={forgetValue}
              data={
                Array.isArray(dataArray) &&
                dataArray.length > 0 &&
                dataArray.map((value, index) => {
                  return { x: `${value.x}`, y: `${value.y}` };
                })
              }
            />
            {state ? (
              <Hint className={classNames.hint} value={state}>
                <p>{`${state.y}`}</p>
              </Hint>
            ) : null}

            <GradientDefs>
              <linearGradient x1="0" x2="0" y1="0" y2="1" id="areaGradient">
                <stop offset="0%" stopColor="#52EABC3B" />
                <stop offset="100%" stopColor="#52EABC3B" stopOpacity="-0.1" />
              </linearGradient>
            </GradientDefs>

            <AreaSeries
              animation={true}
              data={dataArray}
              colorType="literal"
              color={"url(#areaGradient)"}
              barWidth={1}
              curve={"curveMonotoneX"}
            />

            <LineSeries
              // animation={true}
              data={dataArray}
              colorType="literal"
              color={"#1DBC83"}
              barWidth={1}
              curve={"curveMonotoneX"}
              lineStyle={{ stroke: "red" }}
              markStyle={{ stroke: "black", fill: "red" }}
            />
          </FlexibleXYPlot>
        </div>
      </div>
      <div className={classNames.itemsWrapper}>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div className={classNames.iconContainer}>
              <RiArrowRightUpLine color="#1DBC83" size={18} />
            </div>
            <div className={classNames.heading}>Average Invoice Frequency </div>
          </div>
          <div className={classNames.count}>{averageInvoiceFrequency}</div>
          {/* <div className={classNames.itemFooter}>
            <div className={classNames.trend}>$384.58</div>
            <RiArrowDownSFill color="#1DBC83" />
          </div> */}
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div className={classNames.iconContainer}>
              <RiArrowRightUpLine color="#1DBC83" size={18} />
            </div>
            <div className={classNames.heading}>
              Highest Billed Customer Amount
            </div>
          </div>
          <div className={classNames.count}>{highestBilledCustomerAmount}</div>
          {/* <div className={classNames.itemFooter}>
            <div className={classNames.trend}>$384.58</div>
            <RiArrowDownSFill color="#EE6969" />
          </div> */}
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div className={classNames.iconContainer}>
              <RiArrowRightUpLine color="#1DBC83" size={18} />
            </div>
            <div className={classNames.heading}>
              Average Amount Billed to Each Customer
            </div>
          </div>
          <div className={classNames.count}>
            {averageAmountBilledToEachCustomer}
          </div>
          {/* <div className={classNames.itemFooter}>
            <div className={classNames.trend}>$384.58</div>
            <RiArrowDownSFill color="#EE6969" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NoOfUniqueCustomers;
