import classNames from "../ProductsServices.module.scss";
import { CTAButton, Input, Select, Message } from "../../../../common";
import { Modal, Form, Input as AntInput } from "antd";
import api from "../../../../API";
import { useMutation, useQueryClient } from "react-query";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function EditProduct({
  visible,
  onCancel,
  product,
  setEditProductCardModalVisibility,
  taxRates,
}) {
  const language = useLanguage(messages);
  const { Option } = Select;
  const [form] = Form.useForm();
  const { TextArea } = AntInput;
  const queryClient = useQueryClient();

  function editProductFn(data) {
    let input = {
      id: product.id,
      productName: data.productName ? data.productName : product.productName,
      taxRate: data.taxRate ? data.taxRate : product.taxRate,
      // companyId: product.companyId,
      currency: data.currency ? data.currency : product.currency,
      description: data.description ? data.description : product.description,
      price: data.price ? data.price : product.price,
    };
    return api.Receivables.Product.edit(input);
  }

  const editProduct = useMutation((event) => editProductFn(event));
  const handleOnFinish = (values) => {
    editProduct.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries("getProducts");
        Message({ type: "success", content: language.PRODUCT_UPDATE_SUCCESS });
        setEditProductCardModalVisibility({ visibily: false, record: null });
        form.resetFields();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };
  
  const taxRatesOptions =
    Array.isArray(taxRates) &&
    taxRates.length > 0 &&
    taxRates.map((val, index) => (
      <Select.Option value={val.taxRate}>{val.taxRateName} ({val.taxRate}%)</Select.Option>
    ));

  if (taxRatesOptions.length > 0) {
    taxRatesOptions.unshift(<Select.Option value={"0"}>No tax</Select.Option>);
  }

  return (
    product && (
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={() => {
          form.resetFields();
          onCancel();
          setEditProductCardModalVisibility({ visibily: false, record: null });
        }}
        onCancel={() => {
          form.resetFields();
          onCancel();
          setEditProductCardModalVisibility({ visibily: false, record: null });
        }}
        title="Edit Product"
        className={classNames.modal}
        bodyStyle={{
          height: "auto",
          padding: 24,
          display: "flex",
          flexDirection: "column",
        }}
        width={434}
        footer={null}
        centered
        key={product.key}
      >
        <div className={classNames.modalBody}>
          <Form
            form={form}
            onFinish={handleOnFinish}
            layout="vertical"
            hideRequiredMark
            key={product.key}
            initialValues={{
              productName: product?.productName,
              description: product?.description,
              price: product?.price,
              currency: product?.currency,
              taxRate: product?.taxRate,
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item
              name="productName"
              label={
                <span className={classNames.label}>
                  Product Name <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              initialValue={product?.productName}
              rules={[
                { max: 256, message: "Max 256 characters" },
                // { required: true, message: "Product name is required" },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Product name is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="Please Enter"
                size="small"
                defaultValue={product?.productName}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className={classNames.label}>Description</span>}
              rules={[{ max: 1000, message: "Max 1000 characters" }]}
            >
              <TextArea
                defaultValue={product?.description}
                placeholder="Please enter"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>

            <Form.Item>
              <Form.Item
                name="price"
                label={<span className={classNames.label}>Price</span>}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  { max: 11, message: "Max 8 digits with 2 decimal points" },
                  { min: 0, message: "Minimum value is zero" },
                  {
                    pattern: new RegExp(/^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/gm),
                    message: "Please enter a valid amount (eg. 12345678.12)",
                  },
                ]}
              >
                <Input
                  placeholder="Enter amount"
                  size="small"
                  prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                  defaultValue={product?.price}
                />
              </Form.Item>

              <Form.Item
                name="currency"
                label={
                  <span className={classNames.label}>
                    Currency <sup style={{ top: 1 }}>*</sup>{" "}
                  </span>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <Select
                  placeholder="Please select"
                  style={{ width: 180 }}
                  disabled
                  size="small"
                  defaultValue={product?.currency}
                >
                  <Option value={"USD"}>USD</Option>
                  <Option value={"GBP"}>GBP</Option>
                  <Option value={"AED"}>AED</Option>
                  <Option value={"UYD"}>UYD</Option>
                </Select>
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="taxRate"
              label={<span className={classNames.label}>Tax Rate</span>}
            >
              <Select
                placeholder="Please select"
                style={{ width: 185 }}
                size="small"
                defaultValue={product?.taxRate}
              >
                {taxRatesOptions}
              </Select>
            </Form.Item>
            <CTAButton
              type="primary"
              style={{ height: 44, width: 384, marginTop: 20 }}
              onClick={form.submit}
              loading={editProduct.isLoading}
            >
              Save
            </CTAButton>
          </Form>
        </div>
      </Modal>
    )
  );
}
