import { API } from "aws-amplify";

export default async function callWebhook(data) {
  if (data?.tenant_id) {
    return await API.post("webhook", "", {
      body: {
        ...data,
      },
    });
  }
}
