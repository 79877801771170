import IField, { ACH_TYPE, CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Radio,
  Space,
  Spin,
  Upload,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import useIFieldKey from "../../../../../hooks/useIFieldKey";
import api from "../../../../API";
import {
  CTAButton,
  Input,
  Message,
  Select,
  Title,
  ExpiryInput,
  DatePicker,
} from "../../../../common";
import { useIfield } from "../../../../hooks/useIfield";
import classNames from "./AddNewSchedule.module.scss";
import {
  achIfieldStyle,
  achIframeStyle,
  cardIfieldStyles,
  cardIframeStyles,
  cvvIfieldStyles,
  cvvIframeStyles,
} from "./styles";
import formatExpiryInput from "../../../../util/formatExpiryInput.util";
import generateErrorMessage from "../../../../util/functions/customError";
import REGEX from "../../../../util/constants/regex";
import { validationPayee } from "../../../../BusinessBanking/config";
import { validation } from "../../../../Auth/config";
import { MAX_MIN } from "../../../../util/constants";
import { RiCloseFill, RiFileFill } from "react-icons/ri";
import messages from "../../../../BusinessBanking/config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { FILE_SIZE } from "../../../../constants";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/browser";

export default function AddNewScheduleForm({ onCancel, queryClient }) {
  const [form] = Form.useForm();

  const cvvRef = useRef();
  const cardRef = useRef();
  const achRef = useRef();

  const [values, setValues] = useState({});
  const [exDate, setExDate] = useState(null);
  const [intervalType, setIntervalType] = useState(null);
  const [frequencyValue, setFrequencyValue] = useState([]);
  const [endScheduleType, setEndScheduleType] = useState(null);
  const [options, setOptions] = useState({});
  const [initialTransactionDecline, setInitialTransactionDecline] =
    useState(false);

  const [tokenFetching, setTokenFetching] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [error, setError] = useState(false);
  const [front, setFront] = useState(false);
  const [back, setBack] = useState(false);
  const [, forceUpdate] = useState({});
  const [check, setCheck] = useState(false);

  const { Dragger } = Upload;
  const acceptableType = ["image/jpg", "image/jpeg", "image/png"];
  const acceptableTypes = acceptableType.join(",");

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);
  const language = useLanguage(messages);
  const { iFieldKey, iFieldLoading } = useIFieldKey();

  const {
    cvvoptions,
    ccoptions,
    onError,
    onToken,
    account,
    threeds,
    onCVVUpdate,
    onCcUpdate,
    achOption,
    onACHUpdate,
  } = useIfield(
    iFieldKey,
    cvvIfieldStyles,
    cvvIframeStyles,
    cardIfieldStyles,
    cardIframeStyles,
    paymentMethod,
    handleSubmit,
    achIfieldStyle,
    achIframeStyle
  );

  const getToken = () => {
    setTokenFetching(true);

    if (paymentMethod === "cc") {
      cardRef.current.getToken();
      cvvRef.current.getToken();
    }
    if (paymentMethod === "check") {
      achRef.current.getToken();
    }
  };

  const onChangeOptions = (name, value) => {
    setOptions(Object.assign({}, options, { [name]: value }));
  };

  function createScheduleFn(data) {
    let { allowInitialTransactionToDecline, ...newOptions } = options;

    return api.Receivables.PaymentAcceptance.createSchedule(
      Object.assign({}, data, {
        ...(initialTransactionDecline ? { ...newOptions } : { ...options }),
        tokenType: paymentMethod,
      })
    );
  }

  const createScheduleMt = useMutation((event) => createScheduleFn(event));

  const onChange = (date) => {
    setExDate(formatExpiryInput(date.target.value));
  };

  const formatData = (val1, val2) => {
    let selectedValues = values;

    if (values.startDate) {
      selectedValues.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }
    if (values.endDate) {
      selectedValues.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    if (paymentMethod === "cc") {
      return Object.assign({}, selectedValues, {
        cardNum: val2,
        cvv: val1,
        exp: exDate,
      });
    }
    return Object.assign({}, selectedValues, {
      accountNumber: val1,
      xCheckImageFront: front ? front : null,
      xCheckImageRear: back ? back : null,
      xCheckNum: values.checkNum,
    });
  };

  function handleSubmit(val1, val2) {
    setTokenFetching(false);
    if (paymentMethod) {
      createScheduleMt.mutate(formatData(val1, val2), {
        onSuccess: (data, variables, context) => {
          Message({
            type: "success",
            content: "Transaction create successfully",
          });

          try {
            mixpanel.track("Successful Recurring Payment Request", {
              "Reference Number": data?.data?.RefNum,
              "Payment Method": "CC",
              "Amount": variables?.amount,
              "Currency": "USD",
              "Transaction Status": data?.data?.Result,
              "Frequency": variables?.intervalType,
              "Start Date": variables?.startDate,
              "End Date": variables?.endDate,
            });
          } catch (error) {
            Bugsnag.notify("mixpanel error", error);
          }

          queryClient.invalidateQueries("listSchedule");
          form.resetFields();
          onCancel();
        },
        onError: (error, variables) => {
          try {
            mixpanel.track("Failed Recurring Payment Request", {
              "Payment Method": "CC",
              "Failure Message": generateErrorMessage(error),
              "Amount": variables?.amount,
              "Currency": "USD",
          });
          } catch (error) {
            Bugsnag.notify("mixpanel error", error);
          }
        },
      });
    }
  }

  function getIntervalValues(interval) {
    if (interval === "day") {
      setFrequencyValue(Array.from({ length: 31 }, (_, i) => i + 1));
    }
    if (interval === "week") {
      setFrequencyValue(Array.from({ length: 52 }, (_, i) => i + 1));
    }
    if (interval === "month") {
      setFrequencyValue(Array.from({ length: 12 }, (_, i) => i + 1));
    }
  }
  function onChangeFrontImage(info) {
    if (!error) {
      setFront(info?.file);
    }
  }

  function onChangeBackImage(info) {
    if (!error) {
      setBack(info?.file);
    }
  }
  const onFinish = (values) => {
    delete values.paymentMethod;
    setValues(values);
    getToken();
  };

  const onClickPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  function disabledDateBefore(current) {
    return current && current <= moment().subtract(1, "days");
  }

  function disableEndDate(current) {
    return (
      (current && current <= moment().subtract(1, "days")) ||
      moment().endOf("year") <= current
    );
  }

  function checkInitalTransactionAccepted(date) {
    setInitialTransactionDecline(date !== moment().format("LL") && date);
  }

  useEffect(() => {
    forceUpdate({});
  }, []);
  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <div style={{ display: "flex" }}>
        <div className={classNames.modalLeft}>
          <Spin spinning={iFieldLoading}>
            <div>
              {/* <Title
                as="h5"
                style={{ color: "#BC7C1D" }}
                className={classNames.label}
              >
                General Information
              </Title> */}
            </div>

            <Form.Item>
              <Form.Item
                name={"firstName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    First Name <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  {
                    required: true,
                    message: validation.firstName.required,
                  },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.firstName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.firstName.valid,
                  },
                ]}
              >
                <Input placeholder="Please enter" size="small" />
              </Form.Item>
              <Form.Item
                name={"lastName"}
                label={
                  <Title as="h4" className={classNames.label}>
                    Last Name <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: validation.lastName.required },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.lastName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.lastName.valid,
                  },
                ]}
              >
                <Input placeholder="Please enter" size="small" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name={"company"}
              label={<span className={classNames.label}>Company</span>}
              rules={[
                {
                  max: MAX_MIN.businessName.max,
                  message: validation.businessName.max,
                },
                {
                  pattern: new RegExp(REGEX.businessName),
                  message: "Enter valid company name",
                },
              ]}
            >
              <Input size="small" placeholder="Please enter" />
            </Form.Item>
            <Form.Item
              name="invoice"
              label={<span className={classNames.label}>Invoice</span>}
              rules={[
                {
                  pattern: new RegExp(REGEX.invoice),
                  message: "Enter a valid invoice number",
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: "49%" }}
                placeholder="Please enter"
              />
            </Form.Item>
          </Spin>
        </div>
        <div className={classNames.modalRight}>
          {/* <Title
            as="h5"
            style={{ color: "#EE6969" }}
            className={classNames.label}
          >
            Mandatory fields
          </Title> */}
          <Form.Item
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
            name="amount"
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              className={classNames.inputAmount}
              style={{
                backgroundColor: "#F6F7FA",
                hight: 54,
                border: "#fff",
              }}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              placeholder="Enter Amount"
              maxlength="16"
            />
          </Form.Item>

          <Form.Item
            label={<span className={classNames.label}>Payment Method</span>}
            name={"paymentMethod"}
          >
            <div className={classNames.paymentMethod}>
              <Radio.Group
                defaultValue="cc"
                buttonStyle="solid"
                onChange={onClickPaymentMethod}
              >
                <Space size={12}>
                  <Radio.Button
                    value="cc"
                    style={{
                      width: "120px",
                      height: "36px",
                      border: "1px solid #d1d5dd",
                    }}
                  >
                    Card
                  </Radio.Button>
                  {/* <Radio.Button
                    value="check"
                    style={{
                      width: "120px",
                      height: "36px",
                      border: "1px solid #d1d5dd",
                    }}
                  >
                    {" "}
                    ACH/Check
                  </Radio.Button> */}
                </Space>
              </Radio.Group>
            </div>
          </Form.Item>
          {paymentMethod === "cc" && (
            <>
              <div style={{ display: "inline-flex" }}>
                <Form.Item
                  name="cardNumber"
                  label={
                    <span className={classNames.label}>
                      Card Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  className={classNames.cardNumberField}
                >
                  {iFieldKey && (
                    <IField
                      type={CARD_TYPE}
                      account={account}
                      options={ccoptions}
                      threeDS={threeds}
                      onUpdate={onCcUpdate}
                      onToken={onToken}
                      ref={cardRef}
                      onError={onError}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name="cardCVV"
                  label={
                    <span className={classNames.label}>
                      CVV <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  className={classNames.cardNumberField}
                >
                  {iFieldKey && (
                    <IField
                      type={CVV_TYPE}
                      account={account}
                      options={cvvoptions}
                      threeDS={threeds}
                      onUpdate={onCVVUpdate}
                      onToken={onToken}
                      ref={cvvRef}
                      onError={onError}
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item
                name="expiry"
                label={
                  <span className={classNames.label}>
                    Expiry <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[{ required: true, message: "Expiry is required" }]}
              >
                <ExpiryInput onChange={onChange} style={{ height: 44 }} />
              </Form.Item>
            </>
          )}
          {paymentMethod === "check" && (
            <>
              <Row gutter={[16]}>
                <Col span={12}>
                  <Form.Item
                    className={front ? classNames.hasFile : ""}
                    name="xCheckImageFront"
                    label={
                      <span className={classNames.label}>
                        {language.FRONT_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback("Max 20MB is allowed");
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={front ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageFront")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeFrontImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>{" "}
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !front ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setFront(null);
                        form.setFieldsValue({ xCheckImageFront: null });
                        form.validateFields(["xCheckImageFront"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div>{front?.name}</div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className={back ? classNames.hasFile : ""}
                    name="xCheckImageRear"
                    label={
                      <span className={classNames.label}>
                        {language.BACK_IMAGE}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback(`Max ${FILE_SIZE.MB} is allowed`);
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Dragger
                      className={back ? classNames.hideControl : ""}
                      fileList={
                        form.getFieldValue("xCheckImageRear")?.fileList || []
                      }
                      accept={acceptableTypes}
                      customRequest={({ file, onSuccess, onError }) => {
                        if (acceptedType(file)) {
                          onSuccess("ok");
                        } else {
                          onError();
                        }
                      }}
                      beforeUpload={(file) => {
                        if (!acceptedType(file)) {
                          setError(false);
                          Message({
                            type: "Error",
                            content: language.SELECT_JPEG,
                          });
                        } else if (file?.size > FILE_SIZE.B) {
                          setError(true);
                        } else {
                          setError(false);
                          return false;
                        }
                      }}
                      onChange={onChangeBackImage}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "12px" }}
                      >
                        {language.DRAG_DROP}
                        <Button
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </Button>
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Card
                    className={
                      !back ? classNames.hideControl : classNames.uploadCard
                    }
                  >
                    <RiCloseFill
                      onClick={() => {
                        setBack(null);
                        form.setFieldsValue({ xCheckImageRear: null });
                        form.validateFields(["xCheckImageRear"]);
                      }}
                      size={20}
                      className={classNames.closeIcon}
                    />
                    <div>
                      <RiFileFill size={40} className={classNames.fileIcon} />
                    </div>
                    <div> {back?.name} </div>
                  </Card>
                </Col>
              </Row>
              <Form.Item
                name="checkNum"
                label={<span className={classNames.label}>Check Number</span>}
                rules={[
                  { min: 4, message: "Invalid check number" },
                  { max: 4, message: "Invalid check number" },
                ]}
              >
                <Input
                  id="checkNum"
                  size="small"
                  placeholder={"Check Number"}
                />
              </Form.Item>
              <Form.Item
                name="accountName"
                label={
                  <span className={classNames.label}>
                    Account Owner's Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Account Owner's Name is required",
                  },
                  { max: 255, message: "Max 255 characters" },
                ]}
              >
                <Input
                  id="accountName"
                  size="small"
                  placeholder={"Account Owner's Name"}
                />
              </Form.Item>
              <Form.Item
                name="routing"
                label={
                  <span className={classNames.label}>
                    Routing Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: validationPayee.addPayee.routingNumber,
                  },
                  {
                    pattern: new RegExp(REGEX.einNumber),
                    message: validationPayee.addPayee.routingNumberValid,
                  },
                ]}
              >
                <Input
                  id="routingNumber"
                  size="small"
                  placeholder={"Routing Number"}
                />
              </Form.Item>{" "}
              <Form.Item
                name="accountNumber"
                label={
                  <span className={classNames.label}>
                    Account Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                className={classNames.accountNumberField}
              >
                {iFieldKey && (
                  <IField
                    type={ACH_TYPE}
                    account={account}
                    options={achOption}
                    threeDS={threeds}
                    onUpdate={onACHUpdate}
                    onToken={onToken}
                    ref={achRef}
                    onError={onError}
                  />
                )}
              </Form.Item>
            </>
          )}
          <Form.Item
            label={
              <span className={classNames.label}>
                Frequency <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              name={"intervalType"}
              rules={[{ required: true, message: "Frequency is required" }]}
            >
              <Select
                prefix={"Sdsd"}
                size="small"
                onSelect={(val) => {
                  setIntervalType(val);
                  getIntervalValues(val);
                }}
                placeholder="Please select"
              >
                <Select.Option value={"day"}>Every day</Select.Option>
                <Select.Option value={"week"}> Every week</Select.Option>
                <Select.Option value={"month"}>Every month</Select.Option>
                {/* <Select.Option value={"year"}>Every year</Select.Option> */}
              </Select>
            </Form.Item>
            <Form.Item
              name={"intervalCount"}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[{ required: true, message: "Interval is required" }]}
            >
              <Select size="small" placeholder="Days">
                {frequencyValue.map((val) => (
                  <Select.Option value={`${val}`}>{val}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {intervalType === "month" && (
              <Checkbox style={{ marginLeft: 0 }}>
                Run recurring at a specific day of the week
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span className={classNames.label}>
                Start Date <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            style={{
              display: "inline-block",
              marginRight: 14,
              marginTop: 15,
            }}
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.scheduleDate,
              },
            ]}
            name={"startDate"}
          >
            <DatePicker
              size="small"
              style={{ height: 44, width: 175 }}
              disabledDate={disabledDateBefore}
              onChange={(e) => {
                checkInitalTransactionAccepted(e?.format("LL"));
              }}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={classNames.label}>
                End Date <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            style={{
              display: "inline-block",
              marginTop: 15,
            }}
            rules={[
              {
                required: true,
                message: "Please select",
              },
            ]}
          >
            <Select
              size="small"
              style={{ width: 175 }}
              onSelect={(val) => setEndScheduleType(val)}
            >
              <Select.Option value={"never"}>Never</Select.Option>
              <Select.Option value={"date"}>Date</Select.Option>
            </Select>
          </Form.Item>
          {endScheduleType === "date" && (
            <Form.Item
              label={
                <span className={classNames.label}>
                  End Date <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                marginTop: 15,
              }}
              name={"endDate"}
              rules={[
                {
                  required: true,
                  message: validationPayee.quickFundTransfer.endDate,
                },
              ]}
            >
              <DatePicker
                size="small"
                style={{ height: 44, width: 175 }}
                disabledDate={disableEndDate}
              />
            </Form.Item>
          )}
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames.checkbox}>
              <Checkbox
                style={{ marginLeft: 0, paddingBottom: 5, paddingTop: 5 }}
                onChange={(e) => {
                  onChangeOptions("sendReciept", e.target.checked);
                }}
              >
                Email receipt to customer
              </Checkbox>
              <Form.Item
                name={"email"}
                label={
                  <Title as="h4" className={classNames.label}>
                    Email address <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                hidden={!options?.sendReciept || options.sendReciept === false}
                rules={
                  options?.sendReciept && [
                    { required: true, message: validation.email.required },
                    {
                      type: "email",
                      message: validation.email.valid,
                    },
                    {
                      min: MAX_MIN.email.min,
                      message: validation.email.minLength,
                    },
                    {
                      max: MAX_MIN.email.max,
                      message: validation.email.maxLength,
                    },
                  ]
                }
              >
                <Input placeholder="Please enter" size="small" />
              </Form.Item>
              <Checkbox
                style={{ marginLeft: 0, paddingBottom: 5 }}
                onChange={(e) => {
                  onChangeOptions(
                    "useDefaultPaymentMethodOnly",
                    e.target.checked
                  )
                  setCheck(!check)
                }
                }
              >
                Retry declined transaction
              </Checkbox>
              {/* TODO: Check with Cardknox. API fails when this flag is set, event its the current day. Documentation says this is possible. */}
              {/* <Checkbox
                disabled={initialTransactionDecline}
                style={{ marginLeft: 0, paddingBottom: 5 }}
                onChange={(e) => {
                  onChangeOptions(
                    "allowInitialTransactionToDecline",
                    e.target.checked
                  );
                }}
              >
                Create the schedule even if the initial payment Fails
              </Checkbox> */}
            </div>
          </Form.Item>

          {check && 
          <Form.Item
            label={<span className={classNames.label}>Number of tries</span>}
            style={{
              display: "inline-block",
              width: "100%",
              marginRight: 4,
              marginTop: 8,
            }}
            name={"failedTransactionRetryTimes"}
          >
            <Select size="small" style={{ width: "100%", marginRight: 10 }}>
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
            </Select>
          </Form.Item>
          }

          {/* <Form.Item
            label={<span className={classNames.label}>Tries With</span>}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              marginLeft: 4,
              marginTop: 8,
            }}
            name={"daysBetweenRetries"}
          >
            <Select size="small">
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            label={
              <span className={classNames.label}>Day(s) between Retries: </span>
            }
            style={{
              marginTop: 10,
            }}
          >
            <Select size="small" allowClear>
              <Select.Option>Disable recurring scheduling</Select.Option>
            </Select>
          </Form.Item> */}
        </div>
      </div>
      <div className={classNames.modalFooter}>
        <CTAButton
          loading={tokenFetching || createScheduleMt.isLoading}
          htmlType="submit"
          type="primary"
          key="submit"
          id="submit-btn"
          style={{
            height: 44,
            width: 385,
            float: "right",
            marginBottom: "100px !important",
          }}
        >
          Save
        </CTAButton>
      </div>
    </Form>
  );
}
