import * as types from "../../actionTypes";

const getApplicationByTenantIdReducer = (state, action) => {
  switch (action.type) {
    case types.GET_APPLICATION_BY_TENANT_ID_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_APPLICATION_BY_TENANT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        ...action.payload,
      };

    case types.GET_APPLICATION_BY_TENANT_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CLEAR_APPLICATION_DATA:
      return {};

    default:
      return state;
  }
};

export default getApplicationByTenantIdReducer;
