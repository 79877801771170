import moment from "moment";
export function isPaymentOnPast(paymentDate) {
  if (!paymentDate) {
    throw new Error('Missing required parameter (param 1: " yyyy-MM-DD "');
  }
  const paymentOnPast = !moment(
    /**
     * TEMP: This will be deprecated with scheduler timestamp implementation and edge case optimizations.
     * In payment scheduler, the payments run on constant time which is 00:00:00 +00:00 GMT.
     * The APIs do not return a ISO timstamp (which is the standard used through out the app) for scheduled events.
     * In following function passing string T00:00:00.000+00:00 will construct a time object
     * which will represent 0 GMT time 00:00:00. So any user comming from any timezone's current
     * time can be derieved from that object
     */
    moment(paymentDate).toLocaleString()
  ).isAfter(moment(), "day");

  return paymentOnPast;
}
