import { API } from "aws-amplify";

export default async function deleteInvoice(id) {
  if (id) {
    return await API.del("invoices", "recurring", {
      queryStringParameters: {
        recurringId: id,
      },
    });
  }
}
