import { Auth } from 'aws-amplify';
import endpoints from '../endpoints';

export default async function createAccountRelationship(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    return await(
        await fetch(endpoints.ONBOARDING.CREATE_ACCOUNT_RELATIONSHIP[env]+data.otz_account_id, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify({
                "otz_person_id": data.otz_person_id,
                "relationship_type": data.relationship_type
            })
        })
    ).json();
}
