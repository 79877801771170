import classNames from "../Reports.module.scss";
import { Modal, CTAButton, Title, Message, Input } from "../../../../common";

export default function SaveReport({ visible, onCancel }) {
  const handleConfirmClick = () => {
    Message({ type: "success", content: "Report saved" });
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Save Report
      </Title>
      <div style={{ margin: "8px 0 16px 0" }}>
        <Input />
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            border: "none",
          }}
          onClick={() => {
            handleConfirmClick();
          }}
        >
          Save
        </CTAButton>
      </div>
    </Modal>
  );
}
