import { useState, useEffect } from "react";
import { Card, Table, Space, Empty, Button as AntButton } from "antd";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiEditLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";

import { Input, Header, Select } from "../../../common";
import AddNewRecurringInvoices from "./AddNewRecurringInvoices/AddNewRecurringInvoices";
import ChangeStatus from "../../Invoicing/Invoices/ChangeStatus/ChangeStatus";
import DeleteInvoice from "../ViewRecurringInvoice/DeleteRecurringInvoice/DeleteRecurringInvoice";
import EditRecurringInvoice from "../ViewRecurringInvoice/EditRecurringInvoice/EditRecurringInvoice";
import api from "../../../API";
import { uuidv4 } from "../../../util/functions/uuidV4";
import { intToDecimalDollar } from "../../../util/functions/dollarConvertion";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import { DEFAULT_AMOUNT, FORMATS, ROUTES } from "../../../constants";
import classNames from "./RecurringInvoices.module.scss";

export default function RecurringInvoices() {
  const [pagination, setPagination] = useState(PAGINATION);
  const [sort, setSortChange] = useState();

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: recurringInvoiceQuery, isLoading: isRecurringLoading } =
    useQuery(
      "getRecurringData",
      () => {
        return api.Receivables.Recurring.getRecurringInvoices(
          currentUserData?.signInUserSession?.idToken?.payload?.icid
        );
      },
      {
        enabled: !!currentUserData,
        refetchOnWindowFocus: false,
      }
    );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: productQuery } = useQuery(
    "getProducts",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoiceTemplateSettingsQuery,
    // isFetching: invoiceTemplateSettingsFetching,
    // isSuccess: invoiceTemplateSettingsSuccess,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const [recurringData, setRecurringData] = useState([]);
  const [filteredRecurringInvoiceData, setFilteredRecurringInvoiceData] =
    useState(recurringData);
  const [keyword, setKeyword] = useState();
  const [payeeName, setClient] = useState();
  const [statusAttribute, setStatusAttribute] = useState();

  useEffect(() => {
    setPagination(PAGINATION);
  }, [keyword]);

  useEffect(() => {
    if (sort) {
      setPagination(PAGINATION);
    }
  }, [sort]);

  const [
    editRecurringInvoiceModalVisibility,
    setEditRecurringInvoiceModalVisibility,
  ] = useState({ visible: false, data: false });

  const [createNewRecurringInvoiceModal, setCreateNewRecurringInvoiceModal] =
    useState(false);

  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);
  // const [recurringInvoiceStatus, setRecurringInvoiceStatus] = useState();

  const [
    deleteRecurringInvoiceModalVisibility,
    setDeleteRecurringInvoiceModalVisibility,
  ] = useState({ visible: false, data: [] });
  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
  };
  // const handleStatusChange = (value, record) => {
  //   setRecurringInvoiceStatus({flag: "recurringInvoice", value: value, record: record});
  //   setChangeStatusModalVisibility(true);
  // };

  useEffect(() => {
    recurringInvoiceQuery &&
      recurringInvoiceQuery.data &&
      recurringInvoiceQuery.data?.length > 0 &&
      setRecurringData(
        recurringInvoiceQuery.data
          .map((invoice) => {
            return {
              key: `recurringInvoice${uuidv4()}`,
              id: invoice.id,
              number: invoice.invoiceNumber,
              payeeName: invoice.receiverName,
              lastRun:
                invoice.scheduledLastRun &&
                moment(invoice.scheduledLastRun).format(FORMATS.DATE_FORMAT),
              nextRun:
                invoice.scheduledNextRun &&
                moment(invoice.scheduledNextRun).format(FORMATS.DATE_FORMAT),
              amount:
                intToDecimalDollar(Number(invoice.total)) || DEFAULT_AMOUNT,
              frequency: invoice.scheduledPeriod,
              // reference: invoice.reference,
              status: invoice.invoiceStatus,
              // frequency: {
              // interval: invoice.frequency.interval,
              // period: invoice.frequency.period,
              // },
              salesItems: invoice.item,
              ...invoice,
            };
          })
          .sort((a, b) => {
            return (
              new Date(moment(a.nextRun).toLocaleString()).getTime() -
              new Date(moment(b.nextRun).toISOString()).getTime()
            );
          })
      );
  }, [recurringInvoiceQuery]);

  const handleRecurringCancelClick = () => {
    setCreateNewRecurringInvoiceModal(false);
  };

  const handleEditRecurringCancelClick = () => {
    setEditRecurringInvoiceModalVisibility({
      visible: false,
      data: false,
    });
  };

  const navigate = useNavigate();

  const renderClientOptions = (data) => {
    const mainData =
      data?.data && data?.data?.length
        ? data?.data
            ?.filter(Boolean)
            ?.filter((item) =>
              recurringInvoiceQuery?.data?.some(
                (invoice) => invoice?.receiverName === item?.name
              )
            )
        : [];

    return mainData?.map((item, index) => (
      <Select.Option key={`client${index}`} value={item.name}></Select.Option>
    ));
  };
  //Commenting due to the UI change
  // const status = ["Draft", "Sent", "Late", "Paid"];
  // const renderStatusOptions = (data) =>
  //   data?.map((item) => <Option value={item}></Option>);

  // commenting due to future use - AfterMVP
  // Issue id: OTT 4341
  // const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  //   console.log(
  //     `selectedRowKeys: ${selectedRowKeys}`,
  //     "selectedRows: ",
  //     selectedRows
  //   );
  // },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const rowClick = (record) => {
    setEditRecurringInvoiceModalVisibility({
      visible: true,
      data: record,
    });
  };

  useEffect(() => {
    if (!payeeName && !keyword && !statusAttribute) {
      setFilteredRecurringInvoiceData(
        recurringInvoiceQuery?.data
          .map((invoice) => {
            return {
              key: `recurringInvoice${uuidv4()}`,
              id: invoice.id,
              number: invoice.invoiceNumber,
              payeeName: invoice.receiverName,
              lastRun:
                invoice.scheduledLastRun &&
                moment(invoice.scheduledLastRun).format(
                  FORMATS.DATEPICKER_FORMAT
                ),
              nextRun:
                invoice.scheduledNextRun &&
                moment(invoice.scheduledNextRun).format(
                  FORMATS.DATEPICKER_FORMAT
                ),
              amount:
                intToDecimalDollar(Number(invoice.total)) || DEFAULT_AMOUNT,
              frequency: invoice.scheduledPeriod,
              // reference: invoice.reference,
              status: invoice.invoiceStatus,
              // frequency: {
              // interval: invoice.frequency.interval,
              // period: invoice.frequency.period,
              // },
              salesItems: invoice.item,
              ...invoice,
            };
          })
          ?.sort((a, b) => {
            return (
              new Date(moment(a.nextRun).toLocaleString()).getTime() -
              new Date(moment(b.nextRun).toISOString()).getTime()
            );
          })
      );
    }
  }, [payeeName, recurringInvoiceQuery?.data, keyword, statusAttribute]);
  useEffect(() => {
    let searchQuery = {
      payeeName: payeeName,
      status: statusAttribute,
    };
    let recurringInvoiceQueryDatasource = [];

    let array =
      searchQuery.payeeName && searchQuery.status
        ? recurringInvoiceQueryDatasource
        : recurringData;

    if (searchQuery.payeeName) {
      recurringInvoiceQueryDatasource =
        array &&
        array?.length > 0 &&
        array.filter((obj) => obj.receiverName === searchQuery.payeeName);
      setFilteredRecurringInvoiceData(recurringInvoiceQueryDatasource);
    }
    if (searchQuery.status) {
      recurringInvoiceQueryDatasource =
        array &&
        array?.length > 0 &&
        array.filter(
          (obj) => obj.invoiceStatus === searchQuery.status.toLowerCase()
        );
      setFilteredRecurringInvoiceData(recurringInvoiceQueryDatasource);
    }
  }, [payeeName, statusAttribute, recurringData]);

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Customer",
      dataIndex: "payeeName",
      key: "payeeName",
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      key: "lastRun",
      sorter: (a, b) => {
        return moment(a.lastRun, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b.lastRun, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    // {
    //   title: "Last Rune",
    //   dataIndex: "lastRun",
    //   key: "lastRun",
    // },
    {
      title: "Next Run",
      dataIndex: "nextRun",
      key: "nextRun",
      sorter: (a, b) => {
        return moment(a.nextRun, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b.nextRun, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (frequency) => (
        <span className={classNames.camelCase}>{frequency}</span>
      ),
    },
    {
      // title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "right",
          }}
        >
          {/* <AntSelect
            onClick={(event) => event.stopPropagation()}
            defaultValue={status}
            bordered={false}
            style={{ width: 120 }}
            onChange={(value) => {
              handleStatusChange(value, record);
            }}
          >
            <AntSelect.Option value="draft">
              <span style={{ color: "#BC7C1D" }}>Draft</span>
            </AntSelect.Option>
            <AntSelect.Option value="sent">
              <span style={{ color: "#7372FF" }}>Sent</span>
            </AntSelect.Option>
            <AntSelect.Option value="late">
              <span style={{ color: "#EE6969" }}>Late</span>
            </AntSelect.Option>
            <AntSelect.Option value="paid">
              <span style={{ color: "#1DBC83" }}>Paid</span>
            </AntSelect.Option>
          </AntSelect> */}
          {/* <Space size="middle"> */}
          <RiEditLine
            size={14}
            color="#9BA3AB"
            className={classNames.actionIcon}
            onClick={(e) => {
              setEditRecurringInvoiceModalVisibility({
                visible: true,
                data: record,
              });
              e.stopPropagation();
            }}
          />
          <RiDeleteBin7Line
            size={14}
            color="#9BA3AB"
            className={classNames.actionIcon}
            onClick={(e) => {
              setDeleteRecurringInvoiceModalVisibility({
                visible: true,
                record: record,
              });
              e.stopPropagation();
            }}
          />
          {/* </Space> */}
        </Space>
      ),
    },
  ];

  const keywordFilteredArray =
    (keyword
      ? filteredRecurringInvoiceData &&
        filteredRecurringInvoiceData?.length > 0 &&
        filteredRecurringInvoiceData.filter((obj) => {
          let filterObj = {
            number: obj.number,
            payeeName: obj.payeeName,
            // lastRun: obj.lastRun && moment(obj.lastRun).format(FORMATS.DATE_FORMAT),
            nextRun:
              obj.nextRun && moment(obj.nextRun).format(FORMATS.DATE_FORMAT),
            amount: obj.amount,
            status: obj.status,
            frequency: obj.frequency,
          };
          return Object.values(filterObj)
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      : filteredRecurringInvoiceData) || [];

  //Display pagination count text
  let totalNoOfRecords =
    Array.isArray(filteredRecurringInvoiceData) &&
    filteredRecurringInvoiceData?.length > 0 &&
    (keyword || payeeName)
      ? Array.isArray(keywordFilteredArray)
        ? keywordFilteredArray?.length
        : Array.isArray(filteredRecurringInvoiceData)
        ? recurringData?.length
        : 0
      : recurringData?.length;

  let maximumNoOfPages = Math.ceil(totalNoOfRecords / pagination.pageSize);
  let calculateRecordCount =
    pagination.pageSize > totalNoOfRecords
      ? totalNoOfRecords
      : pagination.page === maximumNoOfPages
      ? totalNoOfRecords
      : pagination.pageSize * pagination.page;

  const paginationText =
    totalNoOfRecords === 0
      ? `No recurring invoices to display`
      : `Displaying
      ${calculateRecordCount}
      of ${totalNoOfRecords} ${
          totalNoOfRecords > 1 ? "recurring invoices" : "recurring invoice"
        }`;

  //Adding following userEffect to remove errors occured due to the UI change
  useEffect(() => {
    setClient("");
    setStatusAttribute("");
  }, []);

  const handleClientEvent = (event) => {
    setPagination(PAGINATION);
    if (!event) {
      setClient("");
      setFilteredRecurringInvoiceData(
        recurringInvoiceQuery?.data
          .map((invoice) => {
            return {
              key: `recurringInvoice${uuidv4()}`,
              id: invoice.id,
              number: invoice.invoiceNumber,
              payeeName: invoice.receiverName,
              lastRun:
                invoice.scheduledLastRun &&
                moment(invoice.scheduledLastRun).format(
                  FORMATS.DATEPICKER_FORMAT
                ),
              nextRun:
                invoice.scheduledNextRun &&
                moment(invoice.scheduledNextRun).format(
                  FORMATS.DATEPICKER_FORMAT
                ),
              amount:
                intToDecimalDollar(Number(invoice.total)) || DEFAULT_AMOUNT,
              frequency: invoice.scheduledPeriod,
              // reference: invoice.reference,
              status: invoice.invoiceStatus,
              // frequency: {
              // interval: invoice.frequency.interval,
              // period: invoice.frequency.period,
              // },
              salesItems: invoice.item,
              ...invoice,
            };
          })
          .sort((a, b) => {
            return a.number - b.number;
          })
      );
    } else {
      setClient(event);
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title="Recurring Invoices"
          back
          onClick={() => navigate(ROUTES.APP.RECEIVABLES.INVOICES)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.invoiceFilters}>
              <Input
                placeholder="Search recurring invoice"
                prefix={<RiSearch2Line size={26} />}
                className={classNames.search}
                onChange={(event) => setKeyword(event.target.value)}
              />
              <span style={{ margin: "0 16px" }}>Filter by</span>
              <Select
                placeholder="Customers"
                className={classNames.filterSelect}
                bordered={false}
                onChange={(event) => handleClientEvent(event)}
                allowClear
              >
                {renderClientOptions(customerQuery)}
              </Select>
              {/* <span style={{ margin: "0 16px" }}>Status</span>
              <Select
                placeholder="All"
                className={classNames.filterSelect}
                bordered={false}
                onChange={(event) => setStatusAttribute(event)}
                >
                      {renderStatusOptions(status)}
              </Select> */}
            </div>
            <Card
              className={classNames.optionCard}
              onClick={() => setCreateNewRecurringInvoiceModal(true)}
            >
              <div className={classNames.cardAction}>
                <span>Create Recurring Invoice</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              {paginationText}
            </div>
            <Table
              // commenting due to future use - AfterMVP
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              loading={isRecurringLoading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    rowClick(record);
                  },
                };
              }}
              // scroll={{ y: `var(--table-height)` }}
              size="large"
              dataSource={keywordFilteredArray
                ?.filter((item) => item)
                ?.sort((a, b) => b.number - a.number)}
              onChange={(pagination, filters, sorter) => {
                const value = sorter?.order
                  ? sorter?.order + sorter?.field
                  : sorter?.order;
                setSortChange(value);
              }}
              columns={columns}
              className={classNames.table}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  }
                  if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      keyword && totalNoOfRecords === 0
                        ? "No Result found"
                        : "No Data Found"
                    }
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <DeleteInvoice
        visible={deleteRecurringInvoiceModalVisibility.visible}
        setDeleteRecurringInvoiceModalVisibility={
          setDeleteRecurringInvoiceModalVisibility
        }
        onCancel={() => setDeleteRecurringInvoiceModalVisibility(false)}
        invoice={deleteRecurringInvoiceModalVisibility.record}
      />
      <ChangeStatus
        visible={changeStatusModalVisibility}
        // status={recurringInvoiceStatus}
        onCancel={handleChangeStatusModalCancelClick}
      />
      <EditRecurringInvoice
        key={editRecurringInvoiceModalVisibility?.data}
        recurringInvoice={editRecurringInvoiceModalVisibility.data}
        visible={editRecurringInvoiceModalVisibility.visible}
        customers={customerQuery?.data}
        products={productQuery?.data}
        taxRates={taxRates?.data}
        onCancel={handleEditRecurringCancelClick}
        setEditRecurringInvoiceModalVisibility={
          setEditRecurringInvoiceModalVisibility
        }
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
      />
      <AddNewRecurringInvoices
        icid={currentUserData?.signInUserSession?.idToken?.payload?.icid}
        visible={createNewRecurringInvoiceModal}
        onCancel={handleRecurringCancelClick}
        setCreateNewRecurringInvoiceModal={setCreateNewRecurringInvoiceModal}
        customers={customerQuery?.data}
        products={productQuery?.data}
        taxRates={taxRates?.data}
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
        // data={}
      />
    </div>
  );
}
