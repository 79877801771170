// import endpoints from "../endpoints";

export default async function addFund(input) {
  return await (
    await fetch(`api/payments`, {
      method: "POST",
      body: JSON.stringify(input),
    })
  ).json();
}
