import classNames from "./AddNewSchedule.module.scss";
import { Modal } from "../../../../common";
import AddNewScheduleForm from "./AddNewScheduleForm";
import React, { useState } from "react";
import ConfirmModel from "../ConfirmModal/ConfirmModel";

export default function AddNewSchedule({
  visible,
  onCancel,
  queryClient,
  selectedRecord,
}) {
  const [confirmModel, setConfirmModel] = useState(false);
  const handleConfirmModelCancelClick = () => {
    setConfirmModel(false);
  };

  return (
    <>
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={onCancel}
        onCancel={onCancel}
        title={"Add New Schedule"}
        className={classNames.modal}
        destroyOnClose
        bodyStyle={{
          height: "70vh",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        width={866}
      >
        <AddNewScheduleForm
          queryClient={queryClient}
          onCancel={onCancel}
          selectedRecord={selectedRecord}
        />
      </Modal>
      <ConfirmModel
        visible={confirmModel}
        onCancel={handleConfirmModelCancelClick}
      />
    </>
  );
}
