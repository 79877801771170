import React from "react";
import { CTAButton, Modal } from "../../../common";
import { OWNER_INFO } from "./OwnerInfo.constant";
import ClassNames from "./OwnerInfo.module.scss";

const OwnerInfoModal = ({ visible, onCancel }) => {
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={true}
      onCloseClick={onCancel}
      onCancel={onCancel}
      className={ClassNames.infoModal}
      destroyOnClose={true}
      bodyStyle={{
        padding: 24,
        paddingRight: 0,
        display: "flex",
        flexDirection: "column",
        height: "70vh",
      }}
      title=""
      width={800}
      footer={
        <div className={ClassNames.actionButton}>
          <CTAButton htmlType="submit" type="primary" onClick={onCancel}>
            OK
          </CTAButton>
        </div>
      }
    >
      <div className={ClassNames.modalBody}>
        {OWNER_INFO.map((info) => {
          return (
            <div className={ClassNames.modalBodyContent}>
              <h2>{info?.que}</h2>
              <p dangerouslySetInnerHTML={{ __html: info?.ans }}></p>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default OwnerInfoModal;
