const strings = {
  errors: {
    invalidPayment: {
      title: "Invalid payment request",
      message: "Payment link information are not in correct format",
    },
    incompletePayment: {
      title: "Incomplete payment request",
      message:
        "Payment request is missing mandatory merchant information. Please contact support team.",
    },
    invalidMerchant: {
      title: "Invalid merchant",
      message:
        "Merchant's information could not be found. Please contact support team.",
    },
    infoNotFound: {
      title: "Payment gateway info not found",
      message:
        "Merchant is not capable of accepting payment. Please contact support team.",
    },
    invalidLink: {
      title: "Payment Link is Expired",
      message:
        "Your link has been expired. Please generate a new link",
    },
  },
};

const constants = { strings };

export default constants;
