import { API } from "aws-amplify";

export default async function updateUserStatus(reqBody) {
  const url = reqBody.isPerson
    ? `person/update-status/${reqBody.id}`
    : `business/update-status/${reqBody.id}`;

  if (reqBody.id) {
    return await API.patch("updateStatus", url, {
      body: {
        ...reqBody.data,
      },
    });
  }
}
