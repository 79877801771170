import { useNavigate } from "react-router-dom";
import { Input, Header } from "../../../common";
import { RiEditLine, RiDeleteBin7Line, RiSearch2Line } from "react-icons/ri";
import { Table, Space } from "antd";
import moment from "moment";
import { FORMATS, PAGE_SIZE } from "../../../constants";
import { PAGINATION_DEFAULT } from "../../../util/constants";
import draftInvoice from "../../../data/draftEstimatesInvoices.data";
import classNames from "./DraftEstimatesInvoices.module.scss";

export default function DraftEstimatesInvoices() {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b?.date, FORMATS.DATEPICKER_FORMAT)
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Space size="middle">
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
            />
            <RiDeleteBin7Line
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
            />
          </Space>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title="Draft estimates & invoices"
          back
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.searchBar}>
              <Input
                placeholder="Search draft "
                prefix={<RiSearch2Line size={26} />}
                className={classNames.search}
              />
            </div>
            <div className={classNames.actionRow}>
              <span>Displaying 50 of 205 drafts</span>
            </div>
            <div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                // scroll={{ y: `var(--table-height)` }}
                size="middle"
                dataSource={draftInvoice
                  ?.sort((a, b) =>
                    moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
                      moment(b?.date, FORMATS.DATEPICKER_FORMAT)
                    )
                  )
                  ?.reverse()}
                columns={columns}
                className={classNames.table}
                pagination={{
                  ...PAGINATION_DEFAULT,
                  pageSize: PAGE_SIZE,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
