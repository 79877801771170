import { API } from "aws-amplify";
import Bugsnag from "@bugsnag/js";
import mixpanel from "mixpanel-browser";
import api from "..";

export default async function createKYCKYB(data) {
  const response = await API.post("kyckyb", "", {
    body: {
      ...data,
    },
  });

  try {
    mixpanel.identify(data?.email);
           
    /* Mixpanel event for kyc/kyb verification */ 
    if(response?.payload?.verification_status !== "REJECTED") {
      mixpanel.track('KYC/KYB Check', {
        'Email ID': data?.email,
        'Sign Up Date': new Date().toISOString(),
        'Status': "Successful",
      });
    } else {
      mixpanel.track('KYC/KYB Check', {
        'Email ID': data?.email,
        'Sign Up Date': new Date().toISOString(),
        'Status': "Rejected"
      });
    }

    /* Check if verification_status is REJECTED */ 
    if (response?.payload?.verification_status === "REJECTED") {
      try {
        const businessRelationsResponse = await api.onBoarding.getBusinessRelations();

        const failedKYCPersons = businessRelationsResponse.payload.relationShips.filter(
          (relation) => relation.person_info.verification_status !== "ACCEPTED"
        );

        if (failedKYCPersons.length > 0) {
          const otzPersonIds = failedKYCPersons.map(
            (relation) => relation.person_info.otz_person_id
          );

          // Send the mail
          await sendSocureMail({ otz_person_ids: otzPersonIds });
        }
      } catch (error) {
        console.error("Something went wrong, please try again", error);
      }
    }
  } catch (error) {
    Bugsnag.notify(error);
  }

  return response;
}

// Define the sendSocureMail function
async function sendSocureMail(data) {
  try {
    await api.onBoarding.sendSocureMail(data);
  } catch (error) {
    Bugsnag.notify("Failed to send emails for socure. Please try again.", error);
  }
}
