import { Modal } from "../../../common";
import ActivateCardForm from "./ActivateCardForm";
import classNames from "../ManageCards.module.scss";

export default function ActivateCard({
  visible,
  onCancel,
  currentCard,
  isPin,
  title,
}) {
  return (
    <Modal
      destroyOnClose
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title={title}
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <div className={classNames.modalBody}>
        <ActivateCardForm currentCard={currentCard} isPin={isPin} />
      </div>
    </Modal>
  );
}
