import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { Form, Radio, Space, Button } from "antd";
import { RiCheckFill } from "react-icons/ri";
import { useState } from "react";

const Industry = ({ nextStep }) => {
  const [isRadioSelect, setIsRadioSet] = useState(true);
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setIsRadioSet(false);
    setValue(e.target.value);
  };

  const onFinish = () => {
    nextStep(Number(value));
  };
  return (
    <div className={classNames.formWrapper}>
      <Title as="h3">What industry does your business belong to?</Title>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        autoComplete="off"
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Form.Item>
            <Radio.Group onChange={onChange} value={value}>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <Radio.Button value="1" className={classNames.industryRadioBtn}>
                  <span>Vertical 1</span>
                  <RiCheckFill size={20} />
                </Radio.Button>
                <Radio.Button value="2" className={classNames.industryRadioBtn}>
                  <span>Vertical 2</span>
                  <RiCheckFill size={20} />
                </Radio.Button>
                <Radio.Button value="3" className={classNames.industryRadioBtn}>
                  <span>Vertical 3</span>
                  <RiCheckFill size={20} />
                </Radio.Button>
                <Radio.Button value="5" className={classNames.industryRadioBtn}>
                  <span>Other</span>
                  <RiCheckFill size={20} />
                </Radio.Button>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>

        <div className={classNames.footerButtonWrapper}>
          <Button
            htmlType="submit"
            type="primary"
            className={classNames.nextButton}
            disabled={isRadioSelect}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Industry;
