import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, Dropdown, Form, Menu as AntMenu } from "antd";
import { RiImage2Line } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api from "../../../../API";
import { CTAButton, Input, Message, Modal, Title } from "../../../../common";
// import { useLocalStorageData } from "../../../../../hooks/useLocalStorage";

import Backdrop from "../../../../common/Backdrop";
import classNames from "./Settings.module.scss";
import user from "../../../../../assets/user.png";
import { utilFileReader } from "../../../../util/functions/utilFileReader";
import generateErrorMessage from "../../../../util/functions/customError";
import { useStoreContext } from "../../../../../store";
import { MAX_MIN } from "../../../../util/constants";
import { validation } from "../../../../Auth/config";
import imageCompression from "browser-image-compression";
import REGEX from "../../../../util/constants/regex";
import { AppContext } from "../../../../../context/AppState";

const randomLogo = user;

const Settings = ({
  visible: visibleInit,
  onCancel,
  setPASettingsAvailability,
  onComplete,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  // const userData = useLocalStorageData("OTTERZ.userData");
  const { userData } = useContext(AppContext);
  const tenantId = userData?.tenantId;

  const [submitClicked, setSubmitClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const {
    actions: { setSettings },
  } = useStoreContext((state) => state.paymentAcceptanceSettings);

  const [imageProps, setImageProps] = useState({
    fileName: "",
    base64String: "",
  });

  useEffect(() => {
    setVisible(visibleInit);
  }, [visibleInit]);

  const enableSubmitButton = useCallback(
    () => submitClicked && setSubmitClicked(false),
    [submitClicked]
  );

  const onSubmitClick = () => {
    form.submit();
    setSubmitClicked(true);
  };

  const { data: getPASettings, isLoading: getPASettingsLoading } = useQuery(
    "getPASettings",
    () =>
      api.Receivables.PaymentAcceptance.getPASettings({
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!tenantId,
      refetchOnMount: "always",
    }
  );
  const {
    id = "",
    companyName = "",
    address = "",
    email = "",
    phoneNumber = "",
    logo = "",
  } = getPASettings?.data || {};

  useEffect(() => {
    if (!!companyName) form.resetFields();
  }, [companyName, form]);

  const {
    mutate: savePASettings,
    isLoading: savePASettingsLoading,
    reset: resetSave,
  } = useMutation(
    (data) => api.Receivables.PaymentAcceptance.savePASettings(data),
    {
      onSuccess: () => {
        setPASettingsAvailability(true);
        Message({
          type: "success",
          content: `Settings saved successfully`,
        });
        dismissDialog();
        onComplete();
      },
      onError: (error) => generateErrorMessage(error),
    }
  );

  const {
    mutate: updatePASettings,
    isLoading: updatePASettingsLoading,
    reset: resetUpdate,
  } = useMutation(api.Receivables.PaymentAcceptance.updatePASettings, {
    onSuccess: () => {
      Message({
        type: "success",
        content: `Settings updated successfully`,
      });
      queryClient.invalidateQueries("getPASettings");
      dismissDialog();
      onComplete()
    },
    onError: (error) => generateErrorMessage(error),
  });

  const dismissDialog = useCallback(() => {
    onCancel();
    form.resetFields();
    if (!!id) resetUpdate();
    else resetSave();
  }, [onCancel, form, id, resetUpdate, resetSave]);

  const onFormFinish = (values) => {
    if (!!id) {
      updatePASettings({
        data: { id, ...values, ...imageProps },
      });
      return;
    }
    if (imageProps.base64String) {
      savePASettings({ data: { ...values, ...imageProps } });
      return;
    }
    setError("Logo is required");
  };

  const iframeRef = useRef();
  const refreshIFrame = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src += "";
    }
  }, []);

  useEffect(() => {
    setSettings({
      companyName,
      address,
      email,
      phoneNumber,
      logo: imageProps.base64String || logo || randomLogo,
    });
    refreshIFrame();
  }, [
    companyName,
    address,
    email,
    phoneNumber,
    logo,
    imageProps.base64String,
    setSettings,
    refreshIFrame,
  ]);

  const debounceRef = useRef();
  const onFormChanged = () => {
    enableSubmitButton();

    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      setSettings({
        ...form.getFieldsValue(),
        logo: imageProps.base64String || logo || randomLogo,
      });
      refreshIFrame();
    }, 1000);
  };

  const compressImageProcess = async (inputFile) => {
    const options = {
      maxSizeMB: 5,
      useWebWorker: true,
    };

    try {
      const compressFile = await imageCompression(inputFile, options);

      return compressFile;
    } catch (error) {
      setError(`Error during image compression: ${error}`);
    }
  };

  const uploadPASettingsLogo = useCallback(
    async (fileEvent) => {
      const [inputFile] = fileEvent?.target?.files;
      if (!inputFile) return;
      if (!"image/png, image/jpeg".includes(inputFile.type)) {
        setError(`Invalid file type: ${inputFile.type}`);
        return;
      }

      if (inputFile.size > 5000000) {
        setError(`File size should be less than 5 MB.`);
        return;
      }
      const compressedFile = await compressImageProcess(inputFile);

      try {
        setImageProps({
          fileName: compressedFile.name,
          base64String: await utilFileReader(compressedFile),
        });
        setError("");
        refreshIFrame();
        enableSubmitButton();
      } catch (error) {
        setError("Image processing failed");
      }
    },
    [refreshIFrame, enableSubmitButton]
  );

  const fileInputRef = useRef();
  const onLogoClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Settings"
      className={classNames.modal}
      bodyStyle={{
        maxHeight: "65vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid #E5E7EB",
      }}
      footer={
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          style={{
            height: 44,
            width: 385,
          }}
          disabled={savePASettingsLoading || updatePASettingsLoading}
          onClick={onSubmitClick}
        >
          Update
        </CTAButton>
      }
      width={866}
      centered
    >
      <div className={classNames.modalBody}>
        {(getPASettingsLoading ||
          savePASettingsLoading ||
          updatePASettingsLoading) && <Backdrop size="large" />}
        <div className={classNames.modalBodyLeft}>
          <div className={classNames.avatarRow}>
            <Avatar
              size={80}
              src={imageProps.base64String || logo || randomLogo}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={uploadPASettingsLogo}
            />
            <Dropdown
              overlay={
                <AntMenu>
                  <AntMenu.Item key="0" onClick={onLogoClick}>{`${
                    id ? "Change" : "Upload"
                  } image`}</AntMenu.Item>
                </AntMenu>
              }
            >
              <Button shape="circle" icon={<RiImage2Line />} size="small" />
            </Dropdown>
          </div>
          {error && <span style={{ color: "red", fontSize: 12 }}>{error}</span>}
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            disabled={getPASettingsLoading}
            onFinish={onFormFinish}
            onChange={onFormChanged}
            initialValues={{ companyName, address, email, phoneNumber }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Company name is required",
                },
                {
                  pattern: new RegExp(REGEX.businessName),
                  message: "Enter a valid company name",
                },
                {
                  max: MAX_MIN.businessName.max,
                  message: `Max ${MAX_MIN.businessName.max} characters`,
                },
              ]}
              label={
                <Title as="h4" className={classNames.label}>
                  Company Name <sup style={{ top: 1, fontSize: 15 }}>*</sup>
                </Title>
              }
            >
              <Input placeholder="Please enter" />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Address is required",
                },
                {
                  max: 255,
                  message: "Max 255 characters",
                },
              ]}
              label={
                <Title as="h4" className={classNames.label}>
                  Address <sup style={{ top: 1, fontSize: 15 }}>*</sup>
                </Title>
              }
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: validation.email.required },
                {
                  type: "email",
                  message: validation.email.valid,
                },
                {
                  min: MAX_MIN.email.min,
                  message: validation.email.minLength,
                },
                {
                  max: MAX_MIN.email.max,
                  message: validation.email.maxLength,
                },
              ]}
              label={
                <Title as="h4" className={classNames.label}>
                  Email <sup style={{ top: 1, fontSize: 15 }}>*</sup>
                </Title>
              }
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[
                { required: true, message: validation.phoneNumber.required },
                {
                  pattern: new RegExp(REGEX.phoneNumber),
                  message: validation.phoneNumber.valid,
                },
              ]}
              label={
                <Title as="h4" className={classNames.label}>
                  Phone Number<sup style={{ top: 1, fontSize: 15 }}>*</sup>
                </Title>
              }
            >
              <Input placeholder="Phone" />
            </Form.Item>
          </Form>
        </div>
        <div className={classNames.modalBodyRight}>
          <iframe
            ref={iframeRef}
            title="Otterz payment acceptance settings preview"
            src={`${
              new URL(window.location.href).origin
            }/payment/summary?amountEditable=dHJ1ZQ==&infoEditable=dHJ1ZQ==&paSettings=dHJ1ZQ==`}
            className={classNames.settingsPreview}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
