import classNames from "../ProductsServices.module.scss";
import { Form, Input as AntInput, Modal } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CTAButton, Input, Select, Message } from "../../../../common";
import api from "../../../../API";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { MAX_MIN } from "../../../../util/constants";

export default function AddNewProduct({ visible, onCancel }) {
  const language = useLanguage(messages);
  const { Option } = Select;
  const [form] = Form.useForm();
  const { TextArea } = AntInput;
  const queryClient = useQueryClient();

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  function createProdcutFn(data) {
    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      productName: data.productName,
      taxRate: data.taxRate,
      currency: data.currency,
      description: data.description,
      price: data.price,
    };
    return api.Receivables.Product.create(input);
  }

  const { data: taxQuery, isSuccess: taxQueryIsSuccess } = useQuery(
    "getAllTaxes",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const taxRates =
    taxQueryIsSuccess &&
    Array.isArray(taxQuery?.data) &&
    taxQuery?.data.map((val, index) => (
      <Select.Option value={val.taxRate}>{val.taxRateName} ({val.taxRate}%)</Select.Option>
    ));

  if (taxRates.length > 0) {
    taxRates.unshift(<Select.Option value={"0"}>No tax</Select.Option>);
  }

  const createProductMn = useMutation((event) => createProdcutFn(event));

  const handleOnFinish = (data) => {
    createProductMn.mutate(data, {
      onSuccess: () => {
        Message({ type: "success", content: language.PRODUCT_CREATE_SUCCESS });
        queryClient.invalidateQueries("getProducts");
        form.resetFields();
        onCancel(true);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  //use Modal from antd due to rendering error
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      title="Add New Product  "
      className={classNames.modal}
      bodyStyle={{
        height: "auto",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
      footer={false}
      centered
    >
      <div className={classNames.modalBody}>
        <Form
          form={form}
          onFinish={handleOnFinish}
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            rules={[
              {
                max: MAX_MIN.businessName.max,
                message: `Max ${MAX_MIN.businessName.max} characters`,
              },
              { required: true, message: "Product name is required" },
            ]}
            name="productName"
            label={
              <span className={classNames.label}>
                Product Name <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input placeholder="Please Enter" size="small" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                max: MAX_MIN.description.max,
                message: `Max ${MAX_MIN.description.max} characters`,
              },
            ]}
            name="description"
            label={<span className={classNames.label}>Description</span>}
          >
            <TextArea
              placeholder="Please enter"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              rules={[
                { max: 11, message: "Max 8 digits with 2 decimal points" },
                { min: 0, message: "Minimum value is zero" },
                {
                  pattern: new RegExp(/^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/gm),
                  message: "Please enter a valid amount (eg. 12345678.12)",
                },
              ]}
              name="price"
              label={<span className={classNames.label}>Price</span>}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
            >
              <Input
                placeholder="Enter amount"
                size="small"
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                min={0}
                maxLength={11}
              />
            </Form.Item>

            <Form.Item
              name="currency"
              label={
                <span className={classNames.label}>
                  Currency <sup style={{ top: 1 }}>*</sup>{" "}
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              initialValue="USD"
            >
              <Select
                placeholder="Please select"
                style={{ width: 180 }}
                size="small"
                disabled
              >
                <Option value={"USD"}>USD</Option>
                <Option value={"GBP"}>GBP</Option>
                <Option value={"AED"}>AED</Option>
                <Option value={"UYD"}>UYD</Option>
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="taxRate"
            label={<span className={classNames.label}>Tax Rate</span>}
          >
            <Select
              placeholder="Please select"
              style={{ width: 185 }}
              size="small"
            >
              {taxRates}
            </Select>
          </Form.Item>
          <CTAButton
            type="primary"
            style={{ height: 44, width: 384, marginTop: 20 }}
            onClick={form.submit}
            loading={createProductMn.isLoading}
          >
            Save
          </CTAButton>
        </Form>
      </div>
    </Modal>
  );
}
