import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Form, Upload, Button, Checkbox } from "antd";
import {
  CTAButton,
  Title,
  Message,
} from "../../common";
import useLanguage from "../../../hooks/useLanguage";
import messages from "../util/messages.const";
import Heading from "../Heading";
import Steps from "../Steps";
import AccountStatus from "../VerifyAccount/VerifyAccount";
import useLocalStorage, {useLocalStorageData} from "../../../hooks/useLocalStorage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { ROUTES, ROUTE_STATUS, USER_STATES } from "../../constants";
import { FILE_SIZE } from "../../constants";
import classNames from "../Signup/Signup.module.scss";
import mixpanel from "mixpanel-browser";

export default function BABeneficialOwnerDocs() {
  //Hooks
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [progress, setProgress] = useLocalStorage("OTTERZ_op_od");
  const businessData = useLocalStorageData("OTTERZ_op_bd");
  const { userData } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [, setBOData] = useState();
  const [isDisclosureAccepted, setIsDisclosureAccepted] = useState(false);
  const [isOwnershipCertified, setIsOwnershipCertified] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const language = useLanguage(messages);

  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: boData } = useQuery(
    "getBusinessPersonDetail",
    () => api.onBoarding.getBusinessPersonDetail({otz_to_business_id: application?.payload?.otz_business_id}),
    {
      onSuccess: (data) => {
        setBOData(data);
      },
      refetchOnWindowFocus: false,
      enabled: !!application?.payload?.otz_business_id, // This ensures the query runs only when otz_business_id is available
    }
  );

  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  const uploadRegDocsMutation = useMutation((formData) =>
    api.onBoarding.uploadRegDocsOnboarding(formData)
  );

  const addDigiStampMutation = useMutation((objectData) =>
    api.onBoarding.addDigiStamp(objectData)
  );

  //Event handlers
  async function handleOnFinish() {
    setIsSubmitting(true);

    let inputData = form.getFieldsValue();

    setProgress({
      ...inputData,
    });

    const uploadTasks = Object.values(uploadedFiles).flat().map(async (file) => {
        const formData = new FormData();
        if (file?.otz_person_id) {
          formData.append("otz_person_id", file?.otz_person_id);
        }
        // if (application?.otz_business_id) {
        //     formData.append("otz_business_id", application?.payload?.otz_business_id);
        // } else {
        //   formData.append("otz_business_id", businessData?.id);
        // }

        formData.append("file", file);
        formData.append("doc_type", file.docType);
      
        try {
            const data = await uploadRegDocsMutation.mutateAsync(formData);

            await addDigiStampMutation.mutateAsync({
              "person_name": file?.personName,
              "title": file?.personType,
              "personID": file?.otz_person_id
            })
            // Message({ type: "success", content: data?.message });
            return data?.payload?.otz_business_id;
        } catch (error) {
            generateErrorMessage(error);
            throw error;
        }
    });

    try {
        const businessIds = await Promise.all(uploadTasks);
        if (businessIds.length > 0) {
            setProgress((prevVals) => ({
                ...prevVals,
                BODocs: true,
                id: businessIds[0], 
            }));

            try {
              const listOfDocumentsUploaded = Object.values(uploadedFiles).flat().map(file => file.name);
              mixpanel.track("Person Documents", {
                  "Upload status": "Success",
                  "List of documents uploaded": listOfDocumentsUploaded.join(', ')
              });
            } catch (error) {
              console.error("mixpanel-error: ", error)
            }

            updateApplication.mutate(
              {
                onBoardingStatus: "BENEFICIAL_OWNER_DOCS_UPLOADED",
              },
              {
                onSuccess: () => {},
                onError: (err) => {
                  throw new Error(err);
                },
              }
            );
            handleSave();
            Message({ type: "success", content: "All documents uploaded successfully!" });
        }
    } catch (error) {
        // Handle the error here. For example, show a message to the user.
        try {
          mixpanel.track("Person Documents", {
            "Upload status": "Failed",
            "List of documents uploaded": "None"
        });
        } catch (error) {
          console.error("mixpanel-error: ", error)
        }
        Message({ type: "error", content: "Failed to upload documents. Please try again." });
    }
}

  const handlerOnSuccess = () => {
    setVisible(false);

    try {
      mixpanel.people.set({
        $name: businessData?.name,
      });
      mixpanel.track("Officer - Completed", {
        "Email ID": userData?.email,
        "Sign Up Date": new Date().toISOString(),
        "Business name": businessData?.name,
        "Company_ID": businessData?.id,
        "Officer added?": Object.keys(progress).length !== 0,
      });

      mixpanel.track("Sign up - completed", {
        "Company_ID": businessData?.id,
        "Sign Up Date": new Date().toISOString(),
        "App permission status": userData?.approved,
        "Account type": userData?.planType,
      });
    } catch (error) {
      console.error("error: ", error);
    }

    navigate(
      `${ROUTES.ONBOARDING.THANK_YOU}${
        ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
      }`
    );
  };

  const handleSave = () => {
    
    setVisible(true);
  };

  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleBeforeUpload = (file, identifier, otzPersonID, personType) => {
    if (uploadedFiles[identifier] && uploadedFiles[identifier].length >= 1) {
        Message({
            type: "error",
            content: `Only one document is allowed for each beneficial owner or officer.`,
        });
        return false;
    }
  
    if (!acceptedType(file)) {
        setError(false);
        Message({
            type: "error",
            content: `File type is not allowed. Please select a JPEG`,
        });
        return false;
    } else if (file?.size > FILE_SIZE.B) {
        setError(true);
        return false;
    } else {
        setError(false);
        const docType = "Goverment ID";
        file.docType = docType;
        file.otz_person_id = otzPersonID;
        file.personName = identifier;
        file.personType = personType;
        setUploadedFiles(prev => ({
            ...prev,
            [identifier]: [...(prev[identifier] || []), file]
        }));
      
        const formKey = `${identifier}-government-id`;
        form.setFieldsValue({ [formKey]: file.name });
      
        return false; // return false so file is not auto uploaded
    }
  };



  const handleRemove = (file, identifier) => {
      setUploadedFiles(prev => ({
          ...prev,
          [identifier]: prev[identifier].filter(f => f.uid !== file.uid)
      }));
  };

  const acceptableType = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
  const acceptableTypes = acceptableType.join(",");
  const { Dragger } = Upload;

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  return (
    <>
      <div className={classNames.accountFormWrapper}>
        <Steps classNames={classNames} currentIndex={6} />
        <div className={classNames.formWrapper}>
          <Heading
            classNames={classNames}
            title="Sign up for business account"
            description="Please upload the supporting documents as requested below"
          />
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            form={form}
            initialValues={{
              progress,
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <div className={classNames.formLabel}>Beneficial Owners Documents</div>
            {/* Use array of Beneficial Owners */}
            {
              boData?.payload?.filter(item => item.beneficial_type === "BENEFICIAL_OWNER_OF").map((data, index) => {
              // progressBO?.beneficialOwners?.map((data, index) => {
                const identifier = `${data.first_name}-${data.last_name}-${index}-BOwner`;
                return (
                  <Form.Item key={index}>
                      <div className={classNames.formLabel}>{`${data.first_name} ${data.last_name}`}</div>
                      <Form.Item
                    name={`${identifier}-government-id`}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Government-issued ID <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Document Required",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback(`Max ${FILE_SIZE.MB} is allowed`);
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                      <label style={{fontSize: "10px", color: 'gray', lineHeight: "1.0715 !important"}}>Upload government-issued ID, like a passport or driver's license verification</label>
                      <Dragger
                        className={""}
                        maxCount={1}
                        accept={acceptableTypes}
                        fileList={uploadedFiles[identifier] || []}
                        beforeUpload={(file) => handleBeforeUpload(file, identifier, data?.otz_person_id, "Beneficial Owner")}
                        onRemove={(file) => handleRemove(file, identifier)}
                        customRequest={({ file, onSuccess, onError }) => {
                          if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                            onSuccess("ok");
                          } else onError();
                        }}
                        onChange={({ file, fileList }) => {
                          if (file.status !== 'uploading') {
                          }
                        }}
                        showUploadList={true}
                      >
                        <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                          <i
                            className="ri-drag-drop-line"
                            style={{ fontSize: "24px", color: "#9BA3AB" }}
                          ></i>
                        </p>
                        <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                          {language.DRAG_DROP}
                          <Button
                            type="link"
                            size="small"
                            style={{ fontSize: "12px" }}
                          >
                            {language.IMAGE_UPLOAD_BTN_TEXT}
                          </Button>
                        </p>
                      </Dragger>
                      </Form.Item>
                  </Form.Item>
                )
              })
            }

            <div className={classNames.formLabel}>Officer Documents</div>
            {/* Use array of Beneficial Officers */}
            {
              boData?.payload?.filter(item => item.beneficial_type !== "BENEFICIAL_OWNER_OF").map((data, index) => {
              // progress?.officers?.map((data, index) => {
                const identifier = `${data.first_name}-${data.last_name}-${index}-Officer`;
                return (
                  <Form.Item key={index}>
                      <div className={classNames.formLabel}>{`${data.first_name} ${data.last_name}`}</div>
                      <Form.Item
                    name={`${identifier}-government-id`}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Government-issued ID <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Document Required",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (error) {
                            callback(`Max ${FILE_SIZE.MB} is allowed`);
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                      <label style={{fontSize: "10px", color: 'gray', lineHeight: "1.0715 !important"}}>Upload government-issued ID, like a passport or driver's license verification</label>
                      <Dragger
                        className={""}
                        maxCount={1}
                        accept={acceptableTypes}
                        fileList={uploadedFiles[identifier] || []}
                        beforeUpload={(file) => handleBeforeUpload(file, identifier, data?.otz_person_id, "Officer")}
                        onRemove={(file) => handleRemove(file, identifier)}
                        customRequest={({ file, onSuccess, onError }) => {
                          if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                            onSuccess("ok");
                          } else onError();
                        }}
                        onChange={({ file, fileList }) => {
                          if (file.status !== 'uploading') {
                          }
                        }}
                        showUploadList={true}
                      >
                        <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                          <i
                            className="ri-drag-drop-line"
                            style={{ fontSize: "24px", color: "#9BA3AB" }}
                          ></i>
                        </p>
                        <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                          {language.DRAG_DROP}
                          <Button
                            type="link"
                            size="small"
                            style={{ fontSize: "12px" }}
                          >
                            {language.IMAGE_UPLOAD_BTN_TEXT}
                          </Button>
                        </p>
                      </Dragger>
                      </Form.Item>
                  </Form.Item>
                )
              })
            }

            <Form.Item className={classNames.links}>
              <Checkbox
                checked={isDisclosureAccepted}
                defaultChecked={false}
                onChange={() => setIsDisclosureAccepted(!isDisclosureAccepted)}
                style={{textAlign: "left"}}
              >
                <span>
                  I agree that I have read and accept all the
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      window.open(`https://www.otterz.co/disclosures`);
                    }}
                    style={{fontSize: "0.75rem"}}
                  >
                    disclosures{" "}
                  </Button>
                  provided by Otterz.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item className={classNames.links}>
              <Checkbox
                checked={isOwnershipCertified}
                defaultChecked={false}
                onChange={() => setIsOwnershipCertified(!isOwnershipCertified)}
                style={{textAlign: "left"}}
              >
                <span style={{textAlign: "left"}}>
                I certify that I've listed all owners with at least 25% ownership and anyone with significant control over managing the business.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <CTAButton
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting || uploadRegDocsMutation.isLoading}
                  disabled={!(isDisclosureAccepted && isOwnershipCertified)}
                >
                  Next
                </CTAButton>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      <AccountStatus
        visible={visible}
        onCancel={() => setVisible(false)}
        onRetry={() => setVisible(true)}
        userData={userData}
        inputData={form.getFieldsValue()}
        onSuccess={handlerOnSuccess}
        isPerson={false}
      />
    </>
  );
}
