import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../App/constants";

const UseIdleTimer = () => {
  const [, setRemaining] = useState(0);
  const navigate = useNavigate();

  const onIdle = () => {
    navigate(ROUTES.SESSION_TIMEOUT);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1200000,
    stopOnIdle: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return null;
};

export default UseIdleTimer;
