import classNames from "../../Overview.module.scss";
import { Modal, CTAButton, Input } from "../../../../common";

export default function ShareModal({ visible, onCancel }) {
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Share Account Details"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        height: 200,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <label style={{ marginBottom: 14, fontSize: "12px", fontWeight: "500" }}>
        Email Address
      </label>
      <Input size="small" placeholder="Please enter email address" />

      <CTAButton
        style={{
          height: 44,
          width: 384,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "24px",
        }}
        type="primary"
      >
        Share
      </CTAButton>
    </Modal>
  );
}
