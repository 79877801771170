const receivablesMenuItems = [
  // { item: "Invoices", path: "invoices", key: "invoices", isPaid: false },
  {
    item: "Payment Acceptance",
    path: "payment-acceptance",
    key: "payment_acceptance",
    isPaid: false,
  },
];

export default receivablesMenuItems;
