// import { Progress, Button } from "antd";
import camelCaseToTitle from "../camelCaseToTitle.util";

export default function CardInfo({
  classNames,
  cardHolder,
  cardType,
  // status,
  usage,
  limit,
  editLimit,
  currentCard,
}) {
  /* const [percentage, setPercentage] = useState();
  const [higherLimit, setHigherLimit] = useState();
  const [cardSpend, setCardSpend] = useState();

  const CURRENCYCONVERSION = 100;
  const DEFAULTVALUE = "$0.00";

  useEffect(() => {
    setCardSpend(usage?.monthlyUsage);
    if (
      limit &&
      limit.hasOwnProperty("monthlyPurchases") &&
      limit?.monthlyPurchases > 0 &&
      usage
    ) {
      setPercentage(
        (usage.monthlyUsage /
          (limit?.monthlyPurchases && limit?.monthlyPurchases)) *
          CURRENCYCONVERSION
      );

      setHigherLimit(limit.monthlyPurchases);
    } else if (
      limit &&
      limit.hasOwnProperty("dailyPurchases") &&
      limit.dailyPurchases > 0 &&
      usage
    ) {
      setPercentage(
        (usage.dailyUsage / (limit?.dailyPurchases && limit?.dailyPurchases)) *
          CURRENCYCONVERSION
      );
      setHigherLimit(limit.dailyPurchases);
    }
  }, [
    currentCard?.id,
    limit?.dailyPurchases,
    limit?.monthlyPurchases,
    usage?.monthlyUsage,
    usage?.dailyUsage,
    limit,
    usage,
    percentage,
    higherLimit,
  ]); */

  // const [cardStatus, setCardStatus] = useState(status);

  // useEffect(() => {
  //   setCardStatus(status);
  // }, [cardStatus]);

  return (
    <>
      <div className={classNames.carouselItemDetails}>
        <div>
          <span>{cardHolder}</span>
          <span>{camelCaseToTitle(cardType)}</span>
        </div>
        <div>
          {currentCard?.card_status === "ACTIVE" ? (
            <span style={{ background: "#0f0" }}>
              {currentCard?.card_status}
            </span>
          ) : currentCard?.card_status === "UNACTIVATED" ? (
            <span style={{ background: "#bc7c1d" }}>INACTIVE</span>
          ) : currentCard?.card_status === "SUSPENDED" ? (
            <span style={{ background: "#bc7c1d" }}>FREEZE</span>
          ) : (
            <span style={{ background: "#dd0000" }}>CLOSED</span>
          )}
        </div>
      </div>
      {/* <div className={classNames.carouselItemUsageDetails}>
        {!limit?.msg && (
          <Progress
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={!Number.isNaN(percentage) ? percentage : 0}
            showInfo={false}
          />
        )}
      </div> */}
      {/* <div className={classNames.carouselItemFooter}>
        {limit?.msg === "Not Defined" ? (
          <div className={classNames.carouselItemNotDefine}>
            <b>Limits Not Defined</b>
            <b>
              {" "}
              Total Card Purchases: {centsToDollar(cardSpend) || DEFAULTVALUE}
            </b>
          </div>
        ) : (
          <div>
            <span>
              {!Number.isNaN(percentage) ? percentage?.toFixed(0) : 0}%
            </span>
            <span>
              used of <br />{" "}
              <b>
                $
                {!Number.isNaN(higherLimit)
                  ? higherLimit / CURRENCYCONVERSION
                  : 0}
              </b>
            </span>
          </div>
        )}
        <Button
          type="link"
          onClick={editLimit}
          disabled={[
            "Inactive",
            "ClosedByCustomer",
            "Stolen",
            "SuspectedFraud",
          ].includes(currentCard?.attributes?.status)}
        >
          Edit Limit
        </Button>
      </div> */}
    </>
  );
}
