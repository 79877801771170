// import endpoints from "../endpoints";
import { API } from "aws-amplify";

export default async function getPendingTransaction(params) {
  if (params?.otz_account_id) {
    return await API.get("transactions", "pending", {
      queryStringParameters: params,
    });
  }
}
