import moment from "moment";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  QueryErrorResetBoundary,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button as AntButton,
  Card,
  Empty,
  Radio,
  Select as AntSelect,
  Space,
  Spin,
  Table,
} from "antd";
import {
  RiAlertLine,
  RiAnticlockwise2Line,
  RiArrowLeftDownLine,
  RiArrowRightLine,
  RiArrowRightUpLine,
  RiBillLine,
  RiDatabase2Line,
  RiEdit2Line,
  RiMoneyDollarBoxLine,
  RiUser2Line,
} from "react-icons/ri";
import api from "../../../API";
import { Header, Message, Title } from "../../../common";
import { DEFAULT_AMOUNT,
   // FORMATS, 
   ROUTES 
  } from "../../../constants";
import { intToDecimalDollar } from "../../../util/functions/dollarConvertion";
import AddNewRecurringInvoices from "../RecurringInvoices/AddNewRecurringInvoices/AddNewRecurringInvoices";
import ChangeStatus from "./ChangeStatus/ChangeStatus";
import CreateNewEstimate from "./CreateNewEstimate/CreateNewEstimate";
import CreateNewInvoice from "./CreateNewInvoice/CreateNewInvoice";
import classNames from "./Invoices.module.scss";
import generateErrorMessage from "../../../util/functions/customError";
import messages from "../../config/messages.const";
import useLanguage from "../../../../hooks/useLanguage";
import usePAActiveStatus from "../../../../hooks/usePAActiveStatus";
import Settings from "../../PaymentAcceptance/PaymentAcceptanceOverview/Settings/Settings";

export default function Invoices() {
  const language = useLanguage(messages);
  const [invoiceData, setInvoiceData] = useState();
  const [estimateData, setEstimateData] = useState();
  const [invoiceFilterAttribute, setInvoiceFilterAttribute] = useState("all");
  const [estimateFilterAttribute, setEstimateFilterAttribute] = useState("all");
  const [createNewInvoiceModalVisibility, setCreateNewInvoiceModalVisibility] =
    useState(false);
  const [enableOnlinePaymentCheck, setEnableOnlinePaymentCheck] = useState();
  const queryClient = useQueryClient();
  const [
    createNewEstimateModalVisibility,
    setCreateNewEstimateModalVisibility,
  ] = useState(false);
  const [settingsVisibility, setSettingsVisibility] = useState(false);

  const handleCreateNewInvoiceModalCancelClick = () => {
    setCreateNewInvoiceModalVisibility(false);
  };

  const handleCreateNewEstimateModalCancelClick = () => {
    setCreateNewEstimateModalVisibility(false);
  };
  const [createNewRecurringInvoiceModal, setCreateNewRecurringInvoiceModal] =
    useState(false);

  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState();
  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
  };
  const handleStatusChangeInvoice = (value, record) => {
    setInvoiceStatus({ flag: "invoice", value: value, record: record });
    setChangeStatusModalVisibility(true);
  };
  const handleStatusChangeEstimate = (value, record) => {
    setInvoiceStatus({ flag: "estimate", value: value, record: record });
    setChangeStatusModalVisibility(true);
  };

  const handleRecurringCancelClick = () => {
    setCreateNewRecurringInvoiceModal(false);
  };

  // fetching PA status to disable enable online payments widget
  // onlline payment widget will disable if the payment acceptance is not active (paStatus attribute is false)
  const {
    status: paStatus,
    paSettingsStatus,
    isPASettingsLoading: getPASettingsLoading,
    isLoading: onboardingRequestLoading,
  } = usePAActiveStatus();

  const [isPASettingsAvailable, setPASettingsAvailability] =
    useState(paSettingsStatus);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoiceQuery } = useQuery(
    "getInvoices",
    () =>
      api.Receivables.Invoices.getInvoices(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateQuery } = useQuery(
    "getEstimates",
    () =>
      api.Receivables.Estimates.listEstimates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: overallInvoiceDetailsQuery,
    isFetching: overallInvoiceDetailsFetching,
  } = useQuery(
    "getOverallInvoiceDetails",
    () =>
      api.Receivables.Invoices.getOverallInvoiceDetails(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoiceTemplateSettingsQuery,
    // isFetching: invoiceTemplateSettingsFetching,
    isSuccess: invoiceTemplateSettingsSuccess,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateTemplateSettingsQuery } = useQuery(
    "getEstimateSettings",
    () =>
      api.Receivables.Settings.getEstimateTemplateSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const handleSettingsCancelClick = () => {
    setSettingsVisibility(false);
  };

  useEffect(() => {
    if (invoiceTemplateSettingsSuccess) {
      setEnableOnlinePaymentCheck(
        invoiceTemplateSettingsQuery?.data.enableOnlinePayments
      );
    }
  }, [
    invoiceTemplateSettingsSuccess,
    invoiceTemplateSettingsQuery?.data.enableOnlinePayments,
  ]);

  useEffect(() => {
    invoiceQuery &&
      !invoiceQuery?.data?.message &&
      setInvoiceData(
        invoiceQuery?.data
          .map((invoice) => {
            return {
              key: `invoice${invoice.id}`,
              id: invoice.id,
              number: invoice.invoiceNumber,
              client: invoice.receiverName,
              date: invoice.invoiceDate,
              amount: `${invoice.currencySymbol}${invoice.total}`,
              total: invoice.total,
              status: invoice.invoiceStatus,
            };
          })
          .sort((a, b) => a.number - b.number)
          ?.reverse()
      );
  }, [invoiceQuery]);

  useEffect(() => {
    estimateQuery &&
      !estimateQuery?.data?.message &&
      setEstimateData(
        estimateQuery?.data
          .map((estimate) => {
            return {
              key: `estimate${estimate.id}`,
              id: estimate.id,
              number: estimate.estimateNumber,
              client: estimate.receiverName,
              date: estimate.estimateDate,
              amount: `${estimate.currencySymbol}${estimate.total}`,
              total: estimate.total,
              status: estimate.estimateStatus,
            };
          })
          .sort((a, b) => a.number - b.number)
          ?.reverse()
      );
  }, [estimateQuery]);

  const navigate = useNavigate();
  const location = useLocation();

  //Invoice columns
  const invoiceColumns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "10%",
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <span>{moment(date).format()}</span>;
      },
      width: "20%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => {
        return <span>{intToDecimalDollar(total)}</span>;
      },
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: `status${Math.random()}`,
      width: "20%",
      render: (status, record) =>
        status === "paid" ? (
          <span style={{ color: "#1DBC83" }}>Paid</span>
        ) : (
          <AntSelect
            key={`invoiceStatus${record.id}`}
            defaultValue={status}
            bordered={false}
            className={classNames.statusSelect}
            onChange={(value) => {
              handleStatusChangeInvoice(value, record);
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {" "}
            {status === "draft" || status === "overdue" ? (
              <>
                <AntSelect.Option value="draft">
                  <span style={{ color: "#BC7C1D" }}>Draft</span>
                </AntSelect.Option>
                <AntSelect.Option value="sent">
                  <span style={{ color: "#7372FF" }}>Sent</span>
                </AntSelect.Option>
                {/* <AntSelect.Option value="late">
    <span style={{ color: "#EE6969" }}>Overdue</span>
  </AntSelect.Option> */}
                <AntSelect.Option value="paid">
                  <span style={{ color: "#1DBC83" }}>Paid</span>
                </AntSelect.Option>
              </>
            ) : status === "sent" ? (
              <>
                {/* <AntSelect.Option value="late">
    <span style={{ color: "#EE6969" }}>Overdue</span>
  </AntSelect.Option> */}
                <AntSelect.Option value="sent">
                  <span style={{ color: "#7372FF" }}>Sent</span>
                </AntSelect.Option>

                <AntSelect.Option value="paid">
                  <span style={{ color: "#1DBC83" }}>Paid</span>
                </AntSelect.Option>
              </>
            ) : (
              ""
            )}
          </AntSelect>
        ),
    },
  ];

  //Estimate columns
  const estimateColumns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "10%",
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <span>{moment(date).format()}</span>;
      },
      width: "20%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => {
        return <span>{intToDecimalDollar(total)}</span>;
      },
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: `status${Math.random()}`,
      width: "20%",
      render: (status, record) => (
        <AntSelect
          key={`estimateStatus${record.id}`}
          defaultValue={status === "overdue" ? "Overdue" : status}
          // disabled={status === "overdue" ? true : false}
          bordered={false}
          className={classNames.statusSelect}
          onChange={(value) => {
            handleStatusChangeEstimate(value, record);
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {" "}
          {status === "draft" || status === "overdue" ? (
            <>
              <AntSelect.Option value="draft">
                <span style={{ color: "#BC7C1D" }}>Draft</span>
              </AntSelect.Option>
              <AntSelect.Option value="sent">
                <span style={{ color: "#7372FF" }}>Sent</span>
              </AntSelect.Option>
            </>
          ) : status === "sent" ? (
            <>
              {/* <AntSelect.Option value="late">
    <span style={{ color: "#EE6969" }}>Overdue</span>
  </AntSelect.Option> */}
              <AntSelect.Option value="sent">
                <span style={{ color: "#7372FF" }}>Sent</span>
              </AntSelect.Option>
            </>
          ) : (
            ""
          )}
        </AntSelect>
      ),
    },
  ];

  const invoicesRowClick = (record) => {
    Object.assign(record, { from: location.pathname });
    navigate(ROUTES.APP.RECEIVABLES.INVOICE.replace(":id", record.number), {
      state: record,
    });
  };

  const estimatesRowClick = (record) => {
    Object.assign(record, { from: location.pathname });
    navigate(ROUTES.APP.RECEIVABLES.ESTIMATES.replace(":id", record.number), {
      state: record,
    });
  };

  const updateInvoiceTemplateFn = (event) => {
    let inputInvoiceSettings = {
      id: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      enableOnlinePayments: event,
    };
    return api.Receivables.Settings.invoice(inputInvoiceSettings);
  };

  const enableOnlinePaymentseMn = useMutation((event) =>
    updateInvoiceTemplateFn(event)
  );

  const enableOnlinePaymentsOnChange = (event) => {
    const paSetting = paSettingsStatus
      ? paSettingsStatus
      : isPASettingsAvailable;
    if (!paSetting) {
      setSettingsVisibility(true);
    } else {
      enableOnlinePaymentseMn.mutate(event, {
        onSuccess: () => {
          Message({
            type: "success",
            content: event
              ? language.ENABLE_ONLINE_PAYMENTS
              : language.DISABLE_ONLINE_PAYMENTS,
          });
          setEnableOnlinePaymentCheck(event);
          queryClient.invalidateQueries("getInvoiceSettings");
        },
        onError: (error) => generateErrorMessage(error),
      });
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title="Invoices"
          enableOnlinePayments={
            !(onboardingRequestLoading || getPASettingsLoading)
          }
          enableOnlinePaymentsOnChange={enableOnlinePaymentsOnChange}
          enableOnlinePaymentsChecked={enableOnlinePaymentCheck}
          enableOnlinePaymentLoading={enableOnlinePaymentseMn.isLoading}
          paSettingsStatus={paSettingsStatus
            ? paSettingsStatus
            : isPASettingsAvailable}
          paStatus={paStatus}
        />
      </div>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <AntButton onClick={() => resetErrorBoundary()}>
                  Try again
                </AntButton>
              </div>
            )}
          >
            <div className={classNames.layout}>
              <div className={classNames.bodyWrapper}>
                <div className={classNames.topRow}>
                  <div>
                    <Card className={classNames.valueCard}>
                      <div className={classNames.sentInvoices}>
                        <div className={classNames.icon}>
                          <RiArrowRightUpLine size={26} />
                        </div>
                        <Spin spinning={overallInvoiceDetailsFetching}>
                          <div className={classNames.amount}>
                            <Title as="h3">Invoices Sent</Title>
                            <div>
                              {intToDecimalDollar(
                                Number(
                                  overallInvoiceDetailsQuery?.data
                                    ?.sentInvoicesAmount
                                )
                              ) || DEFAULT_AMOUNT}
                            </div>
                            <span>
                              {`${
                                overallInvoiceDetailsQuery?.data
                                  ?.sentInvoicesCount || 0
                              } invoices`}
                            </span>
                          </div>
                        </Spin>
                      </div>
                      <div className={classNames.paidInvoices}>
                        <div className={classNames.icon}>
                          <RiArrowLeftDownLine size={26} />
                        </div>
                        <Spin spinning={overallInvoiceDetailsFetching}>
                          <div className={classNames.amount}>
                            <Title as="h3">Invoices Paid</Title>
                            <div>
                              {intToDecimalDollar(
                                Number(
                                  overallInvoiceDetailsQuery?.data
                                    ?.paidInvoicesAmount
                                )
                              ) || DEFAULT_AMOUNT}
                            </div>
                            <span>
                              {`${
                                overallInvoiceDetailsQuery?.data
                                  ?.paidInvoicesCount || 0
                              } invoices`}
                            </span>
                          </div>
                        </Spin>
                      </div>
                      <div className={classNames.overdueInvoices}>
                        <div className={classNames.icon}>
                          <RiAlertLine size={26} />
                        </div>
                        <Spin spinning={overallInvoiceDetailsFetching}>
                          <div className={classNames.amount}>
                            <Title as="h3">Invoices Overdue</Title>
                            <div>
                              {intToDecimalDollar(
                                Number(
                                  overallInvoiceDetailsQuery?.data
                                    ?.overdueInvoicesAmount
                                )
                              ) || DEFAULT_AMOUNT}
                            </div>
                            <span>
                              {`${
                                overallInvoiceDetailsQuery?.data
                                  ?.overdueInvoicesCount || 0
                              } invoices`}
                            </span>
                          </div>
                        </Spin>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className={classNames.optionCard}
                      onClick={() => setCreateNewInvoiceModalVisibility(true)}
                    >
                      <RiMoneyDollarBoxLine
                        color="#fff"
                        size={26}
                        className={classNames.optionIcon}
                      />
                      <div className={classNames.optionIconBackdrop} />
                      <div className={classNames.cardAction}>
                        <span>Create Invoices</span>
                        <RiArrowRightLine
                          size={26}
                          className={classNames.arrowBtnWithBg}
                        />
                      </div>
                    </Card>
                    <Card
                      className={classNames.optionCard}
                      onClick={() => setCreateNewEstimateModalVisibility(true)}
                    >
                      <RiBillLine
                        color="#fff"
                        size={26}
                        className={classNames.optionIcon}
                      />
                      <div className={classNames.optionIconBackdrop} />
                      <div className={classNames.cardAction}>
                        <span>Create Estimates</span>
                        <RiArrowRightLine
                          size={26}
                          className={classNames.arrowBtnWithBg}
                        />
                      </div>
                    </Card>

                    <Card
                      className={classNames.optionCard}
                      onClick={() => setCreateNewRecurringInvoiceModal(true)}
                    >
                      <RiAnticlockwise2Line
                        color="#fff"
                        size={26}
                        className={classNames.optionIcon}
                      />
                      <div className={classNames.optionIconBackdrop} />
                      <div className={classNames.cardAction}>
                        <span>Create Recurring Invoice</span>
                        <RiArrowRightLine
                          size={26}
                          className={classNames.arrowBtnWithBg}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
                <div className={classNames.body}>
                  <Card className={classNames.tableCard}>
                    <div className={classNames.tableCardHeader}>
                      <Title as="h3">Invoices</Title>
                      <AntButton
                        type="link"
                        onClick={() =>
                          navigate(ROUTES.APP.RECEIVABLES.ALL_INVOICES)
                        }
                      >
                        View all
                      </AntButton>
                    </div>
                    <div className={classNames.tableCardFilters}>
                      <Radio.Group defaultValue="all" buttonStyle="solid">
                        <Space size={0}>
                          <Radio.Button
                            value="all"
                            onClick={() => setInvoiceFilterAttribute("all")}
                          >
                            ALL
                          </Radio.Button>
                          <Radio.Button
                            value="draft"
                            onClick={() => setInvoiceFilterAttribute("draft")}
                          >
                            DRAFT
                          </Radio.Button>
                          <Radio.Button
                            value="paid"
                            onClick={() => setInvoiceFilterAttribute("paid")}
                          >
                            PAID
                          </Radio.Button>
                          <Radio.Button
                            value="sent"
                            onClick={() => setInvoiceFilterAttribute("sent")}
                          >
                            SENT
                          </Radio.Button>
                          <Radio.Button
                            value="overdue"
                            onClick={() => setInvoiceFilterAttribute("overdue")}
                          >
                            OVERDUE
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>
                    <Table
                      key="invoicesTable"
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {
                            invoicesRowClick(record);
                          },
                        };
                      }}
                      locale={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No Data Found"
                            imageStyle={{ height: 20 }}
                            style={{ margin: 10 }}
                          />
                        ),
                      }}
                      pagination={false}
                      dataSource={
                        invoiceFilterAttribute === "all"
                          ? invoiceData
                          : invoiceData &&
                            invoiceData.length > 0 &&
                            invoiceData.filter(
                              (value) => value.status === invoiceFilterAttribute
                            )
                      }
                      columns={invoiceColumns}
                      className={classNames.table}
                      loading={overallInvoiceDetailsFetching}
                      size="small"
                    />
                  </Card>
                  <Card className={classNames.tableCard}>
                    <div className={classNames.tableCardHeader}>
                      <Title as="h3">Estimates</Title>
                      <AntButton
                        type="link"
                        onClick={() =>
                          navigate(ROUTES.APP.RECEIVABLES.ALL_ESTIMATES)
                        }
                      >
                        View all
                      </AntButton>
                    </div>
                    <div className={classNames.tableCardFilters}>
                      <Radio.Group defaultValue="all" buttonStyle="solid">
                        <Space size={0}>
                          <Radio.Button
                            value="all"
                            onClick={() => setEstimateFilterAttribute("all")}
                          >
                            ALL
                          </Radio.Button>
                          <Radio.Button
                            value="draft"
                            onClick={() => setEstimateFilterAttribute("draft")}
                          >
                            DRAFT
                          </Radio.Button>
                          <Radio.Button
                            value="sent"
                            onClick={() => setEstimateFilterAttribute("sent")}
                          >
                            SENT
                          </Radio.Button>
                          <Radio.Button
                            value="overdue"
                            onClick={() =>
                              setEstimateFilterAttribute("overdue")
                            }
                          >
                            OVERDUE
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>
                    <Table
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {
                            estimatesRowClick(record);
                          },
                        };
                      }}
                      locale={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No Data Found"
                            imageStyle={{ height: 20 }}
                            style={{ margin: 10 }}
                          />
                        ),
                      }}
                      pagination={false}
                      dataSource={
                        estimateFilterAttribute === "all"
                          ? estimateData
                          : estimateData &&
                            estimateData.length > 0 &&
                            estimateData.filter(
                              (value) =>
                                value.status === estimateFilterAttribute
                            )
                      }
                      columns={estimateColumns}
                      className={classNames.table}
                      loading={overallInvoiceDetailsFetching}
                      size="small"
                    />
                  </Card>
                </div>
                <div className={classNames.bottomRow}>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate(ROUTES.APP.RECEIVABLES.CUSTOMERS)}
                  >
                    <RiUser2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Customer Database</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() =>
                      navigate(ROUTES.APP.RECEIVABLES.PRODUCTS_SERVICES)
                    }
                  >
                    <RiDatabase2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Product Database</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate(ROUTES.APP.RECEIVABLES.TAX_DB)}
                  >
                    <RiMoneyDollarBoxLine
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Tax Database</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() =>
                      navigate(ROUTES.APP.RECEIVABLES.RECURRING_INVOICES)
                    }
                  >
                    <RiAnticlockwise2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Recurring Invoices</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                  {/* <Card
                    className={classNames.optionCardLight}
                    onClick={() =>
                      navigate(ROUTES.APP.RECEIVABLES.DRAFT_ESTIMATES_INVOCES)
                    }
                  >
                    <RiHonourLine
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Drafts</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card> */}
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() =>
                      navigate(ROUTES.APP.RECEIVABLES.TEMPLATE_SETTINGS, {
                        state: { from: ROUTES.APP.RECEIVABLES.INVOICES },
                      })
                    }
                  >
                    <RiEdit2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Template Settings</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <CreateNewInvoice
        visible={createNewInvoiceModalVisibility}
        onCancel={handleCreateNewInvoiceModalCancelClick}
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
      />
      <CreateNewEstimate
        visible={createNewEstimateModalVisibility}
        onCancel={handleCreateNewEstimateModalCancelClick}
        invoiceTemplateSettings={estimateTemplateSettingsQuery?.data}
      />
      <ChangeStatus
        visible={changeStatusModalVisibility}
        status={invoiceStatus}
        onCancel={handleChangeStatusModalCancelClick}
        setChangeStatusModalVisibility={setChangeStatusModalVisibility}
      />
      <AddNewRecurringInvoices
        account={currentUserData?.signInUserSession?.idToken?.payload?.icid}
        visible={createNewRecurringInvoiceModal}
        onCancel={handleRecurringCancelClick}
        setCreateNewRecurringInvoiceModal={setCreateNewRecurringInvoiceModal}
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
      />
      {settingsVisibility && (
        <Settings
          visible={settingsVisibility}
          onCancel={handleSettingsCancelClick}
          setPASettingsAvailability={setPASettingsAvailability}
        />
      )}
    </div>
  );
}
