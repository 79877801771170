import { API } from "aws-amplify";

export default async function getTransaction(refNum) {
  return await API.post("pa-transaction", `/get-transaction`, {
    body: {data : {
      xRefNum : refNum,
      xCommand : "Report:Transaction" 
    }}
  });
}
