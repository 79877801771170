import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppState";
import { Modal, Select, Table } from "antd";
import { useMutation } from "react-query";
import { Message } from "../../common";
import generateErrorMessage from "../../util/functions/customError";
import api from "../../API";

const { Option } = Select;

const AccountActivityModal = ({
  visible,
  title = "Wire Transactions",
  onCancel,
  onDone,
  spawnModal,
  setIsAccountInfoNeeded,
  estimatedAmountOptions = [
    "Less than $1,000",
    "$1,000 - $5,000",
    "$5,001 - $10,000",
    "$10,001 - $25,000",
    "More than $25,000",
  ],
  estimatedTransactionsOptions = [
    "1 - 10",
    "11 - 50",
    "51 - 100",
    "101 - 200",
    "More than 200",
  ],
}) => {

  const { application } = useContext(AppContext);
  const [selectedEstimatedAmount, setSelectedEstimatedAmount] = useState(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedEstimatedTransactions, setSelectedEstimatedTransactions] =
    useState(null);

  const updateDetails = useMutation((data) =>
    api.onBoarding.updateBusinessOperationDetails(data)
  ); 
  
  const getBusiness = useMutation((data) =>
    api.BusinessBanking.getBusiness(data)
  );
  
  useEffect(() => {
    getBusiness.mutate(application?.otz_business_id, {
      onSuccess: (response) => {
        const metaData = response?.payload?.metadata
        if (visible) {
          if(metaData && metaData?.wire_transaction_domestic_amt && title === "Wire Transactions") {
            // setIsAccountInfoNeeded(false);
            onDone(true)
          }
          else if(metaData && metaData?.remote_deposits_amt && title === "Deposit Check") {
            // setIsAccountInfoNeeded(false);
            onDone(true)
          }
          else if(metaData && metaData?.debit_card_transactions_amt && title === "Debit Card") {
            // setIsAccountInfoNeeded(false);
            onDone(true)
          }
          else if(metaData && metaData?.incoming_ach_transaction_amt && title === "Incoming ACH Transactions") {
            // setIsAccountInfoNeeded(false);
            if(onDone) {
              onDone(true)
            }
          }
          else if(metaData && metaData?.outgoing_ach_transaction_amt && title === "Add New Schedule") {
            // setIsAccountInfoNeeded(false);
            onDone(true)
          }
          else if(metaData && metaData?.outgoing_ach_transaction_amt && title === "Outgoing ACH Transactions") {
            // setIsAccountInfoNeeded(false);
            onDone(true)
          } else {
            setShowModal(true)
          }
        }
        spawnModal(false)
        // setSelectedEstimatedAmount(response?.data?.wire_transaction_domestic_amt);
        // setSelectedEstimatedTransactions(response?.data?.wire_transaction_domestic_count);
      },
      onError: (error) => {
        generateErrorMessage(error);
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);


  const onOk = async (selectedEstimatedAmount, selectedEstimatedTransactions) => {
    if (!selectedEstimatedAmount) {
      Message({ type: "error", content: "Estimated $ amount / month is required." });
    } else {
      let data = null
      if(title === "Wire Transactions") {
          data = {
              otz_business_id: application?.otz_business_id,
              wire_transaction_domestic_amt: selectedEstimatedAmount,
              wire_transaction_domestic_count: selectedEstimatedTransactions,
          };
      } else if (title === "Deposit Check") {
          data = {
              otz_business_id: application?.otz_business_id,
              remote_deposits_amt: selectedEstimatedAmount,
              remote_deposits_count: selectedEstimatedTransactions,
          };
      } else if (title === "Debit Card") {
          data = {
              otz_business_id: application?.otz_business_id,
              debit_card_transactions_amt: selectedEstimatedAmount,
              debit_card_transactions_count: selectedEstimatedTransactions,
          };
      } else if (title === "Incoming ACH Transactions") {
          data = {
              otz_business_id: application?.otz_business_id,
              incoming_ach_transaction_amt: selectedEstimatedAmount,
              incoming_ach_transaction_count: selectedEstimatedTransactions,
          };
      } else if (title === "Outgoing ACH Transactions") {
          data = {
              otz_business_id: application?.otz_business_id,
              outgoing_ach_transaction_amt: selectedEstimatedAmount,
              outgoing_ach_transaction_count: selectedEstimatedTransactions,
          };
      } else if (title === "Add New Schedule") {
          data = {
              otz_business_id: application?.otz_business_id,
              outgoing_ach_transaction_amt: selectedEstimatedAmount,
              outgoing_ach_transaction_count: selectedEstimatedTransactions,
          };
      }
  
      updateDetails.mutate(data, {
          onSuccess: () => {
            Message({ type: "success", content: "Business details submitted successfully" });
            handleCloseModal()
            if(onDone) {
              onDone(true);
            }
          },
          onError: (error) => {
            generateErrorMessage(error);
          },
        });
    }
  };

  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
      width: "48%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.field === "Estimated $ amount / month *"
          ? (
              <Select
                value={selectedEstimatedAmount}
                onChange={setSelectedEstimatedAmount}
                style={{ width: "100%" }}
                placeholder="Select a range"
              >
                {estimatedAmountOptions.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            )
          : (
              <Select
                value={selectedEstimatedTransactions}
                onChange={setSelectedEstimatedTransactions}
                style={{ width: "100%" }}
                placeholder="Select a range"
              >
                {estimatedTransactionsOptions.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            ),
    },
  ];

  const data = [
    {
      key: "1",
      field: "Estimated $ amount / month *",
      value: null,
    },
    {
      key: "2",
      field: "Estimated # transactions / month",
      value: null,
    },
  ];

  const customFooter = [
    <button
      key="submit"
      type="button"
      className="ant-btn ant-btn-primary"
      onClick={() => {
        onOk(selectedEstimatedAmount, selectedEstimatedTransactions);
      }}
      style={{marginRight: "18px", padding: "0 20px"}}
    >
      Submit
    </button>,
  ];

  const handleCloseModal = () => {
    setShowModal(false)
    onCancel(); // Call the onCancel function
  };

  return (
    <Modal
      title={title}
      visible={showModal}
      onOk={() => {
        onOk(selectedEstimatedAmount, selectedEstimatedTransactions);
      }}
      onCancel={handleCloseModal}
      footer={customFooter}
      style={{   
        top: '25%',
        // transform: 'translate(-50%, -50%)', // Center the modal both vertically and horizontally
        minWidth: '400px'
      }}
    >
      <Table
        style={{ background: "white", height: "120px" }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
      />
    </Modal>
  );
};

export default AccountActivityModal;
