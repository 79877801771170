import { API } from "aws-amplify";

export default async function createProduct(data) {
  return await API.post("product", "", {
    body: {
      ...data,
      action: "mark-draft",
    },
  });
}
