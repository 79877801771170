import discoverCCLogo from "../../../assets/payment-acceptance/credit-cards/discover.svg";
import dinersLogo from "../../../assets/payment-acceptance/credit-cards/Diners.jpeg";
import jcbLogo from "../../../assets/payment-acceptance/credit-cards/JCB.jpeg";

export const cardTypeLogos = {
  Visa: {
    image: (
      <img
        src={"https://www.logo.wine/a/logo/Visa_Inc./Visa_Inc.-Logo.wine.svg"}
        alt="Visa card"
        width={26}
      />
    ),
  },
  MasterCard: {
    image: (
      <img
        src={"https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg"}
        alt="Master card"
        width={26}
      />
    ),
  },
  Amex: {
    image: (
      <img
        src={
          "https://www.logo.wine/a/logo/American_Express/American_Express-Logo.wine.svg"
        }
        alt="American Express card"
        width={26}
      />
    ),
  },
  Discover: {
    image: <img src={discoverCCLogo} alt="Discover card" width={26} />,
  },
  Diners: {
    image: <img src={dinersLogo} alt="Diners card" width={26} />,
  },
  JCB: {
    image: <img src={jcbLogo} alt="JCB card" width={26} />,
  },

};
