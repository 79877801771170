import React, { useState, useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Modal, Tabs } from 'antd';

import { ROUTES } from "../../constants";
// import useLocalStorage from "../../../hooks/useLocalStorage";
import api from "../../API";
import { AppContext } from "../../../context/AppState";
// import { ROUTE_STATUS, USER_STATES } from "../../constants";

import classNames from './styles.module.scss';
import mixpanel from "mixpanel-browser";

const { TabPane } = Tabs;

const DashboardOnboard = ({ onSubmit, selectedSubscriptionPlan }) => {
    const navigate = useNavigate();
    // const [, setProgress] = useLocalStorage("OTTERZ_op_accountType", {
    //     accountType: "",
    // });

    const { userData } = useContext(AppContext);
    mixpanel.identify(userData?.email);

    // State to control the visibility of the modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [subPlan, setCurrSubPlan] = useState(false);
    const [subPlanName, setCurrSubPlanName] = useState(false);

    const openHubSpotScheduler = () => {
        setIsModalVisible(true);
    }

    const closeModal = () => {
        setIsModalVisible(false);
    }

    const handleTabChange = (activeKey) => {
        if (activeKey === "hubspot-bookeeper") {
            // User has selected the "Bookeeper" tab
            // You can set some state here to track the selected tab
        }
    }

    // const { data: application } = useQuery(
    //     "getApplicationByTenantId",
    //     () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    //     {
    //         refetchOnWindowFocus: false,
    //     }
    // );

    useQuery(
        "getCurrentSubscriptionPlanId",
        () => api.Settings.getCurrentSubscriptionPlan(),
        {
          refetchOnWindowFocus: false,
          onSuccess: (response) => {
            if(response?.payload?.data === null) {
                setCurrSubPlan(true);
                setCurrSubPlanName(null)
                mixpanel.people.set({
                  "Subscription Status": "None",
                });
            } else {
                setCurrSubPlanName(response?.payload?.data?.plan_name)
                if (response?.payload?.data?.plan_name === "custom_starter") {
                    mixpanel.people.set({
                      "Subscription Status": "starter",
                    });
                  } else {
                    mixpanel.people.set({
                      "Subscription Status": response?.payload?.data?.plan_name,
                    });
                  }
            }
          },
        }
    );

    // const showContinueText = 
    // application !== undefined ||
    // application?.payload?.onBoardingStatus === "BUSINESS_CREATED" || 
    // application?.payload?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED" ||
    // application?.payload?.onBoardingStatus === "OFFICER_CREATED";

    // const routeToBusiness = async () => {
    //     /* Over-engineering */
    //     // If data is still loading, manually refetch and await its completion

    //     // if (dataLoading || !application) {
    //     //     console.log("Data is still loading... Refetching...");
    //     //     try {
    //     //         await refetch();
    //     //     } catch (error) {
    //     //         console.error("Error refetching data:", error);
    //     //         return;
    //     //     }
    //     // }
    //     if(application?.payload?.verification_status === "REJECTED") {
    //         navigate(`${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[USER_STATES.APPLICATION_CANCELED]}`)
    //     } else {
    //         if (application === undefined) {
    //             setProgress((data) => ({ ...data, accountType: "BUSINESS" }));
    //             navigate(ROUTES.ONBOARDING.BUSINESS_APPLICATION);
    //         } else if (application?.payload?.onBoardingStatus === "BUSINESS_CREATED") {
    //             navigate(ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS);
    //         } else if (application?.payload?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED") {
    //             navigate(ROUTES.ONBOARDING.OFFICER_DETAILS);
    //         } else if (application?.payload?.onBoardingStatus === "OFFICER_CREATED" || application?.payload?.onBoardingStatus === "BUSINESS_DOCS_UPLOADED") {
    //             navigate(ROUTES.ONBOARDING.ONBOARDING_DOCUMENT_UPLOAD);
    //         } else {
    //             navigate(
    //                 `${ROUTES.ONBOARDING.THANK_YOU}${
    //                   ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
    //                 }`
    //             );
    //         }
    //     }
    // }
    

    const routeToPlans = () => {
        navigate(ROUTES.ONBOARDING.SUBSCRIPTION_PLAN);
    }

    return (
        <div className={classNames.container}>
            <h1 className={classNames.greetings}>Hello !</h1>
            <div className={classNames.centeredDiv}>
                {/* <p className={classNames.description}>
                    Welcome Onboard! Create your business checking account and complete your business profile to experience the complete range of features.
                </p> */}
                {/* <div className={classNames.card} onClick={routeToBusiness}>
                    <div className={classNames.cardText}>
                        <span style={{fontWeight: "bold"}}>Create a Business Account {showContinueText && <span style={{color: "#00d600"}}>- Continue</span>}</span>
                        <small style={{color: "grey"}}>In 3 steps</small>
                    </div>
                    <span className={classNames.arrow}>→</span>
                </div> */}
                <div className={classNames.card} onClick={openHubSpotScheduler}>
                    <div className={classNames.cardText}>
                        <span style={{fontWeight: "bold"}}>Manage Books & Taxes</span>
                        <small style={{color: "grey"}}>Book a call with us</small>
                    </div>
                    <span className={classNames.arrow}>→</span>
                </div>
                {subPlan && <div className={classNames.gradientCard} onClick={null}>
                    <span>Ready to Begin?</span>
                    <button className={classNames.ctaButton} onClick={routeToPlans}>Choose a Plan</button>
                </div>}
            </div>

            {/* AntD Modal for HubSpot Scheduler */}
            <Modal
                title="Books & Taxes - Book a call with us"
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                width={800}
            >
                {
                    subPlanName === "elite" || subPlanName === "elite_annual" ? (
                        <Tabs defaultActiveKey="hubspot" tabPosition="top" onChange={handleTabChange}>
                            <TabPane tab="Book a call - Bookkeeper" key="hubspot-bookeeper">
                                <iframe
                                    src="https://meetings.hubspot.com/jay-patel11?embed=true"
                                    width="100%"
                                    height="500"
                                    frameBorder="0"
                                    title="HubSpotMeetingScheduler"
                                ></iframe>
                            </TabPane>
                            <TabPane tab="Book a call - Sales" key="hubspot-marketing">
                                <iframe
                                    src="https://meetings.hubspot.com/tapan-r?embed=true"
                                    width="100%"
                                    height="500"
                                    frameBorder="0"
                                    title="HubSpotMeetingScheduler"
                                ></iframe>
                            </TabPane>
                        </Tabs>
                    ) : (
                        <iframe
                            src="https://meetings.hubspot.com/tapan-r?embed=true"
                            width="100%"
                            height="500"
                            frameBorder="0"
                            title="HubSpotMeetingScheduler"
                        ></iframe>
                    )
                }

            </Modal>
        </div>
    )
}

export default DashboardOnboard;
