import React from "react";
import PropTypes from "prop-types";

import {
  Select
} from "../../common";
/* TODO: Unit doesn't support Non-US countries. Get their support later */
import {
  countryList,
} from "../../util";

export default function CountryPicker({ className, placeholder = "Please select", onChange, ...props }) {
  return (
    <Select
    className={className}
    placeholder={placeholder}
    showSearch
    onChange={onChange}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    {...props}
  >
    {/* TODO: Unit doesn't support Non-US countries. Get their support later */}
    
    {props.countries === "all" && countryList()
      .filter((country) => (country.code !== "US"))
      .map((value) => (
        <Select.Option key={value.code} value={value.code}>
          {value.name}
        </Select.Option>
    ))}
    <Select.Option key="US" value="US">
      United States
    </Select.Option>
  </Select>
  );
}

CountryPicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
