import { useEffect, useState, useRef, useContext } from "react";
import mixpanel from "mixpanel-browser";
import { ErrorBoundary } from "react-error-boundary";
import {
  Card,
  Table,
  Space,
  Radio,
  Button as AntButton,
  Menu,
  Dropdown,
  ConfigProvider,
  Empty,
  Spin,
} from "antd";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import DownloadCloudLineIcon from "remixicon-react/DownloadCloudLineIcon";
import ArrowDropDownLineIcon from "remixicon-react/ArrowDropDownLineIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { RiCloseFill } from "react-icons/ri";
import { Input, Header, Title, TypeTag, ErrorFallback } from "../../common";
import StatementCard from "./StatementCard/StatementCard";
// import DownloadStatement from "./DownloadStatement/DownloadStatement";
// import PrintStatement from "./PrintStatement/PrintStatement";
import CustomFilter from "./CustomFilter/CustomFilter";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import { centsToDollarWithoutSign } from "../../util/functions/dollarConvertion";
import dateUtils from "../../util/dateUtils";
import generateErrorMessage from "../../util/functions/customError";
import { PAGINATION, PAGINATION_DEFAULT } from "../../util/constants";
import { FORMATS } from "../../constants";
import classNames from "./StatementsAndTransactions.module.scss";

export const FILTERS = {
  COMPLETED: "Completed",
  PENDING: "Pending",
};

const FILTER_TYPES = {
  PERIOD: "period",
  CUSTOM: "custom",
};

export default function StatementsAndTransactions() {
  //Hooks
  const [downloadStatementId, setDownloadStatementId] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [filteredStatments, setFilteredStatement] = useState();
  const [keyword, setKeyword] = useState();
  // const [downloadModalVisibility, setDownloadModalVisibility] = useState(false);
  // const [printModalVisibility, setPrintModalVisibility] = useState(false);
  const [filterModalVisibility, setFilterModalVisibility] = useState(false);
  const [mainFilter, setMainFilter] = useState(FILTERS.COMPLETED);
  const [selectedValue, setSelectedValue] = useState("d");
  const [initialData, setInitialData] = useState(null);
  const [initialPendingTransactions, setInitialPendingTransactions] =
    useState(null);
  const [cardTransaction, setCardTransaction] = useState([]);
  const [cardTransactionLoading, setCardTransactionLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const location = useLocation();
  const componentRef = useRef();
  const [downloadTransaction, setDownloadTransaction] = useState(false);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const { application } = useContext(AppContext);
  const [pagination, setPagination] = useState(PAGINATION);

  const LIMIT = 5000;
  const currentDate = new Date();
  const nextDay = new Date().setDate(currentDate.getDate() + 1);
  const monthStartDate = new Date(
    new Date(nextDay).setMonth(currentDate.getMonth() - 1)
  );

  const defaultPaginate = {
    limit: LIMIT,
    otz_account_id: application?.otz_account_id,
  };

  const [queryParams, setQueryParams] = useState({
    ...defaultPaginate,
    filterType: FILTER_TYPES.PERIOD,
    from_date: moment(monthStartDate).format(FORMATS.API_SEND_FORMAT),
  });

  const navigate = useNavigate();

  //Other constants
  const { cardNumber, id, otz_card_id } = location?.state || {};
  const isCardStatement = id === "card";
  const monthList = [
    {
      index: "01",
      title: "Jan",
    },
    {
      index: "02",
      title: "Feb",
    },
    {
      index: "03",
      title: "Mar",
    },
    {
      index: "04",
      title: "Apr",
    },
    {
      index: "05",
      title: "May",
    },
    {
      index: "06",
      title: "Jun",
    },
    {
      index: "07",
      title: "Jul",
    },
    {
      index: "08",
      title: "Aug",
    },
    {
      index: "09",
      title: "Sep",
    },
    {
      index: "10",
      title: "Oct",
    },
    {
      index: "11",
      title: "Nov",
    },
    {
      index: "12",
      title: "Dec",
    },
  ];

  //Api calls
  const {
    mutate: pendingTransaction,
    isLoading: isListPaymentsFetching,
    data: pendingPayments,
  } = useMutation({
    mutationFn: api.BusinessBanking.pendingTransaction,
    onSuccess: (data) => {
      setPagination(PAGINATION);
      setTotalRecords(data?.payload?.length || 0);
    },
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: mainFilter === FILTERS.PENDING && !isCardStatement,
    onError: (error) => generateErrorMessage(error),
  });

  const {
    data: transactionData,
    isLoading,
    refetch: refetchTransactionData,
    isFetching: getTransLoading,
  } = useQuery(
    ["getTransactions", queryParams],
    () => {
      const param = prepareQueryParam();
      return api.BusinessBanking.getTransactions(param);
    },
    {
      onSuccess: (data) => {
        setPagination(PAGINATION);
        setTotalRecords(data?.payload?.length || 0);
      },
      onError: (error) => generateErrorMessage(error),
      retry: false,
      enabled: mainFilter === FILTERS.COMPLETED && !isCardStatement,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  const { data: cardTransactionData, refetch: refetchCardTransactionData } =
    useQuery(
      ["getCardTransaction", queryParams],
      () => {
        setCardTransaction(true);
        const param = prepareQueryParam();
        return api.BusinessBanking.getCardTransaction(param);
      },
      {
        onSuccess: () => {
          setPagination(PAGINATION);
          setCardTransactionLoading(false);
        },
        onError: (error) => {
          generateErrorMessage(error);
          setCardTransactionLoading(false);
        },
        retry: false,
        enabled: !!(isCardStatement && otz_card_id),
        refetchOnWindowFocus: false,
        keepPreviousData: false,
      }
    );

  const { data: statementsPayload, isLoading: loadingStatements } = useQuery(
    ["getStatements"],
    () =>
      api.BusinessBanking.getStatements({
        otz_account_id: application?.otz_account_id,
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    "getStatementPDF",
    () => api.BusinessBanking.getStatementPDF(downloadStatementId),
    {
      onSuccess: (file) => {
        const data = new Blob([file], { type: "application/pdf" });
        const url = window.URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        const fileName = `${downloadStatementId}-statements.pdf`;
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
        setDownloadStatementId(null);
      },
      onError: (error) => generateErrorMessage(error),
      enabled: !!downloadStatementId,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ["getTransactionPDF", queryParams],
    () => {
      const param = prepareQueryParam();
      return api.BusinessBanking.getTransactionPdf(
        param,
        mainFilter === FILTERS.COMPLETED ? true : false
      );
    },
    {
      onSuccess: (file) => {
        const data = new Blob([file], { type: "application/pdf" });
        const url = window.URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        const fileName = `bank-transaction.pdf`;
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
        try {
          mixpanel.track("Bank Statement", {
            "Statement": "Download bank statement",
            "Statement since": queryParams.from_date,
            "Limit": queryParams.limit
          });
        } catch (error) {
          console.error("Mixpanel error: ", error);
        }
        setDownloadTransaction(false);
      },
      onError: (error) => generateErrorMessage(error),
      enabled: !!downloadTransaction,
      refetchOnWindowFocus: false,
    }
  );

  //Hooks - useEffect
  useEffect(() => {
    if (isCardStatement) {
      refetchCardTransactionData();
    } else {
      if (mainFilter === FILTERS.COMPLETED) {
        refetchTransactionData();
      }
      if (mainFilter === FILTERS.PENDING) {
        fetchPendingPayments();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, mainFilter, otz_card_id]);

  useEffect(() => {
    setSelectedValue("d");
  }, [mainFilter]);

  useEffect(() => {
    handleTransactionDataResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionData,
    keyword,
    location.state?.otz_card_id,
    cardTransactionData,
    pendingPayments,
  ]);

  //Event handlers
  const handleFilterModalCancelClick = () => {
    setFilterModalVisibility(false);
  };

  const setCustomQueryParams = (customSearch) => {
    setSelectedValue("g");
    const type = customSearch?.type?.toString();
    setQueryParams({
      ...customSearch,
      ...(type && { type }),
      otz_account_id: application?.otz_account_id,
    });
  };

  const handleTransactionDownload = () => {
    setDownloadTransaction(true);
  };

  //JSX and table data
  const getColumns = () => {
    const commonColumn = [
      {
        title: "Description",
        dataIndex: "transaction",
        key: "transaction",
        ellipsis: true,
      },
      {
        title: "Date & Time",
        dataIndex: "dateTime",
        key: "dateTime",
        width: 200,
        render: (text) => text,
        sorter: (a, b) => {
          setPagination(PAGINATION);
          return moment(a?.dateTime, FORMATS.DATE_TIME_FORMAT).diff(
            moment(b?.dateTime, FORMATS.DATE_TIME_FORMAT)
          );
        },
      },
      {
        title: "Transfer Type",
        dataIndex: "transferType",
        key: "transferType",
        render: (type) => {
          return (
            <span style={{ display: "flex", justifyContent: "flex-start" }}>
              <TypeTag type={type} />
            </span>
          );
        },
        sorter: (a, b) => {
          setPagination(PAGINATION);
          return a.transferType.length - b.transferType.length;
        },
      },
      {
        title: "Credit",
        dataIndex: "credit",
        key: "credit",
        render: (amount) =>
          amount && <span style={{ color: "#1DBC83" }}>${amount} </span>,
      },
      {
        title: "Debit",
        dataIndex: "debit",
        key: "debit",
        render: (amount) =>
          amount && <span style={{ color: "#EE6969" }}>${amount}</span>,
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        render: (amount) => <span>${amount}</span>,
      },
    ];

    if (isCardStatement || mainFilter === FILTERS.COMPLETED) {
      return commonColumn;
    }
    if (mainFilter === FILTERS.PENDING) {
      return [
        {
          title: "Description",
          dataIndex: "memo",
          key: "memo",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Date & Time",
          dataIndex: "date",
          key: "date",
          width: 200,
          render: (text) => text,
          sorter: (a, b) => {
            setPagination(PAGINATION);
            return moment(a?.date, FORMATS.DATE_TIME_FORMAT).diff(
              moment(b?.date, FORMATS.DATE_TIME_FORMAT)
            );
          },
        },
        {
          title: "Transfer Type",
          dataIndex: "transferType",
          key: "transferType",
          render: (type) => {
            return (
              <span style={{ display: "flex", justifyContent: "flex-start" }}>
                <TypeTag type={type} />
              </span>
            );
          },
          sorter: (a, b) => {
            setPagination(PAGINATION);
            return a.transferType?.length - b.transferType?.length;
          },
        },
        {
          title: "Credit",
          dataIndex: "credit",
          key: "credit",
          render: (credit, record) =>
            credit && <span style={{ color: "#1DBC83" }}>${credit}</span>,
        },
        {
          title: "Debit",
          dataIndex: "debit",
          key: "debit",
          render: (debit, record) =>
            debit && <span style={{ color: "#EE6969" }}>${debit}</span>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (status) => {
            return <span>{status}</span>;
          },
        },
      ];
    }
  };

  const years = statementsPayload?.payload &&
    statementsPayload?.payload !== {} && [
      ...new Set(
        statementsPayload?.payload?.statements.map((record) => {
          return record.start_date.substr(0, 4);
        })
      ),
    ];

  const yearMenu = (
    <Menu
      onClick={(e) => {
        setYear(e.key);
        setFilteredStatement(
          fiterStatements(e.key, month, statementsPayload?.payload?.statements)
        );
      }}
    >
      {years &&
        years.length > 0 &&
        years.map((v) => <Menu.Item key={v}>{v}</Menu.Item>)}
    </Menu>
  );

  const months = statementsPayload?.payload !== {} && [
    ...new Set(
      statementsPayload?.payload?.statements.map((record) => {
        return record?.start_date?.slice(5, 7);
      })
    ),
  ];

  const monthMenu = (
    <Menu
      onClick={(e) => {
        setMonth(e.key);
        setFilteredStatement(
          fiterStatements(year, e.key, statementsPayload?.payload?.statements)
        );
      }}
    >
      {months &&
        months.length > 0 &&
        monthList
          .filter((month) => months.includes(month.index))
          .map((entry) => {
            return <Menu.Item key={entry.index}>{entry.title}</Menu.Item>;
          })}
    </Menu>
  );

  //Helpre function
  const prepareQueryParam = () => {
    const param = {
      ...queryParams,
    };

    if (isCardStatement) {
      param.otz_card_id = otz_card_id;
    } else {
      if (queryParams.type) {
        param.type = queryParams?.type;
      }
    }
    if (queryParams.filterType === FILTER_TYPES.PERIOD) {
      param.from_date = dateFilter(queryParams);
      param.to_date = moment(currentDate).format(FORMATS.API_SEND_FORMAT);
      delete param?.period;
      delete param?.type;
    }

    delete param?.filterType;

    return param;
  };

  const fetchPendingPayments = () => {
    const param = prepareQueryParam();
    pendingTransaction(param);
  };

  const filteredArray = () => {
    if (mainFilter === FILTERS.COMPLETED) {
      return (
        initialData &&
        initialData.length > 0 &&
        initialData.filter((obj) => {
          let filterObj = {
            transaction: obj?.transaction,
            balance: obj?.balance,
            dateTime: obj?.dateTime,
            debit: obj?.debit,
            credit: obj?.credit,
            transferType: obj?.transferType,
          };
          return Object.values(filterObj)
            .toString()
            ?.toLowerCase()
            .includes(keyword?.toLowerCase());
        })
      );
    }
    if (mainFilter === FILTERS.PENDING) {
      return (
        initialPendingTransactions &&
        initialPendingTransactions.length > 0 &&
        initialPendingTransactions.filter((obj) => {
          let filterObj = {
            memo: obj?.memo,
            debit: obj?.debit,
            credit: obj?.credit,
            date: obj?.date,
            status: obj?.status,
            transferType: obj?.transferType,
          };
          return Object.values(filterObj)
            .toString()
            ?.toLowerCase()
            .includes(keyword?.toLowerCase());
        })
      );
    }
  };

  const handleTransactionDataResponse = () => {
    if (isCardStatement) {
      setCardTransaction(
        cardTransactionData?.payload
          ? cardTransactionData.payload
              .map((value, index) => {
                return {
                  key: index,
                  transaction: value?.memo ? value?.memo : "-",
                  dateTime: dateUtils.toMMDDYYYYHHmmss(
                    moment(value?.convertedDate)
                  ),
                  transferType: value?.transfer_type,
                  credit:
                    value?.dc_sign === "credit"
                      ? centsToDollarWithoutSign(value?.creditAmount)
                      : null,
                  debit:
                    value?.dc_sign === "debit"
                      ? centsToDollarWithoutSign(value?.debitAmount)
                      : null,
                  balance: centsToDollarWithoutSign(value?.balance),
                  status: value?.status,
                };
              })
              .sort((a, b) =>
                moment(a?.dateTime, FORMATS.DATE_TIME_FORMAT).diff(
                  moment(b?.dateTime, FORMATS.DATE_TIME_FORMAT)
                )
              )
              ?.reverse()
          : null
      );
    } else {
      if (mainFilter === FILTERS.COMPLETED) {
        setInitialData(
          transactionData?.payload
            ? transactionData.payload
                .map((value, index) => {
                  return {
                    key: index,
                    transaction: value?.memo ? value?.memo : "-",
                    dateTime: dateUtils.toMMDDYYYYHHmmss(
                      moment(value?.convertedDate)
                    ),
                    transferType: value?.transfer_type,
                    credit:
                      value?.dc_sign === "credit"
                        ? centsToDollarWithoutSign(value?.creditAmount)
                        : null,
                    debit:
                      value?.dc_sign === "debit"
                        ? centsToDollarWithoutSign(value?.debitAmount)
                        : null,
                    balance: centsToDollarWithoutSign(value?.balance),
                  };
                })
                .sort((a, b) =>
                  moment(a?.dateTime, FORMATS.DATE_TIME_FORMAT).diff(
                    moment(b?.dateTime, FORMATS.DATE_TIME_FORMAT)
                  )
                )
                ?.reverse()
            : null
        );
      } else {
        setInitialPendingTransactions(
          pendingPayments?.payload
            ? pendingPayments?.payload
                .map((obj, index) => {
                  return {
                    key: index,
                    memo: obj?.memo,
                    credit:
                      obj?.dc_sign === "credit"
                        ? centsToDollarWithoutSign(obj?.amount)
                        : null,
                    debit:
                      obj?.dc_sign === "debit"
                        ? centsToDollarWithoutSign(obj?.amount)
                        : null,
                    date: dateUtils.toMMDDYYYYHHmmss(
                      moment(obj?.convertedDate)
                    ),
                    status: obj?.status,
                    transferType: obj?.transfer_type,
                  };
                })
                .sort((a, b) =>
                  moment(a?.date, FORMATS.DATE_TIME_FORMAT).diff(
                    moment(b?.date, FORMATS.DATE_TIME_FORMAT)
                  )
                )
                ?.reverse()
            : null
        );
      }
    }
  };

  const getDataSource = () => {
    let dataSource;
    if (isCardStatement) {
      dataSource = cardTransaction;
    } else {
      if (mainFilter === FILTERS.COMPLETED) {
        dataSource = keyword ? filteredArray() : initialData;
      }
      if (mainFilter === FILTERS.PENDING) {
        dataSource = keyword ? filteredArray() : initialPendingTransactions;
      }
    }
    return dataSource;
  };

  function fiterStatements(year, month, statements) {
    return statements.filter((statement) => {
      let period = statement?.start_date;
      if (!month) {
        return period?.substr(0, 4) === year;
      } else if (!year) {
        return period?.substr(5, 2) === month;
      } else {
        return period?.substr(0, 4) === year && period?.substr(5, 2) === month;
      }
    });
  }

  function dateFilter(paramList) {
    switch (paramList?.period) {
      case "day":
        return moment(currentDate).format(FORMATS.API_SEND_FORMAT);

      case "week":
        const weekStartDate = new Date(
          new Date(currentDate).setDate(currentDate.getDate() - 6)
        );
        return moment(weekStartDate).format(FORMATS.API_SEND_FORMAT);

      case "sixMonth":
        const monthStart = new Date(
          new Date(nextDay).setMonth(currentDate.getMonth() - 6)
        );
        return moment(monthStart).format(FORMATS.API_SEND_FORMAT);

      case "year":
        const yearStartDate = new Date(
          new Date(nextDay).setFullYear(currentDate.getFullYear() - 1)
        );
        return moment(yearStartDate).format(FORMATS.API_SEND_FORMAT);

      default:
        const monthStartDate = new Date(
          new Date(nextDay).setMonth(currentDate.getMonth() - 1)
        );
        return moment(monthStartDate).format(FORMATS.API_SEND_FORMAT);
    }
  }

  const getLoadingStatus = () => {
    return (
      getTransLoading ||
      isLoading ||
      isListPaymentsFetching ||
      cardTransactionLoading
    );
  };

  const getCustomQueryParams = () => queryParams;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={classNames.wrapper}>
        <div className={classNames.headerWrapper}>
          <Header
            title={`Statements & Transactions`}
            back
            onClick={() => navigate(-1)}
          />
        </div>
        <div className={classNames.layout}>
          <div className={classNames.bodyWrapper}>
            <div className={classNames.body}>
              <div className={classNames.statementFilters}>
                <Input
                  placeholder={
                    mainFilter === FILTERS.COMPLETED
                      ? "Search transaction"
                      : "Search pending transaction"
                  }
                  prefix={<Search2LineIcon />}
                  className={classNames.searchStatement}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setPagination(PAGINATION);
                  }}
                />
                <Card className={classNames.filtersCard}>
                  {getLoadingStatus() ? null : (
                    <div className={classNames.filtersGroup}>
                      <Radio.Group
                        defaultValue={"d"}
                        buttonStyle="solid"
                        value={selectedValue}
                        onChange={(e) => {
                          if (e.target.value !== "g") {
                            setSelectedValue(e.target.value);
                          }
                        }}
                      >
                        <Space size={0}>
                          <Radio.Button
                            value="b"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "day",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1D
                          </Radio.Button>
                          <Radio.Button
                            value="c"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "week",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1W
                          </Radio.Button>
                          <Radio.Button
                            value="d"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "month",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1M
                          </Radio.Button>
                          <Radio.Button
                            value="e"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "sixMonth",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            6M
                          </Radio.Button>
                          <Radio.Button
                            value="f"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "year",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            Y
                          </Radio.Button>
                          <Radio.Button
                            value="g"
                            onClick={() => setFilterModalVisibility(true)}
                          >
                            <CalendarLineIcon size={12} />
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>
                  )}
                </Card>
              </div>
              <div className={classNames.actionRow}>
                {location?.state ? (
                  <span>
                    Displaying transactions for card {"  "} {cardNumber}{" "}
                    <RiCloseFill
                      size={20}
                      className={classNames.closeIcon}
                      onClick={() =>
                        navigate(location.pathname, { replace: true })
                      }
                    />
                  </span>
                ) : (
                  <span />
                )}
                {!isCardStatement ? (
                  <div className={classNames.actions}>
                    <div className={classNames.transactionStatusFilters}>
                      <Radio.Group>
                        <Space size="small">
                          <Radio.Button
                            name="tabs"
                            className={
                              mainFilter === FILTERS.COMPLETED
                                ? classNames.tabRadioButtonFocused
                                : classNames.tabRadioButton
                            }
                            onClick={() => {
                              setMainFilter(FILTERS.COMPLETED);
                              setQueryParams({
                                ...queryParams,
                                ...defaultPaginate,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "month",
                              });
                            }}
                          >
                            Transactions
                          </Radio.Button>
                          <Radio.Button
                            name="tabs"
                            className={
                              mainFilter === FILTERS.PENDING
                                ? classNames.tabRadioButtonFocused
                                : classNames.tabRadioButton
                            }
                            onClick={() => {
                              setMainFilter(FILTERS.PENDING);
                              setQueryParams({
                                ...queryParams,
                                ...defaultPaginate,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "month",
                              });
                            }}
                          >
                            Incomplete Transactions
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>

                    {getDataSource()?.length > 0 && (
                      <Card className={classNames.actionCard}>
                        <AntButton
                          type="link"
                          onClick={handleTransactionDownload}
                        >
                          Download
                          <DownloadCloudLineIcon
                            size={15}
                            style={{ margin: 10 }}
                          />
                        </AntButton>
                      </Card>
                    )}
                  </div>
                ) : null}
              </div>

              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"No Result found"}
                  />
                )}
              >
                <Table
                  dataSource={getDataSource()}
                  columns={getColumns()}
                  className={classNames.table}
                  loading={getLoadingStatus()}
                  pagination={{
                    ...PAGINATION_DEFAULT,
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    onChange: (page, pageSize) => {
                      setPagination({ page: page, pageSize: pageSize });
                    },
                    total: totalRecords,
                    itemRender: (page, type, originalElement) => {
                      if (type === "prev") {
                        return (
                          <AntButton style={{ color: "#9f9cff" }}>
                            Prev
                          </AntButton>
                        );
                      }
                      if (type === "next") {
                        return (
                          <AntButton style={{ color: "#9f9cff" }}>
                            Next
                          </AntButton>
                        );
                      }
                    },
                  }}
                  footer={false}
                />

                <div style={{ overflow: "hidden", height: 0 }}>
                  <Table
                    ref={componentRef}
                    dataSource={getDataSource()}
                    columns={getColumns()}
                    loading={getLoadingStatus()}
                    pagination={false}
                    scroll={false}
                  />
                </div>
              </ConfigProvider>
            </div>
            <div className={classNames.bodyRight}>
              <Card className={classNames.statementCard}>
                <div className={classNames.statementCardHeader}>
                  <div className={classNames.headerTopRow}>
                    <Title as="h3">Monthly Statements</Title>
                    <AntButton
                      type="link"
                      onClick={() => {
                        setYear();
                        setMonth();
                        setFilteredStatement();
                      }}
                    >
                      Clear Filter
                    </AntButton>
                  </div>
                  <div className={classNames.filterRow}>
                    <span>Filter</span>
                    <span className={classNames.filterButtons}>
                      <Dropdown overlay={yearMenu}>
                        <AntButton
                          type="link"
                          className={classNames.dropdownFilter}
                        >
                          {year || "Year"} <ArrowDropDownLineIcon />
                        </AntButton>
                      </Dropdown>
                      <Dropdown overlay={monthMenu}>
                        <AntButton
                          type="link"
                          className={classNames.dropdownFilter}
                        >
                          {month
                            ? moment()
                                .month(parseInt(month - 1))
                                .format("MMM")
                            : "Month"}
                          <ArrowDropDownLineIcon />
                        </AntButton>
                      </Dropdown>
                    </span>
                  </div>
                </div>
                <div className={classNames.statementCardBody}>
                  {loadingStatements ? (
                    <div className={classNames.spinnerContainer}>
                      <div>
                        <Spin>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={"No Result found"}
                          />
                        </Spin>
                      </div>
                    </div>
                  ) : statementsPayload?.payload &&
                    statementsPayload?.payload?.statements &&
                    statementsPayload?.payload?.statements.length === 0 ? (
                    <div className={classNames.spinnerContainer}>
                      <div>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={"No Result found"}
                        />
                      </div>
                    </div>
                  ) : filteredStatments ? (
                    filteredStatments?.map((value) => {
                      return (
                        <div
                          key={value.id}
                          onClick={() => {
                            setDownloadStatementId(value?.id);
                          }}
                        >
                          <StatementCard
                            month={dateUtils.toMMMMYYYY(
                              moment(value?.start_date)
                            )}
                            date={moment(value?.start_date)
                              .endOf("month")
                              .format()}
                          />
                        </div>
                      );
                    })
                  ) : (
                    statementsPayload?.payload?.statements.map((value) => {
                      return (
                        <div
                          key={value?.id}
                          onClick={() => {
                            setDownloadStatementId(value?.id);
                          }}
                        >
                          <StatementCard
                            month={dateUtils.toMMMMYYYY(
                              moment(value?.start_date)
                            )}
                            date={moment(value?.start_date)
                              .endOf("month")
                              .format()}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
        {/* <DownloadStatement
          visible={downloadModalVisibility}
          onCancel={handleDownloadModalCancelClick}
          setDownloadModalVisibility={setDownloadModalVisibility}
        /> */}
        {/* <PrintStatement
          visible={printModalVisibility}
          onCancel={handlePrintModalCancelClick}
        /> */}
        <CustomFilter
          visible={filterModalVisibility}
          onCancel={handleFilterModalCancelClick}
          setCustomQueryParams={setCustomQueryParams}
          mainFilter={mainFilter}
          getCustomQueryParams={getCustomQueryParams}
          isForCard={isCardStatement}
        />
      </div>
    </ErrorBoundary>
  );
}
