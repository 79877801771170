import { useContext, useMemo } from "react";

import {
  ROUTES,
  USER_STATES,
  ROLE_SPECIFIC_LANDING_PAGE,
  ROUTE_STATUS,
} from "../App/constants";

import { AppContext } from "../context/AppState";
import useUserState from "./useUserState";

function useRouterSwitch() {
  const { userData } = useContext(AppContext);
  const { roleName } = userData || {};
  const userState = useUserState();

  const path = useMemo(() => {
    let path = ROUTES.PUBLIC.LOGIN;

    switch (userState) {
      case USER_STATES.ONBOARDING:
        path = ROUTES.VERIFICATION.VERIFY_EMAIL;
        break;
      case USER_STATES.EMAIL_VERIFIED: {
        path = ROUTES.VERIFICATION.VERIFY_PHONE;
        break;
      }
      case USER_STATES.PHONE_VERIFIED:
        // path = ROUTES.ONBOARDING.ACCOUNT_TYPE;
        path = ROUTES.ONBOARDING.ONBOARDING_DASHBOARD;
        break;
      // case USER_STATES.INDIVIDUAL_ACCOUNT_TYPE_SELECTED:
      //   path = ROUTES.ONBOARDING.INDIVIDUAL_APPLICATION;
      //   break;
      // case USER_STATES.BUSINESS_ACCOUNT_TYPE_SELECTED:
      //   path = ROUTES.ONBOARDING.BUSINESS_APPLICATION;
      //   break;
      case USER_STATES.BUSINESS_DETAILS_VALID:
        path = ROUTES.ONBOARDING.BENEFICIAL_OWNER_DETAILS;
        break;
      case USER_STATES.BUSINESS_BENEFICIAL_DETAILS_VALID:
        path = ROUTES.ONBOARDING.OFFICER_DETAILS;
        break;
      case USER_STATES.BUSINESS_OFFICER_DETAILS_VALID:
        path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[userState]}`;
        break;

      // case USER_STATES.BUSINESS_AWAITING_DOCUMENTS:
      // case USER_STATES.INDIVIDUAL_AWAITING_DOCUMENTS:
      //   path = ROUTES.ONBOARDING.UPLOAD_DOCUMENTS;
      //   break;
      case USER_STATES.PENDING_REVIEW:
        path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[userState]}`;
        break;
      // case USER_STATES.APPLICATION_AWAITING_DOCUMENT:
      //     path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[userState]}`;
      //     break;
      case USER_STATES.AUTHORIZED_USER:
        path = ROLE_SPECIFIC_LANDING_PAGE[roleName] || ROUTES.APP.INDEX;
        break;
      // case USER_STATES.APPLICAITON_DENIED:
      //   path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[userState]}`;
      //   break;
      case USER_STATES.APPLICATION_CANCELED:
        path = `${ROUTES.ONBOARDING.THANK_YOU}${ROUTE_STATUS[userState]}`;
        break;
      default:
        path = ROUTES.PUBLIC.LOGIN;
        break;
    }

    return path;
  }, [userState, roleName]);

  return path;
}

export default useRouterSwitch;
