import mixpanel from "mixpanel-browser";
import classNames from "./NotificationCard.module.scss";
import { Card, Spin } from "antd";
import { RiArrowRightLine } from "react-icons/ri";
import { Title } from "../../../common";
import ROLES from "../../../constants/scopesToRoles/roles";
import { useNavigate } from "react-router-dom";

const NotificationCard = ({
  title,
  count,
  amount,
  onClick,
  disable,
  roleName,
  path,
  isLoading = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {disable ? (
        <Card className={classNames.notificationCardDisabled}>
          <Title as="h3" style={{ color: "#D1D5DD" }}>
            {title}
          </Title>
          <div className={classNames.notificationDetailsDisabled}>
            <div>Coming soon</div>
            <div />

            {roleName === ROLES.OWNER ? (
              <RiArrowRightLine
                size={26}
                className={classNames.arrowBtnWithBgDisabled}
              />
            ) : null}
          </div>
        </Card>
      ) : (
        <Card
          className={classNames.notificationCard}
          onClick={() => {
            try {
              mixpanel.track('Notification Checked', {
                'Notification Type': title,
                'Count': count,
                'Amount': amount,
              });
            } catch (error) {
              console.error("Mixpanel_error: ", error);
            }

            if (roleName === ROLES.OWNER) {
              navigate(path[0], { state: { filter: path[1] } });
            }
          }}
        >
          <Title as="h3">{title}</Title>
          <div className={classNames.notificationDetails}>
            {isLoading ? (
              <Spin className={classNames.spinner} spinning={isLoading} size="small"></Spin>
            ) : (
              <>
                <div>{count}</div>
                <div className={classNames.amount}>
                  <span>$</span>
                  <span>{amount}</span>
                </div>
              </>
            )}
            {roleName === ROLES.OWNER ? (
              <RiArrowRightLine
                size={26}
                className={classNames.arrowBtnWithBg}
              />
            ) : null}
          </div>
        </Card>
      )}
    </>
  );
};

export default NotificationCard;
