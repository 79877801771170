import { useState } from "react";
import classNames from "./ProductsServices.module.scss";
import { Card, Table, Space, Empty, Button as AntButton } from "antd";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiEditLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AddNewProduct from "./AddNewProduct/AddNewProduct";
import EditProduct from "./EditProduct/EditProduct";
import { useQuery } from "react-query";
import { Input, Header } from "../../../common";
import api from "../../../API";
import DeleteProduct from "./DeleteProduct/DeleteProduct";
import moment from "moment";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
export default function ProductsServices() {
  const [keyword, setKeyword] = useState(null);
  const [pagination, setPagination] = useState(PAGINATION);

  const [addProductCardModalVisibility, setAddProductCardModalVisibility] =
    useState(false);

  const handleAddProductCardModalCancelClick = () => {
    setAddProductCardModalVisibility(false);
  };
  const [editProductCardModalVisibility, setEditProductCardModalVisibility] =
    useState({ visibility: null, record: null });

  const [deletePorductModalVisibility, setDeletePorductModalVisibility] =
    useState({ visibility: null, record: null });

  const handleEditProductCardModalCancelClick = () => {
    setEditProductCardModalVisibility(false);
  };

  const navigate = useNavigate();

  const { data: currentUserData, isLoading: currentUserIsLoading } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: productQuery, isLoading: productQueryLoading } = useQuery(
    "getProducts",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const productArray = productQuery?.data;
  const productDataSource =
    Array.isArray(productArray) &&
    productArray
      .map((value, index) => Object.assign(value, { key: index }))
      .sort((a, b) => {
        return new Date(moment(b.updatedAt)) - new Date(moment(a.updatedAt));
      });

  const onClickEditProduct = (item) => {
    setEditProductCardModalVisibility({ visibility: true, record: item });
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: "30%",
      render: (text, record) => (
        <Space
          style={{ cursor: "pointer" }}
          onClick={() => {
            onClickEditProduct(record);
          }}
        >
          {text}
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return <span>{price ? "$" + price : "-"}</span>;
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "right",
            width: "80%",
          }}
        >
          <Space size="middle">
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClickEditProduct(record);
              }}
            />
            <RiDeleteBin7Line
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                setDeletePorductModalVisibility({
                  visibility: true,
                  record: record,
                });
              }}
            />
          </Space>
        </Space>
      ),
    },
  ];

  // commenting due to future use - afterMVP
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {},
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const filteredArray =
    keyword &&
    productDataSource &&
    productDataSource.length > 0 &&
    productDataSource.filter((obj) => {
      let filterObj = {
        name: obj.productName,
        price: obj.price,
        quantity: obj.quantity,
      };
      return Object.values(filterObj)
        .toString()
        .toLowerCase()
        .includes(keyword.toLowerCase());
    });

  //Display pagination count text
  let totalNoOfRecords =
    Array.isArray(productDataSource) && productDataSource?.length > 0 && keyword
      ? Array.isArray(filteredArray)
        ? filteredArray.length
        : Array.isArray(productDataSource)
        ? productDataSource?.length
        : 0
      : productDataSource.length;

  let maximumNoOfPages = Math.ceil(totalNoOfRecords / pagination.pageSize);
  let noOfRecordsForLastPage =
    totalNoOfRecords / maximumNoOfPages > 0
      ? (totalNoOfRecords - pagination.pageSize) * (maximumNoOfPages - 1)
      : pagination.pageSize;
  let calculateRecordCount =
    pagination.pageSize >= totalNoOfRecords
      ? totalNoOfRecords
      : pagination.page === maximumNoOfPages
      ? noOfRecordsForLastPage
      : pagination.pageSize;

  const paginationText =
    totalNoOfRecords === 0
      ? `No products or services to display`
      : `Displaying
      ${calculateRecordCount}
      of ${totalNoOfRecords} ${
          totalNoOfRecords > 1 ? "products or services" : "products or services"
        }`;

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Products & Services" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Input
              placeholder="Search Products and Services"
              prefix={<RiSearch2Line size={26} />}
              className={classNames.searchInvoice}
              onChange={(event) => {
                setKeyword(event.target.value);
                setPagination(PAGINATION);
              }}
            />

            <Card
              className={classNames.optionCard}
              onClick={() => {
                setAddProductCardModalVisibility(true);
              }}
            >
              <div className={classNames.cardAction}>
                <span>Add New Product</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              {paginationText}
            </div>
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => onClickEditProduct(record),
                };
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      keyword && totalNoOfRecords === 0
                        ? "No Result found"
                        : "No Data Found"
                    }
                  />
                ),
              }}
              // commenting due to future use - afterMVP
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              dataSource={keyword ? filteredArray : productDataSource}
              columns={columns}
              className={classNames.table}
              rowClassName={classNames.actionIcon}
              // scroll={{ y: `var(--table-height)` }}
              size="large"
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  }
                  if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              loading={productQueryLoading || currentUserIsLoading}
            />
            <AddNewProduct
              visible={addProductCardModalVisibility}
              onCancel={handleAddProductCardModalCancelClick}
            />
            <EditProduct
              key={editProductCardModalVisibility?.record?.key}
              visible={editProductCardModalVisibility.visibility}
              onCancel={handleEditProductCardModalCancelClick}
              product={editProductCardModalVisibility.record}
              setEditProductCardModalVisibility={
                setEditProductCardModalVisibility
              }
              taxRates={taxRates?.data}
            />
            <DeleteProduct
              visible={deletePorductModalVisibility.visibility}
              onCancel={() => setDeletePorductModalVisibility(false)}
              product={deletePorductModalVisibility.record}
              setDeletePorductModalVisibility={setDeletePorductModalVisibility}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
