import { Form } from "antd";
import EditEstimateForm from "./EditEstimateForm";
import { Modal } from "../../../../common";
import classNames from "./EditEstimate.module.scss";

export default function EditEstimate({
  visible,
  onCancel,
  estimate,
  customers,
  products,
  taxRates,
  setEditEstimateModalVisibility,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  return (
    <Modal
      getContainer={false}
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        onCancel();
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      title="Edit Estimate"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={872}
      // footer={
      //   <CTAButton
      //     htmlType="submit"
      //     type="primary"
      //     key="submit"
      //     onClick={form.submit}
      //     style={{
      //       height: 44,
      //       width: 248,
      //     }}
      //   >
      //     Save
      //   </CTAButton>
      // }
    >
      <EditEstimateForm
        estimate={estimate}
        products={products}
        customers={customers}
        taxRates={taxRates}
        setEditEstimateModalVisibility={setEditEstimateModalVisibility}
        invoiceTemplateSettings={invoiceTemplateSettings}
        onCancel={onCancel}
      />
    </Modal>
  );
}
