const revenueList = () => {
    return [
      { value: '<= $5,000', label: '<= $5,000' },
      { value: '$5,001 - $10,000', label: '$5,001 - $10,000' },
      { value: '$10,001 - $20,000', label: '$10,001 - $20,000' },
      { value: '$20,001 - $50,000', label: '$20,001 - $50,000' },
      { value: '>$50,000', label: '>$50,000' },
    ];
  };
  
  export default revenueList;