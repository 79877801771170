import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import api from "../../../API";
import { /*USER_STATES, */ APPLICAITON_STATUS_MESSAGES } from "../../../constants";
// import useUserState from "../../../../hooks/useUserState";
import { Title } from "../../../common";
import { AppContext } from "../../../../context/AppState";

const StatusTitle = () => {
  // const userState = useUserState();
  const { userData } = useContext(AppContext);
  const [verifiedOwners, setVerifiedOwners] = useState(true);
  const [ownerRejected, setOwnerRejected] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data: application, isLoading: dataLoading } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: async (data) => {
        const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
        await setVerifiedOwners(businessRelationsResponse?.payload?.relationShips?.every(relation => 
          relation.person_info.verification_status === "ACCEPTED"
        ));
        await setOwnerRejected(businessRelationsResponse?.payload?.relationShips?.some(relation => 
          relation.person_info.verification_status === "REJECTED"
        ));
        setLoading(false)
      },
      refetchOnWindowFocus: false,
    }
  );

  if (dataLoading) {
    return null; // or return a loading spinner or some placeholder content
  }

  let content;

  // if (userState === USER_STATES.APPLICATION_CANCELED) {
  if (!loading) {
    if (application?.payload?.verification_status === "REJECTED" || application?.payload?.verification_status === "denied") {
      content = (
        <>
          <Title as="h2" style={{ width: "100%" }}>
            We're Sorry, <br/>your application has been rejected
          </Title>
          <p style={{marginBottom: "20vh"}}>Please
            <a href="https://www.otterz.co/contact-us">
               {APPLICAITON_STATUS_MESSAGES.CONTACT_US}
            </a>{" "}
            {APPLICAITON_STATUS_MESSAGES.DENIED_TITLE_END}
          </p>
        </>
      );
    } else if (application?.payload?.verification_status === "PENDING_REVIEW") {
      content = (
        <>
          <Title as="h2" style={{ width: "100%" }}>
            Your application is under review
          </Title>
        </>
      );
    } else if (ownerRejected) {
      content = (
        <>
          <Title as="h2" style={{ width: "100%" }}>
            We're Sorry, <br/>your application has been rejected
          </Title>
          <p style={{marginBottom: "20vh"}}>Please
            <a href="https://www.otterz.co/contact-us">
               {APPLICAITON_STATUS_MESSAGES.CONTACT_US}
            </a>{" "}
            {APPLICAITON_STATUS_MESSAGES.DENIED_TITLE_END}
          </p>
        </>
      );
    } else if ((!verifiedOwners || ownerRejected) && application?.payload?.verification_status === "ACCEPTED") {
      content = (
        <Title as="h1" style={{width: "60%", fontWeight: "bold"}}>
          Complete Verification
        </Title>
      );
    } else {
      content = (
        <Title as="h1" style={{width: "60%", fontWeight: "bold"}}>
          Complete identity verification to submit your application
        </Title>
      );
    }
  } else {
    content = null
  }

  return content;
};


export default StatusTitle;
