import { Auth } from 'aws-amplify';
import endpoints from "../../../endpoints";

export default async function getAPInvoiceFile(data) {
    let env = process.env.REACT_APP_ENV?.trim();
        
    const url = `${endpoints.PAYABLES.ACCOUNTS_PAYABLES.INVOICES[env]}file/${data}`;

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`
            }
        })
    );
}
