import { useEffect, useState, useContext } from "react";
import { 
  // useLocation, 
  useNavigate 
} from "react-router-dom";
import { 
  useMutation, 
  useQuery, 
  QueryErrorResetBoundary 
} from "react-query";
import {
  Card,
  Table,
  // Space,
  // Select as AntSelect,
  Empty,
  Button as AntButton,
  Modal,
  Input as AntInput,
  Select,
} from "antd";
import { ErrorBoundary } from "react-error-boundary";
import {
  // RiSearch2Line,
  RiArrowRightLine,
  // RiEditLine,
  // RiDeleteBin7Line,
} from "react-icons/ri";
import moment from "moment";
import { 
  Input, 
  Header, 
  // Select, 
  Button, 
  Message,
  DatePicker,
} from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import { isBusinessType } from "../../../util/functions/onboarding";
import api from "../../../API";
import { AppContext } from "../../../../context/AppState";
import { ROUTES } from "../../../constants";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import classNames from "./PendingInvoices.module.scss";

export default function AllInvoices() {
  const navigate = useNavigate();
  // const location = useLocation();

  const [pagination, setPagination] = useState(PAGINATION);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState(invoiceData);
  const [keyword, setKeyword] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [dateFilterValue, setDateFilterValue] = useState(null);

  // const [client, setClient] = useState(null);
  const [sort, setSortChange] = useState({
    field: null,
    order: null,
  });
  const { application } = useContext(AppContext);
  const applicationData = application;

  const { data: payeeData, isLoading: isPayeeLoading } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "receipt_date",
      key: "receipt_date",
      render: (date) => <span>{moment(date).format()}</span>,
      width: "20%",
      sorter: (a, b) => moment(a.receipt_date).unix() - moment(b.receipt_date).unix(),
    },
    {
      title: "Vendor Name",
      dataIndex: "otz_payee_id",
      key: "otz_payee_id",
      width: "20%",
      render: (otz_payee_id) => {
        if(payeeData?.payload &&
          payeeData?.payload.length > 0) {
            return (<span>{payeeData?.payload?.find((entry) => entry?.otz_payee_id === otz_payee_id)?.account_owner_name}</span>)
        }
      },
      ellipsis: true,
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      render: (total) => <span>$ {total}</span>,
      width: "20%",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Invoice Number",
      dataIndex: "receipt_id",
      key: "receipt_id",
      width: "20%",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (dueDate) => <span>{moment(dueDate, "DD/MM/YYYY").format()}</span>,
      width: "20%",
      sorter: (a, b) => moment(a.due_date, "DD/MM/YYYY") - moment(b.due_date, "DD/MM/YYYY"),
    },
  ];

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedInvoices(selectedRows); // Update the selectedInvoices state
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
    }),
  };
  

  const rowClick = (invoiceData) => {
    navigate(
        ROUTES.APP.PAYABLES.UPDATE_INVOICE.replace(":id", invoiceData?.invoice_id), 
        { state: { invoiceData, routeFrom: "pending" } }
    );
  };

  // Fetch all invoices
  const fetchInvoicesMutation = useMutation(() => api.AccountsPayable.getAPInvoiceByStatus("pending"));
  useEffect(() => {
      fetchInvoicesMutation.mutate();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingInvoice = fetchInvoicesMutation.isLoading;
  const totalNoOfRecords = fetchInvoicesMutation?.data?.payload?.length || 0;

  useEffect(() => {
    if (fetchInvoicesMutation.data) {
      const dataWithKeys = fetchInvoicesMutation.data.payload.map(invoice => ({
        ...invoice,
        key: invoice.invoice_id
      }));
      setInvoiceData(dataWithKeys);
      setFilteredInvoiceData(dataWithKeys);
    }
  }, [fetchInvoicesMutation.data]);

  const getDataSource = () => {
    let data = [...filteredInvoiceData];
  
    if (keyword && filterType === 'otz_payee_id') {
      data = data.filter((item) => {
        const vendor = payeeData?.payload?.find((entry) => entry?.otz_payee_id === item?.otz_payee_id);
        return vendor && vendor?.account_owner_name.toLowerCase().includes(keyword.toLowerCase());
      });
    }
  
    if (dateFilterValue && filterType === 'due_date') {
      data = data.filter(item => moment(item.due_date).isSameOrBefore(dateFilterValue));
    }

    if (sort) {
      data.sort((a, b) => {
        // Custom sorting based on the selected column and order
        const sortField = sort.field;
        const sortOrder = sort.order === 'ascend' ? 1 : -1;
        if (sortField === 'receipt_date') {
          return sortOrder * (moment(a.receipt_date).unix() - moment(b.receipt_date).unix());
        } else if (sortField === 'due_date') {
          return sortOrder * (moment(a.due_date).unix() - moment(b.due_date).unix());
        } else if (sort.field === "total") {
          return sortOrder * (a.total - b.total);
        }
        // Add more cases for other columns if needed
        return 0;
      });
    }

    // Pagination logic
    const startIndex = (pagination.page - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    data = data.slice(startIndex, endIndex);

    return data;
  };

  // const displayedRecords = Math.min(pagination.pageSize, getDataSource().length);
  // const paginationText = `Displaying ${displayedRecords} of ${getDataSource().length} invoices`;
  
  // Function to handle the approval of invoices

  const approveInvoice = useMutation(api.AccountsPayable.approveAPInvoice);
  const approveInvoiceBulk = useMutation(api.AccountsPayable.approveAPInvoicesBulk);
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleApproveInvoices = async () => {
    try {
      let responseData = null
      if (selectedInvoices.length === 1) {
        responseData = await approveInvoice.mutateAsync({ invoice_id: selectedInvoices[0].invoice_id });
      } else if (selectedInvoices.length > 1) {
          const invoiceIDsList = selectedInvoices.map(invoice => invoice.invoice_id);
          responseData = await approveInvoiceBulk.mutateAsync(invoiceIDsList);
      }
      if (responseData.status === 200) {
        Message({ type: "success", content: "Invoices approved" });
        setIsModalVisible(false);
      } else {
        Message({ type: "error", content: responseData?.message || "Error approving invoices."  });
      }

      setSelectedInvoices([])

      setIsModalVisible(false);
      
      // Refresh the invoice list
      fetchInvoicesMutation.mutate();
    } catch (error) {
      console.error("Error approving invoices:", error);
      Message({ type: "error", content: "Error approving invoices. Please try again." });
    }
  };

  // Function to handle the closing of the modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // Function to handle the rejection of invoices
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const handleOpenRejectModal = () => {
    // setIsRejectModalVisible(true);
    setIsRejectModalVisible(false);
    handleRejectInvoices();
  };
  const handleCloseRejectModal = () => {
    setIsRejectModalVisible(false);
  };

  const rejectInvoice = useMutation(api.AccountsPayable.rejectAPInvoice);
  const rejectInvoiceBulk = useMutation(api.AccountsPayable.rejectAPInvoicesBulk);
  const handleRejectInvoices = async () => {
    try {
      // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      // if (email && !emailRegex.test(email)) {
      //   // If the email is invalid, show an error message
      //   console.log("Not email");
      //   Message({ type: "error", content: "The input is not a valid email!" });
      //   return;
      // }

      // if (!rejectionReason) {
      //   Message({ type: "error", content: "Rejection reason is required!" });
      //   return;
      // }

      if (selectedInvoices.length === 1) {
        await rejectInvoice.mutateAsync({
            invoice_id: selectedInvoices[0].invoice_id,
            email: email,
            reason: rejectionReason
        });
      } else if (selectedInvoices.length > 1) {
        const invoiceIDsList = selectedInvoices.map(invoice => invoice.invoice_id);
        await rejectInvoiceBulk.mutateAsync(invoiceIDsList);
      }
      Message({ type: "success", content: "Invoices rejected" });
      setIsRejectModalVisible(false);
      setSelectedInvoices([])

      // Refresh the invoice list
      fetchInvoicesMutation.mutate();
    } catch (error) {
      console.error("Error rejecting invoices:", error);
      Message({ type: "error", content: "Error rejecting invoices. Please try again." });
    }
  };


  return (
    <div className={classNames.wrapper}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
              </div>
            )}
          >
            <div className={classNames.headerWrapper}>
              <Header
                title="Pending Invoices"
                back
                onClick={() => navigate("../account_payable")}
              />
            </div>
            <div className={classNames.layout}>
              <div className={classNames.bodyWrapper}>
                <div className={classNames.topRow}>
                  <div className={classNames.invoiceFilters}>
                    <Select
                      placeholder="Filter by"
                      className={classNames.filterSelect}
                      onChange={(value) => {
                        setFilterType(value);
                        setKeyword(''); // clear the search bar
                        setDateFilterValue(null); // clear the date picker
                      }}
                      style={{ width: 150, marginRight: 3 }}
                    >
                      <Select.Option value="due_date">Due Date</Select.Option>
                      <Select.Option value="otz_payee_id">Vendor Name</Select.Option>
                    </Select>
                    
                    {filterType === 'otz_payee_id' && (
                      <Input
                        value={keyword}
                        placeholder="Search by Vendor Name"
                        onChange={(event) => setKeyword(event.target.value)}
                        className={classNames.search}
                      />
                    )}

                    {filterType === 'due_date' && (
                      <DatePicker
                        value={dateFilterValue}
                        onChange={(date) => setDateFilterValue(date)}
                        // className={classNames.datePicker}
                        style={{ borderRadius: 0, borderColor: "#d9d9d947" }}
                      />
                    )}
                  </div>

                  <Card
                    className={classNames.optionCard}
                    onClick={() => navigate(ROUTES.APP.PAYABLES.UPLOAD_INVOICE)}
                  >
                    <div className={classNames.cardAction}>
                      <span>Upload an Invoice</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                </div>
                <div className={classNames.body}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "26px 0",
                      width: "fit-content",
                    }}
                  >
                    {/* {paginationText} */}
                    <div style={{ display: 'flex', alignItems: 'center', margin: "0 10px" }}>
                      {selectedInvoices.length > 0 && (
                        <>
                          <AntButton
                            type="solid"
                            danger
                            style={{ marginRight: '10px' }}
                            onClick={handleOpenRejectModal}
                          >
                            Reject Selected
                          </AntButton>
                          <AntButton
                            type="primary"
                            onClick={() => setIsModalVisible(true)}
                          >
                            Approve Selected
                          </AntButton>
                        </>
                      )}
                    </div>
                  </div>
                  <Table
                    loading={isLoadingInvoice || isPayeeLoading}
                    rowKey="invoice_id"
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            keyword && totalNoOfRecords === 0
                              ? "No Result found"
                              : "No Data Found"
                          }
                        />
                      ),
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          rowClick(record);
                        },
                      };
                    }}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    // scroll={{ y: `var(--table-height)` }}
                    size="large"
                    dataSource={getDataSource()
                      ?.sort((a, b) => b.number - a.number)}
                    columns={columns}
                    className={classNames.table}
                    rowClassName={classNames.actionIcon}
                    onChange={(pagination, filters, sorter) => {
                      if (sorter && sorter.field && sorter.order) {
                        setSortChange({ field: sorter.field, order: sorter.order });
                      } else {
                        setSortChange(null); // Reset the sort state if no sorting is applied
                      }
                      setPagination({ page: pagination.current, pageSize: pagination.pageSize });
                    }}
                    pagination={{
                      ...PAGINATION_DEFAULT,
                      total: filteredInvoiceData.length,
                      current: pagination.page,
                      pageSize: pagination.pageSize,
                      onChange: (page, pageSize) => {
                        setPagination({ page: page, pageSize: pageSize });
                      },
                      itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                          return (
                            <AntButton style={{ color: "#9f9cff" }}>
                              Prev
                            </AntButton>
                          );
                        }
                        if (type === "next") {
                          return (
                            <AntButton
                              style={{
                                color: "#9f9cff",
                                marginLeft: "32px",
                              }}
                            >
                              Next
                            </AntButton>
                          );
                        }
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Approve Invoices Modal component */}
            <Modal
              // title="Approve Invoices"
              centered
              visible={isModalVisible}
              onCancel={handleCloseModal}
              footer={[
                <div className={classNames.centeredModalFooter}>
                  <AntButton key="no" onClick={handleCloseModal}>
                    Cancel
                  </AntButton>
                  <AntButton key="yes" type="primary" onClick={handleApproveInvoices}>
                    Yes, Approve
                  </AntButton>
                </div>
              ]}
            >
              <div className={classNames.centeredModalContent}>
                <h2>Approve Invoices</h2>
                <p>
                  Are you sure you want to approve all {selectedInvoices.length} invoices selected?
                </p>
              </div>
            </Modal>
            
            {/* Reject Invoices Modal */}
            <Modal
              title={<span style={{ fontWeight: 'bold' }}>Reject Invoices</span>}
              visible={isRejectModalVisible}
              onCancel={handleCloseRejectModal}
              footer={[
                <AntButton 
                  key="reject" 
                  type="primary" 
                  danger 
                  style={{ width: '100%' }}
                  onClick={handleRejectInvoices}
                >
                  Reject
                </AntButton>
              ]}
            >
              <div>
                <label style={{ fontWeight: '500' }}>Email address:</label>
                <Input
                  type="email"
                  value={email}
                  placeholder="Please enter email address"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <label style={{ fontWeight: '500' }}>Reason for rejection:</label>
                <AntInput.TextArea
                  value={rejectionReason}
                  placeholder="Let us know why you are rejecting these invoices"
                  onChange={(e) => setRejectionReason(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </Modal>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
}
