import classNames from "../ViewInvoice.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";

export default function ShareInvoice({ visible, onCancel, invoice }) {
  const queryClient = useQueryClient();

  function shareInvoiceFn() {
    let input = { 
      id: invoice.id,
      type: "invoice"
    }
    return api.Receivables.Share.email(input);
  }

  const shareInvoiceMn = useMutation(() => shareInvoiceFn());
  const handleConfirmClick = (values) => {
    shareInvoiceMn.mutate(values, {
      onSuccess: (data, variables, context) => {
        Message({ type: "success", content: "Email sent successfully!" });
        queryClient.invalidateQueries("getInvoiceById", {
          queryKey: { id: invoice.id },
        });
        onCancel();
      },
      onError: (error, variables, context) => {
        Message({ type: "error", content: error.toString() });
      },
    });
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Share Invoice
      </Title>
      <div style={{ marginBottom: 8 }}>
        {`Are you sure you want to share this invoice with ${invoice?.receiverName}?`}
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            border: "none",
          }}
          onClick={() => {
            handleConfirmClick();
          }}
          loading={shareInvoiceMn.isLoading}
        >
          Yes, share
        </CTAButton>
      </div>
    </Modal>
  );
}
