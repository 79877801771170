import { API } from "aws-amplify";

export default async function updateApplication(reqBody, isBusiness) {
  if (reqBody?.id) {
    return await API.patch(
      "createApplication",
      `${isBusiness ? "business" : "person"}/${reqBody.id}`,
      {
        body: {
          ...reqBody?.data,
        },
      }
    );
  }
}
