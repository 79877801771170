import { useState, useEffect, useMemo, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";
import LockPasswordLineIcon from "remixicon-react/LockPasswordLineIcon";
import Notification3LineIcon from "remixicon-react/Notification3LineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import ExchangeLineIcon from "remixicon-react/ExchangeLineIcon";
import Lock2LineIcon from "remixicon-react/Lock2LineIcon";
import GhostLineIcon from "remixicon-react/GhostLineIcon";
import { Title, Header } from "../../common";
import { Card, Switch, Spin, Empty, Button } from "antd";
import Carousel from "./CardCarousel";
import ActivateCard from "./ActivateCard";
import RequestNewCard from "./RequestNewCard";
import CloseCard from "./CloseCard/CloseCard";
import FreezeCard from "./FreezeCard/FreezeCard";
import ReplaceCard from "./ReplaceCard/ReplaceCard";
import UnfreezeCard from "./UnfreezeCard/UnfreezeCard";
import SetSpendLimit from "./SetSpendLimit/SetSpendLimit";
import ReportStolen from "./ReportStolen/ReportStolenPopup";
import SetNotifications from "./SetNotifications/SetNotifications";
import CategoryWiseSpend from "./CategoryWiseSpend/CategoryWiseSpend";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
// import {
//   getCustomerToken,
//   setCustomerToken,
// } from "../../util/functions/customerTokenConfig";
// import generateErrorMessage from "../../util/functions/customError";
import { COLORS } from "../config/constants";
import noCards from "../../../assets/manage-cards/noCards.svg";
import noDataCategoryWiseSpend from "../../../assets/manage-cards/noDataCategoryWiseSpend.svg";
import classNames from "./ManageCards.module.scss";
import AccountActivityModal from "../config/accountActivityModal";

export default function ManageCards() {
  const [accountType] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [cardStatus, setCardStatus] = useState("Set New Pin");
  const [allCards, setAllCards] = useState();
  const [currentCard, setCurrentCard] = useState(undefined);
  // const [currentPinStatus, setCurrentPinStatus] = useState();
  const [showAllCards] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hideCardDetail, setHideCardDetail] = useState();
  const [cardwiseTrans, setCardWiseTrans] = useState([]);
  const { application } = useContext(AppContext);
  const [physicalCard, setPhysicalCard] = useState(0);
  const [virtualCard, setVirtualCard] = useState(0);
  const [allowedPhysicalCard, setAllowedPhysicalCard] = useState(1);
  const [allowedVirtualCard, setAllowedVirtualCard] = useState(1);
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);
  const queryClient = useQueryClient();

  // <------------ State Changes  ----------->
  //#region
  const [freezeCardModalVisibility, setFreezeCardModalVisibility] =
    useState(false);

  const handleFreezeCardModalVisibility = () => {
    setFreezeCardModalVisibility(false);
    setHideCardDetail(false);
  };
  const [unfreezeCardModalVisibility, setUnfreezeCardModalVisibility] =
    useState(false);

  const handleUnfreezeCardModalVisibility = () => {
    setHideCardDetail(false);
    setUnfreezeCardModalVisibility(false);
  };
  const [setSpendLimitModalVisibility, setSetSpendLimitModalVisibility] =
    useState(false);

  const handleSetSpendLimitModalCancelClick = () => {
    setHideCardDetail(false);
    setSetSpendLimitModalVisibility(false);
  };
  const [replaceCardModalVisibility, setReplaceCardModalVisibility] =
    useState(false);

  const handleReplaceCardModalCancelClick = () => {
    setHideCardDetail(false);
    setReplaceCardModalVisibility(false);
  };
  const [reportStolenModalVisibility, setReportStolenModalVisibility] =
    useState(false);

  const handleReportStolenModalCancelClick = () => {
    setHideCardDetail(false);
    setReportStolenModalVisibility(false);
  };
  const [requestNewCardModalVisibility, setRequestNewCardModalVisibility] =
    useState(false);

  const handleRequestNewCardModalCancelClick = () => {
    setHideCardDetail(false);
    setRequestNewCardModalVisibility(false);
  };
  const [setNotificationsModalVisibility, setSetNotificationsModalVisibility] =
    useState(false);

  const handleSetNotificationsModalCancelClick = () => {
    setHideCardDetail(false);
    setSetNotificationsModalVisibility(false);
  };

  const [closeCardModalVisibility, setCloseCardModalVisibility] =
    useState(false);

  const handleCloseCardModalCancelClick = () => {
    setHideCardDetail(false);
    setCloseCardModalVisibility(false);
  };

  // <-------------------PIN + Activate Cards------------------------------>
  const [activateCardModalVisibility, setActivateCardModalVisibility] =
    useState(false);

  const handleActivateCardModalCancel = () => {
    setHideCardDetail(false);
    setActivateCardModalVisibility(false);
    queryClient.invalidateQueries("listCards");
  };

  const [changePinModalVisibility, setChangePinModalVisibility] =
    useState(false);

  const handleChangePinModalCancel = () => {
    setChangePinModalVisibility(false);
    setHideCardDetail(false);
    queryClient.invalidateQueries("listCards");
  };

  const [setNewPinModalVisibility, setSetNewPinModalVisibility] =
    useState(false);

  const handleSetNewPinModalCancelClick = () => {
    setHideCardDetail(false);
    setSetNewPinModalVisibility(false);
    queryClient.invalidateQueries("listCards");
  };
  //#endregion

  // <---- Customer & Verification Token Manipulation ---->
  //#region
  // const [verificationToken, setVerificationToken] = useState();
  // const [customerBearerToken, setCustomerBearerToken] = useState();
  // const [showVGSForm, setShowVGSForm] = useState();
  // const [showDisplayOTP, setShowDisplayOTP] = useState();

  /* const verficationTokenMutation = useMutation(
    api.BusinessBanking.createVerificationToken,
    {
      onSuccess: (data) => {
        setVerificationToken(data.data.verificationToken);
        Message({ type: "success", content: "OTP sent." });
      },
      onError: (error) => generateErrorMessage(error),
    }
  ); */

  // const createCustomerBearerToken = useMutation(
  //   api.BusinessBanking.createCustomerBearerToken,
  //   {
  //     onSuccess: (data) => {
  //       setCustomerToken(
  //         "UNIT_customer_token",
  //         data?.data?.data?.attributes?.token,
  //         86400000
  //       );
  //       Message({ type: "success", content: "User verified!" });
  //       queryClient.invalidateQueries("listCards");
  //       queryClient.invalidateQueries([
  //         "getPinStatus",
  //         currentCard?.otz_card_id,
  //       ]);
  //       setTimeout(() => {
  //         setShowDisplayOTP(false);
  //         setShowVGSForm(true);
  //       }, 1000);
  //     },
  //     onError: (error) => generateErrorMessage(error),
  //   }
  // );

  // useEffect(() => {
  //   setCustomerBearerToken(getCustomerToken("UNIT_customer_token"));
  // }, [showVGSForm]);

  //#endregion

  const date = useMemo(() => new Date(), []);

  // Get all cards data
  const { refetch: cardsRefetch } = useQuery(
    "listCards",
    () =>
      api.BusinessBanking.listCards(
        application?.type === "PERSON"
          ? {
              otz_customer_id: application?.otz_person_id,
              otz_account_id: application?.otz_account_id,
            }
          : {
              otz_account_id: application?.otz_account_id,
            }
      ),
    {
      onSuccess: (data) => {
        const totalCards = data?.payload?.cards?.length;

        if (totalCards) {
          setAllCards(data?.payload?.cards);

          const totalActiveVirtualCard = data?.payload?.cards?.filter(
            (card) => card?.form === "VIRTUAL" && card?.card_status === "ACTIVE"
          )?.length;

          const totalPhysicalActiveCard = data?.payload?.cards?.filter(
            (card) =>
              card?.form === "PHYSICAL" &&
              (card?.card_status === "ACTIVE" ||
                card?.card_status === "UNACTIVATED")
          )?.length;

          setVirtualCard(totalActiveVirtualCard);
          setPhysicalCard(totalPhysicalActiveCard);
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  // Get PIN status of current card
  // const { isLoading: pinStatusLoading } = useQuery(
  //   ["getPinStatus", currentCard?.otz_card_id],
  //   () => api.BusinessBanking.getPinStatus(currentCard?.otz_card_id),
  //   {
  //     onSuccess: (data) => {
  //       setCurrentPinStatus(currentCard?.is_pin_set);
  //     },
  //     enabled: !!currentCard?.otz_card_id,
  //     refetchOnWindowFocus: false,
  //     retry: false,
  //   }
  // );

  // Get all transactions of of all cards
  // useQuery(
  //   ["getCardTransactions", queryParams, showAllCards],
  //   () => api.BusinessBanking.getTransactions(queryParams),
  //   {
  //     onSuccess: (data) => {
  //       setAllCardTrns(data?.data);
  //     },
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // Get all transactions of current card
  // const { refetch: refetchCurrentCardTrns } = useQuery(
  //   ["getCurrentCardTrns", currentCard?.otz_card_id, queryParams, showAllCards],
  //   () =>
  //     api.BusinessBanking.getTransactions({
  //       ...queryParams,
  //       cardId: currentCard?.otz_card_id,
  //     }),
  //   {
  //     onSuccess: (data) => {
  //       setCurrentCardTrns(data?.data);
  //     },
  //     refetchOnWindowFocus: false,
  //   }
  // );

  //Card limit api integration
  useQuery(
    "getCurrentSubscriptionPlanId",
    () => api.Settings.getCurrentSubscriptionPlan(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const bankingObject = response?.payload?.data?.banking;
        if (bankingObject) {
          setAllowedPhysicalCard(bankingObject.physical_debit_card);
          setAllowedVirtualCard(bankingObject?.virtual_debit_card);
        }
      },
    }
  );

  // Get card wise spend
  useQuery(
    "cardwiseSpendTransaction",
    () =>
      api.BusinessBanking.getCategorywiseCardSpend({
        otz_account_id: application?.otz_account_id,
      }),
    {
      onSuccess: (data) => setCardWiseTrans(data?.payload),
      refetchOnWindowFocus: false,
    }
  );

  /* const { data: accountPayload } = useQuery(
    "getAccount",
    () => api.BusinessBanking.getAccount(1),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  ); */

  // Attach script for VGS show --> Show card sensitive data
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js";
    script.async = true;
    document.body.appendChild(script);
    setScriptLoaded(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    setCurrentCard(() => {
      if (allCards) {
        return allCards[activeSlide];
      }
    });
  }, [
    isLoading,
    activeSlide,
    currentCard?.otz_card_id,
    allCards,
    currentCard,
    showAllCards,
  ]);

  useEffect(() => {
    (function checkCardPinStatus() {
      if (currentCard?.card_status === "ACTIVE") {
        if (!currentCard?.is_pin_set) {
          setCardStatus("Set New Pin");
        } else {
          setCardStatus("Change Pin");
        }
      } else {
        setCardStatus("Activate Card");
      }
    })();
  }, [
    currentCard?.otz_card_id,
    date,
    currentCard?.is_pin_set,
    // pinStatusLoading,
    currentCard?.card_status,
  ]);

  // <------------- Category-wise expenditure ------------->
  //#region
  const [displayData, setDisplayData] = useState();

  /* function sanitizeData(arr, property) {
    return arr.reduce((memo, x) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  } */

  /* function getTotal(transactions) {
    let total = 0;
    let summary = {};
    let types = Object.keys(transactions);
    types.forEach((type) => {
      transactions[type].forEach(
        (transaction) => (total += transaction?.debit)
      );
      summary[type] = transactions[type].reduce(
        (sum, { debit }) => sum + debit,
        0
      );
    });

    return { total, summary };
  } */

  /* function findMaxCategory(data) {
    return (
      Array.isArray(data) &&
      data[
        Object.entries(data).reduce((a, data) => (a[1] > data[1] ? data : a))[0]
      ]
    );
  } */

  /* function formatData(data) {
    let sorted = Object.entries(Object.assign({}, ...data))
      .sort((a, b) => b[1] - a[1])
      .reduce((acc, [key, val]) => {
        acc.push({ [key]: val });
        return acc;
      }, []);
    return sorted;
  } */

  /* function geTotalOtherCategories(arr) {
    let sum = 0;
    arr.forEach((item) => (sum += parseInt(Object.values(item))));
    return sum;
  } */

  //#endregion
  /* useEffect(() => {
    let newArr = [];
    let aggregratedData;
    let totalAmount;
    let transactionSummary;
    let maxCategory;
    let formattedSummary;

    if (showAllCards) {
      if (Array.isArray(allCardTrns.data) && !allCardTrns?.message) {
        allCardTrns.data.forEach((trns) => {
          for (let i = 0; i < MCC.length; i++) {
            if (
              trns.merchantType >= MCC[i].min &&
              trns.merchantType < MCC[i].max + 1
            ) {
              newArr.push({
                ...trns,
                merchantType: MCC[i].category.trim(" "),
              });
            }
          }
        });
      } else {
        return;
      }
      aggregratedData = sanitizeData(newArr, "merchantType");
    } else {
      Array.isArray(currentCardTrns?.data) &&
        !currentCardTrns?.message &&
        currentCardTrns?.data.forEach((trns) => {
          for (let i = 0; i < MCC.length; i++) {
            if (
              trns.merchantType >= MCC[i].min &&
              trns.merchantType < MCC[i].max + 1
            ) {
              newArr.push({
                ...trns,
                merchantType: MCC[i].category.trim(" "),
              });
            }
          }
        });

      aggregratedData = sanitizeData(newArr, "merchantType");
    }
    totalAmount = getTotal(aggregratedData).total;

    transactionSummary =
      (showAllCards || currentCardTrns?.data) &&
      Object.entries(getTotal(aggregratedData).summary)
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [key, val]) => {
          acc.push({ [key]: val });
          return acc;
        }, []);

    maxCategory = findMaxCategory(transactionSummary);

    let sorted = transactionSummary && formatData(transactionSummary);

    if (sorted) {
      let maxCategoryValue = Object.values(maxCategory)[0];

      if (sorted?.length <= 6) {
        formattedSummary = sorted?.map((item, index) => {
          let amount = parseInt(Object.values(item));
          return {
            angle: amount,
            radius: (amount / maxCategoryValue) * MAX_RADIUS,
            color: COLORS[index],
            amount,
            label: Object.keys(item)[0],
          };
        });
      } else {
        const othersCategories = sorted?.slice(5, sorted?.length + 1);
        const othersTotal = geTotalOtherCategories(othersCategories);

        maxCategoryValue = Math.max(maxCategoryValue, othersTotal);

        let top5Categories = sorted?.slice(0, 5).map((item, index) => {
          let amount = parseInt(Object.values(item));
          return {
            angle: amount,
            radius: (amount / maxCategoryValue) * MAX_RADIUS,
            color: COLORS[index],
            amount,
            label: Object.keys(item)[0],
          };
        });

        let summaryObj = [
          ...top5Categories,
          {
            angle: othersTotal,
            radius: (othersTotal / maxCategoryValue) * MAX_RADIUS,
            color: COLORS.at(-1),
            amount: othersTotal,
            label: "Others",
          },
        ];
        formattedSummary = summaryObj;
      }

      setDisplayData({
        card: currentCard?.otz_card_id,
        newArr,
        aggregratedData,
        totalAmount,
        transactionSummary,
        maxCategory,
        formattedSummary,
      });

      console.log("Display Data >>>> ", displayData);
    }
  }, [
    // CHECK
    showAllCards,
    displayData?.card,
    allCardTrns,
    allCardTrns?.data?.length,
    currentCard?.otz_card_id,
    currentCardTrns?.data?.length,
    currentCardTrns?.data,
    currentCardTrns?.message,
    activeSlide,
    refetchCurrentCardTrns,
  ]); */
  useEffect(() => {
    const categoryList = [];
    const spendCalculation = {};
    let totalAmount = 0;

    cardwiseTrans.forEach((el) => {
      if (!categoryList.includes(el?.enhanced_transaction)) {
        categoryList.push(el?.enhanced_transaction);
        spendCalculation[el?.enhanced_transaction] = el.amount;
      } else {
        spendCalculation[el?.enhanced_transaction] += el.amount;
      }
      totalAmount += el.amount;
    });

    const formattedSummary = Object.keys(spendCalculation).map((el, index) => {
      return {
        angle: parseInt((spendCalculation[el] * 10000) / totalAmount),
        radius: 10,
        label: el || "Other",
        color: COLORS[index],
        amount: spendCalculation[el],
      };
    });

    setDisplayData({ formattedSummary, totalAmount });
  }, [cardwiseTrans]);

  /* function setParams(value) {
    const { cardId, period, periodCount, ...rest } = queryParams;

    value === "all"
      ? setQueryParams(
          showAllCards
            ? { ...rest }
            : {
                ...rest,
                cardId: currentCard?.otz_card_id,
              }
        )
      : value === "week"
      ? setQueryParams(
          showAllCards
            ? {
                ...rest,
                period: "week",
                periodCount: 1,
              }
            : {
                ...rest,
                period: "week",
                periodCount: 1,
                cardId: currentCard?.otz_card_id,
              }
        )
      : setQueryParams(
          showAllCards
            ? {
                ...rest,
                period: "month",
                periodCount: 1,
              }
            : {
                ...rest,
                period: "month",
                periodCount: 1,
                cardId: currentCard?.id,
              }
        );
  } */

  const [accountActivityVisible, setAccountActivityVisible] =
  useState(false);
  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };

  //#endregion
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Manage Cards" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.layoutLeft}>
              <Spin spinning={isLoading} style={{ marginTop: 180 }}>
                {!isLoading ? (
                  Boolean(allCards) && Boolean(currentCard) ? (
                    <Carousel
                      currentCard={currentCard}
                      cards={allCards}
                      // account={accountPayload}
                      activeSlide={activeSlide}
                      setActiveSlide={setActiveSlide}
                      classNames={classNames}
                      setSetSpendLimitModalVisibility={
                        setSetSpendLimitModalVisibility
                      }
                      accountType={accountType}
                      // accountPayload={accountPayload}
                      // customerBearerToken={customerBearerToken}
                      // createCustomerBearerToken={createCustomerBearerToken}
                      // verificationToken={verificationToken}
                      // verficationTokenMutation={verficationTokenMutation}
                      scriptLoaded={scriptLoaded}
                      // setShowDisplayOTP={setShowDisplayOTP}
                      // showDisplayOTP={showDisplayOTP}
                      customerDetail={application}
                      hideCardDetail={hideCardDetail}
                    />
                  ) : (
                    <Empty
                      className={classNames.noCardsContainer}
                      image={noCards}
                      imageStyle={{
                        height: 250,
                      }}
                      description={
                        <div className={classNames.textContainer}>
                          <div>No cards available</div>
                          <span className={classNames.bottomRow}>
                            {" "}
                            Seems like you don’t have any cards yet,
                          </span>
                        </div>
                      }
                    >
                      <Button
                        onClick={() => {
                          setHideCardDetail(true);
                          // setRequestNewCardModalVisibility(true);
                          setAccountActivityVisible(true);
                        }}
                        type="primary"
                      >
                        Request a new card
                      </Button>
                    </Empty>
                  )
                ) : null}
              </Spin>

              <Card className={classNames.tableCard}>
                {/* <Spin spinning={isLoading} style={{ marginTop: 550 }}> */}
                {isLoading ? null : (
                  <>
                    <div className={classNames.tableCardHeader}>
                      <div>
                        <Title as="h3">Category wise spend of All Cards</Title>
                        <div className={classNames.tableCardFilters}>
                          {/* <Radio.Group defaultValue="all" buttonStyle="solid">
                          <Space size={0}>
                            <Radio.Button
                              value="all"
                              onClick={(e) => setParams(e.target.value)}
                            >
                              All
                            </Radio.Button>
                            <Radio.Button
                              value="week"
                              onClick={(e) => setParams(e.target.value)}
                            >
                              W
                            </Radio.Button>
                            <Radio.Button
                              value="month"
                              onClick={(e) => setParams(e.target.value)}
                            >
                              M
                            </Radio.Button>
                          </Space>
                        </Radio.Group> */}
                        </div>
                      </div>

                      {/* {allCards?.length > 0 && (
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            const { cardId, ...rest } = queryParams;
                            setQueryParams({ ...rest });
                          }
                          setShowAllCards(e.target.checked);
                        }}
                      >
                        Show spending for all cards
                      </Checkbox>
                    )} */}
                    </div>
                    <div className={classNames.tableCardBody}>
                      {/* <Spin spinning={loadingCardData} size="large"> */}
                      {
                        displayData &&
                        displayData?.formattedSummary?.length === 0 ? (
                          <Empty
                            image={noDataCategoryWiseSpend}
                            imageStyle={{
                              height: 110,
                            }}
                            description={false}
                          />
                        ) : /* !displayData?.formattedSummary ? (
                        !currentCardTrns?.message && !!displayData ? ( */
                        displayData?.formattedSummary?.length > 0 ? (
                          <CategoryWiseSpend displayData={displayData} />
                        ) : (
                          <div
                            className={classNames.categoryWiseSpendEmptyWrapper}
                          >
                            <div>
                              <Empty
                                description={
                                  <span style={{ color: "#9ba3ab" }}>
                                    No Transactions Made
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        )
                        // ) : (
                        //   <div
                        //     className={classNames.categoryWiseSpendEmptyWrapper}
                        //   >
                        //     <div>
                        //       <Empty
                        //         description={
                        //           <span style={{ color: "#9ba3ab" }}>
                        //             No Transactions Made
                        //           </span>
                        //         }
                        //       />
                        //     </div>
                        //   </div>
                        // )
                        // ) : !allCardTrns?.message && !!displayData ? (
                        //   <CategoryWiseSpend displayData={displayData} />
                        // ) : (
                        //   <div
                        //     className={classNames.categoryWiseSpendEmptyWrapper}
                        //   >
                        //     <div>
                        //       <Empty
                        //         description={
                        //           <span style={{ color: "#9ba3ab" }}>
                        //             No Transactions Made
                        //           </span>
                        //         }
                        //       />
                        //     </div>
                        //   </div>
                        // )
                      }
                      {/* </Spin> */}
                    </div>
                  </>
                )}
                {/* </Spin> */}
              </Card>
            </div>
          </div>

          <div className={classNames.layoutRight}>
            <Card
              className={classNames.optionCardCTA}
              onClick={() => {
                setHideCardDetail(true);

                // Account Activity details
                console.log(isAccountInfoNeeded);
                setAccountActivityVisible(true);
                // if(isAccountInfoNeeded) {
                // } else {
                //   setRequestNewCardModalVisibility(true);
                // }
              }}
            >
              <div className={classNames.cardAction}>
                <span>Request a New Card</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            {/* <Card
              className={classNames.optionCard}
              onClick={(e) => {
                setSetSpendLimitModalVisibility(true);
              }}
              disabled={
                currentCard?.card_status === "Stolen" ||
                currentCard?.card_status === "ClosedByCustomer" ||
                currentCard?.card_status === "Frozen" ||
                currentCard?.card_status === "SuspectedFraud" ||
                !(Boolean(allCards) && Boolean(currentCard))
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <MoneyDollorCircleLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>Set spend limit</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card> */}
            <Card
              className={classNames.optionCard}
              onClick={() => {
                setHideCardDetail(true);
                setSetNotificationsModalVisibility(true);
              }}
              // disabled={
              //   currentCard?.card_status === "Stolen" ||
              //   currentCard?.card_status === "ClosedByCustomer" ||
              //   currentCard?.card_status === "Frozen" ||
              //   currentCard?.card_status === "SuspectedFraud" ||
              //   !(Boolean(allCards) && Boolean(currentCard))
              // }

              //--------TEMP----------
              disabled={true}
              style={{ display: "none" }}
            >
              <div className={classNames.cardAction}>
                <div>
                  <Notification3LineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>Set Notifications</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>

            <Card
              className={classNames.optionCard}
              onClick={() => {
                setHideCardDetail(true);
                cardStatus === "Set New Pin"
                  ? setSetNewPinModalVisibility(true)
                  : cardStatus === "Change Pin"
                  ? setChangePinModalVisibility(true)
                  : setActivateCardModalVisibility(true);

                // !customerBearerToken && verficationTokenMutation.mutate();
              }}
              disabled={
                currentCard?.card_status === "TERMINATED" ||
                currentCard?.card_status === "SUSPENDED" ||
                !(Boolean(allCards) && Boolean(currentCard))
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <LockPasswordLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>
                    {/* isLoading ? <Spin spinning={true} /> :  */ cardStatus}
                  </span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            <Card
              className={classNames.optionCard}
              onClick={(e) => {
                setHideCardDetail(true);
                setReplaceCardModalVisibility(true);
              }}
              disabled={
                currentCard?.card_status === "TERMINATED" ||
                currentCard?.card_status === "SUSPENDED" ||
                (currentCard?.form === "VIRTUAL" && !currentCard?.isExpReq) ||
                !(Boolean(allCards) && Boolean(currentCard)) ||
                (currentCard?.reissued_to_id &&
                  currentCard?.card_status === "ACTIVE")
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <ExchangeLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>Replace Card</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            <Card
              className={classNames.optionCard}
              onClick={() => {
                setHideCardDetail(true);
                setCloseCardModalVisibility(true);
              }}
              disabled={
                currentCard?.card_status === "TERMINATED" ||
                currentCard?.card_status === "SUSPENDED" ||
                !(Boolean(allCards) && Boolean(currentCard))
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <CloseCircleLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>Close Card</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            <Card
              className={classNames.optionCard}
              disabled={
                currentCard?.card_status === "TERMINATED" ||
                currentCard?.card_status === "UNACTIVATED" ||
                !(Boolean(allCards) && Boolean(currentCard))
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <Lock2LineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  {currentCard?.card_status === "SUSPENDED" ? (
                    <span>Unfreeze Card</span>
                  ) : (
                    <span>Freeze Card</span>
                  )}
                </div>
                <Switch
                  onChange={(checked) => {
                    if (checked) {
                      setFreezeCardModalVisibility(true);
                    } else {
                      setUnfreezeCardModalVisibility(true);
                    }
                    setHideCardDetail(true);
                  }}
                  checked={
                    currentCard?.card_status === "SUSPENDED" ? true : false
                  }
                  defaultChecked={
                    currentCard?.card_status === "SUSPENDED" ? true : false
                  }
                />
              </div>
            </Card>
            <Card
              className={classNames.stolenCard}
              onClick={() => {
                setHideCardDetail(true);
                setReportStolenModalVisibility(true);
              }}
              disabled={
                currentCard?.card_status === "TERMINATED" ||
                currentCard?.card_status === "SUSPENDED" ||
                currentCard?.form === "VIRTUAL" ||
                !(Boolean(allCards) && Boolean(currentCard)) ||
                (currentCard?.reissued_to_id &&
                  currentCard?.card_status === "ACTIVE")
              }
            >
              <div className={classNames.cardAction}>
                <div>
                  <GhostLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionIconBackdropLight} />
                  <span>Report Stolen</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            {
              //#endregion
            }
          </div>
        </div>
      </div>
      {
        //#region
      }
      <ActivateCard
        currentCard={currentCard}
        visible={scriptLoaded && activateCardModalVisibility}
        onCancel={handleActivateCardModalCancel}
        title="Activate Card"
      />
      <ActivateCard
        visible={
          scriptLoaded && (setNewPinModalVisibility || changePinModalVisibility)
        }
        onCancel={
          setNewPinModalVisibility
            ? handleSetNewPinModalCancelClick
            : handleChangePinModalCancel
        }
        currentCard={currentCard}
        isPin={true}
        title={setNewPinModalVisibility ? "Set Pin" : "Change PIN"}
      />
      <UnfreezeCard
        visible={unfreezeCardModalVisibility}
        onCancel={handleUnfreezeCardModalVisibility}
        currentCard={currentCard}
      />
      <FreezeCard
        visible={freezeCardModalVisibility}
        onCancel={handleFreezeCardModalVisibility}
        currentCard={currentCard}
      />
      <SetSpendLimit
        visible={setSpendLimitModalVisibility}
        onCancel={handleSetSpendLimitModalCancelClick}
        currentCard={currentCard}
      />
      <ReplaceCard
        visible={replaceCardModalVisibility}
        onCancel={handleReplaceCardModalCancelClick}
        currentCard={currentCard}
      />
      <ReportStolen
        visible={reportStolenModalVisibility}
        onCancel={handleReportStolenModalCancelClick}
        currentCard={currentCard}
        cardsRefetch={cardsRefetch}
      />
      <RequestNewCard
        visible={requestNewCardModalVisibility}
        onCancel={handleRequestNewCardModalCancelClick}
        accountType={accountType}
        allowedPhysicalCard={allowedPhysicalCard}
        allowedVirtualCard={allowedVirtualCard}
        isAllowPhysicalCard={Number(allowedPhysicalCard) > Number(physicalCard)}
        isAllowVirtualCard={Number(allowedVirtualCard) > Number(virtualCard)}
        key={physicalCard || virtualCard}
      />
      <SetNotifications
        visible={setNotificationsModalVisibility}
        onCancel={handleSetNotificationsModalCancelClick}
      />
      <CloseCard
        visible={closeCardModalVisibility}
        onCancel={handleCloseCardModalCancelClick}
        currentCard={currentCard}
      />
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setRequestNewCardModalVisibility}
        spawnModal={setAccountActivityVisible}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        title="Debit Card"
      />
      {
        //#endregion
      }
    </div>
  );
}
