import classNames from "../Reports.module.scss";
import {
  Modal,
  CTAButton,
  Select,
  DatePicker,
  Input,
} from "../../../../common";
import { Form, Button } from "antd";
import {
  RiAddFill,
  RiEyeLine,
  RiPrinterLine,
  RiDownloadCloud2Line,
} from "react-icons/ri";
import { FORMATS } from "../../../../constants";

export default function EditReport({ visible, onCancel }) {
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Edit Report"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <Form layout="vertical" hideRequiredMark autoComplete="off">
        <Form.Item
          label={<span className={classNames.label}>Select Date</span>}
        >
          <DatePicker
            placeholder={FORMATS.API_OTHER_FORMAT}
            style={{ height: 44 }}
          />
        </Form.Item>

        <Form.Item
          label={<span className={classNames.label}>View/ Sort by</span>}
        >
          <Select size="small" placeholder="Please select"></Select>
        </Form.Item>

        <Form.Item
          label={<span className={classNames.label}>Transaction Type</span>}
        >
          <Select size="small" placeholder="Please select"></Select>
        </Form.Item>

        <Form.Item
          label={<span className={classNames.label}>Transaction Status</span>}
        >
          <Select size="small" placeholder="Please select"></Select>
        </Form.Item>

        <Form.Item className={classNames.addFilters}>
          <Input
            size="small"
            placeholder="Please enter"
            className={classNames.filterInput}
            prefix={
              <Form.Item>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Amount"
                  className={classNames.inputPrefix}
                ></Select>
              </Form.Item>
            }
          />
          <Button type="link" className={classNames.linkButton}>
            <RiAddFill />
            Add another filter
          </Button>
        </Form.Item>

        <CTAButton type="primary" style={{ height: 44, marginBottom: 16 }}>
          Save Report
        </CTAButton>
        <CTAButton type="secondary" style={{ height: 44 }}>
          Save as New
        </CTAButton>

        <div className={classNames.reportOptions}>
          <Button type="link" className={classNames.linkButton}>
            <RiEyeLine />
            View
          </Button>
          <Button type="link" className={classNames.linkButton}>
            <RiPrinterLine />
            Print
          </Button>
          <Button type="link" className={classNames.linkButton}>
            <RiDownloadCloud2Line />
            Download
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
