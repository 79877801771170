import classNames from "../Customers.module.scss";
import { Modal } from "../../../../common";
import AddNewCustomerForm from "./AddNewCustomerForm";
import { Form } from "antd";
export default function AddNewCustomer({ visible, onCancel, setAddCustomersCardModalVisibility }) {
  const [form] = Form.useForm();
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        onCancel()
        form.resetFields();
      }}
      onCancel={onCancel}
      title="Add New Customer"
      className={classNames.modal}
      bodyStyle={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      }}
      width={434}
    >
      <AddNewCustomerForm setAddCustomersCardModalVisibility={setAddCustomersCardModalVisibility} form={form}/>
    </Modal>
  );
}
