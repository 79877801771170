import { useContext } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import classNames from "../../ManageCards.module.scss";
import { CTAButton, Input, Select } from "../../../../common";
import messages from "../../../config/messages.const";
import { validationCards } from "../../../config/validation.messages";
import useLanguage from "../../../../../hooks/useLanguage";
import { AppContext } from "../../../../../context/AppState";

export default function Basic({
  onSuccess,
  onBack,
  form,
  cardData,
  createNewCardMutation,
  userData,
}) {
  const language = useLanguage(messages);
  const validation = useLanguage(validationCards);
  const { application } = useContext(AppContext);

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      autoComplete="off"
      scrollToFirstError={true}
    >
      {application?.type === "PERSON" ? (
        <>
          <Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.FIRST_NAME} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              name={["firstName"]}
            >
              <Input
                placeholder={language.FIRST_NAME}
                size="small"
                defaultValue={userData?.first_name}
                readOnly={true}
              />
            </Form.Item>

            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.LAST_NAME}
                  <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              name={["lastName"]}
            >
              <Input
                placeholder={language.LAST_NAME}
                size="small"
                defaultValue={userData?.last_name}
                readOnly={true}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.PHONE_NUMBER} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name={["phoneNumber"]}
            >
              <Input
                size="small"
                placeholder={language.PLEASE_ENTER}
                prefix="+1"
                defaultValue={userData?.phone_number.slice(2)}
                readOnly={true}
              />
            </Form.Item>
            <Form.Item hidden name={["phone", "countryCode"]} initialValue="1">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.EMAIL_ADDRESS} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name={["email"]}
            >
              <Input
                placeholder={validation.email.placeholder}
                size="small"
                defaultValue={userData?.email}
                readOnly={true}
              />
            </Form.Item>

            <Form.Item
              initialValue={cardData?.emboss_name}
              label={
                <span className={classNames.label}>
                  {language.NAME_ON_CARD}
                  <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: validation.emboss_name.required,
                },
                { max: 21, message: validation.emboss_name.max },
              ]}
              name={["emboss_name"]}
            >
              <Input placeholder={language.NAME_ON_CARD} size="small" />
            </Form.Item>
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.SELECT_PERSON}
                  <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name={["personId"]}
            >
              <Select
                size="small"
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={validation.person.placeholder}
                onChange={(person) => {
                  const personDetail = userData.filter(
                    (ele) => ele.otz_person_id === person
                  );
                  form.setFieldsValue({
                    firstName: personDetail[0].first_name,
                    lastName: personDetail[0].last_name,
                    phoneNumber: personDetail[0].phone_number?.slice(2),
                    email: personDetail[0].email,
                  });
                }}
              >
                {userData?.map((value) => (
                  <Select.Option
                    key={value?.otz_person_id}
                    value={value?.otz_person_id}
                  >
                    {`${value?.first_name} ${value?.last_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.FIRST_NAME} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              name={["firstName"]}
            >
              <Input
                placeholder={language.FIRST_NAME}
                size="small"
                readOnly={true}
              />
            </Form.Item>

            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.LAST_NAME}
                  <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              name={["lastName"]}
            >
              <Input
                placeholder={language.LAST_NAME}
                size="small"
                readOnly={true}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.PHONE_NUMBER} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name={["phoneNumber"]}
            >
              <Input
                size="small"
                placeholder={language.PLEASE_ENTER}
                prefix="+1"
                readOnly={true}
              />
            </Form.Item>
            <Form.Item hidden name={["phone", "countryCode"]} initialValue="1">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  {language.EMAIL_ADDRESS} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name={["email"]}
            >
              <Input
                placeholder={validation.email.placeholder}
                size="small"
                readOnly={true}
              />
            </Form.Item>

            <Form.Item
              initialValue={cardData?.emboss_name}
              label={
                <span className={classNames.label}>
                  {language.NAME_ON_CARD}
                  <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: validation.emboss_name.required,
                },
                { max: 21, message: validation.emboss_name.max },
              ]}
              name={["emboss_name"]}
            >
              <Input placeholder={language.NAME_ON_CARD} size="small" />
            </Form.Item>
          </Form.Item>
        </>
      )}

      <Form.Item
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "10px 0 0 0px",
          float: "left",
        }}
      >
        <CTAButton
          type="outline"
          style={{ height: 44 }}
          onClick={() => onBack()}
        >
          {language.BACK}
        </CTAButton>
      </Form.Item>
      <Form.Item
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "10px 0 0 0px",
          float: "right",
        }}
      >
        {cardData?.type === "VIRTUAL" ? (
          <CTAButton
            type="primary"
            style={{ height: 44 }}
            loading={createNewCardMutation.isLoading}
            onClick={() =>
              form.validateFields().then((data) => {
                onSuccess(data);
              })
            }
          >
            Submit
          </CTAButton>
        ) : (
          <CTAButton
            type="primary"
            style={{ height: 44 }}
            onClick={() =>
              form.validateFields().then((data) => onSuccess(data))
            }
          >
            {language.NEXT}
          </CTAButton>
        )}
      </Form.Item>
    </Form>
  );
}

Basic.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  cardData: PropTypes.object.isRequired,
};
