import { Auth } from 'aws-amplify';
import endpoints from "../endpoints";

export default async function uploadRegDocsOnboardingAPI(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    return await (
        await fetch(endpoints.ONBOARDING.UPLOAD_REG_DOCUMENTS[env], {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`
            },
            body: data,
        })
    ).json();
}
