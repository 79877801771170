import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { Form, Button, Input as AntInput, Radio, Space } from "antd";
import {
  CTAButton,
  Input,
  Select,
  DatePicker,
  Message,
} from "../../../../common";
import moment from "moment";
import AddNewPayee from "../../Payees/AddNewPayee/AddNewPayee";
import { AppContext } from "../../../../../context/AppState";
import useLanguage from "../../../../../hooks/useLanguage";
import api from "../../../../API";
import {
  centsToDollar,
  dollarToCents,
} from "../../../../util/functions/dollarConvertion";
import { checkIsAmount } from "../../../../util/functions/checkIsAmount";
import generateErrorMessage from "../../../../util/functions/customError";
import { isBusinessType } from "../../../../util/functions/onboarding";
import messages from "../../../config/messages.const";
import { validationPayee } from "../../../config";
import REGEX from "../../../../util/constants/regex";
import { MAX_MIN } from "../../../../util/constants";
import { DEFAULT_AMOUNT, FORMATS } from "../../../../constants";
import classNames from "../../MakePayments.module.scss";

export default function NewPaymentForm({
  payees,
  accountBalance,
  onCancel,
  queryClient,
  refetch,
}) {
  //Hooks
  const [form] = Form.useForm();
  const { TextArea } = AntInput;

  const [amount, setAmount] = useState(0.00);
  const [payee, setPayee] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(true);
  const [startDate, setStartDate] = useState();
  const { application } = useContext(AppContext);
  const language = useLanguage(messages);

  //Api calls
  const {
    mutate: createRecurringPayment,
    isLoading: createRecurringPaymentLoading,
  } = useMutation(api.BusinessBanking.createRecurringPayment);

  //Event handlers
  function onFinish(values) {
    const reqBody = {
      is_recurring: 1,
      description: values?.description,
      amount: dollarToCents(values.amount),
      frequency: values?.interval || "DAILY",
      start_date: moment(values?.startDate).format(FORMATS.API_SEND_FORMAT),
      end_date: moment(values?.endDate).format(FORMATS.API_SEND_FORMAT),
      otz_payee_id: payee?.otz_payee_id,
      ...(isBusinessType(application?.type)
        ? { otz_business_id: application?.otz_business_id }
        : {
            otz_person_id: application?.otz_person_id,
          }),
    };
    handleCreateRequest(reqBody);
  }

  const handleModalCancelClick = () => {
    setModalVisibility(false);
  };

  const handleCreateRequest = (reqBody) => {
    createRecurringPayment(reqBody, {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.NEW_RECURRING_PAYMENT_CREATED,
        });
        queryClient.invalidateQueries("getStatementDetail");
        onCancel();
        refetch();
        form.resetFields();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  function disabledDateEnd(current) {
    return current && moment(current).add(-1, "days") < moment(startDate);
  }

  function disabledDateBefore(current) {
    return current && current <= moment().subtract(0, "days");
  }

  return (
    <div className={classNames.modal}>
      <div className={classNames.modalLeft}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="payee"
            label={
              <span className={classNames.label}>
                Payee <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: validationPayee.quickFundTransfer.payee,
              },
            ]}
          >
            <Select
              name="payee"
              size="small"
              placeholder={validationPayee.quickFundTransfer.payeePlaceholder}
              onSelect={(e) =>
                setPayee(payees?.find((entry) => entry?.otz_payee_id === e))
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {payees &&
                payees.length > 0 &&
                payees.map((value) => (
                  <Select.Option value={value?.otz_payee_id}>
                    {value?.account_owner_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="dashed"
              style={{ marginTop: 16, height: 44, width: "100%" }}
              onClick={() => setModalVisibility(true)}
            >
              Add new payee
            </Button>
          </Form.Item>

          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value > accountBalance / 100) {
                    callback(
                      validationPayee.quickFundTransfer.insufficientFund
                    );
                  }
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Available Balance:{" "}
                <span style={{ color: "#000" }}>
                  {!Number(accountBalance)
                    ? DEFAULT_AMOUNT
                    : centsToDollar(accountBalance)}
                </span>
              </span>
            }
          >
            <Input
              onChange={(e) => setAmount(e.target.value)}
              placeholder={validationPayee.quickFundTransfer.amountPlaceholder}
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              maxlength="11"
              align="left"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <span className={classNames.label}>
                Description<sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.description,
              },
              {
                max: MAX_MIN.description.max,
                message: validationPayee.quickFundTransfer.descriptionMax,
              },
            ]}
          >
            <TextArea
              autoSize
              placeholder={
                validationPayee.quickFundTransfer.descriptionPlaceholder
              }
              style={{ height: 44 }}
            ></TextArea>
          </Form.Item>
          <Form.Item name="recurring">
            Recurring Payment Settings
            <div
              style={{
                backgroundColor: "#F6F7FA",
                borderRadius: 8,
                padding: "16px 6px 6px 16px",
                marginTop: "16px",
              }}
            >
              <Form.Item
                name="interval"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    message: validationPayee.quickFundTransfer.period,
                  },
                ]}
              >
                <Radio.Group
                  name="radiogroup"
                  defaultValue={"DAILY"}
                  onChange={() => form.setFieldsValue({ endDate: null })}
                >
                  <Space size={0}>
                    <Radio value="DAILY">Daily</Radio>
                    <Radio value="WEEKLY">Weekly</Radio>
                    <Radio value="MONTHLY">Monthly</Radio>
                    <Radio value="QUARTERLY">Quarterly</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="startDate"
                  style={{
                    display: "inline-block",
                    marginRight: 14,
                  }}
                  rules={[
                    {
                      type: "object",
                      required: true,
                      whitespace: true,
                      message: validationPayee.quickFundTransfer.startDate,
                    },
                  ]}
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      Payment Date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker
                    onChange={(value) => {
                      setStartDate(value);
                      form.resetFields(["endDate"]);
                      value
                        ? setDisableEndDate(false)
                        : setDisableEndDate(true);
                    }}
                    disabledDate={disabledDateBefore}
                    style={{ height: 44 }}
                  />
                </Form.Item>

                <Form.Item
                  name="endDate"
                  style={{
                    display: "inline-block",
                  }}
                  rules={[
                    {
                      type: "object",
                      required: true,
                      whitespace: true,
                      message: validationPayee.quickFundTransfer.endDate,
                    },
                  ]}
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      End date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker
                    disabled={disableEndDate}
                    disabledDate={disabledDateEnd}
                    style={{ height: 44 }}
                  />
                </Form.Item>
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <div>Transaction Summary</div>
        <div className={classNames.transactionCard}>
          <div className={classNames.payee}>
            {payees &&
              Array.isArray(payees) &&
              payees.length > 0 &&
              payees
                .filter((value) => value?.otz_payee_id === payee?.otz_payee_id)
                .map((value) => (
                  <div>
                    <div className={classNames.payeeDetails}>
                      {value && value?.account_owner_name}
                      {value?.account_number_masked && (
                        <span>
                          Account Number :{" "}
                          {value && value?.account_number_masked}
                        </span>
                      )}
                      {value?.routing_no && (
                        <span>Routing Number : {value?.routing_no}</span>
                      )}
                    </div>
                    {/* <span>{value?.defaultPaymentMethod}</span> */}
                  </div>
                ))}
          </div>
          <div className={classNames.transaction}>
            Amount<span>$ {amount ? checkIsAmount(amount) : "0.00"}</span>
          </div>
          <div className={classNames.transaction}>
            Transaction Fee<span>{DEFAULT_AMOUNT}</span>
          </div>
          <div className={classNames.total}>
            Total<span>$ {checkIsAmount(amount)}</span>
          </div>
          {form.getFieldValue("payee") && startDate && (
            <div className={classNames.summaryText}>
              {payees?.find(
                (item) => item.otz_payee_id === form.getFieldValue("payee")
              )?.account_owner_name + " "}
              will receive $ {checkIsAmount(amount)} on <br />{" "}
              {moment(startDate).format("ll")}.
            </div>
          )}
        </div>
        <CTAButton
          loading={createRecurringPaymentLoading.isLoading}
          onClick={form.submit}
          type="primary"
          style={{ height: 44, width: 248 }}
          disabled={+accountBalance <= 0}
        >
          Save
        </CTAButton>
        {+accountBalance <= 0 && (
          <p style={{ color: "#ff4d4f", width: 248, marginTop: 10 }}>
            Your account balance is USD 0.00, Please add funds to your account.
          </p>
        )}
      </div>
      <AddNewPayee
        visible={modalVisibility}
        onCancel={handleModalCancelClick}
      />
    </div>
  );
}
