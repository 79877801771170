// import endpoints from "../../endpoints";
import { API } from "aws-amplify";

export default async function getOverallInvoiceDetails(id) {
  if (id) {
    return await API.get("invoices", "invoice-summary", {
      queryStringParameters: {
        companyId: id,
      },
    });
  }
  // let env = process.env.REACT_APP_ENV?.trim();
  // return await (
  //   await fetch(`${endpoints.RECEIVABLES.INVOICES.GET_OVERALL_INVOICES_DETAILS[env]}invoice-summary/?companyId=${id}`)
  // ).json();
}
