import classNames from "../SettingsHome.module.scss";
import { Card } from "antd";
import { RiArrowRightLine, RiArrowDownSLine } from "react-icons/ri";

export default function SettingsTopicCard({
  topic,
  icon,
  onClick,
  dropdown = true,
  comingSoon = false
}) {
  return (
    <Card className={`${classNames.supportTopicCard} ${comingSoon && classNames.supportTopicCardDisabled}`} onClick={onClick}>
      {icon}
      <div className={classNames.topicIconBackdrop} />
      <div className={classNames.topicAction}>
        <span>{topic}</span>
        {comingSoon && <span className={classNames.comingSoon}>coming soon</span>}
        {dropdown ? (
          <RiArrowDownSLine size={26} className={classNames.arrowBtnWithoutBg} />
        ) : (
          <RiArrowRightLine
            size={26}
            className={classNames.arrowBtnWithBgFixed}
          />
        )}
      </div>
    </Card>
  );
}
