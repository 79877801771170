import { useMutation, useQueryClient } from "react-query";
import { useState, useEffect, useContext } from "react";
import { Input, Header, Message } from "../../../common";
import { Card, Table, Empty, Button as AntButton, Modal, Form, Select, } from "antd";
import {
  RiSearch2Line,
  RiArrowRightLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AddNewTax from "./AddNewTax/AddNewTax";
import EditTax from "./EditTax/EditTax";
import api from "../../../API";
import { useQuery } from "react-query";
import { AppContext } from "../../../../context/AppState";
import DeleteTaxRate from "./DeleteTaxRate/DeleteTaxRate";
import generateErrorMessage from "../../../util/functions/customError";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";

import { isBusinessType } from "../../../util/functions/onboarding"; 
// import { validation } from "../../../Auth/config";
import { validationPayee } from "../../../BusinessBanking/config";
import { MAX_MIN } from "../../../util/constants"; 
import REGEX from "../../../util/constants/regex";

import classNames from "./VendorDatabase.module.scss";

export default function VendorDatabase() {
  const [pagination, setPagination] = useState(PAGINATION);
  const { application } = useContext(AppContext);

  const [keyword, setKeyword] = useState(null);
  const [selectedTax, /*setSelectedTax*/] = useState();
  const [, setType] = useState("");

  const [addTaxCardModalVisibility, setAddTaxCardModalVisibility] =
    useState(false);
  const [deleteTaxCardModalVisibility, setDeleteTaxCardModalVisibility] =
    useState(false);

  const handleAddTaxCardModalCancelClick = () => {
    setAddTaxCardModalVisibility(false);
  };
  const [editTaxCardModalVisibility, setEditTaxCardModalVisibility] = useState({
    visibility: null,
    record: null,
  });

  const handleEditTaxCardModalCancelClick = () => {
    setEditTaxCardModalVisibility({ visibility: false, record: null });
  };

  const handleDeleteTaxCardModalCancelClick = () => {
    setDeleteTaxCardModalVisibility(false);
  };

  const navigate = useNavigate();

  // Commenting due to future use - afterMVP
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  // State for "Add a New Vendor" modal visibility
  const [isAddVendorModalVisible, setIsAddVendorModalVisible] = useState(false);
  // Function to show the "Add a New Vendor" modal
  const showAddVendorModal = () => {
    setIsAddVendorModalVisible(true);
  };
  // Function to close the "Add a New Vendor" modal
  const handleAddVendorModalClose = () => {
    setIsAddVendorModalVisible(false);
  };
  // State to keep track of the number of filled fields
  // const [filledFieldsCount, setFilledFieldsCount] = useState(0);
  // Function to handle form value changes
  const handleFormValuesChange = (changedValues, allValues) => {
    // const filledCount = Object.values(allValues).filter(value => !!value).length;
    // setFilledFieldsCount(filledCount);
  };

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  //Api calls
  const { mutate, isLoading } = useMutation(api.BusinessBanking.createPayee, {
    onSuccess: () => {
      setIsAddVendorModalVisible(false);
      Message({ type: "success", content: "Vendor saved successfully" });
      queryClient.invalidateQueries("getPayees");
      form.resetFields();
    },
    onError: (error) => {
      if (error.response.data.message === "duplicate account") {
        Message({ type: "error", content: "Payee is already exist" });
      } else {
        generateErrorMessage(error);
      }
      form.resetFields();
    },
  });

  function handleSubmit(input) {
    input.customerType = input?.customerType ? input?.customerType : "personal";

    // Split the vendorName and handle cases where the last name might not be provided
    const nameParts = input?.vendorName.split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts[1] || "";

    const reqPayload = {
      ...(isBusinessType(application?.type)
        ? { otz_business_id: application?.otz_business_id }
        : { otz_person_id: application?.otz_person_id }),
      ...(input?.customerType === "personal" && {
        payee_first_name: firstName,
        payee_last_name: lastName,
      }),
      ...(input?.customerType === "business" && {
        payee_entity_name: input?.vendorName,
      }),
      email: input?.vendorEmail,
      number: input?.accountNumber,
      customer_type: input?.customerType || "personal",
      ach_routing_number: input?.routingNumber,
      bank_countries: ["US"],
      bank_name: input?.bank,
      type: input?.accountType || "CHECKING",
    };
    mutate({
      ...reqPayload,
    });
  }

  //Api calls
  const [refresh, setRefresh] = useState(false);
  const [formattedData, setFormattedData] = useState();
  const [totalNoOfRecords, setTotalNoOfRecords] = useState(0);

  const {
    loading,
    data: payeeList,
  } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(application?.type)
          ? { otz_business_id: application?.otz_business_id }
          : { otz_person_id: application?.otz_person_id }),
      }), //TODO: currently getting maximum records and planing to manage pagination from backend later
    {
      onSuccess: (data) => {
        setPagination(PAGINATION);
        setRefresh((refresh) => !refresh);
      },
      onError: (error) => generateErrorMessage(error),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (Boolean(payeeList?.payload)) {
      let filteredData = payeeList?.payload;
  
      if (keyword) {
        filteredData = filteredData.filter(payee =>
          payee?.account_owner_name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
  
      setFormattedData(
        filteredData.reverse().map(payee => {
          return {
            key: payee?.otz_payee_id,
            payeeName: payee?.account_owner_name,
            accountNumber: payee?.account_number_masked,
            accountType: payee?.account_type,
            routingNumber: payee?.routing_no,
            email: payee?.email,
          };
        })
      );
      setTotalNoOfRecords(filteredData.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeList, refresh, keyword]);
  
  useEffect(() => {
    setPagination(PAGINATION);
  }, [keyword]);

  const paginationText = `Displaying ${Math.min(pagination.page * pagination.pageSize, totalNoOfRecords)} of ${totalNoOfRecords} vendors`;

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "payeeName",
      key: "payeeName",
      width: "18%",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: "18%",
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
      width: "18%",
    },
    {
      title: "Routing Number",
      dataIndex: "routingNumber",
      key: "routingNumber",
      width: "18%",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      width: "18%",
    },

    // {
    //   title: "Profile Completeness",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "18%",
    //   render: (_, record) => (
    //     <Space
    //       size="middle"
    //       style={{
    //         display: "flex",
    //         justifyContent: "right",
    //         width: "80%",
    //       }}
    //     >
    //       <hr style={{borderColor: "green"}}></hr>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "10%",
    //   render: (_, record) => (
    //     <Space
    //       size="middle"
    //       style={{
    //         display: "flex",
    //         justifyContent: "right",
    //         width: "80%",
    //       }}
    //     >
    //       <Space size="middle">
    //         <RiEditLine
    //           size={14}
    //           color="#9BA3AB"
    //           className={classNames.actionIcon}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setIsAddVendorModalVisible(true)
    //           }}
    //         />
    //         {/* <RiDeleteBin7Line
    //           size={14}
    //           color="#9BA3AB"
    //           className={classNames.actionIcon}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setSelectedTax(record);
    //             setDeleteTaxCardModalVisibility(true);
    //           }}
    //         /> */}
    //       </Space>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Vendor Database" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Input
              placeholder="Search Vendor"
              prefix={<RiSearch2Line size={26} />}
              className={classNames.searchInvoice}
              onChange={(e) => {
                setKeyword(e.target.value);
                setPagination(PAGINATION);
              }}
            />

            <Card
              className={classNames.optionCard}
              onClick={showAddVendorModal}
            >
              <div className={classNames.cardAction}>
                <span>Add a New Vendor</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              {loading ? null : <span>{paginationText}</span>}
            </div>
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    // onClickTax(record);
                  },
                };
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      keyword && totalNoOfRecords === 0
                        ? "No Result found"
                        : "No Data Found"
                    }
                  />
                ),
              }}
              loading={loading}
              // commenting due to future use - afterMVP
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              // scroll={{ y: `var(--table-height)` }}
              size="large"
              dataSource={formattedData}
              columns={columns}
              className={classNames.table}
              rowClassName={classNames.actionIcon}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                total: totalNoOfRecords,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  }
                  if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
            />

            {/* Add a New Vendor Modal */}
            <Modal
              title="Add a New Vendor"
              visible={isAddVendorModalVisible}
              onCancel={handleAddVendorModalClose}
              footer={[
                <AntButton style={{width:"100%", height:"50px"}} key="submit" type="primary" onClick={() => form.submit()}  loading={isLoading}>
                  Save Vendor
                </AntButton>
              ]}
      
              width="30%"
            >
              {/* <p>Profile Completeness</p>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: "30px",
                      height: "8px",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      marginRight: "5px",
                      backgroundColor: index < filledFieldsCount ? "#1DBC83" : "transparent"
                    }}
                  ></div>
                ))}
              </div> */}
              <Form 
                form={form}
                onFinish={handleSubmit} 
                layout="vertical" 
                onValuesChange={handleFormValuesChange}
              >
                <Form.Item
                  name="customerType"
                  label={
                    <span>
                      Vendor Type <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  rules={[
                    { required: true, message: validationPayee.addPayee.accountType },
                  ]}
                >
                  <Select
                    placeholder={validationPayee.addPayee.dropdown}
                    style={{ width: "100%" }}
                    onChange={(value) => setType(value)}
                  >
                    <Select.Option style={{ height: "15%"}} value="personal">Personal</Select.Option>
                    <Select.Option style={{ height: "15%"}} value="business">Business</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Vendor Name *"
                  name="vendorName"
                  rules={[{ required: true, message: "Please input the vendor name!" }]}
                >
                  <Input placeholder="Vendor Name" />
                </Form.Item>
                <Form.Item label="Vendor Email Address" name="vendorEmail"  rules={[{ required: true, message: "Please input the vendor email!" }]}>
                  <Input placeholder="Vendor Email Address" />
                </Form.Item>
                {/* <Form.Item label="Vendor Address" name="vendorAddress">
                  <Input placeholder="Vendor Address" />
                </Form.Item>
                <Form.Item label="Vendor Phone Number" name="vendorPhone">
                  <Input placeholder="Vendor Phone Number" />
                </Form.Item> */}
                {/* <Form.Item label="Linked Counterparty" name="linkedCounterparty">
                  <Select placeholder="Select a counterparty">
                    <Select.Option value="counterparty1">Counterparty 1</Select.Option>
                    <Select.Option value="counterparty2">Counterparty 2</Select.Option>
                  </Select>
                </Form.Item> */}
                
                <Form.Item
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: validationPayee.addPayee.accountNumber,
                    },
                    {
                      max: MAX_MIN.accountNumber.max,
                      message: validationPayee.addPayee.accountMax,
                    },
                    {
                      pattern: new RegExp(REGEX.accountNumber),
                      message: validationPayee.addPayee.accountNumberValid,
                    },
                  ]}
                  type="number"
                  label={
                    <span>
                      Account Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.payeePlaceholder}
                    size="small"
                  ></Input>
                </Form.Item>
                
                <Form.Item
                  name="bank"
                  rules={[
                    { required: true, message: validationPayee.addPayee.bank },
                    {
                      max: MAX_MIN.bank.max,
                      message: validationPayee.addPayee.bankMax,
                    },
                    {
                      pattern: new RegExp(REGEX.bankName),
                      message: validationPayee.addPayee.validBankName,
                    },
                  ]}
                  type="text"
                  label={
                    <span>
                      Bank Name <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.bankNamePlaceholder}
                    size="small"
                  ></Input>
                </Form.Item>
                
                <Form.Item
                  name="accountType"
                  label={
                    <span>
                      Account Type <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  rules={[
                    { required: true, message: validationPayee.addPayee.accountType },
                  ]}
                >
                  <Select
                    placeholder={validationPayee.addPayee.dropdown}
                    style={{ width: "100%" }}
                  >
                    <Select.Option value="SAVINGS">Savings</Select.Option>
                    <Select.Option value="CHECKING">Checking</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: validationPayee.addPayee.routingNumber,
                    },
                    {
                      pattern: new RegExp(REGEX.einNumber),
                      message: validationPayee.addPayee.routingNumberValid,
                    },
                  ]}
                  name="routingNumber"
                  label={
                    <span>
                      Routing Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.payeePlaceholder}
                    size="small"
                  ></Input>
                </Form.Item>

              </Form>
            </Modal>


            <AddNewTax
              visible={addTaxCardModalVisibility}
              onCancel={handleAddTaxCardModalCancelClick}
            />
            <EditTax
              key={editTaxCardModalVisibility?.record?.key}
              visible={editTaxCardModalVisibility.visibility}
              onCancel={handleEditTaxCardModalCancelClick}
              tax={editTaxCardModalVisibility.record}
              setEditTaxCardModalVisibility={setEditTaxCardModalVisibility}
            />
            <DeleteTaxRate
              visible={deleteTaxCardModalVisibility}
              onCancel={handleDeleteTaxCardModalCancelClick}
              tax={selectedTax}
              setDeleteTaxCardModalVisibility={setDeleteTaxCardModalVisibility}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
