import classNames from "../ProductsServices.module.scss";
import { CTAButton, Title, Message } from "../../../../common";
import api from "../../../../API";
import { useMutation, useQueryClient } from "react-query";
import { Modal } from "antd";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function DeleteProduct({
  visible,
  onCancel,
  product,
  setDeletePorductModalVisibility,
}) {
  const queryClient = useQueryClient();
  const language = useLanguage(messages);
  function deleteProductFn(data) {
    return api.Receivables.Product.deleteProduct(data.id);
  }

  const deleteProductMn = useMutation((event) => deleteProductFn(event));

  const onDeleteClick = () => {
    deleteProductMn.mutate(product, {
      onSuccess: () => {
        Message({ type: "success", content: language.PRODUCT_DELETE_SUCCESS });
        queryClient.invalidateQueries("getProducts");
        setDeletePorductModalVisibility(false);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
      footer={null}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete product
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this product?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={onDeleteClick}
          loading={deleteProductMn.isLoading}
        >
          Yes, delete
        </CTAButton>
      </div>
    </Modal>
  );
}
