import { API } from "aws-amplify";
import ENDPOINT_KEYS from "../../../apiEndpoints/endpointKeys";

export default async function updateBusinessOperationDetails({otz_business_id, ...data}) {
  return await API.post(ENDPOINT_KEYS.updateBusinessOperationDetails, `metadata/${otz_business_id}`, {
    body: {
      metadata: data,
    },
  });
}
