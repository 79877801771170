import { Auth } from 'aws-amplify';
import endpoints from "../../../endpoints";

export default async function uploadAPInvoiceFile(data) {
  const env = process.env.REACT_APP_ENV?.trim();
  const formData = new FormData();

  formData.append('file', data.file);

  try {
    const response = await fetch(`${endpoints.PAYABLES.ACCOUNTS_PAYABLES.INVOICES[env]}${data.invoice_id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: formData,
    })

    if (response.status === 200) {
      const result = await response.json();
      return result;
    } else {
      throw new Error(`Failed to upload the file. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}
