const REGEX = {
  bankName: /^[a-zA-Z0-9.&(),'\-/\\ ]+$/i,
  name: /^[a-z ]+$/i,
  payeeName: /^[a-zA-Z0-9.&(),'\-/\\ ]+$/i,
  businessName: /^(?=(?:[^!@#$%^&*()_+={}[\]:;<>,?~]*[!@#$%^&*()_+={}[\]:;<>,?~]){0,3}[^!@#$%^&*()_+={}[\]:;<>,?~]*$)[a-z0-9,'&-.!@#$%^&*()_+={}[\]:;<>,?~ ]+$/i,
  phoneNumber: /^[0-9]{10}$/,
  accountNumber: /^\d{5,17}$/,
  postalCode: /^[0-9]{5}$/,
  einNumber: /^[0-9]{9}$/,
  percentage: /^(2[5-9]|[3-9]\d|100)$/,
  amount: /^\d{0,8}(\.\d{1,2})?$/,
  website:
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})?$/,
  password:
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
  invoice: /^[0-9]{6}$/,
  discount: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g,
  onlyNumbers: /^[0-9]*$/,
};
export default REGEX;
