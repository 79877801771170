import { useEffect, useState, useContext } from "react";
import { Form, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import moment from "moment";
import {
  CTAButton,
  Input,
  Title,
  DatePicker,
  CountryPicker,
  AddressForm,
} from "../../common";
import Heading from "../Heading";
import api from "../../API";
import Steps from "../Steps";
import AccountStatus from "../VerifyAccount/VerifyAccount";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AppContext } from "../../../context/AppState";
import { validation } from "../config";
import { FORMATS, ROUTES, ROUTE_STATUS, USER_STATES } from "../../constants";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import classNames from "../Signup/Signup.module.scss";

export default function IndividualAccount() {
  //Hooks
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [countrySelected, setCountrySelected] = useState(null);
  const { userData } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useLocalStorage(
    "OTTERZ_op_individualApplication"
  );

  useEffect(() => {
    setCountrySelected(progress?.country);
  }, [progress, form]);

  //Api calls
  const createIndiApplication = useMutation((data) => {
    return api.onBoarding.createApplication(data);
  });

  //Event handlers
  const handlerOnSuccess = () => {
    setVisible(false);
    navigate(
      `${ROUTES.ONBOARDING.THANK_YOU}${
        ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
      }`
    );
  };

  const handleOnFinish = () => {
    let inputData = form.getFieldsValue();

    setProgress((prevData) => {
      return {
        ...prevData,
        ...inputData,
        savedStatus: "individual-application",
      };
    });

    setVisible(true);
  };

  return (
    <>
      <div className={classNames.accountFormWrapper}>
        <Steps classNames={classNames} currentIndex={3} />
        <div className={classNames.formWrapper}>
          <Heading
            classNames={classNames}
            title="Sign up for an individual account"
            description="Fill in the form below and submit, we will review your details and get
          back to you"
          />
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            form={form}
            initialValues={{
              ...progress,
              dateOfBirth: progress?.dateOfBirth
                ? moment(progress?.dateOfBirth)
                : null,
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item
              name="ssnInput"
              label={
                <Title as="h4" className={classNames.label}>
                  Social Security Number (SSN) <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                {
                  required: true,
                  message: validation.ssn.required,
                },
                {
                  pattern: new RegExp(REGEX.einNumber),
                  message: validation.ssn.valid,
                },
              ]}
            >
              <Input placeholder={validation.ssn.placeholder} />
            </Form.Item>

            <Form.Item
              name="nationality"
              label={
                <Title as="h4" className={classNames.label}>
                  Nationality <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              rules={[
                { required: true, message: validation.nationality.required },
              ]}
            >
              <CountryPicker countries="all"/>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="first"
                label={
                  <Title as="h4" className={classNames.label}>
                    First Name <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  { required: true, message: validation.firstName.required },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.firstName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.firstName.valid,
                  },
                ]}
              >
                <Input placeholder={validation.firstName.placeholder} />
              </Form.Item>

              <Form.Item
                name="last"
                label={
                  <Title as="h4" className={classNames.label}>
                    Last Name <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: validation.lastName.required },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.lastName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.lastName.valid,
                  },
                ]}
              >
                <Input placeholder={validation.lastName.placeholder} />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="dateOfBirth"
                label={
                  <Title as="h4" className={classNames.label}>
                    Date of Birth <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  { required: true, message: validation.dateOfBirth.required },
                ]}
              >
                <DatePicker
                  disabledDate={(date) =>
                    !date ||
                    date?.isAfter(moment().format(FORMATS.DATEPICKER_FORMAT))
                  }
                  // defaultPickerValue={moment().subtract(18, "years")}
                  placeholder={validation.dateOfBirth.placeholder}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={
                <Title as="h4" className={classNames.label}>
                  Address <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
            >
              <AddressForm
                countrySelected={countrySelected}
                onCountryChange={setCountrySelected}
                validation={validation}
              />
            </Form.Item>

            <Form.Item
              name="number"
              label={
                <Title as="h4" className={classNames.label}>
                  Phone Number <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              // rules={[
              //   { required: true, message: validation.phoneNumber.required },
              //   {
              //     pattern: new RegExp(/^[0-9]{10}$/),
              //     message: validation.phoneNumber.valid,
              //   },
              // ]}
            >
              <Input
                placeholder={validation.phoneNumber.placeholder}
                prefix="+1"
                defaultValue={userData?.phone_number?.substring(2)}
                disabled
              />
            </Form.Item>
            <Form.Item hidden name="countryCode" initialValue="1">
              <Input type="hidden" />
            </Form.Item>

            <Form.Item
              name="email"
              label={
                <Title as="h4" className={classNames.label}>
                  Email Address <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              // rules={[
              //   { required: true, message: validation.email.required },
              //   {
              //     type: "email",
              //     message: validation.email.valid,
              //   },
              //   { max: 255, message: validation.email.maxLength },
              //   { min: 10, message: validation.email.minLength },
              // ]}
            >
              <Input
                placeholder={validation.email.placeholder}
                disabled
                defaultValue={userData?.email}
              />
            </Form.Item>
            {/* <Form.Item name="soleProprietorship" valuePropName="checked">
            <Checkbox
              checked={isSoleProprietor}
              defaultChecked={false}
              onChange={() => setIsSoleProprietor(!isSoleProprietor)}
            >
              <Title as="h4" className={classNames.label}>
                I’m the sole proprietor
              </Title>
            </Checkbox>
          </Form.Item>
          {isSoleProprietor === true ? (
            <>
              <Form.Item>
                <Form.Item
                  name="ein"
                  label={
                    <Title as="h4" className={classNames.label}>
                      Employer Identification Number{" "}
                      <sup style={{ top: 1 }}>*</sup>
                    </Title>
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(60% - 8px)",
                    marginBottom: "10px",
                    float: "left",
                  }}
                  rules={[
                    { required: true, message: validation.ein.required },
                    {
                      pattern: new RegExp(/^[0-9]{9}$/),
                      message: validation.ein.onlyNumbers,
                    },
                  ]}
                >
                  <Input placeholder="Enter EIN" />
                </Form.Item>
              </Form.Item>
              <Form.Item name="isSame" valuePropName="checked">
                <Checkbox checked={isSame} onChange={handleCheckboxChange}>
                  <Title as="h4" className={classNames.label}>
                    Business name is same as above
                  </Title>
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="dba"
                label={
                  <Title as="h4" className={classNames.label}>
                    Please specify if you are doing business under a different
                    name {<sup style={{ top: 1 }}>*</sup>}
                  </Title>
                }
                rules={[
                  {
                    required: true,
                    message: validation.businessName.required,
                  },
                  { max: 64, message: "Max 64 characters" },
                ]}
              >
                <Input placeholder="Please specify" />
              </Form.Item>
            </>
          ) : (
            ""
          )} */}

            <Form.Item className={classNames.links}>
              <span>
                By clicking 'Next' , I agree that I have read and accepted the
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    window.open("https://www.otterz.co/terms-of-service");
                  }}
                >
                  KYC disclosure
                </Button>
                of Otterz
              </span>
            </Form.Item>

            <Form.Item style={{ marginTop: 25 }}>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <CTAButton
                  htmlType="submit"
                  type="primary"
                  loading={createIndiApplication.isLoading}
                >
                  Next
                </CTAButton>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      <AccountStatus
        visible={visible}
        onCancel={() => setVisible(false)}
        userData={userData}
        inputData={form.getFieldsValue()}
        onSuccess={handlerOnSuccess}
        isPerson={true}
      />
    </>
  );
}
