import moment from "moment";

const columns = [
  {
    title: "Description",
    dataIndex: "payee",
    key: "payee",
    width: "25%",
    ellipsis: true,
    render: (name) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{name}</span>
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: "15%",
    render: (text, record) => {
      return <span>{moment(text).format()}</span>;
    },
  },
  {
    title: "Transfer Type",
    dataIndex: "paymentType",
    key: "paymentType",
    width: "15%",
    render: (text, record) => {
      return text && <span>{text}</span>;
    },
  },
  {
    title: "Credit",
    dataIndex: "credit",
    key: "credit",
    width: "15%",
    render: (text, record) => {
      return record?.credit && <span style={{ color: "#1DBC83" }}>{text}</span>;
    },
  },
  {
    title: "Debit",
    dataIndex: "debit",
    key: "debit",
    width: "15%",
    render: (text, record) => {
      return record?.debit && <span style={{ color: "#EE6969" }}>{text}</span>;
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    width: "15%",
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
];

export default columns;
