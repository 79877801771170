import { Message } from "../../common";
import PropTypes from "prop-types";
import commonMessages from "../constants";

export default function generateErrorMessage(
  error,
  customMessage = null,
  type = "error",
  language = "en"
) {
  const messageList = [];
  const errorData = error?.response?.data;
  if (!!errorData?.error) {
    // Invoicea error response
    if (!customMessage) {
      const errorList = errorData.error;

      if (typeof error === "object") {
        messageList.push({
          type,
          content: errorList?.message,
        });
      } else {
        for (let err in errorList) {
          messageList.push({
            type,
            content: errorList[err][0].toString(),
          });
        }
      }
    } else {
      messageList.push({
        type,
        content: customMessage,
      });
    }
  } else {
    if (!customMessage && typeof error === "object") {
      messageList.push({
        type,
        content:
          error?.response?.data?.message ||
          commonMessages[language].SOMETHING_WENT_WRONG,
      });
    } else if (customMessage) {
      messageList.push({
        type,
        content: customMessage,
      });
    } else {
      messageList.push({
        type,
        content: commonMessages[language].SOMETHING_WENT_WRONG,
      });
    }
  }

  return messageList.map((msg) => Message(msg));
}

generateErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customMessage: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
};
