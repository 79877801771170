import classNames from "./Refund.module.scss";
import { Modal } from "../../../../common";
import RefundForm from "./RefundForm";

export default function Refund({
  visible,
  onCancel,
  refNum,
  onTransactionClick,
  record,
  transaction,
  currentUserEmail,
}) {
  return (
    <Modal
      destroyOnClose={true}
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Refund"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <RefundForm
        currentUserEmail={currentUserEmail}
        record={record}
        refNum={refNum}
        transaction={transaction}
        onTransactionClick={onTransactionClick}
      />
    </Modal>
  );
}
