import classNames from "./TypeTag.module.scss";
import { Tag } from "antd";
import PropTypes from "prop-types";

// TODO: This implementation is suboptimal
// Implementation converts the original type to shortform and vice versa.
// Added a tech debt to optimize all the common components.
export default function TypeTag({ type }) {
  switch (type?.toLowerCase()) {
    case "wire":
      return <Tag className={`${classNames.tag} ${classNames.wire}`}>Wire</Tag>;
    case "ach":
    case "internal_transfer":
    case "achpayment":
      return <Tag className={`${classNames.tag} ${classNames.ach}`}>ACH</Tag>;
    case "card":
    case "external_card":
      return <Tag className={`${classNames.tag} ${classNames.card}`}>Card</Tag>;
    case "check":
      return (
        <Tag className={`${classNames.tag} ${classNames.check}`}>Check</Tag>
      );
    case "fee":
      return <Tag className={`${classNames.tag}`}>{type}</Tag>;
    case "cashback":
      return (
        <Tag className={`${classNames.tag} ${classNames.cashback}`}>{type}</Tag>
      );
    default:
      return <Tag className={`${classNames.tag}`}>{type}</Tag>;
  }
}

TypeTag.propTypes = {
  type: PropTypes.oneOf(["wire", "ach", "achpayment", "card", "check", "fee"]),
};
