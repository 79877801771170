import { useMemo, useState/*, useContext*/ } from "react";
import { Card, Menu, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import UserSmileLineIcon from "remixicon-react/UserSmileLineIcon";

import { Header, Title, Input } from "../../common";
import AlertsAndNotifications from "../AlertsAndNotifications/TempNotifications";
// import { AppContext } from "../../../context/AppState";

import UserProfile from "../UserProfile/UserProfile";
import SignInPreferences from "../SignInPreferences/SignInPreferences";
import { cardListConfig } from "./settingsPage.config";
import user from "../../../assets/user.png";

// import api from "../../API";
// import { useQuery } from "react-query";
// import { AppContext } from "../../../context/AppState";
import classNames from "./SettingsHome.module.scss";
// import api from "../../API";
// import { useQuery } from "react-query";

export default function SettingsHome() {
  const navigate = useNavigate();
  const [alertsModalVisibility, setAlertsModalVisibility] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [signInPrefModalVisibility, setSignInPrefModalVisibility] =
    useState(false);
  const [userProfileVisibility, setUserProfileVisibility] = useState(false);
  // const { application } = useContext(AppContext);

  //Api calls
  // const { data: accountDetail } = useQuery(
  //   "getBankLetterDetail",
  //   () =>
  //     api.BusinessBanking.getBankLetterDetail(
  //       application?.otz_account_id
  //     ),
  //   {
  //     refetchOnWindowFocus: true,
  //   }
  // );

  const handleAlertsModalCancelClick = () => {
    setAlertsModalVisibility(false);
  };

  const handleUserProfileModalCancelClick = () => {
    setUserProfileVisibility(false);
  };

  const handleSignInPrefModalCancelClick = () => {
    setSignInPrefModalVisibility(false);
  };

  const menu = (list) => {
    return (
      <Menu className={classNames.menuWrapper}>
        {list.map((item) => {
          return (
            <a
              key={item.key}
              href={item.link}
              onClick={item.onClick}
              rel="noreferrer"
              target={item.openInSameWindow ? "_self" : "_blank"}
            >
              <Menu.Item className={classNames.menuItem}>
                <span className={classNames.menuItemTitle}>
                  <span>{item.title}</span>
                </span>
                <RiArrowRightLine
                  size={16}
                  className={classNames.arrowBtnWithBg}
                />
              </Menu.Item>
            </a>
          );
        })}
      </Menu>
    );
  };

  const onSearchTextChange = (e) => setSearchText(e.target.value);

  const cardList = useMemo(() => {
    const signInItems = [
      {
        key: 1,
        title: "Manage Main Account Password",
        onClick: () => setSignInPrefModalVisibility(true),
      },
    ];
    const signInPreferenceList = menu(signInItems);

    return cardListConfig(
      classNames,
      { navigate, menu, setAlertsModalVisibility, setUserProfileVisibility },
      { signInPreferenceList }
    );
  }, [navigate]);

  const renderCardList = useMemo(() => {
    if (!searchText) {
      return cardList.map((card) => card.component);
    } else {
      const searchRegex = new RegExp(
        searchText.toLowerCase().replace(/[^\w|\s&]/g, "")
      );
      return cardList.map((card) =>
        card.name.toLowerCase().match(searchRegex)?.length
          ? card.component
          : null
      );
    }
  }, [cardList, searchText]);

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Settings" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <Card className={classNames.settingsHomeCard}>
              <div>
                <Title as="h3">Settings & Configurations</Title>
                <span>Search for a specific setting</span>
                <Input
                  placeholder="Search settings"
                  prefix={<Search2LineIcon />}
                  className={classNames.searchTopic}
                  onChange={onSearchTextChange}
                />
              </div>
              <div>
                <UserSmileLineIcon />
                <span>Reach out to your dedicated support agent</span>
                <div className={classNames.supportPill}>
                  <Avatar size={34} src={user} />
                  <div>
                    {/* <span>{accountDetail?.payload?.customer_care?.name}</span>
                    <span>{accountDetail?.payload?.customer_care?.number}</span> */}
                    {/* <span>Tapan</span> */}
                    <span>hello@otterz.co</span>
                  </div>
                </div>
              </div>
            </Card>
            <div className={classNames.topicsWrapper}>{renderCardList}</div>
          </div>
        </div>
      </div>
      <UserProfile
        visible={userProfileVisibility}
        onCancel={handleUserProfileModalCancelClick}
      />
      <AlertsAndNotifications
        visibility={alertsModalVisibility}
        onCancel={handleAlertsModalCancelClick}
      />
      <SignInPreferences
        visible={signInPrefModalVisibility}
        onCancel={handleSignInPrefModalCancelClick}
      />
    </div>
  );
}
