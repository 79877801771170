import { API } from "aws-amplify";

export default async function deleteSchedule(data) {
  return await API.post("pa-transaction", `/schedule/delete`, {
    body: {
      data: {
        scheduleId: data,
      },
    },
  });
}
