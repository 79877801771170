import { API } from "aws-amplify";
import moment from "moment";
import { FORMATS } from "../../../constants";

export const transactionTypes = Object.freeze({
  all: { type: "all", name: "All transaction types" },
  creditCard: { type: "CC", name: "Credit Card" },
  check: { type: "Check", name: "Check" },
  gift: { type: "Gift", name: "Gift" },
});

export const transactionModes = Object.freeze({
  all: { mode: "All", name: "All transaction modes" },
  sale: { mode: "Sale", name: "Sale" },
  authOnly: { mode: "AuthOnly", name: "Auth Only" },
  capture: { mode: "Capture", name: "Capture" },
  postAuth: { mode: "PostAuth", name: "Post Auth" },
  save: { mode: "Save", name: "Save" },
  credit: { mode: "Credit", name: "Credit" },
  refund: { mode: "Refund", name: "Refund" },
});

export const transactionStates = Object.freeze({
  all: { state: "Report:All", name: "All transaction states" },
  approved: { state: "Report:Approved", name: "Approved" },
  declined: { state: "Report:Declined", name: "Declined" },
});

export const dateFormat = FORMATS.DATE_TIME_YEAR_FORMAT;

const nextDayMidNight = moment(
  moment().add(1, "days").format("YYYY-MM-DD").concat(" 00:00:00")
);

export const lastHourFilterProps = {
  xEndDate: moment().format(dateFormat),
  xBeginDate: moment().subtract(1, "hours").format(dateFormat),
};

export const last30DaysFilterProps = {
  xEndDate: moment(nextDayMidNight).subtract(1, "seconds").format(dateFormat),
  xBeginDate: moment(
    moment(nextDayMidNight)
      .subtract(1, "months")
      .format("YYYY-MM")
      .concat("-01 00:00:00"),
    dateFormat,
    true
  ).format(dateFormat),
};

export const last3MonthsFilterProps = {
  xEndDate: moment(nextDayMidNight).subtract(1, "seconds").format(dateFormat),
  xBeginDate: moment(
    moment(nextDayMidNight)
      .subtract(3, "months")
      .format("YYYY-MM-DD")
      .concat(" 00:00:00"),
    dateFormat,
    true
  ).format(dateFormat),
};

export default async function listTransaction(
  xCommand,
  xMaxRecords,
  xBeginDate,
  xEndDate,
  otherFilters
) {
  return await API.post("pa-transaction", `/card/list-transactions`, {
    body: {
      data: {
        xCommand,
        xGetNewest: true,
        xMaxRecords,
        xBeginDate,
        xEndDate,
        xVersion: "5.0.0",
        xFields:
          "xCurrency,xRefNum,xAmount,xName,xExp,xEnteredDate,xResponseResult,xEntryMethod,xCardType,xMaskedCardNumber,xMaskedAccountNumber,xInvoice,xResponseBatch,xDescription,xCommand,xResponseCVVCode,xResponseAVSCode,xStreet,xZip,xOrderID,xResponseAuthCode,xTerminalNum,xPONum,xSourceKey,xSignature,xResponseRefNum,xUserName,xClientIp,xEmail,xSoftwareName,xTip,xIsDebit,xStatus,xProcessingFee,xDigitalWalletType,xSplitAmount,xCardSource,xServiceFee,xTax,xDisputeAmount,xDisputeReason,xInvoice,xBillFirstName,xBillMiddleName,xBillLastName,xBillCompany,xBillStreet,xBillCity,xBillZip,xBillState,xBillCountry,xBillPhone,xShipFirstName,xShipMiddleName,xShipLastName,xShipCompany,xShipStreet,xShipCity,xShipZip,xShipState,xShipCountry,xShipPhone,xCustom01,xCustom02,xCustom03,xCustom04,xCustom05,xCustom06,xCustom07,xCustom08,xCustom09,xCustom10,xCustom11,xCustom12,xCustom13,xCustom14,xCustom15,xCustom16,xCustom17,xCustom18,xCustom19,xCardLastFour",
        ...otherFilters,
      },
    },
  });
}
