import { API } from "aws-amplify";

export default async function deleteCustomers(id) {
  if(id){
    return await API.del("invoices", "customer", {
      queryStringParameters: {
        // this should be replaced
        customerId: id,
      },
    });
  }
}
