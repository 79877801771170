import React from "react";
import classNames from "../BillPayments.module.scss";
import { Modal } from "../../../../common";
import BillPaymentForm from "./BillPaymentForm";

export default function MakePaymentModal({ visible, onCancel }) {
  return (
    <Modal
      closable={true}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      title="Bill Payment"
      className={classNames.modal}
      bodyStyle={{
        padding: 0,
        height: "70vh",
        display: "flex",
        justifyContent: "space-between",
      }}
      width={730}
    >
      <BillPaymentForm />
  </Modal>
  );
}
