import { API } from "aws-amplify";

export default async function deletePayee(reqBody) {
  if (reqBody.id) {
    return await API.del("payees", `${reqBody.id}`, {
      body: {
        ...reqBody.body,
      },
    });
  }
}
