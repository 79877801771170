import endpoints from "../endpoints";

export default async function uploadDocumentBackAPI(data){
    let env = process.env.REACT_APP_ENV?.trim()
    return await(
        await fetch(endpoints.ONBOARDING.UPLOAD_DOCUMENTS_BACK[env],{
            method: "POST",
            body: JSON.stringify(data),
          })
    ).json();
}