import { useCallback } from "react";
import usePersistedReducer from "./usePersistedReducer";

const initialState = {
  companyName: "",
  address: "",
  email: "",
  phoneNumber: "",
  logo: "",
};

const types = {
  SET_SETTINGS: "PAYMENT-ACCEPTANCE-SETTINGS-SET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SETTINGS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

const usePASettings = () => {
  const [state, dispatch] = usePersistedReducer(
    "OTTERZ_PaymentAcceptanceSettings",
    reducer,
    initialState
  );

  const setSettings = useCallback(
    (settingsDetails) =>
      dispatch({
        type: types.SET_SETTINGS,
        payload: settingsDetails,
      }),
    [dispatch]
  );

  return {
    state,
    actions: { setSettings },
  };
};

export default usePASettings;
