import classNames from "../ManageCards.module.scss";
import { Modal, CTAButton, Input, Title } from "../../../common";
import { Form, Checkbox } from "antd";
import NotificationOffLineIcon from "remixicon-react/NotificationOffLineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";
import MessageLineIcon from "remixicon-react/MessageLineIcon";
import NotificationBadgeLineIcon from "remixicon-react/NotificationBadgeLineIcon";

export default function SetNotifications({ visible, onCancel }) {
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Set Notifications"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={730}
    >
      <div className={classNames.setNotificationsModalHeader}>
        <Title as="h3">Transaction</Title>
        <span>Set all your transaction based alerts</span>
      </div>
      <div className={classNames.setNotificationsModalTableHeader}>
        <div>
          <NotificationOffLineIcon color="#9BA3AB" size={14} />
          <span>None</span>
        </div>
        <div>
          <MailLineIcon color="#9BA3AB" size={14} />
          <span>Email</span>
        </div>
        <div>
          <MessageLineIcon color="#9BA3AB" size={14} />
          <span>SMS</span>
        </div>
        <div>
          <NotificationBadgeLineIcon color="#9BA3AB" size={14} />
          <span>Push</span>
        </div>
      </div>
      <div className={classNames.setNotificationsModalBody}>
        <Form
          layout="horizontal"
          hideRequiredMark
          colon={false}
          autoComplete="off"
        >
          <Form.Item
            label={
              <span className={classNames.label}>
                Alert me on all transactions
              </span>
            }
            className={classNames.setNotificationsModalBodyItem}
          >
            <span className={classNames.setNotificationsModalCheckboxes}>
              <Checkbox.Group>
                <Checkbox value="None" style={{ marginRight: 55 }} />
                <Checkbox value="Email" style={{ marginRight: 55 }} />
                <Checkbox value="SMS" style={{ marginRight: 55 }} />
                <Checkbox value="Push" />
              </Checkbox.Group>
            </span>
          </Form.Item>
          <Form.Item
            label={
              <span className={classNames.label}>
                Alert me on transactions above
              </span>
            }
            className={classNames.setNotificationsModalBodyItem}
          >
            <Input
              prefix={"$"}
              palceholder="Please enter"
              size="small"
              style={{ width: 140 }}
            />
            <span className={classNames.setNotificationsModalCheckboxesLastRow}>
              <Checkbox.Group>
                <Checkbox value="None" style={{ marginRight: 55 }} />
                <Checkbox value="Email" style={{ marginRight: 55 }} />
                <Checkbox value="SMS" style={{ marginRight: 55 }} />
                <Checkbox value="Push" />
              </Checkbox.Group>
            </span>
          </Form.Item>
          <Form.Item className={classNames.setNotificationsModalFooterCTA}>
            <CTAButton
              type="primary"
              style={{
                height: 44,
                width: 248,
                marginTop: 20,
              }}
            >
              Done
            </CTAButton>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
