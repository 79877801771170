import { useState } from "react";
import classNames from "./BillPayments.module.scss";
import { Title, Header } from "../../../common";
import { Card, Button as AntButton, Table, Radio, Space } from "antd";
import ArrowRightUpLineIcon from "remixicon-react/ArrowRightUpLineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import ArrowRightCircleLineIcon from "remixicon-react/ArrowRightCircleLineIcon";
import MakePaymentModal from "./MakePaymentModal/MakePaymentModal";
import payments from "../../../data/payments.data";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function BillPayments() {
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleModalCancelClick = () => {
    setModalVisibility(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return <span>{moment(text).format()}</span>;
      },
    },
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Bill Payments" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Card className={classNames.totalsCard}>
              <div className={classNames.totalsCardSections}>
                <div className={classNames.icon}>
                  <ArrowRightUpLineIcon size={26} />
                </div>
                <div className={classNames.amount}>
                  <Title as="h3">Total for Water</Title>
                  <div className={classNames.count}>
                    <span>$</span> 2,389.55
                  </div>
                </div>
              </div>
              <div className={classNames.totalsCardSections}>
                <div className={classNames.icon}>
                  <ArrowRightUpLineIcon size={26} />
                </div>
                <div className={classNames.amount}>
                  <Title as="h3">Total for Electricity</Title>
                  <div className={classNames.count}>
                    <span>$</span> 2,389.55
                  </div>
                </div>
              </div>
              <div className={classNames.totalsCardSections}>
                <div className={classNames.icon}>
                  <ArrowRightUpLineIcon size={26} />
                </div>
                <div className={classNames.amount}>
                  <Title as="h3">Total for Broadband</Title>
                  <div className={classNames.count}>
                    <span>$</span> 2,389.55
                  </div>
                </div>
              </div>
            </Card>
            <Card
              className={classNames.optionCard}
              onClick={() => setModalVisibility(true)}
            >
              <ArrowRightCircleLineIcon
                color="#fff"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdrop} />
              <div className={classNames.cardAction}>
                <span>Make a Bill Payment</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <Card className={classNames.tableCard}>
              <div className={classNames.tableCardHeader}>
                <Title as="h3">Upcoming Bill Payments</Title>
                <AntButton type="link">View all</AntButton>
              </div>
              <div className={classNames.tableCardFilters}>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Space size={0}>
                    <Radio.Button value="a">All</Radio.Button>
                    <Radio.Button value="b">Due</Radio.Button>
                    <Radio.Button value="c">Completed</Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
              <Table
                pagination={false}
                dataSource={payments}
                columns={columns}
                className={classNames.table}
              />
            </Card>
            <Card className={classNames.tableCard}>
              <div className={classNames.tableCardHeader}>
                <Title as="h3">Previous Bill Payments</Title>
                <AntButton type="link">View all</AntButton>
              </div>
              <div className={classNames.tableCardFilters}>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Space size={0}>
                    <Radio.Button value="a">All</Radio.Button>
                    <Radio.Button value="b">Due</Radio.Button>
                    <Radio.Button value="c">Completed</Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
              <Table
                pagination={false}
                dataSource={payments}
                columns={columns}
                className={classNames.table}
              />
            </Card>
          </div>
        </div>
      </div>
      <MakePaymentModal
        visible={modalVisibility}
        onCancel={handleModalCancelClick}
      />
    </div>
  );
}
