import { API } from "aws-amplify";

export default async function getScheduledPayments(filters = {}) {
  return await API.post(
    "paymentAcceptanceTransaction",
    "schedule/list-schedule",
    {
      body: {
        Filters: { ...filters },
      },
    }
  );
}
