import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Spin } from "antd";
import { useQuery } from "react-query";
import { RiSearchLine } from "react-icons/ri";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import api from "../../API";
import { Header } from "../../common";
import pin from "../../../assets/common/maps/marker-unselected.svg";
import pinSelected from "../../../assets/common/maps/marker-selected.svg";

import SearchResultCard from "./SearchResultCard/SearchResultCard";
import ToolTip from "./SearchResultCard/ToolTip";
import LocationSearch from "./LocationSearch";
import LocateUserLocation from "./LocateUserLocation";
import mapStyles from "./mapStyles";

import classNames from "./ATMLocator.module.scss";

const libraries = ["places"];
const mapContainerStyle = {
  height: "100%",
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px",
};
const googleMapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  clickableIcons: false,
  zoomControl: true,
};
export const DEFAULT_MAP_CENTER_COORDS = {
  lat: 40.73061,
  lng: -73.935242,
};

export default function ATMLocator() {
  //#region Definitions
  const navigate = useNavigate();

  const mapRef = useRef();
  const resultContainer = useRef();

  const [selected, setSelected] = useState(null);
  const [isMapLoadComplete, setIsMapLoadComplete] = useState(false);
  // TODO: I'm not sure how we got this lat and lng values. This was here when I start bug fixing. This is something we need to document
  // or need to find to get this values automatically.
  const [mapCenter, setMapCenter] = useState(null);
  const [queryParams, setQueryParams] = useState({
    filter: "coordinates",
    searchRadius: "100",
    filterParams: mapCenter,
  });

  const { data: ATMLocations, isFetching: isLoadingATMData } = useQuery(
    ["getDefaultATMLocations", queryParams],
    () => {
      // persist dragged location in the map. Otherwise when we click an marker in the map it will navigated to the hard coded
      // defaultCenter and then pan back to the selected marker.
      mapRef.current && setMapCenter(mapRef.current.getCenter()?.toJSON());

      return queryParams?.filterParams
        ? api.Support.getATMLocations(queryParams)
        : { data: [] };
    },
    {
      enabled: !!mapCenter?.lat && !!mapCenter?.lng,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "",
    libraries,
  });

  //#endregion Definitions

  //#region useEffects

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        setMapCenter(DEFAULT_MAP_CENTER_COORDS);
      }
    );
  }, []);

  useEffect(() => {
    setQueryParams({
      filter: "coordinates",
      searchRadius: "100",
      filterParams: mapCenter,
    });
  }, [mapCenter]);

  useEffect(() => {
    // select the first location automatically
    if (ATMLocations?.data?.length) {
      setSelected(ATMLocations.data[0]);
    }
  }, [ATMLocations]);

  //#endregion Functions

  //#region Functions

  const panTo = ({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
    setQueryParams({
      filter: "coordinates",
      searchRadius: "100",
      filterParams: {
        lat,
        lng,
      },
    });
  };

  const isLocationPositionMatched = (source, dest) => {
    return (
      source?.attributes.coordinates.latitude ===
      dest?.attributes.coordinates.latitude &&
      source?.attributes.coordinates.longitude ===
      dest?.attributes.coordinates.longitude
    );
  };

  //#endregion Functions

  //#region Events
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  const onDragEnd = () => {
    setMapCenter(mapRef.current.getCenter().toJSON());
  };

  const onMarkerClick = (point) => () => {
    try {
      setSelected(point);
      const result = resultContainer.current?.querySelectorAll(
        `[result-id="${point?.attributes.coordinates.latitude}-${point?.attributes.coordinates.longitude}"`
      )?.[0];

      if (result) {
        resultContainer?.current.scrollTo({
          top: result.offsetTop,
          behavior: "smooth",
        });
      }
    } catch (error) {
      // gracefully ignore any error since this is just a ui functionality
    }
  };

  //#endregion Events

  //#region Render

  if (loadError) return "Error";

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="ATM Locator" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <Spin spinning={!isLoaded}>
              <Card className={classNames.atmLocatorCard}>
                <div className={classNames.searchBrowser}>
                  {isLoaded && (
                    <LocationSearch
                      panTo={panTo}
                      isLoading={isLoadingATMData}
                      classNames={classNames}
                      onSearch={(coords) => setMapCenter(coords)}
                    />
                  )}
                  <Spin spinning={isLoadingATMData}>
                    <div
                      className={`${classNames.searchResultsList} ${!ATMLocations?.data?.length ? "empty" : ""
                        }`}
                      ref={resultContainer}
                    >
                      {ATMLocations?.data?.length ? (
                        ATMLocations.data.map((atm, index) => {
                          return (
                            <div
                              key={`${atm.attributes.locationName}-${index}`}
                              onClick={() => setSelected(atm)}
                              result-id={`${atm.attributes.coordinates.latitude}-${atm.attributes.coordinates.longitude}`}
                            >
                              <SearchResultCard
                                key={index}
                                network={atm.attributes.network}
                                address={atm.attributes.address.street}
                                isSelected={isLocationPositionMatched(
                                  atm,
                                  selected
                                )}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className={classNames.searchingContainer}>
                            <RiSearchLine size={30} />
                            <span>Search</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Spin>
                </div>

                <div
                  className={`${classNames.mapWrapper} ${!isMapLoadComplete ? "is-loading" : ""
                    }`}
                >
                  {isLoaded ? (
                    <>
                      <Spin spinning={!isMapLoadComplete} />
                      <LocateUserLocation
                        onCenter={(coordinates) => setMapCenter(coordinates)}
                      />
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={mapCenter}
                        zoom={14}
                        onLoad={onMapLoad}
                        onUnmount={onUnmount}
                        options={googleMapOptions}
                        onTilesLoaded={() => setIsMapLoadComplete(true)}
                        onDragEnd={onDragEnd}
                      >
                        {ATMLocations &&
                          ATMLocations.data.map((point, index) => (
                            <Marker
                              key={index}
                              position={{
                                lat: point.attributes.coordinates.latitude,
                                lng: point.attributes.coordinates.longitude,
                              }}
                              zIndex={
                                isLocationPositionMatched(point, selected)
                                  ? 10
                                  : 1
                              }
                              icon={{
                                url: isLocationPositionMatched(point, selected)
                                  ? pinSelected
                                  : pin,
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 15),
                                scaledSize: new window.google.maps.Size(30, 30),
                              }}
                              onClick={onMarkerClick(point)}
                            />
                          ))}
                        {selected ? (
                          <InfoWindow
                            position={{
                              lat: selected.attributes.coordinates.latitude,
                              lng: selected.attributes.coordinates.longitude,
                            }}
                            onCloseClick={() => {
                              setSelected(null);
                            }}
                            options={{
                              pixelOffset: { height: 125, width: 114 },
                            }}
                          >
                            <ToolTip
                              network={selected.attributes.network}
                              address={selected.attributes.address.street}
                            />
                          </InfoWindow>
                        ) : null}
                      </GoogleMap>
                    </>
                  ) : (
                    <Spin />
                  )}
                </div>
              </Card>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );

  //#endregion Render
}
