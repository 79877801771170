const FORMATS = {
  DATE_FORMAT: "MMM DD YYYY",
  DATEPICKER_FORMAT: "MM-DD-YYYY",
  TEST_FORMAT: "yyyy-MM-DD",
  API_SEND_FORMAT: "YYYY-MM-DD",
  DATE_TIME_FORMAT: "MM-DD-YYYY HH:mm:ss",
  DATE_TIME_YEAR_FORMAT: "YYYY-MM-DD HH:mm:ss",
  DATE_TIME_A_FORMAT: "MM/DD/YYYY h:mm:ss A",
  DATE_MONTH: "DD MMM",
  API_OTHER_FORMAT: "MM/DD/YYYY",
};

export default FORMATS;
