import { Result } from "antd";
import classNames from "./DataNotAvailableComponent.scss";

const DataNotAvailableResult = ({ language }) => (
  <div className={classNames.noDataWrapper}>
    <Result status="warning" title={language.DATA_NOT_AVAILABLE} />
  </div>
);

export default DataNotAvailableResult;
