import endpoints from "../../../endpoints";

export default async function getAllInvoicesQBs(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Check if data and data.tenantId are defined
    if (!data || !data.tenantId) {
        throw new Error("Data or tenantId is not defined");
    }

    const url = `${endpoints.PAYABLES.ACCOUNTS_PAYABLES.QUICKBOOKS[env]}/${data.tenantId}/bill`;

    const response = await fetch(url, {
        method: "GET",
    });

    return await response.json();
}
