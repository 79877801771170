export const FileIcon = ({ height = "18", width = "14", fill = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66659 12.3333H10.3333V13.9999H3.66659V12.3333ZM3.66659 8.99993H10.3333V10.6666H3.66659V8.99993ZM8.66659 0.666595H1.99992C1.08325 0.666595 0.333252 1.4166 0.333252 2.33326V15.6666C0.333252 16.5833 1.07492 17.3333 1.99159 17.3333H11.9999C12.9166 17.3333 13.6666 16.5833 13.6666 15.6666V5.6666L8.66659 0.666595ZM11.9999 15.6666H1.99992V2.33326H7.83325V6.49993H11.9999V15.6666Z"
        fill={fill}
      />
    </svg>
  );
};
