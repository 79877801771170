import Steps from "../Steps";
import classNames from "../Signup/Signup.module.scss";

export default function BusinessProfileSuccess() {    
    return (
        <div className={classNames.accountFormWrapper}>
            <Steps classNames={classNames} currentIndex={3} count={4} />
            <div className={classNames.formBOInstructionsWrapper} style={{width: "100%"}}>
            <div className={classNames.success}>
                <h2 className={classNames.subheading} style={{marginTop: "4rem", width: "100%", fontSize: "1.5rem", fontWeight: "700"}}>
                    Your business profile is updated
                </h2>
                <p>Wire Transfers will be activated for your business account</p>
            </div>
            </div>
        </div>
    );
}
