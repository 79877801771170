import { useState } from "react";
import { Card, Table } from "antd";
import { FiArrowRightCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import EditReport from "./EditReport";
import SaveReport from "./SaveReport";
import { ROUTES } from "../../../constants";
import classNames from "./Reports.module.scss";
import qaReports from "../../../data/paQuickReports.data";
import otherReports from "../../../data/paOtherReports.data";
import { Header, Title } from "../../../common";
import { useStoreContext } from "../../../../store";

const Reports = () => {
  const navigation = useNavigate();

  const {
    actions: {
      setDateRangeFilter,
      setTransactionTypeFilter,
      setTransactionModeFilter,
      setTransactionCommandFilter,
    },
  } = useStoreContext((store) => store.paymentAcceptanceTransactions);

  const [editReportModalVisibility, setEditReportModalVisibility] =
    useState(false);

  const handleEditReportModalCancelClick = () => {
    setEditReportModalVisibility(false);
  };

  const [saveReportModalVisibility, setSaveReportModalVisibility] =
    useState(false);

  const handleSaveReportModalCancelClick = () => {
    setSaveReportModalVisibility(false);
  };

  // const customSavedReports = [
  //   {
  //     title: "Report status",
  //     dataIndex: "reportStatus",
  //     key: "reportStatus",
  //     render: (reportStatus) => (
  //       <div
  //         style={{
  //           width: "100%",
  //           display: "inline-flex",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //         }}
  //       >
  //         <span>{reportStatus}</span>
  //         <Space
  //           size="large"
  //           style={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <RiDownloadCloud2Line
  //             size={14}
  //             color="#9BA3AB"
  //             className={classNames.actionIcon}
  //           />
  //           <RiEditLine
  //             size={14}
  //             color="#9BA3AB"
  //             className={classNames.actionIcon}
  //             onClick={() => setEditReportModalVisibility(true)}
  //           />
  //           <RiDeleteBin7Line
  //             size={14}
  //             color="#9BA3AB"
  //             className={classNames.actionIcon}
  //           />
  //         </Space>
  //       </div>
  //     ),
  //   },
  // ];

  const onReportClicked = (param, index) => {
    const {
      filter: {
        transactionType,
        transactionMode,
        xCommand,
        xEndDate,
        xBeginDate,
      },
    } = (param === "orIndex" ? otherReports : qaReports)[index];

    setTransactionTypeFilter(transactionType);
    setTransactionModeFilter(transactionMode);
    setTransactionCommandFilter(xCommand);
    setDateRangeFilter(xBeginDate, xEndDate);

    navigation(ROUTES.APP.RECEIVABLES.TRANSACTIONS);
  };

  const otherReportsColumns = [
    {
      title: "Report status",
      dataIndex: "name",
      key: "name",
      render: (text, record, i) => (
        <div
          style={{
            width: "100%",
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => onReportClicked("orIndex", i)}
        >
          <span>{text}</span>
          <FiArrowRightCircle
            size={14}
            color="#9BA3AB"
            className={classNames.actionIcon}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header back onClick={() => navigation(-1)} title="Reports" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.subHeader}>
              <span>Quick Reports </span>
              <span>(Past 30 days)</span>
            </div>
            <div className={classNames.quickReportsWrapper}>
              {qaReports.map((report, i) => (
                <Card
                  key={report.name}
                  className={classNames.quickReportCard}
                  onClick={() => onReportClicked("qrIndex", i)}
                >
                  <div className={classNames.quickReportTitle}>
                    <span>{report.name}</span>
                    <span>{report.shortDescription}</span>
                  </div>
                  <FiArrowRightCircle
                    color="#7372FF"
                    size={26}
                    className={classNames.icon}
                  />
                </Card>
              ))}
            </div>
            <div
              className={classNames.tableWrapper}
              style={{ display: "none" }}
            >
              {/* <Card className={classNames.tableCardLeft}>
                <div className={classNames.tableCardHeader}>
                  <Title as="h3">Custom Saved Reports</Title>
                </div> */}
              {/* <Table
                  pagination={false}
                  dataSource={reports}
                  columns={customSavedReports}
                  className={classNames.table}
                /> */}
              {/* </Card> */}
            </div>
          </div>
          <div className={classNames.layoutRight} style={{ marginTop: 40 }}>
            <Card className={classNames.tableCardRight}>
              <div className={classNames.tableCardHeader}>
                <Title as="h3">Other Reports</Title>
              </div>
              <Table
                pagination={false}
                dataSource={otherReports}
                columns={otherReportsColumns}
                className={classNames.table}
              />
            </Card>
            {/* <Card className={classNames.createReportCard}>
              <Title as="h3">Create Report</Title>
              <Form layout="vertical" hideRequiredMark>
                <Form.Item
                  label={<span className={classNames.label}>Select Date</span>}
                >
                  <DatePicker placeholder=FORMATS.API_OTHER_FORMAT style={{ height: 44 }} />
                </Form.Item>

                <Form.Item
                  label={
                    <span className={classNames.label}>View/ Sort by</span>
                  }
                >
                  <Select size="small" placeholder="Please select"></Select>
                </Form.Item>

                <Form.Item
                  label={
                    <span className={classNames.label}>Transaction Type</span>
                  }
                >
                  <Select size="small" placeholder="Please select"></Select>
                </Form.Item>

                <Form.Item
                  label={
                    <span className={classNames.label}>Transaction Status</span>
                  }
                >
                  <Select size="small" placeholder="Please select"></Select>
                </Form.Item>

                <Form.Item className={classNames.addFilters}>
                  <Button type="link" className={classNames.linkButton}>
                    <RiAddFill />
                    Add filters
                  </Button>
                </Form.Item>

                <CTAButton
                  type="primary"
                  style={{ height: 44 }}
                  onClick={() => setSaveReportModalVisibility(true)}
                >
                  Save Report
                </CTAButton>

                <div className={classNames.reportOptions}>
                  <Button type="link" className={classNames.linkButton}>
                    <RiEyeLine />
                    View
                  </Button>
                  <Button type="link" className={classNames.linkButton}>
                    <RiPrinterLine />
                    Print
                  </Button>
                  <Button type="link" className={classNames.linkButton}>
                    <RiDownloadCloud2Line />
                    Download
                  </Button>
                </div>
              </Form>
            </Card> */}
          </div>
        </div>
      </div>
      <EditReport
        visible={editReportModalVisibility}
        onCancel={handleEditReportModalCancelClick}
      />
      <SaveReport
        visible={saveReportModalVisibility}
        onCancel={handleSaveReportModalCancelClick}
      />
    </div>
  );
};

export default Reports;
