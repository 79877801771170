import { QRCodeCanvas } from "qrcode.react";
import { RiPrinterLine } from "react-icons/ri";

import classNames from "../PaymentAcceptanceOverview.module.scss";
import { Modal, CTAButton } from "../../../../common";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

export default function QrModal({ visible, onCancel, queryString }) {
  const currentRef = useRef();

  const onPrintClick = useReactToPrint({
    content: () => currentRef.current,
    bodyClass: classNames.qrCodePrint,
    documentTitle: "On-Fields QR Code",
  });

  // const onPrintClick = () => {
  //   const qrContent = document.getElementById("qrCanvas").toDataURL();
  //   var a = window.open("", "", "width='100%',height='100%'");
  //   a.document.write(`
  //   <!DOCTYPE html><html><body><img src="${qrContent}"/></body></html>
  //   `);

  //   a.document.addEventListener(
  //     "load",
  //     function () {
  //       a.focus();
  //       a.print();
  //       a.close();
  //     },
  //     true
  //   );
  // };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="On-Fields QR Code"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <div className={classNames.qrCode} ref={currentRef}>
        <div>{"On-Fields QR Code"}</div>
        <QRCodeCanvas id="qrCanvas" value={queryString} size={400} />,
      </div>
      <CTAButton
        style={{
          height: 44,
          width: 384,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 16,
        }}
        type="outline"
        onClick={onPrintClick}
      >
        <RiPrinterLine size={16} style={{ marginRight: 10 }} />
        Print
      </CTAButton>
    </Modal>
  );
}
