const removeFile = (setUploadTrackingDocs, documentId) => {
  setUploadTrackingDocs((prev) => {
    return prev.map((value) => {
      if (value.documentId === documentId) {
        return { ...value, isUploaded: false };
      } else {
        return { ...value };
      }
    });
  });
};

export default removeFile;
