import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Form,
  Input as AntInput,
  Button as AntButton,
  AutoComplete,
} from "antd";
import {
  RiAddFill,
  RiArrowDropDownLine,
  RiCloseCircleFill,
} from "react-icons/ri";
import moment from "moment";

import classNames from "./EditEstimate.module.scss";
import {
  Title,
  Input,
  Select,
  DatePicker,
  Message,
  CTAButton,
} from "../../../../common";
import { validation } from "../../../../Auth/config";
import api from "../../../../API";
import { intToDecimalDollar } from "../../../../util/functions/dollarConvertion";
import { uuidv4 } from "../../../../util/functions/uuidV4";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { DEFAULT_AMOUNT, FORMATS } from "../../../../constants";
import TextArea from "antd/lib/input/TextArea";

export default function EditEstimateForm({
  estimate,
  products,
  taxRates,
  customers,
  setEditEstimateModalVisibility,
  invoiceTemplateSettings,
  onCancel,
}) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  const [summary, setSummary] = useState({
    subTotal: estimate.subTotal,
    tax: estimate.taxTotal,
    total: 0,
  });
  const [discount, setDiscount] = useState(estimate.discount);
  const [currFields, setCurrFields] = useState(estimate.item.length);

  const calculatePrice = (index, flag, value) => {
    let values = form.getFieldsValue();
    let obj = values["salesItemsEstimate"]?.length
      ? values["salesItemsEstimate"][index]
      : {};
    let product =
      Array.isArray(products) &&
      products.filter((value, index) => value.id === obj.description?.[0]);
    let productUnitCost;
    let productTaxRate;
    if (flag === "description") {
      let productFromProductsArray =
        Array.isArray(products) &&
        products.filter((productItem, index) => productItem.id === value.id);
      productUnitCost = productFromProductsArray[0]?.price || 0;
      productTaxRate = productFromProductsArray[0]?.taxRate || 0;
    }
    if (flag === "taxRate") {
      let taxObj =
        Array.isArray(taxRates) &&
        taxRates.filter((val) => val.id === value[0])[0];
      productTaxRate = (taxObj && taxObj.taxRate) || value[0];
    }
    if (flag === "unitCost") {
      let taxObj =
        (Array.isArray(taxRates) &&
          taxRates.filter((val) => val.id === obj.taxRate?.[0])[0]) ||
        0;
      productTaxRate =
        (taxObj && taxObj.taxRate) || (obj.taxRate && obj.taxRate[0]);
    }
    productTaxRate = productTaxRate
      ? Number(productTaxRate)
      : Number(obj.taxRate);
    let unitCost = productUnitCost ? Number(productUnitCost) : obj?.unitCost;
    let quantity = obj?.quantity ? parseInt(obj?.quantity) : 1;
    let totalWithOutTax = Number(unitCost) * Number(quantity) || 0;
    let taxAmount = productTaxRate
      ? parseFloat((totalWithOutTax * Number(productTaxRate)) / 100)
      : 0;
    let price = unitCost * quantity;
    // + taxAmount;
    let array = values["salesItemsEstimate"];

    Object.assign(obj, {
      unitCost: unitCost || 0,
      price: Number(price.toFixed(2)) || 0,
      taxAmount: taxAmount,
      taxRate: productTaxRate ? Number(productTaxRate) : 0,
      subTotal: totalWithOutTax,
      quantity: quantity,
      description:
        product && product.length > 0
          ? product[0]?.productName
          : obj?.description,
    });

    if (flag === "remove") {
      delete array[`${index}`];
      array?.filter(Boolean);
      form.setFieldsValue({ salesItemsEstimate: array });
      setCurrFields((prev) => prev - 1);
    } else {
      array?.filter(Boolean);
      form.setFieldsValue({ salesItemsEstimate: array });
    }
    let subTotal = array
      .map(
        (value, index) =>
          Number(value?.subTotal) ||
          Number(value?.unitCost) * Number(value?.quantity)
      )
      .reduce((sum, next) => sum + next, 0);
    let tax = array
      .map((value, index) => {
        return (
          Number(value?.taxAmount) ||
          Number(value?.tax) ||
          Number(value?.unitCost * value?.quantity * (value?.taxRate / 100)) ||
          0
        );
      })
      .reduce((sum, next) => sum + next, 0);
    setSummary({ subTotal, tax });
  };

  function editEstimateFn(data) {
    const reference =
      data.purchaseOrderNumber === "" && !data.purchaseOrderNumber
        ? " "
        : data.purchaseOrderNumber;
    let input = {
      id: estimate.id,
      estimateNumber: data.estimateNumber
        ? data.estimateNumber
        : estimate.estimateNumber,
      receiverName: data.receiverName
        ? data.receiverName
        : estimate.receiverName,
      estimateDate: data.estimateDate
        ? moment(data.estimateDate).format(FORMATS.API_OTHER_FORMAT)
        : moment(estimate.estimateDate).format(FORMATS.API_OTHER_FORMAT),
      discount: data.discount
        ? Number(data.discount)
        : data.discount === ""
        ? 0
        : Number(estimate.discount),
      reference: reference || estimate.purchaseOrderNumber,
      itemDto: data.salesItemsEstimate
        ? data.salesItemsEstimate
        : estimate?.item,
    };
    return api.Receivables.Estimates.editEstimate(input);
  }

  const updateEstimateStatusFn = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);
  const updateEstimateStatusMutation = useMutation((event) =>
    updateEstimateStatusFn(event)
  );

  const editEstimate = useMutation((event) => editEstimateFn(event));

  const onFinish = (values) => {
    if (form.isFieldsTouched()) {
      editEstimate.mutate(values, {
        onSuccess: () => {
          updateEstimateStatusMutation.mutate(
            {
              id: estimate.id,
              status: "draft",
              type: "estimate",
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries("getEstimates");
                queryClient.invalidateQueries("getEstimateById", estimate.id);
                queryClient.invalidateQueries("getEstimatePDF", estimate.id);
                setEditEstimateModalVisibility(false);
                onCancel(true);
                Message({
                  type: "success",
                  content: language.ESTIMATE_UPDATE_SUCCESS,
                });
                form.resetFields();
              },
              onError: (error) => generateErrorMessage(error),
            }
          );
        },
        onError: (error) => generateErrorMessage(error),
      });
    }
  };

  function disabledDate(current) {
    return current && current <= moment().subtract(1, "days");
  }

  let initialEstimatesSaleItems = estimate?.item
    ? estimate?.item.length > 0 &&
      estimate?.item.map((value) => {
        return {
          key: uuidv4(),
          description: value.description,
          unitCost: value.unitCost,
          quantity: value.quantity,
          taxRate: value.taxRate,
          price: value.unitCost * value.quantity,
          subTotal: value.subTotal,
        };
      })
    : [];

  // const updateEstimateFunction = (event) => {
  //   return api.Receivables.Invoices.setInvoiceStatus(event);
  // };

  // const updateEstimateMutation = useMutation(
  //   (event) => updateEstimateFunction(event),
  //   {
  //     onSuccess: () => {
  //       Message({
  //         type: "success",
  //         content: language.ESTIMATE_UPDATE_SUCCESS,
  //       });
  //     },
  //   }
  // );

  //Helper
  function validationCheck() {
    return (
      form
        ?.getFieldsError()
        ?.filter(
          (item) =>
            ["unitCost", "description"].includes(item?.name[2]) &&
            item?.errors?.length
        )?.length ||
      !(form?.getFieldsValue()?.salesItemsEstimate?.length
        ? form?.getFieldsValue()?.salesItemsEstimate[currFields - 1]
            ?.unitCost &&
          form?.getFieldsValue()?.salesItemsEstimate[currFields - 1]
            ?.description
        : false)
    );
  }
  return (
    <Form
      key={`editEstimateForm`}
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <div key={`modalBodyFormUpper`} className={classNames.modalBodyFormUpper}>
        <div key={`modalLeft`} className={classNames.modalLeft}>
          <div key={`modalHeader`} className={classNames.modalHeader}>
            <Title as="h3">Estimate Details</Title>
          </div>
          <Form.Item
            name="estimateNumber"
            key={`estimateNumber`}
            label={
              <Title as="h4" className={classNames.label}>
                Estimate Number
              </Title>
            }
            initialValue={estimate?.estimateNumber}
            rules={[
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Please enter Estimate No.")
                    );
                  }
                  if (value && value.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              key={`estimateNumberInput`}
              type="number"
              size="small"
              style={{ width: 168 }}
              // defaultValue={estimate?.estimateNumber}
              disabled
            />
          </Form.Item>
          <Form.Item
            name="receiverName"
            key={`receiverName`}
            label={
              <Title as="h4" className={classNames.label}>
                Customer <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            initialValue={estimate?.receiverName}
          >
            <Select
              key={`receiverNameInput`}
              size="small"
              style={{ width: 352 }}
              // defaultValue={estimate?.receiverName}
              disabled
            >
              {Array.isArray(customers) &&
                customers.map((val, index) => (
                  <Select.Option key={`${val.name}`} value={val.id}>
                    {val.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item key={`inlineFields`}>
            <Form.Item
              key={`estimateDate`}
              name="estimateDate"
              label={<span className={classNames.label}>Date</span>}
              style={{
                display: "inline-block",
                marginRight: 14,
              }}
              initialValue={moment(estimate?.estimateDate)}
            >
              <DatePicker
                key={`estimateDateInput`}
                disabledDate={disabledDate}
                style={{ height: 44, width: 168 }}
                // defaultValue={moment(estimate.estimateDate)}
              />
            </Form.Item>

            <Form.Item
              key={`discount`}
              name="discount"
              label={<span className={classNames.label}>Discount</span>}
              style={{
                display: "inline-block",
              }}
              initialValue={estimate?.discount}
              rules={[
                {
                  pattern: new RegExp(
                    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,5})?$)/g
                  ),
                  message: "Valid Discount required.",
                },
              ]}
            >
              <Input
                type="number"
                key={`discountInput`}
                defaultValue={estimate.discount}
                size="small"
                style={{ width: 168 }}
                prefix="%"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="purchaseOrderNumber"
            key={`purchaseOrderNumber`}
            label={
              <Title as="h4" className={classNames.label}>
                Reference
              </Title>
            }
            initialValue={estimate?.purchaseOrderNumber}
            rules={[{ max: 50, message: "Max 50 characters" }]}
          >
            <Input
              key={`purchaseOrderNumberInput`}
              size="small"
              style={{ width: 352 }}
              // defaultValue={estimate?.purchaseOrderNumber}
            />
          </Form.Item>
        </div>
        <div key={`modalRight`} className={classNames.modalRight}>
          <div key={`invoiceSummary`} className={classNames.invoiceSummary}>
            <div key={`modalHeader`} className={classNames.modalHeader}>
              <Title key={`estimateSummary`} as="h3">
                Estimate Summary
              </Title>
            </div>
            <div>
              <span>Sub Total</span>
              <span>
                {intToDecimalDollar(Number(summary.subTotal)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Tax</span>
              <span>
                {intToDecimalDollar(Number(summary.tax)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Discount</span>
              <span>{discount}% </span>
            </div>
            <div className={classNames.total}>
              <span>Total</span>
              <span>
                {intToDecimalDollar(
                  Number(
                    summary.subTotal -
                      summary.subTotal * (discount / 100 || 0) +
                      summary.tax
                  )
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div key={`saleItemsWrapper`} className={classNames.saleItemsWrapper}>
        <div key={`modalHeader`} className={classNames.modalHeader}>
          <Title as="h3">Sale Items</Title>
        </div>
        <div className={classNames.salesItemsHeader}>
          <span
            style={{
              width: "35%",
              marginLeft: currFields > 1 ? "32px" : "",
              marginRight: currFields > 1 ? "-5px" : "5px",
            }}
          >
            Description <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 125 }}>
            Unit Cost <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 75 }}>
            Qty <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 115 }}>
            Tax <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 150 }}>Price</span>
        </div>
        <Form.List
          name="salesItemsEstimate"
          key={`saleItemsFormList`}
          rules={[
            {
              validator: async (_, salesItemsEstimate) => {
                if (!salesItemsEstimate || salesItemsEstimate.length < 1) {
                  return Promise.reject(new Error(validation.salesItems.error));
                }
              },
            },
          ]}
          initialValue={initialEstimatesSaleItems}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={`formInputGroup`}
                  required={false}
                  className={classNames.inputGroup}
                >
                  {fields.length > 1 ? (
                    <RiCloseCircleFill
                      key={`remove_btn_`}
                      size={20}
                      className={classNames.inputGroupRemoveIcon}
                      onClick={() => {
                        if (currFields > 1) {
                          calculatePrice(index, "remove");
                          remove(field.name);
                        } else {
                          form.resetFields();
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <AntInput.Group
                    key={`inputGroup`}
                    compact
                    className={classNames.inputGroupItems}
                  >
                    <Form.Item
                      name={[field.name, "description"]}
                      key={`description`}
                      style={{
                        width: "35%",
                        marginRight: "5px",
                      }}
                      rules={[
                        { required: true, message: "Required" },
                        () => ({
                          validator(_, value) {
                            if (value?.toString()?.length > 250) {
                              return Promise.reject(
                                new Error("Max 250 characters")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <AutoComplete
                        key={`descriptionInput`}
                        size="large"
                        style={{ width: "100%" }}
                        className={classNames.autoSelectDesc}
                        onChange={(value) => {
                          calculatePrice(index, "description", value);
                        }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={true}
                        options={
                          Array.isArray(products) &&
                          products.map((val, index) => {
                            return {
                              key: `${val.productName}}`,
                              label: val.productName,
                              value: val.productName,
                            };
                          })
                        }
                        mode="tags"
                        maxTagCount={1}
                      ></AutoComplete>
                    </Form.Item>
                    <Form.Item
                      key={`unitCost`}
                      name={[field.name, "unitCost"]}
                      rules={[
                        { required: true, message: "Required" },
                        {
                          pattern: new RegExp(
                            /^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/
                          ),
                          message: "Enter a valid price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value === "0" || value === 0) {
                              return Promise.reject(
                                new Error("0 is not allowed")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                        () => ({
                          validator(_, value) {
                            if (value?.toString()?.length > 11) {
                              return Promise.reject(
                                new Error("Max 8 digits with 2 decimal points")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        key={`unitCostInput`}
                        size="small"
                        placeholder="Enter"
                        prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                        style={{ width: 125, marginRight: "5px" }}
                        type="number"
                        min={0}
                        onChange={() => calculatePrice(index, "unitCost")}
                        maxLength={11}
                      />
                    </Form.Item>
                    <Form.Item
                      key={`quantity`}
                      name={[field.name, "quantity"]}
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Invalid quantity",
                        },
                        () => ({
                          validator(_, value) {
                            if (value && value?.toString()?.length > 6) {
                              return Promise.reject(
                                new Error("Max 6 characters")
                              );
                            }
                            if (!value?.toString()?.length) {
                              return Promise.reject(new Error("Required"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        key={`quantityInput`}
                        size="small"
                        placeholder="Enter"
                        style={{ width: 75, marginRight: "5px" }}
                        type="number"
                        min={0}
                        onChange={() => calculatePrice(index)}
                      />
                    </Form.Item>
                    <Form.Item name={[field.name, "taxRate"]} key={`taxRate`}>
                      <Select
                        key={`taxRateInput`}
                        size="small"
                        style={{ width: 115, marginRight: "5px" }}
                        className={classNames.salesItemSelect}
                        onChange={(value) => {
                          calculatePrice(index, "taxRate", value);
                        }}
                        showSearch
                        optionFilterProp="keyword"
                        filterOption={true}
                        // mode="tags"
                        // maxTagCount={1}
                        suffixIcon={
                          <span style={{ color: "#9ba3ab" }}>
                            %<RiArrowDropDownLine />
                          </span>
                        }
                        options={
                          Array.isArray(taxRates) &&
                          taxRates.map((val, index) => {
                            return {
                              key: `${val.taxRate}`,
                              label: `${val.taxRateName} (${val.taxRate}%)`,
                              value: Number(val.taxRate),
                              keyword: val.taxRate,
                            };
                          })
                        }
                      ></Select>
                    </Form.Item>
                    <Form.Item key={`price`} name={[field.name, "price"]}>
                      <Input
                        key={`priceInput`}
                        size="small"
                        placeholder="0"
                        prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                        style={{
                          width: 150,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          marginRight: "5px",
                        }}
                        disabled
                        // type="number"
                        //   disabled
                        //   value={getItemsPrice(
                        //     form.getFieldValue("Items")[index]
                        //   )}
                      />
                    </Form.Item>
                  </AntInput.Group>
                </Form.Item>
              ))}
              <Form.Item>
                <div
                  className={classNames.salesItemsFooter}
                  style={{ marginLeft: currFields === 1 ? "0" : "14px" }}
                >
                  <AntButton
                    onClick={() => {
                      const currentSaleItem =
                        form.getFieldsValue().salesItemsEstimate[
                          currFields - 1
                        ];
                      if (
                        currentSaleItem?.unitCost &&
                        currentSaleItem?.description
                      ) {
                        add();
                        setCurrFields((prev) => prev + 1);
                      }
                    }}
                    type="link"
                    className={classNames.addNewButton}
                    disabled={validationCheck()}
                  >
                    <RiAddFill size={13} style={{ marginRight: 5 }} />
                    Add new item
                  </AntButton>
                </div>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className={classNames.modalHeader}>
        <Title as="h3">More Information</Title>
      </div>
      <Form.Item>
        <Form.Item
          name="EstimateNotes"
          label={<span className={classNames.label}>Notes</span>}
          initialValue={invoiceTemplateSettings?.notes}
          style={{
            display: "inline-block",
            marginRight: 14,
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            // placeholder="Prefilled notes"
            disabled={true}
            autoSize={true}
            defaultValue={invoiceTemplateSettings?.notes}
          />
        </Form.Item>

        <Form.Item
          name="EstimateTerms"
          label={<span className={classNames.label}>Terms</span>}
          style={{
            display: "inline-block",
          }}
          initialValue={invoiceTemplateSettings?.terms}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            // placeholder="Prefilled estimate terms"
            disabled={true}
            autoSize={true}
            defaultValue={invoiceTemplateSettings?.terms}
          />
        </Form.Item>
      </Form.Item>
      <div className={classNames.modalBottom}>
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          onClick={() => form.submit()}
          style={{
            height: 44,
            width: 248,
          }}
          loading={editEstimate.isLoading}
          disabled={!form.isFieldsTouched()}
        >
          Save
        </CTAButton>
      </div>
    </Form>
  );
}
