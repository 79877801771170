import commonMessages from "../../util/constants";
import deepMerge from "../../util/functions/deepMerge";

const directoryMessages = {
  en: {
    DEPOSIT_CHECK: "Deposit Check",
    DEPOSIT_CHECK_SUCCESS: "Check Deposited Successfully",
    FRONT_IMAGE: `Check Front Image`,
    BACK_IMAGE: `Check Back Image`,
    DRAG_DROP: `Drag & Drop or`,
    IMAGE_UPLOAD_BTN_TEXT: `click here to upload`,
    DEPOSIT: `Deposit`,
    INSTRUCTIONS: `Instructions`,
    INSTRUCTIONS_1: `Please Upload clear images of Front and Back of the Check`,
    INSTRUCTIONS_2: `Please ensure that all text on the images is readable`,
    INSTRUCTIONS_3: `Please ensure that the full check is visible and not cut from any of
        the sides or corners`,
    INSTRUCTIONS_4: `Single check deposit cannot exceed $1,000`,
    INSTRUCTIONS_5: `Please upload files in one of these formats : JPEG, JPG or PNG`,
    INSTRUCTIONS_6: `The file size should not exceed 20MB.`,
    INSTRUCTIONS_7: `Place the check on a flat, uniform background before taking a clear and centered photo for uploading.`,
    INSTRUCTIONS_8: `The check should be endorsed, 'For mobile deposit only at Otterz, Inc.'`,
    CHECK_LIMITS: `Check Deposit limits`,
    DAILY: `Daily`,
    MONTHLY: `Monthly`,
    SELECT_JPEG: "File type is not allowed. Please select a JPEG, JPG or PNG",
    AWAITING_IMAGES: "Awaiting Images",
    AWAITING_FRONT: "Awaiting Front Images",
    AWAITING_BACK: "Awaiting Back Images",
    PENDING: "DEPOSITED",
    PENDING_REVIEW: "Pending Review",
    CLEARING: "Clearing",
    REJECTED: "Rejected",
    CANCELED: "Canceled",
    RETURNED: "Returned",
    CHECK_ID: "Check Number",
    MANAGE_CHECKS: "Manage Checks",
    SEND_CHECK: "Send Check",
    COMING_SOON: "Coming soon",
    CHECK_DEPOSITED: "Checks Deposited",
    ALL: "ALL",
    SENT: "CLEARED",
    FAILED: "FAILED",
    // Payments
    DAILY_ACH_CREDIT_LIMIT_EXCEEDED: `Payment rejected due to exceeding ACH credit limit.`,
    PAYMENT_DELETED: `Payment deleted!`,
    PAYMENT_UPDATED_SUCCESS: `Payment updated successfully!`,
    NEW_RECURRING_PAYMENT_CREATED: "New Recurring Payment created",
    PAYMENT_FROZEN_SUCCESS: "Payment successfully frozen!",
    PAYMENT_UNFROZEN_SUCCESS: "Payment successfully unfrozen!",
    WIRE_PAYMENT_SUCCESS: "Wire transfer sent successfully!",
    //Cards
    USAGE_LIMITS: "Usage Limits",
    DAILY_WITHDRAWAL_LIMIT: "Daily Withdrawal Limit",
    DAILY_PURCHASE_LIMIT: "Daily Purchase Limit",
    MONTHLY_WITHDRAWAL_LIMIT: "Monthly Withdrawal Limit",
    MONTHLY_PURCHASE_LIMIT: "Monthly Purchase Limit",
    DAILY_WITHDRAWAL_VALIDATION:
      "Daily withdrawal cannot be greater than the monthly withdrawal.",
    DAILY_PURCHASE_VALIDATION:
      "Daily purchase cannot be greater than the monthly purchase.",
    MONTHLY_WITHDRAWAL_VALIDATION:
      "Monthly withdrawal cannot be less than the daily withdrawal.",
    MONTHLY_PURCHASE_VALIDATION:
      "Monthly purchase cannot be less than daily purchase.",
    SELECT_PERSON: "Whom do you want to issue card ?",
  },
};

const messages = deepMerge(commonMessages, directoryMessages);

export default messages;
