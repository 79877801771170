import { Button } from "antd";

import logo from "../../../assets/logo.svg";
import useDeviceOS from "../../../hooks/useOs";

import classNames from './DownloadApplication.module.scss'

function DownloadApplication() {
    const os = useDeviceOS();
    const isIos = os === 'iOS'
    return (
        <div className={classNames.wrapper}>
            <img src={logo} alt="logo" className={classNames.logo} />
            <div className={classNames.description}>
                Enhance your experience!
            </div>
            <div className={classNames.description}>
                Download our app for seamless access to our platform.
            </div>
            <Button
                className={classNames.button}
                type="primary"
                    onClick={() => {
                        window.open(isIos ? 'https://apps.apple.com/us/app/otterz/id6444387482' : 'https://play.google.com/store/apps/details?id=co.otterz.app')
                    }}
            >
            Download
            </Button>
        </div>
    );
}

export default DownloadApplication;
