import { useContext } from "react";
import { Form, Button } from "antd";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Title, Input, Message } from "../../../../common";
// import { useLocalStorageData } from "../../../../../hooks/useLocalStorage";
import api from "../../../../API";
import REGEX from "../../../../util/constants/regex";
import { MAX_MIN } from "../../../../util/constants";
import generateErrorMessage from "../../../../util/functions/customError";
import { validation } from "../../../../Auth/config";
import { ROUTES } from "../../../../constants";
import messages from "../config/common.message";
import { AppContext } from "../../../../../context/AppState";
import classNames from "../Onboarding.module.scss";

const Callback = ({ setStep, validKey }) => {
  //Hooks
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const userData = useLocalStorageData("OTTERZ.userData");
  const { userData } = useContext(AppContext);
  const email = userData?.email;

  const { application } = useContext(AppContext);

  //Api calls
  const callbackRequestMn = useMutation((event) => callbackRequest(event));

  const sendEmailInternal = useMutation((data) =>
    api.Receivables.PaymentAcceptOnboard.sendInternalMail(data, false)
  );

  function callbackRequest(data) {
    return api.Receivables.PaymentAcceptOnboard.callbackRequest(data);
  }

  const { refetch: fetchBankVerificationLetter } = useQuery(
    "getBankVerificationLetter",
    () =>
      api.BusinessBanking.getBankVerificationLetter(
        application?.otz_account_id
      ),
    { enabled: false, refetchOnWindowFocus: false }
  );

  //Event handlers
  const onFinish = (data) => {
    callbackRequestMn.mutate(data, {
      onSuccess: (data, variables, context) => {
        Message({ type: "success", content: "Callback Request sent!" });
        fetchBankVerificationLetter();
        setStep(6);
        form.resetFields();
      },
      onError: (error) => generateErrorMessage(error),
    });

    const reqBody = {
      templateName: "PAYMENT_ACCEPTANCE_HARDWARE_ONBOARDING_REQUEST",
      tenantId: userData?.tenantId,
      formData: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        mobile: data?.phoneNumber,
        type: messages?.callBackText[validKey]?.type,
        email: data?.email,
      },
    };
    sendEmailInternal.mutate(reqBody);
  };

  return (
    <div className={classNames.formWrapper}>
      <Title as="h3">Request a call back</Title>
      <div className={classNames.subheading}>
        Please fill the following form, one of our representatives will get in
        touch with you soon.
      </div>
      {email && (
        <Form
          layout="vertical"
          onFinish={onFinish}
          hideRequiredMark
          initialValues={{ email }}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item>
            <Form.Item
              name={"firstName"}
              label={
                <Title as="h4" className={classNames.label}>
                  First Name <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                { required: true, message: validation.firstName.required },
                {
                  max: MAX_MIN.name.max,
                  message: validation.firstName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.firstName.valid,
                },
              ]}
            >
              <Input
                placeholder={validation.firstName.placeholder}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              name={"lastName"}
              label={
                <Title as="h4" className={classNames.label}>
                  Last Name <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                { required: true, message: validation.lastName.required },
                {
                  max: MAX_MIN.name.max,
                  message: validation.lastName.maxLength,
                },
                {
                  pattern: new RegExp(REGEX.name),
                  message: validation.lastName.valid,
                },
              ]}
            >
              <Input
                placeholder={validation.lastName.placeholder}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name={"phoneNumber"}
            label={
              <Title as="h4" className={classNames.label}>
                Phone Number <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
            rules={[
              { required: true, message: validation.phoneNumber.required },
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: validation.phoneNumber.valid,
              },
            ]}
          >
            <Input
              placeholder={validation.phoneNumber.placeholder}
              prefix="+1"
            />
          </Form.Item>
          <Form.Item hidden name="countryCode" initialValue="1">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name={"email"}
            label={
              <Title as="h4" className={classNames.label}>
                Email Address <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
          >
            <Input disabled />
          </Form.Item>
          <div className={classNames.footerButtonWrapper}>
            {validKey !== "isOnBoard" ? (
              <Button
                onClick={() => {
                  navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE);
                }}
                disabled={callbackRequestMn.isLoading}
                className={classNames.nextButton}
                style={{ marginRight: "20px" }}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              loading={callbackRequestMn.isLoading}
              htmlType="submit"
              type="primary"
              className={classNames.nextButton}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Callback;
