// login action types
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_LOADING";
export const LOGIN_ERROR = "LOGIN_LOADING";

// get-application-by-tenanat-id action types
export const GET_APPLICATION_BY_TENANT_ID_LOADING =
  "GET_APPLICATION_BY_TENANT_ID_LOADING";
export const GET_APPLICATION_BY_TENANT_ID_SUCCESS =
  "GET_APPLICATION_BY_TENANT_ID_SUCCESS";
export const GET_APPLICATION_BY_TENANT_ID_RESET =
  "GET_APPLICATION_BY_TENANT_ID_RESET";
export const GET_APPLICATION_BY_TENANT_ID_ERROR =
  "GET_APPLICATION_BY_TENANT_ID_ERROR";

// get-application-by-id action types
export const GET_APPLICATION_BY_ID_LOADING = "GET_APPLICATION_BY_ID_LOADING";
export const GET_APPLICATION_BY_ID_SUCCESS = "GET_APPLICATION_BY_ID_SUCCESS";
export const GET_APPLICATION_BY_ID_RESET = "GET_APPLICATION_BY_ID_RESET";
export const GET_APPLICATION_BY_ID_ERROR = "GET_APPLICATION_BY_ID_ERROR";

export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";

export const SET_LOGGED_USER_DATA = "SET_LOGGED_USER_DATA";
export const CLEAR_LOGGED_USER_DATA = "CLEAR_LOGGED_USER_DATA";

// get-business-person-detail action types
export const GET_BUSINESS_PERSON_DETAIL_LOADING =
  "GET_BUSINESS_PERSON_DETAIL_LOADING";
export const GET_BUSINESS_PERSON_DETAIL_SUCCESS =
  "GET_BUSINESS_PERSON_DETAIL_SUCCESS";
export const GET_BUSINESS_PERSON_DETAIL_RESET =
  "GET_BUSINESS_PERSON_DETAIL_RESET";
export const GET_BUSINESS_PERSON_DETAIL_ERROR =
  "GET_BUSINESS_PERSON_DETAIL_ERROR";
export const CLEAR_BUSINESS_PERSON_DETAIL_DATA =
  "CLEAR_BUSINESS_PERSON_DETAIL_DATA";
