import { useQuery, useQueryClient } from "react-query";
import { useContext, useEffect, useState } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import moment from "moment";
import {
  Card,
  Table,
  Space,
  Radio,
  Empty,
  Button as AntButton,
  Form,
} from "antd";
import { Title, Header } from "../../common";
import DepositCheck from "./DepositCheck/DepositCheck";
import api from "../../API";
import useLanguage from "../../../hooks/useLanguage";
import { AppContext } from "../../../context/AppState";
import { centsToDollar } from "../../util/functions/dollarConvertion";
import generateErrorMessage from "../../util/functions/customError";
import { PAGINATION, PAGINATION_DEFAULT } from "../../util/constants";
import messages from "../config/messages.const";
import { FORMATS } from "../../constants";
import noImgIcon from "../../../assets/common/table/no-img.svg";
import classNames from "./ManageChecks.module.scss";
import AccountActivityModal from "../config/accountActivityModal";

export default function ManageChecks() {
  //Hooks
  const [form] = Form.useForm();
  const language = useLanguage(messages);
  const { application } = useContext(AppContext);
  const [depositCheckModalVisibility, setDepositCheckModalVisibility] =
    useState(false);
  const [status, setStatus] = useState("All");
  const [sanitizedData, setSanitizedData] = useState();
  const [pagination, setPagination] = useState(PAGINATION);

  const [accountActivityVisible, setAccountActivityVisible] =
  useState(false);
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);
  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };

  const initCheckDeposit = () => {
    if(isAccountInfoNeeded) {
      setAccountActivityVisible(true);
    } else {
      setDepositCheckModalVisibility(true);
    }
  };
  const queryClient = useQueryClient();

  //Other variables
  const columns = [
    {
      title: language.CHECK_ID,
      dataIndex: "checkNumber",
      key: "checkNumber",
    },
    {
      title: language.DATE,
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return moment(date, FORMATS.API_SEND_FORMAT).format(
          FORMATS.DATEPICKER_FORMAT
        );
      },
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a?.date, FORMATS.API_SEND_FORMAT).diff(
          moment(b?.date, FORMATS.API_SEND_FORMAT)
        );
      },
    },
    {
      title: language.AMOUNT,
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <span>{centsToDollar(amount)}</span>;
      },
    },
    {
      title: language.STATUS,
      dataIndex: "status",
      key: "status",
    },
  ];

  //Api calls
  const { isLoading: checksDepositedIsLoading, data: checksDepositedPayload } =
    useQuery(
      "getCheckDeposits",
      () =>
        api.ManageChecks.listChecksDeposited({
          otz_account_id: application?.otz_account_id,
        }),
      {
        onError: (error) => generateErrorMessage(error),
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    setPagination(PAGINATION);
    if (status === "All") {
      setSanitizedData(
        checksDepositedPayload?.payload &&
          checksDepositedPayload?.payload?.deposits &&
          checksDepositedPayload?.payload?.deposits.length > 0 &&
          checksDepositedPayload?.payload?.deposits
            .map((value, index) => {
              return {
                key: value.id,
                image: <img alt="img" src={noImgIcon} />,
                date: value?.creation_time,
                checkNumber: value?.ocr_check_number,
                amount: value?.check_amount,
                status: renderStatus(value?.status, language),
              };
            })
            ?.sort((a, b) =>
              moment(a?.date, FORMATS.API_SEND_FORMAT)?.diff(
                moment(b?.date, FORMATS.API_SEND_FORMAT)
              )
            )
            ?.reverse()
      );
    } else if (status === "Sent") {
      setSanitizedData(
        checksDepositedPayload?.payload &&
          checksDepositedPayload?.payload?.deposits &&
          checksDepositedPayload?.payload?.deposits.length > 0 &&
          checksDepositedPayload?.payload?.deposits
            .filter((value) => value?.status === "SUBMITTED")
            .map((value, index) => {
              return {
                key: value?.id,
                image: <img alt="img" src={noImgIcon} />,
                date: value?.creation_time,
                checkNumber: value?.ocr_check_number,
                amount: value?.check_amount,
                status: renderStatus(value?.status, language),
              };
            })
            ?.sort((a, b) =>
              moment(a?.date, FORMATS.API_SEND_FORMAT)?.diff(
                moment(b?.date, FORMATS.API_SEND_FORMAT)
              )
            )
            ?.reverse()
      );
    } else if (status === "Failed") {
      setSanitizedData(
        checksDepositedPayload?.payload &&
          checksDepositedPayload?.payload?.deposits &&
          checksDepositedPayload?.payload?.deposits.length > 0 &&
          checksDepositedPayload?.payload?.deposits
            .filter((value) => value?.status === "FAILED")
            .map((value, index) => {
              return {
                key: value?.id,
                image: <img alt="img" src={noImgIcon} />,
                date: value?.creation_time,
                checkNumber: value?.ocr_check_number,
                amount: value?.check_amount,
                status: renderStatus(value?.status, language),
              };
            })
            ?.sort((a, b) =>
              moment(a?.date, FORMATS.API_SEND_FORMAT)?.diff(
                moment(b?.date, FORMATS.API_SEND_FORMAT)
              )
            )
            ?.reverse()
      );
    } else {
      setSanitizedData(
        checksDepositedPayload?.payload &&
          checksDepositedPayload?.payload?.deposits &&
          checksDepositedPayload?.payload?.deposits.length > 0 &&
          checksDepositedPayload?.payload?.deposits
            .filter((value) => value.status === "PENDING")
            .map((value, index) => {
              return {
                key: value.id,
                image: <img alt="img" src={noImgIcon} />,
                date: value?.creation_time,
                checkNumber: value?.ocr_check_number,
                amount: value?.check_amount,
                status: renderStatus(value?.status, language),
              };
            })
            ?.sort((a, b) =>
              moment(a?.date, FORMATS.API_SEND_FORMAT)?.diff(
                moment(b?.date, FORMATS.API_SEND_FORMAT)
              )
            )
            ?.reverse()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    status,
    checksDepositedPayload?.payload?.deposits,
    sanitizedData?.length,
    language,
  ]);

  //Event handlers
  const handleDepositCheckModalCancelClick = () => {
    form.resetFields();
    refreshScreen();
    setDepositCheckModalVisibility(false);
  };

  //JSX
  const renderStatus = (status, language) => {
    let render;
    switch (status) {
      case "AwaitingImages":
        render = (
          <span style={{ color: "#BC7C1D" }}>{language.AWAITING_IMAGES}</span>
        );
        break;
      case "AwaitingFrontImage":
        render = (
          <span style={{ color: "#BC7C1D" }}>{language.AWAITING_FRONT}</span>
        );
        break;
      case "AwaitingBackImage":
        render = (
          <span style={{ color: "#BC7C1D" }}>{language.AWAITING_BACK}</span>
        );
        break;
      case "PENDING":
        render = <span style={{ color: "#BC7C1D" }}>{language.PENDING}</span>;
        break;
      case "PendingReview":
        render = (
          <span style={{ color: "#BC7C1D" }}>{language.PENDING_REVIEW}</span>
        );
        break;
      case "Clearing":
        render = <span style={{ color: "#BC7C1D" }}>{language.CLEARING}</span>;
        break;
      case "SUBMITTED":
        render = <span style={{ color: "#1DBC83" }}>{language.SENT}</span>;
        break;
      case "REJECTED":
        render = <span style={{ color: "#F85734" }}>{language.REJECTED}</span>;
        break;
      case "FAILED":
        render = <span style={{ color: "#F85734" }}>{language.FAILED}</span>;
        break;

      case "Canceled":
        render = <span style={{ color: "#F85734" }}>{language.CANCELED}</span>;
        break;
      default:
        render = <span style={{ color: "#F85734" }}>{language.RETURNED}</span>;
    }
    return render;
  };

  //Helper functions
  const refreshScreen = () => queryClient.invalidateQueries("getCheckDeposits");

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title={language.MANAGE_CHECKS} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Card className={classNames.optionCardLight}>
              <div className={classNames.cardAction}>
                <div>
                  <span>{language.SEND_CHECK}</span>
                  <span className={classNames.optionCardComingSoonLabel}>
                    {language.COMING_SOON}
                  </span>
                </div>
                <RiArrowRightLine
                  size={30}
                  color="#fff"
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
            <Card
              className={classNames.optionCard}
              onClick={() => initCheckDeposit()}
            >
              <div className={classNames.cardAction}>
                <span> {language.DEPOSIT_CHECK}</span>
                <RiArrowRightLine
                  size={30}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <Card className={classNames.tableCard}>
              <div className={classNames.tableCardHeader}>
                <Title as="h3">{language.CHECK_DEPOSITED}</Title>
              </div>
              <div className={classNames.tableCardFilters}>
                <Radio.Group
                  defaultValue="All"
                  buttonStyle="solid"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <Space size={0}>
                    <Radio.Button value="All">{language.ALL}</Radio.Button>
                    <Radio.Button value="Pending">
                      {language.PENDING}
                    </Radio.Button>
                    <Radio.Button value="Sent">{language.SENT}</Radio.Button>
                    <Radio.Button value="Failed">
                      {language.FAILED}
                    </Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
              <Table
                loading={checksDepositedIsLoading}
                dataSource={!!sanitizedData && sanitizedData}
                columns={columns}
                className={classNames.chequeTable}
                size="large"
                pagination={{
                  ...PAGINATION_DEFAULT,
                  current: pagination.page,
                  pageSize: pagination.pageSize,
                  onChange: (page, pageSize) => {
                    setPagination({ page: page, pageSize: pageSize });
                  },
                  itemRender: (page, type, originalElement) => {
                    if (type === "prev") {
                      return (
                        <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                      );
                    } else if (type === "next") {
                      return (
                        <AntButton
                          style={{ color: "#9f9cff", marginLeft: "32px" }}
                        >
                          Next
                        </AntButton>
                      );
                    }
                  },
                }}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"No Data Found"}
                    />
                  ),
                }}
              />
            </Card>
          </div>
        </div>
      </div>
      <DepositCheck
        visible={depositCheckModalVisibility}
        onCancel={handleDepositCheckModalCancelClick}
        form={form}
        personId={application?.otz_person_id}
        businessId={application?.otz_business_id}
        accountId={application?.otz_account_id}
      />
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setDepositCheckModalVisibility}
        spawnModal={setAccountActivityVisible}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        title="Deposit Check"
      />
    </div>
  );
}
