import { Card, Dropdown, Menu, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  RiAnticlockwise2Line,
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiArrowLeftDownLine,
  RiArrowRightLine,
  RiArrowRightUpLine,
  RiArrowUpSFill,
  RiDatabase2Line,
  RiEdit2Line,
  RiHonourLine,
  RiMoneyDollarCircleLine,
  RiUser2Line,
} from "react-icons/ri";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  FlexibleXYPlot,
  GradientDefs,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";

// import useIFieldKey from "../../../../hooks/useIFieldKey";
// import { useLocalStorageData } from "../../../../hooks/useLocalStorage";

import api from "../../../API";
import { Header, Title } from "../../../common";
import { ROUTES, STATUS } from "../../../constants";

import VirtualTerminal from "../Transactions/AddNewTransaction/AddNewTransactions";

import HostedPayment from "./HostedPayment/HostedPayment";
import PaymentRequests from "./PaymentRequests/PaymentRequests";
import QrModal from "./QrModal/QrModal";
import DownloadModal from "../../../BusinessBanking/Overview/BankAccountDetails/DownloadModal/DownloadModal";
import RecentTransactions from "./RecentTransactions/RecentTransactions";
import Settings from "./Settings/Settings";

import { uuidv4 } from "../../../util/functions/uuidV4";
import { toastPAMerchantSettingsStatus } from "../../../Messages/paymentAcceptance";
import classNames from "./PaymentAcceptanceOverview.module.scss";
import { AppContext } from "../../../../context/AppState";

export default function PaymentAcceptanceOverview() {
  const navigate = useNavigate();
  const { application } = useContext(AppContext);

  const { data: onboardRequest, isLoading } = useQuery(
    "getOnboardRequest",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onboardStatus = onboardRequest?.data?.onboardStatus;

  const { data: details, isLoading: isLoadingDetails } = useQuery(
    "getTransactions",
    () => api.Receivables.PaymentAcceptance.getDashboardDeatils(),
    {
      refetchOnWindowFocus: false,
      // enabled: onboardStatus === STATUS.APPROVED,
      enabled: true,
    }
  );

  const { data: merchantDetail, isFetching } = useQuery(
    ["getMerchantDetail"],
    () => api.Receivables.PaymentAcceptance.getMerchantDetail(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  //Api calls
  const { data: accountDetail } = useQuery(
    "getBankLetterDetail",
    () =>
      api.BusinessBanking.getBankLetterDetail(
        application?.otz_account_id
      ),
    {
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (!isLoading && onboardStatus !== STATUS.APPROVED)
      navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD);
  }, [isLoading, onboardStatus, navigate]);

  const informationMenuItems = [
    { name: "Customers", path: "customers-payment-acceptance" },
    { name: "Reports", path: "reports" },
  ];

  const activityMenuItems = [
    { name: "Transactions", path: "transactions" },
    // TODO: post-mvp { name: "Gift Card Reports", path: "gift-card-reports" },
    // TODO: post-mvp { name: "Batches", path: "batches" },
    { name: "Recurring Schedules", path: "recurring-schedules" },
  ];

  const [qrModal, setQrModal] = useState(false);
  const handleQrModalCancelClick = () => {
    setQrModal(false);
  };
  const [virtualTerminal, setVirtualTerminal] = useState(false);
  const handleVirtualTerminalModalCancelClick = () => {
    setVirtualTerminal(false);
  };
  const [hostedPayment, setHostedPayment] = useState(false);
  const handleHostedPaymentModalCancelClick = () => {
    setHostedPayment(false);
  };
  const [paymentRequests, setPaymentRequests] = useState(false);

  const handlePaymentRequestsModalCancelClick = () => {
    setPaymentRequests(false);
  };

  const [shareModalVisibility, setShareModalVisibility] = useState(false);
  const handleShareModalCancelClick = () => {
    setShareModalVisibility(false);
  };

  const [settingsVisibility, setSettingsVisibility] = useState(false);

  const handleSettingsCancelClick = () => {
    setSettingsVisibility(false);
  };

  const [isPASettingsAvailable, setPASettingsAvailability] = useState(false);

  const menu = (menuArray) => (
    <Menu className={classNames.menuWrapper}>
      {menuArray.map((items) => (
        <Menu.Item
          key={items.name.replace(" ", "_")}
          className={classNames.menuItem}
        >
          <Link to={`/app/receivables/${items.path}`}>
            <span className={classNames.menuItemTitle}>
              <span> {items.name}</span>
            </span>
          </Link>
          <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
        </Menu.Item>
      ))}
    </Menu>
  );

  // const userData = useLocalStorageData("OTTERZ.userData");
  const { userData } = useContext(AppContext);
  const tenantId = userData?.tenantId;

  const [queryString, setQueryString] = useState(() => {});

  useEffect(() => {
    const id = uuidv4();
    const queryParams = {
      infoEditable: "true",
      amountEditable: "true",
      tenantId,
    };
    setQueryString(
      `${new URL(window.location.href).origin}/payment/summary?id=${id}&` +
        Object.keys(queryParams)
          .map((param) => `${param}=${window.btoa(queryParams[param] || "")}`)
          .join("&")
    );
  }, [tenantId]);

  const { data: getPASettings, isLoading: getPASettingsLoading = false } =
    useQuery(
      "getPASettings",
      () =>
        api.Receivables.PaymentAcceptance.getPASettings({
          tenantId,
        }),
      {
        refetchOnWindowFocus: false,
        enabled: !!tenantId,
      }
    );

  useEffect(() => {
    setPASettingsAvailability(!!getPASettings?.data?.companyName);
  }, [getPASettings?.data?.companyName]);

  const onPaymentRequestClick = () => {
    if (!isPASettingsAvailable) {
      return toastPAMerchantSettingsStatus();
    }
    setPaymentRequests(true);
  };

  const onHostedPaymentPageClick = () => {
    const id = uuidv4();
    if (!isPASettingsAvailable) {
      setSettingsVisibility(true);
    } else {
      window.open(
        `${
          new URL(window.location.href).origin
        }/payment/summary?amountEditable=dHJ1ZQ==&infoEditable=dHJ1ZQ==&id=${id}&tenantId=${window.btoa(
          tenantId
        )}`
      );
    }
  };

  const receivePaymentsMenu = (
    <Menu className={classNames.menuWrapper}>
      <Menu.Item
        key="Virtual_Terminal"
        className={classNames.menuItem}
        onClick={() => {
          setVirtualTerminal(true);
        }}
      >
        <span className={classNames.menuItemTitle}>
          <span> Virtual Terminal</span>
        </span>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key="payment_requests"
        className={classNames.menuItem}
        onClick={onPaymentRequestClick}
        disabled={getPASettingsLoading}
      >
        <Spin spinning={getPASettingsLoading} size="default">
          <span className={classNames.menuItemTitle}>
            <span> Payment Requests</span>
          </span>
        </Spin>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key="Hosted_Payment_Page"
        className={classNames.menuItem}
        onClick={onHostedPaymentPageClick}
        disabled={getPASettingsLoading}
      >
        <Spin spinning={getPASettingsLoading} size="default">
          <span className={classNames.menuItemTitle}>
            <span> Hosted Payment Page</span>
          </span>
        </Spin>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key={"On-Field_QR_Code"}
        className={classNames.menuItem}
        onClick={() => {
          setQrModal(true);
        }}
      >
        <Spin spinning={!queryString}>
          <span className={classNames.menuItemTitle}>
            <span> On-Field QR Code</span>
          </span>
        </Spin>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key={"Account_details"}
        className={classNames.menuItem}
        onClick={() => {
          setShareModalVisibility(true);
        }}
      >
        <Spin spinning={!queryString}>
          <span className={classNames.menuItemTitle}>
            <span> Share ACH Details</span>
          </span>
        </Spin>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>
    </Menu>
  );

  const omniChannelMenu = (
    <Menu className={classNames.menuWrapper}>
      <Menu.Item
        key={"Order_Devices"}
        className={classNames.menuItem}
        onClick={() => {
          navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD, {
            state: {
              isOnBoard: false,
              isOrder: true,
              isEcommerce: false,
              isSetupHardWare: false,
            },
          });
        }}
      >
        <span className={classNames.menuItemTitle}>
          <span> Order Devices</span>
        </span>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key={"E-Commerce_Integrations"}
        className={classNames.menuItem}
        onClick={() => {
          navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD, {
            state: {
              isOnBoard: false,
              isOrder: false,
              isEcommerce: true,
              isSetupHardWare: false,
            },
          });
        }}
      >
        <span className={classNames.menuItemTitle}>
          <span> E-Commerce Integrations</span>
        </span>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>

      <Menu.Item
        key={"Setup_Hardware"}
        className={classNames.menuItem}
        onClick={() => {
          navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE_ONBOARD, {
            state: {
              isOnBoard: false,
              isOrder: false,
              isEcommerce: false,
              isSetupHardWare: true,
            },
          });
        }}
      >
        <span className={classNames.menuItemTitle}>
          <span>Setup Hardware</span>
        </span>
        <RiArrowRightLine size={16} className={classNames.arrowBtnWithBg} />
      </Menu.Item>
    </Menu>
  );

  const selectArrow = (value) => {
    if (value < 1) {
      return <RiArrowDownSFill size={20} color="#EE6969" />;
    }
    return <RiArrowUpSFill size={24} color="#1DBC83" />;
  };

  return (
    <div className={classNames.wrapper}>
      {(onboardStatus === STATUS.APPROVED && (
        <>
          <div className={classNames.headerWrapper}>
            <Header title="Payment Acceptance" />
          </div>
          <div className={classNames.layout}>
            <div className={classNames.bodyWrapper}>
              <div className={classNames.body}>
                <Card className={classNames.tableCard}>
                  <div className={classNames.tableCardHeader}>
                    <div className={classNames.headerLeft}>
                      <Title as="h3">Total Sales</Title>
                      {/* NOTE: COMMENTED FOR FUTURE USE */}
                      {/* <div className={classNames.salesBottomCardFilters}>
                        <Radio.Group defaultValue="a" buttonStyle="solid">
                          <Space size={0}>
                            <Radio.Button value="a">H</Radio.Button>
                            <Radio.Button value="b">D</Radio.Button>
                            <Radio.Button value="c">W</Radio.Button>
                            <Radio.Button value="d">M</Radio.Button>
                            <Radio.Button value="e">Y</Radio.Button>
                          </Space>
                        </Radio.Group>
                      </div> */}
                    </div>

                    <div className={classNames.netSales}>
                      <div className={classNames.amount}>
                        <Title as="h3">Net Sales</Title>
                        <div>
                          <sup
                            style={{
                              fontSize: "12px",
                              marginRight: "2px",
                              fontWeight: "normal",
                            }}
                          >
                            $
                          </sup>
                          {details?.data?.netSales?.value}
                        </div>
                        <span>
                          {selectArrow(details?.data?.netSales?.percentage)}
                          <span>{details?.data?.netSales?.percentage}%</span>
                          <span style={{ color: "#9ba3ab" }}>
                            (${details?.data?.netSales?.pastDayValue})
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <Spin spinning={isLoadingDetails}>
                    <div className={classNames.tableCardBody}>
                      <FlexibleXYPlot
                        xType="ordinal"
                        margin={{ left: 55, right: 0, top: 10, bottom: 30 }}
                        animation={true}
                      >
                        <XAxis />
                        <YAxis />
                        <HorizontalGridLines />
                        <GradientDefs>
                          <linearGradient
                            x1="0"
                            x2="0"
                            y1="0"
                            y2="1"
                            id="barGradient"
                          >
                            <stop
                              offset="0%"
                              stopColor="#e4fcf1"
                              stopOpacity="-0.1"
                            />
                            <stop offset="100%" stopColor="#83DEB3" />
                          </linearGradient>
                        </GradientDefs>

                        <LineSeries
                          animation={true}
                          data={details?.data?.sales}
                          colorType="literal"
                          color={"#83DEB3"}
                          barWidth={0.6}
                          curve={"curveMonotoneX"}
                        />

                        <VerticalBarSeries
                          animation={true}
                          data={details?.data?.sales}
                          colorType="literal"
                          color={"url(#barGradient)"}
                          barWidth={0.5}
                        />
                      </FlexibleXYPlot>
                    </div>
                  </Spin>

                  <div className={classNames.todayStats}>
                    <div className={classNames.tableCardHeader}>
                      <Title as="h3">Today</Title>
                    </div>
                    <div className={classNames.todayStatsBody}>
                      <div className={classNames.transactions}>
                        <div className={classNames.icon}>
                          <RiArrowRightUpLine size={20} />
                        </div>
                        <div className={classNames.amount}>
                          <Title as="h3">No.of Transactions</Title>
                          <div>
                            {parseInt(details?.data?.transactionCount?.value) ||
                              "0"}
                          </div>
                          <span>
                            {selectArrow(
                              details?.data?.transactionCount?.percentage
                            )}
                            <span>
                              {details?.data?.transactionCount?.percentage}%
                            </span>
                            <span style={{ color: "#9ba3ab" }}>
                              (${details?.data?.transactionCount?.pastDayValue})
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className={classNames.separator}></div>
                      <div className={classNames.avgTicketSize}>
                        <div className={classNames.icon}>
                          <RiArrowLeftDownLine size={20} />
                        </div>
                        <div className={classNames.amount}>
                          <Title as="h3">Avg. Ticket Size</Title>
                          <div>
                            <sup
                              style={{
                                fontSize: "12px",
                                marginRight: "2px",
                              }}
                            >
                              $
                            </sup>
                            {details?.data?.avgTicket?.value || "0.00"}
                          </div>
                          <span>
                            {selectArrow(details?.data?.avgTicket?.percentage)}
                            <span>
                              {details?.data?.avgTicket?.percentage || 0}%
                            </span>
                            <span style={{ color: "#9ba3ab" }}>
                              ($
                              {isNaN(details?.data?.avgTicket?.amount)
                                ? "0.00"
                                : details?.data?.avgTicket?.amount}
                              )
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className={classNames.separator}></div>
                      <div className={classNames.refunds}>
                        <div className={classNames.icon}>
                          <RiArrowRightUpLine size={20} />
                        </div>
                        <div className={classNames.amount}>
                          <Title as="h3">Refunds</Title>
                          <div>
                            <sup
                              style={{
                                fontSize: "12px",
                                marginRight: "2px",
                              }}
                            >
                              $
                            </sup>
                            {details?.data?.refund?.value || "0.00"}
                          </div>
                          <span>
                            {selectArrow(details?.data?.refund?.percentage)}
                            <span>
                              {details?.data?.refund?.percentage || 0}%
                            </span>
                            <span style={{ color: "#9ba3ab" }}>
                              (${details?.data?.refund?.pastDayValue})
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <div>
                  <RecentTransactions />
                  <Card className={classNames.tableCard}>
                    <div className={classNames.tableCardHeader}>
                      <Title as="h3">Merchant Account Details</Title>
                      {/* COMMENTED FOR FUTURE USE
                      <AntButton
                        type="link"
                        onClick={() =>
                          navigate("/app/receivables/all-estimates")
                        }
                        icon={<RiShareBoxFill />}
                      /> */}
                    </div>
                    <div className={classNames.merchantAccountDetails}>
                      {/* <div className={classNames.merchantDetailsTopRow}>
                        <span>Business Name</span>
                        <Title as="h3">ABC & Company</Title>
                      </div> */}
                      {isFetching ? (
                        <div className={classNames.merchantAccountDetailsSpin}>
                          <Spin />
                        </div>
                      ) : !merchantDetail?.payload?.mid ? (
                        <span>
                          Your Merchant Details will appear here after your
                          application for Payment Acceptance is approved.
                        </span>
                      ) : (
                        <div className={classNames.merchantDetails}>
                          <div>
                            <span>Business Name</span>
                            <Title as="h4">
                              {merchantDetail?.payload?.corporateName || "-"}
                            </Title>
                          </div>
                          <div>
                            <span>Merchant ID</span>
                            <Title as="h4">
                              {merchantDetail?.payload?.mid || "-"}
                            </Title>
                          </div>
                          <div>
                            <span>Business Contact Name</span>
                            <Title as="h4">
                              {merchantDetail?.payload?.businessContactName ||
                                "-"}
                            </Title>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>

              <div className={classNames.bottomRow}>
                <Dropdown overlay={receivePaymentsMenu} placement="top" arrow>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate("#")}
                  >
                    <RiUser2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Receive Payments</span>
                      <RiArrowDownSLine
                        size={26}
                        className={classNames.arrowDropBtnWithBg}
                      />
                    </div>
                  </Card>
                </Dropdown>
                <Dropdown
                  overlay={menu(activityMenuItems)}
                  placement="top"
                  arrow
                >
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate("#")}
                  >
                    <RiDatabase2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Activity</span>
                      <RiArrowDownSLine
                        size={26}
                        className={classNames.arrowDropBtnWithBg}
                      />
                    </div>
                  </Card>
                </Dropdown>
                <Dropdown
                  overlay={menu(informationMenuItems)}
                  placement="top"
                  arrow
                >
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate("#")}
                  >
                    <RiMoneyDollarCircleLine
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Information</span>
                      <RiArrowDownSLine
                        size={26}
                        className={classNames.arrowDropBtnWithBg}
                      />
                    </div>
                  </Card>
                </Dropdown>
                <Dropdown overlay={omniChannelMenu} placement="top" arrow>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={() => navigate("#")}
                  >
                    <RiAnticlockwise2Line
                      color="#9BA3AB"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Omni Channel</span>
                      <RiArrowDownSLine
                        size={26}
                        className={classNames.arrowDropBtnWithBg}
                      />
                    </div>
                  </Card>
                </Dropdown>
                <Card className={classNames.optionCardLight}>
                  <RiHonourLine
                    color="#9BA3AB"
                    size={26}
                    className={classNames.optionIcon}
                  />
                  <span className={classNames.optionCardComingSoonLabel}>
                    Coming soon
                  </span>
                  <div className={classNames.optionIconBackdropLight} />
                  <div className={classNames.cardActionDisabled}>
                    <span>Disputes</span>
                    <RiArrowRightLine
                      size={26}
                      className={classNames.arrowBtnWithBg}
                    />
                  </div>
                </Card>
                <Card
                  className={classNames.optionCardLight}
                  onClick={() => setSettingsVisibility(true)}
                >
                  <RiEdit2Line
                    color="#9BA3AB"
                    size={26}
                    className={classNames.optionIcon}
                  />
                  <div className={classNames.optionIconBackdropLight} />
                  <div className={classNames.cardAction}>
                    <span> Settings</span>
                    <RiArrowRightLine
                      size={26}
                      className={classNames.arrowBtnWithBg}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
          {paymentRequests && (
            <PaymentRequests
              visible={paymentRequests}
              onCancel={handlePaymentRequestsModalCancelClick}
            />
          )}
          <HostedPayment
            visible={hostedPayment}
            onCancel={handleHostedPaymentModalCancelClick}
          />
          <VirtualTerminal
            title="Virtual Terminal"
            visible={virtualTerminal}
            onCancel={handleVirtualTerminalModalCancelClick}
          />
          {queryString && (
            <QrModal
              visible={qrModal}
              onCancel={handleQrModalCancelClick}
              queryString={queryString}
            />
          )}
          {settingsVisibility && (
            <Settings
              visible={settingsVisibility}
              onCancel={handleSettingsCancelClick}
              setPASettingsAvailability={setPASettingsAvailability}
            />
          )}
          {queryString && (
            <DownloadModal 
              visible={shareModalVisibility}
              onCancel={handleShareModalCancelClick}
              accountData={accountDetail?.payload}
              displayBankLetter={"hide"}
            />
          )}
        </>
      )) || (
        <div className={classNames.spinner}>
          {" "}
          <Spin size="large" />{" "}
        </div>
      )}
    </div>
  );
}
