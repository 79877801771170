import React from "react";

const ACHAuthorization = () => {
  return (
    <>
      <p>
        I acknowledge that the “Transfer Funds” feature allows me to
        electronically transfer funds via the Automated Clearing House (“ACH”)
        between my eligible account(s) and my external account(s) at other
        financial institutions (“External Account(s)”) once I have successfully
        registered my External Account(s). This includes:
        <ul>
          <li>
            <span>
              One-time on-demand transfers, which allow me to initiate multiple,
              non-recurring, individual transfers based on the transfer date and
              dollar amount that I request through the Transfer Funds feature of
              Online and Mobile Banking from time to time;
            </span>
          </li>
          <li>
            <span>
              Recurring transfers, which allow me to initiate recurring
              transfers of a set dollar amount at regular intervals that I have
              established through the Transfer Funds feature of Online and
              Mobile Banking; and/or
            </span>
          </li>
          <li>
            <span>
              Other ACH transfer features that Otterz may make available.
            </span>
          </li>
        </ul>
      </p>
      <p>
        Subject to the terms of the Transfer Funds feature and the terms of my
        other agreements with Otterz, I hereby authorize Otterz to initiate
        electronic credit/debit entries to my eligible Otterz account(s) and
        each registered External Account indicated previously for (a) various
        amounts at varying times by making on-demand transfers pursuant to my
        instructions, and/or (b) fixed amount at the frequency I have selected
        by making recurring transfers pursuant to my instructions (a
        “Preauthorized Transfer”). If any transfer date falls on a weekend or
        bank holiday, I understand and agree that the transfer may be executed
        on the next business day, or as soon as reasonably possible at the
        discretion of Otterz.
      </p>
      <p>
        I hereby represent and warrant that I have all necessary right, power
        and authority to debit and credit my Otterz account and any External
        Account(s) registered, and that all information or instructions that I
        provide to Otterz in relation to any ACH transfer is complete and
        accurate. I agree that ACH transactions I authorize comply with all
        applicable law.
      </p>
      <p>
        Should an incorrect amount be withdrawn from or deposited to my External
        Account(s) or my Otterz account(s), I authorize Otterz to correct the
        error by debiting/crediting my External Account(s) or my Otterz
        account(s).
      </p>
      <p>
        This authorization is to remain in full force and effect until Otterz
        has received written notification from me of its termination in such a
        time and in such a manner as to afford Otterz a reasonable opportunity
        to act on it. For Preauthorized Transfers, I understand that it is my
        responsibility to contact Otterz to stop or cancel a Preauthorized
        Transfer at least three business days prior to the following scheduled
        payment. In either case, I will send my notice to:
      </p>
      <a href="mailto:support@otterz.co" className="link">
        support@otterz.co.
      </a>
      <p>
        I acknowledge that the origination of ACH transactions must comply with
        the provisions of applicable law and the rules of the National Automated
        Clearing House Association (NACHA). I request the financial institution
        that holds my External Account(s) to honor all transfers initiated in
        accordance with this authorization form.
      </p>
    </>
  );
};

export default ACHAuthorization;
