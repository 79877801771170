import noImgIcon from "../../assets/common/table/no-img.svg";

const payments = [
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#1DBC83" }}>Completed</span>,
  },
  {
    key: "1",
    image: <img alt="" src={noImgIcon} />,
    date: "21 Nov 2021",
    checkNumber: "884783",
    amount: "$350.50",
    status: <span style={{ color: "#BC7C1D" }}>Due</span>,
  },
];

export default payments;
