import { Switch } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RiCloseLine, RiCheckFill } from "react-icons/ri";
import { useState } from "react";
import { CTAButton, Message, Modal } from "../../common";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";

export default function TempNotification({ visibility, onCancel }) {
  //Hooks
  const [email, setEmail] = useState(true);
  const [sms, setSMS] = useState(true);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  //Other constant
  const style = {
    display: "grid",
    gridTemplateColumns: "1fr 100px 100px",
    gap: "20px",
    marginBottom: 10,
    marginTop: 10,
  };

  //Api calls
  const toggleTempNotifications = useMutation(
    (data) => api.Notification.setAlertNotification(data),
    {
      onSuccess: (res) => {
        setLoading(false);
        Message({
          type: "success",
          content: res?.message,
        });
        queryClient.invalidateQueries("getNotifications");
      },
      onError: (error) => {
        setLoading(false);
        generateErrorMessage(error);
      },
    }
  );

  useQuery("getNotifications", () => api.Notification.getAlertNotification(), {
    onSuccess: (res) => {
      setEmail(res?.payload?.is_email);
      setSMS(res?.payload?.is_sms);
    },
    onSettled: () => setLoading(false),
    retry: false,
    refetchOnWindowFocus: false,
  });

  //Event handler
  const handleSwitchChange = (checked, isSMS) => {
    if (isSMS) {
      setSMS(checked);
    } else {
      setEmail(checked);
    }
  };

  const handleToggle = () => {
    setLoading(true);
    toggleTempNotifications.mutate({
      is_email: email,
      is_sms: sms,
    });
  };

  //Helper
  // const isNotificationEnable = getNotificationStatus(
  //   settings?.data.result?.notifications
  // );

  return (
    <Modal
      visible={visibility}
      onCloseClick={onCancel}
      title="Alerts & Notifications"
      style={{ height: "80vh" }}
      bodyStyle={{
        height: "auto",
      }}
    >
      <span style={{ fontWeight: 700 }}>
        You will receive Email & SMS Alerts for the following events
      </span>

      <ul style={{ margin: "10px 0 10px 0", padding: 0 }}>
        <div style={{ ...style }}>
          <li style={{ listStyle: "none" }}>
            <span style={{ fontWeight: 600 }}>Event name</span>
          </li>
          <li
            style={{
              listStyle: "none",
              justifySelf: "center",
            }}
          >
            <div style={{ fontWeight: 600, marginBottom: 5 }}>SMS</div>
            <span
              style={{
                color: sms ? "#1DBC83" : "#EE6969",
              }}
            >
              <Switch
                onChange={(checked) => handleSwitchChange(checked, true)}
                checked={sms}
                defaultChecked={sms}
                loading={toggleTempNotifications?.loading}
              />
            </span>{" "}
          </li>
          <li style={{ listStyle: "none", justifySelf: "center" }}>
            <div style={{ fontWeight: 600, marginBottom: 5 }}>Email</div>
            <span
              style={{
                color: email ? "#1DBC83" : "#EE6969",
              }}
            >
              <Switch
                onChange={(checked) => handleSwitchChange(checked, false)}
                checked={email}
                defaultChecked={email}
                loading={toggleTempNotifications?.loading}
              />
            </span>{" "}
          </li>
        </div>

        {notificationsList.map((item, index) => (
          <>
            <div style={{ ...style }} key={index}>
              <li key={item} style={{ listStyle: "none" }}>
                {item}
              </li>
              <li
                style={{
                  listStyle: "none",
                  justifySelf: "center",
                }}
              >
                <span
                  style={{
                    color: sms ? "#1DBC83" : "#EE6969",
                  }}
                >
                  {sms ? <RiCheckFill size={20} /> : <RiCloseLine size={20} />}
                </span>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  justifySelf: "center",
                }}
              >
                <span
                  style={{
                    color: email ? "#1DBC83" : "#EE6969",
                  }}
                >
                  {email ? (
                    <RiCheckFill size={20} />
                  ) : (
                    <RiCloseLine size={20} />
                  )}
                </span>{" "}
              </li>
            </div>
          </>
        ))}
      </ul>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
        <CTAButton
          onClick={handleToggle}
          type="primary"
          loading={loading}
          style={{
            background: "#7372ff",
            color: "#ffff",
            borderColor: "#ffff",
            height: 44,
            marginTop: 10,
          }}
        >
          Submit
        </CTAButton>
      </div>
    </Modal>
  );
}
const notificationsList = [
  // "All incoming payments",
  "Outgoing payments",
  // "Cashback rewards",
  // "Card transactions",
  "New payee addition",
  "Pay later payments",
  "Recurring payments",
  "Check deposits",
];
