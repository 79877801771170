import { Button } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Header, Message } from "../../../../common";
import api from "../../../../API";
import { AppContext } from "../../../../../context/AppState";
import { tempUrls } from "../../../../../apiEndpoints";
import classNames from "./AccountSync.module.scss";
import quickbooks from "./quickbooks.png";

function GeneralInformation() {
  const { userData } = useContext(AppContext);
  const env = process.env.REACT_APP_ENV?.trim();
  const initSyncURL = tempUrls.INIT_SYNC_QB[env];
  const syncStatusURL = tempUrls.GET_SYNC_QB[env]; // URL to get sync status

  const { 
    data: bills, 
    isLoading: isLoadingApprovedInvoices, 
  } = useQuery('getAllInvoicesQBs', () => api.AccountsPayable.getAllInvoicesQBs({
    tenantId: userData?.tenantId
  }));

  console.log("bills: ", bills?.response, isLoadingApprovedInvoices);
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncProgress, setSyncProgress] = useState(0);

  useEffect(() => {
    if (bills?.error === "Tenant ID not found in database") {
      setIsConnected(false);
    } else {
      setIsConnected(true);
    }
  }, [bills]);

  const handleSyncClick = async () => {
    if (isConnected) {
      setIsSyncing(true);
      try {
        const response = await fetch(initSyncURL, {
          method: 'POST',
          body: "",
          headers: {
            'Authorization': `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            'Content-Type': 'application/json'
          },
        });

        if (response.status === 200) {
          Message({ type: "success", content: "QuickBooks Sync has started" });
          // Start periodic fetch to get sync status
          const syncInterval = setInterval(async () => {
            const syncStatusResponse = await fetch(syncStatusURL, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              },
            });

            if (syncStatusResponse.status === 200) {
              const syncStatusData = await syncStatusResponse.json();
              setSyncProgress(syncStatusData.payload.progress_percentage);
              if (syncStatusData.payload.sync_status === "SUCCESS") {
                clearInterval(syncInterval);
                setIsSyncing(false);
                setSyncProgress(0);
                Message({ type: "success", content: "Sync completed successfully" });
              }
            }
          }, 1000); // Fetch sync status every 1 second
        } else {
          setIsSyncing(false);
          Message({ type: "error", content: "Error syncing with Quickbooks" });
        }
      } catch (error) {
        setIsSyncing(false);
        console.log('error', error);
        Message({ type: "error", content: "Error syncing with Quickbooks" });
      }
    } else {
      // Open the connection link when not connected
      window.open(`http://ec2-52-91-35-16.compute-1.amazonaws.com:8000/?tenant_id=${userData?.tenantId}`, '_blank');
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Account Sync" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.infoWrapper}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", margin: "3rem 0 3rem"}}></div>
              <div className={classNames.infoCardWrapper}>
                <div className={classNames.infoCard}>
                  <div>
                    <img style={{width: "85px", height: "auto"}} src={quickbooks} alt="quickbooks-logo"></img>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                    <div style={{fontWeight: "bold"}}>Quickbooks</div>
                    <div>Automate your work - connect the apps you use every day. Automate processes without code.</div>
                  </div>
                  <div>
                    <Button onClick={handleSyncClick} disabled={isSyncing}>
                      {isSyncing ? `Syncing ${syncProgress}%` : isConnected ? "Sync" : "Connect"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformation;
