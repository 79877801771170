import { API } from "aws-amplify";

export default async function createACHPayment(data) {
  // if (data?.attributes) {
  //   data.attributes = {
  //     ...data.attributes,
  //     idempotencyKey: uuidv4()
  //   };
  // }

  return await API.post("achPayments", "", {
    body: {
      ...data,
    },
  });
}
