import {
  RiArrowLeftDownLine,
  RiArrowRightUpLine,
  RiArrowDownSFill,
  RiArrowUpSFill,
} from "react-icons/ri";
import { Spin } from "antd";
import classNames from "./TabHeader.module.scss";

const TabHeader = ({
  title,
  count,
  amount,
  trend,
  type = "incoming",
  isLoading = true,
}) => {
  return (
    <>
      <div className={classNames.tabHeader}>
        <div className={classNames.tabHeading}>
          {type === "incoming" ? (
            <div className={classNames.iconContainerIncoming}>
              <RiArrowLeftDownLine color="#1DBC83" size={18} />
            </div>
          ) : (
            <div className={classNames.iconContainerOutgoing}>
              <RiArrowRightUpLine color="#7372FF" size={18} />
            </div>
          )}
          <div
            className={`${
              type === "incoming"
                ? classNames.headingIncoming
                : classNames.headingOutgoing
            }`}
          >
            {title}
          </div>
        </div>
        <div className={classNames.count}>{count}</div>
        <div className={classNames.tabFooter}>
          <div className={classNames.trend}>{amount}</div>
          {trend === "up" ? (
            <RiArrowUpSFill color="#1DBC83" />
          ) : (
            trend === "down" && <RiArrowDownSFill color="#EE6969" />
          )}
        </div>
      </div>
      <Spin className={classNames.spinner} spinning={isLoading}></Spin>
    </>
  );
};

export default TabHeader;
