import classNames from "../VendorDatabase.module.scss";
import { CTAButton, Input, Message } from "../../../../common";
import { Modal, Form } from "antd";
import api from "../../../../API";
import { useMutation, useQuery, useQueryClient } from "react-query";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { MAX_MIN } from "../../../../util/constants";

const FormBody = ({ onCancel, form }) => {
  // const { Option } = Select;
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  function createTaxRateFn(data) {
    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      taxRateName: data.taxRateName,
      taxRate: data.taxRate,
    };
    return api.Receivables.Taxes.createTaxRate(input);
  }

  const createTaxRateMn = useMutation((event) => createTaxRateFn(event));

  const onFinish = (data) => {
    createTaxRateMn.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllTaxes");
        Message({ type: "success", content: language.TAX_RATE_CREATE_SUCCESS });
        onCancel(true);
        form.resetFields();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <div className={classNames.modalBody}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item
          name="taxRateName"
          label={
            <span className={classNames.label}>
              Tax Rate Name <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Tax rate name is required",
            },
            {
              max: MAX_MIN.businessName.max,
              message: `Max ${MAX_MIN.businessName.max} characters`,
            },
          ]}
        >
          <Input placeholder="Please Enter" size="small" />
        </Form.Item>
        <Form.Item
          name="taxRate"
          label={
            <span className={classNames.label}>
              Tax Rate <sup style={{ top: 1 }}>*</sup>{" "}
            </span>
          }
          rules={[
            { required: true, message: "Tax rate is required" },
            {
              pattern: new RegExp(
                /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,13})?$)/g
              ),
              message: "Valid Tax rate is required",
            },
          ]}
        >
          <Input
            style={{ width: 220 }}
            placeholder="Enter amount"
            type="number"
            size="small"
            suffix="%"
          />

          {/* <Select
            suffixIcon="%"
            placeholder="Please select"
            style={{ width: 220 }}
            size="small"
          >
            <Option value={3.5}>3.5%</Option>
            <Option value={10.5}>10.5%</Option>
            <Option value={12.5}>12.5%</Option>
            <Option value={10.5}>13.5%</Option>
          </Select> */}
        </Form.Item>
        <CTAButton
          type="primary"
          style={{ height: 44, width: 384, marginTop: 20 }}
          onClick={form.submit}
          loading={createTaxRateMn.isLoading}
        >
          Save
        </CTAButton>
      </Form>
    </div>
  );
};

export default function AddNewTax({ visible, onCancel }) {
  const [form] = Form.useForm();
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      title="Add New Tax Rate"
      className={classNames.modal}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
      footer={null}
    >
      <FormBody onCancel={onCancel} form={form} />
    </Modal>
  );
}
