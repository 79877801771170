import { useMutation } from "react-query";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import useLanguage from "../../../../../hooks/useLanguage";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import classNames from "../../MakePayments.module.scss";

export default function DeletePayment({
  visible,
  onCancel,
  record,
  setVisible,
  refetch,
  queryClient,
}) {
  //Hooks
  const language = useLanguage(messages);

  let id = record && record.id;

  //Api calss
  const deletePayment = useMutation((id) => {
    return api.BusinessBanking.deleteScheduledPayment(id);
  });

  //Event handlers
  function deletePaymentFn() {
    deletePayment.mutate(id, {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.PAYMENT_DELETED,
        });
        queryClient.invalidateQueries("getStatementDetail");
        setVisible(false);
        refetch();
      },
      onError: (error) => generateErrorMessage(error),
    });
  }

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete payment?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this payment?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          loading={deletePayment.isLoading}
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={() => deletePaymentFn()}
        >
          Delete
        </CTAButton>
      </div>
    </Modal>
  );
}
