//#region Account Types
// export const INDIVIDUAL_ACC = "PERSON";
// export const BUSINESS_ACC = "BUSINESS";
//#endregion

import { PAGINATION_LIMIT } from "../../constants";

//#region Unit Limits
// Check Deposit Limits
export const CHECK_INDIVIDUAL_DAILY = "$1,000";
export const CHECK_INDIVIDUAL_MONTHLY = "$20,000";
export const CHECK_BUSINESS_DAILY = "$50,000";
export const CHECK_BUSINESS_MONTHLY = "$100,000";
//#endregion

//#region Common Messages
const commonMessages = {
  en: {
    BACK: "Back",
    SAVE: `Save`,
    NEXT: `Next`,
    SUBMIT: `Submit`,
    REMOVE: `Remove`,
    REQUIRED: `required`,
    RESEND: `Resend`,
    DELETE: `Delete`,
    PLEASE_SELECT: `Please select`,
    PLEASE_ENTER: `Please enter`,
    PLEASE_SPECIFY: `Please specify`,
    DRAG_OR_DROP: ` Drag & Drop or`,
    AMOUNT: "Amount",
    AMOUNT_REQUIRED: "Amount is required",
    AMOUNT_VALID: "Enter a valid amount",
    AMOUNT_ENTER: "Enter amount",
    CLICK_TO_UPLOAD: `click here to upload`,
    SAVE_SUCCESS: `Saved successfully`,
    SUCCESS: `Success`,
    INDIVIDUAL_ACC: `person`,
    BUSINESS_ACC: `business`,
    FIRST_NAME: `First Name`,
    FIRST_NAME_PLACEHOLDER: `First name`,
    LAST_NAME: `Last Name`,
    LAST_NAME_PLACEHOLDER: `Last name`,
    TITLE: `Title`,
    IDENTIFICATION_TYPE: `Identification Type`,
    STATUS: "Status",
    SOCIAL_SECURITY: `Social Security Number (SSN)`,
    PASSPORT: `Passport Number`,
    PASSPORT_PLACEHOLDER: `N478372`,
    NATIONALITY: `Nationality`,
    DATE: "Date",
    DATE_OF_BIRTH: `Date of Birth`,
    DATE_PLACEHOLDER: `DD/MM/YYYY`,
    ADDRESS: `Address`,
    PHONE_NUMBER: `Phone Number`,
    ENTITY_NAME: `Business Name`,
    NAME_ON_CARD: `Name to appear in card`,
    //#region Email
    EMAIL_ADDRESS: `Email Address`,
    EMAIL_ADDRESS_PLACEHOLDER: `Email address`,
    EMAIL_ADDRESS_PLACEHOLDER_EG: `Eg: john@mail.com`,
    EMAIL_ADDRESS_REQUIRED: `Email address is required`,
    EMAIL_ADDRESS_VALID: `Enter a valid email address`,
    RESEND_EMAIL_PROMPT: `Didn’t receive email?`,
    RESEND_EMAIL: `Resend`,
    //#endregion
    COUNTRY_PLACEHOLDER: `Country `,
    STATE_PLACEHOLDER: `State `,
    STREET_PLACEHOLDER: `Street `,
    POSTAL_CODE_PLACEHOLDER: `Postal Code `,
    PERCENTAGE: `Percentage`,
    PERCENTAGE_PLACEHOLDER: `Eg: 100%`,
    ERROR_OCCURRED: `Error occurred!`,
    INTERNAL_SERVER_ERROR: `Internal server error`,
    //#region Lengths
    MAX_50: `Max length is 50 characters`,
    MAX_64: `Max 64 characters`,
    MAX_16: `Max length is 16 characters`,
    MIN_8: `Min length is 8 characters`,
    MAX_8_DESC: `Password must be 8 characters long with a number and a capital letter`,
    //#endregion
    //#region Accounts
    NO_ACC: `Don't have an account?`,
    SIGN_UP_HERE: `Sign up here`,
    SIGN_UP: `Sign up`,
    SIGN_UP_DESC: `You can sign up with your phone number or email address`,
    SIGN_IN_HERE: `Sign in here`,
    ACC_EXIST: `Already have an account?`,
    //#endregion
    //#region OTP
    ENTER_OTP: `Enter OTP`,
    SEND_OTP: `Send OTP`,
    OTP_REQUIRED: `OTP is required`,
    INCORRECT_OTP: `Please enter correct OTP`,
    RESENT_OTP_SUCCESS: `OTP resent successfully`,
    OTP_SENT: `An OTP has been sent to your email address`,
    OTP_SENT_SUCCESS: `OTP sent successfully`,
    OTP_SENT_WITH_NUMBER: (number) =>
      `We have sent an OTP to your phone number ${number}`,
    OTP_SENDING_WITH_NUMBER: (number) =>
      `We'll be sending an OTP to your phone number ${number}`,
    OTP_SENT_FAILED: `Email not found`,
    VERIFY_PHONE: `Verify your phone number`,
    CHANGE_PHONE_NUMBER: `Change phone number`,
    VERIFICATION_CODE_SENT_SUCCESS: `Verification code sent successfully!`,
    VERIFIED_PHONE: (number) =>
      `Your phone number ${number} has already been verified!`,
    RESEND_CODE_PROMPT: `Didn’t receive code`,
    REQUEST_CODE: `Request Code`,
    CONFIRM_CODE_SENT_SUCCESS: `Confirmation code sent successfully`,
    OTP_SENT_SUCCESS_WITH_EMAIL: (email) =>
      `We have sent an OTP to your email address ${email}`,
    //#endregion
    //#region Passwords
    PASSWORD: `Password`,
    ENTER_PASSWORD: `Enter your password`,
    SET_NEW_PASSWORD: `Set new password`,
    NEW_PASSWORD: `New password`,
    CONFIRM_NEW_PASSWORD: `Confirm new password`,
    FORGOT_PASSWORD: `Forgot password?`,
    PASSWORD_REQUIRED: `Password is required`,
    INSECURE_PASSWORD: `Insecure password`,
    PASSWORD_RESET_SUCCESS: `Password reset successfully`,
    PASSWORD_MAX: `Max 16 characters`,
    CONFIRM_PASSWORD: `Confirm password`,
    PASSWORD_MISMATCH: `Passwords do not match`,
    PASSWORD_CONFIRMATION_REQUIRED: `Password confirmation is required`,
    PASSWORD_CHANGE_SUCCESS: `Password changed successfully`,
    RECOVER_EMAIL: `Enter your email address registered with Otterz`,
    VERIFY_EMAIL: `Verify your email address`,
    SOMETHING_WENT_WRONG: `Something went wrong!`,
    //#endregion
    PREV: "Prev",
  },
};

export const MAX_MIN = {
  name: {
    min: 1,
    max: 35,
  },
  email: {
    min: 10,
    max: 255,
  },
  businessName: {
    min: 1,
    max: 75,
  },
  serviceOffered: {
    max: 280,
  },
  percentage: {
    min: 25,
    max: 100,
  },
  street: {
    max: 75,
  },
  city: {
    max: 75,
  },
  state: {
    max: 75,
  },
  country: {
    max: 75,
  },
  website: {
    max: 150,
  },
  accountNumber: {
    min: 5,
    max: 17,
  },
  bank: {
    max: 150,
  },
  description: {
    max: 100,
  },
  password: {
    min: 8,
    max: 255,
  },
  phone: {
    max: 10,
    min: 10,
  },
  invoice: {
    max: 6,
    min: 6,
  },
};

export const PAGINATION = {
  page: 1,
  pageSize: PAGINATION_LIMIT,
};

export const PAGINATION_DEFAULT = {
  position: ["bottomCenter", "bottomCenter"],
  pageSize: PAGINATION_LIMIT,
  hideOnSinglePage: true,
  scrollToFirstRowOnChange: true,
  showSizeChanger: false,
  showPrevNextJumpers: false,
};

export const COMMON_WORDS = [
  "password",
  "123456",
  "123456789",
  "guest",
  "qwerty",
  "12345678",
  "111111",
  "12345",
  "col123456",
  "123123",
  "1234567",
  "1234",
  "1234567890",
  "000000",
  "555555",
  "666666",
  "123321",
  "654321",
  "7777777",
  "123",
  "D1lakiss",
  "777777",
  "110110jp",
  "1111",
  "987654321",
  "121212",
  "Gizli",
  "abc123",
  "112233",
  "azerty",
  "159753",
  "1q2w3e4r",
  "54321",
  "pass@123",
  "222222",
  "qwertyuiop",
  "qwerty123",
  "qazwsx",
  "vip",
  "asdasd",
  "123qwe",
  "123654",
  "iloveyou",
  "a1b2c3",
  "999999",
  "Groupd2013",
  "1q2w3e",
  "usr",
  "Liman1000",
  "1111111",
  "333333",
  "123123123",
  "9136668099",
  "11111111",
  "1qaz2wsx",
  "password1",
  "mar20lt",
  "987654321",
  "gfhjkm",
  "159357",
  "abcd1234",
  "131313",
  "789456",
  "luzit2000",
  "aaaaaa",
  "zxcvbnm",
  "asdfghjkl",
  "1234qwer",
  "88888888",
  "dragon",
  "987654",
  "888888",
  "qwe123",
  "football",
  "3601",
  "asdfgh",
  "master",
  "samsung",
  "12345678910",
  "killer",
  "1237895",
  "1234561",
  "12344321",
  "daniel",
  "000000",
  "444444",
  "101010",
  "fuckyou",
  "qazwsxedc",
  "789456123",
  "super123",
  "qwer1234",
  "123456789a",
  "823477aA",
  "147258369",
  "unknown",
  "98765",
  "q1w2e3r4",
  "232323",
  "102030",
  "12341234",
  "147258",
  "shadow",
  "123456a",
  "87654321",
  "10203",
  "pokemon",
  "princess",
  "azertyuiop",
  "thomas",
  "baseball",
  "monkey",
  "jordan",
  "michael",
  "love",
  "1111111111",
  "11223344",
  "123456789",
  "asdf1234",
  "147852",
  "252525",
  "11111",
  "loulou",
  "111222",
  "superman",
  "qweasdzxc",
  "soccer",
  "qqqqqq",
  "123abc",
  "computer",
  "qweasd",
  "zxcvbn",
  "sunshine",
  "1234554321",
  "asd123",
  "marina",
  "lol123",
  "a123456",
  "Password",
  "123789",
  "jordan23",
  "jessica",
  "212121",
  "7654321",
  "googledummy",
  "qwerty1",
  "123654789",
  "naruto",
  "Indya123",
  "internet",
  "doudou",
  "anmol123",
  "55555",
  "andrea",
  "anthony",
  "martin",
  "basketball",
  "nicole",
  "xxxxxx",
  "1qazxsw2",
  "charlie",
  "12345qwert",
  "zzzzzz",
  "q1w2e3",
  "147852369",
  "hello",
  "welcome",
  "marseille",
  "456123",
  "secret",
  "matrix",
  "zaq12wsx",
  "password123",
  "qwertyu",
  "hunter",
  "freedom",
  "999999999",
  "eminem",
  "junior",
  "696969",
  "andrew",
  "michelle",
  "wow12345",
  "juventus",
  "batman",
  "justin",
  "12qwaszx",
  "Pass@123",
  "passw0rd",
  "soleil",
  "nikita",
  "Password1",
  "qweqwe",
  "nicolas",
  "robert",
  "starwars",
  "liverpool",
  "5555555",
  "bonjour",
  "124578",
];

export default commonMessages;
