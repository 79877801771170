import { useState } from "react";
import moment from "moment";
import {
  RiAddFill,
  RiArrowDropDownLine,
  RiCloseCircleFill,
} from "react-icons/ri";
import TextArea from "antd/lib/input/TextArea";

import {
  Form,
  Input as AntInput,
  Button as AntButton,
  AutoComplete,
} from "antd";
import { Title, Input, Select, DatePicker } from "../../../../common";
import AddNewTax from "../../TaxDatabase/AddNewTax/AddNewTax";
import { validation } from "../../../../Auth/config";
import { intToDecimalDollar } from "../../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../../../constants";
import REGEX from "../../../../util/constants/regex";
import classNames from "../Invoices.module.scss";

export default function CreateNewEstimateForm({
  form,
  onFinish,
  customers,
  products,
  taxRates,
  invoiceTemplateSettings,
  summary,
  setSummary,
  discount,
  setDiscount,
}) {
  const [addTaxCardModalVisibility, setAddTaxCardModalVisibility] =
    useState(false);
  const [currFields, setCurrFields] = useState(1);

  let dataSource =
    products &&
    products.length > 0 &&
    products.map((value, index) => {
      return {
        text: value.productName,
        value: value.productName,
        key: value.id,
        ...value,
      };
    });

  const toNumber = (num) => Number(num?.toFixed(2));

  const handleAddTaxCardModalCancelClick = () => {
    setAddTaxCardModalVisibility(false);
  };
  const calculatePrice = (index, flag, option) => {
    let values = form.getFieldsValue();
    let obj = values["salesItemsEstimate"][index];
    if (obj.taxRate === "addnewtax") {
      setAddTaxCardModalVisibility(true);
      return;
    }
    let unitCost = obj.unitCost ? parseFloat(obj.unitCost) : 0;
    let quantity = obj.quantity ? parseInt(obj.quantity) : 1;
    let totalWithOutTax = unitCost * quantity;
    let taxAmount = obj.taxRate
      ? parseFloat((totalWithOutTax * obj.taxRate) / 100)
      : 0;
    let price = unitCost * quantity;
    //+ taxAmount;
    let array = values["salesItemsEstimate"];

    if (flag === "productSelect") {
      Object.assign(obj, {
        unitCost: option.price ? parseFloat(option.price) : 0,
        taxRate: option.taxRate ? parseFloat(option.taxRate) : 0,
        taxAmount: Number(parseFloat(option.taxRate / 100))
          ? parseFloat(option.taxRate / 100) * parseFloat(option.price)
          : 0,
        subTotal: option.price ? parseFloat(option.price) : 0,
        quantity: 1,
        price: Number(parseFloat(option.price)?.toFixed(2)) || 0,
        // price: (parseFloat(option.price) + parseFloat(option.taxRate / 100)).toFixed(2),
        // subTotal: totalWithOutTax,
      });
    } else {
      Object.assign(obj, {
        price: price.toFixed(2),
        taxAmount: taxAmount,
        subTotal: totalWithOutTax,
      });
    }

    // Object.assign(obj, {
    //   price: toNumber(price),
    //   taxAmount: toNumber(taxAmount),
    //   subTotal: toNumber(totalWithOutTax),
    // });

    if (flag === "remove") {
      delete array[index];
      array.filter(Boolean);
      form.setFieldsValue({ salesItemsEstimate: array });
      setCurrFields((prev) => prev - 1);
    } else {
      array.filter(Boolean);
      form.setFieldsValue({ salesItemsEstimate: array });
    }
    let subTotal = array
      .map((value, index) => toNumber(value?.subTotal))
      .reduce((sum, next) => sum + next, 0);
    let tax = array
      .map((value, index) => toNumber(value?.taxAmount))
      .reduce((sum, next) => sum + next, 0);
    setSummary({ subTotal, tax });
  };

  function disabledDate(current) {
    return current && current <= moment().subtract(1, "days");
  }

  //Helper
  function validationCheck() {
    return (
      form
        ?.getFieldsError()
        ?.filter(
          (item) =>
            ["unitCost", "description"].includes(item?.name[2]) &&
            item?.errors?.length
        )?.length ||
      !(form?.getFieldsValue()?.salesItemsEstimate?.length
        ? form?.getFieldsValue()?.salesItemsEstimate[currFields - 1]
            ?.unitCost &&
          form?.getFieldsValue()?.salesItemsEstimate[currFields - 1]
            ?.description
        : false)
    );
  }

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <div className={classNames.modalBodyFormUpper}>
        <div className={classNames.modalLeft}>
          <div className={classNames.modalHeader}>
            <Title as="h3">Estimate Details</Title>
          </div>
          {/* Commenting below form input to according to Mukund's approval on 4/12/2022.
          Reason: Invoice number is not an editable field from invoicea's end. - Sagara */}
          {/* <Form.Item
            name="estimateNumber"
            label={
              <Title as="h4" className={classNames.label}>
                Estimate Number
              </Title>
            }
          >
            <Input
              size="small"
              style={{ width: 168 }}
              placeholder="Please Enter"
            />
          </Form.Item> */}
          <Form.Item
            name="client"
            rules={[{ required: true, message: validation.client.required }]}
            label={
              <Title as="h4" className={classNames.label}>
                Customer <sup style={{ top: 1 }}>*</sup>
              </Title>
            }
          >
            <Select
              size="small"
              style={{ width: 352 }}
              placeholder="Please Select"
            >
              {Array.isArray(customers) &&
                customers.map((val, index) => (
                  <Select.Option value={val.id}>{val.name}</Select.Option>
                ))}
              {/* <AntButton type="link" className={classNames.addNewButton}>
                <RiAddFill size={13} style={{ marginRight: 5 }} />
                Add new client
              </AntButton> */}
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="estimateDate"
              label={
                <span className={classNames.label}>
                  Date <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                marginRight: 14,
              }}
              rules={[{ required: true, message: "Date is required" }]}
              initialValue={moment()}
            >
              <DatePicker
                disabledDate={disabledDate}
                style={{ height: 44, width: 168 }}
                defaultValue={moment()}
              />
            </Form.Item>

            <Form.Item
              name="discount"
              label={<span className={classNames.label}>Discount</span>}
              style={{
                display: "inline-block",
              }}
              rules={[
                {
                  pattern: new RegExp(REGEX.discount),
                  message: "Valid rate is required",
                },
              ]}
            >
              <Input
                placeholder="Please enter"
                size="small"
                style={{ width: 168 }}
                onChange={(e) => setDiscount(e.target.value)}
                prefix="%"
                defaultValue={0}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="reference"
            label={
              <Title as="h4" className={classNames.label}>
                Reference
              </Title>
            }
            rules={[{ max: 50, message: validation.reference.max(50) }]}
          >
            <Input
              size="small"
              style={{ width: 352 }}
              placeholder="Please Enter"
            />
          </Form.Item>
        </div>
        <div className={classNames.modalRight}>
          <div className={classNames.invoiceSummary}>
            <div className={classNames.modalHeader}>
              <Title as="h3">Estimate Summary</Title>
            </div>
            <div>
              <span>Sub Total</span>
              <span>
                {intToDecimalDollar(Number(summary?.subTotal)) ||
                  DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Tax</span>
              <span>
                {intToDecimalDollar(Number(summary?.tax)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Discount</span>
              <span>{discount}%</span>
            </div>
            <div className={classNames.total}>
              <span>Total</span>
              <span>
                {intToDecimalDollar(
                  Number(
                    summary?.subTotal -
                      summary?.subTotal * (discount / 100) -
                      (summary?.subTotal - summary?.subTotal) *
                        (discount / 100) +
                      summary?.tax
                  )
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.saleItemsWrapper}>
        <div className={classNames.modalHeader}>
          <Title as="h3">Sale Items</Title>
        </div>
        <div className={classNames.salesItemsHeader}>
          <span
            style={{
              width: "35%",
              marginLeft: currFields > 1 ? "32px" : "",
              marginRight: currFields > 1 ? "-5px" : "5px",
            }}
          >
            Description <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 125 }}>
            Unit Cost <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 75 }}>
            Qty <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 115 }}>
            Tax <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 150 }}>Price</span>
        </div>
        <div>
          <Form.List
            name="salesItemsEstimate"
            on
            rules={[
              {
                validator: async (_, salesItemsEstimate) => {
                  if (!salesItemsEstimate || salesItemsEstimate.length < 1) {
                    return Promise.reject(
                      new Error(validation.salesItems.error)
                    );
                  }
                },
              },
            ]}
            initialValue={[{}]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={field.key}
                    className={classNames.inputGroup}
                  >
                    {fields.length > 1 ? (
                      <RiCloseCircleFill
                        size={20}
                        className={classNames.inputGroupRemoveIcon}
                        onClick={() => {
                          if (currFields > 1) {
                            calculatePrice(index, "remove");
                            remove(field.index);
                          } else {
                            form.resetFields();
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <AntInput.Group
                      compact
                      className={classNames.inputGroupItems}
                    >
                      <Form.Item
                        name={[field.name, "description"]}
                        style={{
                          width: "35%",
                          marginRight: "5px",
                        }}
                        rules={[
                          { required: true, message: "Required" },
                          { max: 250, message: "Max 250 characters" },
                        ]}
                      >
                        <AutoComplete
                          size="large"
                          placeholder="Please enter"
                          style={{
                            width: "100%",
                          }}
                          className={classNames.autoSelectDesc}
                          options={dataSource}
                          filterOption={(inputValue, option) => {
                            return Object.values(option)
                              .toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          onSelect={(value, option) => {
                            calculatePrice(index, "productSelect", option);
                          }}
                        />
                        {/* <Input
                          size="small"
                          placeholder="Please enter"
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        /> */}
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "unitCost"]}
                        rules={[
                          { required: true, message: "Required" },
                          {
                            pattern: new RegExp(
                              /^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/
                            ),
                            message: "Enter a valid price",
                          },
                          () => ({
                            validator(_, value) {
                              if (value === "0" || value === 0) {
                                return Promise.reject(
                                  new Error("0 is not allowed")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                          () => ({
                            validator(_, value) {
                              if (value?.toString()?.length > 11) {
                                return Promise.reject(
                                  new Error(
                                    "Max 8 digits with 2 decimal points"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder="Enter"
                          prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                          style={{ width: 125, marginRight: "5px" }}
                          type="number"
                          onChange={() => calculatePrice(index)}
                          min={0}
                          maxLength={11}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "quantity"]}
                        initialValue={1}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "Invalid quantity",
                          },
                          {
                            required: true,
                            message: "Required",
                          },
                          () => ({
                            validator(_, value) {
                              if (value && value?.toString()?.length > 6) {
                                return Promise.reject(
                                  new Error("Max 6 characters")
                                );
                              }
                              if (!value?.toString()?.length) {
                                return Promise.reject(new Error("Required"));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder="Enter"
                          style={{ width: 75, marginRight: "5px" }}
                          type="number"
                          onChange={() => calculatePrice(index)}
                          defaultValue={1}
                          min={0}
                        />
                        {/* <Select
                          size="small"
                          style={{ width: 86 }}
                          className={classNames.salesItemSelect}
                          onChange={() => calculatePrice(index)}
                        >
                          <Select.Option value={1}>1</Select.Option>
                          <Select.Option value={2}>2</Select.Option>
                        </Select> */}
                      </Form.Item>
                      <Form.Item name={[field.name, "taxRate"]}>
                        <Select
                          size="small"
                          style={{ width: 115, marginRight: "5px" }}
                          className={classNames.salesItemSelect}
                          onChange={() => calculatePrice(index)}
                          defaultValue={0}
                          suffixIcon={
                            <span style={{ color: "#9ba3ab" }}>
                              %<RiArrowDropDownLine />
                            </span>
                          }
                        >
                          <Select.Option key={0} value={"0"}>
                            0
                          </Select.Option>
                          {Array.isArray(taxRates) &&
                            taxRates.map((val, index) => (
                              <Select.Option
                                key={`${index}${val.taxRate}`}
                                value={`${val.taxRateName} ${val.taxRate}`}
                              >
                                {val.taxRateName} ({val.taxRate}%)
                              </Select.Option>
                            ))}
                          {/* <AntButton
                            type="link"
                            value={"addnewtax"}
                            className={classNames.addNewButton}
                          >
                            <RiAddFill size={13} style={{ marginRight: 5 }} />
                            Add new tax
                          </AntButton> */}
                        </Select>
                      </Form.Item>
                      <Form.Item name={[field.name, "price"]}>
                        <Input
                          size="small"
                          placeholder="0"
                          prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                          style={{
                            width: 150,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            marginRight: "5px",
                          }}
                          disabled
                        />
                      </Form.Item>
                    </AntInput.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <div
                    className={classNames.salesItemsFooter}
                    style={{ marginLeft: currFields === 1 ? "0" : "14px" }}
                  >
                    <AntButton
                      onClick={() => {
                        const currentSaleItem =
                          form.getFieldsValue().salesItemsEstimate[
                            currFields - 1
                          ];
                        if (
                          currentSaleItem?.unitCost &&
                          currentSaleItem?.description
                        ) {
                          add();
                          setCurrFields((prev) => prev + 1);
                        }
                      }}
                      type="link"
                      className={classNames.addNewButton}
                      disabled={validationCheck()}
                    >
                      <RiAddFill size={13} style={{ marginRight: 5 }} />
                      Add new item
                    </AntButton>
                  </div>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* {salesItemsRows.map((saleItem) => (
          <Form.Item className={classNames.inputGroup} key={saleItem.id}>
            <RiCloseCircleFill
              size={20}
              className={classNames.inputGroupRemoveIcon}
              onClick={removeSalesItemsRow}
            />
            <AntInput.Group compact>
              <Input
                size="small"
                style={{ width: "40%" }}
                value={saleItem.description}
              />
              <Input
                size="small"
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                style={{ width: 107 }}
                value={saleItem.unitCost}
              />
              <Select
                size="small"
                defaultValue={saleItem.qty}
                style={{ width: 86 }}
              >
                <Select.Option value={saleItem.qty}>
                  {saleItem.qty}
                </Select.Option>
              </Select>
              <Select
                size="small"
                defaultValue={saleItem.tax}
                style={{ width: 86 }}
              >
                <Select.Option value={saleItem.tax}>
                  {saleItem.tax}
                </Select.Option>
              </Select>
              <Input
                size="small"
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                style={{ width: 125 }}
                value={saleItem.price}
              />
            </AntInput.Group>
          </Form.Item>
        ))
        } */}
        </div>
      </div>
      <div className={classNames.modalHeader}>
        <Title as="h3">More Information</Title>
      </div>
      <Form.Item>
        <Form.Item
          name="notes"
          label={<span className={classNames.label}>Notes</span>}
          style={{
            display: "inline-block",
            marginRight: 14,
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            defaultValue={invoiceTemplateSettings?.notes}
            disabled
            autoSize={true}
          />
        </Form.Item>

        <Form.Item
          name="terms"
          label={<span className={classNames.label}>Terms</span>}
          style={{
            display: "inline-block",
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            disabled
            defaultValue={invoiceTemplateSettings?.terms}
            autoSize={true}
          />
        </Form.Item>
      </Form.Item>
      <AddNewTax
        visible={addTaxCardModalVisibility}
        onCancel={handleAddTaxCardModalCancelClick}
      />
    </Form>
  );
}
