import commonMessages from "../../util/constants";
import deepMerge from "../../util/functions/deepMerge";

// Note: Constants predefined in advance as of 11/09/2022

const directoryMessages = {
  en: {
    ACC_SAVE_SUCCESS: `Account type saved successfully`,
    ACC_SAVE_FAIL: `Please select account type`,
    ACC_TITLE: `Account Type`,
    ACC_DESC: `Please select the type of account required`,
    BUSINESS: `Business`,
    BUSINESS_DESC: `For corporate, organizations, NGOs & government
        authorities`,
    INDIVIDUAL: `Individual`,
    INDIVIDUAL_DESC: `For freelancers, consultants, and anyone who works on
        their own`,
    INDIVIDUAL_ACC_SIGN_UP: `Sign up for an individual account`,
    BUSINESS_DETAILS: `Business Details`,
    BUSINESS_ACC_SIGN_UP: `Sign up for business account`,
    ACC_SIGN_UP_DESC: `Fill in the form below and submit, we will review your details and get
        back to you`,
    BUSINESS_NAME: `Name of the business`,
    OFFICER_DETAILS: `Officer Details`,
    ADD_ANOTHER_BENEFICIAL_OWNER: `Add another beneficial owner`,
    STATE_OF_INCORPORATION: `State of incorporation`,
    STATE_OF_INCORPORATION_REQUIRED: `State of incorporation is required`,
    BUSINESS_EIN: `Business EIN`,
    ENTITY_TYPE: `Entity Type`,
    WEBSITE: `Website`,
    BUSINESS_CONTACT: `Business Contact`,
    childUser: {
      GREET: `Hello Andrew`,
      WELCOME_DESC: ` You have been invited as a [Role 1] to Otterz. Please login with the
            username and temporary password given below and make sure to reset the
            password`,
      USERNAME: `Username`,
      EMAIL: `henry@gmail.com`,
      TEMP_PASS: `Temporary Password`,
      PASSWRD: "he8938OTT",
      LOGIN_HERE: `Login Here`,
    },
    SOLE_PROPRIETOR_CONFIRM: `I'm the sole proprietor`,
    EIN: {
      EMPLOYER_ID_NUM: `Employer Identification Number`,
      ENTER_EIN: `Enter EIN`,
    },
    DIFFERENT_BUSINESS_NAME: `Please specify if you are doing business under a different
        name`,
    LOG_IN: {
      LOGIN: `Login`,
      LOGIN_DESC: `Please enter your email address to login`,
      LOGGING_IN: `Logging you in...`,
      OTP_EMAIL_PROMPT: `Please enter the OTP sent to your email to proceed with the onboarding`,
      OTP_INCORRECT_PROMPT: `The email or password entered by you is incorrect.`,
      USER_NOT_FOUND: `Unauthorize access. User does not exist`,
    },
    SIGN_UP: {
      SIGN_UP_REQUEST: `We have received your request`,
      SIGN_UP_REQUEST_DESC: `We will send you an email once your account is verified and ready to use.`,
      ADDRESS_CONFIRM: `Address confirmation`,
      ADDRESS_CONFIRM_DESC: (ownerName) =>
        `Upload a utility bill, bank statement or anything that can confirm ${(
          <b>{ownerName}</b>
        )}'s address`,
      IMAGE_SIZE_PROMPT: `Image must smaller than 20Mb`,
      IMAGE_SELECT_PROMPT: `Please select a image`,
      PICTURE: `picture`,
      UPLOAD_SUCCESS: `Upload successful`,
      UPLOAD_FAIL: `Upload failed please try again`,
      UPLOAD_PROMPT: (ownerName) =>
        `Upload a document of the main page of ${(<b>{ownerName}</b>)}'s `,
      ID_DOC_PICTURE: `ID Document picture`,
      ID_DOC_PICTURE_DESC: (
        ownerName
      ) => `Upload a document of the main page of ${(<b>{ownerName}</b>)}'s ID
          Document`,
      FRONT: `Front`,
      BACK: `Back`,
      SOCIAL_SEC_CARD: `Social Security Card`,
      CERT_OF_INC: `Certificate Of Incorporation`,
      EIN_CONFIRMATION: `Employer Identification Number Confirmation`,
      ATTORNEY: `Power Of Attorney`,
      DOCUMENT: `Document`,
      DOCUMENT_REQUIRED: (doc) => `${doc} is required`,
      UPLOAD_DOCS: `Upload Documents`,
      UPLOAD_DOCS_DESC: `Please upload the supporting documents as requested below`,
    },
  },
};

const messages = deepMerge(commonMessages, directoryMessages);

export default messages;
