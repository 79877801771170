import classNames from "../ViewEstimate.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import useLanguage from "../../../../../hooks/useLanguage";
import messages from "../../../config/messages.const";

export default function ShareEstimate({ visible, onCancel, estimate }) {
  const language = useLanguage(messages);
  const queryClient = useQueryClient();

  function shareEstimateFn() {
    let input = {
      id: estimate.id,
      type: "estimate",
    };
    return api.Receivables.Share.email(input);
  }

  const shareEstimateMn = useMutation(() => shareEstimateFn());
  const handleConfirmClick = (values) => {
    shareEstimateMn.mutate(values, {
      onSuccess: () => {
        Message({ type: "success", content: language.EMAIL_SENT_SUCCESS });
        queryClient.invalidateQueries("getEstimateById", {
          queryKey: { id: estimate.id },
        });
        onCancel();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Share Estimate
      </Title>
      <div style={{ marginBottom: 8 }}>
        {`Are you sure you want to share this estimate with ${estimate?.receiverName}?`}
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            border: "none",
          }}
          onClick={() => {
            handleConfirmClick();
          }}
          loading={shareEstimateMn.isLoading}
        >
          Yes, share
        </CTAButton>
      </div>
    </Modal>
  );
}
