import React from "react";
import { Spin } from "antd";

import classNames from "./Backdrop.module.scss";

const Backdrop = ({ props }) => (
  <div className={classNames.container}>
    <Spin {...props} className={classNames.spinner} />
  </div>
);

export default Backdrop;
