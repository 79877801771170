import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Button } from "antd";

const AdditionalInfoRequired = ({ name }) => {
  return (
    <div className={classNames.success}>
      <RiCheckboxCircleFill
        size={90}
        color="#1DBC83"
        style={{ marginBottom: 34 }}
      />
      <Title as="h3">Hi 
      {/* {`${name}`} */}
      </Title>
      <div className={classNames.extra}>
        There is additional information required for your application, please
        Contact&nbsp;
        <Button type="link" className={classNames.linkButton} href="https://www.otterz.co/contact-us" rel="noreferrer" target="_blank">
          Customer Support.
        </Button>
      </div>
    </div>
  );
};

export default AdditionalInfoRequired;
