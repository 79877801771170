import { useState } from "react";
import { Form } from "antd";
import { CTAButton, Input, Select, CountryPicker } from "../../../../common";
import { usStateList } from "../../../../util";
import { validation } from "../../../../Auth/config";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";
import classNames from "../../ManageCards.module.scss";

export default function Addresses({
  onSuccess,
  onBack,
  form,
  cardData,
  createNewCardMutation,
}) {
  const [countrySelected, setCountrySelected] = useState(null);

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      onFinish={onSuccess}
      form={form}
      style={{ margin: "auto" }}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <Form.Item
        label={
          <span className={classNames.label}>
            Address <sup style={{ top: 1 }}>*</sup>
          </span>
        }
      >
        <Form.Item
          name="physicalCardCountry"
          rules={[{ required: true, message: validation.country.required }]}
        >
          <CountryPicker
            size="small"
            placeholder={validation.country.placeholder}
            onChange={(code) => {
              setCountrySelected(code);
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: validation.street.required,
            },
            { max: MAX_MIN.street.max, message: validation.street.max },
          ]}
          name="physicalCardStreet"
        >
          <Input placeholder={validation.street.placeholder} size="small" />
        </Form.Item>
        <Form.Item
          name="physicalCardStreet2"
          rules={[{ max: MAX_MIN.street.max, message: validation.street2.max }]}
        >
          <Input placeholder={validation.street2.placeholder} size="small" />
        </Form.Item>
        <Form.Item
          name="physicalCardCity"
          rules={[
            { required: true, message: validation.city.required },
            { max: MAX_MIN.city.max, message: validation.city.max },
          ]}
        >
          <Input placeholder={validation.city.placeholder} size="small" />
        </Form.Item>
        <Form.Item
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          name="physicalCardState"
          rules={[{ required: true, message: validation.state.required }]}
        >
          {countrySelected === "US" ? (
            <Select
              size="small"
              showSearch
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              placeholder={validation.state.placeholder}
            >
              {usStateList().map((value) => (
                <Select.Option key={value.code} value={value.code}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              size="small"
              placeholder={validation.state.placeholder}
              readOnly={true}
            />
          )}
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: validation.postalCode.required },
            {
              pattern: new RegExp(REGEX.postalCode),
              message: validation.postalCode.valid,
            },
          ]}
          name="physicalCardPostalCode"
        >
          <Input placeholder={validation.postalCode.placeholder} size="small" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "10px 0 0 0px",
          float: "left",
        }}
      >
        <CTAButton
          type="outline"
          style={{ height: 44 }}
          onClick={() => onBack()}
        >
          Back
        </CTAButton>
      </Form.Item>
      <Form.Item
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          margin: "10px 0 0 0px",
          float: "right",
        }}
      >
        {/* <CTAButton
          type="primary"
          style={{ height: 44 }}
          onClick={() => form.validateFields().then((data) => onSuccess(data))}
        >
          Next
        </CTAButton> */}
        <CTAButton
          type="primary"
          style={{ height: 44 }}
          loading={createNewCardMutation.isLoading}
          onClick={() =>
            form.validateFields().then((data) => {
              onSuccess(data);
            })
          }
        >
          Submit
        </CTAButton>
      </Form.Item>
    </Form>
  );
}
