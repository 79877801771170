import React from "react";

const ESignConsent = () => {
  return (
    <>
      <h2>Statement of Electronic Disclosure:</h2>
      <p>
        In order to electronically review and retrieve the documents related to
        your account(s) with Otterz and delivered to you in connection with this
        request for consent (statements, transactions and other relevant
        reports), you will first need to review and accept this consent form.
      </p>
      <p>
        With your consent, Otterz can deliver the Documents and other
        disclosures and contracts related to your account(s) to you by
        displaying or delivering them electronically, and requesting that you
        print or download them and retain them for your records.
      </p>
      <h2>
        This notice contains important information that you are entitled to
        receive before you consent to electronic records and electronic
        signatures in connection with your account(s) at Otterz. Please read
        this notice carefully and print or download a copy for your files.
      </h2>
      <p>
        After you have read this information, if you agree to receive and accept
        the Documents from Otterz electronically, and if you agree to general
        use of electronic records and electronic signatures in connection with
        your account(s) at Otterz, please acknowledge your acceptance of this
        consent by checking the box before you are able to continue with your
        application. In doing so, you are providing your affirmative consent to
        use electronic communications. By checking the box, you are also
        confirming that you meet the system requirements described below, that
        you have demonstrated your ability to receive, retain, and view
        electronic documents on your access device, and that you have a current
        email address.
      </p>
      <div>
        <b>
          You have the right to change back to non-electronic delivery of
          statements.
        </b>{" "}
        <span>
          We do not offer a non-electronic delivery of statements. If you choose
          this option, you agree that your account(s) will be closed, and
          existing balance(s) will be returned to you.
        </span>
      </div>
      <p>
        You agree to print out or download the Documents when we advise you to
        do so and keep them for your records. If you have any trouble printing
        or downloading any of the Documents, you may contact us by telephone at
        ( 857-234-4000) or by email at{" "}
        <a href="mailto:support@otterz.co" className="link">
          support@otterz.co.
        </a>
      </p>
      <p>
        If you wish to change your notification email address, you may do so
        within the mobile app, by logging in to your account through our
        website, by telephone at ( 857-234-4000) or by email at{" "}
        <a href="mailto:support@otterz.co" className="link">
          support@otterz.co.
        </a>
      </p>
      <div>
        In order to access the Documents electronically, you must have:
        <ul>
          <li>
            <span>A computer or mobile device with an Internet connection</span>
          </li>
          <li>
            <span>
              We support personal computers, Android and current IOS platforms
              for both phone and tablets; and Internet Explorer, Microsoft Edge,
              Safari, Chrome browsers. Windows 10 or higher, Mac OS 10 or higher
              with 32 bit encryption is required to access statements and
              electronic records. In additon the following is required:
            </span>
            <ul>
              <li>
                <span>
                  The ability to view and retain Portable Document Format (PDF)
                  files
                </span>
              </li>
              <li>
                <span>An email address</span>
              </li>
              <li>
                <span>
                  Either a printer, hard drive or other storage device
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p>
        You represent that you have the hardware, software, email address and
        capacities described above, and your ability to read this Consent
        demonstrates that you have the necessary hardware and software to
        receive Communications electronically.
      </p>
      <p>
        Your consent does not mean that the Otterz must provide all Documents
        related to your account(s) electronically. Otterz, at its option, may
        deliver documents or otherwise communicate with you on paper if it
        chooses to do so. Otterz may also require that certain communications
        from you be delivered to Otterz on paper at a specific address.
      </p>
      <p>
        I have read the information about the use of electronic records,
        disclosures, notices, and email, and consent to use of electronic
        records for the delivery of documents, including disclosures and other
        notices, in connection with my account(s) at Otterz. I have been able to
        view this information using my computer and software. I have an account
        with an Internet Service Provider, and I am able to send email and
        receive emails with hyperlinks to websites and attached files. I also
        consent to the use of electronic records and electronic signatures in
        connection with my account(s) to [Otterz] in place of written documents
        and handwritten signatures.
      </p>
      <h1>IMPORTANT: PLEASE PRINT AND RETAIN THIS CONSENT AND AUTHORIZATION</h1>
    </>
  );
};

export default ESignConsent;
