import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Bugsnag from '@bugsnag/js';
import { useNavigate, useLocation } from "react-router-dom";

import { ROUTES } from '../../constants';
import { Message } from '../../common';
import { tempUrls } from '../../../apiEndpoints';

/**
 * SocureDocV Component
 * 
 * This component initiates the Socure DocV process for document verification.
 * It is primarily used during the public signup flow.
 */
function SocureDocV() {
    const [sdkInitiated, setSdkInitiated] = useState(false);
    const [showStart, setShowStart] = useState(true);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const token = queryParams.get('token');
    const otz_person_id = queryParams.get('otz_person_id');

    const navigate = useNavigate();

    /**
     * Callback for tracking the progress of the verification.
     * 
     * @param {Object} progress - Progress data from Socure SDK.
     */
    const onProgress = (progress) => {
        // Currently not logging progress, but can be used for future enhancements.
    };

    /**
     * Callback for successful verification.
     * 
     * @param {Object} response - Response data from Socure SDK.
     */
    const onSuccess = async (response) => {
        try {
            const env = process.env.REACT_APP_ENV?.trim();
            const docVerificationEndpoint = tempUrls.DOCV_INIT_KYC[env];

            // Send verification data to the backend.
            const verificationResponse = await fetch(docVerificationEndpoint, {
                method: "POST",
                headers: {
                    // "Authorization": `Bearer ${process.env.REACT_APP_SOCURE_TOKEN}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "otz_person_id": otz_person_id,
                    "token": token,
                    "document_id": response.documentUuid,
                    "customer_consent": true
                })
            });

            const verificationData = await verificationResponse.json();

            // if (verificationData.payload.verification_status === "REJECTED") {
            //     Message({
            //         type: "success",
            //         content: "Document verification successful!"
            //     });
            //     navigate(ROUTES.PUBLIC.LOGIN);
            // } else {
            //     Message({
            //         type: "error",
            //         content: "Document verification failed!"
            //     });
            //     navigate(ROUTES.PUBLIC.LOGIN);
            // }
            if (verificationData?.payload?.verifications[0]?.result === "REJECTED") {
                Message({
                    type: "error",
                    content: "Document verification failed!"
                });
                navigate(ROUTES.PUBLIC.LOGIN);
            } else if (verificationData?.payload?.verifications[0]?.result === "ACCEPTED" || verificationData.payload.verification_status === "ACCEPTED") {
                Message({
                    type: "success",
                    content: "Document verification successful!"
                });
                navigate(ROUTES.PUBLIC.LOGIN);
            } else {
                Message({
                    type: "warning",
                    content: "Document verification pending!"
                });
                navigate(ROUTES.PUBLIC.LOGIN);
            }
        } catch (error) {
            Bugsnag.notify("Error verifying the document: ", error);
        }
    };

    /**
     * Callback for handling errors during verification.
     * 
     * @param {Object} error - Error data from Socure SDK.
     */
    const onError = (error) => {
        Bugsnag.notify("DocV Error: ", error);
    };

    // Configuration for the Socure SDK.
    const config = {
        onProgress,
        onSuccess,
        onError,
        qrCodeNeeded: true
    };

    /**
     * Start the Socure SDK process.
     */
    const start = async () => {
        try {
            const env = process.env.REACT_APP_ENV?.trim();
            const tokenVerifyURL = tempUrls.DOCV_VERIFY_TOKEN[env];

            // Verify token in URL.
            const verificationResponse = await fetch(tokenVerifyURL, {
                method: "POST",
                headers: {
                    // "Authorization": `Bearer ${process.env.REACT_APP_SOCURE_TOKEN}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "otz_person_id": otz_person_id,
                    "token": token,
                })
            });

            const verificationData = await verificationResponse.json();

            if (!verificationData.payload.isExpired && verificationData?.status !== 400) {
                setShowStart(false);

                if (sdkInitiated) {
                    clearSession();
                    setSdkInitiated(false);
                }
            
                window.SocureInitializer.init(process.env.REACT_APP_SOCURE_TOKEN)
                    .then(lib => {
                        lib.init(process.env.REACT_APP_SOCURE_TOKEN, "#websdk", config)
                            .then(() => {
                                lib.start(1).then(response => {
                                    setSdkInitiated(true);
                                }, error => {
                                    Bugsnag.notify("DocV Initiation error: ", error);
                                });
                            });
                    });

            } else {
                if(verificationData?.message === "token is expired") {
                    Message({
                        type: "error",
                        content: "Link is expired"
                    });
                } else {
                    Message({
                        type: "error",
                        content: "Invalid link, token is invalid"
                    });
                }
            }
        } catch (error) {
            Bugsnag.notify("Token verification failed: ", error);
        }
    };

    /**
     * Clear the Socure SDK session.
     */
    const clearSession = () => {
        window.Socure.cleanup();
        window.Socure.reset();
        sessionStorage.removeItem('documentVerificationToken');
        sessionStorage.removeItem('publicApiKey');
        localStorage.removeItem('devicer_id');
    };

    // Load the Socure SDK script when the component mounts.
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://websdk.socure.com/bundle.js";
        script.async = true;
        document.body.appendChild(script);

        // Cleanup the script when the component is unmounted.
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            {showStart && (
                <div style={{width: '100%', height: '100vh', display: 'flex', position: "absolute", top: "0", right: "0", alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <h1>Start document verification procedure</h1>
                    <Button type='primary' size="large" onClick={start}>Start</Button>
                </div>
            )}
            <div id="websdk" style={{height: "100vh"}}></div>
        </>
    );
}

export default SocureDocV;
