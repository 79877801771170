import { useState, useEffect, useMemo } from "react";
import { Space, Radio, Spin, Empty } from "antd";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  GradientDefs,
  LineMarkSeries,
  DiscreteColorLegend,
  Hint,
} from "react-vis";
import moment from "moment";
import ArrowRightDownLineIcon from "remixicon-react/ArrowRightDownLineIcon";
import ArrowRightUpLineIcon from "remixicon-react/ArrowRightUpLineIcon";
import { Title } from "../../../common";
import {
  centsToDollarWithoutSign,
  intToDecimalDollar,
} from "../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT, FORMATS } from "../../../constants";
import classNames from "../Overview.module.scss";

export default function CashFlow({
  totalWithdrawals,
  totalDeposits,
  transactions,
  isDataLoading,
}) {
  //Hooks
  const [filterType, setFilterType] = useState("month");
  const [dataGroup, setDataGroup] = useState({
    deposit: getTotalSumByMonth(transactions, "credit"),
    withdrawal: getTotalSumByMonth(transactions, "debit"),
  });
  const [toolTipState, setToolTipState] = useState();

  useEffect(() => {
    if (filterType) {
      switch (filterType) {
        case "month":
          setDataGroup({
            deposit: getTotalSumByMonth(transactions, "credit"),
            withdrawal: getTotalSumByMonth(transactions, "debit"),
          });
          break;
        case "day":
          setDataGroup({
            deposit: getTotalSumByDayOfWeek(transactions, "credit"),
            withdrawal: getTotalSumByDayOfWeek(transactions, "debit"),
          });
          break;
        case "week":
          setDataGroup({
            deposit: getTotalSumByWeek(transactions, "credit"),
            withdrawal: getTotalSumByWeek(transactions, "debit"),
          });
          break;
        default:
          setDataGroup({
            deposit: getTotalSumByMonth(transactions, "credit"),
            withdrawal: getTotalSumByMonth(transactions, "debit"),
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, transactions]);

  const trendLine = useMemo(() => {
    if (
      Array.isArray(dataGroup?.deposit) &&
      Array.isArray(dataGroup?.withdrawal)
    ) {
      return dataGroup?.deposit?.map((point, i) => {
        return {
          x: point.x,
          y: parseFloat(
            (point.y - Math.abs(dataGroup?.withdrawal[i]?.y)).toFixed(2)
          ),
        };
      });
    } else if (!dataGroup?.deposit) {
      return dataGroup?.withdrawal?.map((point, i) => {
        return {
          x: point.x,
          y: parseFloat((0 - Math.abs(dataGroup?.withdrawal[i].y)).toFixed(2)),
        };
      });
    } else {
      return dataGroup?.deposit?.map((point, i) => {
        return {
          x: point.x,
          y: parseFloat(point.y),
        };
      });
    }
  }, [dataGroup?.deposit, dataGroup?.withdrawal]);

  //Other variable
  const oneWeekBefore = moment()
    .subtract(6, "days")
    .format(FORMATS.DATEPICKER_FORMAT);

  //Event handlers
  const setToolTipValue = (value, type) => {
    let tooltipContent = {};

    switch (filterType) {
      case "day":
        tooltipContent = tooltipValue(value, "Day", type);
        break;
      case "week":
        tooltipContent = tooltipValue(value, "Week", type);
        break;
      default:
        tooltipContent = tooltipValue(value, "Month", type);
    }

    setToolTipState(tooltipContent);
  };

  const removeToolTipValue = () => {
    setToolTipState(null);
  };

  //Helper functions
  function filterDataByMonth(data, month) {
    return data?.filter(
      (item) => new Date(item?.date).getMonth() === month - 1
    );
  }

  function calculateTotalSum(data, type) {
    if (type === "credit") {
      return data?.reduce((total, item) => total + item?.creditAmount, 0);
    } else {
      return -data?.reduce((total, item) => total + item?.debitAmount, 0);
    }
  }

  function filterDataByWeek(data, week) {
    return data.filter((item) => {
      const itemWeek = getWeek(new Date(item?.date));
      return itemWeek === week;
    });
  }

  function filterDataByDayOfWeek(data, dayOfWeek) {
    return data?.filter((item) => {
      const itemDayOfWeek = new Date(item?.date).getDay();
      const date = moment(item.date).format(FORMATS.DATEPICKER_FORMAT);

      if (moment(date)?.isAfter(moment(oneWeekBefore))) {
        return itemDayOfWeek === dayOfWeek;
      } else {
        return false;
      }
    });
  }

  function getWeek(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  function getTotalSumByWeek(data, type) {
    const weeks = [...Array(4)].map((item, index) => ({
      weekNumber: moment().subtract(index, "weeks").week(),
      dateInterval: `${moment()
        .subtract(index, "weeks")
        .startOf("week")
        .format(FORMATS.DATE_MONTH)} to ${moment()
        .subtract(index, "weeks")
        .endOf("week")
        .format(FORMATS.DATE_MONTH)}`,
    }));

    return weeks
      .map((week) => ({
        x: week.dateInterval,
        y:
          calculateTotalSum(filterDataByWeek(data, week.weekNumber), type) /
          100,
      }))
      ?.reverse();
  }

  function getTotalSumByMonth(data, type) {
    const months = [
      { month: "Jan", number: 1 },
      { month: "Feb", number: 2 },
      { month: "Mar", number: 3 },
      { month: "Apr", number: 4 },
      { month: "May", number: 5 },
      { month: "Jun", number: 6 },
      { month: "Jul", number: 7 },
      { month: "Aug", number: 8 },
      { month: "Sep", number: 9 },
      { month: "Oct", number: 10 },
      { month: "Nov", number: 11 },
      { month: "Dec", number: 12 },
    ];

    return months.map((month) => ({
      x: month.month,
      y: calculateTotalSum(filterDataByMonth(data, month.number), type) / 100,
    }));
  }

  function getTotalSumByDayOfWeek(data, type) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const currentDay = new Date().getDay();

    const dataByWeekTemp = daysOfWeek.map((day, index) => {
      return {
        x: day,
        y: calculateTotalSum(filterDataByDayOfWeek(data, index), type) / 100,
      };
    });

    const tempArray = dataByWeekTemp.slice(0, currentDay + 1);
    const newArray = dataByWeekTemp.slice(currentDay + 1).concat(tempArray);

    return newArray;
  }

  const getCurrentYearMonth = () => {
    if (filterType === "day") {
      return `(${oneWeekBefore}  to  ${moment().format(
        FORMATS.DATEPICKER_FORMAT
      )})`;
    } else if (filterType === "week") {
      return null;
    } else {
      return `(${moment().year()})`;
    }
  };

  const tooltipValue = (value, filter, type) => {
    let dummyVal;
    switch (type) {
      case "cashIn":
        dummyVal = {
          [filter]: value.x,
          "Cash In": intToDecimalDollar(value.y) || DEFAULT_AMOUNT,
        };
        break;
      case "cashOut":
        dummyVal = {
          [filter]: value.x,
          "Cash Out": intToDecimalDollar(value.y) || DEFAULT_AMOUNT,
        };
        break;
      default:
        dummyVal = {
          [filter]: value.x,
          "Net Cashflow": intToDecimalDollar(value.y) || DEFAULT_AMOUNT,
        };
    }

    return dummyVal;
  };

  return (
    <>
      <div className={classNames.cashflow}>
        <div className={classNames.cashflowCardHeader}>
          <Title as="h3">Cashflow {getCurrentYearMonth()}</Title>
          <Spin spinning={isDataLoading}>
            {totalDeposits || totalWithdrawals ? (
              <div className={classNames.cashflowCardFilters}>
                <Radio.Group defaultValue="d" buttonStyle="solid">
                  <Space size={0}>
                    <Radio.Button
                      value="b"
                      onClick={() => {
                        setFilterType("day");
                      }}
                    >
                      D
                    </Radio.Button>
                    <Radio.Button
                      value="c"
                      onClick={() => {
                        setFilterType("week");
                      }}
                    >
                      W
                    </Radio.Button>
                    <Radio.Button
                      value="d"
                      onClick={() => {
                        setFilterType("month");
                      }}
                    >
                      M
                    </Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
            ) : null}
          </Spin>
        </div>
        <div className={classNames.cashflowChart}>
          {(totalDeposits || totalWithdrawals) && transactions?.length >= 1 ? (
            <>
              <FlexibleXYPlot
                xType="ordinal"
                margin={{ left: 45, right: 0, top: 10, bottom: 35 }}
                animation={true}
              >
                <HorizontalGridLines />
                <XAxis />
                <YAxis
                  tickPadding={1}
                  tickFormat={(value) => {
                    const lookupArray = [
                      { scale: 1, symbol: "" },
                      { scale: 1e3, symbol: "k" },
                      { scale: 1e6, symbol: "M" },
                      { scale: 1e9, symbol: "G" },
                      { scale: 1e12, symbol: "T" },
                      { scale: 1e15, symbol: "P" },
                      { scale: 1e18, symbol: "E" },
                    ];
                    const regX = /\.0+$|(\.[0-9]*[1-9])0+$/;
                    let item = lookupArray
                      .slice()
                      .reverse()
                      .find(function (item) {
                        return Math.abs(value) >= item.scale;
                      });
                    return item
                      ? (value / item.scale).toFixed(0).replace(regX, "$1") +
                          item.symbol
                      : "0";
                  }}
                />
                <GradientDefs>
                  <linearGradient
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="1"
                    id="depositGradient"
                  >
                    <stop
                      offset="0%"
                      stop-color="#e4fcf1"
                      stop-opacity="-0.1"
                    />
                    <stop offset="100%" stop-color="#83DEB3" />
                  </linearGradient>
                  <linearGradient
                    x1="0"
                    x2="0"
                    y1="1"
                    y2="0"
                    id="withdrawlGradient"
                  >
                    <stop
                      offset="0%"
                      stop-color="#E8E8E8"
                      stop-opacity="-0.1"
                    />
                    <stop offset="100%" stop-color="#E8E8E8" />
                  </linearGradient>
                </GradientDefs>
                {toolTipState ? <Hint value={toolTipState} /> : null}

                {/* Withdrawals */}
                <VerticalBarSeries
                  cluster="netCashFlow"
                  animation={false}
                  // color="#EE6969"
                  colorType="literal"
                  onValueMouseOver={(value) =>
                    setToolTipValue(value, "cashOut")
                  }
                  onValueMouseOut={removeToolTipValue}
                  color={"url(#withdrawlGradient)"}
                  barWidth={0.5}
                  data={dataGroup?.withdrawal}
                  className={classNames.barSeriesRoundedCorner}
                />

                {/* Deposits */}
                <VerticalBarSeries
                  cluster="netCashFlow"
                  animation={false}
                  onValueMouseOver={(value) => setToolTipValue(value, "cashIn")}
                  onValueMouseOut={removeToolTipValue}
                  // color="#9AF198"
                  colorType="literal"
                  color={"url(#depositGradient)"}
                  barWidth={0.5}
                  data={dataGroup?.deposit}
                  className={classNames.barSeriesRoundedCorner}
                />
                {/* Net cashflow */}
                <LineMarkSeries
                  animation={false}
                  onValueMouseOver={(value) =>
                    setToolTipValue(value, "netCashFlow")
                  }
                  onValueMouseOut={removeToolTipValue}
                  data={trendLine}
                  colorType="literal"
                  color={"#7372ff"}
                  // color={"#83DEB3"}
                  strokeWidth={2}
                  barWidth={0.6}
                  size={4}
                  curve={"curveMonotoneX"}
                />
              </FlexibleXYPlot>
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"No Data Found"}
            />
          )}
        </div>

        <DiscreteColorLegend
          className={classNames.cashflowLegend}
          style={{ strokeLinecap: "round" }}
          orientation="horizontal"
          items={[
            {
              title: "Cash in",
              color: "#83DEB3",
              strokeWidth: 8,
              style: {
                display: "inline-block",
              },
            },
            {
              title: "Cash out",
              color: "#E8E8E8",
              strokeWidth: 8,
            },
            {
              title: "Net cashflow",
              color: "#7372ff",
              strokeWidth: 8,
            },
          ]}
        />
      </div>
      <div className={classNames.cardRight}>
        <div className={classNames.totalWithdrawls}>
          <div>
            <ArrowRightUpLineIcon className={classNames.icon} />
            <Title as="h3">Total Cash Out</Title>
          </div>
          <div className={classNames.amount}>
            <span>$</span> {centsToDollarWithoutSign(totalWithdrawals) || 0.00}
          </div>
        </div>
        <div className={classNames.totalDeposits}>
          <div>
            <ArrowRightDownLineIcon size={10} className={classNames.icon} />
            <Title as="h3">Total Cash In</Title>
          </div>
          <div className={classNames.amount}>
            <span>$</span> {centsToDollarWithoutSign(totalDeposits) || 0.00}
          </div>
        </div>
      </div>
    </>
  );
}
