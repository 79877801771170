import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { Form, Upload, /*Button as AntButton,*/ Input, Select, Table, Divider } from "antd";
import {
  CTAButton,
  Title,
  Message,
  BackButton,
} from "../../common";
import { useNavigate } from "react-router-dom";
import Close from "remixicon-react/CloseLineIcon";
import UploadIcon from "remixicon-react/Upload2LineIcon";
import { FILE_SIZE } from "../../constants";
import Heading from "../Heading";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import classNames from "../Signup/Signup.module.scss";

export default function UploadBusinessDocs() {
  const { userData } = useContext(AppContext);

  const [boData, setBOData] = useState([]);
  const [, setRequiredDocsUploaded] = useState(false);
  const [businessDocs, setBusinessDocs] = useState([
    { name: 'Formation Document', fileList: [] },
    { name: 'EIN Verification', fileList: [] }
  ]);
  const [hasFilesUploaded, setHasFilesUploaded] = useState(false);
  const [files, setFiles] = useState({});
  const [documents, setDocuments] = useState([]);
  const [identificationDocs, setIdentificationDocs] = useState([{ person: '', name: '', fileList: [] }]);
  const [, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  const beneficialOwnerDetailMutation = useMutation((id) => {
    return api.onBoarding.getBusinessPersonDetail({ otz_to_business_id: id });
  });

  const uploadRegDocsMutation = useMutation((formData) =>
    api.onBoarding.uploadRegDocsOnboarding(formData)
  );

  useEffect(() => {
    getListDocuments.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Update the mutation to set the documents
  const getListDocuments = useMutation(() => api.onBoarding.getListDocuments({otz_business_id: application?.payload?.otz_business_id}), {
      onSuccess: (data) => {
        const documentsArray = data?.payload;
        setDocuments(documentsArray);
        
        // Call getDocumentFile mutation for each document in the array
        if (Array.isArray(documentsArray)) {
          documentsArray.forEach(document => {
            if (document?.otz_doc_id) {
              getDocumentFile.mutate({ id: document.otz_doc_id });
            }
          });
        }
      }
  });
  
  const getDocumentFile = useMutation((obj) => api.onBoarding.getDocumentFile(obj), {
    onSuccess: async (data, variables) => {
      try {
        const response = await data;
        if (response?.status === 200) {
          const blob = await response.blob();

          let finalBlob;
          if (blob.type === "application/pdf") {
            finalBlob = new Blob([blob], { type: "application/pdf" });
          } else {
            finalBlob = new Blob([blob], { type: blob.type });
          }
  
          const file = new File([finalBlob], "filename", { type: finalBlob.type });
          
          // Store the fetched file in the state
          setFiles(prevFiles => ({
            ...prevFiles,
            [variables.id]: file
          }));
        }
      } catch (error) {
        console.error("Error fetching the document file:", error);
      }
    }
  });
    

  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: (data) => {
        beneficialOwnerDetailMutation.mutate(data?.payload?.otz_business_id, {
          onSuccess: (res) => {
            setBOData(res?.payload);
          },
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const truncateFileName = (name, maxLength = 15) => {
    if (name.length <= maxLength) return name;
    return `${name.substring(0, maxLength - 3)}...`;
  };

  const { Dragger } = Upload;
  const addBusinessDoc = () => {
    setBusinessDocs([...businessDocs, { name: '', dragger: null }]);
  };

  const addIdentificationDoc = () => {
    setIdentificationDocs([...identificationDocs, { person: '', name: '', dragger: null }]);
  };

  const removeBusinessDoc = (indexToRemove) => {
    setBusinessDocs(prevDocs => prevDocs.filter((_, index) => index !== indexToRemove));
  };

  const removeIdentificationDoc = (indexToRemove) => {
    setIdentificationDocs(prevDocs => prevDocs.filter((_, index) => index !== indexToRemove));
  };

  const handleOnFinish = async () => {
    const businessFiles = businessDocs.flatMap(doc => doc.fileList.map(file => ({ ...file, docType: doc.name })));
    const identificationFiles = identificationDocs.flatMap(doc => doc.fileList.map(file => ({ ...file, docType: doc.name, otz_person_id: doc.otz_person_id }))); // Include otz_person_id

    const uploadBusinessDocs = businessFiles.map(async (file) => {
      const formData = new FormData();
      if (application?.payload?.otz_business_id) {
        formData.append("otz_business_id", application?.payload?.otz_business_id);
      }
      formData.append("file", file.originFileObj);
      formData.append("doc_type", file.docType);
  
      try {
        return await uploadRegDocsMutation.mutateAsync(formData);
      } catch (error) {
        generateErrorMessage(error);
        throw error;
      }
    });
  
    const uploadIdentificationDocs = identificationFiles.map(async (file) => {
      const formData = new FormData();
      if (file?.otz_person_id) {
        formData.append("otz_person_id", file?.otz_person_id);
      }
      formData.append("file", file.originFileObj);
      formData.append("doc_type", file.docType);
  
      try {
        return await uploadRegDocsMutation.mutateAsync(formData);
      } catch (error) {
        generateErrorMessage(error);
        throw error;
      }
    });
  
    try {
      setIsUploading(true);
    
      const results = await Promise.all([...uploadBusinessDocs, ...uploadIdentificationDocs]);
    
      console.log("Upload results: ", results);
      if (results.every(result => result && (result?.message === "Document added successfully" || result?.message === "Document exist and has been updated to newer version."))) { // Check if result exists and has a success message
        console.log("Uploaded successfully!");
        
        // Clear the fileList for businessDocs and identificationDocs
        setBusinessDocs(prevDocs => prevDocs.map(doc => ({ ...doc, fileList: [] })));
        setIdentificationDocs(prevDocs => prevDocs.map(doc => ({ ...doc, fileList: [] })));

        const formationUploaded = businessDocs.some(doc => doc.name === 'Formation Document' && doc.fileList.length > 0);
        const einUploaded = businessDocs.some(doc => doc.name === 'EIN Verification' && doc.fileList.length > 0);
        
        if (formationUploaded && einUploaded) {
            updateApplication.mutate(
                {
                  onBoardingStatus: "BUSINESS_DOCS_UPLOADED",
                },
                {
                  onSuccess: () => {},
                  onError: (err) => {
                    throw new Error(err);
                  },
                }
            );
        }

        // Reset the businessDocs and identificationDocs to their initial values
        setBusinessDocs([
            { name: 'Formation Document', fileList: [] },
            { name: 'EIN Verification', fileList: [] }
        ]);
        setIdentificationDocs([{ person: '', name: '', fileList: [] }]);
        setInputKey(Date.now());

        Message({ type: "success", content: "All documents uploaded successfully!" });
        setIsUploading(false);

        getListDocuments.mutate();
      }
    } catch (error) {
      setIsUploading(false);
      Message({ type: "error", content: "Failed to upload documents. Please try again." });
    }
  };

  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case "image/jpg":
      case "image/jpeg":
        return ".jpg";
      case "image/png":
        return ".png";
      case "application/pdf":
        return ".pdf";
      default:
        return ".file";
    }
  };  
  
  const businessColumns = [
    {
      title: 'Document Name',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (text, record) => {
        const file = files[record.key];
        if (file) {
          return (
            <a 
              href={URL.createObjectURL(file)}
              download={text + getFileExtension(file.type)}
            >
              {text}
            </a>
          );
        }
        return text;
      }
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploadedOn',
      key: 'uploadedOn',
    },
  ];

  const identificationColumns = [
    {
      title: 'Person Name',
      dataIndex: 'personName',
      key: 'personName',
    },
    {
      title: 'Document Name',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (text, record) => {
        const file = files[record.key];
        if (file) {
          return (
            <a 
              href={URL.createObjectURL(file)}
              download={text + getFileExtension(file.type)}
            >
              {text}
            </a>
          );
        }
        return text;
      }
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploadedOn',
      key: 'uploadedOn',
    },
  ];

  const formationDocuments = documents.filter(doc => doc.doc_type === 'Formation Document').sort((a, b) => new Date(b.last_updated_time) - new Date(a.last_updated_time));
  const einDocuments = documents.filter(doc => doc.doc_type === 'EIN Verification').sort((a, b) => new Date(b.last_updated_time) - new Date(a.last_updated_time));
  
  const latestFormationDocument = formationDocuments.length > 0 ? [formationDocuments[0]] : [];
  const latestEINDocument = einDocuments.length > 0 ? [einDocuments[0]] : [];
  
  const businessDocuments = [
    ...latestFormationDocument,
    ...latestEINDocument,
    ...documents.filter(doc => doc.otz_business_id && doc.doc_type !== 'Formation Document' && doc.doc_type !== 'EIN Verification')
  ].map(doc => ({
    key: doc.otz_doc_id,
    documentName: doc.doc_type,
    uploadedOn: new Date(doc.last_updated_time).toLocaleDateString()
  }));

  const identificationDocuments = documents.filter(doc => doc.otz_person_id).map(doc => {
    const person = boData.find(owner => owner.otz_person_id === doc.otz_person_id);
    const personName = person ? `${person.first_name} ${person.last_name}` : 'Unknown';
  
    return {
      key: doc.otz_doc_id,
      personName: personName,
      documentName: doc.doc_type,
      uploadedOn: new Date(doc.last_updated_time).toLocaleDateString()
    };
  });  

  const handleBusinessDocNameChange = (e, index) => {
    const updatedDocs = [...businessDocs];
    updatedDocs[index].name = e.target.value;
    setBusinessDocs(updatedDocs);
  };
  
  const handleIdentificationDocNameChange = (type, value, index) => {
    const updatedDocs = [...identificationDocs];
    if (type === "name") {
      updatedDocs[index].name = value;
    } else if (type === "person") {
      updatedDocs[index].person = value;
      updatedDocs[index].otz_person_id = value.split('-')[1];
    }
    setIdentificationDocs(updatedDocs);
  };
  

  // Handle file change for business docs
  const handleBusinessDocsFileChange = (info, index) => {
    const currentDoc = businessDocs[index];
    if (!currentDoc.name) {
      Message({
        type: "error",
        content: `Enter file name in input first`,
      });
      return;
    }

    const updatedFileList = [...info.fileList].map(file => {
        return {
            ...file,
            name: truncateFileName(file.name)
        };
    });

    if (updatedFileList.length > 0) {
      updatedFileList[updatedFileList.length - 1].doc_type = currentDoc.name;
    }
    const updatedDocs = [...businessDocs];
    updatedDocs[index].fileList = updatedFileList;
    setBusinessDocs(updatedDocs);
    
    const formationUploaded = businessDocs.some(doc => doc.name === 'Formation Document' && doc.fileList.length > 0);
    const einUploaded = businessDocs.some(doc => doc.name === 'EIN Verification' && doc.fileList.length > 0);
    setRequiredDocsUploaded(formationUploaded && einUploaded);
    
    if (info.file.status === 'removed') {
      const updatedDocs = [...businessDocs];
      updatedDocs[index].fileList = updatedFileList.filter(file => file.status !== 'removed');
      setBusinessDocs(updatedDocs);
    }

    // Check if any files are uploaded
    const hasBusinessFiles = businessDocs.some(doc => doc.fileList && doc.fileList.length > 0);
    const hasIdentificationFiles = identificationDocs.some(doc => doc.fileList && doc.fileList.length > 0);
    setHasFilesUploaded(hasBusinessFiles || hasIdentificationFiles);
  };
  

  // Handle file change for identification docs
  const handleIdentificationDocsFileChange = (info, index) => {
    const currentDoc = identificationDocs[index];
    if (!currentDoc.name) {
      Message({
        type: "error",
        content: `Enter file name in input first`,
      });
      return;
    }
    
    const updatedFileList = [...info.fileList].map(file => {
        return {
            ...file,
            name: truncateFileName(file.name)
        };
    });

    if (updatedFileList.length > 0) {
      updatedFileList[updatedFileList.length - 1].doc_type = currentDoc.name;
      updatedFileList[updatedFileList.length - 1].otz_person_id = currentDoc.otz_person_id;
    }
  
    const updatedDocs = [...identificationDocs];
    updatedDocs[index].fileList = updatedFileList;
    setIdentificationDocs(updatedDocs);
    
    if (info.file.status === 'removed') {
      const updatedDocs = [...identificationDocs];
      updatedDocs[index].fileList = updatedFileList.filter(file => file.status !== 'removed');
      setIdentificationDocs(updatedDocs);
    }

    const hasBusinessFiles = businessDocs.some(doc => doc.fileList && doc.fileList.length > 0);
    const hasIdentificationFiles = identificationDocs.some(doc => doc.fileList && doc.fileList.length > 0);
    setHasFilesUploaded(hasBusinessFiles || hasIdentificationFiles);
  };  

  const acceptableType = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
  const acceptableTypes = acceptableType.join(",");

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  const navigate = useNavigate();
  const renderBackButton = () => {
    return <BackButton variant="tail" onClick={() => navigate(-1)} />;
  };

  return (
    <div className={classNames.accountFormWrapper}>
      <div className={classNames.stepsWrapper} style={{backgroundColor: "rgb(28 30 31 / 0%)"}}>
        <div className={classNames.stepperBackButton}>{renderBackButton()}</div>
      </div>
      <div className={classNames.formBOInstructionsWrapper} style={{width: "55%", margin: "-12vh auto 36px auto"}}>
        <Heading
          classNames={classNames}
          title="Documents"
        />

        <Title as="h4" className={classNames.label} style={{marginTop:"2rem"}}>
          Business Documents
        </Title>

        {businessDocuments.length > 0 && (
          <Table 
            columns={businessColumns} 
            dataSource={businessDocuments} 
            pagination={false} 
            style={{marginTop: "1rem"}} 
          />
        )}

        {businessDocs.map((doc, index) => (
          <div key={index} style={{margin: "2rem 0"}}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Form.Item style={{ flex: 1, marginRight: 8 }}>
                <Input 
                  placeholder="Enter document name" 
                  value={doc.name}
                  readOnly={index < 2}
                  onChange={(e) => handleBusinessDocNameChange(e, index)}
                />
              </Form.Item>
                <Dragger
                    className={"dragger-docs"}
                    maxCount={1}
                    accept={acceptableTypes}
                    beforeUpload={(file) => {
                      if (!acceptedType(file)) {
                        setError(false);
                        Message({
                          type: "error",
                          content: `File type is not allowed. Please select a JPEG`,
                        });
                      } else if (file?.size > FILE_SIZE.B) {
                        setError(true);
                      } else {
                        return false; // return false so file is not auto uploaded
                      }
                    }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (acceptedType(file) && file?.size < FILE_SIZE.B) {
                        onSuccess("ok");
                      } else onError();
                    }}
                    fileList={doc.fileList}
                    onChange={(info) => handleBusinessDocsFileChange(info, index)}
                    showUploadList={true}
                    style={{padding:'0 10px', height: '10px', width: '100%'}}
                  >
                      <p className="ant-upload-icon" style={{ margin: 0, height: '22px' }}>
                        <UploadIcon style={{ fontSize: "18px", color: "#9BA3AB" }} />
                      </p>
                      {/* <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                        {language.DRAG_DROP}
                        <AntButton
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </AntButton>
                      </p> */}
                </Dragger>
              {doc.name !== 'Formation Document' && doc.name !== 'EIN Verification' && (
                <button 
                  onClick={() => removeBusinessDoc(index)}
                  style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer', fontSize: '1.5rem', marginLeft: '0', marginTop: '10px' }}
                >
                  <Close className={classNames.featureIcon} color='#dd2c2c' size={16} />
                </button>
              )}
            </div>
          </div>
        ))}
        <button onClick={addBusinessDoc} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', width: '100%', textAlign: 'right'  }}>
          Add another document
        </button>

        <Divider />

        <Title as="h4" className={classNames.label} style={{marginTop:"2rem"}}>
          Identification documents
        </Title>
        
        {identificationDocuments.length > 0 && (
          <Table 
            columns={identificationColumns} 
            dataSource={identificationDocuments} 
            pagination={false} 
            style={{marginTop: "1rem"}}
          />
        )}

        {identificationDocs.map((doc, index) => (
          <div key={index} style={{margin: "2rem 0"}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '3px' }}>
            <Form.Item name={`personName-${index}`} style={{ flex: 1, marginRight: 8 }}>
              <Select 
                placeholder="Select person"
                onChange={(value) => handleIdentificationDocNameChange("person", value, index)}
                value={doc.person}
              >
                  {boData.map((owner) => (
                    <Select.Option key={owner.otz_person_id} value={`${owner.first_name} ${owner.last_name}-${owner.otz_person_id}`}>
                      {owner.first_name} {owner.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={`identificationDocName-${index}`} key={`doc-${inputKey}`} style={{ flex: 1 }}>
                <Input 
                  placeholder="Enter document name" 
                  onChange={(e) => handleIdentificationDocNameChange("name", e.target.value, index)}
                  value={doc.name}
                />
              </Form.Item>
              <Dragger
                  className={"dragger-docs"}
                  maxCount={1}
                  accept={acceptableTypes}
                  beforeUpload={(file) => {
                    if (!acceptedType(file)) {
                      setError(false);
                      Message({
                        type: "error",
                        content: `File type is not allowed. Please select a JPEG`,
                      });
                    } else if (file?.size > FILE_SIZE.B) {
                      setError(true);
                    } else {
                      return false; // return false so file is not auto uploaded
                    }
                  }}
                  fileList={doc.fileList}
                  onChange={(info) => handleIdentificationDocsFileChange(info, index)}
                  customRequest={({ file, onSuccess, onError }) => {
                    if (acceptedType(file) && file?.size < FILE_SIZE.B) {
                      onSuccess("ok");
                    } else onError();
                  }}
                  showUploadList={true}
                  style={{ padding: "0 10px", height: '10px', width: '100%'}}
                >
                    <p className="ant-upload-drag-icon" style={{ margin: 0, height: '22px' }}>
                        <UploadIcon style={{ fontSize: "18px", color: "#9BA3AB" }} />
                    </p>
                    {/* <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                      {language.DRAG_DROP}
                      <AntButton
                        type="link"
                        size="small"
                        style={{ fontSize: "12px" }}
                      >
                        {language.IMAGE_UPLOAD_BTN_TEXT}
                      </AntButton>
                    </p> */}
              </Dragger>
              <button onClick={() => removeIdentificationDoc(index)} style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer', marginTop: '20px' }}>
                <Close className={classNames.featureIcon} color='#dd2c2c' size={16} />
              </button>
            </div>
          </div>
        ))}
        <button onClick={addIdentificationDoc} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', width: '100%', textAlign: 'right' }}>
          Add another document
        </button>
        <CTAButton type="primary" style={{marginTop:"3rem", display: "inline-block", width: "calc(40% - 4px)", float: "right" }} onClick={handleOnFinish} disabled={!hasFilesUploaded} loading={isUploading || uploadRegDocsMutation.isLoading}>Submit</CTAButton>
      </div>
    </div>
  );
}
