import {
  Button as AntButton,
  Card,
  DatePicker,
  Input as AntInput,
  Space,
  Table,
} from "antd";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "../../../../store";
import api from "../../../API";
import {
  dateFormat,
  transactionModes,
  transactionStates,
  transactionTypes,
} from "../../../API/PaymentAcceptance/Transactions/listTransaction.api";
import { Header, Input, Message, Select } from "../../../common";
import { FORMATS, STATUS } from "../../../constants";
import AddNewTransactions from "./AddNewTransaction/AddNewTransactions";
import TransactionsInformation from "./TransactionsInformation/TransactionsInformation";
import { cardTypeLogos } from "../../common/cardTypeLogos";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import dateUtils from "../../../util/dateUtils";
import classNames from "./Transactions.module.scss";

export default function Transactions() {
  const navigate = useNavigate();

  const [transactionRecord, setTransactionRecord] = useState();
  const [selectedFilter, setSelectedFilter] = useState("dateRange");
  const [dateFilter, setDateFilter] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION);

  const {
    state: { filter },
    actions: {
      setDateRangeFilter,
      setTransactionTypeFilter,
      setTransactionModeFilter,
      setTransactionCommandFilter,
      setTransactionInputTypeFilter,
    },
  } = useStoreContext((store) => store.paymentAcceptanceTransactions);

  const [transactionsInformationModal, setTransactionsInformationModal] =
    useState(false);

  const [createNewTransactionsModal, setCreateNewTransactionsModal] =
    useState(false);

  const { RangePicker } = DatePicker;

  const { data: currentUserData } = useQuery(
    ["getCurrentUser"],
    () => api.Auth.fetchCurrentUser(),
    {
      onSuccess: (data) => {},
      refetchOnWindowFocus: false,
    }
  );

  const [transactions, setTransactions] = useState([]);
  const [isAPIError, setIsAPIError] = useState(false);

  useEffect(() => {
    setDateFilter(false);
    setDateRangeFilter(null);
    setTransactionTypeFilter("");
    setTransactionModeFilter("");
    setTransactionCommandFilter("");
    setTransactionInputTypeFilter("xAmount", "");
    setTransactionInputTypeFilter("xName", "");
    setTransactionInputTypeFilter("xRefNum", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Other constant
  const currentDate = new Date();
  const nextDay = new Date().setDate(currentDate.getDate() + 1);
  const monthStartDate = new Date(
    new Date(nextDay).setMonth(currentDate.getMonth() - 1)
  );
  const cardArray = ["JCB", "Diners"];

  const { mutate: fetchTransaction, isLoading: isTransactionsListLoading } =
    useMutation(
      async () => {
        let maxRecordCount = 1000;
        let xBeginDateMoment;
        let xEndDateMoment;
        if (dateFilter) {
          xBeginDateMoment = moment(dateFilter?.xBeginDate);
          xEndDateMoment = moment(dateFilter?.xEndDate);
          if (xEndDateMoment.diff(xBeginDateMoment, "hours", true) <= 24) {
            maxRecordCount = 5000;
          }
        } else {
          xBeginDateMoment = moment(monthStartDate);
          xEndDateMoment = moment();
        }

        const otherFilters = {};
        ["xName", "xRefNum", "xAmount", "xBillCountry", "xBillCity"].forEach(
          (field) => {
            if (filter.api[field]?.length > 2)
              otherFilters[field] = filter.api[field];
          }
        );

        const response =
          await api.Receivables.PaymentAcceptance.listTransactions(
            filter.api.xCommand,
            maxRecordCount,
            dateUtils.localToTimezone(xBeginDateMoment, "EST", dateFormat),
            dateUtils.localToTimezone(xEndDateMoment, "EST", dateFormat),
            otherFilters
          );

        const filteredTransactions = response?.data?.xReportData
          ?.map((transaction) => {
            if (
              filter.transactionType === transactionTypes.all.type ||
              transaction?.xCommand?.includes(filter.transactionType)
            ) {
              return {
                ...transaction,
                transactionType: transaction?.xCommand?.split(":")[0],
              };
            }
            return undefined;
          })
          .filter((transaction) => !!transaction)
          .map((transaction) => {
            if (
              filter.transactionMode === transactionModes.all.mode ||
              transaction?.xCommand?.includes(filter.transactionMode)
            ) {
              return {
                ...transaction,
                transactionMode: transaction?.xCommand?.split(":")[1],
              };
            }
            return undefined;
          })
          .filter((transaction) => !!transaction);

        return {
          ...response,
          data: { ...response.data, xReportData: filteredTransactions },
        };
      },
      {
        onSuccess: (data) => {
          setPagination(PAGINATION);
          if (data?.data?.xError) {
            setIsAPIError(true);
            setTransactions([]);
            Message({ type: "error", content: data.data.xError });
          } else {
            setIsAPIError(false);
            setTransactions(data?.data?.xReportData || []);
            Message({ type: "success", content: "Transactions Fetched Successfully" });
          }
        },
        onError: (error) => {
          if (error?.message) {
            setIsAPIError(true);
            setTransactions([]);
            Message({ type: "error", content: error.message });
          }
        },
      }
    );

  const debounceRef = useRef();
  // In initial page load initialFlagRef.current will be undefined.
  // We don't want to fetch if transactions are already persisted.
  // If there are no persisted data, the other Effect will fetch the transactions in the initial page load.
  // This Effect is dedicated only for fetching transactions when user interacts and changes the filters, with a debounce.
  // `isAPIError` flag is being used to identify state update on setting transactions to [] as a result of API failure.
  // Here, checking `isAPIError` is a must to stop continuous re-renders.
  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (!isAPIError) {
        fetchTransaction();
      }
    }, 600);
  }, [filter, isAPIError, fetchTransaction]);

  const onFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const onTransactionTypeChanged = (value) => {
    setTransactionTypeFilter(value);
    setIsAPIError(false);
  };

  const onTransactionModeChanged = (value) => {
    setTransactionModeFilter(value);
    setIsAPIError(false);
  };

  const onTransactionStateChanged = (value) => {
    setTransactionCommandFilter(value);
    setIsAPIError(false);
  };

  const onDateRangeChange = (values) => {
    if (values) {
      setDateFilter({
        xBeginDate: moment(values[0]).format(dateFormat),
        xEndDate: moment(values[1]).format(dateFormat),
      });
      setDateRangeFilter(
        moment(values[0]).format(dateFormat),
        moment(values[1]).format(dateFormat)
      );
      setIsAPIError(false);
    } else {
      setDateRangeFilter(null);
      setDateFilter(false);
    }
  };

  const onInputFilterChange = (filterParam, value) => {
    setTransactionInputTypeFilter(filterParam, value);
    setIsAPIError(false);
  };

  const handleTransactionsInformationCancelClick = () => {
    setTransactionsInformationModal(false);
  };

  const handleTransactionsCancelClick = () => {
    setCreateNewTransactionsModal(false);
  };

  const columns = [
    {
      title: "Reference",
      dataIndex: "xRefNum",
      key: "xRefNum",
      width: 150,
    },
    {
      title: "Cardholder Name",
      dataIndex: "xName",
      key: "xName",
      render: (xName, record) =>
        xName ||
        [record?.xShipFirstName, record?.xShipLastName]
          ?.filter(Boolean)
          ?.join(" ") ||
        [record?.xBillFirstName, record?.xBillLastName]
          ?.filter(Boolean)
          ?.join(" ") ||
        "-",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "xAmount",
      key: "xAmount",
      width: 125,
    },
    {
      title: "Country",
      dataIndex: "xBillCountry",
      key: "xBillCountry",
      width: 100,
      ellipsis: true,
    },
    {
      title: "City",
      dataIndex: "xBillCity",
      key: "xBillCity",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "transactionType",
      key: "transactionType",
      width: 75,
    },
    {
      title: "Mode",
      dataIndex: "transactionMode",
      key: "transactionMode",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "xResponseResult",
      key: "xResponseResult",
      width: 100,
      render: (status, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {record?.xVoid === "1" ? (
            <span style={{ color: "#BC7C1D" }}>Void</span>
          ) : (
            <>
              {(status === STATUS.APPROVED && (
                <span style={{ color: "#1DBC83" }}>Approved</span>
              )) ||
                (status === "Declined" && (
                  <span style={{ color: "#BC7C1D" }}>Declined</span>
                )) ||
                (status === "Error" && (
                  <span style={{ color: "#EE6969" }}>Declined</span>
                ))}
            </>
          )}
        </Space>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "xEnteredDate",
      key: "xEnteredDate",
      width: 190,
      render: (xEnteredDate) => {
        const newFormattedDate = moment(
          xEnteredDate,
          FORMATS.DATE_TIME_A_FORMAT
        ).format(FORMATS.DATE_TIME_FORMAT);

        const date = dateUtils.tzToLocal(
          newFormattedDate,
          "EST",
          FORMATS.DATE_TIME_FORMAT
        );
        return date;
      },
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a.xEnteredDate, FORMATS.DATE_TIME_FORMAT).diff(
          moment(b.xEnteredDate, FORMATS.DATE_TIME_FORMAT)
        );
      },
    },
    {
      title: "Account Number",
      dataIndex: "xMaskedAccountNumber",
      key: "xMaskedAccountNumber",
      width: 150,
      render: (account, record) => {
        return cardTypeLogos[record.xCardType]?.image ? (
          "-"
        ) : (
          <div className={classNames.paymentMedium}>
            <span>{account}</span>
          </div>
        );
      },
    },
    {
      title: "Card Number",
      dataIndex: "xMaskedAccountNumber",
      key: "xMaskedAccountNumber",
      render: (account, record) => {
        return cardTypeLogos[record.xCardType]?.image ? (
          <div className={classNames.paymentMedium}>
            <div
              className={
                cardArray?.includes(record?.xCardType)
                  ? classNames.cardImage
                  : ""
              }
            >
              {cardTypeLogos[record.xCardType]?.image}
            </div>
            <span>{account}</span>
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Transactions" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.transactionFilters}>
              <AntInput.Group className={classNames.search} compact>
                <Select
                  value={selectedFilter}
                  className={classNames.filterSelect}
                  onChange={onFilterChange}
                >
                  <Select.Option value="dateRange">
                    Date Range
                    {/* {(filter?.api?.xBeginDate || filter?.api?.xEndDate) && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="transactionType">
                    Transaction Type
                   {/*  {filter?.transactionType !== transactionTypes.all.type && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="transactionMode">
                    Transaction Mode
                   {/*  {filter?.transactionMode !== transactionModes.all.mode && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="transactionState">
                    Transaction State
                   {/*  {filter?.api?.xCommand !== transactionStates.all.state && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="xRefNum">
                    Reference Number
                  {/*   {!!filter?.api?.xRefNum?.substring(2) && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="xName">
                    Cardholder Name
                    {/* {!!filter?.api?.xName?.substring(2) && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                  <Select.Option value="xAmount">
                    Transaction Amount
                   {/*  {!!filter?.api?.xAmount?.substring(2) && (
                      <span style={{ color: "red", marginLeft: 8 }}>
                        {" "}
                        &bull;
                      </span>
                    )} */}
                  </Select.Option>
                </Select>

                {["xName", "xRefNum", "xAmount"].includes(selectedFilter) && (
                  <Input
                    style={{ width: "65%" }}
                    className={classNames.filterInput}
                    placeholder="Please enter"
                    value={filter.api[selectedFilter].substring(2)}
                    onChange={(e) =>
                      onInputFilterChange(selectedFilter, e?.target?.value)
                    }
                  />
                )}

                {selectedFilter === "transactionType" && (
                  <Select
                    placeholder="Select type"
                    onChange={onTransactionTypeChanged}
                    value={filter?.transactionType}
                    style={{ minWidth: 200 }}
                  >
                    {Object.values(transactionTypes).map((type) => (
                      <Select.Option key={type.type} value={type.type}>
                        {type.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                {selectedFilter === "transactionMode" && (
                  <Select
                    placeholder="Select mode"
                    onChange={onTransactionModeChanged}
                    value={filter?.transactionMode}
                    style={{ minWidth: 200 }}
                  >
                    {Object.values(transactionModes).map((mode) => (
                      <Select.Option key={mode.mode} value={mode.mode}>
                        {mode.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                {selectedFilter === "transactionState" && (
                  <Select
                    placeholder="Select state"
                    onChange={onTransactionStateChanged}
                    value={filter?.api?.xCommand}
                    style={{ minWidth: 200 }}
                  >
                    {Object.values(transactionStates).map((state) => (
                      <Select.Option key={state.state} value={state.state}>
                        {state.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                {selectedFilter === "dateRange" && (
                  <RangePicker
                    // showTime
                    style={{ width: "65%" }}
                    placeholder={["From", "To"]}
                    suffixIcon={false}
                    nextIcon={false}
                    separator={<span style={{ color: "#E5E7EB" }}>|</span>}
                    className={classNames.filterDatePicker}
                    format={FORMATS.DATE_FORMAT}
                    value={
                      dateFilter
                        ? [
                            moment(filter?.api?.xBeginDate),
                            moment(filter?.api?.xEndDate),
                          ]
                        : [moment(monthStartDate), moment()]
                    }
                    onChange={onDateRangeChange}
                  />
                )}
              </AntInput.Group>
            </div>
            <Card
              className={classNames.optionCard}
              onClick={() => setCreateNewTransactionsModal(true)}
            >
              <div className={classNames.cardAction}>
                <span>Add New Transaction</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                margin: "26px 0",
              }}
            >
              {isTransactionsListLoading
                ? "Transactions loading . . ."
                : `${transactions.length} transactions loaded`}
            </div>
            <Table
              loading={isTransactionsListLoading}
              size="large"
              dataSource={transactions
                ?.sort((a, b) =>
                  moment(a?.xEnteredDate, FORMATS.DATE_TIME_FORMAT).diff(
                    moment(b?.xEnteredDate, FORMATS.DATE_TIME_FORMAT)
                  )
                )
                ?.reverse()}
              columns={columns}
              className={classNames.table}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  }
                  if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setTransactionsInformationModal(true);
                    setTransactionRecord(record);
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
      <TransactionsInformation
        visible={transactionsInformationModal}
        onCancel={handleTransactionsInformationCancelClick}
        record={transactionRecord}
        fetchTransaction={fetchTransaction}
        currentUserEmail={
          currentUserData?.signInUserSession?.idToken?.payload?.email
        }
      />

      <AddNewTransactions
        visible={createNewTransactionsModal}
        onCancel={handleTransactionsCancelClick}
        fetchTransaction={fetchTransaction}
        isCustomerPage={false}
      />
    </div>
  );
}
