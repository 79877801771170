import * as types from "../../actionTypes";
import api from "../../../App/API";

const getApplicationByTenantId = (id) => async (dispatch) => {
  dispatch({
    type: types.GET_APPLICATION_BY_TENANT_ID_LOADING,
  });
  try {
    const data = await api.onBoarding.getApplicationByTenantId(id);
    dispatch({
      type: types.GET_APPLICATION_BY_TENANT_ID_SUCCESS,
      payload: data?.payload,
    });
    return {
      type: types.GET_APPLICATION_BY_TENANT_ID_SUCCESS,
      payload: data?.payload,
    };
  } catch (error) {
    dispatch({
      type: types.GET_APPLICATION_BY_TENANT_ID_ERROR,
      error: "Error occurred",
    });
  }
};

export const clearApplicationData = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_APPLICATION_DATA,
  });
  dispatch({ type: types.CLEAR_BUSINESS_PERSON_DETAIL_DATA });
};

export const getBusinessPersonDetail = (id) => async (dispatch) => {
  dispatch({
    type: types.GET_BUSINESS_PERSON_DETAIL_LOADING,
  });
  try {
    const data = await api.onBoarding.getBusinessPersonDetail({
      otz_to_business_id: id,
    });
    dispatch({
      type: types.GET_BUSINESS_PERSON_DETAIL_SUCCESS,
      payload: data?.payload,
    });
  } catch (error) {
    dispatch({
      type: types.GET_BUSINESS_PERSON_DETAIL_ERROR,
      error: "Error occurred",
    });
  }
};

export default getApplicationByTenantId;
