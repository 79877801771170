import { API } from "aws-amplify";

export default async function cardReplace(id) {
  if (id) {
    return await API.post("cards", "replace-card", {
      queryStringParameters: {
        cardId: id,
      },
    });
  }
}
