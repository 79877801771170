import { API } from "aws-amplify";

export default async function getProducts(id) {
  if (id) {
    return await API.get("product", "list-products", {
      queryStringParameters: {
        companyId: id,
      },
    });
  }
}
