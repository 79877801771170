import { Form } from "antd";
import PropTypes from "prop-types";
import { Input } from "../../../common";

export default function InputPassword({
  label,
  name,
  rules,
  placeholder,
  ...props
}) {
  return (
    <Form.Item label={label} name={name} rules={rules} {...props}>
      <Input placeholder={placeholder} type="password" />
    </Form.Item>
  );
}

InputPassword.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
};
