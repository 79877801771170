import classNames from "./EditInvoice.module.scss";
import {
  Title,
  Input,
  Select,
  DatePicker,
  CTAButton,
  Message,
} from "../../../../common";
import {
  Form,
  Input as AntInput,
  Button as AntButton,
  AutoComplete,
} from "antd";
import {
  RiAddFill,
  RiArrowDropDownLine,
  RiCloseCircleFill,
} from "react-icons/ri";
import { validation } from "../../../../Auth/config";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import api from "../../../../API";
import { intToDecimalDollar } from "../../../../util/functions/dollarConvertion";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { DEFAULT_AMOUNT, FORMATS } from "../../../../constants";
import TextArea from "antd/lib/input/TextArea";
import { MAX_MIN } from "../../../../util/constants";

export default function EditInvoiceForm({
  invoice,
  customers,
  products,
  taxRates,
  onCancel,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  const language = useLanguage(messages);

  const [summary, setSummary] = useState({
    subTotal: invoice.subTotal,
    tax: invoice.taxTotal,
  });
  const queryClient = useQueryClient();
  const [discount, setDiscount] = useState(invoice.discount);
  const [currFields, setCurrFields] = useState(invoice.item.length);

  const calculatePrice = (index, flag, value) => {
    let values = form.getFieldsValue();
    let obj = values["salesItems"][index];
    let product =
      Array.isArray(products) &&
      products.filter((value, index) => value.id === obj.description?.[0]);
    let productUnitCost;
    let productTaxRate;
    if (flag === "description") {
      let productFromProductsArray =
        Array.isArray(products) &&
        products.filter((productItem, index) => productItem.id === value.id);
      productUnitCost = productFromProductsArray[0]?.price || 0;
      productTaxRate = productFromProductsArray[0]?.taxRate || 0;
    }
    if (flag === "taxRate") {
      let taxObj =
        Array.isArray(taxRates) &&
        taxRates.filter((taxRate) => taxRate.id === value.id)[0];
      productTaxRate = (taxObj && taxObj.taxRate) || value[0];
    }
    if (flag === "unitCost") {
      let taxObj =
        (Array.isArray(taxRates) &&
          taxRates.filter(
            (taxRateItem) => taxRateItem.id === obj.taxRate?.[0]
          )[0]) ||
        0;
      productTaxRate =
        (taxObj && taxObj.taxRate) || (obj.taxRate && obj.taxRate[0]);
    }

    productTaxRate = productTaxRate
      ? Number(productTaxRate)
      : Number(obj.taxRate);
    let unitCost = productUnitCost ? Number(productUnitCost) : obj.unitCost;
    let quantity = obj.quantity && (parseInt(obj.quantity) && parseInt(obj.quantity) > 0) 
      ? parseInt(obj.quantity) 
      : '';
    let totalWithOutTax = Number(unitCost) * Number(quantity) || 0;
    let taxAmount = productTaxRate
      ? parseFloat((totalWithOutTax * Number(productTaxRate)) / 100)
      : 0;
    let price = unitCost * quantity;
    //  + taxAmount;
    let array = values["salesItems"];

    Object.assign(obj, {
      unitCost: unitCost || 0,
      price: Number(price.toFixed(2)) || 0,
      taxAmount: taxAmount,
      taxRate: productTaxRate ? Number(productTaxRate) : 0,
      subTotal: totalWithOutTax,
      quantity: quantity,
      description:
        product && product.length > 0
          ? product[0]?.productName
          : obj.description,
    });

    if (flag === "remove") {
      delete array[index];
      array.filter(Boolean);
      form.setFieldsValue({ salesItems: array });
      setCurrFields((prev) => prev - 1);
    } else {
      array.filter(Boolean);
      form.setFieldsValue({ salesItems: array });
    }
    let subTotal = array
      .map(
        (value, index) =>
          Number(value.subTotal) ||
          Number(value.unitCost) * Number(value.quantity)
      )
      .reduce((sum, next) => sum + next, 0);
    let tax = array
      .map((value, index) => {
        return (
          Number(value.taxAmount) ||
          Number(value.tax) ||
          Number(value.unitCost * value.quantity * (value.taxRate / 100)) ||
          0
        );
      })
      .reduce((sum, next) => sum + next, 0);
    setSummary({ subTotal, tax });
  };

  function editInvoiceFn(data) {
    let array = data.salesItems ? data.salesItems : invoice.item;
    let itemDto =
      array &&
      array.length > 0 &&
      array.map((item, index) => {
        return {
          description:
            item.description && Array.isArray(item.description)
              ? item.description[0]
              : item.description,
          quantity: Number(item.quantity),
          taxRate: item.taxRate ? Number(item.taxRate) : 0,
          unitCost: Number(item.unitCost),
        };
      });

    const reference =
      data.invoiceReference === "" && !data.invoiceReference
        ? " "
        : data.invoiceReference;
    let input = {
      id: invoice.id,
      invoiceDate: data.invoiceDate
        ? moment(data.invoiceDate).format(FORMATS.API_OTHER_FORMAT)
        : moment(invoice.invoiceDate).format(FORMATS.API_OTHER_FORMAT),
      discount: data.invoiceDiscount
        ? Number(data.invoiceDiscount)
        : data.invoiceDiscount === ""
        ? 0
        : Number(invoice.discount),
      reference: reference || invoice.purchaseOrderNumber,
      itemDto: itemDto,
    };

    return api.Receivables.Invoices.editInvoice(input);
  }

  const updateInvoiceStatusFn = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);
  const updateInvoiceStatusMutation = useMutation((event) =>
    updateInvoiceStatusFn(event)
  );

  const editInvoiceMn = useMutation((event) => editInvoiceFn(event));
  const onFinish = (values) => {
    if (form.isFieldsTouched()) {
      editInvoiceMn.mutate(values, {
        onSuccess: () => {
          updateInvoiceStatusMutation.mutate(
            {
              id: invoice.id,
              type: "invoice",
              status: "draft",
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries("getInvoices");
                queryClient.invalidateQueries("getInvoiceById", invoice.id);
                queryClient.invalidateQueries("getInvoicePDF", invoice.id);
                Message({
                  type: "success",
                  content: language.INVOICE_UPDATE_SUCCESS,
                });
                onCancel(true);
              },
              onError: (error) => generateErrorMessage(error),
            }
          );
        },
        onError: (error) => generateErrorMessage(error),
      });
    }
  };

  let initialInvoiceSaleItems =
    invoice &&
    invoice.item &&
    invoice.item.length > 0 &&
    invoice.item.map((value) => {
      return {
        description: value.description,
        unitCost: value.unitCost,
        quantity: value.quantity,
        taxRate: value.taxRate,
        price: value.unitCost * value.quantity,
      };
    });

  //Update invoice
  // const updateInvoiceFunction = (event) =>
  //   api.Receivables.Invoices.setInvoiceStatus(event);

  // const updateInvoiceMutation = useMutation(
  //   (event) => updateInvoiceFunction(event),
  //   {
  //     onSuccess: () => {
  //       Message({
  //         type: "success",
  //         content: language.INVOICE_UPDATE_SUCCESS,
  //       });
  //     },
  //   }
  // );

  //Helper
  function validationCheck() {
    return (
      form
        ?.getFieldsError()
        ?.filter(
          (item) =>
            ["unitCost", "description"].includes(item?.name[2]) &&
            item?.errors?.length
        )?.length ||
      !(form?.getFieldsValue()?.salesItems?.length
        ? form?.getFieldsValue()?.salesItems[currFields - 1]?.unitCost &&
          form?.getFieldsValue()?.salesItems[currFields - 1]?.description
        : false)
    );
  }

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <div className={classNames.modalBodyFormUpper}>
        <div className={classNames.modalLeft}>
          <div className={classNames.modalHeader}>
            <Title as="h3">Invoice Details</Title>
          </div>
          <Form.Item
            name="invoiceNumber"
            label={
              <Title as="h4" className={classNames.label}>
                Invoice Number
              </Title>
            }
          >
            <Input
              size="small"
              style={{ width: 168 }}
              defaultValue={invoice?.invoiceNumber}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="receiverName"
            label={
              <Title as="h4" className={classNames.label}>
                Customer
              </Title>
            }
          >
            <Select
              size="small"
              style={{ width: 352 }}
              defaultValue={invoice.receiverName}
              disabled={true}
            >
              {Array.isArray(customers) &&
                customers.map((val, index) => (
                  <Select.Option value={val.id}>{val.name}</Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="invoiceDate"
              label={
                <span className={classNames.label}>
                  Date<sup style={{ top: 1 }}>*</sup>
                </span>
              }
              style={{
                display: "inline-block",
                marginRight: 14,
              }}
              initialValue={moment(invoice.invoiceDate)}
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker
                defaultValue={moment(invoice.invoiceDate)}
                def
                style={{ height: 44, width: 168 }}
              />
            </Form.Item>

            <Form.Item
              name="invoiceDiscount"
              label={<span className={classNames.label}>Discount</span>}
              style={{
                display: "inline-block",
              }}
              rules={[
                {
                  pattern: new RegExp(
                    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g
                  ),
                  message: "Valid Discount required.",
                },
              ]}
            >
              <Input
                type="number"
                defaultValue={invoice.discount}
                size="small"
                style={{ width: 168 }}
                prefix="%"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="invoiceReference"
            label={
              <Title as="h4" className={classNames.label}>
                Reference
              </Title>
            }
            rules={[
              {
                max: MAX_MIN.description.max,
                message: `Max ${MAX_MIN.description.max} characters`,
              },
            ]}
          >
            <Input
              size="small"
              style={{ width: 352 }}
              maxLength={50}
              defaultValue={invoice.purchaseOrderNumber}
            />
          </Form.Item>
        </div>

        <div className={classNames.modalRight}>
          <div className={classNames.invoiceSummary}>
            <div className={classNames.modalHeader}>
              <Title as="h3">Invoice Summary</Title>
            </div>
            <div>
              <span>Sub Total</span>
              <span>
                {intToDecimalDollar(Number(summary.subTotal)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Tax</span>
              <span>
                {intToDecimalDollar(Number(summary.tax)) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Discount</span>
              <span>{discount}% </span>
            </div>
            <div className={classNames.total}>
              <span>Total</span>
              <span>
                {intToDecimalDollar(
                  Number(
                    summary.subTotal -
                      summary.subTotal * (discount / 100 || 0) +
                      summary.tax
                  )
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Amount Paid</span>
              <span>
                {intToDecimalDollar(
                  Number(invoice.total - invoice.amountDue)
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
            <div>
              <span>Amount Due</span>
              <span>
                {intToDecimalDollar(
                  Number(
                    summary.subTotal -
                      summary.subTotal * (discount / 100) -
                      (summary.subTotal - summary.subTotal) * (discount / 100) +
                      summary.tax -
                      (invoice.total - invoice.amountDue)
                  )
                ) || DEFAULT_AMOUNT}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.saleItemsWrapper}>
        <div className={classNames.modalHeader}>
          <Title as="h3">Sale Items</Title>
        </div>

        <div className={classNames.salesItemsHeader}>
          <span
            style={{
              width: "35%",
              marginLeft: currFields > 1 ? "32px" : "",
              marginRight: currFields > 1 ? "-5px" : "5px",
            }}
          >
            Description <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 125 }}>
            Unit Cost <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 75 }}>
            Qty <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 115 }}>
            Tax <sup style={{ top: 1 }}>*</sup>
          </span>
          <span style={{ width: 150 }}>Price</span>
        </div>
        <Form.List
          name="salesItems"
          rules={[
            {
              validator: async (_, salesItems) => {
                if (!salesItems || salesItems.length < 1) {
                  return Promise.reject(new Error(validation.salesItems.error));
                }
              },
            },
          ]}
          initialValue={initialInvoiceSaleItems}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  className={classNames.inputGroup}
                >
                  {fields.length > 1 ? (
                    <RiCloseCircleFill
                      size={20}
                      className={classNames.inputGroupRemoveIcon}
                      onClick={() => {
                        if (currFields > 1) {
                          calculatePrice(index, "remove");
                          remove(field.name);
                        } else {
                          form.resetFields();
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <AntInput.Group
                    compact
                    className={classNames.inputGroupItems}
                  >
                    <Form.Item
                      name={[field.name, "description"]}
                      style={{
                        width: "35%",
                        marginRight: "5px",
                      }}
                      rules={[
                        { required: true, message: "Required" },
                        {
                          max: MAX_MIN.description.max,
                          message: `Max ${MAX_MIN.description.max} characters`,
                        },
                      ]}
                    >
                      <AutoComplete
                        size="large"
                        style={{ width: "100%" }}
                        className={classNames.autoSelectDesc}
                        onChange={(label, value) => {
                          calculatePrice(index, "description", value);
                        }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={true}
                        options={
                          Array.isArray(products) &&
                          products.map((val, index) => {
                            return {
                              label: val.productName,
                              value: val.productName,
                              id: val.id,
                            };
                          })
                        }
                        mode="tags"
                        maxTagCount={1}
                      ></AutoComplete>
                      {/* <Input
                        size="small"
                        placeholder="Please enter"
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      /> */}
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "unitCost"]}
                      rules={[
                        { required: true, message: "Required" },
                        {
                          pattern: new RegExp(
                            /^(?![0.]*$)\d{0,8}(?:\.\d{1,2})?$/
                          ),
                          message: "Enter a valid price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value === "0" || value === 0) {
                              return Promise.reject(
                                new Error("0 is not allowed")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                        () => ({
                          validator(_, value) {
                            if (value && value?.toString()?.length > 11) {
                              return Promise.reject(
                                new Error("Max 8 digits with 2 decimal points")
                              );
                            }
                            if (!value?.toString()?.length) {
                              return Promise.reject(new Error("Required"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder="0"
                        prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                        style={{ width: 125, marginRight: "5px" }}
                        onChange={() => calculatePrice(index, "unitCost")}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "quantity"]}
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Invalid quantity",
                        },
                        () => ({
                          validator(_, value) {
                            if (value) {
                              value = value.toString().replace(/[e+-.]/gi, '');
                            }
                            if (value && value?.toString()?.length > 6) {
                              return Promise.reject(
                                new Error("Max 6 characters")
                              );
                            }
                            if (!value?.toString()?.length) {
                              return Promise.reject(new Error("Required"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder="Enter"
                        style={{ width: 75, marginRight: "5px" }}
                        onChange={() => calculatePrice(index)}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "taxRate"]}
                      // rules={[
                      //   {
                      //     max: 1,
                      //     min: 1,
                      //     message: "Only one tax rate allowed!",
                      //     type: "number",
                      //   },
                      // ]}
                      // validateTrigger={["onChange", "onBlur"]}
                    >
                      <Select
                        size="small"
                        style={{ width: 115, marginRight: "5px" }}
                        className={classNames.salesItemSelect}
                        onChange={(value) => {
                          calculatePrice(index, "taxRate", value);
                        }}
                        showSearch
                        optionFilterProp="keyword"
                        filterOption={true}
                        // mode="tags"
                        // maxTagCount={1}
                        suffixIcon={
                          <span style={{ color: "#9ba3ab" }}>
                            %<RiArrowDropDownLine />
                          </span>
                        }
                        options={
                          Array.isArray(taxRates) &&
                          taxRates.map((val, index) => {
                            return {
                              label: `${val.taxRateName} (${val.taxRate}%)`,
                              value: Number(val.taxRate),
                              keyword: val.taxRate,
                            };
                          })
                        }
                      ></Select>
                    </Form.Item>
                    <Form.Item name={[field.name, "price"]}>
                      <Input
                        size="small"
                        placeholder="0"
                        prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                        style={{
                          width: 150,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          marginRight: "5px",
                        }}
                        // type="number"
                        disabled
                        //   value={getItemsPrice(
                        //     form.getFieldValue("Items")[index]
                        //   )}
                      />
                    </Form.Item>
                  </AntInput.Group>
                </Form.Item>
              ))}
              <Form.Item>
                <div
                  className={classNames.salesItemsFooter}
                  style={{ marginLeft: currFields === 1 ? "0" : "14px" }}
                >
                  <AntButton
                    onClick={() => {
                      const currentSaleItem =
                        form.getFieldsValue().salesItems[currFields - 1];
                      if (
                        currentSaleItem?.unitCost &&
                        currentSaleItem?.description
                      ) {
                        add();
                        setCurrFields((prev) => prev + 1);
                      }
                    }}
                    type="link"
                    className={classNames.addNewButton}
                    disabled={validationCheck()}
                  >
                    <RiAddFill size={13} style={{ marginRight: 5 }} />
                    Add new item
                  </AntButton>
                </div>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div className={classNames.modalHeader}>
        <Title as="h3">More Information</Title>
      </div>
      <Form.Item>
        <Form.Item
          name="invoiceNotes"
          label={<span className={classNames.label}>Notes</span>}
          style={{
            display: "inline-block",
            marginRight: 14,
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            disabled
            defaultValue={invoiceTemplateSettings?.notes}
            autoSize={true}
          />
        </Form.Item>

        <Form.Item
          name="invoiceTerms"
          label={<span className={classNames.label}>Terms</span>}
          style={{
            display: "inline-block",
          }}
        >
          <TextArea
            size="small"
            style={{ width: 380 }}
            disabled
            defaultValue={invoiceTemplateSettings?.terms}
            autoSize={true}
          />
        </Form.Item>
      </Form.Item>
      <div className={classNames.modalBottom}>
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          onClick={() => form.submit()}
          style={{
            height: 44,
            width: 248,
            marginLeft: "71px",
          }}
          loading={editInvoiceMn.isLoading}
          disabled={!form.isFieldsTouched()}
        >
          Save
        </CTAButton>
      </div>
    </Form>
  );
}
