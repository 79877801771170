import { useState, useEffect, useContext } from "react";
import mixpanel from "mixpanel-browser";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";
import BankLineIcon from "remixicon-react/BankLineIcon";
import FileTransferLineIcon from "remixicon-react/FileTransferLineIcon";
import BillLineIcon from "remixicon-react/BillLineIcon";
import GlobalLineIcon from "remixicon-react/GlobalLineIcon";
import SecurePaymentLineIcon from "remixicon-react/SecurePaymentLineIcon";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { Card, Table, Button as AntButton, Spin, Space, Tooltip } from "antd";
import { Title, Button, Header, TypeTag } from "../../common";
import ACHPayments from "./ACHPayments/ACHPayments";
// import FreezePayment from "./ScheduledPayments/FreezePayment/FreezePayment";
// import UnfreezePayment from "./ScheduledPayments/UnfreezePayment/UnfreezePayment";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
// import { isPaymentOnPast } from "./helpers/isPaymentOnPast";
import { centsToDollarWithoutSign } from "../../util/functions/dollarConvertion";
import { isBusinessType } from "../../util/functions/onboarding";
import generateErrorMessage from "../../util/functions/customError";
import { FORMATS, ROUTES } from "../../constants";
import WirePayments from "./WirePayments/WirePayments";
import AccountActivityModal from "../config/accountActivityModal";
import classNames from "./MakePayments.module.scss";

function MakePaymentsOverview() {
  //Hooks
  // const queryClient = useQueryClient();
  const [paymentData, setPaymentData] = useState();
  const { application } = useContext(AppContext);
  const applicationData = application;
  //#region
  const [achPaymentsModalVisibility, setACHPaymentsModalVisibility] =
    useState(false);
  const [wirePaymentsModalVisibility, setWirePaymentsModalVisibility] =
    useState(false);
  const [accountActivityVisible, setAccountActivityVisible] =
    useState(false);
  // const [billPaymentsModalVisibility, setBillPaymentsModalVisibility] =
  //   useState(false);
  // const [freezeModalVisible, setFreezeModalVisible] = useState(false);
  // const [unfreezeModalVisible, setUnfreezeModalVisible] = useState(false);
  // const [editPaymentModalVisible, setEditPaymentModalVisible] = useState(false);
  // const [selectedPayments, setSelectedPayments] = useState([]);
  const navigate = useNavigate();

  //Api calls
  const getScheduledPayments = useMutation(
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: payeeData, isLoading: isPayeeLoading } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  const { data: wiresPayeeData } = useQuery(
    "getWiresPayees",
    () =>
      api.BusinessBanking.listWiresPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: accountPayload,
    isLoading,
    isFetching,
    refetch: accountRefetch,
  } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: applicationData?.otz_account_id,
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  //Process Api response
  useEffect(() => {
    handleSchedulePaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Event handlers
  // const handlePayWithCardModalCancelClick = () => {
  //   setPayWithCardModalVisibility(false);
  // };

  const handleACHPaymentModalCancelClick = () => {
    setACHPaymentsModalVisibility(false);
  };

  const handleSchedulePaymentList = () => {
    const reqBody = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: applicationData?.otz_business_id }
        : {
            otz_person_id: applicationData?.otz_person_id,
          }),
      is_recurring: 0,
    };

    getScheduledPayments.mutate(reqBody, {
      onSuccess: (paymentDataPayload) => {
        const filteredData = paymentDataPayload?.payload?.map((item) => {
          // if (!isPaymentOnPast(item?.next_payment_date?.scheduled_date)) {

          return {
            payeeName: item?.payee_name,
            paymentDate: item?.schedule?.start_date,
            amount: item?.payment_instruction?.request?.amount,
            paymentType: item?.payment_instruction?.type,
          };
        });
        setPaymentData(filteredData);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  const handleWirePaymentModalCancelClick = () => {
    setWirePaymentsModalVisibility(false);
  };
  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);
  const initWireTransfer = () => {
    if(isAccountInfoNeeded) {
      setAccountActivityVisible(true);
    } else {
      setWirePaymentsModalVisibility(true);
    }
  };

  // const handleBillPaymentModalCancelClick = () => {
  //   setBillPaymentsModalVisibility(false);
  // };

  // const handleOnRowClick = (record) => () => {
  //   setSelectedPayments(record);
  //   setEditPaymentModalVisible(true);
  // };
  //#endregion

  //JSX and other data
  const columns = [
    {
      title: "Payee",
      dataIndex: "payeeName",
      key: "payeeName",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "date",
      render: (type) => {
        return moment(type, FORMATS.API_SEND_FORMAT).format(
          FORMATS.DATEPICKER_FORMAT
        );
      },
    },
    {
      title: "Transfer Type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "20%",
      render: (type) => {
        return (
          <Space
            size="middle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TypeTag type={type} />
          </Space>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <span>$ {centsToDollarWithoutSign(amount)}</span>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "10%",
    //   render: (_, record) => {
    //     if (record?.freezed === "true") {
    //       return (
    //         <ActionButton
    //           type="Unfreeze"
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setUnfreezeModalVisible(true);
    //             setSelectedPayments({ item: "single", record, screen: "main" });
    //           }}
    //         />
    //       );
    //     } else {
    //       return (
    //         <ActionButton
    //           type="Freeze"
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setFreezeModalVisible(true);
    //             setSelectedPayments({ item: "single", record, screen: "main" });
    //           }}
    //         />
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Make Payments" />
      </div>
      <div className={classNames.layout}>
        <Card className={classNames.balanceCard}>
          <div className={classNames.totalBalance}>
            <Title as="h4">Net Available Balance</Title>
            <div className={classNames.amount}>
              <span>$</span>{" "}
              {isLoading ? (
                <Spin />
              ) : (
                // TEMP || Needs to be replaced with calculated value
                centsToDollarWithoutSign(
                  accountPayload?.payload?.total_available_balance
                ) || "0.00"
              )}
              <Button
                shape="circle"
                disabled={!isLoading && isFetching}
                icon={
                  !isLoading && isFetching ? (
                    <Spin size={10} />
                  ) : (
                    <RefreshLineIcon size={26} />
                  )
                }
                className={classNames.refreshBtn}
                style={{ width: 48, height: 48, marginLeft: 20 }}
                onClick={() => {
                  accountRefetch();
                }}
              />
            </div>
          </div>
          <div className={classNames.cardRight}>
            {/* <div className={classNames.currentBalance}>
              <div className={classNames.icon}>
                <ArrowLeftDownLineIcon size={26} />
              </div>
              <div className={classNames.amount}>
                <Title as="h3">Current Balance</Title>
                <div>
                  <span>$</span>
                  {centsToDollarWithoutSign(
                    accountPayload?.payload?.total_available_balance
                  ) || "0.00"}
                </div>
              </div>
            </div>
            <div className={classNames.upcomingPayments}>
              <div className={classNames.icon}>
                <ArrowRightUpLineIcon size={26} />
              </div>
              <div className={classNames.amount}>
                <Title as="h3">Upcoming Payments</Title>
                <div>
                  <span>$</span>
                  {centsToDollarWithoutSign(upcomingPayments) || 0}
                </div>
              </div>
            </div> */}
          </div>
        </Card>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.paymentsWrapper}>
            <div className={classNames.tableWrapper}>
              <Card className={classNames.tableCard}>
                <div className={classNames.tableCardHeader}>
                  <Title as="h3">Scheduled Payments</Title>
                  <AntButton
                    type="link"
                    onClick={() =>
                      navigate(ROUTES.APP.BUSINESS_BANKING.SCHEDULED_PAYMENTS)
                    }
                  >
                    View all
                  </AntButton>
                </div>
                <Table
                  loading={getScheduledPayments.isLoading}
                  pagination={false}
                  size="small"
                  dataSource={
                    !!paymentData &&
                    paymentData
                      ?.sort((a, b) =>
                        moment(a.paymentDate, FORMATS.API_SEND_FORMAT).diff(
                          moment(b.paymentDate, FORMATS.API_SEND_FORMAT)
                        )
                      )
                      ?.reverse()
                  }
                  columns={columns}
                  className={classNames.table}
                />
              </Card>
            </div>
            <div className={classNames.paymentCardWrapper}>
              <Card
                className={classNames.paymentCard}
                onClick={() =>
                  navigate(ROUTES.APP.BUSINESS_BANKING.RECURRING_PAYMENTS)
                }
              >
                <GlobalLineIcon
                  color="#9BA3AB"
                  size={30}
                  className={classNames.optionIcon}
                />
                <div className={classNames.optionIconBackdropCenter} />
                <div className={classNames.paymentCardMethod}>
                  <span>Recurring Payments</span>
                  <span>View & manage recurring payments</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtn} />
              </Card>
              <Card
                className={classNames.paymentCard}
                onClick={() => navigate(ROUTES.APP.BUSINESS_BANKING.PAYEES)}
              >
                <GlobalLineIcon
                  color="#9BA3AB"
                  size={30}
                  className={classNames.optionIcon}
                />
                <div className={classNames.optionIconBackdropCenter} />
                <div className={classNames.paymentCardMethod}>
                  <span>Payees</span>
                  <span>View, manage, edit & delete payees</span>
                </div>
                <ArrowRightLineIcon className={classNames.arrowBtn} />
              </Card>
            </div>
          </div>
          <div className={classNames.paymentOptionsWrapper}>
            <Card
              className={classNames.paymentOption}
              onClick={() => 
                {
                  navigate(ROUTES.APP.BUSINESS_BANKING.MANAGE_CARDS)
                  try {
                    mixpanel.track("Pay with card", {
                      "Pay Method": "Card"
                    });
                  } catch (error) {
                    console.error("Mixpanel error: ", error);
                  }
                }
              }
            >
              <BankCardLineIcon
                color="#fff"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdrop} />
              <div className={classNames.paymentMethod}>
                <span>Pay with Card</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            <Card
              className={classNames.paymentOption}
              onClick={() => setACHPaymentsModalVisibility(true)}
            >
              <BankLineIcon
                color="#fff"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdrop} />
              <div className={classNames.paymentMethod}>
                <span>Make Payments</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
            <Card className={classNames.paymentOption} style={application?.onBoardingStatus !== "ACCOUNT_ACTIVITY_DETAILS_FILLED" ? {background: "white"} : {}}>
              {/* Conditionally render the Tooltip */}
              {(process.env.REACT_APP_ENV?.trim() === "TTEN" || process.env.REACT_APP_ENV?.trim() === "PROD")? (
                <Tooltip
                  title="Complete profile in settings > user profile to get access to wire transfers"
                  placement="top"
                >
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                  <FileTransferLineIcon
                    color="#9BA3AB"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <div className={classNames.optionIconBackdrop} />
                  {/* <div className={classNames.optionIconBackdropLight} /> */}
                  <div className={classNames.comingSoonMethod}>
                    <span>Make a Wire Transfer</span>
                    <span>Coming soon</span>
                    {/* <ArrowRightLineIcon style={{display: "none"}} className={classNames.arrowBtnWithBg} /> */}
                  </div>
                  </div>
                </Tooltip>
              ) : (
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}} onClick={() => initWireTransfer()}>
                  <FileTransferLineIcon
                    color="#fff"
                    size={30}
                    className={classNames.optionIcon}
                  />
                  <div className={classNames.optionIconBackdrop} />
                  {/* <div className={classNames.optionIconBackdropLight} /> */}
                  <div className={classNames.paymentMethod}>
                    <span>Make a Wire Transfer</span>
                    <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
                  </div>
                </div>
              )}
            </Card>

            <Card
              className={classNames.comingSoonOption}
              onClick={() => {
                // setBillPaymentsModalVisibility(true);
              }}
            >
              <BillLineIcon
                color="#9BA3AB"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdropLight} />
              <div className={classNames.comingSoonMethod}>
                <span>Bill Payments</span>
                <span>Coming soon</span>
              </div>
            </Card>
            <Card className={classNames.comingSoonOption}>
              <GlobalLineIcon
                color="#9BA3AB"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdropLight} />
              <div className={classNames.comingSoonMethod}>
                <span>International Transfers</span>
                <span>Coming soon</span>
              </div>
            </Card>
            <Card className={classNames.comingSoonOption}>
              <SecurePaymentLineIcon
                color="#9BA3AB"
                size={30}
                className={classNames.optionIcon}
              />
              <div className={classNames.optionIconBackdropLight} />
              <div className={classNames.comingSoonMethod}>
                <span>Check Payments</span>
                <span>Coming soon</span>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* <PayWithCardPayments
        visible={payWithCardModalVisibility}
        onCancel={handlePayWithCardModalCancelClick}
      /> */}

      <ACHPayments
        visible={achPaymentsModalVisibility}
        setVisible={setACHPaymentsModalVisibility}
        onCancel={handleACHPaymentModalCancelClick}
        payeeData={!!payeeData?.payload && payeeData?.payload}
        isPaymentDataLoading={isPayeeLoading}
        accountBalance={accountPayload?.payload?.total_available_balance || 0}
        refetch={handleSchedulePaymentList}
      />

      <WirePayments
        visible={wirePaymentsModalVisibility}
        onCancel={handleWirePaymentModalCancelClick}
        accountBalance={accountPayload?.payload?.total_available_balance || 0}
        payeeData={!!wiresPayeeData?.payload && wiresPayeeData?.payload}
        refetch={handleSchedulePaymentList}
      />

      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setWirePaymentsModalVisibility}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        spawnModal={setAccountActivityVisible}
        title="Wire Transactions"
      />
      {/* <MakePaymentModal
        visible={billPaymentsModalVisibility}
        onCancel={handleBillPaymentModalCancelClick}
      /> */}
      {/* <FreezePayment
        visible={freezeModalVisible}
        payments={selectedPayments || undefined}
        onCancel={() => setFreezeModalVisible(false)}
        queryClientMain={queryClient}
        refetch={handleSchedulePaymentList}
        freeze={null}
      />
      <UnfreezePayment
        visible={unfreezeModalVisible}
        payments={selectedPayments || undefined}
        onCancel={() => setUnfreezeModalVisible(false)}
        queryClientMain={queryClient}
        refetch={handleSchedulePaymentList}
        accountBalance={accountPayload?.payload?.total_available_balance}
        unfreeze={null}
      /> */}
      {/* <EditPayment
        payments={selectedPayments}
        visible={editPaymentModalVisible}
        onCancel={() => setEditPaymentModalVisible(false)}
        accountBalance={accountPayload?.payload?.total_available_balance}
        refetch={handleSchedulePaymentList}
      /> */}
    </div>
  );
}

export default MakePaymentsOverview;
