import { useEffect, useState, useContext } from "react";
import {
  Card,
  Table,
  Empty,
  Button as AntButton, 
  Select,
  Radio,
} from "antd";
import {
  // RiSearch2Line,
  RiArrowRightLine,
} from "react-icons/ri";
import { 
  useLocation, 
  useNavigate 
} from "react-router-dom";
import {
  QueryErrorResetBoundary,
  useMutation,
  useQuery
} from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { isBusinessType } from "../../../util/functions/onboarding";
import generateErrorMessage from "../../../util/functions/customError";
import moment from "moment";

import { 
  Input, 
  Header, 
  // Select, 
  Button,
  DatePicker,
} from "../../../common";
import { 
  // DEFAULT_AMOUNT, 
  // FORMATS, 
  ROUTES 
} from "../../../constants";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
// import { intToDecimalDollar } from "../../../util/functions/dollarConvertion";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
// import messages from "../../config/messages.const";
// import useLanguage from "../../../../hooks/useLanguage";
import classNames from "./ApprovedInvoices.module.scss";

export default function AllEstimates() {
  const navigate = useNavigate();
  // const location = useLocation();

  const [pagination, setPagination] = useState(PAGINATION);
  const [estimateData, setEstimateData] = useState([]);
  const [filteredEstimateData, setFilteredEstimateData] =
    useState(estimateData);
  const [keyword, setKeyword] = useState(null);
  const [sort, setSortChange] = useState();
  const [filterType, setFilterType] = useState(null);
  const [dateFilterValue, setDateFilterValue] = useState(null);

  const location = useLocation();
  // const [selectedEstimateId, setEstimateId] = useState(null);

  const { application } = useContext(AppContext);
  const applicationData = application;

  const { data: payeeData, isLoading: isPayeeLoading } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "receipt_date",
      key: "receipt_date",
      render: (date) => <span>{moment(date).format()}</span>,
      width: "20%",
      sorter: (a, b) => moment(a.receipt_date) - moment(b.receipt_date),
    },
    {
      title: "Vendor Name",
      dataIndex: "otz_payee_id",
      key: "otz_payee_id",
      width: "20%",
      render: (otz_payee_id, data) => {
        if(payeeData?.payload &&
          payeeData?.payload.length > 0) {
            // For cases when somehow the otz_payee_id is not present in the invoiceData, instead we have vendor_name assigned the value of the otz_payee_id
            if (payeeData?.payload?.find((entry) => entry?.otz_payee_id === data?.vendor_name)?.account_owner_name !== undefined) {
              return (<span>{payeeData?.payload?.find((entry) => entry?.otz_payee_id === data?.vendor_name)?.account_owner_name}</span>)
            } 

            // For cases when the otz_payee_id is not present in the invoiceData (a pure invoice coming from quickbooks)
            if (otz_payee_id === undefined) {
              return (<span>{data?.vendor_name}</span>)
            }

            // General case of invoice upload from ottez, we have a vendor and invoice attached to it
            if (payeeData?.payload?.find((entry) => entry?.otz_payee_id === otz_payee_id)?.account_owner_name !== "") {
              return (<span>{payeeData?.payload?.find((entry) => entry?.otz_payee_id === otz_payee_id)?.account_owner_name}</span>)
            } 
        }
      },
      ellipsis: true,
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      render: (total) => <span>$ {total}</span>,
      width: "20%",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Invoice Number",
      dataIndex: "receipt_id",
      key: "receipt_id",
      width: "20%",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (dueDate) => <span>{moment(dueDate, "DD/MM/YYYY").format()}</span>,
      width: "20%",
      sorter: (a, b) => moment(a.due_date, "DD/MM/YYYY") - moment(b.due_date, "DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: "20%",
      render: (status) => {
        let color;
        switch (status) {
          case "paid":
            color = "#1DBC83";
            break;
          case "unpaid":
            color = "#EE6969";
            break;
          case "scheduled":
            color = "#BC7C1D";
            break;
          default:
            color = "black";
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
  ];

  const fetchApprovedInvoicesMutation = useMutation(() => api.AccountsPayable.getAPInvoiceByStatus("approved"));
  useEffect(() => {
    const statusState = location.state?.status;

    if (statusState && (statusState === 'scheduled' || statusState === 'unpaid')) {
        setFilterType('payment_status');
        setKeyword(statusState);
    }
    // Define the sorting parameters
    const sortParams = sort
      ? `${sort.field}_${sort.order === 'ascend' ? 'asc' : 'desc'}`
      : '';

    fetchApprovedInvoicesMutation.mutate({
      sort: sortParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);
  
  const isLoadingInvoices = fetchApprovedInvoicesMutation.isLoading;
  const totalNoOfRecords = fetchApprovedInvoicesMutation?.data?.payload?.length || 0;
  
  useEffect(() => {
    if (fetchApprovedInvoicesMutation.data) {
      const dataWithKeys = fetchApprovedInvoicesMutation.data.payload.map(estimate => ({
        ...estimate,
        key: estimate.estimate_id
      }));
      setEstimateData(dataWithKeys);
      setFilteredEstimateData(dataWithKeys);
    }
  }, [fetchApprovedInvoicesMutation.data]);
  
  const getDataSource = () => {
    let data = [...filteredEstimateData];
  
    if (filterType && keyword) {
      switch (filterType) {
        case 'due_date':
          data = data.filter(item => moment(item.due_date).isSameOrBefore(keyword));
          break;
        case 'receipt_date':
          data = data.filter(item => moment(item.receipt_date).isBefore(moment(keyword).format(), 'day') || moment(item.receipt_date).isSame(moment(keyword, "DD/MM/YYYY"), 'day'));
          break;
        case 'payment_status':
          if (keyword !== "all") {
            data = data.filter(item => item.payment_status.toLowerCase() === keyword.toLowerCase());
          }
          break;
        case 'otz_payee_id':
          data = data.filter(item => {
            const vendor = payeeData?.payload?.find(entry => entry?.account_owner_name.toLowerCase().includes(keyword.toLowerCase()));
            return vendor && item.otz_payee_id === vendor.otz_payee_id;
          });
          break;
        default:
          break;
      }
    }

    if (sort) {
      // Server-side sorting for "due date" and "total amount"
      data = data.sort((a, b) => {
        switch (sort.field) {
          case 'due_date':
            return sort.order === 'ascend'
              ? moment(a.due_date) - moment(b.due_date)
              : moment(b.due_date) - moment(a.due_date);
          case 'total':
            return sort.order === 'ascend' ? a.total - b.total : b.total - a.total;
          default:
            return 0;
        }
      });
    }
  
    // Pagination logic
    const startIndex = (pagination.page - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    data = data.slice(startIndex, endIndex);

    return data;
  };
  
  // const displayedRecords = Math.min(pagination.pageSize, getDataSource().length);
  // const paginationText = `Displaying ${displayedRecords} of ${filteredEstimateData.length} invoices`;

  // comment for future use: AfterMvp
  // const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  // },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const rowClick = (invoiceData) => {
    navigate(ROUTES.APP.PAYABLES.UPDATE_INVOICE.replace(":id", invoiceData.number), { state: { invoiceData } });
  };

  // const status = [
  //   { label: "All", value: "" },
  //   { label: "Draft", value: "Draft" },
  //   { label: "Sent", value: "Sent" },
  //   { label: "Overdue", value: "Overdue" },
  // ];
  // const renderStatusOptions = (data) =>
  //   data?.map((item, index) => (
  //     <Select.Option key={`status${index}`} value={item.value}>
  //       {item.label}
  //     </Select.Option>
  //   ));

  return (
    <div className={classNames.wrapper}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
              </div>
            )}
          >
            <div className={classNames.headerWrapper}>
              <Header
                title="Approved Invoices"
                back
                onClick={() => navigate("../account_payable")}
              />
            </div>
            <div className={classNames.layout}>
              <div className={classNames.bodyWrapper}>
                <div className={classNames.topRow}>
                  <div className={classNames.invoiceFilters}>
                    <Select
                      placeholder="Filter by"
                      className={classNames.filterSelect}
                      onChange={(value) => {
                        setFilterType(value);
                        setKeyword(null); // Reset the keyword
                        setDateFilterValue(null)
                      }}
                      style={{ 
                        marginLeft: 2,
                        width: 150,
                        borderColor: "#d9d9d947",
                      }}
                    >
                      <Select.Option value="due_date">Due Date</Select.Option>
                      <Select.Option value="receipt_date">Invoice Date</Select.Option>
                      <Select.Option value="payment_status">Status</Select.Option>
                      <Select.Option value="otz_payee_id">Vendor Name</Select.Option>
                    </Select>
                    
                    {filterType === "due_date" || filterType === "receipt_date" ? (
                      <DatePicker
                        value={dateFilterValue}
                        onChange={(date, dateString) => {
                          setKeyword(dateString)
                          setDateFilterValue(date)
                        }}
                        style={{ marginLeft: 2, borderRadius: 0, borderColor: "#d9d9d947" }}
                      />
                    ) : filterType === "payment_status" ? (
                      <div className={classNames.tableCardFilters}>
                        <Radio.Group
                          onChange={(e) => setKeyword(e.target.value)}
                          buttonStyle="solid"
                          style={{ marginLeft: 2, background: '#fff', padding: '14.7px', display: 'flex' }}
                        >
                          <Radio.Button value="all">ALL</Radio.Button>
                          <Radio.Button value="paid">PAID</Radio.Button>
                          <Radio.Button value="unpaid">UNPAID</Radio.Button>
                          <Radio.Button value="scheduled">SCHEDULED</Radio.Button>
                        </Radio.Group>
                      </div>
                    ) : filterType === "otz_payee_id" ? (
                    <Input
                      value={keyword}
                      placeholder="Please Enter"
                      className={classNames.search}
                      onChange={(event) => setKeyword(event.target.value)}
                      style={{ marginLeft: 2 }}
                    />
                  ) : null}
                  </div>

                  <Card
                    className={classNames.optionCard}
                    onClick={() => navigate(ROUTES.APP.PAYABLES.UPLOAD_INVOICE)}
                  >
                    <div className={classNames.cardAction}>
                      <span>Upload an Invoice</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                </div>
                <div className={classNames.body}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "26px 0",
                    }}
                  >
                    {/* {paginationText} */}
                  </div>
                  <Table
                    rowKey="invoice_id"
                    loading={isLoadingInvoices || isPayeeLoading}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          rowClick(record);
                        },
                      };
                    }}
                    // comment due to future use afterMVP
                    // rowSelection={{
                    //   type: "checkbox",
                    //   ...rowSelection,
                    // }}
                    // scroll={{ y: `var(--table-height)` }}
                    size="large"
                    dataSource={getDataSource()}
                    columns={columns}
                    className={classNames.table}
                    rowClassName={classNames.actionIcon}
                    pagination={{
                      ...PAGINATION_DEFAULT,
                      total: filteredEstimateData.length,
                      current: pagination.page,
                      pageSize: pagination.pageSize,
                      onChange: (page, pageSize) => {
                        setPagination({ page: page, pageSize: pageSize });
                      },
                      itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                          return (
                            <AntButton style={{ color: "#9f9cff" }}>
                              Prev
                            </AntButton>
                          );
                        }
                        if (type === "next") {
                          return (
                            <AntButton
                              style={{
                                color: "#9f9cff",
                                marginLeft: "32px",
                              }}
                            >
                              Next
                            </AntButton>
                          );
                        }
                      },
                    }}
                    onChange={(pagination, filters, sorter) => {
                      if (sorter.columnKey) {
                        setSortChange(sorter);
                      } else {
                        setSortChange(null);
                      }
                    }}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            keyword && totalNoOfRecords === 0
                              ? "No Result found"
                              : "No Data Found"
                          }
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
}
