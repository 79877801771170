const ENDPOINT_KEYS = Object.freeze({
  achPayments: "achPayments",
  bankLetter: "bankLetter",
  businessRelationship: "businessRelationship",
  externalAccount: "externalAccount",
  schedulePayments: "schedulePayments",
  disclosure: "disclosure",
  kyckyb: "kyckyb",
  status: "updateStatus",
  otzcards: "otzcards",
  webhook: "webhook",
  transactions: "transactions",
  sendMail: "sendMail",
  notification: "notification",
  rewards: "rewards",
  subscription: "subscription",
  paLink: "paLink",
  payees: "payees",
  cardknoxSync: "cardknoxSync",

  businessBanking: "businessBanking",
  tenant: "tenant",
  taxRate: "taxRate",
  product: "product",
  invoices: "invoices",
  statements: "statements",
  getOverallInvoiceDetails: "getOverallInvoiceDetails",
  cards: "cards",
  getApplicationByTenantId: "getApplicationByTenantId",
  createApplication: "createApplication",
  payments: "payments",
  account: "account",
  checkDeposits: "checkDeposits",
  notifications: "notifications",
  authentication: "authentication",
  subscriptionPlan: "subscriptionPlan",
  subscriptionUser: "subscriptionUser",
  cardknox: "cardknox",
  paymentAcceptanceOnboard: "paymentAcceptanceOnboard",
  paymentAcceptanceTransaction: "paymentAcceptanceTransaction",
  customPayment: "customPayment",
  "general-info": "general-info",
  "temp-notifications": "temp-notifications",
  "pa-transaction": "pa-transaction",
  "pa-transaction-public": "pa-transaction-public",
  "pa-settings": "pa-settings",
  "pa-settings-public": "pa-settings-public",
  "cardknox-ifields": "cardknox-ifields",
  "subscription-public": "subscription-public",
  updateBusinessOperationDetails: 'updateBusinessOperationDetails'
});

export default ENDPOINT_KEYS;
