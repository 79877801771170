import React from "react";

const PrivacyNotice = () => {
  return (
    <>
      <div>
        <h4>Overview</h4>
        <p>
          Otterz, Inc. (“Otterz” or the “Company”) has developed this Policy to
          comply with Privacy and Controlling the Assault of Non-Solicited
          Pornography And Marketing Act (“CAN-SPAM”) requirements.
        </p>
        <p>
          To ensure that customers are protected against unwanted sharing of
          their financial information, the Gramm-Leach-Bliley Act (“GLBA”)
          includes a series of regulations known as the Privacy Rules. The
          Privacy Rules are intended to ensure the confidentiality and security
          of consumer and customer information.
        </p>
        <p>
          When an institution chooses to share customer information, a customer
          can opt-out, or forbid the sharing of their information. Because we do
          not share any of our customers' personal information with outside
          parties (except for the purposes of day-to-day business), it is not
          necessary for the customer to opt-out. However, we do share
          information with affiliates, and as required under the Fair Credit
          Reporting Act, customers are given the opportunity to opt-out.
        </p>
        <div>
          The GLBA Privacy Rules address the following four concepts:
          <ul>
            <li>
              <span>
                Our obligation to inform consumers and customers of our policies
                and procedures regarding the sharing of their personal
                information.
              </span>
            </li>
            <li>
              <span>
                The concept of personal customer information, and the limited
                exceptions under which we may share this information with
                outside parties.
              </span>
            </li>
            <li>
              <span>
                Our responsibilities to protect personal customer information.
              </span>
            </li>
            <li>
              <span>
                Our responsibility to establish appropriate standards relating
                to safeguarding customer information.
              </span>
            </li>
          </ul>
        </div>
        <div>
          The three principal requirements of the GLBA Privacy Rules are as
          follows:
          <ul>
            <li>
              <span>
                Provide customers with notices describing our privacy policies
                and practices, including policies with respect to the disclosure
                of nonpublic personal information to affiliates and to
                nonaffiliated third parties.
              </span>
            </li>
            <li>
              <span>
                Subject to specific exceptions, we may not disclose nonpublic
                personal information about consumers to any nonaffiliated third
                party unless consumers are given a reasonable opportunity to
                direct that such information not be shared (i.e., Opt-Out).
              </span>
            </li>
            <li>
              <span>
                We generally may not disclose account numbers to any
                nonaffiliated third party for marketing purposes.
              </span>
            </li>
          </ul>
        </div>
        <p>
          We generally may not disclose account numbers to any nonaffiliated
          third party for marketing purposes.
        </p>
        <p>
          The Right to Financial Privacy Act (“RFPA”) establishes specific
          procedures that federal government authorities must follow in order to
          obtain information from us about a customer’s financial records.
          Generally, these requirements include obtaining subpoenas, notifying
          the customer of the request, and providing the customer with an
          opportunity to object. The Act imposes related limitations and duties
          on financial institutions prior to the release of information
          requested by federal authorities.
        </p>
        <p>
          The Children’s Online Privacy Protection Act (“COPPA”) was enacted to
          prohibit unfair and deceptive acts or practices in connection with the
          collection, use, or disclosure of personal information from children
          under the age of 13 in an online environment. Generally, the Act
          requires operators of Websites or online services directed to
          children, or that have actual knowledge that they are collecting or
          maintaining personal information from children online, to provide
          certain notices and obtain parental consent to collect, use, or
          disclose information about children. The FDIC is granted enforcement
          authority under the Act. Federal Trade Commission regulations (16 CFR
          312) that implement COPPA became effective April 21, 2000.
        </p>
        <p>
          The California Consumer Privacy Act of 2018 (“CCPA”) gives consumers
          more control over the personal information that companies collect
          about them.
        </p>
        <p>
          The Controlling the Assault of Non-Solicited Pornography And Marketing
          Act (“CAN-SPAM”) requires Otterz to follow specific procedures when
          initiating electronic mail (“email”) messages to any recipient with
          the primary purpose of communicating a commercial message. CAN-SPAM
          has several requirements related to email messages, including that
          certain information in email messages is not false or misleading and
          recipients of commercial messages are able to opt‐out of future
          correspondence.
        </p>
      </div>
      <div>
        <h4>Policy Statement - CAN SPAM</h4>
        <p>
          Otterz is committed to complying with all applicable provisions of
          CAN-SPAM. Otterz sends email messages to prospective customers as well
          as to customers where it already has an established business
          relationship. CAN-SPAM communicates requirements for email messages
          based on the primary purpose of such email messages.
        </p>
      </div>
      <div>
        <h4>Policy Statement - Privacy</h4>
        <div>
          Otterz seeks to proactively comply with all requirements that stem
          from regulations that govern our activities. The Privacy Rules within
          the GLBA apply to all activities that involve nonaffiliated third
          parties and the disclosure of nonpublic personal information for
          consumers. Otterz engages with nonaffiliated third parties for the
          carrying out of financial transactions and for marketing of financial
          products which includes:
          <ul>
            <li>
              <span>
                Vendors that verify consumers' identification information
              </span>
            </li>
            <li>
              <span>Processors that conduct transaction information</span>
            </li>
            <li>
              <span>Financial institutions that process financial data</span>
            </li>
          </ul>
        </div>
        <p>
          The use of nonpublic personal information for any of these reasons
          does not require that Otterz obtain explicit prior consent from the
          consumer. As of the enacting of this policy, Otterz does not engage
          with any nonaffiliated third party for the purpose of marketing
          non-financial products to consumers and there are no known plans to do
          so in the future.
        </p>
        <p>
          It is the policy of Otterz not to disclose nonpublic personal
          information about our customers to nonaffiliated third parties except
          as authorized by law (outlined above). However, Otterz will permit
          additional information sharing in a manner consistent with legal
          requirements. To the extent that Otterz seeks to disclose nonpublic
          information to nonaffiliated third parties in additional circumstances
          (such as for marketing), Otterz will ensure that the customer is
          provided with the right to opt-out or limit the sharing by notifying
          Otterz of such intent through the use of a mail-in form or other
          permissible means.
        </p>
        <p>
          Additionally, Otterz aims to comply with the RFPA, which establishes
          specific procedures that federal government authorities must follow in
          order to obtain information from a financial institution about a
          customer’s financial records.
        </p>
        <p>
          Otterz will comply with CCPA requirements for its California
          consumers.
        </p>
        <p>
          Should Otterz operate a website or online service directed to children
          that collects or maintains personal information about them, or
          knowingly collects or maintains personal information from a child
          online, the Company will comply with COPPA requirements.
        </p>
        <p>
          The objective of this Privacy Policy is to protect customer
          information in accordance with the Privacy Rules. Otterz respects the
          privacy of our customers and is committed to treating customer
          information responsibly. We are dedicated to protecting confidential
          information and have established standards and procedures to safeguard
          that personal information.
        </p>
      </div>
      <div>
        <h4>Responsibilities</h4>
        <div>
          <h5>Chief Compliance Officer</h5>
          <p>
            The CCO, or designee (individually and collectively, referred to
            herein as Compliance) will report directly to the executive team and
            is responsible for owning, maintaining and enforcing this Policy.
            Compliance institutes proper controls that ensure the requirements
            of this Policy are followed, and identifies and ensures Company
            managers and employees who are affected by this policy are made
            aware of its requirements. Compliance also ensures all appropriate
            personnel have access to resources necessary to comply with this
            Policy.
          </p>
        </div>
      </div>
      <div>
        <h4>Key Definitions </h4>
        <ol className="upper-alpha">
          <li>
            <div>
              <u>Child.</u>
              <span> An individual under the age of 13.</span>
            </div>
          </li>
          <li>
            <div>
              <u>Consumer.</u>
              <span>
                {" "}
                An individual who obtains from us a financial product or service
                that is to be used primarily for personal, family, or household
                purposes. For example, a consumer is an individual who applies
                for credit (regardless of whether the credit is extended).
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Customer (GLBA).</u>
              <span>
                {" "}
                A consumer who has a continuing relationship with us under which
                we provide one or more financial products or services.
              </span>
              <p>
                NOTE: A consumer has a more temporary relationship with us than
                a customer. All customers are consumers, but all consumers are
                not customers.
              </p>
            </div>
          </li>
          <li>
            <div>
              <u>Personally identifiable financial information.</u>
              <span>
                {" "}
                Any information – financial or otherwise – that we have about
                our customers, which can be tied to a specific customer.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Nonpublic personal information.</u>
              <span>
                {" "}
                The nonpublic portion of personally identifiable financial
                information, including any customer lists. Nonpublic personal
                information consists of nonpublic information that is collected
                in connection with providing a financial product or service.
                Specifically, it means:
              </span>
            </div>
            <ul>
              <li>
                <span>
                  Personally identifiable financial information, which includes:
                </span>
                <ul>
                  <li>
                    <span>
                      Information a customer provides on an application;
                    </span>
                  </li>
                  <li>
                    <span>
                      Account balance information, payment history, overdraft
                      history and credit or debit card purchase information;
                    </span>
                  </li>
                  <li>
                    <span>
                      Any information collected through an Internet “cookie”;
                    </span>
                  </li>
                  <li>
                    <span>Information from a consumer report;</span>
                  </li>
                  <li>
                    <span>
                      The fact that an individual is or has been one of our
                      customers or has obtained a financial product or service
                      from us; and
                    </span>
                  </li>
                  <li>
                    <span>
                      Any information about our customer if it is disclosed in a
                      manner that indicates that the individual is or has been
                      our customer.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  Any list, description, or other grouping of consumers that is
                  derived using any personally identifiable financial
                  information that is not publicly available. Lists include, but
                  are not limited to, any list of individuals’ names and
                  addresses that is derived in whole or in part using personally
                  identifiable information that is not publicly available, such
                  as account numbers.
                </span>
                <p>
                  NOTE: Nonpublic personal information does not include
                  information that is available from public sources, such as
                  telephone directories or government records. It also does not
                  include aggregate information or blind data that does not
                  contain personal identifiers.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <div>
              <u>Affiliate.</u>
              <span>
                {" "}
                Any company that controls, is controlled by, or is under common
                control with another company.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Nonaffiliated Third Party.</u>
              <span>
                Persons or entities except affiliates and persons jointly
                employed by a financial institution and a nonaffiliated third
                party. GLBA Privacy Rules restrict information sharing with
                nonaffiliated third parties.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Affirmative Consent.</u>
              <span>
                {" "}
                As it relates to commercial electronic mail (“email”) messages,
                the recipient expressly consented to receive the message, either
                in response to a clear and conspicuous request for such consent
                or at the recipient’s own initiative. If the message is from a
                party other than the party to which the recipient communicated
                such consent, the recipient was given clear and conspicuous
                notice at the time the consent was communicated that the
                recipient’s email address could be transferred to such other
                party for the purpose of initiating commercial email messages.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Commercial email message.</u>
              <span>
                {" "}
                Any email message the primary purpose of which is to advertise
                or promote for a commercial purpose, a commercial product or
                service including content on the Internet. An email message
                would not be considered to be a commercial email message solely
                because such a message includes a reference to a commercial
                entity that serves to identify the sender or a reference or link
                to an Internet website operated for a commercial purpose.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Harvesting.</u>
              <span>
                {" "}
                Obtaining email addresses using an automated means from an
                Internet website or proprietary online service operated by
                another person, where such service/person, at the time the
                address was obtained, had provided a notice stating that the
                operator of such Web site or online service would not give,
                sell, or otherwise transfer electronic addresses.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Hijacking.</u>
              <span>
                {" "}
                The use of automated means to register for multiple email
                accounts or online user accounts from which to transmit, or
                enable another person to transmit, a commercial email message
                that is unlawful.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Primary Purpose.</u>
              <span>
                {" "}
                The primary purpose of an email messages will be deemed to be
                commercial if it:
              </span>
            </div>
            <ol>
              <li>
                <span>
                  Contains only the commercial advertisement or promotion of a
                  commercial product or service;
                </span>
              </li>
              <li>
                <span>
                  Contains both commercial content and “transactional or
                  relationship” content if either:
                </span>
                <ul>
                  <li>
                    <span>
                      A recipient reasonably interpreting the subject line of
                      the email message would likely conclude that the message
                      contains commercial content; or
                    </span>
                  </li>
                  <li>
                    <span>
                      The email message’s “transactional or relationship”
                      content does not appear in whole or substantial part at
                      the beginning of the body of the message.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  Contains both commercial content as well as content that is
                  not transactional or relationship content if a recipient
                  reasonably interpreting either:
                </span>
                <ul>
                  <li>
                    <span>
                      The subject line of the email message would likely
                      conclude that the message contains commercial content; or
                    </span>
                  </li>
                  <li>
                    <span>
                      The body of the message would likely conclude that the
                      primary purpose of the message is commercial.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <div>
              <u>Recipient.</u>
              <span>
                {" "}
                An authorized user of the email address to which the message was
                sent or delivered.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Sender.</u>
              <span>
                {" "}
                A person who initiates an email message and whose product,
                service, or Internet website is advertised or promoted by the
                message.
              </span>
            </div>
          </li>
          <li>
            <div>
              <u>Transactional or Relationship email message.</u>
              <span>
                {" "}
                An email message with the primary purpose of facilitating,
                completing or confirming a commercial transaction that the
                recipient had previously agreed to enter into; to provide
                warranty, product recall, or safety/security information; or
                subscription, membership, account, loan, or other information
                relating to an ongoing purchase or use.
              </span>
            </div>
          </li>
        </ol>
      </div>
      <div>
        <h4>GLBA Policy Requirements </h4>
        <p>
          Information about customers is accumulated at the point of purchase or
          service, when customer service inquiries are made, or when Otterz
          responds to customer requests for information.
        </p>
        <div>
          <h5>Information Otterz is Allowed to Share</h5>
          <p>
            The law allows Otterz to share information with our affiliates, to
            the extent that there is a need for our affiliate to have that
            information, and subject to the opt-out provisions for affiliate
            marketing and consumer credit report information established by the
            Fair Credit Reporting Act.
          </p>
          <div>
            <span>
              We may share information with other parties, without meeting the
              “opt-out” condition (defined below), under any of the following
              conditions:
            </span>
            <ul>
              <li>
                <span>
                  To market Otterz’s own financial products or services;
                </span>
              </li>
              <li>
                <span>
                  Where third parties are performing services or functions on
                  behalf of Otterz, including marketing Otterz’s own products or
                  services or products and services offered pursuant to a joint
                  agreement between Otterz and another financial institution,
                  provided that Otterz:
                </span>
                <ul>
                  <li>
                    <span>discloses that arrangement, and</span>
                  </li>
                  <li>
                    <span>
                      enters into an agreement with the third party to maintain
                      the confidentiality of the information.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span>The customer consents;</span>
              </li>
              <li>
                <span>
                  It is necessary to effect, administer, or enforce a
                  transaction requested or authorized by the customer;
                </span>
              </li>
              <li>
                <span>
                  To process and service transactions the customer requests or
                  authorizes;
                </span>
              </li>
              <li>
                <span>
                  To protect against potential fraud or unauthorized
                  transactions;
                </span>
              </li>
              <li>
                <span>
                  To protect the confidentiality or security of Otterz’s
                  records;
                </span>
              </li>
              <li>
                <span>
                  For risk control purposes or for resolving customer disputes;
                </span>
              </li>
              <li>
                <span>
                  To persons holding a legal or beneficial interest relating to
                  the customer;
                </span>
              </li>
              <li>
                <span>
                  To persons acting in a fiduciary capacity on behalf of the
                  customer;
                </span>
              </li>
              <li>
                <span>
                  To law enforcement agencies if permitted or required under
                  other provisions of law;
                </span>
              </li>
              <li>
                <span>
                  To respond to judicial process, attorneys, accountants and
                  auditors;
                </span>
              </li>
              <li>
                <span>
                  To respond to governmental authorities for examination,
                  compliance, or other lawful purposes;
                </span>
              </li>
              <li>
                <span>
                  To a consumer reporting agency in accordance with the Fair
                  Credit Reporting Act; or
                </span>
              </li>
              <li>
                <span>To comply with federal state or local laws.</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>Opt Out Provision</h5>
          <p>
            Although Otterz does not currently engage in this practice, if the
            company does eventually share nonpublic personal information with
            nonaffiliated third parties in any other capacity than as defined
            above, Otterz will offer customers the opportunity to “opt out” of
            the information sharing process. Prior to any such sharing, Otterz
            will ensure that the customer is provided with the right to opt-out
            or limit the sharing by notifying Otterz of the intent to “opt out”
            through the use of a mail-in form or other permissible means. Otterz
            will share information with its affiliates, and will provide
            opt-outs as required by FCRA.
          </p>
        </div>
        <div>
          <h5>GLBA Notice to New Customers</h5>
          <p>
            Otterz is required to provide a copy of the GLBA Notice when it
            enters into a customer relationship with a consumer. A customer
            relationship means a continuing relationship between a consumer and
            Otterz, and is established when we provide one or more financial
            products or services to the consumer that are to be used primarily
            for personal, family, or household purposes. Otterz will make the
            GLBA Notice available on the website and require the
            consumer/customer to acknowledge receipt of the notice as a
            necessary step to opening an account with Otterz.
          </p>
          <p>
            Otterz will provide a clear and conspicuous notice that accurately
            reflects the privacy policies and practices as they relate to: a)
            the Company’s customers and b) consumers who may inquire or apply
            for our services, but do not become customers. This Privacy Notice
            will be given to the individual when that individual enters into a
            continuing relationship with the Company. If our sharing of
            information changes, a new Privacy Notice will be delivered to
            covered customers. The Privacy notice will inform the customer of
            the following information:
          </p>
          <ul>
            <li>
              <span>
                Categories of nonpublic personal information we collect;
              </span>
            </li>
            <li>
              <span>
                Categories of nonpublic personal information we disclose;
              </span>
            </li>
            <li>
              <span>
                Categories of affiliates and nonaffiliated third parties to whom
                we disclose nonpublic personal information;
              </span>
            </li>
            <li>
              <span>
                Categories of nonpublic personal information about our former
                customers that the bank discloses and the categories of
                affiliates and nonaffiliated third parties to whom we disclose
                nonpublic personal information about our former customers;
              </span>
            </li>
            <li>
              <span>
                An explanation of the consumer’s right to opt-out of the
                disclosure of nonpublic personal information to nonaffiliated
                third parties and the ability to opt-out of disclosures of
                information among affiliates;
              </span>
            </li>
            <li>
              <span>
                Our policies and practices with respect to protecting the
                confidentiality and security of nonpublic personal information;
              </span>
            </li>
            <li>
              <span>Any exceptions to the opt-out requirements</span>
            </li>
          </ul>
          <p>
            Otterz does not disclose nonpublic personal information about
            customers to anyone, except as permitted by law. When customers
            close accounts or become inactive customers, we adhere to the
            privacy policies and practices as described in our privacy
            disclosures. It is our policy not to reveal specific information
            about customer accounts or other personally identifiable data to
            unaffiliated third parties for their independent use, except as
            permitted by law.
          </p>
        </div>
        <div>
          <h5>Annual GLBA Notice</h5>
          <p>
            Not less than annually thereafter, Otterz provides a GLBA Notice to
            applicable customers as long as there is a continuation of the
            customer relationship. Annually means at least once in any period of
            12 consecutive months during which that relationship exists. Otterz
            will post the GLBA Notice on the website and will send an e-mail to
            all applicable customers notifying them of the location of the
            Notice.
          </p>
        </div>
        <div>
          <h5>Changes to the GLBA Notice</h5>
          <p>
            Otterz will not disclose any nonpublic personal information about a
            customer other than as described in the GLBA Notice, unless Otterz
            provides a clear and conspicuous revised notice that accurately
            describes the new policies and practices – along with any
            appropriate opt-out. In such circumstances, Otterz will not engage
            in such sharing until after it provides at least 30 days for the
            customer to opt-out.
          </p>
        </div>
        <div>
          <h5>Other Information Use and Sharing Restrictions</h5>
          <p>
            Account numbers or similar forms of access numbers or access codes
            for a customer's account cannot be disclosed to any non‑affiliated
            third party for the purpose of marketing non‑bank products.
          </p>
          <p>
            Customer information or other types of information obtained from
            companies for which Otterz is a service provider may not be used
            beyond the purpose of the contract.
          </p>
          <p>
            Business Lines are responsible for ensuring information passed to
            non‑affiliated third parties (e.g.,service providers, marketing
            companies, etc.) is limited to information needed to fulfill the
            service provided by the third party.
          </p>
          <p>
            Non-public personal information obtained concerning non-customers,
            consumers, and customers may not be disclosed to third parties to
            make their own product.
          </p>
        </div>
        <div>
          <h5>Confidentiality and Security</h5>
          <p>
            The Company is committed to the security of customer financial and
            personal information. All of our operational and data processing
            systems are in a secure environment that protects account
            information from being accessed by third parties. We maintain and
            grant access to customer information only in accordance with our
            internal security standards.
          </p>
          <p>
            Our employee access to personally identifiable customer information
            is limited to those with a business reason to know such information.
            Employees are educated on the importance of maintaining the
            confidentiality of customer information and on these privacy
            principles. Because of the importance of these issues, our employees
            are responsible for maintaining the confidentiality of customer
            information and employees who violate these privacy policies will be
            subject to disciplinary measures, including, but not limited to
            termination.
          </p>
        </div>
        <div>
          <h5>Maintenance of Accurate Information</h5>
          <p>
            The Company continually strives to maintain complete and accurate
            information about customer accounts.
          </p>
        </div>
        <div>
          <h5>Maintaining Customer Privacy in Third Party Relationships</h5>
          <p>
            When the Company conducts business with third parties, we require
            vendors and suppliers to maintain similar standards of conduct
            regarding the privacy of personally identifiable customer
            information provided to them.
          </p>
        </div>
      </div>
      <div>
        <h4>RFPA Policy Requirements</h4>
        <div>
          <h5>Access to Financial Records by Federal Government Authorities</h5>
          <div>
            Before Otterz staff provides a customer’s financial records to a
            federal government authority, one of the following must have been
            received:
            <ul>
              <li>
                <span>
                  Voluntarily signed and dated authorization by a customer which
                  –
                </span>
                <ul>
                  <li>
                    <span>
                      Authorizes such disclosure for a period not to exceed
                      three (3) months;
                    </span>
                  </li>
                  <li>
                    <span>
                      States that the customer may revoke such authorization at
                      any time before the financial records are disclosed;
                    </span>
                  </li>
                  <li>
                    <span>
                      Identifies the financial records authorized to be
                      disclosed;
                    </span>
                  </li>
                  <li>
                    <span>
                      Specifies the purposes for which, and the government
                      authority to which, such records may be disclosed; and
                    </span>
                  </li>
                  <li>
                    <span>States the member’s rights under the Act.</span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  An administrative subpoena or summons. Otterz may release a
                  customer’s financial information only if:
                </span>
                <ul>
                  <li>
                    <span>
                      Otterz has reason to believe the records sought are
                      related to a legitimate law enforcement inquiry;
                    </span>
                  </li>
                  <li>
                    <span>
                      The customer has been served with a copy of the subpoena
                      on or before the date that Otterz is served, and Otterz
                      receives a copy of the notice sent to the customer
                      specifically describing the nature of the inquiry; and
                    </span>
                  </li>
                  <li>
                    <span>
                      Otterz waits ten (10) days from the date the customer was
                      served (or 14 days if the customer was served by mail), to
                      see if notice is received that the customer has filed a
                      motion to stop the subpoena. Otterz will ensure that all
                      required elements are met before disclosing financial
                      information to the federal authority.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span>A search warrant.</span>
              </li>
              <li>
                <span>
                  A judicial subpoena. If the customer does not challenge the
                  subpoena in court, the records may be available to a federal
                  government authority upon expiration of ten (10) days from the
                  date of service by the court (or 14 days if the notice was
                  mailed to the customer). Otterz will ensure that all required
                  elements are met before disclosing financial information to
                  the federal authority.
                </span>
              </li>
              <li>
                <span>
                  A formal written request by a federal government authority,
                  which is only used when no other authority is available to the
                  federal authority (above). If the customer does not challenge
                  this written request in court, the records may be available to
                  a federal government authority upon expiration of ten (10)
                  days from the date of service by the court (or 14 days if the
                  notice was mailed to the customer). Otterz will ensure that
                  all required elements are met before disclosing financial
                  information to the federal authority.
                </span>
              </li>
            </ul>
            <p>
              In addition to one of the above documents, Otterz must also
              receive a written certification from the federal government
              authority that the authority has complied with the applicable
              provisions of the Act. Upon receipt, Otterz will begin to prepare
              delivery of the requested financial information.
            </p>
          </div>
        </div>
        <div>
          <h5>Delayed Notice</h5>
          <div>
            Otterz may be required to delay notice to the customer that records
            have been requested or obtained for ninety (90) days, or
            indefinitely, if a judge finds that:
            <ul>
              <li>
                <span>
                  The investigation being conducted is within the lawful
                  jurisdiction of the federal government authority seeking the
                  financial records;
                </span>
              </li>
              <li>
                <span>
                  There is reason to believe the records sought are relevant to
                  a legitimate law enforcement inquiry; and
                </span>
              </li>
              <li>
                <span>
                  There is reason to believe such notice will result in –
                </span>
                <ul>
                  <li>
                    <span>
                      Endangering the life or physical safety of any person;
                    </span>
                  </li>
                  <li>
                    <span>Flight from prosecution;</span>
                  </li>
                  <li>
                    <span>Destruction of or tampering with evidence;</span>
                  </li>
                  <li>
                    <span>Intimidation of potential witnesses; or</span>
                  </li>
                  <li>
                    <span>
                      Otherwise seriously jeopardizing an investigation or
                      official proceeding or unduly delaying a trial or ongoing
                      proceeding to the same extent as the circumstances above.
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>Exceptions</h5>
          <div>
            The Act’s notification and certification requirements do not apply
            to the following situations:
            <ul>
              <li>
                <span>
                  When the request for disclosure is not identified with a
                  particular customer, which also includes records or
                  information that is not identifiable as being derived from the
                  financial records of a particular customer.
                </span>
              </li>
              <li>
                <span>
                  When the request for disclosure is pursuant to the exercise of
                  supervisory, regulatory or monetary functions with respect to
                  financial institutions (e.g., examinations, conservatorships
                  and receiverships).
                </span>
              </li>
              <li>
                <span>
                  When the disclosure is pursuant to procedures authorized by
                  the Internal Revenue Code.
                </span>
              </li>
              <li>
                <span>
                  When the disclosure is pursuant to the filing of a Suspicious
                  Activity Report (SAR) when Otterz believes that information
                  may be relevant to a possible violation of a statute or
                  regulation.
                </span>
              </li>
              <li>
                <span>
                  When the disclosure is required, pursuant to a federal statute
                  or regulation.
                </span>
              </li>
              <li>
                <span>
                  When the request for disclosure is sought under the Federal
                  Rules of Civil or Criminal Procedure, or comparable rules of
                  other courts in connection with litigation to which the
                  government authority and the customer are parties.
                </span>
              </li>
              <li>
                <span>
                  When the request is pursuant to an administrative subpoena
                  issued by an administrative law judge in an adjudicatory
                  proceeding.
                </span>
              </li>
              <li>
                <span>
                  When the request is pursuant to legitimate law enforcement
                  inquiries, and the information sought is the name, address,
                  account number and type of account of any customer.
                </span>
              </li>
              <li>
                <span>
                  When the request is pursuant to a grand jury subpoena. In
                  these instances, Otterz staff will not disclose the existence
                  of such a subpoena to the customer, or that financial records
                  were turned over to a grand jury.
                </span>
              </li>
              <li>
                <span>
                  When the records are sought by the General Accounting Office
                  pursuant to an authorized proceeding, investigation,
                  examination or audit directed at a government authority.
                </span>
              </li>
              <li>
                <span>
                  When Otterz or supervisory agency provides any record of any
                  officer or employee to the U.S. Attorney General, a state law
                  enforcement agency, or the Secretary of the Treasury that
                  there is reason to believe there were crimes against Otterz by
                  an insider.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>Special Procedures</h5>
          <p>
            Access to Financial Records for Certain Intelligence and Protective
            Purposes
          </p>
          <div>
            Aside from the exceptions listed above, Otterz may provide records
            to the following entities:
            <ul>
              <li>
                <span>
                  A government authority authorized to conduct foreign counter-
                  or foreign positive-intelligence activities, when the
                  authority has certified in writing that it has complied with
                  the applicable provisions of the Act;
                </span>
              </li>
              <li>
                <span>
                  The Secret Service for the purpose of conducting its
                  protective functions, when the agency has certified in writing
                  that it has complied with the applicable provisions of the
                  Act;
                </span>
              </li>
              <li>
                <span>
                  A government authority authorized to conduct investigations
                  of, or intelligence or counterintelligence analyses related
                  to, international terrorism, when the authority has certified
                  in writing that it has complied with the applicable provisions
                  of the Act; or
                </span>
              </li>
              <li>
                <span>
                  The Federal Bureau of Investigation (FBI), when the FBI
                  Director (or its designee) certifies in writing that such
                  records are sought for foreign counterintelligence purposes to
                  protect against international terrorism or clandestine
                  intelligence activities, provided that such an investigation
                  of a U.S. person is not conducted solely on the basis
                  protected by the first amendment to the U.S. Constitution.
                </span>
              </li>
            </ul>
          </div>
          <p>
            Otterz staff will not disclose that a government authority listed
            above has sought or obtained access to financial records when such
            authority certifies that there may result a danger to the national
            security of the United States, interference with a criminal,
            counterterrorism, or counterintelligence investigation, interference
            with diplomatic relations, or danger to the life or safety of any
            person.
          </p>
        </div>
        <div>
          <h5>Emergency Access to Financial Records</h5>
          <div>
            Otterz staff may release financial records to a government authority
            when the authority determines that delay in obtaining access to such
            records would result in imminent danger of the following:
            <ul>
              <li>
                <span>Physical injury to any person;</span>
              </li>
              <li>
                <span>Serious property damage; or</span>
              </li>
              <li>
                <span>Flight to avoid prosecution.</span>
              </li>
            </ul>
          </div>
          <p>
            In these cases, the government authority will submit the required
            certificate of compliance with the Act, which is signed by a
            supervisory official of a rank designated by the head of the
            government authority.
          </p>
        </div>
      </div>
      <div>
        <h4>COPPA Policy Requirements</h4>
        <div>
          The Company currently does not operate a website or online service
          directed to children that collects or maintains personal information
          about them, or knowingly collects or maintains personal information
          from a child online. In the event that the Company does, Otterz will
          comply with the requirements of COPPA including:
          <ul>
            <li>
              <span>
                Providing notice on the Company’s website or online service of
                what information it collects from children, how it uses such
                information, and its disclosure practices for such information;
              </span>
            </li>
            <li>
              <span>
                Obtaining verifiable parental consent prior to any collection,
                use, and/or disclosure of personal information from children;
              </span>
            </li>
            <li>
              <span>
                Providing a reasonable means for a parent to review the personal
                information collected from a child and to refuse to permit its
                further use or maintenance;
              </span>
            </li>
            <li>
              <span>
                Not conditioning a child's participation in a game, the offering
                of a prize, or another activity on the child disclosing more
                personal information than is reasonably necessary to participate
                in such activity and
              </span>
            </li>
            <li>
              <span>
                Establishing and maintaining reasonable procedures to protect
                the confidentiality, security, and integrity of personal
                information collected from children.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h4>CCPA</h4>
        <div>
          The California Consumer Privacy Act of 2018 (“CCPA”) gives consumers
          more control over the personal information that companies collect
          about them. The act secures privacy rights for California consumers
          including:
          <ul>
            <li>
              <span>
                The right to know about the personal information a company
                collects about a consumer and how it is used and shared;{" "}
              </span>
            </li>
            <li>
              <span>
                The right to delete personal information collected from a
                consumer (with some exceptions);
              </span>
            </li>
            <li>
              <span>
                The right to opt-out of the sale of a consumer’s personal
                information; and
              </span>
            </li>
            <li>
              <span>
                The right to non-discrimination for excercising a consumer’s
                CCPA rights.
              </span>
            </li>
          </ul>
        </div>
        <p>
          If a consumer is a California resident, CCPA requires the Company to
          provide the consumer with certain notices prior to the Company’s
          collection and use of personal information about a consumer. Otterz
          must provide consumers a notice with information set forth below.
        </p>
        <div>
          <h5>
            Right to Know About Personal Information Collected, Disclosed, or
            Sold
          </h5>
          <ul>
            <li>
              <span>
                Explanation that a consumer has the right to request that the
                Company disclose what personal information it collects, uses,
                discloses, and sells;
              </span>
            </li>
            <li>
              <span>
                Instructions for submitting a verifiable consumer request to
                know and links to an online request form or portal for making
                the request, if offered by the Company;
              </span>
            </li>
            <li>
              <span>
                General description of the process the Company will use to
                verify the consumer request, including any information the
                consumer must provide;
              </span>
            </li>
            <li>
              <span>
                Identification of the categories of personal information the
                Company has collected about consumers in the preceding 12
                months.
              </span>
            </li>
            <li>
              <span>
                Identification of the categories of sources from which the
                personal information is collected;
              </span>
            </li>
            <li>
              <span>
                Identification of the business or commercial purpose for
                collecting or selling personal information; and
              </span>
            </li>
            <li>
              <span>
                Disclosure or Sale of Personal Information which includes
              </span>
              <ul>
                <li>
                  <span>
                    Identification of the categories of personal information, if
                    any, that the Company has disclosed for a business purpose
                    or sold to third parties in the preceding 12 months.
                  </span>
                </li>
                <li>
                  <span>
                    For each category of personal information identified, the
                    categories of third parties to whom the information was
                    disclosed or sold.
                  </span>
                </li>
                <li>
                  <span>
                    Statement regarding whether the Company has actual knowledge
                    that it sells the personal information of consumers under 16
                    years of age.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h5>Right to Request Deletion of Personal Information</h5>
          <ul>
            <li>
              <span>
                Explanation that the consumer has a right to request the
                deletion of their personal information collected by the Company;
              </span>
            </li>
            <li>
              <span>
                Instructions for submitting a verifiable consumer request to
                delete and links to an online request form or portal for making
                the request, if offered by the Company; and
              </span>
            </li>
            <li>
              <span>
                General description of the process the Company will use to
                verify the consumer request, including any information the
                consumer must provide.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h5>Right to Opt-Out of the Sale of Personal Information</h5>
          <ul>
            <li>
              <span>
                Explanation that the consumer has a right to opt-out of the sale
                of their personal information by a Company; and
              </span>
            </li>
            <li>
              <span>
                Statement regarding whether or not the Company sells personal
                information and the contents of the notice of right to opt-out.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h5>
            Right to Non-Discrimination for the Exercise of a Consumer's Privacy
            Rights
          </h5>
          <ul>
            <li>
              <span>
                Explanation that the consumer has a right not to receive
                discriminatory treatment by the business for the exercise of the
                privacy rights conferred by the CCPA.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h5>Other Information</h5>
          <ul>
            <li>
              <span>
                Instructions on how an authorized agent can make a request under
                the CCPA on the consumer's behalf;
              </span>
            </li>
            <li>
              <span>
                A contact for questions or concerns about the Otterz’s privacy
                policies and practices; and
              </span>
            </li>
            <li>
              <span>Date the privacy policy was last updated.</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h4>Monitoring and Testing</h4>
        <p>
          Otterz will ensure that compliance with Privacy requirements is
          independently monitored and tested at least annually. Results from the
          testing are maintained and reported to the Board.
        </p>
      </div>
      <div>
        <h4>CAN-SPAM Policy Requirements</h4>
        <div>
          The CAN-SPAM Act details several prohibitions related to the sending
          of commercial email messages. Otterz has established internal
          procedures to identify an email message where the primary purpose
          would be considered commercial under CAN-SPAM to comply with the
          following requirements:
          <ul>
            <li>
              <span>
                Don’t use false or misleading header information. The “From,”
                “To,” “Reply-To,” and routing information – including the
                originating domain name and email address – must be accurate and
                identify the person or business who initiated the message.
              </span>
            </li>
            <li>
              <span>
                Don’t use deceptive subject lines. The subject line must
                accurately reflect the content of the message.
              </span>
            </li>
            <li>
              <span>
                Identify the message as an ad. The fact that the message is an
                advertisement must be clearly and conspicuously disclosed.
              </span>
            </li>
            <li>
              <span>
                Tell recipients where Otterz is located. The message must
                include a valid physical postal address. This can be Otterz’s
                current street address, a post office box registered with the
                U.S. Postal Service, or a private mailbox registered with a
                commercial mail receiving agency established under Postal
                Service regulations.
              </span>
            </li>
            <li>
              <span>
                Tell recipients how to opt out of receiving future email. The
                message must include a clear and conspicuous explanation of how
                the recipient can opt out of receiving future emails. Any notice
                containing the explanation of how the recipient can opt out
                should be designated, so that it is easy for an ordinary person
                to recognize, read, and understand. Creative use of type size,
                color, and location can improve clarity. A return email address
                or another easy Internet-based way to allow people to
                communicate their choice to opt out of further email
                communications must be provided. A menu can be used to allow a
                recipient to opt out of certain types of messages, but the
                option to stop all commercial messages must also be included.
                Make sure that Otterz’s spam filter doesn’t block these opt-out
                requests.
              </span>
            </li>
            <li>
              <span>
                Honor opt-out requests promptly. Any opt-out mechanism offered
                must be able to process opt-out requests for at least 30 days
                after a message is sent. A recipient’s opt-out request must be
                honored within 10 business days of receipt. Otterz cannot charge
                a fee, require the recipient to provide any personally
                identifying information beyond an email address, or make the
                recipient take any step other than sending a reply email or
                visiting a single page on an Internet website as a condition for
                honoring an opt-out request. Once Otterz has been notified of an
                opt-out request from the email recipient, Otterz is prohibited
                from selling or transferring the recipient’s email address to
                any other party, even in the form of a mailing list. The only
                exception is that the address may be transferred to a company
                hired to help ensure compliance with the CAN-SPAM Act.
              </span>
            </li>
            <li>
              <span>
                Third-party monitoring. The law makes it clear that even if a
                third party is hired to handle Otterz’s email marketing
                campaigns or solicitations, both Otterz and the third-party
                vendor that distributes the message may be held legally
                responsible and/or liable for violations of the CAN-SPAM Act.
                Any email marketing materials or solicitations created through a
                third-party vendor relationship are to be reviewed for
                compliance with the CAN-SPAM Act and this policy prior to
                distribution.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h4>Training</h4>
        <p>
          The Company will train all employees on Privacy and CAN-SPAM
          Compliance each calendar year, and monitor and track completion of
          this training. Other periodic or ad hoc trainings may be added as
          required.
        </p>
      </div>
      <div>
        <h4>Record Retention</h4>
        <p>
          Federal privacy requirements do not specifically require Otterz to
          maintain records for a specified time period. All records related to
          compliance with the Privacy laws for any Otterz account must be
          maintained in accordance with the Company's record retention policies
          and practices. The CCPA requires the Company to document consumer
          requests and the Company’s responses to those requests for a minimum
          of two years.
        </p>
        <p>
          Otterz must also keep a record of all marketing and advertising
          materials consistent with all applicable laws and in accordance with
          its record retention practices. All documentation supporting the
          review and approval of materials related to marketing and advertising
          also must be maintained.
        </p>
      </div>
    </>
  );
};

export default PrivacyNotice;
