import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "antd";
import { CTAButton, Message, OTPInput } from "../../common";
import Heading from "../Heading";
import useLocalStorage from "../../../hooks/useLocalStorage";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { ROUTES } from "../../constants";
import classNames from "../Signup/Signup.module.scss";
import mixpanel from "mixpanel-browser";

export default function VerifyEmail() {
  //Hooks
  const [email] = useLocalStorage("OTTERZ.verifyEmail");
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const [requested, setRequested] = useState("");
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (requested === "success" || requested === "") {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, requested]);

  useEffect(() => {
    if (window.localStorage.getItem("OTTERZ.emailVerified")) {
      navigate(ROUTES.PUBLIC.LOGIN);
    }
    if (!email) {
      navigate(ROUTES.PUBLIC.SIGN_UP);
    }
  }, [navigate, email]);

  const verifyEmailOTP = useMutation((data) => {
    return api.onBoarding.verifyEmail(data);
  });

  const resendSignup = useMutation((data) => {
    return api.onBoarding.resendSignup(data);
  });

  //Event handlers and api calls
  const handleOnFinish = (values) => {
    let code = values["otp"];
    mixpanel.identify(email);
    verifyEmailOTP.mutate(
      { email: email, code: code },
      {
        onSuccess: (data, variables, context) => {
          window.localStorage.setItem("OTTERZ.emailVerified", true);
          mixpanel.people.set({
            "Sign Up": "Sign Up completed",
          });
          Message({ type: "success", content: "Success" });
          navigate(ROUTES.PUBLIC.LOGIN);
        },
        onError: (error, variables, context) => {
          mixpanel.people.set({
            "Sign Up": "Sign Up failed",
          });
          if (error.code === "CodeMismatchException") {
            generateErrorMessage(error, "Please enter the correct OTP");
          } else {
            generateErrorMessage(error, "OTP is required");
          }
          form.resetFields();
        },
      }
    );
  };

  function handleResend() {
    form.resetFields();
    resendSignup.mutate(email, {
      onSuccess: () => {
        setRequested("success");
        setSeconds(60);

        Message({
          type: "success",
          content: "Confirmation code sent successfully",
        });
      },
      onError: (error) => {
        setRequested("error");
        generateErrorMessage(error, `${error.message}`);
      },
    });
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Verify your email address"
          description={`We have sent an OTP to your email address ${email}`}
        />
        <Form
          form={form}
          onFinish={handleOnFinish}
          layout="vertical"
          autoComplete="off"
          onValuesChange={() => {
            setDisable(false);
          }}
        >
          <Form.Item
            label="Enter OTP"
            name="otp"
            rules={[{ required: true, message: "required" }]}
          >
            <OTPInput />
          </Form.Item>
          <div className={classNames.links} style={{ textAlign: "left" }}>
            {seconds > 0 ? (
              <span>
                Time Remaining: {`00:${seconds < 10 ? 0 : ""}${seconds}`}
              </span>
            ) : (
              <>
                <span>Didn’t receive code</span>
                <Button
                  type="link"
                  size="small"
                  onClick={handleResend}
                  loading={resendSignup.isLoading}
                >
                  Resend
                </Button>
              </>
            )}
          </div>

          <CTAButton
            htmlType="submit"
            type="primary"
            loading={verifyEmailOTP.isLoading}
            disabled={disable || resendSignup.isLoading}
          >
            Next
          </CTAButton>
        </Form>
      </div>
    </div>
  );
}
