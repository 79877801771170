/* eslint-disable no-undef */
import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
  memo,
} from "react";

const CardknoxContext = createContext();

const IFields = ({
  iFieldsIFrameSrc,
  iFieldsAPIKey,
  softwareName,
  softwareVersion,
  onError,
  forPaymentTransaction = true,
  children,
}) => {
  const [achState, setACHState] = useState("neutral");
  const [cardState, setCardState] = useState("neutral");
  const [cvvState, setCVVState] = useState("neutral");

  const [achSUT, setACHSUT] = useState("");
  const [cardSUT, setCardSUT] = useState("");
  const [cvvSUT, setCVVSUT] = useState("");
  const [error, setError] = useState("");

  const [onBeforeSubmit, setOnBeforeSubmit] = useState(null);

  if (error) onError(error);

  const setACHStyle = useCallback(
    (styles) => setIfieldStyle("ach", styles),
    []
  );

  const setCardStyle = useCallback(
    (styles) => setIfieldStyle("card-number", styles),
    []
  );

  const setCVVStyle = useCallback(
    (styles) => setIfieldStyle("cvv", styles),
    []
  );

  useEffect(() => {
    setAccount(iFieldsAPIKey, softwareName, softwareVersion);
    enableAutoSubmit("payment-form");
    if (forPaymentTransaction)
      enable3DS("amount", "month", "year", true, 20000);
    enableAutoFormatting();
  }, [iFieldsAPIKey, softwareName, softwareVersion, forPaymentTransaction]);

  useEffect(() => {
    addIfieldCallback(
      "input",
      ({
        ifieldValueChanged,
        lastIfieldChanged,
        cardNumberFormattedLength,
        cardNumberIsValid,
        issuer,
        cvvLength,
        cvvIsValid,
        achLength,
        achIsValid,
      }) => {
        if (!ifieldValueChanged) return;

        if (lastIfieldChanged === "ach") {
          setACHState(
            achLength === 0 ? "neutral" : achIsValid ? "valid" : "invalid"
          );
          return;
        }

        if (lastIfieldChanged === "card-number") {
          setCardState(
            cardNumberFormattedLength === 0
              ? "neutral"
              : cardNumberIsValid
              ? "valid"
              : "invalid"
          );
          setCVVState(
            issuer === "unknown" || cvvLength === 0
              ? "neutral"
              : (issuer === "amex" && cvvLength === 4) || cvvLength === 3
              ? "valid"
              : "invalid"
          );
          return;
        }

        if (lastIfieldChanged === "cvv") {
          setCVVState(
            cvvLength === 0 ? "neutral" : cvvIsValid ? "valid" : "invalid"
          );
          return;
        }
      }
    );
  }, []);

  const value = {
    iFieldsIFrameSrc,
    achState,
    cardState,
    cvvState,
    achSUT,
    cardSUT,
    cvvSUT,
    error,
    onBeforeSubmit,
    setOnBeforeSubmit,
    setACHStyle,
    setCardStyle,
    setCVVStyle,
    setACHSUT,
    setCardSUT,
    setCVVSUT,
    setError,
  };
  return (
    <CardknoxContext.Provider value={value}>
      {children}
    </CardknoxContext.Provider>
  );
};

const Form = ({ children: formItems, ...props }) => {
  const { onBeforeSubmit, setACHSUT, setCardSUT, setCVVSUT, setError } =
    useContext(CardknoxContext);

  const formProps = {
    ...props,
    id: "payment-form",
    method: "POST",
    onSubmit: (e) => {
      if (typeof onBeforeSubmit === "function") onBeforeSubmit(e);
      e.preventDefault();
      getTokens(
        () => console.info("IFields returned success"),
        () => console.error("IFields returned error")
      );
    },
  };

  const achRef = useRef();
  const cardRef = useRef();
  const cvvRef = useRef();
  const errorRef = useRef();

  useEffect(() => {
    const achSUTWatcher = setInterval(() => {
      const achSUT = achRef.current?.value;
      if (achSUT) setACHSUT(achSUT);
    }, 600);

    return () => clearInterval(achSUTWatcher);
  }, [achRef, setACHSUT]);

  useEffect(() => {
    const cardSUTWatcher = setInterval(() => {
      const cardSUT = cardRef.current?.value;
      if (cardSUT) setCardSUT(cardSUT);
    }, 600);

    return () => clearInterval(cardSUTWatcher);
  }, [cardRef, setCardSUT]);

  useEffect(() => {
    const cvvSUTWatcher = setInterval(() => {
      const cvvSUT = cvvRef.current?.value;
      if (cvvSUT) setCVVSUT(cvvSUT);
    }, 600);

    return () => clearInterval(cvvSUTWatcher);
  }, [cvvRef, setCVVSUT]);

  useEffect(() => {
    const cvvSUTWatcher = setInterval(() => {
      const cvvSUT = cvvRef.current?.value;
      if (cvvSUT) setCVVSUT(cvvSUT);
    }, 600);

    return () => clearInterval(cvvSUTWatcher);
  }, [cvvRef, setCVVSUT]);

  useEffect(() => {
    const errorWatcher = setInterval(() => {
      const error = errorRef.current?.value;
      if (error) setError(error);
    }, 600);

    return () => clearInterval(errorWatcher);
  }, [errorRef, setError]);

  return (
    <form {...formProps} autoComplete="off">
      {formItems}
      <input hidden ref={achRef} data-ifields-id="ach-token"></input>
      <input
        hidden
        ref={cardRef}
        data-ifields-id="card-number-token"
        name="xCardNum"
      ></input>
      <input
        hidden
        ref={cvvRef}
        data-ifields-id="cvv-token"
        name="xCVV"
      ></input>
      <input hidden ref={errorRef} data-ifields-id="card-data-error"></input>
    </form>
  );
};

const ACH = ({
  containerStyle,
  neutralStyle,
  validStyle,
  invalidStyle,
  placeHolderText = "Checking Account Number",
  onACHChange,
  ...props
}) => {
  const { iFieldsIFrameSrc, achState, achSUT, setACHStyle } =
    useContext(CardknoxContext);

  if (achSUT) onACHChange(achSUT);

  const styles = {
    neutral: neutralStyle,
    valid: validStyle,
    invalid: invalidStyle,
  };
  setACHStyle(styles[achState]);

  return (
    <iframe
      {...props}
      style={containerStyle}
      title="ach"
      data-ifields-id="ach"
      data-ifields-placeholder={placeHolderText}
      src={iFieldsIFrameSrc}
    ></iframe>
  );
};

const Card = ({
  containerStyle,
  neutralStyle,
  validStyle,
  invalidStyle,
  placeHolderText = "Card Number",
  onCardChange,
  ...props
}) => {
  const { iFieldsIFrameSrc, cardState, cardSUT, setCardStyle } =
    useContext(CardknoxContext);

  if (cardSUT) onCardChange(cardSUT);

  const styles = {
    neutral: neutralStyle,
    valid: validStyle,
    invalid: invalidStyle,
  };
  setCardStyle(styles[cardState]);

  return (
    <iframe
      {...props}
      style={containerStyle}
      title="card-number"
      data-ifields-id="card-number"
      data-ifields-placeholder={placeHolderText}
      src={iFieldsIFrameSrc}
    ></iframe>
  );
};

const CVV = ({
  containerStyle,
  neutralStyle,
  validStyle,
  invalidStyle,
  placeHolderText = "CVV",
  onCVVChange,
  ...props
}) => {
  const { iFieldsIFrameSrc, cvvState, cvvSUT, setCVVStyle } =
    useContext(CardknoxContext);

  if (cvvSUT) onCVVChange(cvvSUT);

  const styles = {
    neutral: neutralStyle,
    valid: validStyle,
    invalid: invalidStyle,
  };
  setCVVStyle(styles[cvvState]);

  return (
    <iframe
      {...props}
      style={containerStyle}
      title="cvv"
      data-ifields-id="cvv"
      data-ifields-placeholder={placeHolderText}
      src={iFieldsIFrameSrc}
    ></iframe>
  );
};

const AmountInput = ({ children }) =>
  children({ id: "amount", name: "xAmount" });

const ExpireMonthInput = ({ children }) =>
  children({ id: "month", name: "xMonth" });

const ExpireYearInput = ({ children }) =>
  children({ id: "year", name: "xYear" });

const SubmitButton = ({ children, onBeforeSubmit }) => {
  const { setOnBeforeSubmit } = useContext(CardknoxContext);

  useEffect(() => {
    setOnBeforeSubmit(() => onBeforeSubmit);
  }, [setOnBeforeSubmit, onBeforeSubmit]);

  return children({ type: "submit" });
};

IFields.Form = Form;
IFields.ACH = memo(ACH);
IFields.Card = memo(Card);
IFields.CVV = memo(CVV);
IFields.AmountInput = AmountInput;
IFields.ExpireMonthInput = ExpireMonthInput;
IFields.ExpireYearInput = ExpireYearInput;
IFields.SubmitButton = SubmitButton;

export default IFields;
