import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Form, Radio, Checkbox } from "antd";
import { Input, CTAButton } from "../../../../common";
import classNames from "./Refund.module.scss";
import { validation } from "../../../config";
import api from "../../../../API";
import checkNullAmount from "../../../../util/functions/checkNullAmount";

const RefundForm = ({ onTransactionClick, record, currentUserEmail }) => {
  const [refundType, setRefundType] = useState("full");
  const [form] = Form.useForm();

  const [sendEmail, setSendEmail] = useState(false);
  const [sendReceipt, setSendReceipt] = useState(false);

  const onFinish = (data) => {
    sendEmail && onEmailSend(currentUserEmail);
    onTransactionClick(
      Object.assign(
        {},
        {
          amount:
            (refundType === "full" ? data.fullAmount : data.partialAmount) || 0,
          description: data.description || "",
          partial: refundType === "partial" ? true : false,
        },
        {
          refNum: record?.xRefNum,
          ...sendReceipt,
        }
      )
    );
  };

  function handleEmailSendfn(event) {
    return api.Receivables.PaymentAcceptance.sendEmail(event);
  }

  const handleEmailSendMt = useMutation((event) => handleEmailSendfn(event));

  const onEmailSend = (email) => {
    const userDetails = {
      xEmail: email,
      xRefNum: record?.xRefNum,
    };

    handleEmailSendMt.mutate(userDetails);
  };

  const amountFieldValidator = (value) => {
    if (checkNullAmount(value)) {
      return Promise.reject(validation.transactionRefund.amountValid);
    }
    if (Number(value) <= Number(record?.xAmount)) {
      return Promise.resolve();
    }
    return Promise.reject(validation.transactionRefund.amountValid);
  };

  useEffect(() => {
    if (record?.transactionType === "Check") {
      setRefundType("partial");
    }
  }, [record?.transactionType]);

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError={true}
    >
      <Radio.Group
        className={classNames.refundType}
        defaultValue={record?.transactionType === "Check" ? "partial" : "full"}
      >
        {(record?.transactionType === "CC" ||
          record?.xCommand.includes("CC")) && (
          <div className={classNames.refundTypeRow}>
            <Radio
              onChange={(e) => {
                setRefundType(e.target.value);
                form.setFieldValue("partialAmount", "");
                form.resetFields(["partialAmount"]);
              }}
              value={"full"}
            >
              Full Refund
            </Radio>
            <Form.Item
              name={"fullAmount"}
              rules={
                refundType === "full" && [
                  {
                    required: true,
                    message: validation.transactionRefund.amount,
                  },
                  () => ({
                    validator(_, value) {
                      return amountFieldValidator(value);
                    },
                  }),
                ]
              }
              initialValue={record?.xAmount}
            >
              <Input
                // value={record?.xAmount}
                placeholder="Enter amount"
                type="number"
                size="small"
                disabled={true}
                prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
                style={{ width: "184px" }}
                readOnly={true}
                defaultValue={record?.xAmount}
              />
            </Form.Item>
          </div>
        )}
        <div className={classNames.refundTypeRow}>
          <Radio
            onChange={(e) => setRefundType(e.target.value)}
            value={"partial"}
          >
            Partial Refund
          </Radio>
          <Form.Item
            name={"partialAmount"}
            rules={
              refundType === "partial" && [
                {
                  required: true,
                  message: validation.transactionRefund.amount,
                },
                () => ({
                  validator(_, value) {
                    return amountFieldValidator(value);
                  },
                }),
              ]
            }
          >
            <Input
              value={record?.xAmount}
              placeholder="Enter amount"
              type="text"
              size="small"
              disabled={refundType === "full"}
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              style={{ width: "184px" }}
            />
          </Form.Item>
        </div>
      </Radio.Group>
      <Form.Item
        name={"description"}
        label={<span className={classNames.label}>Description</span>}
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input size="small" placeholder="Please enter" />
      </Form.Item>
      <Form.Item>
        <Checkbox
          disabled={!!record?.xEmail}
          onChange={(e) => setSendReceipt(e.target.checked)}
        >
          Send receipt
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox onChange={(e) => setSendEmail(e.target.checked)}>
          Send me a copy
        </Checkbox>
      </Form.Item>
      <CTAButton
        htmlType="submit"
        type="primary"
        key="submit"
        style={{
          height: 44,
          width: 385,
        }}
      >
        Refund
      </CTAButton>
    </Form>
  );
};

export default RefundForm;
