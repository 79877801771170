import classNames from "./MainLayout.module.scss";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../common";
import UseIdleTimer from "../../hooks/useIdleTimer";
// import { Result, Button } from "antd";
// import ErrorBoundary from "react-error-boundary";
// import { QueryErrorResetBoundary } from "react-query";

function MainLayout() {
  return (
    <div className={classNames.wrapper}>
      <UseIdleTimer />
      <div className={classNames.sidebarWrapper}>
        <Sidebar />
      </div>
      <div className={classNames.sectionWrapper}>
        {/* line 15 to 35 has been commmented out to be implemented in the future */}

        {/* <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary
              onReset={reset}
              fallbackRender={({ resetErrorBoundary }) => (
                <Result
                  status="error"
                  title="Error"
                  extra={
                    <Button type="primary" onClick={resetErrorBoundary}>
                      Try again
                    </Button>
                  }
                />
              )}
            >
              <Outlet />
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary> */}
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
