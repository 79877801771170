import { Outlet } from "react-router-dom";
import classNames from "./BusinessBanking.module.scss";

function BusinessBanking() {
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.sectionWrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default BusinessBanking;
