import { Auth } from 'aws-amplify';
import endpoints from "../../../endpoints";

export default async function createAPInvoice(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    return await(
        await fetch(endpoints.PAYABLES.ACCOUNTS_PAYABLES.INVOICES[env], {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data)
        })
    ).json();
}
