import { useState, useEffect, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Spin } from "antd";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import { isBusinessType } from "../../../util/functions/onboarding";
import classNames from "../ManageCards.module.scss";
import generateErrorMessage from "../../../util/functions/customError";

export default function ActivateCardForm({ currentCard, isPin }) {
  const { application } = useContext(AppContext);
  const [cardWidgetUrl, setCardWidgetUrl] = useState("");
  const queryClient = useQueryClient();

  const id = isBusinessType(application?.type)
    ? currentCard?.otz_customer_id
    : application?.otz_person_id;

  useEffect(() => {
    getWidgetUrl.mutate({
      widget_type: isPin ? "set_pin" : "activate_card",
      otz_customer_id: id,
      otz_account_id: application?.otz_account_id,
      otz_card_id: currentCard?.otz_card_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Api calls
  const getWidgetUrl = useMutation(
    (data) => api.BusinessBanking.getWidgetUrl(data),
    {
      onSuccess: (response) => {
        setCardWidgetUrl(response?.payload?.url);
        queryClient.invalidateQueries("listCards");
      },
      onError: (error) => generateErrorMessage(error),
    }
  );

  return (
    <>
      {getWidgetUrl.isLoading || !cardWidgetUrl ? (
        <div className={classNames.activateCard}>
          <Spin />
        </div>
      ) : (
        <iframe
          className="iframe"
          src={cardWidgetUrl}
          title="Card Widget Url"
          style={{
            borderStyle: "none",
            overflow: "hidden",
            margin: "0 33px",
            width: "100%",
          }}
        />
      )}
    </>
  );
}
