import classNames from "../ManageCards.module.scss";
import { Modal } from "../../../common";
import SetSpentLimitForm from "./EditCreditLimitForm";

export default function SetSpendLimit({ visible, onCancel, currentCard }) {
  return (
    <Modal
      destroyOnClose={true}
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Set Spend Limit"
      className={classNames.modal}
      bodyStyle={{
        padding: "12px",
        display: "flex",
        flexDirection: "column",
      }}
      width={450}
    >
      <div className={classNames.modalBody}>
        <SetSpentLimitForm currentCard={currentCard} onCancel={onCancel} />
      </div>
    </Modal>
  );
}
