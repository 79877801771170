import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { Button } from "antd";
import { commonMessage } from "../config";

const NotApproved = ({ name }) => {
  return (
    <div className={classNames.success}>
      <Title as="h3">
        {commonMessage.notApproved.title+" "}
        {/* {`${name}`} */}
      </Title>
      <div className={classNames.extra}>
        {commonMessage.notApproved.extra}
        <Button
          type="link"
          className={classNames.linkButton}
          href={commonMessage.notApproved.link}
          rel="noreferrer"
          target="_blank"
        >
          Customer Support.
        </Button>
      </div>
    </div>
  );
};

export default NotApproved;
