import { useMutation, useQueryClient } from "react-query";
import { useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { Form, Switch } from "antd";
import {
  Modal,
  CTAButton,
  Input,
  Select,
  Message,
  CountryPicker,
} from "../../../../common";
import { AppContext } from "../../../../../context/AppState";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import { isBusinessType } from "../../../../util/functions/onboarding";
import { validation } from "../../../../Auth/config";
import { validationPayee } from "../../../config";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";
import { usStateList } from "../../../../util";
import classNames from "../Payees.module.scss";
import Bugsnag from "@bugsnag/browser";

export default function AddNewPayee({ visible, onCancel, achModal, refetch, isQBPayee, payeeSet, payStart, reUpload, invoiceID, payeeName, email }) {
  //Hooks
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  const [wireChecked, setWireChecked] = useState(false);
  // const wireChecked = false;
  const [countrySelected, setCountrySelected] = useState(null);
  const { application } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    setType(null);
  }, [visible]);

  useEffect(() => {
    if (isQBPayee) {
      setType("business"); // Set Payee Type to "business" if isQBPayee is true
      form.setFieldsValue({
        customerType: "business",
        name: payeeName, // Set the Payee Name (Business Name) to the value of payeeName
        email: email, // Set the Email Address to the value of email
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isQBPayee, payeeName]);

  //Api calls
  const updateAPInvoiceMutation = useMutation((invoiceData) =>
    api.AccountsPayable.updateAPInvoice(invoiceData)
  );

  const { mutate, isLoading } = useMutation(api.BusinessBanking.createPayee, {
    onSuccess: async (data) => {
      Message({ type: "success", content: "Payee created successfully" });
      if(isQBPayee) {
        if (invoiceID) {
          let response = await updateAPInvoiceMutation.mutateAsync({
            invoiceID: invoiceID,
            update: {
              otz_payee_id: data?.payload?.otz_payee_id,
            }
          });
          Bugsnag.notify("Response success: ", response);
          await payeeSet(data?.payload)
          payStart(data?.payload)
        } else {
          await payeeSet(data?.payload)
          reUpload(data?.payload)
        }
      }
      try {
        mixpanel.track("Add Payee Successful", {
          "Payee Type": form.getFieldValue("customerType"),
          "Payee Name": form.getFieldValue("customerType") === "business" ? form.getFieldValue("name") : form.getFieldValue("first") + " " + form.getFieldValue("last"),
          "Bank Name": form.getFieldValue("bank"),
        });
      } catch (error) {
        console.error("Mixpanel error: ", error);
      }
      queryClient.invalidateQueries("getPayees");
      form.resetFields();
      onCancel();
      refetch();
    },
    onError: (error) => {
      if (error.response.data.message === "duplicate account") {
        Message({ type: "error", content: "Payee is already exist" });
      } else {
        generateErrorMessage(error);
      }
      try {
        mixpanel.track("Add Payee Failure", {
          "Payee Type": form.getFieldValue("customerType"),
          "Payee Name": form.getFieldValue("customerType") === "business" ? form.getFieldValue("name") : form.getFieldValue("first") + " " + form.getFieldValue("last"),
          "Bank Name": form.getFieldValue("bank"),
          "Error": error?.response?.data?.message,
        });
      } catch (error) {
        console.error("Mixpanel error: ", error);
      }
      form.resetFields();
      onCancel();
    },
  });

  //Event handlers
  function handleSubmit(input) {
    input.customerType = input?.customerType ? input?.customerType : "personal";
    const reqPayload = {
      ...(isBusinessType(application?.type)
        ? { otz_business_id: application?.otz_business_id }
        : { otz_person_id: application?.otz_person_id }),
      ...(input?.customerType === "personal" && {
        payee_first_name: input?.first,
        payee_last_name: input?.last,
      }),
      ...(input?.customerType === "business" && {
        payee_entity_name: input?.name,
      }),
      ...(wireChecked && {
        address_line_1: input?.payeeStreet,
        address_line_2: input?.payeeStreet2,
        city: input?.payeeCity,
        country_code: input?.payeeCountry,
        postal_code: input?.payeePostalCode,
        state: input?.payeeState,
        wire_routing_number: input?.wireroutingNumber,
      }),
      email: input?.email,
      number: input?.accountNumber,
      customer_type: input?.customerType || "personal",
      ach_routing_number: input?.routingNumber,
      bank_countries: ["US"],
      bank_name: input?.bank,
      type: input?.accountType || "CHECKING",
    };
    mutate({
      ...reqPayload,
    });
  }

  const resetFormFieldsOnCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={resetFormFieldsOnCancel}
      onCancel={resetFormFieldsOnCancel}
      title={achModal ? "Add New Payee for ACH Payment" : isQBPayee ? "Add a New Vendor" : "Add New Payee"}
      className={classNames.modal}
      bodyStyle={{
        height: "auto",
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
      footer={
        <CTAButton
          onClick={() => form.submit()}
          type="primary"
          style={{ height: 44, width: 384 }}
          loading={isLoading}
        >
          {isQBPayee ? "Save Vendor" : "Add Payee"}
        </CTAButton>
      }
      width={450}
    >
      <div className={classNames.modalBody}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleSubmit}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="customerType"
            label={
              <span className={classNames.label}>
                {isQBPayee ? "Vendor Type" : "Payee Type"} <sup style={{ top: 1 }}>*</sup>
              </span>
            } 
            rules={[
              { required: true, message: validationPayee.addPayee.accountType },
            ]}
          >
            <Select
              placeholder={validationPayee.addPayee.dropdown}
              size="small"
              style={{ width: "100%" }}
              onChange={(value) => setType(value)}
            >
              <Select.Option value="personal">Personal</Select.Option>
              <Select.Option value="business">Business</Select.Option>
            </Select>
          </Form.Item>

          {type === "business" ? (
            <Form.Item
              name="name"
              rules={[
                { required: true, message: validationPayee.addPayee.name },
                {
                  max: MAX_MIN.businessName.max,
                  message: validationPayee.addPayee.nameMax,
                },
                {
                  pattern: new RegExp(REGEX.payeeName),
                  message: validationPayee.addPayee.validPayeeName,
                },
              ]}
              label={
                <span className={classNames.label}>
                  {isQBPayee ? "Vendor Name" : "Payee Name (Business Name)"} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
            >
              <Input
                placeholder={validationPayee.addPayee.payeePlaceholder}
                size="small"
              ></Input>
            </Form.Item>
          ) : (
            <Form.Item>
              <Form.Item
                name="first"
                label={
                  <span className={classNames.label}>
                    First Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  {
                    required: true,
                    message: validation.firstName.required,
                  },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.firstName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.firstName.valid,
                  },
                ]}
              >
                <Input placeholder={validation.firstName.placeholder} />
              </Form.Item>

              <Form.Item
                name="last"
                label={
                  <span className={classNames.label}>
                    Last Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: true, message: validation.lastName.required },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.lastName.maxLength,
                  },
                  {
                    pattern: new RegExp(REGEX.name),
                    message: validation.lastName.valid,
                  },
                ]}
              >
                <Input placeholder={validation.lastName.placeholder} />
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item
            name="email"
            type="email"
            rules={[
              {
                required: true,
                message: validationPayee.addPayee.email,
              }
            ]}
            label={
              <span className={classNames.label}>
                {isQBPayee ? "Vendor Email Address" : "Email Address"} <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder={validationPayee.addPayee.payeePlaceholder}
              size="small"
            ></Input>
          </Form.Item>

          <Form.Item
            name="accountNumber"
            rules={[
              {
                required: true,
                message: validationPayee.addPayee.accountNumber,
              },
              {
                max: MAX_MIN.accountNumber.max,
                message: validationPayee.addPayee.accountMax,
              },
              {
                pattern: new RegExp(REGEX.accountNumber),
                message: validationPayee.addPayee.accountNumberValid,
              },
            ]}
            type="number"
            label={
              <span className={classNames.label}>
                Account Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder={validationPayee.addPayee.payeePlaceholder}
              size="small"
            ></Input>
          </Form.Item>

          <Form.Item
            name="bank"
            rules={[
              { required: true, message: validationPayee.addPayee.bank },
              {
                max: MAX_MIN.bank.max,
                message: validationPayee.addPayee.bankMax,
              },
              {
                pattern: new RegExp(REGEX.bankName),
                message: validationPayee.addPayee.validBankName,
              },
            ]}
            type="text"
            label={
              <span className={classNames.label}>
                Bank Name <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder={validationPayee.addPayee.bankNamePlaceholder}
              size="small"
            ></Input>
          </Form.Item>

          <Form.Item
            name="accountType"
            label={
              <span className={classNames.label}>
                Account Type <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: validationPayee.addPayee.accountType },
            ]}
          >
            <Select
              placeholder={validationPayee.addPayee.dropdown}
              size="small"
              style={{ width: "100%" }}
            >
              <Select.Option value="SAVINGS">Savings</Select.Option>
              <Select.Option value="CHECKING">Checking</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: validationPayee.addPayee.routingNumber,
              },
              {
                pattern: new RegExp(REGEX.einNumber),
                message: validationPayee.addPayee.routingNumberValid,
              },
            ]}
            name="routingNumber"
            label={
              <span className={classNames.label}>
                Routing Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder={validationPayee.addPayee.payeePlaceholder}
              size="small"
            ></Input>
          </Form.Item>
          {!isQBPayee && <Form.Item
            name="enableWire"
            label={
              <span className={classNames.label}>Enable Wire Payments</span>
            }
          >
            <Switch
              checked={wireChecked}
              onChange={() => {
                setWireChecked(!wireChecked);
              }}
            />
          </Form.Item>}
          {wireChecked && (
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: validationPayee.addPayee.routingNumber,
                  },
                  {
                    pattern: new RegExp(REGEX.einNumber),
                    message: validationPayee.addPayee.routingNumberValid,
                  },
                ]}
                name="wireroutingNumber"
                label={
                  <span className={classNames.label}>
                    Wire Routing Number <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
              >
                <Input
                  placeholder={validationPayee.addPayee.payeePlaceholder}
                  size="small"
                ></Input>
              </Form.Item>
              <Form.Item
                label={
                  <span className={classNames.label}>
                    Address <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
              >
                <Form.Item
                  name="payeeCountry"
                  rules={[
                    { required: true, message: validation.country.required },
                  ]}
                >
                  <CountryPicker
                    size="small"
                    placeholder={validation.country.placeholder}
                    onChange={(code) => {
                      setCountrySelected(code);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: validation.street.required,
                    },
                    { max: MAX_MIN.street.max, message: validation.street.max },
                  ]}
                  name="payeeStreet"
                >
                  <Input
                    placeholder={validation.street.placeholder}
                    size="small"
                  />
                </Form.Item>
                <Form.Item
                  name="payeeStreet2"
                  rules={[
                    {
                      max: MAX_MIN.street.max,
                      message: validation.street2.max,
                    },
                  ]}
                >
                  <Input
                    placeholder={validation.street2.placeholder}
                    size="small"
                  />
                </Form.Item>
                <Form.Item
                  name="payeeCity"
                  rules={[
                    { required: true, message: validation.city.required },
                    { max: MAX_MIN.city.max, message: validation.city.max },
                  ]}
                >
                  <Input
                    placeholder={validation.city.placeholder}
                    size="small"
                  />
                </Form.Item>
                <Form.Item
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  name="payeeState"
                  rules={[
                    { required: true, message: validation.state.required },
                  ]}
                >
                  {countrySelected === "US" ? (
                    <Select
                      size="small"
                      showSearch
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={validation.state.placeholder}
                    >
                      {usStateList().map((value) => (
                        <Select.Option key={value.code} value={value.code}>
                          {value.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      size="small"
                      placeholder={validation.state.placeholder}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: validation.postalCode.required },
                    {
                      pattern: new RegExp(REGEX.postalCode),
                      message: validation.postalCode.valid,
                    },
                  ]}
                  name="payeePostalCode"
                >
                  <Input
                    placeholder={validation.postalCode.placeholder}
                    size="small"
                  />
                </Form.Item>
              </Form.Item>
            </>
          )}

          {/* To be integrated after MVP as per Praveena & Mukund */}
          {/* <Form.Item
            name="defaultPaymentMethod"
            label={
              <span className={classNames.label}>Default Payment Method</span>
            }
          >
            <Group>
              <Space size="small">
                <RButton
                  value="ACH"
                  className={classNames.optionTxtButton}
                  style={{ border: "1px solid #d1d5dd" }}
                >
                  ACH
                </RButton>
                <RButton
                  value="Wire Transfer"
                  className={classNames.optionTxtButton}
                  style={{ border: "1px solid #d1d5dd" }}
                >
                  Wire Transfer
                </RButton>
                <RButton
                  value="Check"
                  className={classNames.optionTxtButton}
                  style={{ border: "1px solid #d1d5dd" }}
                >
                  Check
                </RButton>
                <RButton
                  value="Card"
                  className={classNames.optionTxtButton}
                  style={{ border: "1px solid #d1d5dd" }}
                >
                  Card
                </RButton>
              </Space>
            </Group>
          </Form.Item> */}
        </Form>
      </div>
    </Modal>
  );
}
