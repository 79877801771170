import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, QueryErrorResetBoundary } from "react-query";
import {
  Card,
  Table,
  Space,
  Select as AntSelect,
  Empty,
  Button as AntButton,
} from "antd";
import { ErrorBoundary } from "react-error-boundary";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiEditLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import moment from "moment";
import { Input, Header, Select, Button } from "../../../common";

import api from "../../../API";
import ChangeStatus from "../Invoices/ChangeStatus/ChangeStatus";
import { DEFAULT_AMOUNT, FORMATS, ROUTES } from "../../../constants";
import DeleteInvoice from "./DeleteInvoice/DeleteInvoice";
import CreateNewInvoice from "../Invoices/CreateNewInvoice/CreateNewInvoice";
import EditInvoice from "../ViewInvoice/EditInvoice/EditInvoice";
import { intToDecimalDollar } from "../../../util/functions/dollarConvertion";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import classNames from "./AllInvoices.module.scss";

export default function AllInvoices() {
  const navigate = useNavigate();
  const location = useLocation();

  const [pagination, setPagination] = useState(PAGINATION);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState(invoiceData);
  const [keyword, setKeyword] = useState(null);
  const [client, setClient] = useState(null);
  const [sort, setSortChange] = useState();
  const [statusAttribute, setStatusAttribute] = useState(
    location.state?.filter
  );
  const [createNewInvoiceModalVisibility, setCreateNewInvoiceModalVisibility] =
    useState(false);
  const [editInvoiceModalVisibility, setEditInvoiceModalVisibility] = useState({
    visible: false,
    record: [],
  });

  const handleCreateNewInvoiceModalCancelClick = () => {
    setCreateNewInvoiceModalVisibility(false);
  };
  const handleEditInvoiceCancelClick = () => {
    setEditInvoiceModalVisibility({ visible: false, record: false });
  };

  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState();
  const [deleteInvoiceModalVisibility, setDeleteInvoiceModalVisibility] =
    useState({ visible: false, record: null });

  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
  };
  const handleStatusChange = (value, record) => {
    setInvoiceStatus({ flag: "invoice", value: value, record: record });
    setChangeStatusModalVisibility(true);
  };

  const handleDeleteInvoiceCancelClick = () => {
    setDeleteInvoiceModalVisibility(false);
  };

  const handleInvoiceEditModalButtonClick = (record) => {
    setEditInvoiceModalVisibility({ visible: true, record: record });
  };
  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoiceQuery, isLoading: isLoadingInvoice } = useQuery(
    ["getInvoices", location.state],
    () =>
      api.Receivables.Invoices.getInvoices(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: products } = useQuery(
    "getProductsByCompanyId",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoiceTemplateSettingsQuery,
    // isFetching: invoiceTemplateSettingsFetching,
    // isSuccess: invoiceTemplateSettingsSuccess,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  //Update invoice
  const updateInvoiceFunction = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);

  const updateInvoiceMutation = useMutation((event) =>
    updateInvoiceFunction(event)
  );

  // const updateInvoiceStatus = (data) =>
  //   updateInvoiceMutation.mutate(data, {
  //     onSuccess: (data, variables, context) => {
  //       Message({ type: "success", content: "Status changed successfully" });
  //     },
  //     onError: (error, variables, context) => {
  //       Message({ type: "error", content: `${error}` });
  //     },
  //   }
  //   );

  useEffect(() => {
    setPagination(PAGINATION);
  }, [keyword]);

  useEffect(() => {
    if (sort) {
      setPagination(PAGINATION);
    }
  }, [sort]);

  useEffect(() => {
    invoiceQuery &&
      !invoiceQuery?.data?.message &&
      setInvoiceData(
        invoiceQuery?.data.map((invoice) => {
          return {
            number: invoice.invoiceNumber,
            client: invoice.receiverName,
            date: invoice.invoiceDate,
            amount: intToDecimalDollar(invoice.total),
            total: invoice.total,
            status: invoice.invoiceStatus,
            ...invoice,
          };
        })
      );
  }, [invoiceQuery]);

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <span>{moment(date).format()}</span>;
      },
      sorter: (a, b) => {
        return moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b?.date, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (total, record) =>
        intToDecimalDollar(Number(total)) || DEFAULT_AMOUNT,
    },
    //Uncomment line 106 and 330 when reference attribute is ready on backend
    // { title: "Reference", dataIndex: "reference", key: "reference" },
    {
      title: "Status",
      dataIndex: "status",
      key: `status${Math.random()}`,
      render: (status, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {status === "paid" ? (
            <span style={{ color: "#1DBC83" }}>Paid</span>
          ) : (
            <AntSelect
              key={`allInvoiceStatus${record.id}`}
              onClick={(event) => event.stopPropagation()}
              defaultValue={status}
              bordered={false}
              style={{ width: 120 }}
              onChange={(value) => {
                handleStatusChange(value, record);
              }}
              className={classNames.statusSelect}
              loading={updateInvoiceMutation.isLoading}
            >
              {status === "draft" || status === "overdue" ? (
                <>
                  <AntSelect.Option value="draft">
                    <span style={{ color: "#BC7C1D" }}>Draft</span>
                  </AntSelect.Option>
                  <AntSelect.Option value="sent">
                    <span style={{ color: "#7372FF" }}>Sent</span>
                  </AntSelect.Option>
                  {/* <AntSelect.Option value="late">
            <span style={{ color: "#EE6969" }}>Overdue</span>
          </AntSelect.Option> */}
                  <AntSelect.Option value="paid">
                    <span style={{ color: "#1DBC83" }}>Paid</span>
                  </AntSelect.Option>
                </>
              ) : status === "sent" ? (
                <>
                  {/* <AntSelect.Option value="late">
            <span style={{ color: "#EE6969" }}>Overdue</span>
          </AntSelect.Option> */}
                  <AntSelect.Option value="sent">
                    <span style={{ color: "#7372FF" }}>Sent</span>
                  </AntSelect.Option>
                  <AntSelect.Option value="paid">
                    <span style={{ color: "#1DBC83" }}>Paid</span>
                  </AntSelect.Option>
                </>
              ) : (
                ""
              )}

              {/* <AntSelect.Option value="draft">
              <span
                style={{ color: "#BC7C1D" }}
                // onClick={() =>
                //   updateInvoiceStatus({ id: record.id, setInvoiceStatus: "draft" })
                // }
              >
                Draft
              </span>
            </AntSelect.Option>
            <AntSelect.Option value="sent">
              <span
                style={{ color: "#7372FF" }}
                // onClick={() =>
                //   updateInvoiceStatus({ id: record.id, setInvoiceStatus: "sent" })
                // }
              >
                Sent
              </span>
            </AntSelect.Option>
            <AntSelect.Option value="paid">
              <span
                style={{ color: "#1DBC83" }}
                // onClick={() =>
                //   updateInvoiceStatus({
                //     id: record.id,
                //     setInvoiceStatus: "completed",
                //   })
                // }
              >
                Paid
              </span>
            </AntSelect.Option> */}
              {/* <AntSelect.Option value="late">
              <span
                style={{ color: "#EE6969" }}
                onClick={() =>
                  updateInvoiceStatus({ id: record.id, setInvoiceStatus: "late" })
                }
              >
                Overdue
              </span>
            </AntSelect.Option> */}
            </AntSelect>
          )}
          <Space size="middle">
            <RiEditLine
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleInvoiceEditModalButtonClick(record);
              }}
            />
            <RiDeleteBin7Line
              disabled
              size={14}
              color="#9BA3AB"
              className={classNames.actionIcon}
              onClick={(event) => {
                event.stopPropagation();
                setDeleteInvoiceModalVisibility({
                  visible: true,
                  record: record,
                });
              }}
            />
          </Space>
        </Space>
      ),
    },
  ];

  // comment due to future use
  // const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  //   console.log(
  //     `selectedRowKeys: ${selectedRowKeys}`,
  //     "selectedRows: ",
  //     selectedRows
  //   );
  // },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const rowClick = (record) => {
    Object.assign(record, { from: location.pathname });
    navigate(ROUTES.APP.RECEIVABLES.INVOICE.replace(":id", record.number), {
      state: record,
    });
  };

  const renderClientOptions = (data) => {
    const mainData =
      data?.data && data?.data?.length
        ? data?.data
            ?.filter(Boolean)
            ?.filter((item) =>
              invoiceQuery?.data?.some(
                (invoice) => invoice?.receiverName === item?.name
              )
            )
        : [];

    return mainData?.map((item, index) => (
      <Select.Option key={`client${index}`} value={item.name}></Select.Option>
    ));
  };

  // const status = ["All", "Draft", "Sent", "Paid", "Late"];
  const status = [
    { label: "All", value: "" },
    { label: "Draft", value: "Draft" },
    { label: "Sent", value: "Sent" },
    { label: "Paid", value: "Paid" },
    { label: "Overdue", value: "Overdue" },
  ];
  const renderStatusOptions = (data) =>
    data?.map((item) => (
      <Select.Option value={item.value}>{item.label}</Select.Option>
    ));

  useEffect(() => {
    if (!client && !keyword && !statusAttribute) {
      setFilteredInvoiceData(
        invoiceQuery?.data
          .map((invoice) => {
            return {
              key: invoice.id,
              number: invoice.invoiceNumber,
              client: invoice.receiverName,
              date: invoice.invoiceDate,
              amount: intToDecimalDollar(invoice.total),
              total: invoice.total,
              status: invoice.invoiceStatus,
              ...invoice,
            };
          })
          .sort((a, b) => {
            return a.number - b.number;
          })
      );
    }
  }, [client, invoiceQuery?.data, keyword, statusAttribute]);

  useEffect(() => {
    let invoiceQueryDatasource = [];

    if (client && statusAttribute) {
      invoiceQueryDatasource =
        invoiceData &&
        invoiceData.length > 0 &&
        invoiceData
          .filter(
            (obj) =>
              obj.receiverName === client &&
              obj.invoiceStatus ===
                (statusAttribute && statusAttribute.toLowerCase())
          )
          .sort((a, b) => {
            return a.number - b.number;
          });
      setFilteredInvoiceData(invoiceQueryDatasource);
    } else if (statusAttribute || client) {
      invoiceQueryDatasource =
        invoiceData &&
        invoiceData.length > 0 &&
        invoiceData
          .filter(
            (obj) =>
              obj.invoiceStatus ===
                (statusAttribute && statusAttribute.toLowerCase()) ||
              obj.receiverName === client
          )
          .sort((a, b) => {
            return a.number - b.number;
          });
      setFilteredInvoiceData(invoiceQueryDatasource);
    }
  }, [client, statusAttribute, invoiceData]);

  const keywordFilteredArray =
    (keyword
      ? filteredInvoiceData &&
        filteredInvoiceData.length > 0 &&
        filteredInvoiceData.filter((obj) => {
          let filterObj = {
            amount: obj.amount,
            client: obj.client,
            date: obj.date,
            number: obj.number,
            status: obj.status,
            // status: obj.reference
          };
          return Object.values(filterObj)
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      : filteredInvoiceData) || [];

  //Display pagination count text
  let totalNoOfRecords =
    Array.isArray(invoiceData) &&
    invoiceData?.length > 0 &&
    (keyword || client || statusAttribute)
      ? Array.isArray(keywordFilteredArray)
        ? keywordFilteredArray?.length
        : Array.isArray(invoiceData)
        ? invoiceData?.length
        : 0
      : invoiceData.length;
  let maximumNoOfPages = Math.ceil(totalNoOfRecords / pagination.pageSize);
  let noOfRecordsForLastPage =
    totalNoOfRecords % pagination.pageSize > 0
      ? totalNoOfRecords - pagination.pageSize * (maximumNoOfPages - 1)
      : pagination.pageSize;
  let calculateRecordCount =
    pagination.pageSize > totalNoOfRecords
      ? totalNoOfRecords
      : pagination.page === maximumNoOfPages
      ? noOfRecordsForLastPage
      : pagination.pageSize;

  const paginationText =
    totalNoOfRecords === 0
      ? `No invoices to display`
      : `Displaying
      ${calculateRecordCount}
      of ${totalNoOfRecords} ${totalNoOfRecords > 1 ? "invoices" : "invoice"}`;

  const handleClientEvent = (event, isFilter) => {
    if (!event) {
      if (isFilter) {
        setClient("");
      } else {
        setStatusAttribute("");
      }
      setFilteredInvoiceData(
        invoiceQuery?.data
          .map((invoice) => {
            return {
              key: invoice.id,
              id: invoice.id,
              number: invoice.estimateNumber,
              client: invoice.receiverName,
              date: invoice.estimateDate,
              amount: invoice.total,
              total: invoice.total,
              status: invoice.estimateStatus,
              ...invoice,
            };
          })
          .sort((a, b) => {
            return a.number - b.number;
          })
      );

      setPagination(PAGINATION);
    } else {
      setPagination(PAGINATION);
      if (isFilter) {
        setClient(event);
      } else {
        setStatusAttribute(event);
      }
    }
  };

  //Helper
  const getDataSource = () => {
    if (keyword) {
      return (
        filteredInvoiceData &&
        filteredInvoiceData.length > 0 &&
        filteredInvoiceData.filter((obj) => {
          let filterObj = {
            amount: obj.amount,
            client: obj.client,
            date: obj.date,
            number: obj.number,
            status: obj.status,
          };
          return Object.values(filterObj)
            .toString()
            .toLowerCase()
            .includes(keyword.toLowerCase());
        })
      );
    } else {
      return filteredInvoiceData || [];
    }
  };
  return (
    <div className={classNames.wrapper}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
              </div>
            )}
          >
            <div className={classNames.headerWrapper}>
              <Header
                title="All Invoices"
                back
                onClick={() => navigate("../invoices")}
              />
            </div>
            <div className={classNames.layout}>
              <div className={classNames.bodyWrapper}>
                <div className={classNames.topRow}>
                  <div className={classNames.invoiceFilters}>
                    <Input
                      placeholder="Search invoices"
                      prefix={<RiSearch2Line size={26} />}
                      onChange={(event) => {
                        setKeyword(event.target.value);
                        // setPagination(PAGINATION);
                      }}
                      className={classNames.search}
                    />
                    <span style={{ margin: "0 16px" }}>Filter by</span>
                    <Select
                      placeholder="Customers"
                      className={classNames.filterSelect}
                      bordered={false}
                      onChange={(event) => handleClientEvent(event, true)}
                      allowClear
                    >
                      {renderClientOptions(customerQuery)}
                    </Select>
                    <span style={{ margin: "0 16px" }}>Status</span>
                    <Select
                      placeholder={location.state ? "Draft" : "All"}
                      className={classNames.filterSelect}
                      bordered={false}
                      onChange={(event) => handleClientEvent(event, false)}
                      allowClear
                    >
                      {renderStatusOptions(status)}
                    </Select>
                  </div>
                  <Card
                    className={classNames.optionCard}
                    onClick={() => setCreateNewInvoiceModalVisibility(true)}
                  >
                    <div className={classNames.cardAction}>
                      <span>Create Invoice</span>
                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                </div>
                <div className={classNames.body}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "26px 0",
                    }}
                  >
                    {paginationText}
                  </div>
                  <Table
                    loading={isLoadingInvoice}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            keyword && totalNoOfRecords === 0
                              ? "No Result found"
                              : "No Data Found"
                          }
                        />
                      ),
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          rowClick(record);
                        },
                      };
                    }}
                    // scroll={{ y: `var(--table-height)` }}
                    size="large"
                    dataSource={getDataSource()
                      ?.sort((a, b) => b.number - a.number)}
                    columns={columns}
                    className={classNames.table}
                    rowClassName={classNames.actionIcon}
                    onChange={(pagination, filters, sorter) => {
                      setSortChange(sorter?.order);
                    }}
                    pagination={{
                      ...PAGINATION_DEFAULT,
                      current: pagination.page,
                      pageSize: pagination.pageSize,
                      onChange: (page, pageSize) => {
                        setPagination({ page: page, pageSize: pageSize });
                      },
                      itemRender: (page, type, originalElement) => {
                        if (type === "prev") {
                          return (
                            <AntButton style={{ color: "#9f9cff" }}>
                              Prev
                            </AntButton>
                          );
                        }
                        if (type === "next") {
                          return (
                            <AntButton
                              style={{
                                color: "#9f9cff",
                                marginLeft: "32px",
                              }}
                            >
                              Next
                            </AntButton>
                          );
                        }
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <DeleteInvoice
              visible={deleteInvoiceModalVisibility.visible}
              onCancel={handleDeleteInvoiceCancelClick}
              invoice={deleteInvoiceModalVisibility.record}
              invoiceStatus={deleteInvoiceModalVisibility.record?.status}
              setDeleteInvoiceModalVisibility={setDeleteInvoiceModalVisibility}
            />
            <ChangeStatus
              visible={changeStatusModalVisibility}
              status={invoiceStatus}
              onCancel={handleChangeStatusModalCancelClick}
            />
            <CreateNewInvoice
              visible={createNewInvoiceModalVisibility}
              onCancel={handleCreateNewInvoiceModalCancelClick}
              invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
            />
            <EditInvoice
              customers={customerQuery?.data}
              invoice={editInvoiceModalVisibility.record}
              products={products?.data}
              taxRates={taxRates?.data}
              visible={editInvoiceModalVisibility.visible}
              onCancel={handleEditInvoiceCancelClick}
              invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
            />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
}
