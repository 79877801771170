import { API } from "aws-amplify";

export default async function deleteSchedule(data) {
  return await API.put("pa-transaction", `/schedule/edit`, {
    body: {
      data: {
        ...data,
      },
    },
  });
}
