import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button } from "antd";
import { AppContext } from "../../../context/AppState";
import { clearUserData } from "../../../context/actions/userData.ac";
import { clearApplicationData } from "../../../context/actions/application/getApplicationByTenantId.ac";
import { removeValuesFromLocalStorage } from "../../Auth/util";
import { ROUTES } from "../../constants";
import SessionTimeoutStyle from "./SessionTimeout.module.scss";

const forcedLogOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error("error signing out: ", error);
  }
};

const SessionTimeout = () => {
  //Hooks
  const navigate = useNavigate();
  const { userData, dispatchUserData, dispatchApplication } =
    useContext(AppContext);

  useEffect(() => {
    removeValuesFromLocalStorage(userData.email_verified, userData.approved);
    clearUserData()(dispatchUserData);
    clearApplicationData()(dispatchApplication);
    localStorage.removeItem("icupdated");
    forcedLogOut();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Event handlers
  const onLoginClick = () => {
    clearUserData()(dispatchUserData);
    navigate(ROUTES.PUBLIC.LOGIN, { replace: true });
  };

  return (
    <div className={SessionTimeoutStyle.sessionContainer}>
      <h2 className={SessionTimeoutStyle.heading}>Session Expired</h2>
      <p className={SessionTimeoutStyle.info}>
        Since there was no activity detected for 20 minutes, your session got
        expired and we have logged you out.
      </p>
      <span className={SessionTimeoutStyle.link}>
        Click
        <Button type="link" size="large" onClick={onLoginClick}>
          here
        </Button>
        to login again.{" "}
      </span>
    </div>
  );
};

export default SessionTimeout;
