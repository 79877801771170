import { API } from "aws-amplify";

export default async function getStatements(data) {
  if (data?.otz_account_id) {
    return await API.get("statements", "", {
      queryStringParameters: {
        ...data,
      },
    });
  }
}
