// import endpoints from "../endpoints";
import { API } from "aws-amplify";

export default async function getTransactions(params) {
  if (params?.otz_account_id) {
    return await API.get("transactions", "posted", {
      queryStringParameters: params,
    });
  }
}
