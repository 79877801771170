import classNames from "../Customers.module.scss";
import { CTAButton, Input, Select, Message } from "../../../../common";
import { Form, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api from "../../../../API";
import { useState } from "react";
// import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { validation } from "../../../../Auth/config";
import { countryList, usStateList } from "../../../../util";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";

export default function AddNewCustomerForm({
  setAddCustomersCardModalVisibility,
  form,
}) {
  const { Option } = Select;
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  const { Text } = Typography;

  const [changedValues, setChangedValues] = useState({});
  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const createCustomer = (data) => {
    const input = {
      name: data.name,
      email: data.email,
      street1: data?.street1,
      street2: data?.street2,
      zipcode: data?.zipcode,
      city: data.city,
      state: data.state,
      country: data.country,
      currencyIso: data.currencyIso,
      paymentDue: data.paymentDue ? data.paymentDue : 0,
      registrationNumber: data.registrationNumber,
      taxNumber: data.taxNumber,
      countryIso: "",
      enableReminders: false,
      enableOnlinePayment: false,
    };
    return api.Receivables.Customers.createCustomer(
      input,
      currentUserData?.signInUserSession?.idToken?.payload?.icid
    );
  };

  const createMn = useMutation((event) => createCustomer(event));

  const onFinish = (values) => {
    createMn.mutate(values, {
      onSuccess: () => {
        Message({ type: "success", content: language.CUSTOMER_CREATE_SUCCESS });
        queryClient.invalidateQueries("getCustomers");
        form.resetFields();
        setAddCustomersCardModalVisibility(false);
      },
      onError: (error) => {
        const errorObj = error.response.data.error
        for (let key in errorObj) {
          if (errorObj.hasOwnProperty(key)) {
            Message({
              type: "error",
              content: errorObj[key][0]
            });
          }
        }
      },
    });
  };
  return (
    <div className={classNames.modalBody}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        onValuesChange={(values, changedValues) => {
          setChangedValues(changedValues);
        }}
        autoComplete="off"
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label={
            <span className={classNames.label}>
              Customer Name <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            { required: true, message: "Customer name is required" },
            {
              max: MAX_MIN.businessName.max,
              message: `Max ${MAX_MIN.businessName.max} characters`,
            },
          ]}
        >
          <Input placeholder="Please Enter" size="small" />
        </Form.Item>
        <Form.Item
          name="email"
          label={
            <span className={classNames.label}>
              Email Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            { required: true, message: "Email is required" },
            {
              type: "email",
              message: "Enter a valid email address",
            },
            { min: MAX_MIN.email.min, message: validation.email.minLength },
            { max: MAX_MIN.email.max, message: validation.email.maxLength },
          ]}
        >
          <Input placeholder="Please Enter" size="small" />
        </Form.Item>
        <Form.Item
          name="address"
          label={
            <span className={classNames.label}>
              Billing Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
        >
          <Form.Item
            name="street1"
            rules={[
              { required: true, message: validation.street.required },
              { max: MAX_MIN.street.max, message: validation.street.max },
            ]}
          >
            <Input placeholder="Street*" size="small" />
          </Form.Item>
          <Form.Item
            name="street2"
            rules={[
              { max: MAX_MIN.street.max, message: validation.street2.max },
            ]}
          >
            <Input placeholder="Street 2" size="small" />
          </Form.Item>
          {/* <Form.Item
            name="city"
            rules={[{ required: true, message: "City is required" }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="City*"
              size="small"
            >
              <Option value={"Los Angeles"}>Los Angeles</Option>
              <Option value={"California"}> California</Option>
              <Option value={"New York"}>New York</Option>
              <Option value={"Chicago"}>Chicago</Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            name="city"
            rules={[
              { required: true, message: validation.city.required },
              { max: MAX_MIN.city.max, message: validation.city.max },
            ]}
          >
            <Input placeholder="City*" size="small" />
          </Form.Item>

          {/* <Form.Item> */}
          <Form.Item
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            name="state"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[{ required: true, message: validation.state.required }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={validation.state.placeholder}
              size="small"
            >
              {usStateList().map((value) => (
                <Select.Option key={value.code} value={value.code}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="state"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[
              { required: true, message: "State is required" },
              { max: 264, message: "Max 264 characters" },
            ]}
          >
            <Input placeholder="State*" size="small" />
          </Form.Item> */}

          <Form.Item
            name="zipcode"
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
            rules={[
              { required: true, message: validation.postalCode.required },
              {
                pattern: new RegExp(REGEX.postalCode),
                message: validation.postalCode.valid,
              },
            ]}
          >
            <Input placeholder="Postal Code*" size="small" />
          </Form.Item>
        </Form.Item>

        <Form.Item name="country">
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Country"
            size="small"
          >
            {countryList(true).map((value) => (
              <Select.Option key={value.code} value={value.code}>
                {value.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item
          name="country"
          rules={[
            { required: true, message: "Country is required" },
            { max: 264, message: "Max 264 characters" },
          ]}
        >
          <Input placeholder="Country*" size="small" />
        </Form.Item> */}

        <Form.Item>
          <Form.Item
            name="currencyIso"
            label={
              <span className={classNames.label}>
                Currency <sup style={{ top: 1 }}>*</sup>{" "}
              </span>
            }
            rules={[{ required: true, message: "Currency is required" }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            initialValue={"USD"}
          >
            <Select
              placeholder="Please select"
              style={{ width: 180 }}
              size="small"
              // defaultValue={"USD"}
              disabled
            >
              <Option value={"USD"}>USD</Option>
              <Option value={"GBP"}>GBP</Option>
              <Option value={"AED"}>AED</Option>
              <Option value={"UYD"}>UYD</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="paymentDue"
            label={<span className={classNames.label}>Payment Due in</span>}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
            rules={[
              {
                pattern: new RegExp(/^[0-9]\d*$/),
                message: "Enter a valid value",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    form.getFieldValue("paymentDue") &&
                    form.getFieldValue("paymentDue").length > 2
                  ) {
                    return Promise.reject(new Error("Max two characters"));
                  }
                  return Promise.resolve();
                },
              }),
              {
                required: form.getFieldValue("paymentDue") ? false : true,
                message: "Payment due is required",
              },
            ]}
            initialValue={0}
          >
            <Input
              type="number"
              placeholder="Please Enter"
              size="small"
              suffix={<Text type="secondary">Days</Text>}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="taxNumber"
            label={<span className={classNames.label}>EIN Number</span>}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginBottom: "10px",
              float: "left",
            }}
            rules={[
              {
                pattern: new RegExp(/^[0-9]{9}$/),
                message: "Enter a valid EIN",
              },
            ]}
          >
            <Input placeholder="Please Enter" size="small" />
          </Form.Item>
          <Form.Item
            name="registrationNumber"
            label={
              <span className={classNames.label}>Registration Number</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
            }}
            rules={[{ max: 15, message: "Max 16 characters" }]}
          >
            <Input placeholder="Please Enter" size="small" />
          </Form.Item>
        </Form.Item>

        <CTAButton
          htmlType="submit"
          key="submit"
          type="primary"
          style={{ height: 44, width: 384, marginBottom: 20 }}
          loading={createMn.isLoading}
          disabled={
            // changedValues?.name &&
            // changedValues?.email &&
            changedValues?.street1 &&
            changedValues?.city &&
            changedValues?.zipcode &&
            changedValues?.currencyIso &&
            changedValues?.state &&
            changedValues?.country
              ? false
              : true
          }
        >
          Save
        </CTAButton>
      </Form>
    </div>
  );
}
