import classNames from "../ManageCards.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../common";
import api from "../../../API";
import { useMutation, useQueryClient } from "react-query";
import generateErrorMessage from "../../../util/functions/customError";

const CloseCardBody = ({ onCancel, currentCard }) => {
  const queryClient = useQueryClient();

  const closeCardFn = (event) => {
    return api.BusinessBanking.updateCard(
      {
        card_status: "TERMINATED",
        reason: "REQ",
      },
      currentCard?.otz_card_id
    );
  };

  const closeCardMutation = useMutation((event) => closeCardFn(event));

  const closeCardOnclick = (data) => {
    closeCardMutation.mutate(data, {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("listCards");
        Message({ type: "success", content: "Card closed successfully!" });
        onCancel();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Close Card?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to close this card?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
            marginLeft: "10px",
          }}
          loading={!!closeCardMutation.isLoading}
          disabled={!!closeCardMutation.isLoading}
          onClick={() => closeCardOnclick(currentCard?.otz_card_id)}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};

export default function CloseCard({ visible, onCancel, currentCard }) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <CloseCardBody onCancel={onCancel} currentCard={currentCard} />
    </Modal>
  );
}
