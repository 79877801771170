const strings = {
  onboardStatus: {
    warning:
      "Payment acceptance service needs to be activated to use this feature",
  },
  settingsStatus: {
    warning:
      "Please go to Payment Acceptance->Settings and update the Merchant details in order to use this feature",
  },
};

const constants = { strings };

export default constants;
