import classNames from "../Customers.module.scss";
import { Modal } from "../../../../common";
import EditCustomerForm from "./EditCustomerForm";
import { Form } from "antd";

export default function EditProduct({
  visible,
  onCancel,
  data,
  setEditCustomersCardModalVisibility,
}) {
  const [form] = Form.useForm();
  return (
    data && (
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={() => {
          form.resetFields();
          onCancel();
        }}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        title="Edit Customer"
        className={classNames.modal}
        bodyStyle={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          height: "80vh",
        }}
        width={434}
        key={data.key}
      >
        <EditCustomerForm
          form={form}
          data={data}
          onCancel={onCancel}
          setEditCustomersCardModalVisibility={
            setEditCustomersCardModalVisibility
          }
        />
      </Modal>
    )
  );
}
