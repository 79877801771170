import React from "react";

const RegCC = () => {
  return (
    <>
      <div>
        <b>PURPOSE OF THIS DISCLOSURE</b>
        <span>
          . Our general policy is to allow you to withdraw funds deposited in an
          account by the first Business Day after the day of deposit. Funds from
          electronic direct deposits will be available on the day we receive the
          deposit. In some cases, we may delay your ability to withdraw funds
          beyond the first Business Day after the day of deposit. Our complete
          policy is summarized below.
        </span>
      </div>
      <p>
        For purposes of this disclosure, the terms "you" and "your" refer to the
        customer and the terms "our", “we”, "us" refer to Otterz, Inc. The term
        "account" includes any demand deposit, negotiable order of withdrawal
        account, savings deposit, money market account or other non-time deposit
        account
      </p>
      <div>
        <b>DETERMINING THE AVAILABILITY OF YOUR DEPOSIT. </b>
        <span>
          The length of the delay varies depending on the type of deposit and is
          explained below. When we delay your ability to withdraw funds from a
          deposit, you may not withdraw the funds in cash, and we will not pay
          checks you have your account by using these funds. Even after we have
          made these funds available to you and you have withdrawn the funds,
          you are still responsible for checks you deposit that are returned to
          us unpaid and for any other problems involving your deposit.
        </span>
      </div>
      <p>
        When we delay your ability to withdraw funds, the length of the delay is
        counted in Business Days from the clay of your deposit. The term
        "Business Day" means any day other than a Saturday, Sunday or federally
        declared legal holiday, and the term "Banking Day" means that part of
        any Business Day on which we are open to the public for carrying on
        substantially all of our banking functions.
      </p>
      <p>
        If you make a deposit before the close of business on a Business Day
        that we are open, we will consider that day to be the day of your
        deposit. However, if you make a deposit after the close of business or
        on a day that we are not open, we will consider the deposit made on the
        next Business Day we are open.
      </p>
      <div>
        <b>AVAILABILITY SCHEDULE. </b>
        <span>
          Our policy is to make funds from your cash and check deposits
          available to you by the first Business Day after the day we receive
          your deposit. Electronic direct deposits will be available on the day
          we receive the deposit. Once the funds are available, you can withdraw
          them in cash and we will use them to pay checks that you have written.
        </span>
      </div>
      <div>
        <b>LONGER DELAYS MAY APPLY. </b>
        <span>
          In some cases, we will not make all of the funds that you deposit by
          check available to you according to the previously stated availability
          schedule. Depending on the type of check that you deposit, funds may
          not be available until the first Business Day after the day of your
          deposit. The first $225.00 of your deposits, however, may be available
          on the first Business Day after the day of deposit.
        </span>
      </div>
      <p>
        If we are not going to make all of the funds from your deposit available
        to you according to the previously stated availability schedule, we will
        notify you at the time you make your deposit. We will also tell you when
        the funds will be available. If your deposit is not made directly to one
        of our employees, or if we decide to take this action after you have
        left the premises, we will mail you the notice by the day after we
        receive your deposit. If you will need the funds from a deposit right
        away, you should ask us when the funds will be available.
      </p>
      <div>
        Funds you deposit by check may be delayed for a longer period under the
        following circumstances:
        <ol class="lower-alpha">
          <li>
            <span>If we believe a check you deposit will not be paid;</span>
          </li>
          <li>
            <span>
              If you deposit checks totaling more than $5,525 on any one day;
            </span>
          </li>
          <li>
            <span>If you redeposit a check that has been returned unpaid;</span>
          </li>
          <li>
            <span>
              if you have overdrawn your account repeatedly in the last six
              months; or
            </span>
          </li>
          <li>
            <span>
              if an emergency condition arises that would not enable us to make
              the funds available to you, such as the failure of computer or
              communications equipment.
            </span>
          </li>
        </ol>
      </div>
      <p>
        We will notify you if we delay your ability to withdraw funds for any of
        these reasons, and we will tell you when the funds will be available.
        They will generally be available no later than the seventh Business Day
        after the day of your deposit. If you will need the funds from a deposit
        right away, you should ask us when the funds will be available.
      </p>
      <div>
        <b>SPECIAL RULES FOR NEW ACCOUNTS</b>
        <span>
          . If you are a new customer, the following special rules may apply
          during the first 30 days your account is open.
        </span>
      </div>
      <p>
        Funds from electronic direct deposits to your account and checks drawn
        on us will be available on the day we receive the deposit. Funds from
        deposits of cash, wire transfers, and the first $5,525 of a day’s total
        deposits of U.S. Treasury checks, U.S. Postal Service money orders,
        cashier's, certified, teller's, traveler's, and federal, state and local
        government checks will be available by the first Business Day after the
        day of deposit if the deposit meets certain conditions. For example, the
        checks must be payable to you (and you may have to use a special deposit
        slip). The excess over $5,525 will be available by the ninth Business
        Day after the day of your deposit. If your deposit of these checks
        (other than a U.S. Treasury check) is not made in person to one{" "}
        <i>of</i> our employees, the first $5,525 will not be available until
        the first Business Day after the day of deposit.
      </p>
    </>
  );
};

export default RegCC;
