import classNames from "./Title.module.scss";
import PropTypes from "prop-types";

export default function Title({ as: El = "h1", children, className, style }) {
  return (
    <El
      style={{ ...style }}
      className={`${classNames.title} ${classNames[El]} ${className}`}
    >
      {children}
    </El>
  );
}

Title.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  El: PropTypes.element,
};
