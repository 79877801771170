import { Auth } from 'aws-amplify';
import endpoints from '../endpoints';

export default async function getDocumentFile(obj){
    let env = process.env.REACT_APP_ENV?.trim();

    const url = endpoints.ONBOARDING.GET_DOCUMENT_FILE[env]+obj.id;

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`
            }
        })
    )
}
