import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";

import { Select, Input, CountryPicker } from "../../common";

import { usStateList } from "../../util";
import REGEX from "../../util/constants/regex";
import { MAX_MIN } from "../../util/constants";

export default function AddressForm({
  countrySelected,
  validation,
  onCountryChange,
  name,
  disabled,
  required,
}) {
  return (
    <>
      <Form.Item
        name={[name, "street"]}
        rules={[
          { required: required, message: validation.street.required },
          { max: MAX_MIN.street.max, message: validation.street.max },
        ]}
      >
        <Input disabled={disabled} placeholder={validation.street.placeholder} />
      </Form.Item>
      <Form.Item
        name={[name, "street2"]}
        rules={[{ max: MAX_MIN.street.max, message: validation.street2.max }]}
      >
        <Input disabled={disabled} placeholder={validation.street2.placeholder} />
      </Form.Item>
      <Form.Item
        name={[name, "city"]}
        rules={[
          { required: required, message: validation.city.required },
          { max: MAX_MIN.city.max, message: validation.city.max },
        ]}
      >
        <Input disabled={disabled} placeholder={validation.city.placeholder} />
      </Form.Item>
      <Form.Item>
        <Form.Item
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          name={[name, "state"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            marginBottom: "10px",
            float: "left",
          }}
          rules={[{ required: required, message: validation.state.required }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={validation.state.placeholder}
            disabled={disabled}
          >
            {usStateList().map((value) => (
              <Select.Option key={value.code} value={value.code}>
                {value.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={[name, "postalCode"]}
          style={{
            display: "inline-block",
            width: "calc(50% - 4px)",
            margin: "0 0 0 4px",
            float: "right",
          }}
          rules={[
            { required: required, message: validation.postalCode.required },
            {
              pattern: new RegExp(REGEX.postalCode),
              message: validation.postalCode.valid,
            },
          ]}
        >
          <Input disabled={disabled} placeholder={validation.postalCode.placeholder} />
        </Form.Item>
      </Form.Item>

      <Form.Item
        name={[name, "country"]}
        rules={[{ required: required, message: validation.country.required }]}
      >
        <CountryPicker
          disabled={disabled}
          placeholder={validation.country.placeholder}
          onChange={(code) => {
            onCountryChange(code);
          }}
        />
      </Form.Item>
    </>
  );
}

AddressForm.propTypes = {
  countrySelected: PropTypes.string,
  validation: PropTypes.object,
  onCountryChange: PropTypes.func,
};
