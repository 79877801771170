import { Form, Select } from "antd";
import { useEffect, useState, useContext } from "react"; // Import useState and useEffect
import { useMutation } from "react-query";
import { validation } from "../config";
import { AppContext } from "../../../context/AppState";
import classNames from "./UserProfile.module.scss";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";

const proxyTexts = [
  "Incoming ACH Transactions",
  "Outgoing ACH Transactions",
  "Incoming/Outgoing Wires (Domestic)",
  "Incoming/Outgoing Wires (International)",
  "Remote Deposits",
  "Debit Card Transactions",
];

// Define a mapping from text to metadata keys
const metadataKeyMapping = {
  "Incoming ACH Transactions": {
    amountKey: "incoming_ach_transaction_amt",
    countKey: "incoming_ach_transaction_count",
  },
  "Outgoing ACH Transactions": {
    amountKey: "outgoing_ach_transaction_amt",
    countKey: "outgoing_ach_transaction_count",
  },
  "Incoming/Outgoing Wires (Domestic)": {
    amountKey: "wire_transaction_domestic_amt",
    countKey: "wire_transaction_domestic_count",
  },
  "Incoming/Outgoing Wires (International)": {
    amountKey: "wire_transaction_inter_amt",
    countKey: "wire_transaction_inter_count",
  },
  "Remote Deposits": {
    amountKey: "remote_deposits_amt",
    countKey: "remote_deposits_count",
  },
  "Debit Card Transactions": {
    amountKey: "debit_card_transactions_amt",
    countKey: "debit_card_transactions_count",
  },
};

export default function AccountActivityForm({
  form,
  onFinish,
  setUserProfileFile,
  userData,
  userProfileData,
  applicationData,
  isBusiness,
}) {
  const [estimatedAmountOptions, ] = useState([
    "Less than $1,000",
    "$1,000 - $5,000",
    "$5,001 - $10,000",
    "$10,001 - $25,000",
    "More than $25,000",
  ]);

  const [estimatedTransactionsOptions, ] = useState([
    "1 - 10",
    "11 - 50",
    "51 - 100",
    "101 - 200",
    "More than 200",
  ]);

  const { application } = useContext(AppContext);
  const getBusiness = useMutation((data) =>
    api.BusinessBanking.getBusiness(data)
  );

  useEffect(() => {
    // Make an API call to get business details
    getBusiness.mutate(application?.otz_business_id, {
      onSuccess: (response) => {
        const metadata = response?.payload?.metadata;

        if (metadata) {
          // Set the initial values for the Select components
          const initialValues = {};
          proxyTexts.forEach((text, index) => {
            initialValues[`estimatedAmount${index}`] = metadata[metadataKeyMapping[text].amountKey];
            initialValues[`estimatedTransactions${index}`] = metadata[metadataKeyMapping[text].countKey];
          });
          form.setFieldsValue(initialValues);
        }
      },
      onError: (error) => {
        generateErrorMessage(error);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      className={classNames.accountActivityForm}
    >
      <table className={classNames.table}>
        <thead>
          <tr>
            <th></th>
            <th>Estimated $ amount / month</th>
            <th>Estimated # of transactions / month</th>
          </tr>
        </thead>
        <tbody>
          {proxyTexts.map((text, index) => (
            <tr key={index}>
              <td>
                <span>{text}</span>
              </td>
              <td>
                <Form.Item
                  name={`estimatedAmount${index}`}
                  rules={[
                    {
                      required: true,
                      message: validation.estimatedAmount.required,
                    },
                  ]}
                >
                  <Select placeholder="Select an option">
                    {estimatedAmountOptions.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </td>
              <td>
                <Form.Item
                  name={`estimatedTransactions${index}`}
                  rules={[
                    {
                      required: true,
                      message: validation.estimatedTransactions.required,
                    },
                  ]}
                >
                  <Select placeholder="Select an option">
                    {estimatedTransactionsOptions.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classNames.formRow}>
        {/* Your CTAButton or submit button can be placed here */}
      </div>
    </Form>
  );
}
