import {
  AccountAgreement,
  CardholderAgreement,
  ACHAuthorization,
  ESignConsent,
  RegCC,
  RegDD,
  RegE,
  USAPatriotAct,
  PrivacyNotice,
} from "./components";

export const OTZ_INFO =
  "Otterz is a financial technology company and not a bank. Banking services are provided by Otterz’s partner banks who are member FDIC.   Mastercard debit cards are issued by Otterz’s partner banks and may  be used everywhere Mastercard debit cards are accepted.";

export const DISCLOSURES_TYPE = [
  {
    param: "Account Terms and Conditions",
    name: "Account Terms and Conditions",
    subTitle: "COMMERCIAL CHECKING ACCOUNT AGREEMENT",
    link: `https://www.otterz.co/terms-and-conditions`,
    value: "TandC",
    content: <AccountAgreement />,
  },
  {
    param: "Cardholder Agreement",
    name: "Cardholder Agreement",
    subTitle: "OTTERZ BUSINESS DEBIT CARD ACCOUNT AGREEMENT",
    link: `https://www.otterz.co/terms-of-service`,
    value: "Agreement",
    content: <CardholderAgreement />,
  },
  {
    param: "Privacy Notice",
    name: "Privacy Notice (Reg P)",
    subTitle: "PRIVACY COMPLIANCE POLICY",
    link: `https://www.otterz.co/privacy-policy`,
    value: "RegP",
    content: <PrivacyNotice />,
  },
  {
    param: "ACH Authorization",
    name: "ACH Authorization & Agreement Disclosure",
    subTitle: "CONSUMER ACH AUTHORIZATION AND AGREEMENT",
    link: `https://www.otterz.co/terms-of-service`,
    value: "Authorization",
    content: <ACHAuthorization />,
  },
  {
    param: "USA Patriot Act",
    name: "USA Patriot Act (KYC) Disclosure",
    subTitle: "CONSUMER ACH AUTHORIZATION AND AGREEMENT",
    link: `https://www.otterz.co/terms-of-service`,
    value: "Patriot",
    content: <USAPatriotAct />,
  },
  {
    param: "E-Sign Consent",
    name: "E-Sign Consent Disclosure",
    subTitle: "E-SIGN CONSENT DISCLOSURE",
    link: `https://www.otterz.co/terms-of-service`,
    value: "Consent",
    content: <ESignConsent />,
  },
  {
    param: "Error Resolution",
    name: "Error Resolution Disclosure / *Reg E Only",
    subTitle: "IN CASE OF ERRORS OR QUESTIONS ABOUT YOUR ELECTRONIC TRANSFERS ",
    link: `https://www.otterz.co/terms-of-service`,
    value: "RegE",
    content: <RegE />,
  },
  {
    param: "Reg CC",
    name: "Reg CC (Fund Availability) Disclosure",
    subTitle: "FUNDS AVAILABILITY AGREEMENT",
    link: `https://www.otterz.co/terms-of-service`,
    value: "RegCC",
    content: <RegCC />,
  },
  {
    param: "Reg DD",
    name: "Reg DD (Truth in Savings Act) Disclosure",
    subTitle: "TRUTH IN SAVINGS – Reg DD Disclosure",
    link: `${new URL(window.location.href).origin}/disclosure`,
    value: "RegDD",
    content: <RegDD />,
  },
];
