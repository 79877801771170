import { Card, Progress, Spin, Tooltip, Modal } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import {
  RiAccountBoxLine,
  // RiArrowDownSFill,
  // RiArrowLeftCircleLine,
  RiArrowLeftDownLine,
  RiArrowLeftRightFill,
  RiArrowRightCircleLine,
  RiArrowRightLine,
  RiArrowRightUpLine,
  // RiArrowUpSFill,
  RiBankLine,
  RiBillLine,
  // RiEqualizerLine,
  RiSecurePaymentLine,
} from "react-icons/ri";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppState";
import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import api from "../../API";
import { Title } from "../../common";
import { ROUTES, STATUS, DISCLOSURE_TYPE } from "../../constants";
import ROLES from "../../constants/scopesToRoles/roles";
import { toastPAOnboardStatusWarning } from "../../Messages/paymentAcceptance";
import PaymentRequests from "../../Receivables/PaymentAcceptance/PaymentAcceptanceOverview/PaymentRequests/PaymentRequests";
import { centsToDollarWithoutSign } from "../../util/functions/dollarConvertion";
import classNames from "./LandingPage.module.scss";
import NotificationCard from "./NotificationCard/NotificationCard";
import UsersCard from "./UsersCard/UsersCard";
import Settings from "../../Receivables/PaymentAcceptance/PaymentAcceptanceOverview/Settings/Settings";
import AccountActivityModal from "../../BusinessBanking/config/accountActivityModal";
import DisclosureModal from "./DisclosureModal/DisclosureModal";
import generateErrorMessage from "../../util/functions/customError";
import DisclosureCheckModal from "./DisclosureCheckModal/DisclosureCheckModal";

export default function LandingPage() {
  const navigate = useNavigate();

  const { icidStatus: invoiceStatus, status: invoiceActive } =
    useInvoiceActiveStatus();

  const [paymentRequests, setPaymentRequests] = useState(false);

  const [subscriptionDetails, setSubscriptionDetails] = useState([{}]);
  // const [planType, setPlanType] = useState("free");
  const [accountData, setAccountData] = useState();
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const [isPASettingsAvailable, setPASettingsAvailability] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookkeeperModalVisible, setBookkeeperModalVisible] = useState(false);
  const [accountActivityVisible, setAccountActivityVisible] = useState(false);
  const [disclosureModalVisible, setDisclosureModalVisible] = useState(null);
  const [disclosureCheckModalVisible, setDisclosureCheckModalVisible] = useState(null);
  const [boData, setBOData] = useState([]);

  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] = useState(true);

  const openHubSpotScheduler = (tag) => {
    if(tag === "sales") {
      setIsModalVisible(true);
    } else {
      setBookkeeperModalVisible(true)
    }
  }

  const closeModal = (tag) => {
    if(tag === "sales") {
      setIsModalVisible(false);
    } else {
      setBookkeeperModalVisible(false)
    }
  }

  const handleSettingsCancelClick = () => {
    setSettingsVisibility(false);
  };

  const handlePaymentRequestsModalCancelClick = () => {
    setPaymentRequests(false);
  };

  const { userData, application } = useContext(AppContext);

  localStorage.removeItem("OTTERZ.application");

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: userByIdData } = useQuery(
    "getUserById",
    () => api.Settings.getUserById(),
    {
      refetchOnWindowFocus: false,
      enabled:
        currentUserData?.signInUserSession?.idToken?.payload?.roleName !==
        ROLES.OWNER,
    }
  );

  //This should move to backend later. Did frontend calculation as this is time consuming
  const { data: invoiceQuery, isFetching: isLoadingInvoices } = useQuery(
    "getInvoices",
    () =>
      api.Receivables.Invoices.getInvoices(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: estimateQuery, isFetching: isLoadingEstimates } = useQuery(
    "getEstimates",
    () =>
      api.Receivables?.Estimates.listEstimates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const getTransactions = useMutation((data) =>
    api.BusinessBanking.getTransactions(data)
  );

  useEffect(() => {
    if((!disclosureCheckModalVisible && !disclosureModalVisible) && !(disclosureCheckModalVisible === null && disclosureModalVisible === null)) {
      getTransactions.mutate({
        otz_account_id: application?.otz_account_id,
        type: "all"
      },{
        onSuccess: (response) => {
          // Check for incoming ACH transactions in the response
          const hasIncomingACHTransaction = response?.payload.some((transaction) => {
            return transaction.transfer_type === "ACH" && transaction.dc_sign === "credit";
          });
          // If at least one incoming ACH transaction is found, set accountActivityVisible to true
          if((!disclosureCheckModalVisible && !disclosureModalVisible) && !(disclosureCheckModalVisible === null && disclosureModalVisible === null)) {
            if (hasIncomingACHTransaction) {
              setAccountActivityVisible(true);
            }
          }
        },
        onError: (error) => {
          generateErrorMessage(error);
        }
      });
    } else {
      setAccountActivityVisible(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosureCheckModalVisible, disclosureModalVisible])
  

  useQuery(
    "getAccount",
    () => api.BusinessBanking.getAccount(application?.otz_account_id),
    {
      onSuccess: (data) => {
        setAccountData(data);
  
        // Calculate the active time of the account
        const creationTime = new Date(data.payload.creation_time);
        const currentTime = new Date();
        const timeDifference = currentTime - creationTime;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
  
        // Check if the active time is greater than or equal to a month (30 days)
        if(!disclosureCheckModalVisible && !disclosureModalVisible) {
          if (daysDifference >= 30) {
            if(isAccountInfoNeeded) {
              setAccountActivityVisible(true);
            }
          }
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    "getDisclosure",
    () => api.onBoarding.getDisclosure(),
    {
      onSuccess: (data) => {
  
        // Check if 'OWNER_CERTIFICATION' is present in disclosures
        const hasOwnerCertification = data.payload.disclosures.some(disclosure => disclosure.type === "OWNER_CERTIFICATION");
        
        // If 'OWNER_CERTIFICATION' is not present, set the modal visibility to true
        if (!hasOwnerCertification) {
          setDisclosureCheckModalVisible(true);
        } else {
          setDisclosureCheckModalVisible(false);
        }
      },
      refetchOnWindowFocus: false,
    }
  );  

  const createDisclosure = useMutation((data) => {
    return api.onBoarding.createDisclosure(data);
  });

  const beneficialOwnerDetailMutation = useMutation((id) => {
    return api.onBoarding.getBusinessPersonDetail({ otz_to_business_id: id });
  });

  // const [isLoadingBOData, setIsLoadingBOData] = useState(true);
  const { data: applicationData } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: async (data) => {
        if (boData.length === 0) {
          beneficialOwnerDetailMutation.mutate(data?.payload?.otz_business_id, {
            onSuccess: (res) => {
              setBOData(res?.payload);
              // setIsLoadingBOData(false);
            },
          });
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleConfirmOwnershipSubmit = async (selectedOption) => {
    // Close the Confirm Ownership Modal
    // If the user wants to add more beneficial owners, open the BABeneficialOwner modal
    if (selectedOption === 2) {
      setDisclosureCheckModalVisible(false);
      setDisclosureModalVisible(true);
    }
    if (selectedOption === 1) {

      const reqPayload = {
        // event_type: "ACKNOWLEDGED",
        disclosure_date: new Date().toJSON(),
        // person_id: data.payload?.from_person_id,
        acknowledging_person_id: boData[0]?.otz_person_id,
        otz_business_id: applicationData?.payload?.otz_business_id,
        type: [DISCLOSURE_TYPE.OWNER_CERTIFICATION],
        // version: "1.0"
      };
      
      await createDisclosure.mutate(reqPayload);
      setDisclosureModalVisible(false);
      // setDisclosureCheckModalVisible(false);
    }
  };

  const { data: statementPayload } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: application?.otz_account_id,
      }),
    {
      refetchOnWindowFocus: true,
    }
  );

  let draftInvoicesArray =
    invoiceQuery &&
    invoiceQuery.data &&
    invoiceQuery?.data.length &&
    invoiceQuery.data
      .filter((value, index) => value?.invoiceStatus === "draft")
      .map((value, index) => Number(value.total));

  let totalDraftInvoiceArray =
    draftInvoicesArray &&
    draftInvoicesArray?.length > 0 &&
    draftInvoicesArray?.reduce((prev, next) => prev + next);

  let draftEstimatesArray =
    estimateQuery &&
    estimateQuery?.data &&
    estimateQuery?.data.length &&
    estimateQuery?.data
      .filter((value, index) => value?.estimateStatus === "draft")
      .map((value, index) => value.total);

  let totalDraftEstimateArray =
    draftEstimatesArray &&
    draftEstimatesArray.length > 0 &&
    draftEstimatesArray.reduce((prev, next) => prev + next);

  //This should move to backend later. Did frontend calculation as this is time consuming
  const {
    data: listScheduledTransactionsPayload,
    isFetching: isScheduledTransactionsLoading,
  } = useQuery(
    "getScheduledTransactions",
    () => api.BusinessBanking.getTempCustomPayments(),
    { refetchOnWindowFocus: false }
  );

  let scheduledTransactionsArray =
    Array.isArray(listScheduledTransactionsPayload?.data?.data) &&
    listScheduledTransactionsPayload?.data?.data.length > 0 &&
    listScheduledTransactionsPayload?.data?.data
      .filter(
        (value) =>
          value.paymentDate === moment().format("YYYY-MM-DD").toString()
      )
      .map((value) => value.amount);

  let totalScheduledTransactions =
    scheduledTransactionsArray &&
    Array.isArray(scheduledTransactionsArray) &&
    scheduledTransactionsArray.length > 0 &&
    scheduledTransactionsArray.reduce((prev, next) => +prev + +next);

  // fetching PA status
  const [paStatus, setPAStatus] = useState(false);
  const {
    data: onboardRequest,
    isSuccess: onboardRequestSuccess,
    isLoading: paOnboardRequestLoading,
  } = useQuery(
    "getOnboardRequest",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const tenantId = userData?.tenantId;

  const { isLoading: getPASettingsLoading = false } = useQuery(
    "getPASettings",
    () =>
      api.Receivables.PaymentAcceptance.getPASettings({
        tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!tenantId,
      onSuccess: (res) => {
        setPASettingsAvailability(!!res?.data?.companyName);
      },
    }
  );

  useEffect(() => {
    setPAStatus(
      onboardRequestSuccess &&
        onboardRequest?.data?.onboardStatus === STATUS.APPROVED
    );
  }, [onboardRequestSuccess, onboardRequest]);

  const notifications = [
    {
      id: 1,
      title: "No. of Scheduled Payments Today",
      count: Array.isArray(scheduledTransactionsArray)
        ? scheduledTransactionsArray?.length
        : 0,
      amount: totalScheduledTransactions
        ? centsToDollarWithoutSign(totalScheduledTransactions)
        : "0.00",
      disable: false,
      path: [ROUTES.APP.BUSINESS_BANKING.MAKE_PAYMENTS],
      isLoading: isScheduledTransactionsLoading,
    },

    {
      id: 2,
      title: "Unsent Draft Invoices",
      count: Array.isArray(draftInvoicesArray) ? draftInvoicesArray?.length : 0,
      amount: totalDraftInvoiceArray
        ? centsToDollarWithoutSign(totalDraftInvoiceArray?.toFixed(2) * 100)
        : "0.00",
      disable: false,
      path: !invoiceStatus
        ? [ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED]
        : [ROUTES.APP.RECEIVABLES.ALL_INVOICES, "Draft"],
      isLoading: isLoadingInvoices,
    },
    {
      id: 3,
      title: "Unsent Draft Estimates",
      count: Array.isArray(draftEstimatesArray)
        ? draftEstimatesArray?.length
        : 0,
      amount: totalDraftEstimateArray
        ? centsToDollarWithoutSign(totalDraftEstimateArray?.toFixed(2) * 100)
        : "0.00",
      disable: false,
      path: !invoiceStatus
        ? [ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED]
        : [ROUTES.APP.RECEIVABLES.ALL_ESTIMATES, "Draft"],
      isLoading: isLoadingEstimates,
    },
    {
      id: 4,
      title: "Vendor Invoices Awaiting Approval",
      count: "0",
      amount: "0.00",
      disable: true,
      path: ["#"],
    },
    // {
    //   id: 5,
    //   title: "Expense Reports Awaiting Approval",
    //   count: "0",
    //   amount: "0.00",
    //   disable: true,
    //   path: ["#"],
    // },
    {
      id: 5,
      title: "Overdue Vendor Invoices",
      count: "0",
      amount: "0.00",
      disable: true,
      path: ["#"],
    },
  ];

  useQuery(
    "getCurrentSubscriptionPlan",
    () => api.Settings.getCurrentSubscriptionPlan(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setSubscriptionDetails(response?.payload?.data);
        // setPlanType(response?.payload?.plan_type);
      },
    }
  );

  let integrations = [
    {
      text: "Banking",
      subText: null,
      icon: (
        <RiBankLine
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active:
        // (userData.active === "true" || userData.active === true) &&
        // (userData.approved === "true" || userData.approved === true)
        accountData?.payload?.access_status === "ACTIVE" ? true : false,
      link: ROUTES.APP.BUSINESS_BANKING.OVERVIEW,
    },
    // {
    //   text: "Cashback Rate",
    //   //need to add the cashback rate once the endpoint is done
    //   subText: `${subscriptionDetails?.rewards_percentage || "0.00"}%`,
    //   icon: (
    //     <RiArrowLeftCircleLine
    //       color="#9BA3AB"
    //       size={20}
    //       className={classNames.statusIcon}
    //     />
    //   ),
    //   active: true,
    //   link: ROUTES.APP.REWARDS.OVERVIEW,
    // },
    {
      text: "Invoicing",
      subText: null,
      icon: (
        <RiBillLine
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active:
        invoiceActive && (subscriptionDetails?.receivable?.invoices || true),
      link: !invoiceStatus
        ? ROUTES.APP.RECEIVABLES.INVOICES_GETTING_STARTED
        : ROUTES.APP.RECEIVABLES.INVOICES,
    },
    {
      text: "Payment Acceptance",
      subText: null,
      icon: (
        <RiArrowRightCircleLine
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active: paStatus && subscriptionDetails?.receivable?.payment_acceptance,
      link: ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE,
    },
    {
      text: "Accounts Payable",
      subText: null,
      icon: (
        <RiSecurePaymentLine
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active: false,
    },
    {
      text: "Account Sync",
      subText: null,
      icon: (
        <RiArrowLeftRightFill
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active: false,
    },
    // {
    //   text: "Expense Management",
    //   subText: null,
    //   icon: (
    //     <RiEqualizerLine
    //       color="#9BA3AB"
    //       size={20}
    //       className={classNames.statusIcon}
    //     />
    //   ),
    //   active: false,
    // },
    {
      text: "Account Status",
      subText: null,
      icon: (
        <RiAccountBoxLine
          color="#9BA3AB"
          size={20}
          className={classNames.statusIcon}
        />
      ),
      active: accountData?.payload?.access_status === "ACTIVE" ? true : false,
      link: ROUTES.APP.SETTINGS.INDEX,
    },
  ];

  function calculateIntegrationPercentage(integrationsArray) {
    if (!Array.isArray(integrations)) {
      return 0;
    }
    let activeIntegrationsArray = integrations.filter(
      (value, index) => value.active === true
    );
    return (activeIntegrationsArray.length / integrations.length) * 100;
  }

  calculateIntegrationPercentage();

  const onSendPaymentRequestClicked = () => {
    if (!paStatus) {
      return toastPAOnboardStatusWarning();
    }
    if (!isPASettingsAvailable) {
      setSettingsVisibility(true);
    } else {
      setPaymentRequests(true);
    }
  };

  return (
    <>
      <div className={classNames.wrapper}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.layoutLeft}>
              <div className={classNames.headerTopLeft}>
                <Title as="h1">
                  Welcome,{" "}
                  {currentUserData?.signInUserSession?.idToken?.payload
                    ?.roleName === ROLES.OWNER
                    ? application?.username
                    : userByIdData?.data?.username}
                </Title>
                <div>
                  <span>Here are your business highlights for</span>
                  <span> {moment().format("ddd D MMMM  YYYY")}</span>
                </div>
              </div>
              <Card className={classNames.detailsCardWrapper}>
                <div className={classNames.detailsCardTop}>
                  <div>
                    <span style={{ color: "#E5E7EB" }}>
                      Bank Available Balance
                    </span>
                    <div>
                      <span>
                        <sup style={{ top: -25, marginRight: "2px" }}>$</sup>
                      </span>
                      <span>
                        {
                          // TEMP || Needs to be replaced with calculated value
                          centsToDollarWithoutSign(
                            statementPayload?.payload?.total_available_balance
                          ) || "0.00"
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classNames.tableCardBody}>
                  <Card className={classNames.valueCard}>
                    <div className={classNames.incomingFunds}>
                      <div className={classNames.amount}>
                        <Title as="h3">Incoming Funds</Title>
                        <div>
                          <span>
                            <sup style={{ top: -2, marginRight: "1px" }}>$</sup>
                          </span>
                          {centsToDollarWithoutSign(
                            statementPayload?.payload?.total_deposits
                          ) || "0.00"}
                        </div>
                        {/* <span>
                        {Number(depositPercentage)
                          ? `${depositPercentage.toFixed(2)}% `
                          : "0% -"}
                        {Number(depositPercentage) > 0 ? (
                          <RiArrowUpSFill
                            size={18}
                            style={{ color: "#1DBC83" }}
                            hidden={!Number(depositPercentage)}
                          />
                        ) : (
                          <RiArrowDownSFill
                            size={18}
                            style={{ color: "#EE6969" }}
                            hidden={!Number(depositPercentage)}
                          />
                        )}
                      </span> */}
                      </div>
                      <div className={classNames.icon}>
                        <RiArrowLeftDownLine size={26} />
                      </div>
                    </div>

                    <div className={classNames.outgoingFunds}>
                      <div className={classNames.amount}>
                        <Title as="h3">Outgoing Funds</Title>

                        <div>
                          <span>
                            <sup style={{ top: -2, marginRight: "1px" }}>$</sup>
                          </span>
                          {centsToDollarWithoutSign(
                            statementPayload?.payload?.total_withdrawals
                          ) || "0.00"}
                        </div>
                        {/* <span>
                        {Number(withdrawPercentage)
                          ? `${withdrawPercentage.toFixed(2)}% `
                          : "0% -"}
                        {Number(withdrawPercentage) > 0 ? (
                          <RiArrowUpSFill
                            size={18}
                            style={{ color: "#EE6969" }}
                            hidden={!Number(withdrawPercentage)}
                          />
                        ) : (
                          <RiArrowDownSFill
                            size={18}
                            style={{ color: "#1DBC83" }}
                            hidden={!Number(withdrawPercentage)}
                          />
                        )}
                      </span> */}
                      </div>
                      <div className={classNames.icon}>
                        <RiArrowRightUpLine size={26} />
                      </div>
                    </div>

                    <div className={classNames.cashflow}>
                      <div className={classNames.amount}>
                        <Title as="h3">Cashflow</Title>
                        <div>
                          <span>
                            <sup style={{ top: -2, marginRight: "1px" }}>$</sup>
                          </span>
                          {Number(
                            statementPayload?.payload?.total_deposits -
                              statementPayload?.payload?.total_withdrawals
                          )
                            ? centsToDollarWithoutSign(
                                statementPayload?.payload?.total_deposits -
                                  statementPayload?.payload?.total_withdrawals
                              )
                            : "0.00"}
                        </div>
                        {/* <span>
                        {Number(cashflowPercentage)
                          ? `${cashflowPercentage.toFixed(2)}% `
                          : "0% -"}
                        {Number(cashflowPercentage) > 0 ? (
                          <RiArrowUpSFill
                            size={18}
                            style={{ color: "#1DBC83" }}
                            hidden={!Number(cashflowPercentage)}
                          />
                        ) : (
                          <RiArrowDownSFill
                            size={18}
                            style={{ color: "#EE6969" }}
                            hidden={!Number(cashflowPercentage)}
                          />
                        )}
                      </span> */}
                      </div>
                      {Number(
                        statementPayload?.payload?.total_deposits -
                          statementPayload?.payload?.total_withdrawals
                      ) > 0 ? (
                        <div className={classNames.iconIncoming}>
                          <RiArrowLeftDownLine size={26} />
                        </div>
                      ) : (
                        <div className={classNames.iconOutgoing}>
                          <RiArrowRightUpLine size={26} />
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </Card>
              <div className={classNames.notificationWrapper}>
                <Title as="h3"> Notifications</Title>
                <div className={classNames.middleRow}>
                  {notifications.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      title={notification.title}
                      count={notification.count}
                      amount={notification.amount}
                      onClick={notification.onClick}
                      disable={notification.disable}
                      isLoading={notification.isLoading}
                      roleName={
                        currentUserData?.signInUserSession?.idToken?.payload
                          ?.roleName
                      }
                      path={notification.path}
                    />
                  ))}
                </div>
              </div>
              <Spin
                spinning={paOnboardRequestLoading || getPASettingsLoading}
                size="default"
              >
                <div className={classNames.bottomRow}>
                  <Card
                    className={classNames.optionCardLight}
                    onClick={onSendPaymentRequestClicked}
                  >
                    <RiArrowRightCircleLine
                      color="#7372ff"
                      size={26}
                      className={classNames.optionIcon}
                    />
                    <div className={classNames.optionIconBackdropLight} />
                    <div className={classNames.cardAction}>
                      <span>Send Payment Request</span>

                      <RiArrowRightLine
                        size={26}
                        className={classNames.arrowBtnWithBg}
                      />
                    </div>
                  </Card>
                  <UsersCard />
                </div>
              </Spin>
            </div>
          </div>
          <div className={classNames.layoutRight}>
            <div className={classNames.headerTopRight}>
              {/* POST MVP */}

              {/* <Dropdown overlay={menu}>
              <Button shape="round" icon={<RiNotification2Line size={20} />}>
                <RiArrowDropDownLine />
              </Button>
            </Dropdown> */}
            </div>
            <div className={classNames.integrationsHeader}>
              <Title as="h4">Otterz Integrations</Title>
              <Progress
                strokeColor="#1DBC83"
                percent={Number(calculateIntegrationPercentage())}
                showInfo={false}
              />
              <div>
                <span>
                  {parseInt(Number(calculateIntegrationPercentage()))}%
                </span>
                <div>
                  <span>used of </span>
                  <span>{integrations?.length} integrations</span>
                </div>
              </div>
            </div>
            {Array.isArray(integrations) &&
              integrations.map((value, index) => {
                const isComingSoon = !value.link;
                const card = (
                  <Card
                    key={index}
                    className={`${classNames.statusCard} ${
                      isComingSoon && classNames.disabled
                    }`}
                    data-coming-soon={isComingSoon}
                    onClick={() => value.link && navigate(value.link)}
                  >
                    <div className={classNames.cardAction}>
                      <div>
                        {value.icon}
                        <span className={classNames.optionIconBackdropLight} />
                        <span>{value.text}</span>
                      </div>
                      {value.subText ? (
                        <span>{value.subText}</span>
                      ) : value.active ? (
                        <span style={{ color: "#1DBC83" }}>
                          <BsDot size={21} />
                          Active
                        </span>
                      ) : (
                        <span style={{ color: "#82898F" }}>Inactive</span>
                      )}
                    </div>
                  </Card>
                );
                return isComingSoon ? (
                  <Tooltip key={index} title="Coming soon">
                    {card}
                  </Tooltip>
                ) : (
                  card
                );
              })}

              {
                (subscriptionDetails?.plan_name === "elite" || subscriptionDetails?.plan_name === "elite_annual") && (
                  // <div className={classNames.cardAction} onClick={openHubSpotScheduler}>
                  //     <div className={classNames.cardText}>
                  //         <span>Call your bookkeeper</span>
                  //     </div>
                  //     <span className={classNames.arrow}>→</span>
                  // </div>

                  <Card
                  // style={{marginTop: "5vh"}}
                  className={`${classNames.statusCard}`}
                  onClick={()=>{openHubSpotScheduler("bookkeeper")}}
                  >
                  <div className={classNames.cardAction}>
                    <div>
                      {/* {value.icon} */}
                      <span className={classNames.optionIconBackdropLight} />
                      <span>Call your bookkeeper</span>
                    </div>
                    {/* {value.subText ? (
                      <span>{value.subText}</span>
                    ) : value.active ? (
                      <span style={{ color: "#1DBC83" }}>
                        <BsDot size={21} />
                        Active
                      </span>
                    ) : (
                      <span style={{ color: "#82898F" }}>Inactive</span>
                    )} */}
                    <div style={{backgroundColor: "#7372FF", borderRadius: "50%", width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <span style={{color: "white"}} className={classNames.arrow}>→</span>
                    </div>
                  </div>
                  </Card>
                )
              }

              {/* <div className={classNames.card} onClick={() => {openHubSpotScheduler("sales")}}>
                  <div className={classNames.cardText}>
                      <span style={{fontWeight: "600"}}>Manage Books & Taxes</span>
                      <small style={{color: "white"}}>Book a call with us</small>
                  </div>
                  <div style={{backgroundColor: "white", borderRadius: "50%", width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <span style={{color: "#7372FF"}} className={classNames.arrow}>→</span>
                  </div>
              </div> */}

              <Modal
                title="Books & Taxes - Book a call with us"
                visible={isModalVisible}
                onCancel={()=>{closeModal("sales")}}
                footer={null}
                width={800}
            >
                <iframe
                    src="https://meetings.hubspot.com/vvuorisalo?embed=true"
                    width="100%"
                    height="480"
                    frameBorder="0"
                    title="HubSpotMeetingScheduler"
                ></iframe>
            </Modal>

            <Modal
                title="Books & Taxes - Book a call with us"
                visible={bookkeeperModalVisible}
                onCancel={()=>{closeModal("bookkeeper")}}
                footer={null}
                width={800}
            >
                <iframe
                    src="https://meetings.hubspot.com/jay-patel11?embed=true"
                    width="100%"
                    height="480"
                    frameBorder="0"
                    title="HubSpotMeetingScheduler-Bookkeeper"
                ></iframe>
            </Modal>

            {/* <Card className={classNames.subscriptionPlanCard}>
            <div className={classNames.subscriptionHeading}>Current Plan</div>
            <div className={classNames.cardAction}>
              <div>
                <span>Otterz Basic</span>
                <div>
                  <span>{planType === "paid" ? "Pro" : "Free"}</span>
                </div>
              </div>
              <AntButton
                shape="round"
                onClick={() => {
                  navigate(ROUTES.APP.SETTINGS.PLAN_PREFERENCES);
                }}
              >
                {planType === "paid" ? "Downgrade" : "Upgrade"}
              </AntButton>
            </div>
          </Card> */}

            <PaymentRequests
              visible={paymentRequests}
              onCancel={handlePaymentRequestsModalCancelClick}
            />
          </div>
        </div>
      </div>
      {settingsVisibility && (
        <Settings
          visible={settingsVisibility}
          onCancel={handleSettingsCancelClick}
          setPASettingsAvailability={setPASettingsAvailability}
        />
      )}
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={null}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        spawnModal={setAccountActivityVisible}
        title="Incoming ACH Transactions"
      />
      <DisclosureModal 
        modalVisible={disclosureModalVisible}
        onClose={() => {
          setDisclosureModalVisible(false)
          setDisclosureCheckModalVisible(true)
        }}
      />
      <DisclosureCheckModal 
        isVisible={disclosureCheckModalVisible}
        onSubmit={handleConfirmOwnershipSubmit} 
        onClose={setDisclosureCheckModalVisible}
        onConfirm={() => {
          setDisclosureModalVisible(false)
          setDisclosureCheckModalVisible(false)
        }}
      />
    </>
  );
}
