import classNames from "../ManageCards.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import api from "../../../API";
import { useMutation, useQueryClient } from "react-query";

const FreezeFormBody = ({ onCancel, currentCard }) => {
  const queryClient = useQueryClient();

  const cardFreezeFn = (event) => {
    return api.BusinessBanking.updateCard(
      { card_status: "SUSPENDED", reason: "REQ" },
      currentCard?.otz_card_id
    );
  };

  const cardFreezeMutation = useMutation((event) => cardFreezeFn(event));

  const cardFreezeOnclick = (data) => {
    cardFreezeMutation.mutate(data, {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("listCards");
        Message({ type: "success", content: "Card successfully frozen" });
        onCancel();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Freeze Card?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to freeze this card?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
            marginLeft: "5px",
          }}
          loading={!!cardFreezeMutation.isLoading}
          disabled={!!cardFreezeMutation.isLoading}
          onClick={() => cardFreezeOnclick(currentCard?.otz_card_id)}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};

export default function FreezeCard({ visible, onCancel, freeze, currentCard }) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <FreezeFormBody
        freeze={freeze}
        onCancel={onCancel}
        currentCard={currentCard}
      />
    </Modal>
  );
}
