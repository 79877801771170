import classNames from "./AddNewTransactions.module.scss";
import { Modal } from "../../../../common";
import AddNewTransactionsForm from "./AddNewTransactionsForm";
import React, { useState } from "react";
import ConfirmModel from "../ConfirmModal/ConfirmModel";
import { Form } from "antd";
export default function AddNewTransactions({
  visible,
  onCancel,
  title = "Add New Transaction",
  fetchTransaction,
  customer,
  onTransactionModelClose,
  existCustomer,
  isCustomerPage,
}) {
  const [confirmModel, setConfirmModel] = useState(false);
  const handleConfirmModelCancelClick = () => {
    setConfirmModel(false);
  };
  const [form] = Form.useForm();

  return (
    <>
      <Modal
        closable={true}
        visible={visible}
        maskClosable={false}
        onCloseClick={() => {
          onCancel ? onCancel() : onTransactionModelClose();
          form.resetFields();
        }}
        onCancel={onCancel}
        title={title}
        className={classNames.modal}
        destroyOnClose={true}
        bodyStyle={{
          height: "70vh",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
        width={866}
      >
        <AddNewTransactionsForm
          title={title}
          onCancel={onCancel ? onCancel : onTransactionModelClose}
          fetchTransaction={fetchTransaction}
          form={form}
          customer={customer}
          existCustomer={existCustomer}
          isCustomerPage={isCustomerPage}
        />
      </Modal>
      <ConfirmModel
        visible={confirmModel}
        onCancel={handleConfirmModelCancelClick}
      />
    </>
  );
}
