import { API } from "aws-amplify";

export default async function getCustomers(id) {
  if (id) {
    return await API.get("invoices", "customers", {
      queryStringParameters: {
        // this should be replaced
        companyId: id,
      },
    });
  }
}
