const strings = {
  en: {
    paymentSuccessMessage: "Payment created successfully!",
    paymentErrorMessage: "An error has occurred!",
    recPaymentSuccessMessage: "New Recurring Payment created successfully!",
    payLaterSuccessMessage: "New Pay Later Payment created successfully!",
    rejectionReasons: {
      DailyACHCreditLimitExceeded:
        "The amount exceeds your ACH limit, please contact support.",
    },
    amountRequiredMessage: "Amount is required",
    enterValidAmount: "Enter a valid amount",
  },
};

export { strings };