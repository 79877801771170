import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { Card, Button as AntButton, Table, Spin, Radio, Space } from "antd";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import WalletLineIcon from "remixicon-react/WalletLineIcon";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import { Header, Title, Button, TypeTag, Input } from "../../common";
import CustomDateFilter from "../CustomDateFilter/CustomDateFilter";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import { centsToDollarWithoutSign } from "../../util/functions/dollarConvertion";
import { PAGINATION, PAGINATION_DEFAULT } from "../../util/constants";
import { FORMATS } from "../../constants";
import classNames from "./RewardsOverview.module.scss";

export const FILTERS = {
  SETTLED: "SETTLED",
  UNSETTLED: "UNSETTLED",
};

const FILTER_TYPES = {
  PERIOD: "period",
  CUSTOM: "custom",
};

export default function RewardsOverview() {
  //Hooks
  const [rewardList, setRewardList] = useState([]);
  const [settledCashback, setSettledCashback] = useState(0.0);
  const [unsettledCashback, setUnsettledCashback] = useState(0.0);
  const [percentage, setPercentage] = useState();
  const { application } = useContext(AppContext);
  const [pagination, setPagination] = useState(PAGINATION);
  const [mainFilter, setMainFilter] = useState(FILTERS.UNSETTLED);
  const [selectedValue, setSelectedValue] = useState("d");
  const [keyword, setKeyword] = useState();
  const [filterModalVisibility, setFilterModalVisibility] = useState(false);

  const LIMIT = 5000;
  const currentDate = new Date();
  const nextDay = new Date().setDate(currentDate.getDate() + 1);
  const monthStartDate = new Date(
    new Date(nextDay).setMonth(currentDate.getMonth() - 1)
  );

  const defaultPaginate = {
    limit: LIMIT,
    otz_account_id: application?.otz_account_id,
  };

  const [queryParams, setQueryParams] = useState({
    ...defaultPaginate,
    filterType: FILTER_TYPES.PERIOD,
    from_date: moment(monthStartDate).format(FORMATS.API_SEND_FORMAT),
  });

  //Api calls
  const {
    refetch: cashbackRefetch,
    isLoading,
    isFetching,
  } = useQuery(
    ["getRewardsDetails", queryParams],
    () => {
      const param = prepareQueryParam();
      return api.Rewards.cashbackHistory(param);
    },
    {
      onSuccess: (response) => {
        if (response?.payload) {
          setPercentage(response?.payload?.currentPercentage);
          handleRewardHistory(response?.payload?.data);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  const {
    isLoading: isTotalLoading,
    isFetching: isTotalFetching,
    refetch: cashbackTotalRefetch,
  } = useQuery(
    "getRewardsList",
    () =>
      api.Rewards.cashbackHistory({
        otz_account_id: application?.otz_account_id,
      }),
    {
      onSuccess: (response) => {
        if (response?.payload) {
          calculateTotalCashback(response?.payload?.data);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    setSelectedValue("d");
  }, [mainFilter]);

  useEffect(() => {
    cashbackRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, mainFilter]);

  //Event handlers
  const handleFilterModalCancelClick = () => {
    setFilterModalVisibility(false);
  };

  const setCustomQueryParams = (customSearch) => {
    setSelectedValue("g");
    setQueryParams({
      ...defaultPaginate,
      ...customSearch,
    });
  };

  //JSX Element
  const columns = [
    // {
    //   title: "Description",
    //   dataIndex: "transaction",
    //   key: "transaction",
    // },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => text,
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
          b?.date,
          FORMATS.DATEPICKER_FORMAT
        );
      },
    },
    {
      title: "Transfer Type",
      dataIndex: "transferType",
      key: "transferType",
      render: (type) => {
        return (
          <span style={{ display: "flex", justifyContent: "flex-start" }}>
            <TypeTag type={type} />
          </span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) =>
        record?.dc_sign === "credit" ? (
          <span style={{ color: "#1DBC83" }}>${amount} </span>
        ) : (
          <span style={{ color: "#EE6969" }}>${amount || "0.00"}</span>
        ),
    },
    {
      title: "Cashback",
      dataIndex: "cashback",
      key: "cashback",
      render: (cashback) => (
        <span style={{ color: "#1DBC83" }}>${cashback || "0.00"}</span>
      ),
    },
    {
      title: "Cashback Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (percentage) => (
        <span style={{ color: "#1DBC83" }}>{percentage || "0"}%</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "UNSETTLED") {
          return <span style={{ color: "#BC7C1D" }}>{status}</span>;
        } else {
          return <span style={{ color: "#1DBC83" }}>{status}</span>;
        }
      },
    },
  ];

  //Helper
  const prepareQueryParam = () => {
    const param = {
      ...queryParams,
    };

    if (queryParams.type) {
      param.type = queryParams?.type;
    }
    if (queryParams.filterType === FILTER_TYPES.PERIOD) {
      param.from_date = dateFilter(queryParams);
      param.to_date = moment(currentDate).format(FORMATS.API_SEND_FORMAT);
      delete param?.period;
      delete param?.type;
    }

    delete param?.filterType;

    return param;
  };

  function dateFilter(paramList) {
    switch (paramList?.period) {
      case "day":
        return moment(currentDate).format(FORMATS.API_SEND_FORMAT);

      case "week":
        const weekStartDate = new Date(
          new Date(currentDate).setDate(currentDate.getDate() - 6)
        );
        return moment(weekStartDate).format(FORMATS.API_SEND_FORMAT);

      case "sixMonth":
        const monthStart = new Date(
          new Date(nextDay).setMonth(currentDate.getMonth() - 6)
        );
        return moment(monthStart).format(FORMATS.API_SEND_FORMAT);

      case "year":
        const yearStartDate = new Date(
          new Date(nextDay).setFullYear(currentDate.getFullYear() - 1)
        );
        return moment(yearStartDate).format(FORMATS.API_SEND_FORMAT);

      default:
        const monthStartDate = new Date(
          new Date(nextDay).setMonth(currentDate.getMonth() - 1)
        );
        return moment(monthStartDate).format(FORMATS.API_SEND_FORMAT);
    }
  }

  const handleRewardHistory = (rewardListData) => {
    if (Array.isArray(rewardListData)) {
      const tempRewardList = rewardListData
        ?.map((reward, index) => {
          return {
            key: index,
            transaction: reward?.memo || "-",
            date: moment(reward?.date)?.format(FORMATS.DATE_TIME_FORMAT),
            transferType: reward?.transfer_type,
            cashback: centsToDollarWithoutSign(reward?.cashback),
            status: reward?.status,
            dc_sign: reward?.dc_sign,
            amount: centsToDollarWithoutSign(reward?.debitAmount),
            percentage: reward?.percentage,
          };
        })
        ?.sort((a, b) =>
          moment(b?.date, FORMATS.DATE_TIME_FORMAT).diff(
            moment(a?.date, FORMATS.DATE_TIME_FORMAT)
          )
        );

      setRewardList(tempRewardList);
    }
  };

  const calculateTotalCashback = (totalRewardListData) => {
    if (Array.isArray(totalRewardListData)) {
      const tempRewardList = totalRewardListData
        ?.map((reward, index) => {
          return {
            key: index,
            transaction: reward?.memo || "-",
            date: moment(reward?.date)?.format(FORMATS.DATE_TIME_FORMAT),
            transferType: reward?.transfer_type,
            cashback: centsToDollarWithoutSign(reward?.cashback),
            status: reward?.status,
            dc_sign: reward?.dc_sign,
            amount: centsToDollarWithoutSign(reward?.debitAmount),
            percentage: reward?.percentage,
          };
        })
        ?.sort((a, b) =>
          moment(b?.date, FORMATS.DATE_TIME_FORMAT).diff(
            moment(a?.date, FORMATS.DATE_TIME_FORMAT)
          )
        );

      const totalUnsettledCashback = tempRewardList
        ?.filter((reward) => reward?.status === "UNSETTLED")
        ?.reduce((previousValue, currItem) => {
          return previousValue + Number(currItem?.cashback || 0);
        }, 0);
      setUnsettledCashback(parseFloat(totalUnsettledCashback)?.toFixed(2));

      const totalSettledCashback = tempRewardList
        ?.filter((reward) => reward?.status === "SETTLED")
        ?.reduce((previousValue, currItem) => {
          return previousValue + Number(currItem?.cashback || 0);
        }, 0);

      setSettledCashback(parseFloat(totalSettledCashback)?.toFixed(2));
    }
  };

  const getCustomQueryParams = () => queryParams;

  const getApiStatus = (isForTotal) => {
    if (isForTotal) {
      return isTotalFetching || isTotalLoading;
    } else {
      return isFetching || isLoading;
    }
  };

  const getFilteredRewardList = () => {
    const filteredRewardList = rewardList?.filter(
      (reward) => reward?.status === mainFilter
    );
    if (keyword) {
      return Object.values(filteredRewardList)
        .toString()
        ?.toLowerCase()
        .includes(keyword?.toLowerCase());
    } else {
      return filteredRewardList;
    }
  };

  return (
    <>
      <div className={classNames.wrapper}>
        <div className={classNames.headerWrapper}>
          <Header title="Rewards" />
        </div>
        <div className={classNames.layout}>
          <div className={classNames.bodyWrapper}>
            <div className={classNames.topRow}>
              <Card className={classNames.cashbackCard}>
                <div className={classNames.totalCashback}>
                  <Title as="h4">Settled Cashback</Title>
                  <div className={classNames.amount}>
                    {getApiStatus(true) ? (
                      <Spin style={{ marginTop: 10 }} />
                    ) : (
                      <>
                        <span>$</span>
                        <div>{settledCashback || "00.00"}</div>
                      </>
                    )}
                    {/* <Button
                      shape="circle"
                      disabled={!isLoading && isFetching}
                      icon={
                        !isLoading && isFetching ? (
                          <Spin size={10} />
                        ) : (
                          <RefreshLineIcon size={20} />
                        )
                      }
                      className={classNames.refreshBtn}
                      style={{ width: 48, height: 48, marginLeft: 20 }}
                      onClick={() => cashbackTotalRefetch()}
                    /> */}
                  </div>
                </div>
                <div className={classNames.totalCashback}>
                  <Title as="h4">Unsettled Cashback</Title>
                  <div className={classNames.amount}>
                    {getApiStatus(true) ? (
                      <Spin style={{ marginTop: 10 }} />
                    ) : (
                      <>
                        <span>$</span>
                        <div>{unsettledCashback || "00.00"}</div>
                      </>
                    )}
                    <Button
                      shape="circle"
                      disabled={!isTotalFetching && isTotalLoading}
                      icon={
                        !isTotalLoading && isTotalFetching ? (
                          <Spin size={10} />
                        ) : (
                          <RefreshLineIcon size={20} />
                        )
                      }
                      className={classNames.refreshBtn}
                      style={{ width: 48, height: 48, marginLeft: 20 }}
                      onClick={() => cashbackTotalRefetch()}
                    />
                  </div>
                </div>

                <div className={classNames.previousPayout}>
                  <div>
                    <span className={classNames.icon}>
                      <WalletLineIcon size={18} />
                    </span>
                    Currently applicable cashback percentage
                    {/* Cashback percentage you earn for all your transactions */}
                  </div>
                  <div>
                    {getApiStatus(false) ? (
                      <Spin />
                    ) : (
                      <span>{percentage || 0}%</span>
                    )}
                  </div>
                </div>
              </Card>
            </div>

            <div className={classNames.body}>
              <div className={classNames.rewardFilters}>
                <Input
                  placeholder={
                    mainFilter === FILTERS.SETTLED
                      ? "Search settled rewards"
                      : "Search unsettled rewards"
                  }
                  prefix={<Search2LineIcon />}
                  className={classNames.searchReward}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setPagination(PAGINATION);
                  }}
                />
                {getApiStatus(false) ? null : (
                  <Card className={classNames.filtersCard}>
                    <div className={classNames.filtersGroup}>
                      <Radio.Group
                        defaultValue={"d"}
                        buttonStyle="solid"
                        value={selectedValue}
                        onChange={(e) => {
                          if (e.target.value !== "g") {
                            setSelectedValue(e.target.value);
                          }
                        }}
                      >
                        <Space size={0}>
                          <Radio.Button
                            value="b"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "day",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1D
                          </Radio.Button>
                          <Radio.Button
                            value="c"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "week",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1W
                          </Radio.Button>
                          <Radio.Button
                            value="d"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "month",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            1M
                          </Radio.Button>
                          <Radio.Button
                            value="e"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "sixMonth",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            6M
                          </Radio.Button>
                          <Radio.Button
                            value="f"
                            onClick={() => {
                              setQueryParams((queryParams) => ({
                                ...queryParams,
                                filterType: FILTER_TYPES.PERIOD,
                                period: "year",
                                ...defaultPaginate,
                              }));
                            }}
                          >
                            Y
                          </Radio.Button>
                          <Radio.Button
                            value="g"
                            onClick={() => setFilterModalVisibility(true)}
                          >
                            <CalendarLineIcon size={12} />
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>
                  </Card>
                )}
                <div className={classNames.actionRow}>
                  <div className={classNames.actions}>
                    <div className={classNames.transactionStatusFilters}>
                      <Radio.Group>
                        <Space size="small">
                          <Radio.Button
                            name="tabs"
                            className={
                              mainFilter === FILTERS.SETTLED
                                ? classNames.tabRadioButtonFocused
                                : classNames.tabRadioButton
                            }
                            onClick={() => {
                              if (mainFilter !== FILTERS.SETTLED) {
                                setMainFilter(FILTERS.SETTLED);
                                setSelectedValue("d");
                                setQueryParams({
                                  ...queryParams,
                                  ...defaultPaginate,
                                  filterType: FILTER_TYPES.PERIOD,
                                  period: "month",
                                });
                              }
                            }}
                          >
                            SETTLED
                          </Radio.Button>
                          <Radio.Button
                            name="tabs"
                            className={
                              mainFilter === FILTERS.UNSETTLED
                                ? classNames.tabRadioButtonFocused
                                : classNames.tabRadioButton
                            }
                            onClick={() => {
                              if (mainFilter !== FILTERS.UNSETTLED) {
                                setMainFilter(FILTERS.UNSETTLED);
                                setSelectedValue("d");
                                setQueryParams({
                                  ...queryParams,
                                  ...defaultPaginate,
                                  filterType: FILTER_TYPES.PERIOD,
                                  period: "month",
                                });
                              }
                            }}
                          >
                            UNSETTLED
                          </Radio.Button>
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames.tableCardRight}>
                <div className={classNames.tableCardHeader}>
                  <Title as="h3" style={{ padding: "16px 0 12px 20px" }}>
                    Cashback History
                  </Title>
                </div>
                <Table
                  // scroll={{ y: `var(--table-height)` }}
                  pagination={{
                    ...PAGINATION_DEFAULT,
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    onChange: (page, pageSize) => {
                      setPagination({ page: page, pageSize: pageSize });
                    },
                    itemRender: (page, type, originalElement) => {
                      if (type === "prev") {
                        return (
                          <AntButton
                            style={{ color: "#9f9cff", marginRight: 10 }}
                          >
                            Prev
                          </AntButton>
                        );
                      }
                      if (type === "next") {
                        return (
                          <AntButton style={{ color: "#9f9cff" }}>
                            Next
                          </AntButton>
                        );
                      }
                    },
                  }}
                  loading={getApiStatus(false)}
                  dataSource={Boolean(rewardList) && getFilteredRewardList()}
                  columns={columns}
                  className={classNames.table}
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDateFilter
        visible={filterModalVisibility}
        onCancel={handleFilterModalCancelClick}
        setCustomQueryParams={setCustomQueryParams}
        mainFilter={mainFilter}
        getCustomQueryParams={getCustomQueryParams}
      />
    </>
  );
}
