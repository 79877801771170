import { Empty } from "antd";
import EmptyImage from "../../../assets/common/Empty/NoResultFound.svg";

function NoResult({ imgHeight = 300, description = "Nothing found" }) {
  return (
    <div style={{ margin: "40px 0 0 0" }}>
      <Empty
        image={EmptyImage}
        imageStyle={{
          height: imgHeight,
        }}
        description={
          <span style={{ fontWeight: 700, fontSize: "20px" }}>
            {description}
          </span>
        }
      />
    </div>
  );
}

export default NoResult;
