import { Card } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Modal } from "../../../common";
import CreditCard from "./CreditCard/CreditCard";
import EnterOTP from "../EnterOTP";
import cardTypes from "./cardTypes.data";
import MasterCardImg from "../../../../assets/Mastercard.svg";
import CardInfo from "./CardInfo/CardInfo";

SwiperCore.use([EffectCoverflow, Pagination]);

function Carousel({
  cards,
  classNames,
  activeSlide,
  currentCard,
  accountType,
  scriptLoaded,
  accountPayload,
  setActiveSlide,
  showDisplayOTP,
  setShowDisplayOTP,
  verificationToken,
  customerBearerToken,
  verficationTokenMutation,
  createCustomerBearerToken,
  refetchCreateVerificationToken,
  setSetSpendLimitModalVisibility,
  customerDetail,
  hideCardDetail,
}) {
  // const [cardUsage, setCardUsage] = useState();
  // const [cardLimits, setCardLimits] = useState();
  // const [limitData, setLimitData] = useState();

  // const { isLoading } = useQuery(
  //   ["getLimits", currentCard?.id],
  //   () => api.BusinessBanking.getCardLimits(currentCard?.id),
  //   {
  //     onSuccess: (data) => {
  //       setLimitData(data.data.data.attributes);
  //     },
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // useEffect(() => {
  //   setCardUsage({
  //     dailyUsage: Math.round(limitData?.dailyTotals?.purchases),
  //     monthlyUsage: Math.round(limitData?.monthlyTotals?.purchases),
  //   });

  //   let dailyPurchases = 0;
  //   let monthlyPurchases = 0;

  //   if (
  //     limitData &&
  //     Object.keys(limitData?.limits).filter((key) => key.includes("Purchase"))
  //       .length > 0
  //   ) {
  //     dailyPurchases = limitData?.limits?.dailyPurchase;
  //     monthlyPurchases = limitData?.limits?.monthlyPurchase;

  //     setCardLimits({
  //       dailyPurchases,
  //       monthlyPurchases,
  //     });
  //   } else {
  //     setCardLimits({ msg: "Not Defined" });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentCard?.id, activeSlide, limitData]);

  let cardData =
    cards &&
    cards.length > 0 &&
    cards.map((value, index) => {
      return {
        id: value.otz_card_id,
        cardType: value.type,
        cardNumber: `**** **** **** ${value?.last_four}`,
        cardHolder: value?.emboss_name?.line_1,
        expiryDate: `${value?.expiration_month}/${value?.expiration_year}`,
        cvv: "***",
        cardStatus: value?.card_status,
        cardIssuer:
          value?.card_brand === "MASTERCARD"
            ? MasterCardImg
            : "https://www.logo.wine/a/logo/Visa_Inc./Visa_Inc.-Logo.wine.svg",
        bgColor: cardTypes[value?.form]?.bgColor,
        cardForm: value?.form,
        // editLimit: () => setSetSpendLimitModalVisibility(true),
        ...customerDetail,
      };
    });

  return (
    <Card className={classNames.tableCard}>
      {/*  {currentCard && (
        <SlideHeader
          currentCard={currentCard}
          classNames={classNames}
          status={cardData[activeSlide].status}
        />
      )} */}
      <div style={{ height: "48px" }}></div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        coverflowEffect={{
          rotate: 0,
          stretch: -150,
          depth: 400,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          bulletClass: classNames.carouselDot,
          renderBullet: (index, className) => {
            return '<div class="' + className + '"></div>';
          },
        }}
        slidesPerGroup={1}
        style={{ width: "100%" }}
        onSlideChange={(slide) => {
          setActiveSlide(slide.activeIndex);
        }}
      >
        {cardData.map((item) => (
          <SwiperSlide className={classNames.swiperSlide} key={item.id}>
            <div className={classNames.carouselItemCreditCard}>
              {/* TODO ==> REMOVE excess props */}
              <CreditCard
                card={item}
                key={`creditcard-${item.otz_card_id}`}
                activeSlide={activeSlide}
                classNames={classNames}
                scriptLoaded={scriptLoaded}
                verificationToken={verificationToken}
                customerBearerToken={customerBearerToken}
                verficationTokenMutation={verficationTokenMutation}
                createCustomerBearerToken={createCustomerBearerToken}
                refetchCreateVerificationToken={refetchCreateVerificationToken}
                showDisplayOTP={showDisplayOTP}
                setShowDisplayOTP={setShowDisplayOTP}
                hideCardDetail={hideCardDetail}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Spin spinning={isLoading}> */}
      <CardInfo
        // usage={cardUsage}
        // limit={cardLimits}
        classNames={classNames}
        currentCard={currentCard}
        // status={cardData[activeSlide].status}
        cardType={cardData[activeSlide].cardType}
        // editLimit={cardData[activeSlide].editLimit}
        cardHolder={cardData[activeSlide].cardHolder}
      />
      {/* <div className={classNames.tableCardFooter}>
          <div
            className="swiper-pagination"
            style={{ display: "flex", flexDirection: "row" }}
          />
        </div> */}
      {/* </Spin> */}
      {/* <div className={classNames.noCardsContainer}></div> */}

      <Modal
        destroyOnClose
        closable={true}
        visible={showDisplayOTP}
        maskClosable={false}
        onCloseClick={() => setShowDisplayOTP(false)}
        onCancel={() => setShowDisplayOTP(false)}
        title="Display Sensitive Data"
        className={classNames.modal}
        bodyStyle={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
        }}
        width={434}
      >
        <div className={classNames.modalBody}>
          <EnterOTP
            createCustomerBearerToken={createCustomerBearerToken}
            verficationTokenMutation={verficationTokenMutation}
            verificationToken={verificationToken}
          />
        </div>
      </Modal>
    </Card>
  );
}

export default Carousel;
