import { API } from "aws-amplify";

export default async function updateOfficerBusinessRelationship(data, id) {
  if (id) {
    return await API.post("businessRelationship", `managing-person/${id}`, {
      body: {
        ...data,
      },
    });
  }
}
