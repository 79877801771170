import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form, Spin } from "antd";

import { AppContext } from "../../../../context/AppState";

import { Modal, CTAButton, Input, Select, Message } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import lowerCase from "../../../util/functions/lowerCase";
import api from "../../../API";

import classNames from "../UserManagement.module.scss";
import { MAX_MIN } from "../../../util/constants";
import { validation } from "../../../Auth/config";
import REGEX from "../../../util/constants/regex";

export default function AddUser({ visible, onCancel, form, users }) {
  // const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  const queryClient = useQueryClient();

  const { application, userData } = useContext(AppContext);

  const { data: applicationData } = useQuery(
    "getApplicationData",
    () =>
      api.Settings.getUnitApplication(
        application.application?.data?.data?.applicationId
      ),
    {
      refetchOnWindowFocus: false,
      enabled:
        application?.application?.data?.data?.applicationId !== undefined,
    }
  );

  const customerType =
    applicationData?.data?.data?.relationships?.customer?.data?.type;

  const userEmailsArray =
    Array.isArray(users) && users.length > 0
      ? users.map((value) => value.email)
      : [];
  // add admin users email to the array as admins only can
  // visit this page.
  // Each otterz account currently have one admin user
  // Currently the authorizationTable does not store "owners"
  // email in the database.
  userEmailsArray.push(userData?.email);

  const { mutate, isLoading } = useMutation(api.onBoarding.inviteUser, {
    onSuccess: () => {
      Message({ type: "success", content: "Invitation sent successfully" });
      onCancel();
      queryClient.invalidateQueries("getChildUsersByTenantId");
      form.resetFields();
    },
    onError: (error) => generateErrorMessage(error),
  });

  function handleSubmit() {
    form
      .validateFields()
      .then(() => {
        mutate({
          data: {
            email: lowerCase(form.getFieldValue("email").toString()),
            role: form.getFieldValue("role"),
            username: form.getFieldValue("username"),
            customerType,
          },
        });
      })
      .catch(() => {
        return;
      });
  }

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const renderRoles = () => {
    return (
      <>
        <Select.Option
          label={<span>Accounting User</span>}
          value="accountingUser"
          key={"accounting-index"}
          className={classNames.payeeSelection}
          size="small"
        >
          <div>
            <span>Accounting User</span>
          </div>
          <div>
            <span className={classNames.roleSelectionDetails}>
              Team member with accounting access
            </span>
          </div>
        </Select.Option>
        <Select.Option
          label={<span>Invoicing User</span>}
          value="invoicingUser"
          key={"invoicing-index"}
          className={classNames.payeeSelection}
          size="small"
        >
          <div>
            <span>Invoicing User</span>
          </div>
          <div>
            <span className={classNames.roleSelectionDetails}>
              Team member with invoicing access
            </span>
          </div>
        </Select.Option>

        {/* Need this role after MVP */}

        {/* <Select.Option
          label={<span>Expense User</span>}
          value="expenseUser"
          key={"expense-index"}
          className={classNames.payeeSelection}
          size="small"
        >
          <div>
            <span>Expense User</span>
          </div>
          <div>
            <span className={classNames.roleSelectionDetails}>
              Team member with expense access
            </span>
          </div>
        </Select.Option> */}
      </>
    );
  };
  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Add New User"
      className={classNames.modal}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <div className={classNames.modalBody}>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label={
                <span className={classNames.label}>
                  User Name <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name="username"
              rules={[
                { required: true, message: "Username is required" },
                {
                  pattern: new RegExp(REGEX.name),
                  message: "Enter a valid company name",
                },

                {
                  max: MAX_MIN.businessName.max,
                  message: `Max ${MAX_MIN.businessName.max} characters`,
                },
              ]}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>

            <Form.Item
              label={
                <span className={classNames.label}>
                  Email Address <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name="email"
              rules={[
                { required: true, message: "Email address is required" },
                { type: "email", message: "Enter a valid email address" },
                { min: MAX_MIN.email.min, message: validation.email.minLength },
                { max: MAX_MIN.email.max, message: validation.email.maxLength },
                () => ({
                  validator(_, value) {
                    if (value && userEmailsArray.includes(value)) {
                      return Promise.reject(
                        new Error(
                          "Unable to invite the child user. An account with this email already exists"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder="Please enter" size="small" />
            </Form.Item>
            <Form.Item
              label={
                <span className={classNames.label}>
                  Role <sup style={{ top: 1 }}>*</sup>
                </span>
              }
              name="role"
              rules={[{ required: true, message: "Role is required" }]}
            >
              <Select
                optionLabelProp="label"
                size="small"
                placeholder="Please select"
              >
                {renderRoles()}
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <CTAButton
                  type="primary"
                  style={{ height: 44, width: 384, marginTop: "42px" }}
                  onClick={handleSubmit}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  Send Invitation
                </CTAButton>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
}
