import moment from "moment";
import { FORMATS } from "../constants";

const TIME_ZONE = {
  EST: { zone: "America/New_York", offset: 4.0 },
};

// const toMMDDYYYYHHmmss = (momentObj) =>
//   moment.utc(momentObj).local().format(FORMATS.DATE_TIME_FORMAT);

const toMMDDYYYYHHmmss = (momentObj) =>
  momentObj.format(FORMATS.DATE_TIME_FORMAT);
const toMMDDYYYY = (momentObj) => momentObj.format(FORMATS.API_OTHER_FORMAT);
const toddddMMMMDYYYY = (momentObj) => momentObj.format("dddd, MMMM D YYYY");
const toMMMMYYYY = (momentObj) => momentObj.format("MMMM YYYY");

const localToTimezone = (
  date,
  timezone = false,
  format = FORMATS.DATEPICKER_FORMAT
) => {
  if (timezone) {
    const timeZone = TIME_ZONE[timezone]?.zone;
    return moment.utc(date).tz(timeZone).format(format);
  }
  return moment.utc(date).format(format);
};

const tzToLocal = (
  date,
  timezone = false,
  format = FORMATS.DATEPICKER_FORMAT
) => {
  if (timezone) {
    const timeZoneOffset = TIME_ZONE[timezone]?.offset;
    const inputDate = new Date(date);
    const utc = inputDate.getTime() - inputDate.getTimezoneOffset() * 60000;
    const localDate = new Date(utc + 3600000 * timeZoneOffset);

    return moment(localDate).format(format);
  }
  return moment.utc(date).local().format(format);
};

const dateUtils = {
  toMMDDYYYYHHmmss,
  toMMDDYYYY,
  toddddMMMMDYYYY,
  toMMMMYYYY,
  tzToLocal,
  localToTimezone,
};

export default dateUtils;
