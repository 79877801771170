import { Auth } from 'aws-amplify';
import endpoints from '../../endpoints';

export default async function requestOTP(){
    let env = process.env.REACT_APP_ENV?.trim();

    const url = endpoints.ONBOARDING.REQUEST_OTP[env];

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`
            }
        })
    ).json();
}
