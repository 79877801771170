import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import mixpanel from "mixpanel-browser";
import { Form, Upload, Button as AntButton, Card, Row, Col } from "antd";
import { RiFileFill, RiCloseFill } from "react-icons/ri";
import { Input, CTAButton, Title, Message } from "../../../common";
import useLanguage from "../../../../hooks/useLanguage";
import api from "../../../API";
import generateErrorMessage from "../../../util/functions/customError";
import { dollarToCents } from "../../../util/functions/dollarConvertion";
import REGEX from "../../../util/constants/regex";
import messages from "../../config/messages.const";
import { FILE_SIZE } from "../../../constants";
import classNames from "./DepositCheck.module.scss";

export default function DepositCheckForm({
  onCancel,
  form,
  removeCheckImage,
  personId,
  businessId,
  accountId,
  setBack,
  setFront,
  back,
  front,
}) {
  //Hooks
  const [error, setError] = useState(false);
  // const [front, setFront] = useState(false);
  // const [frontBnry, setFrontBnry] = useState(null);
  // const [back, setBack] = useState(false);
  // const [backBnry, setBackBnry] = useState(null);
  // const [accountType, setAccountType] = useState(
  //   personId ? ACCOUNT_TYPE.INDIVIDUAL : ACCOUNT_TYPE.BUSINESS
  // );
  const language = useLanguage(messages);

  // acceptable image types for the front-side-image and back-side-image
  // Unit only allows JPEG type
  // https://docs.unit.co/check-deposits#upload-front-side-image
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (removeCheckImage) {
      setFront(false);
      setBack(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCheckImage]);

  // useEffect(() => {
  //   if (personId) {
  //     setAccountType("PERSON");
  //   }
  //   if (businessId) {
  //     setAccountType("BUSINESS");
  //   }
  // }, [personId, businessId]);

  //Other variables
  const acceptableType = ["image/jpg", "image/jpeg", "image/png"];
  const acceptableTypes = acceptableType.join(",");
  const { Dragger } = Upload;

  //Api calls
  const createDepositCheckMutation = useMutation((formData) =>
    api.ManageChecks.createCheckDeposit(formData)
  );

  //Event handlers
  function onChangeFrontImage(info) {
    // const reader = new FileReader();
    // reader.onloadend = async function () {
    //   setFrontBnry(reader.result);
    // };
    // reader.readAsArrayBuffer(info.file);
    if (!error) {
      setFront(info.file);
    }
  }

  function onChangeBackImage(info) {
    // const reader = new FileReader();
    // reader.onloadend = async function () {
    //   setBackBnry(reader.result);
    // };
    // reader.readAsArrayBuffer(info.file);
    if (!error) {
      setBack(info.file);
    }
  }

  function onFinish(data) {
    const formData = new FormData();

    if (personId) {
      formData.append("otz_person_id", personId);
    }
    if (businessId) {
      formData.append("otz_business_id", businessId);
    }
    formData.append("check_amount", dollarToCents(data.amount));
    formData.append("otz_account_id", accountId);
    formData.append("frontImageFile", front);
    formData.append("backImageFile", back);

    createDepositCheckMutation.mutate(formData, {
      onSuccess: (data) => {
        setFront(false);
        setBack(false);
        try {
          mixpanel.track("Check Deposit Successful", {
            "Amount": data?.payload?.check_amount/100.0,
            "Bank Name": data?.bankName, 
            "Date of Deposit": data?.payload?.creation_time, 
            "Date of Settlement": data?.payload?.date_processed, 
            "Date of Activation": data?.payload?.date_captured, 
            "Transaction Status": data?.payload?.status 
          });
        } catch (error) {
          console.error("error: ", error)
        }
        onCancel();

      },
      onError: (error) => {
        generateErrorMessage(error);
        setFront(false);
        setBack(false);
        try {
          mixpanel.track("Check Deposit Successful", {
            "Amount": data?.payload?.check_amount/100.0,
            "Bank Name": data?.bankName,
            "Date of Deposit": data?.payload?.creation_time,
            "Date of Settlement": data?.payload?.date_processed,
            "Date of Activation": data?.payload?.date_captured,
            "Transaction Status": error
          });
        } catch (error) {
          console.error("error: ", error)
        }
        onCancel();
      },
    });
  }

  //Helper functions
  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  return (
    <div className={classNames.modal}>
      <div className={classNames.modalLeft}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                required
                className={front ? classNames.hasFile : ""}
                name="frontImage"
                label={
                  <span className={classNames.label}>
                    {language.FRONT_IMAGE}
                    <sup style={{ top: "-0.2em", left: ".2em" }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Check front image is required",
                    transform: (e) => e?.fileList?.[0]?.name || null,
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (error) {
                        callback("Max 20MB is allowed");
                      }
                      callback();
                    },
                  },
                ]}
              >
                <Dragger
                  className={front ? classNames.hideControl : ""}
                  fileList={form.getFieldValue("frontImage")?.fileList || []}
                  accept={acceptableTypes}
                  customRequest={({ file, onSuccess, onError }) => {
                    if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                      onSuccess("ok");
                    } else onError();
                  }}
                  beforeUpload={(file) => {
                    if (!acceptedType(file)) {
                      setError(false);
                      Message({
                        type: "error",
                        content: `File type is not allowed. Please select a JPEG`,
                      });
                    } else if (file?.size > FILE_SIZE.B) {
                      setError(true);
                    } else {
                      setError(false);
                      return false;
                    }
                  }}
                  onChange={onChangeFrontImage}
                  showUploadList={false}
                >
                  <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                    <i
                      className="ri-drag-drop-line"
                      style={{ fontSize: "24px", color: "#9BA3AB" }}
                    ></i>
                  </p>
                  <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                    {language.DRAG_DROP}
                    <AntButton
                      type="link"
                      size="small"
                      style={{ fontSize: "12px" }}
                    >
                      {language.IMAGE_UPLOAD_BTN_TEXT}
                    </AntButton>
                  </p>
                </Dragger>
              </Form.Item>
              <Card
                className={`${classNames.uploadCard} ${
                  !front ? classNames.hideControl : ""
                }`}
              >
                <RiCloseFill
                  onClick={() => {
                    setFront(null);
                    form.setFieldsValue({ frontImage: null });
                    form.validateFields(["frontImage"]);
                  }}
                  size={20}
                  className={classNames.closeIcon}
                />
                <div>
                  <RiFileFill size={40} className={classNames.fileIcon} />
                </div>
                <div> {front?.name}</div>
              </Card>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                className={back ? classNames.hasFile : ""}
                name="backImage"
                label={
                  <span className={classNames.label}>
                    {language.BACK_IMAGE}
                    <sup style={{ top: "-0.2em", left: ".2em" }}>*</sup>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Check back image is required",
                    transform: (e) => e?.fileList?.[0]?.name || null,
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (error) {
                        callback(`Max ${FILE_SIZE.MB} is allowed`);
                      }
                      callback();
                    },
                  },
                ]}
              >
                <Dragger
                  className={back ? classNames.hideControl : ""}
                  fileList={form.getFieldValue("backImage")?.fileList || []}
                  accept={acceptableTypes}
                  customRequest={({ file, onSuccess, onError }) => {
                    if (acceptedType(file)) {
                      onSuccess("ok");
                    } else onError();
                  }}
                  beforeUpload={(file) => {
                    if (!acceptedType(file)) {
                      setError(false);
                      Message({
                        type: "error",
                        content: language.SELECT_JPEG,
                      });
                    } else if (file?.size > FILE_SIZE.B) {
                      setError(true);
                    } else {
                      setError(false);
                      return false;
                    }
                  }}
                  onChange={onChangeBackImage}
                  showUploadList={false}
                >
                  <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                    <i
                      className="ri-drag-drop-line"
                      style={{ fontSize: "24px", color: "#9BA3AB" }}
                    ></i>
                  </p>
                  <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                    {language.DRAG_DROP}
                    <AntButton
                      type="link"
                      size="small"
                      style={{ fontSize: "12px" }}
                    >
                      {language.IMAGE_UPLOAD_BTN_TEXT}
                    </AntButton>
                  </p>
                </Dragger>
              </Form.Item>
              <Card
                className={`${classNames.uploadCard} ${
                  !back ? classNames.hideControl : ""
                }`}
              >
                <RiCloseFill
                  onClick={() => {
                    setBack(null);
                    form.setFieldsValue({ backImage: null });
                    form.validateFields(["backImage"]);
                  }}
                  size={20}
                  className={classNames.closeIcon}
                />
                <div>
                  <RiFileFill size={40} className={classNames.fileIcon} />
                </div>
                <div> {back?.name}</div>
              </Card>
            </Col>
          </Row>
          <Form.Item
            name="amount"
            style={{ width: "50%", margin: "20px 0" }}
            label={
              <span className={classNames.label}>
                {language.AMOUNT}
                <sup style={{ top: "-0.2em", left: ".2em" }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: language.AMOUNT_REQUIRED },
              {
                pattern: new RegExp(REGEX.amount),
                message: language.AMOUNT_VALID,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value <= 0) {
                    callback(`Enter a valid amount`);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input
              placeholder={language.AMOUNT_ENTER}
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <CTAButton
                onClick={form.submit}
                key="submit"
                htmlType="submit"
                type="primary"
                small
                loading={createDepositCheckMutation.isLoading}
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {language.DEPOSIT}
              </CTAButton>
            )}
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <Title as="h3">{language.INSTRUCTIONS}</Title>
        <ul>
          <li>{language.INSTRUCTIONS_1}</li>
          <li>{language.INSTRUCTIONS_2}</li>
          <li>{language.INSTRUCTIONS_3}</li>
          <li>{language.INSTRUCTIONS_5}</li>
          <li>{language.INSTRUCTIONS_6}</li>
          <li>{language.INSTRUCTIONS_7}</li>
          <li>{language.INSTRUCTIONS_8}</li>
          {/* {accountType === ACCOUNT_TYPE.BUSINESS && (
            <li>{language.INSTRUCTIONS_4}</li>
          )} */}

          {/* <li> */}
          {/* {language.CHECK_LIMITS}:
            <ul>
              <li>
                {language.DAILY}:{" "}
                {accountType === ACCOUNT_TYPE.INDIVIDUAL
                  ? CHECK_INDIVIDUAL_DAILY
                  : CHECK_BUSINESS_DAILY}
              </li>
              <li>
                {language.MONTHLY}:{" "}
                {accountType === ACCOUNT_TYPE.INDIVIDUAL
                  ? CHECK_INDIVIDUAL_MONTHLY
                  : CHECK_BUSINESS_MONTHLY}
              </li>
            </ul> */}
          {/* </li> */}
        </ul>
      </div>
    </div>
  );
}

DepositCheckForm.propTypes = {
  refreshScreen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  removeCheckImage: PropTypes.bool.isRequired,
};
