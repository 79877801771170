import { ROUTES } from ".";

export const invoiceRoutes = [
  ROUTES.APP.RECEIVABLES.INVOICES,
  ROUTES.APP.RECEIVABLES.ESTIMATES,
  ROUTES.APP.RECEIVABLES.INVOICE,
  ROUTES.APP.RECEIVABLES.RECURRING_INVOICE,
  ROUTES.APP.RECEIVABLES.RECURRING_INVOICES,
  ROUTES.APP.RECEIVABLES.ALL_ESTIMATES,
  ROUTES.APP.RECEIVABLES.ALL_INVOICES,
  ROUTES.APP.RECEIVABLES.CUSTOMERS,
  ROUTES.APP.RECEIVABLES.PRODUCTS_SERVICES,
  ROUTES.APP.RECEIVABLES.TAX_DB,
  ROUTES.APP.RECEIVABLES.DRAFT_ESTIMATES_INVOICES,
];
