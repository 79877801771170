import * as types from '../actionTypes';

const userDataReducer = (state, action) => {
  switch (action.type) {
    case types.SET_LOGGED_USER_DATA: {
      return { ...state, ...action.payload };
    }
    case types.CLEAR_LOGGED_USER_DATA: {
      return {};
    }
    default:
      return state;
  }
}

export default userDataReducer;