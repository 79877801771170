import { useEffect, useState } from "react";
import classNames from "./ViewInvoice.module.scss";
import { CTAButton, Header, Select } from "../../../common";
import { Card, Button as AntButton, Spin } from "antd";
import {
  RiEditLine,
  RiShareBoxFill,
  RiPrinterLine,
  RiDownloadCloudLine,
  RiDeleteBin7Line,
} from "react-icons/ri";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import DeleteRecurringInvoiceInvoice from "./DeleteRecurringInvoice/DeleteRecurringInvoice";
import ShareInvoice from "./ShareInvoice/ShareInvoice";
import ChangeStatus from "../Invoices/ChangeStatus/ChangeStatus";
import { useQuery } from "react-query";
import api from "../../../API";
import EditRecurringInvoice from "./EditRecurringInvoice/EditRecurringInvoice";
import { ROUTES } from "../../../constants";

export default function ViewRecurringInvoice() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;
  // const [refetch, setRefetch] = useState(true);

  const [invoiceData] = useState(location.state);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoice, isLoading: invoiceLoading } = useQuery(
    "getRecurringInvoiceById",
    () => api.Receivables.Recurring.getRecurringInvoiceById(invoiceData.id),
    {
      enabled: !!invoiceData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoicePDF, isLoading: invoicePDFLoading } = useQuery(
    "getInvoicePDF",
    () => api.Receivables.Invoices.getInvoicePDF(invoiceData.id),
    {
      enabled: !!invoiceData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: products } = useQuery(
    "getProductsByCompanyId",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxRates } = useQuery(
    "getTaxRatesByCompanyId",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: invoiceTemplateSettingsQuery,
    // isFetching: invoiceTemplateSettingsFetching,
    // isSuccess: invoiceTemplateSettingsSuccess,
  } = useQuery(
    "getInvoiceSettings",
    () =>
      api.Receivables.Settings.getInvoiceSettings(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const [
    editRecurringInvoiceModalVisibility,
    setEditRecurringInvoiceModalVisibility,
  ] = useState({ visible: false, data: false });

  const [deleteInvoiceModalVisibility, setDeleteInvoiceModalVisibility] =
    useState(false);

  const [shareInvoiceModalVisibility, setShareInvoiceModalVisibility] =
    useState(false);

  // const [invoiceZoomLevel, setInvoiceZoomLevel] = useState(0);
  const [changeStatusModalVisibility, setChangeStatusModalVisibility] =
    useState(false);

  const [invoiceStatus, setInvoiceStatus] = useState();

  const handleChangeStatusModalCancelClick = () => {
    setChangeStatusModalVisibility(false);
    setInvoiceStatus(
      invoice?.data?.invoiceStatus
        ? invoice?.data?.invoiceStatus
        : invoiceData.status
    );
  };

  const handleInvoiceDeleteClick = () => {
    setDeleteInvoiceModalVisibility(true);
  };

  const handleDeleteInvoiceCancelClick = () => {
    setDeleteInvoiceModalVisibility(false);
  };

  const handleShareInvoiceClick = () => {
    setShareInvoiceModalVisibility(true);
  };
  const handleShareInvoiceCancelClick = () => {
    setShareInvoiceModalVisibility(false);
  };

  // const handleInvoiceZoomLevel = () => {
  //   console.log(invoiceZoomLevel);
  //   setInvoiceZoomLevel(100);
  // };

  const handleInvoiceStatusChange = (value) => {
    if (value === "sendToCustomer") {
      handleShareInvoiceClick();
      return;
    }
    setChangeStatusModalVisibility(true);
    setInvoiceStatus({
      flag: "recurringInvoice",
      value: value,
      record: location.state,
    });
  };

  const handlePrintClick = () => {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ` src="data:application/pdf;base64,${invoicePDF?.data?.pdf}` +
      '"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    // printWindow.print();

    // Message({ type: "success", content: "Sent for print" });
  };

  const handleDownloadClick = () => {
    // Message({ type: "success", content: "Downloaded" });
  };

  useEffect(() => {
    if (invoice) {
      setInvoiceStatus(
        invoice?.data?.invoiceStatus
          ? invoice?.data?.invoiceStatus
          : invoiceData.status
      );
    }
  }, [invoice, invoiceData]);

  if (!invoiceData) {
    return <Navigate to={ROUTES.APP.RECEIVABLES.INVOICES} />;
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title={`Invoice ${
            invoice?.data.number || invoice?.data.invoiceNumber
          }`}
          back
          onClick={() => navigate(ROUTES.APP.RECEIVABLES.RECURRING_INVOICES)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <Spin spinning={invoiceLoading || invoicePDFLoading}>
              <Card className={classNames.invoiceCard}>
                <div className={classNames.cardLeft}>
                  <div className={classNames.invoiceContainer}>
                    <div>
                      <b>Invoice</b>
                    </div>
                    {invoiceData && (
                      <iframe
                        id="pdfIframe"
                        src={`data:application/pdf;base64,${invoicePDF?.data?.pdf}`}
                        // src={`${invoice}#zoom=${100}&view=Fit&toolbar=0&scrollbar=0&statusbar=0&navpanes=0`}
                        title="invoice"
                        frameBorder="0"
                        className={classNames.invoice}
                      />
                    )}
                  </div>
                  <div className={classNames.invoiceOptions}>
                    <AntButton
                      className={classNames.deleteBtn}
                      onClick={handleInvoiceDeleteClick}
                    >
                      <RiDeleteBin7Line size={13} color="#EE6969" />
                      Delete
                    </AntButton>
                    {/* <AntButton
                    className={classNames.zoomBtn}
                    // onClick={handleInvoiceZoomLevel}
                  >
                    <RiSearch2Line size={13} color="#fff" />
                    Zoom
                  </AntButton> */}
                  </div>
                </div>
                <div className={classNames.cardRight}>
                  <div className={classNames.invoiceStatusSelect}>
                    <span>Mark invoice as</span>
                    <Select
                      defaultValue={invoiceStatus}
                      value={invoiceStatus}
                      onChange={handleInvoiceStatusChange}
                    >
                      <Option value="draft">Draft</Option>
                      <Option value="sent">Sent</Option>
                      {/* <Option value="late">Late</Option> */}
                      <Option value="paid">Paid</Option>
                      <Option value="sendToCustomer">Send to Customer</Option>
                    </Select>
                  </div>
                  <div>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={() => {
                        setEditRecurringInvoiceModalVisibility({
                          visible: true,
                          data: invoice?.data,
                        });
                      }}
                    >
                      <RiEditLine size={12} />
                      Edit
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handleShareInvoiceClick}
                    >
                      <RiShareBoxFill size={12} />
                      Share
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handlePrintClick}
                    >
                      <RiPrinterLine size={12} />
                      Print
                    </CTAButton>
                    <CTAButton
                      className={classNames.invoiceCardCTA}
                      style={{ height: 48 }}
                      onClick={handleDownloadClick}
                      download={`${invoice?.data?.invoiceNumber}_${invoice?.data?.receiverName}.pdf`}
                      href={`data:application/octet-stream;base64, ${invoicePDF?.data?.pdf}`}
                    >
                      <RiDownloadCloudLine size={12} />
                      Download
                    </CTAButton>
                  </div>
                </div>
              </Card>
            </Spin>
          </div>
        </div>
      </div>
      <ChangeStatus
        visible={changeStatusModalVisibility}
        status={invoiceStatus}
        onCancel={handleChangeStatusModalCancelClick}
        setChangeStatusModalVisibility={setChangeStatusModalVisibility}
      />
      <DeleteRecurringInvoiceInvoice
        visible={deleteInvoiceModalVisibility}
        setDeleteRecurringInvoiceModalVisibility={
          setDeleteInvoiceModalVisibility
        }
        invoice={invoice?.data}
        onCancel={handleDeleteInvoiceCancelClick}
      />
      <EditRecurringInvoice
        customers={customerQuery?.data}
        invoice={editRecurringInvoiceModalVisibility.data}
        products={products?.data}
        taxRates={taxRates?.data}
        visible={editRecurringInvoiceModalVisibility.visible}
        invoiceTemplateSettings={invoiceTemplateSettingsQuery?.data}
        setEditRecurringInvoiceModalVisibility={
          setEditRecurringInvoiceModalVisibility
        }
        key={editRecurringInvoiceModalVisibility.data}
      />
      <ShareInvoice
        visible={shareInvoiceModalVisibility}
        onCancel={handleShareInvoiceCancelClick}
        customers={customerQuery?.data}
        invoice={invoice?.data}
      />
    </div>
  );
}
