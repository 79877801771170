import { Input, Message, Select, Title } from "../../../../../common";
import { Form, Upload, Button as AntButton, Checkbox } from "antd";
import { RiDragDropLine } from "react-icons/ri";
import { usStateList, countryList } from "../../../../../util";
import { MAX_MIN } from "../../../../../util/constants";
import REGEX from "../../../../../util/constants/regex";
import { validation } from "../../../../../Auth/config";
import classNames from "../../TemplateSettings.module.scss";

export default function CompanyDetails({
  fileReader,
  form,
  data,
  setRemoved,
  userInfo,
  changeAddr,
  setChangeAddr,
}) {
  const { Dragger } = Upload;
  // acceptable image types for the companyLogo field
  const acceptableType = ["image/png", "image/jpg", "image/jpeg"];
  const acceptableTypes = acceptableType.join(",");
  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  return (
    <div>
      <div className={classNames.modalHeader}>
        <Title as="h3">Company Details</Title>
      </div>
      <Form.Item
        name="companyName"
        label={
          <span className={classNames.label}>
            Company Name <sup style={{ top: 1 }}>*</sup>
          </span>
        }
        initialValue={data?.companyName}
        rules={[
          () => ({
            validator(_, value) {
              if (
                value &&
                value?.toString()?.length > MAX_MIN.businessName.max
              ) {
                return Promise.reject(
                  new Error(`Max ${MAX_MIN.businessName.max} characters`)
                );
              }
              if (!value?.toString()?.length) {
                return Promise.reject(new Error("Company name is required"));
              }
              if (/required.*/.test(value.toString())) {
                return Promise.reject(
                  new Error(`"required" is a reserved key word.`)
                );
              }
              return Promise.resolve();
            },
          }),
          {
            pattern: new RegExp(REGEX.businessName),
            message: "Enter a valid company name",
          },
        ]}
      >
        <Input
          placeholder="Please enter"
          size="small"
          defaultValue={data?.companyName}
          maxLength={50}
        />
      </Form.Item>
      <Form.Item
        name="companyEmail"
        label={
          <span className={classNames.label}>
            Email Address <sup style={{ top: 1 }}>*</sup>
          </span>
        }
        initialValue={data?.email}
        rules={[
          {
            required: form.getFieldValue("companyEmail") ? true : false,
            message: "Email address is required",
          },
          {
            type: "email",
            message: "Enter valid email",
          },
          { min: MAX_MIN.email.min, message: validation.email.minLength },
          { max: MAX_MIN.email.max, message: validation.email.maxLength },
        ]}
      >
        <Input
          placeholder="Please enter"
          size="small"
          defaultValue={data?.email}
          disabled
        ></Input>
      </Form.Item>
      <Form.Item
        name="companyLogo"
        label={
          <Title as="h4" className={classNames.label}>
            Company Logo
          </Title>
        }
      >
        <Dragger
          customRequest={({ file, onSuccess, onError }) => {
            if (acceptedType(file)) {
              onSuccess("ok");
            } else onError();
          }}
          onChange={(e) => {
            fileReader(e);
            setRemoved(false);
          }}
          maxCount={1}
          defaultFileList={
            data?.logo
              ? [
                  {
                    uid: "1",
                    name: "company_logo.png",
                    status: "done",
                  },
                ]
              : []
          }
          onRemove={(e) => setRemoved(true)}
          accept={acceptableTypes}
          beforeUpload={(file) => {
            if (!acceptedType(file)) {
              Message({
                type: "error",
                content: `${file.name} is not an image file`,
              });
            }
          }}
        >
          <RiDragDropLine size={21} color="#9BA3AB" />
          <span
            className="ant-upload-text"
            style={{ fontSize: "12px", marginLeft: 14 }}
          >
            {`Drag & Drop or `}
            <AntButton
              type="link"
              size="small"
              style={{ fontSize: "12px", padding: 0 }}
            >
              click here to upload
            </AntButton>
          </span>
        </Dragger>
      </Form.Item>

      {!data?.street1 && (
        <Form.Item name="changeAddr" valuePropName="checked">
          <Checkbox
            checked={changeAddr}
            defaultChecked={false}
            onChange={() => {
              setChangeAddr(!changeAddr);
            }}
          >
            <Title as="h4" className={classNames.label}>
              Change Address
            </Title>
          </Checkbox>
        </Form.Item>
      )}

      {changeAddr ? (
        <Form.Item
          name="companyAddress"
          label={
            <span className={classNames.label}>
              Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
        >
          <Form.Item
            name="companyNewAddressStreet1"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  if (!value?.toString()?.length) {
                    return Promise.reject(
                      new Error("Valid Address is required")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Street*" size="small" maxLength={256} />
          </Form.Item>
          <Form.Item
            name="companyNewAddressStreet2"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Street 2" size="small" maxLength={256} />
          </Form.Item>
          <Form.Item
            name="companyNewAddressCity"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  if (!value?.toString()?.length) {
                    return Promise.reject(new Error("City is required"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="City *" size="small" maxLength={256} />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="companyNewAddressState"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  required: true,
                  message: "Please select state",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="State"
                size="small"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {usStateList().map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="companyNewAddressPostalCode"
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value?.toString()?.length < 5) {
                      return Promise.reject(
                        new Error("Valid Postal Code is required")
                      );
                    }

                    if (value && value?.toString()?.length > 5) {
                      return Promise.reject(new Error("Max 5 characters"));
                    }
                    if (!value?.toString()?.length) {
                      return Promise.reject(
                        new Error("Postal Code is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="Postal code *"
                size="small"
                type="text"
                maxLength={5}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="companyNewAddressCountry"
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Country"
              size="small"
            >
              {countryList(true).map((value) => (
                <Select.Option key={value.code} value={value.code}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
      ) : (
        <Form.Item
          name="companyAddress"
          label={
            <span className={classNames.label}>
              Address <sup style={{ top: 1 }}>*</sup>
            </span>
          }
        >
          <Form.Item
            name="companyAddressStreet1"
            initialValue={
              data?.street1
                ? data?.street1
                : userInfo?.legal_address?.address_line_1
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  if (!value?.toString()?.length) {
                    return Promise.reject(
                      new Error("Valid Address is required")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Street*"
              size="small"
              defaultValue={
                data?.street1
                  ? data?.street1
                  : userInfo?.legal_address?.address_line_1
              }
              maxLength={256}
            />
          </Form.Item>
          <Form.Item
            name="companyAddressStreet2"
            initialValue={
              data?.street2
                ? data?.street2
                : userInfo?.legal_address?.address_line_2
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Street 2"
              size="small"
              defaultValue={
                data?.street2
                  ? data?.street2
                  : userInfo?.legal_address?.address_line_2
              }
              maxLength={256}
            />
          </Form.Item>
          <Form.Item
            name="companyAddressCity"
            initialValue={
              data?.city ? data?.city : userInfo?.legal_address?.city
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value?.toString()?.length > 256) {
                    return Promise.reject(new Error("Max 256 characters"));
                  }
                  if (!value?.toString()?.length) {
                    return Promise.reject(new Error("City is required"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="City *"
              size="small"
              defaultValue={
                data?.city ? data?.city : userInfo?.legal_address?.city
              }
              maxLength={256}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="companyAddressState"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                marginBottom: "10px",
                float: "left",
              }}
              rules={[
                {
                  required: !(userInfo?.legal_address?.state || data?.state),
                  message: "Please select state",
                },
              ]}
              initialValue={
                data?.state ? data?.state : userInfo?.legal_address?.state
              }
              u
            >
              <Select
                showSearch
                placeholder="State"
                size="small"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultValue={
                  data?.state ? data?.state : userInfo?.legal_address?.state
                }
              >
                {usStateList().map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="companyAddressPostalCode"
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
              initialValue={
                data?.zipcode
                  ? data?.zipcode
                  : userInfo?.legal_address?.postal_code
              }
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value?.toString()?.length < 5) {
                      return Promise.reject(
                        new Error("Valid Zip Code is required")
                      );
                    }

                    if (value && value?.toString()?.length > 5) {
                      return Promise.reject(new Error("Max 5 characters"));
                    }
                    if (!value?.toString()?.length) {
                      return Promise.reject(new Error("Zip Code is required"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="Postal code *"
                size="small"
                type="text"
                defaultValue={
                  data?.zipcode
                    ? data?.zipcode
                    : userInfo?.legal_address?.postal_code
                }
                maxLength={5}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="companyAddressCountry"
            rules={[
              {
                required: !(
                  userInfo?.legal_address?.country_code || data?.country
                ),
                message: "Please select a country",
              },
            ]}
            initialValue={
              data?.country
                ? data?.country
                : userInfo?.legal_address?.country_code
            }
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Country"
              size="small"
              defaultValue={
                data?.country
                  ? data?.country
                  : userInfo?.legal_address?.country_code
              }
            >
              {countryList(true).map((value) => (
                <Select.Option key={value.code} value={value.code}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
      )}
    </div>
  );
}
