import logo from "../../../assets/logo-light.svg";
import { Carousel as AntCarousel } from "antd";
import Slide from "./Slides";
import data from "./slide.data";

function Carousel({ classNames }) {
  return (
    <div className={classNames.sidebar}>
      <a href="https://www.otterz.co/">
        <img src={logo} alt="logo" className={classNames.logo} />
      </a>
      <div className={classNames.carousel}>
        <AntCarousel autoplay fade>
          {data.map((item) => (
            <Slide
              key={item.title.replace(" ", "")}
              classNames={classNames}
              description={item.description}
              title={item.title}
              imgSrc={item.imgSrc}
            />
          ))}
        </AntCarousel>
      </div>
    </div>
  );
}

export default Carousel;
