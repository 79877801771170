export const messages = {
  en: {
    UPGRADE_TO_PRO_TITLE: "Upgrade to Pro",
    DOWNGRADE_TO_FREE_TITLE: "Downgrade to Free",
    DOWNGRADE_TO_FREE_SUBTITLE:
      "You will be downgraded to free plan at the end of current billing cycle. ",
    NEXT_BILL_CYCLE_DATE: "Next billing cycle: ::date",
    UPGRADE_TO_PRO_SUBTITLE:
      "You will be charged :paid-plan every month from your bank account. ",
    SUBTITLE: "",
    SUBSCRIPTION_ASSIGNMENT_SUCCESS: "Upgrade request accepted. Your new subscription will be activated from the next update cycle.",
  },
};
