import { Modal, Title, CTAButton } from "../../../../common";
import classNames from "./Void.module.scss";

const Void = ({ visible, onCancel, onTransactionClick }) => {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
      destroyOnClose={true}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Void Transaction
      </Title>
      <div className={classNames.voidText}>
        Are you sure you want to void this transactions
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
          }}
          onClick={onTransactionClick}
        >
          Yes, void
        </CTAButton>
      </div>
    </Modal>
  );
};

export default Void;
