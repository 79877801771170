import classNames from "./Error.module.scss";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { Title } from "../../../common";

const Error = ({ title, message }) => {
  return (
    <div className={classNames.success}>
      <RiCloseCircleFill
        size={90}
        color="#ff6090"
        style={{ marginBottom: 34 }}
      />
      <Title as="h3">{title}</Title>
      <div className={classNames.extra}>{message}</div>
    </div>
  );
};

Error.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Error;
