import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "./Onboarding.module.scss";
import { Header } from "../../../common";
import { Card, Spin } from "antd";
import carouselImg from "../../../../assets/auth/undraw_revenue_re_2bmg 1.png";
import Industry from "./Steps/Industry";
import Type from "./Steps/Type";
import Application from "./Steps/Application";
import Success from "./Steps/Success";
import Callback from "./Steps/Callback";
import { useEffect, useState } from "react";
import { ROUTES, STATUS } from "../../../constants";
import PendingApproval from "./Steps/PendingApproval";
import NotApproved from "./Steps/NotApproved";
import AdditionalInfoRequired from "./Steps/AdditionalInfoRequired";
import api from "../../../API";
import { useQuery } from "react-query";
import { AppContext } from "../../../../context/AppState";
import { commonMessage } from "./config";

const Onboarding = () => {
  const [step, setStep] = useState(5);
  const navigate = useNavigate();

  const { application } = useContext(AppContext);
  const location = useLocation();
  const typeOfOnboard = location.state;
  const validKey =
    (typeOfOnboard &&
      Object.keys(typeOfOnboard).find((key) => typeOfOnboard[key] === true)) ||
    "isOnBoard";

  const { isLoading: accountLoading } = useQuery(
    "getAccountById",
    () => api.BusinessBanking.getAccount(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const userName = application?.username;

  const { data: onboardRequest, isLoading: requestInfoLoading } = useQuery(
    "getOnboardRequest",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onboardStatus = onboardRequest?.data?.onboardStatus;

  useEffect(() => {
    if (!requestInfoLoading) {
      if (onboardStatus && typeOfOnboard?.isOrder) {
        setStep(5);
      } else if (onboardStatus && typeOfOnboard?.isEcommerce) {
        setStep(5);
      } else if (onboardStatus && typeOfOnboard?.isSetupHardWare) {
        setStep(5);
      } else {
        switch (onboardStatus) {
          case STATUS.REQUESTED:
            setStep(6);
            break;
          case STATUS.DECLINED:
            setStep(7);
            break;
          case STATUS.INCOMPLETE:
            setStep(8);
            break;
          case STATUS.APPROVED:
            navigate(ROUTES.APP.RECEIVABLES.PAYMENT_ACCEPTANCE);
            break;
          default:
            setStep(5);
            break;
        }
      }
    }
  }, [requestInfoLoading, onboardStatus, navigate, typeOfOnboard]);

  const nextStep = (count) => {
    if (count === 5) {
      setStep(5);
    } else {
      setStep(step + 1);
    }
  };

  const RenderStep = () => {
    switch (step) {
      case 1:
        return <Industry nextStep={nextStep} />;
      case 2:
        return <Type nextStep={nextStep} />;
      case 3:
        return <Application nextStep={nextStep} />;
      case 4:
        return <Success name={userName} />;
      case 5:
        return <Callback setStep={setStep} validKey={validKey} />;
      case 6:
        return <PendingApproval name={userName} validKey={validKey} />;
      case 7:
        return <NotApproved name={userName} />;
      case 8:
        return <AdditionalInfoRequired name={userName} />;
      default:
        return null;
    }
  };

  return (
    <Spin spinning={accountLoading || requestInfoLoading} size="large">
      <div className={classNames.wrapper}>
        <div className={classNames.headerWrapper}>
          <Header title="Payment Acceptance" />
        </div>
        <div className={classNames.layout}>
          <div className={classNames.bodyWrapper}>
            <div className={classNames.body}>
              <Card className={classNames.onboardingCard}>
                <div className={classNames.onboardingCardLeft}>
                  <img
                    src={carouselImg}
                    alt=""
                    className={classNames.carouselImg}
                  />
                  <div>
                    <div>{commonMessage?.callBackText[validKey]?.title}</div>
                    <div>{commonMessage?.callBackText[validKey]?.subTitle}</div>
                  </div>
                </div>
                <div className={classNames.onboardingCardRight}>
                  {!accountLoading && !requestInfoLoading && <RenderStep />}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Onboarding;
