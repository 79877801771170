import { useEffect, useState } from "react";
import classNames from "../ManageCards.module.scss";
import { CTAButton, Input, Message } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import { Form, Spin } from "antd";
import api from "../../../API";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function SetSpentLimitForm({ currentCard, onCancel }) {
  const [limits, setLimits] = useState();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { isFetching } = useQuery(
    ["getLimits", currentCard?.id],
    () => api.BusinessBanking.getCardLimits(currentCard?.id),
    {
      onSuccess: (data) => setLimits(data?.data?.data?.attributes.limits),
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    form.setFieldsValue({
      dailyWithdrawal: limits?.dailyWithdrawal / 100,
      dailyPurchase: limits?.dailyPurchase / 100,
      monthlyWithdrawal: limits?.monthlyWithdrawal / 100,
      monthlyPurchase: limits?.monthlyPurchase / 100,
    });
  }, [form, limits, currentCard?.id]);

  function setSpendLimitFn(data) {
    let newData = {};
    for (const key in data) {
      if (data[key]) {
        newData[key] = parseFloat(data[key]) * 100;
      }
    }
    let input = {
      data: {
        type: currentCard?.type,
        attributes: {
          limits: {
            ...newData,
          },
        },
      },
    };

    return api.BusinessBanking.updateLimits({ cardId: currentCard.id, input });
  }

  const setSpendLimitMutation = useMutation((event) => setSpendLimitFn(event));

  const onFinish = (data) => {
    setSpendLimitMutation.mutate(data, {
      onSuccess: () => {
        Message({ type: "success", content: "Limits updated successfully!" });
        queryClient.invalidateQueries("listCards");
        queryClient.invalidateQueries(["getLimits", currentCard?.id]);
        onCancel();
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  return (
    <Spin spinning={setSpendLimitMutation.isLoading || isFetching}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item>
          <Form.Item
            name={"dailyWithdrawal"}
            label={
              <span className={classNames.label}>Daily Withdrawal Limit</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "left",
              padding: "10px 0 20px 0",
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const monthlyWithdrawal = Number(
                    getFieldValue("monthlyWithdrawal")
                  );
                  const dailyWithdrawal = Number(value);

                  if (monthlyWithdrawal > 0 && dailyWithdrawal > 0) {
                    if (dailyWithdrawal <= monthlyWithdrawal) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "Daily withdrawal cannot be greater than the monthly withdrawal."
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder="Enter amount"
              type="number"
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={classNames.label}>Daily Purchase Limit</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
              padding: "10px 0 20px 0",
            }}
            name={"dailyPurchase"}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const monthlyPurchase = Number(
                    getFieldValue("monthlyPurchase")
                  );
                  const dailyPurchase = Number(value);

                  if (monthlyPurchase > 0 && value > 0) {
                    if (dailyPurchase <= monthlyPurchase) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "Daily purchase cannot be greater than the monthly purchase."
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder="Enter amount"
              type="number"
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            label={
              <span className={classNames.label}>Monthly Withdrawal Limit</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "left",
              padding: "10px 0 20px 0",
            }}
            name={"monthlyWithdrawal"}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const dailyWithdrawal = Number(
                    getFieldValue("dailyWithdrawal")
                  );
                  const monthlyWithdrawal = Number(value);

                  if (dailyWithdrawal > 0 && monthlyWithdrawal > 0) {
                    if (monthlyWithdrawal >= dailyWithdrawal) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "Monthly withdrawal cannot be less than the daily withdrawal."
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder="Enter amount"
              type="number"
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={classNames.label}>Monthly Purchase Limit</span>
            }
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              margin: "0 0 0 4px",
              float: "right",
              padding: "10px 0 20px 0",
            }}
            name={"monthlyPurchase"}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const dailyPurchase = Number(getFieldValue("dailyPurchase"));
                  const monthlyWithdrawal = Number(value);

                  if (dailyPurchase > 0 && monthlyWithdrawal > 0) {
                    if (dailyPurchase <= monthlyWithdrawal) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "Monthly purchase cannot be less than daily purchase."
                      )
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder="Enter amount"
              type="number"
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          style={{
            display: "inline-block",
            width: "calc(50% - 4px)",
            margin: "10px 0 0 4px",
            float: "right",
          }}
        >
          <CTAButton
            onClick={form.submit}
            type="primary"
            style={{ height: 44, marginTop: 20 }}
          >
            Update
          </CTAButton>
        </Form.Item>
        <Form.Item
          style={{
            display: "inline-block",
            width: "calc(50% - 4px)",
            margin: "0 0 0 4px",
            float: "left",
            padding: "10px 0 20px 0",
          }}
        >
          <CTAButton
            type="outline"
            style={{ height: 44, marginTop: 20 }}
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </CTAButton>
        </Form.Item>
      </Form>
    </Spin>
  );
}
