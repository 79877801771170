import { createContext } from "react";
import { usePATransactions, usePASettings } from "./reducers";

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const paymentAcceptanceSettings = usePASettings();
  const paymentAcceptanceTransactions = usePATransactions();
  const store = {
    paymentAcceptanceSettings,
    paymentAcceptanceTransactions,
  };
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
