const MCC = [
  {
    category: "Agricultural Services",
    min: 700,
    max: 999,
  },
  {
    category: "Contracted services",
    min: 1500,
    max: 2999,
  },
  {
    category: "Transportation",
    min: 4000,
    max: 4799,
  },
  {
    category: "Utilities",
    min: 4800,
    max: 4999,
  },
  {
    category: "Retail outlets",
    min: 5000,
    max: 5499,
  },
  {
    category: "Automobiles and vehicles",
    min: 5500,
    max: 5599,
  },
  {
    category: "Clothing outlets",
    min: 5600,
    max: 5699,
  },
  {
    category: "Miscellaneous outlets",
    min: 5700,
    max: 5999,
  },
  {
    category: "Service providers	",
    min: 6000,
    max: 7299,
  },
  {
    category: "	Business services",
    min: 7300,
    max: 7529,
  },
  {
    category: "	Repair services	",
    min: 7530,
    max: 7799,
  },
  {
    category: "Amusement and entertainment	",
    min: 7800,
    max: 7999,
  },
  {
    category: "Professional services and membership organizations	",
    min: 8000,
    max: 8999,
  },
  {
    category: "	Government services	",
    min: 9200,
    max: 9402,
  },
];
const COLORS = [
  "#5150B3",
  "#2E2E66",
  "#B9B9FF",
  "#8F8EFF",
  "#D5D5FF",
  "#7372FF",
  "#4140B3",
  "#4444F9",
  "#E4E4FA",
  "#64A7EE",
  "#7F4CDF",
];
const MAX_RADIUS = 19;

export { COLORS, MAX_RADIUS, MCC };
