import React from "react";

const AccountAgreement = () => {
  return (
    <>
      <h2>
        IMPORTANT – PLEASE READ CAREFULLY. THIS AGREEMENT (AS DEFINED BELOW)
        CONTAINS AN ARBITRATION PROVISION (IN SECTION M BELOW) REQUIRING ALL
        CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION.
      </h2>
      <h2>TABLE OF CONTENTS</h2>
      <ol className="upper-alpha">
        <li>
          <b>Introduction; Account Opening Disclosures.</b>
        </li>
        <li>
          <b>
            Consent to Use Electronic Signatures, Communications and Statements
          </b>
        </li>
        <li>
          <b>The Account, Generally</b>
        </li>
        <li>
          <b>Interest Information</b>
        </li>
        <li>
          <b>Deposits to the Account</b>
        </li>
        <li>
          <b>Withdrawals from the Account</b>
        </li>
        <li>
          <b>Funds Availability</b>
        </li>
        <li>
          <b>Electronic Funds Transfer Disclosures</b>
        </li>
        <li>
          <b>Wire Transfer Services</b>
        </li>
        <li>
          <b>Additional Information Regarding Card</b>
        </li>
        <li>
          <b>ACH Origination</b>
        </li>
        <li>
          <b>Instant Payments</b>
        </li>
        <li>
          <b>Miscellaneous</b>
        </li>
      </ol>
      <ol className="upper-alpha">
        <li>
          <h3>Introduction; Account Opening Disclosures.</h3>
          <p>
            <b>
              Platform Legal Name (“Platform”) is providing this Commercial
              Interest Checking Account Agreement (this “Agreement”) to you on
              behalf of Regent Bank (“Bank”), a member of the Federal Deposit
              Insurance Corporation (“FDIC”). The Bank is the provider of the
              interest-bearing checking account (the “Account”). Bank is also
              the issuer of the MasterCard® Debit Card (“Card”) pursuant to a
              license by Mastercard International Incorporated. As used in this
              Agreement the words “we”, “our”, and “us” refer to Bank, our
              successors, affiliates, or assignees, and the words “you” and
              “your” refer to you, the individual or legal entity, and the
              Account holder and Card holder, who agrees to be bound by this
              Agreement, as well as anyone else with the authority to deposit,
              withdraw, or exercise control over the funds in the Account and
              anyone else that you authorize to use a Card.
            </b>
          </p>
          <p>
            <b>
              If enabled by the Platform, the Card is linked to the Account. Use
              of the Account and Card is governed by this Agreement. Access to
              your Account and the services under this Agreement are available
              only through the website and/or phone application (collectively,
              the “Platform App”) of Otterz, Inc. (“Platform”). Platform is
              responsible for making the Platform App available to you and as a
              result, some services under this Agreement may not be available to
              you. You should review your agreement with Platform for a complete
              list of services available. For a list of the services enabled and
              made available to you by the Platform, please visit the Platform
              App.
            </b>
          </p>
          <p>
            <b>
              Access to your Account and the services offered under this
              Agreement shall be accessed through the Platform App unless we
              notify you otherwise. YOU UNDERSTAND THAT BY OPENING AN ACCOUNT
              THROUGH THE PLATFORM APP, YOU AUTHORIZE BANK TO ACCEPT ALL
              INSTRUCTIONS PROVIDED TO BANK BY PLATFORM ON YOUR BEHALF.
            </b>
          </p>
          <table>
            <caption>
              <b>
                <u>Account Opening Disclosures</u>
              </b>
            </caption>
            <tr>
              <td>Initial Deposit and Balance</td>
              <td>
                There is no initial deposit or minimum balance required to open
                this Account.
              </td>
            </tr>
            <tr>
              <td>Interest Rate</td>
              <td>Subject to APY</td>
            </tr>
            <tr>
              <td>Annual Percentage Yield (“APY”)</td>
              <td>Variable based on APY</td>
            </tr>
            <tr>
              <td>Fees to Bank</td>
              <td>There are no fees charged by Bank for this Account.</td>
            </tr>
            <tr>
              <td>Transaction Limitations</td>
              <td>
                The transaction limitations for your Accounts are set forth in
                Sections E and F.
              </td>
            </tr>
            <tr>
              <td>Additional Fee Disclosure</td>
              <td>
                As noted above, you are not responsible for paying any fees to
                Bank for holding this Account. You may be responsible for paying
                fees to the Platform as provided in your agreement with
                Platform. You should refer to your agreement with Platform to
                understand how fees are charged.
              </td>
            </tr>
          </table>
          <p>
            This is an interest-bearing account. You may deposit any amount
            after you open the Account. The interest rate and APY is correct as
            of today’s date, but is subject to change upon notice from the
            Platform (including via posting on the Platform App).
          </p>
        </li>
        <li>
          <h3>
            Consent to Use Electronic Signatures, Communications and Statements.
          </h3>
          <div>
            <b>Introduction.</b>
            <span>
              {" "}
              In order to apply for an Account, you must consent to the use of
              electronic signatures and electronic records for all transactions
              with us and for all agreements, disclosures, notices and other
              Communications (as defined below) relating to those transactions.
              Once you have given your consent, we may deliver or make any of
              the documents or Communications available to you by posting them
              in the Platform App and sending you an email notification when
              they become available.
            </span>
          </div>
          <div>
            <b>Your Legal Rights.</b>
            <span>
              {" "}
              Certain laws require us to provide specific information to you in
              writing, which means you have a right to receive that information
              on paper. We may provide such information to you electronically if
              we first obtain your consent to receive the information
              electronically. Your consent will also apply to any other person
              named on your Account, product or service, subject to applicable
              law.
            </span>
          </div>
          <div>
            <b>Your Consent to Electronic Signatures.</b>
            <span>
              {" "}
              By accepting this Agreement, you understand that: (i)
              electronically signing and submitting any document(s) to Bank
              legally binds you in the same manner as if you had signed in a
              non-electronic form, and (ii) the electronically-stored copy of
              your signature, any written instruction or authorization and any
              other document provided to you by Bank is considered to be a true,
              accurate and complete record, and legally enforceable in any
              proceeding to the same extent as if such documents were originally
              generated and maintained in printed form. By accepting this
              Agreement, you also acknowledge and agree that you have read,
              understand and agree to all the terms of this Section B, can
              access the Communications in electronic form, affirmatively
              consent to the use of electronic signatures and electronic
              records, and have an active email account. You agree not to
              contest the admissibility or enforceability of Bank’s
              electronically stored copy of this Agreement and any other
              documents.
            </span>
          </div>
          <div>
            <b>Your Review of Communications.</b>
            <span>
              {" "}
              Please review promptly all Communications we deliver or make
              available to you. If Communications are mailed to you, they will
              be delivered to you at the postal address shown in our records. If
              Communications are sent to you electronically, they will be
              delivered to you at the email address shown in our records or made
              available to you on the Platform App. We will retain printable
              versions of your Account statements for seven (7) years or longer
              periods as may be required by applicable law. You agree to give us
              and Platform notice of any change of your postal or email address.
              If you fail to update or change an incorrect email address or
              other contact information, you understand and agree that any
              Communication shall nevertheless be deemed to have been provided
              to you if it was made available to you in electronic form in the
              Platform App or you were provided email notification of its
              availability.
            </span>
          </div>
          <div>
            <b>
              Reporting to You (Statements); Errors and Disputes. Statements
              will be made available to you to view and/or print on the Platform
              App (if available). We will send an email notification when the
              statements are available online on a periodic basis at
              approximately monthly intervals. The Account statement will
              describe each credit or debit (including Card transactions), date
              of credit or debit. You may view your statement by logging into
              Platform’s website and/or mobile application.
            </b>
            <span>
              {" "}
              Electronically delivered statements will provide all information
              that would be available in paper statements. Account statements
              will be considered to be correct unless you notify us, through
              Platform, of any errors within sixty (60) days of becoming
              available. Carefully review your statement each statement cycle
              and notify us of any errors within sixty (60) days of your
              statement becoming available. Bank will not be liable to you for
              any error that you do not report to Bank within that period of
              sixty (60) days. If you think your statement is wrong or if you
              need more information about a transaction listed on it, please
              contact Platform, at the customer service contact information set
              forth in Section M below. In making such communication, you need
              to tell us: (i) your name and Account number; (ii) why you believe
              there is an error and the dollar amount involved; and (iii)
              approximately when the error took place. If you need more
              information about our error resolution process, contact customer
              service (at the customer service contact information set forth in
              Section M below).
            </span>
          </div>
          <div>
            <b>Hardware and Software Requirements.</b>
            <span>
              {" "}
              To access and retain electronic records of the Communications, you
              must use computer hardware and software that meets the following
              requirements: a Current Version (defined below) of an Internet
              browser we support; a connection to the Internet; a Current
              Version of a program that reads and displays PDF files (such as
              Adobe Acrobat Reader); and a computer or mobile device with an
              operating system capable of supporting all of the above. By
              "Current Version," we mean a version of the software that we
              support and that is currently being supported by its publisher. To
              print or download electronic records of Communications, you must
              have a connected printer or sufficient space on a long-term
              storage device.
            </span>
          </div>
        </li>
        <li>
          <h3>The Account, Generally.</h3>
          <div>
            <b>
              Eligibility. The Account is available to legal entities with an
              Employer Identification Number, and individuals who are citizens,
              permanent residents or non-permanent resident aliens in the United
              States on a valid long-term visa, at least eighteen (18) years of
              age, and with a valid Social Security Number or a Tax
              Identification Number and who will use the Account to pursue
              business purposes. All deposits and withdrawals must be in U.S.
              dollars. You must agree to accept electronic, rather than paper
              statements, as provided above. This means: (i) you must keep us
              supplied with your valid email address; and (ii) you must agree to
              accept electronic delivery of all account communications (such as
              end-of-year tax forms and electronic statements). If you do not do
              so, you may not open an Account. If you withdraw your consent, we
              may close your Account.
            </b>
          </div>
          <div>
            <b>Authorized Representatives.</b>
            <span>
              {" "}
              “Authorized Representatives” are persons with authority to act on
              your behalf. You may appoint Authorized Representatives and
              authorized signers only by following our separate procedures for
              doing so. Unless a restriction that we have acknowledged in
              writing is specifically stated in your authorizing resolution,
              each Authorized Representative: (i) will have authority to appoint
              the authorized signers on your Account, to appoint secondary
              cardholders, to add or delete authorized signers on your Account
              or secondary cardholders, to open additional accounts, to close
              your Account, and to give us any other instructions on your behalf
              with respect to your Account; and (ii) may do these things acting
              alone, unless we require otherwise. We may, but are not obligated
              to, accept oral instructions from your Authorized Representatives
              and authorized signers. As between you and us, all actions of your
              Authorized Representatives and authorized signers shall be deemed
              within the scope of such person’s authority. We are not required
              to verify or confirm the purpose or propriety of any action by
              your Authorized Representatives and authorized signers and are not
              responsible for any misapplication or misappropriation of funds by
              your Authorized Representatives and authorized signers. Authorized
              Representatives and the authorized signers they appoint shall have
              the authority to: (i) make withdrawals and, if offered as an
              Account feature by the Platform, to draw checks, drafts or other
              orders for the payment of money on your Account; (ii) make
              deposits and endorsements; and (iii) issue or cancel stop payment
              orders on your Account. The authority of each Authorized
              Representative or authorized signer shall remain in effect until
              we receive written notice of revocation by contacting Platform at
              the customer service contact information set forth in Section M
              below and we have a reasonable opportunity to act on such notice.
            </span>
          </div>
          <div>
            <b>
              Important Information about Procedures for Opening a New Account.
              To help the government fight the funding of terrorism and money
              laundering activities, Federal law requires all financial
              institutions to obtain, verify, and record information that
              identifies each person who opens an Account. In the case that an
              account is opened by a legal entity, we will obtain, verify, and
              record information on all ultimate beneficial owners of such legal
              entity. What this means for you:
            </b>
            <span>
              {" "}
              When you open an Account, we will ask for the names, addresses,
              dates of birth, and other information concerning each principal
              owner that will allow us to identify the Account owner and its
              principal owners. We may also ask to see a copy of each principal
              owner’s driver’s license or other identifying documents.
            </span>
          </div>
          <div>
            <b>Account.</b>
            <span>
              {" "}
              The Account consists of an interest-bearing checking account used
              to hold your deposits and make payments and transfers to and from
              the Account. You will access your Account via the Platform App. If
              made enabled by Platform, the Account may include the use of a
              Card, which is linked to the Account for purposes of making
              payments and transfers to third parties. Additional information
              relating to use of the Card is set forth in Section J.
            </span>
          </div>
          <div>
            <b>Password Security.</b>
            <span>
              {" "}
              You are responsible for maintaining adequate security and control
              of any and all user IDs, passwords, hints, personal identification
              numbers (“PINs”), or any other codes that you use to access the
              Account or Card. If you permit any other person(s), including any
              data aggregation service providers, to access or use your user
              IDs, passwords, hints, PINs, or any other codes that you use to
              access the Account or Card, you are responsible for any
              transactions and activities performed from your Account or Card
              and for any use of your personal and account information by such
              person(s). Do not discuss, compare, or share information about
              your account number or password unless you are willing to give
              them full use of your money. Any loss or compromise of the
              foregoing information and/or your personal information may result
              in unauthorized access to your Account or Card by third parties
              and the loss or theft of any funds held in your Account and any
              associated accounts. Checks and electronic withdrawals are
              processed by automated methods, and anyone who obtains your
              Account number, Card or access device could use it to withdraw
              money from your Account, with or without your permission. You are
              responsible for keeping your email address and telephone number up
              to date in order to receive any notices or alerts that we may send
              you. We assume no responsibility for any loss that you may sustain
              due to compromise of your account login credentials due to no
              fault of Bank and/or your failure to follow or act on any notices
              or alerts that we may send to you. You agree to promptly review
              all Account and transaction records and other Communications that
              we make available to you and to promptly report any discrepancy to
              us.
            </span>
          </div>
          <div>
            <b>
              Fee Disclosure. Except as set forth in the Fee Schedule, you are
              not required to pay Bank any fees in connection with this Account.
              Platform may charge additional transaction fees and other fees
              associated with the services provided to you as provided in your
              agreement with Platform.
            </b>
          </div>
          <div>
            <b>Location of the Account.</b>
            <span> Your Account is established in the State of Oklahoma.</span>
          </div>
          <div>
            <b>Internet Gambling; Illegal Transactions.</b>
            <span>
              {" "}
              We may, but are not required to, deny authorization for any
              internet gambling transactions. You agree not to use your Account,
              Card or our services for online gambling or any illegal activity.
              We may refuse to process any transaction that we believe may
              violate the terms of this Agreement or applicable law. You
              acknowledge and agree that we have no obligation to monitor,
              review or evaluate the legality of your transactions and Account
              activity. You agree that using Bank services or your Account or
              Card for illegal activity will be deemed an action of default
              and/or breach of contract and, in such event, our services and/or
              your Account may be terminated at our discretion. You further
              agree that should illegal use occur, you waive any right to sue us
              for such illegal use or any activity directly or indirectly
              related to it, and you agree to indemnify and hold us harmless
              from any suits, legal action, or liability directly resulting from
              such illegal use. To the fullest extent permitted by law, you
              agree to pay for any transaction that you authorized, even if that
              transaction is determined to be illegal.
            </span>
          </div>
          <div>
            <b>
              Freezes, Blocking or Closing Accounts Due to Irregular or Unlawful
              Activities.
            </b>
            <span>
              {" "}
              You agree that if Bank suspects that any irregular, unauthorized,
              or unlawful activity may be occurring in connection with your
              Account or Card, Bank may “freeze” or place a hold on the balance
              in the Account pending an investigation of such activities. If
              Bank freezes your Account, Bank will give any notice required
              under the circumstances by the laws governing the Account. If
              Bank’s investigation confirms Bank’s suspicions of irregular,
              unauthorized, or unlawful activity then, notwithstanding anything
              to the contrary in this Agreement, Bank may immediately close your
              Account and Card, and may also close any or all other accounts, if
              necessary, to comply with applicable law. You agree that Bank may
              also freeze, block, or close your Account as necessary in order to
              comply with regulations issued by the United States Department of
              Treasury’s Office of Foreign Assets Control (“OFAC”).
            </span>
          </div>
        </li>
        <li>
          <h3>Interest Information.</h3>
          <div>
            <b>Which Accounts Bear Interest.</b>
            <span>
              {" "}
              Your Account will bear interest as described in this Section D.
            </span>
          </div>
          <div>
            <b>Rate Information.</b>
            <span>
              {" "}
              The initial interest rate and APY for the Account appear in the
              Account Opening Disclosures. This is a variable rate account. We
              may, at our discretion, change the interest rate and APY for your
              Account at any time; this may be changed daily. Platform will
              notify you of any change in the interest rate and APY for your
              Account by posting the new interest rate on the Platform App.
            </span>
          </div>
          <div>
            <b>
              Accrual of Interest. Interest begins to accrue no later than the
              business day the deposit is applied to your Account. Interest will
              be compounded daily and credited to your Account on a monthly
              basis. Account interest is calculated using the daily balance
              calculation method. This method applies a daily periodic rate to
              the principal balance in the Account each day.
            </b>
            <span>
              {" "}
              There are no minimum or maximum balance restrictions on your
              Account.
            </span>
          </div>
          <div>
            <b>Forfeit of Uncredited Interest.</b>
            <span>
              {" "}
              If you close your Account after interest has accrued but before it
              is credited to your Account, you will forfeit that interest, and
              it will not be credited to your Account.
            </span>
          </div>
        </li>
        <li>
          <h3>Deposits to your Account.</h3>
          <div>
            <b>Deposits to the Account.</b>
            <span>
              {" "}
              You can make deposits into your Account using any of these methods
              (if enabled by the Platform):
            </span>
          </div>
          <table className="dashed-border">
            <thead>
              <tr>
                <th>
                  <u>Transaction Type</u>
                </th>
                <th>
                  <u>Frequency and Dollar Amounts/Per Day*</u>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Direct deposits or Automated Clearing House (“ACH”) transfers
                  initiated from an outside financial institution
                </td>
                <td>$10,000</td>
              </tr>
              <tr>
                <td>
                  ACH transfers (debit) initiated from us or a linked bank
                  account
                </td>
                <td>10,000</td>
              </tr>
              <tr>
                <td>Remote Deposit Capture (RDC)</td>
                <td>No Limit</td>
              </tr>
              <tr>
                <td>Internal Transfers</td>
                <td>No Limit</td>
              </tr>
            </tbody>
          </table>
          <p>
            * The limits included here are the highest limits allowed for
            transactions. However, we reserve the right to allow you to transact
            higher volume than the limits defined herein at any time without
            prior notice. In certain cases, for security reasons, we may lower
            your limits upon notice to you at the time you attempt to initiate a
            transaction. Limits for Instant Account Funding are described in
            Section L below.
          </p>
          <ol className="lower-alpha">
            <li>
              <div>
                <u>Linking Bank Accounts.</u>{" "}
                <span>
                  If enabled by Platform for your Account, you may link an
                  account with us or an external account at a third-party
                  financial institution for online transfers between your linked
                  account(s) and your Account. If enabled by Platform for your
                  Account, you may link your external account(s) with your
                  Account by (i) logging into your financial institution on the
                  Platform App, or (ii) by providing the account and routing
                  details for the external account and verifying the two (2)
                  micro deposits we send to your external account the next
                  business day. We may also verify your control of the external
                  account by requiring you to submit proof of ownership of the
                  external account(s). All linked accounts must be with
                  financial institutions in the United States. We may decline
                  the use of any external account that we believe may present a
                  risk to you and/or us. By linking your external account to
                  your Account, and by subsequently logging into your linked
                  account(s) through the Platform App, you authorize us to view
                  your account history and profile, including, but not limited
                  to, your account and routing details, authentication details,
                  balance, transaction history, contact information, and other
                  related information made available by such external financial
                  institution; and you understand this information may be used
                  to transact on your behalf and perform other services. When
                  adding an external account, you represent and warrant that you
                  are the owner of, and have the right to access, use and
                  authorize us to use, the external account for information and
                  funds transfer purposes. If any of your linked accounts has a
                  joint account holder, you represent and warrant that the joint
                  account holder has consented for you to represent both you and
                  them, and to use the external account with the Platform
                  services. If you do not have such consent, you should not use
                  that external account and we will terminate your use of the
                  linking service if we are notified of such a situation. If you
                  close any of your external accounts, you are responsible for
                  removing it as an account eligible for the linking service to
                  avoid any transaction failure and possible charges related to
                  a failed transaction. We are not responsible for any acts or
                  omissions by the external financial institution or other
                  provider of any linked external bank account, including,
                  without limitation, any modification, interruption, or
                  discontinuance of any linked external bank account by such
                  financial institution, service provider or Platform.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Direct Deposits.</u>{" "}
                <span>
                  If enabled by Platform for your Account, your Account number
                  and bank routing number may be used for the purpose of
                  initiating direct deposits to your Account. The recipient’s
                  name on any direct deposit we receive must match your name.
                  Any direct deposit received in a name other than the name
                  registered to the Account will be returned to the originator.
                  If your Account number changes you must immediately notify any
                  payors. You must provide them with the new Account number to
                  ensure that your direct deposit activity continues
                  uninterrupted. If you have arranged to have direct deposits
                  made to your Account at least once every sixty (60) days from
                  the same person or company, you can contact Platform at the
                  customer service contact information set forth in Section M
                  below to find out whether or not the deposit has been made.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Account Funding with Cards.</u>{" "}
                <span>This feature is not enabled</span>
              </div>
            </li>
            <li>
              <div>
                <u>Remote Deposit Capture (“RDC”).</u>{" "}
                <span>
                  If enabled by Platform for your Account, you may make deposits
                  into your Account by using the Platform App to take a legible
                  picture of the front and back of a negotiable check and
                  transmitting such images. We will attempt to collect the item
                  by presenting the image or converting the image into a digital
                  representation of the original check (a “Substitute Check”).
                  Unlike traditional check deposits, you retain the original
                  paper check when you use Remote Deposit Capture. We request
                  you to retain the original check until final settlement of the
                  check. There is currently no charge for using RDC; should
                  there be charges in the future, you will be given appropriate
                  notice. Your wireless telecommunications provider for your
                  wireless device or other third parties that you may utilize
                  may impose fees to make that device data-capable, to exchange
                  data between the device and the Platform, or based on the
                  location of your use. By using the RDC service, you agree that
                  you will be bound by the terms of this Agreement and will
                  follow any and all other procedures and instructions for use
                  of RDC that we may establish from time to time.
                </span>
              </div>
              <p>
                You agree to scan and deposit only checks, as that term is
                defined in Federal Reserve Regulation CC (“Reg. CC”). You agree
                that the image of the check transmitted to us shall be deemed an
                “item” within the meaning of Article 4 of the Uniform Commercial
                Code as adopted in the State of Oklahoma. You agree that you
                will not use RDC to scan and deposit, and you agree that we are
                not required to accept for deposit, any of the following checks
                or other items: checks or items payable to any person or entity
                other than you, including a check payable to “Cash”; checks or
                items containing obvious alteration to any of the fields on the
                front of the check or item, or which you know or suspect, or
                should know or suspect, are fraudulent or otherwise not
                authorized by the owner of the account on which the check or
                item is drawn; checks or items previously converted to a
                substitute check, as defined in Reg. CC; checks or items drawn
                on a foreign bank or payable in a foreign currency; checks or
                items that are demand drafts or remotely created checks (checks
                lacking the original signature of the drawer); checks that have
                been previously returned stop payment or account closed; and
                checks or items dated more than six (6) months prior to the date
                of deposit.
              </p>
              <p>
                The image of a check transmitted to us using RDC must be
                legible. The image quality of the check must comply with the
                requirements established from time to time by the American
                National Standards Institute, the Board of Governors of the
                Federal Reserve Board, or any other regulatory agency, or other
                clearinghouses. The checks to be deposited via RDC shall be
                properly endorsed in the same manner in which it is made payable
                to you and with the restrictive endorsement: “For mobile deposit
                only.” Receipt of your check image must be received by 3 pm
                Eastern Time, for us to consider that day to be the day of your
                deposit. Any check image received by us after 3 pm Eastern Time
                is considered as a deposit made on the next business day we are
                open.
              </p>
              <p>
                We reserve the right to reject any item transmitted through RDC,
                at our discretion, without liability to you. We are not
                responsible for items we do not receive or for images that are
                dropped during transmission. You agree to receive notices
                electronically relating to RDC, whether or not you have
                previously agreed to accept electronic disclosures for your
                Account. An image of an item shall be deemed received when you
                receive an electronic confirmation from us that we have received
                the image. Receipt of such confirmation does not mean that the
                transmission was error free or complete. As to all checks
                transmitted to us through RDC, you represent and warrant that:
                (i) you will comply with the terms and conditions set forth in
                this Agreement; (ii) you will only transmit eligible items
                described above in this paragraph; (iii) you have good title to
                each check and no defense of any party to the check is good
                against you; (iv) the original check, or a paper or electronic
                representation, has not previously been deposited for collection
                with us or any other financial institution, and no depositary
                bank, drawee, or drawer will be asked to pay a check that it
                already has paid; (v) you have no knowledge or notice of
                information to indicate that the transaction is fraudulent; (vi)
                all necessary signatures and endorsements have been placed on
                the check and are genuine; and (vii) the check has not been
                materially altered. If any such representation or warranty is
                breached, we may deduct the amount of the check from any of your
                accounts or otherwise collect from you this amount plus
                expenses. You agree to indemnify and hold us, our affiliates,
                directors, officers, employees, and agents harmless from and
                against all losses, liabilities, cost, damages and expenses
                (including reasonable attorneys’ fees and cost of litigation) to
                which we may be subject or that we may incur in connection with
                any claims that might arise from or out of your use of RDC,
                including, but not limited, your breach of any representation or
                warranty in this Section. You agree to notify us, through
                Platform, of any suspected errors regarding checks deposited
                through RDC right away, and in no event later than forty (40)
                days after the applicable account statement is sent or made
                available to you. Unless you notify us within forty (40) days,
                such statement regarding all deposits made through RDC shall be
                deemed correct, and you are prohibited from bringing a claim
                against us for such alleged error.
              </p>
            </li>
            <li>
              <div>
                <u>Wire Transfers.</u>
                <span>
                  {" "}
                  If enabled by Platform for your Account, you may fund your
                  Account with a wire transfer. When we accept a wire transfer
                  payment order instructing payment to you or to your Account,
                  we will notify you of our receipt of payment by indicating the
                  amount in your Account statement. Your Account statement will
                  be the only notice of receipt which we will provide you, and
                  no interest will be paid on wire transfer payments deposited
                  into your account unless the account otherwise pays interest.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>No Deposits in Cash, Paper Checks or Foreign Currency.</u>
                <span>
                  {" "}
                  Bank will only accept funds deposited electronically through
                  Platform. We are not liable for any deposits (including cash)
                  lost in the mail, lost in transit, or not received by us. We
                  do not accept (i) deposits in (1) cash, cashier’s checks or
                  money order or (2) foreign currency or (ii) deposits of any
                  type of check via mail. If we receive any of those instruments
                  by mail, we will return it to the address we have for you on
                  file. Only deposits made in accordance with the terms of this
                  Agreement will be accepted.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>
                  Our Right to Charge Back Deposited Checks or Electronic
                  Transfers.
                </u>
                <span>
                  {" "}
                  If you deposit a check or receive an electronic transfer as
                  provided in this Agreement and (i) the paying bank returns it
                  to us unpaid; (ii) the paying bank or the issuer of a check
                  demands that we repay them because the check was altered,
                  forged or unauthorized, is missing a signature or endorsement,
                  or has a forged endorsement; or (iii) the sending bank or the
                  originator of an item demands that we return the item because
                  it was unauthorized, sent to the wrong account number or
                  procured by fraud, we may pay the return or demand, and
                  subtract the funds from your Account, even if the balance in
                  your Account is not sufficient to cover the amount we subtract
                  or hold, causing an overdraft. If we have reason to believe
                  that any of the events in the previous sentence have occurred
                  or may occur or that the check or other item should not have
                  been paid or may not be paid for any other reason, we may
                  place a hold on the funds or move them to a non-customer
                  account until we determine who is entitled to them.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Right to Reject Any Deposit.</u>
                <span>
                  {" "}
                  We may refuse any check for deposit, with or without cause, or
                  may elect to take a check on a collection basis only. We are
                  under no obligation to accept any check, item, wire,
                  electronic funds transfer, or other transaction for deposit to
                  your Account or for collection, and we may refuse to cash or
                  give value for any such transaction. We may restrict access to
                  any deposit credited to your account that violates any laws of
                  the United States, including those giving rise to OFAC
                  sanctions. Unless Bank specifically permits you to do so, you
                  may not deposit any substitute check that has not been
                  previously handled by a bank in the collection process. This
                  means you cannot deposit a substitute check you create, or one
                  that is created by another person, unless we enter into an
                  agreement to do so. Nevertheless, if a substitute check is
                  received for deposit, you will be responsible for any losses
                  you or another person suffers relating to that substitute
                  check.
                </span>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>Withdrawals from the Account.</h3>
          <p>
            In addition to those withdrawals using your Card (if applicable)
            described in Section J below and Instant Push to Card Transactions
            described in Section L below, you can make withdrawals from your
            Account using any of these methods (if enabled by the Platform):
          </p>
          <table className="dashed-border">
            <tr>
              <th>
                <u>Transaction Type</u>
              </th>
              <th>
                <u>Frequency and Dollar Amounts/Per Day*</u>
              </th>
            </tr>
            <tbody>
              <tr>
                <td>ACH transfers to an external bank account or payee</td>
                <td>10,000</td>
              </tr>
              <tr>
                <td>Debit Card Payments </td>
                <td>$3,000</td>
              </tr>
            </tbody>
          </table>

          <p>
            * The limits included here are the highest limits allowed for
            transactions. However, we reserve the right to allow you to transact
            higher volume than the limits defined herein at any time without
            prior notice. In certain cases, for security reasons, we may lower
            your limits upon notice to you at the time you attempt to initiate a
            transaction. Limits for Instant Push to Card are described in
            Section L below.
          </p>
          <ol>
            <li>
              <div>
                <u>No Overdrafts.</u>
                <span>
                  You are not permitted to overdraw your Account. If the
                  available balance in your Account is at any time not
                  sufficient to cover any payment or withdrawal you have
                  authorized, we may refuse to process the payment or
                  withdrawal. If your Account balance becomes negative for any
                  reason, you must make a deposit immediately to cover the
                  negative balance. If your Account has a negative balance of
                  (i) $__or more, it will be closed immediately and (ii) less
                  than $__ for thirty (30) calendar days or more it will be
                  closed. If you fail to pay the amount of any overdraft, we
                  reserve the right to refer your overdrawn Account to an
                  attorney for collection, and you agree to pay all reasonable
                  expenses, including, but not limited to, reasonable attorney’s
                  fees and court costs, incurred by us as a result of your
                  Account being overdrawn.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Notice of Furnishing Negative Information.</u>
                <span>
                  We may report information about your Account to credit
                  bureaus. Unpaid overdrafts may be reflected in your credit
                  report.
                </span>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>Funds Availability.</h3>
          <div>
            <b>Availability. </b>
            <span>
              We make funds available according to the type of deposit and when
              the funds are applied or credited to your Account. Some types of
              deposits may not be available for immediate use. When we delay the
              availability of funds or place a hold on a deposit made to your
              Account, you may not withdraw those funds, and we will not use
              them to pay any debits, such as ACH transfers or payments, check
              payments or, if available, transactions using your debit card
              during the deposit hold period. We have the right to refuse any
              deposit. If final payment is not received on any check you have
              deposited into your Account for which you have received credit, or
              if any direct deposit, ACH transfer or other transfer for which
              you have received credit is not paid for any reason, you agree to
              pay us the amount for which you have received credit. The length
              of the delay in the availability of funds will vary depending on
              the type of deposit.
            </span>
          </div>
          <div>
            <b>Business Days. </b>
            <span>
              The length of the delay in the availability of funds is counted in
              business days from the day your deposit is applied to your
              Account. For purposes of these disclosures, our business days are
              Monday through Friday. Federal holidays are not included. Deposits
              received after the cut-off times provided in this Agreement or on
              a day Bank is not open will be processed the following business
              day that Bank is open.
            </span>
          </div>
          <div>
            <b>Same Day Availability. </b>
            <span>
              Funds received from preauthorized electronic payments will be
              available on the day we receive the deposit. Funds from ACH
              credits that we receive from a verified external bank account will
              be available on the day we receive the deposit.
            </span>
          </div>
          <div>
            <b>Longer Availability. </b>
            <span>
              Electronic transfers depositing into the Account initiated through
              the Platform may take up to five (5) business days from the date
              of the initial request but will post on the payment date of the
              deposit once the money has reached us.
            </span>
          </div>
          <div>
            <b>Availability of Mobile Deposits. </b>
            <span>
              Checks deposited through the RDC service are not subject to the
              funds availability requirements of Regulation CC. Checks deposited
              using the RDC service will generally be made available in your
              Account no later than six (6) business days after the day you made
              the deposit. In some cases, we may not make the funds available in
              accordance with the general policy for other reasons, including,
              but not limited to, if we have reasonable cause to doubt the
              collectability of the check. The length of the delay will be
              counted in business days from the day of your deposit. If your
              deposit is declined, we will email you the notice by the first
              business day after the day we receive your deposit. We reserve the
              right to change the limits on the amount(s) and/or number of
              deposits that you transmit using RDC and may modify these limits
              from time to time.
            </span>
          </div>
          <div>
            <b>
              How Your Account Balance and Available Balance are Determined.{" "}
            </b>
            <span>
              How Your Account Balance and Available Balance are Determined. To
              avoid a negative balance and/or Bank’s refusal to process a
              transaction, it is necessary to understand how your Account
              balance is determined to avoid having non-sufficient funds in your
              Account. Your Account has two kinds of balances: the actual
              balance and the available balance. Your actual balance reflects
              the full amount of all deposits to your Account as well as payment
              transactions that have been posted to your Account. It does not
              reflect checks you have written and are still outstanding or
              transactions that have been authorized but are still pending. Your
              available balance is the amount of money in your Account that is
              available for you to use. Your available balance is your actual
              balance less: (1) holds placed on deposits; (2) holds on debit
              card or other transactions that have been authorized but are not
              yet posted; and (3) any other holds, such as holds related to any
              pledges of Account funds and any minimum balance requirements or
              to comply with legal process. We use your available balance to
              determine whether there are sufficient funds in your Account to
              pay items, including checks and drafts, as well as ACH, debit card
              and other electronic transactions, as applicable. Pending
              transactions and holds placed on your Account may reduce your
              available balance, in our sole discretion. Pending transactions
              and holds also may cause your Account to become overdrawn during
              the time the transaction is pending or a hold is in effect. You
              may check your available balance and actual balance on the
              Platform App, at an ATM, or by contacting Platform at the customer
              service contact information set forth in Section M below.
            </span>
          </div>
          <div>
            <b>How Transactions are Posted to Your Account. </b>
            <span>
              There are two types of transactions that affect your account:
              credits (deposits of money into your Account) and debits (payments
              out of your Account). It is important to understand how each is
              applied to your Account so that you know how much money you have
              and how much is available to you at any given time. This section
              explains generally how and when we post transactions to your
              Account. If we change these practices, we will provide you notice
              in accordance with this Agreement.
            </span>
          </div>
          <ol>
            <li>
              <div>
                <u>Credits.</u>
                <span>
                  {" "}
                  Deposits are generally added to your account when we receive
                  them. However, in some cases when you deposit funds, the full
                  amount of the deposit may not be available to you at the time
                  of deposit.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Debits.</u>
                <span>
                  {" "}
                  There are several types of debit transactions. Common debit
                  transactions are generally described below. There are many
                  ways transactions are presented for payment by merchants, and
                  we are not in control of when transactions are received. We
                  receive multiple data files every day from the Federal Reserve
                  with ACH transactions Monday through Friday. If the day is a
                  recognized federal holiday by the Federal Reserve, we may not
                  receive or process a file that day. For ACH transactions that
                  we receive at the same time, for example, all in the same data
                  file, ACH credit transactions are posted first and ACH debit
                  transactions are posted second in the order received by us.
                  Bank posts ACH transactions throughout the day Monday through
                  Friday, excluding federal holidays. PIN-Based Card purchase
                  transactions are purchase transactions using your Card for
                  which a merchant may require you to enter your personal
                  identification number (PIN) at the time of sale. They are
                  processed through a PIN debit network. These transactions are
                  similar to ATM withdrawal transactions because the money is
                  usually deducted from your available balance immediately at
                  the time of the transaction. However, depending on the
                  merchant, a PIN-based transaction may not be immediately
                  presented for payment. Signature-Based Card purchase
                  transactions are purchase transactions using your Card that
                  are processed through a signature-based network. Rather than
                  entering a PIN, you typically sign for the purchase; however,
                  merchants may not require your signature for certain
                  transactions.
                </span>
              </div>
            </li>
            <li>
              <div>
                <u>Authorization Holds.</u>
                <span>
                  {" "}
                  When you use your Card for certain transactions, the merchant
                  accepting your Card may request advance authorization of that
                  transaction, and we may place a hold on your Account. This may
                  be the case where you present your Card for payment at
                  restaurants, gas stations, hotels and certain other retail
                  establishments. In certain situations, certain kinds of
                  merchants are permitted to request authorization for a Card
                  transaction in an amount different from the amount of the
                  actual transaction. When we receive an authorization request
                  from a merchant, we do not receive information from which to
                  determine whether the request covers the actual amount of a
                  transaction, an estimated amount, or whether the merchant’s
                  request exceeds the amount permitted. Each hold will reduce
                  the available balance in your Account by the amount of the
                  hold, but will not affect your actual balance. In the event
                  the available balance in your Account (i.e., net of the hold)
                  is insufficient to pay items posted to your Account,
                  transactions will decline. An authorization hold is not
                  payment for an authorized transaction. The transaction is
                  subsequently processed by the merchant and submitted to us for
                  payment. We will make payment for a transaction only after the
                  actual transaction is presented to us physically or
                  electronically. This can happen hours or sometimes days after
                  the transaction, depending on the merchant and its payment
                  processor. These payment requests are received in real time
                  throughout the day and are posted to your Account when they
                  are received. Many purchases that are done through the
                  Internet or with a merchant where goods are ordered and to be
                  delivered at a future date will create an authorization at the
                  time of purchase but the transaction may not be sent to us for
                  posting until the merchandise is shipped. Any authorization
                  hold on your Account will be released upon the earlier of: (a)
                  the day we: (i) receive the actual transaction, (ii) post that
                  transaction to your Account for payment, and (iii) match the
                  authorization of that transaction to the actual transaction;
                  or (b) five (5) business days after the date we authorized the
                  transaction. The release of the hold will be in the form of a
                  credit to your Account for the amount of the original hold.
                  Credits for released holds and payments for Card transactions
                  are posted separately to your Account, and the credit for any
                  particular hold is not used to pay the particular transaction
                  for which the hold was originally placed.
                </span>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>Electronic Funds Transfer Disclosures.</h3>
          <div>
            <b>Electronic Transfers via ACH.</b>
            <span>
              {" "}
              If enabled by Platform, you may originate transfers to and from
              your Account via ACH as provided in Sections E and F of this
              Agreement. These requests must be made via the Platform. In the
              case of electronic transfer requests from an external bank account
              to your Account, we will complete such requests only if the funds
              are being transferred from your linked external account. Any
              deposits received in any other name may be returned to the
              originator.
            </span>
          </div>
          <div>
            <b>Next Day ACH. Cut-off Time.</b>
            <span>
              {" "}
              The cut-off time for scheduling a next day ACH transfer is 4 pm
              Central Time. Any next day ACH transfer scheduled after the
              cut-off time will be treated as if it were scheduled on the next
              business day. In some circumstances, transactions may be delayed
              for risk or compliance reasons.
            </span>
          </div>
          <div>
            <b>Same Day ACH. Cut-off Time.</b>
            <span>
              {" "}
              The cut-off time for scheduling same day ACH transfers (if enabled
              on your Account) is 12:30 pm Central Time. If enabled by Platform,
              the Same Day ACH means that the transfer will be performed on the
              same business day. Any same day ACH transfer scheduled after the
              cut-off time will be treated as if it were scheduled as next day
              ACH transfer. In some circumstances, transactions may be delayed
              for risk or compliance reasons.
            </span>
          </div>
          <div>
            <b>Electronic Transfers Using Your Account Number. </b>
            <span>
              If enabled by Platform, you may authorize a third party to
              transfer funds to and from your Account by providing your Account
              number and your routing number to such third party. Your Account
              information should only be provided to trusted third parties
              authorized to initiate the electronic funds transfers.
            </span>
          </div>
          <div>
            <b>Debit Cards and ATM Services. </b>
            <span>
              Your Card can be used for purchases at points of sales and/or
              withdrawals at ATMs.
            </span>
          </div>
          <div>
            <b>Types of Electronic Transfers Available. </b>
            <span>
              If enabled by Platform, you may arrange with another party to
              electronically deposit funds on a one-time or recurring basis
              directly into your Account. If enabled by Platform, you may
              arrange with another party to make one-time or recurring charges
              to your Account to pay bills.
            </span>
          </div>
          <div>
            <b>Limitations on Transfers, Amounts and Frequency. </b>
            <span>
              Your rights under this Agreement to make electronic funds
              transfers are subject to the frequency and dollar amount limits
              established in Sections E and F of this Agreement.
            </span>
          </div>
          <div>
            <b>
              Right to Receive Documentation of Electronic Funds Transfers.{" "}
            </b>
            <span>
              Your electronic funds transfers will be reflected on the
              statements that will be delivered to you through the Platform App.
              You can also contact Platform at the customer service contact
              information set forth in Section M below to obtain information
              about any particular deposit or transfer.
            </span>
          </div>
          <div>
            <b>
              Right to Stop Payment of Preauthorized Transfers and Procedures.{" "}
            </b>
            <span>
              If you have scheduled a one-time or a recurring ACH transfer via
              the Platform, you may stop or cancel that transfer by following
              the procedures defined here. To stop an ACH transfer that you
              initiated via the Platform App, either one-time or recurring, use
              the Platform App to cancel such payment, or contact Platform at
              the customer service contact information set forth in Section M
              below to request cancellation of the transfer. To stop a one-time
              or recurring transfer that you have authorized a third party to
              debit from the Account, please contact that third party to request
              the cancellation of the payment. You also may contact Platform at
              the customer service contact information set forth in Section M
              below to request a stop on the payment. If you wish to contact us
              to stop a one-time or recurring transfer that you initiated via
              the Platform App or that you have authorized a third party to
              debit from the Account, your request to stop the transfer must be
              received at least three (3) business days before the transfer is
              scheduled to occur. Regarding recurring payments, you should
              specify whether you wish to stop one recurring payment or all
              recurring payments. If you do not specify, all recurring payments
              may be stopped. You must specify the name of the payee, the dollar
              amount of the payment and the date of the payment. In the case of
              a recurring third-party debit authorization, you may contact us to
              stop one recurring payment. To stop all future payments pursuant
              to the third party's recurring authorization, you must put your
              initial oral request (if oral) in writing and send it to us within
              fourteen (14) days of your initial oral request. If the written
              stop payment notification is not received at least fourteen (14)
              days before the future payment is scheduled to occur, we will
              honor that and all other future payments as originally authorized
              without liability to you.
            </span>
          </div>
          <div>
            <b>Your Liability for Unauthorized Transfers. </b>
            <span>
              The Account is a commercial account and does not provide consumer
              protections for unauthorized transactions or unauthorized access
              to your Account, including by way of lost or stolen Account or
              Card numbers. Treat the Card like cash. If your Card or PIN is
              lost or stolen, or if you believe either your Card, PIN, or the
              Account number associated with your Card has been compromised or
              is subject to any unauthorized use, you must notify us
              immediately, and you agree to take any reasonable actions we may
              request to prevent further unauthorized Card use.{" "}
              <b>
                Until an Authorized Representative reports an Account or Card
                number as lost or stolen or reports an unauthorized transaction
                and we have had a reasonable amount of time to stop future
                transactions, the Account owner is fully responsible for all
              </b>{" "}
              transactions initiated and fees incurred from the unauthorized use
              of the Account or Card. Contact customer service IMMEDIATELY if
              you believe: (a) a PIN or an Account or Card number has been lost
              or stolen, (b) someone has gained unauthorized access to any
              information to access the Account or Card, or (c) someone has
              transferred or may transfer funds from the Account without an
              Authorized Representative's permission. Reporting a lost/stolen
              Account or Card number or unauthorized transactions by calling
              Platform at +1 857-234-4000, by emailing{" "}
              <a href="mailto:support@otterz.co" className="link">
                support@otterz.co
              </a>{" "}
              or by logging into the Platform App is the best way to minimize
              possible losses. Account owner is responsible for all unauthorized
              transactions initiated and fees incurred from the unauthorized use
              of the Account or Card. Failure to promptly notify us could result
              in the Account owner losing ALL of the money in the Account. If
              you have both a virtual and a physical Card and you report one of
              your Cards lost or stolen, both Cards will be canceled and new
              Cards will be issued.
            </span>
          </div>
          <p>
            You are responsible for all transactions initiated and fees incurred
            by use of the Account and Card. If you or your Authorized
            Representative permits another person to access the Account or Card,
            we will treat this as if you or your Authorized Representative has
            authorized such person to use the Account or Card, and you will be
            liable for all transactions and fees incurred by such person, even
            if they exceed the authorization granted. You will further be
            responsible for any transactions made and any fees incurred by your
            Authorized Representative even if your Authorized Representative
            exceeds the scope of the authority granted to such Authorized
            Representative by you. If you want to withdraw permission for an
            Authorized Representative to use the Account or Card, then
            transactions made with the Account or Card will be considered
            unauthorized only after you notify us that the person is no longer
            authorized to use the Account or Card and we have had a reasonable
            amount of time to stop future transactions by that Authorized
            Representative.
          </p>
          <div>
            <b>Our Liability for Failure to Complete Transactions.</b>
            <span>
              {" "}
              We are not liable for the failure to complete a transaction on the
              Account or Card if we send you notice that the transaction was not
              completed. We also are not liable for any failed transfer if: (a)
              through no fault of ours, you do not have enough funds available
              in the Account to cover a transaction; (b) the merchant refuses to
              accept the transaction or Card; (c) the ATM or device does not
              have enough cash or is not working properly; (d) if an electronic
              terminal where you are making a transaction does not operate
              properly, and you knew about the problem when you initiated the
              transaction; (e) the merchant requests authorization for an amount
              greater than the purchase amount; (f) access to your Account or
              Card or linked account has been blocked after you reported your
              Account or Card number or linked account number lost or stolen;
              (g) there is a hold or your funds are subject to legal or
              administrative process or other encumbrance restricting their use;
              (h) we have reason to believe the requested transaction is
              unauthorized; (i) we have received incomplete or inaccurate
              information from the third-party payor or payee; (j) if
              circumstances beyond our control (such as fire, flood, or computer
              or communication failure) prevent the completion of the
              transaction, despite reasonable precautions that we have taken; or
              (k) if there are other exceptions stated in this Agreement or as
              provided by law.
            </span>
          </div>
          <div>
            <b>Business Day.</b>
            <span>
              {" "}
              For purposes of this Agreement, our business days are Monday
              through Friday. Federal holidays are not included.
            </span>
          </div>
          <div>
            <b>Confidentiality.</b>
            <span>
              {" "}
              We will disclose information to third parties about your Account
              or the transfers you make (including transactions using your
              Card): (i) where it is necessary for completing transfers; (ii) in
              order to verify the existence and condition of your Account for a
              third party, such as a credit bureau or merchant; (iii) in order
              to comply with government agency or court orders; or (iv) if you
              give us your written permission.
            </span>
          </div>
          <div>
            <b>ACH Services and NACHA Rules.</b>
            <span>
              {" "}
              If Platform allows you to originate ACH transactions, Section K
              below sets forth additional provisions regarding your origination
              of ACH transactions.
            </span>
          </div>
        </li>
        <li>
          <h3>Wire Transfer Services</h3>
        </li>
        <li>
          <h3>Additional Information Regarding Card.</h3>
          <div>
            <b>Debit Card. </b>
            <span>
              If enabled by Platform, you may use a Card to access or use funds
              in your Account. The Card is a debit card that allows you to have
              access to your Account to perform electronic funds transfers. You
              must activate your Card prior to use. You can activate it by
              accessing the Platform App. You are responsible for all authorized
              transactions initiated and fees incurred in connection with use of
              the Card. If you permit another person to have access to the Card
              or your Card credentials, we will treat this as if you have
              authorized such use and you will be liable for all transactions
              and fees incurred by those persons. If you need to replace your
              Card for any reason, please deactivate the Card by notifying us
              via the Platform website and/or mobile application, by calling
              Platform at +1 8572344000, or by emailing Platform at _
              <a href="mailto:support@otterz.co" className="link">
                support@otterz.co
              </a>.
            </span>
          </div>
          <div>
            <b>Expiration Date. </b>
            <span>
              The expiration date of the Card is identified on the back of the
              Card. You agree to sign the back of the Card immediately upon
              receipt. The Card is our property and must be surrendered to us or
              discarded upon demand. The Card is a debit card. The Card is not a
              credit card. The Card is nontransferable, and it may be canceled,
              repossessed, or revoked at any time without prior notice subject
              to applicable law. We may refuse to process any transaction that
              we believe may violate the terms of this Agreement.
            </span>
          </div>
          <div>
            <b>Secondary Cardholder. </b>
            <span>
              You may be allowed to authorize additional Cards to be linked to
              your Account and provided to a secondary cardholder. We will treat
              the transactions initiated by the secondary cardholder as
              authorized by you and you will be liable for all transactions,
              fees and other liabilities related to such secondary cardholder.
            </span>
          </div>
          <div>
            <b>Virtual Card. </b>
            <span>
              You may have the option of accessing your Account with, in
              addition to your physical card, a virtual card represented by a
              16-digit account number (“Virtual Card”). The Virtual Card may
              either be single-use, meaning the 16-digit account number will
              expire after a single transaction, or recurring-use, meaning you
              may use your 16-digit Account number for multiple transactions.
              You may not use your Virtual Card to obtain cash anywhere.
            </span>
          </div>
          <div>
            <b>PIN. </b>
            <span>
              You will be provided with a personal identification number
              (“PIN”), which must be used in all automated teller machine
              (“ATM”) transactions and certain PIN debit transactions when
              requested at the point of sale. You should not disclose your PIN
              to anyone. If the security or confidentiality of the PIN is
              compromised, please notify Platform at Platform’s website and/or
              mobile application in order to block your Card. Alternatively, you
              may notify Platform at the customer service contact information
              set forth in Section M below.
            </span>
          </div>
          <div>
            <b>Spending and Withdrawals Limits.</b>
            <span>
              You may use your Card to withdraw cash from your Account, pay for
              purchases at places that have agreed to accept the Card, and pay
              bills directly from your Account in the amounts and on the days
              you request. There are transactional (dollar) limits per day
              imposed on your Card. The transactional limits imposed on your
              Card are provided in the table below. These limits are the maximum
              amounts that you can spend with your Card per day and are subject
              to periodic review and to change based on your Account history,
              activity, Platform’s reviews and other factors. We reserve the
              right to allow you to transact higher volume than the limits
              defined herein at any time without prior notice. Because of the
              limitations imposed by some ATMs and in the event of equipment
              failure or unavailability, you may not be able to withdraw or to
              access funds even though you have not exceeded these limitations.
              The daily period to which the transactional limits apply shall
              commence at 12:00 Midnight (Central Time) on each day and end at
              12:00 Midnight (Central Time) on the following day.
            </span>
          </div>
          <table>
            <thead>
              <tr>
                <th>Transaction Type</th>
                <th>Dollar Limit per Day</th>
                <th>Dollar Limit per Month</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Points of Sale (Merchant) Transactions</td>
                <td>N.A.</td>
                <td>$30,000</td>
              </tr>
              <tr>
                <td>ATM Cash Withdrawals</td>
                <td>$1000</td>
                <td>N.A.</td>
              </tr>
            </tbody>
          </table>
          <div>
            <b>Fees. </b>
            <span>
              [We do not charge any fees for the use or initial issuance of the
              Card. You may use your Card at ATMs and cash dispensers. In such
              cases, you might be charged a fee from such ATMs’ or
              cash-dispensers’ operators, networks or merchants. You may be
              allowed to fund your Account via reload at the register services
              at retailers within [the GreenDot® network]. You may be charged a
              fee from such retailer at the time of the transaction.]
            </span>
          </div>
          <div>
            <b>Foreign Transactions. </b>
            <span>
              If you make a purchase in a currency other than dollars, the
              amount deducted from your Account will be converted by Mastercard
              International Incorporated into dollars. The exchange rate between
              the transaction currency and the billing currency used for
              processing international transactions is a rate selected by
              Mastercard International Incorporated from the range of rates
              available in wholesale currency markets for the applicable central
              processing date, which may vary from the rate Mastercard
              International Incorporated itself receives, or the
              government-mandated rate in effect for the applicable central
              processing date.
            </span>
          </div>
          <div>
            <b>Card not Present Transactions. </b>
            <span>
              If you use the 16-digit Card number without presenting the Card in
              person (such as for a mail order, telephone, or internet
              purchase), the legal effect will be the same as if you had
              physically presented the Card itself. For security reasons, we may
              limit the amount or number of such transactions you may make.
            </span>
          </div>
          <div>
            <b>Processing Errors. </b>
            <span>
              We will rectify any processing error that we discover. If the
              error results in your receipt of less than the correct amount to
              which you are entitled, we will credit the Account for the
              difference. If the error results in your receipt of more than the
              correct amount to which you are entitled, we will debit the extra
              funds from the Account.
            </span>
          </div>
          <div>
            <b>Card on File. </b>
            <span>
              If you keep your Card on file with a merchant or payee with
              authorization to bill that Card for recurring payments, or to make
              future purchases or payments, you may need to notify each merchant
              of your updated debit card information, such as a new card number
              or expiration date, to ensure payments are uninterrupted.
            </span>
          </div>
          <div>
            <b>No Warranty. </b>
            <span>
              We are not responsible for the delivery, quality, safety,
              legality, or any other aspect of the goods and services purchased
              from merchants with the Card. All disputes concerning those
              matters should be addressed to the merchants from whom the goods
              and services were purchased. Merchants have no authority to make
              representations or warranties on our behalf, to bind us or to
              enter into any agreement on our behalf. EXCEPT AS EXPRESSLY
              OTHERWISE PROVIDED IN THIS AGREEMENT OR REQUIRED BY LAW, WE MAKE
              NO REPRESENTATIONS OR WARRANTIES OF ANY KIND TO YOU, WHETHER
              EXPRESS OR IMPLIED, REGARDING THE CARD, INCLUDING, WITHOUT
              LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS
              FOR A PARTICULAR PURPOSE.
            </span>
          </div>
          <div>
            <b>Receipts. </b>
            <span>
              You should get a receipt at the time you make a transaction using
              your Card. You agree to retain, verify, and reconcile your
              transactions and receipts.
            </span>
          </div>
          <div>
            <b>Digital Wallets. </b>
            <span>
              You can add your Card to a digital wallet by following the
              instructions of the digital wallet. The digital wallet may not be
              accepted in all places where your Card is accepted. We may
              terminate the ability for you to add the Card to a digital wallet
              at any time. You should contact the digital wallet provider to
              remove a Card from the digital wallet. We can end or suspend your
              ability to use a Card with the digital wallet at any time. You are
              solely responsible for maintaining the security of your digital
              wallet credentials (including user identification, password or
              other access credentials). If you share these credentials with any
              other person, you expressly authorize that person to have access
              to your personal information, to access your Account and to
              initiate charges to your Account using the digital wallet service.
              We currently do not impose a fee for using your Card with a
              digital wallet, but we reserve the right to impose a fee in the
              future. Please note that a digital wallet provider or another
              third party enabling or associated with your use of a digital
              wallet may charge a fee for using your Card with such digital
              wallet service. We are not a provider of any digital wallet
              service that you may choose to use, and we are not responsible for
              providing the digital wallet service to you. We are only
              responsible for supplying information securely to the digital
              wallet provider to enable usage of the Card in such digital
              wallet. We are not responsible for any failure of the digital
              wallet or the inability to use the digital wallet for any
              transaction. We are not responsible for the performance or
              non-performance of the digital wallet provider or any other third
              parties regarding any agreement you enter into with the digital
              wallet provider or associated third-party relationships that may
              impact your use of the digital wallet. YOU EXPRESSLY ACKNOWLEDGE
              AND AGREE THAT USE OF A DIGITAL WALLET SERVICE IS AT YOUR SOLE
              RISK, AND WE ARE NOT RESPONSIBLE FOR THE RISK AS TO SATISFACTORY
              QUALITY, PERFORMANCE, OR ACCURACY. You agree that we may exchange
              your information with your digital wallet provider, a payment
              network (e.g., Visa and Mastercard), and others in order to enable
              your use of a digital wallet service, make information available
              to you about your Card transactions, and improve our ability to
              offer our services in connection with a digital wallet service. We
              do not control how digital wallet providers or other third parties
              use the information received in connection with digital wallet
              services, and the use of such information is governed by such
              party’s privacy policy. We are not responsible for the security of
              the information provided to any digital wallet provider or stored
              in a digital wallet. We are not responsible if a security breach
              occurs that affects any information stored in, or sent from, a
              digital wallet.
            </span>
          </div>
        </li>
        <li>
          <h3>ACH ORIGINATION.</h3>
          <p>
            Capitalized terms that are used in this Section K but not otherwise
            defined in this Agreement have the meanings given to them in the
            NACHA Operating Rules & Guidelines (the "NACHA Rules") as adopted by
            NACHA and in effect from time to time (except that: (A) the term
            "Entry", in addition to the meaning given to it in the NACHA Rules,
            also (1) includes any data you give us and that we use to prepare or
            initiate an Entry on your behalf and (2) refers to a batch or file
            of Entries, as applicable; and (B) the term "Transmit", in addition
            to the meaning given to it in the NACHA Rules, also includes your
            physical delivery of an Entry to us if we permit certain physical
            delivery methods from time to time in our discretion). For the
            avoidance of doubt, a transfer of funds made through wire transfer
            is not subject to this Section K.
          </p>
          <p>
            The ACH Origination Service enables you to initiate debit and credit
            Entries through the Automated Clearing House. As used in this
            Section K, a "debit" Entry is an Entry that transfers funds from the
            Receiver's account to a Designated Account and a "credit" Entry is
            an Entry that transfers funds from a Designated Account to the
            Receiver's account. You will be the Originator for, and authorize us
            to originate on your behalf to the Receiver's account, each Entry
            you Transmit to us through the ACH Origination Service. We will be
            the ODFI for each such Entry.
          </p>
          <p>
            You are bound by and will comply with the NACHA Rules in using the
            ACH Origination Service, whether or not an Entry is sent through the
            Automated Clearing House. You will not originate Entries that
            violate applicable law or regulations (including any economic
            sanctions programs administered by the Office of Foreign Assets
            Control or any other laws of the United States). If a credit Entry
            is subject to Article 4A: (A) that Entry may be Transmitted through
            the Automated Clearing House, (B) your rights and obligations
            concerning that Entry are governed by and construed in accordance
            with the laws of the State that apply to this Agreement, (C) credit
            given by the RDFI to the Receiver for that Entry is provisional
            until the RDFI has received final settlement through a Federal
            Reserve Bank or otherwise has received payment as provided for in
            Section 4A-403(a) of Article 4A and (D) if the RDFI does not receive
            payment for that Entry, the RDFI is entitled to a refund from the
            Receiver in the amount of the credit to the Receiver's account, and
            you will not be considered to have paid the amount of the credit
            Entry to the Receiver.
          </p>
          <p>
            Generally, you may only Transmit Entries to us through the Platform.
          </p>
          <p>
            You may only Transmit Entries to us of the types designated by SEC
            Codes in your ACH Origination Service Setup Form.
          </p>
          <p>
            Each Entry you Transmit to us must comply with the requirements of,
            and be identified by, the applicable SEC Code, including all NACHA
            record formatting specifications. We may, at any time, prohibit you
            from initiating certain types of Entries through the ACH Origination
            Service.
          </p>
          <p>
            You will not use a third party to Transmit an Entry (or a request to
            cancel an Entry) to us on your behalf without our prior written
            consent and, if you use any such third party: (A) you are
            responsible for all acts, errors and omissions of such third party
            and (B) each reference to you in this Service Schedule includes such
            third party, as appropriate.
          </p>
          <p>
            We may establish and change aggregate and individual dollar limits
            for your Entries (or types of Entries). Those limits are referred to
            in this Section K as the "Exposure Limits". We will tell you what
            your Exposure Limits are from time to time. We may elect to process
            an Entry that exceeds an Exposure Limit, but doing so does not
            affect or limit our right to reject any future Entry that exceeds an
            Exposure Limit.
          </p>
          <p>
            You have no right to reverse, delete, cancel or amend an Entry after
            we receive it. If you request us to reverse, delete or cancel an
            Entry, and we are able to verify that request in accordance with the
            applicable Security Procedures, we will use commercially reasonable
            efforts to effect that request (generally by issuing a Reversing
            Entry except in limited circumstances where we have the capability
            to delete that Entry prior to Transmitting it to the ACH Operator
            or, in the case of an On-Us Entry (as defined below), prior to
            crediting or debiting the Receiver's Account) but are not liable if
            we are unable to do so. You will indemnify and hold us harmless from
            and against all claims and losses relating in any way to effecting
            or attempting to effect any such request and, at our option, will
            deposit funds with us or provide us other assurances of payment
            reasonably satisfactory to us as a condition to our effecting or
            attempting to effect any such request. We will not amend an Entry
            after we receive it.
          </p>
          <p>
            Except as provided below for On-Us Entries and suspension or
            rejection of Entries, we will: (1) process Entries received from you
            to conform with the file specifications set forth in the NACHA
            Rules, (2) Transmit those Entries as the ODFI to an ACH Operator and
            (3) settle for those Entries as provided in the NACHA Rules.
          </p>
          <p>
            Each ACH Operator establishes certain deadlines from time to time
            for transmission of Entries in order for those Entries to be
            processed on the Effective Entry Date shown on those Entries. We
            will use commercially reasonable efforts to Transmit Entries to an
            ACH Operator by the deadline established by that ACH Operator one
            Business Day prior to the Effective Entry Date shown in those
            Entries so long as: (1) we receive those Entries on a Business Day
            by the deadline set forth in the ACH Origination Service
            Documentation; (2) the Effective Entry Date shown in those Entries
            is at least one day after that Business Day and (3) that ACH
            Operator is open for business on that Business Day. For Entries we
            receive after that deadline on a Business Day or on a day that is
            not a Business Day, we will use commercially reasonable efforts to
            Transmit those Entries to an ACH Operator by the next deposit
            deadline of that ACH Operator on a Business Day on which that ACH
            Operator is open for business.
          </p>
          <p>
            If you send us a Reversing Entry (or if we issue a Reversing Entry
            in an attempt to effect your request to cancel an Entry), you will
            notify the Receiver that the Reversing Entry has been Transmitted to
            the Receiver's account (along with the reason for the Reversing
            Entry) no later than the Settlement Date of the Reversing Entry.
          </p>
          <p>
            If you elect to originate Same Day Entries on the ACH Origination
            Service Setup Form, the following terms and conditions apply.
          </p>
          <p>
            Notwithstanding any other provision of this Section K to the
            contrary, (1) we will use commercially reasonable efforts to
            Transmit Same Day Entries to an ACH Operator by the deadline
            established by that ACH Operator for same-day processing and
            settlement on the Effective Entry Date shown in those Same Day
            Entries so long as: (a) we receive those Same Day Entries on a
            Business Day by the same-day processing deadline set forth in the
            ACH Origination Service Documentation; (b) the Effective Entry Date
            shown in those Same Day Entries is that Business Day and (c) that
            ACH Operator is open for business on that Business Day; (2) for Same
            Day Entries we receive after that deadline on a Business Day or on a
            day that is not a Business Day, we will use commercially reasonable
            efforts to Transmit those Entries to an ACH Operator by the next
            same-day processing and settlement deadline of that ACH Operator on
            a Business Day on which that ACH Operator is open for business; and
            (3) Entries with a stale or invalid Effective Entry Date that we
            receive before the same-day processing deadline set forth in the ACH
            Origination Service Documentation will be processed as Same Day
            Entries if they are otherwise eligible for same-day processing and
            settlement.
          </p>
          <p>
            You may not Transmit a Same Day Entry to us that exceeds the dollar
            limit for Same Day Entries set forth in the NACHA Rules. An IAT
            Entry cannot be a Same Day Entry. In addition to that limit and any
            other Exposure Limits we have established for you, we may establish
            and change aggregate and individual dollar limits on your Same Day
            Entries. We may also require you to designate an Entry as a Same Day
            Entry by using the Company Descriptive Date field of the
            Company/Batch Header Record. If we elect to do so, (1) that
            requirement will be reflected in the ACH Origination Service
            Documentation; (2) we have no obligation to process an Entry as a
            Same Day Entry if it is not so designated, even if we receive that
            Entry by the same-day processing deadline set forth in the ACH
            Origination Service Documentation on the Effective Entry Date shown
            in that Entry and (3) if we receive an Entry by the same-day
            processing deadline set forth in the ACH Origination Service
            Documentation on the Effective Entry Date shown in that Entry, we
            may process that Entry as a Same Day Entry, even if that Entry is
            not so designated. Each Entry that you Transmit to us, or that we
            process, as a Same Day Entry is subject to a special fee as we
            disclose to you from time to time.
          </p>
          <p>
            Except as provided below for suspension or rejection of Entries, if
            we receive an Entry for credit or debit to an account maintained
            with us (each such Entry is referred to in this Service Schedule as
            an "On-Us Entry"), we will credit or debit the amount of that Entry
            to the Receiver's account on its Effective Entry Date, so long as:
            (A) we receive that Entry on a Business Day by the deadline set
            forth in the ACH Origination Service Documentation; and (B) except
            in the case of Same Day Entries, the Effective Entry Date of that
            Entry is at least one day after that Business Day. For an On-Us
            Entry we receive after that deadline on a Business Day or on a day
            that is not a Business Day, we will use commercially reasonable
            efforts to credit or debit the amount of that Entry to the
            Receiver's account on the Business Day following its Effective Entry
            Date.
          </p>
          <p>
            We may suspend processing of and/or reject an Entry for any reason
            set forth in this Agreement. We may also suspend processing of
            and/or reject an Entry: (A) that exceeds your Exposure Limits; (B)
            that contains an Effective Entry Date more than 30 business days
            after the business day we receive that Entry; or (C) for any reason
            for which that Entry may be returned under the NACHA Rules. We will
            notify you through the Platform App of that suspension in processing
            and/or rejection no later than the business day we would otherwise
            have Transmitted that Entry to an ACH Operator or, in the case of an
            On-Us Entry, its Effective Entry Date. We are not liable to you for
            any such suspension of processing and/or rejection of an Entry or
            for not giving you earlier notice of it. If an Entry is rejected by
            an ACH Operator for any reason, you are solely responsible for
            correcting any Entry you intend to resubmit and for reinitiating
            such Entry.
          </p>
          <p>
            In addition to any other rights we have to terminate or suspend your
            use of the ACH Origination Service, we may terminate or suspend your
            use of the ACH Origination Service immediately and without giving
            you prior written notice if: (A) you breach the NACHA Rules; (B) any
            Entry you Transmit to us or any of your acts or omissions might
            cause us to breach the NACHA Rules or any representations or
            warranties we make under the NACHA Rules; or (C) we believe such
            termination or suspension is necessary for us to comply with the
            NACHA Rules.
          </p>
          <p>
            We have no obligation to review an Entry for inconsistencies or to
            confirm the accuracy or completeness of an Entry. If an Entry
            inconsistently describes the Receiver by name and account number,
            Payment of that Entry may be made by the RDFI (or by us in the case
            of an On-Us Entry) on the basis of the account number, even if that
            number identifies a person other than the Receiver. You are solely
            responsible for any such inconsistency and the accuracy and
            completeness of each Entry you Transmit to us.
          </p>
          <p>
            We will notify you through the Platform App of each Return Entry
            received from an ACH Operator no later than one Business Day after
            the Business Day on which we receive that Return Entry. We have no
            obligation to Transmit a Return Entry to an ACH Operator again if we
            complied with this Section K with respect to the original Entry.
          </p>
          <p>
            An Entry that has been returned may not be reinitiated unless: (1)
            it was returned for insufficient or uncollected funds; (2) it was
            returned for stopped payment and reinitiation has been separately
            authorized by the Receiver after you or we received the Return
            Entry; or (3) you or we have taken corrective action to remedy the
            reason for the return. If you want to reinitiate an Entry, you must
            Transmit that Entry to us again.
          </p>
          <p>
            We will notify you through the Platform App of each Notification of
            Change relating to your Entries within two Banking Days after we
            receive it. You will make the required changes before submitting any
            further Entries to the applicable Receiver's account or, if later,
            within six Banking Days after receiving a Notification of Change. If
            you fail to correct an Entry in response to a Notification of
            Change, NACHA may impose fines against us. You are responsible for
            all such fines and we may debit them directly against your Account
            without prior notice.
          </p>
          <p>
            The Security Procedures for verifying the authenticity of an Entry
            Transmitted through the Platform, and for reversing, deleting or
            canceling an Entry Transmitted through the Platform, are those
            specified in the Platform App terms of service. The Security
            Procedures for verifying the authenticity of an Entry Transmitted
            via SFTP include key authentication, connection to SFTP from a
            specified numeric source IP, and the use of GPG encryption to
            encrypt and digitally sign files before they are transmitted. In
            addition, your Authorized Representative must separately verify
            certain information about the file with us, as described in the ACH
            Origination Service Documentation. You agree that these Security
            Procedures are commercially reasonable for you in light of the
            anticipated size, type and frequency of your Entries.
          </p>
          <p>
            You and we will pay each other for Entries we Transmit on your
            behalf as follows:
          </p>
          <ol>
            <li>
              <span>
                You will pay us the amount of each credit Entry (including On-Us
                Entries) we Transmit on your behalf at the time on the
                Settlement Date of that credit Entry as we may determine.
              </span>
            </li>
            <li>
              <span>
                You will pay us the amount of each debit Entry we Transmit on
                your behalf and that is returned by the RDFI immediately on the
                date that debit Entry is returned to us.
              </span>
            </li>
            <li>
              <span>
                You will pay us the amount of each adjustment Entry we
                previously paid to you immediately on the date we receive that
                adjustment Entry.
              </span>
            </li>
            <li>
              <span>
                We will pay you the amount of each debit Entry (including On-Us
                Entries) we Transmit on your behalf at such time on the
                Settlement Date of that debit Entry as we may determine.
              </span>
            </li>
            <li>
              <span>
                We will pay you the amount of each credit Entry we Transmit on
                your behalf and that is returned by the RDFI at such time on the
                date that Entry is returned to us as we may determine.
              </span>
            </li>
            <li>
              <span>
                Any payment we make for any debit Entry, returned credit Entry
                or credit reversal is provisional until we receive final
                settlement for such Entry. If final settlement is not received,
                we are entitled to a refund. We are not obligated to make the
                amount of any debit Entry, returned credit Entry or credit
                reversal available for you for withdrawal before that amount
                constitutes collected funds. Whether or not constituting
                collected funds, we may charge back and recover from you any
                amount credited to your Account if we do not receive final
                settlement, the Entry is returned, we receive an adjustment
                Entry pursuant to the NACHA Rules or the Entry violates the
                Agreement. We may, in our discretion, require that you maintain
                all settlement funds in the Account.
              </span>
            </li>
          </ol>
          <p>
            You authorize us, without notice or demand, to debit or credit your
            Designated Accounts in order to effect any of the payments described
            above.
          </p>
          <p>
            Unless we notify you otherwise with respect to certain Entry types,
            you must fund your Account prior to the Settlement Date of an Entry.
            You will provide immediately available and collected funds
            sufficient to pay all Entries you initiate: (1) not later than 8:00
            a.m. EST time the number of business days before each Settlement
            Date that we notify to you from time to time; and (2) prior to
            initiating any Entries for which pre‐funding is required.
          </p>
          <p>
            In addition, from time to time we may evaluate your transaction
            activity for the purpose of establishing averages for transaction
            frequency, amount, returns and adjustments. In connection with these
            evaluations, we may require you to establish reserves with us in an
            amount we determine is sufficient to cover your obligations to us.
            We may express those reserves as a fixed dollar amount or as a
            "rolling reserve" calculated based on "rolling" averages determined
            by our periodic evaluations. We will notify you of the amount of
            reserves, if any, we may require from time to time. You will
            establish any reserves we may require within five business days
            after the date of our notice setting forth the amount of those
            reserves and the basis of the calculation we used to determine that
            amount. We may suspend your use of the ACH Origination Service if
            you fail to timely establish any required reserve.
          </p>
          <p>
            Entries we Transmit on your behalf to an ACH Operator (or, with
            respect to On-Us Entries, that we post to a Receiver's account
            maintained with us) will be reflected in the online account
            summaries regarding a Designated Account that we make available to
            you pursuant to the Commercial Online Banking Service. You must
            promptly review each such online account summary and notify us of
            any discrepancy between the information shown in such online account
            summary and your books and records. If you fail to notify us of any
            such discrepancy within one business day after we make an online
            account summary available to you, you are precluded from asserting
            that discrepancy against us and we have no liability for any other
            losses resulting from your failure to give us such notice or any
            loss of interest or any interest equivalent with respect to any
            Entry reflected in that online account summary. The foregoing
            obligation for you to promptly discover and report, and the
            limitation on our liability regarding, any discrepancies are in
            addition to any similar obligation, or limitation, under other
            Sections of this Agreement.
          </p>
          <p>
            With respect to each Entry you Transmit to us, you represent and
            warrant to, and agree with, us that: (A) that Entry has been
            properly authorized by you and the Receiver in accordance with the
            NACHA Rules; (B) your authorization of that Entry has not been
            revoked and you do not have actual knowledge of the revocation of
            the Receiver's authorization of, or of the termination of the
            agreement between the RDFI and the Receiver concerning, that Entry;
            (C) at the time that Entry is processed by the RDFI, the
            authorization for that Entry has not been terminated, in whole or in
            part, by operation of Applicable Law; (D) that Entry complies with
            the Agreement and the NACHA Rules, including the use of the proper
            SEC Code; (E) that Entry contains the Receiver's correct account
            number and, except for information within the purview of the RDFI's
            relationship with the Receiver, all other information necessary to
            enable the RDFI to comply with its obligations to provide the
            Receiver information about that Entry under the NACHA Rules; (F) all
            information Transmitted with that Entry is related to the payment
            represented by that Entry; (G) that Entry is not an IAT Entry,
            unless we have given you prior consent to Transmit IAT Entries; (H)
            you have complied with all pre-notification requirements of the
            NACHA Rules relating to that Entry; (I) you have complied with (and
            that Entry complies with) all notice, disclosure and other
            requirements under all applicable law and regulations (including the
            Electronic Fund Transfer Act and its implementing Regulation E) as
            they relate to that Entry; (J) if that Entry is a credit Entry, it
            is timely; and (K) if that Entry is a debit Entry, it is (1) for an
            amount that will be due and owing to you from the Receiver on the
            Settlement Date, (2) for a sum specified by the Receiver to be paid
            to you, (3) to correct a previous credit Entry that was an Erroneous
            Entry, or (4) to reclaim from an RDFI an amount received by a
            recipient after death or legal incapacity of the recipient or the
            death of a beneficiary. In addition, you make each other
            representation and warranty to us regarding each Entry you send us
            that we are deemed to make regarding that Entry to any ACH Operator,
            RDFI or other person under the NACHA Rules or applicable law and
            regulations (including those that relate to Entries under specific
            SEC Codes). The foregoing representations, warranties and agreements
            are in addition to those contained elsewhere in this Agreement.
          </p>
          <p>
            If you send a WEB Entry to us, you further represent and warrant to,
            and agree with, us that: (A) if that WEB Entry is a debit WEB Entry
            to a Consumer Account of the Receiver, that WEB Entry was authorized
            by (1) a written authorization obtained from the Receiver via the
            Internet or a Wireless Network; or (2) the Receiver in any manner
            permissible under the NACHA Rules where the Receiver's authorization
            for the initiation of an individual debit Entry is communicated,
            other than by an oral communication, via a Wireless Network; (B) if
            that WEB Entry is a debit WEB Entry, you employed a commercially
            reasonable fraudulent transaction detection system to screen that
            debit WEB Entry (which, at a minimum, validates the account to be
            debited for the first use of such account number and for each
            subsequent change to such account number); (C) if that WEB Entry is
            a debit WEB Entry, you used commercially reasonable procedures to
            verify the identity of the Receiver of that WEB Entry; (D) if that
            WEB Entry is a debit WEB Entry, you utilized commercially reasonable
            procedures to verify that the routing number associated with that
            WEB Entry is valid; (E) if that WEB Entry is a debit WEB Entry, you
            will conduct or have conducted annual audits to ensure that the
            financial information you obtain from Receivers is protected by
            security practices and procedures that include, at a minimum,
            adequate levels of: (1) physical security to protect against theft,
            tampering, or damage; (2) personnel and access controls to protect
            against unauthorized access and use; and (3) network security to
            ensure secure capture, storage, and distribution; (F) you used
            encryption for Transmittal of banking information related to any WEB
            Entry or established a secure Internet session with the Receiver of
            the WEB Entry, in either case utilizing technology providing a
            commercially reasonable level of security that complies with
            applicable law and regulations prior to the Receiver's key entry and
            through Transmission to you of any banking information, including,
            but not limited to, any Entry, the Receiver's routing number,
            account number and PIN number or other identification symbol; and
            (G) you will otherwise comply with the NACHA Rules with respect to
            that WEB Entry.
          </p>
          <p>
            You may not Transmit IAT Entries without our prior consent. If you
            send an IAT Entry to us, you further represent and warrant to, and
            agree with, us that: (A) you are in compliance with United States
            legal requirements with respect to the IAT Entry, including your
            obligations under programs administered by OFAC and FinCEN; (B) the
            authorization of the IAT Entry complies with the laws and payment
            system rules of the receiving country, if such laws or payment
            system rules require authorization with respect to an IAT Entry; and
            (C) you will otherwise comply with the NACHA Rules with respect to
            that IAT Entry. You bear the risk of any loss caused by any delay,
            tax, cost, tariff, fee, or other charge incurred on account of the
            Entry in any country outside the United States. You assume the risk
            of foreign currency conversion and allocation of gains and losses
            with respect to entries and returns, adjustments and correction of
            errors and for Erroneous Entries, and you agree and acknowledge that
            we are not liable to you for any loss or charge incurred as the
            result of the application of any foreign currency exchange criteria
            imposed by any institution or agency located outside the United
            States. We may or may not permit you to return an IAT Entry,
            including an Erroneous Entry, and any such return would be processed
            only on a best-efforts basis.
          </p>
          <p>
            You will retain and provide us, upon request, all information and
            data necessary to remake or reconstruct any Entry for at least 30
            days following our Transmittal of such Entry.
          </p>
          <p>
            All Entries, Security Procedures and related records we use for
            transactions contemplated by this Section K are and will remain our
            property. At our discretion, we may make that information available
            to you upon your request and at your expense.
          </p>
          <p>
            We have the right, from time to time, to audit your compliance with
            this Agreement, NACHA Rules and applicable law and regulations
            (including the NACHA Rules). You will provide us any information or
            records we reasonably request and otherwise cooperate with us in
            connection with any audit.
          </p>
          <p>
            You will obtain all consents and authorizations required under
            Applicable Law (including the NACHA Rules) for each Entry you
            Transmit to us and will retain those consents and authorizations for
            two years after they expire. You will provide us copies of any
            consent and authorization within five business days of our request.
            In addition, if we receive a notice of error pursuant to the
            Electronic Funds Transfer Act, Regulation E, or other applicable law
            or regulations concerning an Entry processed or authorized to be
            processed to an account with us, you will, within two business days
            after written or oral notice, provide us with a copy of the
            authorization to debit or credit that account and, if applicable,
            proof of sending notice to the holder of that account of the varying
            amount, or other proof satisfactory to us that the error alleged did
            not occur. If the referenced documents or proof are not delivered
            timely, or the documents or proof do not give us a reasonable basis
            for determining that no error occurred, or if the error alleged did
            occur, we may charge you for the amount of the alleged error and for
            any penalty imposed on us.
          </p>
          <p>
            The NACHA Rules contain special requirements and impose additional
            obligations on us when we act as the ODFI with respect to Entries
            that are sent to us by a Third-Party Sender. As a result, we must
            obtain additional agreements, representations and warranties from a
            Third-Party Sender. You will not act as a Third-Party Sender when
            using the ACH Origination Service without our prior written consent
            (which consent may be withheld or conditioned in our discretion) and
            providing all information we reasonably request and entering into
            additional agreement(s) with us with respect to you acting in that
            capacity. However, if you Transmit any Entries to us as a
            Third-Party Sender without our prior written consent, you
            automatically make the additional agreements, representations and
            warranties to us contemplated by the NACHA Rules and will indemnify
            and hold us harmless from all claims and losses relating in any way
            to those Entries.
          </p>
        </li>
        <li>
          <h3>Instant Payments</h3>
        </li>
        <li>
          <h3>MISCELLANEOUS.</h3>
          <div>
            <b>Assignment; Transfer; Non-Waiver. </b>
            <span>
              The Account and your obligations under this Agreement may not be
              assigned. We may transfer our rights under this Agreement.
              Platform is an agent of Bank for some purposes and will be
              responsible for carrying out some of Bank’s responsibilities under
              this Agreement as Bank’s agent, including receiving notices from
              you, responding to any notices relating to questions or complaints
              concerning your Account or Card, and carrying out other
              responsibilities described in this Agreement. Accordingly, where
              Bank is responsible for matters under this Agreement, those
              matters may be handled either by Platform or by Bank directly. Use
              of the Account and Card is subject to applicable Federal and state
              laws and all applicable rules and customs of any clearinghouse or
              other association governing your Account, Card or any
              transactions. If you or we excuse each other from complying with
              any part of this Agreement, this will not waive compliance by the
              excused party on any other occasion, notwithstanding the number of
              previous excusals or their duration. A party may not rely
              justifiably upon the other party's past forbearance to vary
              present or future rights, obligations or performance under this
              Agreement. If any provision of this Agreement is determined to be
              invalid, illegal or unenforceable for any reason, that provision
              shall not invalidate or render unenforceable any other provision
              of this Agreement.
            </span>
          </div>
          <div>
            <b>Deposit Network Service. </b>
            <span>
              From time to time, we may, but are not obligated to, deposit some
              or all the funds in your Account with one or more other depository
              institutions through a deposit network program (a “Deposit Network
              Service”) administered by us or by a third-party service provider
              (a “Third-Party Service Provider”). In order for you to
              participate in a Deposit Network Service, we may ask you to enter
              into additional agreement(s) with us with respect to the Deposit
              Network Service. All depository institutions that participate in a
              Deposit Network Service (each a “Network Bank” and, together, the
              “Network Banks”) are insured by the Federal Deposit Insurance
              Corporation (“FDIC”) or the National Credit Union Administration
              (“NCUA”). Each account at a Network Bank in which any of the funds
              in your Account are deposited as part of a Deposit Network Service
              shall be titled in the Bank’s name or Bank’s agent’s name as
              custodian and beneficially held to provide deposit insurance
              coverage to Account customers of Bank. Bank may transfer funds
              from your Account directly to a Network Bank, or your funds may be
              placed in the custody of a FDIC-insured or NCUA insured custodian
              bank (a "Custodian Bank") prior to being transferred to a Network
              Bank. If any of your funds are held at a Custodian Bank, they
              shall be placed in an account that is titled and beneficially held
              to provide deposit insurance coverage to Account customers of
              Bank. Your funds that are held at a Network Bank will be governed
              by the terms of this Agreement in the same manner and to the same
              extent as funds held in your Account at Bank, including with
              respect to interest rates, fee schedules, and the Funds
              Availability Policy, but you will not have direct access to any
              account that is established at a Network Bank or Custodian Bank
              and may not take any direct action with respect to a Deposit
              Network Service.
            </span>
          </div>
          <p>
            You appoint Bank as your limited agent to provide the Deposit
            Network Service. Bank may at any time and in its sole discretion
            effect deposits to and withdrawals from your Account(s) at Bank and
            any accounts established at the Network Banks as part of the Deposit
            Network Service. You authorize Bank to use one or more Third-Party
            Service Providers selected solely by Bank to act as your limited
            agent(s) to provide the Deposit Network Service, including the
            selection of one or more Network Banks and one or more Custodian
            Banks as Bank deems suitable for the purpose of providing the
            Deposit Network Service. Any funds in your Account(s) at Bank that
            Bank or its agents deposits at a Network Bank as part of the Deposit
            Network Service, and any funds that you hold or are held for your
            benefit at the same Network Bank by any other means (i.e., not by
            Bank or its agents as part of the Deposit Network Service) in the
            same capacity in which you hold the funds in your Account(s) at
            Bank, will be eligible for FDIC deposit insurance or NCUA share
            insurance, as applicable, in the aggregate up to the Standard
            Maximum Deposit Insurance Amount (“SMDIA”) in effect under FDIC or
            NCUA regulations, which is currently $250,000. IT IS YOUR
            RESPONSIBILITY TO MONITOR THE AGGREGATE BALANCE OF FUNDS YOU
            BENEFICIALLY OWN AT EACH NETWORK BANK TO DETERMINE WHETHER THE
            AGGREGATE BALANCE EXCEEDS THE SMDIA AND TO TAKE APPROPRIATE ACTION
            REGARDING THE FUNDS YOU OWN OUTSIDE YOUR ACCOUNT AT A NETWORK BANK.
            IF THE AGGREGATE AMOUNT OF FUNDS THAT YOU BENEFICIALLY OWN AT A
            NETWORK BANK EXCEEDS THE SMDIA, AND THAT NETWORK BANK WERE TO FAIL,
            YOU MAY BE SUBJECT TO A LOSS OF FUNDS IN EXCESS OF THE SMDIA. If you
            determine the aggregate balance of your funds at a Network Bank may
            exceed the SMDIA, then upon your request to customer service (see
            contact information below), Bank will use reasonable efforts to
            limit or lower the amount of your funds held, or avoid holding your
            funds, at such Network Bank. In order to help you monitor your
            deposits, a current list of Network Banks will be available on the
            Platform App.
          </p>
          <p>
            Bank and its agents will maintain records of the balance that each
            depositor of Bank beneficially holds at each Network Bank. The use
            of the Deposit Network Service will not modify the Funds
            Availability Policy that is part of this Agreement. Subject to the
            terms of the Funds Availability Policy, your access to the funds in
            your Account will not be affected by Bank’s participation in a
            Deposit Network Service.
          </p>
          <div>
            <b>Abandoned or Inactive Accounts. </b>
            <span>
              Applicable state unclaimed property laws govern when accounts are
              considered abandoned. Your Account is usually considered abandoned
              if you have not made a deposit or withdrawal, or signed in to your
              online Account, for a specified period of time. We are required by
              the unclaimed property laws to turn over accounts considered
              abandoned to the applicable state. Before we turn over an
              abandoned account, we may send a notice to you by e-mail or the
              address we show for the account statement. Unless prohibited by
              law, we may charge to your Account our costs and expenses of any
              notice, payment and turnover of the remaining balance of your
              Account to the applicable state.
            </span>
          </div>
          <div>
            <b>FDIC Insurance. </b>
            <span>
              For any deposit accounts you open, the FDIC requires Bank to
              disclose, and you hereby acknowledge, that deposits held by Bank
              are insured up to $250,000 federal deposit insurance limit, per
              depositor for each ownership category.
            </span>
          </div>
          <div>
            <b>Account Owner Representations and Warranties. </b>
            <span>
              By requesting, activating or using the Account or Card or by
              retaining, using or authorizing the use of the Account or Card,
              the owner of the Account represents and warrants to us that: (a)
              Account owner has received a copy of this Agreement and agrees to
              be bound by and to comply with this Agreement; (b) if the Account
              owner is a legal entity, such legal entity is duly organized,
              validly existing and in good standing under the laws of the state
              in the United States of its formation; (c) if the Account owner is
              a legal entity, such legal entity is duly qualified and in good
              standing to do business in all jurisdictions where such legal
              entity conducts its business; (d) if the Account owner is a legal
              entity, such legal entity has all necessary organizational power
              and authority to establish the Account, enter into this Agreement,
              and to perform all of the obligations to be performed by it under
              this Agreement; (e) the personal and business information that the
              Account owner provides to us in connection with the Account is
              true, correct and complete; (f) if the Account owner is a legal
              entity, the individual accepting and agreeing to this Agreement
              for the Account owner has the requisite corporate authority to
              accept and agree to this Agreement on the Account owner’s behalf;
              (g) each Authorized Representative designated by the Account owner
              is a citizen or permanent resident of the United States and is at
              least eighteen (18) years of age (or older if residing in a state
              where the majority age is older); (h) the Account owner authorized
              each Authorized Representative to accept and use the Account and
              Card; (i) the Account owner has provided each Authorized
              Representative with a copy of this Agreement and each Authorized
              Representative accepts and agrees to be bound by and to comply
              with this Agreement; and (j) the Account and Card will only be
              used for business purposes and not be used for personal, family or
              household purposes.
            </span>
          </div>
          <div>
            <b>Our Relationship with You. </b>
            <span>
              This Agreement and the relationship between you and the Bank is
              that of debtor and creditor, and the Bank owes no fiduciary duty
              to you. The products and services offered by Platform are not
              endorsed or guaranteed by Bank and Bank assumes no liability for
              the Platform's products and services, except for those services
              expressly provided for in this Agreement. You understand that
              Platform and Bank are not partners, affiliates or joint venturers
              with each other. Nothing in this Agreement is intended to be read
              or understood as making Platform and Bank partners, affiliates or
              joint venturers or impose any liability as such on either of them.
              Unless otherwise expressly stated in this Agreement, Platform has
              no authority to act or represent Bank in any way. Bank provides
              the services under this Agreement in part through one or more
              service providers, including Platform. You agree that Platform and
              any other such service providers are third-party beneficiaries of
              this Agreement, which means they can enforce the Agreement against
              you.
            </span>
          </div>
          <div>
            <b>Standard of Care; Limitation of Liability. </b>
            <span>
              Our liability for losses you incur in connection with your Account
              is limited to actual damages proved that are proximately caused by
              our gross negligence or willful misconduct. Without limiting the
              generality of the foregoing, in no event will our liability for
              any loss, cost, or liability arising from our gross negligence or
              willful misconduct exceed $1, except as otherwise required by
              UCC Article 4A.
            </span>
          </div>
          <p>
            In the case of the unauthorized disclosure of private or
            confidential information, such willful and malicious act must also
            be defamatory. We will have no liability for acting on instructions
            from you, or instructions we believe in good faith to be from you.
            We will have no liability for declining to act on instructions whose
            authenticity or accuracy cannot be verified to our satisfaction, or
            for not acting on instructions not actually received. Except as
            otherwise expressly provided in this Agreement or as otherwise
            required by applicable law, we, our affiliates, and the parties with
            whom we and our affiliates contract in order to offer your Account
            and related services are neither responsible nor liable for any
            indirect, incidental, consequential, special, exemplary, or punitive
            damages arising out of or relating in any way to your Account, Card,
            any products or services purchased using the Account, or this
            Agreement (as well as any related or prior agreement you may have
            had with us).
          </p>
          <p>
            Without limiting the foregoing, we will not be liable for and will
            be excused from failing to act or delay in acting if such failure or
            delay is caused by legal constraint, interruption of transmission or
            communications facilities, equipment failure, war, act of terror,
            emergency conditions, or other conditions beyond our control. To the
            extent allowed by law, we not be liable for and will be excused from
            failing to process or any delay in processing a transfer of funds
            (i) if, in our sole discretion, processing a transfer would violate
            or contribute to the violation of any law, regulation or regulatory
            requirement; or (ii) if processing a transfer, in our sole
            discretion, would cause it to engage in an unsafe and unsound
            practice.
          </p>
          <p>
            Except if another provision of this Agreement requires a shorter
            time period, any claim, action or proceeding by you to enforce the
            terms of this Agreement or to recover for any Account-related loss
            must be commenced within one year from the date that the event
            giving rise to the claim, action or proceeding first occurs.
          </p>
          <p>
            You agree to cooperate with us in any loss recovery efforts we
            undertake to reduce any loss or liability that arises in connection
            with the Account.
          </p>
          <p>This Section shall survive termination of this Agreement.</p>
          <div>
            <b>Adjustments. </b>
            <span>
              If we make an error in your favor by excessively crediting or
              insufficiently debiting your Account for any reason, including,
              without limitation, the giving of cash or credit in excess of a
              corresponding account debit, you agree that you immediately owe us
              the amount in error, whether you relied on the error or not, and
              that we may debit your Account for the amount in error.
            </span>
          </div>
          <div>
            <b>Indemnification. </b>
            <span>
              You will indemnify and defend us and our officers, directors,
              employees, agents, and representatives, and hold each of them
              harmless, against suit, judgment, asserted claim, demand, excise
              taxes, claims, liabilities or losses, including fees of counsel,
              interest and other expenses, arising directly or indirectly from
              your Account, Card, our performance under this Agreement, your
              breach of your obligations under this Agreement, your
              instructions, actions or omissions, or the instructions, actions
              or omissions of third parties whom you have permitted to direct,
              manage, view or otherwise act or omit to act in connection with
              your Account, unless the claim, liability or loss arises out of
              our gross negligence or willful misconduct. If we and our
              officers, directors, employees, agents or representatives are
              entitled to indemnification under this Agreement, we will give you
              notice of the claim and any further pleadings, communication or
              other information connected with it. You shall defend us, and our
              officers, directors, employees, agents, and representatives, or
              pay for the cost of our defense, as we or our officers, directors,
              employees, agents or representatives shall elect. The parties
              shall cooperate for the cost-effective defense of the claim, and
              we and our officers, directors, employees, agents and
              representatives will not settle any claim for which
              indemnification is demanded without your consent. This provision
              shall survive termination of this Agreement.
            </span>
          </div>
          <div>
            <b>Right to Set Off. </b>
            <span>
              If your Account balance becomes and remains negative, we can use
              the funds in any of your accounts with us to repay the negative
              balance in your Account without any further notice or demand.
              Moreover, we have the right to set-off against any account you
              have with us any liability, direct or contingent, past, present or
              future that you owe us, including, but not limited to, any such
              liability you owe us under this Agreement. Further, you grant us a
              lien on and security interest in the funds on deposit in each of
              your accounts as security for all of your liabilities and
              obligations to us, now or in the future. You may not grant a
              security interest in your Account to anyone other than us without
              our written agreement.
            </span>
          </div>
          <div>
            <b>Claims of Loss. </b>
            <span>
              If you claim a credit or refund because of a forgery, alteration,
              or any other unauthorized withdrawal, you agree to cooperate with
              us in the investigation of the loss, including giving us an
              affidavit containing whatever reasonable information we require
              concerning your Account, the transaction, and the circumstances
              surrounding the loss. You will notify law enforcement authorities
              of any criminal act related to the claim of lost, missing, or
              stolen checks or unauthorized withdrawals. We will have a
              reasonable period of time to investigate the facts and
              circumstances surrounding any claim of loss. You agree that you
              will not waive any rights you have to recover your loss against
              anyone who is obligated to repay, insure, or otherwise reimburse
              you for your loss. You will pursue your rights or, at our option,
              assign them to us so that we may pursue them. Our liability will
              be reduced by the amount you recover or are entitled to recover
              from these other sources.
            </span>
          </div>
          <div>
            <b>No Warranty of Availability or Uninterrupted Use. </b>
            <span>
              From time to time, services related to the Platform App or the
              Account may be inoperative. When this happens, you may be unable
              to access the Platform App, and you may be unable to use the
              Account or obtain information about the Account. Please notify us
              if you have any problems using the Account or Platform App. You
              agree that we will not be responsible for temporary interruptions
              in service due to maintenance, website changes, or failures, nor
              shall we be liable for extended interruptions due to failures
              beyond our control, including but not limited to the failure of
              interconnecting and operating systems, computer viruses, forces of
              nature, labor disputes and armed conflicts. To the fullest extent
              permitted by law, we do not make any warranties of any kind
              related to the Platform App, either express or implied, including
              but not limited to, implied warranties of merchantability or
              fitness for a particular purpose.
            </span>
          </div>
          <div>
            <b>Amendment. </b>
            <span>
              Except as otherwise required by applicable law, we may amend or
              change the terms and conditions of this Agreement at any time by
              posting the amended Agreement on the Platform App, and any such
              amendment will be effective upon such posting to the website. You
              will be notified of any amendment(s) in the manner provided by
              applicable law prior to the effective date of the amendment(s).
              However, if the amendment(s) is made for security purposes or your
              benefit, we may implement it without prior notice.
            </span>
          </div>
          <div>
            <b>Customer Service. </b>
            <span>
              For customer service or additional information regarding your
              Account, please contact Platform at:
            </span>
            <ul className="none-list-type">
              <li>
                <span>Phone: _+1 8885945946</span>
              </li>
              <li>
                <span>
                  Email: _
                  <a href="mailto:support@otterz.co" className="link">
                    support@otterz.co
                  </a>
                </span>
              </li>
              <li>
                <span>
                  Hours. Customer Service agents are available to answer your
                  calls:
                </span>
                <ul className="none-list-type">
                  <li>
                    <span>
                      Eastern Time: Monday through Friday: 9:00 AM – 9:00 PM.
                    </span>
                  </li>
                  <li>
                    <span>
                      Central Time: Monday through Friday: 8:00 AM – 8:00 PM.
                    </span>
                  </li>
                  <li>
                    <span>
                      Pacific Time: Monday through Friday: 6:00 AM – 6:00 PM.
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <b>Communications About Your Account. </b>
            <span>
              You agree that we may contact you by phone, text message, email,
              or mail to service your Account. You agree to provide us accurate
              contact information. You agree that we may contact you at any
              phone number, email address, or mailing address that you provide
              us. When you give us a phone number, you expressly consent that we
              and our agents may contact you by phone call or text message at
              that phone number. You agree that we may leave you prerecorded
              messages and that we may use automated telephone dialing systems
              when contacting you by phone call or text message at any number
              you provide us. You agree that we may monitor, record, and retain
              your communications with us at any time in accordance with
              applicable law.
            </span>
          </div>
          <div>
            <b>
              Arbitration. PLEASE READ THE FOLLOWING ARBITRATION PROVISION
              CAREFULLY. IN THIS ARBITRATION PROVISION, "WE," "OUR" AND "US"
              REFER TO BANK AND PLATFORM. THIS ARBITRATION PROVISION PROVIDES
              FOR MANDATORY ARBITRATION OF CLAIMS (SUBJECT TO SOME EXCEPTIONS),
              INSTEAD OF COURT PROCEEDINGS. IF YOU OR WE ELECT ARBITRATION OF A
              CLAIM, NEITHER WILL HAVE THE RIGHT TO PURSUE THAT CLAIM BEFORE A
              JUDGE OR JURY IN COURT OR TO PARTICIPATE IN A CLASS ACTION
              PROCEEDING. RIGHTS YOU WOULD HAVE IN COURT THAT MAY BE LIMITED OR
              UNAVAILABLE IN ARBITRATION INCLUDE THE RIGHT TO CONDUCT DISCOVERY
              OR TO APPEAL. FEES AND EXPENSES OF ARBITRATION MAY BE HIGHER THAN
              THOSE ASSOCIATED WITH COURT PROCEEDINGS. THE ARBITRATOR’S DECISION
              WILL BE BINDING, EXCEPT AS PROVIDED BELOW.
            </b>
            <ol className="lower-alpha">
              <li>
                <div>
                  <u>Agreement to Arbitrate.</u>
                  <span>
                    {" "}
                    Any claim, dispute, or controversy (“Claim”) arising out of
                    or relating in any way to: i) this Agreement; ii) the
                    Account, Card or our services; iii) your use of the Account,
                    Card or our services; iv) the amount of funds held in the
                    Account; v) advertisements, promotions or oral or written
                    statements related to the Account or our services; vi) the
                    benefits related to the Account, Card or our services; or
                    vii) transactions made using the Account, Card or our
                    services, no matter how described, pleaded or styled, shall
                    be FINALLY and EXCLUSIVELY resolved by binding individual
                    arbitration conducted by the American Arbitration
                    Association (“AAA”) under its Commercial Arbitration Rules.
                    This arbitration agreement is made pursuant to a transaction
                    involving interstate commerce and shall be governed by the
                    Federal Arbitration Act (9 U.S.C. 1-16). The arbitration
                    shall occur in [_______________].
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    ARBITRATION OF YOUR CLAIM IS MANDATORY AND BINDING. NEITHER
                    PARTY WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM THROUGH A
                    COURT. IN ARBITRATION, NEITHER PARTY WILL HAVE THE RIGHT TO
                    A JURY TRIAL OR TO ENGAGE IN DISCOVERY, EXCEPT AS PROVIDED
                    FOR IN THE AAA CODE OF PROCEDURE.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    For a copy of the procedures, to file a Claim or for other
                    information about this organization, contact it at: AAA, 335
                    Madison Avenue, New York, NY 10017, or at www.adr.org.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    All determinations as to the scope, interpretation,
                    enforceability and validity of this Agreement shall be made
                    exclusively by the arbitrator, which award shall be binding
                    and final. Judgment on the arbitration award may be entered
                    in any court having jurisdiction.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    NO CLASS ACTION, OR OTHER REPRESENTATIVE ACTION OR PRIVATE
                    ATTORNEY GENERAL ACTION OR JOINDER OR CONSOLIDATION OF ANY
                    CLAIM WITH A CLAIM OF ANOTHER PERSON OR CLASS OF CLAIMANTS
                    SHALL BE ALLOWABLE.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    This arbitration provision shall survive: i) the termination
                    of the Agreement; ii) the bankruptcy of any party; iii) any
                    transfer, sale or assignment of your Account, or any amounts
                    owed on your Account, to any other person or entity; or iv)
                    closing of the Account. If any portion of this arbitration
                    provision is deemed invalid or unenforceable, the remaining
                    portions shall remain in force.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    IF YOU DO NOT AGREE TO THE TERMS OF THIS ARBITRATION
                    AGREEMENT, YOU MUST NOTIFY PLATFORM WITHIN SIXTY (60) DAYS
                    FROM THE DATE YOU ENTER INTO THIS AGREEMENT AT
                    [________________], TO CLOSE THE ACCOUNT AND REQUEST A
                    REFUND, IF APPLICABLE.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <u>Costs of Arbitration.</u>
                  <span>
                    {" "}
                    You and we will be responsible for paying the fees of the
                    arbitrator and any administrative fees charged by the
                    arbitrator according to the rules and procedures of the
                    arbitrator. We will also pay or reimburse you for all or
                    part of other arbitration fees, if the arbitrator determines
                    there is good reason to do so, and we will pay any fees and
                    costs, which we are required to pay by law or by the rules
                    and procedures of the arbitrator. In addition, in the event
                    that you receive an arbitration award that is greater than
                    our last written settlement offer, the arbitrator shall have
                    the discretion to require us to pay your attorneys’ fees and
                    costs. Otherwise, each party will bear its own attorneys’
                    fees and costs, regardless of who prevails.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span>
                    The arbitrator’s decision is final and binding on the
                    parties, except for any right of appeal provided by the
                    Federal Arbitration Act. Costs will be allocated in the same
                    way as costs are allocated in arbitration by a single
                    arbitrator. A final and binding award is subject to judicial
                    review only as provided by the Federal Arbitration Act. An
                    arbitration award will be enforceable under the Federal
                    Arbitration Act by any court having jurisdiction.
                  </span>
                </div>
              </li>
            </ol>
          </div>
          <div>
            <b>Governing Law. </b>
            <span>
              Except to the governed by federal law, this Agreement shall be
              governed by the laws of the State of Oklahoma.
            </span>
          </div>
          <div>
            <b>Termination. </b>
            <span>
              We and you each may terminate this relationship unilaterally at
              any time upon notice. We may suspend your Account, Card or this
              Agreement at any time. To close your Account and Card and
              terminate this Agreement, please contact us through Platform at
              the customer service contact information set forth in this Section
              M above. For you to close your Account, your Account must not have
              a negative balance or any holds on funds in the Account. When you
              close your Account and Card, all outstanding items will be
              processed and posted to your Account or returned unpaid. Upon
              termination, Bank will return to you any remaining funds in the
              Account in a check mailed to your address on file associated with
              the Account, provided that you may request the balance to be
              returned to an external account that you have maintained,
              including any linked bank account, such request to be granted in
              our discretion. Allow at least fourteen (14) days for processing
              of any such balance return. You agree that we require a reasonable
              amount of time to make your cancellation effective after you give
              us notice of cancellation. We will not be liable for any loss or
              damage that may result from not honoring any items we receive
              after your Account is closed. All cards and linked bank accounts
              will be disconnected and no longer available to use in connection
              with the Account. Your cancellation of this Agreement will not
              affect any of our rights or your obligations arising under this
              Agreement prior to the effective date of your cancellation. You
              understand and acknowledge that even after executing this
              Agreement and opening an Account, Bank has the right to close your
              Account and terminate this relationship, and you will have no
              right to compel Bank to grant access to Bank services, either
              initially or after an Account is opened. If you terminate your
              relationship with Platform, the Account will automatically be
              closed.
            </span>
          </div>
        </li>
      </ol>
      <p>
        Regent Bank, member FDIC, is the issuer of the Card pursuant to a
        license by Mastercard International Incorporated. Mastercard is a
        registered trademark of Mastercard International Incorporated.
      </p>
      <table>
        <caption>
          <b>Fee Schedule</b>
        </caption>
        <thead>
          <tr>
            <th>Services</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ACH - Domestic</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>ATM fee</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>Check</td>
            <td>$0.00, Sending and receiving</td>
          </tr>
          <tr>
            <td>Wire Transfer - domestic</td>
            <td>N.A.</td>
          </tr>
          <tr>
            <td>Wire Transfer - international</td>
            <td>N.A.</td>
          </tr>
          <tr>
            <td>Account opening fee</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>Account maintenance fee</td>
            <td>$0.00</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AccountAgreement;
