import classNames from "./OTPInput.module.scss";
import { Form, Space } from "antd";
import { Input } from "../index";

const OTPInput = () => {
  return (
    <div className={classNames.inputsWrapper}>
       <Space 
        size="middle"
        style={{display: "block"}}
      >
        <Form.Item
          name="otp"
          key="otp"
          noStyle
          rules={[
            { required: true, message: "OTP is required" },
            { pattern: /^[0-9]{6}$/, message: "Only numbers are allowed" }        
          ]}
        >
          <Input
            id="otpBox"
            maxLength={6}
            className={classNames.label}
          />
        </Form.Item>
      </Space>
    </div>
  );
};

export default OTPInput;
