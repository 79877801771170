import { useEffect, useState, useRef, useContext } from "react";
import mixpanel from "mixpanel-browser";
import classNames from "../ManageCards.module.scss";
import { Form } from "antd";
import { Message, Modal } from "../../../common";
import generateErrorMessage from "../../../util/functions/customError";
import Basic from "./Steps/Basic";
import Addresses from "./Steps/Addresses";
import CardType from "./Steps/CardType";
import { useMutation, useQueryClient, useQuery } from "react-query";
import api from "../../../API";
import { AppContext } from "../../../../context/AppState";

export default function RequestNewCard({
  visible,
  onCancel,
  accountType,
  accountId,
  isAllowPhysicalCard,
  isAllowVirtualCard,
  allowedPhysicalCard,
  allowedVirtualCard,
}) {
  const [step, setStep] = useState(1);
  const [cardData, setCardData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const isMounted = useRef(false);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { application } = useContext(AppContext);

  useQuery(
    "getPerson",
    () => api.BusinessBanking.getPerson(application?.otz_person_id),
    {
      refetchOnWindowFocus: false,
      enabled: step === 1,
      onSuccess: (data) => {
        setUserInfo(data?.payload);
      },
    }
  );
  useQuery(
    "getBusinessPersonDetail",
    () =>
      api.onBoarding.getBusinessPersonDetail({
        otz_to_business_id: application?.otz_business_id,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: step === 1,
      onSuccess: (data) => {
        setUserInfo(data?.payload);
      },
    }
  );

  const createNewCardMutation = useMutation(api.BusinessBanking.createNewCard, {
    onSuccess: (data) => {
      Message({ type: "success", content: "Card successfully created!" });
      try {
        mixpanel.track("Card Request Successful", {
          "Account Holder Name": `${cardData?.firstName} ${cardData?.lastName}`,
          "Name on Card": cardData?.emboss_name,
          "Card Type": cardData?.type,
          "Date of Issuance": new Date().toISOString(),
          "Date of Activation": new Date().toISOString(),
          "Card Status": data.payload.status_reason
        });
      } catch (error) {
        console.error("Mixpanel error: ", error);
      }
      onCancel();
      setStep(1);
      form.resetFields();
    },
    onError: (error) => {
      generateErrorMessage(error);
      try {
        mixpanel.track("Card Request Failed", {
          "Account Holder Name": `${cardData?.firstName} ${cardData?.lastName}`, // replace with actual account holder name
          "Name on Card": cardData?.emboss_name,
          "Card Type": cardData?.type,
          "Date of Issuance": new Date().toISOString(), // replace with actual date of issuance
          "Date of Activation": new Date().toISOString(), // replace with actual date of activation
          "Card Status": "Failed"
        });
      } catch (error) {
        console.error("Mixpanel error: ", error);
      }
      setStep(1);
      queryClient.invalidateQueries("listCards");
      onCancel();
      form.resetFields();
    },
  });

  const handleModalClose = () => {
    onCancel();
    setStep(1);
    setCardData();
  };

  const handleNextStep = (data) => {
    setStep(step + 1);
    setCardData({ ...cardData, ...form.getFieldsValue(), ...data });
  };

  const handlePrevStep = () => setStep(step - 1);

  const onFinish = (data) => {
    setCardData({ ...cardData, ...form.getFieldsValue(), ...data });
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (isMounted.current) {
      const cardDataObj = {
        // data: {
        form: cardData?.type,
        otz_account_id: application?.otz_account_id,
        otz_customer_id:
          application?.type === "PERSON"
            ? application?.otz_person_id
            : cardData?.personId,
        emboss_name: { line_1: cardData?.emboss_name },
        ...(Boolean(cardData?.type === "PHYSICAL") && {
          address_line_1: cardData?.physicalCardStreet,
          address_line_2: cardData?.physicalCardStreet2,
          city: cardData?.physicalCardCity,
          country_code: cardData?.physicalCardCountry,
          postal_code: cardData?.physicalCardPostalCode,
          state: cardData?.physicalCardState,
          recipient_first_name: cardData?.firstName,
          recipient_last_name: cardData?.lastName,
        }),
        // attributes: {
        //   ...(Boolean(accountType === "business") && {
        //     address: {
        //       street: cardData.address.street,
        //       street2: cardData.address?.street2,
        //       city: cardData.address.city,
        //       state: cardData.address.state,
        //       postalCode: cardData.address.postalCode,
        //       country: cardData.address.country,
        //     },
        //     fullName: {
        //       first: cardData.fullName.first,
        //       last: cardData.fullName.last,
        //     },
        //     dateOfBirth: moment(cardData.dateOfBirth).format("YYYY-MM-DD"),
        //     phone: {
        //       countryCode: cardData.phone.countryCode,
        //       number: cardData.phone.number,
        //     },
        //     email: cardData.email,
        //   }),
        // limits: {
        //   ...(Boolean(cardData?.limits?.dailyPurchase) && {
        //     dailyPurchase: parseInt(cardData.limits?.dailyPurchase) * 100,
        //   }),
        //   ...(Boolean(cardData?.limits?.dailyWithdrawal) && {
        //     dailyWithdrawal:
        //       parseInt(cardData.limits?.dailyWithdrawal) * 100,
        //   }),
        //   ...(Boolean(cardData?.limits?.monthlyPurchase) && {
        //     monthlyPurchase:
        //       parseInt(cardData.limits?.monthlyPurchase) * 100,
        //   }),
        //   ...(Boolean(cardData?.limits?.monthlyWithdrawal) && {
        //     monthlyWithdrawal:
        //       parseInt(cardData.limits?.monthlyWithdrawal) * 100,
        //   }),
        // },
        // },
        // relationships: {
        //   account: {
        //     data: {
        //       type: "depositAccount",
        //       id: accountId,
        //     },
        //   },
        // },
        // },
      };
      createNewCardMutation.mutate(cardDataObj, {
        onSuccess: () => {
          setCardData();
          form.resetFields();
          queryClient.invalidateQueries("listCards");
          onCancel();
        },
        onError: (error) => {
          generateErrorMessage(error);
          setStep(1);
          queryClient.invalidateQueries("listCards");
          onCancel();
          form.resetFields();
        },
      });
    } else {
      isMounted.current = true;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const renderFormSteps = () => {
    // if (accountType === "individual") {
    //   if (step === 1) {
    //     return (
    //       <CardType
    //         accountType={accountType}
    //         onSuccess={handleNextStep}
    //         form={form}
    //         cardData={cardData}
    //       />
    //     );
    //   }
    //   if (step === 2) {
    //     return (
    //       <UsageLimit
    //         accountType={accountType}
    //         onSuccess={onFinish}
    //         onBack={handlePrevStep}
    //         form={form}
    //         cardData={cardData}
    //         createNewCardMutation={createNewCardMutation}
    //       />
    //     );
    //   }
    // } else {
    if (step === 1) {
      return (
        <CardType
          accountType={accountType}
          onSuccess={handleNextStep}
          form={form}
          cardData={cardData}
          userData={userInfo}
          isAllowPhysicalCard={isAllowPhysicalCard}
          isAllowVirtualCard={isAllowVirtualCard}
          allowedPhysicalCard={allowedPhysicalCard}
          allowedVirtualCard={allowedVirtualCard}
        />
      );
    }
    if (cardData.type === "PHYSICAL") {
      if (step === 2) {
        return (
          <Basic
            accountType={accountType}
            onSuccess={handleNextStep}
            onBack={handlePrevStep}
            form={form}
            cardData={cardData}
            userData={userInfo}
          />
        );
      }
      if (step === 3) {
        return (
          <Addresses
            accountType={accountType}
            onSuccess={onFinish}
            onBack={handlePrevStep}
            form={form}
            cardData={cardData}
            createNewCardMutation={createNewCardMutation}
            userData={userInfo}
          />
        );
      }
      // if (step === 4) {
      //   return (
      //     <UsageLimit
      //       accountType={accountType}
      //       onSuccess={onFinish}
      //       onBack={handlePrevStep}
      //       form={form}
      //       cardData={cardData}
      //       createNewCardMutation={createNewCardMutation}
      //     />
      //   );
      // }
    } else {
      if (step === 2) {
        return (
          <Basic
            accountType={accountType}
            onSuccess={onFinish}
            onBack={handlePrevStep}
            form={form}
            cardData={cardData}
            createNewCardMutation={createNewCardMutation}
            userData={userInfo}
          />
        );
      }
    }
    // }
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={handleModalClose}
      onCancel={onCancel}
      title="Request a new card"
      className={classNames.modal}
      bodyStyle={{
        // height: `${step === 3 ? "290px" : "auto"}`,
        height: "auto",
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <div className={classNames.overflowModalBody}>{renderFormSteps()}</div>
    </Modal>
  );
}
