import { API } from "aws-amplify";

export default async function getUnitApplication(id) {
  if (id) {
    return await API.get("getApplicationByTenantId", "unit-application-id", {
      queryStringParameters: {
        applicationId: id,
      },
    });
  }
}
