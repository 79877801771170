import { useContext, useMemo } from "react";
import { AppContext } from "../context/AppState";
import {
  ACCOUNT_TYPE,
  APPLICATION_STATUS,
  USER_STATES,
} from "../App/constants";
import ROLES from "../App/constants/scopesToRoles/roles";
// import useLocalStorage from "./useLocalStorage";
/*import {
  validateBeneficialDetailsForm,
  validateBusinessDetailsForm,
  validateOfficerDetailsForm,
} from "../App/util/functions/onboarding"; */

function useUserState() {
  //Hooks
  const { application, userData } = useContext(AppContext);
  // const [accountType] = useLocalStorage("OTTERZ_op_accountType");
  // const [businessDetails] = useLocalStorage("OTTERZ_op_bd");
  // const [officerDetails] = useLocalStorage("OTTERZ_op_od");
  // const [beneficialDetails] = useLocalStorage("OTTERZ_op_bo");

  const applicationData = useMemo(() => application || null, [application]);

  const isLoggedUser = userData?.active && userData?.tenantId ? true : false;

  const isEmailVerified = userData?.email_verified;

  const isChildUser = userData?.roleName !== ROLES.OWNER;

  const isPhoneVerified = useMemo(
    () =>
      isLoggedUser &&
      userData?.email_verified &&
      userData?.phone_number_verified,
    [isLoggedUser, userData?.email_verified, userData?.phone_number_verified]
  );

  const isAccountTypeSelected = useMemo(
    () =>
      isPhoneVerified &&
      isEmailVerified &&
      Object.values(ACCOUNT_TYPE).includes(applicationData?.type) /* ||
        Object.values(ACCOUNT_TYPE).includes(accountType?.accountType) */,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPhoneVerified, applicationData?.type /* accountType?.accountType */]
  );

  // const isApplicationAwaitingDocuments = useMemo(
  //   () =>
  //     isPhoneVerified &&
  //     applicationData?.applicationStatus.toLowerCase() ===
  //       APPLICATION_STATUS.AWAITING_DOCUMENTS,
  //   [isPhoneVerified, applicationData?.applicationStatus]
  // );

  const isBusinessAccountCreated = useMemo(
    () => applicationData?.onBoardingStatus === "BUSINESS_CREATED",
    [applicationData]
  );

  const isBeneficialOwnerCreated = useMemo(
    () => applicationData?.onBoardingStatus === "BENEFICIAL_OWNER_CREATED",
    [applicationData]
  );

  const isOfficerCreated = useMemo(
    () => applicationData?.onBoardingStatus === "OFFICER_CREATED",
    [applicationData]
  );

  const isApplicationPendingReview = useMemo(
    () =>
      isPhoneVerified &&
      isEmailVerified &&
      [
        APPLICATION_STATUS.PENDING_REVIEW,
        APPLICATION_STATUS.PROVISIONAL,
        APPLICATION_STATUS.REVIEW,
        APPLICATION_STATUS.UNVERIFIFED,
        APPLICATION_STATUS.CREATED,
      ].includes(applicationData?.verification_status),
      
      // [(isPhoneVerified, applicationData?.verification_status)]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [(isEmailVerified, applicationData?.verification_status)]
  );

  const isUserApproved = useMemo(
    () => userData?.approved === "true",
    [userData]
  );

  const isAccountPending = useMemo(
    () =>
      applicationData?.status === "ACTIVE" &&
      [APPLICATION_STATUS.APPROVED].includes(
        applicationData?.verification_status
      ) &&
      !applicationData?.otz_account_id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationData]
  );

  const isAccountCreated = useMemo(
    () =>
      applicationData?.status === "ACTIVE" &&
      [APPLICATION_STATUS.APPROVED].includes(
        applicationData?.verification_status
      ) &&
      applicationData?.otz_account_id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationData]
  );

  // const isApplicationDenied = useMemo(
  //   () =>
  //     isPhoneVerified &&
  //     !isUserApproved &&
  //     applicationData?.verification_status === APPLICATION_STATUS.DENIED,
  //   [applicationData, isPhoneVerified, isUserApproved]
  // );

  const isApplicationCanceled = useMemo(
    () =>
      // isPhoneVerified &&
      isPhoneVerified &&
      isEmailVerified &&
      !isUserApproved &&
      [APPLICATION_STATUS.CANCELED].includes(
        applicationData?.verification_status
      ),
    // [applicationData, isPhoneVerified, isUserApproved]
    [applicationData, isPhoneVerified, isEmailVerified, isUserApproved]
  );

  const isApplicationCompleted = useMemo(
    () =>
      (isPhoneVerified &&
        isEmailVerified &&
        applicationData?.status === "ACTIVE" &&
        applicationData?.verification_status === APPLICATION_STATUS.APPROVED) ||
      userData?.approved === "true",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmailVerified, applicationData?.verification_status, userData]
    // [isPhoneVerified, applicationData?.verification_status, userData]
  );

  const status = useMemo(
    () => {
      let status = USER_STATES.NOT_REGISTERED;

      if (!isEmailVerified && isLoggedUser) {
        status = USER_STATES.ONBOARDING;
      }

      if (isEmailVerified && isLoggedUser) {
        status = USER_STATES.EMAIL_VERIFIED;
      }

      // if (isEmailVerified && isPhoneVerified) {
      //   status = USER_STATES.PHONE_VERIFIED;
      // }

      if (isPhoneVerified && isAccountTypeSelected) {
        if (
          // accountType.accountType === ACCOUNT_TYPE.INDIVIDUAL ||
          applicationData?.type === ACCOUNT_TYPE.INDIVIDUAL
        ) {
          status = USER_STATES.INDIVIDUAL_ACCOUNT_TYPE_SELECTED;

          // if (isApplicationAwaitingDocuments) {
          //   status = USER_STATES.INDIVIDUAL_AWAITING_DOCUMENTS;
          // }

          if (isApplicationPendingReview) {
            status = USER_STATES.PENDING_REVIEW;
          }

          if (applicationData?.status === "PROSPECT") {
            status = USER_STATES.PENDING_REVIEW;
          }
        } else if (
          // accountType.accountType === ACCOUNT_TYPE.BUSINESS ||
          applicationData?.type === ACCOUNT_TYPE.BUSINESS
        ) {
          // status = USER_STATES.BUSINESS_ACCOUNT_TYPE_SELECTED;

          // const isBusinessDetailsFormValid =
          //   validateBusinessDetailsForm(businessDetails);
          // // || !!applicationData?.otz_business_id;
          // const isOfficerDetailsFormValid =
          //   validateOfficerDetailsForm(officerDetails);
          // const isBeneficialDetailsValid = validateBeneficialDetailsForm(
          //   beneficialDetails?.beneficialOwners
          // );

          if (isBusinessAccountCreated) {
            status = USER_STATES.BUSINESS_DETAILS_VALID;
          }

          if (isBeneficialOwnerCreated) {
            status = USER_STATES.BUSINESS_BENEFICIAL_DETAILS_VALID;
          }

          if (isOfficerCreated) {
            status = USER_STATES.BUSINESS_OFFICER_DETAILS_VALID;
          }

          // if (isApplicationAwaitingDocuments) {
          //   status = USER_STATES.BUSINESS_AWAITING_DOCUMENTS;
          // }

          if (isApplicationPendingReview && isOfficerCreated) {
            status = USER_STATES.PENDING_REVIEW;
          }

          if (applicationData?.status === "PROSPECT" && isOfficerCreated) {
            status = USER_STATES.PENDING_REVIEW;
          }
        }
      }

      if (
        (isApplicationPendingReview && !isUserApproved && isOfficerCreated) ||
        isAccountPending
      ) {
        status = USER_STATES.PENDING_REVIEW;
      }

      // if (!isUserApproved && isApplicationAwaitingDocuments) {
      //   status = USER_STATES.APPLICATION_AWAITING_DOCUMENT;
      // }

      // if (isApplicationDenied) {
      //   status = USER_STATES.APPLICAITON_DENIED;
      // }

      if (isApplicationCanceled) {
        status = USER_STATES.APPLICATION_CANCELED;
      }

      if (isChildUser && isLoggedUser) {
        status = USER_STATES.AUTHORIZED_USER;
      }

      if (isApplicationCompleted && isAccountCreated) {
        status = USER_STATES.AUTHORIZED_USER;
      }

      return status;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoggedUser,
      isEmailVerified,
      isPhoneVerified,
      isAccountTypeSelected,
      isApplicationCompleted,
      isApplicationPendingReview,
      isBusinessAccountCreated,
      isBeneficialOwnerCreated,
      isOfficerCreated,
      // accountType,
      // businessDetails,
      // officerDetails,
      // beneficialDetails?.beneficialOwners,
      isChildUser,
      isUserApproved,
      isApplicationCanceled,
    ]
  );

  return status;
}

export default useUserState;
