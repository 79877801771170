// convert interger dollar to decimal dollar with $ sign (ex:- 45 -> $45.00)
const intToDecimalDollar = (amount) => {
  if (!amount) return null;
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
};

// convert cents dollar to decimal dollar WITH $ sign (ex:- 3500 -> $35.00)
const centsToDollar = (amount) => {
  if (!amount) return null;
  return (amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
};

// convert cents dollar to decimal dollar WITHOUT sign (ex:- 1500 -> 15.00)
const centsToDollarWithoutSign = (amount) => {
  if (!amount && amount !== 0) return null;
  return (amount / 100).toLocaleString("en-US", { minimumFractionDigits: 2 });
};

const centsToDollarFourDecimal = (amount) => {
  if (!amount && amount !== 0) return null;
  return (amount / 100).toLocaleString("en-US", { minimumFractionDigits: 4 });
};

// convert decimal dollar to cents dollar (ex:- 35.50 -> 3550)
const dollarToCents = (amount) => {
  if (!amount) return null;
  //TODO: this following comment code is added as fix 0.58x100 = 57.99999999999999, there is another issue of this code
  // so I comment this until we found fix for 0.58x100 = 57.99999999999999
  //const digits = amount.toString().length;
  //const value = parseFloat(amount * 100).toPrecision(digits);
  return parseInt(amount * 100);
};

const dollarFourDecimal = (amount) => {
  if (!amount && amount !== 0) return null;
  return amount.toLocaleString("en-US", { minimumFractionDigits: 4 });
};
export {
  intToDecimalDollar,
  centsToDollar,
  dollarToCents,
  centsToDollarWithoutSign,
  centsToDollarFourDecimal,
  dollarFourDecimal,
};
