import { Card, Button, Spin } from "antd";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import EyeFillIcon from "remixicon-react/EyeFillIcon";
import EyeOffFill from "remixicon-react/EyeOffFillIcon";
// import FileListLineIcon from "remixicon-react/FileListLineIcon";
import api from "../../../../API";
// import camelCaseToTitle from "../camelCaseToTitle.util";
import { OtterzBlackIcon } from "../../../../../assets/otterzBlackIcon";
import { OtterzWhiteIcon } from "../../../../../assets/otterzWhiteIcon";
import { FileIcon } from "../../../../../assets/fileIcon";
import { VectorIcon } from "../../../../../assets/vectorIcon";
import { ROUTES } from "../../../../constants";
import generateErrorMessage from "../../../../util/functions/customError";

export default function CreditCard({
  card,
  classNames,
  activeSlide,
  scriptLoaded,
  hideCardDetail,
}) {
  const {
    cvv,
    id: cardId,
    bgColor,
    cardType,
    cardHolder,
    cardIssuer,
    viewStatement,
    cardNumber,
    cardStatus,
    cardForm,
  } = card;

  const [showCardNumber, setShowCardNumber] = useState(false);
  const [showCVV, setShowCVV] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const { VGSShow } = window;

  const cvv2Ref = useRef();
  const cardRef = useRef();

  const createClientToken = useMutation(
    (id) => api.BusinessBanking.createClientToken(id),
    {
      onSuccess: (res) => {
        window.marqeta.bootstrap({
          clientAccessToken: res?.payload?.client_token,
          integrationType: "custom",
          showPan: {
            cardPan: {
              domId: "display-card-pan",
              format: true,
              styles: {
                span: {
                  color: cardForm === "VIRTUAL" ? "" : "#ffffff",
                  "font-family": "Inter",
                  "font-size": "22px",
                  "font-weight": "300",
                },
              },
            },
            cardExp: {
              domId: "display-card-exp",
              format: true,
              styles: {
                span: {
                  color: cardForm === "VIRTUAL" ? "" : "#ffffff",
                  "font-family": "Inter",
                  "font-size": "22px",
                  "font-weight": "300",
                },
              },
            },
            cardCvv: {
              domId: "display-card-cvv",
              styles: {
                span: {
                  color: cardForm === "VIRTUAL" ? "" : "#ffffff",
                  "font-family": "Inter",
                  "font-size": "22px",
                  "font-weight": "300",
                },
              },
            },
          },
          callbackEvents: {
            onFailure: (error) => console.log(error),
            onSuccess: () => console.log("Marqeta successfully loaded"),
          },
        });
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        generateErrorMessage(error);
      },
    }
  );

  useEffect(() => {
    if (hideCardDetail) hideDetail();
  }, [hideCardDetail]);

  useEffect(() => {
    if (scriptLoaded && showCVV && showCardNumber) {
      createClientToken.mutate(cardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, showCVV, scriptLoaded, showCardNumber]);

  useEffect(() => {
    return () => {
      setShowCVV(false);
      setShowCardNumber(false);
      setIsLoading(false);
      cardRef.current = null;
      cvv2Ref.current = null;
    };
  }, [activeSlide]);

  const navigate = useNavigate();
  const onClickNavigate = () => {
    <div className={classNames.creditCardNumber}>{cardNumber}</div>;
    navigate(ROUTES.APP.BUSINESS_BANKING.STATEMENTS_TRANSACTIONS, {
      state: {
        id: "card",
        cardNumber: cardNumber,
        otz_card_id: cardId,
      },
    });
  };

  const CardNum = () => {
    return (
      <span
        id="display-card-pan"
        style={{
          height: 18,
          marginBottom: showCVV ? "10px" : "20px",
          padding: "1px 0 2px 1px",
          zIndex: 1,
        }}
      />
    );
  };

  const ExpDate = () => {
    return (
      <span
        id="display-card-exp"
        style={{
          display: "inline-block",
          height: 20,
          width: 40,
          padding: "1px 0 2px 1px",
          zIndex: 1,
        }}
      />
    );
  };

  const CVVNum = () => {
    return (
      <div
        id="display-card-cvv"
        style={{
          display: "inline-block",
          height: 20,
          width: 30,
          padding: "1px 0 2px 1px",
          zIndex: 1,
        }}
      />
    );
  };

  const onClickEyeIcon = () => {
    // if (!customerBearerToken) {
    //   verficationTokenMutation.mutate();
    //   setShowDisplayOTP(true);
    // } else {
    setShowCVV((prevData) => !prevData);
    setShowCardNumber((prevData) => !prevData);
    // }
  };

  const hideDetail = () => {
    setShowCVV(false);
    setShowCardNumber(false);
    setIsLoading(false);
  };

  const handleClick = () => {
    if (cardStatus === "TERMINATED" || cardStatus === "SUSPENDED") {
      return;
    }
    onClickEyeIcon();
    !showCVV && setIsLoading(true);
  };

  return (
    <>
      <Spin spinning={isLoading} style={{ left: -66 }}>
        <Card
          className={classNames.creditCard}
          style={{
            backgroundColor: `${bgColor}`,
            opacity:
              (cardStatus === "TERMINATED" || cardStatus === "SUSPENDED") &&
              0.4,
          }}
        >
          <div className={classNames.creditCardHeader} style={{ zIndex: 1 }}>
            <span
              style={{
                color: cardForm === "PHYSICAL" ? "#0C0C0C" : "#FFF",
                backgroundColor: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
              }}
              className={classNames.cardType}
            >
              {cardForm + " " + cardType}
            </span>
            {cardForm === "VIRTUAL" ? (
              <OtterzBlackIcon height={35} width={97} />
            ) : (
              <OtterzWhiteIcon height={35} width={97} />
            )}
          </div>

          <div
            className={classNames.creditCardNumber}
            style={{ margin: showCardNumber && 0 }}
          >
            <span
              style={{
                color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                fontSize: "8px",
                fontWeight: 500,
              }}
            >
              CARD NUMBER
            </span>
            {(!showCardNumber && (
              <>
                <span
                  style={{
                    color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                    zIndex: 1,
                  }}
                >
                  {cardNumber}{" "}
                </span>
              </>
            )) || <CardNum />}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className={classNames.creditCardInfo}
              style={{
                height: showCVV && showCardNumber ? "41px" : "initial",
                color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                zIndex: 1,
                marginTop: showCVV && "10px",
              }}
            >
              <div
                style={{
                  height: showCVV && showCardNumber ? "41px" : "initial",
                  color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                }}
              >
                <span>GOOD THRU</span>
                {!showCardNumber && !showCVV ? (
                  <span
                    style={{
                      color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                    }}
                  >
                    {"**/**"}
                  </span>
                ) : (
                  <ExpDate />
                )}
              </div>
              <div
                style={{
                  height: showCVV && showCardNumber ? "41px" : "initial",
                }}
              >
                <span>CVC</span>
                {(!showCVV && (
                  <span
                    style={{
                      marginLeft: "auto",
                      color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                    }}
                  >
                    {cvv}
                  </span>
                )) || <CVVNum />}
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#4B494B",
                  fontSize: "10px",
                  fontWeight: 700,
                }}
              >
                BUSINESS
              </span>
              &nbsp;
              <span
                style={{
                  color: "#4B494B",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                DEBIT
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: showCVV ? 9 : 5,
              zIndex: 1,
            }}
          >
            <div
              style={{
                fontSize: "15px",
                fontWeight: 500,
                color: cardForm === "VIRTUAL" ? "#0C0C0C" : "#FFF",
                textTransform: "uppercase",
              }}
            >
              <span>{cardHolder}</span>
            </div>

            <img
              src={cardIssuer}
              alt="card issuer"
              width={80}
              style={{
                alignSelf: showCVV && showCardNumber ? "start" : "center",
              }}
            />
          </div>

          <div
            style={{
              zIndex: 0,
              position: "absolute",
              left: 4,
              top: 0,
              opacity: 0.3,
            }}
          >
            <VectorIcon fill={cardForm === "VIRTUAL" ? "#0D0E0E" : "#D3D3D3"} />
          </div>
        </Card>{" "}
        {cardStatus === "TERMINATED" ? (
          <div
            className={classNames.creditCardFooter}
            style={{ backgroundColor: "#C41212" }}
          >
            This card has been closed
          </div>
        ) : cardStatus === "SUSPENDED" ? (
          <div
            className={classNames.creditCardFooter}
            style={
              cardForm === "VIRTUAL"
                ? { backgroundColor: "#0C0C0C" }
                : { backgroundColor: "#C3C3C3", color: "#0C0C0C" }
            }
          >
            This card has been freezed
          </div>
        ) : (
          <div className={classNames.creditCardFooter}>
            <Button
              type="link"
              className={classNames.creditCardFooterAction}
              onClick={viewStatement}
              disabled={
                cardStatus === "TERMINATED" || cardStatus === "UNACTIVATED"
              }
              style={{
                opacity:
                  (cardStatus === "TERMINATED" ||
                    cardStatus === "UNACTIVATED") &&
                  0.4,
                color: "#FFF",
              }}
            >
              <FileIcon />
              <span onClick={onClickNavigate}>View Statement</span>
            </Button>

            <div
              className={classNames.viewCard}
              onClick={handleClick}
              style={{
                opacity:
                  (cardStatus === "TERMINATED" || cardStatus === "SUSPENDED") &&
                  0.4,
                cursor:
                  (cardStatus === "TERMINATED" || cardStatus === "SUSPENDED") &&
                  "not-allowed",
              }}
            >
              {showCVV && showCardNumber ? (
                <EyeOffFill
                  size={15}
                  style={{
                    color: "#FFF",
                  }}
                />
              ) : (
                <EyeFillIcon
                  size={15}
                  style={{
                    color: "#FFF",
                  }}
                />
              )}
              <span>{!showCVV ? "Show Card Detail" : "Hide Card Detail"}</span>
            </div>
          </div>
        )}
      </Spin>
    </>
  );
}
