import classNames from "../Invoices.module.scss";
import { CTAButton, Title, Message } from "../../../../common";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../../API";
import { Modal } from "antd";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function 
ChangeStatus({
  visible,
  status,
  onCancel,
  setChangeStatusModalVisibility,
}) {
  const language = useLanguage(messages);
  const queryClient = useQueryClient();
  //Update invoice
  const updateInvoiceFunction = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);

  const updateInvoiceMutation = useMutation((event) =>
    updateInvoiceFunction(event)
  );

  function shareInvoiceFn(input) {
    return api.Receivables.Share.email(input);
  }

  const shareInvoiceMn = useMutation((data) => shareInvoiceFn(data));

  const updateInvoiceStatus = (data) =>
    updateInvoiceMutation.mutate(data, {
      onSuccess: () => {
        if (data.status === "sent") {
          shareInvoiceMn.mutate({
            id: data.id,
            type: data.type,
          });
        }

        queryClient.invalidateQueries("getInvoices");
        queryClient.invalidateQueries("getInvoiceById", status.record.id);
        queryClient.invalidateQueries("getOverallInvoiceDetails");
        Message({ type: "success", content: language.STATUS_CHANGE_SUCCESS });
        onCancel();
      },
      onError: (error) => {
        generateErrorMessage(error);
        queryClient.invalidateQueries("getInvoices");
        queryClient.invalidateQueries("getOverallInvoiceDetails");
        onCancel();
      },
    });

  //Update estimate
  const updateEstimateFunction = (event) => {
    return api.Receivables.Invoices.setInvoiceStatus(event);
  };

  const updateEstimateMutation = useMutation((event) =>
    updateEstimateFunction(event)
  );

  const updateEstimateStatus = (data) => {
    updateEstimateMutation.mutate(data, {
      onSuccess: () => {
        if (data.status === "sent") {
          shareInvoiceMn.mutate({
            id: data.id,
            type: data.type,
          });
        }

        queryClient.invalidateQueries("getEstimates");
        queryClient.invalidateQueries("getEstimateById", status.record.id);
        queryClient.invalidateQueries("getOverallInvoiceDetails");
        setChangeStatusModalVisibility(false);
        Message({ type: "success", content: language.STATUS_CHANGE_SUCCESS });
      },
      onError: (error) => {
        queryClient.invalidateQueries("getEstimates");
        queryClient.invalidateQueries("getOverallInvoiceDetails");
        generateErrorMessage(error);
      },
    });
  };

  //Update recurring invoice
  const updateRecurringInvoiceFunction = (event) => {
    return api.Receivables.Recurring.editRecurringInvoice(event);
  };

  const updateRecurringInvoiceMutation = useMutation((event) =>
    updateRecurringInvoiceFunction(event)
  );

  const updateRecurringInvoiceStatus = (data) => {
    updateRecurringInvoiceMutation.mutate(data, {
      onSuccess: () => {
        Message({ type: "success", content: language.STATUS_CHANGE_SUCCESS });
        queryClient.invalidateQueries("getRecurringData");
        queryClient.invalidateQueries(
          "getRecurringInvoiceById",
          status.record.id
        );
        setChangeStatusModalVisibility(false);
      },
      onError: (error) => {
        generateErrorMessage(error);
        queryClient.invalidateQueries("getRecurringData");
      },
    });
  };

  const loading =
    updateInvoiceMutation.isLoading ||
    updateEstimateMutation.isLoading ||
    updateRecurringInvoiceMutation.isLoading;
  // const { mutate: changeInvoiceStatus } = useMutation(
  //   api.Receivables.Invoices.editInvoice,
  //   {
  //     onSuccess: (data) => {
  //       setTimeout(() => {
  //         message.success("Payment updated successfully!");
  //       }, 1000);
  //       onCancel();
  //     },
  //   }
  // );
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
      footer={false}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Change Status
      </Title>
      <div style={{ marginBottom: 18 }}>
        Are you sure you want to change the status to ‘{status && status.value}
        ’?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "auto",
            marginLeft: "10px",
          }}
          onClick={() =>
            status && status.flag === "invoice"
              ? updateInvoiceStatus({
                  id: status.record.id,
                  type: "invoice",
                  status: status.value,
                })
              : status && status.flag === "estimate"
              ? updateEstimateStatus({
                  id: status.record.id,
                  type: "estimate",
                  status: status.value,
                })
              : updateRecurringInvoiceStatus({
                  id: status.record.id,
                  type: "invoice",
                  status: status.value,
                })
          }
          loading={loading}
        >
          Yes, change
        </CTAButton>
      </div>
    </Modal>
  );
}

ChangeStatus.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  status: PropTypes.string,
};
