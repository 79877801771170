import ENDPOINT_KEYS from "./endpointKeys";

const baseUrl = "https://v0.api.otterz.co";
const bankingBaseURL = "https://tten.app.otterz.co/api/v1/";
const cardknoxBaseUrl = "https://tten.app.otterz.co:8000/api/v1/cardknox/";

const TTEN = {
  [ENDPOINT_KEYS.account]: `${bankingBaseURL}accounts/`,
  [ENDPOINT_KEYS.payees]: `${bankingBaseURL}payee/`,
  [ENDPOINT_KEYS.businessBanking]: `${bankingBaseURL}transactions/overview`,
  [ENDPOINT_KEYS.statements]: `${bankingBaseURL}statements`,
  [ENDPOINT_KEYS.transactions]: `${bankingBaseURL}transactions/`,
  [ENDPOINT_KEYS.achPayments]: `${bankingBaseURL}make-payments`,
  [ENDPOINT_KEYS.createApplication]: `${bankingBaseURL}`,
  [ENDPOINT_KEYS.bankLetter]: `${bankingBaseURL}bankLetter/`,
  [ENDPOINT_KEYS.businessRelationship]: `${bankingBaseURL}business-relation/`,
  [ENDPOINT_KEYS.externalAccount]: `${bankingBaseURL}external-account/`,
  [ENDPOINT_KEYS.checkDeposits]: `${bankingBaseURL}check/deposits`,
  [ENDPOINT_KEYS.schedulePayments]: `${bankingBaseURL}schedule`,
  [ENDPOINT_KEYS.getApplicationByTenantId]: `${bankingBaseURL}tenant`,
  [ENDPOINT_KEYS.updateBusinessOperationDetails]: `${bankingBaseURL}business/`,
  [ENDPOINT_KEYS.disclosure]: `${bankingBaseURL}disclosure`,
  [ENDPOINT_KEYS.kyckyb]: `${bankingBaseURL}kyckyb/verify`,
  [ENDPOINT_KEYS.status]: `${bankingBaseURL}`,
  [ENDPOINT_KEYS.otzcards]: `${bankingBaseURL}cards/`,
  [ENDPOINT_KEYS.webhook]: `${bankingBaseURL}webhook/kyc`,
  [ENDPOINT_KEYS.sendMail]: `${bankingBaseURL}sendMail`,
  [ENDPOINT_KEYS.notification]: `${bankingBaseURL}notification/`,
  [ENDPOINT_KEYS.rewards]: `${bankingBaseURL}rewards/`,
  [ENDPOINT_KEYS.subscription]: `${bankingBaseURL}subscription/`,
  [ENDPOINT_KEYS.paLink]: `${bankingBaseURL}pa-link/`,
  [ENDPOINT_KEYS.cardknoxSync]: `${cardknoxBaseUrl}`,

  [ENDPOINT_KEYS.taxRate]: `${baseUrl}/it/invoice-tax/`,

  [ENDPOINT_KEYS.product]: `${baseUrl}/ip/invoice-product/`,

  [ENDPOINT_KEYS.invoices]: `${baseUrl}/invoice/invoice/`,

  [ENDPOINT_KEYS.getOverallInvoiceDetails]: `${baseUrl}/invoice/`,

  [ENDPOINT_KEYS.cards]: `${baseUrl}/cards/card/`,

  [ENDPOINT_KEYS.payments]: `${baseUrl}/pay/payments/`, // TODO: Remove the duplication with 'createPayment'

  [ENDPOINT_KEYS.customPayment]: `${baseUrl}/pay/payments/`,

  [ENDPOINT_KEYS.subscriptionPlan]: `${baseUrl}/sp/subscription-plan/`,

  [ENDPOINT_KEYS.subscriptionUser]: `${baseUrl}/su/`, // TODO: Check whether path is correct. GET endpoint is defined under '/subscription-user' endpoint

  [ENDPOINT_KEYS.authentication]: `${baseUrl}/authentication/authentication/`,

  [ENDPOINT_KEYS.notifications]: `${baseUrl}/en/`,

  [ENDPOINT_KEYS["general-info"]]: `${baseUrl}/gi/general-info`,

  [ENDPOINT_KEYS.paymentAcceptanceOnboard]: `${baseUrl}/pa/pa-onboarding`,

  [ENDPOINT_KEYS.paymentAcceptanceTransaction]: `${baseUrl}/pat/pa-transaction`,

  [ENDPOINT_KEYS.cardknox]: `${baseUrl}/pac/cardknox/`,

  [ENDPOINT_KEYS["temp-notifications"]]: `${baseUrl}/en/`,

  [ENDPOINT_KEYS["pa-transaction"]]: `${baseUrl}/pat/pa-transaction`, // TODO: Remove the duplication with 'paymentAcceptanceTransaction'

  [ENDPOINT_KEYS["pa-transaction-public"]]: `${baseUrl}/pat/pa-transaction`, // TODO: Remove the duplication with 'paymentAcceptanceTransaction'

  [ENDPOINT_KEYS["pa-settings"]]: `${baseUrl}/pas/pa-settings/`,

  [ENDPOINT_KEYS["pa-settings-public"]]: `${baseUrl}/pas/pa-settings/`,

  [ENDPOINT_KEYS["cardknox-ifields"]]: `${baseUrl}/tenant/merchant/`,

  [ENDPOINT_KEYS["subscription-public"]]: `${baseUrl}/subscription/`, // TODO: This public endpoint should removed after fixing authorizer user pool issue};
};

export default TTEN;
