import {
  last30DaysFilterProps,
  last3MonthsFilterProps,
  lastHourFilterProps,
  transactionModes,
  transactionStates,
  transactionTypes,
} from "../API/PaymentAcceptance/Transactions/listTransaction.api";

const reports = [
  {
    name: "Approved transactions",
    filter: {
      xCommand: transactionStates.approved.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.all.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Declined transactions",
    filter: {
      xCommand: transactionStates.declined.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.all.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Card transactions",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.creditCard.type,
      transactionMode: transactionModes.all.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Check transactions",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.check.type,
      transactionMode: transactionModes.all.mode,
      ...last30DaysFilterProps,
    },
  },
  {
    name: "Last hour",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.all.mode,
      ...lastHourFilterProps,
    },
  },
  {
    name: "Last 3 months",
    filter: {
      xCommand: transactionStates.all.state,
      transactionType: transactionTypes.all.type,
      transactionMode: transactionModes.all.mode,
      ...last3MonthsFilterProps,
    },
  },
];

export default reports;
