import { useState, useMemo, useEffect } from "react";
import {
  Form,
  Button as AntButton,
  Space,
  DatePicker as RangeDatePicker,
} from "antd";
import moment from "moment";
// import { RiCloseCircleFill } from "react-icons/ri";
// import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";
import { Modal, CTAButton } from "../../../common";
import { FORMATS } from "../../../constants";
import classNames from "../StatementsAndTransactions.module.scss";

const FilterFields = ({
  onCancel,
  setCustomQueryParams,
  getCustomQueryParams,
  mainFilter,
  isForCard,
}) => {
  //Hooks
  const [form] = Form.useForm();

  const { from_date, to_date, filterType, type } = getCustomQueryParams();

  const [transactionType, setTransactionType] = useState(
    type ? type?.split(",") : []
  );

  const { RangePicker } = RangeDatePicker;

  const addToType = useMemo(
    () => (value) => {
      if (!transactionType.includes(value)) {
        setTransactionType([...transactionType, value]);
      } else {
        return setTransactionType(transactionType.filter((el) => el !== value));
      }
    },
    [transactionType]
  );

  useEffect(() => {
    if (filterType) {
      setTransactionType([]);
    }
  }, [filterType, mainFilter]);

  // const transactionBtns = ["ACH", "Wire", "Card", "Check"];
  const transactionBtns = [
    { value: "ach", label: "ACH" },
    { value: "card", label: "Card" },
    { value: "check", label: "Check" },
    // { value: "wire", label: "Wire" },
  ];

  //Event handlers
  const handleFilter = (values) => {
    const input = {
      ...(values?.range && {
        from_date: moment(values?.range[0])
          .startOf("day")
          .format(FORMATS.API_SEND_FORMAT),
        to_date: moment(values?.range[1])
          .endOf("day")
          .format(FORMATS.API_SEND_FORMAT),
      }),
      ...(transactionType.length > 0 ? { type: transactionType } : {}),
    };
    setCustomQueryParams(input, transactionType);
    onCancel();
  };

  //JSX Element
  const renderTransactionBtns = () => {
    return transactionBtns.map((btn, index) => {
      const isSelect = transactionType.includes(btn.value);
      return (
        <AntButton
          key={index}
          onClick={() => addToType(btn.value)}
          disabled={isForCard ? true : false}
          type="text"
          className={
            isSelect
              ? classNames.optionTxtButtonSelected
              : classNames.customFieldOptionTxtButton
          }
        >
          {btn.label}
        </AntButton>
      );
    });
  };

  function disabledFutureDate(current) {
    return current && current >= moment();
  }

  //TODO: TRANSACTION AMOUNT AND TRANSACTION PATH TO BE IMPLEMENTED POST MVP
  // const [transactionPath, setTransactionPath] = useState([]);
  // const paymentTypeBtns = ["Outgoing", "Incoming"];
  // const addToPath = (value) => {
  //   if (!transactionPath.includes(value)) {
  //     setTransactionPath([...transactionPath, value]);
  //   } else {
  //     return setTransactionPath(transactionPath.filter((el) => el !== value));
  //   }
  // };
  // const renderPaymentTypeBtns = () => {
  //   return paymentTypeBtns.map((btn, index) => {
  //     return (
  //       <AntButton
  //         key={index}
  //         type="text"
  //         onClick={() => addToPath(btn)}
  //         className={
  //           transactionPath.includes(btn)
  //             ? classNames.optionTxtButtonSelected
  //             : classNames.optionTxtButton
  //         }
  //         style={{
  //           height: 44,
  //           width: "48.5%",
  //           display: "inline-flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         {btn} <ArrowDownLineIcon />
  //       </AntButton>
  //     );
  //   });
  // };

  return (
    <div className={classNames.modalBody}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={handleFilter}
        autoComplete="off"
      >
        <Form.Item
          label={<span className={classNames.label}>Filter by date</span>}
        >
          <Form.Item
            name="range"
            initialValue={
              from_date && to_date && !filterType
                ? [moment(from_date), moment(to_date)]
                : undefined
            }
          >
            <RangePicker
              disabledDate={disabledFutureDate}
              format={FORMATS.DATEPICKER_FORMAT}
              style={{ width: "380px", height: "40px" }}
            />
          </Form.Item>
        </Form.Item>

        {/* <Form.Item>
          <Form.Item
            name="amount"
            style={{
              display: "inline-block",
              marginRight: 14,
              width: "47.5%",
            }}
            label={<span className={classNames.label}>Transaction amount</span>}
          >
            <Input type="number" placeholder="Min" size="small" />
          </Form.Item>

          <Form.Item
            name="maximumAmount"
            style={{
              display: "inline-block",
              width: "47.5%",
            }}
            label={<span className={classNames.label}>Maximum</span>}
          >
            <Input type="number" placeholder="Max" size="small" />
          </Form.Item>
        </Form.Item> */}

        {!isForCard && (
          <Form.Item
            label={<span className={classNames.label}>Transaction type</span>}
          >
            <Space size={27}>
              {renderTransactionBtns()}{" "}
              {/* <span
                style={{
                  padding: "5px 30px 0",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <RiCloseCircleFill
                  size={24}
                  className={classNames.inputGroupRemoveIcon}
                  onClick={() => {
                    setTransactionType([]);
                  }}
                />
              </span> */}
            </Space>
          </Form.Item>
        )}

        {/* <Form.Item
          label={<span className={classNames.label}>Transaction path</span>}
        >
          {renderPaymentTypeBtns()}
        </Form.Item> */}
        <CTAButton
          htmlType="submit"
          type="primary"
          style={{ height: 44, width: 384 }}
        >
          Filter
        </CTAButton>
      </Form>
    </div>
  );
};

export default function CustomFilter({
  visible,
  onCancel,
  setCustomQueryParams,
  getCustomQueryParams,
  mainFilter,
  isForCard,
}) {
  return (
    <Modal
      destroyOnClose
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Custom Filter"
      className={classNames.modal}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <FilterFields
        onCancel={onCancel}
        setCustomQueryParams={setCustomQueryParams}
        mainFilter={mainFilter}
        getCustomQueryParams={getCustomQueryParams}
        isForCard={isForCard}
      />
    </Modal>
  );
}
