import { Title } from "../../common";

function Heading({ classNames, title, description }) {
  return (
    <div className={classNames.headerWrapper}>
      <Title as="h1">{title}</Title>
      <div {...( classNames.description ? {className: classNames.description} : {style: { width: "348px" }})}>{description}</div>
    </div>
  );
}

export default Heading;
