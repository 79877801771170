import classNames from "../../TemplateSettings.module.scss";
import { Input, Select, Title } from "../../../../../common";
import { Form, Checkbox, Switch, Popover } from "antd";
import constants from "../../../../../Messages/paymentAcceptance/constants";
import REGEX from "../../../../../util/constants/regex";

export default function InvoiceDetails({
  setAcceptQR,
  setEnableOnlinePayments,
  setReminderMessage,
  acceptQR,
  enableOnlinePayments,
  reminderMessage,
  data,
  paStatus,
  paSettingsStatus,
}) {
  return (
    <div>
      <div className={classNames.modalHeader}>
        <Title as="h3">Invoice Details</Title>
      </div>
      <Form.Item>
        <Form.Item
          name="taxNumber"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            marginBottom: "10px",
            float: "left",
          }}
          label={<span className={classNames.label}>EIN Number</span>}
          initialValue={data?.taxNumber?.replace("-", "")}
          rules={[
            {
              pattern: new RegExp(REGEX.einNumber),
              message: "Enter a valid EIN",
            },
          ]}
        >
          <Input
            placeholder="Please enter"
            size="small"
            defaultValue={data?.taxNumber}
          />
        </Form.Item>

        <Form.Item
          name="registrationNumber"
          style={{
            display: "inline-block",
            width: "calc(50% - 4px)",
            margin: "0 0 0 4px",
            float: "right",
          }}
          label={<span className={classNames.label}>Registration Number</span>}
          rules={[{ max: 50, message: "Max 50 characters" }]}
        >
          <Input
            placeholder="Please enter"
            size="small"
            defaultValue={data?.registrationNumber}
            maxLength={50}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item name="acceptQR" className={classNames.switchRow}>
        <div>
          <span>Activate payment acceptance QR code</span>
          <span>Coming soon</span>
        </div>
        <Switch
          onChange={(e) => setAcceptQR(e)}
          disabled
          defaultChecked={acceptQR}
        />
      </Form.Item>
      <Form.Item
        name="companyTerms"
        label={<span className={classNames.label}>Invoice Terms</span>}
        rules={[{ max: 1024, message: "Max 1024 characters" }]}
      >
        <Input
          placeholder="Please enter"
          size="small"
          defaultValue={data?.terms}
          maxLength={1024}
        ></Input>
      </Form.Item>
      <Form.Item
        name="companyNotes"
        label={<span className={classNames.label}>Invoice Notes</span>}
        rules={[{ max: 256, message: "Max 256 characters" }]}
      >
        <Input
          placeholder="Please enter"
          size="small"
          defaultValue={data?.notes}
          maxLength={256}
        ></Input>
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="companyCurrency"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            marginBottom: "10px",
            float: "left",
          }}
          label={
            <span className={classNames.label}>
              Currency <sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[{ required: false, message: "Please select a currency" }]}
        >
          <Select
            defaultValue={data?.currencyIso || "USD"}
            placeholder="Please select"
            size="small"
            disabled={true}
          >
            <Select.Option value={"USD"}>USD</Select.Option>
            <Select.Option value={"GBP"}>GBP</Select.Option>
            <Select.Option value={"AED"}>AED</Select.Option>
            <Select.Option value={"UYD"}>UYD</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="companyPrefix"
          style={{
            display: "inline-block",
            width: "calc(50% - 4px)",
            margin: "0 0 0 4px",
            float: "right",
          }}
          label={<span className={classNames.label}>Prefix</span>}
          rules={[{ max: 25, message: "Max 25 characters" }]}
          extra={
            <div>
              <span style={{ fontSize: 10.5 }}>
                Your invoice no. prefix (e.g. INV-333)
              </span>
            </div>
          }
        >
          <Input
            placeholder="Please enter"
            size="small"
            defaultValue={data?.prefix}
            maxLength={25}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="companySeed"
          initialValue={data?.seed || 1}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            marginBottom: "10px",
            float: "left",
          }}
          label={
            <span className={classNames.label}>
              Seed<sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            () => ({
              validator(_, val) {
                // Seed value is not allowed to be empty according to invoicea.
                // Endpoint: Patch / invoice settings
                // As the max char length is zero,
                // following function calculates the sum of the added seed value and validates.
                // Seed value needs be an integer
                const value = val && val.toString();
                let amount;
                let valuesArray =
                  (value && value.length > 1 ? value.split("") : [value]) || [];
                if (Array.isArray(valuesArray) && valuesArray.length > 0) {
                  amount = valuesArray.reduce(
                    (a, b) => Number(a) + Number(b),
                    0
                  );
                }
                if (value.length > 6) {
                  return Promise.reject(new Error("Max 6 characters"));
                }
                if (!Number.isInteger(Number(value))) {
                  return Promise.reject(
                    new Error("Decimal numbers are not allowed")
                  );
                }
                if (!value.length || amount === 0 || value < 0) {
                  return Promise.reject(
                    new Error("Seed should be greater than zero")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <div>
              <span style={{ fontSize: 10.5, lineHeight: 1 }}>
                Your next invoice# will be increased by this amount
              </span>
            </div>
          }
        >
          <Input
            placeholder="Please enter"
            size="small"
            type="text"
            defaultValue={data?.seed}
            maxLength={6}
          />
        </Form.Item>
        <Form.Item
          name="companyStartNumber"
          initialValue={data?.startNumber || 1}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            marginBottom: "10px",
            float: "left",
            marginLeft: "10px",
          }}
          label={
            <span className={classNames.label}>
              Start Number<sup style={{ top: 1 }}>*</sup>
            </span>
          }
          rules={[
            () => ({
              validator(_, val) {
                const value = val && val.toString();
                let amount;
                let valuesArray =
                  (value && value.length > 1 ? value.split("") : [value]) || [];
                if (Array.isArray(valuesArray) && valuesArray.length > 0) {
                  amount = valuesArray.reduce(
                    (a, b) => Number(a) + Number(b),
                    0
                  );
                }
                if (!value.length) {
                  return Promise.reject(new Error("Start Number is required"));
                }
                if (value.length > 6) {
                  return Promise.reject(new Error("Max 6 characters"));
                }
                if (!Number.isInteger(Number(value))) {
                  return Promise.reject(new Error("Only interger numbers are allowed"));
                }
                if (!value.length || amount === 0 || value < 0) {
                  return Promise.reject(
                    new Error("Start Number should be greater than zero")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          extra={
            <div>
              <span style={{ fontSize: 10.5, lineHeight: 1 }}>
                Your invoice# will be started by this number
              </span>
            </div>
          }
        >
          <Input
            placeholder="Please enter"
            size="small"
            type="text"
            defaultValue={data?.startNumber}
            maxLength={6}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item name="enableOnlinePayment" className={classNames.switchRow}>
        <div>
          <span>Enable online payments</span>
          <span>Send a payment link with the invoices</span>
        </div>
        {!paStatus || !paSettingsStatus ? (
          <Popover
            content={() => {
              if (!paStatus) {
                return constants.strings.onboardStatus.warning;
              }
              if (!paSettingsStatus) {
                return constants.strings.settingsStatus.warning;
              }
            }}
          >
            <Switch disabled />
          </Popover>
        ) : (
          <Switch
            disabled={!paStatus || !paSettingsStatus}
            onChange={(e) => setEnableOnlinePayments(e)}
            defaultChecked={
              data.enableOnlinePayments
                ? data.enableOnlinePayments
                : enableOnlinePayments
            }
          />
        )}
      </Form.Item>
      <Form.Item name="enableReminders">
        <Checkbox
          onChange={(e) => {
            setReminderMessage(e.target.checked);
          }}
          defaultChecked={
            data?.enableReminders ? data?.enableReminders : reminderMessage
          }
        >
          Send reminder message
        </Checkbox>
      </Form.Item>
      {reminderMessage && (
        <div style={{ display: "flex" }}>
          <span className={classNames.label}>When invoice is</span>
          <Form.Item name="sendRemindersAfterDueDateInDays">
            <Input
              type="number"
              min={0}
              size="small"
              style={{ width: 50, margin: "1px" }}
              defaultValue={data?.sendRemindersAfterDueDateInDays}
            />
          </Form.Item>
          <span className={classNames.label}>days late and every</span>
          <Form.Item name="sendRemindersRepetitionInDays">
            <Input
              type="number"
              min={0}
              size="small"
              style={{ width: 50, margin: "1px" }}
              defaultValue={data?.sendRemindersRepetitionInDays}
            />
          </Form.Item>
          <span className={classNames.label}>days after</span>
        </div>
      )}
    </div>
  );
}
