import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Modal, CTAButton, Title, Message } from "../../../../common";
import { message } from 'antd';

import api from "../../../../API";
import { ROUTES } from "../../../../constants";
import classNames from "../AllInvoices.module.scss";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function DeleteInvoice({
  visible,
  onCancel,
  invoice,
  invoiceStatus,
  setDeleteInvoiceModalVisibility,
}) {

  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const language = useLanguage(messages);

  const invoiceDeleteMn = useMutation(() =>
    api.Receivables.Invoices.deleteInvoice(invoice.id)
  );
  
  const invoiceDelete = () => {
    if(invoiceStatus === "Paid") {
      invoiceDeleteMn.mutate(
        {},
        {
          onSuccess: () => {
            queryClient.invalidateQueries("getInvoices");
            if (
              location.pathname ===
              ROUTES.APP.RECEIVABLES.INVOICE.replace(
                ":id",
                location?.state?.number
              )
            ) {
              navigate(ROUTES.APP.RECEIVABLES.ALL_INVOICES);
            }
            setDeleteInvoiceModalVisibility(false);
            Message({
              type: "success",
              content: language.INVOICE_DELETE_SUCCESS,
            });
          },
  
          onError: (error) => generateErrorMessage(error),
        }
      );
    } else {
      message.error("Pending draft, can't be deleted yet", 2);
    }
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete Invoice
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this invoice?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={invoiceDelete}
          loading={invoiceDeleteMn.isLoading}
        >
          Yes, delete
        </CTAButton>
      </div>
    </Modal>
  );
}
