import isEmpty from "lodash/isEmpty";
import { APPLICANT_TYPE } from "../../constants";
import REGEX from "../constants/regex";
import { COMMON_WORDS } from "../constants";

const businessDetailsFormFields = [
  "name",
  "street",
  "city",
  "state",
  "postalCode",
  "country",
  "countryCode",
  "number",
  "stateOfIncorporation",
  "ein",
  "entityType",
  "businessContactFirstName",
  "businessContactLastName",
  "businessContactEmail",
  "businessContactCountryCode",
  "businessContactNumber",
];

const officerDetailsFormFields = [
  "officerCity",
  "officerCountry",
  "officerCountryCode",
  "officerDOB",
  "officerEmail",
  "officerFirstName",
  "officerIdentityNumber",
  "officerIdentityType",
  "officerNationality",
  "officerNumber",
  "officerPostalCode",
  "officerSecondName",
  "officerState",
  "officerStreet",
  "officerTitle",
  "ssnInput",
];

const beneficialDetailsFormFields = [
  "beneficialOwnerCity",
  "beneficialOwnerCountry",
  "beneficialOwnerCountryCode",
  "beneficialOwnerDateOfBirth",
  "beneficialOwnerEmail",
  "beneficialOwnerFirstName",
  "beneficialOwnerLastName",
  "beneficialOwnerNationality",
  "beneficialOwnerNumber",
  "beneficialOwnerPercentage",
  "beneficialOwnerPostalCode",
  "beneficialOwnerState",
  "beneficialOwnerStreet",
  "boIdentificationType",
  "ssnInput",
];

export const validateBusinessDetailsForm = (formData) =>
  !isEmpty(formData) &&
  businessDetailsFormFields.every((fieldName) => Boolean(formData[fieldName]));
export const validateOfficerDetailsForm = (formData) =>
  !isEmpty(formData) &&
  officerDetailsFormFields.every((fieldName) => Boolean(formData[fieldName]));
export const validateBeneficialDetailsForm = (formData) => {
  if (!isEmpty(formData)) {
    return formData.every(
      (beneficial) =>
        !isEmpty(beneficial) &&
        beneficialDetailsFormFields.every((field) => Boolean(beneficial[field]))
    );
  }

  return false;
};

export const isBusinessType = (type) => {
  if (type === APPLICANT_TYPE.BUSINESS) {
    return true;
  }
  return false;
};

export const validatePassword = (pswd, rankFun) => {
  const rank = {
    WEAK: 1,
    MEDIUM: 2,
    STRONG: 3,
  };

  const defaultOption = {
    lower: 1,
    upper: 1,
    numeric: 1,
    special: 1,
    length: [8, Infinity],
    custom: [REGEX.password],
    badWords: COMMON_WORDS,
    badSequenceLength: 3,
    noQwertySequences: false,
    noSequential: true,
  };

  const regex = {
    lower: /[a-z]/g,
    upper: /[A-Z]/g,
    numeric: /[0-9]/g,
    special: /[\W_]/g,
  };

  const { length: pswdLength } = pswd || "";

  if (
    pswdLength < defaultOption.length[0] ||
    pswdLength > defaultOption.length[1]
  ) {
    rankFun(rank.WEAK);
    return false;
  }

  const rules = Object.entries(regex).concat([
    ["customer", defaultOption.custom],
  ]);

  for (const [name, rule] of rules) {
    const matches = pswd.match(rule) || [];
    if (matches.length < defaultOption[name]) {
      rankFun(rank.WEAK);
      return false;
    }
  }

  rankFun(rank.MEDIUM);

  // Enforce word ban (case insensitive)
  if (
    defaultOption.badWords.some((word) =>
      pswd.toLowerCase().includes(word.toLowerCase())
    )
  ) {
    return false;
  }

  // Enforce the no sequential, identical characters rule
  if (defaultOption.noSequential && /^(\S)\1*$/.test(pswd)) {
    return false;
  }

  // Enforce alphanumeric/qwerty sequence ban rules
  if (defaultOption.badSequenceLength) {
    const qwerty = "qwertyuiopasdfghjklzxcvbnm",
      sequenceArray = [
        "abcdefghijklmnopqrstuvwxyz",
        "zyxwvutsrqponmlkjihgfedcba",
        "0123456789",
        "9876543210",
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "ZYXWVUTSRQPONMLKJIHGFEDCBA",
      ],
      start = defaultOption.badSequenceLength - 1;
    let seq = "_" + pswd.slice(0, start);

    for (let i = start; i < pswd.length; i++) {
      seq = seq.slice(1) + pswd.charAt(i);
      /* eslint-disable */
      if (
        sequenceArray.some((arrSeq) => arrSeq?.includes(seq)) ||
        (defaultOption.noQwertySequences && qwerty.includes(seq))
      ) {
        return false;
      }
      /* eslint-enable */
    }
  }

  rankFun(rank.STRONG);
  return true;
};
