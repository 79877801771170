import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  RiAccountBoxLine,
  RiCloseCircleLine,
  RiBankLine,
  // RiBillLine,
  RiArrowRightCircleLine,
  RiSecurePaymentLine,
  RiEqualizerLine,
  // RiArrowLeftCircleLine,
  RiStackLine,
} from "react-icons/ri";
import { BsDot } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../API";
import { useContext, useState } from "react";
import { Header, Message } from "../../common";
import CloseAccount from "./CloseAccount/CloseAccount";
// import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import { STATUS } from "../../constants";
import generateErrorMessage from "../../util/functions/customError";
import { AppContext } from "../../../context/AppState";
import { DISCLOSURES_TYPE } from "../../Disclosure/Disclosure.constant";
import classNames from "./GeneralInformation.module.scss";

function GeneralInformation() {
  // const { status: invoiceActive } = useInvoiceActiveStatus();

  const navigate = useNavigate();
  const [closeAccModalVisibility, setCloseAccModalVisibility] = useState(false);
  const [accountDetails, setAccountDetails] = useState("Active");
  const [accountOpenedOn, setAccountOpenedOn] = useState("");

  const { application } = useContext(AppContext);

  const queryClient = useQueryClient();

  const handleCloseAccModalCancelClick = () => {
    setCloseAccModalVisibility(false);
  };

  const { isLoading } = useQuery(
    "getAccountStatus",
    () => api.Settings.getAccountDetails(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        data?.data?.Items.length > 0
          ? setAccountDetails(data?.data?.Items[0].status)
          : setAccountDetails("Active");
      },
    }
  );

  const closeAccountMutation = useMutation(api.Settings.closeAccount, {
    onSuccess: (data) => {
      Message({ type: "success", content: `${data?.data?.message}` });
      queryClient.invalidateQueries("getAccountStatus");
      setCloseAccModalVisibility(false);
    },
    onError: () => generateErrorMessage(),
  });

  const deleteCloseRequestMutation = useMutation(
    api.Settings.deleteCloseAccountRequest,
    {
      onSuccess: (data) => {
        Message({ type: "success", content: `${data?.data?.message}` });
        queryClient.invalidateQueries("getAccountStatus");
      },
      onError: () => generateErrorMessage(),
    }
  );

  const { data: accountData, isLoading: isAccountLoading } = useQuery(
    "getAccountById",
    () => api.BusinessBanking.getAccount(application?.otz_account_id),
    {
      onSuccess: (data) => {
        setAccountOpenedOn(moment(data?.payload?.creation_time).format());
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: onboardRequest, isLoading: isOnboardLoading } = useQuery(
    "getOnboardRequest",
    () => api.Receivables.PaymentAcceptOnboard.getOnboardRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: currentSubscriptionPlan } = useQuery(
    "getCurrentSubscriptionPlanId",
    () => api.Settings.getCurrentSubscriptionPlan(),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="General Information" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div className={classNames.infoWrapper}>
              <div className={classNames.infoCardWrapper}>
                <div className={classNames.infoCard}>
                  <div>
                    <RiAccountBoxLine color="#82898F" size={18} />
                    <div>Account Status</div>
                  </div>
                  <div className={classNames.accountStatus}>
                    <div style={{ color: "#1DBC83" }}>
                      <BsDot size={36} style={{ verticalAlign: "middle" }} />
                      Active
                    </div>
                    {accountOpenedOn && (
                      <div className={classNames.openedOn}>
                        Opened on {accountOpenedOn}
                      </div>
                    )}
                  </div>
                </div>
                <div className={classNames.infoCard}>
                  <div>
                    <RiBankLine color="#82898F" size={18} />
                    <div>Banking</div>
                  </div>

                  {isAccountLoading ? (
                    <Spin />
                  ) : (
                    <>
                      {accountData?.payload?.access_status === "ACTIVE" ? (
                        <div style={{ color: "#1DBC83" }}>
                          <BsDot size={36} />
                          Active
                        </div>
                      ) : (
                        <div style={{ color: "#82898F" }}>Inactive</div>
                      )}
                    </>
                  )}
                </div>
                {/* <div className={classNames.infoCard}>
                  <div>
                    <RiBillLine color="#82898F" size={18} />
                    <div>Invoicing</div>
                  </div>
                  {invoiceActive &&
                  (currentSubscriptionPlan?.payload?.data?.receivable
                    ?.invoices ||
                    true) ? (
                    <div style={{ color: "#1DBC83" }}>
                      <BsDot size={36} />
                      Active
                    </div>
                  ) : (
                    <div style={{ color: "#82898F" }}>Inactive</div>
                  )}
                </div> */}
                <div className={classNames.infoCard}>
                  <div>
                    <RiArrowRightCircleLine color="#82898F" size={18} />
                    <div>Acceptance</div>
                  </div>
                  {isOnboardLoading ? (
                    <Spin />
                  ) : (
                    <>
                      {onboardRequest?.data?.onboardStatus ===
                        STATUS.APPROVED &&
                      currentSubscriptionPlan?.payload?.data?.receivable
                        ?.payment_acceptance ? (
                        <div style={{ color: "#1DBC83" }}>
                          <BsDot size={36} />
                          Active
                        </div>
                      ) : (
                        <div style={{ color: "#82898F" }}>Inactive</div>
                      )}
                    </>
                  )}
                </div>
                <div className={classNames.infoCard}>
                  <div>
                    <RiSecurePaymentLine color="#82898F" size={18} />
                    <div>Accounts Payable</div>
                  </div>
                  <div className={classNames.comingSoon}>Coming Soon</div>
                </div>
                <div className={classNames.infoCard}>
                  <div>
                    <RiEqualizerLine color="#82898F" size={18} />
                    <div>Expense Management</div>
                  </div>
                  <div className={classNames.comingSoon}>Coming Soon</div>
                </div>
                {/* <div className={classNames.infoCard}>
                  <div>
                    <RiArrowLeftCircleLine color="#82898F" size={18} />
                    <div>Cashback Rate</div>
                  </div>
                  <div style={{ color: "#3B3F43" }}>
                    {currentSubscriptionPlan?.payload?.data
                      ?.rewards_percentage || "0"}
                    %
                  </div>
                </div> */}
                <div className={classNames.infoCard}>
                  <div>
                    <RiStackLine color="#82898F" size={18} />
                    <div>Plan</div>
                  </div>
                  <div className={classNames.changePlan}>
                    <div>
                      {currentSubscriptionPlan?.payload?.plan_type === "paid"
                        ? "Pro"
                        : "Free"}
                    </div>
                    {/* <div
                      onClick={() => {
                        navigate(ROUTES.APP.SETTINGS.PLAN_PREFERENCES);
                      }}
                    >
                      Change Plan
                    </div> */}
                  </div>
                </div>
                {isLoading ? (
                  <Spin />
                ) : accountDetails === "Active" ? (
                  <div
                    className={classNames.close}
                    onClick={() => setCloseAccModalVisibility(true)}
                  >
                    <RiCloseCircleLine size={18} style={{ marginRight: 12 }} />{" "}
                    Close Account
                  </div>
                ) : (
                  <div className={classNames.closeRequested}>
                    <div>
                      <RiCloseCircleLine
                        size={18}
                        style={{ marginRight: 12 }}
                      />{" "}
                      <div>
                        <div>Close Account requested</div>
                        <div>
                          Our representative will reach out to you shortly with
                          account closure procedure
                        </div>
                      </div>
                    </div>
                    <div onClick={() => deleteCloseRequestMutation.mutate()}>
                      Cancel close request
                    </div>
                  </div>
                )}
              </div>
              <div className={classNames.infoFooter}>
                <span
                  onClick={() => {
                    // window.open(
                    //   `${
                    //     new URL(window.location.href).origin
                    //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                    //     ":param",
                    //     DISCLOSURES_TYPE[0].param
                    //   )}`
                    // );
                    window.open(`${DISCLOSURES_TYPE[0].link}`);
                  }}
                >
                  {DISCLOSURES_TYPE[0].name}
                </span>
                <span
                  onClick={() => {
                    // window.open(
                    //   `${
                    //     new URL(window.location.href).origin
                    //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                    //     ":param",
                    //     DISCLOSURES_TYPE[2].param
                    //   )}`
                    // );
                    window.open(`${DISCLOSURES_TYPE[2].link}`);
                  }}
                >
                  {DISCLOSURES_TYPE[2].name}{" "}
                </span>
                {/* <span
                  onClick={() => {
                    window.open("https://www.otterz.co/terms-of-service");
                  }}
                >
                  Banking Terms & Conditions
                </span>
                <span
                  onClick={() => {
                    window.open("https://www.otterz.co/terms-of-service");
                  }}
                >
                  Merchant Acceptance Terms & Conditions
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CloseAccount
        visible={closeAccModalVisibility}
        onCancel={handleCloseAccModalCancelClick}
        closeAccountMutation={closeAccountMutation}
      />
    </div>
  );
}

export default GeneralInformation;
