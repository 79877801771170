import classNames from "../Customers.module.scss";
import { CTAButton, Title, Message } from "../../../../common";
import api from "../../../../API";
import { useMutation, useQueryClient } from "react-query";
import { Modal } from "antd";

import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";

export default function DeleteCustomer({
  visible,
  onCancel,
  data,
  setRefetch,
  setDeleteCustomerCardModalVisibility,
}) {
  const queryClient = useQueryClient();
  const language = useLanguage(messages);

  function deleteTaxRateFn(data) {
    return api.Receivables.Customers.deleteCustomer(data.id);
  }

  const deleteTaxRateMn = useMutation((event) => deleteTaxRateFn(event));

  const onDeleteClick = () => {
    // setRefetch(false)
    deleteTaxRateMn.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries("getCustomers");
        Message({ type: "success", content: language.CUSTOMER_DELETE_SUCCESS });
        // setRefetch(true);
        setDeleteCustomerCardModalVisibility(false);
      },
      onError: (error) => {
        const clientId = error?.response?.data?.error?.clientID;
        const customMessage =
          Array.isArray(clientId) && clientId?.length && clientId[0];
        generateErrorMessage(error, customMessage);
        setDeleteCustomerCardModalVisibility(false);
      },
    });
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
      footer={null}
    >
      <Title as="h3" style={{ marginBottom: 8 }}>
        Delete Customer
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to delete this customer?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={onCancel}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
          }}
          onClick={onDeleteClick}
          loading={deleteTaxRateMn.isLoading}
        >
          Yes, delete
        </CTAButton>
      </div>
    </Modal>
  );
}
