import ROLES from "./scopesToRoles/roles";
import ROUTES from "./routes";

export { default as FORMATS } from "./formats";
export { default as STATUS } from "./status";
export { ROUTES };

export const STEPS = Object.freeze({
  ONE: 1,
  TWO: 2,
});

export const APPLICATION_STATUS = Object.freeze({
  CREATED: "created",
  AWAITING_DOCUMENTS: "awaitingdocuments",
  PENDING_REVIEW: "PENDING",
  APPROVED: "ACCEPTED",
  CANCELED: "REJECTED",
  DENIED: "denied",
  UNVERIFIFED: "UNVERIFIED",
  PROVISIONAL: "PROVISIONAL",
  REVIEW: "REVIEW",
});

export const USER_STATES = Object.freeze({
  NOT_REGISTERED: "not_registered",
  ONBOARDING: "onboarding",
  EMAIL_VERIFIED: "email_verified",
  PHONE_VERIFIED: "phone_verified",

  INDIVIDUAL_ACCOUNT_TYPE_SELECTED: "individual_account_type_selected",
  INDIVIDUAL_AWAITING_DOCUMENTS: "individual_awaiting_document",

  BUSINESS_ACCOUNT_TYPE_SELECTED: "business_account_type_selected",
  BUSINESS_DETAILS_VALID: "business_details_valid",
  BUSINESS_OFFICER_DETAILS_VALID: "business_officer_details_valid",
  BUSINESS_BENEFICIAL_DETAILS_VALID: "business_beneficial_details_valid",
  // BUSINESS_AWAITING_DOCUMENTS: "business_awaiting_document",

  PENDING_REVIEW: "pending_review",
  // APPLICATION_AWAITING_DOCUMENT: "application_awaiting_document",
  // APPLICAITON_DENIED: "application_denied",
  APPLICATION_CANCELED: "application_canceled",
  // APPLICATION_DOCUMENT_REJECTED: "application_document_rejected",
  // APPLICATION_DOCUMENT_SUBMIT_REQUIRED: "application_document_submit_required",

  AUTHORIZED_USER: "authorized_user",
});

export const ONBOARDING_USER_STATES = Object.freeze([
  USER_STATES.PHONE_VERIFIED,
  USER_STATES.EMAIL_VERIFIED,

  USER_STATES.INDIVIDUAL_ACCOUNT_TYPE_SELECTED,
  USER_STATES.INDIVIDUAL_AWAITING_DOCUMENTS,
  USER_STATES.BUSINESS_ACCOUNT_TYPE_SELECTED,
  USER_STATES.BUSINESS_DETAILS_VALID,
  USER_STATES.BUSINESS_OFFICER_DETAILS_VALID,
  USER_STATES.BUSINESS_BENEFICIAL_DETAILS_VALID,
  // USER_STATES.BUSINESS_AWAITING_DOCUMENTS,
  USER_STATES.PENDING_REVIEW,
  // USER_STATES.APPLICATION_AWAITING_DOCUMENT,
  // USER_STATES.APPLICAITON_DENIED,
  USER_STATES.APPLICATION_CANCELED,
]);

export const ACCOUNT_TYPE = Object.freeze({
  INDIVIDUAL: "PERSON",
  BUSINESS: "BUSINESS",
});

export const APPLICATION_TYPE = Object.freeze({
  INDIVIDUAL_APPLICATION: "individualApplication",
  BUSINESS_APPLICATION: "businessApplication",
});

export const ROLE_SPECIFIC_LANDING_PAGE = Object.freeze({
  [ROLES.OWNER]: ROUTES.APP.INDEX,
  [ROLES.ACCOUNTING_USER]: ROUTES.APP.INDEX,
  [ROLES.INVOICING_USER]: ROUTES.APP.RECEIVABLES.INVOICES,
  // TODO: since the expense management is post MVP I added APP.INDEX as a temp value.
  [ROLES.EXPENSE_USER]: ROUTES.APP.INDEX,
});

export const DOCUMENT_STATUS = Object.freeze({
  REQUIRED: "Required",
  INVALID: "Invalid",
  APPROVED: "Approved",
  PENDING_REVIEW: "PendingReview",
  RECEIVED_BACK: "ReceivedBack",
  RECEIVED_FRONT: "ReceivedFront",
  REJECTED: "rejected",
});

export const ALERT_TYPE = Object.freeze({
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
});

export const DOCUMENT_STATUS_DESCRIPTION = Object.freeze({
  [DOCUMENT_STATUS.REQUIRED.toLowerCase()]: {
    description:
      "The document is required for the application to be evaluated.",
    type: ALERT_TYPE.INFO,
    message: "Document Required",
  },
  [DOCUMENT_STATUS.INVALID.toLowerCase()]: {
    description:
      "The document is invalid. You may re-upload the correct document for the application to be evaluated.",
    type: ALERT_TYPE.ERROR,
    message: "Document Invalid",
  },
  [DOCUMENT_STATUS.APPROVED.toLowerCase()]: {
    description: "The document is approved.",
    type: ALERT_TYPE.SUCCESS,
    message: "Document Approved",
  },
  [DOCUMENT_STATUS.PENDING_REVIEW.toLowerCase()]: {
    description: "The document is currently undergoing review.",
    type: ALERT_TYPE.INFO,
    message: "Document Pending Review",
  },
  [DOCUMENT_STATUS.RECEIVED_BACK.toLowerCase()]: {
    description:
      "Back-side of the document was received. Front-side is still required.",
    type: ALERT_TYPE.WARNING,
    message: "Document Front Side Required",
  },
  [DOCUMENT_STATUS.RECEIVED_FRONT.toLowerCase()]: {
    description:
      "Front-side of the document was received. Back-side is still required.",
    type: ALERT_TYPE.WARNING,
    message: "Document Back Side Required",
  },
  [DOCUMENT_STATUS.REJECTED.toLocaleLowerCase()]: {
    description: "The document is rejected, please re-submit document.",
    type: ALERT_TYPE.ERROR,
    message: "Document Rejected",
  },
});

export const ROUTE_STATUS = {
  [USER_STATES.PENDING_REVIEW]: "/thank-you",
  // [USER_STATES.APPLICATION_AWAITING_DOCUMENT]: "/awaiting-document",
  [USER_STATES.APPLICATION_CANCELED]: "/application-denied",
};

export const APPLICAITON_STATUS_MESSAGES = {
  DESCRIPTION:
    "We will send you an email once your account is verified and ready to use.",
  CLICK_HERE: "Click here",
  CONTACT_US: " contact us",
  PREVIEW_MESSAGE: "We have received your request",
  AWAITING_TITLE_START: "Awaiting Documents ",
  AWAITING_TITLE_END: " to submit",
  DENIED_TITLE_START: "We’re Sorry, your application has been rejected",
  DENIED_TITLE_END: " for further details",
};

export const APPLICANT_TYPE = {
  PERSON: "PERSON",
  BUSINESS: "BUSINESS",
};

export const DISCLOSURE_TYPE = {
  ACH_AUTHORIZATION: "ACH_AUTHORIZATION",
  CARDHOLDER_AGREEMENT: "CARDHOLDER_AGREEMENT",
  E_SIGN: "E_SIGN",
  KYC_DATA_COLLECTION: "KYC_DATA_COLLECTION",
  PRIVACY_NOTICE: "PRIVACY_NOTICE",
  REG_CC: "REG_CC",
  REG_DD: "REG_DD",
  REG_E: "REG_E",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  OWNER_CERTIFICATION: "OWNER_CERTIFICATION",
};

export const PAGINATION_LIMIT = 10;

export const PAGE_SIZE = 50;

export const DEFAULT_AMOUNT = "$ 0.00";

export const FILE_SIZE = { B: 20000000, MB: "20MB" };
