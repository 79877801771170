import { useState, useMemo } from "react";
import { Form, Upload, Button, Alert } from "antd";

import { Title } from "../../../common";

import classNames from "../Signup.module.scss";

import handleUploadFunc from "./handleUploadFunc";
import checkFileSize from "./checkFileSize";
import handleRemove from "./removeFile";
import { DOCUMENT_STATUS, DOCUMENT_STATUS_DESCRIPTION } from '../../../constants';

const AddressVerificationUpload = ({
  applicationId,
  documentId,
  applicationDBId,
  tenantId,
  setUploadTrackingDocs,
  dispatcAcRef,
  ownerName,
  docStatus
}) => {
  const [showUpload, setShowUpload] = useState(true);
  const handleChange = (data) => {
    if (data?.fileList.length > 0) {
      setShowUpload(false);
    } else if (data?.fileList.length === 0) {
      setShowUpload(true);
    }
  };

  const isEnbleUpload = useMemo(
    () => ![DOCUMENT_STATUS.PENDING_REVIEW.toLowerCase(), DOCUMENT_STATUS.APPROVED.toLowerCase()].includes(docStatus.toLowerCase()),
    [docStatus]
  );

  const isShowStatusLable = useMemo(
    () => docStatus.toLowerCase() !== DOCUMENT_STATUS.REQUIRED.toLowerCase(),
    [docStatus]
  );

  return (
    <Form.Item
      label={
        <Title as="h4" className={classNames.label}>
          Address confirmation <sup style={{ top: 1 }}>*</sup>
          <div>
            Upload a utility bill, bank statement or anything that can confirm
            {" "} <b>{ownerName}</b>'s address
          </div>
        </Title>
      }
    >
      {isShowStatusLable &&
        <Alert
          message={DOCUMENT_STATUS_DESCRIPTION[docStatus.toLowerCase()].message}
          description={DOCUMENT_STATUS_DESCRIPTION[docStatus.toLowerCase()].description}
          type={DOCUMENT_STATUS_DESCRIPTION[docStatus.toLowerCase()].type}
          className={classNames.alretBox}
        />
      }
      {isEnbleUpload &&
        <Upload.Dragger
          customRequest={({ onSuccess, onError, file }) =>
            handleUploadFunc(
              onSuccess,
              onError,
              file,
              applicationId,
              documentId,
              applicationDBId,
              tenantId,
              setUploadTrackingDocs,
              dispatcAcRef
            )
          }
          listType="picture"
          accept="application/pdf"
          beforeUpload={checkFileSize}
          maxCount={1}
          onChange={handleChange}
          onRemove={() => handleRemove(setUploadTrackingDocs, documentId)}
          style={{ display: `${showUpload ? "block" : "none"}` }}
        >
          <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
            <i
              className="ri-drag-drop-line"
              style={{ fontSize: "24px", color: "#9BA3AB" }}
            ></i>
          </p>
          <p className="ant-upload-text" style={{ fontSize: "12px" }}>
            Drag & Drop or
            <Button type="link" size="small" style={{ fontSize: "12px" }}>
              click here to upload
            </Button>
          </p>
        </Upload.Dragger>
      }
    </Form.Item>
  );
};

export default AddressVerificationUpload;
