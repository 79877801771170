import { useContext } from "react";
import { StoreContext } from "./StoreProvider";

/**
 * Hook for accessing either the entire global state or part of it.
 *
 * @param {*} selector Global state will be passed into the 'selector'. Should return the sub state you wish to use.
 * @returns Application state
 */
const useStoreContext = (selector) => {
  const state = useContext(StoreContext);
  return selector ? selector(state) : state;
};

export default useStoreContext;
