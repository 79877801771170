import { Modal } from "../../../../common";
import NewPaymentForm from "./NewPaymentForm";

export default function NewPayment({
  visible,
  onCancel,
  payees,
  accountBalance,
  queryClient,
  refetch,
}) {
  return (
    <Modal
      closable={true}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      title="New Recurring Payment"
      bodyStyle={{
        padding: 0,
        height: "70vh",
        display: "flex",
        justifyContent: "space-between",
      }}
      width={730}
    >
      <NewPaymentForm
        onCancel={onCancel}
        payees={payees}
        accountBalance={accountBalance}
        queryClient={queryClient}
        refetch={refetch}
      />
    </Modal>
  );
}
