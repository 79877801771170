import Search2LineIcon from "remixicon-react/Search2LineIcon";
import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";
import moment from "moment";
import { useState, useEffect, useContext } from "react";
import { Table, Space, Empty, Button as AntButton, Spin, Card } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Input, Header, TypeTag, Title } from "../../../common";
import DeletePayment from "./DeletePayment";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import {
  centsToDollar,
  centsToDollarWithoutSign,
} from "../../../util/functions/dollarConvertion";
import { isBusinessType } from "../../../util/functions/onboarding";
import generateErrorMessage from "../../../util/functions/customError";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import { FORMATS } from "../../../constants";
import classNames from "./ScheduledPayments.module.scss";

export default function ScheduledPayments() {
  //Hooks
  // const [freezeModalVisible, setFreezeModalVisible] = useState(false);
  // const [unfreezeModalVisible, setUnfreezeModalVisible] = useState(false);
  // const [editPaymentModalVisible, setEditPaymentModalVisible] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [searchResults, setSearchResults] = useState();
  // const [accountBalance, setAccountBalance] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [sanitizedData, setSanitizedData] = useState();
  const { application } = useContext(AppContext);
  const applicationData = application;
  const navigate = useNavigate();
  const [upcomingPayments, setUpcomingPayments] = useState(0);
  const [recurringPayments, setRecurringPayments] = useState(0);
  const [pagination, setPagination] = useState(PAGINATION);

  //Other variables
  const today = moment().format(FORMATS.DATEPICKER_FORMAT);
  const oneMonthFromNow = moment()
    .add(1, "month")
    .add(1, "day")
    .format(FORMATS.DATEPICKER_FORMAT);

  //Api calls
  const getScheduledPayments = useMutation(
    "getSchedulePayment",
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getRecurringPayments = useMutation(
    "getSchedulePayment",
    (reqBody) => api.BusinessBanking.getScheduledPayment(reqBody),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // useQuery(
  //   "getStatementDetail",
  //   () =>
  //     api.BusinessBanking.statementDetail({
  //       otz_account_id: accountDetail?.id,
  //     }),
  //   {
  //     onSuccess: (data) => {
  //       setAccountBalance(data?.payload?.total_available_balance);
  //     },
  //     retry: false,
  //   }
  // );

  useEffect(() => {
    handleSchedulePaymentList();
    handleRecurringPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination(PAGINATION);

    let sortData =
      !getScheduledPayments.isLoading &&
      Boolean(getScheduledPayments.data) &&
      paymentData?.message !== "No payment found" &&
      paymentData?.length > 0 &&
      paymentData
        ?.sort((a, b) =>
          moment(a.paymentDate, FORMATS.DATEPICKER_FORMAT).diff(
            moment(b.paymentDate, FORMATS.DATEPICKER_FORMAT)
          )
        )
        ?.reverse();
    setSanitizedData(sortData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  //Event handlers
  const searchItems = (searchValue) => {
    setSearchTerm(searchValue);
    searchRecords(searchValue, paymentData);
  };

  const handleSchedulePaymentList = () => {
    const reqBody = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: applicationData?.otz_business_id }
        : {
            otz_person_id: applicationData?.otz_person_id,
          }),
      is_recurring: 0,
    };

    getScheduledPayments.mutate(reqBody, {
      onSuccess: (response) => {
        const filteredData = response?.payload
          ?.map((item) => {
            // if (isPaymentOnPast(item?.next_payment_date?.scheduled_date)) {
            //   return false;
            // }

            return {
              id: item?.id,
              payeeName: item?.payee_name,
              paymentDate: moment(
                item?.schedule?.start_date,
                FORMATS.API_SEND_FORMAT
              ).format(FORMATS.DATEPICKER_FORMAT),
              amount: centsToDollar(item?.payment_instruction?.request?.amount),
              paymentType: item?.payment_instruction?.type,
              status: item?.status,
            };
          })
          .sort((a, b) =>
            moment(a?.paymentDate, FORMATS.DATEPICKER_FORMAT).diff(
              moment(b?.paymentDate, FORMATS.DATEPICKER_FORMAT)
            )
          )
          ?.reverse();

        const amount = response?.payload
          ?.filter((item) => {
            let date = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            return (
              moment(date).isBefore(moment(oneMonthFromNow)) &&
              moment(date)?.isAfter(moment(today)) &&
              item?.status === "ACTIVE"
            );
          })
          .map((item) => {
            return item?.payment_instruction?.request?.amount;
          })
          .reduce((value1, value2) => {
            return value1 + value2;
          }, 0);

        setUpcomingPayments(amount);

        setPaymentData(filteredData);

        if (searchTerm) {
          searchRecords(searchTerm, filteredData);
        }
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  const handleRecurringPaymentList = () => {
    const reqBody = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: applicationData?.otz_business_id }
        : {
            otz_person_id: applicationData?.otz_person_id,
          }),
      is_recurring: 1,
    };

    getRecurringPayments.mutate(reqBody, {
      onSuccess: (response) => {
        const amount = response?.payload
          ?.filter((item) => {
            const date = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );

            if (
              moment(date).isBefore(moment(oneMonthFromNow)) &&
              item?.status === "ACTIVE"
            ) {
              return true;
            }
            return false;
          })
          .map((item) => {
            const startDate = moment(item?.schedule?.start_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            const endDate = moment(item?.schedule?.end_date).format(
              FORMATS.DATEPICKER_FORMAT
            );
            if (item?.schedule?.frequency === "DAILY") {
              let day;
              if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                day = moment
                  .duration(moment(endDate).diff(moment(today)))
                  .asDays();
              } else if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isAfter(moment(today))
              ) {
                day =
                  moment
                    .duration(moment(endDate).diff(moment(startDate)))
                    .asDays() + 1;
              } else if (
                moment(endDate).isAfter(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                day = moment
                  .duration(moment(oneMonthFromNow).diff(moment(today)))
                  .asDays() - 1;
              } else {
                day = moment
                  .duration(moment(oneMonthFromNow).diff(moment(startDate)))
                  .asDays();
              }
              return day * item?.payment_instruction?.request?.amount;
            } else if (item?.schedule?.frequency === "WEEKLY") {
              let currentStartDate = startDate;
              let count = 0;

              if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                while (
                  moment(currentStartDate).isSameOrBefore(moment(endDate))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else if (
                moment(endDate).isBefore(moment(oneMonthFromNow)) &&
                moment(startDate).isAfter(moment(today))
              ) {
                while (
                  moment(currentStartDate).isSameOrBefore(moment(endDate))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else if (
                moment(endDate).isAfter(moment(oneMonthFromNow)) &&
                moment(startDate).isSameOrBefore(moment(today))
              ) {
                while (
                  moment(currentStartDate).isBefore(moment(oneMonthFromNow))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              } else {
                while (
                  moment(currentStartDate).isBefore(moment(oneMonthFromNow))
                ) {
                  if (moment(currentStartDate).isAfter(moment(today))) {
                    count++;
                  }
                  currentStartDate = moment(currentStartDate).add(7, "day");
                }
              }
              return count * item?.payment_instruction?.request?.amount;
            } else {
              const date = moment(
                item?.next_payment_date?.execution_date
              ).format(FORMATS.DATEPICKER_FORMAT);
              if (
                (moment(date).isSame(moment(today)) &&
                  moment(endDate).isSameOrAfter(oneMonthFromNow)) ||
                (moment(date).isAfter(moment(today)) &&
                  moment(date).isBefore(moment(oneMonthFromNow)))
              ) {
                return item?.payment_instruction?.request?.amount;
              }
              return 0;
            }
          })
          .reduce((value1, value2) => {
            return value1 + value2;
          }, 0);

        setRecurringPayments(amount);
      },
      onError: (error) => generateErrorMessage(error),
    });
  };

  const searchRecords = (searchTerm, paymentData) => {
    setPagination(PAGINATION);
    if (!searchTerm) {
      setSearchResults(null);
    } else {
      const filtered =
        paymentData &&
        paymentData
          .filter((payee) => {
            const filterObj = {
              payeeName: payee?.payeeName,
              paymentDate: payee?.paymentDate,
              amount: payee?.amount,
              paymentType: payee?.paymentType,
              status: payee?.status,
              scheduleType: "Pay Later",
            };
            return Object.values(filterObj)
              ?.toString()
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase());
          })
          .sort((a, b) =>
            moment(a.paymentDate, FORMATS.DATEPICKER_FORMAT).diff(
              moment(b.paymentDate, FORMATS.DATEPICKER_FORMAT)
            )
          );
      setSearchResults(filtered);
    }
  };

  // const updateRecordState = (record, type) => {
  //   const newUpdatedState = searchResults?.map((obj) => {
  //     if (obj?.id === record.id && type?.type === "freeze" && !!searchResults) {
  //       return { ...obj, freezed: "true" };
  //     } else if (
  //       obj.id === record.id &&
  //       type.type === "unfreeze" &&
  //       !!searchResults
  //     ) {
  //       return { ...obj, freezed: "false" };
  //     }
  //     return obj;
  //   });
  //   setSearchResults(newUpdatedState);
  // };

  // This will be enabled after MVP
  // const rowSelection = {
  //   onChange: (index, selectedRows) => {
  //     setSelectedPayments(selectedRows);
  //     setRowsSelected(index);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  const hideDeleteModal = () => {
    setDeleteModalVisibility(false);
  };

  // const onTableRowClick = (record) => () => {
  //   setSelectedPayments(record);
  //   setEditPaymentModalVisible(true);
  // };

  //JSX
  const columns = [
    {
      title: "Payee Name",
      dataIndex: "payeeName",
      key: "payeeName",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (type) => type,
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return moment(a.paymentDate, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b.paymentDate, FORMATS.DATEPICKER_FORMAT)
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <span>{amount}</span>;
      },
    },
    {
      title: "Schedule Type",
      dataIndex: "scheduleType",
      key: "scheduleType",
      render: () => {
        return <span>Pay Later</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        switch (status) {
          case "CANCELLED":
            return "Cancelled";
          case "ACTIVE":
            return "Active";
          case "EXPIRED":
            return "Expired";
          default:
            return "N/A";
        }
      },
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return a.status.length - b.status.length;
      },
    },
    {
      title: "Transfer Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (type) => {
        return (
          <Space
            size="middle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TypeTag type={type} />
          </Space>
        );
      },
      sorter: (a, b) => {
        setPagination(PAGINATION);
        return a.paymentType.length - b.paymentType.length;
      },
    },
    {
      dataIndex: "actions",
      key: "actions",
      width: "25%",
      render: (type, record) => {
        return (
          record?.status === "ACTIVE" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* {record.freezed === "false" ? (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setFreezeModalVisible(true);
                    setSelectedPayments({ item: "single", record });
                    // updateRecordState(record, { type: "freeze" });
                  }}
                  style={{
                    marginRight: 25,
                  }}
                  type="Freeze"
                />
              ) : (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setUnfreezeModalVisible(true);
                    setSelectedPayments({ item: "single", record });
                    // updateRecordState(record, { type: "unfreeze" });
                  }}
                  style={{
                    marginRight: 25,
                  }}
                  type="Unfreeze"
                />
              )} */}
              {/* 
              <EditLineIcon
                size={14}
                color="#9BA3AB"
                className={classNames.actionIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditPaymentModalVisible(true);
                  setSelectedPayments(record);
                }}
              /> */}

              <DeleteBin7LineIcon
                size={14}
                color="#9BA3AB"
                className={classNames.actionIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteModalVisibility(true);
                  setSelectedPayments(record);
                }}
              />
            </div>
          )
        );
      },
    },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Scheduled Payments" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <Input
              placeholder="Search schedule payment"
              prefix={<Search2LineIcon />}
              className={classNames.searchPayment}
              onChange={(e) => searchItems(e.target.value)}
            />
          </div>
          <div
            className={classNames.body}
            style={{
              marginTop: 40,
            }}
          >
            <div
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                width: "410px",
                margin: "26px 0",
                display: "none",
              }}
            >
              {/* Pagination Here */}
            </div>
            <Card className={classNames.balanceCard}>
              {getScheduledPayments.isLoading ||
              getRecurringPayments.isLoading ? (
                <Spin />
              ) : (
                <>
                  <div className={classNames.totalBalance}>
                    <Title as="h4">
                      Upcoming Payments
                      <span style={{ color: "#EE6969" }}>* </span> (next 30
                      days)
                    </Title>
                    <div className={classNames.amount}>
                      <sup>$</sup>
                      {centsToDollarWithoutSign(
                        upcomingPayments + recurringPayments
                      ) || "0.00"}
                    </div>
                  </div>
                  <span>
                    <span style={{ color: "#EE6969" }}>*</span>Amount includes
                    both - one time pay later payments and recurring payments.
                  </span>
                </>
              )}
            </Card>

            <Table
              loading={
                getScheduledPayments.isLoading ||
                getScheduledPayments.isFetching
              }
              size="large"
              dataSource={
                Boolean(sanitizedData) &&
                sanitizedData.length > 0 &&
                !searchResults
                  ? sanitizedData
                  : searchResults
              }
              columns={columns}
              className={classNames.table}
              // onRow={(record) => ({
              //   onClick: onTableRowClick(record),
              //   className: classNames.tableRow,
              // })}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },

                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  } else if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <DeletePayment
        payments={selectedPayments}
        visible={deleteModalVisibility}
        onCancel={hideDeleteModal}
        refetch={() => handleSchedulePaymentList()}
      />
      {/* <FreezePayment
        visible={freezeModalVisible}
        payments={selectedPayments}
        onCancel={() => setFreezeModalVisible(false)}
        refetch={handleSchedulePaymentList}
        freeze={updateRecordState}
      />
      <UnfreezePayment
        visible={unfreezeModalVisible}
        payments={selectedPayments}
        onCancel={() => setUnfreezeModalVisible(false)}
        accountBalance={accountBalance}
        refetch={handleSchedulePaymentList}
        unfreeze={updateRecordState}
      /> */}
      {/* <EditPayment
        payments={selectedPayments}
        visible={editPaymentModalVisible}
        onCancel={() => setEditPaymentModalVisible(false)}
        accountBalance={accountBalance}
        refetch={handleSchedulePaymentList}
      /> */}
    </div>
  );
}
