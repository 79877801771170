import { useContext } from "react";
import classNames from "../ManageCards.module.scss";
import { Modal, CTAButton, Title, Message } from "../../../common";
import api from "../../../API";
import { useMutation, useQueryClient } from "react-query";
import generateErrorMessage from "../../../util/functions/customError";
import { AppContext } from "../../../../context/AppState";

const Body = ({ currentCard, onCancel }) => {
  const queryClient = useQueryClient();

  const { application } = useContext(AppContext);

  function cardReportStolenFn(event) {
    return api.BusinessBanking.createNewCard({
      form: currentCard?.form,
      otz_account_id: application?.otz_account_id,
      otz_customer_id:
        application?.type === "PERSON"
          ? application?.otz_person_id
          : currentCard?.otz_customer_id,
      reissue_reason: "STOLEN",
      otz_reissued_from_id: currentCard?.otz_card_id,
      ...currentCard.shipping.address,
      emboss_name: { ...currentCard?.emboss_name },
      recipient_first_name: currentCard?.shipping?.recipient_name?.first_name,
      recipient_last_name: currentCard?.shipping?.recipient_name?.last_name,
    });
  }

  const cardReportStolenMutation = useMutation((event) =>
    cardReportStolenFn(event)
  );

  function reportStolenOnClick(data) {
    cardReportStolenMutation.mutate(data, {
      onSuccess: (data, variables, context) => {
        onCancel();
        queryClient.invalidateQueries("listCards");
        Message({
          type: "success",
          content: "Reported successfully!",
        });
      },
      onError: (error) => generateErrorMessage(error),
    });
  }

  return (
    <>
      <Title as="h3" style={{ marginBottom: 8 }}>
        Report Card Stolen?
      </Title>
      <div style={{ marginBottom: 8 }}>
        Are you sure you want to report this card as stolen?
      </div>
      <div className={classNames.confirmModalCTA}>
        <CTAButton
          type="secondary"
          style={{
            height: 44,
            width: "47%",
            color: "#9BA3AB",
            border: "1px solid #9BA3AB",
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </CTAButton>
        <CTAButton
          type="primary"
          style={{
            height: 44,
            width: "47%",
            backgroundColor: "#EE6969",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          loading={cardReportStolenMutation.isLoading}
          disabled={cardReportStolenMutation.isLoading}
          onClick={() => reportStolenOnClick(currentCard?.otz_card_id)}
        >
          Yes
        </CTAButton>
      </div>
    </>
  );
};

export default function ReportStolen({
  visible,
  onCancel,
  currentCard,
  cardsRefetch,
}) {
  return (
    <Modal
      closable={false}
      visible={visible}
      onCloseClick={onCancel}
      onCancel={onCancel}
      maskClosable={false}
      className={classNames.confirmModal}
      bodyStyle={{
        padding: "24px 39px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      width={350}
    >
      <Body
        currentCard={currentCard}
        onCancel={onCancel}
        cardsRefetch={cardsRefetch}
      />
    </Modal>
  );
}
