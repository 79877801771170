import { API } from "aws-amplify";

export default async function getCheckDepositById(id) {
  if (id) {
    return await API.get("checkDeposits", "get", {
      queryStringParameters: {
        id: id,
      },
    });
  }
}
