import { useEffect, useReducer, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Button, Input as AntInput, Tooltip } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { CTAButton, Input, Message } from "../../common";
import Heading from "../Heading";
import signupReducer from "../stateManagement/signupReducer";
import { types } from "../stateManagement/types";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { removeValuesFromLocalStorage } from "../util";
import REGEX from "../../util/constants/regex";
import { MAX_MIN } from "../../util/constants";
import lowerCase from "../../util/functions/lowerCase";
import { validatePassword } from "../../util/functions/onboarding";
import { ROUTES } from "../../constants";
import { DISCLOSURES_TYPE } from "../../Disclosure/Disclosure.constant";
import { validation } from "../config";
import classNames from "./Signup.module.scss";
import mixpanel from "mixpanel-browser";

export default function Signup() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [, setVerifyEmail] = useLocalStorage("OTTERZ.verifyEmail");
  const [rank, setRank] = useState(0);
  const [matcher, setMatcher] = useState(0);

  const [queryParams] = useSearchParams()
  const [{ status, error }, dispatch] = useReducer(signupReducer, {
    status: "idle",
  });

  const initialEmailAddress = queryParams.get("CTAemail")

  useEffect(() => {
    // remove localstrage cache
    removeValuesFromLocalStorage(true, true);
  }, []);

  useEffect(() => {
    if (status === "success") {
      navigate(ROUTES.VERIFICATION.VERIFY_EMAIL);
    }
    if (error) {
      Message({ type: "error", content: error.message });
    }
  }, [status, error, navigate]);

  async function handleOnFinish({ email, password, mobile, countryCode }) {
    email = lowerCase(email);

    try {
      mixpanel.identify(email);

      // Set user properties
      mixpanel.people.set({
        $email: email,
        "Sign Up": "Sign Up started",
      });

      // Track the signup event
      mixpanel.track("Sign up started", {
        "Email ID": email,
        "Sign Up Date": new Date().toISOString(),
        "Phone number": `+${countryCode + mobile}`
        // "Phone number": `+1 506 701 5696`,
      });

      dispatch({ type: types.PERFORMING_SIGNUP });
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: `+${countryCode + mobile}`,
          // phone_number: `+15067015696`,
        },
      });

      setVerifyEmail(email);
      dispatch({ type: types.VERIFICATION_SENT, payload: user });
    } catch (error) {
      dispatch({ type: types.OPERATION_FAILED, error });
      mixpanel.track("Sign up failed", {
        "Email ID": email,
        "Sign Up Date": new Date().toISOString(),
        Reason: error?.name,
      });
    }
  }

  //Helper
  const rankFunction = () => {
    if (rank === 1) {
      return {
        color: ["#ff0000", "#e8e8e8", "#e8e8e8"],
        value: "Password is weak",
      };
    } else if (rank === 2) {
      return {
        color: ["#ffa500", "#ffa500", "#e8e8e8"],
        value: "Password is medium",
      };
    } else if (rank === 3) {
      return {
        color: ["#00ff00", "#00ff00", "#00ff00"],
        value: "Password is strong",
      };
    } else {
      return {
        color: ["#e8e8e8", "#e8e8e8", "#e8e8e8"],
        value: "",
      };
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Sign Up"
        />
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleOnFinish}
          autoComplete="off"
          scrollToFirstError={true}
          initialValues={initialEmailAddress ? {email: initialEmailAddress } : {}}
        >
          <Form.Item
            name="email"
            label={
              <span className={classNames.label}>
                Email Address <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: validation.email.required },
              {
                type: "email",
                message: validation.email.valid,
              },
              {
                min: MAX_MIN.email.min,
                message: validation.email.minLength,
              },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
            ]}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={
              <span className={classNames.label}>
                Phone Number <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: validation.phoneNumber.required },
              {
                pattern: new RegExp(REGEX.phoneNumber),
                message: validation.phoneNumber.valid,
              },
            ]}
          >
            <Input
              placeholder={validation.phoneNumber.placeholder}
              prefix="+1"
              suffix={
                <div className={classNames.infoIcon}>
                  <Tooltip
                    title="By providing your number you opt-in receiving SMS messages from Otterz, Inc. Otterz uses SMS services to increase security of your login and access to platform. Message and data rates may apply. Message frequency varies."
                    color="white"
                    placement="topLeft"
                    overlayInnerStyle={{
                      color: "rgba(0,0,0,0.85)",
                      fontSize: 12,
                    }}
                  >
                    <InfoCircleOutlined height="1rem" width="1rem" />
                  </Tooltip>
                </div>
              }
            />
          </Form.Item>
          <Form.Item hidden name="countryCode" initialValue="1">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <span className={classNames.label}>
                Password <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              { required: true, message: validation.password.required },
              {
                min: MAX_MIN.password.min,
                message: validation.password.minLength,
              },
              {
                max: MAX_MIN.password.max,
                message: validation.password.maxLength,
              },
              {
                pattern: new RegExp(REGEX.password),
                message: validation.password.regex,
              },
              {
                validator: async (_, password, callback) => {
                  if (password) {
                    if (!validatePassword(password, setRank)) {
                      callback(validation.password.regex);
                    }
                  } else {
                    setRank(null);
                  }
                },
              },
            ]}
            extra={<sup style={{ top: "5px" }}>{validation.password.hint}</sup>}
          >
            <AntInput.Password
              iconRender={(visible) =>
                visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
              placeholder={validation.password.placeholder}
            />
          </Form.Item>
          <div className={classNames.passHint}>
            <div>Password strength :</div>
            <span
              style={{ backgroundColor: rankFunction()?.color?.[0] }}
            ></span>
            <span
              style={{ backgroundColor: rankFunction()?.color?.[1] }}
            ></span>
            <span
              style={{ backgroundColor: rankFunction()?.color?.[2] }}
            ></span>
            <div key={rank} style={{ color: rankFunction()?.color?.[0] }}>
              {rankFunction()?.value}
            </div>
          </div>
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  setMatcher(getFieldValue("password") === value);
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(validation.confirmPassword.match)
                  );
                },
              }),
              { required: true, message: validation.confirmPassword.required },
            ]}
            name="confirmPassword"
            label={
              <span className={classNames.label}>
                Confirm Password <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <AntInput.Password
              iconRender={(visible) =>
                visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
              placeholder={validation.confirmPassword.placeholder}
            />
          </Form.Item>

          <Form.Item className={classNames.links}>
            {" "}
            <span>
              By clicking on 'Sign Up', I agree that I have read and accepted
              the{" "}
              <Button
                type="link"
                size="small"
                style={{ padding: "0px 4px", fontSize: "0.8rem" }}
                onClick={() => {
                  // window.open(
                  //   `${
                  //     new URL(window.location.href).origin
                  //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                  //     ":param",
                  //     DISCLOSURES_TYPE[0].param
                  //   )}`
                  // );
                  window.open(`${DISCLOSURES_TYPE[0].link}`);
                }}
              >
                Terms and Conditions
              </Button>
              and the{""}
              <Button
                type="link"
                size="small"
                style={{ padding: "0px 4px", fontSize: "0.8rem" }}
                onClick={() => {
                  // window.open(
                  //   `${
                  //     new URL(window.location.href).origin
                  //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                  //     ":param",
                  //     DISCLOSURES_TYPE[2].param
                  //   )}`
                  // );
                  window.open(`${DISCLOSURES_TYPE[2].link}`);
                }}
              >
                Privacy Notice{" "}
              </Button>
              of Otterz.
            </span>
            <div className={classNames.tandc}>
              <span>
                Sign up requires you to opt-in receiving SMS messages from
                Otterz, Inc. Otterz uses SMS services to secure your login and
                access to platform. Message and data rates may apply. Message
                frequency varies.
              </span>
            </div>
          </Form.Item>

          <CTAButton
            htmlType="submit"
            type="primary"
            loading={status === "fetching"}
            disabled={rank !== 3 || !matcher}
          >
            Sign Up
          </CTAButton>

          <Form.Item className={classNames.links}>
            <span>Already have an account?</span>

            <Button
              type="link"
              size="small"
              onClick={() => navigate(ROUTES.PUBLIC.LOGIN)}
            >
              Sign in here
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
