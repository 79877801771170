import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Space,
  Select as AntSelect,
  Input as AntInput,
  Button as AntButton,
  DatePicker,
  Form,
} from "antd";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { RiArrowRightLine, RiDeleteBin7Line } from "react-icons/ri";
import DownloadCloudLineIcon from "remixicon-react/DownloadCloudLineIcon";
import { Input, Header } from "../../../common";

import AddNewSchedule from "./AddNewSchedule/AddNewSchedule";
import DeleteSchedule from "./DeleteSchedule/DeleteSchedule";
import UpdateSchedule from "./UpdateSchedule/UpdateSchedule";
import api from "../../../API";
import { FORMATS } from "../../../constants";
import generateErrorMessage from "../../../util/functions/customError";
import classNames from "./RecurringSchedules.module.scss";
import AccountActivityModal from "../../../BusinessBanking/config/accountActivityModal";

export default function RecurringSchedules() {
  //Hooks
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [selectedFilter, setSelectedFilter] = useState("StartDate");
  const [selectedRecord, setSelectedRecord] = useState();
  const [filter, setFilter] = useState({
    BillName: "",
    ScheduleId: "",
    Amount: "",
    StartDate: "",
  });

  const navigate = useNavigate();

  const [AddSchedules, setAddSchedules] = useState(false);
  const [deleteSchedule, setDeleteSchedule] = useState(false);
  const [updateSchedule, setUpdateSchedule] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [pageToken, setPageToken] = useState("");
  const [nextToken, setNextToken] = useState("");
  const [prevToken, setPrevToken] = useState("");
  const [prevCount, setPrevCount] = useState(0);
  const [accountActivityVisible, setAccountActivityVisible] = useState(false);

  const componentRef = useRef();
  const debounceRef = useRef();

  //Api calls
  const {
    isLoading: isLoadingListSchedule,
    isFetching,
    refetch: refetchSchedules,
  } = useQuery(
    ["listSchedule", { pageToken: pageToken }],
    () =>
      api.Receivables.PaymentAcceptance.listSchedule(
        pageToken,
        {
          ...filter,
        },
        isDownload
      ),
    {
      onSuccess: (data) => {
        setIsDownload(false);

        if (!isDownload) {
          setSchedules(data?.data?.Schedules || []);
        } else {
          handlePrint(data?.data?.Schedules || []);
        }
        setNextToken(data.data.NextToken);
      },
      onError: (err) => {
        generateErrorMessage(err);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isDownload) {
      refetchSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownload]);

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      refetchSchedules();
    }, 400);
  }, [refetchSchedules, filter]);

  //Event handlers
  const handleAddSchedulesModelCancelClick = () => {
    setAddSchedules(false);
    setSelectedRecord();
  };

  const handleDeleteScheduleModalCancelClick = () => {
    setDeleteSchedule(false);
    setSelectedRecord();
  };

  const handleUpdateScheduleModalCancelClick = () => {
    setUpdateSchedule(false);
    setSelectedRecord();
  };

  const nextButton = () => {
    setPrevToken([...prevToken, pageToken]);
    setPageToken(nextToken);
    setPrevCount(prevCount + 1);
  };

  const prevButton = () => {
    let indexValue = prevCount - 1;
    setPrevCount(indexValue);
    setPageToken(prevToken[indexValue]);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handlePrint = (downloadSchedules) => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = [
      "Schedule ID",
      "Date Created",
      "Start Date",
      "End Date",
      "Frequency",
      "Remaining Charges",
      "Transaction Amount",
      "Customer Name",
      "Status",
    ];

    const tableStyles = {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 2.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      alternateRowStyles: {
        fillColor: [251, 251, 251],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    };

    const rows = downloadSchedules?.map((schedule) => {
      return [
        schedule.ScheduleId,
        moment(schedule.CreatedDate).format(),
        moment(schedule.StartDate).format(),
        moment(schedule.EndDate).format(),
        schedule.IntervalType,
        schedule.remainingCharges,
        schedule.Amount?.toFixed(2),
        [schedule?.BillFirstName, schedule?.BillLastName]
          .filter(Boolean)
          ?.join(" ") || "-",
        schedule?.IsActive ? "Active" : "Inactive",
      ];
    });

    pdf.text(235, 40, "Recurring Schedules");

    pdf.autoTable(columns, rows, tableStyles);

    pdf.save("pdf");
  };

  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
  };
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);

  //Helper functions
  function disableIfScheduleStarted(date, id) {
    return moment(date, "YYYY-MM-DD").isBefore(moment());
  }

  //JSX Element
  // TO DO: Needs Refactoring
  const renderInput = (selectedFilter) => {
    if (selectedFilter === "BillName") {
      return (
        <Input
          value={filter["BillName"]}
          style={{ width: "65%" }}
          placeholder="Please enter"
          className={classNames.filterInput}
          onChange={(e) => {
            setFilter({
              ...filter,
              [selectedFilter]: e.target.value,
            });
          }}
        />
      );
    }

    if (selectedFilter === "Amount") {
      return (
        <Input
          type="number"
          value={filter["Amount"]}
          style={{ width: "65%" }}
          placeholder="Please enter"
          className={classNames.filterInput}
          onChange={(e) => {
            setFilter({
              ...filter,
              [selectedFilter]: e.target.value,
            });
          }}
        />
      );
    }
    if (selectedFilter === "ScheduleId") {
      return (
        <Input
          value={filter["ScheduleId"]}
          style={{ width: "65%" }}
          placeholder="Please enter"
          className={classNames.filterInput}
          onChange={(e) => {
            setFilter({
              ...filter,
              [selectedFilter]: e.target.value,
            });
          }}
        />
      );
    }
  };

  const columns = [
    {
      title: "Schedule ID",
      dataIndex: "ScheduleId",
      key: "ScheduleId",
      width: "8%",
    },
    {
      title: "Date Created",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      render: (_, value) => {
        return moment(value?.CreatedDate).format();
      },
    },

    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
      render: (_, value) => {
        return moment(value?.StartDate).format();
      },
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
      render: (_, value) => {
        return value?.EndDate ? moment(value?.EndDate).format() : "Infinite";
      },
    },
    {
      title: "Frequency",
      dataIndex: "IntervalType",
      key: "IntervalType",
    },
    {
      title: "Remaining Charges",
      dataIndex: "remainingCharges",
      key: "remainingCharges",
    },
    {
      title: "Transaction Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (amount) => amount?.toFixed(2),
    },
    {
      title: "Customer Name",
      dataIndex: "BillFirstName",
      key: "BillFirstName",
      render: (value, record) =>
        [record?.BillFirstName, record?.BillLastName]
          .filter(Boolean)
          ?.join(" ") || "-",
    },
    {
      title: "Status",
      dataIndex: "IsActive",
      key: "IsActive",
      render: (IsActive, record) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {!!IsActive && <span style={{ color: "#1DBC83" }}>Active</span>}
          {!IsActive && <span style={{ color: "#EE6969" }}>Inactive</span>}
          <Space size="middle">
            {/* <span
              className={
                (!record?.hasOwnProperty("IsActive") ||
                  disableIfScheduleStarted(
                    record?.StartDate,
                    record.ScheduleId
                  )) &&
                classNames.actionIconDisabledSpan
              }
            >
              <RiEditLine
                onClick={() => {
                  setSelectedRecord(record);
                  setUpdateSchedule(true);
                }}
                size={14}
                color="#9BA3AB"
                className={
                  !record?.hasOwnProperty("IsActive") ||
                  disableIfScheduleStarted(record?.StartDate, record.ScheduleId)
                    ? classNames.actionIconDisabled
                    : classNames.actionIcon
                }
              />
            </span> */}
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setDeleteSchedule(true);
              }}
            >
              <RiDeleteBin7Line
                size={14}
                color="#9BA3AB"
                className={classNames.actionIconDisabled}
              />
            </span>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Recurring Schedules" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.transactionFilters}>
              <Form form={form} autoComplete="off" scrollToFirstError={true}>
                <AntInput.Group className={classNames.search} compact>
                  <AntSelect
                    placeholder="Filter by"
                    className={classNames.filterSelect}
                    onChange={handleFilterChange}
                    defaultValue="StartDate"
                  >
                    <AntSelect.Option value="BillName">
                      Customer Name
                      {/* {filter?.BillName && (
                        <span style={{ color: "red", marginLeft: 8 }}>
                          {" "}
                          &bull;
                        </span>
                      )} */}
                    </AntSelect.Option>
                    <AntSelect.Option value="ScheduleId">
                      Schedule ID
                      {/* {!!filter?.ScheduleId && (
                        <span style={{ color: "red", marginLeft: 8 }}>
                          {" "}
                          &bull;
                        </span>
                      )} */}
                    </AntSelect.Option>
                    <AntSelect.Option value="Amount">
                      Transaction Amount
                      {/* {!!filter?.Amount && (
                        <span style={{ color: "red", marginLeft: 8 }}>
                          {" "}
                          &bull;
                        </span>
                      )} */}
                    </AntSelect.Option>
                    <AntSelect.Option value="StartDate">
                      Start Date
                      {/* {!!filter?.StartDate && (
                        <span style={{ color: "red", marginLeft: 8 }}>
                          {" "}
                          &bull;
                        </span>
                      )} */}
                    </AntSelect.Option>
                  </AntSelect>
                  {selectedFilter === "StartDate" ? (
                    <DatePicker
                      style={{ width: "65%" }}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          [selectedFilter]: e
                            ?.startOf("day")
                            .format("YYYY-MM-DD"),
                        })
                      }
                    />
                  ) : (
                    renderInput(selectedFilter)
                  )}
                  {/* <Input style={{ width: "65%" }} placeholder="Please enter" /> */}
                </AntInput.Group>
              </Form>
            </div>
            {/* <Card className={classNames.actionCard}> */}
            <AntButton
              style={{ height: 55, width: "320px", margin: "0 0 0 20px" }}
              onClick={() => {
                setIsDownload(true);
              }}
              loading={isDownload && (isLoadingListSchedule || isFetching)}
              disabled={
                schedules.length === 0 ||
                (!isDownload && (isLoadingListSchedule || isFetching))
              }
            >
              Download{" "}
              <DownloadCloudLineIcon size={15} style={{ marginLeft: 10 }} />
            </AntButton>
            {/* </Card> */}
            <Card
              className={classNames.optionCard}
              onClick={() => {
                if(isAccountInfoNeeded) {
                  setAccountActivityVisible(true);
                } else {
                  setAddSchedules(true);
                }
              }}
            >
              <div className={classNames.cardAction}>
                <span>Add New Schedule</span>
                <RiArrowRightLine
                  size={26}
                  className={classNames.arrowBtnWithBg}
                />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "26px 0",
              }}
            />

            <Table
              loading={!isDownload && isLoadingListSchedule}
              size="large"
              dataSource={schedules
                ?.sort((a, b) =>
                  moment(a?.xEnteredDate, FORMATS.DATEPICKER_FORMAT).diff(
                    moment(b?.xEnteredDate, FORMATS.DATEPICKER_FORMAT)
                  )
                )
                ?.reverse()}
              columns={columns}
              className={classNames.table}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (
                      !(
                        !!!record?.hasOwnProperty("IsActive") ||
                        disableIfScheduleStarted(record?.StartDate)
                      )
                    ) {
                      setUpdateSchedule(true);
                      setSelectedRecord(record);
                    }
                  },
                };
              }}
            />
            <div style={{ textAlign: "center", margin: 20 }}>
              <AntButton
                disabled={prevCount === 0 || isLoadingListSchedule}
                style={{ marginRight: 10 }}
                onClick={prevButton}
              >
                Prev
              </AntButton>
              <AntButton
                disabled={isLoadingListSchedule || schedules.length < 10}
                onClick={nextButton}
              >
                Next
              </AntButton>
            </div>
            <div style={{ overflow: "hidden", height: 0 }}>
              <Table
                ref={componentRef}
                size="large"
                dataSource={schedules
                  ?.sort((a, b) =>
                    moment(a?.xEnteredDate, FORMATS.DATEPICKER_FORMAT).diff(
                      moment(b?.xEnteredDate, FORMATS.DATEPICKER_FORMAT)
                    )
                  )
                  ?.reverse()}
                //  columns={columns}
                columns={columns?.slice(0, 8)}
                className={classNames.table}
                pagination={false}
                scroll={false}
              />
            </div>
          </div>
        </div>
      </div>
      <AddNewSchedule
        visible={AddSchedules}
        onCancel={handleAddSchedulesModelCancelClick}
        queryClient={queryClient}
        selectedRecord={selectedRecord}
      />
      <DeleteSchedule
        visible={deleteSchedule}
        selectedRecord={selectedRecord}
        onCancel={handleDeleteScheduleModalCancelClick}
        queryClient={queryClient}
      />
      <UpdateSchedule
        visible={updateSchedule}
        onCancel={handleUpdateScheduleModalCancelClick}
        queryClient={queryClient}
        selectedRecord={selectedRecord}
      />
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setAddSchedules}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        spawnModal={setAccountActivityVisible}
        title="Add New Schedule"
      />
    </div>
  );
}
