import classNames from "../ATMLocator.module.scss";
import { Card } from "antd";
import { Title } from "../../../common";
import DirectionLineIcon from "remixicon-react/DirectionLineIcon";
import { BsDot } from "react-icons/bs";

export default function ToolTip({ network, address }) {
  return (
    <Card className={classNames.toolTipCard}>
      <div>
        <Title as="h4">{network} - ATM </Title>
        <span>
          ATM <BsDot /> {address}
        </span>
      </div>
      <div>
        <DirectionLineIcon color="#7372FF" size={35} />
      </div>
    </Card>
  );
}
