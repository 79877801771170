import { useState } from "react";
import classNames from "../Onboarding.module.scss";
import { Title } from "../../../../common";
import { Form, Space, Button } from "antd";
import hardwareImg1 from "../../../../../assets/payment-acceptance/type/1.png";
import hardwareImg2 from "../../../../../assets/payment-acceptance/type/2.png";
import paymentImg1 from "../../../../../assets/payment-acceptance/type/3.png";
import paymentImg2 from "../../../../../assets/payment-acceptance/type/4.png";

const Type = ({ nextStep }) => {
  const [setOnboardMethod] = useState();
  const [selectedItems, setSelectedItems] = useState({});

  const HARDWARE = 5;
  const ONLINE = 3;

  const getPaymentMethod = (values) => {
    if (!values) return;
    if (Object.values(values).length > 1) {
      return "BOTH";
    } else if (values[HARDWARE]) {
      return "HARDWARE";
    } else {
      return "ONLINE";
    }
  };

  const handleClick = (selectedValue) => {
    if (!selectedValue) return;
    if (selectedItems[selectedValue]) {
      let newObj = Object.assign({}, selectedItems);
      delete newObj[selectedValue];
      setSelectedItems(newObj);
      setOnboardMethod(getPaymentMethod(newObj));
    } else {
      setSelectedItems({ ...selectedItems, [selectedValue]: true });
      setOnboardMethod(
        getPaymentMethod({ ...selectedItems, [selectedValue]: true })
      );
    }
  };

  const getNextStep = () => {
    if (!selectedItems) return;
    if (Object.values(selectedItems).length > 1 || selectedItems[HARDWARE]) {
      return HARDWARE;
    } else {
      return ONLINE;
    }
  };

  return (
    <div className={classNames.formWrapper}>
      <Title as="h3">
        What type of payment acceptance channels do you need?
      </Title>
      <div className={classNames.subheading}>You can select more than one</div>
      <Form layout="vertical" hideRequiredMark autoComplete="off">
        <Form.Item>
          <div className={classNames.industryBtnWrapper} buttonStyle="solid">
            <Space
              direction="horizontal"
              size="middle"
              style={{ width: "100%" }}
            >
              <div
                value={HARDWARE}
                className={classNames.typeRadioBtn}
                style={{
                  backgroundColor: HARDWARE in selectedItems && "#7372ff1a",
                  borderColor: HARDWARE in selectedItems && "#7372ff",
                }}
                onClick={(e) => handleClick(HARDWARE)}
              >
                <div className={classNames.typeRadioBtnImgs}>
                  <img alt="" src={hardwareImg1} />
                  <img alt="" src={hardwareImg2} />
                </div>
                <div className={classNames.typeRadioBtnTitle}>
                  <div>Payment Hardware</div>
                  <div>POS Terminal & Card readers</div>
                </div>
              </div>
              <div
                value={ONLINE}
                className={classNames.typeRadioBtn}
                style={{
                  backgroundColor: ONLINE in selectedItems && "#7372ff1a",
                  borderColor: ONLINE in selectedItems && "#7372ff",
                }}
                onClick={(e) => handleClick(ONLINE)}
              >
                <div className={classNames.typeRadioBtnImgs}>
                  <img alt="" src={paymentImg1} />
                  <img alt="" src={paymentImg2} />
                </div>
                <div className={classNames.typeRadioBtnTitle}>
                  <div>Online Payments</div>
                  <div>QR codes, Payment gateway & e-commerce</div>
                </div>
              </div>
            </Space>
          </div>
        </Form.Item>
        <div className={classNames.footerButtonWrapper}>
          <Button
            type="primary"
            className={classNames.nextButton}
            onClick={() => nextStep(getNextStep())}
            disabled={!Object.values(selectedItems).length ? true : false}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Type;
