import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import mixpanel from "mixpanel-browser";
import { Form, Input as AntInput, Radio, Button, Checkbox } from "antd";
import moment from "moment";
import {
  Modal,
  Message,
  CTAButton,
  Input,
  Select,
  DatePicker,
} from "../../../common";
import AddNewPayee from "../Payees/AddNewPayee/AddNewPayee";
import useLanguage from "../../../../hooks/useLanguage";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import { isBusinessType } from "../../../util/functions/onboarding";
import { checkIsAmount } from "../../../util/functions/checkIsAmount";
import {
  centsToDollar,
  dollarToCents,
} from "../../../util/functions/dollarConvertion";
import dateUtils from "../../../util/dateUtils";
import generateErrorMessage from "../../../util/functions/customError";
import ACHTransactionModal from "./ACHTransactionModal";
import REGEX from "../../../util/constants/regex";
import { MAX_MIN } from "../../../util/constants";
import { DEFAULT_AMOUNT, FORMATS } from "../../../constants";
import { validationPayee } from "../../config";
import { strings } from "../../businessBanking.const";
import classNames from "../MakePayments.module.scss";
import AccountActivityModal from "../../config/accountActivityModal";

function AddACHPayment({ onCancel, payeeData, accountBalance, refetch, setVisible }) {
  //Hooks
  const [amount, setAmount] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [periodFilter, setPeriodFilter] = useState();
  const [payee, setPayee] = useState();
  const [payLater, setPayLater] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(true);
  const [transMsg, setTransMsg] = useState("");
  const messages = useLanguage(strings);
  const [form] = Form.useForm();
  const { application } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [addPayeeModalVisibility, setAddPayeeModalVisibility] = useState(false);

  //Other variables
  const { TextArea } = AntInput;

  //Api calls
  const { mutate: createACHPayment, isLoading: createACHPaymentLoading } =
    useMutation(api.BusinessBanking.createACHPayment, {
      onSuccess: () => {
        refetch();
      },
    });

  const {
    mutate: createRecurringPayment,
    isLoading: createRecurringPaymentLoading,
  } = useMutation(api.BusinessBanking.createRecurringPayment, {
    onSuccess: () => {
      refetch();
    },
  });

  const [OTPModalVisible, setOTPModalVisible] = useState(false);

  //Event handlers
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (!payLater && !isRecurring) {
        let newACHPayment = {
          amount: Number(dollarToCents(values.amount)),
          otz_payee_id: payee?.otz_payee_id,
          description: values.description,
          ...(isBusinessType(application?.type)
            ? { otz_business_id: application?.otz_business_id }
            : { otz_person_id: application?.otz_person_id }),
        };

        createACHPayment(newACHPayment, {
          onSuccess: () => {
            Message({
              type: "success",
              content: messages.paymentSuccessMessage,
            });

            onCancel();
            form.resetFields();
            setVisible(false)
            queryClient.invalidateQueries("getStatementDetail");
            queryClient.invalidateQueries("getPayees");
          },
          onError: (error) => {
            if (
              error?.response?.status === 422 &&
              error?.response?.data?.payload?.code === "MFA_REQUIRED"
            ) {
              setOTPModalVisible(true);
            } else if (
              error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
            ) {
              Message({
                type: "error",
                content:
                  "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
              });
            } else {
              generateErrorMessage(error);
            }
          },
        });
      } else if (isRecurring) {
        let newRecurringPayment = {
          description: values.description,
          amount: dollarToCents(values.amount),
          frequency: values.frequency || "DAILY",
          start_date: moment(values.startDate).format(FORMATS.API_SEND_FORMAT),
          end_date: moment(values.endDate).format(FORMATS.API_SEND_FORMAT),
          otz_payee_id: payee?.otz_payee_id,
          is_recurring: 1,
          ...(isBusinessType(application?.type)
            ? { otz_business_id: application?.otz_business_id }
            : { otz_person_id: application?.otz_person_id }),
        };

        createRecurringPayment(newRecurringPayment, {
          onSuccess: (data) => {
            Message({
              type: "success",
              content: messages.recPaymentSuccessMessage,
            });
            try {
              mixpanel.track("Recurring Payment Scheduling Successful", {
                "Payee id": payee?.otz_payee_id,
                Amount:
                  data?.payload?.payment_instruction?.request?.amount / 100.0,
                Description: data?.payload?.description,
                "Start Date": data?.payload?.schedule?.start_date,
                Frequency: data?.payload?.schedule?.frequency,
                "End date": data?.payload?.schedule?.end_date,
                Status: data?.payload?.status,
                "Settlement Status": data?.payload?.status,
                "Settlement Date":
                  data?.payload?.next_payment_date?.scheduled_date,
              });
            } catch (error) {
              console.error("error: ", error?.response?.data);
            }
            form.resetFields();
            onCancel();
            setVisible(false)
            queryClient.invalidateQueries("getStatementDetail");
            queryClient.invalidateQueries("getPayees");
          },
          onError: (error) => {
            if (
              error?.response?.status === 422 &&
              error?.response?.data?.payload?.code === "MFA_REQUIRED"
            ) {
              setOTPModalVisible(true);
            } else if (
              error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
            ) {
              Message({
                type: "error",
                content:
                  "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
              });
            } else {
              generateErrorMessage(error);
              try {
                mixpanel.track("Recurring Payment Scheduling Failed", {
                  "Payee Details": payee?.otz_payee_id,
                  Amount: newRecurringPayment?.amount / 100.0,
                  Description: newRecurringPayment?.description,
                  "Start Date": newRecurringPayment?.start_date,
                  "End Date": newRecurringPayment?.end_date,
                  Frequency: newRecurringPayment?.frequency,
                  "Failure message": error?.response?.data?.message,
                });
              } catch (error) {
                console.error("error: ", error?.response?.data);
              }
            }
          },
        });
      } else if (payLater) {
        let newScheduledPayment = {
          description: values.description,
          amount: dollarToCents(values.amount),
          frequency: "DAILY",
          start_date: moment(values.date).format(FORMATS.API_SEND_FORMAT),
          // end_date: moment(values.date).format(FORMATS.API_SEND_FORMAT),
          is_recurring: 0,
          otz_payee_id: payee?.otz_payee_id,
          // count: 1,
          ...(isBusinessType(application?.type)
            ? { otz_business_id: application?.otz_business_id }
            : { otz_person_id: application?.otz_person_id }),
        };

        createRecurringPayment(newScheduledPayment, {
          onSuccess: (data) => {
            Message({
              type: "success",
              content: messages.payLaterSuccessMessage,
            });

            try {
              mixpanel.track("Payment Scheduling Successful", {
                "Payee id": payee?.otz_payee_id,
                Amount:
                  data?.payload?.payment_instruction?.request?.amount / 100.0,
                Description: data?.payload?.description,
                "Start Date": data?.payload?.schedule?.start_date,
                Status: data?.payload?.status,
                "Settlement Status": data?.payload?.status,
                "Settlement Date":
                  data?.payload?.next_payment_date?.scheduled_date,
              });
            } catch (error) {
              console.error("error: ", error?.response?.data);
            }
            form.resetFields();
            onCancel();
            setVisible(false)
            queryClient.invalidateQueries("getStatementDetail");
            queryClient.invalidateQueries("getPayees");
          },
          onError: (error) => {
            if (
              error?.response?.status === 422 &&
              error?.response?.data?.payload?.code === "MFA_REQUIRED"
            ) {
              setOTPModalVisible(true);
            } else if (
              error?.response?.data?.message === "SPEND_CONTROL_VIOLATION"
            ) {
              Message({
                type: "error",
                content:
                  "You have exhausted the spend limit of $100. Complete video verification to increase the limit.",
              });
            } else {
              form.resetFields()
              onCancel()
              generateErrorMessage(error);
              try {
                mixpanel.track("Payment Scheduling Failed", {
                  "Payee Details": payee?.otz_payee_id,
                  Amount: newScheduledPayment?.amount / 100.0,
                  Description: newScheduledPayment?.description,
                  "Start Date": newScheduledPayment?.start_date,
                  "Error message": error?.response?.data?.message,
                });
              } catch (error) {
                console.error("error: ", error?.response?.data);
              }
            }
          },
        });
      }
    });
  };

  const handleModalCancelClick = () => {
    setAddPayeeModalVisibility(false);
  };

  //Helper functions
  function disabledDateEnd(current) {
    return (
      current &&
      moment(current).add(-1, "days") < moment(form.getFieldValue("startDate"))
    );
  }

  function disabledDateBefore(current) {
    return current && current <= moment().subtract(0, "days");
  }
  function disabledDateBeforePayLater(current) {
    return current && current <= moment().subtract(0, "days");
  }

  useEffect(() => {
    let message = "";
    if (!(paymentDate && (isRecurring || payLater))) {
      if (payee) {
        message = `${payee?.account_owner_name} will receive $${
          amount && checkIsAmount(amount)
        }.`;
      }
    } else {
      message = `Payment will be initiated ${
        paymentDate && ` on ${paymentDate}`
      }.`;
    }
    setTransMsg(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDate, isRecurring, payLater, amount]);

  return (
    // <Spin spinning={isLoading}>
    <>
      <div className={classNames.modalLeft}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="payee"
            label={
              <span className={classNames.label}>
                Payee <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: validationPayee.quickFundTransfer.payee,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              name="payee"
              size="small"
              placeholder={validationPayee.quickFundTransfer.payeePlaceholder}
              onSelect={(e) =>
                setPayee(payeeData?.find((entry) => entry?.otz_payee_id === e))
              }
            >
              {payeeData &&
                payeeData.length > 0 &&
                payeeData.map((value) => (
                  <Select.Option value={value?.otz_payee_id}>
                    {`${value?.account_owner_name} - (${value?.account_number_masked})`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="dashed"
              style={{ marginTop: 16, height: 44, width: "100%" }}
              onClick={() => setAddPayeeModalVisibility(true)}
            >
              Add new payee
            </Button>
          </Form.Item>
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: validationPayee.quickFundTransfer.amount,
              },
              {
                pattern: new RegExp(REGEX.amount),
                message: validationPayee.quickFundTransfer.validAmount,
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value > accountBalance / 100) {
                    callback(
                      validationPayee.quickFundTransfer.insufficientFund
                    );
                  }
                  if (value && value <= 0) {
                    callback(validationPayee.quickFundTransfer.validAmount);
                  }
                  callback();
                },
              },
            ]}
            label={
              <span className={classNames.label}>
                Amount <sup style={{ top: 1 }}>*</sup>
              </span>
            }
            extra={
              <span style={{ top: "6px", fontSize: 12 }}>
                Available Balance:{" "}
                <span style={{ color: "#000" }}>
                  {centsToDollar(accountBalance) || DEFAULT_AMOUNT}
                </span>
              </span>
            }
          >
            <Input
              onChange={(e) => setAmount(e.target.value)}
              placeholder={validationPayee.quickFundTransfer.amountPlaceholder}
              size="small"
              prefix={<span style={{ color: "#9ba3ab" }}>$</span>}
              maxlength="11"
            />
          </Form.Item>
          <Form.Item
            rules={[
              // {
              //   required: true,
              //   message: validationPayee.quickFundTransfer.description,
              // },
              {
                max: MAX_MIN.description.max,
                message: validationPayee.quickFundTransfer.descriptionMax,
              },
            ]}
            name="description"
            label={
              <span className={classNames.label}>
                Description *
                {/* <sup style={{ top: 1 }}>*</sup> */}
              </span>
            }
          >
            <TextArea
              autoSize
              placeholder={
                validationPayee.quickFundTransfer.descriptionPlaceholder
              }
              style={{ height: 44 }}
            ></TextArea>
          </Form.Item>
          <Form.Item layout="vertical" name="payLater">
            <Checkbox
              checked={payLater}
              value="payLater"
              onChange={() => {
                setPayLater(!payLater);
                setIsRecurring(false);
                setPaymentDate(null);
                form.resetFields(["startDate", "endDate"]);
              }}
            >
              Pay later
            </Checkbox>
            {payLater && (
              <div
                style={{
                  backgroundColor: "#F6F7FA",
                  borderRadius: 8,
                  padding: "16px 6px 6px 16px",
                  marginTop: "16px",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: validationPayee.quickFundTransfer.date,
                    },
                  ]}
                  name="date"
                  label={
                    <span
                      className={classNames.label}
                      style={{ fontWeight: "normal", fontSize: 12 }}
                    >
                      Date <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <DatePicker
                    disabledDate={disabledDateBeforePayLater}
                    // disabled={[true, false]}
                    onChange={(e) =>
                      setPaymentDate(
                        moment(e).format(FORMATS.DATEPICKER_FORMAT)
                      )
                    }
                    style={{ height: 44, width: 142 }}
                  />
                </Form.Item>
              </div>
            )}
          </Form.Item>
          <Form.Item name="recurring">
            <Checkbox
              checked={isRecurring}
              value="recurring"
              onChange={() => {
                setIsRecurring(!isRecurring);
                setPayLater(false);
                setPaymentDate(null);
                form.resetFields(["date"]);
              }}
            >
              Recurring Payment
            </Checkbox>

            {isRecurring && (
              <div
                style={{
                  backgroundColor: "#F6F7FA",
                  borderRadius: 8,
                  padding: "16px 6px 6px 16px",
                  marginTop: "16px",
                }}
              >
                <Form.Item
                  name="frequency"
                  rules={[
                    {
                      required: false,
                      message: validationPayee.quickFundTransfer.period,
                    },
                  ]}
                >
                  <Radio.Group
                    defaultValue={"DAILY"}
                    onChange={(e) => {
                      setPeriodFilter(e.target.value);
                      form.validateFields(["endDate"]);
                    }}
                  >
                    <Radio value="DAILY" style={{ fontSize: 12 }}>
                      Daily
                    </Radio>
                    <Radio value="WEEKLY" style={{ fontSize: 12 }}>
                      Weekly
                    </Radio>
                    <Radio value="MONTHLY" style={{ fontSize: 12 }}>
                      Monthly
                    </Radio>
                    <Radio value="QUARTERLY" style={{ fontSize: 12 }}>
                      Quarterly
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="startDate"
                    style={{
                      display: "inline-block",
                      marginRight: 14,
                    }}
                    rules={[
                      {
                        required: true,
                        message: validationPayee.quickFundTransfer.startDate,
                      },
                    ]}
                    label={
                      <span
                        className={classNames.label}
                        style={{ fontWeight: "normal", fontSize: 12 }}
                      >
                        Payment Date <sup style={{ top: 1 }}>*</sup>
                      </span>
                    }
                  >
                    <DatePicker
                      disabledDate={disabledDateBefore}
                      onChange={(e) => {
                        form.resetFields(["endDate"]);
                        setPaymentDate(
                          moment(e).format(FORMATS.DATEPICKER_FORMAT)
                        );
                        e ? setDisableEndDate(false) : setDisableEndDate(true);
                      }}
                      style={{ height: 44 }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: validationPayee.quickFundTransfer.endDate,
                      },
                      () => ({
                        validator(_, value) {
                          const valueDateFormatted = dateUtils.toMMDDYYYY(
                            moment(value)
                          );
                          // date variable contains last date according to the periodFilter attr
                          let date = dateUtils.toMMDDYYYY(
                            moment(paymentDate).add(1, periodFilter)
                          );
                          if (
                            periodFilter &&
                            moment(valueDateFormatted).isBefore(date)
                          ) {
                            return Promise.reject(
                              new Error(`Please select any date after ${date}`)
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    style={{
                      display: "inline-block",
                    }}
                    label={
                      <span
                        className={classNames.label}
                        style={{ fontWeight: "normal", fontSize: 12 }}
                      >
                        End date <sup style={{ top: 1 }}>*</sup>
                      </span>
                    }
                  >
                    <DatePicker
                      disabled={disableEndDate}
                      disabledDate={disabledDateEnd}
                      style={{ height: 44 }}
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            )}
          </Form.Item>
        </Form>
      </div>
      <div className={classNames.modalRight}>
        <div>Transaction Summary</div>
        <div className={classNames.transactionCard}>
          <div className={classNames.payee}>
            <div className={classNames.payeeDetails}>
              {payee && payee?.account_owner_name}
              {payee?.account_number_masked && (
                <span>
                  Account Number : {payee && payee?.account_number_masked}
                </span>
              )}
              {payee?.routing_no && (
                <span>Routing Number : {payee?.routing_no}</span>
              )}
            </div>
            <span>
              {payee && payee?.type === "achCounterparty" ? "ACH" : payee?.type}
            </span>
          </div>
          <div className={classNames.transaction}>
            Amount
            <span>$ {checkIsAmount(amount)}</span>
          </div>
          <div className={classNames.transaction}>
            Transaction Fee
            <span>{DEFAULT_AMOUNT}</span>
          </div>
          <div className={classNames.total}>
            Total
            <span>${checkIsAmount(amount)}</span>
          </div>
          {payee && amount && (
            <div className={classNames.summaryText}>
              {/*  {/* {payee && payee?.account_owner_name} will receive $ $
              {amount && `${checkIsAmount(amount)}`} payment will be initiated
              {paymentDate && ` on  ${paymentDate}`}. */}
              {transMsg}
              <br />
              <span> Standard ACH will take 2-3 working days</span>
            </div>
          )}
        </div>

        <CTAButton
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmit}
          style={{ height: 44, width: 248 }}
          loading={createACHPaymentLoading || createRecurringPaymentLoading}
          disabled={+accountBalance <= 0}
        >
          Transfer
        </CTAButton>
        {+accountBalance <= 0 && (
          <p style={{ color: "#ff4d4f", width: 248, marginTop: 10 }}>
            Your account balance is USD 0.00, Please add funds to your account.
          </p>
        )}
      </div>
      <AddNewPayee
        visible={addPayeeModalVisibility}
        onCancel={handleModalCancelClick}
        achModal
        refetch={refetch}
      />
      <ACHTransactionModal
        visible={OTPModalVisible}
        // closable={true}
        onCloseClick={() => setOTPModalVisible(false)}
        onCancel={() => setOTPModalVisible(false)}
        onAuthorize={handleSubmit}
      />
    </>
  );
}

export default function ACHPayments({
  visible,
  onCancel,
  payeeData,
  isLoadingPayeeData,
  setVisible,
  accountBalance,
  refetch,
}) {

  const [accountActivityVisible, setAccountActivityVisible] = useState(false);
  const [showACHPayment, setShowACHPayment] = useState(false);
  const [isAccountInfoNeeded, setIsAccountInfoNeeded] =
  useState(true);

  const handleAccountActivityModalCancelClick = () => {
    setAccountActivityVisible(false);
    setVisible(false)
  }

  useEffect(() => { 
    if(visible) {
      if(isAccountInfoNeeded) {
        setAccountActivityVisible(true);
      } else {
        setShowACHPayment(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Modal
        closable={true}
        visible={showACHPayment}
        onCloseClick={() => {
          setShowACHPayment(false)
          setVisible(false)
        }}
        onCancel={onCancel}
        maskClosable={false}
        title="ACH Payment"
        className={classNames.modal}
        destroyOnClose
        bodyStyle={{
          padding: 0,
          height: "70vh",
          display: "flex",
          justifyContent: "space-between",
        }}
        width={730}
      >
        <AddACHPayment
          onCancel={() => setShowACHPayment(false)}
          payeeData={payeeData}
          isLoading={isLoadingPayeeData}
          accountBalance={accountBalance}
          refetch={refetch}
          setVisible={setVisible}
        />
      </Modal>
      <AccountActivityModal
        visible={accountActivityVisible}
        onCancel={handleAccountActivityModalCancelClick}
        onDone={setShowACHPayment}
        setIsAccountInfoNeeded={setIsAccountInfoNeeded}
        spawnModal={setAccountActivityVisible}
        title="Outgoing ACH Transactions"
      />
    </>
  );
}
