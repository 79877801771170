import { useNavigate } from "react-router-dom";
import { Form, Radio, Space } from "antd";
import BriefcaseLineIcon from "remixicon-react/BriefcaseLineIcon";
// import User2LineIcon from "remixicon-react/User2LineIcon";
import { CTAButton } from "../../common";
import Heading from "../Heading";
import Steps from "../Steps";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { ACCOUNT_TYPE, ROUTES } from "../../constants";
import classNames from "../Signup/Signup.module.scss";

export default function AccountType() {
  const navigation = useNavigate();
  const [form] = Form.useForm();
  const [, setProgress] = useLocalStorage("OTTERZ_op_accountType", {
    accountType: "",
  });

  function handleOnFinish() {
    const accountType = form.getFieldValue("accountType");
    accountType && setProgress((data) => ({ ...data, accountType }));

    if (accountType === ACCOUNT_TYPE.BUSINESS) {
      navigation(ROUTES.ONBOARDING.BUSINESS_APPLICATION);
    } else if (accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      navigation(ROUTES.ONBOARDING.INDIVIDUAL_APPLICATION);
    }
  }

  return (
    <div className={classNames.wrapper}>
      <Steps classNames={classNames} currentIndex={2} backButtonDisabled />
      <div className={classNames.formWrapper}>
        <Heading
          classNames={classNames}
          title="Account Type"
          description="Please select the type of account required"
        />
        <Form
          initialValues={{
            accountType: "BUSINESS",
          }}
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleOnFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="accountType"
            rules={[
              {
                required: true,
                message: "Account Type is required",
              },
            ]}
          >
            <Radio.Group onChange={() => {}} className={classNames.form}>
              <Space direction="vertical" size="middle">
                <Radio.Button value="BUSINESS" className={classNames.radioBtn}>
                  <span>
                    <BriefcaseLineIcon />
                    <span className={classNames.radioBtnText}>
                      Business
                      <div>
                        For corporate, sole proprietor, organizations, NGOs &
                        government authorities
                      </div>
                    </span>
                  </span>
                </Radio.Button>
                {/* <Radio.Button value="PERSON" className={classNames.radioBtn}>
                  <span>
                    <User2LineIcon />
                    <span className={classNames.radioBtnText}>
                      Individual
                      <div>
                        For freelancers, consultants, and anyone who works on
                        their own
                      </div>
                    </span>
                  </span>
                </Radio.Button> */}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item style={{ marginTop: 25 }}>
            <Form.Item
              className={classNames.accountTypeButton}
            >
              <CTAButton htmlType="submit" type="primary">
                Next
              </CTAButton>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
