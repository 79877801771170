import { useState, useEffect, useCallback, useContext } from "react";
import mixpanel from "mixpanel-browser";
import { Card, Spin } from "antd";
import { useMutation, useQuery } from "react-query";
import { usePlaidLink } from "react-plaid-link";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import { Title, Button, CTAButton, Header, Message } from "../../common";
import BankAccountDetails from "./BankAccountDetails";
import QuickFundTransfer from "./QuickFundTransfer";
import RecentTransactions from "./RecentTransactions";
import CashFlow from "./CashFlow/CashFlow";
import FundAmount from "./FundAmount";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import { isBusinessType } from "../../util/functions/onboarding";
import {
  centsToDollarWithoutSign,
  dollarToCents,
} from "../../util/functions/dollarConvertion";
import generateErrorMessage from "../../util/functions/customError";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import DelinkAccount from "./RecentTransactions/DelinkAccount";
import classNames from "./Overview.module.scss";

const PlaidLink = ({
  token,
  transferAmountPlaid,
  setTransferAmountPlaid,
  isAccountLinked,
  setIsAccountLinked,
  applicationData,
  balance,
  setRefetch,
}) => {
  //Hooks
  const [amountModalVisible, setAmountModalVisible] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [delinkModal, setDelinkModal] = useState(false);

  //Api calls
  const { mutate: processorTokenMutate, isLoading: processorTokenLoading } =
    useMutation((data) => api.BusinessBanking.createPlaidProcessorToken(data));

  const {
    mutate: createCPWithPlaidMutate,
    isLoading: createCPWithPlaidLoading,
  } = useMutation((data) => {
    return api.BusinessBanking.createCounterPartyWithPlaid(data);
  });

  const {
    mutate: createACHWithLinkedCPMutate,
    isLoading: createACHWithLinkedCPLoading,
  } = useMutation((data) => api.BusinessBanking.createACHWithLinkedCP(data));

  const {
    mutate: createWithdrawMutate,
    isLoading: createWithdrawMutateLoading,
  } = useMutation((data) => api.BusinessBanking.createWithdrawPayment(data));

  useEffect(() => {
    if (transferAmountPlaid) {
      const reqData = {
        amount: dollarToCents(transferAmountPlaid),
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : { otz_person_id: applicationData?.otz_person_id }),
      };

      withdraw
        ? createWithdrawMutate(
            { ...reqData, description: "Funds withdrawn from Otterz A/C" },
            {
              onSuccess: (data) => {
                setTransferAmountPlaid(null);
                Message({
                  type: "success",
                  content: "Withdraw fund request sent successfully!",
                });
                try {
                  mixpanel.track("Withdraw Funds Successful", {
                    "Amount": reqData.amount/100.0,
                    "Currency": "USD",
                    "Available Balance" : (balance - reqData)/100.0
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
                setRefetch(true);
              },
              onError: (error) => {
                setTransferAmountPlaid(null);
                generateErrorMessage(error);
                try {
                  mixpanel.track("Withdraw Funds Failed", {
                    "Amount": reqData.amount/100.0,
                    "Currency": "USD",
                    "Available Balance" : balance,
                    "Reason of Failure": error
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
              },
            }
          )
        : createACHWithLinkedCPMutate(
            { ...reqData, description: "Funds added to Otterz A/C" },
            {
              onSuccess: (data) => {
                setTransferAmountPlaid(null);
                Message({
                  type: "success",
                  content: "Add funds request sent successfully!",
                });
                try {
                  mixpanel.track("Add Funds Successful", {
                    "Amount": reqData.amount/100.0,
                    "Currency": "USD",
                    "Available Balance" : balance/100.0
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
              },
              onError: (error) => {
                setTransferAmountPlaid(null);
                generateErrorMessage(error);
                try {
                  mixpanel.track("Add Funds Failed", {
                    "Amount": reqData.amount/100.0,
                    "Currency": "USD",
                    "Available Balance" : balance/100.0,
                    "Reason of Failure": error
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
              },
            }
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferAmountPlaid]);

  const onSuccess = useCallback(
    (public_token, metadata) => {
      localStorage.setItem("account_id", metadata?.account_id);
      processorTokenMutate(
        {
          ...(isBusinessType(applicationData?.type)
            ? { otz_business_id: applicationData?.otz_business_id }
            : { otz_person_id: applicationData?.otz_person_id }),
          vendor_institution_id: metadata?.institution?.institution_id,
          vendor_public_token: public_token,
        },
        {
          onSuccess: (response) => {
            const accId = localStorage.getItem("account_id");

            createCPWithPlaidMutate(
              {
                ...(isBusinessType(applicationData?.type)
                  ? {
                      otz_business_id: applicationData?.otz_business_id,
                      customer_type: "BUSINESS",
                    }
                  : {
                      otz_person_id: applicationData?.otz_person_id,
                      customer_type: "PERSONAL",
                    }),
                vendor_access_token: response?.payload?.vendor_access_token,
                vendor_account_ids: [accId],
                vendor_institution_id: metadata?.institution?.institution_id,
              },
              {
                onSuccess: (data) => {
                  setIsAccountLinked(true);
                  localStorage.removeItem("account_id");

                  try {
                    mixpanel.track("Link Account Successful", {
                      "Bank Name": metadata.institution.name,
                    });
                  } catch (error) {
                    console.error("Mixpanel error: ", error);
                  }
                },
                onError: (error) => {
                  localStorage.removeItem("account_id");
                  setIsAccountLinked(false);
                  try {
                    mixpanel.track("Link Account Failed", {
                      "Bank Name": metadata.institution.name,
                    });
                  } catch (error) {
                    console.error("Mixpanel error: ", error);
                  }
                  Message({
                    type: "error",
                    content: "Counterparty creation failed!",
                  });
                },
              }
            );
          },
          onError: (error) => {
            localStorage.removeItem("account_id");
            Message({
              type: "error",
              content: "External account linking request failed!",
            });
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processorTokenMutate, createCPWithPlaidMutate]
  );

  //Plaid config
  const config = {
    token,
    onSuccess,
    // onExit
    // onEvent
  };
  const { open, ready } = usePlaidLink(config);

  const handleDelinkModalCancelClick = () => {
    setDelinkModal(false);
  };

  return (
    <>
      <div className={classNames.addBtn}>
        <CTAButton
          type="primary"
          className={classNames.addFundBtn}
          onClick={() => {
            setAmountModalVisible(true);
            setWithdraw(false);
          }}
          disabled={!isAccountLinked}
          loading={createACHWithLinkedCPLoading}
        >
          Add Funds
          <ArrowRightLineIcon size={16} className={classNames.arrowBtnWithBg} />
        </CTAButton>
        <CTAButton
          type="primary"
          className={classNames.addFundBtn}
          onClick={() => {
            setAmountModalVisible(true);
            setWithdraw(true);
          }}
          disabled={!isAccountLinked}
          loading={createWithdrawMutateLoading}
        >
          Withdraw
          <ArrowLeftLineIcon size={16} className={classNames.arrowBtnWithBg} />
        </CTAButton>
        {!isAccountLinked ? (
          <CTAButton
            type="primary"
            className={classNames.addFundBtn}
            onClick={open}
            disabled={!ready || isAccountLinked}
            loading={processorTokenLoading || createCPWithPlaidLoading}
          >
            Link Account
          </CTAButton>
        ) : (
          <CTAButton
            className={classNames.addFundBtn}
            onClick={() => {
              setDelinkModal(true);
            }}
            disabled={!isAccountLinked}
          >
            Delink Account
          </CTAButton>
        )}
      </div>

      <FundAmount
        visible={amountModalVisible}
        setVisible={setAmountModalVisible}
        onOpenPlaidModal={open}
        onCancel={() => {
          setAmountModalVisible(false);
          try {
            mixpanel.track("Add Funds Cancelled", {
              "Currency": "USD",
              "Available Balance": balance/100.0
            });
          } catch (error) {
            console.error("error: ", error); 
          }
        }}
        setFundAmount={setTransferAmountPlaid}
        balance={balance}
        withdraw={withdraw}
      />

      <DelinkAccount
        visible={delinkModal}
        onCancel={handleDelinkModalCancelClick}
        customerID={
          isBusinessType(applicationData?.type)
            ? { otz_business_id: applicationData?.otz_business_id }
            : { otz_person_id: applicationData?.otz_person_id }
        }
      />
    </>
  );
};

export default function Overview() {
  //Hooks
  const { application } = useContext(AppContext);
  const [transferAmount, setTransferAmount] = useState();
  const [refetch, setRefetch] = useState(true);
  const [isAccountLinked, setIsAccountLinked] = useState(false);
  const applicationData = application;
  const id = isBusinessType(applicationData?.type)
    ? applicationData?.otz_business_id
    : applicationData?.otz_person_id;

  //Api calls
  useQuery("getPersonDetail", () => api.BusinessBanking.getPerson(id), {
    onSuccess: (data) => {
      setIsAccountLinked(!!data?.payload?.external_account);
    },
    enabled: !!applicationData?.otz_person_id,
    refetchOnWindowFocus: false,
  });

  useQuery("getBusinessDetail", () => api.BusinessBanking.getBusiness(id), {
    onSuccess: (data) => {
      setIsAccountLinked(!!data?.payload?.external_account);
    },
    enabled: !!applicationData?.otz_business_id,
    refetchOnWindowFocus: false,
  });

  const {
    data: statementPayload,
    isLoading,
    isFetching,
    refetch: accountRefetch,
  } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: applicationData?.otz_account_id,
      }),
    {
      enabled: !!refetch,
      refetchOnWindowFocus: true,
      onSuccess: () => setRefetch(false),
    }
  );

  const {
    mutate: plaidTokenMn,
    data: plaidTokenMnData,
    isLoading: plaidTokenMnIsLoading,
  } = useMutation(api.BusinessBanking.createPlaidToken);

  //Hooks - useEffect
  useEffect(() => {
    const reqPayload = {
      ...(isBusinessType(applicationData?.type)
        ? { otz_business_id: id }
        : { otz_person_id: id }),
    };

    plaidTokenMn(reqPayload, {
      onError: (error) => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaidTokenMn]);

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Banking Overview" />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <Card className={classNames.balanceCard}>
              <div className={classNames.totalBalance}>
                <div>
                  <Title as="h4">Total Available Balance</Title>
                  <div className={classNames.amount}>
                    <span>$</span>{" "}
                    {isLoading ? (
                      <Spin />
                    ) : (
                      // TEMP || Needs to be replaced with calculated value
                      centsToDollarWithoutSign(
                        statementPayload?.payload?.total_available_balance
                      ) || "0.00"
                    )}
                    <Button
                      shape="circle"
                      disabled={!isLoading && isFetching}
                      icon={
                        !isLoading && isFetching ? (
                          <Spin size={10} />
                        ) : (
                          <RefreshLineIcon size={20} />
                        )
                      }
                      className={classNames.refreshBtn}
                      style={{ width: 40, height: 40, marginLeft: 10 }}
                      onClick={() => accountRefetch()}
                    />
                  </div>
                </div>
              </div>
              <Spin spinning={plaidTokenMnIsLoading}>
                <div>
                  <PlaidLink
                    token={plaidTokenMnData?.payload?.link_token}
                    transferAmountPlaid={transferAmount}
                    setTransferAmountPlaid={setTransferAmount}
                    setIsAccountLinked={setIsAccountLinked}
                    isAccountLinked={isAccountLinked}
                    applicationData={applicationData}
                    balance={
                      statementPayload?.payload?.total_available_balance || 0
                    }
                    setRefetch={setRefetch}
                  />
                </div>
              </Spin>
            </Card>
            <Card className={classNames.cashflowCard}>
              <CashFlow
                totalDeposits={statementPayload?.payload?.total_deposits}
                totalWithdrawals={statementPayload?.payload?.total_withdrawals}
                transactions={statementPayload?.payload?.data}
                isDataLoading={isLoading}
              />
            </Card>
            <Card className={classNames.tableCard}>
              <RecentTransactions
                transactionData={statementPayload?.payload?.data}
              />
            </Card>
          </div>
          <div className={classNames.layoutRight}>
            <QuickFundTransfer
              balance={statementPayload?.payload?.total_available_balance || 0}
              setRefetch={setRefetch}
            />
            <BankAccountDetails />
          </div>
        </div>
      </div>
    </div>
  );
}
