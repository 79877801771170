import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Form, Checkbox } from "antd";
import { CTAButton, Message } from "../../common";
import BeneficialOwnerFormList from "./BeneficialOwnerFormList";
import OwnerInfoModal from "./OwnerInfoDialog/OwnerInfo";
import Heading from "../Heading";
import Steps from "../Steps";
import useLocalStorage, {
  useLocalStorageData,
} from "../../../hooks/useLocalStorage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { DISCLOSURE_TYPE, FORMATS, ROUTES } from "../../constants";
import classNames from "../Signup/Signup.module.scss";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";

export default function BABeneficialOwner() {
  //Hooks
  const navigate = useNavigate();
  const [progress, setProgress] = useLocalStorage("OTTERZ_op_bo");
  const businessData = useLocalStorageData("OTTERZ_op_bd");
  const [form] = Form.useForm();
  
  const [isOwnershipCertified, setIsOwnershipCertified] = useState(false);
  const { userData } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [percentageList] = useState([]);
  const [availableEquity, setAvailableEquity] = useState(100);

  try {
    mixpanel.identify(userData?.email);

    // Set user properties
    mixpanel.people.set({
      $name: businessData?.name,
      $email: userData?.email,
    });
  } catch (error) {
    console.error("error: ", error);
  }

  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  //Api calls
  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  const createDisclosure = useMutation((data) => {
    return api.onBoarding.createDisclosure(data);
  });

  const addDigiStampMutation = useMutation((objectData) =>
    api.onBoarding.addDigiStamp(objectData)
  );
  
  const beneficiaryBusinesssRelation = useMutation(
    (data) => {
      return api.onBoarding.createBeneficiaryBusinessRelation(data);
    },
    {
      onSuccess: async (data) => {
        const inputData = form.getFieldsValue();
        const count = localStorage.getItem("count") || 0;
        localStorage.setItem("count", Number(count) + 1);

        // Create the disclosure for this person
        if(count === 0) {
          const reqPayload = {
            // event_type: "ACKNOWLEDGED",
            disclosure_date: new Date().toJSON(),
            // person_id: data.payload?.from_person_id,
            acknowledging_person_id: data.payload?.from_person_id,
            otz_business_id: application?.payload?.otz_business_id,
            type: [DISCLOSURE_TYPE.OWNER_CERTIFICATION],
            // version: "1.0"
          };
    
          await createDisclosure.mutate(reqPayload);
        }
        
        if (
          Number(localStorage.getItem("count")) ===
          progress?.beneficialOwners?.length
        ) {
          localStorage.removeItem("count");
          updateApplication.mutate(
            {
              onBoardingStatus: "BENEFICIAL_OWNER_CREATED",
            },
            {
              onSuccess: () => {
                Bugsnag.notify("Beneficial Owners - Success");
              },
              onError: (err) => {
                try {
                  mixpanel.track("Beneficial Owners - Failed", {
                    "Email ID": userData?.email,
                    "Sign Up Date": new Date().toISOString(),
                    "Business Name": businessData?.name,
                    "No. of beneficial owners": inputData?.beneficialOwners?.length,
                    "Company_ID": businessData?.id,
                    "Beneficial Owners Name": progress?.beneficialOwners.map(it => `${it.beneficialOwnerFirstName} ${it.beneficialOwnerLastName}`),
                    "Error": err
                  });
                } catch (error) {
                  console.error("error: ", error);
                }
                throw new Error(err);
              },
            }
          );

          try {
            mixpanel.track("Beneficial Owners - Completed", {
              "User Email ID": userData?.email,
              "Sign Up Date": new Date().toISOString(),
              "Business Name": businessData?.name,
              "No. of beneficial owners": inputData?.beneficialOwners?.length,
              "Company_ID": businessData?.id,
              "Beneficial Owner's Names": progress?.beneficialOwners.map(owner => `${owner.beneficialOwnerFirstName} ${owner.beneficialOwnerLastName}`),
              "Beneficial Owner's Emails": progress?.beneficialOwners.map(owner => owner.beneficialOwnerEmail),
              "Beneficial Owners Relationship ID": data?.payload?.id,
              "Beneficial Owners - from_person_id": data?.payload?.from_person_id,
            });
            mixpanel.people.set({
              "Sign Up": "Beneficial owners listed",
            });
          } catch (error) {
            console.error("error: ", error);
          }
          
          Message({ type: "success", content: data?.message });
          navigate(ROUTES.ONBOARDING.OFFICER_DETAILS);
        }
      },

      onError: (error) => {
        localStorage.removeItem("count");
        try {
          mixpanel.track("Beneficial Owners - Failed", {
            "Email ID": userData?.email,
            "Sign Up Date": new Date().toISOString(),
            "Business Name": businessData?.name,
            "Company_ID": businessData?.id,
            "Error": error,
          });
        } catch (error) {
          console.error("error: ", error);
        }
        generateErrorMessage(error);
      },
    }
  );

  const createBeneficiaryApplication = useMutation(
    (data) => {
      return api.onBoarding.createApplication(data);
    },
    {
      onSuccess: (response) => {
        const count = Number(localStorage.getItem("count") || 0);
        const currentData = {
          percent: progress?.beneficialOwners[count]?.beneficialOwnerPercentage,
          id: response?.payload?.otz_person_id,
          title: progress?.beneficialOwners[count]?.beneficialOwnerTitle,
        };
        percentageList?.push(currentData);
        localStorage.setItem("count", Number(count) + 1);

        if (
          Number(localStorage.getItem("count")) ===
          progress?.beneficialOwners?.length
        ) {
          localStorage.removeItem("count");
          manageRelation(count);
        }
      },
      onError: (error) => {
        localStorage.removeItem("count");
        generateErrorMessage(error);
      },
    }
  );
  
  const handleOnFinish = async () => {
    const inputData = form.getFieldsValue();

    setProgress({
      ...inputData,
    });

    if (form.isFieldsTouched()) {
      percentageList.splice(0, percentageList?.length);
      await addPerson(inputData?.beneficialOwners).then(() => {});
    } else {
      // navigate(ROUTES.ONBOARDING.OFFICER_DETAILS);
    }
  };

  const addPerson = async (personsData) => {
    const personPromises = personsData.map(async (beneficialOwner) => {
      const request = {
        tenant_id: userData?.tenantId,
        ssn: getSSN(beneficialOwner?.ssnInput),
        dob: moment(beneficialOwner?.beneficialOwnerDateOfBirth).format(
          FORMATS.API_SEND_FORMAT
        ),
        email: beneficialOwner?.beneficialOwnerEmail,
        first_name: beneficialOwner?.beneficialOwnerFirstName,
        last_name: beneficialOwner?.beneficialOwnerLastName,
        state: beneficialOwner?.beneficialOwnerState,
        address_line_1: beneficialOwner?.beneficialOwnerStreet,
        address_line_2: "",
        city: beneficialOwner?.beneficialOwnerCity,
        country_code: beneficialOwner?.beneficialOwnerCountry,
        postal_code: beneficialOwner?.beneficialOwnerPostalCode,
        phone_number: `+${beneficialOwner?.beneficialOwnerCountryCode}${beneficialOwner?.beneficialOwnerNumber}`,
        nationality: beneficialOwner?.beneficialOwnerNationality,
        is_customer: true,
      };
  
      try {
        const response = await createBeneficiaryApplication.mutateAsync(request);

        // Create the disclosure for this person
        // const reqPayload = {
        //   // event_type: "ACKNOWLEDGED",
        //   disclosure_date: new Date().toJSON(),
        //   // person_id: response.payload?.otz_person_id,
        //   acknowledging_person_id: response.payload?.otz_person_id,
        //   otz_business_id: application?.payload?.otz_business_id,
        //   type: [DISCLOSURE_TYPE.OWNER_CERTIFICATION],
        //   // version: "1.0"
        // };
  
        // await createDisclosure.mutate(reqPayload);
  
        // Call addDigiStampMutation for this person
        await addDigiStampMutation.mutateAsync({
          person_name: beneficialOwner?.beneficialOwnerFirstName, // Adjust as needed
          title: beneficialOwner?.beneficialOwnerTitle, // Adjust as needed
          personID: response.payload?.otz_person_id, // Adjust as needed 
        });
        
        return response;
      } catch (error) {
        // Properly handle and log any errors that occur during the process
        console.error("Error adding person:", error);
        generateErrorMessage("Something went wrong, please try again");
        throw error;
      }
    });
  
    return await Promise.all(personPromises);
  };
  
  
  const manageRelation = async (count) => {
    return await Promise.all(
      percentageList?.map((percent, index) => {
        const reqPayload = {
          to_business_id: application?.payload?.otz_business_id,
          from_person_id: percent?.id,
          title: percent?.title,
          percent_ownership: Number(percent?.percent),
        };

        const reqBoy = {
          data: reqPayload,
        };
        return beneficiaryBusinesssRelation.mutate(reqBoy);
      })
    );
  };

  //Event handler
  const onCancel = () => {
    setVisible(false);
  };

  //Helper
  const getSSN = (ssnNumber) => {
    if (ssnNumber) {
      return (
        ssnNumber?.slice(0, 3) +
        "-" +
        ssnNumber?.slice(3, 5) +
        "-" +
        ssnNumber?.slice(5)
      );
    }
  };

  return (
    <>
      <div className={classNames.accountFormWrapper} >
        <Steps classNames={classNames} currentIndex={2} count={5}/>
        <div className={classNames.formWrapper} style={{width: "75%"}}>
          <Heading
            classNames={classNames}
            title="Open a Business Account"
            description="Fill in the details of all beneficial owners with >=25% of stake in the business"
          />
          <div className={classNames.formLabelOwner}>Beneficial Owners</div>
          <div className={classNames.infoLabel}>
            <Button type="link" size="small" onClick={() => setVisible(true)}>
              Why do we need this information ?
            </Button>
          </div>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            initialValues={{
              beneficialOwners: progress?.beneficialOwners?.map((item) => ({
                ...item,
                beneficialOwnerDateOfBirth: item?.beneficialOwnerDateOfBirth
                  ? moment(item.beneficialOwnerDateOfBirth)
                  : null,
              })),
            }}
            form={form}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <BeneficialOwnerFormList
              initialValues={progress.beneficialOwners}
              form={form}
              availableEquity={availableEquity}
              setAvailableEquity={setAvailableEquity}
            />
            
            <Form.Item className={classNames.links}>
              <Checkbox
                checked={isOwnershipCertified}
                defaultChecked={false}
                onChange={() => setIsOwnershipCertified(!isOwnershipCertified)}
                style={{textAlign: "left"}}
              >
                <span style={{textAlign: "left"}}>
                I certify that I've listed all owners with at least 25% ownership and anyone with significant control over managing the business.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item style={{ marginTop: 25 }}>
              {/* <Form.Item className={classNames.links}>
              <span>
                By clicking 'Next' , I agree that I have read and accepted the
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    window.open("https://www.otterz.co/terms-of-service");
                  }}
                >
                  KYC disclosure
                </Button>
                of Otterz
              </span>
            </Form.Item> */}

              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <CTAButton
                  htmlType="submit"
                  type="primary"
                  loading={
                    createBeneficiaryApplication.isLoading ||
                    beneficiaryBusinesssRelation.isLoading
                  }
                  // disabled={availableEquity !== 0}
                  disabled={!(isOwnershipCertified)}
                  onClick={form.submit}
                >
                  Next
                </CTAButton>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      <OwnerInfoModal visible={visible} onCancel={onCancel} />
    </>
  );
}
