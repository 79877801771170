import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps as AntSteps } from "antd";
import { v4 } from "uuid";
import { BackButton } from "../../common";
import { ACCOUNT_TYPE } from "../../constants";
const Step = AntSteps.Step;

function Steps({ classNames, currentIndex, backButtonDisabled, count }) {
  const [stepsCount, setStepsCount] = useState(count || 7);
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("OTTERZ_op_accountType"))?.accountType ===
      ACCOUNT_TYPE.INDIVIDUAL
    ) {
      setStepsCount(5);
    }
  }, []);

  const navigate = useNavigate();
  const renderBackButton = () => {
    if (backButtonDisabled) {
      return null;
    // } else if (currentIndex === 3 || (stepsCount === 7 && currentIndex === 5)) {
    } else {
      if(count === 4) {
        return <BackButton variant="tail" onClick={() => navigate("/app/settings")} />;
      } else if (count === 5) {
        return <BackButton variant="tail" onClick={() => navigate("/onboarding/dashboard")} />;
      }
    }
  };

  return (
    <div className={classNames.stepsWrapper}>
      <AntSteps
        current={currentIndex}
        size="small"
        className={classNames.stepper}
        responsive={false}
      >
        {new Array(stepsCount).fill(v4()).map((item) => (
          <Step key={item} className={classNames.step} />
        ))}
      </AntSteps>
      <div className={classNames.stepperBackButton}>{renderBackButton()}</div>
    </div>
  );
}

export default Steps;
