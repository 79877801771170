const messages = {
  success: {
    title: "Thank you",
    extra: "Your Application is Submitted and Will be Processed soon",
  },
  pendingApproval: {
    title: "Hi",
    extra: "Your application is submitted and will be processed soon. Contact",
    link: "https://www.otterz.co/contact-us",
  },
  notApproved: {
    title: "Hi",
    extra:
      " Your application has not been approved by our underwriting team, please contact",
    link: "https://www.otterz.co/contact-us",
  },
  callBackText: {
    isOnBoard: {
      type: "ONBOARD",
      title: "Welcome to Otterz Payment Acceptance",
      subTitle: "Please fill out the form given to be able to use this feature",
      pendingSentence:
        "We have received your request and one of our representatives will get in touch with you soon. Contact",
      link: "https://www.otterz.co/contact-us",
    },
    isOrder: {
      type: "ORDER",
      title: "",
      subTitle:
        "Please fill out the form to initiate the conversation for ordering a new device. One of our sales representatives will contact you about this.",
      pendingSentence:
        "We have received your request and one of our representatives will get in touch with you soon. Contact",
      link: "https://www.otterz.co/contact-us",
    },
    isEcommerce: {
      type: "E_COMMERCE",
      title: "",
      subTitle:
        "Please fill out the form to initiate the conversation for an e-commerce integration. One of our sales representatives will contact you about this.",
      pendingSentence:
        "We have received your request and one of our representatives will get in touch with you soon. Contact",
      link: "https://www.otterz.co/contact-us",
    },
    isSetupHardWare: {
      type: "HARDWARE",
      title: "",
      subTitle:
        "Please fill out the form if you have any queries about setting up your payments acceptance hardware. One of our representatives will contact you about this.",
      pendingSentence:
        "We have received your request and one of our representatives will get in touch with you soon. Contact",
      link: "https://www.otterz.co/contact-us",
    },
  },
};

export default messages;
