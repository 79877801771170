import { Input, Header } from "../../common";
import { Table, Card, Radio, Space } from "antd";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useQuery } from "react-query";
import api from "../../API";
import { useEffect, useState } from "react";

import generateErrorMessage from "../../util/functions/customError";
import { FORMATS, PAGE_SIZE } from "../../constants";
import { PAGINATION_DEFAULT } from "../../util/constants";
import classNames from "./CashbackHistory.module.scss";

export default function CashbackHistory() {
  const [keyword, setKeyword] = useState();
  const [queryParams, setQueryParams] = useState();
  const [cashbackData, setCashBackData] = useState();

  // const {
  //   data: paymentData,
  //   isLoading: isPaymentDataLoading,
  //   refetch: refetchList,
  // } = useQuery(
  //   ["listRecurringPayments", { pageKey: pageKey[pageKey.length - 1] }],
  //   () =>
  //     api.BusinessBanking.listRecurringPayments(pageKey[pageKey.length - 1]),
  //   { refetchOnWindowFocus: false, onSuccess: (data) => console.log(data) }
  // );

  const { isLoading, refetch } = useQuery(
    "getRewardsDetails",
    () => api.Rewards.listPayouts(1, 3),

    {
      onSuccess: (data) => setCashBackData(data?.data?.res?.Items),
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    Boolean(cashbackData) &&
      cashbackData.length > 0 &&
      setCashBackData(
        cashbackData.map((value, index) => {
          return {
            key: value.payoutId,
            transaction: value?.summary,
            date: moment(value.createdAt).format(),
            amount: `$${value.amount}`,
            cashback: "$" + value.cashback,
            status: value.status,
          };
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(cashbackData), Boolean(queryParams)]);

  const columns = [
    {
      title: "Transaction",
      dataIndex: "transaction",
      key: "transaction",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format(),
      sorter: (a, b) =>
        moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
          moment(b?.date, FORMATS.DATEPICKER_FORMAT)
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Cashback",
      dataIndex: "cashback",
      key: "cashback",
      render: (amount) => <span style={{ color: "#1DBC83" }}>{amount}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "pending") {
          return (
            <span style={{ color: "#BC7C1D" }}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          );
        } else {
          return (
            <span style={{ color: "#1DBC83" }}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          );
        }
      },
    },
  ];
  const navigate = useNavigate();

  const getDataSource = () => {
    if (keyword) {
      cashbackData &&
        cashbackData.length > 0 &&
        cashbackData
          .filter((value, index) => {
            return Object.values(value)
              .toString()
              .toLowerCase()
              .includes(keyword.toLowerCase());
          })
          ?.sort((a, b) =>
            moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
              moment(b?.date, FORMATS.DATEPICKER_FORMAT)
            )
          )
          ?.reverse();
    } else {
      return cashbackData
        ?.sort((a, b) =>
          moment(a?.date, FORMATS.DATEPICKER_FORMAT).diff(
            moment(b?.date, FORMATS.DATEPICKER_FORMAT)
          )
        )
        ?.reverse();
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Cashback History" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.cashbackFilters}>
              <Input
                placeholder="Search cashback"
                prefix={<Search2LineIcon />}
                className={classNames.searchCashback}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Card className={classNames.filtersCard}>
                <div className={classNames.filtersGroup}>
                  <Radio.Group defaultValue="a" buttonStyle="solid">
                    <Space size={0}>
                      <Radio.Button
                        value="a"
                        onClick={() => {
                          setQueryParams({});
                          refetch();
                        }}
                      >
                        All
                      </Radio.Button>
                      <Radio.Button
                        value="b"
                        onClick={() => {
                          setQueryParams({
                            filterEnd: moment()
                              .subtract(1, "days")
                              .toISOString(),
                          });
                          refetch();
                        }}
                      >
                        1D
                      </Radio.Button>
                      <Radio.Button
                        value="c"
                        onClick={() => {
                          setQueryParams({
                            filterEnd: moment()
                              .subtract(7, "days")
                              .toISOString(),
                          });
                          refetch();
                        }}
                      >
                        1W
                      </Radio.Button>
                      <Radio.Button
                        value="d"
                        onClick={() => {
                          setQueryParams({
                            filterEnd: moment()
                              .subtract(1, "months")
                              .toISOString(),
                          });
                          refetch();
                        }}
                      >
                        1M
                      </Radio.Button>
                      <Radio.Button
                        value="e"
                        onClick={() => {
                          setQueryParams({
                            filterEnd: moment()
                              .subtract(6, "months")
                              .toISOString(),
                          });
                          refetch();
                        }}
                      >
                        6M
                      </Radio.Button>
                      <Radio.Button
                        value="f"
                        onClick={() => {
                          setQueryParams({
                            filterEnd: moment()
                              .subtract(1, "years")
                              .toISOString(),
                          });
                          refetch();
                        }}
                      >
                        Y
                      </Radio.Button>
                      {/* <Radio.Button value="g">
                        <CalendarLineIcon size={12} />
                      </Radio.Button> */}
                    </Space>
                  </Radio.Group>
                </div>
              </Card>
            </div>
          </div>
          <div className={classNames.body}>
            <span />
            <Table
              // scroll={{ y: `var(--table-height)` }}
              size="large"
              loading={isLoading}
              dataSource={getDataSource()}
              columns={columns}
              className={classNames.table}
              pagination={{
                ...PAGINATION_DEFAULT,
                pageSize: PAGE_SIZE,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
