import { Form } from "antd";
import { Modal } from "../../../../common";
import EditInvoiceForm from "./EditInvoiceForm";
import classNames from "./EditInvoice.module.scss";

export default function EditInvoice({
  visible,
  onCancel,
  invoice,
  customers,
  products,
  taxRates,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  return (
    <Modal
      getContainer={false}
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        onCancel();
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      title="Edit Invoice"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={872}
    >
      <EditInvoiceForm
        invoice={invoice}
        onCancel={onCancel}
        customers={customers}
        products={products}
        taxRates={taxRates}
        invoiceTemplateSettings={invoiceTemplateSettings}
      />
    </Modal>
  );
}
