import { Form, Upload, /*Button as AntButton,*/ Select, Tooltip  } from "antd";
import { useQuery } from "react-query";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {
  CTAButton,
  Title,
  Message,
} from "../../common";
import Heading from "../Heading";
import Steps from "../Steps";
import UploadIcon from "remixicon-react/Upload2LineIcon";
import useLocalStorage from "../../../hooks/useLocalStorage";
// import useLanguage from "../../../hooks/useLanguage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { ROUTES, ROUTE_STATUS, USER_STATES } from "../../constants";
// import messages from "../util/messages.const";
import { FILE_SIZE } from "../../constants";
import classNames from "../Signup/Signup.module.scss";
import AccountStatus from "../VerifyAccount/VerifyAccount";

export default function OnboardingDocs() {
  //Hooks
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useLocalStorage("OTTERZ_op_bd");
  // const businessData = useLocalStorageData("OTTERZ_op_bd");
  const { userData } = useContext(AppContext);
//   const language = useLanguage(messages);
  const [error, setError] = useState(false);
  const [, setFormationDoc] = useState(false);
  const [, setEINDoc] = useState(false);
  const [, setDBADoc] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [formationFiles, setFormationFiles] = useState([]);
  const [EINFiles, setEINFiles] = useState([]);
  const [DBAFiles, setDBAFiles] = useState([]);
  
  const [formationFieldName, setFormationFieldName] = useState(null);
  const [einFieldName, setEinFieldName] = useState(null);
  const [dbaFieldName, setDbaFieldName] = useState(null);

  const [entityLoading, setEntityLoading] = useState(true)
  const [entityType, setEntityType] = useState("");

  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: businessData, isLoading: isBusinessLoading } = useQuery(
    "getBusinessDetail",
    () =>
      api.BusinessBanking.getBusiness(application?.payload.otz_business_id),
    {
      onSuccess: (data) => {
        setEntityType(data?.payload?.structure);
        getListDocuments.mutate();
        setEntityLoading(false)
      },
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const getListDocuments = useMutation(() => api.onBoarding.getListDocuments({otz_business_id: application?.payload?.otz_business_id}), {
    onSuccess: (data) => {
      const documentsArray = data?.payload;

      documentsArray.forEach((document) => {
        document.name = document.file_name

        if (document.doc_type === "Formation Document") {
          setFormationFiles([document]); // Update the fileList state
          setFormationFieldName(document.doc_type);
          form.setFieldsValue({ 'formation-document': document.doc_type });
        } else if (document.doc_type === "EIN Verification") {
          setEINFiles([document]); // Update the fileList state
          setEinFieldName(document.doc_type)
          form.setFieldsValue({ 'ein-verification': document.doc_type });
        } else if (document.doc_type === "DBA Document") {
          setDBAFiles([document]); // Update the fileList state
          setDbaFieldName(document.doc_type)
          form.setFieldsValue({ 'dba-document': document.doc_type });
        }
      });
    }
  });

  function onFormationDoc(info) {
    if (!error && formationFiles.length < 1) {
      setFormationDoc(info.file);
    }
  }

  function onEINDoc(info) {
    if (!error && EINFiles.length < 1) {
      setEINDoc(info.file);
    }
  }

  function onDBADoc(info) {
    if (!error && DBAFiles.length < 1) {
      setDBADoc(info.file);
    }
  }

  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  const uploadRegDocsMutation = useMutation((formData) =>
    api.onBoarding.uploadRegDocsOnboarding(formData)
  );

  // Add this validation function to check if mandatory fields have files
const validateMandatoryFields = async () => {
  const formationDoc = form.getFieldValue('formation-document');
  const einVerification = form.getFieldValue('ein-verification');

  if (!formationFiles.length && !formationDoc) {
    form.setFields([
      {
        name: 'formation-document',
        errors: ['Please upload a file for the Formation Document.'],
      },
    ]);
    Message({ type: "error", content: "Please upload a file for the Formation Document" });
  }

  if (!EINFiles.length && entityType !== "SOLE_PROPRIETORSHIP" && !einVerification) {
    form.setFields([
      {
        name: 'ein-verification',
        errors: ['Please upload a file for the EIN Verification.'],
      },
    ]);
    Message({ type: "error", content: "Please upload a file for the EIN Verification" });
  }

  if (!formationFiles.length || (entityType !== "SOLE_PROPRIETORSHIP" && !EINFiles.length)) {
    Message({ type: "error", content: "Please upload related files before moving ahead" });
    return false;
  }

  return true;
};

  const handleOnFinish = async () => {
    // let inputData = form.getFieldsValue();
    const isMandatoryFieldsValid = await validateMandatoryFields();
    setProgress((prevVals) => ({
        ...prevVals,
    }));

    const allFiles = [...formationFiles, ...EINFiles, ...DBAFiles];

    const uploadFile = async (file) => {
        const formData = new FormData();
        // if (application?.otz_person_id) {
        //     formData.append("otz_person_id", application?.otz_person_id);
        // }
        if (application?.payload.otz_business_id) {
            formData.append("otz_business_id", application?.payload?.otz_business_id);
        } else {
            formData.append("otz_business_id", progress?.id);
        }
        formData.append("file", file);
        formData.append("doc_type", file.docType);

        try {
            // const data = 
            await uploadRegDocsMutation.mutateAsync(formData);

            setProgress((prevVals) => ({
                ...prevVals,
                formData
            }));
            // Message({ type: "success", content: data?.message });
        } catch (error) {
            generateErrorMessage(error);
            throw error; // re-throw the error to be caught in Promise.all
        }
    };

    if (isMandatoryFieldsValid) { 
      try {
          setIsUploading(true);
  
          if (!progress?.id || (progress?.id && form.isFieldsTouched())) {
              const results = await Promise.all(allFiles.map(file => uploadFile(file)));
              
              if (results.every(result => result?.message === "Success")) {
                Message({ type: "success", content: "All documents uploaded successfully!" });
              }
  
              try {
                const uploadedDocs = [
                  form?.getFieldValue('formation-document'),
                  form?.getFieldValue('ein-verification'),
                  form?.getFieldValue('dba-document')
                ].filter(Boolean); // This will remove any null or undefined values
  
                mixpanel.track("Business documents", {
                  'Upload status': results.every(result => result?.message === "Success") ? "Success" : "Failed",
                  'List of documents uploaded': uploadedDocs.join(", ")
                });
              } catch (error) {
                console.error("mixpanel-error: ", error)
              }
  
              updateApplication.mutate(
                {
                  onBoardingStatus: "ONBOARDING_DOCS_UPLOADED",
                },
                {
                  onSuccess: () => {
                      setVisible(true);
                  },
                  onError: (err) => {
                    throw new Error(err);
                  },
                }
              );
              // navigate(ROUTES.ONBOARDING.BUSINESS_OPERATION_DETAILS);
          } else {
              // navigate(ROUTES.ONBOARDING.BUSINESS_OPERATION_DETAILS);
          }
      } catch (error) {
        setIsUploading(false);
        Message({ type: "error", content: "Failed to upload documents. Please try again." });
      }
    }
  };

  const handlerOnSuccess = () => {
    setVisible(false);

    try {
      mixpanel.track("Sign up - completed", {
        "Company_ID": businessData?.id,
        "Sign Up Date": new Date().toISOString(),
        "App permission status": userData?.approved,
        "Account type": userData?.planType,
      });
    } catch (error) {
      console.error("error: ", error);
    }

    navigate(
      `${ROUTES.ONBOARDING.THANK_YOU}${
        ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
      }`
    );
  };

  const acceptableType = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
  const acceptableTypes = acceptableType.join(",");
  const { Dragger } = Upload;

  const acceptedType = (file) =>
    acceptableType.find((value) => value === file.type);

  return (
    <div className={classNames.accountFormWrapper}>
      <Steps classNames={classNames} currentIndex={3} count={5} />
      <div className={classNames.formBOInstructionsWrapper} style={{width: "100%"}}>
        <Heading
          classNames={classNames}
          title="Sign up for business account"
          description="Please upload the supporting documents as requested below"
        />
        {
          !isBusinessLoading && !entityLoading && (
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={handleOnFinish}
              form={form}
              initialValues={{
                progress,
                country: "US",
              }}
              autoComplete="off"
              scrollToFirstError={true}
            >
              <Form.Item>
            <Form.Item
              name="formation-document"
              label={
                <Title as="h4" className={classNames.label}>
                  {entityType === "SOLE_PROPRIETORSHIP"
                    ? "Tax Returns Documents"
                    : "Formation Document"
                  } <sup style={{ top: 1 }}>*</sup>
                  <Tooltip title={
                    entityType === "SOLE_PROPRIETORSHIP"
                      ? "Upload IRS Forms"
                      : "Upload Articles of Incorporation/Organization or Certificate of Formation or Certificate of Good Standing or Trust Agreement or any document issued when the company was formed"
                  }>
                    <i className="ri-question-line" style={{ color: "#9BA3AB", marginLeft: 4, fontSize: 12 }} />
                  </Tooltip>
                </Title>
              }
              rules={[
                {
                  required: true,
                  message: "Document Required",
                },
                {
                  validator: (rule, value, callback) => {
                    if (error) {
                      callback(`Max ${FILE_SIZE.MB} is allowed`);
                    }
                    callback();
                  },
                },
              ]}
              getValueFromEvent={(event) => event.target.value} 
            >
              {/* <label style={{fontSize: "10px", color: 'gray', lineHeight: "1.0715 !important"}}>Upload Articles of Incorporation/Organization or Certificate of Formation or Certificate of Good Standing or Trust Agreement or any document issued when the company was formed</label> */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: "10px" }}>
              <Select
                style={{ margin: "0" }}
                value={formationFieldName}
                placeholder="Select a document type"
                onChange={value => {
                  form.setFieldsValue({ 'formation-document': value })
                  setFormationFieldName(value);
                }}
              >
                {
                  entityType === "SOLE_PROPRIETORSHIP" ? (
                    <>
                    <Select.Option value="IRS document">IRS document</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                    </>
                  ) : (
                    <>
                    <Select.Option value="Articles of Incorporation/Organization">Articles of Incorporation/Organization</Select.Option>
                    <Select.Option value="Certificate of Formation">Certificate of Formation</Select.Option>
                    <Select.Option value="Certificate of Good Standing">Certificate of Good Standing</Select.Option>
                    <Select.Option value="Trust Agreement">Trust Agreement</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                    </>
                  )
                }
              </Select>
              <Dragger
                className={""}
                maxCount={1}
                fileList={formationFiles}
                accept={acceptableTypes}
                beforeUpload={(file) => {
                  const FormationVerification = form.getFieldValue('formation-document');
                  
                  // if (formationFiles.length >= 1) {
                  //   Message({
                  //     type: "error",
                  //     content: `Only one file can be uploaded.`,
                  //   });
                  //   return false;
                  // }
                  if (!FormationVerification) {
                    Message({
                      type: "error",
                      content: `Please provide the required information in the input before uploading.`,
                    });
                    return false;
                  }

                  if (!acceptedType(file)) {
                    setError(false);
                    Message({
                      type: "error",
                      content: `File type is not allowed. Please select a JPEG`,
                    });
                  } else if (file?.size > FILE_SIZE.B) {
                    setError(true);
                  } else {
                    const docType = form.getFieldValue('formation-document');
                    file.docType = docType;
                    file.uid = Math.random().toString(36).substring(2, 15);
                    setFormationFiles([file]);
                    form.setFieldsValue({ 'formation-document': "Formation Document" });
                    return false; // return false so file is not auto uploaded
                  }
                }}
                customRequest={({ file, onSuccess, onError }) => {
                  if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                    onSuccess("ok");
                  } else onError();
                }}
                onRemove={(file)=>{
                  const newFileList = formationFiles.filter(item => item.uid !== file.uid);
                  setFormationFiles(newFileList);
                }}
                onChange={onFormationDoc}
                showUploadList={{
                  showRemoveIcon: true, // Always show the remove icon
                  showPreviewIcon: true,
                }}
                style={{padding:'0 10px', height: '10px', width: '100%'}}
              >
                  {/* <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                    <i
                      className="ri-drag-drop-line"
                      style={{ fontSize: "24px", color: "#9BA3AB" }}
                    ></i>
                  </p>
                  <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                    {language.DRAG_DROP}
                    <AntButton
                      type="link"
                      size="small"
                      style={{ fontSize: "12px" }}
                    >
                      {language.IMAGE_UPLOAD_BTN_TEXT}
                    </AntButton>
                  </p> */}
                  <p className="ant-upload-icon" style={{ margin: 0, height: '22px' }}>
                    <UploadIcon style={{ fontSize: "18px", color: "#9BA3AB" }} />
                  </p>
                </Dragger>
              </div>
            </Form.Item>
              </Form.Item>

              {entityType !== "SOLE_PROPRIETORSHIP" && (
              <Form.Item>
            <Form.Item
              name="ein-verification"
              label={
                <Title as="h4" className={classNames.label}>
                  EIN Verification <sup style={{ top: 1 }}>*</sup>
                  <Tooltip title="Upload Form CP 575, a 147c letter, or a screenshot of the IRS website with your EIN on it">
                    <i className="ri-question-line" style={{ color: "#9BA3AB", marginLeft: 4, fontSize: 12 }} />
                  </Tooltip>
                </Title>
              }
              rules={[
                {
                  required: true,
                  message: "Document Required",
                },
                {
                  validator: (rule, value, callback) => {
                    if (error) {
                      callback(`Max ${FILE_SIZE.MB} is allowed`);
                    }
                    callback();
                  },
                },
              ]}
              getValueFromEvent={(event) => event.target.value} 
            >
              {/* <label style={{fontSize: "10px", color: 'gray', lineHeight: "1.0715 !important"}}>Upload Form CP 575, a 147c letter, or a screenshot of the IRS website with your EIN on it</label> */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: "10px" }}>
              <Select
                style={{ margin: "0" }}
                value={einFieldName}
                placeholder="Select a document type"
                onChange={value => {
                  form.setFieldsValue({ 'ein-verification': value })
                  setEinFieldName(value);
                }}
              >
                <Select.Option value="Form CP 575">Form CP 575</Select.Option>
                <Select.Option value="147c letter">147c letter</Select.Option>
                <Select.Option value="IRS website screenshot">IRS website screenshot</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
              <Dragger
                className={""}
                maxCount={1}
                fileList={EINFiles}
                accept={acceptableTypes}
                style={{padding:'0 10px', height: '10px', width: '100%'}}
                beforeUpload={(file) => {
                  const EINVerification = form.getFieldValue('ein-verification');

                  // if (EINFiles.length >= 1) {
                  //   Message({
                  //     type: "error",
                  //     content: `Only one file can be uploaded.`,
                  //   });
                  //   return false;
                  // }

                  if (!EINVerification) {
                    Message({
                      type: "error",
                      content: `Please provide the required information in the input before uploading.`,
                    });
                    return false;
                  }
                  if (!acceptedType(file)) {
                    setError(false);
                    Message({
                      type: "error",
                      content: `File type is not allowed. Please select a JPEG`,
                    });
                  } else if (file?.size > FILE_SIZE.B) {
                    setError(true);
                  } else {
                    const docType = form.getFieldValue('ein-verification');
                    file.docType = docType;
                    file.uid = Math.random().toString(36).substring(2, 15);
                    setEINFiles([file]);
                    form.setFieldsValue({ 'ein-verification': "EIN Verification" });
                    return false; // return false so file is not auto uploaded
                  }
                }}
                customRequest={({ file, onSuccess, onError }) => {
                  if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                    onSuccess("ok");
                  } else onError();
                }}
                onChange={onEINDoc}
                onRemove={(file)=>{
                  const newFileList = EINFiles.filter(item => item.uid !== file.uid);
                  setEINFiles(newFileList);
                }}
                showUploadList={true}
              >
                  {/* <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                    <i
                      className="ri-drag-drop-line"
                      style={{ fontSize: "24px", color: "#9BA3AB" }}
                    ></i>
                  </p>
                  <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                    {language.DRAG_DROP}
                    <AntButton
                      type="link"
                      size="small"
                      style={{ fontSize: "12px" }}
                    >
                      {language.IMAGE_UPLOAD_BTN_TEXT}
                    </AntButton>
                  </p> */}
                  <p className="ant-upload-icon" style={{ margin: 0, height: '22px' }}>
                    <UploadIcon style={{ fontSize: "18px", color: "#9BA3AB" }} />
                  </p>
              </Dragger>
              </div>
            </Form.Item>
              </Form.Item>)}

              <Form.Item>
                <Form.Item
                  name="dba-document"
                  label={
                    <Title as="h4" className={classNames.label}>
                      DBA Document
                      <Tooltip title="Upload Form CP 575, a 147c letter, or a screenshot of the IRS website with your EIN on it">
                        <i className="ri-question-line" style={{ color: "#9BA3AB", marginLeft: 4, fontSize: 12 }} />
                      </Tooltip>
                    </Title>
                  }
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (error) {
                          callback(`Max ${FILE_SIZE.MB} is allowed`);
                        }
                        callback();
                      },
                    },
                  ]}
                  getValueFromEvent={(event) => event.target.value} 
                >
                  {/* <label style={{fontSize: "10px", color: 'gray', lineHeight: "1.0715 !important"}}>If you have a DBA (Doing Business As) or an official name change, upload DBA or trade name documents</label> */}
                  <div style={{ display: 'flex', alignItems: 'flex-start', gap: "10px" }}>
                  <Select
                    value={dbaFieldName}
                    style={{ margin: "0" }}
                    placeholder="Select a document type"
                    onChange={value => {
                      form.setFieldsValue({ 'dba-document': value })
                      setDbaFieldName(value)
                    }}
                  >
                    <Select.Option value="Business License">Business License</Select.Option>
                    <Select.Option value="Assumed Name Statement">Assumed Name Statement</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </Select>
                  <Dragger
                    className={""}
                    maxCount={1}
                    fileList={DBAFiles}
                    style={{padding:'0 10px', height: '10px', width: '100%'}}
                    accept={acceptableTypes}
                    beforeUpload={(file) => {
                      const DBAVerification = form.getFieldValue('dba-document');

                      // if (DBAFiles.length >= 1) {
                      //   Message({
                      //     type: "error",
                      //     content: `Only one file can be uploaded.`,
                      //   });
                      //   return false;
                      // }
                      if (!DBAVerification) {
                        Message({
                          type: "error",
                          content: `Please provide the required information in the input before uploading.`,
                        });
                        return false;
                      }
                    
                      if (!acceptedType(file)) {
                        setError(false);
                        Message({
                          type: "error",
                          content: `File type is not allowed. Please select a JPEG`,
                        });
                      } else if (file?.size > FILE_SIZE.B) {
                        setError(true);
                      } else {
                        const docType = form.getFieldValue('dba-document');
                        file.docType = docType;
                        file.uid = Math.random().toString(36).substring(2, 15);
                        setDBAFiles([file]);
                        form.setFieldsValue({ 'dba-document': "DBA Document" });
                      
                        return false; // return false so file is not auto uploaded
                      }
                    }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (acceptedType(file) && file?.size > FILE_SIZE.B) {
                        onSuccess("ok");
                      } else onError();
                    }}
                    onChange={onDBADoc}
                    onRemove={(file)=>{
                      const newFileList = DBAFiles.filter(item => item.uid !== file.uid);
                      setDBAFiles(newFileList);
                    }}
                    showUploadList={true}
                  >
                      {/* <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                        <i
                          className="ri-drag-drop-line"
                          style={{ fontSize: "24px", color: "#9BA3AB" }}
                        ></i>
                      </p>
                      <p className="ant-upload-text" style={{ fontSize: "12px" }}>
                        {language.DRAG_DROP}
                        <AntButton
                          type="link"
                          size="small"
                          style={{ fontSize: "12px" }}
                        >
                          {language.IMAGE_UPLOAD_BTN_TEXT}
                        </AntButton>
                      </p> */}
                      <p className="ant-upload-icon" style={{ margin: 0, height: '22px' }}>
                        <UploadIcon style={{ fontSize: "18px", color: "#9BA3AB" }} />
                      </p>
                    </Dragger>
                  </div>
                </Form.Item>
              </Form.Item>
                    
              <Form.Item>
                {/* <Form.Item className={classNames.links}>
                  <span>
                    By clicking 'Next' , I agree that I have read and accepted the
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        window.open("https://www.otterz.co/terms-of-service");
                      }}
                    >
                      KYC disclosure
                    </Button>
                    of Otterz
                  </span>
                </Form.Item> */}

                <Form.Item
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    margin: "0 0 0 4px",
                    float: "right",
                  }}
                >
                  <CTAButton
                    htmlType="submit"
                    type="primary"
                    loading={isUploading || uploadRegDocsMutation.isLoading}
                  >
                    Next
                  </CTAButton>
                </Form.Item>
              </Form.Item>
            </Form>
          )
        }
      </div>
      <AccountStatus
        visible={visible}
        onCancel={() => setVisible(false)}
        userData={userData}
        inputData={form.getFieldsValue()}
        onSuccess={handlerOnSuccess}
        isPerson={false}
      />
    </div>
  );
}
