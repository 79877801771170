import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";

export default async function createDisclosure(data) {
  const response = await API.post("disclosure", "", {
    body: {
      ...data,
    },
  });

  try {
    mixpanel.identify(data.email)
                   
    if(response.status === 200) {
      mixpanel.track('Disclosure addition - success', {
        'Email ID': data.email,
        'Sign Up Date': new Date().toISOString(),
        'Company_ID': data.otz_business_id,
        'Disclosure acceptance status': 'Accepted',
      });
    } else {
      mixpanel.track('Disclosure addition - failure', {
        'Email ID': data.email,
        'Sign Up Date': new Date().toISOString(),
        'Company_ID': data?.otz_business_id,
        'Disclosure acceptance status': 'Failed',
      });
    }
  } catch (error) {
    console.error("error: ", error)
  }

  // code related to disclosures here

  return response
}
