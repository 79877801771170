import { useState } from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  GradientDefs,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  Hint,
  MarkSeries,
} from "react-vis";
import { RiArrowRightUpLine } from "react-icons/ri";

import classNames from "./TotalOutgoingPayments.module.scss";
import { centsToDollar } from "../../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../../constants";

const TotalOutgoingPayments = ({
  dataArray,
  averageTransactionAmount,
  highestTransactionValue,
  height,
  width,
}) => {
  const [state, setState] = useState();
  const forgetValue = () => {
    setState(null);
  };

  const rememberValue = (value) => {
    setState(value);
  };
  return (
    <div className={classNames.wrapper} key="TotalOutgoingPaymentsWrapper">
      <div className={classNames.chartWrapper} key="TotalOutgoingPaymentsName">
        <div className={classNames.chartName}>
          Total Outgoing Payments from Bank Account
        </div>
        <div className={classNames.chart} key="TotalOutgoingPaymentsGraph">
          <FlexibleXYPlot
            height={height}
            width={width}
            key="TotalOutgoingPayments"
            xType="ordinal"
            margin={{ left: 55, right: 0, top: 50, bottom: 30 }}
            animation={true}
          >
            <XAxis />
            <YAxis
              tickFormat={(value) => {
                const lookupArray = [
                  { scale: 1e-9, symbol: "" },
                  { scale: 1e-6, symbol: "" },
                  { scale: 1e-3, symbol: "" },
                  { scale: 1, symbol: "" },
                  { scale: 1e3, symbol: "k" },
                  { scale: 1e6, symbol: "M" },
                  { scale: 1e9, symbol: "G" },
                  { scale: 1e12, symbol: "T" },
                  { scale: 1e15, symbol: "P" },
                  { scale: 1e18, symbol: "E" },
                ];
                const regX = /\.0+$|(\.[0-9]*[1-9])0+$/;
                let item = lookupArray
                  .slice()
                  .reverse()
                  .find(function (item) {
                    return Math.abs(value) >= item.scale;
                  });
                return item
                  ? (value / item.scale).toFixed(2).replace(regX, "$1") +
                      item.symbol
                  : "0";
              }}
              tickPadding={1}
            />
            <HorizontalGridLines />
            <MarkSeries
              onNearestXY={rememberValue}
              onValueMouseOver={rememberValue}
              onValueMouseOut={forgetValue}
              data={
                Array.isArray(dataArray) &&
                dataArray.length > 0 &&
                dataArray.map((value, index) => {
                  return { x: `${value.x}`, y: `${value.y}` };
                })
              }
            />
            {state ? (
              <Hint className={classNames.hint} value={state}>
                <p>{`${centsToDollar(state.y * 100) || DEFAULT_AMOUNT}`}</p>
              </Hint>
            ) : null}
            <GradientDefs>
              <linearGradient x1="0" x2="0" y1="0" y2="1" id="areaGradient">
                <stop offset="0%" stopColor="#52EABC3B" />
                <stop offset="100%" stopColor="#52EABC3B" stopOpacity="-0.1" />
              </linearGradient>
            </GradientDefs>

            <AreaSeries
              animation={true}
              data={dataArray}
              colorType="literal"
              color={"url(#areaGradient)"}
              barWidth={1}
              curve={"curveMonotoneX"}
            />

            <LineSeries
              // animation={true}
              data={dataArray}
              colorType="literal"
              color={"#1DBC83"}
              barWidth={1}
              curve={"curveMonotoneX"}
              lineStyle={{ stroke: "red" }}
              markStyle={{ stroke: "black", fill: "red" }}
            />
          </FlexibleXYPlot>
        </div>
      </div>
      <div className={classNames.itemsWrapper}>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div className={classNames.iconContainer}>
              <RiArrowRightUpLine color="#1DBC83" size={18} />
            </div>
            <div className={classNames.heading}>Average Transaction Amount</div>
          </div>
          <div className={classNames.count}>{averageTransactionAmount}</div>
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemHeading}>
            <div className={classNames.iconContainer}>
              <RiArrowRightUpLine color="#1DBC83" size={18} />
            </div>
            <div className={classNames.heading}>Highest Transaction Value</div>
          </div>
          <div className={classNames.count}>{highestTransactionValue}</div>
        </div>
      </div>
    </div>
  );
};

export default TotalOutgoingPayments;
