import { useState } from "react";
import { Card, Form, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { useQuery } from "react-query";
import {
  RiSearch2Line,
  RiArrowRightLine,
  RiDeleteBin7Line,
} from "react-icons/ri";

import { Input, Header, NoResult } from "../../common";
import api from "../../API";

import AddUser from "./AddUser/AddUser";
import DeleteUser from "./DeleteUser/DeleteUser";
import classNames from "./UserManagement.module.scss";

export default function UserManagement() {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [addUser, setAddUser] = useState(false);

  const [keyword, setKeyword] = useState();

  const [selectedUserId, setSelectedUserId] = useState();

  const [deleteUserModalVisibility, setDeleteUserModalVisibility] =
    useState(false);

  const handleAddUserModalCancelClick = () => {
    form.resetFields();
    setAddUser(false);
  };

  const {
    data: childUsersQuery,
    isSuccess: childUsersQueryIsSuccess,
    isLoading: getChildUsersLoading,
  } = useQuery(
    "getChildUsersByTenantId",
    () => api.Settings.getChildUsersByTenantId(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleDeleteOnClick = (data) => {
    setSelectedUserId({ ...data });
    setDeleteUserModalVisibility(true);
  };

  const handleCancel = () => {
    setDeleteUserModalVisibility(false);
  };

  let childUsersArray = childUsersQuery?.data || [];

  const roles = {
    invoicingUser: "Invoicing User",
    accountingUser: "Accounting User",
    expenseUser: "Expense User",
  };
  const users =
    childUsersQueryIsSuccess &&
    Array.isArray(childUsersArray) &&
    childUsersArray.length > 0 &&
    childUsersArray
      .filter((value, index) => value.roleName !== "owner")
      .map((value, index) => {
        return {
          name: value.username,
          email: value.email,
          role: roles[value.roleName],
          subId: value.id,
          active: value.active,
        };
      })
      .filter((value, index) =>
        keyword
          ? Object.values(value)
              .toString()
              .toLowerCase()
              .includes(keyword.toLowerCase())
          : value
      );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="User Management" back onClick={() => navigate(-1)} />
        <div className={classNames.subHeaderWrapper}>
          <Input
            placeholder="Search users"
            prefix={<RiSearch2Line size={26} />}
            className={classNames.searchUser}
            onChange={(event) => setKeyword(event.target.value)}
          />
          <Card
            className={classNames.optionCard}
            onClick={() => setAddUser(true)}
          >
            <div className={classNames.cardAction}>
              <span>Add New User</span>
              <RiArrowRightLine
                size={26}
                className={classNames.arrowBtnWithBg}
              />
            </div>
          </Card>
        </div>
      </div>
      <div className={classNames.bodyWrapper}>
        <Spin spinning={getChildUsersLoading}>
          <div className={classNames.cardContainer}>
            {users && users.length > 0 ? (
              users.map((user) => {
                return (
                  <Card className={classNames.userCard} key={user.email}>
                    <div>
                      {user.name}
                      {!user.active && (
                        <span
                          className={classNames.inactiveLabel}
                        >{`( Inactive user )`}</span>
                      )}{" "}
                      <div>
                        <span>{user.email}</span>
                        <span>
                          <sup style={{ top: 1 }}>
                            <BsDot />
                          </sup>
                        </span>
                        <span>{user.role}</span>
                      </div>
                    </div>
                    <div>
                      <RiDeleteBin7Line
                        disabled
                        size={14}
                        color="#9BA3AB"
                        className={classNames.actionIcon}
                        onClick={() =>
                          handleDeleteOnClick({ subId: user?.subId })
                        }
                      />
                    </div>
                  </Card>
                );
              })
            ) : (
              <div style={{ margin: "auto" }}>
                <NoResult imgHeight={230} />
              </div>
            )}
          </div>
        </Spin>
      </div>
      <AddUser
        visible={addUser}
        onCancel={handleAddUserModalCancelClick}
        form={form}
        users={childUsersArray}
      />
      <DeleteUser
        visible={deleteUserModalVisibility}
        onCancel={handleCancel}
        currentUserId={selectedUserId}
      />
    </div>
  );
}
