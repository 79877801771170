import classNames from "./Success.module.scss";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Title } from "../../../common";

const Success = ({ refNum }) => {
  return (
    <div className={classNames.success}>
      <RiCheckboxCircleFill
        size={90}
        color="#1DBC83"
        style={{ marginBottom: 34 }}
      />
      <Title as="h3">Payment sent successfully!</Title>
      <div className={classNames.extra}>
        Transaction reference number:{" "}
        <span>
          <strong>{refNum}</strong>
        </span>
      </div>
    </div>
  );
};

export default Success;
