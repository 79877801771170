import { API } from "aws-amplify";

export default async function createRecurringInvoice(data) {
  return await API.post("invoices", "recurring", {
    body: {
      ...data,
      action: "send-email",
    },
  });
}
