import ENDPOINT_KEYS from "../../../apiEndpoints/endpointKeys";
import customFetch from "../utils/customFetch";

export default function uploadImage(data) {
  const queryParams = {
    id: data.id,
    page: data.page,
  };

  const headers = {
    "Content-Type": "image/jpeg",
  };

  return customFetch(
    "PUT",
    ENDPOINT_KEYS.checkDeposits,
    "upload-image",
    queryParams,
    data.input,
    headers
  );
}
