import { useState } from "react";
import { Form, Upload, Button } from "antd";

import { Title } from "../../../common";

import classNames from "../Signup.module.scss";

import handleUploadFunc from "./handleUploadFunc";
import checkFileSize from "./checkFileSize";
import handleRemove from "./removeFile";

const IdDocument = ({
  applicationId,
  documentId,
  applicationDBId,
  tenantId,
  setUploadTrackingDocs,
  dispatcAcRef,
  ownerName,
}) => {
  const [showUploadFront, setShowUploadFront] = useState(true);
  const [showUploadBack, setShowUploadBack] = useState(true);
  const handleChangeFront = (data) => {
    if (data?.fileList.length > 0) {
      setShowUploadFront(false);
    } else if (data?.fileList.length === 0) {
      setShowUploadFront(true);
    }
  };
  const handleChangeBack = (data) => {
    if (data?.fileList.length > 0) {
      setShowUploadBack(false);
    } else if (data?.fileList.length === 0) {
      setShowUploadBack(true);
    }
  };
  return (
    <Form.Item
      label={
        <Title as="h4" className={classNames.label}>
          ID Document picture <sup style={{ top: 1 }}>*</sup>
          <div>
            Upload a document of the main page of <b>{ownerName}</b>'s ID
            Document
          </div>
        </Title>
      }
    >
      <Form.Item
        label="Front"
        style={{
          display: "inline-block",
          width: "calc(50% - 8px)",
          marginBottom: "10px",
          float: "left",
        }}
      >
        <Upload.Dragger
          customRequest={({ onSuccess, onError, file }) =>
            handleUploadFunc(
              onSuccess,
              onError,
              file,
              applicationId,
              documentId,
              applicationDBId,
              tenantId,
              setUploadTrackingDocs,
              dispatcAcRef
            )
          }
          listType="picture"
          beforeUpload={checkFileSize}
          accept="application/pdf"
          maxCount={1}
          onChange={handleChangeFront}
          onRemove={() => handleRemove(setUploadTrackingDocs, documentId)}
          style={{ display: `${showUploadFront ? "block" : "none"}` }}
        >
          <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
            <i
              className="ri-drag-drop-line"
              style={{ fontSize: "24px", color: "#9BA3AB" }}
            ></i>
          </p>
          <p className="ant-upload-text" style={{ fontSize: "12px" }}>
            Drag & Drop or
            <Button type="link" size="small" style={{ fontSize: "12px" }}>
              click here to upload
            </Button>
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Back"
        style={{
          display: "inline-block",
          width: "calc(50% - 4px)",
          margin: "0 0 0 4px",
          float: "right",
        }}
      >
        <Upload.Dragger
          customRequest={({ onSuccess, onError, file }) =>
            handleUploadFunc(
              onSuccess,
              onError,
              file,
              applicationId,
              documentId,
              applicationDBId,
              tenantId,
              setUploadTrackingDocs,
              dispatcAcRef,
              false
            )
          }
          listType="picture"
          accept="image/png,image/jpeg,application/pdf"
          beforeUpload={checkFileSize}
          maxCount={1}
          onChange={handleChangeBack}
          onRemove={() => handleRemove(setUploadTrackingDocs, documentId)}
          style={{ display: `${showUploadBack ? "block" : "none"}` }}
        >
          <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
            <i
              className="ri-drag-drop-line"
              style={{ fontSize: "24px", color: "#9BA3AB" }}
            ></i>
          </p>
          <p className="ant-upload-text" style={{ fontSize: "12px" }}>
            Drag & Drop or
            <Button type="link" size="small" style={{ fontSize: "12px" }}>
              click here to upload
            </Button>
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Form.Item>
  );
};

export default IdDocument;
