import { useQuery } from "react-query";
import { useContext } from "react";
import { AppContext } from "../../../context/AppState";
import { Form, Button, Spin } from "antd";
import { CTAButton, Input, Title } from "../../common";
import api from "../../API";
import { maskPhone } from "../../util";
import generateErrorMessage from "../../util/functions/customError";
import { isBusinessType } from "../../util/functions/onboarding";
import classNames from "./ManageCards.module.scss";

export default function EnterOTP({
  createCustomerBearerToken,
  verificationToken,
  verficationTokenMutation,
}) {
  const [form] = Form.useForm();
  const { application } = useContext(AppContext);

  //Api calls
  const { data: personData, isLoading: isPersonLoading } = useQuery(
    "getPersonDetail",
    () => api.BusinessBanking.getPerson(application?.otz_person_id),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { data: businessData, isLoading: isBusinessLoading } = useQuery(
    "getBusinessDetail",
    () =>
      api.BusinessBanking.getBusiness(application?.otz_business_id),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function onKeyUp(first, last, event) {
    //Need to update below logic for backspace
    if (event && event.nativeEvent.inputType === "deleteContentBackward") {
      document.getElementById(`otpBox${first}`).focus();
    } else {
      document.getElementById(`otpBox${last}`).focus();
    }
  }

  async function onFinish(values) {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    let code = Object.keys(values)
      .filter((item) => item !== "password" && item !== "confirmPassword")
      .map((item) => values[item])
      .join("");

    createCustomerBearerToken.mutate({
      input: { verificationToken, verificationCode: code },
    });
  }

  return (
    <Spin
      spinning={
        isBusinessType(application?.type)
          ? isBusinessLoading
          : isPersonLoading
      }
    >
      <div className={classNames.otpWrapper}>
        <Title as="h3">Enter OTP</Title>
        <span>
          We have sent an OTP to your phone number{" "}
          {isBusinessType(application?.type)
            ? maskPhone(businessData?.payload?.phone_number)
            : maskPhone(personData?.payload?.phone_number)}
        </span>
        <Form
          layout="inline"
          hideRequiredMark
          className={classNames.otpForm}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          {
            //#region
          }
          <Form.Item className={classNames.otp} name="1">
            <Input
              otp
              maxLength={1}
              id="otpBox1"
              onChange={(event) => onKeyUp(1, 2, event)}
            />
          </Form.Item>
          <Form.Item className={classNames.otp} name="2">
            <Input
              otp
              maxLength={1}
              id="otpBox2"
              onChange={(event) => onKeyUp(2, 3, event)}
            />
          </Form.Item>
          <Form.Item className={classNames.otp} name="3">
            <Input
              otp
              maxLength={1}
              id="otpBox3"
              onChange={(event) => onKeyUp(3, 4, event)}
            />
          </Form.Item>
          <Form.Item className={classNames.otp} name="4">
            <Input
              otp
              maxLength={1}
              id="otpBox4"
              onChange={(event) => onKeyUp(4, 5, event)}
            />
          </Form.Item>
          <Form.Item className={classNames.otp} name="5">
            <Input
              otp
              maxLength={1}
              id="otpBox5"
              onChange={(event) => onKeyUp(5, 6, event)}
            />
          </Form.Item>
          <Form.Item className={classNames.otp} name="6">
            <Input
              otp
              maxLength={1}
              id="otpBox6"
              onChange={(event) => onKeyUp(6, 7, event)}
            />
          </Form.Item>
          {
            //#endregion
          }

          <span>Didn’t receive OTP?</span>
          <Button
            loading={verficationTokenMutation.isLoading}
            type="link"
            className={classNames.linkBtn}
            onClick={() => {
              verficationTokenMutation.reset();
              verficationTokenMutation.mutate();
            }}
          >
            Resend
          </Button>
          <CTAButton
            type="primary"
            htmlType="submit"
            style={{ height: 44, width: 384, marginTop: 35, color: "#fff" }}
            loading={createCustomerBearerToken.isLoading}
            disabled={verficationTokenMutation.isLoading}
          >
            Next
          </CTAButton>
        </Form>
      </div>
    </Spin>
  );
}
