import { useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Spin, Button, Input } from "antd";
import { useMutation } from "react-query";
import IField, { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import classNames from "./VirtualTerminal.module.scss";
import useIFieldKey from "../../../hooks/useIFieldKey";
import api from "../../API";
import { Message, ExpiryInput } from "../../common";
import { useIfield } from "../../hooks/useIfield";
import formatExpiryInput from "../../util/formatExpiryInput.util";
import { intToDecimalDollar } from "../../util/functions/dollarConvertion";
import { DEFAULT_AMOUNT } from "../../constants";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import { validation } from "../../Auth/config";

const cvvIfieldStyles = {
  borderRadius: "6px",
  height: "44px",
  border: "1px solid #d1d5dd",
  width: "90%",
  fontSize: "14px",
};

const cvvIframeStyles = {
  border: "none",
  height: "56px",
  width: "100%",
};

const cardIfieldStyles = {
  borderRadius: "6px",
  height: "44px",
  border: "1px solid #d1d5dd",
  width: "95%",
  fontSize: "14px",
};

const cardIframeStyles = {
  border: "none",
  height: "56px",
  marginTop: "16px",
  width: "100%",
};

const achIfieldStyle = {
  borderRadius: "6px",
  height: "40px",
  border: "1px solid #d1d5dd",
  width: "598px",
};

const achIframeStyle = {
  border: "none",
  height: "50px",
  marginTop: "20px",
  width: "100%",
};

const VirtualTerminal = () => {
  const [queryParams] = useSearchParams();

  const companyId = window.atob(queryParams.get("companyId") || "");
  const tenantId = window.atob(queryParams.get("tenantId") || "");

  const [xBillFirstName, setXBillFirstName] = useState(
    window.atob(queryParams.get("firstName") || "")
  );

  const [xBillLastName, setXBillLastName] = useState(
    window.atob(queryParams.get("lastName") || "")
  );

  const fullName =
    useMemo(
      () =>
        [xBillFirstName, xBillLastName]
          .filter((namePart) => !!namePart)
          .join(" ")
          .trim(),
      [xBillFirstName, xBillLastName]
    ) || "";

  const [form] = Form.useForm();

  const { iFieldKey, iFieldLoading } = useIFieldKey(tenantId, companyId);

  const [successTransaction, setSuccessTransaction] = useState(null);

  const [exDate, setExDate] = useState(null);
  const [values, setValues] = useState({});
  const paymentMethod = "cc";

  const cvvRef = useRef();
  const cardRef = useRef();

  const createTransactionFn = (data) => {
    const requestBody = {
      amount: String(queryParams.get("amount")),
      email: "",
      billFirstName: xBillFirstName,
      billLastName: xBillLastName,
      billMiddleName: "",
      accountName: fullName,
      billCompany: "",
      billStreet: "",
      billStreet2: "",
      billCity: "",
      billState: "",
      billZip: "",
      billCountry: "",
      billPhone: "",
      invoiceNumber: "",
      poNumber: "",
      description: queryParams.get("description"),
      orderId: "",
      custReceipt: true,
      ...data,
    };
    return api.Receivables.PaymentAcceptance.createHPPSaleTransaction(
      { ...requestBody, tenantId },
      "/card/sale"
    );
  };

  const createTransactionMt = useMutation((event) =>
    createTransactionFn(event)
  );

  const formatData = (val1, val2) => {
    return Object.assign({}, values, {
      cardNum: val2,
      cvv: val1,
      exp: exDate,
    });
  };

  const handleSubmit = (val1, val2) => {
    createTransactionMt.mutate(formatData(val1, val2), {
      onSuccess: (data, variables, context) => {
        const {
          data: { xResult = "E", xError = "Something went wrong" } = {},
        } = data;
        Message({
          type: xResult === "E" ? "error" : "success",
          content: xResult === "E" ? xError : "Transaction create successfully",
        });
        if (xResult !== "E") {
          setSuccessTransaction(data);
        }
        form.resetFields();
      },
      onError: (error, variables, context) => {
        Message({ type: "error", content: error.toString() });
      },
    });
  };

  const getToken = () => {
    cardRef.current.getToken();
    cvvRef.current.getToken();
  };

  const onChange = (date) => {
    setExDate(formatExpiryInput(date.target.value));
  };

  const onFinish = (values) => {
    setValues(values);
    getToken();
  };

  const {
    cvvoptions,
    ccoptions,
    onError,
    onToken,
    account,
    threeds,
    onCVVUpdate,
    onCcUpdate,
  } = useIfield(
    iFieldKey,
    cvvIfieldStyles,
    cvvIframeStyles,
    cardIfieldStyles,
    cardIframeStyles,
    paymentMethod,
    handleSubmit,
    achIfieldStyle,
    achIframeStyle
  );

  const sendDataToReactNativeApp = async (data) => {
    window.ReactNativeWebView.postMessage(data);
  };

  if (successTransaction) {
    sendDataToReactNativeApp(successTransaction?.data?.xRefNum);
  }

  if (!companyId && !tenantId) {
    return <div>Invalid request</div>;
  }

  return (
    <Spin spinning={iFieldLoading}>
      <div className={classNames.wrapper}>
        <div className={classNames.headerWrapper}>
          <div className={classNames.amountRow}>
            <div className={classNames.dollarSymbol}>$</div>
            <div className={classNames.amount}>
              {intToDecimalDollar(queryParams.get("amount"))?.slice(1) ||
                DEFAULT_AMOUNT}
            </div>
          </div>
          <div className={classNames.description}>
            {queryParams.get("description") || ""}
          </div>
        </div>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          className={classNames.formWrapper}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <div className={classNames.innerFormWrapper}>
            <Form.Item className={classNames.ccFormItemWrapper}>
              <div>
                <span className={classNames.label}>
                  Card Details <sup style={{ top: 1 }}>*</sup>
                </span>
                <IField
                  type={CARD_TYPE}
                  account={account}
                  options={ccoptions}
                  threeDS={threeds}
                  onUpdate={onCcUpdate}
                  onToken={onToken}
                  ref={cardRef}
                  onError={onError}
                />
              </div>
              <Form.Item
                name="expiry"
                rules={[{ required: true, message: "Expiry is required" }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <ExpiryInput onChange={onChange} />
              </Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <IField
                  type={CVV_TYPE}
                  account={account}
                  options={cvvoptions}
                  threeDS={threeds}
                  onUpdate={onCVVUpdate}
                  onToken={onToken}
                  ref={cvvRef}
                  onError={onError}
                />
              </Form.Item>
            </Form.Item>
            <div className={classNames.detailsFormWrapper}>
              <Form.Item>
                <Form.Item
                  name="first"
                  label={
                    <span className={classNames.darkLabel}>First Name</span>
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginBottom: "10px",
                    float: "left",
                  }}
                  rules={[
                    {
                      max: MAX_MIN.name.max,
                      message: validation.firstName.maxLength,
                    },
                    {
                      pattern: new RegExp(REGEX.name),
                      message: validation.firstName.valid,
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter"
                    defaultValue={xBillFirstName}
                    onChange={(e) => setXBillFirstName(e.target.value)}
                    className={classNames.input}
                  />
                </Form.Item>

                <Form.Item
                  name="last"
                  label={
                    <span className={classNames.darkLabel}>Last Name</span>
                  }
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 4px)",
                    margin: "0 0 0 4px",
                    float: "right",
                  }}
                  rules={[
                    {
                      max: MAX_MIN.name.max,
                      message: validation.lastName.maxLength,
                    },
                    {
                      pattern: new RegExp(REGEX.name),
                      message: validation.lastName.valid,
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter"
                    defaultValue={xBillLastName}
                    onChange={(e) => setXBillLastName(e.target.value)}
                    className={classNames.input}
                  />
                </Form.Item>
              </Form.Item>
            </div>
          </div>
          <div className={classNames.buttonWrapper}>
            <Button
              htmlType="submit"
              type="primary"
              id="submit-btn"
              key="submit"
              loading={createTransactionMt.isLoading}
              className={classNames.submitButton}
            >
              Process Payment
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default VirtualTerminal;
