import React from "react";

const USAPatriotAct = () => {
  return (
    <>
      <h2>
        Important information about procedures for opening or changing an
        account with Otterz under the USA Patriot Act
      </h2>
      <p>
        To help the government fight the funding of terrorism and money
        laundering activities, Section 326 of the USA PATRIOT ACT requires all
        financial institutions to obtain, verify and record information that
        identifies each person who opens an account or changes an existing
        account.
      </p>
      <div>
        When you open an account or change an existing account, we will ask for
        the following:
        <ul>
          <li>
            <span>Name</span>
          </li>
          <li>
            <span>Date of Birth for individuals</span>
          </li>
          <li>
            <span>Place of residence and mailing address (if different)</span>
          </li>
          <li>
            <span>
              Taxpayer Identification Number (i.e., Social Security Number) for
              U.S. persons
            </span>
          </li>
          <li>
            <span>One or more for a non-U.S. person:</span>
            <ul>
              <li>
                <span>A taxpayer identification number,</span>
              </li>
              <li>
                <span>Passport number and country of issuance,</span>
              </li>
              <li>
                <span>Alien identification card number, or</span>
              </li>
              <li>
                <span>
                  Number and country of issuance of any other government-issued
                  document evidencing nationality or residence and bearing a
                  photograph or similar safeguard.
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p>
        Financial Institutions are permitted to impose and implement additional
        identity verification procedures when deemed necessary and appropriate,
        as specified under the Act.
      </p>
    </>
  );
};
export default USAPatriotAct;
