import React, { useReducer, createContext, useEffect } from "react";

import {
  getAccountReducer,
  getApplicationByTenantIdReducer,
  userDataReducer,
  getBusinessPersonDetailReducer,
} from "./reducers";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  account: {
    tempIndividualAccountId: "480981",
    tempIndividualCustomerId: "202707",
    tempStatementAccId: "154284",
    companyId: "67F30660-332B-457C-A3D0-7024E4AD05B5",
    unitCompanyId: "236915",
    invoiceaClientId: "F08BE99E-2EF4-4E26-8675-B3B07207874B",
  },
  user: {},
  application: {},
  businessData: [],
};

const AppState = (props) => {
  const [cachedUserData, setUserDataCache] = useLocalStorage(
    "OTTERZ.userData",
    {
      ...initialState.user,
    }
  );
  const [cachedApplicationData, setApplicationDataCache] = useLocalStorage(
    "OTTERZ.application",
    { ...initialState.application }
  );

  const [cachedBusinessPersonDetailData, setBusinessPersonDetailCache] =
    useLocalStorage("OTTERZ.businessPersonDetail", {
      ...initialState.application,
    });

  const [getAccountState, getAccountDispatch] = useReducer(
    getAccountReducer,
    initialState.account
  );
  const [getAppliState, getAppliDispatch] = useReducer(
    getApplicationByTenantIdReducer,
    cachedApplicationData
  );

  const [getBusinessPersonDetailState, getBusinessPersonDetailDispatch] =
    useReducer(getBusinessPersonDetailReducer, cachedBusinessPersonDetailData);

  const [userData, dispatchUserData] = useReducer(
    userDataReducer,
    cachedUserData
  );

  useEffect(() => {
    setUserDataCache(userData);
  }, [userData, setUserDataCache]);

  useEffect(() => {
    setApplicationDataCache(getAppliState);
  }, [getAppliState, setApplicationDataCache]);

  useEffect(() => {
    setBusinessPersonDetailCache(getBusinessPersonDetailState);
  }, [getBusinessPersonDetailState, setBusinessPersonDetailCache]);

  return (
    <AppContext.Provider
      value={{
        account: getAccountState,
        getAccount: getAccountDispatch,
        application: getAppliState,
        dispatchApplication: getAppliDispatch,
        userData,
        dispatchUserData,
        businessPersonDetail: getBusinessPersonDetailState,
        dispatchBusinessPersonDetail: getBusinessPersonDetailDispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
export const AppContext = createContext({});
