import moment from "moment";

const tomorrowMorning = moment(
  moment().add(1, "days").format("YYYY-MM-DD").concat(" 00:00:00")
);

const thisWeekSundayMorning = moment(
  moment()
    .add(7 - moment().isoWeekday(), "days")
    .format("YYYY-MM-DD")
    .concat(" 00:00:00")
);

const beginningOfNextMonth = moment(
  moment(moment().add(1, "month").format("YYYY-MM").concat("-01 00:00:00"))
);

const days = [];
for (let d = 1; d <= 10; d++) {
  const someDaysAgo = moment(moment(tomorrowMorning).subtract(d, "days"));
  const x = `${someDaysAgo.get("month") + 1}/${someDaysAgo.get("date")}`;
  days.push({
    x,
    y: 0,
  });
}

const weeks = [];
for (let w = 1; w <= 10; w++) {
  const someWeeksAgo = moment(
    moment(thisWeekSundayMorning).subtract(w, "weeks")
  );
  const x = `${someWeeksAgo.get("month") + 1}/${someWeeksAgo
    .startOf("week")
    .get("date")}`;
  weeks.push({
    x,
    y: 0,
  });
}

const months = [];
for (let m = 1; m <= 3; m++) {
  const someMonthsAgo = moment(
    moment(beginningOfNextMonth).subtract(m, "months")
  );
  const x = `${someMonthsAgo.format("MMM")}`;
  months.push({
    x,
    y: 0,
  });
}

days.reverse();
weeks.reverse();
months.reverse();

const graphData = { days, weeks, months };

export default graphData;
