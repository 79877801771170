import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Button } from 'antd';
import { Modal, OTPInput, Message } from '../../../../common';
// import { maskPhone } from '../../../util';
// import messages from '../../config/messages.const';
// import useLanguage from '../../../../hooks/useLanguage';
// import generateErrorMessage from '../../../util/functions/customError';
import { tempUrls } from '../../../../../apiEndpoints';
import classNames from "./AuthModal.module.scss"

function ACHTransactionModal({ visible, closable, maskClosable, onCloseClick, onCancel, onAuthorize }) {
  const [timer, setTimer] = useState(60);
  const [disable, setDisable] = useState(true);
  const [otpID, setID] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setTimer(0)
    if (visible) {
      setTimer(60);
      generateOTPApiCall()
    }
  }, [visible]);

  useEffect(() => {
    let timeoutId;
  
    if (timer > 0) {
      timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timer]);
  
  async function generateOTPApiCall() {
    const env = process.env.REACT_APP_ENV?.trim();
    const otpGenerationEndpoint = tempUrls.OTP_GENERATION[env];
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`);
  
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders
    };
  
    try {
      const response = await fetch(otpGenerationEndpoint, requestOptions);
      const result = await response.text();
      setID(JSON.parse(result).payload.id)
    } catch (error) {
      console.error('error', error);
    }
  }
  
  async function verifyOTPApiCall(otp) {
    const env = process.env.REACT_APP_ENV?.trim();
    const otpVerificationEndpoint = tempUrls.OTP_VERIFICATION[env];
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`);
  
    const raw = JSON.stringify({
      "OTPNumber": otp,
      "id": otpID
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    try {
      const response = await fetch(otpVerificationEndpoint, requestOptions);
      const result = await response.text();
      return JSON.parse(result).payload.isOTPVerified
    } catch (error) {
      return error
    }
  }

  const handleAuthorizeClick = (OTP) => {
    const otp = Object.values(OTP).join('');
    setIsOTPVerified(true);
    verifyOTPApiCall(otp).then(isValid => {
      if (isValid) {
        onAuthorize();
      } else {
        setIsOTPVerified(false);
        Message({ type: 'error', content: 'The entered OTP is not correct. Please try again.' });
      }
    });
  };

  const handleResendClick = () => {
    setTimer(60);
    generateOTPApiCall()
  };

  return (
    <Modal className={classNames.wireAuthModal} title="Authorize ACH Transfer" visible={visible} onCloseClick={onCloseClick} onCancel={onCancel}>
      <p className={classNames.subHead} style={{textAlign: "center"}}>An OTP has been sent to the mobile number and email address of the beneficial owners</p>
            <Form
              className={classNames.wireAuthForm}
              form={form}
              onFinish={handleAuthorizeClick}
              layout="vertical"
              autoComplete="off"
              onValuesChange={() => {
                setDisable(false);
              }}
            >
              <Form.Item
                label=""
                rules={[{ required: true, message: "required" }]}
              >
                <OTPInput />
              </Form.Item>
              <div className={classNames.linksWrapper}>
                {timer > 0 ? (
                  <div>
                    Time Remaining: {`00:${timer < 10 ? 0 : ""}${timer}`}
                  </div>
                ) : (
                  <>
                    <div className={classNames.links}>Didn’t receive code</div>
                    <Button type="link" size="small" onClick={handleResendClick}>
                      Resend
                    </Button>
                  </>
                )}
              </div>
              <Button htmlType="submit" type="primary" size="large" disabled={disable || isOTPVerified} style={{padding: "6.4px 50px"}}>
                Authorize Transfer
              </Button>
            </Form>
    </Modal>
  );
}

export default ACHTransactionModal;
