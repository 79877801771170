import { API } from "aws-amplify";

export default async function getAllTaxRates(id) {
  if (id) {
    return await API.get("taxRate", "list-tax", {
      queryStringParameters: {
        companyId: id,
      },
    });
  }
}
