import { Form, Tabs } from "antd"; // Import Tabs from Ant Design
import { useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CTAButton, Message, Modal } from "../../common";
import UserProfileFrom from "./UserProfileForm";
import { uploadProfilePicture } from "./uploadProfilePicture";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import { isBusinessType } from "../../util/functions/onboarding";
import classNames from "./UserProfile.module.scss";
// import BusinessOperationForm from "./BusinessOperationForm";
import AccountActivityForm from "./AccountActivityForm";
import generateErrorMessage from "../../util/functions/customError";

const { TabPane } = Tabs; // Destructure TabPane from Tabs

export default function UserProfile({ visible, onCancel }) {
  const { application, userData } = useContext(AppContext);
  const [form] = Form.useForm();
  const [userProfileFile, setUserProfileFile] = useState();
  const [activeTab, setActiveTab] = useState("1"); // Initialize activeTab state

  const handleTabChange = (key) => {
    setActiveTab(key); // Update activeTab state when the tab changes
  };

  const queryClient = useQueryClient();
  const {
    mutate: updateUserProfileMn,
    isLoading: updateUserProfileMnIsLoading,
  } = useMutation(api.Settings.updateUserProfile);
  const [userInfo, setUserInfo] = useState();

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getUserByIdQuery } = useQuery(
    "getUserById",
    () => api.Settings.getUserById(currentUserData.username),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const applicationData = application;
  const id = isBusinessType(applicationData?.type)
    ? applicationData?.otz_business_id
    : applicationData?.otz_person_id;

  // Api calls
  useQuery("getPersonDetail", () => api.BusinessBanking.getPerson(id), {
    onSuccess: (data) => {
      setUserInfo(data?.payload);
    },
    enabled: !!applicationData?.otz_person_id,
    refetchOnWindowFocus: false,
  });

  useQuery("getBusinessDetail", () => api.BusinessBanking.getBusiness(id), {
    onSuccess: (data) => {
      setUserInfo(data?.payload);
    },
    enabled: !!applicationData?.otz_business_id,
    refetchOnWindowFocus: false,
  });

  const updateDetails = useMutation((data) =>
    api.onBoarding.updateBusinessOperationDetails(data)
  );  

  async function callback(data) {
    let currentUser = await Auth.currentUserInfo();
    updateUserProfileMn(
      {
        id: currentUser.username,
        base64String: data,
        fileName: `${currentUser.username}-otterz-user-profile`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getUserById");
          Message({ type: "success", content: "Uploaded successfully!" });
          onCancel();
        },
        onError: (error) => {
          Message({ type: "error", content: error.toString() });
        },
      }
    );
  }

    // Function to apply styles to tab elements
  const applyTabStyles = () => {
    const tabElements = document.querySelectorAll(".ant-tabs-tab");
    const tabNav = document.querySelectorAll(".ant-tabs-nav-list");

    // tabNav.style.justifyContent = "space-evenly"

    tabElements.forEach((element) => {
      element.style.justifyContent = "center";
      element.style.display = "flex";
    });
    tabNav.forEach((element) => {
      element.style.justifyContent = "space-evenly";
    });
  };

  useEffect(() => {
    applyTabStyles();
  }, [visible]);

  const onOk = () => {
    console.log("ok");
    if (activeTab === "1") {
      if (userProfileFile) {
        uploadProfilePicture(userProfileFile.fileList[0].originFileObj, callback);
      }
    } else if (activeTab === "2") {
      // Perform actions specific to Tab 2
      console.log("Update Profile on Tab 2");
    } else if (activeTab === "3") {
      // Perform actions specific to Tab 3
      const formData = form.getFieldsValue()

      const data = {
        otz_business_id: application?.otz_business_id,
        incoming_ach_transaction_amt: formData.estimatedAmount0,
        incoming_ach_transaction_count: formData.estimatedTransactions0,
        wire_transaction_domestic_amt: formData.estimatedAmount1,
        wire_transaction_domestic_count: formData.estimatedTransactions1,
        wire_transaction_inter_amt: formData.estimatedAmount2,
        wire_transaction_inter_count: formData.estimatedTransactions2,
        remote_deposits_amt: formData.estimatedAmount3,
        remote_deposits_count: formData.estimatedTransactions3,
        debit_card_transactions_amt: formData.estimatedAmount4,
        debit_card_transactions_count: formData.estimatedTransactions4,
      }
  
      updateDetails.mutate(data, {
          onSuccess: () => {
            Message({ type: "success", content: "Business details submitted successfully" });
          },
          onError: (error) => {
            generateErrorMessage(error);
          },
        });
    }
  };

  
  const updateDisabled = () => {
    if (activeTab === "1") {
      return userProfileFile === undefined
    } else if (activeTab === "2") {
      // Perform actions specific to Tab 2
      return false
    } else if (activeTab === "3") {
      // Perform actions specific to Tab 3
      return false
    }
  }
  
  const onFinish = (data) => {};

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      // title={<span style={{ fontWeight: "bold" }}>User Profile</span>}
      className={classNames.modal}
      bodyStyle={{
        height: "75vh",
        maxHeight: 900,
        padding: 0,
        // display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
      footer={
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          style={{
            height: 44,
            width: 300,
          }}
          onClick={onOk}
          loading={updateUserProfileMnIsLoading}
          disabled={updateDisabled()}
        >
          Update Profile
        </CTAButton>
      }
      width={910}
    >
      {/* Add Tabs to switch between UserProfileFrom components */}
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleTabChange}
        tabPosition="top"
        // tabBarGutter={"10vw"}
        centered={true}
        style={{ justifyContent: "center !important" }}
      >
        <TabPane tab="Business Profile" key="1">
          <UserProfileFrom
            form={form}
            onFinish={onFinish}
            setUserProfileFile={setUserProfileFile}
            userData={getUserByIdQuery?.data}
            userProfileData={userData}
            applicationData={userInfo}
            isBusiness={isBusinessType(applicationData?.type)}
          />
        </TabPane>
        {/* <TabPane tab="Business Operations Details" key="2">
          <BusinessOperationForm
            form={form}
            onFinish={onFinish}
            setUserProfileFile={setUserProfileFile}
            userData={getUserByIdQuery?.data}
            userProfileData={userData}
            applicationData={userInfo}
            isBusiness={isBusinessType(applicationData?.type)}
          />
        </TabPane> */}
        <TabPane tab="Account Activity Details" key="3">
          <AccountActivityForm
            form={form}
            onFinish={onFinish}
            setUserProfileFile={setUserProfileFile}
            userData={getUserByIdQuery?.data}
            userProfileData={userData}
            applicationData={userInfo}
            isBusiness={isBusinessType(applicationData?.type)}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}
