const maskEmail = (email) => {
  let prefix = email.substring(0, email.lastIndexOf("@"));
  let postfix = email.substring(email.lastIndexOf("@"));

  switch (prefix.length) {
    case 1:
      return `*${postfix}`;
    case 2:
      return `${prefix[0]}*${postfix}`;
    default:
      return `${prefix[0]}****${prefix[prefix.length - 1]}${postfix}`;
  }
};

export default maskEmail;
